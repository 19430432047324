import source from '../downloaded/char2PotentialWeight.json' assert { type: 'json' };

export const potentialWeights = Array(source.length);

for (const row of source) {
  let { companyRank, potentialWeight_1, potentialWeight_2, potentialWeight_3, potentialWeight_4 } = row;
  const potentialTierWeights = [
    {
      tier: 1,
      weight: +potentialWeight_1
    },
    {
      tier: 2,
      weight: +potentialWeight_2
    },
    {
      tier: 3,
      weight: +potentialWeight_3
    },
    {
      tier: 4,
      weight: +potentialWeight_4
    },
  ]

  potentialWeights[+companyRank - 1] = {
    potentialTierWeights
  }
}


