import source from '../downloaded/ambitions.json' assert { type: 'json' };

export const ambitions = [];
for (const row of source) {
  let { key, name, condition } = row;

  ambitions.push({
    key,
    name,
    condition,
  });
}

