import source from '../downloaded/outloopPoints.json' assert { type: 'json' };

export const points = [];

for (const row of source) {
  const { mission_num, one_point, two_point, gauge_max } = row;

  points.push({
    mission_num: parseInt(mission_num),
    one_point: parseInt(one_point),
    two_point: parseInt(two_point),
    gauge_max: parseInt(gauge_max),
  });
}
