
import AgentDetails from './AgentDetails';
import ItemTab from './ItemTab';

export function AgentDetailDialog(props) {
  const { ch, open, handleClose, onHire } = props;
  return <>
    {open &&
      <div style={{ position: 'fixed', backgroundColor: 'rgba(0,0,0,0.4)', top: '0', left: '0', right: '0', bottom: '0' }}>
        <dialog
          open={open}
          style={{ position: 'fixed', top: '50%', transform: 'translate(0%,-50%)' }}
        >
          <ul>
            <AgentDetails character={ch.character} />
            {ch.hired ?
              <ItemTab ch={ch}
                onPerkSelect={props.onPerkSelect}
                onPerkUnselect={props.onPerkUnselect}
                onWeapon={props.onWeapon}
                onEquip={props.onEquip}
              /> :
              <></>
            }
          </ul>
          <ul>
            {!ch.hired ?
              <button onClick={() => onHire(ch)}>고용하기: {ch.character.cost}BTC</button> :
              <></>
            }
            <button onClick={handleClose}>닫기</button>
          </ul>
        </dialog >
      </div>
    }
  </>;
}