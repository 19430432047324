import React from 'react';

import { L } from './localization.mjs';

import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView.js';

import './Badges.css';

const tierIcons = {
  1: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
    <path d="M8 21L0 15V19L8 25L16 19V15L8 21Z" fill="#ECB86A" />
    <path d="M8 21L0 15V19L8 25L16 19V15L8 21Z" fill="#FFF1A7" />
    <path d="M8 16L0 10V14L8 20L16 14V10L8 16Z" fill="#ECB86A" />
    <path d="M8 16L0 10V14L8 20L16 14V10L8 16Z" fill="#FFF1A7" />
    <path d="M8 11L0 5V9L8 15L16 9V5L8 11Z" fill="#FFF1A7" />
    <rect y="25" width="16" height="3" fill="#ECB86A" />
    <rect y="25" width="16" height="3" fill="#FFF1A7" />
    <path d="M8 0L8.89806 2.76393H11.8042L9.45308 4.47214L10.3511 7.23607L8 5.52786L5.64886 7.23607L6.54692 4.47214L4.19577 2.76393H7.10194L8 0Z" fill="#EF5F5F" />
    <path d="M8 0L8.89806 2.76393H11.8042L9.45308 4.47214L10.3511 7.23607L8 5.52786L5.64886 7.23607L6.54692 4.47214L4.19577 2.76393H7.10194L8 0Z" fill="#FFF1A7" />
  </svg>,
  2: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23" fill="none">
    <path d="M8 16L0 10V14L8 20L16 14V10L8 16Z" fill="#ECB86A" />
    <path d="M8 11L0 5V9L8 15L16 9V5L8 11Z" fill="#ECB86A" />
    <path d="M8 6L0 0V4L8 10L16 4V0L8 6Z" fill="#ECB86A" />
    <rect y="20" width="16" height="3" fill="#ECB86A" />
  </svg>,
  3: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path d="M8 11L0 5V9L8 15L16 9V5L8 11Z" fill="#A4A7B0" />
    <path d="M8 6L0 0V4L8 10L16 4V0L8 6Z" fill="#A4A7B0" />
    <rect y="15" width="16" height="3" fill="#A4A7B0" />
  </svg>,
  4: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
    <path d="M8 6L0 0V4L8 10L16 4V0L8 6Z" fill="#A86637" />
    <rect y="10" width="16" height="3" fill="#A86637" />
  </svg>,
  5: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
    <path d="M8 6L0 0V4L8 10L16 4V0L8 6Z" fill="#666363" />
  </svg>,
};

export function TierBadge2(props) {
  const { tier, isFormal } = props;
  if (isFormal) {
    return <div className='figma-badge2-tier-formal'>
      <div className={'figma-badge2-tier-formal-border figma-badge2-tier-formal-border-' + tier}>
        <div className={'figma-badge2-tier-formal-background figma-badge2-tier-formal-background-' + tier}>
          {tierIcons[tier]}
        </div>
      </div>
    </div>;
  }

  return <div className='figma-badge2-tier'>
    <span className={"figma-badge2-tier-label figma-badge2-tier-label-" + tier}>{tier}</span>
    {tierIcons[tier]}
  </div>;
}

const ICON_POWER = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2357_12562)">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.7452 1.57613C10.3477 1.13185 9.65222 1.13185 9.25471 1.57612L2.09656 9.57641C1.75688 9.95605 1.75688 10.5304 2.09656 10.91L9.25471 18.9103C9.65222 19.3546 10.3477 19.3546 10.7452 18.9103L17.9033 10.91C18.243 10.5304 18.243 9.95605 17.9033 9.57642L10.7452 1.57613ZM6.26655 10.9122L10.8609 2.87211L10.4097 9.18935H13.7324L9.13802 17.2295L9.58925 10.9122H6.26655Z" fill="#083B41" />
  </g>
  <defs>
    <clipPath id="clip0_2357_12562">
      <rect width="19" height="19.4863" fill="white" transform="translate(0.5 0.256836)" />
    </clipPath>
  </defs>
</svg>;

export function PowerIndicatorNumber(props) {
  const { character } = props;
  const isAlly = (character.team !== 1);

  const power = isAlly ? character.power : getEnemyPower(character);
  const gearPower = getGearPower(character).power;

  return <div className="figmalist-body2-list-row-item-label-power-number">
    <div className="figmalist-body2-list-row-item-label-power-number-wrapper">
      <div className="figmalist-body2-list-row-item-label-power-icon">
        {ICON_POWER}
      </div>
      <div className="figmalist-body2-list-row-item-label-power-inner">
        <span>{+(power + gearPower).toFixed(1)}</span>
      </div>
    </div>
  </div>;
}

export function getGearPower(character) {
  const gearPower = {
    power: 0,
    shooting: 0,
    physical: 0,
  };
  const isAlly = (character.team !== 1);
  if (isAlly) {
    if (character.firearm) {
      gearPower.shooting = character.firearm.firearm_expectedPower ?? 0;
      gearPower.power += gearPower.shooting / 5;
    }
    if (character.equipment) {
      gearPower.physical = character.equipment.gear_expectedPower ?? 0;
      gearPower.power += gearPower.physical / 5;
    }
  } else {
    const armor = character.vest_shield ?? 0;

    gearPower.shooting = character.firearm_expectedPower;
    gearPower.physical = armor / 44;
    gearPower.power = (gearPower.shooting + gearPower.physical) / 5;
  }
  return gearPower;
}

export function getEnemyPower(character) {
  const { _stat } = character;
  return (_stat.shooting + _stat.physical + _stat.tactical + _stat.mental + _stat.perception) / 5;
}

export function PowerIndicator(props) {
  const { character, starSize } = props;
  const isAlly = (character.team !== 1);

  const power = isAlly ? character.power : getEnemyPower(character);
  const gearPower = getGearPower(character).power;

  let remaining = power + gearPower;
  let remainingPower = power;
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (remaining >= starSize) {
      if (remainingPower >= starSize) {
        stars.push(<PowerIndicatorStar key={i} amount={starSize} max={starSize} />);
        remainingPower -= starSize;
      } else {
        stars.push(<PowerIndicatorStar key={i} amount={remainingPower} altAmount={starSize - remainingPower} max={starSize} />);
        remainingPower = 0;
      }
      remaining -= starSize;
    } else if (remaining > 0) {
      stars.push(<PowerIndicatorStar key={i} amount={remainingPower} altAmount={remaining - remainingPower} max={starSize} />);
      remainingPower = 0;
      remaining = 0;
    } else {
      stars.push(<PowerIndicatorStar key={i} amount={0} max={starSize} />);
    }
  }
  return <div className="figmalist-body2-power">
    <PowerIndicatorNumber character={character} />
    <div className="figmalist-body2-power-stars">
      {stars}
    </div>
  </div>;
}

function PowerIndicatorStar(props) {
  const { amount, altAmount, max } = props;
  const allStyle = {
    clipPath: `inset(0 ${(1 - (amount + altAmount) / max) * 100}% 0 0)`,
  };
  const baseStyle = {
    clipPath: `inset(0 ${(1 - amount / max) * 100}% 0 0)`,
  };
  const altStyle = {
    clipPath: `inset(0 ${(1 - (amount + altAmount) / max) * 100}% 0 ${(amount / max) * 100}%)`,
  }
  return <div className='figmalist-body2-power-star'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
      <path d="M9.62855 0.548324C10.0109 -0.131033 10.9891 -0.131031 11.3715 0.548325L13.7721 4.81363C13.9512 5.1318 14.2894 5.32708 14.6545 5.32308L19.5487 5.26946C20.3282 5.26092 20.8173 6.10805 20.4202 6.77886L17.9266 10.9905C17.7406 11.3047 17.7406 11.6953 17.9266 12.0095L20.4202 16.2211C20.8173 16.892 20.3282 17.7391 19.5487 17.7305L14.6545 17.6769C14.2894 17.6729 13.9512 17.8682 13.7721 18.1864L11.3715 22.4517C10.9891 23.131 10.0109 23.131 9.62855 22.4517L7.22789 18.1864C7.04881 17.8682 6.71057 17.6729 6.34548 17.6769L1.4513 17.7305C0.671773 17.7391 0.182689 16.892 0.579846 16.2211L3.07338 12.0095C3.25938 11.6953 3.25938 11.3047 3.07338 10.9905L0.579845 6.77886C0.182688 6.10804 0.671774 5.26092 1.4513 5.26946L6.34549 5.32308C6.71057 5.32708 7.04881 5.1318 7.22789 4.81363L9.62855 0.548324Z" fill="#030201" />
    </svg>
    <div className='figmalist-body2-power-star-fill-all' style={allStyle}>
      <div className='figmalist-body2-power-star-fill-base' style={baseStyle}>
        <svg className='figmalist-body2-power-star-fill-bg-base' xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
          <path d="M9.5 0.993156L11.969 5.23049C12.1474 5.53672 12.4746 5.72563 12.829 5.72704L17.7332 5.74658L15.298 10.0035C15.122 10.3111 15.122 10.6889 15.298 10.9965L17.7332 15.2534L12.829 15.273C12.4746 15.2744 12.1474 15.4633 11.969 15.7695L9.5 20.0068L7.03101 15.7695C6.85258 15.4633 6.52539 15.2744 6.17097 15.273L1.26683 15.2534L3.70198 10.9965C3.87797 10.6889 3.87797 10.3111 3.70198 10.0035L1.26683 5.74658L6.17097 5.72704C6.52539 5.72563 6.85258 5.53672 7.03101 5.23049L9.5 0.993156Z" fill="#2899A8" stroke="#0E7C8B" />
        </svg>
      </div>
      {altAmount ? <div className='figmalist-body2-power-star-fill-alt' style={altStyle}>
        <svg className='figmalist-body2-power-star-fill-bg-alt' xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
          <path d="M9.5 0.993156L11.969 5.23049C12.1474 5.53672 12.4746 5.72563 12.829 5.72704L17.7332 5.74658L15.298 10.0035C15.122 10.3111 15.122 10.6889 15.298 10.9965L17.7332 15.2534L12.829 15.273C12.4746 15.2744 12.1474 15.4633 11.969 15.7695L9.5 20.0068L7.03101 15.7695C6.85258 15.4633 6.52539 15.2744 6.17097 15.273L1.26683 15.2534L3.70198 10.9965C3.87797 10.6889 3.87797 10.3111 3.70198 10.0035L1.26683 5.74658L6.17097 5.72704C6.52539 5.72563 6.85258 5.53672 7.03101 5.23049L9.5 0.993156Z" fill="#4a28a8" stroke="#380e8b" />
        </svg>
      </div> : null}
      <svg className='figmalist-body2-power-star-fill-glow' xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <mask id="mask0_793_52949" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="19" height="21">
          <path d="M10.5 0.993156L12.969 5.23049C13.1474 5.53672 13.4746 5.72563 13.829 5.72704L18.7332 5.74658L16.298 10.0035C16.122 10.3111 16.122 10.6889 16.298 10.9965L18.7332 15.2534L13.829 15.273C13.4746 15.2744 13.1474 15.4633 12.969 15.7695L10.5 20.0068L8.03101 15.7695C7.85258 15.4633 7.52539 15.2744 7.17097 15.273L2.26683 15.2534L4.70198 10.9965C4.87797 10.6889 4.87797 10.3111 4.70198 10.0035L2.26683 5.74658L7.17097 5.72704C7.52539 5.72563 7.85258 5.53672 8.03101 5.23049L10.5 0.993156Z" />
        </mask>
        <g mask="url(#mask0_793_52949)">
          <path d="M20 0.5L1.5 16V0.5H20Z" fill="#5CACB7" />
        </g>
      </svg>
    </div>
  </div >;
}

export function AgentModifierIcon(props) {
  const { modifier, turn } = props;
  const { start, term } = modifier;

  const data = data_agentModifiers.find((d) => d.key === modifier.key);
  const remainText = term === 'semiperma' || !turn ? '' : '(' + L('loc_dynamic_string_common_duration_days', { count: Math.ceil((start + term - turn) / 24) }) + ')';
  const modifierTag = data.ty === 'identity' ? 'identity' : data.category;
  const content = <div className="grind-agent-modifier-tooltip-content">
    <div className="grind-agent-modifier-tooltip-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="55" viewBox="0 0 54 55" fill="none">
        <path d="M1 49.8982V4.16656L4.13421 1.03235H49.8658L53 4.16656V49.8982L49.8658 53.0323H4.13417L1 49.8982Z" fill="#EDCA4B" stroke="#E5C061" stroke-width="2" />
      </svg>
      <div className="grind-agent-modifier-tooltip-icon-inner">
        <div className={`grind-agent-modifier-icon grind-agent-modifier-${modifierTag}`}>
          {L(data.name).charAt()}
        </div>
      </div>
    </div>
    <div className="grind-agent-modifier-tooltip-desc">{L(data.name) + remainText + ': ' + L(data.desc)}</div>
  </div>;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className={`grind-agent-modifier-icon grind-agent-modifier-${modifierTag}`}
        {...figmaTooltipEvents(context, content, 'modifier')}
      >{L(data.name).charAt()}</div>;
    }}
  </TooltipContext.Consumer >;
}

export function AgentModifierBody(props) {
  const { modifier, turn } = props;
  const { start, term } = modifier;

  const data = data_agentModifiers.find((d) => d.key === modifier.key);
  const remainText = term === 'semiperma' || !turn ? '' : '(' + L('loc_dynamic_string_common_duration_days', { count: Math.ceil((start + term - turn) / 24) }) + ')';
  const modifierTag = data.ty === 'identity' ? 'identity' : data.category;
  const content = <div className="grind-agent-modifier-tooltip-content">
    <div className="grind-agent-modifier-tooltip-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="55" viewBox="0 0 54 55" fill="none">
        <path d="M1 49.8982V4.16656L4.13421 1.03235H49.8658L53 4.16656V49.8982L49.8658 53.0323H4.13417L1 49.8982Z" fill="#EDCA4B" stroke="#E5C061" stroke-width="2" />
      </svg>
      <div className="grind-agent-modifier-tooltip-icon-inner">
        <div className={`grind-agent-modifier-icon grind-agent-modifier-${modifierTag}`}>
          {L(data.name).charAt()}
        </div>
      </div>
    </div>
    <div className="grind-agent-modifier-tooltip-desc">{L(data.name) + remainText + ': ' + L(data.desc)}</div>
  </div>;
  return <TooltipContext.Consumer>
    {(context) => {

      return <span className={`grind-agent-modifier grind-agent-modifier-${modifierTag}`}
        {...figmaTooltipEvents(context, content, 'modifier')}
      >{L(data.name)}{remainText}</span>;
    }}
  </TooltipContext.Consumer>;
}


