//https://docs.google.com/spreadsheets/d/15vC8KWLlkspvR_4M-WsvyvkltTNCqec9iGTZrmdQ7ao/edit#gid=891026689

const raw_mission_objectives = `obj_kill	전원 사살	1	main		임무에 등장하는 적 모두를 사살`

const raw_mission_locations = `loc_downtown	시내	indoor, indoor2, shooting_house1_short, alley1_short
loc_suburb	근교	embassy_short, maze, shooting_house2_short, exhibition_hall_short
loc_pasture	목장	outdoor, storehouse_short, border_checkpoint_short`

const raw_mission_sizes = `mission_size_0_0	분대 미만	1	1	5
mission_size_1_0	1개 분대 규모	2	6	10
mission_size_1_1		3	11	15
mission_size_2_0	2개 분대 규모	4	16	20
mission_size_2_1		5	21	25
mission_size_3_0	1개 소대 규모	6	26	35
mission_size_3_1		7	36	45
mission_size_4_0	2개 소대 규모	8	46	60
mission_size_4_1		9	61	75`

const raw_mission_levels = `mission_level_0	오합지졸	1	2
mission_level_1	훈련됨	2	3
mission_level_2	프로	3	4
mission_level_3	베테랑	4	5
mission_level_4	엘리트	5	6`

const raw_client_infos = `client_patience_5	patience	인내심	5	1	매우 높음
client_patience_4	patience	인내심	4	0	높음
client_patience_3	patience	인내심	3	0	보통
client_patience_2	patience	인내심	2	0	낮음
client_patience_1	patience	인내심	1	0	매우 낮음
client_patience_0	patience	인내심	0	0	고갈
client_relation_1	relation	관계	1	1	우호적
client_relation_0	relation	관계	0	3	중립적
client_relation_-1	relation	관계	-1	1	적대적
client_personality_1	personality	성격	1	1	느긋함
client_personality_0	personality	성격	0	3	평범함
client_personality_-1	personality	성격	-1	1	성급함
client_attitude_2	attitude	태도	2	0	만족함
client_attitude_1	attitude	태도	1	1	관심 있음
client_attitude_0	attitude	태도	0	0	걱정함
client_attitude_-1	attitude	태도	-1	0	냉랭함
client_attitude_-2	attitude	태도	-2	0	분개함
pref_importance_1	importance	중요도	1	1	손해를 좀 보더라도 어떻게든 임무를 성공시키려 합니다.
pref_importance_0	importance	중요도	0	3	임무가 성공하기를 바라지만 협상에서 손해를 볼 생각은 없습니다.
pref_importance_-1	importance	중요도	-1	1	불리한 조건으로 계약할 바에는 협상을 포기할 생각입니다.
pref_prospect_1	prospect	성공전망	1	1	임무 성공을 확신합니다.
pref_prospect_0	prospect	성공전망	0	3	임무 성공을 기대하지만 절대적인 믿음은 아닙니다.
pref_prospect_-1	prospect	성공전망	-1	1	성공 가능성에 부정적입니다.
pref_early_1	early	조바심	1	1	가능한 빨리 상황이 종료되기를 원합니다.
pref_early_0	early	조바심	0	3	빨리 끝내면 좋지만 절박하지는 않습니다.
pref_early_-1	early	조바심	-1	1	일을 빠르게 끝내는 것에는 별 관심이 없습니다.
pref_perfect_1	perfect	전원 무사	1	1	전투원 전원 멀쩡히 나오는 그림을 바랍니다.
pref_perfect_0	perfect	전원 무사	0	4	전투원의 안위에는 별 관심이 없습니다.
pref_time_1	time	신속 이탈	1	1	신속하게 임무를 끝내고 이탈하기를 바랍니다.
pref_time_0	time	신속 이탈	0	4	빠르게 끝내든 천천히 수행하든 상관 없습니다.
pref_few_1	few	인원 제한	1	1	소수 인원으로 성공시키기를 바랍니다.
pref_few_0	few	인원 제한	0	4	투입하는 병력의 규모에는 관심이 없습니다.`

const raw_options = `option_advance	cash	선금	의뢰에 착수하면 선금 50%를 미리 받고 성공시 잔금 50%를 받습니다.
option_penalty	cash	위약금	임무 실패시 보상의 50%를 고객에게 지급합니다.
option_perfect	objective	전원 무사	임무에 투입된 용병 중 아무도 전투불능상태에 빠지지 않고 핵심 목표를 달성하면 보상의 25%를 추가로 받습니다.
option_time	objective	신속 이탈	작전 시작 후 1분 내로 목표를 달성하고 이탈하면 보상의 25%를 추가로 받습니다.
option_few	objective	인원 제한	3명 이하의 용병만 투입해 핵심 목표를 달성하면 보상의 25%를 추가로 받습니다.`

const raw_texts = `eval_submit_verygood	evaluation_submit	수정제안평가_매우유리	1	score_submit >= (max_limit_score + min_limit_score) / 2	우리에게 매우 유리해집니다.
eval_submit_good	evaluation_submit	수정제안평가_유리	1	(max_limit_score + min_limit_score) / 2 > score_submit >= min_limit_score	이전보다 우리에게 더 유리해집니다.
eval_submit_normal	evaluation_submit	수정제안평가_보통	1	min_limit_score > score_submit >= 0	공정한 제안입니다.
eval_submit_bad	evaluation_submit	수정제안평가_불리	1	score_submit < 0	이전보다 우리에게 더 불리해집니다.
eval_reaction_good	evaluation_client_reaction	고객반응_좋음	1	score_reaction >= 0	고객이 만족할 것입니다.
eval_reaction_bad	evaluation_client_reaction	고객반응_나쁨	1	0 > score_reaction >= -100	고객이 불만을 품을 가능성이 있습니다.
eval_reaction_verybad	evaluation_client_reaction	고객반응_매우나쁨	1	-100 > score_reaction	고객이 불만을 품을 것입니다.
client_reward	refusal_constraint	제약조건_보상	1	max_reward > constraint_reward	그 금액은 예산 초과입니다.
client_deadline	refusal_constraint	제약조건_성공기한	1	curr_deadline > constraint_deadline	이 일은 그보다 빨리 끝내야 합니다.
client_initiate_0	initial_condition	1차 제안 대사 0	1		이런 조건은 어떻습니까?
client_initiate_1	initial_condition	1차 제안 대사 1	1		우리가 드릴 수 있는 최고의 조건입니다.
client_initiate_2	initial_condition	1차 제안 대사 2	1		고치고 싶은 부분이 있으면 말씀하십시오.
client_refusal_close_0	refusal_close	거절_아쉬운요구0	1		안타깝지만 받아들일 수 없습니다.
client_refusal_close_1	refusal_close	거절_아쉬운요구1	1		흥미롭지만, 우리 방침과 맞지 않는군요.
client_refusal_close_2	refusal_close	거절_아쉬운요구2	1		사려 깊은 제안이지만, 거절할 수밖에 없습니다.
client_refusal_toomuch_0	refusal_toomuch	거절_무리한요구0	1		좀 지나치군요. 수용할 수 없습니다.
client_refusal_toomuch_1	refusal_toomuch	거절_무리한요구1	1		이해할 수 없는 무리한 제안입니다.
client_refusal_toomuch_2	refusal_toomuch	거절_무리한요구2	1		좀 더 잘 생각해보시죠. 우리의 요구를 이해하지 못하는 것 같습니다.
client_refusal_mad_0	refusal_mad	거절_헛소리0	1		그건 무슨 말도 안 되는 조건입니까?
client_refusal_mad_1	refusal_mad	거절_헛소리1	1		헛소리. 내가 미쳐서 수용해도 상부에서 거부할 거요.
client_refusal_mad_2	refusal_mad	거절_헛소리2	1		안 됩니다. 이건 협상이지, 투정 부리는 자리가 아닙니다.
client_accept_0	accept	수용0	1		좋습니다. 협상이 잘 진전되는 것 같군요.
client_accept_1	accept	수용1	1		알겠습니다. 그 조건이면 가능합니다.
client_accept_2	accept	수용2	1		그렇게 하지요. 우리에게도 만족스러운 제안입니다.
client_hint_importance_1	hint	힌트_중요도1	1	pref_importance == 1	다시 강조하는데, 아주 중요한 임무입니다.
client_hint_importance_0	hint	힌트_중요도0	1	pref_importance == 0	성공했으면 좋겠군요. 하지만 손해를 감수할 정도는 아닙니다.
client_hint_importance_-1	hint	힌트_중요도-1	1	pref_importance == -1	상부는 이 일에 큰 관심이 없지만, 좋은 조건을 보고하면 통과시켜줄 겁니다.
client_hint_prospect_1	hint	힌트_성공전망1	1	pref_prospect == 1	당신들 실력을 믿습니다.
client_hint_prospect_0	hint	힌트_성공전망0	1	pref_prospect == 0	이 임무의 성공을 기대하고 있습니다.
client_hint_prospect_-1	hint	힌트_성공전망-1	1	pref_prospect == -1	솔직히, 성공할 수 있을지 미지수입니다.
client_hint_early_1	hint	힌트_조바심1	1	pref_early == 1	빨리 협상을 마치고 일을 시작했으면 좋겠습니다.
client_hint_early_0	hint	힌트_조바심0	1	pref_early == 0	너무 서두를 필요는 없지만 빨리빨리 진행해서 나쁠 것 없죠.
client_hint_early_-1	hint	힌트_조바심-1	1	pref_early == -1	시간 여유를 갖고 여러 용병회사를 고려하고 있으니, 참고 바랍니다.
client_hint_perfect_1	hint	힌트_전원무사1	1	pref_perfect == 1	상부는 정말 전투원들이 멀쩡히 빠져나오길 바랍니다.
client_hint_perfect_0	hint	힌트_전원무사0	1	pref_perfect == 0	솔직히, 우리는 전투원의 생존이나 부상에 아무 이해관계가 없습니다.
client_hint_time_1	hint	힌트_신속이탈1	1	pref_time == 1	신속히 일을 처리하고 빠져나오기를 기대하고 있습니다.
client_hint_time_0	hint	힌트_신속이탈0	1	pref_time == 0	작전지에서 오래 머물러도 상관 없습니다.
client_hint_few_1	hint	힌트_인원제한1	1	pref_few == 1	웬만하면 소수 인원만으로 수행하면 좋겠습니다.
client_hint_few_0	hint	힌트_인원제한0	1	pref_few == 0	아, 투입 인원에 대해서는 아무 요구도 없습니다.`

export const negotiate_mission_objectives = [];
for (const item of raw_mission_objectives.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, name, weight, category, exclusive_with, description] = columns;
    negotiate_mission_objectives.push({ key, name, weight: Number(weight), category, exclusive_with, description });
}

export const negotiate_mission_locations = [];
for (const item of raw_mission_locations.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, name, map] = columns;
    negotiate_mission_locations.push({ key, name, maps: map.split(',') });
}

export const negotiate_mission_sizes = [];
let last_mission_name = '';
for (const item of raw_mission_sizes.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, name, interval_no, min, max] = columns;

    if (name === '') {
        negotiate_mission_sizes.push({ key, name: last_mission_name, interval_no, min: Number(min), max: Number(max) });
    }
    else {
        negotiate_mission_sizes.push({ key, name, interval_no, min: Number(min), max: Number(max) });
        last_mission_name = name;
    }
}

export const negotiate_mission_levels = [];
for (const item of raw_mission_levels.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, name, min, max] = columns;
    negotiate_mission_levels.push({ key, name, min: Number(min), max: Number(max) });
}

export const negotiate_client_infos = {};
for (const item of raw_client_infos.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, category, category_name, value, weight_initialize, description] = columns;

    if (!negotiate_client_infos[category]) {
        negotiate_client_infos[category] = [];
    }

    negotiate_client_infos[category].push({ key, category, category_name, value: Number(value), weight: Number(weight_initialize), description });
}


export const negotiate_options = [];
for (const item of raw_options.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, category, name, description] = columns;
    negotiate_options.push({ key, category, name, description });
}

export const negotiate_texts = [];
for (const item of raw_texts.split('\n')) {
    const columns = item.split('\t');
    if (columns.length === 0) {
        continue;
    }

    const [key, category, name, weight, condition, text] = columns;
    negotiate_texts.push({ key, category, name, weight: Number(weight), condition, text });
}