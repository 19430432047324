import source from '../downloaded/agentComment.json' assert { type: 'json' };

export const agentComments = [];
for (const row of source) {
  let { idx, order, schedule, condition, condition_value, comment } = row;
  agentComments.push({
    idx,
    order,
    schedule,
    condition: condition?.split(',').map((s) => s.trim()) ?? [],
    condition_value: condition_value?.split(',').map((s) => s.trim()) ?? [],
    comment,
  });
}
