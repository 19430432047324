import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';

import './FigmaListView.css';

import { Rng } from './rand.mjs';

import { names2 } from './names';
import { createCharacter2 } from './character2.mjs';
import { PortraitWrapper, PortraitWrapper2 } from './PortraitsView';
import { CHART_OPT_DETAIL, characterChartData } from './CharacterView2';
import { roles } from './data/google/processor/data_char2roles.mjs';
import { statsPerksdata } from './data/google/processor/data_char2statsPerks.mjs';
import { rolesPerksdata } from './data/google/processor/data_char2rolesPerks.mjs';
import { perk2_tactical_bonus_display, perk2_tactical_bonus_types } from './presets_perk2.mjs';

import { Lobby2Footer } from './FigmaLobbyView.js';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { getRelationData } from './data/google/processor/data_agentRelations.mjs';
import { scheduleCEOs as data_scheduleCEOs } from './data/google/processor/data_scheduleCEO.mjs';
import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView.js';
import { SoundButton, triggerSound, soundprops } from './sound.mjs';
import { operatorExp as data_operatorExp } from './data/google/processor/data_operatorExp.mjs';
import { L } from './localization.mjs';

export * from './RenderAgentSimple.js';
import { RenderAgentSimple, RoleBadge, roleIcons, GetWeaponAptitudeIcon, evalCSSClass, ICON_CLOSE } from './RenderAgentSimple.js';
import { PowerIndicatorNumber, PowerIndicator, TierBadge2, AgentModifierBody, AgentModifierIcon } from './Badges.js';


const ICON_ARROW = <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5 8.5L0.749999 0.272759L0.75 16.7272L13.5 8.5Z" fill="#3D301C" />
</svg>;

const ICON_RADIO_ON = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="22" height="22" rx="11" fill="#1C160D" stroke="#625443" strokeWidth="2" />
  <circle cx="12" cy="12" r="7" fill="#DFA612" />
</svg>;

const ICON_RADIO_OFF = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="22" height="22" rx="11" fill="#1C160D" stroke="#625443" strokeWidth="2" />
</svg>;

const ICON_BUBBLE = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
  <path d="M1.5 8L8 0.5V6H17C20.3137 6 23 8.68629 23 12V16C23 19.3137 20.3137 22 17 22H6C2.68629 22 0 19.3137 0 16V12C0 10.4643 0.576982 9.06327 1.526 8.002L1.5 8Z" fill="#D9D9D9" />
  <path fillRule="evenodd" clipRule="evenodd" d="M8 14C8 15.1046 7.10457 16 6 16C4.89543 16 4 15.1046 4 14C4 12.8954 4.89543 12 6 12C7.10457 12 8 12.8954 8 14ZM14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14ZM18 16C19.1046 16 20 15.1046 20 14C20 12.8954 19.1046 12 18 12C16.8954 12 16 12.8954 16 14C16 15.1046 16.8954 16 18 16Z" fill="#838383" />
</svg>;

const ICON_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="65" viewBox="0 0 22 65" fill="none" >
  <path d="M21.5 64C10.6332 64 6 64 6 64" stroke="#665C44" strokeWidth="2" />
  <path d="M7 -1.90735e-06C7 24.603 7 63 7 63" stroke="#665C44" strokeWidth="2" />
</svg>;

const ICON_UNLOCK = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25" fill="none">
  <path d="M10.5 11.5V6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6V9H2.5V6C2.5 2.96243 4.96243 0.5 8 0.5C11.0376 0.5 13.5 2.96243 13.5 6V11.5H14.5C15.0523 11.5 15.5 11.9477 15.5 12.5V23.5C15.5 24.0523 15.0523 24.5 14.5 24.5H1.5C0.947715 24.5 0.5 24.0523 0.5 23.5V12.5C0.5 11.9477 0.947715 11.5 1.5 11.5H10.5Z" fill="#D2C6A7" />
</svg>;

const ICON_LOCK = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23" fill="none">
  <rect x="0.5" y="9.5" width="15" height="13" rx="1" fill="#87817A" />
  <rect x="4" y="2" width="8" height="13" rx="4" stroke="#87817A" strokeWidth="3" />
</svg>;

const AGENT_TIER1_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none" className='agent-tier-background'>
  <path d="M13.5 2L19.45 5.82624L25.6244 9.25L25.4 16.5L25.6244 23.75L19.45 27.1738L13.5 31L7.55 27.1738L1.37564 23.75L1.6 16.5L1.37564 9.25L7.55 5.82624L13.5 2Z" fill="#D91A48" />
  <path d="M13.7704 1.57945L13.5 1.40554L13.2296 1.57945L7.29327 5.39687L1.13317 8.81273L0.866451 8.96063L0.875884 9.26547L1.09976 16.5L0.875884 23.7345L0.866451 24.0394L1.13317 24.1873L7.29326 27.6031L13.2296 31.4205L13.5 31.5945L13.7704 31.4205L19.7067 27.6031L25.8668 24.1873L26.1335 24.0394L26.1241 23.7345L25.9002 16.5L26.1241 9.26547L26.1335 8.96063L25.8668 8.81273L19.7067 5.39687L13.7704 1.57945Z" stroke="black" strokeOpacity="0.4" />
</svg>;

const AGENT_TIER1_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none" className='agent-tier-frame'>
  <path d="M1.1077 6.81551L10.5 1.16691L19.8923 6.81551V18.1845L10.5 23.8331L1.1077 18.1845V6.81551Z" stroke="#FC553E" strokeWidth="2" />
</svg>;

const AGENT_TIER2_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none" className='agent-tier-background'>
  <path d="M13.5 2L19.45 5.82624L25.6244 9.25L25.4 16.5L25.6244 23.75L19.45 27.1738L13.5 31L7.55 27.1738L1.37564 23.75L1.6 16.5L1.37564 9.25L7.55 5.82624L13.5 2Z" fill="#D8A84B" />
  <path d="M13.7704 1.57945L13.5 1.40554L13.2296 1.57945L7.29327 5.39687L1.13317 8.81273L0.866451 8.96063L0.875884 9.26547L1.09976 16.5L0.875884 23.7345L0.866451 24.0394L1.13317 24.1873L7.29326 27.6031L13.2296 31.4205L13.5 31.5945L13.7704 31.4205L19.7067 27.6031L25.8668 24.1873L26.1335 24.0394L26.1241 23.7345L25.9002 16.5L26.1241 9.26547L26.1335 8.96063L25.8668 8.81273L19.7067 5.39687L13.7704 1.57945Z" stroke="black" strokeOpacity="0.4" />
</svg>;

const AGENT_TIER2_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none" className='agent-tier-frame'>
  <path d="M1.1077 6.81551L10.5 1.16691L19.8923 6.81551V18.1845L10.5 23.8331L1.1077 18.1845V6.81551Z" stroke="#F0CA81" strokeWidth="2" />
</svg>;

const AGENT_TIER3_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none" className='agent-tier-background'>
  <path d="M13.5 2L19.45 5.82624L25.6244 9.25L25.4 16.5L25.6244 23.75L19.45 27.1738L13.5 31L7.55 27.1738L1.37564 23.75L1.6 16.5L1.37564 9.25L7.55 5.82624L13.5 2Z" fill="#808B97" />
  <path d="M13.7704 1.57945L13.5 1.40554L13.2296 1.57945L7.29327 5.39687L1.13317 8.81273L0.866451 8.96063L0.875884 9.26547L1.09976 16.5L0.875884 23.7345L0.866451 24.0394L1.13317 24.1873L7.29326 27.6031L13.2296 31.4205L13.5 31.5945L13.7704 31.4205L19.7067 27.6031L25.8668 24.1873L26.1335 24.0394L26.1241 23.7345L25.9002 16.5L26.1241 9.26547L26.1335 8.96063L25.8668 8.81273L19.7067 5.39687L13.7704 1.57945Z" stroke="black" strokeOpacity="0.4" />
</svg>;

const AGENT_TIER3_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none" className='agent-tier-frame'>
  <path d="M1.1077 6.81551L10.5 1.16691L19.8923 6.81551V18.1845L10.5 23.8331L1.1077 18.1845V6.81551Z" stroke="#A5AEB7" strokeWidth="2" />
</svg>;

const AGENT_TIER4_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none" className='agent-tier-background'>
  <path d="M13.5 2L19.45 5.82624L25.6244 9.25L25.4 16.5L25.6244 23.75L19.45 27.1738L13.5 31L7.55 27.1738L1.37564 23.75L1.6 16.5L1.37564 9.25L7.55 5.82624L13.5 2Z" fill="#A36A27" />
  <path d="M13.7704 1.57945L13.5 1.40554L13.2296 1.57945L7.29327 5.39687L1.13317 8.81273L0.866451 8.96063L0.875884 9.26547L1.09976 16.5L0.875884 23.7345L0.866451 24.0394L1.13317 24.1873L7.29326 27.6031L13.2296 31.4205L13.5 31.5945L13.7704 31.4205L19.7067 27.6031L25.8668 24.1873L26.1335 24.0394L26.1241 23.7345L25.9002 16.5L26.1241 9.26547L26.1335 8.96063L25.8668 8.81273L19.7067 5.39687L13.7704 1.57945Z" stroke="black" strokeOpacity="0.4" />
</svg>;

const AGENT_TIER4_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none" className='agent-tier-frame'>
  <path d="M1.1077 6.81551L10.5 1.16691L19.8923 6.81551V18.1845L10.5 23.8331L1.1077 18.1845V6.81551Z" stroke="#C6873D" strokeWidth="2" />
</svg>

const AGENT_TIER5_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none" className='agent-tier-background'>
  <path d="M13.5 2L19.45 5.82624L25.6244 9.25L25.4 16.5L25.6244 23.75L19.45 27.1738L13.5 31L7.55 27.1738L1.37564 23.75L1.6 16.5L1.37564 9.25L7.55 5.82624L13.5 2Z" fill="#68554F" />
  <path d="M13.7704 1.57945L13.5 1.40554L13.2296 1.57945L7.29327 5.39687L1.13317 8.81273L0.866451 8.96063L0.875884 9.26547L1.09976 16.5L0.875884 23.7345L0.866451 24.0394L1.13317 24.1873L7.29326 27.6031L13.2296 31.4205L13.5 31.5945L13.7704 31.4205L19.7067 27.6031L25.8668 24.1873L26.1335 24.0394L26.1241 23.7345L25.9002 16.5L26.1241 9.26547L26.1335 8.96063L25.8668 8.81273L19.7067 5.39687L13.7704 1.57945Z" stroke="black" strokeOpacity="0.4" />
</svg>

const AGENT_TIER5_FRAME = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none" className='agent-tier-frame'>
  <path d="M1.1077 6.81551L10.5 1.16691L19.8923 6.81551V18.1845L10.5 23.8331L1.1077 18.1845V6.81551Z" stroke="#806D67" strokeWidth="2" />
</svg>

const ICON_RECOMMENDED = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M18.0707 5.09369L21.1752 7.35721C22.5715 8.37523 23.1562 10.1748 22.625 11.819L21.4418 15.4814L20.2484 19.1335C19.7117 20.776 18.1809 21.8882 16.4529 21.8911L12.6041 21.8975L8.76205 21.8911C7.03408 21.8882 5.50331 20.776 4.96659 19.1335L3.77113 15.4751L2.58998 11.819C2.05875 10.1748 2.64345 8.37523 4.03971 7.35721L7.14966 5.08975L10.2618 2.83663C11.6614 1.8233 13.5535 1.82329 14.9532 2.83663L18.0707 5.09369Z" fill="#ED8A2D" />
  <path d="M11.4346 4.45663C12.1344 3.94996 13.0805 3.94996 13.7803 4.45663L16.8924 6.70976L19.997 8.97328C20.6951 9.48228 20.9874 10.382 20.7218 11.2042L19.5407 14.8602L18.3473 18.5123C18.0789 19.3335 17.3135 19.8896 16.4496 19.8911L12.6075 19.8975L8.76538 19.8911C7.9014 19.8896 7.13601 19.3335 6.86765 18.5123L5.67427 14.8602L4.49311 11.2042C4.2275 10.382 4.51985 9.48229 5.21798 8.97328L8.32252 6.70976L11.4346 4.45663Z" fill="#B26A26" />
  <path d="M12.1458 5.71745C12.3166 5.3068 12.8983 5.3068 13.0691 5.71745L14.6062 9.41304C14.6782 9.58616 14.841 9.70444 15.0279 9.71943L19.0176 10.0393C19.4609 10.0748 19.6407 10.6281 19.3029 10.9174L16.2632 13.5213C16.1208 13.6432 16.0586 13.8346 16.1021 14.017L17.0308 17.9103C17.134 18.3429 16.6633 18.6848 16.2838 18.453L12.868 16.3667C12.708 16.2689 12.5068 16.2689 12.3468 16.3667L8.93105 18.453C8.5515 18.6848 8.08088 18.3429 8.18407 17.9103L9.11276 14.017C9.15626 13.8346 9.09408 13.6432 8.95168 13.5213L5.91195 10.9174C5.57418 10.6281 5.75394 10.0748 6.19727 10.0393L10.187 9.71943C10.3739 9.70444 10.5367 9.58616 10.6087 9.41304L12.1458 5.71745Z" fill="#FFF7AC" />
</svg>;

const ICON_OK = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M13.5 18.5C16.8137 18.5 19.5 15.8137 19.5 12.5C19.5 9.18629 16.8137 6.5 13.5 6.5C10.1863 6.5 7.5 9.18629 7.5 12.5C7.5 15.8137 10.1863 18.5 13.5 18.5ZM13.5 21C18.1944 21 22 17.1944 22 12.5C22 7.80558 18.1944 4 13.5 4C8.80558 4 5 7.80558 5 12.5C5 17.1944 8.80558 21 13.5 21Z" fill="#71C507" />
</svg>;

const ICON_NOT_RECOMMENDED = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M12.7957 9L7.59957 18H17.9919L12.7957 9ZM13.6617 5.5C13.2768 4.83333 12.3146 4.83333 11.9297 5.5L4.13547 19C3.75057 19.6667 4.23169 20.5 5.00149 20.5H20.59C21.3598 20.5 21.8409 19.6667 21.456 19L13.6617 5.5Z" fill="#B2B1AD" />
</svg>;

const ICON_NOT_OK = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="white" />
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="white" />
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="#FE4040" />
</svg>;

export const AGENT_TIER1 = <div className="agent-tier">
  {AGENT_TIER1_BACKGROUND}
  {AGENT_TIER1_FRAME}
  <span className='agent-tier1-label'>1</span>
</div>
export const AGENT_TIER2 = <div className="agent-tier">
  {AGENT_TIER2_BACKGROUND}
  {AGENT_TIER2_FRAME}
  <span className='agent-tier2-label'>2</span>
</div>

export const AGENT_TIER3 = <div className="agent-tier">
  {AGENT_TIER3_BACKGROUND}
  {AGENT_TIER3_FRAME}
  <span className='agent-tier3-label'>3</span>
</div>

export const AGENT_TIER4 = <div className="agent-tier">
  {AGENT_TIER4_BACKGROUND}
  {AGENT_TIER4_FRAME}
  <span className='agent-tier4-label'>4</span>
</div>

export const AGENT_TIER5 = <div className="agent-tier">
  {AGENT_TIER5_BACKGROUND}
  {AGENT_TIER5_FRAME}
  <span className='agent-tier5-label'>5</span>
</div>

export function GetAgentTier(props) {
  const { tier } = props;
  switch (tier) {
    case 1:
      return AGENT_TIER1;
    case 2:
      return AGENT_TIER2;
    case 3:
      return AGENT_TIER3;
    case 4:
      return AGENT_TIER4;
    case 5:
      return AGENT_TIER5;
  }
}

function ListTab(props) {
  const { label, className, title } = props;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className={`figmalist-body-list-tab ${className}`}
        {...figmaTooltipEvents(context, title)}
      >
        <div className="figmalist-body-list-tab-label figma-tooltip-marker">{label}</div>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

export const DetailButton = (props) => {
  const { icon, label, onClick, disabled, cueList } = props;
  return <SoundButton className="figmalist-body-detail-perk-button" cueList={cueList} onClick={() => (!disabled) && onClick()} disabled={disabled}>
    {icon}
    <div className="figmalist-body-detail-perk-button-label">
      {label}
    </div>
  </SoundButton>;
}

export class FigmaListRoot extends React.Component {
  constructor(props) {
    super(props);

    const rng = new Rng();

    const characters = new Array(20).fill(0).map(() => {
      const name = rng.choice(names2);
      const role = rng.weighted_key(roles, "weight");
      return createCharacter2({ rng, name, role });
    });

    this.tooltipRef = React.createRef();

    this.state = {
      rng,
      characters,
      selected: characters[0],
      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
    };
  }

  onSelect(character) {
    this.setState({ selected: character });
  }

  render() {
    const { characters, selected } = this.state;

    if (false) {
      // i18n ignore 8
      const header = <>
        <div className="figmalist-header-title">고용</div>
        <div className="figmalist-header-subtitle">목록 갱신까지 <span className="hl">3</span>일</div>
        <div className="figmalist-header-sep" />
        <div className="figmalist-header-subtitle">용병 고용 증서 <span className="hl">4</span>개</div>
        <div className="figmalist-header-sep" />
        <FigmaListHeaderBtn key='refresh' label="헤드헌터와 미팅" onClick={() => { }} />
      </>;

      return <div className="figmalist-testroot">
        <FigmaListView characters={characters} selected={selected}
          header={header}
          onSelect={(character) => this.onSelect(character)}
          renderDetailButtons={() => {
            return <>TODO</>;
          }}
          onRecruit={() => { }}
        />
      </div>;
    } else {
      // i18n ignore 4
      const header = <div className="figmalist-header2-title">
        <div className="figmalist-header2-title-bullet" />
        <div className="figmalist-header2-title-label">용병단</div>
      </div>;

      const listHeader = <FigmaListSummaryHeader />;

      return <TooltipContext.Provider value={{
        ctx: this.state.tooltipContext,
        setCtx: (ctx) => {
          this.setState({ tooltipContext: ctx });
        }
      }}>
        <div className="figmalist-testroot" onMouseMove={(e) => {
          if (!e._tooltipmove) {
            this.setState({ tooltipContext: { ...this.state.tooltipContext, content: null } });
          }
        }}>
          <FigmaAgentsView2 characters={characters} selected={selected}
            header={header}
            listHeader={listHeader}
            onSelect={(character) => this.onSelect(character)}
            OnClickBackgroundButton={() => { }}
            OnEditCallsignButton={() => { }}
            onClickPerkUnlockButton={(character, type, key) => {
              if (type === 'stat') {
                character.statsPerks[key] = 'activated';
              }
              else if (type === 'operator') {
                character.operatorPerks[key] = 'activated';
              }
              this.setState({ characters })
            }}
            turn={0}
          />
        </div>
      </TooltipContext.Provider>;
    }
  }
}

export function BuildRoleDescription(props) {
  const { role } = props;
  const roleData = rolesBykey(role);

  const weaponAptitudes = [];
  for (const key of ['SG', 'SMG', 'HG', 'AR', 'DMR']) {
    const weaponAptitude = [];
    const value = roleData.firearmAptitudes['firearmAptitude_' + key];
    weaponAptitude.push(<div className="figmalist-body-detail-box-firearmaptitude-ty">
      <span className="figmalist-body-detail-box-firearmaptitude-ty-label">{key}</span>
    </div>);
    weaponAptitude.push(<div className="figma-role-badge-tooltip-aptitude-icon">
      <GetWeaponAptitudeIcon value={value} />
    </div>);
    weaponAptitudes.push(<div className="figma-role-badge-tooltip-firearmaptitude">
      {weaponAptitude}
    </div>);
  }
  const positionAptitudes = [];
  for (const key of [0, 1, 2, 3]) {
    const positionAptitude = [];
    const value = roleData.descPositions[key];
    positionAptitude.push(<SvgBadgePosition value={key + 1} />);
    positionAptitude.push(GetPositionAptitudeIcon(value));
    positionAptitudes.push(<div className="figma-role-badge-tooltip-aptitude">
      {positionAptitude}
    </div>);
  }

  return <>
    <div className='figma-role-badge-tooltip-descr'>
      {L('loc_ui_longtext_common_role_' + roleData.key + '_descr')}
    </div>
    <div className='figma-role-badge-tooltip-info-group'>
      <div className='figma-role-badge-tooltip-info'>
        <div className='figma-role-badge-tooltip-info-label'>{L('loc_ui_string_role_tooltip_firearm_aptitudes')}</div>
        <div className='figma-role-badge-tooltip-info-value'>{weaponAptitudes}</div>
      </div>
      <div className='figma-role-badge-tooltip-info'>
        <div className='figma-role-badge-tooltip-info-label'>{L('loc_ui_string_role_tooltip_position_aptitudes')}</div>
        <div className='figma-role-badge-tooltip-info-value'>{positionAptitudes}</div>
      </div>
    </div>
  </>;
}

function ProgressBar2(props) {
  const { value, max } = props;
  const gaugeMax = max > 0 ? max : value;
  const labelMax = max > 0 ? max : '∞';
  const style = {
    clipPath: `inset(0 ${(1 - value / max) * 100}% 0 0)`,
  }
  return <div className="figma-progressbar2">
    <div className="figma-progressbar2-bg">
      <div className="figma-progressbar2-fill" style={style} />
    </div>
    <span className="figma-progressbar2-label">{value}<span className="figmalist-body-list-row-etc-sep" />{labelMax}</span>
  </div>;
}

export function DetailView2Levels(props) {
  const { level, exp, expMax } = props;
  return <div className="figmalist-body-detail2-profile-levels">
    <span className="figmalist-body-detail2-profile-level">{level}</span>
    <ProgressBar2 value={exp} max={expMax} />
  </div>;
}

export function AptitudeBadge2(props) {
  const { firearm_ty, aptitude_id } = props;
  const thumbsups = [];
  const svg_icon_thumbsup = () => <svg key={thumbsups.length} xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
    <path d="M0 7.4375V13.125L2.57143 14H10.2857L12 6.125L10.7143 4.8125H7.28571V0.875L5.14286 0L4.28571 3.9375L1.71429 7L0 7.4375Z" fill="#70C3FF" />
  </svg>;
  thumbsups.push(svg_icon_thumbsup());
  if (aptitude_id <= 3) {
    thumbsups.push(svg_icon_thumbsup());
    if (aptitude_id <= 2) {
      thumbsups.push(svg_icon_thumbsup());
    }
  }
  return <div className={"figma-badge2-aptitude figma-badge2-aptitude-" + aptitude_id}>
    <div className="figma-badge2-aptitude-thumbsups">
      {thumbsups}
    </div>
    <div className="figma-badge2-aptitude-label-wrapper">
      <span className="figma-badge2-aptitude-label">{firearm_ty}</span>
    </div>
  </div>
}

export function AptitudeBar2(props) {
  const { aptitudes } = props;
  return <div className="figmalist-body-detail2-profile-aptitudes">
    {Object.keys(aptitudes).filter(ty => aptitudes[ty].id < 6).sort((a, b) => { return (aptitudes[a].id - aptitudes[b].id); }).map((firearm_ty, i) => {
      return <AptitudeBadge2 key={i} firearm_ty={firearm_ty} aptitude_id={aptitudes[firearm_ty].id} />;
    })}
  </div>;
}

export function NationalityBadge2(props) {
  const { nationality } = props;
  return <div className="figma-badge2-nationality">
    <div className="figma-badge2-nationality-flag">

    </div>
    <span className="figma-badge2-nationality-label">{L(nationality)}</span>
  </div>;
}

export function FigmaListHeaderBtn(props) {
  const { label, disabled, onClick, reason } = props;

  return <TooltipContext.Consumer>
    {(context) => {
      let events = {};
      if (reason?.length > 0) {
        events = figmaTooltipEvents(context, reason);
      }
      return <div {...events}>
        <SoundButton className="figmalist-header-btn" disabled={disabled} onClick={onClick}>
          <span className="figmalist-header-btn-label">{label}</span>
        </SoundButton>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

export function FigmaListSummaryHeader(props) {
  const { integrity, trust, mood, condition, power } = props;

  /*
<span className="bad">개판</span>
<span className="good">좋음</span>
<span className="good">좋음</span>
<span className="hl">5.6</span>
  */
  return <div className="figmalist-body-list-header-state">
    <div className="figmalist-body-list-header-item">{L('loc_ui_string_lobby_condition_integrity')} {integrity}</div>
    <div className="figmalist-body-list-header-sep" />
    <div className="figmalist-body-list-header-item">{L('loc_ui_string_lobby_condition_trust')} {trust}</div>
    <div className="figmalist-body-list-header-sep" />
    <div className="figmalist-body-list-header-item">{L('loc_ui_string_lobby_condition_average_stamina')} {condition}</div>
    <div className="figmalist-body-list-header-sep" />
    <div className="figmalist-body-list-header-item">{L('loc_ui_string_lobby_condition_average_mood')} {mood}</div>
    <div className="figmalist-body-list-header-sep" />
    <div className="figmalist-body-list-header-item">{L('loc_ui_string_lobby_condition_average_power')} {power}</div>
  </div>;
}

function GreenDot(props) {
  const { top, left, radius } = props;
  const size = radius * 2;

  return <svg style={{ position: "absolute", top: `${top}px`, left: `${left}px`, zIndex: "3" }} width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">

    <circle cx={`${radius}`} cy={`${radius}`} r={`${radius}`} fill="#087C36" />
    <circle cx={`${radius}`} cy={`${radius - 1}`} r={`${radius - 1}`} fill="#06D95A" />
    <circle cx={`${radius}`} cy={`${radius - 1}`} r={`${radius - 3}`} fill="#0BFF41" />

  </svg>;
}

export const RadioButton = (props) => {
  const { checked, option, onClick } = props;
  const { disabled, label } = option;
  const cls = `figmalist-body-list-row-plan-radio-label ${disabled ? "figmalist-body-list-row-plan-radio-label-disabled" : ""}`;
  return <div className="figmalist-body-list-row-plan-radio" onClick={() => disabled ? null : onClick()}>
    {checked ? ICON_RADIO_ON : ICON_RADIO_OFF}
    < div className={cls} > {label}</div >
  </div >;
};

export const ListRowPlan = (props) => {
  const { comment, success_rate, options, selected, onSelect } = props;

  let note_body = null;
  if (comment?.type === 'none') {
    note_body = <>
      {comment.text}
    </>
  }
  else {
    note_body = <>
      {ICON_BUBBLE} "{comment}"
    </>
  }

  return <div className="figmalist-body-list-row-plan">
    <span className="figmalist-body-list-row-plan-note">
      {note_body}
    </span>
    <div className="figmalist-body-list-row-plan-actions">
      {success_rate}
      {options.map((option, i) => {
        return <RadioButton key={i} option={option} checked={option.key === selected} onClick={() => { onSelect(option.key); }} />;
      })}
    </div>
  </div>;
};

const HEADERS = [
  'loc_ui_string_agent_level',
  'loc_ui_string_agent_power_short',
  'loc_ui_string_agent_stamina_short',
  'loc_ui_string_agent_mood_short',
  'loc_ui_string_agent_relation_short',
  'loc_ui_string_agent_life_short',
  'loc_ui_string_agent_stat3_physical_short',
  'loc_ui_string_agent_stat3_perception_short',
  'loc_ui_string_agent_stat3_mental_short',
  'loc_ui_string_agent_stat3_tactical_short',
  'loc_ui_string_agent_stat3_shooting_short',
];

const TITLES_HEADER = [
  'loc_ui_longtext_agent_level',
  'loc_ui_longtext_agent_power',
  'loc_ui_longtext_agent_stamina',
  'loc_ui_longtext_agent_mood',
  'loc_ui_longtext_agent_relation',
  'loc_ui_longtext_agent_life',
  'loc_ui_longtext_agent_stat3_physical',
  'loc_ui_longtext_agent_stat3_perception',
  'loc_ui_longtext_agent_stat3_mental',
  'loc_ui_longtext_agent_stat3_tactical',
  'loc_ui_longtext_agent_stat3_shooting',
];

const HEADERS2 = {
  name: 'loc_ui_string_agent_name',
  role: 'loc_ui_string_agent_role',
  grade: 'loc_ui_string_agent_grade',
  level: 'loc_ui_string_agent_level',
  power: 'loc_ui_string_agent_power_short',
  modifiers: 'loc_ui_string_agent_note',
  relation: 'loc_ui_string_agent_relation_short',
  payment: 'loc_ui_string_agent_payment_short',
};

const TITLES_HEADER2 =
{
  name: 'loc_ui_longtext_agent_identification',
  role: 'loc_ui_longtext_agent_role',
  grade: 'loc_ui_longtext_agent_grade',
  level: 'loc_ui_longtext_agent_level',
  power: 'loc_ui_longtext_agent_power',
  modifiers: 'loc_ui_longtext_agent_note',
  relation: 'loc_ui_longtext_agent_relation',
  payment: 'loc_ui_longtext_agent_payment',
};

const ListRow = (props) => {
  const { character, onSelect, extra, note, selected, onClickPortrait, diffs } = props;
  const { name, role, callsign } = character;
  const { tier, condition, mood, relation, power, realStats, secondaryStats, potentialStats } = character;
  const { life } = secondaryStats;
  const { physical, perception, mental, tactical, shooting } = realStats;

  const cols = {
    level: character.level.cur,
    power,
    condition: condition,
    mood: mood,
    relation: relation,
    life: life.toFixed(0),
    stats_physical: physical.toFixed(0),
    stats_perception: perception.toFixed(0),
    stats_mental: mental.toFixed(0),
    stats_tactical: tactical.toFixed(0),
    stats_shooting: shooting.toFixed(0),
  };

  const exp = character.level.exp;
  let expMax;
  if (character.level.cur <= data_operatorExp.length) {
    expMax = data_operatorExp[character.level.cur - 1].expMax;
  }
  else {
    expMax = '∞';
  }

  const svg_bust = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29" fill="none">
    <path d="M12.5163 27.6924L2.02265 22.8175L1.97704 2.01831L22.9429 1.97235L22.9885 22.7716L12.5163 27.6924Z" fill="#E7C263" stroke="#FFDD9A" strokeWidth="2" />
    <path d="M14.1439 14.1649C15.8443 13.478 17.05 11.7567 17.05 9.74194C17.05 7.12304 15.0129 5 12.5 5C9.9871 5 7.95 7.12304 7.95 9.74194C7.95 11.7567 9.15564 13.478 10.8561 14.1649L4 22L12.5 26L19 23L21 22L14.1439 14.1649Z" fill="#BB8E1C" />
  </svg>

  let greendot = null;
  // if (Object.values(character.statsPerks).find((value) => value === 'deactivated') || Object.values(character.operatorPerks).find((value) => value === 'deactivated')) {
  //   greendot = <GreenDot top={0} left={1} radius={8} />;
  // }

  return <SoundButton className={`figmalist-body-list-row${selected ? "-selected" : ""}`} onClick={() => onSelect?.(character)}>
    <div className="figmalist-body-list-row-info">
      {greendot}
      <div className="figmalist-body-list-row-name">
        <RenderAgentSimple agent={character} onClick={onClickPortrait} />
      </div>

      {Object.entries(cols).map(([key, col], i) => {
        const diff = diffs[key];
        let label;
        if (key === "mood") {
          label = <div className={"figmalist-body-list-row-etc-label multiline" + evalCSSClass(key, col)}>
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {L(getMoodData(col).name)}
            </div>
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {"(" + col + ")"}
            </div>
            <div className="figmalist-body-list-row-etc-diff double">
              {diff}
            </div>
          </div>;
        } else if (key === "condition") {
          label = <div className="figmalist-body-list-row-etc-label multiline">
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {L(getConditionData(col).name)}
            </div>
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {"(" + col + ")"}
            </div>
            <div className="figmalist-body-list-row-etc-diff double">
              {diff}
            </div>
          </div>;
        } else if (key === "relation") {
          label = <div className="figmalist-body-list-row-etc-label multiline">
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {L(getRelationData(col).name)}
            </div>
            <div className={"figmalist-body-list-row-etc-label inline" + evalCSSClass(key, col)}>
              {"(" + col.toFixed(0) + ")"}
            </div>
            {diff}
          </div>;
        } else if (key === "tier") {
          label = <div className={"figmalist-body-list-row-etc-label" + evalCSSClass(key, col)}>
            <TierBadge2 tier={col} />
            {diff}
          </div>;
        } else if (key === "power") {
          // TODO: diff
          label = <PowerIndicator character={character} starSize={20} />;
        } else if (key === "level") {
          label = <div className="figmalist-body-list-row-etc-label multiline">
            <div className="figmalist-body-list-row-etc-label inline">
              {col}
            </div>
            <div className="figmalist-body-list-row-etc-label small">
              {exp}{diff}<span className="figmalist-body-list-row-etc-sep" />{expMax}
            </div>
          </div>;
        } else if (key.split('_')[0] === 'stats') {
          key = key.split('_')[1];
          label = <div className="figmalist-body-list-row-etc-label multiline">
            <div className="figmalist-body-list-row-etc-label inline">
              {col}<span className="figmalist-body-list-row-etc-sep" />{potentialStats[key]}
            </div>
            <div className="figmalist-body-list-row-etc-diff">
              {diffs[key]}
            </div>
          </div>;
        } else {
          label = <div className={"figmalist-body-list-row-etc-label" + evalCSSClass(key, col)}>
            {col}{diff}
          </div>;
        }
        return <div className={"figmalist-body-list-row-etc " + key} key={i}>
          {label}
        </div>;
      })}

    </div>
    <FigmaListRowExtra note={note} />
    {extra}
  </SoundButton >;
};

const ProfileRow = (props) => {
  const { children } = props;
  return <div className="figmalist-body-detail-profile-row">
    <div className="figmalist-body-detail-profile-row-inner">
      {children}
    </div>
    <div className="figmalist-body-detail-profile-sep" />
  </div>;
};

function ActivatedPerkBody(props) {
  const { i, tactical } = props;
  let descrValue = i.data.perkPower;
  if (descrValue !== 0) {
    const perkKey = i.perk_key;
    const bonusType = Object.keys(perk2_tactical_bonus_types).find((key) => {
      return perk2_tactical_bonus_types[key].includes(perkKey);
    });
    if (bonusType) {
      const added = (perk2_tactical_bonus_display[bonusType] * tactical / 100).toFixed(1);
      const result = (+(i.data.perkPower) + +(added)).toFixed(1);
      descrValue = `${result}(${i.data.perkPower} + ${added})`;
    }
  }
  const content = <div className='agentPerkInfo-body'>
    <div className='figmalist-body-detail-perk-activated-body-labelgroup'>
      <span className='agentPerkInfo-body-detail-label'>{L(i.data.name, { level: i.data.level })}</span>
      <div className='agentPerkInfo-body-detail-descr'>{L(i.data.descr, { value: descrValue })}</div>
    </div>
  </div>;

  return <TooltipContext.Consumer>
    {(context) => {
      const events = figmaTooltipEvents(context, content);
      return <div className='figmalist-body-detail-perk-activated'  {...events}>
        <div className='figmalist-body-detail-perk-activated-tags'>
          {i.data.tags.map((tag, i) => <span key={i} className='figmalist-body-detail-perk-activated-tag'>[{L("loc_data_string_perk_tag_" + tag)}]</span>)}
        </div>
        <span className='figmalist-body-detail-perk-activated-body-label'>{L(i.data.name, { level: i.data.level })}</span>
      </div>
    }}
  </TooltipContext.Consumer>
}

const makeStatTable = (character, TableCol) => {
  const { secondaryStats } = character;

  const { life, acceleration } = secondaryStats;
  const { evasion, accuracy } = secondaryStats;
  const { reduction } = secondaryStats;
  const { damage, aimspeed, reloadspeed } = secondaryStats;

  const table = [];
  table.push(<TableCol key={'physical'} title={L('loc_ui_string_agent_stat3_physical_short')}
    data={[
      { label: L('loc_ui_string_agent_stat3_life'), value: life.toFixed(0) },
      { label: L('loc_ui_string_agent_stat3_acceleration'), value: `${(acceleration * 100).toFixed(1)}%` },
      null,
    ]} />);
  table.push(<TableCol key={'perception'} title={L('loc_ui_string_agent_stat3_perception_short')}
    data={[
      { label: L('loc_ui_string_agent_stat3_evasion'), value: `${(evasion * 100).toFixed(1)}%` },
      { label: L('loc_ui_string_agent_stat3_accuracy'), value: `${(accuracy * 100).toFixed(1)}%` },
      null,
    ]} />);

  table.push(<TableCol key={'mental'} title={L('loc_ui_string_agent_stat3_mental_short')}
    data={[
      { label: L('loc_ui_string_agent_stat3_reduction'), value: `${(reduction * 100).toFixed(1)}%` },
      null,
      null,
    ]} />);

  table.push(<TableCol key={'tactical'} title={L('loc_ui_string_agent_stat3_tactical_short')}
    data={[null, null, null]} />);

  table.push(<TableCol key={'shooting'} title={L('loc_ui_string_agent_stat3_shooting_short')}
    data={[
      { label: L('loc_ui_string_agent_stat3_damage'), value: damage.toFixed(0) },
      { label: L('loc_ui_string_agent_stat3_aimspeed'), value: aimspeed.toFixed(1) },
      { label: L('loc_ui_string_agent_stat3_reloadspeed'), value: reloadspeed.toFixed(1) },
    ]} />);

  return table;
}

export const Detailview = (props) => {
  const { character, getCharacterPaymentFunction, renderDetailButtons, additionalButton, OnClickBackgroundButton, onClickPerkUnlockButton } = props;

  const ProfileBackgroundbutton = <SoundButton className='figmalist-body-detail-profile-detail-button' onClick={() => OnClickBackgroundButton(character)}>
    <span className='figmalist-body-detail-profile-detail-button-label'>
      {L('loc_ui_stiring_squad_agent_detail')}
    </span>
  </SoundButton>

  const ProfileRowItem = (props) => {
    const { label, value, detailButton } = props;
    return <div className="figmalist-body-detail-profile-row-item">
      <div className="figmalist-body-detail-profile-label-key">{label}</div>
      <div className='figmalist-body-detail-profile-label-value-slot'>
        <div className="figmalist-body-detail-profile-label-value">{value}</div>
        {detailButton}
      </div>
    </div>;
  }

  const PerkRow = (props) => {
    const { key, info, selected, onClickPerkGroup, onClickPerkUnlockButton, tactical } = props;
    return <>
      {info.filter((i) => i.state === 'activated').map((i) => <ActivatedPerkBody i={i} tactical={tactical} />)}
    </>
  };
  if (!character) {
    return null;
  }

  const TableKV = (props) => {
    const { label, value } = props;
    return <div className="figmalist-body-detail-stat-table-td">
      <div className="figmalist-body-detail-stat-table-label">{label}</div>
      <div className="figmalist-body-detail-stat-table-value">{value}</div>
    </div>;
  };

  const TableCol = (props) => {
    const { title, data } = props;
    return <div className="figmalist-body-detail-stat-table-col">
      <div className="figmalist-body-detail-stat-table-th">
        <div className="figmalist-body-detail-stat-table-th-label">{title}</div>
      </div>

      <div className="figmalist-body-detail-stat-table-col-container">
        {data.map((child, i) => {
          if (!child) {
            return <div key={i} className="figmalist-body-detail-stat-table-td">
              <div className="figmalist-body-detail-stat-table-label">-</div>
            </div>;
          }

          const { label, value } = child;
          return <TableKV key={i} label={label} value={value} />;
        })}
      </div>
    </div>;
  }

  const { name, age, tier, nationality, role, callsign, salary, background, level } = character;
  const { secondaryStats, firearmAptitudes } = character;


  const [detailTab, setDetailTab] = useState('stat');
  const [selectedPerkGroup, setSelectedPerkGroup] = useState('');

  const payment = getCharacterPaymentFunction?.(character);

  let detailBody = null;

  if (detailTab === 'stat') {
    const data = characterChartData(character);

    detailBody = <div className="figmalist-body-detail-box-body2">
      <Radar data={data} options={CHART_OPT_DETAIL} width="500px" height="200px" />

      <div className="figmalist-body-detail-stat-table">
        {makeStatTable(character, TableCol)}
      </div>
    </div>
  }
  else if (detailTab === 'perk') {
    const { statsPerks, operatorPerks } = character;

    const perksInfo = {
      'operator': [],
      'physical': [],
      'perception': [],
      'mental': [],
      'tactical': [],
      'shooting': [],
    };
    for (const [key, value] of Object.entries(statsPerksdata)) {
      if (statsPerks[key] === 'activated' || (value.overwrites?.length === 0 && statsPerks[key] !== 'blocked')) {
        perksInfo[value.stat].push({ perk_key: key, data: { ...value, minStat: (value.minStat).toFixed(0) }, state: statsPerks[key], stat: (character.realStats[value.stat]).toFixed(0) });
      }
    }

    for (const [key, value] of Object.entries(operatorPerks)) {
      const perkData = rolesPerksdata[key];
      if (value === 'activated' || (perkData.overwrites?.length === 0 && value !== 'blocked')) {
        perksInfo['operator'].push({
          perk_key: key, data: { ...rolesPerksdata[key], minStat: (rolesPerksdata[key].minStat / 5).toFixed(1) },
          state: value, stat: (character.totalRealStat / 5).toFixed(1)
        });
      }
    }

    detailBody = <div className="figmalist-body-detail-box-body2" width="380px">
      <div className='figmalist-body-detail-perks'>
        {PerkRow({
          key: 'operator',
          info: perksInfo['operator'],
          selected: selectedPerkGroup === 'operator',
          onClickPerkGroup: () => setSelectedPerkGroup(prevState => prevState != 'operator' ? 'operator' : ''),
          onClickPerkUnlockButton: (key) => onClickPerkUnlockButton(character, 'operator', key),
          tactical: character.realStats.tactical,
        })}
        {Object.keys(character.realStats).map((key) =>
          PerkRow({
            key,
            info: perksInfo[key],
            selected: selectedPerkGroup === key,
            onClickPerkGroup: () => { setSelectedPerkGroup(prevState => prevState != key ? key : '') },
            onClickPerkUnlockButton: (key) => onClickPerkUnlockButton(character, 'stat', key),
            tactical: character.realStats.tactical,
          })
        )}
      </div>
    </div>
  }

  let greendot = null;
  // if (Object.values(character.statsPerks).find((value) => value === 'deactivated') || Object.values(character.operatorPerks).find((value) => value === 'deactivated')) {
  //   greendot = <GreenDot top={-5} left={-5} radius={8} />;
  // }

  let expText;
  if (level.cur <= data_operatorExp.length) {
    expText = <span>{level.exp}<span className="figmalist-body-list-row-etc-sep" />{data_operatorExp[level.cur - 1].expMax}</span>;
  }
  else {
    expText = <span>{level.exp}<span className="figmalist-body-list-row-etc-sep" />∞</span>;
  }

  return <div className="figmalist-body-detail">
    <div className="figmalist-body-detail-inner">
      <div className="figmalist-body-detail-title">
        <div className="figmalist-body-detail-title-label">{L('loc_dynamic_string_agent_detail_title', { value: L(name) })}</div>
        {additionalButton != null ? additionalButton(character) : null}
      </div>

      <div className="figmalist-body-detail-profile">
        <div className="figmalist-body-detail-profile-left">
          <div className="figmalist-body-detail-profile-img">
            <PortraitWrapper agent={{ name, role }} className="portrait-frame-small1-figma" />
          </div>
        </div>

        <div className="figmalist-body-detail-profile-right">
          <ProfileRow >
            <ProfileRowItem label={L('loc_ui_string_agent_role')} value={<RoleBadge role={role} isLabel={true} />} />
            <ProfileRowItem label={L('loc_ui_string_agent_grade')} value={tier} />
          </ProfileRow>

          <ProfileRow >
            <ProfileRowItem label={L('loc_ui_string_agent_nationality')} value={nationality} />
            <ProfileRowItem label={L('loc_ui_string_agent_age')} value={age} />
          </ProfileRow>

          <ProfileRow >
            <ProfileRowItem label={L('loc_ui_string_agent_callsign')} value={callsign} />
          </ProfileRow>

          <ProfileRow >
            <ProfileRowItem label={L('loc_ui_string_agent_background')} value={L(background.name)}
              detailButton={ProfileBackgroundbutton} />
          </ProfileRow>
        </div>
      </div>

      <div className="figmalist-body-detail-box">
        <div className="figmalist-body-detail-box-header">
          <div className="figmalist-body-detail-box-header-label">
            {/* {L('loc_ui_string_agent_education')} */}
            {L('loc_ui_string_agent_level')}
          </div>
        </div>

        <div className="figmalist-body-detail-box-body">
          <div className="figmalist-body-detail-box-body-label">
            {/* {trainingCompletion.map((training) => training.name).join(', ')} */}
            {L('loc_ui_string_agent_level')} {level.cur} ({expText})
          </div>
        </div>
      </div>

      <div className="figmalist-body-detail-box">
        <div className="figmalist-body-detail-box-header">
          <div className="figmalist-body-detail-box-header-label">
            {L('loc_ui_string_role_tooltip_firearm_aptitudes')}
          </div>
        </div>

        <div className="figmalist-body-detail-box-firearmaptitudes">
          <div className="figmalist-body-detail-box-firearmaptitude">
            <div className="figmalist-body-detail-box-firearmaptitude-ty">
              <span className="figmalist-body-detail-box-firearmaptitude-ty-label">AR</span>
            </div>
            <span className="figmalist-body-detail-box-firearmaptitude-dash">-</span>
            <span className="figmalist-body-detail-box-firearmaptitude-label"><GetWeaponAptitudeIcon value={firearmAptitudes["AR"].id} /></span>
          </div>
          <div className="figmalist-body-detail-box-firearmaptitude">
            <div className="figmalist-body-detail-box-firearmaptitude-ty">
              <span className="figmalist-body-detail-box-firearmaptitude-ty-label">DMR</span>
            </div>
            <span className="figmalist-body-detail-box-firearmaptitude-dash">-</span>
            <span className="figmalist-body-detail-box-firearmaptitude-label"><GetWeaponAptitudeIcon value={firearmAptitudes["DMR"].id} /></span>
          </div>
          <div className="figmalist-body-detail-box-firearmaptitude">
            <div className="figmalist-body-detail-box-firearmaptitude-ty">
              <span className="figmalist-body-detail-box-firearmaptitude-ty-label">HG</span>
            </div>
            <span className="figmalist-body-detail-box-firearmaptitude-dash">-</span>
            <span className="figmalist-body-detail-box-firearmaptitude-label"><GetWeaponAptitudeIcon value={firearmAptitudes["HG"].id} /></span>
          </div>
          <div className="figmalist-body-detail-box-firearmaptitude">
            <div className="figmalist-body-detail-box-firearmaptitude-ty">
              <span className="figmalist-body-detail-box-firearmaptitude-ty-label">SG</span>
            </div>
            <span className="figmalist-body-detail-box-firearmaptitude-dash">-</span>
            <span className="figmalist-body-detail-box-firearmaptitude-label"><GetWeaponAptitudeIcon value={firearmAptitudes["SG"].id} /></span>
          </div>
          <div className="figmalist-body-detail-box-firearmaptitude">
            <div className="figmalist-body-detail-box-firearmaptitude-ty">
              <span className="figmalist-body-detail-box-firearmaptitude-ty-label">SMG</span>
            </div>
            <span className="figmalist-body-detail-box-firearmaptitude-dash">-</span>
            <span className="figmalist-body-detail-box-firearmaptitude-label"><GetWeaponAptitudeIcon value={firearmAptitudes["SMG"].id} /></span>
          </div>
        </div>
      </div>

      <div className="figmalist-body-detail-box2">
        <div className="figmalist-body-detail-box-header-tabgroup">
          <div className={`figmalist-body-detail-box-header-tab-${detailTab == 'stat' ? 'on' : 'off'}`} onClick={() => {
            setDetailTab('stat');
            triggerSound('UI_Outgame_Button_Click_Default')
          }}>
            <span className={`figmalist-body-detail-box-header-tab-label-${detailTab == 'stat' ? 'on' : 'off'}`}>
              {L('loc_ui_string_agent_stat')}
            </span>
          </div>

          <div className={`figmalist-body-detail-box-header-tab-${detailTab == 'perk' ? 'on' : 'off'}`} onClick={() => {
            setDetailTab('perk');
            triggerSound('UI_Outgame_Button_Click_Default');
          }}>
            {greendot}
            <span className={`figmalist-body-detail-box-header-tab-label-${detailTab == 'perk' ? 'on' : 'off'}`}>
              {L('loc_ui_title_agent_perks')}
            </span>
          </div>
        </div>

        {detailBody}
      </div>

      <div className="figmalist-body-detail-box-salary">
        <span className="figmalist-body-detail-box-salary-title">{L('loc_ui_string_agent_salary')}</span>
        <span className="figmalist-body-detail-box-salary-value">$ {salary.toLocaleString('en-US')}</span>
        {payment ? <>
          |
          <span className="figmalist-body-detail-box-salary-title">{L('loc_ui_string_agent_payment')}</span>
          <span className="figmalist-body-detail-box-salary-value">$ {payment.toLocaleString('en-US')}</span>
        </> : null}
      </div>

      <div className="figmalist-body-detail-perk-buttons">
        {renderDetailButtons != null && renderDetailButtons(character)}
      </div>

    </div>
  </div>;
};

export class FigmaListBody extends React.Component {
  render() {
    const { characters, selected, onSelect, renderRowExtra, renderNoteView, onClickPortrait, makeDiffs } = this.props;

    return <>
      <div className="figmalist-body-list-tabs">
        <ListTab className="figmalist-body-list-tab-name" label={L('loc_ui_title_menu_agent')} title={L('loc_ui_longtext_agent_identification')} />
        {HEADERS.map((label, i) => {
          let cls = "figmalist-body-list-tab-etc";
          if (label.includes('power')) {
            cls += " power";
          }
          return <ListTab key={i} className={cls} label={L(label)} title={L(TITLES_HEADER[i])} />;
        })}

      </div>

      <div className="figmalist-body-list-body">
        {characters.map((character, i) => {
          const extra = renderRowExtra ? renderRowExtra(character) : null;
          const note = renderNoteView ? renderNoteView(character) : null
          const diffs = makeDiffs ? makeDiffs(character) : {};
          return <ListRow key={i} character={character} onSelect={onSelect} extra={extra} note={note} selected={selected === character} onClickPortrait={onClickPortrait} diffs={diffs} />;
        })}
      </div>
    </>;
  }
}

export class FigmaListView extends React.Component {
  render() {
    const { characters, selected, onSelect, renderDetailButtons, header, listHeader, onClickButtonClose, additionalButton, OnClickBackgroundButton, onClickPerkUnlockButton, renderRowExtra, renderNoteView, makeDiffs, getCharacterPaymentFunction } = this.props;

    let closebtn = null;
    if (onClickButtonClose) {
      closebtn = <SoundButton className="figmalist-header-btn-close" onClick={onClickButtonClose}>
        {ICON_CLOSE}
      </SoundButton>;
    }

    return <div className="figmalist-root">
      <div className="figmalist-header">
        <div className="figmalist-header-inner">
          {header}
          {closebtn}
        </div>
      </div>

      <div className="figmalist-body">

        <div className="figmalist-body-list">
          {listHeader}
          <FigmaListBody characters={characters} selected={selected} onSelect={onSelect} renderRowExtra={renderRowExtra} renderNoteView={renderNoteView} makeDiffs={makeDiffs} />
        </div>
        <Detailview character={selected} renderDetailButtons={renderDetailButtons}
          getCharacterPaymentFunction={getCharacterPaymentFunction}
          additionalButton={additionalButton}
          OnClickBackgroundButton={OnClickBackgroundButton}
          onClickPerkUnlockButton={onClickPerkUnlockButton} />
      </div >
    </div >;

    /*<FigmaListSummaryHeader />*/
  }
}

export function FigmaListRowExtra(props) {
  let { note } = props;
  let header = null;
  if (note?.header) {
    header = note.header;
    note = note.note;
  }

  const title = note != null ? L('loc_ui_string_agent_note') : L('loc_ui_string_agent_note_empty');

  return <div className='figmalist-body-list-row-extra'>
    <div className='figmalist-body-list-row-extra-inner'>
      {header}
      <span className='figmalist-body-list-row-extra-title'>{title}</span>
      <span className='figmalist-body-list-row-extra-note'>{note}</span>
    </div>
  </div>
}

export function FigmaCard2Note(props) {
  const { note } = props;
  return <div className="figmalist-body2-card-notes">
    {note}
  </div>;
}

export function FigmaRecruitView2Wrapper(props) {
  const { game, focusedMenus, focusedEvent, overlayPush, overlayPopMaybe, grindSetState, onRecruit, buildEventActionButton, onScheduleCEO, makeNote } = props;
  const characters = game.recruit_listings;
  const [selected, setSelected] = useState(characters[0]);
  if (characters.indexOf(selected) === -1) {
    setTimeout(() => {
      setSelected(characters[0]);
    });
  }

  const recruit_avail = game.resources.agent_piece > 0 && game.events.length <= 0;

  const schedule_id = 'meeting_headhunter';
  const schedule = data_scheduleCEOs.find((d) => d.id === schedule_id);
  const schedule_disabled = game.ceoSchedules.includes(schedule_id) || game.events.length > 0 || focusedEvent || !game.config.opts.allow_meeting_headhunter;
  const header = <>
    <div className="figmalist-header2-title">
      <div className="figmalist-header2-title-bullet" />
      <div className="figmalist-header2-title-label">{L('loc_ui_string_menu_recruit')}</div>
    </div>
    <FigmaListHeaderBtn2
      key='ceo_schedule_meeting_headhunter'
      label={L('loc_data_string_schedule_ceo_meeting_headhunter')}
      desc={L('loc_data_longtext_schedule_ceo_meeting_headhunter_desc')}
      cueList={['UI_Outgame_Button_Click_Proposal', 'UI_Outgame_InsertShot_Talk']}
      enabled={!schedule_disabled}
      onClick={() => {
        onScheduleCEO(schedule, null, false);
      }}
      icon={<svg className="figmalist-header2-title-button-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M10.0597 13.042C10.7992 14.3826 11.6515 15.6692 12.7582 16.8425C13.8684 18.023 15.249 19.0973 17.036 20.015C17.1685 20.0798 17.2939 20.0798 17.4067 20.0348C17.5768 19.97 17.7505 19.8278 17.9206 19.6569C18.0531 19.5237 18.2178 19.3114 18.3898 19.0793C19.0774 18.1705 19.9279 17.0422 21.1294 17.6073C21.1563 17.6199 21.176 17.6343 21.2029 17.6451L25.2103 19.961C25.2229 19.9682 25.2372 19.9808 25.2479 19.988C25.7762 20.3533 25.9946 20.9165 26 21.5554C26 22.205 25.7618 22.9356 25.4127 23.5528C24.9507 24.368 24.2702 24.9078 23.4859 25.2641C22.7392 25.6096 21.9084 25.795 21.1097 25.9137C19.8563 26.0991 18.6816 25.9803 17.4801 25.6096C16.3054 25.2443 15.1218 24.6415 13.829 23.8389L13.7341 23.7777C13.1414 23.4052 12.5003 23.0076 11.8718 22.5379C9.57261 20.7924 7.22865 18.2713 5.70123 15.4965C4.42091 13.1679 3.72256 10.654 4.10397 8.25889C4.31527 6.94526 4.87574 5.75039 5.85343 4.96222C6.70578 4.27121 7.85358 3.89332 9.33982 4.02648C9.50993 4.03907 9.66214 4.13805 9.74272 4.28561L12.3123 8.65118C12.6883 9.14064 12.7349 9.62651 12.529 10.1124C12.3589 10.5101 12.015 10.8772 11.5459 11.2191C11.408 11.3378 11.2433 11.4584 11.0696 11.5844C10.4948 12.0036 9.8412 12.4877 10.065 13.0563L10.0597 13.042Z" fill="#382500" />
      </svg>}
    />
  </>;
  return <>
    <FigmaRecruitView2
      characters={characters}
      selected={selected}
      onSelect={(agent) => { setSelected(agent) }}
      header={header}
      OnClickBackgroundButton={(agent) => {
        overlayPush({ ty: 'AGENT_BACKGROUND', agent });
      }}
      additionalButton={null}
      turn={game.turn}
      getCharacterPaymentFunction={(agent) => game.getAgentPayment(agent)}
      makeNote={makeNote}
    />
    <Lobby2Footer
      game={game}
      overrideNextActionButtons={[{
        key: 'recruits_prev',
        label: L('loc_ui_string_lobby_button_next_back'),
        desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
        onClick: () => {
          overlayPopMaybe('LOBBY-RECRUITS');
          grindSetState({ focusedMenus: focusedMenus.slice(0, -1) });
        },
        enabled: !game.disable_button['recruits_prev'],
        className: 'secondary',
      },
      {
        key: 'recruit',
        label: L('loc_ui_string_recruit_proposal'),
        cueList: ['UI_Outgame_Button_Click_Proposal', 'UI_Outgame_InsertShot_Talk'],
        enabled: recruit_avail && game.events.length <= 0 && !focusedEvent && selected?.relation > -100,
        onClick: () => {
          onRecruit(selected);
        }
      }, buildEventActionButton()]}
    />
  </>
}


export function FigmaRecruitView2(props) {
  const { characters, selected, onSelect, header, onClickButtonClose, additionalButton, OnClickBackgroundButton, OnEditCallsignButton, onClickPerkUnlockButton, renderRowExtra, turn, getCharacterPaymentFunction, makeNote } = props;

  let closeBtn = null;
  if (onClickButtonClose) {
    closeBtn = <SoundButton className="figmalist-header-btn-close" onClick={onClickButtonClose}>
      {ICON_CLOSE}
    </SoundButton>;
  }

  return <div className="figmalist-root2">
    <div className="figmalist-header2">
      {header}
      {closeBtn}
    </div>

    <div className="figmalist-body2">

      <div className="figmalist-body2-list">
        <FigmaListBody2
          characters={characters}
          selected={selected}
          onSelect={onSelect}
          renderRowExtra={renderRowExtra}
          getCharacterPaymentFunction={getCharacterPaymentFunction}
          makeNote={makeNote} />
      </div>
      <DetailView2 character={selected}
        additionalButton={additionalButton ?? null}
        OnClickBackgroundButton={OnClickBackgroundButton}
        OnEditCallsignButton={OnEditCallsignButton}
        onClickPerkUnlockButton={onClickPerkUnlockButton}
        turn={turn} />
    </div >
  </div >;
}

const ProfileRowItem2 = (props) => {
  const { label, value, detailButton, flexBasis, alterValue } = props;
  const style = {
    flexBasis: flexBasis ?? 1,
    flexGrow: 1,
  }
  return <div className="figmalist-body-detail2-profile-row-item" style={style}>
    <span className="figmalist-body-detail2-profile-label-key">{label}</span>
    {value ? <div className='figmalist-body-detail2-profile-label-value-slot'>
      <span className="figmalist-body-detail2-profile-label-value">{value}</span>
      {detailButton}
    </div> : null}
    {alterValue ?? null}
  </div >;
}

export function DetailView2(props) {
  const { character, additionalButton, OnClickBackgroundButton, OnEditCallsignButton, onClickPerkUnlockButton, turn } = props;

  const ProfileBackgroundButton = <div {...soundprops(null)} className='btn-tiny figmalist-body-detail2-profile-detail-button' onClick={() => OnClickBackgroundButton(character)}>
    <div className='btn-tiny-inner'>
      <span className='btn-tiny-label'>
        {L('loc_ui_stiring_squad_agent_detail')}
      </span>
    </div>
  </div>

  const EditCallsignButton = OnEditCallsignButton ?
    <div {...soundprops(null)} className='btn-tiny figmalist-body-detail2-profile-detail-button' onClick={() => OnEditCallsignButton(character)}>
      <div className='btn-tiny-inner'>
        <span className='btn-tiny-label'>
          {L('Edit')}
        </span>
      </div>
    </div> : null;

  const PerkRow = (props) => {
    const { key, info, selected, onClickPerkGroup, onClickPerkUnlockButton, tactical } = props;
    return <>
      {info.filter((i) => i.state === 'activated').map((i) => <ActivatedPerkBody i={i} tactical={tactical} />)}
    </>
  };
  if (!character) {
    return null;
  }

  const TableKV = (props) => {
    const { label, value } = props;
    return <div className="figmalist-body-detail2-stat-table-td">
      <div className="figmalist-body-detail2-stat-table-label">{label}</div>
      <div className="figmalist-body-detail2-stat-table-value">{value}</div>
    </div>;
  };

  const TableCol = (props) => {
    const { title, data } = props;
    return <div className="figmalist-body-detail2-stat-table-col">
      <div className="figmalist-body-detail2-stat-table-th">
        <div className="figmalist-body-detail2-stat-table-th-label">{title}</div>
      </div>

      <div className="figmalist-body-detail2-stat-table-col-container">
        {data.map((child, i) => {
          if (!child) {
            return <div key={i} className="figmalist-body-detail2-stat-table-td">
              <div className="figmalist-body-detail2-stat-table-label">-</div>
            </div>;
          }

          const { label, value } = child;
          return <TableKV key={i} label={label} value={value} />;
        })}
      </div>
    </div>;
  }

  const { name, age, nationality, callsign, salary, background, level } = character;
  const { firearmAptitudes } = character;

  const [detailTab, setDetailTab] = useState('stat');
  const [selectedPerkGroup, setSelectedPerkGroup] = useState('');

  let detailBody = null;

  if (detailTab === 'stat') {
    const data = characterChartData(character);

    detailBody = <div className="figmalist-body-detail2-box-body2">

      <div className="figmalist-body-detail2-stats-detail">
        <div className="figmalist-body-detail2-stats-graph-legend">
          <div className="figmalist-body-detail2-stats-graph-legend-item current">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
              <rect x="0.0605469" y="0.631348" width="16" height="16" rx="8" fill="#D59B57" />
            </svg>
            <span>{L('loc_ui_string_agent_stat3_current')}</span>
          </div>
          <div className="figmalist-body-detail2-stats-graph-legend-item potential">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
              <rect x="0.561523" y="1.4895" width="16" height="16" rx="8" fill="#AF683F" />
              <path d="M11.2972 1.96947C10.4437 1.6589 9.5224 1.4895 8.56152 1.4895C8.18753 1.4895 7.81953 1.51517 7.45919 1.56483L0.636871 8.38701C0.587194 8.7474 0.561523 9.11545 0.561523 9.4895C0.561523 10.4503 0.730906 11.3716 1.04144 12.225L11.2972 1.96947Z" fill="#5D2F14" />
              <path d="M4.06509 16.1073C3.23172 15.5399 2.5111 14.8193 1.94377 13.9859L13.0581 2.87184C13.8914 3.43918 14.612 4.15982 15.1794 4.9932L4.06509 16.1073Z" fill="#5D2F14" />
              <path d="M9.6641 17.4141C9.30368 17.4638 8.9356 17.4895 8.56152 17.4895C7.6007 17.4895 6.67945 17.3201 5.82598 17.0096L16.0817 6.75411C16.3922 7.60753 16.5615 8.52874 16.5615 9.4895C16.5615 9.86363 16.5358 10.2318 16.4861 10.5922L9.6641 17.4141Z" fill="#5D2F14" />
            </svg>
            <span>{L('loc_ui_string_agent_stat3_potential')}</span>
          </div>
          <div className="figmalist-body-detail2-stats-graph-legend-item expected">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
              <rect y="1.2627" width="16" height="16" rx="8" fill="#8A795F" />
            </svg>
            <span>{L('loc_ui_string_agent_stat3_expected')}</span>
          </div>
        </div>
        <AgentDetailStatsGraph data={data} name={name} />
        <div className="figmalist-body-detail2-stats-table">
          {makeStatTable(character, TableCol)}
        </div>
      </div>
    </div>
  } else if (detailTab === 'perk') {
    const { statsPerks, operatorPerks } = character;

    const perksInfo = {
      'operator': [],
      'physical': [],
      'perception': [],
      'mental': [],
      'tactical': [],
      'shooting': [],
    };
    for (const [key, value] of Object.entries(statsPerksdata)) {
      if (statsPerks[key] === 'activated' || (value.overwrites?.length === 0 && statsPerks[key] !== 'blocked')) {
        perksInfo[value.stat].push({ perk_key: key, data: { ...value, minStat: (value.minStat).toFixed(0) }, state: statsPerks[key], stat: (character.realStats[value.stat]).toFixed(0) });
      }
    }

    for (const [key, value] of Object.entries(operatorPerks)) {
      const perkData = rolesPerksdata[key];
      if (value === 'activated' || (perkData.overwrites?.length === 0 && value !== 'blocked')) {
        perksInfo['operator'].push({
          perk_key: key, data: { ...rolesPerksdata[key], minStat: (rolesPerksdata[key].minStat / 5).toFixed(1) },
          state: value, stat: (character.totalRealStat / 5).toFixed(1)
        });
      }
    }

    detailBody = <div className="figmalist-body-detail2-box-body2">
      <div className='figmalist-body-detail-perks'>
        {PerkRow({
          key: 'operator',
          info: perksInfo['operator'],
          selected: selectedPerkGroup === 'operator',
          onClickPerkGroup: () => setSelectedPerkGroup(prevState => prevState != 'operator' ? 'operator' : ''),
          onClickPerkUnlockButton: (key) => onClickPerkUnlockButton(character, 'operator', key),
          tactical: character.realStats.tactical,
        })}
        {Object.keys(character.realStats).map((key) =>
          PerkRow({
            key,
            info: perksInfo[key],
            selected: selectedPerkGroup === key,
            onClickPerkGroup: () => { setSelectedPerkGroup(prevState => prevState != key ? key : '') },
            onClickPerkUnlockButton: (key) => onClickPerkUnlockButton(character, 'stat', key),
            tactical: character.realStats.tactical,
          })
        )}
      </div>
    </div>;
  } else if (detailTab === 'modifier') {
    const { modifier } = character;
    const visible_modifiers = modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);

    detailBody = <div className="figmalist-body-detail2-box-body2">
      <div className='figmalist-body-detail-modifiers'>
        {visible_modifiers.map((mod, i) =>
          <div className='figmalist-body-detail-modifier-item'>
            <div className='figmalist-body-detail-modifier-item-inner'>
              <div className='figmalist-body-detail-modifier-item-icon'>
                <AgentModifierIcon key={i} modifier={mod} turn={turn} />
              </div>
              <div className='figmalist-body-detail-modifier-item-label'>
                <AgentModifierBody key={i} modifier={mod} turn={turn} />
              </div>
            </div>
          </div>)}
      </div>
    </div>;
  }

  let greendot = null;
  // if (Object.values(character.statsPerks).find((value) => value === 'deactivated') || Object.values(character.operatorPerks).find((value) => value === 'deactivated')) {
  //   greendot = <GreenDot top={-5} left={-5} radius={8} />;
  // }

  let expMax;
  if (level.cur <= data_operatorExp.length) {
    expMax = data_operatorExp[level.cur - 1].expMax;
  }
  else {
    expMax = -1;
  }

  return <div className="figmalist-body-detail2">
    <div className="figmalist-body-detail2-title">
      <div className="figmalist-body-detail2-title-inner">
        <div className="figmalist-body-detail2-title-label">
          <span className="figmalist-body2-card-header-role"><RoleBadge role={character.role} isLabel={true} hideIcon={true} /></span>
          <span className="figmalist-body2-card-header-name">{L(character.name)}</span>
        </div>
        {additionalButton !== null ? additionalButton(character) : null}
      </div>
    </div>
    <div className="figmalist-body-detail2-profile">
      <div className="figmalist-body-detail2-profile-upper">
        <PortraitWrapper2 agent={character} className="portrait-frame-card" inSquad={character.squad !== undefined && character.squad !== null} />
        <div className="figmalist-body-detail2-profile-right">
          <div className="figmalist-body-detail-profile-row2">
            <ProfileRowItem2 label={L('loc_ui_string_agent_level')} alterValue={<DetailView2Levels level={level.cur} exp={level.exp} expMax={expMax} />} flexBasis={408} />
          </div>
          <div className="figmalist-body-detail2-profile-sep" />
          <div className="figmalist-body-detail-profile-row2">
            <ProfileRowItem2 label={L('loc_ui_string_agent_nationality')} alterValue={<NationalityBadge2 nationality={nationality} />} flexBasis={100} />
            <ProfileRowItem2 label={L('loc_ui_string_agent_age')} value={age} flexBasis={100} />
          </div>
          <div className="figmalist-body-detail2-profile-sep" />
          <div className="figmalist-body-detail-profile-row2">
            <ProfileRowItem2 label={L('loc_ui_string_agent_callsign')} value={callsign} detailButton={EditCallsignButton} flexBasis={408} />
          </div>
          <div className="figmalist-body-detail2-profile-sep" />
          <div className="figmalist-body-detail-profile-row2">
            <ProfileRowItem2 label={L('loc_ui_string_agent_background')} value={L(background.name)} detailButton={ProfileBackgroundButton} flexBasis={532} />
          </div>
          <div className="figmalist-body-detail2-profile-sep" />
        </div>
      </div>
      <div className="figmalist-body-detail2-profile-lower">
        <div className="figmalist-body-detail-profile-row2">
          <ProfileRowItem2 label={L('loc_ui_string_role_tooltip_firearm_aptitudes')} alterValue={<AptitudeBar2 aptitudes={firearmAptitudes} />} flexBasis={532} />
        </div>
      </div>
    </div>
    <div className="figmalist-body-detail2-empty" />
    <div className="figmalist-body-detail2-spec">
      <div className="figmalist-body-detail2-box2">
        <div className="figmalist-body-detail2-box2-header">
          <div className="figmalist-body-detail2-box2-header-tabgroup">
            <div className={`figmalist-body-detail2-box2-header-tab-${detailTab == 'stat' ? 'on' : 'off'}`} onClick={() => {
              setDetailTab('stat');
              triggerSound('UI_Outgame_Button_Click_Default')
            }}>
              <div className="figmalist-body-detail2-box2-header-tab-outer" />
              <div className="figmalist-body-detail2-box2-header-tab-inner" />
              <span className={`figmalist-body-detail2-box2-header-tab-label-${detailTab == 'stat' ? 'on' : 'off'}`}>
                {L('loc_ui_string_agent_stat')}
              </span>
            </div>

            <div className={`figmalist-body-detail2-box2-header-tab-${detailTab == 'modifier' ? 'on' : 'off'}`} onClick={() => {
              setDetailTab('modifier');
              triggerSound('UI_Outgame_Button_Click_Default');
            }}>
              <div className="figmalist-body-detail2-box2-header-tab-outer" />
              <div className="figmalist-body-detail2-box2-header-tab-inner" />
              <span className={`figmalist-body-detail2-box2-header-tab-label-${detailTab == 'modifier' ? 'on' : 'off'}`}>
                {L('loc_ui_title_agent_modifiers')}
              </span>
            </div>

            <div className={`figmalist-body-detail2-box2-header-tab-${detailTab == 'perk' ? 'on' : 'off'}`} onClick={() => {
              setDetailTab('perk');
              triggerSound('UI_Outgame_Button_Click_Default');
            }}>
              {greendot}
              <div className="figmalist-body-detail2-box2-header-tab-outer" />
              <div className="figmalist-body-detail2-box2-header-tab-inner" />
              <span className={`figmalist-body-detail2-box2-header-tab-label-${detailTab == 'perk' ? 'on' : 'off'}`}>
                {L('loc_ui_title_agent_perks')}
              </span>
            </div>
          </div>
          <div className="figmalist-body-detail2-box2-header-divider" />
        </div>
        {detailBody}
      </div>
      <div className="figmalist-body-detail2-box2-salary">
        <span className="figmalist-body-detail2-box2-salary-title">{L('loc_ui_string_agent_salary')}</span>
        <span className="figmalist-body-detail2-box2-salary-value">$ {salary.toLocaleString('en-US')}</span>
        <div className="figmalist-body-detail2-box2-salary-deco left" />
        <div className="figmalist-body-detail2-box2-salary-deco right" />
      </div>
    </div>


    {/* <div className="figmalist-body-detail2-perk-buttons">
      {renderDetailButtons != null && renderDetailButtons(character)}
    </div> */}
  </div>;
};

export function AgentDetailStatsGraph(props) {
  const { data, name, hidePotential, noAnimation } = props;

  const items = ['physical', 'perception', 'mental', 'tactical', 'shooting'];

  return <div className="figmalist-body-detail2-stats-graph-wrapper">
    <div className="figmalist-body-detail2-stats-graph">
      {items.map((item, i) => {
        const currentLabel = data.labels[i][1];
        const current = data.datasets[0].data[i];
        const currentStyle = {
          height: `${current}%`,
        };
        const potential = data.datasets[1].data[i];
        const potentialStyle = {
          height: `${potential - current > 0 ? potential - current : 0}%`,
          bottom: `${current}%`,
        };
        const potentialLabel = potential.toFixed(0);
        const potentialLabelStyle = {
          bottom: `${potential}%`,
        };
        if (!noAnimation) {
          currentStyle.animation = `grow-${i} 0.6s cubic-bezier(0,.93,0,.99) 0s 1`;
          potentialStyle.animation = `antigrow-${i} 0.6s cubic-bezier(0,.93,0,.99) 0s 1`;
          potentialLabelStyle.animation = `label-${i} 0.6s cubic-bezier(0,.93,0,.99) 0s 1`;
        }
        const expected = data.datasets[2].data[i];
        const expectedStyle = { bottom: `calc(${expected}% - 3px)` };
        return <div className="figmalist-body-detail2-stats-graph-item" key={i}>
          <div key={name + 'potential' + i} className="figmalist-body-detail2-stats-graph-item-potential" style={potentialStyle}>
            <style>
              {`
                @keyframes antigrow-${i} {
                  0% { height: ${potential}%; bottom: 0%; }
                  100% { height: ${potential - current}%; bottom: ${current}%; }
                }
              `}
            </style>
          </div>
          <div key={name + 'current' + i} className="figmalist-body-detail2-stats-graph-item-current" style={currentStyle}>
            <style>
              {`
                @keyframes grow-${i} {
                  0% { height: 0; }
                  100% { height: ${current}%; }
                }
              `}
            </style>
          </div>
          <div className="figmalist-body-detail2-stats-graph-item-expected" style={expectedStyle} />
          <div key={name + 'potential-label' + i} className="figmalist-body-detail2-stats-graph-item-potential-value" data-text={currentLabel} style={potentialLabelStyle}>
            <style>
              {`
                @keyframes label-${i} {
                  0% { bottom: 0%; }
                  100% { bottom: ${potential}%; }
                }
              `}
            </style>
            {hidePotential ? null : potentialLabel}
          </div>
          <div className="figmalist-body-detail2-stats-graph-item-value" data-text={currentLabel}>
            {currentLabel}
          </div>

        </div>;
      })}
    </div>
    <div className="figmalist-body-detail2-stats-graph-divider" />
  </div>
}

function FigmaListBody2(props) {
  const { characters, selected, onSelect, renderRowExtra, getCharacterPaymentFunction, makeNote } = props;

  return <>
    <div className="figmalist-body2-list-tabs">
      {Object.keys(HEADERS2).map((key, i) => {
        const label = HEADERS2[key];
        const title = TITLES_HEADER2[key];
        return <ListTab2 key={i} className={key}
          label={L(label)}
          title={L(title)} />;
      })}

    </div>
    <div className="figmalist-body2-list-body-wrapper">
      <div className="figmalist-body2-list-body">

        {characters.map((character, i) => {
          const extra = renderRowExtra ? renderRowExtra(character) : null;
          return <ListRow2 key={i}
            character={character}
            onSelect={onSelect}
            extra={extra}
            selected={selected === character}
            payment={getCharacterPaymentFunction(character)}
            makeNote={makeNote}
          />;
        })}
      </div>
    </div>
  </>;
}
function ListTab2(props) {
  const { label, className, title } = props;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className={`figmalist-body2-list-tab ${className}`}
        {...figmaTooltipEvents(context, title)}
      >
        <span className="figmalist-body2-list-tab-label figma-tooltip-marker">{label}</span>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

const ListRow2 = (props) => {
  const { character, onSelect, extra, selected, payment, makeNote } = props;
  const { name, role, } = character;
  const { tier, condition, mood, relation, power } = character;
  const note = makeNote(character);
  const cols = {
    role,
    tier,
    level: character.level.cur,
    power,
    modifiers: note,
    relation,
    payment: `$${payment.toLocaleString('en-US')}`,
  };

  const svg_bust = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29" fill="none">
    <path d="M12.5163 27.6924L2.02265 22.8175L1.97704 2.01831L22.9429 1.97235L22.9885 22.7716L12.5163 27.6924Z" fill="#E7C263" stroke="#FFDD9A" strokeWidth="2" />
    <path d="M14.1439 14.1649C15.8443 13.478 17.05 11.7567 17.05 9.74194C17.05 7.12304 15.0129 5 12.5 5C9.9871 5 7.95 7.12304 7.95 9.74194C7.95 11.7567 9.15564 13.478 10.8561 14.1649L4 22L12.5 26L19 23L21 22L14.1439 14.1649Z" fill="#BB8E1C" />
  </svg>

  let greendot = null;
  // if (Object.values(character.statsPerks).find((value) => value === 'deactivated') || Object.values(character.operatorPerks).find((value) => value === 'deactivated')) {
  //   greendot = <GreenDot top={0} left={1} radius={8} />;
  // }

  return <div soundprops={null} className={'figmalist-body2-list-row' + (selected ? " selected" : "")} onClick={() => onSelect?.(character)}>
    <div className="figmalist-body2-list-row-info">
      {greendot}
      <div className="figmalist-body2-list-row-item name">
        <span className={"figmalist-body2-list-row-item-label"}>
          {L(name)}
        </span>
      </div>
      {Object.entries(cols).map(([key, col], i) => {
        let label;
        if (key === "role") {
          label = <span className="figmalist-body2-list-row-item-label">
            <RoleBadge role={role} isLabel={true} />
          </span>;
        } else if (key === "relation") {
          label = <div className="figmalist-body2-list-row-item-label multiline">
            <span className={"figmalist-body2-list-row-item-label inline" + evalCSSClass(key, col)}>
              {L(getRelationData(col).name)}
            </span>
          </div>;
        } else if (key === "tier") {
          label = <div className="figmalist-body2-list-row-item-label">
            <TierBadge2 tier={col} />
          </div>;
        } else if (key === "power") {
          label = <div className="figmalist-body2-list-row-item-label inline" >
            <PowerIndicator character={character} starSize={20} />
          </div>;
        } else if (key === "level") {
          label = <div className="figmalist-body2-list-row-item-label multiline">
            <div className="figmalist-body2-list-row-item-label inline">
              {col}
            </div>
          </div>;
        } else if (key === "payment") {
          label = <div className="figmalist-body2-list-row-item-label multiline">
            <div className="figmalist-body2-list-row-item-label inline">
              {col}
            </div>
          </div>;
        } else if (key === 'modifiers') {
          label = <>
            {col}
          </>
        } else {
          console.warn(`${key} doesn't exist`);
        }
        return <div className={"figmalist-body2-list-row-item " + key} key={i}>
          {label}
        </div >;
      })}
    </div>
    {extra}
  </div >;
};

export function FigmaListHeaderBtn2(props) {
  const { label, enabled, onClick, desc, cueList, icon } = props;

  return <TooltipContext.Consumer>
    {(context) => {
      let events = {};
      if (desc?.length > 0) {
        events = figmaTooltipEvents(context, desc);
      }
      return <div {...events}>
        <div {...soundprops(null)} className="figmalist-header2-title-button" disabled={!enabled} onClick={onClick}>
          <div className="figmalist-header2-title-button-inner">
            {icon}
            <span className="figmalist-header2-title-button-label">{label}</span>
          </div>
        </div>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

export function FigmaAgentsView2Wrapper(props) {
  const { game, focusedMenus, focusedEvent, onScheduleCEO, conditionInfo, overlayPush, overlayPopMaybe, grindSetState, buildEventActionButton, agentFireButton, agentHasModifierEffect, onAssign, onUnassign, makeNote } = props;
  const characters = game.agents_all.filter((a) => {
    return game.agents_avail_all.includes(a);
  });
  const [selected, setSelected] = useState(characters[0]);
  if (characters.indexOf(selected) === -1) {
    setTimeout(() => {
      setSelected(characters[0]);
    });
  }

  const header = <div className="figmalist-header2-title">
    <div className="figmalist-header2-title-bullet" />
    <div className="figmalist-header2-title-label">{L('loc_ui_string_menu_employee_list')}</div>
  </div>;

  const {
    schedule_meeting,
    schedule_allowed,
  } = conditionInfo;

  const schedule_id = 'meeting_person';
  const schedule_meeting_person = data_scheduleCEOs.find((d) => d.id === schedule_id);

  function squadButton(character) {
    const buttonAttributes = {};
    if (character && character.squad !== undefined) {
      buttonAttributes.key = 'squad_unassign_' + character.idx;
      buttonAttributes.label = L('loc_ui_string_squad_agent_exclude');
      buttonAttributes.desc = '';
      buttonAttributes.cueList = ['UI_Outgame_Button_Click_Yellow'];
      buttonAttributes.enabled = !focusedEvent;
      buttonAttributes.onClick = () => {
        onUnassign(0, character, 'player');
        grindSetState({ game });
      };
    } else if (game.squads[0].agents.length < game.squads[0].size) {
      buttonAttributes.key = 'squad_assign_' + character.idx;
      buttonAttributes.label = L('loc_ui_string_squad_agent_add');
      buttonAttributes.desc = '';
      buttonAttributes.cueList = ['UI_Outgame_Button_Click_Yellow'];
      buttonAttributes.enabled = game.agentAvail(character);
      buttonAttributes.onClick = () => {
        onAssign(0, character);
        grindSetState({ game });
      };
    } else {
      buttonAttributes.key = 'squad_full';
      buttonAttributes.label = L('loc_ui_string_squad_alert_agent_cannot_add');
      buttonAttributes.desc = '';
      buttonAttributes.enabled = false;
      buttonAttributes.onClick = () => { };
    }
    return buttonAttributes;
  }

  const Lobby2FooterButtons = [{
    key: 'agents_prev',
    label: L('loc_ui_string_lobby_button_next_back'),
    desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
    onClick: () => {
      overlayPopMaybe('LOBBY-ASSIGN');
      overlayPopMaybe('LOBBY-AGENTS');
      grindSetState({ focusedMenus: focusedMenus.slice(0, -1) });
    },
    enabled: !game.disable_button['agents_prev'],
    className: 'secondary',
  }];

  if (selected) {
    Lobby2FooterButtons.push({
      key: 'ceo_schedule_meeting_person',
      label: L('loc_data_string_schedule_ceo_' + schedule_id),
      desc: L(schedule_meeting_person.desc),
      cueList: ['UI_Outgame_Button_Click_Yellow'],
      enabled: !game.isMeetingDisabled(selected) && onScheduleCEO,
      onClick: () => {
        onScheduleCEO?.(schedule_meeting_person, selected);
      }
    });

    if (onAssign) {
      Lobby2FooterButtons.push(squadButton(selected));
    }
  }
  Lobby2FooterButtons.push(buildEventActionButton());

  return <>
    <FigmaAgentsView2 characters={characters} selected={selected}
      header={header}
      onSelect={(character) => setSelected(character)}
      OnClickBackgroundButton={(agent) => {
        overlayPush({ ty: 'AGENT_BACKGROUND', agent });
      }}
      OnEditCallsignButton={() => { }}
      makeNote={makeNote}
      additionalButton={agentFireButton}
      turn={game.turn}
    />
    <Lobby2Footer
      game={game}
      overrideNextActionButtons={Lobby2FooterButtons}
    />
  </>;
}

export function FigmaAgentsView2(props) {
  const { characters, selected, onSelect, header, onClickButtonClose, additionalButton, OnClickBackgroundButton, OnEditCallsignButton, onClickPerkUnlockButton, renderRowExtra, makeNote, makeDiffs, turn } = props;

  const [selectedFilter, setSelectedFilter] = useState('entire');

  let closebtn = null;
  if (onClickButtonClose) {
    closebtn = <SoundButton className="figmalist-header-btn-close" onClick={onClickButtonClose}>
      {ICON_CLOSE}
    </SoundButton>;
  }

  return <div className="figmalist-agents-root2">
    <div className="figmalist-header2">
      {header}
      {closebtn}
    </div>

    <div className="figmalist-agents-body2">
      <div className="figmalist-agents-body2-list">
        <FigmaCardboardFilters
          characters={characters}
          selected={selectedFilter}
          onSelect={(key) => {
            setSelectedFilter(key);
          }}
        />
        <FigmaCardboard
          characters={characters}
          selected={selected}
          onSelect={onSelect}
          filter={selectedFilter}
          makeNote={makeNote}
        />
      </div>
      <DetailView2 character={selected}
        additionalButton={additionalButton}
        OnClickBackgroundButton={OnClickBackgroundButton}
        OnEditCallsignButton={OnEditCallsignButton}
        onClickPerkUnlockButton={onClickPerkUnlockButton} turn={turn} />
    </div >
  </div >;
}

export function AgentCard2Smiley(props) {
  const { ty, val } = props;
  const svg_smiley_list = [
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 26" fill="none">
      <circle cx="13.167" cy="13" r="13" fill="#D12121" />
      <circle cx="13.167" cy="13" r="12.5" stroke="black" strokeOpacity="0.2" />
      <g opacity="0.8">
        <path d="M6.9323 19.0766C6.73842 19.3811 6.97271 19.7485 7.33367 19.7485H18.9998C19.3608 19.7485 19.5951 19.3811 19.4012 19.0766C18.5017 17.6644 16.455 15.707 13.1667 15.707C9.87844 15.707 7.83174 17.6644 6.9323 19.0766Z" fill="black" />
        <path d="M5.56348 10.8009C5.53016 10.6592 5.50821 10.5175 5.49707 10.3768L10.1487 7.92847C10.6445 8.31408 11.0157 8.86249 11.1695 9.51662C11.5288 11.0448 10.5651 12.5711 9.01697 12.9258C7.46891 13.2804 5.92274 12.3291 5.56348 10.8009Z" fill="black" />
        <path d="M20.77 10.8009C20.8033 10.6592 20.8252 10.5175 20.8364 10.3768L16.1847 7.92847C15.6889 8.31408 15.3177 8.86249 15.1639 9.51662C14.8046 11.0448 15.7684 12.5711 17.3165 12.9258C18.8645 13.2804 20.4107 12.3291 20.77 10.8009Z" fill="black" />
        <path d="M6.9323 19.0766C6.73842 19.3811 6.97271 19.7485 7.33367 19.7485H18.9998C19.3608 19.7485 19.5951 19.3811 19.4012 19.0766C18.5017 17.6644 16.455 15.707 13.1667 15.707C9.87844 15.707 7.83174 17.6644 6.9323 19.0766Z" stroke="black" stroke-width="0.5" />
        <path d="M5.56348 10.8009C5.53016 10.6592 5.50821 10.5175 5.49707 10.3768L10.1487 7.92847C10.6445 8.31408 11.0157 8.86249 11.1695 9.51662C11.5288 11.0448 10.5651 12.5711 9.01697 12.9258C7.46891 13.2804 5.92274 12.3291 5.56348 10.8009Z" stroke="black" stroke-width="0.5" />
        <path d="M20.77 10.8009C20.8033 10.6592 20.8252 10.5175 20.8364 10.3768L16.1847 7.92847C15.6889 8.31408 15.3177 8.86249 15.1639 9.51662C14.8046 11.0448 15.7684 12.5711 17.3165 12.9258C18.8645 13.2804 20.4107 12.3291 20.77 10.8009Z" stroke="black" stroke-width="0.5" />
      </g>
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 26" fill="none">
      <circle cx="13.5" cy="13" r="13" fill="#BCBBAF" />
      <circle cx="13.5" cy="13" r="12.5" stroke="black" strokeOpacity="0.2" />
      <g opacity="0.7">
        <path d="M8.71484 13C10.2342 13 11.4658 11.7683 11.4658 10.249C11.4658 8.72971 10.2342 7.49805 8.71484 7.49805C7.19553 7.49805 5.96387 8.72971 5.96387 10.249C5.96387 11.7683 7.19553 13 8.71484 13Z" fill="black" />
        <path d="M18.2846 13C16.7653 13 15.5336 11.7683 15.5336 10.249C15.5336 8.72971 16.7653 7.49805 18.2846 7.49805C19.8039 7.49805 21.0356 8.72971 21.0356 10.249C21.0356 11.7683 19.8039 13 18.2846 13Z" fill="black" />
        <path d="M8.72369 16.7621C8.17141 16.7621 7.72369 17.2099 7.72369 17.7621C7.72369 18.3144 8.17141 18.7621 8.72369 18.7621L18.2758 18.7621C18.828 18.7621 19.2758 18.3144 19.2758 17.7621C19.2758 17.2099 18.828 16.7621 18.2758 16.7621L8.72369 16.7621Z" fill="black" />
        <path d="M8.71484 13C10.2342 13 11.4658 11.7683 11.4658 10.249C11.4658 8.72971 10.2342 7.49805 8.71484 7.49805C7.19553 7.49805 5.96387 8.72971 5.96387 10.249C5.96387 11.7683 7.19553 13 8.71484 13Z" stroke="black" stroke-width="0.5" />
        <path d="M18.2846 13C16.7653 13 15.5336 11.7683 15.5336 10.249C15.5336 8.72971 16.7653 7.49805 18.2846 7.49805C19.8039 7.49805 21.0356 8.72971 21.0356 10.249C21.0356 11.7683 19.8039 13 18.2846 13Z" stroke="black" stroke-width="0.5" />
        <path d="M8.72369 16.7621C8.17141 16.7621 7.72369 17.2099 7.72369 17.7621C7.72369 18.3144 8.17141 18.7621 8.72369 18.7621L18.2758 18.7621C18.828 18.7621 19.2758 18.3144 19.2758 17.7621C19.2758 17.2099 18.828 16.7621 18.2758 16.7621L8.72369 16.7621Z" stroke="black" stroke-width="0.5" />
      </g>
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 26" fill="none">
      <circle cx="13.334" cy="13" r="13" fill="#389E5A" />
      <circle cx="13.334" cy="13" r="12.5" stroke="black" strokeOpacity="0.2" />
      <path opacity="0.7" d="M7.59101 16.4236C7.5176 16.1181 7.62822 15.8501 7.96071 15.8501H18.7066C19.0391 15.8501 19.1497 16.1181 19.0763 16.4236C18.8804 17.7598 17.2564 20.3431 13.3337 20.3431C9.4109 20.3431 7.78688 17.7598 7.59101 16.4236Z" fill="black" stroke="black" stroke-width="0.5" />
      <g opacity="0.7">
        <path d="M11.2998 9.76025C11.2998 11.2796 10.0681 12.5112 8.54883 12.5112C7.02951 12.5112 5.79785 11.2796 5.79785 9.76025C5.79785 8.24094 7.02951 7.00928 8.54883 7.00928C10.0681 7.00928 11.2998 8.24094 11.2998 9.76025Z" fill="black" />
        <path d="M15.3676 9.76025C15.3676 11.2796 16.5993 12.5112 18.1186 12.5112C19.6379 12.5112 20.8696 11.2796 20.8696 9.76025C20.8696 8.24094 19.6379 7.00928 18.1186 7.00928C16.5993 7.00928 15.3676 8.24094 15.3676 9.76025Z" fill="black" />
        <path d="M11.2998 9.76025C11.2998 11.2796 10.0681 12.5112 8.54883 12.5112C7.02951 12.5112 5.79785 11.2796 5.79785 9.76025C5.79785 8.24094 7.02951 7.00928 8.54883 7.00928C10.0681 7.00928 11.2998 8.24094 11.2998 9.76025Z" stroke="black" stroke-width="0.5" />
        <path d="M15.3676 9.76025C15.3676 11.2796 16.5993 12.5112 18.1186 12.5112C19.6379 12.5112 20.8696 11.2796 20.8696 9.76025C20.8696 8.24094 19.6379 7.00928 18.1186 7.00928C16.5993 7.00928 15.3676 8.24094 15.3676 9.76025Z" stroke="black" stroke-width="0.5" />
      </g>
    </svg>
  ];
  let idx = 1;
  switch (ty) {
    case 'mood':
    case 'condition':
      if (val < 40) {
        idx = 0;
      } else if (val >= 60) {
        idx = 2;
      }
      break;
    case 'relation':
      if (val <= -50) {
        idx = 0;
      } else if (val > 50) {
        idx = 2;
      }
      break;
  }
  return svg_smiley_list[idx];
}

function AgentCard2(props) {
  const { character, onClick, selected, makeNote } = props;

  const exp = character.level.exp;
  let expMax;
  if (character.level.cur <= data_operatorExp.length) {
    expMax = data_operatorExp[character.level.cur - 1].expMax;
  }
  else {
    expMax = '∞';
  }

  const note = makeNote ? makeNote(character) : null;
  return <div className={"figmalist-body2-cardboard-card" + (selected ? " selected" : "")} onClick={onClick}>
    <div className="figmalist-body2-card-header">
      <span className="figmalist-body2-card-header-role"><RoleBadge role={character.role} isLabel={true} hideIcon={true} /></span>
      <span className="figmalist-body2-card-header-name">{L(character.name)}</span>
    </div>
    <div className="figmalist-body2-card-body">
      <div className="figmalist-body2-card-primary">
        <PortraitWrapper2 agent={character} className="portrait-frame-card" inSquad={character.squad !== undefined && character.squad !== null} />
        <div className="figmalist-body2-card-primary-right">
          <div className="figmalist-body2-card-primary-row-2">
            <div className="figmalist-body2-card-primary-row-item">
              <span className="figmalist-body2-card-row-item-label">{L('loc_ui_string_agent_power')}</span>
              <span className="figmalist-body2-card-row-item-value">
                <PowerIndicator character={character} starSize={20} />
              </span>
            </div>
          </div>
          <div className="figmalist-body2-card-primary-divider" />
          <div className="figmalist-body2-card-primary-row-3">
            <div className="figmalist-body2-card-row-item-smily">
              <span className="figmalist-body2-card-row-item-smily-label">{L('loc_ui_string_agent_stamina')}</span>
              <AgentCard2Smiley ty='condition' val={character.condition} />
              <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("condition", character.condition)}>{character.condition.toFixed(0)}</span>
            </div>
            <div className="figmalist-body2-card-row-divider" />
            <div className="figmalist-body2-card-row-item-smily">
              <span className="figmalist-body2-card-row-item-smily-label">{L('loc_ui_string_agent_mood')}</span>
              <AgentCard2Smiley ty='mood' val={character.mood} />
              <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("mood", character.mood)}>{character.mood.toFixed(0)}</span>
            </div>
            <div className="figmalist-body2-card-row-divider" />
            <div className="figmalist-body2-card-row-item-smily">
              <span className="figmalist-body2-card-row-item-smily-label">{L('loc_ui_string_agent_relation')}</span>
              <AgentCard2Smiley ty='relation' val={character.relation} />
              <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("relation", character.relation)}>{character.relation.toFixed(0)}</span>
            </div>
          </div>
          <div className="figmalist-body2-card-right-secondary">
            <div className="figmalist-body2-card-secondary-row">
              <span className="figmalist-body2-card-secondary-row-item-label">{L('Most Recent Enemy Killed:')}</span>
              <span className="figmalist-body2-card-secondary-row-item-value">{character.level.cur}</span>
            </div>
            <div className="figmalist-body2-card-secondary-row">
              <span className="figmalist-body2-card-secondary-row-item-label">{L('Most Recent Damage Dealt to Enemy:')}</span>
              <span className="figmalist-body2-card-secondary-row-item-value">{expMax}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="figmalist-body2-card-secondary">
        <div className="figmalist-body2-card-notes-title">{L('loc_ui_string_agent_note')}</div>
        <FigmaCard2Note note={note} />
      </div>
    </div>
  </div>;
}

function FigmaCardboard(props) {
  const { characters, selected, onSelect, filter, makeNote } = props;
  let filterFunc = () => true;
  if (filter !== 'entire') {
    filterFunc = (character) => character.role === filter;
  }

  return <div className="figmalist-body2-cardboard">
    {characters.filter(filterFunc).map((character, i) => {
      return <AgentCard2 key={i} character={character} onClick={() => onSelect(character)} selected={selected === character} makeNote={makeNote} />;
    })}
  </div>;
}

function FigmaCardboardFilters(props) {
  const { characters, selected, onSelect } = props;
  const tabs = ['entire', 'pointman', 'sharpshooter', 'medic', 'vanguard', 'breacher'];
  return <div className="figmalist-body2-tabs">
    {tabs.map((tab, i) => {
      let length = characters.filter((character) => character.role === tab).length;
      if (tab === 'entire') {
        length = characters.length;
      }
      return <FigmaCardboardFilterButton key={i} role={tab} length={length} selected={selected === tab} onClick={() => onSelect(tab)} />;
    })}
  </div>
}

function FigmaCardboardFilterButton(props) {
  const { role, length, selected, onClick } = props;
  return <div {...soundprops(null)} className={"figmalist-body2-tab" + (selected ? " selected" : "")} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="85" viewBox="0 0 69 85" fill="none">
      <path className="figmalist-body2-tab-svg-1" d="M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z" fill="#3D2723" />
      <mask id="path-2-inside-1_810_49468" fill="white">
        <path className="figmalist-body2-tab-svg-2" d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" />
      </mask>
      <path className="figmalist-body2-tab-svg-3" d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" fill="#331F1C" />
      <path className="figmalist-body2-tab-svg-4" d="M0 7C0 2.02944 4.02944 -2 9 -2H69V2H9C6.23858 2 4 4.23858 4 7H0ZM69 87H9C4.02944 87 0 82.9706 0 78H4C4 80.7614 6.23858 83 9 83H69V87ZM9 87C4.02944 87 0 82.9706 0 78V7C0 2.02944 4.02944 -2 9 -2V2C6.23858 2 4 4.23858 4 7V78C4 80.7614 6.23858 83 9 83V87ZM69 0V85V0Z" fill="#2F1D1A" mask="url(#path-2-inside-1_810_49468)" />
    </svg>
    <div className="figmalist-body2-tab-inner">
      <div className="figmalist-body2-tab-icon">{roleIcons[role]}</div>
      <span className="figmalist-body2-tab-label">{length}</span>
    </div>
  </div>;
}
