import source from '../downloaded/traits.json' assert { type: 'json' };


export const traits = [];
for (const row of source) {
  let { key, name, conflicts, decision, bravery, focus, reaction, toughness, precision, trait_perk_group, ingame, descr } = row;

  traits.push({
    key,
    name,
    conflicts: conflicts.split(','),
    ingame,
    descr,
    trait_perk_group,

    stats2: {
      decision: +decision,
      bravery: +bravery,
      focus: +focus,
      reaction: +reaction,
      toughness: +toughness,
      precision: +precision,
    },
  });
}

export function find(key) {
  return traits.find((t) => t.key === key);
}
