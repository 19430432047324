import { World } from './world.mjs';
import { v2 } from './v2.mjs';
import {
  team0_tmpl_agent_ar_high,
  team0_tmpl_indoor_ar_high,
} from './presets.mjs';
import { Rng } from './rand.mjs';
import { opts } from './opts.mjs';
import { overlapped } from './geom.mjs';
import { ENTITY_CONFIG_TMPL } from './opts.mjs';

const minLen = 400;
const maxLen = 700;

const areaMinLen = 80;
const areaMaxLen = 150;

function fitToGrid(x, grid_size) {
  return Math.floor((x + grid_size / 2) / grid_size) * grid_size;
}

function edge_intersect(a, b) {
  return v2.intersect(a.from, a.to, b.from, b.to);
}

function intersection(edge1, edge2) {
  const d = (edge1.from.x - edge1.to.x) * (edge2.from.y - edge2.to.y) - (edge1.from.y - edge1.to.y) * (edge2.from.x - edge2.to.x);
  const pre = edge1.from.x * edge1.to.y - edge1.from.y * edge1.to.x;
  const post = edge2.from.x * edge2.to.y - edge2.from.y * edge2.to.x;
  const x = (pre * (edge2.from.x - edge2.to.x) - post * (edge1.from.x - edge1.to.x)) / d;
  const y = (pre * (edge2.from.y - edge2.to.y) - post * (edge1.from.y - edge1.to.y)) / d;
  return new v2(x, y);
}

function edgesOfArea(area) {
  const tl = area.pos.sub(area.extent);
  const tr = area.pos.add(new v2(area.extent.x, -area.extent.y));
  const bl = area.pos.add(new v2(-area.extent.x, area.extent.y));;
  const br = area.pos.add(area.extent);
  return [{ from: tl, to: tr }, { from: bl, to: br }, { from: tl, to: bl }, { from: tr, to: br }];
}

function pointsOfArea(area) {
  const offset = new v2(3, 3);
  const offset0 = new v2(3, -3)
  const tl = area.pos.sub(area.extent).sub(offset);
  const tr = area.pos.add(new v2(area.extent.x, -area.extent.y)).add(offset0);
  const bl = area.pos.add(new v2(-area.extent.x, area.extent.y)).sub(offset0);;
  const br = area.pos.add(area.extent).add(offset);
  return [tl, tr, br, bl];
}

function createAreas(width, height, cnt, rng) {
  let areas = [];
  const offset = new v2(width, height).mul(0.5);
  let limit = 0;

  const startArea = { pos: new v2(width / 2, 50).sub(offset), extent: v2.unit(50), heading: 0 };
  areas.push({ ...startArea, edge: edgesOfArea(startArea) });

  const endArea = { pos: new v2(width / 2, height - 50).sub(offset), extent: v2.unit(50), heading: 0 };
  areas.push({ ...endArea, edge: edgesOfArea(endArea) })
  while (areas.length <= cnt) {
    if (limit > 100) break;
    limit++;

    const extent = v2.unit(rng.integer(areaMinLen, areaMaxLen));
    const pos = new v2(rng.integer(extent.x, width - extent.x), rng.integer(extent.y + 150, height - extent.y - 150)).sub(offset);
    const area = { pos, extent, heading: 0 }

    if (areas.find((area0) =>
      overlapped(pointsOfArea(area), pointsOfArea(area0)) ||
      (area.pos.y <= area0.pos.y && area.pos.y + area.extent.y >= area0.pos.y) ||
      (area.pos.y >= area0.pos.y && area.pos.y - area.extent.y <= area0.pos.y) ||
      (area0.pos.y <= area.pos.y && area0.pos.y + area0.extent.y >= area.pos.y) ||
      (area0.pos.y >= area.pos.y && area0.pos.y - area0.extent.y <= area.pos.y)
    )) {
      continue;
    }

    areas.push({ ...area, edge: edgesOfArea(area) });
  }


  areas = areas.sort((a, b) => {
    if (a.pos.y === b.pos.y) {
      return a.pos.x - b.pos.x;
    }
    return a.pos.y - b.pos.y;
  })
  return areas;
}

function createRoads(areas) {
  let roads = [];

  for (let i = 1; i < areas.length; i++) {
    const area_from = areas[i - 1];
    const area_to = areas[i];

    const y = area_from.pos.dist(area_to.pos) / 2;
    const x = 30;

    const pos = area_from.pos.add(area_to.pos).mul(0.5);
    let heading = -area_from.pos.sub(area_to.pos).dir();

    while (heading >= Math.PI * 2) {
      heading -= Math.PI * 2;
    }
    while (heading < 0) {
      heading += Math.PI * 2;
    }

    const extent = new v2(x, y);

    const extent0 = new v2(x, -y);
    if ((Math.PI / 4 < heading && heading <= Math.PI / 4 * 5)) {
      roads.push({
        left: {
          from: pos.add(extent0).rot(pos, heading),
          to: pos.add(extent).rot(pos, heading),
        },
        right: {
          from: pos.sub(extent).rot(pos, heading),
          to: pos.sub(extent0).rot(pos, heading),
        },
        heading,
        pos,
      });
    } else {
      roads.push({
        left: {
          from: pos.sub(extent).rot(pos, heading),
          to: pos.sub(extent0).rot(pos, heading),
        },
        right: {
          from: pos.add(extent0).rot(pos, heading),
          to: pos.add(extent).rot(pos, heading),
        },
        heading,
        pos,
      });
    }
  }
  return roads;
}

function cutEdges(area_from, area_to, road, type) {
  let edges = [];

  const std = type === "from" ? area_from : area_to;
  for (let areaObs of std.edge) {
    let flag = [null, null];
    if (edge_intersect(road.left, areaObs)) {
      flag[0] = intersection(road.left, areaObs);
      road.left[type] = flag[0];
    }
    if (edge_intersect(road.right, areaObs)) {
      flag[1] = intersection(road.right, areaObs);
      road.right[type] = flag[1];
    }
    if (flag[0] === null && flag[1] === null) {
      edges.push(areaObs);
    } else if (flag[1] === null) {
      if ((type === "to" && area_from.pos.x < area_to.pos.x)) {
        edges.push({ from: flag[0], to: areaObs.to });
      }
      else {
        edges.push({ from: areaObs.from, to: flag[0] });
      }
    } else if (flag[0] === null) {
      if ((type === "from" && area_from.pos.x < area_to.pos.x)) {
        edges.push({ from: areaObs.from, to: flag[1] });
      }
      else {
        edges.push({ from: flag[1], to: areaObs.to });
      }
    } else {
      edges.push({ from: areaObs.from, to: flag[0] })
      edges.push({ from: flag[1], to: areaObs.to });
    }
  }
  return edges;
}

function preset(cnt, rng) {
  const width = fitToGrid(rng.integer(minLen, maxLen), opts.GRID_SIZE * 2);
  const height = fitToGrid(rng.integer(minLen, maxLen), opts.GRID_SIZE * 2) + 200;

  let areas = createAreas(width, height, cnt, rng);
  for (const area of areas.slice(1)) {
    area.spawn1 = true;
  }
  let roads = createRoads(areas);

  //road 겹치는거
  for (let i = 1; i < areas.length - 1; i++) {
    let road_from = roads[i - 1];
    let road_to = roads[i];

    for (let j = 0; j < 4; j++) {
      const edge = areas[i].edge[j];
      if (edge_intersect(edge, road_from.right) && edge_intersect(edge, road_to.left)) {
        const inter1 = intersection(edge, road_from.right);
        const inter2 = intersection(edge, road_to.left);
        if (inter2.sub(inter1).y < 0) {
          const move = new v2(0, inter1.sub(inter2).y + 2);

          road_to.left = {
            from: road_to.left.from.add(move),
            to: road_to.left.to.add(move)
          };
          road_to.right = {
            from: road_to.right.from.add(move),
            to: road_to.right.to.add(move)
          };
        }
        break;
      }
    }
  }

  for (let i = 0; i < roads.length; i++) {
    const road = roads[i];
    const area_from = areas[i];
    const area_to = areas[i + 1];

    areas[i].edge = cutEdges(area_from, area_to, road, "from");
    areas[i + 1].edge = cutEdges(area_from, area_to, road, "to");
  }

  return { width, height, areas, roads };
}

export function preset_maze(opts) {
  const seed = opts?.seed ?? Rng.randomseed();
  const rng = new Rng(seed);
  const { width, height, areas, roads } = preset(3, rng);

  const world = {
    width,
    height,
    simover_rule: 'eliminate',
  };

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,
    ...team0_tmpl_indoor_ar_high,

    allow_fire_control: true,

    firearm_range: 500,
    default_rule: 'explore',
    perk_targetpref_low: true,
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1,
    armor: 1,
    default_rule: 'idle',
    vis_range: 300,
    allow_crawl: false,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  const offsetFromOrigin = function (edgeFrom, edgeTo, orig, offset) {
    // TODO: 수직수평 벽 말고 통로에도 적용되려면 임의의 각에서도 돌게 일반화해야한다

    // EPSILON이 너무 작으면 고장나길래 손으로 적당히 찾은 매직넘버;
    const EPSILON = Number.EPSILON * 1000;

    if (Math.abs(edgeFrom.x - edgeTo.x) < EPSILON) {
      if (orig.x < edgeFrom.x) {
        return new v2(+offset, 0);
      } else {
        return new v2(-offset, 0);
      }
    } else if (Math.abs(edgeFrom.y - edgeTo.y) < EPSILON) {
      if (orig.y < edgeFrom.y) {
        return new v2(0, +offset);
      } else {
        return new v2(0, -offset);
      }
    }
    return v2.zero();
  }

  const createObstacle = function (edge, area_pos) {
    const wall_thickness = 3;
    const y = edge.from.dist(edge.to) / 2;
    const offset = offsetFromOrigin(edge.from, edge.to, area_pos, wall_thickness);
    const pos = edge.from.add(edge.to).mul(0.5).add(offset);
    const heading = -edge.from.sub(edge.to).dir();

    // 방 코너로 agent가 안 튀어나오게 적당히 찾은 매직넘버;
    const CORNER_CLOSE_EPSILON = 2;

    return { pos, extent: new v2(wall_thickness, y + CORNER_CLOSE_EPSILON), ty: "full", heading };
  }

  let obstacles = [];
  for (let i = 0; i < areas.length; i++) {
    let area = areas[i];
    for (const edge of area.edge) {
      obstacles.push(createObstacle(edge, area.pos));
    }
    if (i === 0) {
      continue;
    }
    obstacles.push({
      pos: area.pos,
      extent: area.extent.sub(v2.unit(10)),
      ty: 'random',
      random: { ty: 'full', count: 10 }
    });
    obstacles.push({
      pos: area.pos,
      extent: area.extent.sub(v2.unit(10)),
      ty: 'random',
      random: { ty: 'half', count: 8, extent: new v2(5, 5), }
    })
  }

  for (const road of roads) {
    const { left, right } = road;
    obstacles.push(createObstacle(left, road.pos));
    obstacles.push(createObstacle(right, road.pos));

    const pos = left.from.add(left.to).add(right.from).add(right.to).mul(0.25);
    const extent = new v2(30, left.to.sub(left.from).y < 0 ? -left.to.sub(left.from).y : left.to.sub(left.from).y);

    obstacles.push({
      pos,
      extent: extent.sub(v2.unit(5)),
      heading: road.heading,
      ty: 'random',
      random: {
        ty: 'half',
        extent: new v2(3, 5),
        count: 10,
      }
    })
  }

  let entities = [];
  for (let i = 0; i < 4; i++) {
    const entity = { ...team0_tmpl_ar, spawnarea: 0 };
    if (i !== 0) {
      entity.leader = 0;
    }
    entities.push(entity);
  }

  for (let i = 0; i < 5; i++) {
    entities.push({ ...team1_tmpl, spawnarea: rng.integer(1, areas.length - 1) });
  }

  let objects = [];

  if (opts?.object) {
    areas[1].triggers = [
      {
        condition: 'enter1',
        conditiontarget: { ty: 'entity', team: 0 },

        action: 'spawn_object',
        actionarea: 1,
      }
    ];
  }

  return {
    world,

    entities,
    spawnareas: areas,
    obstacle_specs: obstacles,
    objects,

    goals: [],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ]
  };
}
