import { items } from './data/google/processor/data_items.mjs'

export const utility_none = {
  key: 'utility_none',

  utility_name: '없음',
  utility_cost: 0,
  utility_rate: 0,
};

export const utility_healthpack_small = {
  key: 'utility_healthpack_small',

  utility_name: '소형 힐팩',
  utility_cost: 1000,
  utility_rate: 1,

  heal: {
    heal_amount: 0.3, // 최대 체력 비율로 변경
    heal_duration: 2,
  }
}

export const utility_healthpack_big = {
  key: 'utility_healthpack',

  utility_name: '대형 힐팩',
  utility_cost: 2000,
  utility_rate: 2,

  heal: {
    heal_amount: 0.5, // 최대 체력 비율로 변경
    heal_duration: 3,
  }
}

export const utilities = [
  utility_none,
  utility_healthpack_small,
  utility_healthpack_big,
];
