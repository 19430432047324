import source from '../downloaded/agentIntegrity.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { idx, min_condition, max_condition, name, weekly_trust } = row;
  list.push({
    idx,
    min_condition,
    max_condition,
    name,
    weekly_trust: parseInt(weekly_trust),
  });
}

export function getIntegrityData(integrity) {
  const data = list.find((e) => e.min_condition <= integrity && e.max_condition > integrity);
  return data ?? list[0];
}
