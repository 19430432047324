const raw = `0	Abraham Richer	2	9	6	11	5	6	19	100	11	11	12	9	8	2	2	800	평균보다 조금 못 하는 AR 사수
1	Aewol Ryu	2	11	11	17	9	8	20	95	15	12	10	8	12	2	2	1600	좀 잘 하지만, 그보다 꽤 비싼 AR 사수
2	Akari Risekawa	3	10	14	15	8	8	18	90	14	6	8	10	11	1	2	1000	이동, 조준 속도가 느린 AR 사수. DMR 적성 조금 있음
3	Antonio Richitenstein	2	8	10	12	11	5	20	110	18	11	6	11	3	1	3	1000	지향 조준이 정확하지만 반격당했을때 조준이 많이 벌어지는 AR 사수
4	Arlado Reveny	2	6	10	15	6	4	18	135	6	4	4	8	3	2	2	500	AR은 그럴듯하게 쏘는 사격장 죽돌이 민간인(이력서를 받으면 안 되었음)
5	Smith Matthew Giovanni	2	6	6	8	14	10	19	100	11	9	13	10	13	1	2	1000	무난한 SMG 사수
6	Shin "Massacre" Gao	2	5	6	10	16	11	20	100	12	10	14	17	12	2	3	1100	기술은 좋은데, 물몸인 SMG 사수
7	Sadik Mahin Gambhir	2	8	9	8	15	10	19	105	6	13	12	15	15	2	2	1250	지향 조준이 부정확하지만, 교전중 사격은 안정적인 SMG 사수
8	Su-Min Go	2	9	11	10	18	7	21	100	17	11	15	15	9	2	2	1800	정예, 비싼 SMG 사수
9	Sayn Mupo Garaja	1	8	7	11	12	9	22	105	11	10	8	9	15	2	2	1000	체격이 작은 SMG 사수
10	Strong Guts	2	6	6	8	9	10	22	110	6	7	6	8	14	2	3	1100	사격은 별로인데 빠르고 맷집이 좋은 SG 사수
11	Saaya Gotoh	1	11	12	9	8	13	20	85	16	8	11	8	11	2	2	1700	지향 사격이 매우 정확한 SG 사수. 비쌈
12	Dr. Martin Rin	2	9	13	10	7	6	21	100	13	11	15	13	9	1	2	1400	무난하고 좀 비싼 DMR 사수
13	Daniel "Mustang" Reinhart	2	16	17	6	8	10	20	95	12	12	10	8	13	2	1	1500	DMR도 SR도 잘 다룸. 비쌈.
14	Dollores Michael Raam	2	13	16	7	6	5	19	130	9	14	15	12	15	3	2	1200	사격이 꽤 정밀하지만 단점이 많은 DMR 사수
15	Sverte Riffe	2	15	5	6	9	7	21	120	11	16	13	10	8	1	1	1250	행동이 빠르고 성급한 SR 사수
16	Slark Romme	2	11	9	7	7	4	18	130	8	11	10	8	5	3	3	850	능력이 전반적으로 낮고 그나마 SR을 쥐여줄만한 사수
17	Suzy Ranchester	2	15	11	11	8	5	19	90	8	11	16	9	4	2	2	1350	평균적인 SR 사수
18	"Joker"	2	14	16	14	12	11	21	105	12	14	11	9	14	2	2	1950	뭘 시켜도 대충 잘 하지만 비쌈
19	Test Subject #37	2	8	7	8	8	7	19	115	8	8	9	9	8	1	2	750	일반부대 신병 수준
20	Test Subject #66	2	8	6	7	9	8	19	120	6	10	8	9	6	3	1	750	일반부대 신병 수준 2`;

export const agents = [];

for (const row of raw.split('\n')) {
  const columns = row.split('\t');
  if (columns.length === 0) {
    continue;
  }
  for (let i = 0; i < columns.length; i++) {
    if (!isNaN(columns[i])) {
      columns[i] = 0|columns[i];
    }
  }
  const [id, name, stat_size, fa0, fa1, fa2, fa3, fa4,
    stat_movespeed,
    stat_dashdist,
    stat_aimvar_hold,
    stat_aimvar_decay_per_tick,
    stat_aimvar_hold_max,
    stat_aimvar_incr_per_shoot,
    stat_aimvar_incr_per_hit,
    stat_aim_fire_thres,
    stat_aim_reload,
    cost,
    descr,
  ] = columns;

  agents.push({
    id,
    name,
    descr,
    cost,

    stat_size,

    stat_firearm_level: [fa0, fa1, fa2, fa3, fa4],

    stat_movespeed,
    stat_dashdist,
    stat_aimvar_hold,
    stat_aimvar_decay_per_tick,
    stat_aimvar_hold_max,
    stat_aimvar_incr_per_shoot,
    stat_aimvar_incr_per_hit,
    stat_aim_fire_thres,
    stat_aim_reload,
  });
}
