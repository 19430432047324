import source from '../downloaded/agentTrust.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { idx, more_or_equal, less, name, income_multiplier } = row;
  list.push({
    idx,
    min_trust: more_or_equal,
    max_trust: less,
    name,
    income_multiplier: parseFloat(income_multiplier),
  });
}

export function getTrustData(trust) {
  const data = list.find((e) => e.min_trust <= trust && e.max_trust > trust);
  return data ?? list[0];
}
