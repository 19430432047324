import source from '../downloaded/shopItemGroup.json' assert { type: 'json' };

export const shopItemGroups = [];
for (const row of source) {
  const { id, always, pick_one_0, pick_one_1 } = row;

  shopItemGroups.push({
    id,
    always: always?.split(',').map(s => s.trim()) ?? [],
    pick_one_0: pick_one_0?.split(',').map(s => s.trim()) ?? [],
    pick_one_1: pick_one_1?.split(',').map(s => s.trim()) ?? [],
  });
}
