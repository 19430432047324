import source from '../downloaded/missionObjective.json' assert { type: 'json' };

export const missionObjectives = [];
for (const row of source) {
  let { key, condition, condition_value, desc } = row;

  missionObjectives.push({
    key,
    condition,
    condition_value,
    desc,
  });
}
