import sourceEvents from '../downloaded/events.json' assert { type: 'json' };
import sourceEventNotes from '../downloaded/eventNotes.json' assert { type: 'json' };

import { modifiers } from './data_modifiers.mjs';

export const events = []
for (const row of sourceEvents) {
  let { num, choice, ty, cost, amount, length, modifier, action, title, remark } = row;
  modifier = modifier ? modifier.split(', ') : null;
  events.push({
    num: parseInt(num),
    choice, ty, cost: parseInt(cost),
    amount: parseInt(amount), length: parseInt(length),
    modifier,
    action, title, remark
  });

  if (modifier) {
    for (const m of modifier) {
      if (!modifiers[m]) {
        throw new Error(m, 'no data');
      }
    }
  }
}

export const eventNotes = [];
for (const row of sourceEventNotes) {
  let { num, group, title, desc } = row;
  num = parseInt(num);
  eventNotes.push({ num, title, group, desc });
}
