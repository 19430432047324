import { ENTITY_CONFIG_TMPL } from './opts.mjs';
/* eslint-disable */
import {
  tmpl_firearm_dmr_t3,
  tmpl_firearm_hg_t5,

  tmpl_firearm_ar_enemy_t5,
  tmpl_firearm_ar_t1,
  tmpl_firearm_ar_t2,
  tmpl_firearm_ar_t3,
  tmpl_firearm_ar_t4,
  tmpl_firearm_ar_t5,

  tmpl_firearm_sg_t1,
  tmpl_firearm_sg_t2,
  tmpl_firearm_sg_t3,
  tmpl_firearm_sg_t4,
  tmpl_firearm_sg_t5,
} from './presets_firearm.mjs';
/* eslint-enable */

// 화기 template 시작

// 아군 에이전트 정의

// 아군 에이전트 방탄복
// 낮은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const tmpl_bulletproof_low = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',

  armor: 33,
};

// 중간 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const tmpl_bulletproof_mid = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',

  armor: 66,
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 3회 더 버티게 해줍니다.
export const tmpl_bulletproof_high = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',

  armor: 99,
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 4회 더 버티게 해줍니다.
export const tmpl_bulletproof_highend = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',

  armor: 100,
};




// 중간 수준의 dmr 사용하는 에이전트
export const team0_tmpl_agent_dmr_mid = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low,
  ...tmpl_firearm_dmr_t3,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 높은 수준의 sg 사용하는 에이전트
export const team0_tmpl_agent_sg_high = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_mid,
  ...tmpl_firearm_sg_t1,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 낮은 수준의 ar 사용하는 에이전트
export const team0_tmpl_agent_ar_low = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_firearm_ar_t5,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


// 중간 수준의 ar 사용하는 에이전트
export const team0_tmpl_agent_ar_mid = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_mid,
  ...tmpl_firearm_ar_t3,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 높은 수준의 ar 사용하는 에이전트
export const team0_tmpl_agent_ar_high = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_mid,
  ...tmpl_firearm_ar_t1,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

export const team0_tmpl_agent_hg_t1 = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_firearm_hg_t5,
  life: 100,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 실내 프리셋에서의 방탄복 수준 정의

// 낮은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const tmpl_bulletproof_low_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  allow_crawl: false,

  life: 100,
  armor: 33,
};

// 중간 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const tmpl_bulletproof_mid_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  allow_crawl: false,

  life: 100,
  armor: 66,
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 3회 더 버티게 해줍니다.
export const tmpl_bulletproof_high_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  allow_crawl: false,

  life: 100,
  armor: 99,
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 4회 더 버티게 해줍니다.
export const tmpl_bulletproof_highend_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  allow_crawl: false,

  life: 100,
  armor: 100,
};


// 낮은 스펙의 AR. 레퍼런스는 GAU-5입니다.
export const team0_tmpl_indoor_ar_low = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low_indoor,

  // 피카티니 레일이 없음. 광학장비를 달수 없으니 rot_cap 값을 높여둡니다.
  firearm_aimvar_incr_rot_cap: 0.2,

  firearm_ammo_max: 30,
  firearm_range: 380,
  firearm_aimvar_mult: 1.5,

  firearm_shoot_pattern: [0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.75,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_penetrate: 33,
  firearm_projectile_aimvar: Math.PI / 224,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.4,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 스펙의 AR 레퍼런스는 M16A4 MWS 입니다.
export const team0_tmpl_indoor_ar_mid = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low_indoor,

  firearm_aimvar_incr_rot_cap: 0.15,

  firearm_ammo_max: 30,
  firearm_range: 400,
  firearm_aimvar_mult: 1.2,

  firearm_shoot_pattern: [0.15, 0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.7,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_penetrate: 33,
  firearm_projectile_aimvar: Math.PI / 248,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.2,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.6,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 스펙의 AR 레퍼런스는 HK416 입니다.
export const team0_tmpl_indoor_ar_high = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_mid_indoor,

  firearm_aimvar_incr_rot_cap: 0.09,

  firearm_ammo_max: 30,
  firearm_range: 400,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.1],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_penetrate: 33,
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.75,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


export const team0_tmpl_indoor_sg = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low_indoor,
  firearm_aimvar_incr_rot_cap: 0.05,

  firearm_ammo_max: 7,
  firearm_range: 200,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.8,

  firearm_projectile_per_shoot: 9,
  firearm_projectile_damage: 35,
  firearm_projectile_penetrate: 35,
  firearm_projectile_aimvar: Math.PI / 32,

  firearm_reload_duration: 5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 1,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

export const team0_tmpl_indoor_smg = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low_indoor,
  firearm_aimvar_incr_rot_cap: 0.05,

  firearm_ammo_max: 35,
  firearm_range: 250,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.125,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 23,
  firearm_projectile_penetrate: 23,
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

export const team0_tmpl_indoor_dmr = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_bulletproof_low_indoor,
  ...tmpl_firearm_dmr_t3,
  firearm_aimvar_incr_rot_cap: 0.1,

  aim_samples_fire_thres: 0.9,

  firearm_additional_hit_prob: 0.1,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};



// 여기서 부터는 적 에이전트에 대한 화기 설정입니다.
// 낮은 수준의 ar 사용하는 적 에이전트
export const enemy_tmpl_agent_ar_low = {
  ...ENTITY_CONFIG_TMPL,
  ...tmpl_firearm_ar_enemy_t5,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};
