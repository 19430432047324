export const presets = [
  {
    "name": "BPP_Woodenplaet_Vr2_C_1",
    "fullname": "/Game/Environment/SM_BP/BPP_Woodenplaet_Vr2.BPP_Woodenplaet_Vr2_C",
    "extent": [
      114.145206,
      172.32309,
      50
    ]
  },
  {
    "name": "BPP_Bargainte_Vr1_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Bargainte_Vr1_H.BPP_Bargainte_Vr1_H_C",
    "extent": [
      140.959301,
      106.836061,
      51.372748
    ]
  },
  {
    "name": "BPP_Barrier_Vr3_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr3_H.BPP_Barrier_Vr3_H_C",
    "extent": [
      389.048469,
      105.789799,
      59.433479
    ]
  },
  {
    "name": "BPP_Barrier_Vr4_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr4_F.BPP_Barrier_Vr4_F_C",
    "extent": [
      334.275369,
      225.46339,
      140.539782
    ]
  },
  {
    "name": "BPP_Barrier_Vr2_FF_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr2_F.BPP_Barrier_Vr2_F_C",
    "extent": [
      166.603407,
      386.100724,
      72.899786
    ]
  },
  {
    "name": "BPP_Barrier_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr1_H.BPP_Barrier_Vr1_H_C",
    "extent": [
      535.860055,
      95.386205,
      59.010352
    ]
  },
  {
    "name": "BPP_Barrier_Vr6_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr6_H.BPP_Barrier_Vr6_H_C",
    "extent": [
      302.991749,
      36.707309,
      51.344192
    ]
  },
  {
    "name": "BPP_Barrier_Vr5_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Barrier_Vr5_H.BPP_Barrier_Vr5_H_C",
    "extent": [
      577.278755,
      52.320666,
      65.031255
    ]
  },
  {
    "name": "BPP_Box_Vr4_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Box_Vr4_FF.BPP_Box_Vr4_FF_C",
    "extent": [
      196.033706,
      168.578604,
      88.174943
    ]
  },
  {
    "name": "BPP_Box_Vr5_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Box_Vr5_FF.BPP_Box_Vr5_FF_C",
    "extent": [
      181.131296,
      297.870026,
      91.44872
    ]
  },
  {
    "name": "BPP_Box_Vr6_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Box_Vr6_HH.BPP_Box_Vr6_HH_C",
    "extent": [
      245.912262,
      242.774197,
      50.756634
    ]
  },
  {
    "name": "BPP_Car_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr1_FF.BPP_Car_Vr1_FF_C",
    "extent": [
      379.29115,
      353.633225,
      125.332093
    ]
  },
  {
    "name": "BPP_Car_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr2_HH.BPP_Car_Vr2_HH_C",
    "extent": [
      198.76493,
      261.973605,
      64.253658
    ]
  },
  {
    "name": "BPP_Car_Vr3_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr3_FF.BPP_Car_Vr3_FF_C",
    "extent": [
      179.000876,
      259.605535,
      117.665571
    ]
  },
  {
    "name": "BPP_Car_Vr4_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr4_HH.BPP_Car_Vr4_HH_C",
    "extent": [
      126.108778,
      276.804774,
      61.619603
    ]
  },
  {
    "name": "BPP_Cementbag_Vr1_C_1",
    "fullname": "/Game/Environment/BB_BP/BPP_Cementbag_Vr1_HH.BPP_Cementbag_Vr1_HH_C",
    "extent": [
      44.622653,
      67.074859,
      34.002502
    ]
  },
  {
    "name": "BPP_Cementbag_Vr2_C_1",
    "fullname": "/Game/Environment/BB_BP/BPP_Cementbag_Vr2_HH.BPP_Cementbag_Vr2_HH_C",
    "extent": [
      35.254514,
      54.261251,
      34.838162
    ]
  },
  {
    "name": "BPP_Propanetank_Vr1_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Propanetank_Vr1_HH.BPP_Propanetank_Vr1_HH_C",
    "extent": [
      70.44362,
      52.959691,
      65
    ]
  },
  {
    "name": "BPP_Propanetank_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Propanetank_Vr2_FF.BPP_Propanetank_Vr2_FF_C",
    "extent": [
      194.061903,
      155.704619,
      97.164255
    ]
  },
  {
    "name": "BPP_Sandbags_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Sandbags_Vr1_HH.BPP_Sandbags_Vr1_HH_C",
    "extent": [
      514.262329,
      83.468652,
      59.433479
    ]
  },
  {
    "name": "BPP_Sandbags_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Sandbags_Vr2_HH.BPP_Sandbags_Vr2_HH_C",
    "extent": [
      274.080762,
      146.247665,
      57.297372
    ]
  },
  {
    "name": "BPP_SM_Barrel_Vr1_2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_SM_Barrel_Vr1_HH.BPP_SM_Barrel_Vr1_HH_C",
    "extent": [
      117.898271,
      103.88466,
      63.262844
    ]
  },
  {
    "name": "BPP_SmallCar_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_SmallCar_Vr1_FF.BPP_SmallCar_Vr1_FF_C",
    "extent": [
      202.262425,
      87.078718,
      84.309972
    ]
  },
  {
    "name": "BPP_SmallCar_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_SmallCar_Vr2_FF.BPP_SmallCar_Vr2_FF_C",
    "extent": [
      202.262425,
      87.078718,
      84.309972
    ]
  },
  {
    "name": "BPP_Spool_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Spool_Vr1_FF.BPP_Spool_Vr1_FF_C",
    "extent": [
      124.628744,
      168.806949,
      84.067347
    ]
  },
  {
    "name": "BPP_Spool_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Spool_Vr2_FF.BPP_Spool_Vr2_FF_C",
    "extent": [
      94.833542,
      133.234043,
      80.457093
    ]
  },
  {
    "name": "BPP_Tire_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Tire_Vr1_HH.BPP_Tire_Vr1_HH_C",
    "extent": [
      135.682326,
      117.210725,
      61.912087
    ]
  },
  {
    "name": "BPP_Trash_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Trash_HH.BPP_Trash_HH_C",
    "extent": [
      132.403184,
      102.010095,
      23.259917
    ]
  },
  {
    "name": "BPP_Trash_Vr1_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_Trash_Vr1_HH.BPP_Trash_Vr1_HH_C",
    "extent": [
      296.823575,
      146.822444,
      37.698529
    ]
  },
  {
    "name": "BPP_Trashbag_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Trashbag_Vr2_FF.BPP_Trashbag_Vr2_FF_C",
    "extent": [
      114.238175,
      120.787591,
      71.780003
    ]
  },
  {
    "name": "BPP_Trashbox_5_C_1",
    "fullname": "/Game/Environment/BB_BP/BPP_Trashbox_5_HH.BPP_Trashbox_5_HH_C",
    "extent": [
      76.28321,
      75.639926,
      57.656822
    ]
  },
  {
    "name": "BPP_Trashbox_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Trashbox_Vr1_FF.BPP_Trashbox_Vr1_FF_C",
    "extent": [
      68.708067,
      209.903546,
      69.110978
    ]
  },
  {
    "name": "BPP_Trashbox_Vr4_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Trashbox_Vr4_FF.BPP_Trashbox_Vr4_FF_C",
    "extent": [
      192.909001,
      88.695257,
      88.938653
    ]
  },
  {
    "name": "BPP_Trashbox_Vr5_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Trashbox_Vr5_FF.BPP_Trashbox_Vr5_FF_C",
    "extent": [
      193.574318,
      92.002075,
      78.08924
    ]
  },
  {
    "name": "BPP_TrashBox_Vr6_C_4",
    "fullname": "/Game/Environment/BB_BP/BPP_TrashBox_Vr6_FF.BPP_TrashBox_Vr6_FF_C",
    "extent": [
      218.891837,
      117.184922,
      72.997362
    ]
  },
  {
    "name": "BPP_Truck_Vr1_C_3",
    "fullname": "/Game/Environment/BB_BP/BPP_Truck_Vr1_FF.BPP_Truck_Vr1_FF_C",
    "extent": [
      137.308958,
      380.228219,
      132.751343
    ]
  },
  {
    "name": "BPP_Truck_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Truck_Vr2_FF.BPP_Truck_Vr2_FF_C",
    "extent": [
      136.513459,
      410.753897,
      153.765347
    ]
  },
  {
    "name": "BPP_Woodenplaet_Vr1_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Woodenplaet_Vr1_HH.BPP_Woodenplaet_Vr1_HH_C",
    "extent": [
      273.704014,
      101.167533,
      56.961121
    ]
  },
  {
    "name": "BPP_Woodenplaet_Vr2_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Woodenplaet_Vr2_HH.BPP_Woodenplaet_Vr2_HH_C",
    "extent": [
      114.145206,
      172.32309,
      50
    ]
  },
  {
    "name": "BPP_Car_Vr4_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr4_FF.BPP_Car_Vr4_FF_C",
    "extent": [
      78.555862,
      189.441895,
      77.035513
    ]
  },
  {
    "name": "BPP_Car_Vr4_C_3",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr4_FF.BPP_Car_Vr4_FF_C",
    "extent": [
      78.555862,
      189.441895,
      77.035513
    ]
  },
  {
    "name": "BPP_Car_Vr5_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr5_FF.BPP_Car_Vr5_FF_C",
    "extent": [
      78.555862,
      189.441895,
      67.350812
    ]
  },
  {
    "name": "BPP_Car_Vr5_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr5_FF.BPP_Car_Vr5_FF_C",
    "extent": [
      78.555862,
      189.441895,
      67.350812
    ]
  },
  {
    "name": "BPP_Car_Vr6_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr6_FF.BPP_Car_Vr6_FF_C",
    "extent": [
      97.719502,
      231.146571,
      88.037735
    ]
  },
  {
    "name": "BPP_Car_Vr6_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr6_FF.BPP_Car_Vr6_FF_C",
    "extent": [
      97.719502,
      231.146571,
      88.037735
    ]
  },
  {
    "name": "BPP_Car_Vr7_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr7_FF.BPP_Car_Vr7_FF_C",
    "extent": [
      99.537357,
      217.824185,
      97.581996
    ]
  },
  {
    "name": "BPP_Car_Vr7_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr7_FF.BPP_Car_Vr7_FF_C",
    "extent": [
      99.537357,
      217.824185,
      97.581996
    ]
  },
  {
    "name": "BPP_Car_Vr8_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr8_FF.BPP_Car_Vr8_FF_C",
    "extent": [
      93.108454,
      201.259522,
      95.84958
    ]
  },
  {
    "name": "BPP_Car_Vr8_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr8_FF.BPP_Car_Vr8_FF_C",
    "extent": [
      93.108454,
      201.259522,
      95.84958
    ]
  },
  {
    "name": "BPP_Car_Vr9_C_0",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr9_FF.BPP_Car_Vr9_FF_C",
    "extent": [
      123.097506,
      371.605396,
      157.378236
    ]
  },
  {
    "name": "BPP_Car_Vr9_C_2",
    "fullname": "/Game/Environment/BB_BP/BPP_Car_Vr9_FF.BPP_Car_Vr9_FF_C",
    "extent": [
      123.097506,
      371.605396,
      157.378236
    ]
  }
];
