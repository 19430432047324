import { items } from './data/google/processor/data_items.mjs'

const tmpl_firearm_base = {
  firearm_projectile_per_shoot: 1,
  aimvar_incr_per_shoot: 1,
  aimvar_incr_per_hit: 1,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 1200,
  firearm_ty_max_damage: 200,

  /*
  firearm_projectile_damage_prob: [
    { weight: 1, multiplier: 1 },
    { weight: 1, multiplier: 0.3 },
  ],
  */

  movespeed_rules: [
    { reldir: Math.PI * 2, multiplier: 0.5 },
    { reldir: Math.PI / 2, multiplier: 0.8 },
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  requirements: {},

  // sweet spot을 벗어났을 때, 피해량 감소 비율
  firearm_range_penalty_mult: 0.5,
  // 최적 피해량 범위 (sweet spot) 최소값. 0에서 1 사이의 값.
  firearm_range_optimal_min: 0,
  // 최적 피해량 범위 (sweet spot) 최소값. 0에서 1 사이의 값.
  firearm_range_optimal_max: 1,

  firearm_additional_hit_prob: 0,
  firearm_additional_crit_prob: 0,
  firearm_crit_mult: 1,
}

const firearm_aimspeed = {
  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.2 },
    { aimvar: Math.PI * 2, aimspeed: 0.2 },
  ],
};

const tmpl_firearm_ar_base = {
  ...tmpl_firearm_base,
  ...firearm_aimspeed,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 10,
  firearm_reload_duration: 4.5,
  firearm_shoot_pattern_interval_sec: 1.78,
  firearm_shoot_pattern: [],

  firearm_range: 300,
  firearm_ammo_max: 20,

  firearm_range_penalty_mult: 0.5,
  firearm_range_optimal_min: 0,
  firearm_range_optimal_max: 1,
  firearm_projectile_aimvar: 0,

  aimvar_incr_per_hit: 0,
  aimvar_incr_per_shoot: 0,
  aimvar_hold: Math.PI / 10000,
  aimvar_hold_max: Math.PI / 10000,

  aim_samples_fire_thres: 0.25,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.6,

  firearm_additional_crit_prob: 0.05,
  firearm_crit_mult: 2,

  firearm_ty_max_rpm: 500,
};

export const firearm_none = {
  key: 'firearm_none',
  img_key: 'firearm_none',

  firearm_ty: '',
  firearm_name: 'loc_ui_string_common_firearm_none',
  firearm_rate: 0,
  firearm_cost: 0,
};

// 적 전용 AR 화기
export const tmpl_firearm_ar_enemy_t5 = {
  key: 'firearm_ar_t5',
  img_key: 'firearm_ar_t5',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 85,
  firearm_projectile_penetrate: 90,
  firearm_projectile_expectedDamage: 90,
  firearm_projectile_expectedPenetrate: 90,
  firearm_expectedPower: (90 + 90) / 10,

};

// 적 전용 AR 화기
export const tmpl_firearm_ar_enemy_t4 = {
  key: 'firearm_ar_t4',
  img_key: 'firearm_ar_t4',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_4',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 100,
  firearm_projectile_penetrate: 149,
  firearm_projectile_expectedDamage: 100,
  firearm_projectile_expectedPenetrate: 149,
  firearm_expectedPower: (100 + 149) / 10,
};

// 적 전용 AR 화기
export const tmpl_firearm_ar_enemy_t3 = {
  key: 'firearm_ar_t3',
  img_key: 'firearm_ar_t3',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_3',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 115,
  firearm_projectile_penetrate: 208,
  firearm_projectile_expectedDamage: 116,
  firearm_projectile_expectedPenetrate: 208,
  firearm_expectedPower: (116 + 208) / 10,

};

// 적 전용 AR 화기
export const tmpl_firearm_ar_enemy_t2 = {
  key: 'firearm_ar_t2',
  img_key: 'firearm_ar_t2',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_2',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 130,
  firearm_projectile_penetrate: 266,
  firearm_projectile_expectedDamage: 137,
  firearm_projectile_expectedPenetrate: 266,
  firearm_expectedPower: (137 + 266) / 10,
};

// 적 전용 AR 화기
export const tmpl_firearm_ar_enemy_t1 = {
  key: 'firearm_ar_t1',
  img_key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_1',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 145,
  firearm_projectile_penetrate: 325,
  firearm_projectile_expectedDamage: 158,
  firearm_projectile_expectedPenetrate: 325,
  firearm_expectedPower: (158 + 325) / 10,
};


// AR 화기 (5티어 구조)
export const tmpl_firearm_ar_t5 = {
  key: 'firearm_ar_t5',
  img_key: 'firearm_ar_t5',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 170,
  firearm_projectile_penetrate: 140,
  firearm_projectile_expectedDamage: 170,
  firearm_projectile_expectedPenetrate: 140,
  firearm_expectedPower: (170 + 140) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.8 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.8 },
  // ],
};

export const tmpl_firearm_ar_t4 = {
  key: 'firearm_ar_t4',
  img_key: 'firearm_ar_t4',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_4',
  firearm_rate: 4,
  firearm_cost: 400,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 200,
  firearm_projectile_penetrate: 230,
  firearm_projectile_expectedDamage: 200,
  firearm_projectile_expectedPenetrate: 230,
  firearm_expectedPower: (200 + 230) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.1 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_ar_t3 = {
  key: 'firearm_ar_t3',
  img_key: 'firearm_ar_t3',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_3',
  firearm_rate: 3,
  firearm_cost: 1000,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 230,
  firearm_projectile_penetrate: 320,
  firearm_projectile_expectedDamage: 230,
  firearm_projectile_expectedPenetrate: 320,
  firearm_expectedPower: (230 + 320) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.1 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_ar_t2 = {
  key: 'firearm_ar_t2',
  img_key: 'firearm_ar_t2',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_2',
  firearm_rate: 2,
  firearm_cost: 2000,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 260,
  firearm_projectile_penetrate: 410,
  firearm_projectile_expectedDamage: 260,
  firearm_projectile_expectedPenetrate: 410,
  firearm_expectedPower: (260 + 410) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.11 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_ar_t1 = {
  key: 'firearm_ar_t1',
  img_key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 3200,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 290,
  firearm_projectile_penetrate: 500,
  firearm_projectile_expectedDamage: 290,
  firearm_projectile_expectedPenetrate: 500,
  firearm_expectedPower: (290 + 500) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.12 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_ar_civilian_t5 = {
  key: 'firearm_ar_civilian_t5',
  img_key: 'firearm_ar_t5',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_civilian_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 170,
  firearm_projectile_penetrate: 98,
  firearm_projectile_expectedDamage: 170,
  firearm_projectile_expectedPenetrate: 98,
  firearm_expectedPower: (170 + 98) / 10,
};

export const tmpl_firearm_ar_civilian_t4 = {
  key: 'firearm_ar_civilian_t4',
  img_key: 'firearm_ar_t4',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_civilian_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 200,
  firearm_projectile_penetrate: 161,
  firearm_projectile_expectedDamage: 200,
  firearm_projectile_expectedPenetrate: 161,
  firearm_expectedPower: (200 + 161) / 10,
};

export const tmpl_firearm_ar_civilian_t3 = {
  key: 'firearm_ar_civilian_t3',
  img_key: 'firearm_ar_t3',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_civilian_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 230,
  firearm_projectile_penetrate: 224,
  firearm_projectile_expectedDamage: 230,
  firearm_projectile_expectedPenetrate: 224,
  firearm_expectedPower: (230 + 224) / 10,
};

export const tmpl_firearm_ar_civilian_t2 = {
  key: 'firearm_ar_civilian_t2',
  img_key: 'firearm_ar_t2',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_civilian_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 260,
  firearm_projectile_penetrate: 287,
  firearm_projectile_expectedDamage: 260,
  firearm_projectile_expectedPenetrate: 287,
  firearm_expectedPower: (260 + 287) / 10,
};

export const tmpl_firearm_ar_civilian_t1 = {
  key: 'firearm_ar_civilian_t1',
  img_key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_civilian_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 290,
  firearm_projectile_penetrate: 350,
  firearm_projectile_expectedDamage: 290,
  firearm_projectile_expectedPenetrate: 350,
  firearm_expectedPower: (290 + 350) / 10,
};

export const tmpl_firearm_ar_military_t5 = {
  key: 'firearm_ar_military_t5',
  img_key: 'firearm_ar_t5',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_military_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 187,
  firearm_projectile_penetrate: 168,
  firearm_projectile_expectedDamage: 187,
  firearm_projectile_expectedPenetrate: 168,
  firearm_expectedPower: (187 + 168) / 10,
};

export const tmpl_firearm_ar_military_t4 = {
  key: 'firearm_ar_military_t4',
  img_key: 'firearm_ar_t4',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_military_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 220,
  firearm_projectile_penetrate: 276,
  firearm_projectile_expectedDamage: 220,
  firearm_projectile_expectedPenetrate: 276,
  firearm_expectedPower: (220 + 276) / 10,
};

export const tmpl_firearm_ar_military_t3 = {
  key: 'firearm_ar_military_t3',
  img_key: 'firearm_ar_t3',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_military_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 253,
  firearm_projectile_penetrate: 384,
  firearm_projectile_expectedDamage: 253,
  firearm_projectile_expectedPenetrate: 384,
  firearm_expectedPower: (253 + 384) / 10,
};

export const tmpl_firearm_ar_military_t2 = {
  key: 'firearm_ar_military_t2',
  img_key: 'firearm_ar_t2',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_military_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 286,
  firearm_projectile_penetrate: 492,
  firearm_projectile_expectedDamage: 286,
  firearm_projectile_expectedPenetrate: 492,
  firearm_expectedPower: (286 + 492) / 10,
};

export const tmpl_firearm_ar_military_t1 = {
  key: 'firearm_ar_military_t1',
  img_key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_military_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,
  firearm_projectile_damage: 319,
  firearm_projectile_penetrate: 600,
  firearm_projectile_expectedDamage: 319,
  firearm_projectile_expectedPenetrate: 600,
  firearm_expectedPower: (319 + 600) / 10,
};

export const tmpl_firearm_ar_cheat = {
  firearm_ty: 'ar',
  firearm_name: 'C7A1',
  firearm_rate: 5,
  firearm_cost: 3200,

  ...tmpl_firearm_ar_base,

  firearm_projectile_damage: 50,
  firearm_projectile_penetrate: 50,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.025,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.1, 0.1],
  firearm_shoot_pattern_interval_sec: 0.2,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.5,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 310,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.2,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

const tmpl_firearm_sg_base = {
  ...tmpl_firearm_base,
  ...firearm_aimspeed,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 10,
  firearm_reload_duration: 6,
  firearm_projectile_per_shoot: 14,
  firearm_shoot_pattern_interval_sec: 1.5,
  firearm_shoot_pattern: [],

  firearm_range: 200,

  firearm_range_penalty_mult: 0.35,
  firearm_range_optimal_min: 0,
  firearm_range_optimal_max: 1,
  firearm_projectile_aimvar: Math.PI / 120,
  firearm_projectile_speed: 500,

  aimvar_incr_per_hit: 0,
  aimvar_incr_per_shoot: 3,
  aimvar_hold: Math.PI / 10000,
  aimvar_hold_max: Math.PI / 10000,

  aim_samples_fire_thres: 0.7,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 2,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 40,
  firearm_ty_max_damage: 40,

  firearm_crit_mult: 1,
};

export const tmpl_firearm_sg_enemy_t5 = {
  key: 'firearm_sg_t5',
  img_key: 'firearm_sg_t5',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 4,
  firearm_projectile_damage: 14,
  firearm_projectile_penetrate: 8,
  firearm_projectile_expectedDamage: 14 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 8 * 14 * 0.3,
  firearm_expectedPower: (14 + 8) * 14 * 0.3 / 10,
  firearm_shoot_pattern_interval_sec: 2,


};

export const tmpl_firearm_sg_enemy_t4 = {
  key: 'firearm_sg_t4',
  img_key: 'firearm_sg_t4',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_4',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 22,
  firearm_projectile_penetrate: 15,
  firearm_projectile_expectedDamage: 22 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 15 * 14 * 0.3,
  firearm_expectedPower: (22 + 15) * 14 * 0.3 / 10,
  firearm_shoot_pattern_interval_sec: 2,
};

export const tmpl_firearm_sg_enemy_t3 = {
  key: 'firearm_sg_t3',
  img_key: 'firearm_sg_t3',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_3',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 31,
  firearm_projectile_penetrate: 23,
  firearm_projectile_expectedDamage: 31 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 23 * 14 * 0.3,
  firearm_expectedPower: (31 + 23) * 14 * 0.3 / 10,
  firearm_shoot_pattern_interval_sec: 2,
};

export const tmpl_firearm_sg_enemy_t2 = {
  key: 'firearm_sg_t2',
  img_key: 'firearm_sg_t2',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_2',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 39,
  firearm_projectile_penetrate: 30,
  firearm_projectile_expectedDamage: 39 * 0.3,
  firearm_projectile_expectedPenetrate: 30 * 0.3,
  firearm_expectedPower: (39 + 30) * 14 * 0.3 / 10,
  firearm_shoot_pattern_interval_sec: 2,
};

export const tmpl_firearm_sg_enemy_t1 = {
  key: 'firearm_sg_t1',
  img_key: 'firearm_sg_t1',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_1',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 48,
  firearm_projectile_penetrate: 38,
  firearm_projectile_expectedDamage: 48 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 38 * 14 * 0.3,
  firearm_expectedPower: (48 + 38) * 14 * 0.3 / 10,
  firearm_shoot_pattern_interval_sec: 2,
};

export const tmpl_firearm_sg_t5 = {
  key: 'firearm_sg_t5',
  img_key: 'firearm_sg_t5',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 4,
  firearm_projectile_damage: 40,
  firearm_projectile_penetrate: 10,
  firearm_projectile_expectedDamage: 50 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 10 * 14 * 0.3,
  firearm_expectedPower: (50 + 10) * 14 * 0.3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.11 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_sg_t4 = {
  key: 'firearm_sg_t4',
  img_key: 'firearm_sg_t4',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_4',
  firearm_rate: 4,
  firearm_cost: 200,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 64,
  firearm_projectile_penetrate: 20,
  firearm_projectile_expectedDamage: 80 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 20 * 14 * 0.3,
  firearm_expectedPower: (80 + 20) * 14 * 0.3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.11 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_sg_t3 = {
  key: 'firearm_sg_t3',
  img_key: 'firearm_sg_t3',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_3',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 88,
  firearm_projectile_penetrate: 30,
  firearm_projectile_expectedDamage: 110 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 30 * 14 * 0.3,
  firearm_expectedPower: (110 + 30) * 0.3 * 14 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.115 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_sg_t2 = {
  key: 'firearm_sg_t2',
  img_key: 'firearm_sg_t2',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_2',
  firearm_rate: 2,
  firearm_cost: 1600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 112,
  firearm_projectile_penetrate: 40,
  firearm_projectile_expectedDamage: 140 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 40 * 14 * 0.3,
  firearm_expectedPower: (140 + 40) * 14 * 0.3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.115 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_sg_t1 = {
  key: 'firearm_sg_t1',
  img_key: 'firearm_sg_t1',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_1',
  firearm_rate: 1,
  firearm_cost: 2600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 136,
  firearm_projectile_penetrate: 50,
  firearm_projectile_expectedDamage: 170 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 50 * 14 * 0.3,
  firearm_expectedPower: (170 + 50) * 14 * 0.3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.12 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_sg_old_t5 = {
  key: 'firearm_sg_old_t5',
  img_key: 'firearm_sg_t5',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_old_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 4,
  firearm_projectile_damage: 34,
  firearm_projectile_penetrate: 7,
  firearm_projectile_expectedDamage: 34 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 7 * 14 * 0.3,
  firearm_expectedPower: (34 + 7) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_old_t4 = {
  key: 'firearm_sg_old_t4',
  img_key: 'firearm_sg_t4',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_old_tier_4',
  firearm_rate: 4,
  firearm_cost: 200,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 54,
  firearm_projectile_penetrate: 13,
  firearm_projectile_expectedDamage: 54 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 13 * 14 * 0.3,
  firearm_expectedPower: (54 + 13) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_old_t3 = {
  key: 'firearm_sg_old_t3',
  img_key: 'firearm_sg_t3',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_old_tier_3',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 75,
  firearm_projectile_penetrate: 20,
  firearm_projectile_expectedDamage: 75 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 20 * 14 * 0.3,
  firearm_expectedPower: (75 + 20) * 0.3 * 14 / 10,
};

export const tmpl_firearm_sg_old_t2 = {
  key: 'firearm_sg_old_t2',
  img_key: 'firearm_sg_t2',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_old_tier_2',
  firearm_rate: 2,
  firearm_cost: 1600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 95,
  firearm_projectile_penetrate: 26,
  firearm_projectile_expectedDamage: 95 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 26 * 14 * 0.3,
  firearm_expectedPower: (95 + 26) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_old_t1 = {
  key: 'firearm_sg_old_t1',
  img_key: 'firearm_sg_t1',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_old_tier_1',
  firearm_rate: 1,
  firearm_cost: 2600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 116,
  firearm_projectile_penetrate: 33,
  firearm_projectile_expectedDamage: 116 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 33 * 14 * 0.3,
  firearm_expectedPower: (116 + 33) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_military_t5 = {
  key: 'firearm_sg_military_t5',
  img_key: 'firearm_sg_t5',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_military_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 4,
  firearm_projectile_damage: 48,
  firearm_projectile_penetrate: 11,
  firearm_projectile_expectedDamage: 48 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 11 * 14 * 0.3,
  firearm_expectedPower: (48 + 11) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_military_t4 = {
  key: 'firearm_sg_military_t4',
  img_key: 'firearm_sg_t4',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_military_tier_4',
  firearm_rate: 4,
  firearm_cost: 200,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 5,
  firearm_projectile_damage: 77,
  firearm_projectile_penetrate: 22,
  firearm_projectile_expectedDamage: 77 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 22 * 14 * 0.3,
  firearm_expectedPower: (77 + 22) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_military_t3 = {
  key: 'firearm_sg_military_t3',
  img_key: 'firearm_sg_t3',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_military_tier_3',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 106,
  firearm_projectile_penetrate: 33,
  firearm_projectile_expectedDamage: 106 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 33 * 14 * 0.3,
  firearm_expectedPower: (106 + 33) * 0.3 * 14 / 10,
};

export const tmpl_firearm_sg_military_t2 = {
  key: 'firearm_sg_military_t2',
  img_key: 'firearm_sg_t2',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_military_tier_2',
  firearm_rate: 2,
  firearm_cost: 1600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 134,
  firearm_projectile_penetrate: 44,
  firearm_projectile_expectedDamage: 134 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 44 * 14 * 0.3,
  firearm_expectedPower: (134 + 44) * 14 * 0.3 / 10,
};

export const tmpl_firearm_sg_military_t1 = {
  key: 'firearm_sg_military_t1',
  img_key: 'firearm_sg_t1',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_military_tier_1',
  firearm_rate: 1,
  firearm_cost: 2600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 163,
  firearm_projectile_penetrate: 55,
  firearm_projectile_expectedDamage: 163 * 14 * 0.3,
  firearm_projectile_expectedPenetrate: 55 * 14 * 0.3,
  firearm_expectedPower: (163 + 55) * 14 * 0.3 / 10,
};

const tmpl_firearm_dmr_base = {
  ...tmpl_firearm_base,
  ...firearm_aimspeed,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 10,
  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3,

  firearm_range: 700,
  vis_range: 750,

  firearm_range_penalty_mult: 0.5,
  firearm_range_optimal_min: 0,
  firearm_range_optimal_max: 1,
  firearm_projectile_aimvar: 0,
  firearm_additional_hit_prob: 0.2,

  firearm_reload_duration: 6,

  aimvar_incr_per_hit: 0,
  aimvar_incr_per_shoot: 2,
  aimvar_hold: Math.PI / 10000,
  aimvar_hold_max: Math.PI / 10000,

  aim_samples_fire_thres: 0.8,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 5,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 30,

  firearm_additional_crit_prob: 0.3,
  firearm_crit_mult: 1000,
};

export const tmpl_firearm_dmr_enemy_t5 = {
  key: 'firearm_dmr_t5',
  img_key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 300,
  firearm_projectile_penetrate: 300,
  firearm_projectile_expectedDamage: 300,
  firearm_projectile_expectedPenetrate: 300,
  firearm_expectedPower: (300 + 300) / 10,
};

export const tmpl_firearm_dmr_enemy_t4 = {
  key: 'firearm_dmr_t4',
  img_key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_4',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 450,
  firearm_projectile_penetrate: 450,
  firearm_projectile_expectedDamage: 450,
  firearm_projectile_expectedPenetrate: 450,
  firearm_expectedPower: (450 + 450) / 10,
};

export const tmpl_firearm_dmr_enemy_t3 = {
  key: 'firearm_dmr_t3',
  img_key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_3',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 600,
  firearm_projectile_penetrate: 600,
  firearm_projectile_expectedDamage: 600,
  firearm_projectile_expectedPenetrate: 600,
  firearm_expectedPower: (600 + 600) / 10,
};

export const tmpl_firearm_dmr_enemy_t2 = {
  key: 'firearm_dmr_t2',
  img_key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_2',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 750,
  firearm_projectile_penetrate: 750,
  firearm_projectile_expectedDamage: 750,
  firearm_projectile_expectedPenetrate: 750,
  firearm_expectedPower: (750 + 750) / 10,
};

export const tmpl_firearm_dmr_enemy_t1 = {
  key: 'firearm_dmr_t1',
  img_key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_1',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 900,
  firearm_projectile_penetrate: 900,
  firearm_projectile_expectedDamage: 900,
  firearm_projectile_expectedPenetrate: 900,
  firearm_expectedPower: (900 + 900) / 10,
};

export const tmpl_firearm_dmr_t5 = {
  key: 'firearm_dmr_t5',
  img_key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 500,
  firearm_projectile_penetrate: 500,
  firearm_projectile_expectedDamage: 500,
  firearm_projectile_expectedPenetrate: 500,
  firearm_expectedPower: (500 + 500) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.06 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_dmr_t4 = {
  key: 'firearm_dmr_t4',
  img_key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 750,
  firearm_projectile_penetrate: 750,
  firearm_projectile_expectedDamage: 750,
  firearm_projectile_expectedPenetrate: 750,
  firearm_expectedPower: (750 + 750) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.06 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_dmr_t3 = {
  key: 'firearm_dmr_t3',
  img_key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 1000,
  firearm_projectile_penetrate: 1000,
  firearm_projectile_expectedDamage: 1000,
  firearm_projectile_expectedPenetrate: 1000,
  firearm_expectedPower: (1000 + 1000) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.065 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_dmr_t2 = {
  key: 'firearm_dmr_t2',
  img_key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 1250,
  firearm_projectile_penetrate: 1250,
  firearm_projectile_expectedDamage: 1250,
  firearm_projectile_expectedPenetrate: 1250,
  firearm_expectedPower: (1250 + 1250) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.07 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_dmr_t1 = {
  key: 'firearm_dmr_t1',
  img_key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 1500,
  firearm_projectile_penetrate: 1500,
  firearm_projectile_expectedDamage: 1500,
  firearm_projectile_expectedPenetrate: 1500,
  firearm_expectedPower: (1500 + 1500) / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.08 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_dmr_old_t5 = {
  key: 'firearm_dmr_old_t5',
  img_key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_old_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 250,
  firearm_projectile_penetrate: 450,
  firearm_projectile_expectedDamage: 250,
  firearm_projectile_expectedPenetrate: 450,
  firearm_expectedPower: (250 + 450) / 10,
};

export const tmpl_firearm_dmr_old_t4 = {
  key: 'firearm_dmr_old_t4',
  img_key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_old_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 375,
  firearm_projectile_penetrate: 675,
  firearm_projectile_expectedDamage: 375,
  firearm_projectile_expectedPenetrate: 675,
  firearm_expectedPower: (375 + 675) / 10,
};

export const tmpl_firearm_dmr_old_t3 = {
  key: 'firearm_dmr_old_t3',
  img_key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_old_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 500,
  firearm_projectile_penetrate: 900,
  firearm_projectile_expectedDamage: 500,
  firearm_projectile_expectedPenetrate: 900,
  firearm_expectedPower: (500 + 900) / 10,
};

export const tmpl_firearm_dmr_old_t2 = {
  key: 'firearm_dmr_old_t2',
  img_key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_old_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 625,
  firearm_projectile_penetrate: 1125,
  firearm_projectile_expectedDamage: 625,
  firearm_projectile_expectedPenetrate: 1125,
  firearm_expectedPower: (625 + 1125) / 10,
};

export const tmpl_firearm_dmr_old_t1 = {
  key: 'firearm_dmr_old_t1',
  img_key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_old_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 750,
  firearm_projectile_penetrate: 1350,
  firearm_projectile_expectedDamage: 750,
  firearm_projectile_expectedPenetrate: 1350,
  firearm_expectedPower: (750 + 1350) / 10,
};

export const tmpl_firearm_dmr_military_t5 = {
  key: 'firearm_dmr_military_t5',
  img_key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_military_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 550,
  firearm_projectile_penetrate: 600,
  firearm_projectile_expectedDamage: 550,
  firearm_projectile_expectedPenetrate: 600,
  firearm_expectedPower: (550 + 600) / 10,
};

export const tmpl_firearm_dmr_military_t4 = {
  key: 'firearm_dmr_military_t4',
  img_key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_military_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 825,
  firearm_projectile_penetrate: 900,
  firearm_projectile_expectedDamage: 825,
  firearm_projectile_expectedPenetrate: 900,
  firearm_expectedPower: (825 + 900) / 10,
};

export const tmpl_firearm_dmr_military_t3 = {
  key: 'firearm_dmr_military_t3',
  img_key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_military_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 1100,
  firearm_projectile_penetrate: 1200,
  firearm_projectile_expectedDamage: 1100,
  firearm_projectile_expectedPenetrate: 1200,
  firearm_expectedPower: (1100 + 1200) / 10,
};

export const tmpl_firearm_dmr_military_t2 = {
  key: 'firearm_dmr_military_t2',
  img_key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_military_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 1375,
  firearm_projectile_penetrate: 1500,
  firearm_projectile_expectedDamage: 1375,
  firearm_projectile_expectedPenetrate: 1500,
  firearm_expectedPower: (1375 + 1500) / 10,
};

export const tmpl_firearm_dmr_military_t1 = {
  key: 'firearm_dmr_military_t1',
  img_key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_military_tier1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 1650,
  firearm_projectile_penetrate: 1800,
  firearm_projectile_expectedDamage: 1650,
  firearm_projectile_expectedPenetrate: 1800,
  firearm_expectedPower: (1650 + 1800) / 10,
};

export const tmpl_firearm_dmr_experimental_t5 = {
  key: 'firearm_dmr_experimental_t5',
  img_key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_experimental_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 7,
  firearm_projectile_damage: 1100,
  firearm_projectile_penetrate: 50,
  firearm_projectile_expectedDamage: 1100,
  firearm_projectile_expectedPenetrate: 50,
  firearm_expectedPower: (1100 + 50) / 10,
};

export const tmpl_firearm_dmr_experimental_t4 = {
  key: 'firearm_dmr_experimental_t4',
  img_key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_experimental_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 1650,
  firearm_projectile_penetrate: 75,
  firearm_projectile_expectedDamage: 1650,
  firearm_projectile_expectedPenetrate: 75,
  firearm_expectedPower: (1650 + 75) / 10,
};

export const tmpl_firearm_dmr_experimental_t3 = {
  key: 'firearm_dmr_experimental_t3',
  img_key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_experimental_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 2200,
  firearm_projectile_penetrate: 100,
  firearm_projectile_expectedDamage: 2200,
  firearm_projectile_expectedPenetrate: 100,
  firearm_expectedPower: (2200 + 100) / 10,
};

export const tmpl_firearm_dmr_experimental_t2 = {
  key: 'firearm_dmr_experimental_t2',
  img_key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_experimental_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 10,
  firearm_projectile_damage: 2750,
  firearm_projectile_penetrate: 125,
  firearm_projectile_expectedDamage: 2750,
  firearm_projectile_expectedPenetrate: 125,
  firearm_expectedPower: (2750 + 125) / 10,
};

export const tmpl_firearm_dmr_experimental_t1 = {
  key: 'firearm_dmr_military_t1',
  img_key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_experimental_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0.25,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 3300,
  firearm_projectile_penetrate: 150,
  firearm_projectile_expectedDamage: 3300,
  firearm_projectile_expectedPenetrate: 150,
  firearm_expectedPower: (3300 + 150) / 10,
};

const tmpl_firearm_smg_base = {
  ...tmpl_firearm_base,
  ...firearm_aimspeed,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 10,
  firearm_reload_duration: 5,
  firearm_shoot_pattern: Array(2).fill(60 / 500),
  firearm_shoot_pattern_interval_sec: 1.7,

  firearm_range: 300,
  firearm_range_penalty_mult: 0.4,

  firearm_projectile_aimvar: 0,

  aimvar_hold: Math.PI / 10000,
  aimvar_hold_max: Math.PI / 10000,
  aimvar_incr_per_shoot: 0,
  aimvar_incr_per_hit: 0,

  firearm_range_optimal_min: 0,
  firearm_range_optimal_max: 1,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.2,

  firearm_additional_crit_prob: 0.03,
  firearm_crit_mult: 1.5,

  firearm_ty_max_rpm: 800,
};

export const tmpl_firearm_smg_enemy_t5 = {
  key: 'firearm_smg_t5',
  img_key: 'firearm_smg_t5',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 16,
  firearm_projectile_penetrate: 32,
  firearm_projectile_expectedDamage: 16 * 3,
  firearm_projectile_expectedPenetrate: 32 * 3,
  firearm_expectedPower: (16 + 32) * 3 / 10,
};

export const tmpl_firearm_smg_enemy_t4 = {
  key: 'firearm_smg_t4',
  img_key: 'firearm_smg_t4',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_4',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 24,
  firearm_projectile_penetrate: 48,
  firearm_projectile_expectedDamage: 24 * 3,
  firearm_projectile_expectedPenetrate: 48 * 3,
  firearm_expectedPower: (24 + 48) * 3 / 10,
};

export const tmpl_firearm_smg_enemy_t3 = {
  key: 'firearm_smg_t3',
  img_key: 'firearm_smg_t3',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_3',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 32,
  firearm_projectile_penetrate: 64,
  firearm_projectile_expectedDamage: 32 * 3,
  firearm_projectile_expectedPenetrate: 64 * 3,
  firearm_expectedPower: (32 + 64) * 3 / 10,
};

export const tmpl_firearm_smg_enemy_t2 = {
  key: 'firearm_smg_t2',
  img_key: 'firearm_smg_t2',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_2',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 40,
  firearm_projectile_penetrate: 80,
  firearm_projectile_expectedDamage: 40 * 3,
  firearm_projectile_expectedPenetrate: 80 * 3,
  firearm_expectedPower: (40 + 80) * 3 / 10,
};

export const tmpl_firearm_smg_enemy_t1 = {
  key: 'firearm_smg_t1',
  img_key: 'firearm_smg_t1',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_1',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 48,
  firearm_projectile_penetrate: 96,
  firearm_projectile_expectedDamage: 48 * 3,
  firearm_projectile_expectedPenetrate: 96 * 3,
  firearm_expectedPower: (48 + 96) * 3 / 10,
};

export const tmpl_firearm_smg_t5 = {
  key: 'firearm_smg_t5',
  img_key: 'firearm_smg_t5',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 40,
  firearm_projectile_penetrate: 80,
  firearm_projectile_expectedDamage: 40 * 3,
  firearm_projectile_expectedPenetrate: 80 * 3,
  firearm_expectedPower: (40 + 80) * 3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.18 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_smg_t4 = {
  key: 'firearm_smg_t4',
  img_key: 'firearm_smg_t4',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 60,
  firearm_projectile_penetrate: 120,
  firearm_projectile_expectedDamage: 60 * 3,
  firearm_projectile_expectedPenetrate: 120 * 3,
  firearm_expectedPower: (60 + 120) * 3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.18 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_smg_t3 = {
  key: 'firearm_smg_t3',
  img_key: 'firearm_smg_t3',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 80,
  firearm_projectile_penetrate: 160,
  firearm_projectile_expectedDamage: 80 * 3,
  firearm_projectile_expectedPenetrate: 160 * 3,
  firearm_expectedPower: (80 + 160) * 3 / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.19 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_smg_t2 = {
  key: 'firearm_smg_t2',
  img_key: 'firearm_smg_t2',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 100,
  firearm_projectile_penetrate: 200,
  firearm_projectile_expectedDamage: 100 * 3,
  firearm_projectile_expectedPenetrate: 200 * 3,
  firearm_expectedPower: (100 + 200) * 3 / 10,
  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.195 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_smg_t1 = {
  key: 'firearm_smg_t1',
  img_key: 'firearm_smg_t1',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 50,
  firearm_projectile_damage: 120,
  firearm_projectile_penetrate: 240,
  firearm_projectile_expectedDamage: 120 * 3,
  firearm_projectile_expectedPenetrate: 240 * 3,
  firearm_expectedPower: (120 + 240) * 3 / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.2 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_smg_civilian_t5 = {
  key: 'firearm_smg_civilian_t5',
  img_key: 'firearm_smg_t5',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_civilian_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 36,
  firearm_projectile_penetrate: 64,
  firearm_projectile_expectedDamage: 36 * 3,
  firearm_projectile_expectedPenetrate: 64 * 3,
  firearm_expectedPower: (36 + 64) * 3 / 10,
};

export const tmpl_firearm_smg_civilian_t4 = {
  key: 'firearm_smg_civilian_t4',
  img_key: 'firearm_smg_t4',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_civilian_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 54,
  firearm_projectile_penetrate: 96,
  firearm_projectile_expectedDamage: 54 * 3,
  firearm_projectile_expectedPenetrate: 96 * 3,
  firearm_expectedPower: (54 + 96) * 3 / 10,
};

export const tmpl_firearm_smg_civilian_t3 = {
  key: 'firearm_smg_civilian_t3',
  img_key: 'firearm_smg_t3',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_civilian_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 72,
  firearm_projectile_penetrate: 128,
  firearm_projectile_expectedDamage: 72 * 3,
  firearm_projectile_expectedPenetrate: 128 * 3,
  firearm_expectedPower: (72 + 128) * 3 / 10,
};

export const tmpl_firearm_smg_civilian_t2 = {
  key: 'firearm_smg_civilian_t2',
  img_key: 'firearm_smg_t2',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_civilian_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 90,
  firearm_projectile_penetrate: 160,
  firearm_projectile_expectedDamage: 90 * 3,
  firearm_projectile_expectedPenetrate: 160 * 3,
  firearm_expectedPower: (90 + 160) * 3 / 10,
};

export const tmpl_firearm_smg_civilian_t1 = {
  key: 'firearm_smg_civilian_t1',
  img_key: 'firearm_smg_t1',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_civilian_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 50,
  firearm_projectile_damage: 108,
  firearm_projectile_penetrate: 192,
  firearm_projectile_expectedDamage: 108 * 3,
  firearm_projectile_expectedPenetrate: 192 * 3,
  firearm_expectedPower: (108 + 192) * 3 / 10,
};

export const tmpl_firearm_smg_military_t5 = {
  key: 'firearm_smg_military_t5',
  img_key: 'firearm_smg_t5',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_military_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 48,
  firearm_projectile_penetrate: 88,
  firearm_projectile_expectedDamage: 48 * 3,
  firearm_projectile_expectedPenetrate: 88 * 3,
  firearm_expectedPower: (48 + 88) * 3 / 10,
};

export const tmpl_firearm_smg_military_t4 = {
  key: 'firearm_smg_military_t4',
  img_key: 'firearm_smg_t4',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_military_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 72,
  firearm_projectile_penetrate: 132,
  firearm_projectile_expectedDamage: 72 * 3,
  firearm_projectile_expectedPenetrate: 132 * 3,
  firearm_expectedPower: (72 + 132) * 3 / 10,
};

export const tmpl_firearm_smg_military_t3 = {
  key: 'firearm_smg_military_t3',
  img_key: 'firearm_smg_t3',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_military_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 96,
  firearm_projectile_penetrate: 176,
  firearm_projectile_expectedDamage: 96 * 3,
  firearm_projectile_expectedPenetrate: 176 * 3,
  firearm_expectedPower: (96 + 176) * 3 / 10,
};

export const tmpl_firearm_smg_military_t2 = {
  key: 'firearm_smg_military_t2',
  img_key: 'firearm_smg_t2',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_military_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 30,
  firearm_projectile_damage: 120,
  firearm_projectile_penetrate: 220,
  firearm_projectile_expectedDamage: 120 * 3,
  firearm_projectile_expectedPenetrate: 220 * 3,
  firearm_expectedPower: (120 + 220) * 3 / 10,
};

export const tmpl_firearm_smg_military_t1 = {
  key: 'firearm_smg_military_t1',
  img_key: 'firearm_smg_t1',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_military_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 50,
  firearm_projectile_damage: 144,
  firearm_projectile_penetrate: 264,
  firearm_projectile_expectedDamage: 144 * 3,
  firearm_projectile_expectedPenetrate: 264 * 3,
  firearm_expectedPower: (144 + 264) * 3 / 10,
};

// 경기관총 비스므리한거
export const tmpl_firearm_smg_lmg = {
  firearm_ty: 'smg',
  firearm_name: 'RPK-16',
  firearm_rate: 4,
  firearm_cost: 3000,

  firearm_aimvar_incr_rot_cap: 1,

  firearm_ammo_max: 100,
  firearm_range: 900,
  firearm_aimvar_mult: 0.65,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 60 / 700,

  firearm_projectile_damage: 100,
  firearm_projectile_penetrate: 100,
  firearm_projectile_aimvar: Math.PI / 688,

  firearm_reload_duration: 5.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 344,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.75,

  aim_samples_fire_thres: 0.85,

  firearm_additional_hit_prob: 0.25,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.15 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  // TODO
  vis_range: 700,
};

const tmpl_firearm_hg_base = {
  ...tmpl_firearm_base,
  ...firearm_aimspeed,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 8,
  firearm_reload_duration: 3,
  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.7,

  firearm_range: 300,
  firearm_range_penalty_mult: 0.3,
  firearm_range_optimal_min: 0,
  firearm_range_optimal_max: 1,

  firearm_projectile_aimvar: 0,

  aimvar_hold: Math.PI / 10000,
  aimvar_hold_max: Math.PI / 10000,
  aimvar_incr_per_shoot: 0,
  aimvar_incr_per_hit: 0,

  aim_samples_fire_thres: 0.1,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.5,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 60,

  firearm_additional_crit_prob: 0.03,
  firearm_crit_mult: 1.5,
};

export const tmpl_firearm_hg_enemy_t5 = {
  key: 'firearm_hg_t5',
  img_key: 'firearm_hg_t5',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 21,
  firearm_projectile_penetrate: 10,
  firearm_projectile_expectedDamage: 21,
  firearm_projectile_expectedPenetrate: 10,
  firearm_expectedPower: (21 + 10) / 10,
};

export const tmpl_firearm_hg_enemy_t4 = {
  key: 'firearm_hg_t4',
  img_key: 'firearm_hg_t4',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 28,
  firearm_projectile_penetrate: 20,
  firearm_projectile_expectedDamage: 28,
  firearm_projectile_expectedPenetrate: 20,
  firearm_expectedPower: (28 + 20) / 10,
};


export const tmpl_firearm_hg_enemy_t3 = {
  key: 'firearm_hg_t3',
  img_key: 'firearm_hg_t3',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 35,
  firearm_projectile_penetrate: 30,
  firearm_projectile_expectedDamage: 35,
  firearm_projectile_expectedPenetrate: 30,
  firearm_expectedPower: (35 + 30) / 10,
};

export const tmpl_firearm_hg_enemy_t2 = {
  key: 'firearm_hg_t2',
  img_key: 'firearm_hg_t2',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_2',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 13,
  firearm_projectile_damage: 42,
  firearm_projectile_penetrate: 40,
  firearm_projectile_expectedDamage: 42,
  firearm_projectile_expectedPenetrate: 40,
  firearm_expectedPower: (42 + 40) / 10,
};

export const tmpl_firearm_hg_t5 = {
  key: 'firearm_hg_t5',
  img_key: 'firearm_hg_t5',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 60,
  firearm_projectile_penetrate: 40,
  firearm_projectile_expectedDamage: 60,
  firearm_projectile_expectedPenetrate: 40,
  firearm_expectedPower: 0,//(60 + 40) / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.24 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_hg_t4 = {
  key: 'firearm_hg_t4',
  img_key: 'firearm_hg_t4',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4',
  firearm_rate: 4,
  firearm_cost: 250,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 80,
  firearm_projectile_penetrate: 80,
  firearm_projectile_expectedDamage: 80,
  firearm_projectile_expectedPenetrate: 80,
  firearm_expectedPower: (80 + 80) / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.24 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_hg_t3 = {
  key: 'firearm_hg_t3',
  img_key: 'firearm_hg_t3',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3',
  firearm_rate: 3,
  firearm_cost: 400,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 100,
  firearm_projectile_penetrate: 120,
  firearm_projectile_expectedDamage: 100,
  firearm_projectile_expectedPenetrate: 120,
  firearm_expectedPower: (100 + 120) / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.24 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_hg_t2 = {
  key: 'firearm_hg_t2',
  img_key: 'firearm_hg_t2',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_2',
  firearm_rate: 2,
  firearm_cost: 600,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 13,
  firearm_projectile_damage: 120,
  firearm_projectile_penetrate: 160,
  firearm_projectile_expectedDamage: 120,
  firearm_projectile_expectedPenetrate: 160,
  firearm_expectedPower: (120 + 160) / 10,

  // aim_rot_rules: [
  //   { aimvar: Math.PI / 2, aimspeed: 0.24 },
  //   { aimvar: Math.PI * 2, aimspeed: 0.1 },
  // ],
};

export const tmpl_firearm_hg_old_t4 = {
  key: 'firearm_hg_old_t4',
  img_key: 'firearm_hg_t4',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_old_tier_4',
  firearm_rate: 4,
  firearm_cost: 250,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 72,
  firearm_projectile_penetrate: 48,
  firearm_projectile_expectedDamage: 72,
  firearm_projectile_expectedPenetrate: 48,
  firearm_expectedPower: (72 + 48) / 10,
};

export const tmpl_firearm_hg_old_t3 = {
  key: 'firearm_hg_old_t3',
  img_key: 'firearm_hg_t3',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_old_tier_3',
  firearm_rate: 3,
  firearm_cost: 400,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 90,
  firearm_projectile_penetrate: 72,
  firearm_projectile_expectedDamage: 90,
  firearm_projectile_expectedPenetrate: 72,
  firearm_expectedPower: (90 + 72) / 10,
};

export const tmpl_firearm_hg_old_t2 = {
  key: 'firearm_hg_old_t2',
  img_key: 'firearm_hg_t2',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_old_tier_2',
  firearm_rate: 2,
  firearm_cost: 600,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 13,
  firearm_projectile_damage: 108,
  firearm_projectile_penetrate: 96,
  firearm_projectile_expectedDamage: 108,
  firearm_projectile_expectedPenetrate: 96,
  firearm_expectedPower: (108 + 96) / 10,
};

export const tmpl_firearm_hg_military_t4 = {
  key: 'firearm_hg_military_t4',
  img_key: 'firearm_hg_t4',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_military_tier_4',
  firearm_rate: 4,
  firearm_cost: 250,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 8,
  firearm_projectile_damage: 88,
  firearm_projectile_penetrate: 96,
  firearm_projectile_expectedDamage: 88,
  firearm_projectile_expectedPenetrate: 96,
  firearm_expectedPower: (88 + 96) / 10,
};

export const tmpl_firearm_hg_military_t3 = {
  key: 'firearm_hg_military_t3',
  img_key: 'firearm_hg_t3',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_military_tier_3',
  firearm_rate: 3,
  firearm_cost: 400,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 12,
  firearm_projectile_damage: 110,
  firearm_projectile_penetrate: 144,
  firearm_projectile_expectedDamage: 110,
  firearm_projectile_expectedPenetrate: 144,
  firearm_expectedPower: (110 + 144) / 10,
};

export const tmpl_firearm_hg_military_t2 = {
  key: 'firearm_hg_military_t2',
  img_key: 'firearm_hg_t2',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_military_tier_2',
  firearm_rate: 2,
  firearm_cost: 600,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_ammo_max: 13,
  firearm_projectile_damage: 132,
  firearm_projectile_penetrate: 192,
  firearm_projectile_expectedDamage: 132,
  firearm_projectile_expectedPenetrate: 192,
  firearm_expectedPower: (132 + 192) / 10,
};

export const tmpl_firearm_hg_with_shield_enemy_t5 = {
  ...tmpl_firearm_hg_enemy_t5,
  key: 'firearm_hg_t5_with_shield',
  img_key: 'firearm_hg_t5_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5_with_shield',

  firearm_rate: 5,
  shield: 700,
  shield_damage_max: 15,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (700 / 44),
};

export const tmpl_firearm_hg_with_shield_enemy_t4 = {
  ...tmpl_firearm_hg_enemy_t5,
  key: 'firearm_hg_t4_with_shield',
  img_key: 'firearm_hg_t4_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4_with_shield',

  firearm_rate: 4,
  shield: 900,
  shield_damage_max: 15,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (900 / 44),
};

export const tmpl_firearm_hg_with_shield_enemy_t3 = {
  ...tmpl_firearm_hg_enemy_t5,
  key: 'firearm_hg_t3_with_shield',
  img_key: 'firearm_hg_t3_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3_with_shield',

  firearm_rate: 3,
  shield: 1100,
  shield_damage_max: 15,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (1100 / 44),
};


export const tmpl_firearm_hg_t5_with_shield = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t5_with_shield',
  img_key: 'firearm_hg_t5_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5_with_shield',

  firearm_rate: 5,
  shield: 3000,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3000 / 44),
};

export const tmpl_firearm_hg_t4_with_shield = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t4_with_shield',
  img_key: 'firearm_hg_t4_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4_with_shield',

  firearm_rate: 4,
  shield: 3500,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3500 / 44),
};

export const tmpl_firearm_hg_t3_with_shield = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t3_with_shield',
  img_key: 'firearm_hg_t3_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3_with_shield',

  firearm_rate: 3,
  shield: 4000,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (4000 / 44),
};

export const tmpl_firearm_hg_t5_with_shield_reinforced = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t5_reinforced_with_shield',
  img_key: 'firearm_hg_t5_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5_reinforced_with_shield',

  firearm_rate: 5,
  shield: 3600,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3000 / 44),
};

export const tmpl_firearm_hg_t4_with_shield_reinforced = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t4_reinforced_with_shield',
  img_key: 'firearm_hg_t4_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4_reinforced_with_shield',

  firearm_rate: 4,
  shield: 4200,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3500 / 44),
};

export const tmpl_firearm_hg_t3_with_shield_reinforced = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t3_reinforced_with_shield',
  img_key: 'firearm_hg_t3_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3_reinforced_with_shield',

  firearm_rate: 3,
  shield: 4800,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (4000 / 44),
};

export const tmpl_firearm_hg_t5_with_shield_repaired = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t5_repaired_with_shield',
  img_key: 'firearm_hg_t5_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_5_repaired_with_shield',

  firearm_rate: 5,
  shield: 2700,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3000 / 44),
};

export const tmpl_firearm_hg_t4_with_shield_repaired = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t4_repaired_with_shield',
  img_key: 'firearm_hg_t4_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4_repaired_with_shield',

  firearm_rate: 4,
  shield: 3150,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (3500 / 44),
};

export const tmpl_firearm_hg_t3_with_shield_repaired = {
  ...tmpl_firearm_hg_t5,
  key: 'firearm_hg_t3_repaired_with_shield',
  img_key: 'firearm_hg_t3_with_shield',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3_repaired_with_shield',

  firearm_rate: 3,
  shield: 3600,
  shield_damage_max: 20,
  buy_cost: 1000,
  sell_cost: 100,
  durability: -1,
  firearm_expectedPower: (60 + 40) / 10 + (4000 / 44),
};

export const tmpl_firearm_hostage = {
  ...tmpl_firearm_smg_t5,

  firearm_range: 5,
  firearm_aimvar_mult: 30,
};

export const tmpl_firearm_unarmed = {
  key: 'firearm_unarmed',
  img_key: 'firearm_unarmed',

  firearm_ty: 'unarmed',
  firearm_name: 'unarmed1',
  firearm_rate: 5,
  firearm_cost: 1,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 10,
  firearm_range: 25,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.3,

  firearm_projectile_damage: 1,
  firearm_projectile_penetrate: 1,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 296,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
  vis_range: 25
};

// Test mortar
export const tmpl_fireteam_mortar = {
  key: '',

  firearm_ty: 'unarmed',
  firearm_name: 'mortar',
  firearm_rate: 1,
  firearm_cost: 1,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 10,
  firearm_range: 10,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.3,

  firearm_projectile_damage: 1,
  firearm_projectile_penetrate: 1,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 296,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
  vis_range: 5000
};

// Test LMG
export const tmpl_firearm_lmg_fixed = {
  key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assault_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_ammo_max: 100,
  firearm_range: 550,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: 0,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 60 / 400,
  firearm_projectile_damage: 10,
  firearm_projectile_penetrate: 10,

  firearm_projectile_aimvar: 0,
  firearm_reload_duration: 5,

  aimvar_hold: Math.PI / 1000,
  aimvar_hold_max: Math.PI / 1000,
  aimvar_incr_per_shoot: 0,
  aimvar_incr_per_hit: 0,

  firearm_additional_hit_prob: -0.6,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_lmg_t1 = {
  key: 'firearm_ar_t1',

  firearm_ty: 'lmg',
  firearm_name: 'lmg_t1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_ammo_max: 50,
  firearm_range: 550,

  firearm_aimvar_incr_rot_cap: 0,
  firearm_aimvar_incr_move_cap: Math.PI / 2,

  firearm_aimvar_mult: 0.1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 60 / 400,
  firearm_projectile_damage: 10,
  firearm_projectile_penetrate: 10,

  firearm_projectile_aimvar: 0,
  firearm_reload_duration: 5,

  aimvar_hold: Math.PI * 0.66,
  aimvar_hold_max: Math.PI / 1000,
  aimvar_incr_per_shoot: 0,
  aimvar_incr_per_hit: 0,

  firearm_additional_hit_prob: -0.6,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const firearm_keys = {
  // ----- dmr ----- 
  tmpl_firearm_dmr_t1,
  tmpl_firearm_dmr_t2,
  tmpl_firearm_dmr_t3,
  tmpl_firearm_dmr_t4,
  tmpl_firearm_dmr_t5,

  tmpl_firearm_dmr_enemy_t1,
  tmpl_firearm_dmr_enemy_t2,
  tmpl_firearm_dmr_enemy_t3,
  tmpl_firearm_dmr_enemy_t4,
  tmpl_firearm_dmr_enemy_t5,

  tmpl_firearm_dmr_old_t1,
  tmpl_firearm_dmr_old_t2,
  tmpl_firearm_dmr_old_t3,
  tmpl_firearm_dmr_old_t4,
  tmpl_firearm_dmr_old_t5,

  tmpl_firearm_dmr_military_t1,
  tmpl_firearm_dmr_military_t2,
  tmpl_firearm_dmr_military_t3,
  tmpl_firearm_dmr_military_t4,
  tmpl_firearm_dmr_military_t5,

  tmpl_firearm_dmr_experimental_t1,
  tmpl_firearm_dmr_experimental_t2,
  tmpl_firearm_dmr_experimental_t3,
  tmpl_firearm_dmr_experimental_t4,
  tmpl_firearm_dmr_experimental_t5,

  // ----- ar -----
  tmpl_firearm_ar_t1,
  tmpl_firearm_ar_t2,
  tmpl_firearm_ar_t3,
  tmpl_firearm_ar_t4,
  tmpl_firearm_ar_t5,

  tmpl_firearm_ar_enemy_t1,
  tmpl_firearm_ar_enemy_t2,
  tmpl_firearm_ar_enemy_t3,
  tmpl_firearm_ar_enemy_t4,
  tmpl_firearm_ar_enemy_t5,

  tmpl_firearm_ar_civilian_t1,
  tmpl_firearm_ar_civilian_t2,
  tmpl_firearm_ar_civilian_t3,
  tmpl_firearm_ar_civilian_t4,
  tmpl_firearm_ar_civilian_t5,

  tmpl_firearm_ar_military_t1,
  tmpl_firearm_ar_military_t2,
  tmpl_firearm_ar_military_t3,
  tmpl_firearm_ar_military_t4,
  tmpl_firearm_ar_military_t5,

  // ----- smg -----
  tmpl_firearm_smg_t1,
  tmpl_firearm_smg_t2,
  tmpl_firearm_smg_t3,
  tmpl_firearm_smg_t4,
  tmpl_firearm_smg_t5,

  tmpl_firearm_smg_enemy_t1,
  tmpl_firearm_smg_enemy_t2,
  tmpl_firearm_smg_enemy_t3,
  tmpl_firearm_smg_enemy_t4,
  tmpl_firearm_smg_enemy_t5,

  tmpl_firearm_smg_civilian_t1,
  tmpl_firearm_smg_civilian_t2,
  tmpl_firearm_smg_civilian_t3,
  tmpl_firearm_smg_civilian_t4,
  tmpl_firearm_smg_civilian_t5,

  tmpl_firearm_smg_military_t1,
  tmpl_firearm_smg_military_t2,
  tmpl_firearm_smg_military_t3,
  tmpl_firearm_smg_military_t4,
  tmpl_firearm_smg_military_t5,

  // ----- sg -----
  tmpl_firearm_sg_t1,
  tmpl_firearm_sg_t2,
  tmpl_firearm_sg_t3,
  tmpl_firearm_sg_t4,
  tmpl_firearm_sg_t5,

  tmpl_firearm_sg_enemy_t1,
  tmpl_firearm_sg_enemy_t2,
  tmpl_firearm_sg_enemy_t3,
  tmpl_firearm_sg_enemy_t4,
  tmpl_firearm_sg_enemy_t5,

  tmpl_firearm_sg_old_t1,
  tmpl_firearm_sg_old_t2,
  tmpl_firearm_sg_old_t3,
  tmpl_firearm_sg_old_t4,
  tmpl_firearm_sg_old_t5,

  tmpl_firearm_sg_military_t1,
  tmpl_firearm_sg_military_t2,
  tmpl_firearm_sg_military_t3,
  tmpl_firearm_sg_military_t4,
  tmpl_firearm_sg_military_t5,

  /// ----- hg -----
  tmpl_firearm_hg_t2,
  tmpl_firearm_hg_t3,
  tmpl_firearm_hg_t4,
  tmpl_firearm_hg_t5,

  tmpl_firearm_hg_enemy_t2,
  tmpl_firearm_hg_enemy_t3,
  tmpl_firearm_hg_enemy_t4,
  tmpl_firearm_hg_enemy_t5,

  tmpl_firearm_hg_old_t2,
  tmpl_firearm_hg_old_t3,
  tmpl_firearm_hg_old_t4,

  tmpl_firearm_hg_military_t2,
  tmpl_firearm_hg_military_t3,
  tmpl_firearm_hg_military_t4,

  /// ----- hg with shield -----
  tmpl_firearm_hg_t3_with_shield,
  tmpl_firearm_hg_t4_with_shield,
  tmpl_firearm_hg_t5_with_shield,

  tmpl_firearm_hg_with_shield_enemy_t3,
  tmpl_firearm_hg_with_shield_enemy_t4,
  tmpl_firearm_hg_with_shield_enemy_t5,

  tmpl_firearm_hg_t3_with_shield_reinforced,
  tmpl_firearm_hg_t4_with_shield_reinforced,
  tmpl_firearm_hg_t5_with_shield_reinforced,

  tmpl_firearm_hg_t3_with_shield_repaired,
  tmpl_firearm_hg_t4_with_shield_repaired,
  tmpl_firearm_hg_t5_with_shield_repaired,
};

export function replaceTierFirearm(firearm_key, tier) {
  const split = firearm_key.split('_').slice(0, -1);
  const newKey = `${split.reduce((ret, str) => `${ret}${str}_`, '')}t${tier}`;

  for (const [key, value] of Object.entries(firearm_keys)) {
    if (key === newKey) {
      return {
        newKey,
        firearm: value,
      };
    }
  }
  return {};
}


function classifyFirearms() {
  const tys = ['dmr', 'ar', 'smg', 'sg', 'hg'];
  const ret = {};
  for (const ty of tys) {
    ret[ty] = [];
  }

  for (const [key, value] of Object.entries(firearm_keys)) {
    const cls = tys.find((ty) => key.split('_').find((s) => s === ty));
    ret[cls].push(value);
  }
  return ret;
}

export const firearm_tys = classifyFirearms();

function update_cost(target) {
  const item = items.find(i => i.key === target.key);
  if (item) {
    target.buy_cost = item.buy_cost;
    target.sell_cost = item.sell_cost;
    target.durability = item.durability;
  }

  target.requirements = { ...target.requirements };
  if (target.firearm_ty !== 'hg' && target.firearm_ty !== 'unarmed') {
    target.requirements['bullet'] = target.firearm_ammo_max * 30;
  }
}

function updateExpectedValues(target) {
  const damageMultipliers = { ar: 1, sg: 0.3, dmr: 1, smg: 3, hg: 1 };
  const penetrateMultipliers = { ar: 1, sg: 0.3, dmr: 1, smg: 3, hg: 1 };

  target.firearm_projectile_expectedDamage = target.firearm_projectile_damage * damageMultipliers[target.firearm_ty];
  target.firearm_projectile_expectedPenetrate = target.firearm_projectile_penetrate * penetrateMultipliers[target.firearm_ty];
}

export const firearms = [];
export const firearms_tier1 = [];
for (const l of Object.values(firearm_tys)) {
  const firearm_tier1 = l.find((f) => f.firearm_rate === 5);
  firearms_tier1.push(firearm_tier1);
  for (const firearm of l) {
    update_cost(firearm);
    updateExpectedValues(firearm);
    firearms.push(firearm);
  }
}

export const firearm_ty_name = {
  'sr': 'loc_ui_string_common_firearm_sniper_rifle',
  'dmr': 'loc_ui_string_common_firearm_designated_marksman_rifle',
  'ar': 'loc_ui_string_common_firearm_assault_rifle',
  'smg': 'loc_ui_string_common_firearm_submachine_gun',
  'sg': 'loc_ui_string_common_firearm_shotgun',
  'hg': 'loc_ui_string_common_firearm_handgun',
  'lmg': 'loc_ui_string_common_firearm_lmg',
}

export const firearm_ty_name_short = {
  'sr': 'loc_ui_string_common_firearm_sniper_rifle_short',
  'dmr': 'loc_ui_string_common_firearm_designated_marksman_rifle_short',
  'ar': 'loc_ui_string_common_firearm_assault_rifle_short',
  'smg': 'loc_ui_string_common_firearm_submachine_gun_short',
  'sg': 'loc_ui_string_common_firearm_shotgun_short',
  'hg': 'loc_ui_string_common_firearm_handgun_short',
  'lmg': 'loc_ui_string_common_firearm_lmg_short',
}

export const firearm_ty_full = {
  'sr': 'sniper_rifle',
  'dmr': 'designated_marksman_rifle',
  'ar': 'assault_rifle',
  'smg': 'submachine_gun',
  'sg': 'shotgun',
  'hg': 'handgun'
}
