/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PgbButton = ({
  onMouseOver = "D",
  onMouseOut = "D",
  ptSubLabel = "Button Sub Label",
  ptLabel = "ButtonLabel",
  pbShowSubText = true,
  onMouseMove = "D",
  onClick = "dummy",
  varState,
  className,
  overlapGroupClassName,
  perkgroupClassName,
  onMouseMoveDummyClassName,
  onMouseOverDummyClassName,
  onMouseOutDummyClassName,
  onClickDummyClassName,
  vDClassName,
  vD = "/anima/img/v-8af-3-3cda.svg",
  vClassName,
  v = "/anima/img/v-8af-3-3cda.svg",
  vA = "/anima/img/v320cff74-4.svg",
  vCff = "/anima/img/v320cff74-4.svg",
}) => {
  return (
    <div className={`PGB-button ${varState} ${className}`}>
      <div className={`overlap-group-14 ${overlapGroupClassName}`}>
        <div className={`perkgroup ${perkgroupClassName}`}>
          {pbShowSubText && (
            <div className="perkgroup-hgap">
              <img className={`v-d ${vDClassName}`} alt="V d" src={varState === "on" ? vA : vD} />
              <div className="PT-perkgroup">{ptSubLabel}</div>
              <img className={`v-af-cda ${vClassName}`} alt="V" src={varState === "on" ? vCff : v} />
            </div>
          )}

          <div className="PT-perkgroupsub">{ptLabel}</div>
        </div>
        <div className={`text-wrapper-8 ${onMouseMoveDummyClassName}`}></div>
        <div className={`text-wrapper-8 ${onMouseOverDummyClassName}`}></div>
        <div className={`text-wrapper-8 ${onMouseOutDummyClassName}`}></div>
        <div className={`text-wrapper-8 ${onClickDummyClassName}`} onClick={() => typeof onClick === "function" ? onClick() : null} onMouseOut={(e) => typeof onMouseOut === "function" ? onMouseOut(e) : null} onMouseOver={(e) => typeof onMouseOver === "function" ? onMouseOver(e) : null} onMouseMove={(e) => typeof onMouseMove === "function" ? onMouseMove(e) : null} style={{zIndex: 20}}>D</div>
      </div>
    </div>
  );
};

PgbButton.propTypes = {
  onMouseOver: PropTypes.any,
  onMouseOut: PropTypes.any,
  ptSubLabel: PropTypes.string,
  ptLabel: PropTypes.string,
  pbShowSubText: PropTypes.bool,
  onMouseMove: PropTypes.any,
  onClick: PropTypes.any,
  varState: PropTypes.oneOf(["off", "on"]),
  vD: PropTypes.string,
  v: PropTypes.string,
  vA: PropTypes.string,
  vCff: PropTypes.string,
};
