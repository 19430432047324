import _, { flatMap } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { names2 } from './names';
import { advices } from './data/google/processor/data_char2advices.mjs';
import { createCharacter2 } from './character2.mjs';
import { roles, rolesBykey } from './data/google/processor/data_char2roles.mjs';
import { Rng } from './rand.mjs';
import { firearms, tmpl_firearm_unarmed } from './presets_firearm';
import { options as data_itemOptions } from './data/google/processor/data_itemOptions.mjs'
import { gears_vest_bulletproof } from './presets_gear';
import { throwables } from './presets_throwables.mjs';
import { FirearmLabel, GearLabel, ThrowableLabel, UtilityLabel, FirearmBox, GearBox, ThrowableBox, CashBox, NoneBox } from './GearView';
import { CharacterCard, CharacterCardCompact } from './CharacterView2';
import {
  FH1Button as ButtonPrimary,
  FH1ButtonTiny as Button,
  FH1ButtonInline as ButtonInline,
  FH1Grade as Grade,
  FH1KVP as KVP,
  FH1Panel as Panel,
  FH1PanelSmall as PanelSmall,
  FH1ProgressBar as ProgressBar,
  FH1Tabs as Tabs,
  FH1Well as Well,
  FH1WellSeg as WellSegment,
  FH1Window as Window,
  FH1Check as CheckBox,
  FH1ButtonFoldable as ButtonFoldable,
} from './component/figma/fh1';
import { L } from './localization.mjs';
import { DEFAULT_FIREARM, DEFAULT_EQUIPMENT, DEFAULT_THROWABLE, DEFAULT_UTILITY } from './character.mjs';
import { aptitudeMultiplier } from './data/google/processor/data_char2aptitudeMultiplier.mjs';
import { GAMEFACE } from './gameface';

export function VirtualAgentEquipButton(props) {
  const { virtualAgent, onVirtualAgentEquipAvail, onEquipPopup, ty } = props;
  const { role, equips } = virtualAgent;
  const { cur, avail, reason } = onVirtualAgentEquipAvail(virtualAgent, ty);

  return <ButtonInline label={cur} disabled={!avail} onClick={() => onEquipPopup(virtualAgent, ty)} className='equip' />;
}
export function LoadoutPowerEvalView(props) {
  const { effectiveDistanceData, powerRate, antiPersonalPowerRate, antiBulletProofPowerRate } = props

  const xValues = Array.from({ length: effectiveDistanceData.length }, (_, i) => i * 10)
  const yValues = ["distance"];
  const zValues = [effectiveDistanceData];

  const colorscale = [
    [0, '#ffffff'],
    [1, '#ff0000']
  ]

  var heatmapdata = [{
    x: xValues,
    y: yValues,
    z: zValues,
    type: "heatmap",
    colorscale: colorscale,
    zmax: 1,
    zmin: 0,
    showscale: false,
    yref: "container",
    xref: "container"
  }];

  const layout = {
    width: 600,
    height: 60,
    margin: {
      t: 10,
      b: 30
    }
  }

  const config = {
    displaylogo: false,
    staticPlot: true
  }

  function powerToStar(powerRate) {
    let star = ""

    for (let i = 1; i <= powerRate / 2; i++) {
      star += "★"
    }
    if (powerRate % 2 == 1) {
      star += "☆"
    }
    return star;
  }

  const squadPowerRateStar = powerToStar(powerRate);
  const antiPersonalPowerRateStar = powerToStar(antiPersonalPowerRate);
  const antiBulletProofPowerRateStar = powerToStar(antiBulletProofPowerRate);
  // i18n ignore 7
  return <div>
    <p>전투력 : {squadPowerRateStar}</p>
    <p>대인 화력 : {antiPersonalPowerRateStar}</p>
    <p>대방탄 화력 : {antiBulletProofPowerRateStar}</p>
    <p>유효 전투 사거리</p>
    <Plot data={heatmapdata} layout={layout} config={config} />
  </div>
}

export function MakeAdvice(agents, inventories) {
  const leftFirearms = inventories.filter((e) => e.ty == 'firearm').map((e) => e.firearm);
  const leftEquipments = inventories.filter((e) => e.ty == 'equipment').map((e) => e.equipment);

  let unarmedFirearmList = [];
  let unarmedEquipmentList = [];
  let unarmedThrowableList = [];
  let improperFirearmFlag = false;
  let notEnoughAgentFlag = false;
  let betterFirearmList = [];
  let betterEquipmentList = [];
  let sameFirearmFlag = false;
  let sameRoleFlag = false;

  const squadRoles = [];
  const squadFirearmTypes = [];

  const adviceList = [];

  for (let i = 0; i < 4; i++) {
    if (i + 1 > agents.length || agents[i] === null) {
      notEnoughAgentFlag = true;
      continue;
    }
    const agent = agents[i];
    const { firearm, equipment, throwable } = agent;

    if (firearm.firearm_ty == tmpl_firearm_unarmed.firearm_ty) {
      unarmedFirearmList.push({ name: agent.name })
    } else {
      const firearmAptitude = agent.firearmAptitudes[firearm.firearm_ty.toUpperCase()];
      if (firearmAptitude["Text"] == "D") {
        improperFirearmFlag = true;
      }

      for (const leftFirearm of leftFirearms) {
        if (leftFirearm.firearm_ty == firearm.firearm_ty && leftFirearm.firearm_rate < firearm.firearm_rate) {
          betterFirearmList.push({ name: agent.name, firearm_ty: firearm.firearm_ty.toUpperCase() });
          break;
        }
      }
    }

    if (equipment.key == DEFAULT_EQUIPMENT.key) {
      unarmedEquipmentList.push({ name: agent.name });
    } else {
      for (const leftequipment of leftEquipments) {
        if (leftequipment.vest_rate < equipment.vest_rate) {
          betterEquipmentList.push({ name: agent.name });
          break;
        }
      }
    }

    if (throwable.key == DEFAULT_THROWABLE.key) {
      //unarmedThrowableList.push({ name: agent.name });
    }
    squadRoles.push(agent.role)
    squadFirearmTypes.push(firearm.firearm_ty)
  }

  sameRoleFlag = !notEnoughAgentFlag && [...new Set(squadRoles)].length == 1
  sameFirearmFlag = !notEnoughAgentFlag && [...new Set(squadFirearmTypes)].length == 1 && unarmedFirearmList.length == 0

  if (notEnoughAgentFlag) {
    adviceList.push({
      warningLevel: advices.notEnoughAgents.warningLevel,
      warningColor: advices.notEnoughAgents.warningColor,
      advice: L('loc_data_longtext_squad_advice_notEnoughAgents'),
    })
  }

  for (const element of unarmedFirearmList) {
    adviceList.push({
      warningLevel: advices.unarmedFirearm.warningLevel,
      warningColor: advices.unarmedFirearm.warningColor,
      advice: L('loc_data_longtext_squad_advice_unarmedFirearm', { name: element.name }),
    })
  }

  for (const element of unarmedEquipmentList) {
    adviceList.push({
      warningLevel: advices.unarmedEquipment.warningLevel,
      warningColor: advices.unarmedEquipment.warningColor,
      advice: L('loc_data_longtext_squad_advice_unarmedEquipment', { name: element.name }),
    })
  }

  for (const element of unarmedThrowableList) {
    adviceList.push({
      warningLevel: advices.unarmedThrowable.warningLevel,
      warningColor: advices.unarmedThrowable.warningColor,
      advice: L('loc_data_longtext_squad_advice_unarmedThrowable', { name: element.name }),
    })
  }

  for (const element of betterFirearmList) {
    adviceList.push({
      warningLevel: advices.betterFirearm.warningLevel,
      warningColor: advices.betterFirearm.warningColor,
      advice: L('loc_data_longtext_squad_advice_betterFirearm', { name: element.name, ty: element.firearm_ty }),
    })
  }

  for (const element of betterEquipmentList) {
    adviceList.push({
      warningLevel: advices.betterEquipment.warningLevel,
      warningColor: advices.betterEquipment.warningColor,
      advice: L('loc_data_longtext_squad_advice_betterEquipment', { name: element.name }),
    })
  }

  if (sameRoleFlag) {
    adviceList.push({
      warningLevel: advices.sameRole.warningLevel,
      warningColor: advices.sameRole.warningColor,
      advice: L('loc_data_longtext_squad_advice_sameRole'),
    })
  }

  if (sameFirearmFlag) {
    adviceList.push({
      warningLevel: advices.sameFirearm.warningLevel,
      warningColor: advices.sameFirearm.warningColor,
      advice: L('loc_data_longtext_squad_advice_sameFirearm'),
    })
  }

  function compareWarningLevel(a, b) {
    return b.warningLevel - a.warningLevel;
  }

  adviceList.sort(compareWarningLevel)
  return adviceList;
}

export function LoadoutAdviseView(props) {
  const { agents, inventories } = props;

  const adviceList = MakeAdvice(agents, inventories)

  return <div>
    {adviceList.map((element) => {
      return <p>
        <span style={{ fontSize: 15, color: element.warningColor }}>!</span>
        {element.advice}
      </p>
    })}
  </div>
}

function VirtualAgentItemGears(props) {
  const { virtualAgent, onVirtualAgentEquipAvail, onEquipPopup } = props;
  const { role, equips } = virtualAgent;
  const { firearmLabel, gearLabel, throwableLabel, utilityLabel } = props;
  const { firearm, equipment, throwable, utility } = equips;

  function btn(ty) {
    return <VirtualAgentEquipButton virtualAgent={virtualAgent} readonly={false} onVirtualAgentEquipAvail={onVirtualAgentEquipAvail} onEquipPopup={onEquipPopup} ty={ty} />;
  }

  let firearmbutton = btn('firearm');
  let equipmentbutton = btn('equipment');
  let throwablebutton = btn('throwable');
  let utilitybutton = btn('utility');

  if (firearmLabel) {
    return <div>
      <div className='agent-item-body'>{firearmbutton}</div>
    </div>
  }

  if (gearLabel) {
    return <div>
      <div className='agent-item-body'>{equipmentbutton}</div>
    </div>
  }

  if (throwableLabel) {
    return <div>
      <div className='row'>
        <div className='agent-item-body'>{throwablebutton}</div>
      </div>
    </div>
  }

  // i18n ignore 6
  let gears = [
    { key: 'main', label: L('loc_ui_string_common_firearm'), view: firearmbutton },
    { key: 'equip', label: L('loc_ui_string_common_equipment'), view: equipmentbutton },
    { key: 'throw', label: L('loc_ui_string_common_throwable'), view: throwablebutton },
    { key: 'util', label: '유틸', view: utilitybutton },
  ];

  let filter = [];
  // if (agent.role === 'assault') {
  //   filter = filter.concat(['equip', 'throw0', 'throw1', 'util']);
  // } else if (agent.role === 'tank') {
  //   filter = filter.concat(['throw0', 'throw1', 'util']);
  // } else if (agent.role === 'support') {
  //   filter = filter.concat(['equip', 'util']);
  // }
  gears = gears.filter(({ key }) => !filter.includes(key));

  return <>
    <div className='agent-profile-item'>
      {gears.map(({ key, label, view }) => {
        if (view === null) {
          return null;
        }
        return <div className='agent-profile-item-row' key={key}>
          <div className='agent-item-title'>{label}</div>
          <div className='agent-item-body'>{view}</div>
        </div>;
      })}
    </div>
  </>;
}

function VirtualAgentItem(props) {
  const { idx, rolekey, agent, equips } = props;
  const { agents_all, squad } = props;
  const { onChangeAgentOrder, onRoleChange, onAgentChange, onVirtualAgentEquipAvail, onEquipPopup, onAgentDetail } = props;

  const buttons = []

  if (idx > 0) {
    buttons.push(<ButtonInline key="up" onClick={() => onChangeAgentOrder(idx, idx - 1)}>▲</ButtonInline>);
  }

  if (idx < 3) {
    buttons.push(<ButtonInline key="down" onClick={() => onChangeAgentOrder(idx, idx + 1)}>▼</ButtonInline>);
  }

  // i18n ignore 32
  return <div className="grind-virtualagent-item" key={idx} style={{ marginTop: 10 }}>
    <label htmlFor="role">역할</label>
    <select id="role" value={rolekey} onChange={(e) => onRoleChange(idx, e.target.value)}>
      {roles.map((role) => <>
        <option key={role.key} value={role.key}>{role.name}</option>
        <option disabled={true} value={role.key + '_descr'}>{role.descr}</option>
      </>)}
    </select>
    <label htmlFor="agent">용병</label>
    <select id="agent" value={-1} onChange={(e) => onAgentChange(idx, e.target.value)}>
      <option value={-1}>용병을 선택하세요</option>
      {agents_all.filter((agent, index) => agent.role === rolekey && squad.filter((agent) => agent.agentIdx == index) == 0).map((agent) => <option key={agent.idx} value={agent.idx}>{agent.name}</option>)}
    </select>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div >
        {agent ? <CharacterCardCompact
          character={agent}
          onAgentDetail={onAgentDetail}
        />
          : null}
      </div>
      <div>
        {rolekey ? <VirtualAgentItemGears
          virtualAgent={{ idx, role: agent != null ? agent.role : rolekey, agent, equips, }}
          onVirtualAgentEquipAvail={onVirtualAgentEquipAvail}
          onEquipPopup={onEquipPopup}
          onAgentDisarm={null} />
          : null}
      </div>
    </div>
    {buttons}
  </div>;
}

export function VirtualAgentsView(props) {
  const { agents_all, squad, simRef, m } = props;
  const { onSimEvent, onFinish } = props;
  const { onVirtualAgentEquipAvail, onAgentUpdate, onEquipPopup, onAgentDetail, onAssign, onUnassign, onSquadStop, onSquadStart, onSquadLootCollect, onSquadLootDecompose, onSquadSetConfig } = props;
  const { onUpdateSquad } = props;

  const [showStrategy, setShowStrategy] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  function onRoleChange(idx, rolekey) {
    const newSquad = [...squad];
    newSquad[idx].rolekey = rolekey;
    onUpdateSquad(newSquad);
  }

  function onAgentChange(idx, agent_idx) {
    const newSquad = [...squad];
    newSquad[idx].agent = agent_idx ? agents_all[agent_idx] : null;
    newSquad[idx].agentIdx = agent_idx ? agent_idx : null;
    onUpdateSquad(newSquad);
  }

  function onChangeAgentOrder(prevIndex, newIndex) {
    const newSquad = squad;
    [newSquad[prevIndex], newSquad[newIndex]] = [squad[newIndex], squad[prevIndex]];
    onUpdateSquad(newSquad);
  }

  return <div className="box grind-squadbox">
    {squad.map((virtualAgent, i) => <VirtualAgentItem
      idx={i}
      key={i}
      rolekey={virtualAgent.rolekey}
      agent={virtualAgent.agent}
      equips={virtualAgent.equips}
      agents_all={agents_all}
      squad={squad}
      onChangeAgentOrder={onChangeAgentOrder}
      onRoleChange={onRoleChange}
      onAgentChange={onAgentChange}
      onEquipPopup={onEquipPopup}
      onAgentDetail={onAgentDetail}
      onVirtualAgentEquipAvail={onVirtualAgentEquipAvail} />)}
  </div>;
}

export function calcEffectiveDistance(firearms) {
  let firearmsNum = 0;
  const effectiveDistanceData = [];
  for (const firearm of firearms) {
    if (firearm.firearm_ty === 'unarmed') {
      continue;
    }

    const { firearm_range, firearm_range_optimal_min, firearm_range_optimal_max, firearm_range_penalty_mult } = firearm;

    firearmsNum++;
    for (let i = 0; i <= (firearm_range / 10); i++) {
      let range = i * 10;
      let value = 1;
      if (range >= firearm_range * firearm_range_optimal_min && range <= firearm_range * firearm_range_optimal_max) {
        value = firearm_range_penalty_mult;
      }

      if (i >= effectiveDistanceData.length) {
        effectiveDistanceData.push(value);
      } else {
        effectiveDistanceData[i] += value;
      }
    }
  }

  for (let i = 0; i < effectiveDistanceData.length; i++) {
    effectiveDistanceData[i] /= firearmsNum;
  }
  return effectiveDistanceData;
}

export function calcSquadPower(agents, firearms) {
  const MAXIMUM_POWER = 4 * (70 + 5 * 10) * 1.1;
  let squadPower = 0;

  for (let i = 0; i < agents.length; i++) {
    const agent = agents[i];
    const firearm = firearms[i];
    if (agent != null && firearm.firearm_ty != 'unarmed') {
      const firearmAptitudes = agent.firearmAptitudes[firearm.firearm_ty.toUpperCase()];
      squadPower += (agent.power + (6 - firearm.firearm_rate) * 10) * firearmAptitudes.Value;
    }
  }
  const powerRate = squadPower > 0 ? Math.max(Math.round(squadPower / MAXIMUM_POWER * 10), 1) : 0
  return powerRate;
}

export function calcAntiPersonalPower(agents) {
  let totalAntiPersonalPower = 0
  const MaxtotalAntiPersonalPower = 4928;

  for (const agent of agents) {
    if (agent == null) {
      continue;
    }
    const firearm = agent.firearm;

    if (firearm.firearm_ty == tmpl_firearm_unarmed.firearm_ty) {
      continue;
    }
    const firearmAptitudes = agent.firearmAptitudes[firearm.firearm_ty.toUpperCase()].Value;
    totalAntiPersonalPower += firearmAptitudes * firearm.firearm_projectile_expectedDamage + agent.realStats.shooting * 1.5;
  }
  const calcAntiPersonalPowerRate = totalAntiPersonalPower > 0 ? Math.max(Math.round(totalAntiPersonalPower / MaxtotalAntiPersonalPower * 10), 1) : 0
  return calcAntiPersonalPowerRate;
}

export function calcAntiBulletproofpower(agents) {
  let totalAntiBulletproofpower = 0;
  const MaxAntiBulletproofpower = 4140;

  for (const agent of agents) {
    if (agent == null) {
      continue;
    }
    const firearm = agent.firearm;

    if (firearm.firearm_ty == tmpl_firearm_unarmed.firearm_ty) {
      continue;
    }
    const firearmAptitudes = agent.firearmAptitudes[firearm.firearm_ty.toUpperCase()].Value;
    totalAntiBulletproofpower += firearmAptitudes * firearm.firearm_projectile_expectedPenetrate + agent.realStats.shooting * 1.5;
  }
  const calcAntiBulletproofPowerRate = totalAntiBulletproofpower > 0 ? Math.max(Math.round(totalAntiBulletproofpower / MaxAntiBulletproofpower * 10), 1) : 0;
  return calcAntiBulletproofPowerRate;
}

export class LoadoutView extends React.Component {
  constructor(props) {
    super(props);
    const rng = new Rng(Rng.randomseed());
    this.state = {
      rng: rng,
      characters: new Array(24).fill(0).map((arr, idx) => {
        const role = roles[idx % roles.length];
        const name = rng.choice(names2);
        return createCharacter2({ idx, rng, name, role });
      }),
      firearms: new Array(20).fill(0).map(() => {
        return this.createFirearm(rng);
      }),
      gears: new Array(10).fill(0).map(() => {
        return this.createGearVest(rng);
      }),
      throwables: new Array(10).fill(0).map(() => {
        return this.createThrowable(rng);
      }),
      utilities: [],
      squad: [
        {
          rolekey: 'pointman',
          agent: null,
          agentIdx: null,
          equips: {
            firearm: tmpl_firearm_unarmed,
            equipment: DEFAULT_EQUIPMENT,
            throwable: DEFAULT_THROWABLE,
            utility: DEFAULT_UTILITY,
          },
        },
        {
          rolekey: 'pointman',
          agent: null,
          agentIdx: null,
          equips: {
            firearm: tmpl_firearm_unarmed,
            equipment: DEFAULT_EQUIPMENT,
            throwable: DEFAULT_THROWABLE,
            utility: DEFAULT_UTILITY,
          },
        },
        {
          rolekey: 'pointman',
          agent: null,
          agentIdx: null,
          equips: {
            firearm: tmpl_firearm_unarmed,
            equipment: DEFAULT_EQUIPMENT,
            throwable: DEFAULT_THROWABLE,
            utility: DEFAULT_UTILITY,
          },
        },
        {
          rolekey: 'pointman',
          agent: null,
          agentIdx: null,
          equips: {
            firearm: tmpl_firearm_unarmed,
            equipment: DEFAULT_EQUIPMENT,
            throwable: DEFAULT_THROWABLE,
            utility: DEFAULT_UTILITY,
          },
        },
      ],
      effectiveDistanceData: [],
      powerRate: 0,
      overlays: [],
    }
  }

  //#region item

  applyRandomOption(item, ty, rng) {
    let options = [];
    if (ty === 'firearm') {
      options = data_itemOptions.filter((d) => d.item_type === 'firearm' && d.item_group.find((i) => i === item.firearm_ty));
    }
    else if (ty === 'equipment') {
      options = data_itemOptions.filter((d) => d.item_type === 'equipment');
    }
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            item.buy_cost *= value;
            item.sell_cost *= value;
          }
          else if (item[modifier]) {
            item[modifier] *= value;
          }
        }
        item.options.push(option.name);
        return;
      }
    }
  }

  //#region firearm
  createFirearm(rng) {
    const firearm_base = rng.choice(firearms.filter((f) => f.firearm_ty !== 'sr'));

    const firearm = { ...firearm_base, options: [] };
    this.applyRandomOption(firearm, 'firearm', rng);
    const item = {
      ty: 'firearm',
      buy_cost: firearm.buy_cost,
      sell_cost: firearm.sell_cost,
      firearm: { ...firearm },
    };
    return item;
  }

  createUnarmed() {
    const firearm_base = tmpl_firearm_unarmed;
    const firearm = { ...firearm_base, options: [] };

    const item = {
      ty: 'firearm',
      buy_cost: 0,
      sell_cost: 0,
      firearm: { ...firearm },
    };
    return item;

  }
  //#endregion

  //#region gear
  createGearVest(rng) {
    const equipment_base = rng.choice(gears_vest_bulletproof);
    const equipment = { ...equipment_base, options: [] };
    this.applyRandomOption(equipment, 'equipment', rng);
    const item = {
      ty: 'equipment',
      buy_cost: equipment.buy_cost,
      sell_cost: equipment.sell_cost,
      equipment: { ...equipment },
    };
    return item;
  }
  //#endregion

  //#region throwables
  createThrowable(rng) {
    const throwable_base = rng.choice(throwables)
    const throwable = { ...throwable_base, options: [] };
    this.applyRandomOption(throwable, 'throwable', rng);
    const item = {
      ty: 'throwable',
      buy_cost: throwable.buy_cost,
      sell_cost: throwable.sell_cost,
      throwable: { ...throwable },
    };
    return item;
  }
  //#endregion

  //#endregion

  onUpdateSquad(squad) {
    for (const virtualAgent of squad) {
      if (virtualAgent.equips.firearm.firearm_ty === 'dmr' && virtualAgent.agent != null && virtualAgent.agent.role != 'sharpshooter') {
        this.onVirtualAgentEquip(virtualAgent, this.createUnarmed());
      }
    }
    const powerRate = calcSquadPower(squad.map((e) => e.agent), squad.map((e) => e.equips.firearm))
    this.setState({ squad, powerRate });
  }

  onVirtualAgentEquipAvail(virtualAgent, equip_ty) {
    const { role, equips } = virtualAgent;
    const { firearms, gears, throwables, utilities } = this.state;

    let cur = null;
    let avail = true; // need role check
    let reason = null;
    if (equip_ty === 'firearm') {
      cur = <FirearmLabel firearm={equips.firearm} />;
      avail = true;
    } else if (equip_ty === 'equipment') {
      cur = <GearLabel equipment={equips.equipment} />;
      if (role === 'tank') {
        avail = true;
      }
    } else if (equip_ty === 'throwable') {
      cur = <ThrowableLabel throwable={equips.throwable} />;
      if (role === 'support') {
        avail = true;
      }
    } else if (equip_ty === 'utility') {
      cur = <UtilityLabel utility={equips.utility} />;
      if (role === 'support') {
        avail = true;
      }
    }
    else {
      throw new Error(`unknown equip_ty ${equip_ty}`);
    }
    return {
      cur,
      avail: reason === null,
      reason,
    };
  }

  onEquipPopup(virtualAgent, equip_ty) {
    this.overlayPush({ ty: 'EQUIP', equip_ty, virtualAgent });
  }

  onVirtualAgentEquip(virtualAgent, item) {
    const { firearms, gears, throwables, utilities, squad } = this.state;
    const { ty } = item;
    if (ty === 'firearm') {
      const idx = firearms.findIndex((e) => e.firearm === item.firearm);
      if (item.firearm.firearm_ty != 'unarmed') {
        firearms.splice(idx, 1);
      }

      if (virtualAgent.equips.firearm.firearm_ty != 'unarmed') {
        firearms.push({ ty: ty, buy_cost: virtualAgent.equips.firearm.buy_cost, sell_cost: virtualAgent.equips.firearm.sell_cost, firearm: virtualAgent.equips.firearm });
      }
      virtualAgent.equips.firearm = item.firearm;
    } else if (ty === 'equipment') {
      const idx = gears.findIndex((e) => e.equipment === item.equipment);
      gears.splice(idx, 1);
      gears.push({ ty: ty, buy_cost: virtualAgent.equips.equipment.buy_cost, sell_cost: virtualAgent.equips.equipment.sell_cost, equipment: virtualAgent.equips.equipment });
      virtualAgent.equips.equipment = item.equipment;
    } else if (ty === 'throwable') {
      const idx = throwables.findIndex((e) => e.throwable === item.throwable);
      throwables.splice(idx, 1);
      throwables.push({ ty: ty, buy_cost: virtualAgent.equips.throwable.buy_cost, sell_cost: virtualAgent.equips.throwable.sell_cost, throwable: virtualAgent.equips.throwable });
      virtualAgent.equips.throwable = item.throwable;
    } else if (ty === 'utility') {
      const idx = utilities.findIndex((e) => e.utility === item.utility);
      utilities.splice(idx, 1);
      utilities.push({ ty: ty, buy_cost: virtualAgent.equips.utility.buy_cost, sell_cost: virtualAgent.equips.utility.sell_cost, utility: virtualAgent.equips.utility });
      virtualAgent.equips.utility = item.utility;
    }
    const effectiveDistanceData = calcEffectiveDistance(squad.map((e) => e.equips.firearm));
    const powerRate = calcSquadPower(squad.map((e) => e.agent), squad.map((e) => e.equips.firearm))
    this.setState({ firearms, gears, throwables, utilities, effectiveDistanceData, powerRate });
  }

  get overlay_cur() {
    const { overlays } = this.state;
    if (overlays.length === 0) {
      return null;
    }
    return overlays[overlays.length - 1];
  }

  overlayPush(overlay) {
    let { overlays } = this.state;
    overlays.push(overlay);
    this.setState({ overlays });
  }

  overlayUpdate(obj) {
    const overlays = this.state.overlays.slice();
    const last = this.overlay_cur;
    overlays[overlays.length - 1] = { ...last, ...obj };
    this.setState({ overlays });
  }

  overlayPop() {
    const overlays = this.state.overlays.slice();
    overlays.pop();
    this.setState({ overlays });
  }

  overlayPopMaybe(ty) {
    if (this.overlay_cur?.ty === ty) {
      this.overlayPop();
    }
  }

  renderOverlay() {
    const { overlays, game } = this.state;

    if (overlays.length === 0) {
      return null;
    }

    let overlay = overlays[overlays.length - 1];

    let content = null;
    if (overlay.ty === 'EQUIP') {
      content = this.renderVirtualAgentEquip(overlay);
    } else if (overlay.ty === 'PREVIEW2') {
      content = this.renderAgent2Detail(overlay);
    }

    return <div className="overlay-root">
      {content}
    </div>;
  }

  renderVirtualAgentEquip(overlay) {
    const { firearms, gears, throwables, utilities } = this.state;
    const { virtualAgent, equip_ty } = overlay;
    const { role, equips, agent } = virtualAgent;

    const firearmFilter = (e) => {
      if (['hg', 'sg', 'smg', 'ar', 'unarmed'].includes(e.firearm.firearm_ty)) {
        return true;
      }
      if (role === 'sharpshooter' && e.firearm.firearm_ty === 'dmr') {
        return true;
      }
      if (role === 'vanguard') {
        // hg with shield를 장착할 수 있게 해야 함
      }
      return false;
    };

    // i18n ignore 17
    const firearmAptitudeLabel = {
      1: '최고',
      2: '우수',
      3: '보통',
      4: '미흡',
      5: '최악',
      6: '불가',
    }

    const firearmAptitudeKey = {
      'hg': 'firearmAptitude_HG',
      'sg': 'firearmAptitude_SG',
      'smg': 'firearmAptitude_SMG',
      'ar': 'firearmAptitude_AR',
      'dmr': 'firearmAptitude_DMR',
    }

    function getFirearmAptitude(e) {
      return rolesBykey(role)['firearmAptitudes'][firearmAptitudeKey[e.firearm.firearm_ty]];
    }

    let equip_cur = null;
    let equip_list = null;
    if (equip_ty === 'firearm') {
      // i18n ignore 12
      equip_cur = <table>
        <thead>
          <tr>
            <th>적성</th>
            <th>총기</th>
          </tr>
        </thead>
        <tbody>
          <td>{firearmAptitudeLabel[getFirearmAptitude(equips)]}</td>
          <td><FirearmLabel firearm={equips.firearm} /></td>
        </tbody>
      </table>;
      // i18n ignore 28
      equip_list = <table>
        <thead>
          <tr>
            <th>적성</th>
            <th>총기</th>
            <th>행동</th>
          </tr>
        </thead>
        <tbody>
          {_.uniqBy(firearms, (e) => e.firearm.firearm_name + (e.firearm.options ?? []))
            .sort((a, b) => {
              return b.firearm.firearm_rate - a.firearm.firearm_rate;
            })
            .sort((a, b) => {
              return (getFirearmAptitude(a) ?? 7) - (getFirearmAptitude(b) ?? 0); // SR 데이터의 세이프가드
            })
            .map((e, i) => {
              return <tr key={i}>
                <td>{firearmAptitudeLabel[getFirearmAptitude(e)]}</td>
                <td><FirearmLabel firearm={e.firearm} /></td>
                <td>{getFirearmAptitude(e) < 6 ? <ButtonInline className="agent-equip-button" onClick={() => {
                  this.onVirtualAgentEquip(virtualAgent, e);
                  this.overlayPopMaybe('EQUIP');
                }}>{L('loc_ui_button_common_select')}</ButtonInline> : null}</td>
              </tr>;
            })}
        </tbody>
      </table>;
    } else if (equip_ty === 'equipment') {
      equip_cur = <GearLabel equipment={equips.equipment} />;
      equip_list = _.uniqBy(gears)
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <GearLabel equipment={e.equipment} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onVirtualAgentEquip(virtualAgent, e);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    } else if (equip_ty === 'throwable') {
      equip_cur = <ThrowableLabel throwable={equips.throwable} />;
      equip_list = _.uniqBy(throwables, (e) => e.throwable.throwable_name + (e.throwable.options ?? []))
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <ThrowableLabel throwable={e.throwable} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onVirtualAgentEquip(virtualAgent, e);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    }
    else if (equip_ty === 'utility') {
      equip_cur = <UtilityLabel utility={equips.utility} />;
      equip_list = _.uniqBy(utilities, (e) => e.utility.utility_name + (e.utility.options ?? []))
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <UtilityLabel utility={e.utility} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onVirtualAgentEquip(virtualAgent, e);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    }

    let label = equip_list.length === 0 ? L('loc_ui_string_popup_agent_equipment_no_alternatives')
      : L('loc_ui_string_popup_agent_equipment_alternative_exists');
    // i18n ignore 19
    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_dynamic_title_popup_agent_equipable_change_' + equip_ty, { name: rolesBykey(role).name + '(' + (agent ? agent.name : '용병 미배치') + ')' })}</p>

        <p className="mission-warning-overlay-body-important">{L('loc_ui_string_popup_agent_equipment_current')}</p>
        <div className="box item-equip-candidate">
          {equip_cur}
        </div>

        <p className="mission-warning-overlay-body-important">{label}</p>
        {equip_list}

        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('EQUIP');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;
  }

  renderAgent2Detail(overlay) {
    const { agent } = overlay;

    return <div className='overlay-agentpreview' onClick={() => this.overlayPopMaybe('PREVIEW2')}>
      <span onClick={(e) => { e.stopPropagation(); }}>
        <Window
          // i18n ignore 1
          title='요원 미리보기'
          showCloseButton={true}
          onClickButtonClose={() => this.overlayPopMaybe('PREVIEW2')}
          style={{ minHeight: 920, maxHeight: 920 }}
        >
          <Panel
            title={agent.name}
            subtitle={agent.callsign}
            style={{ width: 800 }}
          >
            <CharacterCard
              character={agent}
            />
          </Panel>
        </Window>
      </span>
    </div >;
  }

  render() {
    const { firearms, gears } = this.state;

    const inventories = [];
    for (const firearm of firearms) {
      inventories.push({ ty: 'firearm', firearm: firearm.firearm });
    }
    for (const equipment of gears) {
      inventories.push({ ty: 'equipment', equipment: equipment.equipment });
    }

    const antiPersonalPowerRate = calcAntiPersonalPower(
      this.state.squad.map((e) => {
        if (e.agent == null) {
          return null;
        } else {
          return {
            ...e.agent, firearm: e.equips.firearm
          }
        }
      })
    )

    const antiBulletProofPowerRate = calcAntiBulletproofpower(
      this.state.squad.map((e) => {
        if (e.agent == null) {
          return null;
        } else {
          return {
            ...e.agent, firearm: e.equips.firearm
          }
        }
      })
    )
    // i18n ignore 51
    return <div className="loadout-view">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="loadout-view virtual-agents-ready" >
          <VirtualAgentsView
            squad={this.state.squad}
            agents_all={this.state.characters}
            onUpdateSquad={this.onUpdateSquad.bind(this)}
            onVirtualAgentEquipAvail={this.onVirtualAgentEquipAvail.bind(this)}
            onEquipPopup={this.onEquipPopup.bind(this)}
            onAgentDetail={(agent) => this.overlayPush({ ty: 'PREVIEW2', agent })}
          />
        </div>

        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <h1 style={{ fontSize: 25 }}>전력 평가</h1>
          <LoadoutPowerEvalView
            effectiveDistanceData={this.state.effectiveDistanceData}
            powerRate={this.state.powerRate}
            antiPersonalPowerRate={antiPersonalPowerRate}
            antiBulletProofPowerRate={antiBulletProofPowerRate} />
        </div>
        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <h1 style={{ fontSize: 25 }}>어드바이스</h1>
          <LoadoutAdviseView agents={this.state.squad.map((e) => {
            if (!e.agent) {
              return null;
            }
            return {
              ...e.agent,
              ...e.equips,
            };
          })}
            inventories={inventories} />
        </div>
      </div>

      <div className="loadout-view agents-available">
        <h1>
          용병목록
        </h1>
        {this.state.characters.map((agent, i) => {

          return <div className="grind-inlinebox grind-agentbox" key={i}>
            <CharacterCardCompact character={agent}
              onAgentDetail={(agent) => this.overlayPush({ ty: 'PREVIEW2', agent })}
            />
          </div>;
        })}
      </div>
      {this.renderOverlay()}
    </div>;
  }
}
