import React, { useState, useRef } from 'react';
import { Rng } from './rand.mjs';
import { Simulator } from './sim';
import { CONFIGS } from './presets_grind.mjs';
import { gears_vest_bulletproof } from './presets_gear';
import { firearms } from './presets_firearm';
import { throwables } from './presets_throwables.mjs';
import { opts } from './opts.mjs';
import { SvgBadgePosition, RenderAgentSimple } from './FigmaListView.js';
import { L } from './localization.mjs';
import { tickToDateTimeStrObj } from './tick';
import { LobbySquadDrawer } from './FigmaLobbyView.js';

import {
  FH1ButtonInline as ButtonInline,
} from './component/figma/fh1';
import './FigmaPlanView.css';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView.js';
import { DivCanvas, SvgPolicyIcon } from './Editor.js';
import { SoundButton, triggerSound } from './sound.mjs';
import { PowerIndicator } from './Badges.js';

const DEBUG = process.env.NODE_ENV === 'development';
const DEBUG_ALL_POLICY = DEBUG && false;

const ICON_CLOSE = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path d="M26.3995 10.3432L22.1569 6.10052L16.5 11.7574L10.8431 6.10052L6.60051 10.3432L12.2574 16L6.60051 21.6569L10.8431 25.8995L16.5 20.2427L22.1569 25.8995L26.3995 21.6569L20.7426 16L26.3995 10.3432Z" fill="#3D301C" />
</svg>;

export class FigmaPlanRoot extends React.Component {
  constructor(props) {
    super(props);

    const rng = new Rng();

    const game = props.game;
    game.reset(opts.GRIND_SELECTED_MAP);

    const agents = game.recruit_listings.slice(0, 4);
    for (const agent of agents) {
      game.recruitAgent(agent);
      game.onAssign(0, agent);
    }
    game.renewMission(0);
    const sim = Simulator.create({ ...game.squads[0].mission_state.simstate, m: this.props.m });

    this.state = {
      rng,
      game,
    };
  }

  render() {
    const { game } = this.state;

    return <div className='figmaplan-testroot'>
      <FigmaPlanPopupView
        game={game}
        squad={game.squads[0]}
        onReset={() => {
          game.resetSquadControls(0);
          this.setState({ game });
        }}
        onAgentUpdate={(agent) => this.setState({ game })}
      />
    </div>;
  }
}

function FigmaPlanStrategySidebar(props) {
  const { current, sidebar, onClickPolicy, onClickEnemy, squad, agents, controlSet, agentControlSet, segments, enemies, prev_week_enemies, enemies_diff, currentPlan, plans, onClickPlan, planControlSet, planAgentControlSet } = props;
  if (current < 0) {
    return <div className='figmaplan-strategy-sidebar'>
      <div className='figmaplan-sidebar-container'>
        <div className='figmaplan-strategy-policy-selector-title'>{L('loc_ui_string_mission_plan_select_plan')}</div>
        <div className='figmaplan-strategy-plan-selector-desc'>{L('loc_ui_longtext_mission_plan_select_plan_desc')}</div>
        <MissionPlanSelector currentPlan={currentPlan} plans={plans} onClickPlan={onClickPlan} />
      </div>
    </div>;
  }

  return <div className='figmaplan-strategy-sidebar'>
    <div className='figmaplan-strategy-sidebar-header'>
      <SoundButton
        className={'figmaplan-strategy-sidebar-header-button' + (sidebar === 'policy' ? ' active' : '')}
        onClick={onClickPolicy}
      >{L('loc_ui_string_mission_plan_policy')}</SoundButton>
      <SoundButton
        className={'figmaplan-strategy-sidebar-header-button' + (sidebar === 'enemy' ? ' active' : '')}
        onClick={onClickEnemy}
      >{L('loc_ui_string_mission_plan_enemy_info')}</SoundButton>
    </div>
    {sidebar === 'policy' && SegmentPolicySelector({ current, squad, agents, controlSet, agentControlSet, segment: segments[current], currentPlan, planControlSet, planAgentControlSet })}
    {sidebar === 'enemy' && SegmentEnemyInfo({
      enemies: enemies.map((e, i) => { return { ...e, idx: i } }).filter((e) => segments[current].spawnareas.includes(e.spawnarea)),
      prev_week_enemies,
      enemies_diff
    })}
  </div>;
}

function MissionPlanSelector(props) {
  const { currentPlan, plans, onClickPlan } = props;
  return <div className='figmaplan-strategy-plan-selector'>
    {plans.map((p, i) => {
      return <div
        key={i}
        className={'figmaplan-strategy-plan-selector-item' + (currentPlan === i ? ' active' : '')}
        onClick={() => {
          onClickPlan(i);
          triggerSound('UI_Outgame_Button_Click_Default');
        }}>
        <div className='figmaplan-strategy-plan-selector-item-img'>
          <img src={p.imgSrc} />
        </div>
        <div className='figmaplan-strategy-plan-selector-item-title'>{L(p.title)}</div>
        <div className='figmaplan-strategy-plan-selector-item-desc'>{L(p.desc)}</div>
      </div>;
    })}
  </div>;
}

const svg_icon_enemy_vest = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
  <path d="M10.359 0L9.94871 3.58064C10.2222 4.32257 10.9744 5.80644 12 6.19353V13.7419L10.7692 15L1.23077 15L0 13.7419V6.19357C1.02564 5.80647 1.77778 4.32261 2.05129 3.58067L1.64103 3.59273e-05L3.58976 0.483906L3.69232 3.4839C4.19163 4.52036 5.33913 4.73115 5.99883 4.74154C6.65815 4.73162 7.80778 4.52155 8.30768 3.48386L8.41024 0.48387L10.359 0Z" fill="black" />
</svg>;

function SegmentEnemyInfo(props) {
  const { enemies, prev_week_enemies, enemies_diff } = props;

  function estimateEnemyPower(enemy) {
    const _firearm_stat = enemy._firearm_stat ?? null;
    const _stat = enemy._stat ?? null;
    if (!_stat) {
      return 'Unknown';
    }
    const armor = enemy.vest_shield ?? 0;

    let damage = 0;
    let penetrate = 0;
    if (_firearm_stat) {
      damage = _firearm_stat.firearm_projectile_expectedDamage;
      penetrate = _firearm_stat.firearm_projectile_expectedPenetrate;
    }
    const addedShooting = enemy.firearm_expectedPower;
    const addedPhysical = armor / 44;
    const estimatedPower = (_stat.mental + _stat.perception + _stat.physical + _stat.shooting + _stat.tactical
      + addedShooting + addedPhysical) / 5;

    return estimatedPower;
  }

  return <>
    {enemies.map((e, i) => {
      if (enemies_diff.added.find((element) => element.enemy_idx === e.idx)) {
        return <div key={i} className='figmaplan-strategy-enemy'>
          <PowerIndicator character={e} starSize={20} />
          <div className='figmaplan-strategy-enemy-name'>
            {L('loc_ui_string_common_role_enemy_' + e.role)} {L('loc_ui_string_agent_power')} <font color='#FF00FF'> (NEW!!)</font>
          </div>
          {e.firearm_name && <div className='figmaplan-strategy-enemy-firearm'>
            <div className='figmaplan-strategy-enemy-firearm-ty'>{e.firearm_ty.toUpperCase()}</div>
            <div className='figmaplan-strategy-enemy-firearm-name'>{L(e.firearm_name)}</div>
          </div>}
          {e.vest_name && <div className='figmaplan-strategy-enemy-vest'>
            {svg_icon_enemy_vest}
            <div className='figmaplan-strategy-enemy-vest-name'>{L(e.vest_name)}</div>
          </div>}
        </div>;
      }

      const updated = enemies_diff.updated.find((element) => element.enemy_idx === e.idx);
      if (updated) {
        const prev_week_enemy = prev_week_enemies[updated.prev_week_enemy_idx];
        return <div key={i} className='figmaplan-strategy-enemy'>
          <div className='figmaplan-strategy-enemy-name'>
            {L('loc_ui_string_common_role_enemy_' + prev_week_enemy.role)} {L('loc_ui_string_agent_power')}: {estimateEnemyPower(prev_week_enemy).toFixed(1)} → {L('loc_ui_string_common_role_enemy_' + e.role)} {L('loc_ui_string_agent_power')}: {estimateEnemyPower(e).toFixed(1)} <font color='#FFFF00'> (UPDATED!!)</font>
          </div>
          {e.firearm_name && <div className='figmaplan-strategy-enemy-firearm'>
            {updated.firearm_updated ? <>
              <div className='figmaplan-strategy-enemy-firearm-ty'>{prev_week_enemy.firearm_ty.toUpperCase()}</div>
              <div className='figmaplan-strategy-enemy-firearm-name'>{L(prev_week_enemy.firearm_name)}</div>
              →
            </> : null}
            <div className='figmaplan-strategy-enemy-firearm-ty'>{e.firearm_ty.toUpperCase()}</div>
            <div className='figmaplan-strategy-enemy-firearm-name'>{L(e.firearm_name)}</div>
          </div>}
          {e.vest_name && <div className='figmaplan-strategy-enemy-vest'>
            {updated.equipment_updated ? <>
              {svg_icon_enemy_vest}
              <div className='figmaplan-strategy-enemy-vest-name'>{prev_week_enemy.vest_name ? L(prev_week_enemy.vest_name) : 'X'}</div>
              →
            </> : null}
            {svg_icon_enemy_vest}
            <div className='figmaplan-strategy-enemy-vest-name'>{L(e.vest_name)}</div>
          </div>}
        </div>;
      }
      else {
        return <div key={i} className='figmaplan-strategy-enemy'>
          <PowerIndicator character={e} starSize={20} />
          <div className='figmaplan-strategy-enemy-name'>
            {L('loc_ui_string_common_role_enemy_' + e.role)} {L('loc_ui_string_agent_power')}
          </div>
          {e.firearm_name && <div className='figmaplan-strategy-enemy-firearm'>
            <div className='figmaplan-strategy-enemy-firearm-ty'>{e.firearm_ty.toUpperCase()}</div>
            <div className='figmaplan-strategy-enemy-firearm-name'>{L(e.firearm_name)}</div>
          </div>}
          {e.vest_name && <div className='figmaplan-strategy-enemy-vest'>
            {svg_icon_enemy_vest}
            <div className='figmaplan-strategy-enemy-vest-name'>{L(e.vest_name)}</div>
          </div>}
        </div>;
      }
    })}
  </>;
}

function FigmaPlanStrategyHeader(props) {
  const headers = MakePlanStrategyHeaders(props);
  return <div className='figmaplan-strategy-header-overlay'>
    {headers}
  </div>;
}

export function MakePlanStrategyHeaders(props) {
  const { currentPlan, current, squad, agents, segments } = props;

  const controls_list = squad.plan_controls_list[currentPlan];
  const allHeaders = [];
  const totalThrowables = agents.reduce((all, a) => all.concat(a.throwables.filter((t) => t.throwable_rate !== 0)), []).length;
  const totalAttachables = agents.reduce((all, a) => all.concat(a.attachables), []).length;
  const usingThrowables = controls_list.filter((c) => c.throwopts).length;
  const usingThrowableBreachs = controls_list.filter((c) => c.breachopts === 'breach_throw').length;
  const usingAttachableBreachs = controls_list.filter((c) => c.breachopts === 'breach_attach').length;
  const totalThrowablesPerAgents = agents.map((a) => a.throwables.filter((t) => t.throwable_rate !== 0).length);
  const totalAttachablesPerAgents = agents.map((a) => a.attachables.length);
  const usingThrowablesPerAgents = agents.map((a) => a.plan_controls_list[currentPlan].filter((c) => c.throwable).length);
  const usingThrowableBreachsPerAgents = agents.map((a) => 0); // agents.map((a) => a.plan_controls_list[currentPlan].filter((c) => c.throwable).length);
  const usingAttachableBreachsPerAgents = agents.map((a) => a.plan_controls_list[currentPlan].filter((c) => Object.keys(c.attachable).some(key => c.attachable[key])).length);

  if (agents.length === 0) {
    allHeaders.push({
      segment: -1, header: <div key='noSquadMembers' className='figmaplan-strategy-header-overlay-item excess'>
        {L('loc_ui_longtext_mission_plan_alert_no_squad_members')}
      </div>
    });
  }
  if (totalThrowables > usingThrowables + usingThrowableBreachs) {
    allHeaders.push({
      segment: -1, header: <div key='excessThrowables' className='figmaplan-strategy-header-overlay-item excess'>
        {L('loc_ui_longtext_mission_plan_alert_excess_throwables', { have: totalThrowables, use: usingThrowables + usingThrowableBreachs })}
      </div>
    });
  } else if (totalThrowables < usingThrowables + usingThrowableBreachs) {
    allHeaders.push({
      segment: -1, header: <div key='shortThrowables' className='figmaplan-strategy-header-overlay-item short'>
        {L('loc_ui_longtext_mission_plan_alert_short_throwables', { have: totalThrowables, use: usingThrowables + usingThrowableBreachs })}
      </div>
    });
  }
  if (totalAttachables > usingAttachableBreachs) {
    allHeaders.push({
      segment: -1, header: <div key='excessAttachables' className='figmaplan-strategy-header-overlay-item excess'>
        {L('loc_ui_longtext_mission_plan_alert_excess_attachables', { have: totalAttachables, use: usingAttachableBreachs })}
      </div>
    });
  } else if (totalAttachables < usingAttachableBreachs) {
    allHeaders.push({
      segment: -1, header: <div key='shortAttachables' className='figmaplan-strategy-header-overlay-item short'>
        {L('loc_ui_longtext_mission_plan_alert_short_attachables', { have: totalAttachables, use: usingAttachableBreachs })}
      </div>
    });
  }
  agents.forEach((a, i) => {
    if (totalThrowablesPerAgents[i] > usingThrowablesPerAgents[i] + usingThrowableBreachsPerAgents[i]) {
      allHeaders.push({
        segment: -1, header: <div key={'excessThrowables' + i} className='figmaplan-strategy-header-overlay-item excess'>
          {L('loc_ui_longtext_mission_plan_alert_excess_throwables_who', { have: totalThrowablesPerAgents[i], use: usingThrowablesPerAgents[i] + usingThrowableBreachsPerAgents[i], who: a.name })}
        </div>
      });
    } else if (totalThrowablesPerAgents[i] < usingThrowablesPerAgents[i] + usingThrowableBreachsPerAgents[i]) {
      allHeaders.push({
        segment: -1, header: <div key={'shortThrowables' + i} className='figmaplan-strategy-header-overlay-item short'>
          {L('loc_ui_longtext_mission_plan_alert_short_throwables_who', { have: totalThrowablesPerAgents[i], use: usingThrowablesPerAgents[i] + usingThrowableBreachsPerAgents[i], who: a.name })}
        </div>
      });
    }
    if (totalAttachablesPerAgents[i] > usingAttachableBreachsPerAgents[i]) {
      allHeaders.push({
        segment: -1, header: <div key={'excessAttachables' + i} className='figmaplan-strategy-header-overlay-item excess'>
          {L('loc_ui_longtext_mission_plan_alert_excess_attachables_who', { have: totalAttachablesPerAgents[i], use: usingAttachableBreachsPerAgents[i], who: a.name })}
        </div>
      });
    } else if (totalAttachablesPerAgents[i] < usingAttachableBreachsPerAgents[i]) {
      allHeaders.push({
        segment: -1, header: <div key={'shortAttachables' + i} className='figmaplan-strategy-header-overlay-item short'>
          {L('loc_ui_longtext_mission_plan_alert_short_attachables_who', { have: totalAttachablesPerAgents[i], use: usingAttachableBreachsPerAgents[i], who: a.name })}
        </div>
      });
    }
  });
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const controls = controls_list[i];
    const agentControls = agents.map((a) => a.plan_controls_list[currentPlan][i]);

    const usingSquadThrowable = controls.throwopts;
    const usingAgentThrowable = agentControls.find((c) => c.throwable);
    const usingSquadBreach = controls.breachopts !== 'nobreach';
    const usingAgentBreach = agentControls.find((c) => Object.keys(c.attachable || {}).length > 0);

    if (usingSquadThrowable && !usingAgentThrowable) {
      allHeaders.push({
        segment: i, header: <div key={'throwable' + i} className='figmaplan-strategy-header-overlay-item who'>
          {L('loc_ui_longtext_mission_plan_alert_throwables_who', { value: i + 1 })}
        </div>
      });
    }
    if (usingSquadBreach && !usingAgentBreach) {
      allHeaders.push({
        segment: i, header: <div key={'breach' + i} className='figmaplan-strategy-header-overlay-item who'>
          {L('loc_ui_longtext_mission_plan_alert_attachables_who', { value: i + 1 })}
        </div>
      });
    }
  }

  const headers = allHeaders.filter((h) => h.segment === -1 || h.segment === current || current === -1).map((h) => h.header);
  return headers;
}

function SvgPolicyRadioButton(props) {
  const { selected } = props;
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="1" y="1" width="22" height="22" rx="11" fill="#1C160D" stroke="#523D2C" strokeWidth="2" />
    {selected && <circle cx="12" cy="12" r="6" fill="#F7D19D" />}
  </svg>
}

function SinglePolicySelector(props) {
  const { ty, onChange, selected, door, volatile, availableOptions, volatileOptions } = props;
  const policies = {
    firepolicy: {
      label: 'loc_ui_string_mission_plan_policy_firepower_label',
      desc: 'loc_ui_longtext_mission_plan_policy_firepower_desc',
      options: [
        { key: 'default', value: 'default', label: 'loc_ui_string_mission_plan_policy_firepower_option_default_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_default_desc' },
        { key: 'focus', value: 'focus', label: 'loc_ui_string_mission_plan_policy_firepower_option_focus_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_focus_desc' },
        { key: 'aggressive', value: 'aggressive', label: 'loc_ui_string_mission_plan_policy_firepower_option_aggressive_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_aggressive_desc' },
        { key: 'pair', value: 'pair', label: 'loc_ui_string_mission_plan_policy_firepower_option_pair_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_pair_desc' },
        { key: 'control', value: 'control', label: 'loc_ui_string_mission_plan_policy_firepower_option_control_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_control_desc' },
        { key: 'supress', value: 'supress', label: 'loc_ui_string_mission_plan_policy_firepower_option_supress_label', desc: 'loc_ui_longtext_mission_plan_policy_firepower_option_supress_desc' },
      ]
    },
    throwopts: {
      label: 'loc_ui_string_mission_plan_policy_throwopts_label',
      desc: 'loc_ui_longtext_mission_plan_policy_throwopts_desc',
      options: [
        { key: 'forbid', value: false, label: 'loc_ui_string_mission_plan_policy_throwopts_option_forbid_label', desc: 'loc_ui_longtext_mission_plan_policy_throwopts_option_forbid_desc' },
        { key: 'allow', value: true, label: 'loc_ui_string_mission_plan_policy_throwopts_option_allow_label', desc: 'loc_ui_longtext_mission_plan_policy_throwopts_option_allow_desc' },
      ]
    },
    healopts: {
      label: 'loc_ui_string_mission_plan_policy_healopts_label',
      desc: 'loc_ui_longtext_mission_plan_policy_healopts_desc',
      options: [
        { key: 'forbid', value: -1, label: 'loc_ui_string_mission_plan_policy_healopts_option_forbid_label', desc: 'loc_ui_longtext_mission_plan_policy_healopts_option_forbid_desc' },
        { key: 'conservative', value: opts.HEAL_THRES_CONSERVATIVE, label: 'loc_ui_string_mission_plan_policy_healopts_option_conservative_label', desc: 'loc_ui_longtext_mission_plan_policy_healopts_option_conservative_desc' },
        { key: 'aggressive', value: opts.HEAL_THRES_AGGRESSIVE, label: 'loc_ui_string_mission_plan_policy_healopts_option_aggressive_label', desc: 'loc_ui_longtext_mission_plan_policy_healopts_option_aggressive_desc' },
      ]
    },
    breachopts_full: {
      label: 'loc_ui_string_mission_plan_policy_breachopts_label',
      desc: 'loc_ui_longtext_mission_plan_policy_breachopts_desc',
      options: [
        { key: 'nobreach', value: 'nobreach', label: 'loc_ui_string_mission_plan_policy_breachopts_option_nobreach_label', desc: 'loc_ui_longtext_mission_plan_policy_breachopts_option_nobreach_desc' },
        { key: 'breach_attach', value: 'breach_attach', label: 'loc_ui_string_mission_plan_policy_breachopts_option_breach_attach_label', desc: 'loc_ui_longtext_mission_plan_policy_breachopts_option_breach_attach_desc' },
        { key: 'breach_throw', value: 'breach_throw', label: 'loc_ui_string_mission_plan_policy_breachopts_option_breach_throw_label', desc: 'loc_ui_longtext_mission_plan_policy_breachopts_option_breach_throw_desc' },
      ]
    },
  };

  return <TooltipContext.Consumer>
    {(context) => {
      return <div key={ty} className='figmaplan-strategy-policy-selector'>
        <div className={'figmaplan-strategy-policy-selector-title' + (volatile ? ' volatile' : '')}
          {...figmaTooltipEvents(context, L(policies[ty].desc))}
        >
          <span className="figma-tooltip-marker">{L(policies[ty].label)}</span>
        </div>
        <div className='figmaplan-strategy-policy-selector-options'>
          {policies[ty].options
            .filter((e) => {
              return availableOptions.includes(e.key) || volatileOptions.includes(e.key);
            })
            .map((e, i) => {
              const cls = 'figmaplan-strategy-policy-selector-option' + (selected === e.value ? ' selected' : '') + (volatileOptions.includes(e.key) ? ' volatile' : '');
              return <SinglePolicySelectorOption
                key={i}
                option={e}
                cls={cls}
                onChange={onChange}
                selected={selected}
                ty={ty}
                isVolatile={volatileOptions.includes(e.key)}
              />;
            })}
        </div>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

function SinglePolicySelectorOption(props) {
  const { option, cls, onChange, selected, ty } = props;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div key={option.ty + ' ' + option.key} className={cls}
        {...figmaTooltipEvents(context, L(option.desc))}
        onClick={() => {
          onChange(option.value);
          triggerSound('UI_Outgame_Button_Click_Radio');
        }}>
        <SvgPolicyRadioButton selected={selected == option.value} />
        <SvgPolicyIcon svgKey={option.key} ty={ty} />
        <span className="figma-tooltip-marker">{L(option.label)}</span>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

function PolicyToggleButton(props) {
  const { state, onClick } = props;
  return <div className='figmaplan-strategy-policy-toggle-button' onClick={onClick}>
    <div className={'figmaplan-strategy-policy-toggle-button-foreground' + (state ? ' active' : '')}>
      {state ? L('loc_ui_string_mission_plan_policy_toggle_on') : L('loc_ui_string_mission_plan_policy_toggle_off')}
    </div>
  </div>;
}

function SinglePolicyToggler(props) {
  const { ty, onChange, selected } = props;
  const policies = {
    // 파이컷
    riskdir: {
      label: 'loc_ui_string_mission_plan_policy_riskdir_label',
      desc: 'loc_ui_longtext_mission_plan_policy_riskdir_desc',
      options: [
        { key: 'noriskdir', value: false, },
        { key: 'riskdir', value: true, },
      ]
    },
    // 도어 엔트리
    door_entry: {
      label: 'loc_ui_string_mission_plan_policy_door_entry_label',
      desc: 'loc_ui_longtext_mission_plan_policy_door_entry_desc',
      options: [
        { key: 'door_entry_false', value: false, },
        { key: 'door_entry', value: true, },
      ]
    },
    // 쿠킹
    throwable_cooking: {
      label: 'loc_ui_string_mission_plan_policy_throwable_cooking_label',
      desc: 'loc_ui_longtext_mission_plan_policy_throwable_cooking_desc',
      options: [
        { key: 'nothrowable_cooking', value: false, },
        { key: 'throwable_cooking', value: true, },
      ]
    },
    //피해 분산
    damage_cover: {
      label: 'loc_data_string_perk2_common_intelligence_damage_control',
      desc: 'loc_data_longtext_perk2_common_intelligence_damage_control_desc',
      options: [
        { key: 'nodamage_cover', value: false, },
        { key: 'damage_cover', value: true, },
      ]
    },
  };

  return <TooltipContext.Consumer>
    {(context) => {
      return <div key={ty} className='figmaplan-strategy-policy-toggler'>
        <div className='figmaplan-strategy-policy-toggler-desc'
          {...figmaTooltipEvents(context, L(policies[ty].desc))}
        >
          <SvgPolicyIcon svgKey={ty} />
          <span className="figma-tooltip-marker">{L(policies[ty].label)}</span>
          <PolicyToggleButton state={selected} onClick={() => {
            onChange(!selected);
            triggerSound('UI_Outgame_Button_Click_Default');
          }} />
        </div>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

function SingleDutySelector(props) {
  const { ty, onChange, selected, agents } = props;
  const duties = {
    'throwable': {
      label: 'loc_ui_string_mission_plan_duty_who',
      listFunc: (agents) => agents.filter((a) => a.throwables.find((t) => t.throwable_rate !== 0)),
      iconKey: 'allow',
      iconTy: 'throwable',
      controlKey: 'throw_idx',
    },
    'breach_attach': {
      label: 'loc_ui_string_mission_plan_duty_who',
      listFunc: (agents) => agents.filter((a) => a.attachables.length > 0),
      iconKey: 'breach_attach',
      iconty: null,
      controlKey: 'breach_idx',
    },
    'breach_throw': {
      label: 'loc_ui_string_mission_plan_duty_who',
      listFunc: (agents) => agents.filter((a) => a.throwables.find((t) => t.is_breaching)),
      iconKey: 'breach_throw',
      iconTy: null,
      controlKey: 'breach_idx',
    },
  };

  const duty = duties[ty];
  if (ty === 'throwable') {
    return <div className='figmaplan-strategy-header-item'>
      {L(duty.label)}
      <select value={selected} onChange={onChange}>
        <option value={-1} key={-1}>{L('loc_ui_string_mission_plan_duty_no_one')}</option>
        {duty.listFunc(agents).map((a, j) => {
          return <option value={a.idx} key={j}>[{L('loc_ui_string_common_role_' + a.role + '_shorthand')}] {a.name}</option>;
        })}
      </select>
    </div>;
  } else if (ty === 'breach_attach' || ty === 'breach_throw') {
    const duty = duties[ty];
    return <div className='figmaplan-strategy-header-item'>
      {L(duty.label)}
      <select value={selected} onChange={onChange}>
        <option value={-1} key={-1}>{L('loc_ui_string_mission_plan_duty_no_one')}</option>
        {duty.listFunc(agents).map((a, j) => {
          return <option value={a.idx} key={j}>[{L('loc_ui_string_common_role_' + a.role + '_shorthand')}] {a.name}</option>;
        })}
      </select>
    </div>;
  }
}

function agentFeatures(agents) {
  const agents_heal = agents.filter((a) => a.role === 'medic');
  const agents_throwable = agents.filter((a) => a.throwables.find((t) => t.throwable_rate !== 0));
  const agents_breach_attach = agents.filter((a) => a.attachables.length > 0);
  const agents_breach_throw = agents.filter((a) => a.throwables.find((t) => t.is_breaching));

  const heal = agents_heal.length > 0;
  const throwable = agents_throwable.length > 0;
  const breachable_attach = agents_breach_attach.length > 0;
  const breachable_throw = agents_breach_throw.length > 0;

  const riskdir = agents.find((a) => a.statsPerks.perk2_common_intelligence_piecut !== 'deactivated') !== undefined;
  const door_entry = agents.find((a) => a.statsPerks.perk2_common_intelligence_dynamic_entry !== 'deactivated') !== undefined;
  const throwable_cooking = agents.find((a) => a.throwables.find((t) => t.throwable_rate !== 0) && a.statsPerks.perk2_common_intelligence_grenade_cooking !== 'deactivated') !== undefined;
  const damage_cover = agents.find((a) => a.statsPerks.perk2_common_intelligence_damage_control !== 'deactivated') !== undefined;

  const policy_supress = agents.find((a) => a.statsPerks.perk2_common_strategy_supress !== 'deactivated') !== undefined;
  const policy_aggressive = agents.find((a) => a.statsPerks.perk2_common_strategy_aggressive !== 'deactivated') !== undefined;
  const policy_control = agents.find((a) => a.statsPerks.perk2_common_strategy_control !== 'deactivated') !== undefined;
  const policy_pair = agents.find((a) => a.statsPerks.perk2_common_strategy_pair !== 'deactivated') !== undefined;

  return {
    heal,
    throwable,
    breachable_attach,
    breachable_throw,

    riskdir,
    door_entry,
    throwable_cooking,
    damage_cover,

    policy_supress,
    policy_aggressive,
    policy_control,
    policy_pair,
  };

}

function SegmentPolicySelector(props) {
  const { current, squad, agents, controlSet, agentControlSet, segment, currentPlan, planControlSet, planAgentControlSet } = props;

  const defaultDoorKey = segment.doors?.[0];

  const agents_heal = agents.filter((a) => a.role === 'medic');
  const agents_throwable = agents.filter((a) => a.throwables.find((t) => t.throwable_rate !== 0));
  const agents_breach_attach = agents.filter((a) => a.attachables.length > 0);
  const agents_breach_throw = agents.filter((a) => a.throwables.find((t) => t.is_breaching));

  const features = agentFeatures(agents);

  const volatilePolicies = (() => {
    const usingThrowable = squad.plan_controls_list[currentPlan][current].throwopts && !features.throwable;
    const usingBreachAttach = squad.plan_controls_list[currentPlan][current].breachopts === 'breach_attach' && !features.breachable_attach;
    const usingBreachThrow = squad.plan_controls_list[currentPlan][current].breachopts === 'breach_throw' && !features.breachable_throw;

    const usingSupress = squad.plan_controls_list[currentPlan][current].firepolicy === 'supress' && !features.policy_supress;
    const usingAggressive = squad.plan_controls_list[currentPlan][current].firepolicy === 'aggressive' && !features.policy_aggressive;
    const usingControl = squad.plan_controls_list[currentPlan][current].firepolicy === 'control' && !features.policy_control;
    const usingPair = squad.plan_controls_list[currentPlan][current].firepolicy === 'pair' && !features.policy_pair;

    return {
      usingThrowable,
      usingBreachAttach,
      usingBreachThrow,

      usingSupress,
      usingAggressive,
      usingControl,
      usingPair,
    };
  })();

  const agentsControlSetFn = (key, fn) => {
    for (const a of agents) {
      planAgentControlSet(a.plan_controls_list, currentPlan, a.controls_list, current, key, fn(a));
    }
  };

  const policies = [];

  const firePolicies = ['default', 'focus'];
  const volatileFirePolicies = [];
  if (DEBUG_ALL_POLICY || features.policy_supress) {
    firePolicies.push('supress');
  } else if (volatilePolicies.usingSupress) {
    volatileFirePolicies.push('supress');
  }
  if (DEBUG_ALL_POLICY || features.policy_aggressive) {
    firePolicies.push('aggressive');
  } else if (volatilePolicies.usingAggressive) {
    volatileFirePolicies.push('aggressive');
  }
  if (DEBUG_ALL_POLICY || features.policy_control) {
    firePolicies.push('control');
  } else if (volatilePolicies.usingControl) {
    volatileFirePolicies.push('control');
  }
  if (DEBUG_ALL_POLICY || features.policy_pair) {
    firePolicies.push('pair');
  } else if (volatilePolicies.usingPair) {
    volatileFirePolicies.push('pair');
  }

  policies.push(<SinglePolicySelector
    key='firepolicy'
    ty='firepolicy'
    onChange={(v) => planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'firepolicy', v)}
    selected={squad.plan_controls_list[currentPlan][current].firepolicy}
    volatile={volatilePolicies.usingSupress || volatilePolicies.usingAggressive || volatilePolicies.usingControl || volatilePolicies.usingPair}
    availableOptions={firePolicies}
    volatileOptions={volatileFirePolicies}
  />);

  const controls = squad.plan_controls_list[currentPlan][current];

  if (DEBUG_ALL_POLICY || features.throwable || volatilePolicies.usingThrowable) {
    const setThrowableIdx = (idx) => {
      planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'throw_idx', idx);
      agentsControlSetFn('throwable', (a) => a.idx === idx);
    };

    policies.push(<SinglePolicySelector
      key='throwopts'
      ty='throwopts'
      onChange={(enabled) => {
        planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'throwopts', enabled);
        let selected_idx = -1;
        if (enabled) {
          selected_idx = agents_throwable[0]?.idx ?? -1;
        }
        setThrowableIdx(selected_idx);
      }}
      selected={squad.plan_controls_list[currentPlan][current].throwopts}
      volatile={volatilePolicies.usingThrowable}
      availableOptions={['allow', 'forbid']}
      volatileOptions={volatilePolicies.usingThrowable ? ['allow'] : []}
    />);

    if (controls.throwopts) {
      policies.push(<SingleDutySelector
        key='throwable'
        ty='throwable'
        onChange={(e) => {
          const selected_idx = Number(e.target.value);
          setThrowableIdx(selected_idx);
        }}
        selected={controls.throw_idx}
        agents={squad.agents} />);
    }
  }

  if (DEBUG_ALL_POLICY || features.heal) {
    policies.push(<SinglePolicySelector
      key='healopts'
      ty='healopts'
      onChange={(v) => {
        planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'healopts', v);
        agents.filter((a) => a.role === 'medic').map((a) => planAgentControlSet(a.plan_controls_list, currentPlan, a.controls_list, current, 'heal', v));
      }}
      selected={squad.plan_controls_list[currentPlan][current].healopts}
      volatile={false}
      availableOptions={['forbid', 'conservative', 'aggressive']}
      volatileOptions={[]}
    />);
  }

  for (const doorKey of segment.doors) {
    let policy_ty = null;

    if (DEBUG_ALL_POLICY || (features.breachable_attach && features.breachable_throw)) {
      policy_ty = 'breachopts_full';
    } else if (features.breachable_attach || volatilePolicies.usingBreachAttach) {
      policy_ty = 'breachopts_attach';
    } else if (features.breachable_throw || volatilePolicies.usingBreachThrow) {
      policy_ty = 'breachopts_throw';
    }

    const setBreachIdx = (idx) => {
      planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'breach_idx', idx);
      agentsControlSetFn('attachable', (a) => {
        if (a.idx === idx) {
          return { [defaultDoorKey]: true };
        } else {
          return {};
        }
      });
    };

    const breachPolicies = ['nobreach'];
    if (features.breachable_attach) {
      breachPolicies.push('breach_attach');
    }
    if (features.breachable_throw) {
      breachPolicies.push('breach_throw');
    }
    const volatileBreachPolicies = [];
    if (volatilePolicies.usingBreachAttach) {
      volatileBreachPolicies.push('breach_attach');
    }
    if (volatilePolicies.usingBreachThrow) {
      volatileBreachPolicies.push('breach_throw');
    }

    if (policy_ty) {
      policies.push(<SinglePolicySelector
        key='breachopts_full'
        ty='breachopts_full'
        onChange={(enabled) => {
          planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'breachopts', enabled);
          let default_idx = -1;
          if (enabled !== 'nobreach') {
            default_idx = agents_breach_attach[0]?.idx ?? -1;
          }
          setBreachIdx(default_idx);
        }}
        selected={squad.plan_controls_list[currentPlan][current].breachopts}
        door={doorKey}
        volatile={volatilePolicies.usingBreachAttach || volatilePolicies.usingBreachThrow}
        availableOptions={breachPolicies}
        volatileOptions={volatileBreachPolicies}
      />);
    }

    if (controls.breachopts === 'breach_attach' || controls.breachopts === 'breach_throw') {
      policies.push(<SingleDutySelector
        key={controls.breachopts}
        ty={controls.breachopts}
        onChange={(e) => {
          const selected_idx = Number(e.target.value);
          setBreachIdx(selected_idx);
        }}
        selected={controls.breach_idx}
        agents={squad.agents} />);
    }
  }

  if (DEBUG_ALL_POLICY || features.riskdir || features.door_entry || features.throwable_cooking || features.damage_cover) {
    policies.push(<div key='tactical' className='figmaplan-strategy-policy-tactical'>
      <div className='figmaplan-strategy-policy-tactical-title'>{L('loc_ui_string_mission_plan_squad_tactics')}</div>
      <div className='figmaplan-strategy-policy-toggler-options'>
        {(DEBUG_ALL_POLICY || features.riskdir) &&
          <SinglePolicyToggler
            ty='riskdir'
            onChange={(v) => planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'riskdir', v)}
            selected={squad.plan_controls_list[currentPlan][current].riskdir}
          />
        }
        {(!segment || segment.doors.length > 0) && (DEBUG_ALL_POLICY || features.door_entry) &&
          <SinglePolicyToggler
            ty='door_entry'
            onChange={(v) => planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'door_entry', v)}
            selected={squad.plan_controls_list[currentPlan][current].door_entry}
          />
        }
        {(DEBUG_ALL_POLICY || features.throwable_cooking) &&
          <SinglePolicyToggler
            ty='throwable_cooking'
            onChange={(v) => planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'throwable_cooking', v)}
            selected={squad.plan_controls_list[currentPlan][current].throwable_cooking}
          />
        }
        {(DEBUG_ALL_POLICY || features.damage_cover) &&
          <SinglePolicyToggler
            ty='damage_cover'
            onChange={(v) => planControlSet(squad.plan_controls_list, currentPlan, squad.controls_list, current, 'damage_cover', v)}
            selected={squad.plan_controls_list[currentPlan][current].damage_cover}
          />
        }
      </div>
    </div>);
  }

  return <div className='figmaplan-sidebar-container'>
    {policies}
  </div>;
}

function FigmaPlanStrategyView(props) {
  const cls = 'figmaplan-strategy' + (props.current < 0 ? ' entire' : ' segment');

  const origT = 'scale(1)';
  const [t, setT] = useState(origT);
  let input = <input type='text' value={t} onChange={(e) => {
    e.preventDefault();
    e.stopPropagation();
    setT(e.target.value);
  }} />;
  if (true) {
    input = null;
  }

  const sidebar = FigmaPlanStrategySidebar(props);
  const header = FigmaPlanStrategyHeader(props);
  return <div className={cls}>
    {sidebar}
    <div className='figmaplan-strategy-body'>
      <div className='figmaplan-strategy-container'>
        {input}
        <div className='figmaplan-strategy-previewbox'>
          <DivCanvas
            spawnareas={props.spawnareas}
            obstacle_specs={props.obstacle_specs}
            controls_list={props.squad.plan_controls_list[props.currentPlan]}
            features={agentFeatures(props.agents)}
            segments={props.segments}
            enemies={props.enemies}
            enemies_diff={props.enemies_diff}
            prev_week_enemies={props.prev_week_enemies}
            layerName={'plan' + props.currentPlan}
            routeLayerName={'route' + props.currentPlan}
            setCurrent={props.setCurrent}
            current={props.current}
            scrollRefs={props.scrollRefs}
          />
        </div>
      </div>
      {header}
    </div>
  </div>;
}

const svg_icon_gun = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M5 21.1443L7.80411 12.7063V11.4228C9.21903 11.1108 10.3001 10.1392 10.3001 8H26L25.2759 13.562H17.6635L16.4155 17.4126H12.9284L11.964 22L5 21.1443ZM13.1625 15.7012H15.2162L15.9095 13.562H13.5202L13.1625 15.7012Z" fill="#BAA96C" />
</svg>;

const svg_icon_vest = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path d="M7 9.8167L8.51462 8.64748L8.55556 6H11.2368L11.6257 9.8167H16.3743L16.7632 6H19.4444L19.4854 8.64748L21 9.8167V21.4733C18.8222 22.6947 15.4259 23 14 23C12.5741 23 9.17778 22.6947 7 21.4733V9.8167Z" fill="#BAA96C" />
</svg>;

const svg_icon_grenade = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path d="M10.4594 6L10.6263 9.38154C8.09984 10.2978 6.24699 12.6011 6.00494 15.362H20.1101C19.8737 12.666 18.1014 10.4063 15.6657 9.44842V8.21739H18.1504C19.0234 9.30763 20.3036 11.1871 20.8882 13.827L22 13.7609C22 10.4348 20.1736 6.90472 19.3918 6H10.4594Z" fill="#BAA96C" />
  <path d="M6 16.5353H20.115C19.8288 20.1529 16.7784 23 13.0575 23C9.33666 23 6.28626 20.1529 6 16.5353Z" fill="#BAA96C" />
</svg>;

function FigmaPlanHistorySidebar(props) {
  const { squad, currentTry, agentsAll, checkSquadPresetable, setSquadPreset, onAgentUpdate, setIsHistory, setCurrent } = props;
  const { history } = squad;

  const agent_ids = history[currentTry].entity_ids;
  const gears = history[currentTry].gears;
  const plan_chosen = history[currentTry].plan_chosen;
  const controls_list = history[currentTry].controls_list;
  const plan_controls_list = history[currentTry].plan_controls_list;

  const { res, reasons } = checkSquadPresetable(agent_ids, gears);
  return <div className='figmaplan-history-sidebar'>
    <div className='figmaplan-history-sidebar-info'>
      <div className='figmaplan-history-sidebar-info-title'>{L('loc_ui_string_mission_history_date')}</div>
      <div className='figmaplan-history-sidebar-info-date'>{tickToDateTimeStrObj(history[currentTry].turn).date}</div>
      <div className='figmaplan-history-sidebar-info-title'>{L('loc_ui_string_menu_squad')}</div>
      <div className='figmaplan-history-sidebar-info-squad'>
        {agent_ids.map((e, i) => {
          const agent = agentsAll.find((a) => a.idx === e);
          const firearm = firearms.find((f) => f.key === gears[i].firearm_key);
          const equipment = gears_vest_bulletproof.find((g) => g.key === gears[i].equipment_key);
          const throwable = throwables.find((t) => t.key === gears[i].throwable_key);
          return <div key={e} className='figmaplan-history-sidebar-info-squad-agent'>
            <div className='figmaplan-history-sidebar-info-squad-agent-header'>
              <SvgBadgePosition
                value={i + 1}
                overrideWidth={28}
                overrideHeight={34}
              />
              <RenderAgentSimple
                agent={agent}
                noTooltip={true}
              />
            </div>
            <div className='figmaplan-history-sidebar-info-squad-agent-body'>
              <div className='figmaplan-history-sidebar-info-squad-agent-gear'>
                {svg_icon_gun}
                <div className="figmalist-body-detail-box-firearmaptitude-ty">
                  <span className="figmalist-body-detail-box-firearmaptitude-ty-label">{firearm.firearm_ty.toUpperCase()}</span>
                </div>
                {L(firearm.firearm_name)}
              </div>
              <div className='figmaplan-history-sidebar-info-squad-agent-gear'>
                {svg_icon_vest}
                {L(equipment.vest_name + '_short', equipment.vest_name)}
              </div>
              <div className='figmaplan-history-sidebar-info-squad-agent-gear'>
                {svg_icon_grenade}
                {L(throwable.throwable_name + '_short', throwable.throwable_name)}
              </div>
            </div>
          </div>;
        })}
      </div>
    </div>
    <div className='figmaplan-history-sidebar-footer'>
      <button
        className='figmaplan-history-sidebar-footer-button'
        onClick={() => {
          setSquadPreset(agent_ids, gears, plan_chosen, controls_list, plan_controls_list);
          setCurrent(-1);
          setIsHistory(false);
          onAgentUpdate(null);
        }}
        disabled={!res}
        title={res ? '' : reasons.map((r) => {
          switch (r.ty) {
            case 'agent':
              const agent = agentsAll.find((a) => a.idx === r.agentIdx);
              return L('loc_ui_string_mission_history_preset_error_agent', { value: L(agent?.name ?? '') });
            case 'firearm':
              const firearm = firearms.find((f) => f.key === r.itemKey);
              return L('loc_ui_string_mission_history_preset_error_item', { value: L(firearm.firearm_name) });
            case 'equipment':
              const equipment = gears_vest_bulletproof.find((g) => g.key === r.itemKey);
              return L('loc_ui_string_mission_history_preset_error_item', { value: L(equipment.vest_name) });
            case 'throwable':
              const throwable = throwables.find((t) => t.key === r.itemKey);
              return L('loc_ui_string_mission_history_preset_error_item', { value: L(throwable.throwable_name) });
            default:
              return L('loc_ui_string_mission_history_preset_error_default');
          }
        }).join('\n')}
      >
        {L('loc_ui_string_mission_history_preset_reproduce')}
      </button>
    </div>
  </div >;
}

function FigmaPlanHistoryView(props) {
  const sidebar = FigmaPlanHistorySidebar(props);
  const { squad, currentTry, agentsAll } = props;
  const { history } = squad;
  const plan_chosen = history[currentTry].plan_chosen;
  const controls_list = history[currentTry].controls_list;
  const segments_report = history[currentTry].segments_report;
  return <div className='figmaplan-history'>
    {sidebar}
    <div className='figmaplan-history-previewbox'>
      <DivCanvas
        spawnareas={props.spawnareas}
        obstacle_specs={props.obstacle_specs}
        controls_list={controls_list}
        features={agentFeatures(props.agents)}
        segments={props.segments}
        enemies={props.enemies}
        layerName={'plan' + plan_chosen}
        routeLayerName={'route' + plan_chosen}
        setCurrent={null}
        current={-1}
        scrollRefs={props.scrollRefs}
        isHistory={true}
        segments_report={segments_report}
        agentsAll={agentsAll}
      />
    </div>
  </div>
}

const svg_icon_cleared = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <circle cx="12.5" cy="12.5" r="11.5" fill="#F0D691" stroke="#C7AA5C" strokeWidth="2" />
  <path d="M12.5 4L14.6983 9.47428L20.584 9.87336L16.057 13.6557L17.4962 19.3766L12.5 16.24L7.50383 19.3766L8.94305 13.6557L4.41602 9.87336L10.3017 9.47428L12.5 4Z" fill="#553B28" />
</svg>;



export function FigmaPlanPopupView(props) {
  const { game, squad } = props;
  const { onReset, onAgentUpdate, onClickButtonClose, onClickButtonStart, onClickButtonFinal } = props;

  const { config_key, agents } = squad;
  const currentPlan = squad.current_plan;
  const config = CONFIGS.find((c) => c.key === config_key);
  const plan = config.plans[currentPlan];

  const preset = game.getPlanPreset(config_key, currentPlan);
  const prevWeekPreset = game.getPrevWeekPlanPreset(config_key, currentPlan);
  const enemies_diff = game.getPlanPresetDiff(config_key, currentPlan);

  const haveHistory = squad.history && squad.history.length > 0;
  const HISTORY_TABS_MAX = 10;
  const startingIndex = Math.max(0, squad.history.length - HISTORY_TABS_MAX);

  const [isHistory, setIsHistory] = useState(false);
  const [current, setCurrent] = useState(-1);
  const [sidebar, setSidebar] = useState('policy');
  const scrollRefs = useRef([]);
  const [currentTry, setCurrentTry] = useState(startingIndex);

  let historyPreset = preset;
  if (haveHistory) {
    historyPreset = game.getPlanPreset(config_key, squad.history[currentTry].plan_chosen);
  }

  function controlSet(controls_list, segment_id, key, value) {
    if (controls_list) {
      controls_list[segment_id][key] = value;
    }
    onAgentUpdate(null);
  }

  function agentControlSet(agent_controls_list, segment_id, key, value) {
    if (agent_controls_list) {
      agent_controls_list[segment_id][key] = value;
    }
    onAgentUpdate(null);
  }

  function setCurrentPlan(plan_idx) {
    squad.current_plan = plan_idx;
    if (!squad.plan_controls_list) {
      squad.plan_controls_list = [];
    }
    squad.controls_list = squad.plan_controls_list[plan_idx];
    for (const a of agents) {
      if (!a.plan_controls_list) {
        a.plan_controls_list = [];
      }
      a.controls_list = a.plan_controls_list[plan_idx];
    }
    onAgentUpdate(null);
  }

  function planControlSet(plan_controls_list, plan_id, controls_list, segment_id, key, value) {
    controlSet(controls_list, segment_id, key, value);
    controlSet(plan_controls_list[plan_id], segment_id, key, value);
  }

  function planAgentControlSet(agent_plan_controls_list, plan_id, agent_controls_list, segment_id, key, value) {
    agentControlSet(agent_controls_list, segment_id, key, value);
    agentControlSet(agent_plan_controls_list[plan_id], segment_id, key, value);
  }

  function renderTabs() {
    const { segments } = preset;

    return <div className='figmaplan-tabs'>
      <div key={-1} className={'figmaplan-tab' + (current === -1 ? ' figmaplan-tab-current' : '')} onClick={() => {
        setCurrent(-1);
        triggerSound('UI_Outgame_Button_Click_Default');
      }}>
        <span className='figmaplan-tab-title'>{L('loc_ui_string_mission_plan_section_entire')}</span>
      </div>
      {segments.map((e, i) => {
        if (e.planhide) {
          return null;
        }
        const cls = ['figmaplan-tab'];
        if (current === i) {
          cls.push('figmaplan-tab-current');
        }
        return <div key={i} className={cls.join(' ')} onClick={() => {
          setCurrent(i);
          scrollRefs[i].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center', });
          triggerSound('UI_Outgame_Button_Click_Default');
        }}>
          <span className='figmaplan-tab-title'>{L('loc_ui_string_mission_plan_section_individual', { value: i + 1 })}</span>
        </div>;
      })}
    </div>
  }

  function renderHistoryTabs() {
    const { history } = squad;

    return <div className='figmahistory-tabs'>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
        const idx = startingIndex + i;
        const cls = ['figmahistory-tab'];
        let onClick = null;
        if (idx === currentTry) {
          cls.push('current');
        }
        if (history[idx]) {
          cls.push('available');
          onClick = () => {
            setCurrentTry(idx);
            triggerSound('UI_Outgame_Button_Click_Default');
          };
        }
        return <div key={i} className={cls.join(' ')} onClick={onClick}>
          <div className='figmahistory-tab-title'>{L('loc_ui_string_mission_history_section', { value: idx + 1 })}</div>
          {history[idx] ? <div className='figmahistory-tab-value'>
            {(history[idx].progress * 100).toFixed(1) + '%'}
            {history[idx].res === 0 ? svg_icon_cleared : null}
          </div> : null}
        </div>;
      })
      }
    </div >;
  }
  let closebtn = null;
  if (onClickButtonClose) {
    closebtn = <button className="figmasquad-header-btn-close" onClick={onClickButtonClose}>
      {ICON_CLOSE}
    </button>;
  }
  const enemies = preset.entities.filter((e) => e.team !== 0);

  return <div className='figmaplan-root full'>
    <div className='figmaplan-title'>{L('loc_ui_string_mission_plan_title')}</div>
    <div className='figmaplan-header'>
      <div className='figmaplan-mission'>
        <div className='figmaplan-mission-mark' />
        <div className='figmaplan-mission-desc'>{L('loc_ui_string_mission_plan_mission_name')}</div>
        <div className='figmaplan-mission-value'>{L(config.descr)}</div>
        <div className='figmaplan-mission-plan'>{L(plan.title)}</div>
      </div>
      {closebtn}
    </div>
    <div>
      <div className='figmaplan-map'>
        {renderTabs()}
        <FigmaPlanStrategyView
          current={current}
          sidebar={sidebar}
          onClickPolicy={() => setSidebar('policy')}
          onClickEnemy={() => setSidebar('enemy')}
          squad={squad}
          agents={agents}
          controlSet={controlSet}
          agentControlSet={agentControlSet}
          segments={preset.segments}
          spawnareas={preset.spawnareas}
          obstacle_specs={preset.obstacle_specs}
          enemies={enemies}
          prev_week_enemies={prevWeekPreset ? prevWeekPreset.entities.filter((e) => e.team !== 0) : enemies}
          enemies_diff={enemies_diff}
          currentPlan={currentPlan}
          setCurrent={setCurrent}
          plans={game.getPlans(config_key)}
          onClickPlan={setCurrentPlan}
          planControlSet={planControlSet}
          planAgentControlSet={planAgentControlSet}
          scrollRefs={scrollRefs}
        />
      </div>
      <LobbySquadDrawer game={game} onClickPortrait={() => { }} />
    </div>
    <ButtonInline onClick={() => onReset()}>reset</ButtonInline>
    {DEBUG ? <div>
      <p>squad</p>
      <p>{JSON.stringify(squad.controls_list)}</p>
      <p>agents</p>
      {agents.map((a, i) => <p key={i}>{JSON.stringify(a.controls_list)}</p>)}
    </div> : null}
  </div>;
}
