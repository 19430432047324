import { ENTITY_CONFIG_TMPL, opts } from './opts.mjs';
import { names3 } from './names.mjs';

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}

const derivedFromBase = function (derived, value) {
  if (STATS3_DERIVED_DOMAIN[derived] !== undefined) {
    const { range: derivedRange, decimals, type } = STATS3_DERIVED_DOMAIN[derived];
    if (decimals >= 0) {
      return Math.round(lerp(derivedRange[0], derivedRange[1], value / 100) * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }
    return lerp(derivedRange[0], derivedRange[1], value / 100);
  }
  return value;
}

const ingameFromDerived = function (ingame, value, ingame_domain_key, is_round = true) {
  let ingame_domain = STATS3_INGAME_DOMAIN[ingame];
  if (ingame_domain_key) {
    ingame_domain = SPECIAL_INGAME_DOMAINS[ingame_domain_key][ingame] ?? ingame_domain;
  }

  if (ingame_domain !== undefined) {
    const { range, decimals, derived } = ingame_domain;
    const { range: derivedRange } = STATS3_DERIVED_DOMAIN[derived];
    if (decimals >= 0) {
      const num = lerp(range[0], range[1], (value - derivedRange[0]) / (derivedRange[1] - derivedRange[0])) * Math.pow(10, decimals);
      if (is_round) {
        return Math.round(num) / Math.pow(10, decimals);
      } else {
        return num / Math.pow(10, decimals);
      }
    }
    return lerp(range[0], range[1], (value - derivedRange[0]) / (derivedRange[1] - derivedRange[0]));
  }
  return value;
}

export const STATS3_BASE_DESCR = {
  physical: '피지컬',
  mental: '정신력',
  shooting: '사격술',
  perception: '공간지각',
  tactical: '전술지능',
}

export const STATS3_DERIVED_DESCR = {
  life: '체력',
  acceleration: '교전 이동',
  reduction: '피해 감소',
  // survival: '생존 의지',
  damage: '추가 피해',
  aimspeed: '조준 속도',
  reloadspeed: '장전 속도',
  accuracy: '명중률',
  evasion: '회피',
  speed: '',
  tolerance: '',
  vision: '',
}

export const STATS3_DERIVED_DOMAIN = {
  life: {
    range: [600, 5000],
    type: 'value',
    decimals: 0,
    base: 'physical',
  },
  acceleration: {
    range: [0, 0.5],
    type: 'percentage',
    decimals: 3,
    base: 'physical',
  },
  reduction: {
    range: [0, 0.5],
    type: 'percentage',
    decimals: 3,
    base: 'mental',
  },
  // survival: {
  //   range: [0, 1],
  //   type: 'percentage',
  //   decimals: 2,
  //   base: 'mental',
  //   maximum: 0.5,
  // },
  damage: {
    range: [0, 500],
    type: 'value',
    decimals: 0,
    base: 'shooting',
  },
  aimspeed: {
    range: [0, 89],
    type: 'value',
    decimals: 2,
    base: 'shooting',
  },
  reloadspeed: {
    range: [0, 67],
    type: 'value',
    decimals: 0,
    base: 'shooting',
  },
  evasion: {
    range: [0, 0.3],
    type: 'percentage',
    decimals: 3,
    base: 'perception',
  },
  accuracy: {
    range: [1, 1.35],
    type: 'percentage',
    decimals: 3,
    base: 'perception',
  },
  speed: {
    range: [35, 36],
    type: 'value',
    decimals: 0,
    base: 'physical',
    hidden: true,
  },
  tolerance: {
    range: [0.3, 0.1],
    type: 'value',
    decimals: 2,
    base: 'mental',
    hidden: true,
  },
  vision: {
    range: [300, 420],
    type: 'value',
    decimals: 0,
    base: 'perception',
    hidden: true,
  },
}

export const STATS3_INGAME_DOMAIN = {
  life: {
    range: [400, 5000],
    decimals: 0,
    derived: 'life',
  },
  speed: {
    range: [35, 36],
    decimals: 0,
    derived: 'speed',
  },
  aimvar_incr_per_hit: {
    range: [0.3, 0.1],
    decimals: -1,
    derived: 'tolerance',
  },
  aimvar_decay_per_tick: {
    range: [0.9, 0.1],
    decimals: -1,
    derived: 'aimspeed',
  },
  firearm_reload_duration_mult: {
    range: [1.5, 0.5],
    decimals: -1,
    derived: 'reloadspeed',
  },
  vis_range: {
    range: [300, 420],
    decimals: 0,
    derived: 'vision',
  },
  vis_var: {
    range: [1, 1.3],
    decimals: 0,
    derived: 'vision',
  },
}

export const SPECIAL_INGAME_DOMAINS = {
  enemy: {
    life: {
      range: [100, 4700],
      decimals: 0,
      derived: 'life',
    },
  },


  demo_enemy_t1: {
    life: {
      range: [80, 90],
      decimals: 0,
      derived: 'life',
    },
  },
  demo_enemy_t2: {
    life: {
      range: [60, 70],
      decimals: 0,
      derived: 'life',
    },
  },
  demo_enemy_t3: {
    life: {
      range: [50, 60],
      decimals: 0,
      derived: 'life',
    },
  },
  demo_enemy_t4: {
    life: {
      range: [30, 40],
      decimals: 0,
      derived: 'life',
    },
  },
  demo_enemy_low_life: {
    life: {
      range: [20, 20],
      decimals: 0,
      derived: 'life',
    },
  },
  entity_dmr: {
    vis_range: {
      range: [600, 750],
      decimals: 0,
      derived: 'vision',
    }
  },
}

export function stats3_const(val, ingame_domain_key) {
  return {
    physical: val,
    mental: val,
    shooting: val,
    perception: val,
    tactical: val,
    ingame_domain_key,
  };
}

export function statstoSecondaryStats(stats) {
  const secondaryStats = {}

  for (const key in STATS3_DERIVED_DOMAIN) {
    const derive = STATS3_DERIVED_DOMAIN[key];
    let value = lerp(derive.range[0], derive.range[1], stats[derive.base] / 100);
    value = Math.round(value * Math.pow(10, derive.decimals)) / Math.pow(10, derive.decimals);
    secondaryStats[key] = value;
  }

  return secondaryStats
}

// 1차 스탯에 대한 2차 스탯 증가량
export function calculatePrimaryStat(stat, firearm_stat) {
  const ingame_domain_key = stat.ingame_domain_key;

  let additional_damage = 0;
  if (firearm_stat) {
    // shooting 스탯에 따른 추가 피해
    const { firearm_ty, firearm_projectile_per_shoot, firearm_shoot_pattern } = firearm_stat;
    additional_damage = stat.shooting * 5;
    if (firearm_ty === 'sg') {
      additional_damage = Math.round(stat.shooting * 5 / firearm_projectile_per_shoot);
    } else if (firearm_ty === 'smg') {
      additional_damage = Math.round(stat.shooting * 5 / (firearm_shoot_pattern.length + 1));
    }
  }

  // 정신력 스탯에 따른 피해 감소
  let damage_reduction = 0;
  damage_reduction = stat.mental / 200.0;

  let penetrate_reduction = 0;
  penetrate_reduction = stat.mental / 200.0;

  // 피지컬 스텟에 따른 교전 중 이동속도 증가 배율
  let additional_speed_mult = 0;
  let speed_mult_max = opts.EXP_SPEED_MULT_ENGAGE_PHY_MAX
  additional_speed_mult = Math.min(speed_mult_max, speed_mult_max * stat.physical / 100.0);

  return {
    life: ingameFromDerived('life', stat.life ?? derivedFromBase('life', stat.physical), ingame_domain_key),
    additional_speed_mult,

    damage_reduction,
    penetrate_reduction,

    additional_damage,
    aimvar_decay_per_tick: ingameFromDerived('aimvar_decay_per_tick', stat.aimspeed ?? derivedFromBase('aimspeed', stat.shooting), ingame_domain_key),
    reload_speed: ingameFromDerived('firearm_reload_duration_mult', stat.reloadspeed ?? derivedFromBase('reloadspeed', stat.shooting), ingame_domain_key),

    accuracy: ingameFromDerived('accuracy', stat.accuracy ?? derivedFromBase('accuracy', stat.perception), ingame_domain_key),
    evasion: ingameFromDerived('evasion', stat.evasion ?? derivedFromBase('evasion', stat.perception), ingame_domain_key),

    speed: ingameFromDerived('speed', stat.speed ?? derivedFromBase('speed', stat.physical), ingame_domain_key),
    aimvar_incr_per_hit: ingameFromDerived('aimvar_incr_per_hit', stat.tolerance ?? derivedFromBase('tolerance', stat.mental), ingame_domain_key),
    vis_range: ingameFromDerived('vis_range', stat.vision ?? derivedFromBase('vision', stat.perception), ingame_domain_key),
    vis_var: ingameFromDerived('vis_var', stat.vision ?? derivedFromBase('vision', stat.perception), ingame_domain_key, false),
  };
}

export function primaryStatApply(entity, stat, firearm_stat, ignore_life) {
  const primary_stat = calculatePrimaryStat(stat, firearm_stat);

  if (!ignore_life) {
    entity.life = primary_stat.life;
  }
  entity.evasion = primary_stat.evasion;
  entity.speed = primary_stat.speed;

  entity.aimvar_incr_per_hit = primary_stat.aimvar_incr_per_hit;
  entity.aimvar_decay_per_tick = primary_stat.aimvar_decay_per_tick;

  entity.reload_speed = primary_stat.reload_speed;
  entity.accuracy = primary_stat.accuracy;

  entity.vis_range = primary_stat.vis_range;
  entity.vis_var *= primary_stat.vis_var;

  entity.additional_damage = primary_stat.additional_damage;
  entity.damage_reduction = primary_stat.damage_reduction;
  entity.penetrate_reduction = primary_stat.penetrate_reduction;
  entity.additional_speed_mult = primary_stat.additional_speed_mult;
}

export function updateEntityStat3(entity, stat, firearm_stat) {
  if (firearm_stat) {
    // 총기 template 복사해서 넣기
    for (const key in firearm_stat) {
      entity[key] = firearm_stat[key];
    }
  }

  // DMR 스페셜 스탯 적용
  if (!stat.ingame_domain_key && entity.firearm_ty === 'dmr') {
    stat.ingame_domain_key = 'entity_dmr';
  }

  primaryStatApply(entity, stat, firearm_stat);

  if (stat.name) {
    entity.name = stat.name;
  }
  entity._stat = stat;
  entity._firearm_stat = firearm_stat;

  return entity;
}

export function entityFromStat3(stat, firearm_stat) {
  const base = { ...ENTITY_CONFIG_TMPL };
  return updateEntityStat3(base, stat, firearm_stat);
}

export const STATS3_TMPL = {
  name: null,

  physical: 50,
  mental: 50,
  shooting: 50,
  perception: 50,
  tactical: 50,
};
