import source from '../downloaded/shopItemSale.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { item_count_max, sale_items } = row;
  list.push({
    item_count_max: parseInt(item_count_max),
    sale_items: parseInt(sale_items),
  });
}

export function getItemSaleData(item_count) {
  const data = list.find((e) => e.item_count_max >= item_count);
  return data ?? list[0];
}
