import source from '../downloaded/char2roles.json' assert { type: 'json' };

export const roles = [];
for (const row of source) {
  let { name, physical, perception, mental, tactical, shooting, weight, key, firearmAptitude_AR, firearmAptitude_DMR, firearmAptitude_HG, firearmAptitude_SG, firearmAptitude_SMG, descr, descPositions } = row;
  const stat_weights = {
    physical: +physical,
    perception: +perception,
    mental: +mental,
    tactical: +tactical,
    shooting: +shooting
  };

  const firearmAptitudes = {
    firearmAptitude_AR: +firearmAptitude_AR,
    firearmAptitude_DMR: +firearmAptitude_DMR,
    firearmAptitude_HG: +firearmAptitude_HG,
    firearmAptitude_SG: +firearmAptitude_SG,
    firearmAptitude_SMG: +firearmAptitude_SMG
  }

  roles.push({
    key,
    name: name.trim(),
    stat_weights,
    weight: +weight,
    firearmAptitudes: firearmAptitudes,
    descr,
    descPositions: descPositions ? descPositions.split(',').map((s) => +(s.trim())) : [],
  });
}

export function rolesBykey(key) { return roles.find((p) => p.key === key); }
