/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TreeBranch = ({ property1 }) => {
  return (
    <div className={`tree-branch ${property1}`}>
      {["branch-a", "branch-b", "branch-c", "branch-d"].includes(property1) && (
        <div className="rectangle-7">{property1 === "branch-a" && <div className="rectangle-8" />}</div>
      )}
    </div>
  );
};

TreeBranch.propTypes = {
  property1: PropTypes.oneOf(["branch-d", "branch-x", "branch-a", "branch-c", "branch-b"]),
};
