import dayjs from 'dayjs';

export const TICK_PER_DAY = 24;
export const TICK_PER_WEEK = TICK_PER_DAY * 7;
export const TICK_PER_MONTH = TICK_PER_WEEK * 4;
export const TICK_PER_YEAR = TICK_PER_DAY * 365;

export function tickToDate(tick) {
  return new Date(1970, 0, 1, tick);
}

export function tickToYear(tick) {
  return Math.floor(tick / TICK_PER_YEAR) + 1970;
}

export function tickToMonth(tick) {
  return Math.floor(tick / TICK_PER_MONTH);
}

export function tickToDateStr(tick) {
  return dayjs(tickToDate(tick)).format("YYYY-MM-DD HH:00");
}

export function tickToDateTimeStrObj(tick) {
  return {
    date: dayjs(tickToDate(tick)).locale('en').format("YYYY MM DD"),
    time: dayjs(tickToDate(tick)).locale('en').format("A HH:00"),
  };
}

export function tickToDateShort(tick) {
  return dayjs(tickToDate(tick)).format("M/D");
}

export function tickTodddhhmmDMMMYYYY(tick) {
  return {
    ddd: dayjs(tickToDate(tick)).locale('en').format('ddd'),
    hhmm: dayjs(tickToDate(tick)).locale('en').format('HH:mm'),
    D: dayjs(tickToDate(tick)).locale('en').format('D'),
    MMM: dayjs(tickToDate(tick)).locale('en').format('MMM'),
    YYYY: dayjs(tickToDate(tick)).locale('en').format('YYYY'),
  }
}

export function tickTod(tick) {
  return dayjs(tickToDate(tick)).format("d");
}