import React, { useState } from 'react';
import { PortraitWrapper } from './PortraitsView';
import './DialogView.css';

import { briefs as data_briefs } from './data/google/processor/data_briefs.mjs';
import { SoundButton, triggerSound } from './sound.mjs';

const UPDATE_INTERVAL = 50;
const AUTO_INTERVAL = 1000;

class DialogTextView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idx: 0,
      text: props.text.replace(/\\n/g, '\n'),
      fixed: false,
      rightQuotePosition: 0,
    };

    this.textRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.rightQuoteRef = React.createRef();
  }

  componentDidMount() {
    this.interval = setTimeout(this.onTick.bind(this), UPDATE_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.text !== this.props.text) {
      this.setState({
        idx: 0,
        text: this.props.text.replace(/\\n/g, '\n'),
        fixed: false,
        rightQuotePosition: 0,
      });
      clearTimeout(this.interval);
      this.interval = setTimeout(this.onTick.bind(this), UPDATE_INTERVAL);
    } else if (prevState.idx !== this.state.idx) {
      this.checkLineWrap();
    }
  }

  onTick() {
    const { onEnd } = this.props;
    let { idx, text } = this.state;

    if (idx < text.length) {
      idx++;
      this.setState({ idx }, this.checkLineWrap);
    }

    if (idx < text.length) {
      this.interval = setTimeout(this.onTick.bind(this), UPDATE_INTERVAL);
    } else {
      onEnd?.();
    }
  }

  checkLineWrap() {
    const textElement = this.textRef.current;
    const wrapperElement = this.wrapperRef.current;
    const rightQuoteElement = this.rightQuoteRef.current;

    if (textElement && wrapperElement && rightQuoteElement) {
      const textRect = textElement.getBoundingClientRect();
      const wrapperRect = wrapperElement.getBoundingClientRect();
      const rightQuotePosition = Math.min(textRect.right, wrapperRect.right) - wrapperRect.left;

      if (textRect.right > wrapperRect.right && (!this.state.fixed || this.state.rightQuotePosition !== rightQuotePosition)) {
        this.setState({ fixed: true, rightQuotePosition });
      } else if (textRect.right <= wrapperRect.right && this.state.fixed) {
        this.setState({ fixed: false, rightQuotePosition });
      }
    }
  }

  render() {
    const { skip, className } = this.props;
    let { idx, text, fixed, rightQuotePosition } = this.state;

    if (skip) {
      idx = text.length;
    }

    let substr = text.substring(0, idx);
    if (idx < text.length) {
      substr += '_';
    }

    return (
      <div ref={this.wrapperRef} className={`dialogtextview-wrapper ${className}`}>
        <span className='quote'>“</span>
        <div ref={this.textRef} className='dialogtextview-base'>{substr}</div>
        <span ref={this.rightQuoteRef} className={`quote-right ${fixed ? 'fixed' : ''}`} style={{ right: fixed ? `${rightQuotePosition}px` : 'auto' }}>”</span>
      </div>
    );
  }
}

const svg_icon = <svg className='svgicon-default' xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
  <path d="M17.6065 28.4718L32.9009 2.05427C33.2068 1.52592 32.7378 0.886271 32.1419 1.01908L17.5875 4.26287C17.2006 4.3491 16.7994 4.3491 16.4125 4.26287L1.85809 1.01907C1.2622 0.886267 0.793225 1.52591 1.09911 2.05427L16.3935 28.4718C16.6634 28.9381 17.3366 28.9381 17.6065 28.4718Z" fill="#FFC962" stroke="#F2BA51" stroke-width="2" />
</svg>

const svg_icon_hover = <svg className='svgicon' width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_793_53441)">
    <path d="M25.5281 29.1513C26.1832 30.2829 27.8168 30.2829 28.4719 29.1513L43.7663 2.7338C44.5087 1.45156 43.3705 -0.100786 41.9244 0.221523L27.37 3.46531C27.1263 3.51962 26.8737 3.51962 26.63 3.46531L12.0756 0.221519C10.6295 -0.10079 9.49134 1.45156 10.2337 2.7338L25.5281 29.1513Z" fill="#FFF4DE" />
    <path d="M26.3935 28.6503C26.6634 29.1166 27.3366 29.1166 27.6065 28.6503L42.9009 2.23277C43.2068 1.70441 42.7378 1.06477 42.1419 1.19757L27.5875 4.44136C27.2006 4.5276 26.7994 4.5276 26.4125 4.44136L11.8581 1.19757C11.2622 1.06476 10.7932 1.70441 11.0991 2.23276L26.3935 28.6503Z" stroke="#FFF0D4" stroke-width="2" />
  </g>
  <defs>
    <filter id="filter0_d_793_53441" x="0" y="0.178486" width="54" height="49.8215" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="10" />
      <feGaussianBlur stdDeviation="5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.196549 0 0 0 0 0.0617789 0 0 0 0 0.0192199 0 0 0 1 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_793_53441" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_793_53441" result="shape" />
    </filter>
  </defs>
</svg>

const svg_icon_pressed = <svg className='svgicon' width="50" height="46" viewBox="0 0 50 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_793_53444)">
    <path d="M23.7012 33.2601C24.2793 34.2466 25.7207 34.2466 26.2988 33.2601L39.7938 10.2279C40.4488 9.10994 39.4446 7.75652 38.1686 8.03752L25.3265 10.8656C25.1115 10.913 24.8885 10.913 24.6735 10.8656L11.8314 8.03752C10.5554 7.75651 9.55118 9.10993 10.2062 10.2279L23.7012 33.2601Z" fill="#DED0B5" />
    <path d="M24.564 32.7546C24.7558 33.0818 25.2442 33.0818 25.436 32.7546L38.931 9.72233C39.1289 9.38458 38.8389 8.91386 38.3836 9.01412L25.5415 11.8422L25.3265 10.8656L25.5415 11.8422C25.1848 11.9208 24.8152 11.9208 24.4585 11.8422L24.6735 10.8656L24.4585 11.8422L11.6164 9.01412C11.1611 8.91386 10.8711 9.38458 11.069 9.72233L24.564 32.7546Z" stroke="#D6C5A3" stroke-width="2" />
  </g>
  <defs>
    <filter id="filter0_d_793_53444" x="0" y="0" width="50" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.196549 0 0 0 0 0.0617789 0 0 0 0 0.0192199 0 0 0 1 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_793_53444" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_793_53444" result="shape" />
    </filter>
  </defs>
</svg>

export function resolveName(name, names) {
  let dynamicName = name;
  if (name.startsWith('#dynamic-sq')) {
    const squadIdx = parseInt(name.split('-')[2]);
    dynamicName = names[squadIdx];
  }
  return dynamicName;
}

export class DialogView extends React.Component {
  constructor(props) {
    super(props);

    this.keyDown = this.onKeyDown.bind(this);

    this.state = {
      auto: props.auto ?? false,
      dialogIdx: 0,

      skip: false,
      end: false,
      done: false,
      isHovering: false,
      isPressed: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  onKeyDown(ev) {
    if (ev.key === 'Escape') {
      this.props.onDone?.();
      this.setState({ done: true });
    }
  }

  nextProgress() {
    const { data } = this.props;
    const { dialogIdx, skip, end, done } = this.state;

    if (!skip && !end) {
      return {
        action: 'skip',
        cb: () => this.setState({ skip: true }),
      };
    }

    if (dialogIdx == data.length - 1) {
      return {
        action: 'done',
        cb: () => {
          if (done) {
            return;
          }
          this.props.onDone?.();
          this.setState({ done: true });
        },
      };
    } else {
      return {
        action: 'next',
        cb: () => this.onNext(dialogIdx),
      };
    }
  }

  renderProgress() {
    const { action, cb } = this.nextProgress();
    const handleHovering = this.handleHovering();
    const handlePressed = this.handlePressed();
    return <div onClick={cb} onMouseOver={handleHovering} onMouseOut={handleHovering} onMouseDown={handlePressed} onMouseUp={handlePressed} title={action}>{this.renderSvgIcon()}</div>;
  }

  renderSvgIcon() {
    const { isHovering, isPressed } = this.state;
    return (
      <div className='svgicon-container'>
        <div className='svg-ellipse' />
        {isPressed ? svg_icon_pressed : (isHovering ? svg_icon_hover : svg_icon)}
      </div>
    );
  }

  handleHovering() {
    return () => { this.setState(prevState => ({ isHovering: !prevState.isHovering })) };
  }

  handlePressed() {
    return () => { this.setState(prevState => ({ isPressed: !prevState.isPressed })) };
  }

  onClick() {
    const { cb } = this.nextProgress();
    cb();
    triggerSound('UI_Outgame_Button_Click_Default');
  }

  setDialogIdx(dialogIdx) {
    this.setState({ dialogIdx, skip: false, end: false });
  }

  onNext(curIdx) {
    const { data } = this.props;
    const { dialogIdx } = this.state;

    if (curIdx !== dialogIdx) {
      return;
    }
    if (dialogIdx == data.length - 1) {
      return;
    }

    this.setDialogIdx(dialogIdx + 1);
  }

  render() {
    const { data, squadNames } = this.props;
    const { dialogIdx, auto, skip } = this.state;

    const {
      name,
      text,
    } = data[dialogIdx];

    const dynamicName = resolveName(name, squadNames);
    // 현재 대사를 할 대상이 존재하지 않는 경우
    if (!dynamicName) {
      this.nextProgress().cb?.();
      return null;
    }
    const portrait = name ? <PortraitWrapper agent={{ name: dynamicName }} className="portrait-frame-dialog" noframe={true} /> : null;

    return (
      <div
        className="dialogview"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          this.onClick();
        }}>
        <div className='dialogview-rectangle' />
        <div className='dialogview-stripe-pattern' />
        <div className='dialogview-glow' />
        <div className='dialogview-vector' />
        <div className="dialogview-portrait">{portrait}</div>
        <div className="dialogview-content">
          <div className="dialogview-name">{dynamicName}</div>
          <div className="dialogview-body">
            <DialogTextView text={text} skip={skip} className="dialogtextview" onEnd={() => {
              this.setState({ skip: false, end: true });
              if (auto) {
                setTimeout(() => {
                  this.onClick();
                }, AUTO_INTERVAL);
              }
            }} />
            {this.renderProgress()}
            <div className='dialogview-autocontainer'>
              <SoundButton onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();

                const { action } = this.nextProgress();
                if (action === 'next') {
                  this.onClick();
                }
                this.setState({ auto: !auto })
              }}>Auto={auto ? 'ON' : 'OFF'}</SoundButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// 임무 브리핑 시스템
export class DialogView2 extends React.Component {
  constructor(props) {
    super(props);

    this.keyDown = this.onKeyDown.bind(this);

    this.state = {
      idx: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  onKeyDown(ev) {
    if (ev.key === 'Escape') {
      this.props.onDone?.();
    }
  }

  onNext(curIdx) {
    const { data } = this.props;
    const { idx } = this.state;

    if (curIdx !== idx) {
      return;
    }
    if (idx == data.length - 1) {
      this.props?.onDone?.();
      return;
    }

    this.setState({ idx: idx + 1 });
  }

  render() {
    const { idx } = this.state;
    const { data } = this.props;

    const hist = data.slice(0, idx + 1);
    hist.reverse();
    const text = hist.find((item) => item.text)?.text ?? '';
    let text_end = '';
    if (idx === data.length - 1) {
      text_end = '[END]';
    }
    const img_src = hist.find((item) => item.img_src)?.img_src ?? '';

    return <div className="dialogview2" onClick={() => {
      this.onNext(idx);
      triggerSound('UI_Outgame_Button_Click_Default');
    }}>
      <img className="dialogview2-img" src={img_src} />
      <div className="dialogview2-text">
        <div className="dialogview2-text-inner">
          {text} {text_end}
        </div>
      </div>
    </div>;
  }
}

export const BRIEF_SAMPLE = data_briefs[0].brief;
// i18n ignore 22
export const DIALOG_SAMPLE = [
  {
    name: 'Alice',
    text: 'Hello, how are you?',
  },
  {
    name: 'Indiana Jones',
    text: 'I am good, how are you?',
  },
  {
    name: 'Alice',
    text: 'I am good too, thanks for asking.',
  },
  {
    name: 'Bob',
    text: 'You are welcome.',
  },
  {
    name: 'Alice',
    text: 'Goodbye!',
  },
];

export function DialogDemoView(props) {
  const [key, setKey] = useState(0);

  return <DialogView key={key} data={DIALOG_SAMPLE} onDone={() => setKey(key + 1)} auto={true} />;
}


export function Dialog2DemoView(props) {
  return <DialogView2 data={BRIEF_SAMPLE} />;
}
