import { Component, createRef } from 'react';

export class CanvasTestView extends Component {
  constructor(props) {
    super(props);

    this.canvasRef = createRef();

    this.state = {
    };
  }

  componentDidMount() {
    this.renderCanvas();
  }

  renderCanvas() {
    const canvas = this.canvasRef.current;
    var ctx = canvas.getContext("2d");

    var pos = { x: 0, y: 0 };

    function setPosition(e) {
      pos.x = e.offsetX;
      pos.y = e.offsetY;
    }

    function draw(e) {
      if (e.buttons !== 1) return; // if mouse is pressed.....

      var color = "#cb3594";

      ctx.beginPath(); // begin the drawing path

      ctx.lineWidth = 5; // width of line
      ctx.lineCap = "round"; // rounded end cap
      ctx.strokeStyle = color; // hex color of line

      ctx.moveTo(pos.x, pos.y); // from position
      setPosition(e);
      ctx.lineTo(pos.x, pos.y); // to position
      ctx.stroke(); // draw it!
    }

    var outlineImage = new Image();
    outlineImage.onload = function() {
      // the default, set explicitly because we're changing it elsewhere
      ctx.globalCompositeOperation = 'source-over';

      ctx.drawImage(outlineImage, 0, 0);

      // don't draw over the transparent parts of the canvas
      ctx.globalCompositeOperation = 'source-atop';

      // wait until the stencil is loaded before handing out crayons
      canvas.addEventListener("mousemove", draw);
      canvas.addEventListener("mousedown", setPosition);
      canvas.addEventListener("mouseenter", setPosition);
    }
    outlineImage.src = "https://i.stack.imgur.com/so095.png";
  }

  render() {
    return <div>
      <canvas ref={this.canvasRef} width="800" height="800" />
    </div>;
  }
}

