import source from '../downloaded/eventCore.json' assert { type: 'json' };
import { split } from './parseUtilities.mjs';

export const eventCores = [];
for (const row of source) {
  let { id, category, tags, trigger_group, trigger, condition, condition_value, prob, bonus_condition, bonus_condition_value, bonus_prob, pick_agents, title, scene, branch, desc, Indiana_Jones, Solid_Snake } = row;

  eventCores.push({
    id,
    category,
    tags: split(tags),
    trigger_group,
    trigger,
    condition: split(condition),
    condition_value: split(condition_value),
    prob: parseFloat(prob),
    bonus_condition: split(bonus_condition),
    bonus_condition_value: split(bonus_condition_value),
    bonus_prob: bonus_prob ? parseFloat(bonus_prob) : 0,
    pick_agents: split(pick_agents),
    title,
    scene,
    branch: split(branch),
    desc,
  });
}
