import { ROLE_PRESETS } from './opts.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { entityFromStat2 } from './stats2.mjs';
import { entityFromStat3 } from './stats3.mjs';
import { perk2Sets } from './presets_perk2.mjs';
import { agentModifierEffectValue } from './character2.mjs';

export function entityAlly(agent, index, mission_schedule_ty) {
  const { throwables, attachables, heals, statsPerks, operatorPerks, name, callsign, mood, tier, role } = agent;
  let firearm = { ...agent.firearm };
  let { equipment } = agent;
  const simextra = agent.simextra ?? {};
  const controls_list = agent.controls_list ?? [];

  // TODO
  // const power = agent.power + agentPowerModifiers(agent, turn, squads).amount;
  // const stats = perk_apply_stats(stats_const(power, 10), perks.list);
  // stats.name = name;

  const role_preset = ROLE_PRESETS[role] ?? {};

  const entityopts = {
    ...firearm,
    ...equipment,
    ...simextra,
    controls_list,

    ...role_preset,
  };

  let entity = null;
  if (agent.stats2) {
    const stats2 = agentEffectiveStat(agent);
    entity = entityFromStat2({ name, ...stats2 }, entityopts);
  } else {
    const realStats = { ...agent.realStats };

    const applyStatMultiplier = (stats, stats_mult) => {
      for (const [key, value] of Object.entries(stats)) {
        stats[key] = Math.min(value * stats_mult, 100);
      }
    }

    if (mission_schedule_ty === 'final') {
      const stat_percent_all = agentModifierEffectValue(agent, 'final_additional_stat_percent');
      for (const key of Object.keys(realStats)) {
        realStats[key] = Math.max(Math.min(Math.round(realStats[key] * (100 + stat_percent_all + agentModifierEffectValue(agent, `final_additional_stat_percent_${key}`)) / 100), 100), 0);
      }
    }

    const perkKey = 'perk2_pointman_spearhead';
    if (index === 0 && operatorPerks[perkKey] === 'activated') {
      applyStatMultiplier(realStats, perk2Sets[perkKey].stats_rate);
    }

    if (mood) {
      const moodData = getMoodData(mood);
      applyStatMultiplier(realStats, moodData.stats_mult);
    }

    entity = entityFromStat3({ name, ...realStats }, entityopts);
  }

  const applyPerks = (perks) => {
    for (const [key, value] of Object.entries(perks)) {
      if (value === 'activated') {
        let perk_key = key;
        const splits = key.split('_');
        if (!isNaN(parseInt(splits[splits.length - 1]))) {
          splits.splice(splits.length - 1, 1);
          perk_key = splits.join('_');
        }
        // 고속 탄자
        if (key.startsWith('perk2_sharpshooter_high_velocity')) {
          if (firearm.firearm_ty !== 'dmr') {
            continue;
          }
        }
        // AR 전문화
        if (key.startsWith('perk2_pointman_ar_specialization')) {
          if (firearm.firearm_ty !== 'ar') {
            continue;
          }
        }

        // SMG 전문화
        if (key.startsWith('perk2_pointman_smg_specialization')) {
          if (firearm.firearm_ty !== 'smg') {
            continue;
          }
        }
        entity[perk_key] = perk2Sets[key] ?? perk2Sets[perk_key];

        // 기분파
        if (perk_key === 'perk2_common_increase_acc_per_mood') {
          entity[perk_key].mood = mood;
        }
      }
    }
  }

  applyPerks(statsPerks);
  applyPerks(operatorPerks);

  // growth-33: 스텟에 연결된 퍽 제거
  /*
  for (const s of data_stats.stats) {
    if (s.value > agent.stats2[s.stat_key]) {
      continue;
    }
    entity[s.perk] = true;
  }
  */
  entity.firearm = firearm;
  const firearm_aptitude = agent.firearm.firearm_ty;
  entity.firearm_aptitude_accuracy_multiplier = agent.firearmAptitudes[firearm_aptitude.toUpperCase()].Value;

  entity.life = agent.life ?? entity.life;
  entity.life_max = agent.life_max ?? entity.life;
  if (entity.perk2_common_jack_of_all) {
    entity.life += entity.perk2_common_jack_of_all.life_amount;
    entity.life_max += entity.perk2_common_jack_of_all.life_amount;
  }

  entity.allow_fire_control = true;
  entity.allow_cover_edge = false;

  entity.idx = agent.idx;
  entity.level = agent.level.cur;
  entity.spawnarea = agent.spawnarea;
  entity.vis_group = 0;

  entity.heals = [];

  entity.throwables = [];
  for (let i = 0; i < 1; i++) {
    let throwable = throwables[i];

    if (throwable.throwable_rate !== 0 && throwable.key !== 'throwable_lockpick') {
      entity.throwables.push(throwable);
    }
  }
  entity.throwables_max = throwables.filter(t => t.throwable_rate !== 0 && t.key !== 'throwable_lockpick').length;

  entity.attachables = [...attachables];
  entity.attachables_max = attachables.length;
  entity.heals = [...heals];
  entity.heals_max = heals.length;
  entity.tier = tier;
  entity.role = role;
  entity.mood = mood;

  const ammo_total = entity.firearm.requirements['bullet'];
  if (ammo_total) {
    firearm.ammo_total = ammo_total;
  }
  else {
    firearm.ammo_total = 100000;
  }
  // const ammo_total = firearm.requirements['bullet'];
  // if (ammo_total) {
  //   entity.ammo_total = ammo_total;
  // }

  entity.callsign = callsign;
  if (agent.fixedOperatorKey) {
    entity.fixedOperatorKey = agent.fixedOperatorKey;
  }
  return entity;
}


