import source from '../downloaded/market.json' assert { type: 'json' };

export const marketItems = [];
for (const row of source) {
  let { stability_name, stability_level, ty, min, max, perk_len, perk_list, throwable } = row;
  stability_level = parseInt(stability_level) - 1;
  min = parseInt(min);
  max = parseInt(max);
  perk_len = parseInt(perk_len);
  perk_list = (perk_list ?? '').split(', ');
  marketItems.push({ stability_name, stability_level, ty, min, max, perk_len, perk_list, throwable });
}
