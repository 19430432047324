import '../style.css';

export function FH1Panel(props) {
  const { 'title': _, ...props0 } = props;
  return <div {...props0} className={`fh1-panel ${props.className ?? ''}`}>
    {props.title || props.subtitle ?
      <div className='fh1-title'>
        <div className='fh1-title-label'>{props.title}</div>
        <div className='fh1-subtitle-label'>{props.subtitle}</div>
      </div>
      :
      null
    }
    <div className='fh1-content'>
      {props.children}
    </div>
  </div>;
}

export function FH1PanelSmall(props) {
  props = { ...props, className: props.className ?? '' };
  props.className = 'fh1-panel-small ' + props.className;
  return <FH1Panel {...props} />;
}
