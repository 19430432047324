import React, { useState, useRef } from 'react';
import './FigmaSquadView.css';
import { Rng } from './rand.mjs';
import { names4 } from './names';
import { DEFAULT_FIREARM, createCharacter2 } from './character2.mjs';
import { roles, rolesBykey } from './data/google/processor/data_char2roles.mjs';
import { agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { characterChartData, CHART_OPT_DETAIL } from './CharacterView2';
import { PortraitWrapper, PortraitWrapper2 } from './PortraitsView';
import { Radar } from 'react-chartjs-2';
import { L } from './localization.mjs';
import { calcAntiBulletproofpower, calcAntiPersonalPower, calcEffectiveDistance, calcSquadPower, MakeAdvice } from './LoadoutView';
import { RoleBadge, AgentCard2Smiley, FigmaCard2Note, AgentDetailStatsGraph } from './FigmaListView';
import { evalCSSClass, Lobby2Footer, Lobby2PageButton } from './FigmaLobbyView';
import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { triggerSound, SoundButton, soundprops } from './sound.mjs';
import { operatorExp as data_operatorExp } from './data/google/processor/data_operatorExp.mjs';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView';
import { GearNameLine } from './FigmaGearsView';
import { firearm_none } from './presets_firearm.mjs';
import { PowerIndicator, AgentModifierIcon } from './Badges.js';

const ICON_CLOSE = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path d="M26.3995 10.3432L22.1569 6.10052L16.5 11.7574L10.8431 6.10052L6.60051 10.3432L12.2574 16L6.60051 21.6569L10.8431 25.8995L16.5 20.2427L22.1569 25.8995L26.3995 21.6569L20.7426 16L26.3995 10.3432Z" fill="#3D301C" />
</svg>;

const ICON_CHECKED_BOX = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
  <rect x="2" y="2.75" width="22.9023" height="22.9023" rx="4" fill="black" stroke="#7B5C53" strokeWidth="2" />
  <path d="M7.01855 14.3693L11.5099 19.1281L19.8921 8.49268" stroke="#6BC70F" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

const ICON_UNCHECKED_BOX = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
  <rect x="2" y="2.75" width="22.9023" height="22.9023" rx="4" fill="black" stroke="#7B5C53" strokeWidth="2" />
</svg>;

const ICON_BADGE = <svg xmlns="http://www.w3.org/2000/svg" width="46" height="57" viewBox="0 0 46 57" fill="none" style={{ flexShrink: 0 }}>
  <path d="M23 55.3372L1.5 45.0546V1.5H44.5V45.0546L23 55.3372Z" fill="#AC9C71" stroke="#C9B787" strokeWidth="3" />
</svg>

const ICON_DETAIL = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g filter="url(#filter0_d_252_927)">
    <path d="M15.5 12C17.433 12 19 10.433 19 8.5C19 6.567 17.433 5 15.5 5C13.567 5 12 6.567 12 8.5C12 10.433 13.567 12 15.5 12Z" className='icon-detail' />
    <path d="M18.5 26V14H12.5V26H18.5Z" className='icon-detail' />
    <path d="M18.5 8.5C18.5 10.1569 17.1569 11.5 15.5 11.5C13.8431 11.5 12.5 10.1569 12.5 8.5C12.5 6.84315 13.8431 5.5 15.5 5.5C17.1569 5.5 18.5 6.84315 18.5 8.5ZM13 14.5H18V25.5H13V14.5Z" strokeOpacity="0.1" className='icon-detail-stroke' />
  </g>
  <defs>
    <filter id="filter0_d_252_927" x="12" y="5" width="7" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_927" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_252_927" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_EXCLUDE = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
  <g filter="url(#filter0_d_252_924)">
    <path d="M20.4559 5.01229L16.2133 0.769647L10.5564 6.4265L4.89954 0.769646L0.656902 5.01229L6.31376 10.6691L0.656902 16.326L4.89954 20.5686L10.5564 14.9118L16.2133 20.5686L20.4559 16.326L14.799 10.6691L20.4559 5.01229Z" className='icon-exclude' />
    <path d="M10.2028 6.78005L10.5564 7.13361L10.91 6.78005L16.2133 1.47675L19.7488 5.01229L14.4455 10.3156L14.0919 10.6691L14.4455 11.0227L19.7488 16.326L16.2133 19.8615L10.9099 14.5582L10.5564 14.2047L10.2028 14.5582L4.89954 19.8615L1.36401 16.326L6.66731 11.0227L7.02086 10.6691L6.66731 10.3156L1.36401 5.01229L4.89954 1.47675L10.2028 6.78005Z" strokeOpacity="0.1" className='icon-exclude-stroke' />
  </g>
  <defs>
    <filter id="filter0_d_252_924" x="0.65686" y="0.769531" width="19.7991" height="21.7991" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_924" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_252_924" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_EXCHANGE = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
  <g filter="url(#filter0_d_252_904)">
    <path d="M16.0881 4.29389V1.28174L7.08813 9.28174H15.5881C19.1634 9.29883 22.0564 12.2026 22.0564 15.7818C22.0564 16.7089 21.8623 17.5906 21.5126 18.3886L26.0903 20.4028C26.7116 18.9886 27.0564 17.4255 27.0564 15.7818C27.0564 9.60877 22.1926 4.57172 16.0881 4.29389Z" className='icon-exchange' />
    <path d="M15.5238 22.2817C11.9489 22.2642 9.0564 19.3608 9.0564 15.7818C9.0564 14.8457 9.25429 13.9558 9.61052 13.1517L5.03279 11.1375C4.40504 12.5578 4.0564 14.1291 4.0564 15.7818C4.0564 21.9762 8.95397 27.0268 15.0881 27.2725V30.2817L24.0881 22.2817H15.5238Z" className='icon-exchange' />
    <path d="M15.5881 4.29389V4.77165L16.0654 4.79338C21.904 5.05911 26.5564 9.87729 26.5564 15.7818C26.5564 17.1776 26.2967 18.5116 25.8233 19.739L22.1545 18.1248C22.4148 17.3917 22.5564 16.6029 22.5564 15.7818C22.5564 11.9272 19.4409 8.80015 15.5905 8.78174H15.5881H8.40323L15.5881 2.39516V4.29389ZM15.5213 22.7817H15.5238H22.773L15.5881 29.1683V27.2725V26.7921L15.1081 26.7729C9.24104 26.5379 4.5564 21.7068 4.5564 15.7818C4.5564 14.377 4.81949 13.0347 5.29876 11.8008L8.96688 13.4147C8.70109 14.1546 8.5564 14.9517 8.5564 15.7818C8.5564 19.6361 11.6714 22.7628 15.5213 22.7817Z" className='icon-exchange-stroke' strokeOpacity="0.1" />
  </g>
  <defs>
    <filter id="filter0_d_252_904" x="4.0564" y="1.28174" width="23" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_904" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_252_904" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_NEXT = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g filter="url(#filter0_d_252_896)">
    <path d="M16.5271 24.4641L29.1765 15.7383L16.5271 7.01245V12.7383L4.55591 12.7383L4.55591 18.7383H16.5271V24.4641Z" className='icon-next' />
    <path d="M16.5271 13.2383H17.0271V12.7383V7.96478L28.296 15.7383L17.0271 23.5117V18.7383V18.2383H16.5271H5.05591L5.05591 13.2383L16.5271 13.2383Z" className='icon-next-stroke' strokeOpacity="0.1" />
  </g>
  <defs>
    <filter id="filter0_d_252_896" x="4.55591" y="7.01245" width="24.6206" height="19.4517" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_896" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_252_896" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_PREV = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <g filter="url(#filter0_d_252_966)">
    <path d="M17.2054 24.4641L4.55591 15.7383L17.2054 7.01245V12.7383L29.1765 12.7383L29.1765 18.7383H17.2054V24.4641Z" className='icon-prev' />
    <path d="M17.2054 13.2383H16.7054V12.7383V7.96478L5.43646 15.7383L16.7054 23.5117V18.7383V18.2383H17.2054H28.6765L28.6765 13.2383L17.2054 13.2383Z" className='icon-prev-stroke' strokeOpacity="0.1" />
  </g>
  <defs>
    <filter id="filter0_d_252_966" x="4.55591" y="7.01245" width="24.6206" height="19.4517" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_966" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_252_966" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_POWER = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
  <path d="M2.66675 4.75C2.66675 3.25 3.44697 1.375 4.61731 1C5.78765 1.375 6.56787 3.25 6.56787 4.75V16H2.66675V4.75Z" fill="#B59E7A" />
  <path d="M7.73618 4.75C7.73618 3.25 8.51641 1.375 9.68674 1C10.8571 1.375 11.6373 3.25 11.6373 4.75V16H7.73618V4.75Z" fill="#B59E7A" />
  <path d="M14.7162 1C13.5458 1.375 12.7656 3.25 12.7656 4.75V16H16.6667V4.75C16.6667 3.25 15.8865 1.375 14.7162 1Z" fill="#B59E7A" />
</svg>

const ICON_LIFE = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
  <path d="M9.16675 4.87873C9.16675 3.77068 7.48781 2 5.41675 2C3.34568 2 1.66675 3.92032 1.66675 6.07518C1.66675 7.08261 2.03371 8.00086 2.63615 8.69312L9.16675 17L15.6975 8.69299C16.2998 8.00074 16.6667 7.08254 16.6667 6.07518C16.6667 3.92032 14.9878 2 12.9167 2C10.8457 2 9.16675 3.815 9.16675 4.87873Z" fill="#B59E7A" />
</svg>

const ICON_CONDITION = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.02857 9.50064C2.9083 10.0937 2.79234 10.7176 2.68091 11.3698L4.65234 11.7066C4.78674 10.92 4.9266 10.1836 5.07098 9.50064H9.01258C9.30788 10.5985 9.63515 11.6983 9.98763 12.6528C10.229 13.3065 10.4919 13.9189 10.7775 14.4262C11.0478 14.9062 11.4029 15.4116 11.8769 15.7156C12.1318 15.8791 12.4526 16.0043 12.8234 16.0006C13.199 15.9967 13.5305 15.8615 13.8024 15.6645C14.3018 15.3028 14.6625 14.6902 14.9539 14.0005C15.3733 13.0078 15.7751 11.5388 16.1621 9.50064H17.6667V8.50064H16.343C16.4484 7.88952 16.5527 7.23412 16.656 6.53249L14.6773 6.24118C14.5556 7.06773 14.4338 7.81916 14.3124 8.50064H10.8167C10.7554 8.26593 10.6953 8.03084 10.6364 7.79672C10.0967 5.65027 9.51501 4.08767 8.85935 3.1539C8.53313 2.68932 8.09883 2.24979 7.51847 2.07573C6.86706 1.88037 6.27129 2.08493 5.82107 2.43842C5.40312 2.76659 5.06897 3.24681 4.79201 3.76665C4.50771 4.30026 4.24501 4.95006 4.00072 5.69009C3.73366 6.49907 3.48028 7.44159 3.24206 8.50064H1.66675V9.50064H3.02857ZM5.2946 8.50064C5.49009 7.67537 5.69257 6.94511 5.89991 6.31704C6.12265 5.64229 6.34404 5.10699 6.55711 4.70708C6.74818 4.34846 6.90625 4.14915 7.01152 4.05017C7.06065 4.09627 7.132 4.17425 7.22255 4.3032C7.65296 4.91617 8.1651 6.16979 8.69681 8.28443C8.71487 8.35626 8.73309 8.42835 8.75146 8.50064H5.2946ZM11.0863 9.50064C11.3315 10.3793 11.5921 11.2242 11.8638 11.9599C12.0867 12.5635 12.3074 13.0669 12.5203 13.4451C12.625 13.6309 12.7149 13.763 12.7864 13.8547C12.8702 13.7288 12.9818 13.5293 13.1116 13.2222C13.4354 12.4558 13.7773 11.2563 14.1248 9.50064H11.0863Z" fill="#B59E7A" />
</svg>

const ICON_MOOD = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M16.6667 9.5C16.6667 13.6421 13.3089 17 9.16675 17C5.02461 17 1.66675 13.6421 1.66675 9.5C1.66675 5.35786 5.02461 2 9.16675 2C13.3089 2 16.6667 5.35786 16.6667 9.5ZM6.66675 10C7.21903 10 7.66675 9.32843 7.66675 8.5C7.66675 7.67157 7.21903 7 6.66675 7C6.11446 7 5.66675 7.67157 5.66675 8.5C5.66675 9.32843 6.11446 10 6.66675 10ZM11.6667 10C12.219 10 12.6667 9.32843 12.6667 8.5C12.6667 7.67157 12.219 7 11.6667 7C11.1145 7 10.6667 7.67157 10.6667 8.5C10.6667 9.32843 11.1145 10 11.6667 10ZM5.80426 11.5192L5.52954 12.4808L9.1669 13.52L12.8043 12.4808L12.5295 11.5192L9.1669 12.48L5.80426 11.5192Z" fill="#B59E7A" />
</svg>

const ICON_ADD = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="51" viewBox="0 0 48 51" fill="none">
  <g filter="url(#filter0_d_337_5542)">
    <path d="M29 1C29 0.447715 28.5523 0 28 0H20C19.4477 0 19 0.447715 19 1V18C19 18.5523 18.5523 19 18 19H1C0.447715 19 0 19.4477 0 20V28C0 28.5523 0.447715 29 1 29H18C18.5523 29 19 29.4477 19 30V47C19 47.5523 19.4477 48 20 48H28C28.5523 48 29 47.5523 29 47V30C29 29.4477 29.4477 29 30 29H47C47.5523 29 48 28.5523 48 28V20C48 19.4477 47.5523 19 47 19H30C29.4477 19 29 18.5523 29 18V1Z" fill="#9E8662" />
  </g>
  <defs>
    <filter id="filter0_d_337_5542" x="0" y="0" width="48" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_337_5542" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_337_5542" result="shape" />
    </filter>
  </defs>
</svg>

const ICON_GEAR_EXCHANGE = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
  <path d="M14.9588 4.08855V1.48975L7.19382 8.39192H14.5274C17.612 8.40667 20.108 10.9119 20.108 14C20.108 14.7998 19.9406 15.5606 19.6388 16.249L23.5884 17.9868C24.1244 16.7667 24.4219 15.4181 24.4219 14C24.4219 8.67407 20.2255 4.32825 14.9588 4.08855Z" fill="#D2B993" />
  <path d="M14.4719 19.608C11.3876 19.5928 8.89198 17.0878 8.89198 14C8.89198 13.1923 9.06272 12.4246 9.37006 11.7308L5.42053 9.99299C4.87892 11.2184 4.57812 12.5741 4.57812 14C4.57812 19.3444 8.80362 23.7019 14.096 23.9138V26.5101L21.8609 19.608H14.4719Z" fill="#D2B993" />
</svg>

const ICON_FIREARM = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.255493 22.8387L4.12783 11.3871V9.64516C6.08177 9.22174 7.57464 7.90323 7.57464 5H29.2555L28.2555 12.5484H17.7432L16.0198 17.7742H11.2043L9.87251 24L0.255493 22.8387ZM11.5275 15.4516H14.3636L15.321 12.5484H12.0215L11.5275 15.4516Z" fill="#3D301C" />
</svg>

const ICON_EQUIPMENT = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
  <path d="M3 8.16377L5.05556 6.58189L5.11111 3H8.75L9.27778 8.16377H15.7222L16.25 3H19.8889L19.9444 6.58189L22 8.16377V23.9345C19.0444 25.5869 14.4352 26 12.5 26C10.5648 26 5.95556 25.5869 3 23.9345V8.16377Z" fill="#3D301C" />
</svg>

const ICON_THROWABLE = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
  <path d="M8.79536 2.396L9.02668 7.01711C5.52509 8.26923 2.95707 11.4169 2.6216 15.1898H22.1711C21.8435 11.5055 19.387 8.41751 16.0113 7.10851V5.42622H19.455C20.665 6.9161 22.4392 9.48452 23.2495 13.0921L24.7904 13.0018C24.7904 8.45644 22.2591 3.63236 21.1755 2.396H8.79536Z" fill="#3D301C" />
  <path d="M2.61475 16.7932H22.1779C21.7812 21.7369 17.5534 25.6277 12.3963 25.6277C7.2393 25.6277 3.01149 21.7369 2.61475 16.7932Z" fill="#3D301C" />
</svg>

const IMG_SQUAD_SLOT_EMPTY = <svg xmlns="http://www.w3.org/2000/svg" width="192" height="96" viewBox="0 0 192 96" fill="none">
  <path d="M149.049 4.40914L148.796 2.59365C149.375 2.46253 150.263 2.47937 150.792 2.57771L150.566 4.40903C150.119 4.30278 149.498 4.31018 149.049 4.40914Z" fill="url(#paint0_linear_1354_73720)" />
  <path d="M151.758 2.82525C152.458 3.04784 153.054 3.39219 153.55 3.82857L152.343 5.20146C152.038 4.93289 151.665 4.71373 151.204 4.56726L151.758 2.82525Z" fill="url(#paint1_linear_1354_73720)" />
  <path d="M147.835 2.8253C147.135 3.04789 146.539 3.39224 146.043 3.8286L147.25 5.20151C147.555 4.93295 147.928 4.71378 148.389 4.56731L147.835 2.8253Z" fill="url(#paint2_linear_1354_73720)" />
  <path d="M154.25 4.586C154.656 5.12568 154.948 5.72865 155.153 6.34715L153.419 6.92394C153.264 6.45693 153.055 6.03853 152.789 5.68465L154.25 4.586Z" fill="url(#paint3_linear_1354_73720)" />
  <path d="M145.343 4.58603C144.937 5.12571 144.645 5.72867 144.44 6.34717L146.174 6.92397C146.329 6.45696 146.538 6.03856 146.804 5.68469L145.343 4.58603Z" fill="url(#paint4_linear_1354_73720)" />
  <path d="M155.399 7.26929C155.525 7.88685 155.584 8.51357 155.595 9.1163L153.768 9.15028C153.758 8.64083 153.709 8.12729 153.608 7.63613L155.399 7.26929Z" fill="url(#paint5_linear_1354_73720)" />
  <path d="M144.194 7.2693C144.068 7.88686 144.009 8.51358 143.998 9.11631L145.825 9.15029C145.835 8.64084 145.884 8.1273 145.985 7.63615L144.194 7.2693Z" fill="url(#paint6_linear_1354_73720)" />
  <path d="M155.493 10.9185C155.53 10.6347 155.559 10.3377 155.577 10.0319L153.752 9.92554C153.736 10.1932 153.711 10.4526 153.678 10.6989C153.676 10.7152 153.674 10.7316 153.673 10.7479C153.655 10.9715 153.613 11.2279 153.548 11.5097L155.33 11.9183C155.407 11.5828 155.465 11.2447 155.493 10.9185Z" fill="url(#paint7_linear_1354_73720)" />
  <path d="M144.016 10.0319C144.034 10.3377 144.063 10.6347 144.1 10.9185C144.128 11.2447 144.186 11.5828 144.263 11.9183L146.045 11.5097C145.98 11.2279 145.938 10.9715 145.92 10.7479C145.919 10.7316 145.917 10.7152 145.915 10.6989C145.882 10.4526 145.856 10.1932 145.841 9.92555L144.016 10.0319Z" fill="url(#paint8_linear_1354_73720)" />
  <path d="M145.217 14.652C144.97 14.1024 144.724 13.4853 144.526 12.8661L146.267 12.3084C146.441 12.8508 146.659 13.4032 146.884 13.9021L145.217 14.652Z" fill="url(#paint9_linear_1354_73720)" />
  <path d="M154.376 14.652C154.623 14.1024 154.869 13.4853 155.067 12.8661L153.326 12.3084C153.152 12.8508 152.934 13.4032 152.709 13.9021L154.376 14.652Z" fill="url(#paint10_linear_1354_73720)" />
  <path d="M146.015 16.1927C145.894 15.9965 145.764 15.7656 145.634 15.5144L147.255 14.6709C147.414 14.977 147.553 15.2135 147.655 15.3641C147.726 15.4679 147.774 15.5852 147.797 15.7085C147.933 16.4364 147.758 17.0849 147.442 17.6106L145.875 16.6708C145.969 16.5129 146.017 16.3582 146.015 16.1927Z" fill="url(#paint11_linear_1354_73720)" />
  <path d="M153.959 15.5144C153.829 15.7656 153.699 15.9965 153.578 16.1927C153.576 16.3582 153.624 16.5129 153.718 16.6708L152.151 17.6106C151.835 17.0849 151.66 16.4364 151.796 15.7085C151.819 15.5852 151.867 15.4679 151.938 15.3641C152.04 15.2135 152.179 14.977 152.338 14.6709L153.959 15.5144Z" fill="url(#paint12_linear_1354_73720)" />
  <path d="M144.326 17.7458C144.529 17.6931 144.882 17.548 145.225 17.3145L146.254 18.8248C145.753 19.1665 145.213 19.4026 144.794 19.5127C144.508 19.5933 144.228 19.6804 143.956 19.7723L143.371 18.0408C143.674 17.9383 143.986 17.841 144.308 17.7507L144.326 17.7458Z" fill="url(#paint13_linear_1354_73720)" />
  <path d="M155.267 17.7458C155.064 17.6931 154.711 17.548 154.368 17.3145L153.339 18.8248C153.84 19.1665 154.38 19.4026 154.799 19.5127C155.085 19.5933 155.365 19.6804 155.637 19.7723L156.222 18.0408C155.919 17.9383 155.606 17.841 155.285 17.7507L155.267 17.7458Z" fill="url(#paint14_linear_1354_73720)" />
  <path d="M140.667 19.1918C141.192 18.9177 141.792 18.6386 142.451 18.378L143.124 20.0777C142.525 20.3142 141.983 20.5666 141.513 20.8119L140.667 19.1918Z" fill="url(#paint15_linear_1354_73720)" />
  <path d="M158.926 19.1918C158.401 18.9177 157.801 18.6386 157.142 18.378L156.469 20.0777C157.067 20.3142 157.61 20.5666 158.08 20.8119L158.926 19.1918Z" fill="url(#paint16_linear_1354_73720)" />
  <path d="M138.946 20.2584C139.179 20.0785 139.468 19.8818 139.802 19.6788L140.752 21.2401C140.462 21.4167 140.231 21.5754 140.064 21.7048C139.894 21.8357 139.741 21.9793 139.601 22.1329L138.25 20.9016C138.457 20.6744 138.688 20.4578 138.946 20.2584Z" fill="url(#paint17_linear_1354_73720)" />
  <path d="M160.647 20.2584C160.414 20.0785 160.125 19.8818 159.791 19.6788L158.841 21.2401C159.131 21.4167 159.362 21.5754 159.529 21.7048C159.698 21.8357 159.852 21.9793 159.992 22.1329L161.343 20.9016C161.136 20.6744 160.905 20.4578 160.647 20.2584Z" fill="url(#paint18_linear_1354_73720)" />
  <path d="M136.893 23.2649C137.072 22.7421 137.323 22.1791 137.679 21.6391L139.206 22.6437C138.952 23.029 138.763 23.4468 138.622 23.8575L136.893 23.2649Z" fill="url(#paint19_linear_1354_73720)" />
  <path d="M162.7 23.2649C162.521 22.7421 162.27 22.1791 161.914 21.6391L160.387 22.6437C160.641 23.029 160.83 23.4468 160.971 23.8575L162.7 23.2649Z" fill="url(#paint20_linear_1354_73720)" />
  <path d="M136.491 24.9628C136.525 24.7181 136.576 24.4287 136.65 24.1117L138.43 24.5289C138.369 24.7866 138.328 25.0233 138.3 25.2242L138.298 25.2364L138.117 26.4045L136.311 26.125L136.491 24.9628Z" fill="url(#paint21_linear_1354_73720)" />
  <path d="M162.943 24.1117C163.017 24.4287 163.068 24.7181 163.102 24.9628L163.282 26.125L161.476 26.4045L161.293 25.2242C161.265 25.0233 161.224 24.7866 161.163 24.5289L162.943 24.1117Z" fill="url(#paint22_linear_1354_73720)" />
  <path d="M135.949 28.4612L136.13 27.2931L137.936 27.5726C137.874 28.1055 137.939 28.1701 138.043 28.2735C138.051 28.2809 138.058 28.2886 138.066 28.2966L137.075 29.8324L136.357 29.3688C136.054 29.1732 135.894 28.8177 135.949 28.4612Z" fill="url(#paint23_linear_1354_73720)" />
  <path d="M163.644 28.4612L163.463 27.2931L161.657 27.5726C161.756 28.0435 161.704 28.1729 161.526 28.2961L162.515 29.8329L163.235 29.3694C163.539 29.1739 163.699 28.818 163.644 28.4612Z" fill="url(#paint24_linear_1354_73720)" />
  <path d="M161.795 30.2965L160.356 31.2235L159.366 29.6867L160.806 28.7596L161.795 30.2965Z" fill="url(#paint25_linear_1354_73720)" />
  <path d="M137.793 30.2959L139.23 31.223L140.221 29.6872L138.785 28.7601L137.793 30.2959Z" fill="url(#paint26_linear_1354_73720)" />
  <path d="M159.636 31.6871L158.196 32.6142L157.206 31.0774L158.646 30.1503L159.636 31.6871Z" fill="url(#paint27_linear_1354_73720)" />
  <path d="M139.948 31.6866L141.385 32.6137L142.376 31.0779L140.939 30.1508L139.948 31.6866Z" fill="url(#paint28_linear_1354_73720)" />
  <path d="M157.476 33.0777L156.036 34.0048L155.046 32.468L156.486 31.5409L157.476 33.0777Z" fill="url(#paint29_linear_1354_73720)" />
  <path d="M142.103 33.0772L143.54 34.0043L144.531 32.4685L143.094 31.5414L142.103 33.0772Z" fill="url(#paint30_linear_1354_73720)" />
  <path d="M155.316 34.4684L153.876 35.3955L152.887 33.8586L154.326 32.9316L155.316 34.4684Z" fill="url(#paint31_linear_1354_73720)" />
  <path d="M144.258 34.4679L145.695 35.395L146.686 33.8592L145.249 32.9321L144.258 34.4679Z" fill="url(#paint32_linear_1354_73720)" />
  <path d="M153.156 35.859L151.716 36.7861L150.727 35.2493L152.167 34.3222L153.156 35.859Z" fill="url(#paint33_linear_1354_73720)" />
  <path d="M146.413 35.8585L147.85 36.7856L148.841 35.2498L147.404 34.3227L146.413 35.8585Z" fill="url(#paint34_linear_1354_73720)" />
  <path d="M150.996 37.2497L150.276 37.7132C149.975 37.9074 149.588 37.9072 149.286 37.7127L148.568 37.2492L149.559 35.7134C149.734 35.7824 149.832 35.7815 150.007 35.7128L150.996 37.2497Z" fill="url(#paint35_linear_1354_73720)" />
  <path d="M178.147 66.9143C178.147 66.9143 178.153 66.9129 178.167 66.9073C178.18 66.9129 178.187 66.9143 178.187 66.9143C178.187 66.9143 189.782 59.5142 190.724 58.7914C190.724 58.7914 190.524 57.0256 190.291 55.6807C190.291 55.6807 190.047 53.3011 188.849 51.959L188.845 51.9744L188.846 51.959C188.846 51.959 187.785 50.6084 183.949 49.2593H183.955C180.617 48.5323 180.973 46.4575 180.973 46.4575C181.522 45.6256 182.247 43.6865 182.247 43.6865C183.27 40.9197 182.784 38.3342 182.784 38.3342C181.999 34.5145 178.62 34.3276 178.19 34.3193C178.19 34.3193 178.182 34.3191 178.167 34.3192C178.152 34.3191 178.144 34.3193 178.144 34.3193C177.714 34.3276 174.335 34.5145 173.549 38.3342C173.549 38.3342 173.063 40.9197 174.086 43.6865C174.086 43.6865 174.812 45.6256 175.361 46.4575C175.361 46.4575 175.716 48.5323 172.379 49.2593H172.385C168.548 50.6084 167.487 51.959 167.487 51.959L167.488 51.9744L167.484 51.959C166.286 53.3011 166.042 55.6807 166.042 55.6807C165.81 57.0256 165.61 58.7914 165.61 58.7914C166.552 59.5142 178.147 66.9143 178.147 66.9143Z" fill="url(#paint36_linear_1354_73720)" />
  <path d="M121.404 66.9143C121.404 66.9143 121.411 66.9129 121.425 66.9073C121.438 66.9129 121.445 66.9143 121.445 66.9143C121.445 66.9143 133.04 59.5142 133.982 58.7914C133.982 58.7914 133.782 57.0256 133.549 55.6807C133.549 55.6807 133.305 53.3011 132.107 51.959L132.103 51.9744L132.104 51.959C132.104 51.959 131.043 50.6084 127.207 49.2593H127.212C123.875 48.5323 124.231 46.4575 124.231 46.4575C124.78 45.6256 125.505 43.6865 125.505 43.6865C126.528 40.9197 126.042 38.3342 126.042 38.3342C125.257 34.5145 121.878 34.3276 121.447 34.3193C121.447 34.3193 121.439 34.3191 121.425 34.3192C121.41 34.3191 121.402 34.3193 121.402 34.3193C120.971 34.3276 117.593 34.5145 116.807 38.3342C116.807 38.3342 116.321 40.9197 117.344 43.6865C117.344 43.6865 118.069 45.6256 118.618 46.4575C118.618 46.4575 118.974 48.5323 115.637 49.2593H115.642C111.806 50.6084 110.745 51.959 110.745 51.959L110.746 51.9744L110.742 51.959C109.544 53.3011 109.3 55.6807 109.3 55.6807C109.067 57.0256 108.867 58.7914 108.867 58.7914C109.809 59.5142 121.404 66.9143 121.404 66.9143Z" fill="url(#paint37_linear_1354_73720)" />
  <path d="M149.776 95.1808L149.796 95.1724L149.816 95.1808C149.816 95.1808 161.411 87.7793 162.353 87.0565C162.353 87.0565 162.153 85.2907 161.92 83.9458C161.92 83.9458 161.676 81.5662 160.478 80.2241L160.474 80.2395L160.475 80.2241C160.475 80.2241 159.414 78.8735 155.578 77.5258L155.584 77.5244C152.246 76.7974 152.602 74.7226 152.602 74.7226C153.151 73.8908 153.876 71.9516 153.876 71.9516C154.899 69.1848 154.413 66.5997 154.413 66.5997C153.628 62.7802 150.249 62.5928 149.818 62.5844H149.773C149.342 62.5928 145.964 62.7802 145.178 66.5997C145.178 66.5997 144.692 69.1848 145.715 71.9516C145.715 71.9516 146.441 73.8908 146.989 74.7226C146.989 74.7226 147.345 76.7974 144.008 77.5244L144.013 77.5258C140.177 78.8735 139.116 80.2241 139.116 80.2241L139.117 80.2395L139.113 80.2241C137.915 81.5662 137.671 83.9458 137.671 83.9458C137.438 85.2907 137.238 87.0565 137.238 87.0565C138.181 87.7793 149.776 95.1808 149.776 95.1808Z" fill="url(#paint38_linear_1354_73720)" />
  <path d="M123.375 26.1088L145.714 50.9552L123.375 75.8019L125.135 77.5733L149.796 55.0669L174.457 77.5733L176.216 75.8019L153.877 50.9552L176.216 26.1088L174.457 24.3364L149.796 46.8434L125.135 24.3364L123.375 26.1088Z" fill="url(#paint39_linear_1354_73720)" />
  <path opacity="0.2" d="M149.793 36.4445C149.793 36.4445 149.8 36.4431 149.813 36.4375C149.827 36.4431 149.833 36.4445 149.833 36.4445C149.833 36.4445 161.428 29.0444 162.37 28.3216C162.37 28.3216 162.17 26.5558 161.938 25.2109C161.938 25.2109 161.694 22.8313 160.496 21.4892L160.492 21.5046L160.493 21.4892C160.493 21.4892 159.432 20.1386 155.595 18.7895H155.601C152.264 18.0625 152.619 15.9877 152.619 15.9877C153.168 15.1559 153.894 13.2167 153.894 13.2167C154.917 10.4499 154.431 7.86443 154.431 7.86443C153.645 4.04472 150.266 3.8578 149.836 3.84955C149.836 3.84955 149.828 3.84926 149.813 3.8494C149.798 3.84926 149.791 3.84955 149.791 3.84955C149.36 3.8578 145.981 4.04472 145.196 7.86443C145.196 7.86443 144.71 10.4499 145.733 13.2167C145.733 13.2167 146.458 15.1559 147.007 15.9877C147.007 15.9877 147.363 18.0625 144.025 18.7895H144.031C140.194 20.1386 139.134 21.4892 139.134 21.4892L139.135 21.5046L139.131 21.4892C137.933 22.8313 137.689 25.2109 137.689 25.2109C137.456 26.5558 137.256 28.3216 137.256 28.3216C138.198 29.0444 149.793 36.4445 149.793 36.4445Z" fill="url(#paint40_linear_1354_73720)" />
  <path fillRule="evenodd" clipRule="evenodd" d="M23.5039 82.306C23.48 82.3158 23.4678 82.3206 23.4678 82.3206C23.4678 82.3206 2.66867 68.9795 0.978516 67.6747C0.978516 67.6747 1.33746 64.4935 1.75489 62.0684C1.75489 62.0684 2.19299 57.7772 4.34165 55.3581L4.34912 55.3866L4.34713 55.3596C4.34713 55.3596 6.24986 52.9252 13.1317 50.493L13.1215 50.4925C19.1083 49.1807 18.4698 45.4404 18.4698 45.4404C17.4855 43.9411 16.1842 40.4461 16.1842 40.4461C14.3492 35.4567 15.2209 30.7968 15.2209 30.7968C16.63 23.9109 22.6907 23.5739 23.4633 23.559C23.4633 23.559 23.4773 23.5585 23.5039 23.5588C23.5305 23.5585 23.5445 23.559 23.5445 23.559C24.3171 23.5739 30.3778 23.9109 31.7869 30.7968C31.7869 30.7968 32.6589 35.4567 30.8236 40.4461C30.8236 40.4461 29.5223 43.9411 28.538 45.4404C28.538 45.4404 27.8998 49.1807 33.8863 50.4925L33.8763 50.493C40.7579 52.9252 42.6607 55.3596 42.6607 55.3596L42.6587 55.3866L42.6662 55.3581C44.8148 57.7772 45.2532 62.0684 45.2532 62.0684C45.6703 64.4935 46.0293 67.6747 46.0293 67.6747C44.3391 68.9795 23.54 82.3206 23.54 82.3206C23.54 82.3206 23.5278 82.3158 23.5039 82.306Z" fill="url(#paint41_linear_1354_73720)" />
  <path fillRule="evenodd" clipRule="evenodd" d="M89.4633 20.1635C79.8012 19.8303 63.3128 21.2217 49.6453 33.7485C44.6183 38.3558 41.7549 42.8989 40.7292 44.9019C40.7548 44.8226 44.2673 32.9616 56.9761 22.9425C68.3351 13.988 81.238 11.0597 89.7978 9.99659L90.1048 0.666468L115.576 16.0064L89.1588 29.415L89.4633 20.1635Z" fill="url(#paint42_linear_1354_73720)" />
  <defs>
    <linearGradient id="paint0_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint1_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint2_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint3_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint4_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint5_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint6_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint7_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint8_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint9_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint10_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint11_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint12_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint13_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint14_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint15_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint16_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint17_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint18_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint19_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint20_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint21_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint22_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint23_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint24_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint25_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint26_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint27_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint28_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint29_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint30_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint31_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint32_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint33_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint34_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint35_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint36_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint37_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint38_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint39_linear_1354_73720" x1="149.796" y1="2.49963" x2="149.796" y2="95.1808" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint40_linear_1354_73720" x1="121.442" y1="-27.9702" x2="121.442" y2="64.711" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#694E2F" />
    </linearGradient>
    <linearGradient id="paint41_linear_1354_73720" x1="23.5039" y1="48.5051" x2="23.5039" y2="82.3206" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AA825C" />
      <stop offset="1" stopColor="#291917" />
    </linearGradient>
    <linearGradient id="paint42_linear_1354_73720" x1="89.027" y1="15.4965" x2="46.7816" y2="38.6641" gradientUnits="userSpaceOnUse">
      <stop stopColor="#AC835D" />
      <stop offset="1" stopColor="#836242" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>;

const ICON_STAMINA_2 = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 27" fill="none">
  <path d="M6.23935 8.83856L6.23388 8.84978L6.22897 8.86125C4.31389 13.3394 3.37237 18.7413 3.13867 20.8915L3.12299 21.0358L3.187 21.1661L3.91966 22.657L4.01772 22.8565L4.23159 22.9174L7.77304 23.9248L7.773 23.9249L7.78436 23.9279C10.5871 24.6545 13.715 24.6703 14.9434 24.5876C17.6526 24.4216 20.8383 23.0845 22.1378 22.4203C22.6228 22.2824 23.6862 21.9707 24.1707 21.8281L24.6867 21.6762L24.4976 21.1726L22.1366 14.8863L22.0884 14.7578L21.9812 14.6719C21.2451 14.082 20.1035 13.5213 19.6308 13.3148C18.5991 12.8592 17.4479 12.8509 16.4621 13.0013C15.4725 13.1522 14.597 13.471 14.0789 13.7275C13.0543 14.2346 12.2124 14.8651 11.6031 15.4339L11.1859 11.9295L11.3005 11.668L14.4546 11.422L14.7253 11.4009L14.855 11.1624C15.0842 10.741 16.0211 8.95942 16.4352 8.06348L16.4957 7.93254L16.4772 7.78949C16.366 6.93102 16.1966 5.85084 16.0675 5.07203L16.0371 4.88862L15.8943 4.76963C15.4987 4.44012 14.7592 4.01068 13.9911 3.59896C13.2079 3.17914 12.3468 2.75276 11.6749 2.428L11.413 2.30141L11.1735 2.46652C9.99097 3.2818 8.89756 4.33835 8.37722 4.84114C8.25263 4.96153 8.1609 5.05017 8.10866 5.09615L8.03357 5.16225L7.98969 5.25216L6.23935 8.83856Z" fill="url(#paint0_linear_2257_70576)" stroke="#553D3A" />
  <defs>
    <linearGradient id="paint0_linear_2257_70576" x1="13.8326" y1="2.87817" x2="13.8326" y2="24.1217" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C1A089" />
      <stop offset="1" stopColor="#9D7152" />
    </linearGradient>
  </defs>
</svg>;

const ICON_MOOD_2 = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 27" fill="none">
  <path d="M23.1459 2.18938L23.1543 1.57266L22.5492 1.69208L9.24773 4.31711L8.83289 4.39898L8.84473 4.82165L9.15025 15.7281C8.01087 15.516 6.73007 15.7226 5.57195 16.3706C3.29237 17.6461 2.1539 20.3049 3.30335 22.374C4.45375 24.4447 7.30882 24.8702 9.58869 23.5945C11.3666 22.5996 12.4273 20.7976 12.2963 19.0661V9.67636L19.8123 8.14874V14.0615C18.6683 13.8437 17.3799 14.0489 16.2154 14.7005C13.9359 15.976 12.7974 18.6348 13.9469 20.7038C15.0973 22.7746 17.9523 23.2001 20.2322 21.9244C22.009 20.9302 23.0694 19.1299 22.9401 17.3995L23.1459 2.18938Z" fill="url(#paint0_linear_2257_70580)" stroke="#553D3A" />
  <defs>
    <linearGradient id="paint0_linear_2257_70580" x1="12.9997" y1="2.18262" x2="12.9997" y2="23.8171" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C1A089" />
      <stop offset="1" stopColor="#9D7152" />
    </linearGradient>
  </defs>
</svg>;

const ICON_RELATION_2 = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 27 27" fill="none">
  <path d="M6.45829 10.5751L6.45829 10.5751C6.44362 10.6143 6.41793 10.648 6.38467 10.6723L6.67906 11.0764L6.38466 10.6723L3.3315 12.8964C2.4235 13.5578 1.88672 14.6152 1.88672 15.7399V21.0278C1.88672 22.967 3.45358 24.542 5.39017 24.542H21.2747C23.2113 24.542 24.7782 22.967 24.7782 21.0278V15.7399C24.7782 14.6152 24.2414 13.5578 23.3334 12.8964L20.2802 10.6723L19.9858 11.0764L20.2802 10.6723C20.247 10.648 20.2213 10.6143 20.2066 10.5751L19.7383 10.7503L20.2066 10.5751C20.171 10.4801 20.2084 10.3048 20.3909 10.1956C21.7367 9.39027 22.6311 7.86235 22.6311 6.12622C22.6311 3.58037 20.6941 1.45801 18.235 1.45801L18.2334 1.45801L18.2132 1.45808H18.21L18.1898 1.45801H18.1882C15.729 1.45801 13.7921 3.58037 13.7921 6.12622C13.7921 7.86235 14.6865 9.39027 16.0322 10.1956C16.2147 10.3048 16.2521 10.4801 16.2166 10.5751L16.2166 10.5751C16.2019 10.6143 16.1762 10.648 16.1429 10.6723L16.4373 11.0764L16.1429 10.6723L13.3324 12.7196L10.5219 10.6723L10.2275 11.0764L10.5219 10.6723C10.4887 10.648 10.463 10.6143 10.4483 10.5751L10.4483 10.5751C10.4128 10.4801 10.4502 10.3048 10.6327 10.1956C11.9784 9.39027 12.8728 7.86235 12.8728 6.12622C12.8728 3.58037 10.9359 1.45801 8.4767 1.45801L8.47512 1.45801L8.45331 1.45808L8.43149 1.45801H8.42991C5.97075 1.45801 4.03379 3.58037 4.03379 6.12622C4.03379 7.86235 4.9282 9.39027 6.27394 10.1956C6.45644 10.3048 6.49385 10.4801 6.45829 10.5751Z" fill="url(#paint0_linear_2257_70584)" stroke="#553D3A" />
  <defs>
    <linearGradient id="paint0_linear_2257_70584" x1="13.3324" y1="1.95801" x2="13.3324" y2="24.042" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C1A089" />
      <stop offset="1" stopColor="#9D7152" />
    </linearGradient>
  </defs>
</svg>;

const STAR_SMALL = "22"
const STAR_BIG = "30"
const STAR_SQUAD2 = "25"

function Star(props) {
  const { filled, size } = props;
  if (filled == 0) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill="none">
      <path d="M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z" fill="#6A522D" />
    </svg>;

  } else if (filled == 1) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill="none">
      <path d="M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z" fill="#F2E4B5" />
    </svg>;
  } else {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill="none">
      <path d="M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z" fill="#6A522D" />
      <path d="M15 0V20.7295L6.1832 27.1353L9.55091 16.7705L0.734131 10.3647H11.6323L15 0Z" fill="#F2E4B5" />
    </svg>;
  }
}

function Toggle(onClickToggleWarning, on) {
  const style = on ? { right: 0 } : { left: 0 }
  return <div className='toggle-background' onClick={() => {
    onClickToggleWarning();
    triggerSound('UI_Outgame_Button_Click_Default');
  }}>
    <div className='toggle-button' style={style}>
      <span className='toggle-label'>{on ? "ON" : "OFF"}</span>
    </div>
  </div>
}

const ICON_WARNING_LOW_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none" className='icon-warning-low-background'>
  <path d="M9.95929 1C10.3442 0.333333 11.3064 0.333333 11.6913 1L20.7846 16.75C21.1695 17.4167 20.6884 18.25 19.9186 18.25H1.73205C0.96225 18.25 0.481125 17.4167 0.866025 16.75L9.95929 1Z" fill="#FFDB20" />
</svg>

const ICON_WARNING_LOW = <div className='icon-warning-low'>
  {ICON_WARNING_LOW_BACKGROUND}
  <span className='icon-warning-low-text'>!</span>
</div>

const ICON_WARNING_HIGH_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className='icon-warning-high-background'>
  <path d="M9.08579 0.414214L11.9142 0.414214L21.1066 9.6066V12.435L11.9143 21.6273H9.0857L-0.106518 12.4351V9.60652L9.08579 0.414214Z" fill="#E03333" />
</svg>

const ICON_WARNING_HIGH = <div className='icon-warning-high'>
  {ICON_WARNING_HIGH_BACKGROUND}
  <span className='icon-warning-high-text'>!</span>
</div>

export class FigmaSquadRoot extends React.Component {
  constructor(props) {
    super(props);
    const rng = new Rng(Rng.randomseed());
    const agents = new Array(4).fill(0).map(() => {
      const role = rng.weighted_key(roles, "weight");
      const name = rng.choice(names4);
      return createCharacter2({ rng, name, role });
    });

    this.tooltipRef = React.createRef();

    const squad = { agents };
    this.state = {
      squad,
      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
    };
  }

  onChangeAgentOrder(squadIdx, prevIndex, newIndex) {
    const { squad } = this.state;
    const { agents } = squad;

    [agents[prevIndex], agents[newIndex]] = [agents[newIndex], agents[prevIndex]];
    this.setState({ squad });
  }

  render() {
    return <TooltipContext.Provider value={{
      ctx: this.state.tooltipContext,
      setCtx: (ctx) => {
        this.setState({ tooltipContext: ctx });
      }
    }}>
      <div className='figmasquad-testroot' onMouseMove={(e) => {
        if (!e._tooltipmove) {
          this.setState({ tooltipContext: { ...this.state.tooltipContext, content: null } });
        }
      }}>
        <FigmaSquadView2
          squad={this.state.squad}
          onChangeAgentOrder={this.onChangeAgentOrder.bind(this)}
          makeNote={(ch) => {
            let note = null;
            const visible_modifiers = ch.modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);
            if (visible_modifiers.length > 0) {
              note = visible_modifiers.map((mod, i) => <>
                <AgentModifierIcon key={i} modifier={mod} turn={game.turn} />
              </>);
            };
            return note;
          }}
        />
      </div>
    </TooltipContext.Provider>;
  }
}

export class FigmaSquadView extends React.Component {
  constructor(props) {
    super(props);
    const { squad } = props;
    const warningToggle = true;
    this.state = {
      squad,
      warningToggle,
    };
  }

  onClickToggleWarning() {
    const { warningToggle } = this.state;
    this.setState({ warningToggle: !warningToggle })
  }

  render() {
    const { squad, warningToggle } = this.state;
    const { onChangeAgentOrder, onUnassign, onAgentDetail } = this.props;
    const { inventories } = this.props;
    const { onAgentEquipAvail, onAgentEquipPopup, onExchangeAgent, onClickButtonClose, onAssignAgent } = this.props;

    let closebtn = null;
    if (onClickButtonClose) {
      closebtn = <SoundButton className="figmasquad-header-btn-close" onClick={onClickButtonClose}>
        {ICON_CLOSE}
      </SoundButton>;
    }

    return <div className="figmasquad-root">
      <div className="figmasquad-header">
        <div className='figmasquad-header-inner'>
          <div className='figmasquad-header-title'>{L('loc_ui_string_menu_squad')}</div>
          <div className='figmasquad-header-sep' />
          <div className='figmasquad-header-subtitle'>
            <span style={{ marginRight: "10px" }}>{L('loc_ui_string_squad_current_squad_size')}</span>
            <span><span className='hl'>{squad.agents.length}</span> / 4</span>
          </div>
          <div className='figmasquad-header-sep' />
          <div className='figmasquad-header-warninglowoption'>
            <div className='figmasquad-header-warninglowoption-title'>
              {ICON_WARNING_LOW}
              <span className='figmasquad-header-warninglowoption-title-label'>{L('loc_ui_string_squad_toggle_alerts')}</span>
            </div>
            {Toggle(this.onClickToggleWarning.bind(this), warningToggle)}
          </div>
          {closebtn}
        </div>
      </div>
      <div className='figmasquad-body'>
        <Squad squad={squad} onChangeAgentOrder={onChangeAgentOrder}
          onAgentDetail={onAgentDetail}
          onUnassign={onUnassign}
          onAgentEquipAvail={onAgentEquipAvail}
          onExchangeAgent={onExchangeAgent}
          onAssignAgent={onAssignAgent}
          onAgentEquipPopup={onAgentEquipPopup} />
        <PowerEval warningToggle={warningToggle} squad={squad} inventories={inventories} />
      </div>
    </div>;
  }
}

function SquadColumn(props) {
  const { agent, order: i, length } = props;
  const { name, role } = agent;
  const { onChangeAgentOrder, onAgentDetail, onUnassign } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onExchangeAgent } = props;

  let expMax;
  if (agent.level.cur <= data_operatorExp.length) {
    expMax = data_operatorExp[agent.level.cur - 1].expMax;
  }
  else {
    expMax = '∞';
  }

  return <div className='figmasquad-body-squad-slotframe'>
    <div className='figmasquad-body-squad-slot'>
      <div className='figmasquad-body-squad-slot-buttons'>
        <div className='figmasquad-body-squad-slot-badge'>
          <span className='figmasquad-body-squad-slot-badge-number'>{i + 1}</span>
          {ICON_BADGE}
        </div>
        <div className='figmasquad-body-squad-slot-buttons-sep' />
        <div className={'figmasquad-body-squad-slot-button button-detail'} onClick={() => {
          onAgentDetail(agent);
          triggerSound('UI_Outgame_Button_Click_Default');
        }}>
          {ICON_DETAIL}
          <span className='figmasquad-body-squad-slot-button-label'>{L('loc_ui_stiring_squad_agent_detail')}</span>
        </div>
        {onUnassign ? <>
          <div className='figmasquad-body-squad-slot-buttons-sep' />
          <div className={'figmasquad-body-squad-slot-button button-exclude'} onClick={() => {
            onUnassign(agent);
            triggerSound('UI_Outgame_Button_Click_Default');
          }}>
            {ICON_EXCLUDE}
            <span className='figmasquad-body-squad-slot-button-label'>{L('loc_ui_stiring_squad_agent_remove')}</span>
          </div></> : null}
        {onExchangeAgent ? <>
          <div className='figmasquad-body-squad-slot-buttons-sep' />
          <div className={'figmasquad-body-squad-slot-button button-exchange'} onClick={() => {
            onExchangeAgent(i);
            triggerSound('UI_Outgame_Button_Click_Default');
          }}>
            {ICON_EXCHANGE}
            <span className='figmasquad-body-squad-slot-button-label'>{L('loc_ui_stiring_squad_agent_change')}</span>
          </div></> : null}
        <div className='figmasquad-body-squad-slot-buttons-sep' />
        {
          (i != 0) ?
            <>
              <div className={'figmasquad-body-squad-slot-button button-prev'} onClick={() => {
                onChangeAgentOrder(i, i - 1);
                triggerSound('UI_Outgame_Button_Click_Default');
              }}>
                {ICON_PREV}
                <span className='figmasquad-body-squad-slot-button-label'>{L('loc_ui_stiring_squad_agent_move', { value: i })}</span>
              </div>
              <div className='figmasquad-body-squad-slot-buttons-sep' />
            </> : null
        }
        {
          (i != length - 1) ?
            <><div className={'figmasquad-body-squad-slot-button button-next'} onClick={() => {
              onChangeAgentOrder(i, i + 1);
              triggerSound('UI_Outgame_Button_Click_Default');
            }}>
              {ICON_NEXT}
              <span className='figmasquad-body-squad-slot-button-label'>{L('loc_ui_stiring_squad_agent_move', { value: i + 2 })}</span>
            </div>
              <div className='figmasquad-body-squad-slot-buttons-sep' />
            </> : null
        }
      </div>
      <div className='figmasquad-body-squad-slot-inform'>
        <div className='figmasquad-body-squad-slot-inform-profile'>
          <div className='figmasquad-body-squad-slot-inform-profile-header'>
            <RoleBadge role={role} isLabel={true} />
            <div className='figmasquad-body-squad-slot-inform-profile-name'>
              <span className='figmasquad-body-squad-slot-inform-profile-name-label'>{L(agent.name)}</span>
              <div className='figmasquad-body-squad-slot-inform-profile-callsign'>
                <span className='figmasquad-body-squad-slot-inform-profile-callsign-label'>{agent.callsign}</span>
              </div>
            </div>
          </div>
          <div className='figmasquad-body-squad-slot-inform-profile-basic'>
            <div className='figmasquad-body-squad-slot-inform-profile-portrait'>
              <PortraitWrapper agent={{ name, role }} className="portrait-frame-small1-figma" />
            </div>
            <div className='figmasquad-body-squad-slot-inform-profile-states'>
              <div className='figmasquad-body-squad-slot-inform-profile-state'>
                <div className='figmasquad-body-squad-slot-inform-profile-state-text'>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-title'>
                    <span className='figmasquad-body-squad-slot-inform-profile-state-label'>{L('loc_ui_string_agent_level')}</span>
                  </div>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-value'>
                    <span className='figmasquad-body-squad-slot-inform-profile-state-value-label'>{agent.level.cur} ({agent.level.exp}/{expMax})</span>
                  </div>
                </div>
                <div className='figmasquad-body-squad-slot-inform-profile-state-sep' />
              </div>
              <div className='figmasquad-body-squad-slot-inform-profile-state'>
                <div className='figmasquad-body-squad-slot-inform-profile-state-text'>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-title'>
                    {ICON_POWER}
                    <span className='figmasquad-body-squad-slot-inform-profile-state-label'>{L('loc_ui_string_agent_power')}</span>
                  </div>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-value'>
                    <span className='figmasquad-body-squad-slot-inform-profile-state-value-label'>{agent.power.toFixed(1)}</span>
                    <span className='figmasquad-body-squad-slot-inform-profile-state-valuemax-label'>/{agent.potentialPower.toFixed(1)}</span>
                  </div>
                </div>
                <div className='figmasquad-body-squad-slot-inform-profile-state-sep' />
              </div>
              <div className='figmasquad-body-squad-slot-inform-profile-state'>
                <div className='figmasquad-body-squad-slot-inform-profile-state-text'>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-title'>
                    {ICON_LIFE}
                    <span className='figmasquad-body-squad-slot-inform-profile-state-label'>{L('loc_ui_string_agent_life')}</span>
                  </div>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-value'>
                    <span className='figmasquad-body-squad-slot-inform-profile-state-value-label'>{agent.secondaryStats.life.toFixed(0)}</span>
                  </div>
                </div>
                <div className='figmasquad-body-squad-slot-inform-profile-state-sep' />
              </div>
              <div className='figmasquad-body-squad-slot-inform-profile-state'>
                <div className='figmasquad-body-squad-slot-inform-profile-state-text'>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-title'>
                    {ICON_CONDITION}
                    <span className='figmasquad-body-squad-slot-inform-profile-state-label'>{L('loc_ui_string_agent_stamina')}</span>
                  </div>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-value'>
                    <span className={"figmasquad-body-squad-slot-inform-profile-state-status-label" + evalCSSClass("condition", agent.condition)}>
                      {L(getConditionData(agent.condition).name)}
                    </span>
                    <span className={"figmasquad-body-squad-slot-inform-profile-state-status-label" + evalCSSClass("condition", agent.condition)}>
                      {agent.condition.toFixed(1)}
                    </span>
                  </div>
                </div>
                <div className='figmasquad-body-squad-slot-inform-profile-state-sep' />
              </div>
              <div className='figmasquad-body-squad-slot-inform-profile-state'>
                <div className='figmasquad-body-squad-slot-inform-profile-state-text'>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-title'>
                    {ICON_MOOD}
                    <span className='figmasquad-body-squad-slot-inform-profile-state-label'>{L('loc_ui_string_agent_mood')}</span>
                  </div>
                  <div className='figmasquad-body-squad-slot-inform-profile-state-value'>
                    <span className={"figmasquad-body-squad-slot-inform-profile-state-status-label" + evalCSSClass("mood", agent.mood)}>
                      {L(getMoodData(agent.mood).name)}
                    </span>
                    <span className={"figmasquad-body-squad-slot-inform-profile-state-status-label" + evalCSSClass("mood", agent.mood)}>
                      {agent.mood.toFixed(1)}
                    </span>
                  </div>
                </div>
                <div className='figmasquad-body-squad-slot-inform-profile-state-sep' />
              </div>
            </div>
          </div>
          <div className='figmasquad-body-squad-slot-inform-profile-modifier'>
            <div className='figmasquad-body-squad-slot-inform-profile-modifier-header'>
              <span className='figmasquad-body-squad-slot-inform-profile-modifier-header-label'>{L('loc_ui_stiring_squad_agent_notes')}</span>
            </div>
            <div className='figmasquad-body-squad-slot-inform-profile-modifier-body'>
              {
                agent.modifier.filter(({ key }) => agentModifiers.find((m) => m.key === key).visible).map(({ key }, i) => {
                  const data = agentModifiers.find((d) => d.key === key);
                  return <TooltipContext.Consumer>
                    {(context) => {
                      const modifierTag = data.ty === 'identity' ? 'identity' : data.category;
                      return <div key={i} className='figmasquad-body-squad-slot-inform-profile-modifier-contents'>
                        <span className={`figmasquad-body-squad-slot-inform-profile-modifier-contents-label grind-agent-modifier grind-agent-modifier-${modifierTag}`}
                          {...figmaTooltipEvents(context, L(data.desc))}
                        >{L(data.name)}</span>
                        {
                          (i < agent.modifier.length - 1) ?
                            <span className='figmasquad-body-squad-slot-inform-profile-modifier-contents-label'>,</span>
                            : null
                        }
                      </div>;
                    }}
                  </TooltipContext.Consumer>;
                })
              }
            </div>
          </div>
        </div>
        <div className='figmasquad-body-squad-slot-inform-stat'>
          <Radar data={characterChartData(agent)} options={CHART_OPT_DETAIL} width={"356px"} height={"210px"} />
        </div>
        <SquadGear agent={agent}
          onAgentEquipAvail={onAgentEquipAvail}
          onAgentEquipPopup={onAgentEquipPopup} />
      </div>
    </div>
  </div>
}

function SquadGear(props) {
  const { agent, onAgentEquipAvail, onAgentEquipPopup } = props;

  function Gearbutton(ty, i) {
    const type = ty;
    let rate = -1;
    let firearm_ty = null;
    let name = '';
    switch (type) {
      case 'firearm':
        const firearm = agent.firearm.key === DEFAULT_FIREARM.key ? firearm_none : agent.firearm;
        rate = firearm.firearm_rate;
        firearm_ty = firearm.firearm_ty;
        name = firearm.firearm_name;
        break;
      case 'equipment':
        rate = agent.equipment.vest_rate;
        name = agent.equipment.vest_name;
        break;
      case 'throwable':
        rate = agent.throwables[0].throwable_rate;
        name = agent.throwables[0].throwable_name;
        break;
    }
    return <div key={ty + i} className='figmasquad-body-squad-slot-inform-gear' data-clickable onClick={() => {
      onAgentEquipPopup(agent, ty, 0);
      triggerSound('UI_Outgame_Button_Click_Default');
    }} >
      <div className='figmasquad-body-squad-slot-inform-gear-title'>
        <GearNameLine name={name} type={type} rate={rate} firearm_ty={firearm_ty} />
      </div>
      {ICON_GEAR_EXCHANGE}
    </div>
  }

  const gears = [];

  gears.push(Gearbutton("firearm", gears.length));
  gears.push(Gearbutton("equipment", gears.length));
  if (agent.role == 'pointman') {
    gears.push(Gearbutton("throwable", gears.length));
  } else {
    gears.push(<div key="empty" className='figmasquad-body-squad-slot-inform-gear-empty' />);
  }

  return <div className='figmasquad-body-squad-slot-inform-gears'>
    {gears}
  </div>
}

function Squad(props) {
  const { squad } = props;
  const { onChangeAgentOrder, onUnassign, onAgentDetail } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onExchangeAgent, onAssignAgent } = props;

  /*
  <div className='figmasquad-body-squad-preset'>
    <div className='figmasquad-body-squad-preset-label'>프리셋</div>
    <div className='figmasquad-body-squad-preset-group'>
      {
        Array.from(Array(20), (x, i) => {
          return <div key={i} className='figmasquad-body-squad-preset-button'>
            <span className='figmasquad-body-squad-preset-button-label'>{i + 1}</span>
          </div>
        })
      }
    </div>
  </div>
  */

  return <div className='figmasquad-body-squad'>
    <div className='figmasquad-body-squad-group'>
      {squad.agents.map((agent, i) => {
        return <SquadColumn key={i} agent={agent} order={i} length={squad.agents.length}
          onChangeAgentOrder={onChangeAgentOrder}
          onUnassign={onUnassign}
          onAgentDetail={onAgentDetail}
          onExchangeAgent={onExchangeAgent}
          onAgentEquipAvail={onAgentEquipAvail}
          onAgentEquipPopup={onAgentEquipPopup}

        />
      })}
      {squad.agents.length < 4 ?
        < div className='figmasquad-body-squad-empty-slot' onClick={() => onAssignAgent()}>
          <div className='figmasquad-body-squad-empty-slot-title' >
            {ICON_ADD}
            <span className='figmasquad-body-squad-empty-slot-title-label'>{L('loc_ui_longtext_squad_slot_empty')}</span>
          </div>
        </div>
        : null
      }
    </div>
  </div >

}

function PowerEval(props) {
  const { squad, inventories, warningToggle } = props;
  const agents = squad.agents;
  const firearms = agents.map((agent) => agent.firearm);

  let adviceList = MakeAdvice(agents.map((a) => {
    return {
      ...a,
      throwable: a.throwables[0],
    };
  })
    , inventories)

  if (!warningToggle) {
    adviceList = adviceList.filter((e) => e.warningColor != 'yellow')
  }

  return <div className='figmasquad-body-footer'>
    <div className='figmasquad-body-powereval'>
      <div className='figmasquad-body-powereval-header'>
        <div className='figmasquad-body-powereval-header-title'>
          <span className='figmasquad-body-powereval-header-title-label'>{L('loc_ui_string_squad_info_firepower_evaluation')}</span>
        </div>
      </div>
      <div className='figmasquad-body-powereval-body'>
        <PowerEvalRate agents={agents} firearms={firearms} />
        <PowerEvalEffDis firearms={firearms} />
      </div>
    </div>
    <div className='figmasquad-body-advice'>
      {adviceList.map((e, i) => <AdviceLine key={i} advice={e} />)}
    </div>
  </div>
}

export function AdviceLine(props) {
  const { advice } = props;
  let warningLevel;
  let warningIcon;
  switch (advice.warningColor) {
    case 'yellow':
      warningIcon = ICON_WARNING_LOW;
      warningLevel = 'low';
      break;
    case 'red':
      warningIcon = ICON_WARNING_HIGH;
      warningLevel = 'high';
      break;
  }
  return <div className='figmasquad-body-advice-line'>
    {warningIcon}
    <span className={`figmasquad-body-advice-line-label-${warningLevel}`}>{advice.advice}</span>
  </div>
}

function PowerEvalRate(props) {
  const { agents, firearms } = props
  const powerRate = calcSquadPower(agents, firearms);
  const antiPersonalPower = calcAntiPersonalPower(agents);
  const antiBulletproofPower = calcAntiBulletproofpower(agents);

  function PowerToStar(power, size) {
    const stars = []

    for (let i = 1; i <= power / 2; i++) {
      stars.push(<Star filled={1} size={size} key={'full' + i} />);
    }
    if (power % 2 == 1) {
      stars.push(<Star filled={0.5} size={size} key={'half' + stars.length} />);
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(<Star filled={0} size={size} key={'empty' + i} />);
    }

    return stars;
  }

  return <div className='figmasquad2-powereval-item'>
    <div className='figmasquad2-powereval-item-header'>
      <span className='figmasquad2-powereval-item-header-title'>
        {L('loc_ui_string_squad_info_firepower_evaluation')}
      </span>
    </div>
    <div className='figmasquad2-powereval-item-body-rate'>
      <div className='figmasquad2-powereval-item-body-rate-subitem'>
        <div className='figmasquad2-powereval-item-body-rate-subitem-title'>
          <div className='figmasquad2-powereval-item-body-rate-subitem-title-bullet' />
          <span className='figmasquad2-powereval-item-body-rate-subitem-title-label'>{L('loc_ui_string_squad_info_firepower')}</span>
        </div>
        <div className='figmasquad2-powereval-item-body-rate-subitem-value'>
          {PowerToStar(powerRate, STAR_SQUAD2)}
        </div>
      </div>
      <div className='figmasquad2-powereval-item-body-rate-subitem'>
        <div className='figmasquad2-powereval-item-body-rate-subitem-title'>
          <div className='figmasquad2-powereval-item-body-rate-subitem-title-bullet' />
          <span className='figmasquad2-powereval-item-body-rate-subitem-title-label'>{L('loc_ui_string_squad_info_firepower_antipersonnel')}</span>
        </div>
        <div className='figmasquad2-powereval-item-body-rate-subitem-value'>
          {PowerToStar(antiPersonalPower, STAR_SQUAD2)}
        </div>
      </div>
      <div className='figmasquad2-powereval-item-body-rate-subitem'>
        <div className='figmasquad2-powereval-item-body-rate-subitem-title'>
          <div className='figmasquad2-powereval-item-body-rate-subitem-title-bullet' />
          <span className='figmasquad2-powereval-item-body-rate-subitem-title-label'>{L('loc_ui_string_squad_info_firepower_antibulletproof')}</span>
        </div>
        <div className='figmasquad2-powereval-item-body-rate-subitem-value'>
          {PowerToStar(antiBulletproofPower, STAR_SQUAD2)}
        </div>
      </div>
    </div>
  </div>;
}

function PowerEvalEffDis(props) {
  const { firearms } = props;
  function CheckIncludeFirearm(props) {
    const { firearm_ty } = props;
    if (firearms.filter((e) => e.firearm_ty == firearm_ty).length > 0) {
      return ICON_CHECKED_BOX;
    } else {
      return ICON_UNCHECKED_BOX;
    }
  }

  const effectiveDistanceData = calcEffectiveDistance(firearms);
  const chartBar = [];

  for (let i = 0; i < effectiveDistanceData.length; i++) {
    const bar = <div style={{
      background: `rgba(247, 175, 35,${effectiveDistanceData[i]})`,
      position: 'absolute',
      height: '100%',
      width: `${100 / 80}%`,
      left: `${100 / 80 * i}%`,
    }} key={i} />
    chartBar.push(bar);
  }

  return <div className='figmasquad2-powereval-item'>
    <div className='figmasquad2-powereval-item-header-dis'>
      <div className='figmasquad2-powereval-item-header-title'>
        <span className='figmasquad-body-powereval-body-effdis-header-title-label'>
          {L('loc_ui_string_squad_info_range_effective')}
        </span>
      </div>
      <div className='figmasquad2-powereval-item-header-dis-options'>
        <div className='figmasquad-body-powereval-body-effdis-header-title-option'>
          <CheckIncludeFirearm firearm_ty='ar' />
          <div className='figmasquad2-powereval-item-header-dis-option-firearmty'>
            <span className='figmasquad2-powereval-item-header-dis-option-firearmty-label'>AR</span>
          </div>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-header-title-option'>
          <CheckIncludeFirearm firearm_ty='dmr' />
          <div className='figmasquad2-powereval-item-header-dis-option-firearmty'>
            <span className='figmasquad2-powereval-item-header-dis-option-firearmty-label'>DMR</span>
          </div>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-header-title-option'>
          <CheckIncludeFirearm firearm_ty='hg' />
          <div className='figmasquad2-powereval-item-header-dis-option-firearmty'>
            <span className='figmasquad2-powereval-item-header-dis-option-firearmty-label'>HG</span>
          </div>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-header-title-option'>
          <CheckIncludeFirearm firearm_ty='sg' />
          <div className='figmasquad2-powereval-item-header-dis-option-firearmty'>
            <span className='figmasquad2-powereval-item-header-dis-option-firearmty-label'>SG</span>
          </div>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-header-title-option'>
          <CheckIncludeFirearm firearm_ty='smg' />
          <div className='figmasquad2-powereval-item-header-dis-option-firearmty'>
            <span className='figmasquad2-powereval-item-header-dis-option-firearmty-label'>SMG</span>
          </div>
        </div>
      </div>
    </div>
    <div className='figmasquad-body-powereval-body-effdis-body'>
      <div className='figmasquad-body-powereval-body-effdis-body-scales'>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>0</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>100</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>200</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>300</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>400</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>500</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>600</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>700</span>
        </div>
        <div className='figmasquad-body-powereval-body-effdis-body-scale'>
          <span className='figmasquad-body-powereval-body-effdis-body-scale-label'>800</span>
        </div>

      </div>
      <div className='figmasquad-body-powereval-body-effdis-body-chart'>
        <div className='figmasquad-body-powereval-body-effdis-body-chart-value'>
          {chartBar}
        </div>
      </div>
    </div>
  </div>

}

export function FigmaSquadView2Wrapper(props) {
  const { game, squad, focusedMenus, onChangeAgentOrder, grindSetState, overlayPush, overlayPopMaybe, buildEventActionButton } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onAgentDetail, onUnassign, onExchangeAgent, onAssignAgent, inventories, makeNote } = props;
  const [sorting, setSorting] = useState(false);

  const Lobby2FooterButtons = [
    {
      key: 'squad_prev',
      label: L('loc_ui_string_lobby_button_next_back'),
      desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
      onClick: () => {
        overlayPopMaybe('LOBBY-SQUAD');
        grindSetState({ focusedMenus: focusedMenus.slice(0, -1) });
      },
      enabled: !game.disable_button['squad_prev'] && !sorting,
      className: 'secondary',
    },
    {
      key: 'squad_sort',
      label: (sorting ?
        L('loc_ui_string_squad_button_sort_finish') :
        L('loc_ui_string_squad_button_sort')),
      desc: L('loc_ui_longtext_squad_button_sort_desc'),
      onClick: () => {
        setSorting(!sorting);
      },
      enabled: true,
    },
    {
      key: 'squad_next',
      label: L('loc_ui_string_lobby_button_next_plan'),
      desc: L('loc_ui_longtext_lobby_button_next_plan_desc'),
      onClick: () => {
        overlayPush({ ty: 'LOBBY-MISSION' });
        grindSetState({ focusedMenus: [...focusedMenus, 'mission'] });
      },
      enabled: !game.disable_button['lobby_mission'] && !sorting,
    },
    buildEventActionButton(),
  ];

  return <>
    <FigmaSquadView2
      squad={squad}
      onChangeAgentOrder={onChangeAgentOrder}
      makeNote={makeNote}
      canSort={sorting}
      onAgentEquipAvail={onAgentEquipAvail}
      onAgentEquipPopup={onAgentEquipPopup}
      onUnassign={onUnassign}
      onAssignAgent={onAssignAgent}
      onAgentDetail={onAgentDetail} />
    <Lobby2Footer
      game={game}
      overrideNextActionButtons={Lobby2FooterButtons}
    />
  </>;
}

export function FigmaSquadView2(props) {
  const { squad, onChangeAgentOrder, makeNote, canSort } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onUnassign, onAssignAgent, onAgentDetail } = props;

  const agents = squad.agents;
  const firearms = agents.map((agent) => agent.firearm);

  const [drag, setDrag] = useState(-1);
  const [dragover, setDragover] = useState(-1);

  function handleSort(e) {
    const from = drag;
    const to = dragover;
    if (from === to) return;
    onChangeAgentOrder(from, to);
  }

  function handleSortAdjacent(from, to) {
    if (from === to) return;
    onChangeAgentOrder(from, to);
  }

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className="figmasquad2-root">
        <div className="figmasquad2-header">
          <div className="figmasquad2-header-title">
            <div className="figmasquad2-header-title-bullet" />
            <div className="figmasquad2-header-title-label">{L('loc_ui_string_menu_squad')}</div>
          </div>
        </div>
        <FigmaSquadKanban
          squad={squad}
          drag={drag}
          setDrag={setDrag}
          dragover={dragover}
          setDragover={setDragover}
          handleSort={handleSort}
          handleSortAdjacent={handleSortAdjacent}
          makeNote={makeNote}
          canSort={canSort}
          onAgentEquipAvail={onAgentEquipAvail}
          onAgentEquipPopup={onAgentEquipPopup}
          onUnassign={onUnassign}
          onAssignAgent={onAssignAgent}
          onAgentDetail={onAgentDetail}
        />
        <div className="figmasquad2-powereval">
          <PowerEvalRate agents={agents} firearms={firearms} />
          <PowerEvalEffDis firearms={firearms} />
        </div>
      </div>
    }}
  </TooltipContext.Consumer>;
}

function FigmaSquadKanban(props) {
  const { squad, drag, setDrag, dragover, setDragover, handleSort, handleSortAdjacent, makeNote, canSort } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onUnassign, onAssignAgent, onAgentDetail } = props;
  const ghostRef = useRef(null);
  const handleDragEnter = (e, order) => {
    setDragover(order);
    if (ghostRef.current) {
      ghostRef.current.getElementsByClassName('figmasquad2-squad-agent-order-label')
        .textContent = (dragover + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    }
  }
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleDragStart = (e, order) => {
    setDrag(order);
    const originalE = e.currentTarget;
    const parentE = originalE.parentElement.parentElement;
    const ghostE = originalE.cloneNode(true);
    ghostE.classList.add('dragging');
    ghostE.style.position = 'absolute';
    ghostE.style.pointerEvents = 'none';
    ghostRef.current = ghostE;
    parentE.appendChild(ghostE);
    const rect = originalE.getBoundingClientRect();
    const parentRect = parentE.getBoundingClientRect();
    setDragStart({ 'x': e.clientX + parentRect.left - rect.left, 'y': e.clientY + parentRect.top - rect.top });
    e.dataTransfer.setDragImage(new Image(0, 0), e.clientX - rect.left, e.clientY - rect.top);
  }

  let dragTimer = null;
  const handleDrag = (e) => {
    if (!ghostRef.current) {
      return;
    }
    if (dragTimer) {
      clearTimeout(dragTimer);
    }
    dragTimer = setTimeout(() => {
      ghostRef.current.style.left = (e.clientX - dragStart.x) + 'px';
      ghostRef.current.style.top = (e.clientY - dragStart.y) + 'px';
    });
  }

  const handleDragEnd = (e) => {
    handleSort(e);
    setDrag(-1);
    setDragover(-1);
    if (ghostRef.current) {
      ghostRef.current.remove();
    }
  }

  return <div className="figmasquad2-squad">
    {squad.agents.map((agent, i) => {
      return <FigmaSquadKanbanColumn
        agent={agent}
        order={i}
        size={squad.agents.length}
        draggable={canSort}
        drag={drag}
        setDrag={setDrag}
        dragover={dragover}
        setDragover={setDragover}
        ghostRef={ghostRef}
        handleSort={handleSort}
        makeNote={makeNote}
        handleDragEnter={handleDragEnter}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
        handleDrag={handleDrag}
        onAgentEquipAvail={onAgentEquipAvail}
        onAgentEquipPopup={onAgentEquipPopup}
        onUnassign={onUnassign}
        onAgentDetail={onAgentDetail}
        handleSortAdjacent={handleSortAdjacent}
      />
    })}
    {squad.agents.length < 4 ? Array(4 - squad.agents.length).fill(0).map((_, i) => {
      return <FigmaSquadKanbanEmpty order={squad.agents.length + i} onAssignAgent={onAssignAgent} />
    }) : null}
  </div>;
}

function FigmaSquadKanbanEmpty(props) {
  const { order, onAssignAgent } = props;
  return <div className="figmasquad2-squad-agent-empty" key={'empty' + order}>
    <svg className="figmasquad2-squad-agent-empty-bg-slot" xmlns="http://www.w3.org/2000/svg" width="426" height="692" viewBox="0 0 426 692" fill="none">
      <path d="M107 42.4694H106.91L106.834 42.4787L106.833 42.4788L106.832 42.4789L106.831 42.479C106.825 42.4795 106.812 42.4806 106.792 42.482C106.749 42.4849 106.673 42.4887 106.567 42.4896C106.355 42.4913 106.026 42.4811 105.602 42.4276C104.754 42.3208 103.528 42.0415 102.101 41.3379C99.277 39.9451 95.5085 36.8083 92.3801 29.6617C90.775 25.7874 89.0284 21.9618 87.2943 18.1635C86.6609 16.7762 86.0292 15.3925 85.4066 14.0114L84.0391 14.6279L85.4066 14.0114L85.3549 13.8967C84.0986 11.1093 82.6908 7.98565 80.2801 5.59849C77.7535 3.09658 74.2441 1.5 69.0003 1.5H13C6.4663 1.5 1.5 7.48547 1.5 14.4333V677.567C1.5 684.515 6.46632 690.5 13 690.5H413C419.534 690.5 424.5 684.515 424.5 677.567V55.4028C424.5 48.4549 419.534 42.4694 413 42.4694H107Z" fill="#3C2421" stroke="#472E23" strokeWidth="3" />
    </svg>
    <div className="figmasquad2-squad-agent-order">
      <span className="figmasquad2-squad-agent-order-label">{(order + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</span>
      <div className="figmasquad2-squad-agent-order-underline" />
    </div>
    <div className="figmasquad2-squad-agent-empty-bg">
      <div className="figmasquad2-squad-agent-empty-info">
        {IMG_SQUAD_SLOT_EMPTY}
        <div {...soundprops(null)} className="figmasquad2-squad-agent-empty-info-button" onClick={onAssignAgent}>
          <svg className="figmasquad2-squad-agent-empty-info-button-icon" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
            <path d="M15.5 6H11.5V12H5.5L5.5 16H11.5V22H15.5V16H21.5V12H15.5V6Z" fill="#B18B5F" />
          </svg>
          <span className="figmasquad2-squad-agent-empty-info-button-label">{L('loc_ui_string_squad_agent_add')}</span>
        </div>
      </div>
    </div>
  </div>;
}

function FigmaSquadKanbanColumn(props) {
  const { agent, order, size, draggable, drag, setDrag, dragover, slotMap, setSlotMap, setDragover, ghostRef, handleSort, makeNote } = props;
  const { handleDragEnter, handleDragStart, handleDragEnd, handleDrag } = props;
  const { onAgentEquipAvail, onAgentEquipPopup, onUnassign, onAgentDetail, handleSortAdjacent } = props;
  const dragging = (drag === order);
  const dragovering = (dragover === order);

  const note = makeNote ? makeNote(agent) : null;
  const data = characterChartData(agent);
  console.log(onAgentDetail)
  return <div
    className="figmasquad2-squad-agent-slot"
    onDragEnter={(e) => handleDragEnter(e, order)}
    key={agent.name + order}
  >
    <div
      className={"figmasquad2-squad-agent " + order + (draggable ? " draggable" : "") + (dragging ? " dragging-now" : "") + (dragovering ? " dragover" : "")}
      draggable={false}
      // onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      onDragOver={(e) => e.preventDefault()}
      onDragStart={(e) => handleDragStart(e, order)}
      onDragEnd={handleDragEnd}
      onDrag={handleDrag}
    >
      <div className="figmasquad2-squad-agent-bg-card" />
      <svg className="figmasquad2-squad-agent-bg-slot" xmlns="http://www.w3.org/2000/svg" width="426" height="703" viewBox="0 0 426 703" fill="none">
        <path d="M109 48.2364H108.909L108.83 48.246C108.832 48.2458 108.832 48.2458 108.83 48.246C108.829 48.2461 108.829 48.2461 108.828 48.2462C108.823 48.2467 108.81 48.2478 108.79 48.2491C108.747 48.2521 108.672 48.256 108.567 48.2568C108.356 48.2586 108.028 48.2482 107.605 48.1941C106.76 48.0859 105.535 47.8027 104.11 47.0884C101.287 45.6742 97.5152 42.4858 94.3836 35.2167C92.1936 29.8456 87.7568 19.4949 85.4102 14.206L84.0391 14.8143L85.4102 14.206L85.3588 14.09C84.1025 11.2579 82.6966 8.08844 80.2885 5.66567C77.7635 3.12518 74.2514 1.5 69.0003 1.5H13C6.44566 1.5 1.5 7.59134 1.5 14.6167V688.383C1.5 695.409 6.44567 701.5 13 701.5H413C419.554 701.5 424.5 695.409 424.5 688.383V61.353C424.5 54.3277 419.554 48.2364 413 48.2364H109Z" fill="#3C2421" stroke="#472E23" strokeWidth="3" />
      </svg>
      <div className="figmasquad2-squad-agent-title">
        <span className="figmasquad2-squad-agent-title-name">{L(agent.name)}</span>
      </div>
      <div className="figmasquad2-squad-agent-order">
        <span className="figmasquad2-squad-agent-order-label">{(order + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</span>
        <div className="figmasquad2-squad-agent-order-underline" />
      </div>
      <div className="figmasquad2-squad-agent-info">
        <div className="figmasquad2-squad-agent-info-agent">
          <div className="figmasquad2-squad-agent-info-agent-agentcard2">
            <div className="figmasquad2-squad-agent-info-agent-agentcard2-primary">
              <PortraitWrapper2 agent={agent} className="figmasquad2-squad-agent-portrait"
                onClick={() => { onAgentDetail(agent); }} />
              <div className="figmalist-body2-card-primary-right">
                <div className="figmalist-body2-card-primary-row-2">
                  <div className="figmalist-body2-card-primary-row-item">
                    <span className="figmalist-body2-card-row-item-label">{L('loc_ui_string_agent_power')}</span>
                    <span className="figmalist-body2-card-row-item-value">
                      <PowerIndicator character={agent} starSize={20} />
                    </span>
                  </div>
                </div>
                <div className="figmalist-body2-card-primary-divider" />
                <div className="figmalist-body2-card-primary-row-3-figmasquad2">
                  <div className="figmalist-body2-card-row-item-smily">
                    {ICON_STAMINA_2}
                    <AgentCard2Smiley ty='condition' val={agent.condition} />
                    <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("condition", agent.condition)}>{agent.condition.toFixed(0)}</span>
                  </div>
                  <div className="figmalist-body2-card-row-divider" />
                  <div className="figmalist-body2-card-row-item-smily">
                    {ICON_MOOD_2}
                    <AgentCard2Smiley ty='mood' val={agent.mood} />
                    <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("mood", agent.mood)}>{agent.mood.toFixed(0)}</span>
                  </div>
                  <div className="figmalist-body2-card-row-divider" />
                  <div className="figmalist-body2-card-row-item-smily">
                    {ICON_RELATION_2}
                    <AgentCard2Smiley ty='relation' val={agent.relation} />
                    <span className={"figmalist-body2-card-row-item-smily-value" + evalCSSClass("relation", agent.relation)}>{agent.relation.toFixed(0)}</span>
                  </div>
                </div>
                <div className="figmalist-body2-card-right-secondary">
                  <div className="figmalist-body2-card-secondary-row">
                    <span className="figmalist-body2-card-secondary-row-item-label">{L('loc_ui_string_squad_checkpoint_kills')}</span>
                    <span className="figmalist-body2-card-secondary-row-item-value">{agent.history?.checkpoint_kills ?? 0}</span>
                  </div>
                  <div className="figmalist-body2-card-secondary-row">
                    <span className="figmalist-body2-card-secondary-row-item-label">{L('loc_ui_string_squad_final_kills')}</span>
                    <span className="figmalist-body2-card-secondary-row-item-value">{agent.history?.final_kills ?? 0}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="figmalist-body2-card-secondary">
              <div className="figmalist-body2-card-notes-title">{L('loc_ui_string_agent_note')}</div>
              <FigmaCard2Note note={note} />
            </div>
          </div>
          <div className="figmasquad2-squad-agent-info-agent-stats">
            <div className="figmalist-body-detail2-stats-graph-legend">
              <div className="figmalist-body-detail2-stats-graph-legend-item current">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <rect x="0.0605469" y="0.631348" width="16" height="16" rx="8" fill="#D59B57" />
                </svg>
                <span>{L('loc_ui_string_agent_stat3_current')}</span>
              </div>
              <div className="figmalist-body-detail2-stats-graph-legend-item potential">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <rect x="0.561523" y="1.4895" width="16" height="16" rx="8" fill="#AF683F" />
                  <path d="M11.2972 1.96947C10.4437 1.6589 9.5224 1.4895 8.56152 1.4895C8.18753 1.4895 7.81953 1.51517 7.45919 1.56483L0.636871 8.38701C0.587194 8.7474 0.561523 9.11545 0.561523 9.4895C0.561523 10.4503 0.730906 11.3716 1.04144 12.225L11.2972 1.96947Z" fill="#5D2F14" />
                  <path d="M4.06509 16.1073C3.23172 15.5399 2.5111 14.8193 1.94377 13.9859L13.0581 2.87184C13.8914 3.43918 14.612 4.15982 15.1794 4.9932L4.06509 16.1073Z" fill="#5D2F14" />
                  <path d="M9.6641 17.4141C9.30368 17.4638 8.9356 17.4895 8.56152 17.4895C7.6007 17.4895 6.67945 17.3201 5.82598 17.0096L16.0817 6.75411C16.3922 7.60753 16.5615 8.52874 16.5615 9.4895C16.5615 9.86363 16.5358 10.2318 16.4861 10.5922L9.6641 17.4141Z" fill="#5D2F14" />
                </svg>
                <span>{L('loc_ui_string_agent_stat3_potential')}</span>
              </div>
              <div className="figmalist-body-detail2-stats-graph-legend-item expected">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                  <rect y="1.2627" width="16" height="16" rx="8" fill="#8A795F" />
                </svg>
                <span>{L('loc_ui_string_agent_stat3_expected')}</span>
              </div>
            </div>
            <AgentDetailStatsGraph data={data} name={agent.name} hidePotential={true} noAnimation={true} />
            <div className="figmasquad2-squad-agent-info-agent-stats-table">
              {['physical', 'perception', 'mental', 'tactical', 'shooting'].map((ty, i) => {
                return <div className="figmasquad2-squad-agent-info-agent-stats-table-thead" key={i}>
                  <div className="figmasquad2-squad-agent-info-agent-stats-table-thead-inner">
                    <span className="figmasquad2-squad-agent-info-agent-stats-table-thead-label">{L(`loc_ui_string_agent_stat3_${ty}_short`)}</span>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
        <div className="figmasquad2-squad-agent-info-gears">
          <div className="figmasquad2-squad-agent-info-gears-title">
            <span className="figmasquad2-squad-agent-info-gears-title-label">{L('loc_ui_string_squad_currently_equipped')}</span>
          </div>
          <Squad2Gear agent={agent} onAgentEquipAvail={onAgentEquipAvail} onAgentEquipPopup={draggable ? () => { } : onAgentEquipPopup} />
        </div>
      </div>
    </div>
    {draggable ? <>
      <div className="figmasquad2-squad-agent-slot-outline" />
      {onUnassign || order < size - 1 ?
        <div className="figmasquad2-squad-agent-slot-exclude-button-wrapper">
          {onUnassign ?
            <Lobby2PageButton
              label={L('loc_ui_string_squad_agent_exclude')}
              onClick={() => onUnassign(agent)}
              enabled={true}
              className="figmasquad2-squad-agent-slot-exclude-button"
              highlighted={false}
            /> : null}
        </div> : null}
      {order > 0 || order < size - 1 ?
        <div className="figmasquad2-squad-agent-slot-exclude-button-wrapper exchange">
          {order > 0 ?
            <Lobby2PageButton
              label={'←'}
              onClick={() => handleSortAdjacent(order, order - 1)}
              enabled={true}
              className="figmasquad2-squad-agent-slot-exclude-button exchange"
              highlighted={false}
            /> : null}
          {order < size - 1 ?
            <Lobby2PageButton
              label={'→'}
              onClick={() => handleSortAdjacent(order, order + 1)}
              enabled={true}
              className="figmasquad2-squad-agent-slot-exclude-button exchange"
              highlighted={false}
            /> : null}
        </div>
        : null}
    </> : null
    }
  </div >;
}

function GearButton2(agent, ty, icon, i, onAgentEquipAvail, onAgentEquipPopup) {
  let rate = -1;
  let firearm_ty = null;
  let dur = -1;
  let maxDur = -1;
  let name = '';
  if (ty == 'firearm') {
    const firearm = agent.firearm.key === DEFAULT_FIREARM.key ? firearm_none : agent.firearm;
    rate = firearm.firearm_rate;
    name = firearm.firearm_name;
    firearm_ty = firearm.firearm_ty;
  } else if (ty == 'equipment') {
    rate = agent.equipment.vest_rate;
    name = agent.equipment.vest_name;
  } else if (ty == 'throwable') {
    rate = agent.throwables[0].throwable_rate;
    name = agent.throwables[0].throwable_name;
  }
  return <div key={ty + i} className='figmasquad2-squad-agent-info-gear' data-clickable onClick={() => {
    onAgentEquipPopup(agent, ty, 0);
    triggerSound('UI_Outgame_Button_Click_Default');
  }} >
    <div className='figmasquad2-squad-agent-info-gear-title'>
      <GearNameLine name={name} type={ty} rate={rate} firearm_ty={firearm_ty} />
      {ICON_GEAR_EXCHANGE}
    </div>
  </div>
}

function Squad2Gear(props) {

  const { agent, onAgentEquipAvail, onAgentEquipPopup } = props;

  const gears = [];

  gears.push(GearButton2(agent, "firearm", ICON_FIREARM, gears.length, onAgentEquipAvail, onAgentEquipPopup));
  gears.push(GearButton2(agent, "equipment", ICON_EQUIPMENT, gears.length, onAgentEquipAvail, onAgentEquipPopup));
  if (agent.role == 'pointman') {
    gears.push(GearButton2(agent, "throwable", ICON_THROWABLE, gears.length, onAgentEquipAvail, onAgentEquipPopup));
  } else {
    gears.push(<div key="empty" className='figmasquad-body-squad-slot-inform-gear-empty' />);
  }

  return <div className='figmasquad2-squad-agent-info-gears-slots'>
    {gears}
  </div>

}
