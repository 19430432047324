import source from '../downloaded/eventPreset.json' assert { type: 'json' };
import { split } from './parseUtilities.mjs';

export const eventPresets = {};
for (const row of source) {
  let { id, categories, tags, agents } = row;

  eventPresets[id] = {
    categories: split(categories),
    tags: split(tags),
    agents: split(agents),
  };
}
