import source from '../downloaded/scheduleCEO.json' assert { type: 'json' };

export const scheduleCEOs = [];
for (const row of source) {
  let { id, name, target, target_condition, target_condition_value, desc, event_list, effect } = row;

  scheduleCEOs.push({
    id,
    name,
    target,
    target_condition,
    target_condition_value,
    desc,
    event_list: event_list?.split(',').map((s) => s.trim()),
    effect,
  });
}
