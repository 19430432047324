import source from '../downloaded/eventInstant.json' assert { type: 'json' };

export const eventInstants = [];
for (const row of source) {
  let { category, status, condition, condition_value, priority } = row;

  eventInstants.push({
    category,
    status,
    condition,
    condition_value: parseInt(condition_value),
    priority: parseInt(priority),
  });
}
