/*
  stats_rate : 스탯 증가율

  life_amount : 체력 증가량
  heal_rate : 회복 효율 증가
  heal_amount : 초당 회복량

  shield_mult : 방탄복 내구도 증가율
  shield_break_rate : 추가 실드 비례 피해량
  shield_amount : 실드 증가량

  all_atk_damage_rate_increase : 모든 피해량 증가율
  life_atk_damage_rate_increase : 체력 데미지 증가율
  shield_atk_damage_rate_increase : 방탄 데미지 증가율

  all_damage_reduction : 받는 모든 피해 감소율
  life_damage_reduction : 받는 체력 피해량 감소율
  shield_damage_reduction : 받는 방탄 피해량 감소율

  crit_prob : 치명타 확률 증가량
  crit_damage_rate : 치명타 피해량 증가율

  accuracy_rate_increase : 명중률 증가량
  accuracy_rate_reduction : 명중률 감소율

  shootpattern_mult_increase : 사격 속도 증가량
  shootpattern_mult_reduction : 사격 속도 감소량
  
  aimspeed_mult : 에임 속도 증가율
  reload_speed_mult : 장전 속도 증가율
  ammo_mult : 추가 장탄 비율

  evasion_rate_increase : 회피율 증가량
  evasion_rate_reduction : 회피율 감소량

  speed_rate_reduction : 이동속도 감소량

  duration_seconds : 지속 시간
  life_threshold : 발동 조건 (체력 비율)
  activation_prob : 발동 확률

  additional_atk_damage_amount : 추가 데미지
  num_gadgets : 추가 브리칭 횟수
  num_gadgets : 추가 회복 횟수

  blast_radius_mult : 폭발 범위 증가량
  blast_delay_seconds : 폭발 딜레이 감소
*/

// 공통퍽
// 피지컬
// 건강한 육체
export const perk2_common_physical_healthy_body = {
  heal_rate: 0.2, // 회복 효율 증가
};
export const perk2_common_physical_healthy_body_2 = {
  heal_rate: 0.3, // 회복 효율 증가
};
export const perk2_common_physical_healthy_body_3 = {
  heal_rate: 0.4, // 회복 효율 증가
};
// 버티기
export const perk2_common_physical_regist = {
  all_damage_reduction: 0.2, // 받는 피해 감소
};
export const perk2_common_physical_regist_2 = {
  all_damage_reduction: 0.25, // 받는 피해 감소
};
export const perk2_common_physical_regist_3 = {
  all_damage_reduction: 0.3, // 받는 피해 감소
};
// 초인적인 힘
export const perk2_common_physical_power_overwhelming = {

  duration_seconds: 3, // 사망에 준하는 피해를 받을 시 일정 기간 life가 1 밑으로 떨어지지 않음
};
export const perk2_common_physical_power_overwhelming_2 = {

  duration_seconds: 4, // 사망에 준하는 피해를 받을 시 일정 기간 life가 1 밑으로 떨어지지 않음
};
export const perk2_common_physical_power_overwhelming_3 = {

  duration_seconds: 5, // 사망에 준하는 피해를 받을 시 일정 기간 life가 1 밑으로 떨어지지 않음
};

// 공간지각
// 직감
export const perk2_common_vision_intuition = {
  // no parameters
};
// 효과적인 은엄폐
export const perk2_common_vision_effective_cover = {
  evasion_rate_increase: 0.1, // 엄폐시 회피 확률이 일정량 증가 (덧셈)
};
export const perk2_common_vision_effective_cover_2 = {
  evasion_rate_increase: 0.15, // 엄폐시 회피 확률이 일정량 증가 (덧셈)
};
export const perk2_common_vision_effective_cover_3 = {
  evasion_rate_increase: 0.2, // 엄폐시 회피 확률이 일정량 증가 (덧셈)
};
// 궤도 역학
export const perk2_common_vision_orbital_mechanics = {
  blast_radius_mult: 1.3,
};
export const perk2_common_vision_orbital_mechanics_2 = {
  blast_radius_mult: 1.4,
};
export const perk2_common_vision_orbital_mechanics_3 = {
  blast_radius_mult: 1.5,
};

// 정신력
// 생존 본능
export const perk2_common_mental_survival_instinct = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  evasion_rate_increase: 0.2, // 효과 회피 증가폭 (덧셈)
  duration_seconds: 30, // 효과 지속 시간
};
export const perk2_common_mental_survival_instinct_2 = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  evasion_rate_increase: 0.25, // 효과 회피 증가폭 (덧셈)
  duration_seconds: 30, // 효과 지속 시간
};
export const perk2_common_mental_survival_instinct_3 = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  evasion_rate_increase: 0.3, // 효과 회피 증가폭 (덧셈)
  duration_seconds: 30, // 효과 지속 시간
};
// 명경지수
export const perk2_common_mental_calmstate = {

  activation_prob: 0.5, // 발동 확률
};
export const perk2_common_mental_calmstate_2 = {

  activation_prob: 0.6, // 발동 확률
};
export const perk2_common_mental_calmstate_3 = {

  activation_prob: 0.7, // 발동 확률
};
// 죽거나 죽이거나
export const perk2_common_mental_kill_or_be_killed = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  crit_prob: 0.24, // 크리티컬 확률 증가폭 (덧셈)
};
export const perk2_common_mental_kill_or_be_killed_2 = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  crit_prob: 0.32, // 크리티컬 확률 증가폭 (덧셈)
};
export const perk2_common_mental_kill_or_be_killed_3 = {

  life_threshold: 0.3, // 발동 조건 체력 컷
  crit_prob: 0.4, // 크리티컬 확률 증가폭 (덧셈)
};

// 전술지능
// 파이컷
export const perk2_common_intelligence_piecut = {

  duration_seconds: 5, // 효과 지속 시간
  all_damage_reduction: 0.15, // 받는 피해 감소
};
export const perk2_common_intelligence_piecut_2 = {

  duration_seconds: 5, // 효과 지속 시간
  all_damage_reduction: 0.2, // 받는 피해 감소
};
export const perk2_common_intelligence_piecut_3 = {

  duration_seconds: 5, // 효과 지속 시간
  all_damage_reduction: 0.25, // 받는 피해 감소
};
// 다이나믹 엔트리
export const perk2_common_intelligence_dynamic_entry = {

  duration_seconds: 5, // 효과 지속 시간
  evasion_rate_reduction: 0.1, // 회피 감소율 (덧셈)
  all_atk_damage_rate_increase: 0.2, // 데미지 증가율 (합연산)
};
export const perk2_common_intelligence_dynamic_entry_2 = {

  duration_seconds: 5, // 효과 지속 시간
  evasion_rate_reduction: 0.08, // 회피 감소율 (덧셈)
  all_atk_damage_rate_increase: 0.25, // 데미지 증가율 (합연산)
};
export const perk2_common_intelligence_dynamic_entry_3 = {

  duration_seconds: 5, // 효과 지속 시간
  evasion_rate_reduction: 0.05, // 회피 감소율 (덧셈)
  all_atk_damage_rate_increase: 0.3, // 데미지 증가율 (합연산)
};
// 수류탄 쿠킹
export const perk2_common_intelligence_grenade_cooking = {
  blast_delay_seconds: 0.1, // 수류탄 폭발 딜레이 감소 (뺄셈)
};
export const perk2_common_intelligence_grenade_cooking_2 = {
  blast_delay_seconds: 0.1, // 수류탄 폭발 딜레이 감소 (뺄셈)
};
export const perk2_common_intelligence_grenade_cooking_3 = {
  blast_delay_seconds: 0.1, // 수류탄 폭발 딜레이 감소 (뺄셈)
};

// 사격술
// 퀵드로우
export const perk2_common_shooting_quickdraw = {
  aimspeed_mult: 1.2, // 적 발견 후 초탄 사격 속도 증가율 (곱연산)
};
export const perk2_common_shooting_quickdraw_2 = {
  aimspeed_mult: 1.6, // 적 발견 후 초탄 사격 속도 증가율 (곱연산)
};
export const perk2_common_shooting_quickdraw_3 = {
  aimspeed_mult: 2, // 적 발견 후 초탄 사격 속도 증가율 (곱연산)
};
// 전술 재장전
export const perk2_common_shooting_tactical_reload = {
  // no parameters
};
// 정밀 조준 사격
export const perk2_common_shooting_precision_shooting = {
  activation_prob: 0.5, // 발동 확률
};
export const perk2_common_shooting_precision_shooting_2 = {
  activation_prob: 0.6, // 발동 확률
};
export const perk2_common_shooting_precision_shooting_3 = {
  activation_prob: 0.7, // 발동 확률
};

// 병과 퍽
// 포인트맨
// 기동사격 숙달
export const perk2_pointman_manoeuvre_shooting = {
  accuracy_rate_increase: 1.1, // 이동 사격 시 명중률 증가율 (곱연산)
};
export const perk2_pointman_manoeuvre_shooting_2 = {
  accuracy_rate_increase: 1.2, // 이동 사격 시 명중률 증가율 (곱연산)
};
export const perk2_pointman_manoeuvre_shooting_3 = {
  accuracy_rate_increase: 1.3, // 이동 사격 시 명중률 증가율 (곱연산)
};
// 다표적 교전
export const perk2_pointman_multitarget_engagement = {
  shootpattern_mult_increase: 1.1, // 사격 속도 증가율 (곱연산)
};
// 다표적 교전
export const perk2_pointman_multitarget_engagement_2 = {
  shootpattern_mult_increase: 1.2, // 사격 속도 증가율 (곱연산)
};
// 다표적 교전
export const perk2_pointman_multitarget_engagement_3 = {
  shootpattern_mult_increase: 1.3, // 사격 속도 증가율 (곱연산)
};
// 기수
export const perk2_pointman_spearhead = {
  stats_rate: 1.2, // 스탯 증가율
};
export const perk2_pointman_spearhead_2 = {
  stats_rate: 1.25, // 스탯 증가율
};
export const perk2_pointman_spearhead_3 = {
  stats_rate: 1.3, // 스탯 증가율
};

// 브리처
// 폭발학
export const perk2_breacher_explosive_science = {
  additional_atk_damage_amount: 100, // 추가 데미지 (합연산)
};
export const perk2_breacher_explosive_science_2 = {
  additional_atk_damage_amount: 150, // 추가 데미지 (합연산)
};
export const perk2_breacher_explosive_science_3 = {
  additional_atk_damage_amount: 200, // 추가 데미지 (합연산)
};
// 쿼드로딩
export const perk2_breacher_quadloading = {
  reload_speed_mult: 2.5, // 장전속도 증가율 (곱연산)
};
export const perk2_breacher_quadloading_2 = {
  reload_speed_mult: 2.75, // 장전속도 증가율 (곱연산)
};
export const perk2_breacher_quadloading_3 = {
  reload_speed_mult: 3, // 장전속도 증가율 (곱연산)
};
// 효율적인 화약 분배: 브리처가 들고 들어가는 attachable의 갯수를 변경
export const perk2_breacher_efficient_explosive = {
  num_gadgets: 2, // 브리칭 차지 횟수 (대입연산)
};

// 메딕
// 구급학 초급
export const perk2_medic_paramedics_elementary = {
  heal_rate: 0.3, // 회복량 증가율 (덧셈)
};
export const perk2_medic_paramedics_elementary_2 = {
  heal_rate: 0.4, // 회복량 증가율 (덧셈)
};
export const perk2_medic_paramedics_elementary_3 = {
  heal_rate: 0.5, // 회복량 증가율 (덧셈)
};
// 구급학 중급
export const perk2_medic_paramedics_intermediate = {
  num_gadgets: 7, // 회복 횟수 (대입연산)
};
// 구급학 고급
export const perk2_medic_paramedics_advanced = {
  // no parameters
};

// 뱅가드
// 체력 단련
export const perk2_vanguard_physical_training = {
  life_amount: 500, // 체력 증가량 (덧셈)
};
export const perk2_vanguard_physical_training_2 = {
  life_amount: 750, // 체력 증가량 (덧셈)
};
export const perk2_vanguard_physical_training_3 = {
  life_amount: 1000, // 체력 증가량 (덧셈)
};
// 모잠비크 드릴
export const perk2_vanguard_mozambique_drill = {
  shoot_pattern: [0.1, 0.1], // 사격 패턴
  crit_prob: 1, // 마지막 사격 치명타 확률 증가량(덧셈)
  crit_damage_rate: 1 // 치명타 피해량 증가량 (덧셈)
};
// 효과적인 방탄복 활용
export const perk2_vanguard_effective_armor = {
  shield_mult: 1.4, // 방탄복 내구도 증가율 (곱연산)
};
export const perk2_vanguard_effective_armor_2 = {
  shield_mult: 1.6, // 방탄복 내구도 증가율 (곱연산)
};
export const perk2_vanguard_effective_armor_3 = {
  shield_mult: 1.8, // 방탄복 내구도 증가율 (곱연산)
};

// 샤프슈터
// 사격 숙련
export const perk2_sharpshooter_shoot_mastery = {
  shootpattern_mult_increase: 1.5, // 사격 속도 증가율 (곱연산)
};
export const perk2_sharpshooter_shoot_mastery_2 = {
  shootpattern_mult_increase: 1.8, // 사격 속도 증가율 (곱연산)
};
export const perk2_sharpshooter_shoot_mastery_3 = {
  shootpattern_mult_increase: 2.1, // 사격 속도 증가율 (곱연산)
};
// 저격 숙련
export const perk2_sharpshooter_snipe_mastery = {
  all_atk_damage_rate_increase: 1.3, // 피해량 증가 배율 (곱연산)
};
export const perk2_sharpshooter_snipe_mastery_2 = {
  all_atk_damage_rate_increase: 1.45, // 피해량 증가 배율 (곱연산)
};
export const perk2_sharpshooter_snipe_mastery_3 = {
  all_atk_damage_rate_increase: 1.6, // 피해량 증가 배율 (곱연산)
};
// 숙련된 명사수
export const perk2_sharpshooter_aim_down_sights = {
  activation_prob: 0.2, // 효과 발동 확률
};
export const perk2_sharpshooter_aim_down_sights_2 = {
  activation_prob: 0.35, // 효과 발동 확률
};
export const perk2_sharpshooter_aim_down_sights_3 = {
  activation_prob: 0.5, // 효과 발동 확률
};
// 효과적인 제압
export const perk2_common_strategy_supress_decrease_avoid = {
  accuracy_rate_reduction: 0.05, // 명중률 감소율 (합연산)
};
export const perk2_common_strategy_supress_decrease_avoid_2 = {
  accuracy_rate_reduction: 0.1, // 명중률 감소율 (합연산)
};
export const perk2_common_strategy_supress_decrease_avoid_3 = {
  accuracy_rate_reduction: 0.15, // 명중률 감소율 (합연산)
};
// 트리거 해피
export const perk2_common_strategy_aggressive_dmg = {
  all_atk_damage_rate_increase: 0.1, // 피해량 증가율 (합연산)
};
export const perk2_common_strategy_aggressive_dmg_2 = {
  all_atk_damage_rate_increase: 0.15, // 피해량 증가율 (합연산)
};
export const perk2_common_strategy_aggressive_dmg_3 = {
  all_atk_damage_rate_increase: 0.2, // 피해량 증가율 (합연산)
};
// 포식자
export const perk2_common_return_ammo_on_kill = {
  ammo_mult: 0.3, // 추가되는 장탄 비율 (합연산)
};
export const perk2_common_return_ammo_on_kill_2 = {
  ammo_mult: 0.5, // 추가되는 장탄 비율 (합연산)
};
export const perk2_common_return_ammo_on_kill_3 = {
  ammo_mult: 0.7, // 추가되는 장탄 비율 (합연산)
};
// 다재무능
export const perk2_common_jack_of_all = {
  life_amount: 200, // 체력 증가량 (덧셈)
};
export const perk2_common_jack_of_all_2 = {
  life_amount: 400, // 체력 증가량 (덧셈)
};
export const perk2_common_jack_of_all_3 = {
  life_amount: 750, // 체력 증가량 (덧셈)
};
// 방탄 무효화
export const perk2_breacher_shield_disable = {
  shield_break_rate: 0.5, // 쉴드 비례 피해량
};
export const perk2_breacher_shield_disable_2 = {
  shield_break_rate: 0.7, // 쉴드 비례 피해량
};
export const perk2_breacher_shield_disable_3 = {
  shield_break_rate: 0.9, // 쉴드 비례 피해량
};
// 실드 충전
export const perk2_breacher_shield_regen = {
  shield_amount: 10, // 실드 증가량
};
export const perk2_breacher_shield_regen_2 = {
  shield_amount: 20, // 실드 증가량
};
export const perk2_breacher_shield_regen_3 = {
  shield_amount: 30, // 실드 증가량
};
// 방탄 수선
export const perk2_vanguard_shield_repair = {

};
// 웨폰 마스터
export const perk2_sharpshooter_weapon_master = {
  // empty
};
// 헤드 샷
export const perk2_sharpshooter_headshot = {
  shootpattern_mult_reduction: 1.5 // 공격 속도 감소량
};
// 저격수의 위협
export const perk2_sharpshooter_threat = {
  speed_rate_reduction: 0.12, // 이동속도 감소량 (합연산)
};
export const perk2_sharpshooter_threat_2 = {
  speed_rate_reduction: 0.14, // 이동속도 감소량 (합연산)
};
export const perk2_sharpshooter_threat_3 = {
  speed_rate_reduction: 0.16, // 이동속도 감소량 (합연산)
};
// 긴급 구호
export const perk2_medic_emergency_rescue = {
  shield_amount: 300, // 실드 추가량
};
export const perk2_medic_emergency_rescue_2 = {
  shield_amount: 450, // 실드 추가량
};
export const perk2_medic_emergency_rescue_3 = {
  shield_amount: 600, // 실드 추가량
};
// 블러드 레이지
export const perk2_pointman_blood_rage = {

  shootpattern_mult_increase: 0.08, // 사격 속도 증가율 (합연산)
};
export const perk2_pointman_blood_rage_2 = {

  shootpattern_mult_increase: 0.12, // 사격 속도 증가율 (합연산)
};
export const perk2_pointman_blood_rage_3 = {

  shootpattern_mult_increase: 0.16, // 사격 속도 증가율 (합연산)
};

//전투 고양
export const perk2_pointman_battle_excite = {

  shootpattern_mult_increase: 0.08,  // 사격 속도 증가율 (합연산)
};
export const perk2_pointman_battle_excite_2 = {

  shootpattern_mult_increase: 0.12,  // 사격 속도 증가율 (합연산)
};
export const perk2_pointman_battle_excite_3 = {

  shootpattern_mult_increase: 0.16,  // 사격 속도 증가율 (합연산)
};

// 신중함
export const perk2_common_half_under_mag = {
  evasion_rate_increase: 0.06, // 회피 증가폭 (합연산)
};
export const perk2_common_half_under_mag_2 = {
  evasion_rate_increase: 0.12, // 회피 증가폭 (합연산)
};
export const perk2_common_half_under_mag_3 = {
  evasion_rate_increase: 0.18, // 회피 증가폭 (합연산)
};
// 정확도 강화
export const perk2_common_increase_acc = {
  accuracy_rate_increase: 0.02, // 명중률 증가량 (합연산)
};
export const perk2_common_increase_acc_2 = {
  accuracy_rate_increase: 0.04, // 명중률 증가량 (합연산)
};
export const perk2_common_increase_acc_3 = {
  accuracy_rate_increase: 0.06, // 명중률 증가량 (합연산)
};
// 충분한 보급 퍽
export const perk2_common_half_over_mag = {
  accuracy_rate_increase: 0.1, // 명중률 증가량 (합연산)
};
export const perk2_common_half_over_mag_2 = {
  accuracy_rate_increase: 0.15, // 명중률 증가량 (합연산)
};
export const perk2_common_half_over_mag_3 = {
  accuracy_rate_increase: 0.2, // 명중률 증가량 (합연산)
};
// 회피 강화
export const perk2_common_increase_avoid = {
  evasion_rate_increase: 0.05, // 회피 증가폭 (덧셈)
};
export const perk2_common_increase_avoid_2 = {
  evasion_rate_increase: 0.08, // 회피 증가폭 (덧셈)
};
export const perk2_common_increase_avoid_3 = {
  evasion_rate_increase: 0.12, // 회피 증가폭 (덧셈)
};
// 화력 강화 퍽
export const perk2_common_increase_dmg = {
  all_atk_damage_rate_increase: 0.05, // 모든 데미지 증가율 (합연산)
};
export const perk2_common_increase_dmg_2 = {
  all_atk_damage_rate_increase: 0.1, // 모든 데미지 증가율 (합연산)
};
export const perk2_common_increase_dmg_3 = {
  all_atk_damage_rate_increase: 0.15, // 모든 데미지 증가율 (합연산)
};
// 전열 수비
export const perk2_breacher_frontline = {
  all_damage_reduction: 0.15, // 모든 피해량 감소율 (합연산)
};
export const perk2_breacher_frontline_2 = {
  all_damage_reduction: 0.20, // 모든 피해량 감소율 (합연산)
};
export const perk2_breacher_frontline_3 = {
  all_damage_reduction: 0.25, // 모든 피해량 감소율 (합연산)
};
// 근육 방탄판
export const perk2_vanguard_muscle_bulletproof = {
  life_damage_reduction: 0.04, // 받는 체력 피해 감소
};
export const perk2_vanguard_muscle_bulletproof_2 = {
  life_damage_reduction: 0.08, // 받는 체력 피해 감소
};
export const perk2_vanguard_muscle_bulletproof_3 = {
  life_damage_reduction: 0.12, // 받는 체력 피해 감소
};
// 최대효율 활용
export const perk2_vanguard_full_efficiency = {

  duration_seconds: 4, // 효과 지속 시간
};
export const perk2_vanguard_full_efficiency_2 = {

  duration_seconds: 5, // 효과 지속 시간
};
export const perk2_vanguard_full_efficiency_3 = {

  duration_seconds: 6, // 효과 지속 시간
};
// 방패 숙달
export const perk2_vanguard_shield_mastery = {
  shield_damage_reduction: 0.1, // 받는 대방탄 피해량 감소 
};
export const perk2_vanguard_shield_mastery_2 = {
  shield_damage_reduction: 0.15, // 받는 대방탄 피해량 감소 
};
export const perk2_vanguard_shield_mastery_3 = {
  shield_damage_reduction: 0.3, // 받는 대방탄 피해량 감소 
};
// 고속 탄자 퍽
export const perk2_sharpshooter_high_velocity = {
  all_atk_damage_rate_increase: 0.1, // 모든 데미지 증가율 (합연산)
};
export const perk2_sharpshooter_high_velocity_2 = {
  all_atk_damage_rate_increase: 0.2, // 모든데미지 증가율 (합연산)
};
export const perk2_sharpshooter_high_velocity_3 = {
  all_atk_damage_rate_increase: 0.3, // 모든 데미지 증가율 (합연산)
};

// 조준 사격 숙달
export const perk2_sharpshooter_ads_mastery = {
  crit_prob: 0.1, // 크리티컬 확률 증가폭 (합연산)
};
export const perk2_sharpshooter_ads_mastery_2 = {
  crit_prob: 0.15, // 크리티컬 확률 증가폭 (합연산)
};
export const perk2_sharpshooter_ads_mastery_3 = {
  crit_prob: 0.2, // 크리티컬 확률 증가폭 (합연산)
};
// AR 전문화
export const perk2_pointman_ar_specialization = {
  life_atk_damage_rate_increase: 0.05, // 체력 데미지 증가율 (합연산)
};
export const perk2_pointman_ar_specialization_2 = {
  life_atk_damage_rate_increase: 0.12, // 체력 데미지 증가율 (합연산)
};
export const perk2_pointman_ar_specialization_3 = {
  life_atk_damage_rate_increase: 0.2, // 체력 데미지 증가율 (합연산)
};

// SMG 전문화
export const perk2_pointman_smg_specialization = {
  shield_atk_damage_rate_increase: 0.1 // 관통력 증가율 (합연산)
};
export const perk2_pointman_smg_specialization_2 = {
  shield_atk_damage_rate_increase: 0.2 // 관통력 증가율 (합연산)
};
export const perk2_pointman_smg_specialization_3 = {
  shield_atk_damage_rate_increase: 0.3 // 관통력 증가율 (합연산)
};
// 표적 지정
export const perk2_common_strategy_pair_increase_acc = {
  evasion_rate_reduction: 0.05, // 회피율 감소율 (합연산)
};
export const perk2_common_strategy_pair_increase_acc_2 = {
  evasion_rate_reduction: 0.07, // 회피율 감소율 (합연산)
};
export const perk2_common_strategy_pair_increase_acc_3 = {
  evasion_rate_reduction: 0.1, // 회피율 감소율 (합연산)
};
// 기분파
export const perk2_common_increase_acc_per_mood = {
  accuracy_rate_increase: 0.05, // 명중률 증가량 (합연산)
};
export const perk2_common_increase_acc_per_mood_2 = {
  accuracy_rate_increase: 0.1, // 명중률 증가량 (합연산)
};
export const perk2_common_increase_acc_per_mood_3 = {
  accuracy_rate_increase: 0.15, // 명중률 증가량 (합연산)
};
// 충격과 공포
export const perk2_breacher_shock_and_awe = {
  duration_seconds: 3, // 기절 시간
};
export const perk2_breacher_shock_and_awe_2 = {
  duration_seconds: 4, // 기절 시간
};
export const perk2_breacher_shock_and_awe_3 = {
  duration_seconds: 5, // 기절 시간
};
// 아드레날린
export const perk2_medic_adrenaline = {

  heal_amount: 30, // 회복량
};
export const perk2_medic_adrenaline_2 = {

  heal_amount: 60, // 회복량
};
export const perk2_medic_adrenaline_3 = {

  heal_amount: 90, // 회복량
};
// 스팀팩
export const perk2_medic_stimpack = {
  all_atk_damage_rate_increase: 0.03, // 모든 데미지 증가율 (합연산)
};
export const perk2_medic_stimpack_2 = {
  all_atk_damage_rate_increase: 0.06, // 모든 데미지 증가율 (합연산)
};
export const perk2_medic_stimpack_3 = {
  all_atk_damage_rate_increase: 0.09, // 모든 데미지 증가율 (합연산)
};

// 충격탄
export const perk2_sharpshooter_high_grain = {
  all_atk_damage_rate_increase: 0.3, // 모든 데미지 반영률 (합연산)
};
export const perk2_sharpshooter_high_grain_2 = {
  all_atk_damage_rate_increase: 0.5, // 모든 데미지 반영률 (합연산)
};
export const perk2_sharpshooter_high_grain_3 = {
  all_atk_damage_rate_increase: 0.7, // 모든 데미지 반영률 (합연산)
};

// 장비 파괴자
export const perk2_common_shield_breaker = {
  shield_atk_damage_rate_increase: 0.05, // 관통력 증가율 (합연산)
};
export const perk2_common_shield_breaker_2 = {
  shield_atk_damage_rate_increase: 0.1, // 관통력 증가율 (합연산)
};
export const perk2_common_shield_breaker_3 = {
  shield_atk_damage_rate_increase: 0.15, // 관통력 증가율 (합연산)
};

// 부싯돌
export const perk2_common_first_shot_crit = {
  all_atk_damage_rate_increase: 0.1, // 데미지 증가율 (합연산)
};
export const perk2_common_first_shot_crit_2 = {
  all_atk_damage_rate_increase: 0.15, // 데미지 증가율 (합연산)
};
export const perk2_common_first_shot_crit_3 = {
  all_atk_damage_rate_increase: 0.2, // 데미지 증가율 (합연산)
};

// 일발 역전
export const perk2_common_last_shot_crit = {
  all_atk_damage_rate_increase: 0.5, // 데미지 증가율 (합연산)
};
export const perk2_common_last_shot_crit_2 = {
  all_atk_damage_rate_increase: 0.75, // 데미지 증가율 (합연산)
};
export const perk2_common_last_shot_crit_3 = {
  all_atk_damage_rate_increase: 1, // 데미지 증가율 (합연산)
};

// 스카웃
// 와이드 비전
export const perk2_scout_temp_1 = {
  // 미구현
};
// 부동사격
export const perk2_scout_temp_2 = {
  // 미구현
};
// 은신
export const perk2_scout_temp_3 = {
  // 미구현
};

// 전탄사격 정책 테스트용
export const perk2_test_fire_all = {
  accuracy_mult: 0.2, // 명중률 배율
  shootpattern_mult: 60 / 600, // 사격 RPM
  shoot_pattern: [], // 사격 패턴
  denied_firearm_tys: ['sg', 'dmr'],
};

export const perk2Sets = {
  perk2_common_physical_healthy_body,
  perk2_common_physical_healthy_body_2,
  perk2_common_physical_healthy_body_3,
  perk2_common_physical_regist,
  perk2_common_physical_regist_2,
  perk2_common_physical_regist_3,
  perk2_common_physical_power_overwhelming,
  perk2_common_physical_power_overwhelming_2,
  perk2_common_physical_power_overwhelming_3,
  perk2_common_vision_intuition,
  perk2_common_vision_effective_cover,
  perk2_common_vision_effective_cover_2,
  perk2_common_vision_effective_cover_3,
  perk2_common_vision_orbital_mechanics,
  perk2_common_vision_orbital_mechanics_2,
  perk2_common_vision_orbital_mechanics_3,
  perk2_common_mental_survival_instinct,
  perk2_common_mental_survival_instinct_2,
  perk2_common_mental_survival_instinct_3,
  perk2_common_mental_calmstate,
  perk2_common_mental_calmstate_2,
  perk2_common_mental_calmstate_3,
  perk2_common_mental_kill_or_be_killed,
  perk2_common_mental_kill_or_be_killed_2,
  perk2_common_mental_kill_or_be_killed_3,
  perk2_common_intelligence_piecut,
  perk2_common_intelligence_piecut_2,
  perk2_common_intelligence_piecut_3,
  perk2_common_intelligence_dynamic_entry,
  perk2_common_intelligence_dynamic_entry_2,
  perk2_common_intelligence_dynamic_entry_3,
  perk2_common_intelligence_grenade_cooking,
  perk2_common_intelligence_grenade_cooking_2,
  perk2_common_intelligence_grenade_cooking_3,
  perk2_common_shooting_quickdraw,
  perk2_common_shooting_quickdraw_2,
  perk2_common_shooting_quickdraw_3,
  perk2_common_shooting_tactical_reload,
  perk2_common_shooting_precision_shooting,
  perk2_common_shooting_precision_shooting_2,
  perk2_common_shooting_precision_shooting_3,

  perk2_pointman_manoeuvre_shooting,
  perk2_pointman_manoeuvre_shooting_2,
  perk2_pointman_manoeuvre_shooting_3,
  perk2_pointman_multitarget_engagement,
  perk2_pointman_multitarget_engagement_2,
  perk2_pointman_multitarget_engagement_3,
  perk2_pointman_spearhead,
  perk2_pointman_spearhead_2,
  perk2_pointman_spearhead_3,
  perk2_breacher_explosive_science,
  perk2_breacher_explosive_science_2,
  perk2_breacher_explosive_science_3,
  perk2_breacher_quadloading,
  perk2_breacher_quadloading_2,
  perk2_breacher_quadloading_3,
  perk2_breacher_efficient_explosive,
  perk2_medic_paramedics_elementary,
  perk2_medic_paramedics_elementary_2,
  perk2_medic_paramedics_elementary_3,
  perk2_medic_paramedics_intermediate,
  perk2_medic_paramedics_advanced,
  perk2_vanguard_physical_training,
  perk2_vanguard_physical_training_2,
  perk2_vanguard_physical_training_3,
  perk2_vanguard_mozambique_drill,
  perk2_vanguard_effective_armor,
  perk2_vanguard_effective_armor_2,
  perk2_vanguard_effective_armor_3,
  perk2_sharpshooter_shoot_mastery,
  perk2_sharpshooter_shoot_mastery_2,
  perk2_sharpshooter_shoot_mastery_3,
  perk2_sharpshooter_snipe_mastery,
  perk2_sharpshooter_snipe_mastery_2,
  perk2_sharpshooter_snipe_mastery_3,
  perk2_sharpshooter_aim_down_sights,
  perk2_sharpshooter_aim_down_sights_2,
  perk2_sharpshooter_aim_down_sights_3,
  perk2_pointman_smg_specialization,
  perk2_pointman_smg_specialization_2,
  perk2_pointman_smg_specialization_3,
  perk2_scout_temp_1,
  perk2_scout_temp_2,
  perk2_scout_temp_3,
  perk2_common_strategy_supress_decrease_avoid,
  perk2_common_strategy_supress_decrease_avoid_2,
  perk2_common_strategy_supress_decrease_avoid_3,
  perk2_common_strategy_aggressive_dmg,
  perk2_common_strategy_aggressive_dmg_2,
  perk2_common_strategy_aggressive_dmg_3,
  perk2_common_return_ammo_on_kill,
  perk2_common_return_ammo_on_kill_2,
  perk2_common_return_ammo_on_kill_3,
  perk2_common_jack_of_all,
  perk2_common_jack_of_all_2,
  perk2_common_jack_of_all_3,
  perk2_breacher_shield_disable,
  perk2_breacher_shield_disable_2,
  perk2_breacher_shield_disable_3,
  perk2_breacher_shield_regen,
  perk2_breacher_shield_regen_2,
  perk2_breacher_shield_regen_3,
  perk2_vanguard_shield_repair,
  perk2_sharpshooter_weapon_master,
  perk2_sharpshooter_headshot,
  perk2_sharpshooter_threat,
  perk2_sharpshooter_threat_2,
  perk2_sharpshooter_threat_3,
  perk2_medic_emergency_rescue,
  perk2_medic_emergency_rescue_2,
  perk2_medic_emergency_rescue_3,
  perk2_pointman_blood_rage,
  perk2_pointman_blood_rage_2,
  perk2_pointman_blood_rage_3,
  perk2_pointman_battle_excite,
  perk2_pointman_battle_excite_2,
  perk2_pointman_battle_excite_3,
  perk2_common_half_under_mag,
  perk2_common_half_under_mag_2,
  perk2_common_half_under_mag_3,
  perk2_common_increase_acc,
  perk2_common_increase_acc_2,
  perk2_common_increase_acc_3,
  perk2_common_half_over_mag,
  perk2_common_half_over_mag_2,
  perk2_common_half_over_mag_3,
  perk2_common_increase_avoid,
  perk2_common_increase_avoid_2,
  perk2_common_increase_avoid_3,
  perk2_common_increase_dmg,
  perk2_common_increase_dmg_2,
  perk2_common_increase_dmg_3,
  perk2_breacher_frontline,
  perk2_breacher_frontline_2,
  perk2_breacher_frontline_3,
  perk2_vanguard_muscle_bulletproof,
  perk2_vanguard_muscle_bulletproof_2,
  perk2_vanguard_muscle_bulletproof_3,
  perk2_vanguard_full_efficiency,
  perk2_vanguard_full_efficiency_2,
  perk2_vanguard_full_efficiency_3,
  perk2_vanguard_shield_mastery,
  perk2_vanguard_shield_mastery_2,
  perk2_vanguard_shield_mastery_3,
  perk2_sharpshooter_high_velocity,
  perk2_sharpshooter_high_velocity_2,
  perk2_sharpshooter_high_velocity_3,
  perk2_sharpshooter_ads_mastery,
  perk2_sharpshooter_ads_mastery_2,
  perk2_sharpshooter_ads_mastery_3,
  perk2_pointman_ar_specialization,
  perk2_pointman_ar_specialization_2,
  perk2_pointman_ar_specialization_3,
  perk2_common_strategy_pair_increase_acc,
  perk2_common_strategy_pair_increase_acc_2,
  perk2_common_strategy_pair_increase_acc_3,
  perk2_common_increase_acc_per_mood,
  perk2_common_increase_acc_per_mood_2,
  perk2_common_increase_acc_per_mood_3,
  perk2_common_last_shot_crit,
  perk2_common_last_shot_crit_2,
  perk2_common_last_shot_crit_3,
  perk2_common_first_shot_crit,
  perk2_common_first_shot_crit_2,
  perk2_common_first_shot_crit_3,
  perk2_common_shield_breaker,
  perk2_common_shield_breaker_2,
  perk2_common_shield_breaker_3,
  perk2_breacher_shock_and_awe,
  perk2_breacher_shock_and_awe_2,
  perk2_breacher_shock_and_awe_3,
  perk2_sharpshooter_high_grain,
  perk2_sharpshooter_high_grain_2,
  perk2_sharpshooter_high_grain_3,
  perk2_medic_adrenaline,
  perk2_medic_adrenaline_2,
  perk2_medic_adrenaline_3,
  perk2_medic_stimpack,
  perk2_medic_stimpack_2,
  perk2_medic_stimpack_3,

  perk2_test_fire_all,
}

export const perk2_tactical_bonus = {
  pointman_acc: 0.2,
  pointman_rpm: 0.05,
  pointman_stat_all: 0.1,
  pointman_shield_damage: 0.2,
  pointman_life_damage: 0.2,
  pointman_aim_speed: 0.5,

  breacher_damage_resist: 0.1,
  breacher_reload_mult: 0.25,
  breacher_duration: 1,
  breacher_temp_shield_true: 15,
  breacher_break_shield_rate: 0.2,

  vanguard_life_true: 500,
  vanguard_shield_resist: 0.1,
  vanguard_life_resist: 0.08,
  vanguard_duration: 1.5,

  sharpshooter_prob: 0.2,
  sharpshooter_rpm: 0.2,
  sharpshooter_damage: 0.3,
  sharpshooter_crit_prob: 0.15,

  medic_heal: 0.2,
  medic_damage: 0.06,
  medic_life_regen_true: 30,
  medic_additional_shield: 200,

  attachables_damage_true: 100,
  throwables_radius: 0.1,
  throwables_blast_delay: 0.3,

  common_damage: 0.1,
  common_damage_last_shot: 0.5,
  common_shield_damage: 0.15,
  common_damage_regist: 0.1,
  common_evasion: 0.1,
  common_heal: 0.1,
  common_acc: 0.1,
  common_duration: 1,
  common_prob: 0.15,
  common_crit_prob: 0.1,
  common_ammo_on_kill: 0.3,
  common_life_true: 150,
  common_decr_evasion: 0.1,
  common_decr_accuracy: 0.1,

  damage_resist: 0.1,
};

export const perk2_tactical_bonus_display = {
  pointman_acc: 20,
  pointman_rpm: 5,
  pointman_stat_all: 10,
  pointman_shield_damage: 20,
  pointman_life_damage: 20,
  pointman_aim_speed: 50,

  breacher_damage_resist: 10,
  breacher_reload_mult: 25,
  breacher_duration: 1,
  breacher_temp_shield_true: 15,
  breacher_break_shield_rate: 20,

  vanguard_life_true: 500,
  vanguard_shield_resist: 10,
  vanguard_life_resist: 8,
  vanguard_duration: 1.5,

  sharpshooter_prob: 20,
  sharpshooter_rpm: 20,
  sharpshooter_damage: 30,
  sharpshooter_crit_prob: 15,

  medic_heal: 20,
  medic_damage: 6,
  medic_life_regen_true: 30,
  medic_additional_shield: 200,

  attachables_damage_true: 100,
  throwables_radius: 10,
  throwables_blast_delay: 0.3,

  common_damage: 10,
  common_damage_last_shot: 50,
  common_shield_damage: 15,
  common_damage_regist: 10,
  common_evasion: 10,
  common_heal: 10,
  common_acc: 10,
  common_duration: 1,
  common_prob: 15,
  common_crit_prob: 10,
  common_ammo_on_kill: 30,
  common_life_true: 150,
  common_decr_evasion: 10,
  common_decr_accuracy: 10,

  damage_resist: 10,
};

export const perk2_tactical_bonus_types = {
  // pointman perks
  pointman_acc: [
    "perk2_pointman_manoeuvre_shooting",
    "perk2_pointman_manoeuvre_shooting_2",
    "perk2_pointman_manoeuvre_shooting_3",
  ],
  pointman_rpm: [
    "perk2_pointman_battle_excite",
    "perk2_pointman_battle_excite_2",
    "perk2_pointman_battle_excite_3",
    "perk2_pointman_blood_rage",
    "perk2_pointman_blood_rage_2",
    "perk2_pointman_blood_rage_3",
    "perk2_pointman_multitarget_engagement",
    "perk2_pointman_multitarget_engagement_2",
    "perk2_pointman_multitarget_engagement_3",
  ],
  pointman_stat_all: [
    "perk2_pointman_spearhead",
    "perk2_pointman_spearhead_2",
    "perk2_pointman_spearhead_3",
  ],
  pointman_shield_damage: [
    "perk2_pointman_smg_specialization",
    "perk2_pointman_smg_specialization_2",
    "perk2_pointman_smg_specialization_3",
  ],
  pointman_life_damage: [
    "perk2_pointman_ar_specialization",
    "perk2_pointman_ar_specialization_2",
    "perk2_pointman_ar_specialization_3",
  ],
  pointman_aim_speed: [
    "perk2_common_shooting_quickdraw",
    "perk2_common_shooting_quickdraw_2",
    "perk2_common_shooting_quickdraw_3",
  ],

  // vanguard perks
  vanguard_life_true: [
    "perk2_vanguard_physical_training",
    "perk2_vanguard_physical_training_2",
    "perk2_vanguard_physical_training_3",
  ],
  vanguard_shield_resist: [
    "perk2_vanguard_shield_mastery",
    "perk2_vanguard_shield_mastery_2",
    "perk2_vanguard_shield_mastery_3",
  ],
  vanguard_life_resist: [
    "perk2_vanguard_muscle_bulletproof",
    "perk2_vanguard_muscle_bulletproof_2",
    "perk2_vanguard_muscle_bulletproof_3",
  ],
  vanguard_duration: [
    "perk2_common_physical_power_overwhelming",
    "perk2_common_physical_power_overwhelming_2",
    "perk2_common_physical_power_overwhelming_3",
    "perk2_vanguard_full_efficiency",
    "perk2_vanguard_full_efficiency_2",
    "perk2_vanguard_full_efficiency_3",
  ],

  // breacher perks
  breacher_damage_resist: [
    "perk2_breacher_frontline",
    "perk2_breacher_frontline_2",
    "perk2_breacher_frontline_3",
  ],
  breacher_reload_mult: [
    "perk2_breacher_quadloading",
    "perk2_breacher_quadloading_2",
    "perk2_breacher_quadloading_3",
  ],
  breacher_duration: [
    "perk2_breacher_shock_and_awe",
    "perk2_breacher_shock_and_awe_2",
    "perk2_breacher_shock_and_awe_3",
  ],
  breacher_temp_shield_true: [
    "perk2_breacher_shield_regen",
    "perk2_breacher_shield_regen_2",
    "perk2_breacher_shield_regen_3",
  ],
  breacher_break_shield_rate: [
    "perk2_breacher_shield_disable",
    "perk2_breacher_shield_disable_2",
    "perk2_breacher_shield_disable_3",
  ],

  // sharpshooter perks
  sharpshooter_prob: [
    "perk2_sharpshooter_aim_down_sights",
    "perk2_sharpshooter_aim_down_sights_2",
    "perk2_sharpshooter_aim_down_sights_3",
  ],
  sharpshooter_rpm: [
    "perk2_sharpshooter_shoot_mastery",
    "perk2_sharpshooter_shoot_mastery_2",
    "perk2_sharpshooter_shoot_mastery_3",
  ],
  sharpshooter_damage: [
    "perk2_sharpshooter_high_grain",
    "perk2_sharpshooter_high_grain_2",
    "perk2_sharpshooter_high_grain_3",
    "perk2_sharpshooter_high_velocity",
    "perk2_sharpshooter_high_velocity_2",
    "perk2_sharpshooter_high_velocity_3",
    "perk2_sharpshooter_snipe_mastery",
    "perk2_sharpshooter_snipe_mastery_2",
    "perk2_sharpshooter_snipe_mastery_3",
  ],
  sharpshooter_crit_prob: [
    "perk2_sharpshooter_ads_mastery",
    "perk2_sharpshooter_ads_mastery_2",
    "perk2_sharpshooter_ads_mastery_3",
  ],
  sharpshooter_decr_speed: [
    "perk2_sharpshooter_threat",
    "perk2_sharpshooter_threat_2",
    "perk2_sharpshooter_threat_3",
  ],

  // medic perks
  medic_heal: [
    "perk2_medic_paramedics_elementary",
    "perk2_medic_paramedics_elementary_2",
    "perk2_medic_paramedics_elementary_3",
  ],
  medic_damage: [
    "perk2_medic_stimpack",
    "perk2_medic_stimpack_2",
    "perk2_medic_stimpack_3",
  ],
  medic_additional_shield: [
    "perk2_medic_emergency_rescue",
    "perk2_medic_emergency_rescue_2",
    "perk2_medic_emergency_rescue_3",
  ],
  medic_life_regen_true: [
    'perk2_medic_adrenaline',
    'perk2_medic_adrenaline_2',
    'perk2_medic_adrenaline_3',

  ],

  // common perks
  common_evasion: [
    "perk2_common_vision_effective_cover",
    "perk2_common_vision_effective_cover_2",
    "perk2_common_vision_effective_cover_3",
    "perk2_common_mental_survival_instinct",
    "perk2_common_mental_survival_instinct_2",
    "perk2_common_mental_survival_instinct_3",
    "perk2_common_increase_avoid",
    "perk2_common_increase_avoid_2",
    "perk2_common_increase_avoid_3",
    "perk2_common_half_under_mag",
    "perk2_common_half_under_mag_2",
    "perk2_common_half_under_mag_3",
  ],
  common_acc: [
    "perk2_common_increase_acc",
    "perk2_common_increase_acc_2",
    "perk2_common_increase_acc_3",
    "perk2_common_half_over_mag",
    "perk2_common_half_over_mag_2",
    "perk2_common_half_over_mag_3",
    "perk2_common_increase_acc_per_mood",
    "perk2_common_increase_acc_per_mood_2",
    "perk2_common_increase_acc_per_mood_3",
  ],
  common_life_true: [
    "perk2_common_jack_of_all",
    "perk2_common_jack_of_all_2",
    "perk2_common_jack_of_all_3",
  ],
  common_damage: [
    "perk2_common_intelligence_dynamic_entry",
    "perk2_common_intelligence_dynamic_entry_2",
    "perk2_common_intelligence_dynamic_entry_3",
    "perk2_common_increase_dmg",
    "perk2_common_increase_dmg_2",
    "perk2_common_increase_dmg_3",
    "perk2_common_strategy_aggressive_dmg",
    "perk2_common_strategy_aggressive_dmg_2",
    "perk2_common_strategy_aggressive_dmg_3",
    "perk2_common_first_shot_crit",
    "perk2_common_first_shot_crit_2",
    "perk2_common_first_shot_crit_3",
  ],
  common_damage_last_shot: [
    "perk2_common_last_shot_crit",
    "perk2_common_last_shot_crit_2",
    "perk2_common_last_shot_crit_3",
  ],
  common_shield_damage: [
    "perk2_common_shield_breaker",
    "perk2_common_shield_breaker_2",
    "perk2_common_shield_breaker_3",
  ],
  common_damage_regist: [
    "perk2_common_physical_regist",
    "perk2_common_physical_regist_2",
    "perk2_common_physical_regist_3",
    "perk2_common_intelligence_piecut",
    "perk2_common_intelligence_piecut_2",
    "perk2_common_intelligence_piecut_3",
  ],
  common_heal: [
    "perk2_common_physical_healthy_body",
    "perk2_common_physical_healthy_body_2",
    "perk2_common_physical_healthy_body_3",
  ],
  common_prob: [
    "perk2_common_mental_calmstate",
    "perk2_common_mental_calmstate_2",
    "perk2_common_mental_calmstate_3",
    "perk2_common_shooting_precision_shooting",
    "perk2_common_shooting_precision_shooting_2",
    "perk2_common_shooting_precision_shooting_3",
  ],
  common_crit_prob: [
    "perk2_common_mental_kill_or_be_killed",
    "perk2_common_mental_kill_or_be_killed_2",
    "perk2_common_mental_kill_or_be_killed_3",
  ],
  common_ammo_on_kill: [
    "perk2_common_return_ammo_on_kill",
    "perk2_common_return_ammo_on_kill_2",
    "perk2_common_return_ammo_on_kill_3",
  ],
  common_decr_evasion: [
    "perk2_common_strategy_pair_increase_acc",
    "perk2_common_strategy_pair_increase_acc_2",
    "perk2_common_strategy_pair_increase_acc_3",


  ],
  common_decr_accuracy: [
    "perk2_common_strategy_supress_decrease_avoid",
    "perk2_common_strategy_supress_decrease_avoid_2",
    "perk2_common_strategy_supress_decrease_avoid_3",
  ],

  // attachables
  attachables_damage_true: [
    "perk2_breacher_explosive_science",
    "perk2_breacher_explosive_science_2",
    "perk2_breacher_explosive_science_3",
  ],

  // throwables
  throwables_radius: [
    "perk2_common_vision_orbital_mechanics",
    "perk2_common_vision_orbital_mechanics_2",
    "perk2_common_vision_orbital_mechanics_3",
  ],
};

export const perk2_tactical_bonus_member = {
  // 명중률 증가 (%)
  'perk2_pointman_manoeuvre_shooting': 'accuracy_rate_increase',
  'perk2_pointman_manoeuvre_shooting_2': 'accuracy_rate_increase',
  'perk2_pointman_manoeuvre_shooting_3': 'accuracy_rate_increase',
  'perk2_common_increase_acc': 'accuracy_rate_increase',
  'perk2_common_increase_acc_2': 'accuracy_rate_increase',
  'perk2_common_increase_acc_3': 'accuracy_rate_increase',
  'perk2_common_increase_acc_per_mood': 'accuracy_rate_increase',
  'perk2_common_increase_acc_per_mood_2': 'accuracy_rate_increase',
  'perk2_common_increase_acc_per_mood_3': 'accuracy_rate_increase',
  'perk2_common_half_over_mag': 'accuracy_rate_increase',
  'perk2_common_half_over_mag_2': 'accuracy_rate_increase',
  'perk2_common_half_over_mag_3': 'accuracy_rate_increase',

  // 명중률 감소 (%)
  'perk2_common_strategy_supress_decrease_avoid': 'accuracy_rate_reduction',
  'perk2_common_strategy_supress_decrease_avoid_2': 'accuracy_rate_reduction',
  'perk2_common_strategy_supress_decrease_avoid_3': 'accuracy_rate_reduction',

  // RPM 증가 (%)
  'perk2_pointman_multitarget_engagement': 'shootpattern_mult_increase',
  'perk2_pointman_multitarget_engagement_2': 'shootpattern_mult_increase',
  'perk2_pointman_multitarget_engagement_3': 'shootpattern_mult_increase',
  'perk2_pointman_battle_excite': 'shootpattern_mult_increase',
  'perk2_pointman_battle_excite_2': 'shootpattern_mult_increase',
  'perk2_pointman_battle_excite_3': 'shootpattern_mult_increase',
  'perk2_pointman_blood_rage': 'shootpattern_mult_increase',
  'perk2_pointman_blood_rage_2': 'shootpattern_mult_increase',
  'perk2_pointman_blood_rage_3': 'shootpattern_mult_increase',
  'perk2_sharpshooter_shoot_mastery': 'shootpattern_mult_increase',
  'perk2_sharpshooter_shoot_mastery_2': 'shootpattern_mult_increase',
  'perk2_sharpshooter_shoot_mastery_3': 'shootpattern_mult_increase',

  // 1차 스텟 증가 (%)
  'perk2_pointman_spearhead': 'stats_rate',
  'perk2_pointman_spearhead_2': 'stats_rate',
  'perk2_pointman_spearhead_3': 'stats_rate',

  // 재장전 속도 증가 (%)
  'perk2_breacher_quadloading': 'reload_speed_mult',
  'perk2_breacher_quadloading_2': 'reload_speed_mult',
  'perk2_breacher_quadloading_3': 'reload_speed_mult',

  // 이동속도 감소 (%)
  'perk2_sharpshooter_threat': 'speed_rate_reduction',
  'perk2_sharpshooter_threat_2': 'speed_rate_reduction',
  'perk2_sharpshooter_threat_3': 'speed_rate_reduction',

  // 가젯 수 (대입)
  'perk2_breacher_efficient_explosive': 'num_gadgets',
  'perk2_medic_paramedics_intermediate': 'num_gadgets',

  // 회복량 증가 (%)
  'perk2_medic_paramedics_elementary': 'heal_rate',
  'perk2_medic_paramedics_elementary_2': 'heal_rate',
  'perk2_medic_paramedics_elementary_3': 'heal_rate',
  'perk2_common_physical_healthy_body': 'heal_rate',
  'perk2_common_physical_healthy_body_2': 'heal_rate',
  'perk2_common_physical_healthy_body_3': 'heal_rate',

  // 회복량 증가 (정수)
  'perk2_medic_adrenaline': 'heal_amount',
  'perk2_medic_adrenaline_2': 'heal_amount',
  'perk2_medic_adrenaline_3': 'heal_amount',

  // 실드 증가 (정수)
  'perk2_medic_emergency_rescue': 'shield_amount',
  'perk2_medic_emergency_rescue_2': 'shield_amount',
  'perk2_medic_emergency_rescue_3': 'shield_amount',
  'perk2_breacher_shield_regen': 'shield_amount',
  'perk2_breacher_shield_regen_2': 'shield_amount',
  'perk2_breacher_shield_regen_3': 'shield_amount',

  // 최대실드 비례피해 (%)
  'perk2_breacher_shield_disable': 'shield_break_rate',
  'perk2_breacher_shield_disable_2': 'shield_break_rate',
  'perk2_breacher_shield_disable_3': 'shield_break_rate',

  'perk2_medic_emergency_rescue': 'shield',
  'perk2_medic_emergency_rescue_2': 'shield',
  'perk2_medic_emergency_rescue_3': 'shield',
  'perk2_breacher_shield_regen': 'shield',
  'perk2_breacher_shield_regen_2': 'shield',
  'perk2_breacher_shield_regen_3': 'shield',

  'perk2_medic_stimpack': 'damage_mult',
  'perk2_medic_stimpack_2': 'damage_mult',
  'perk2_medic_stimpack_3': 'damage_mult',
  'perk2_medic_stimpack': 'penetrate_mult',
  'perk2_medic_stimpack_2': 'penetrate_mult',
  'perk2_medic_stimpack_3': 'penetrate_mult',
  'perk2_breacher_shield_disable': 'penetrate_mult',
  'perk2_breacher_shield_disable_2': 'penetrate_mult',
  'perk2_breacher_shield_disable_3': 'penetrate_mult',

  'perk2_medic_adrenaline': 'heal_amount',
  'perk2_medic_adrenaline_2': 'heal_amount',
  'perk2_medic_adrenaline_3': 'heal_amount',

  'perk2_medic_paramedics_advanced': '',
  'perk2_vanguard_mozambique_drill': '',
  'perk2_sharpshooter_headshot': '',
  'perk2_sharpshooter_weapon_master': '',
  'perk2_common_vision_intuition': '',
  'perk2_common_intelligence_damage_control': '',
  'perk2_common_shooting_tactical_reload': '',
  'perk2_vanguard_shield_repair': '',
  'perk2_common_ar_mastery': '',
  'perk2_common_smg_mastery': '',
  'perk2_common_sg_mastery': '',

  // 최대체력 증가 (정수)
  'perk2_vanguard_physical_training': 'life_amount',
  'perk2_vanguard_physical_training_2': 'life_amount',
  'perk2_vanguard_physical_training_3': 'life_amount',
  'perk2_common_jack_of_all': 'life_amount',
  'perk2_common_jack_of_all_2': 'life_amount',
  'perk2_common_jack_of_all_3': 'life_amount',

  // 최대 실드 증가 (%)
  'perk2_vanguard_effective_armor': 'shield_mult',
  'perk2_vanguard_effective_armor_2': 'shield_mult',
  'perk2_vanguard_effective_armor_3': 'shield_mult',

  // 추가피해 (정수)
  'perk2_breacher_explosive_science': 'additional_atk_damage_amount',
  'perk2_breacher_explosive_science_2': 'additional_atk_damage_amount',
  'perk2_breacher_explosive_science_3': 'additional_atk_damage_amount',

  // 모든 피해 증가 (%)
  'perk2_medic_stimpack': 'all_atk_damage_rate_increase',
  'perk2_medic_stimpack_2': 'all_atk_damage_rate_increase',
  'perk2_medic_stimpack_3': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_snipe_mastery': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_snipe_mastery_2': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_snipe_mastery_3': 'all_atk_damage_rate_increase',
  'perk2_common_intelligence_dynamic_entry': 'all_atk_damage_rate_increase',
  'perk2_common_intelligence_dynamic_entry_2': 'all_atk_damage_rate_increase',
  'perk2_common_intelligence_dynamic_entry_3': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_grain': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_grain_2': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_grain_3': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_velocity': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_velocity_2': 'all_atk_damage_rate_increase',
  'perk2_sharpshooter_high_velocity_3': 'all_atk_damage_rate_increase',
  'perk2_common_increase_dmg': 'all_atk_damage_rate_increase',
  'perk2_common_increase_dmg_2': 'all_atk_damage_rate_increase',
  'perk2_common_increase_dmg_3': 'all_atk_damage_rate_increase',
  'perk2_common_strategy_aggressive_dmg': 'all_atk_damage_rate_increase',
  'perk2_common_strategy_aggressive_dmg_2': 'all_atk_damage_rate_increase',
  'perk2_common_strategy_aggressive_dmg_3': 'all_atk_damage_rate_increase',
  'perk2_common_first_shot_crit': 'all_atk_damage_rate_increase',
  'perk2_common_first_shot_crit_2': 'all_atk_damage_rate_increase',
  'perk2_common_first_shot_crit_3': 'all_atk_damage_rate_increase',
  'perk2_common_last_shot_crit': 'all_atk_damage_rate_increase',
  'perk2_common_last_shot_crit_2': 'all_atk_damage_rate_increase',
  'perk2_common_last_shot_crit_3': 'all_atk_damage_rate_increase',

  // 실드 피해 증가 (%)
  'perk2_pointman_smg_specialization': 'shield_atk_damage_rate_increase',
  'perk2_pointman_smg_specialization_2': 'shield_atk_damage_rate_increase',
  'perk2_pointman_smg_specialization_3': 'shield_atk_damage_rate_increase',
  'perk2_common_shield_breaker': 'shield_atk_damage_rate_increase',
  'perk2_common_shield_breaker_2': 'shield_atk_damage_rate_increase',
  'perk2_common_shield_breaker_3': 'shield_atk_damage_rate_increase',

  // 체력 피해 증가 (%)
  'perk2_pointman_ar_specialization': 'life_atk_damage_rate_increase',
  'perk2_pointman_ar_specialization_2': 'life_atk_damage_rate_increase',
  'perk2_pointman_ar_specialization_3': 'life_atk_damage_rate_increase',

  // 발동률 (%)
  'perk2_sharpshooter_aim_down_sights': 'activation_prob',
  'perk2_sharpshooter_aim_down_sights_2': 'activation_prob',
  'perk2_sharpshooter_aim_down_sights_3': 'activation_prob',
  'perk2_common_mental_calmstate': 'activation_prob',
  'perk2_common_mental_calmstate_2': 'activation_prob',
  'perk2_common_mental_calmstate_3': 'activation_prob',
  'perk2_common_shooting_precision_shooting': 'activation_prob',
  'perk2_common_shooting_precision_shooting_2': 'activation_prob',
  'perk2_common_shooting_precision_shooting_3': 'activation_prob',

  // 폭발 범위 (%)
  'perk2_common_vision_orbital_mechanics': 'blast_radius_mult',
  'perk2_common_vision_orbital_mechanics_2': 'blast_radius_mult',
  'perk2_common_vision_orbital_mechanics_3': 'blast_radius_mult',

  // 모든 피해 경감 (%)
  'perk2_common_physical_regist': 'all_damage_reduction',
  'perk2_common_physical_regist_2': 'all_damage_reduction',
  'perk2_common_physical_regist_3': 'all_damage_reduction',
  'perk2_breacher_frontline': 'all_damage_reduction',
  'perk2_breacher_frontline_2': 'all_damage_reduction',
  'perk2_breacher_frontline_3': 'all_damage_reduction',
  'perk2_common_intelligence_piecut': 'all_damage_reduction',
  'perk2_common_intelligence_piecut_2': 'all_damage_reduction',
  'perk2_common_intelligence_piecut_3': 'all_damage_reduction',

  // 실드 피해 경감 (%)
  'perk2_vanguard_shield_mastery': 'shield_damage_reduction',
  'perk2_vanguard_shield_mastery_2': 'shield_damage_reduction',
  'perk2_vanguard_shield_mastery_3': 'shield_damage_reduction',

  // 체력 피해 경감 (%)
  'perk2_vanguard_muscle_bulletproof': 'life_damage_reduction',
  'perk2_vanguard_muscle_bulletproof_2': 'life_damage_reduction',
  'perk2_vanguard_muscle_bulletproof_3': 'life_damage_reduction',

  // 유지시간 (s)
  'perk2_common_physical_power_overwhelming': 'duration_seconds',
  'perk2_common_physical_power_overwhelming_2': 'duration_seconds',
  'perk2_common_physical_power_overwhelming_3': 'duration_seconds',
  'perk2_vanguard_full_efficiency': 'duration_seconds',
  'perk2_vanguard_full_efficiency_2': 'duration_seconds',
  'perk2_vanguard_full_efficiency_3': 'duration_seconds',
  'perk2_breacher_shock_and_awe': 'duration_seconds',
  'perk2_breacher_shock_and_awe_2': 'duration_seconds',
  'perk2_breacher_shock_and_awe_3': 'duration_seconds',

  // 회피 증가 (%)
  'perk2_common_vision_effective_cover': 'evasion_rate_increase',
  'perk2_common_vision_effective_cover_2': 'evasion_rate_increase',
  'perk2_common_vision_effective_cover_3': 'evasion_rate_increase',
  'perk2_common_mental_survival_instinct': 'evasion_rate_increase',
  'perk2_common_mental_survival_instinct_2': 'evasion_rate_increase',
  'perk2_common_mental_survival_instinct_3': 'evasion_rate_increase',
  "perk2_common_increase_avoid": 'evasion_rate_increase',
  "perk2_common_increase_avoid_2": 'evasion_rate_increase',
  "perk2_common_increase_avoid_3": 'evasion_rate_increase',
  "perk2_common_half_under_mag": 'evasion_rate_increase',
  "perk2_common_half_under_mag_2": 'evasion_rate_increase',
  "perk2_common_half_under_mag_3": 'evasion_rate_increase',

  // 회피 감소 (%)
  "perk2_common_strategy_pair_increase_acc": 'evasion_rate_reduction',
  "perk2_common_strategy_pair_increase_acc_2": 'evasion_rate_reduction',
  "perk2_common_strategy_pair_increase_acc_3": 'evasion_rate_reduction',

  // 치명률 (%)
  'perk2_common_mental_kill_or_be_killed': 'crit_prob',
  'perk2_common_mental_kill_or_be_killed_2': 'crit_prob',
  'perk2_common_mental_kill_or_be_killed_3': 'crit_prob',
  'perk2_sharpshooter_ads_mastery': 'crit_prob',
  'perk2_sharpshooter_ads_mastery_2': 'crit_prob',
  'perk2_sharpshooter_ads_mastery_3': 'crit_prob',

  // 폭발 지연시간 감소 (s)
  'perk2_common_intelligence_grenade_cooking': 'blast_delay_seconds',
  'perk2_common_intelligence_grenade_cooking_2': 'blast_delay_seconds',
  'perk2_common_intelligence_grenade_cooking_3': 'blast_delay_seconds',

  // 조준속도 증가 (%)
  'perk2_common_shooting_quickdraw': 'aimspeed_mult',
  'perk2_common_shooting_quickdraw_2': 'aimspeed_mult',
  'perk2_common_shooting_quickdraw_3': 'aimspeed_mult',

  // 장탄 수 회복 (%)
  "perk2_common_return_ammo_on_kill": 'ammo_mult',
  "perk2_common_return_ammo_on_kill_2": 'ammo_mult',
  "perk2_common_return_ammo_on_kill_3": 'ammo_mult',

  "perk2_common_strategy_pair_increase_acc": 'evasion_mult',
  "perk2_common_strategy_pair_increase_acc": 'evasion_mult_2',
  "perk2_common_strategy_pair_increase_acc": 'evasion_mult_3',
};