import source from '../downloaded/training3.json' assert { type: 'json' };

export const training2 = [];
for (const row of source) {
  const { key, name, decision, bravery, focus, reaction, toughness, precision } = row;
  training2.push({
    key,
    name,
    decision: parseFloat(decision) || 0,
    bravery: parseFloat(bravery) || 0,
    focus: parseFloat(focus) || 0,
    reaction: parseFloat(reaction) || 0,
    toughness: parseFloat(toughness) || 0,
    precision: parseFloat(precision) || 0,
  });
}

export function training2Bykey(key) { return training2.find((p) => p.key === key); }
