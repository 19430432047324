import source from '../downloaded/agentConditions.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { idx, more_or_equal, prob_train_success, less, name } = row;
  list.push({
    idx,
    min_condition: more_or_equal,
    max_condition: less,
    prob_train_success: parseFloat(prob_train_success),
    name,
  });
}

export function getConditionData(condition) {
  const data = list.find((e) => e.min_condition <= condition && e.max_condition > condition);
  return data ?? list[0];
}
