import source from '../downloaded/forces.json' assert { type: 'json' };

export const regions = {};
for (const row of source) {
  const {
    name,
    tier,
    force_0_weight,
    force_1_ty,
    force_1_weight,
    force_2_ty,
    force_2_weight,
    force_3_ty,
    force_3_weight,
  } = row;

  const forces = [];
  forces.push({
    tier: 1,
    name: '자경단',
    weight: 0 | force_0_weight,
  });
  forces.push({
    tier: 1,
    name: force_1_ty,
    weight: 0 | force_1_weight,
  });

  if (force_2_ty) {
    forces.push({
      tier: 2,
      name: force_2_ty,
      weight: 0 | force_2_weight,
    });
  }

  if (force_3_ty) {
    forces.push({
      tier: 2,
      name: force_3_ty,
      weight: 0 | force_3_weight,
    });
  }

  regions[name] = {
    name,
    tier: 0 | tier,
    forces,
  };
}
