import React from 'react';

import { Rng } from './rand';

const CELL_SIZE = 50;

const COLORS = [
  "#FF0000",  // Red
  "#00FF00",  // Lime
  "#0000FF",  // Blue
  "#FFFF00",  // Yellow
  "#00FFFF",  // Cyan/Aqua
  "#FF00FF",  // Magenta/Fuchsia
  "#C0C0C0",  // Silver
  "#808080",  // Gray
  "#800000",  // Maroon
  "#808000",  // Olive
  "#008000",  // Green
  "#800080",  // Purple
  "#008080",  // Teal
  "#000080",  // Navy
  "#FFA500",  // Orange
  "#A52A2A",  // Brown
  "#8B4513",  // SaddleBrown
  "#5F9EA0",  // CadetBlue
  "#D2691E",  // Chocolate
  "#9ACD32",  // YellowGreen
  "#7FFF00",  // Chartreuse
  "#D8BFD8",  // Thistle
  "#FF6347",  // Tomato
  "#40E0D0",  // Turquoise
  "#EE82EE",  // Violet
  "#F5DEB3",  // Wheat
  "#FFFFFF",  // White
  "#F0E68C",  // Khaki
  "#ADFF2F",  // GreenYellow
  "#FFB6C1",  // LightPink
  "#20B2AA",  // LightSeaGreen
  "#87CEEB"   // SkyBlue
];

function partition(rng, top, left, bottom, right, depth) {
  let w = right - left;
  let h = bottom - top;
  if (depth === 0 || w < 4 || h < 4 || w * h < 10) {
    return [{ top, left, bottom, right }];
  }

  let sx = rng.integer(2, w - 2);
  let sy = rng.integer(2, h - 2);

  let partitions = [];
  partitions = partitions.concat(partition(rng, top, left, top + sy, left + sx, depth - 1));
  partitions = partitions.concat(partition(rng, top + sy, left, bottom, left + sx, depth - 1));
  partitions = partitions.concat(partition(rng, top, left + sx, top + sy, right, depth - 1));
  partitions = partitions.concat(partition(rng, top + sy, left + sx, bottom, right, depth - 1));
  return partitions;
}

export class GridView extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    const width = 20;
    const height = 20;
    const rng = new Rng();

    const partitions = partition(rng, 0, 0, height, width, 3);

    let idx = 0;
    for (const partition of partitions) {
      if (rng.range(0, 1) < 0.2) {
        partition.idx = idx++;
      } else {
        partition.idx = 0;
      }
    }

    this.state = {
      width,
      height,
      partitions,
    };
  }

  componentDidMount() {
    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  renderCanvas() {
    const { width, height, partitions } = this.state;

    const ctx = this.canvasRef.current.getContext('2d');

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width * CELL_SIZE, height * CELL_SIZE);

    const margin = 4;
    const margin1 = 1;
    if (false) {
      const margin = 1;
      for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
          ctx.fillStyle = 'gray';
          ctx.fillRect(i * CELL_SIZE + margin, j * CELL_SIZE + margin, CELL_SIZE - margin * 2, CELL_SIZE - margin * 2);
        }
      }
    }

    ctx.globalAlpha = 0.5;
    for (let i = 0; i < partitions.length; i++) {
      const { top, left, bottom, right, idx } = partitions[i];

      if (idx !== 0) {
        ctx.fillStyle = COLORS[idx % COLORS.length];
      } else {
        ctx.fillStyle = 'gray';
      }

      for (let x = left; x < right; x++) {
        for (let y = top; y < bottom; y++) {
          let cx = Math.max(left * CELL_SIZE + margin, x * CELL_SIZE + margin1);
          let cy = Math.max(top * CELL_SIZE + margin, y * CELL_SIZE + margin1);
          let w = Math.min(right * CELL_SIZE - margin - cx, (x + 1) * CELL_SIZE - margin1 * 2 - cx);
          let h = Math.min(bottom * CELL_SIZE - margin - cy, (y + 1) * CELL_SIZE - margin1 * 2 - cy);

          ctx.fillRect( cx, cy, w, h);

          /*
          ctx.fillRect(
            left * CELL_SIZE + margin,
            top * CELL_SIZE + margin,
            (right - left) * CELL_SIZE - margin * 2,
            (bottom - top) * CELL_SIZE - margin * 2,
          );
          */
        }
      }

      ctx.fillRect(
        left * CELL_SIZE + margin,
        top * CELL_SIZE + margin,
        (right - left) * CELL_SIZE - margin * 2,
        (bottom - top) * CELL_SIZE - margin * 2,
      );
    }
    ctx.globalAlpha = 1;
  }

  render() {
    const { width, height } = this.state;

    return <div>
      <canvas ref={this.canvasRef}
        width={width * CELL_SIZE}
        height={height * CELL_SIZE}
      />
    </div>;
  }
}
