import source from '../downloaded/agentMoods.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { idx, more_or_equal, less, name, train_effect, stats_mult, prob_train_great, prob_train_bad } = row;
  list.push({
    idx,
    min_condition: more_or_equal,
    max_condition: less,
    name,
    train_effect,
    stats_mult,
    prob_train_great: parseFloat(prob_train_great),
    prob_train_bad: parseFloat(prob_train_bad),
  });
}

export function getMoodData(mood) {
  const data = list.find((e) => e.min_condition <= mood && e.max_condition > mood);
  return data ?? list[0];
}
