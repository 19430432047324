import source from '../downloaded/operatorExp.json' assert { type: 'json' };

export const operatorExp = [];
for (const row of source) {
  const { Lv, expMax } = row;
  operatorExp.push({
    Lv: parseInt(Lv),
    expMax: parseInt(expMax),
  });
}
