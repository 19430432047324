import source from '../downloaded/eventScenario.json' assert { type: 'json' };
import { split } from './parseUtilities.mjs';

export const eventScenarios = [];
for (const row of source) {
  let { id, type, status, duration, lead_cond, lead_value, side_num, side0_cond, side0_value, side1_cond, side1_value, event_presets, event_preset_day, } = row;
  eventScenarios.push({
    id,
    type,
    status,
    duration: parseInt(duration),
    lead_cond: split(lead_cond),
    lead_value: split(lead_value),
    side_num: parseInt(side_num),
    side0_cond: split(side0_cond),
    side0_value: split(side0_value),
    side1_cond: split(side1_cond),
    side1_value: split(side1_value),
    event_presets: split(event_presets),
    event_preset_day: split(event_preset_day),
  })
}
