import source from '../downloaded/char2ages.json' assert { type: 'json' };

let {
  ageMax,
  ageMin
} = source[0];

export const ages = {
  ageMax: +ageMax,
  ageMin: +ageMin,
}
