import React, { useState } from 'react';
import _ from 'lodash';

import './SimView.css';

import { opts } from './opts';
import { v2 } from './v2';
import { Rng } from './rand';
import { Simulator, } from './sim';
import { COLORS, COLORS_ALT, renderCanvas } from './simrender';
import { checkcover, routePathfind, routePathfindAll, hostilityNetwork, coverEdges, stats_populate } from './geom';
import { perks } from './perks';
import { StatView } from './StatView';
import { ProgressBar } from './ProgressBar';
import { PortraitWrapper } from './PortraitsView';
import { ordersDescr } from './data/google/processor/data_ordersDescr.mjs';
import { serializeState, mergeState } from './extobj.mjs';
import { SimOverlay } from './SimOverlay.mjs';
import { L, localeGet } from './localization.mjs';
import { parseQuery } from './utils.mjs';
import { conversationsBySchedule } from './data/google/processor/data_conversations.mjs';

import {
  perk2_tactical_bonus,
  perk2_tactical_bonus_types,
  perk2_tactical_bonus_member,
} from './presets_perk2.mjs';
import { SimController } from './SimController.mjs';

const DEBUG_SERIALIZE = true;
const DEBUG_CONVERSATION = true;

const sw = function (msg, f) {
  const start = Date.now();
  const ret = f();
  console.log(`${msg} took ${Date.now() - start}ms`);
  return ret;
}

const clamp = function (x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function formatCoord(coord) {
  return `[${coord.x.toFixed(0)},${coord.y.toFixed(0)}]`;
}

const EntityOverContext = React.createContext({
  entityOver: null,
  onEntityOver: () => { },
});

function EntityName(props) {
  const { entity } = props;
  if (!entity) {
    return '<none>';
  }
  let { name, team } = entity;
  let cls = `entityname-${team}`;
  if (props.className) {
    cls = `${props.className} ${cls}`;
  }

  if (name.indexOf('"') >= 0) {
    name = name.split('"')[1];
  }

  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      if (entity === entityOver) {
        cls += ` entityname-over`;
      }
      return <span className={cls}
        onMouseOver={() => onEntityOver(entity)}
        onMouseLeave={() => onEntityOver(null)}
      >{L(name)}</span>;
    }}
  </EntityOverContext.Consumer>;
}

function EntityNameList(props) {
  const { entities } = props;
  if (!entities || entities.length === 0) {
    return '<empty>';
  }
  return <span>{entities.map((e) => EntityName({ entity: e })).reduce((prev, curr) => [...prev, ', ', curr], [])}</span>;
}

function JournalText(props) {
  const { item } = props;

  if (item.ty === 'aim') {
    return <span><EntityName entity={item.entity} />이(가) 조준합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'unaim') {
    return <span><EntityName entity={item.entity} />이(가) 조준을 해제합니다.</span>;
  } else if (item.ty === 'fire') {
    const descr = item.kill ? '사살' : '사격';
    return <span><EntityName entity={item.entity} />이(가) {descr}합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'discover') {
    return <span><EntityName entity={item.entity} />이(가) 발견합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'perk') {
    const perkinfo = perks[item.perk];
    return <span><EntityName entity={item.entity} />의 특성이 발동합니다: {perkinfo?.msg ?? item.perk}<br />{item.targets && <EntityNameList entities={item.targets} />}</span>;
  } else if (item.ty === 'throw_door') {
    return <span><EntityName entity={item.entity} />이(가) 실내에 진입하며 {item.throwable.throwable_name} 수류탄을 던집니다.</span>;
  } else if (item.ty === 'throw_general') {
    return <span><EntityName entity={item.entity} />이(가) {item.targets.map((t) => t.name).join(',')}을(를) 노리고 {item.throwable.throwable_name} 수류탄을 던집니다.</span>;
  } else if (item.ty === 'throw_kill') {
    return <span><EntityName entity={item.entity} />이(가) 던진 {item.throwable.throwable_name} 수류탄이 <EntityName entity={item.target} />을(를) 무력화합니다.</span>;
  } else if (item.ty === 'throw_effect') {
    return <span><EntityName entity={item.entity} />이(가) 던진 {item.throwable.throwable_name} 수류탄이 <EntityName entity={item.target} />을(를) {item.effect_ty} 상태에 빠뜨립니다.</span>;
  } else if (item.ty === 'heal_start') {
    return <span><EntityName entity={item.entity} />이(가) <EntityName entity={item.target} />을(를) 치료합니다.</span>;
  } else if (item.ty === 'heal_self') {
    return <span><EntityName entity={item.entity} />이(가) 자기 자신을 치료합니다.</span>;
  } else if (item.ty === 'heal_risk') {
    return <span><EntityName entity={item.entity} />이(가) <EntityName entity={item.target} />을(를) 철저하게 치료하기 위해 일단 상처를 벌립니다.</span>;
  } else {
    console.error('unknown journal ty', item.ty);
  }
}

function JournalItem(props) {
  const { item } = props;

  const totalSeconds = Math.floor(item.tick / opts.tps);
  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60);
  const pad = (n) => n < 10 ? `0${n}` : `${n}`;
  const timestampStr = `${pad(minutes)}:${pad(seconds)}`;
  return <div>{timestampStr}: <JournalText item={item} />{item.reps ? 'x' + item.reps : ''}</div>;
}

function EntityView(props) {
  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      const { entity } = props;

      let cls = 'sim-overlay-entity box';
      if (entityOver === entity) {
        cls += ' entity-over';
      }
      if (entity.state === 'dead') {
        cls += ' entity-dead';
      }

      let extra = '';
      if (entity.leader) {
        extra += ` leader=${L(entity.leader.name)}`;
      }
      if (!isNaN(entity.risk_rank)) {
        extra += ` rr=${entity.risk_rank}`;
      }

      return <div className={cls}
        onMouseOver={() => onEntityOver(entity)}
        onMouseLeave={() => onEntityOver(null)}>
        <div className="sim-overlay-entity-img">
          <img alt="" className="sim-overlay-entity-img-background" src="/img/overlay/Merc_Idle.png" />
          {entityOver === entity ? (
            <div className="sim-overlay-entity-img-hover">
              <img alt="" src="/img/overlay/Merc_Selected.png" />
            </div>
          ) : null}
          <PortraitWrapper agent={{ name: entity.name, role: entity.role }} className="list-portrait sim-overlay-entity-img-icon" />,
        </div>
        <div className="sim-overlay-entity-detail">
          <EntityName entity={entity} /> {entity.throwables && entity.throwables.length > 0 && <span>Throwables: {entity.throwables.length}</span>}
          <br />
          <ProgressBar cur={entity.life} max={entity.life_max} bgcolor='#ff4646' fgcolor='black' width={75} />
          <ProgressBar cur={entity.shield} max={entity.shield_max} bgcolor='#99a837' fgcolor='black' width={75} />
          <ProgressBar cur={entity.ammo} max={entity.firearm_ammo_max} bgcolor='#ffe400' fgcolor='black' width={50} />
          <br />
          {entity.firearm_ty} 사격대상=<EntityName entity={entity.aimtarget} />
          {extra}
          <br />
          {entity.firearm ? `잔탄 : ${entity.firearm.ammo_total > 10000 ? '∞' : entity.firearm.ammo_total}` : null}
        </div>
      </div>;
      // {entity.state}/{entity.movestate}/{entity.waypoint_rule.ty}
    }}
  </EntityOverContext.Consumer>;
}

function EntityDebugView(props) {
  const { sim, entity, debugRng } = props;
  const { onDebugCover, onDebugRoute, onDebugScore, onDebugDist, onDebugMST, onDebugEdge, onDebugTurn, onDebugReroute } = props;
  let dir = Math.floor(entity.dir * 180 / Math.PI).toString();

  let waypoint_dist = 0;
  let at_waypoint = false;
  if (entity.waypoint) {
    at_waypoint = entity.pos.eq(entity.waypoint.pos);
    waypoint_dist = entity.pos.dist(entity.waypoint.pos) / 10;
  }

  const printrules = entity.rules.map((r) => {
    const obj = { ty: r.ty };

    for (const key of ['area', 'target', 'initiator', 'goal', 'object']) {
      if (r[key]) {
        obj[key] = r[key].name;
      }
    }
    return obj;
  });
  printrules.reverse();

  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      let cls = 'box';
      if (entityOver === entity) {
        cls += ' entity-over';
      }

      return <div className={cls} onMouseOver={() => onEntityOver(entity)}>
        <EntityName entity={entity} /> {entity.team} {entity.waypoint_rule.ty}/{entity.state} {formatCoord(entity.pos)} dir={dir}
        <br />
        life={entity.life} ammo={entity.ammo} speed={entity.movespeed.toFixed(2)} aim={entity.aimmult.toFixed(3)}
        /{entity.aimvar.toFixed(3)}
        /{L(entity.aimtarget?.name ?? 'none')} aimvar={entity.aimvar_hold.toFixed(5)}
        /{entity.aimvar_hold_max.toFixed(5)} fire={entity.allow_fire_control ? 'allowed' : 'not allowed'}
        /{sim.entityFireControl(entity) ? 'controlled' : 'not controlled'}
        /{sim.entityFireControlReady(entity, debugRng) ? 'ready' : 'not ready'}
        /{entity.allow_door_wait ? 'dw' : 'ndw'}
        <br />
        armor={entity.armor} shield={entity.shield}
        <br />
        perks
        {Object.keys(entity).filter((k) => k.startsWith('perk2_')).filter((k) => entity[k]).map((k, i) => <div key={i}>- {k.slice(6)}</div>)}
        <br />
        {printrules.map((r, i) => <p key={i}>{JSON.stringify(r)}</p>)}
        <div>
          debug {`waypoint=${waypoint_dist.toFixed(1)}m/${at_waypoint}`}
          <button onClick={() => onDebugCover(entity)}>cover</button>
          <button onClick={() => onDebugRoute(entity)}>route</button>
          <button onClick={() => onDebugScore(entity)}>score</button>
          <button onClick={() => onDebugDist(entity)}>dist</button>
          <button onClick={() => onDebugMST(entity)}>mst</button>
          <button onClick={() => onDebugEdge(entity)}>edge</button>
          <button onClick={() => onDebugTurn(entity)}>turn</button>
          <button onClick={() => onDebugReroute(entity)}>reroute</button>
        </div>
        {entity._stat ? 'stats= ' + JSON.stringify(entity._stat, null, ' ') : null}
      </div>;
    }}
  </EntityOverContext.Consumer>;
}

function GoalView(props) {
  const { goal } = props;
  return <div className="box">
    {goal.name}: {JSON.stringify(goal.goalstate)}
  </div>;
}

const SCALES = [4, 2, 1];

// direct access to sim object
export class DebugSimView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      debugRng: new Rng(),

      debugScore: [],
      debugDist: [],
      debugScoreProp: 'score',
      debugScoreRule: 'covergoal',
    };
  }

  onDebugCover(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { sim } = this.props;

    const cover = checkcover(entity.aimtarget.pos, entity.pos, sim.routes);
    console.log('cover', cover, entity);
  }

  onDebugRoute(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { routes } = this.state;

    let cp = null;
    if (entity.waypoint_rule.ty === 'fire') {
      cp = this.choosefirepoint(entity.aimtarget);
    } else {
      cp = this.choosecoverpoint(entity);
    }

    if (cp === null) {
      console.log('onDebugRoute: cp === null');
      return;
    }

    const atcp = cp?.pos.eq(entity.pos);

    const path = routePathfind(routes, entity.pos, cp.pos, null, true);
    console.log('onDebugRoute', entity, cp, path, `atcp=${atcp}`);
  }

  onDebugScore(entity) {
    const { sim } = this.props;
    const { debugScoreRule } = this.state;
    // const items = this.choosefirepoint(entity.aimtarget, this.debugchoosepoint.bind(this));
    let items = [];
    if (!debugScoreRule) {
      console.error(`debugScoreRule not specified`);
      return;
    }

    const f = sim.debugchoosepoint.bind(sim);
    if (debugScoreRule === 'cover') {
      items = sim.choosecoverpoint(entity, 1000000, f);
    } else if (debugScoreRule === 'capture') {
      items = sim.choosecapturepoint(entity, null, f);
    } else if (debugScoreRule === 'covergoal') {
      items = sim.choosecovergoalpoint(entity, null, f);
    } else {
      const fname = `choose${debugScoreRule}point`;
      if (sim[fname]) {
        items = (sim[fname].bind(sim))(entity, f);
        console.log(items);
      } else {
        console.error(`unknown function: ${fname}`);
      }
    }

    this.setState({
      debugScore: items,
    });
  }

  onDebugDist(entity) {
    const { sim } = this.props;
    const dist = routePathfindAll(sim.routes, entity.pos, true);

    this.setState({ debugDist: dist });
  }

  onDebugMST(entity) {
    const { sim } = this.props;

    const out = sw('hostilityNetwork', () => hostilityNetwork(sim.routes, entity.pos));
    this.setState({ debugMST: out });
  }

  onDebugEdge(entity) {
    const { sim } = this.props;

    const out = sw('edge', () => coverEdges(sim.routes, entity.pos));
    this.setState({ debugMST: out });
  }

  onDebugTurn(entity) {
    const { sim } = this.props;
    sim.convertEntity(entity, 0);
  }

  onDebugReroute(entity) {
    const { sim } = this.props;
    sim.entityNextWaypoint0(entity);
  }

  renderDebugPerks() {
    const operatorPerksDebugButton = <button className={opts.DEBUG_APPLY_OPERATOR_PERKS ? 'selected' : ''}
      onClick={() => opts.DEBUG_APPLY_OPERATOR_PERKS = !opts.DEBUG_APPLY_OPERATOR_PERKS}>apply operator perks</button>;
    const statsPerksDebugButton = <button className={opts.DEBUG_APPLY_STATS_PERKS ? 'selected' : ''}
      onClick={() => opts.DEBUG_APPLY_STATS_PERKS = !opts.DEBUG_APPLY_STATS_PERKS}>apply stats perks</button>;

    return <>
      <p>debugperks</p>
      {operatorPerksDebugButton}
      {statsPerksDebugButton}
    </>
  }

  renderDebugScoreRule() {
    const rules = ['fire', 'cover', 'explore', 'capture', 'covergoal'];

    const onDebugScoreRule = (r) => { this.setState({ debugScoreRule: r }); }
    return <>
      <p>debugrule</p>
      {rules.map((p, i) => <button key={i} onClick={() => onDebugScoreRule(p)}>{p}</button>)}
    </>;
  }

  renderDebugScoreProps() {
    const { debugScore } = this.state;
    if (!debugScore) {
      return null;
    }

    let props = [];
    for (const item of debugScore) {
      if (item.query) {
        props = Object.keys(item.query);
        break;
      }
    }

    const onDebugScoreProp = (p) => {
      this.setState({
        debugScoreProp: p,
      });
    }

    return <>
      <p>debugprops</p>
      {props.map((p, i) => <button key={i} onClick={() => onDebugScoreProp(p)}>{p}</button>)}
    </>;
  }

  renderJournal() {
    const { sim, stats } = this.props;
    const { debugOptJournal } = this.state;
    const { journal } = sim;
    if (!debugOptJournal) {
      return null;
    }

    return <div>
      <p>journal</p>
      {journal.map((s, i) => <JournalItem key={i} tick={sim.tick} item={s} />)}

      <p style={{ wordWrap: "break-word" }}>
        stats={JSON.stringify(stats)}
      </p>
    </div>;
  }

  render() {
    const { sim, stats } = this.props;
    const { entityOver, debugRng } = this.state;
    const { entities, goals } = sim;

    return <>
      <div>
        <p>debug</p>
        {this.renderDebugPerks()}
        {this.renderDebugScoreRule()}
        {this.renderDebugScoreProps()}
        {JSON.stringify(stats)}
      </div>
      <div>
        <p>info</p>
        <div className="sim-overlay-info-entities">
          {entities.map((e, i) =>
            <EntityDebugView key={i} entity={e} sim={sim}
              debugRng={debugRng}
              onDebugCover={this.onDebugCover.bind(this)}
              onDebugRoute={this.onDebugRoute.bind(this)}
              onDebugScore={this.onDebugScore.bind(this)}
              onDebugDist={this.onDebugDist.bind(this)}
              onDebugMST={this.onDebugMST.bind(this)}
              onDebugEdge={this.onDebugEdge.bind(this)}
              onDebugTurn={this.onDebugTurn.bind(this)}
              onDebugReroute={this.onDebugReroute.bind(this)}
              entityOver={entityOver}
            />)}
        </div>
        {goals.map((item, i) => <GoalView key={i} idx={i} goal={item} />)}
      </div>
      {this.renderJournal()}
    </>;
  }
}

export function SideSelectView(props) {
  const { options, selected, onSelect, title, ltr } = props;
  const width = props.width ?? '100px';

  const [expand, setExpand] = useState(false);
  const curopt = _.find(options, (o) => o.key === selected);

  const buttons = [];
  {
    let style = {};
    if (curopt.color) {
      style.color = curopt.color;
    }
    buttons.push(<div key='cur' className='side-btn side-btn-selected' style={style} onClick={() => {
      setExpand(!expand);
    }}>{curopt.label}</div>);
  }

  if (expand) {
    let i = 0;
    for (let opt of options) {
      i += 1;
      let cls = `side-btn side-btn-${i}`;
      if (opt.key === selected) {
        cls += ' side-btn-selected';
      }
      let style = {};
      if (opt.color) {
        style.color = opt.color;
      }

      if (opt.disabled) {
        style.backgroundColor = '#ff5555';
        buttons.push(<div key={opt.key} className={cls} style={style} onClick={() => { }}>{opt.label}</div>);
      }
      else {
        buttons.push(<div key={opt.key} className={cls} style={style} onClick={() => {
          onSelect(opt);
          setExpand(false);
        }}>{opt.label}</div>);
      }
    }
  }

  const style = {
    '--side-select-width': width,
  };

  let cls = `side-select-root`;
  if (props.ltr) {
    cls += ` side-select-ltr`;
  }

  const items = [
    <div key='buttons' className='side-select' style={style}>
      {buttons}
    </div>,
    title && <span key='title' className='side-select-title'>{title}</span>,
  ];
  if (ltr) {
    items.reverse();
  }

  return <div className={cls}>
    {items}
  </div>;
}

function MeterView(props) {
  const { entities } = props;

  const trails = props.trails.filter((t) => entities.includes(t.source));
  const stat_rows = entities.map((entity, i) => {
    const trails1 = trails.filter((t) => t.source === entity);
    const kills = trails1.filter((t) => t.kill).length;
    const dealt = _.sum(trails1.map((t) => t.damage));

    return {
      entity,
      kills,
      dealt,
      i,
    };
  });
  stat_rows.sort((a, b) => {
    if (a.dealt !== b.dealt) {
      return b.dealt - a.dealt;
    }
    if (a.kills !== b.kills) {
      return b.kills - a.kills;
    }
    return a.i - b.i;
  });
  const dealt_max = stat_rows[0].dealt;

  return <div>
    {stat_rows.map(({ entity, kills, dealt }, i) => {
      const text = `kills=${kills} dealt=${dealt.toFixed(0)}`;
      return <div key={i} className="sim-overlay-stat-row">
        <EntityName className="sim-overlay-stat-row-name" entity={entity} />
        <ProgressBar cur={dealt} max={dealt_max} width={200} text={text} />
      </div>;
    })}
  </div>;
}

export function SimPolicyControlBase(props) {
  const { ltr, controlSet, segment, features, agents, agentFeaturesFunc } = props;
  let { controls } = props;
  if (Array.isArray(controls)) {
    controls = controls[segment.idx];
  }
  const showlabel = props.showlabel ?? true;

  let actionbtns = [];

  const renderBoolButton = (key, label, opts) => {
    const opt = opts.find((o) => o.value === controls[key]);

    return <SideSelectView title={showlabel ? label : null} options={opts} ltr={ltr}
      selected={opt.key}
      onSelect={(opt) => {
        controlSet(key, opt.value);
      }} />;
  };

  const pushBoolOpt = (key, name, labels) => {
    const opts = [
      { key: `no${key}`, value: false, label: labels[0], color: 'red' },
      { key, value: true, label: labels[1], color: 'blue' },
    ];
    actionbtns.push(renderBoolButton(key, name, opts));
  }

  const firepolicy_opts = [
    { key: 'default', value: 'default', label: '자율 사격', color: 'gray' },
    { key: 'focus', value: 'focus', label: '집중 사격', color: 'gray' },
    { key: 'aggressive', value: 'aggressive', label: '전탄 사격', color: 'gray' },
    { key: 'pair', value: 'pair', label: '교차 사격', color: 'gray' },
    { key: 'control', value: 'control', label: '분산 사격', color: 'gray' },
    { key: 'supress', value: 'supress', label: '제압 사격', color: 'gray' },
  ];

  const firepolicy_selected = firepolicy_opts.find((o) => o.value === controls.firepolicy);

  actionbtns.push(<SideSelectView title='교전 정책' options={firepolicy_opts} ltr={ltr}
    selected={firepolicy_selected.key}
    onSelect={(opt) => {
      controlSet('firepolicy', opt.value);
    }} />);

  // pushBoolOpt('explore', '탐색 정책', ['신속', '수색']);

  if (features.riskdir) {
    pushBoolOpt('riskdir', '전술: 파이컷', ['금지', '허용']);
  }

  const doorentryopts = [
    { key: 'door_entry_false', value: false, label: '안함', color: 'gray' },
    { key: 'door_entry', value: true, label: '사용', color: 'blue' },
  ]
  if ((!segment || segment.doors?.length > 0) && features.door_entry) {
    actionbtns.push(renderBoolButton('door_entry', '전술: 도어 엔트리', doorentryopts));
  }

  if (features.throwable && features.throwable_cooking) {
    pushBoolOpt('throwable_cooking', '전술: 수류탄 쿠킹', ['금지', '허용']);
  }

  return actionbtns;
}

export function SimPolicyControl(props) {
  const { agentControlSet, ltr, agents, agentFeaturesFunc, segment } = props;

  let actionbtns = SimPolicyControlBase(props);

  const showlabel = props.showlabel ?? true;

  const renderAgentBoolButton = (key, label, opts, agent) => {
    const opt = opts.find((o) => o.value === agent.controls[key])
    if (!opt) {
      return null;
    }

    return <SideSelectView title={showlabel ? label : null} options={opts} ltr={ltr}
      selected={opt.key}
      onSelect={(opt) => {
        agentControlSet(agent, key, opt.value);
      }} />;
  }

  const throwopts = [
    { key: 'forbid', value: false, label: '금지', color: 'red' },
    { key: 'allow', value: true, label: '허용', color: 'green' },
  ];
  const healopts = [
    { key: 'forbid', value: -1, label: '금지', color: 'red' },
    { key: 'conservative', value: opts.HEAL_THRES_CONSERVATIVE, label: '보수적', color: 'green' },
    { key: 'aggressive', value: opts.HEAL_THRES_AGGRESSIVE, label: '적극적', color: 'blue' },
  ];

  const throwables = [];
  const heals = [];

  for (let i = 0; i < agents.length; i++) {

    const agent = agents[i];

    const agentFeatures = agentFeaturesFunc(agent);

    if (agentFeatures.throwable) {
      throwables.push(renderAgentBoolButton(`throwable`, `${i + 1}번 용병 투적물 정책`, throwopts, agent));
    }
    if (agentFeatures.attachable) {
      const doors = segment?.doors;
      if (!doors) {
        continue;
      }

      for (const door of doors) {
        const opt_value = agent.controls['attachable'][door] ?? false;
        const opt = throwopts.find((o) => o.value === opt_value);
        if (opt) {
          throwables.push(<SideSelectView title={showlabel ? `${i + 1}번 용병 브리칭 정책 - ${door}` : null} options={throwopts} ltr={ltr}
            selected={opt.key}
            onSelect={(opt) => {
              const attachable = { ...agent.controls['attachable'] };
              attachable[door] = opt.value;
              agentControlSet(agent, 'attachable', attachable);
            }}
          />);
        }
      }
    }
  }

  actionbtns = actionbtns.concat(throwables, heals);

  return <>
    {actionbtns}
  </>;
}

function FileExportView(props) {
  const { embed, simstate, simview } = props;

  const [serialized, setSerialized] = useState(null);

  if (serialized) {
    return <div>
      아래 텍스트박스를 전체선택/복사해서 공유해주세요.
      <textarea value={serialized} readOnly={true} />
    </div>;
  }

  function downloadStringAsFile(filename, content) {
    if (embed) {
      setSerialized(content);
      return;
    }

    var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return <button onClick={() => {
    (async () => {
      if (!simview.download_idx) {
        simview.download_idx = 0;
      }
      const idx = simview.download_idx++;

      const data = JSON.stringify(simstate);
      downloadStringAsFile(`save_${idx}.json`, data);
    })();
  }}>{L('loc_ui_button_system_export_file')}</button>
}
export class SimView extends React.Component {
  static defaultProps = {
    canvasScale: 1.0,
  }

  constructor(props) {
    super(props);
    const { debug } = props;

    this.canvasRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.timer = null;

    this.img_blood = document.createElement('img');
    this.img_blood.src = '/sim/blood.png';

    this.img_tile = document.createElement('img');
    this.img_tile.src = '/sim/Textures-16.png';

    this.img_icons = document.createElement('img');
    this.img_icons.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAA8FJREFUeF7tW+1ywzAIW97/obtLb+4xVSDhOLt07f4t8QcIELKzbV9v/rO9uf9fTwDcbrfbDsq2bS1w2LzZtf4yKCkAXRCGs3Eee1Y5F8dn46rAzMwvAeiAgJvvhrJnCgDloHqvsgfnSwBcEBz01Vr7GspB9f4UAJTh+3sXgGqtSwOgQOgAkK31AeCnA1Vp/O9LoFvDcbyThZcnwcsB4AqiFW1QkaljS5UFbP6lhNCIPnPCcX5m/mWlcFdFYum481t6X9XnK77/APCKUVtp82UzoHuQmgUlBeAoE88alN0hVHcLWetzOgcFgGnyv7jcUGeBoRMcOew4fz+TsPaRTT4ThOi8amEZUDP2/QJgRQSOpP64RIkByLIRgxSd75SETYJncgJGXwGApeDwQ5bVrQxQqZmpMVWPqwCYuUd8AOA655QJavIOANmBiKW8S4ZVSdwBOCu9XVKqQM0C48xhAKFNrRLotKFuOjoOjas0lYVZxIdNEZgnAI5cWlbHUedOsZPSqqxcPrKPwwr16jiqnI/AKce6dsQSty5EVnKCywERvGzOzFpORto6YFbgzM7DOlaZMbvPZQGYdag77wNAF7FXGe9qm19K0K0zl4lXEpdjW6f/PzQBMvDYKNPnHefZWm4GdUnQtQv3T+8DGACdTaoDjgPCCgCc8016IxTFSzeVGVAd8JgWUSqRHaERAGYDlcKomWejEYFTAKj6fUrd8DdMau0q45a3QUx9zCR13eaUB0rrbE+WATj3AwDT4bMlgKnIuCNL10uVABrukuARAFacJvHkN1UCw4luG+wQkTO2S7x48hs3zDGbWSDTG6Gsj1cpXF1VZ4IrI71ZAFwSfVKCTlQiys7VdWWM2u8IADGLMQMwSEu6QJfAkGS7UXPHIweweUsAcA264rgSAJf9r+iYa5P9edw5jiITV/LVNfDsceWtMGp5lJHMOMUHHSCr9WcldUmCR4WMElCdkmJdQJGa6ixDKNEPI87krA0yEYKqjP3eibBynjmXrW8DkEWsEkNOzVbpi/qCti74dx5VdplN+16Pj6PV11fmcPYsbhbXZ8/jM1Z+TM6ySB8CIFNclRR2nImEqWrfcR4JGO2rMiUD6J4BleRkhrsS1R3nRHTYmcnvysHIPawtlyVQRTqLgGpzGZlV62UZsqIN0+8CDqKqD7snQ+QBRXoV9yjwWbc67cNIpwTQMCa4Kh45RIKKiSvyqVpeFwAsjc78ZQCgWImoKybPxJB67r7vgO1okRHYJYehjNgUSbk16zo0M+7tj8Pf2XhojC5uzXEAAAAASUVORK5CYII=';
    this.img_icons.sheet = {
      'walk': [0, 0],
      'run': [1, 0],
      'obstruct': [2, 0],
      'cover': [3, 0],
      'reload': [0, 1],
      'crawl': [1, 1],
      'explore': [2, 1],
      'idle': [3, 1],
      'gather': [0, 2],
      'alert': [1, 2],
      'heal': [2, 2],
      'granade': [3, 2],
      'aim': [0, 3],
      'skull': [1, 3],
      'blind': [2, 3],
      'briefcase': [3, 3],
    };

    const query = parseQuery(window.location.search);
    this.show_overlay = !!(0 | query.overlay);
    this.ext_incremental = !!(0 | query.ext_incremental);
    if (props.showoverlay) {
      this.show_overlay = true;
    }

    // 현재 webview가 UE5에 embed되어 있는지 확인합니다.
    let embed = false;
    let paused = false;
    let controller = null;
    if (!props.noembed && window.ue) {
      embed = true;
      paused = true;
    }

    this.state = {
      sim: null,
      res: -1,
      seed: 0,
      simtps: props.simtps ?? opts.SIMTPS,
      simtps_scale: 1.0,

      paused,
      embed,

      // merged
      ext_prev: null,
      ext: null,

      debugOptObstacles: true,
      debugOptStructs: false,
      debugOptPoints: false,
      debugOptNet: false,
      debugOptRisk: false,
      debugOptRiskDry: false,
      debugOptVisArc: false,
      debugOptFog: !debug,
      debugOptWasmVis: false,
      debugOptWasmNav: false,
      debugOptWasmThreat: false,
      debugOptSimplices: true,
      debugOptWaypoints: true,
      debugOptPerception: false,
      debugOptKnowledge: false,
      debugOptThrow: false,
      debugOptEdges: false,
      debugOptBreach: false,
      debugOptAutoscroll: false,
      debugOptBubble: !embed,
      debugOptJournal: false,

      debugOptOverlay: true,

      debugOptShowText: true,
      debugOptShowArea: true,

      entityOver: null,

      scale: props.defaultScale ?? 1,

      stats: stats_populate(),

      showStats: false,

      debugMST: [],
      debugGrid: null,

      canvasOffset: new v2(0, 0),

      colors: COLORS_ALT,

      currentRenderGroup: 0,

      controller,
    };

    this.keyDown = this.onKeyDown.bind(this);
    this.keyUp = this.onKeyUp.bind(this);
    this.resize = this.onResize.bind(this);
    this.mouseDown = this.onMouseDown.bind(this, "click");
    this.mouseMove = this.onMouseMove.bind(this, "click");
    this.mouseUp = this.onMouseUp.bind(this, "click");
    this.touchStart = this.onMouseDown.bind(this, "touch");
    this.touchMove = this.onMouseMove.bind(this, "touch");
    this.touchEnd = this.onMouseUp.bind(this, "touch");

    this.last_ms = Date.now();

    this.onTimer = () => {
      let { last_ms } = this;
      let { simtps, stats, simtps_scale } = this.state;

      simtps *= simtps_scale;

      if (simtps > 0 || simtps_scale === 0) {
        const now = Date.now();
        const dt_max = 1000 / simtps * 3;
        if (now - last_ms > dt_max) {
          last_ms = now - dt_max;
        }

        let idx = 0;
        while (last_ms < now) {
          last_ms += 1000 / simtps;
          if (this.onTick() >= 0) {
            break;
          }
          stats = stats_populate();
          idx += 1;
        }
      } else {
        let start_ms = Date.now();
        while (start_ms + 100 > Date.now()) {
          if (this.onTick() >= 0) {
            break;
          }
          stats = stats_populate();
        }
      }

      this.startTimer();
      this.last_ms = last_ms;
      this.onAutoscroll();
      this.setState({ stats });
    };


    // Unreal Object is Exposed in window.ue.{name}

    // All Name's Unreal Object, Function is been lowercased. Be careful about this

    // window.simrestart = () => {
    //   console.log("Sim Restart");
    //   const { sim } = this.state;
    //   this.setState(this.newSimState(this.props, this.props.seed), () => {
    //     sim.free();
    //   });
    //   window.ue.connection.startcombat("ASDFASDF");
    // };

    this.simstate = { ...props };
  }

  componentDidMount() {
    const { paused, embed } = this.state;
    if (!paused) {
      this.startTimer();
    }

    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
    window.addEventListener('resize', this.resize);

    document.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
    document.addEventListener("touchstart", this.touchStart, { passive: false });
    document.addEventListener("touchmove", this.touchMove, { passive: false });
    document.addEventListener("touchend", this.touchEnd, { passive: false });

    if (this.state.sim === null) {
      // TODO: bypass react 18 remounting
      const s = this.state;
      const { seed, sim, controller } = this.newSimState(this.props.simstate, this.props.seed);
      s.seed = seed;
      s.sim = sim;
      this.setState({ seed, sim, controller }, () => {
        this.renderCanvas();
      });
    }

    if (embed) {
      window.simview = this;
      this.onTick();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('resize', this.resize);

    document.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener("touchstart", this.touchStart);
    document.removeEventListener("touchmove", this.touchMove);
    document.removeEventListener("touchend", this.touchEnd);

    if (window.simview === this) {
      window.simview = null;
    }
  }

  // 임시로 여기서 호출합니다. 이후 testbed.js, mission0.js로 옮겨야할 필요성이 있습니다.
  applyPerk2TacticalBonus(entities) {
    const getTacticalBonusType = (perk_name) => {
      for (const [key, value] of Object.entries(perk2_tactical_bonus_types)) {
        if (value.includes(perk_name)) {
          return key;
        }
      }
    };

    for (const entity of entities) {
      const perk2_keys = Object.keys(entity).filter((key) => key.startsWith('perk2'));

      for (const perk_key of perk2_keys) {
        const tactical_bonus_type = getTacticalBonusType(perk_key);

        if (entity._stat && tactical_bonus_type) {
          const diff_amount = perk2_tactical_bonus[tactical_bonus_type] * (entity._stat.tactical / 100.0);
          const modify_key = perk2_tactical_bonus_member[perk_key];
          entity[perk_key] = {
            ...entity[perk_key],
            [modify_key]: entity[perk_key][modify_key] + diff_amount,
          }
        }
      }
    }
  }

  debugConversations(simstate) {
    if (simstate.conversation_triggers) {
      return;
    }

    simstate.conversation_triggers = {};
    const { conversation_triggers } = simstate;

    const conversation_data = conversationsBySchedule('trial');
    for (const data of conversation_data) {
      const trigger = data.trigger[0];
      if (!conversation_triggers[trigger]) {
        conversation_triggers[trigger] = [];
      }
      conversation_triggers[trigger].push(data.conversations);
    }
  }

  newSimState(simstate, seed) {
    const { embed } = this.state;
    if (isNaN(seed)) {
      seed = Rng.randomseed();
    }
    if (DEBUG_CONVERSATION) {
      this.debugConversations(simstate);
    }

    let simprops = { ...simstate, seed };

    if (embed) {
      window.ue.connection.onsimprops?.(JSON.stringify(simprops));
    }

    if (DEBUG_SERIALIZE) {
      simprops = JSON.parse(JSON.stringify(simprops));
    }

    this.applyPerk2TacticalBonus(simprops.entities.filter((e) => e.team === 0));
    const sim = Simulator.create({ ...simprops, m: this.props.m });
    this.props.onSimCreate?.(sim);

    let controller = new SimController(sim);

    return {
      seed,
      // TODO
      sim,
      simprops,
      res: -1,
      entityOver: null,
      mousepos: null,
      controller,
    };
  }

  startTimer() {
    // do not start timer on embed
    if (this.state.embed) {
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.last_ms = Date.now();
    this.timer = setTimeout(this.onTimer, 0);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  // canvas-related shortcuts
  onKeyDownCanvas(ev) {
    const { paused, scale, simtps } = this.state;

    if (!ev.ctrlKey) {
      for (let i = 0; i < opts.SIMTPS_OPTS.length; i++) {
        if (ev.key === (i + 1).toString()) {
          this.startTimer();
          this.setState({ simtps: opts.SIMTPS_OPTS[i], paused: false });
        }
      }
    }

    // embed인 경우 simulation tick은 ue에서 호출합니다.
    if (ev.key === ' ') {
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.setState({ paused: !paused });
    }

    if (ev.key === 'z') {
      const idx = SCALES.indexOf(scale);
      this.setState({
        scale: SCALES[(idx + 1) % SCALES.length],
      });
      // TODO
    } else if (ev.key === 's') {
      // single step
      ev.preventDefault();
      this.onTick();
    } else if (ev.key === 'Tab') {
      ev.preventDefault();
      if (this.state.embed) {
        return;
      }

      let idx = opts.SIMTPS_OPTS.indexOf(simtps);
      this.setState({
        simtps: opts.SIMTPS_OPTS[(idx + 1) % (opts.SIMTPS_OPTS.length)],
      });
    }
  }

  onKeyUp(ev) {
    const { embed } = this.state;
    if (ev.which === 9) {
      if (!embed) {
        this.setState({ showStats: false });
      }
      ev.preventDefault();
      return;
    }
  }

  onResize() {
    this.setState({});
  }

  onAction(ev) {
    const { controller } = this.state;
    controller?.onAction(ev);
  }

  onControllerKeyDown(ev) {
    const { controller } = this.state;

    if (ev.key === 'i') {
      return controller?.onAction({ ty: 'toggle-throwable' });
    } else if (ev.key === 'o') {
      return controller?.onAction({ ty: 'toggle-heal' });
    } else if (ev.key === 'p') {
      return controller?.onAction({ ty: 'toggle-order' });
    }
    return false;
  }

  // screenpos : 마우스의 screen position
  // worldpos : 마우스의 world position
  onControllerMouseDown(_type, e) {
    const { controller } = this.state;
    let { screenpos, worldpos } = e;
    screenpos = v2.from(screenpos);
    if (!worldpos) {
      worldpos = this.worldpos(screenpos);
    }
    worldpos = v2.from(worldpos);

    controller?.onAction({ ty: 'pointer-click', screenpos, worldpos });
  }

  onControllerMouseMove(_type, e) {
    const { controller } = this.state;
    let { screenpos, worldpos } = e;
    screenpos = v2.from(screenpos);
    if (!worldpos) {
      worldpos = this.worldpos(screenpos);
    }
    worldpos = v2.from(worldpos);

    controller?.onAction({ ty: 'pointer-move', screenpos, worldpos });
  }

  onKeyDown(ev) {
    const { sim, paused, embed } = this.state;
    const { onReload } = this.props;

    if (!embed) {
      this.onKeyDownCanvas(ev);
    }

    this.onControllerKeyDown(ev);

    if (ev.key === '0') {
      this.stopTimer();
      this.setState({ paused: true });
    }

    if (ev.which === 9) {
      if (!embed) {
        this.setState({ showStats: true });
      }
      ev.preventDefault();
      return;
    }

    if (!sim || !this.props.debug) {
      return;
    }

    if (ev.key === 'Escape') {
      this.setState({ entityOver: null });
      return;
    }

    if (ev.key === 't') {
      const { sim } = this.state;
      const key = 'throwable';
      const allyEntities = sim.entities.filter((e) => e.team === 0);
      const spawnareas = _.uniq(allyEntities.map((e) => e.spawnarea));
      for (const spawnarea of spawnareas) {
        let cur = sim.controlGet(spawnarea, key);
        sim.controlSet(spawnarea, key, !cur);
      }
      this.setState({ sim });
    }

    if (onReload) {
      let seed = null;
      if (ev.key === 'r' && !(ev.metaKey || ev.ctrlKey)) {
        // restart with new seed
        seed = Rng.randomseed();
      } else if (ev.key === 'R') {
        // restart with same old seed
        seed = this.state.seed;
      }

      if (seed !== null) {
        ev.preventDefault();
        if (paused) {
          this.startTimer();
        }

        const nextstate = onReload(seed);

        this.setState({
          ...this.newSimState(nextstate, seed),
          rng: new Rng(seed),
        }, () => {
          sim.free();
        });
      }
    }
  }

  viewSize() {
    const { viewWidth, viewHeight } = this.viewSize0();
    const renderScale = this.renderScale();
    return {
      viewWidth: viewWidth * renderScale,
      viewHeight: viewHeight * renderScale,
    };
  }

  viewSize0() {
    if (this.state.embed) {
      return {
        viewWidth: 360,
        viewHeight: 360,
      };
    }

    const { sim } = this.state;
    const DEFAULTSIZE = 2048;
    let viewWidth = this.props.viewWidth ?? DEFAULTSIZE;
    let viewHeight = this.props.viewHeight ?? DEFAULTSIZE;

    if (sim) {
      const { width, height } = sim.world;
      viewWidth = Math.min(viewWidth, width);
      viewHeight = Math.min(viewHeight, height);
    }

    return {
      viewWidth,
      viewHeight,
    };
  }

  canvasCursor(type, e) {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }
    const rect = canvas.getBoundingClientRect();
    if (type === "touch") {
      e = e.touches[0];
    }

    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    const { viewWidth, viewHeight } = this.viewSize();

    if (x < 0 || y < 0 || x > viewWidth || y > viewHeight) {
      return null;
    }
    x -= viewWidth / 2;
    y -= viewHeight / 2;

    return new v2(x, y);
  }

  onMouseDown(type, e) {
    const cursor = this.canvasCursor(type, e);
    if (!cursor) {
      return;
    }

    this.onControllerMouseDown(type, { screenpos: this.projectEvent(e) });

    e.preventDefault();

    const { canvasOffset } = this.state;

    this.setState({
      click: cursor.add(canvasOffset),
    })
  }

  renderScale() {
    let renderScale = 1;
    if (typeof window !== 'undefined') {
      renderScale = window.devicePixelRatio;
    }
    return renderScale;
  }

  clampCanvasOffset(offset) {
    const { sim, scale } = this.state;

    const { width, height } = sim.world;
    const { viewWidth, viewHeight } = this.viewSize0();
    const maxX = width - viewWidth / scale;
    const maxY = height - viewHeight / scale;
    return new v2(
      clamp(offset.x, 0, maxX),
      clamp(offset.y, 0, maxY),
    );
  }

  onMouseMove(type, e) {
    const cursor = this.canvasCursor(type, e);
    if (!cursor) {
      return;
    }

    if (this.canvasCursor(type, e)) {
      this.onControllerMouseMove(type, { screenpos: this.projectEvent(e) });
    }

    const { click } = this.state;
    if (!click) {
      return
    }

    const nextOffset = click.sub(cursor);
    this.setState({
      canvasOffset: this.clampCanvasOffset(nextOffset),
    })
  }

  onMouseUp(_e) {
    this.setState({
      click: null
    })
  }

  renderCanvas() {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const renderScale = this.renderScale();
    const resources = {
      blood: this.img_blood,
      icons: this.img_icons,
    };
    renderCanvas(ctx, { ...this.state, renderScale }, { ...this.props, ...this.viewSize0() }, resources, window.ue);
  }

  onEntityOver(e) {
    this.setState({ entityOver: e });
  }

  projectEvent(e) {
    const c = this.canvasRef.current;
    const rect = c.getBoundingClientRect();
    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);
    return new v2(x, y);
  }

  worldpos(mousepos) {
    const { sim, scale, canvasOffset } = this.state;
    const { world } = sim;
    return mousepos.mul(1 / scale).add(canvasOffset).add(new v2(-world.width / 2, -world.height / 2));
  }

  unproject(pos) {
    const { sim, canvasOffset, scale } = this.state;
    const { world } = sim;

    const c = this.canvasRef.current;
    const rect = c.getBoundingClientRect();

    // render: scale -> translate
    // unproject: translate -> scale

    const offsetX = -world.width / 2 + canvasOffset.x;
    const offsetY = -world.height / 2 + canvasOffset.y;

    const x = (pos.x - offsetX) * scale + rect.left;
    const y = (pos.y - offsetY) * scale + c.offsetTop;

    return new v2(x, y);
  }

  canvasMouseMove(e) {
    const pos = this.projectEvent(e);

    this.setState({ mousepos: pos });
  }

  restart(cb) {
    this.setState(this.newSimState(this.props.simstate, this.props.seed), cb);
  }

  runThrowable() {
    this.onFinish(1);
  }

  onTick() {
    const { sim, embed, scale, controller } = this.state;
    let { res } = this.state;
    if (res !== -1) {
      return;
    }

    let first = false;
    if (!this.started) {
      first = true;
      this.started = true;
    }

    if (controller) {
      res = controller.onTick();
    } else {
      res = sim.onTick();
    }

    if (res !== 'skip') {
      // pending events
      while (sim.pending_events.length > 0) {
        const ev = sim.pending_events.shift();
        this.props?.onEvent?.(ev);
      }

      if (true) {
        let pos = new v2(0, 0);
        let count = 0;
        for (const entity of sim.entities) {
          if (entity.team !== 0 || entity.state === 'dead') {
            continue;
          }
          pos = pos.add(entity.pos);
          count += 1;
        }
        pos = pos.mul(1 / count);

        // pos가 (-width/2, -height/2)일 때 offset이 0이어야 함
        const { width, height } = sim.world;
        const { viewWidth, viewHeight } = this.viewSize0();
        const canvasOffset = this.clampCanvasOffset(new v2(
          Math.round(pos.x + width / 2 - viewWidth / scale / 2),
          Math.round(pos.y + height / 2 - viewHeight / scale / 2)
        ));

        this.setState({
          canvasOffset,
        });
      }
    }

    if (res === 'skip') {
      res = -1;
    }

    const ext = serializeState(sim, first, res, this.ext_incremental);

    if (!embed) {
      for (const e of ext.entities) {
        e.screenpos = this.unproject(e.pos.mul(0.1));
      }
      for (const e of ext.indicators) {
        e.screenpos = this.unproject(e.pos.mul(0.1));
      }
    }

    ext.paused = this.state.paused;
    ext.mute_bgm = this.props.mute_bgm ?? false;
    ext.is_practice = this.props.is_practice ?? false;
    ext.locale = localeGet();
    const noobs = this.props.noobs ?? false;
    if (noobs) {
      for (const obstacle of ext.obstacles) {
        obstacle.imported = true;
      }
    }

    const ext_prev = mergeState(this.state.ext_prev ?? ext, ext);

    if (embed) {
      // 페이지가 UE5에 embed되어 있는 경우, 시뮬레이션 상태를 UE5에 보냅니다
      window.ue.connection.onsimulationdata(JSON.stringify(ext));
    }
    this.state.ext_prev = ext_prev;
    this.state.ext = ext;

    if (res >= 0) {
      if (!embed) {
        this.onFinish(res);
      } else {
        // 이전 빌드 hang하지 않도록 조치
        setTimeout(() => {
          this.onFinish(res)
        }, 3000);
      }

      // TODO: ue fix
      this.state.res = res;
      this.stopTimer();
    }

    this.setState({ sim, res });
    return res;
  }

  onExtEvent(obj) {
    const { sim } = this.state;
    const { ty } = obj;
    if (ty === 'select_prompt') {
      const { prompt_idx, option_idx } = obj;

      const prompt = sim.prompts[prompt_idx];
      sim.controlSelectPrompt(prompt, option_idx);
      this.setState({ sim });
    } else if (ty === 'heal') {
      const { idx } = obj;
      this.onAction({ ty: 'heal', heal_target_idx: idx });
    } else if (ty === 'toggle-heal') {
      this.onAction({ ty: 'toggle-heal' });
    } else if (ty === 'order-swap') {
      const { id1, id2 } = obj;
      this.onAction({ ty: 'order-swap', id1, id2 });
    } else if (ty === 'toggle-order') {
      this.onAction({ ty: 'toggle-order' });
    } else if (ty === 'toggle-throwable') {
      this.onAction({ ty: 'toggle-throwable' });
    } else if (ty === 'sim-speed') {
      const { simtps_idx: idx } = obj;
      this.simSetSpeed(opts.SIMTPS_OPTS[idx]);
    }
    // TODO:
  }

  onFinish(res) {
    const { sim } = this.state;
    if (res === undefined) {
      res = this.state.res;
    }
    this.props.onFinish(sim, res);
  }

  onAutoscroll() {
    const { sim, debugOptAutoscroll } = this.state;
    if (!debugOptAutoscroll) {
      return;
    }

    let pos = new v2(0, 0);
    let count = 0;
    for (const entity of sim.entities) {
      if (entity.team !== 0 || entity.state === 'dead') {
        continue;
      }
      pos = pos.add(entity.pos);
      count += 1;
    }
    pos = pos.mul(1 / count);

    const c = this.canvasRef.current;
    const scroll = c.offsetTop + sim.world.height / 2 + pos.y - document.documentElement.clientHeight / 2;
    window.scrollTo(0, scroll);
  }

  renderControls() {
    const { debug } = this.props;
    const controlButtons = [];
    const debugKeys = [];

    for (const key of Object.keys(this.state)) {
      const prefix = 'control';
      if (key.indexOf(prefix) !== 0) {
        continue;
      }
      if (!debug && debugKeys.includes(key)) {
        continue;
      }

      let cls = '';
      if (this.state[key]) {
        cls = 'selected';
      }
      controlButtons.push(<button className={cls} key={key}
        onClick={() => this.setState({ [key]: !this.state[key] })}>debug: {key.substring(prefix.length).toLowerCase()}</button>);
    }

    controlButtons.push(<button key="retreat" onClick={() => {
      const { sim } = this.state;
      for (const entity of sim.entities) {
        if (entity.team !== 0) {
          continue;
        }
        entity.push_rule(sim.tick, { ty: 'capture' });
      }
    }}>retreat</button>);

    return <div className='sim-overlay-debug-controls'>{controlButtons}</div>;
  }

  controlSet(key, value) {
    const { sim } = this.state;
    sim.controlSet(0, key, value);
    this.setState({ sim });
  }

  renderPlayerStats() {
    const { sim } = this.state;
    const embed = this.embedWebview();
    if (embed) {
      return null;
    }
    const { morale, uncover } = sim.playerstats;

    const levels_morale = [
      { value: 81, label: '천하무적', color: '#ffd700' },
      { value: 61, label: '자신만만', color: '#1f4d90' },
      { value: 41, label: '안정', color: '#6cace4' },
      { value: 21, label: '불안', color: '#fcd67a' },
      { value: 1, label: '흔들림', color: '#726e6d' },
      { value: 0, label: '패주', color: '#575757' },
    ];
    const level_morale = levels_morale.find((e) => morale >= e.value);

    const levels_uncover = [
      { value: 100, label: '전면전', color: '#8b0000' },
      { value: 75, label: '비상', color: '#ff0000' },
      { value: 50, label: '소란', color: '#ff7f50' },
      { value: 25, label: '경계', color: '#8c7293' },
      { value: 0, label: '은밀', color: '#4b3d5c' },
    ];
    const level_uncover = levels_uncover.find((e) => uncover >= e.value);
    const style = {
      display: 'inline-block',
    };

    let progress = sim.segmentProgress().map((e, i) => {
      const { killed, total } = e;
      const text = `${killed}/${total}`;
      return <div className="sim-overlay-stat-row" key={`progress-${i}`}>
        <span className="sim-overlay-stat-row-name">구간 #{i}</span>
        <ProgressBar width={200} cur={killed} max={total} text={text} />
      </div>;
    });

    return <>
      <hr />
      <div>
        <div className="sim-overlay-stat-row">
          <span className="sim-overlay-stat-row-name">사기: <span style={{ ...style, color: level_morale.color }}>{level_morale.label}</span></span>
          <ProgressBar width={200} cur={morale} max={100} bgcolor={level_morale.color} />
        </div>
        {progress}
        {/* <div className="sim-overlay-stat-row">
        <span className="sim-overlay-stat-row-name">발각: <span style={{ ...style, color: level_uncover.color }}>{level_uncover.label}</span></span>
        <ProgressBar width={200} cur={uncover} max={100} bgcolor={level_uncover.color} />
      </div> */}
      </div>
    </>;
  }

  renderAreaControls(spawnarea) {
    const { sim } = this.state;
    const { nocontrol } = this.props;
    const t = this;

    function renderButton(key, value, title) {
      let cur = sim.controlGet(spawnarea, key);
      if (value === undefined) {
        value = !cur;
      }

      return <DivBtn key={title ?? key} name={key} disabled={nocontrol} title={title}
        onClick={() => { sim.controlSet(spawnarea, key, value); t.setState({ sim }); }} />
    }

    // state mgmt
    const entities = sim.entities.filter((e) => e.spawnarea === spawnarea);

    let state = sim.areaState(spawnarea);

    const actionbtns = [];
    actionbtns.push(renderButton('withdraw'));
    // actionbtns.push(renderButton('win'));

    const statename = {
      'engage': '교전중',
      'explore': '탐색중',
      'reorg0': '엄폐중',
      'reorg': '재정비중',
    }[state];

    const features = sim.controlsFeatures();
    const entityFeaturesFunc = (e) => sim.getEntityControlsFeature(e);

    return <>
      <p className={`sim-overlay-state sim-overlay-state-${state}`}>상태: {statename}</p>
      <div className='sim-overlay-controls'>
        {actionbtns}
        <SimPolicyControl
          controls={sim.controls(spawnarea)}
          features={features}
          controlSet={(key, value) => {
            sim.controlSet(spawnarea, key, value);
            this.props?.onEvent?.({ ty: 'control', controls: sim.controls(spawnarea) });
            this.setState({ sim });
          }}
          agentControlSet={(agent, key, value) => {
            agent.controls[key] = value;
            this.props?.onEvent?.({ ty: 'entityControl', entity: agent, controls: agent.controls });
            this.setState({ sim });
          }}
          names={sim.entities.map((e) => e.name)}
          firearms={sim.entities.filter((e) => e.firearms).map((e) => e.firearms)}
          agents={entities}
          agentFeaturesFunc={entityFeaturesFunc}
          segment={sim.segment}
        />
      </div>
      <hr />
    </>;
  }

  renderUI() {
    const { sim, colors, stats } = this.state;
    const { debug } = this.props;

    const debugButtons = [];

    if (debug) {
      let cls = '';
      let next = COLORS_ALT;
      if (colors !== COLORS) {
        cls = 'selected';
        next = COLORS;
      }
      debugButtons.push(<button key='color' className={cls} onClick={(ev) => {
        ev.preventDefault();
        this.setState({ colors: next });
      }}>altcolor</button>);
    }

    for (const key of Object.keys(this.state)) {
      const prefix = 'debugOpt';
      if (key.indexOf(prefix) !== 0) {
        continue;
      }
      if (!debug) {
        continue;
      }

      let cls = '';
      if (this.state[key]) {
        cls = 'selected';
      }
      debugButtons.push(<button className={cls} key={key}
        onClick={() => this.setState({ [key]: !this.state[key] })}>{key.substring(prefix.length).toLowerCase()}</button>);
    }

    let controls = null;
    let extras = null;
    if (debug) {
      controls = <div>
        debug
        <button onClick={this.onTick.bind(this)}>step</button>
        {debugButtons}
        <button onClick={() => this.setState({ entityOver: null })}>unselect</button>
      </div>;
      extras = <DebugSimView sim={sim} stats={stats} />;
    } else {
      controls = <div>{debugButtons}</div>;
    }

    return { controls, extras };
  }

  renderAction(action, index) {
    const { sim } = this.state;
    const { tick } = sim;
    const { queue_at, execute_at, trigger } = action;

    const max = (execute_at - queue_at) / sim.tps;
    const cur = (execute_at - tick) / sim.tps;

    let body = null;
    if (trigger.action === 'push_rule' && trigger.actionrules[0].ty === 'idle') {
      body = `교전을 인지합니다`;
    }
    if (trigger.action === 'push_rule' && trigger.actionrules[0].ty === 'explore') {
      body = `침입에 대응합니다`;
    }
    if (trigger.action === 'spawn_entity') {
      body = `증원이 도착합니다`;
    }
    if (body === null) {
      body = `알려지지 않은 트리거: ${trigger.action}`;
    }
    const key = body + index;

    return <div key={key} className="box">
      <ProgressBar cur={cur} max={max} bgcolor='white' fgcolor='black' width={100} />
      {body}
    </div>;
  }

  renderPrompt(prompts) {
    const { nocontrol } = this.props;
    const { sim } = this.state;
    const { tick } = sim;
    const { prompt_options, queue_at, expire_at } = prompts;

    const max = (expire_at - queue_at) / sim.tps;
    const cur = (expire_at - tick) / sim.tps;

    return <div className="box" key={queue_at}>
      <ProgressBar cur={cur} max={max} bgcolor='white' fgcolor='black' width={100} />

      {prompt_options.map((p, i) => {
        let { title, default: d } = p;
        if (d) {
          title += " (default)";
        }
        return <button key={i} disabled={nocontrol} onClick={() => {
          sim.controlSelectPrompt(prompts, i);
        }}>{title}</button>;
      })}
    </div>
  }

  getSpawnAreas() {
    const { sim } = this.state;
    const { entities } = sim;
    const allyEntities = entities.filter((e) => e.team === 0);
    return _.uniq(allyEntities.map((e) => e.spawnarea));
  }

  onChangeRenderGroup(i) {
    this.setState({ currentRenderGroup: i });

  }

  embedWebview() {
    return this.state.embed || this.props.embed;
  }

  renderDebugFileExport() {
    if (!this.props.debug1) {
      return null;
    }

    const { embed } = this.state;
    const { simstate } = this;
    const simview = this;

    return <FileExportView simstate={simstate} simview={simview} embed={embed} />;
  }

  renderOverlayGroup(areas, i) {
    const { sim, entityOver, ext_prev: ext } = this.state;
    const { entities } = sim;
    const area = areas[i];

    if (ext?.pending_prompts.filter((p) => p.dialog !== null).length > 0) {
      return null;
    }

    const prompts = sim.prompts.filter((p) => p.area === area);

    const { viewWidth, viewHeight } = this.viewSize();
    const groupEntities = entities.filter((e) => e.team === 0 && e.spawnarea === area);
    const embed = this.embedWebview();
    let canvas = null;
    if (embed) {
      let cls = "sim-overlay-canvas-container";
      if (window.ue && !this.props.debug1) {
        cls += ' ue';
      }
      canvas = <canvas className={cls}
        width={viewWidth}
        height={viewHeight}
        ref={this.canvasRef}
        onMouseMove={this.canvasMouseMove.bind(this)}
      ></canvas>;
    }

    const journal = this.getJournal();

    const spawnareas = this.getSpawnAreas();

    let extraView = null;
    if (!embed) {
      const allies_idx = entities.map((e, idx) => e.team === 0 ? idx : -1).filter((id) => id !== -1);

      const renderOrderController = (entity) => {
        const { order_ctrl } = this.state?.controller?.controllers;
        const { activate } = order_ctrl;
        if (!activate) {
          return;
        }
        const entity_idx = entities.indexOf(entity);
        const ally_idx = allies_idx.findIndex((idx) => idx === entity_idx);

        const btns = [];
        if (ally_idx > 0) {
          btns.push(<button onClick={() => {
            order_ctrl.onAction({ ty: 'order-swap', id1: allies_idx[ally_idx - 1], id2: allies_idx[ally_idx] });
          }}>Order ▲</button>);
        }
        if (ally_idx + 1 < allies_idx.length) {
          btns.push(<button onClick={() => {
            order_ctrl.onAction({ ty: 'order-swap', id1: allies_idx[ally_idx], id2: allies_idx[ally_idx + 1] });
          }}>Order ▼</button>);
        }

        return <span>
          {btns}
        </span>;
      };

      const renderHealController = (entity) => {
        const { heal_ctrl } = this.state?.controller?.controllers;

        const { activate } = heal_ctrl;
        if (!activate) {
          return;
        }
        if (!heal_ctrl.isHealTarget(entity)) {
          return;
        }

        return <span>
          <button onClick={() => {
            heal_ctrl.onAction({ ty: 'heal', heal_target: entity });
          }}>heal</button>
        </span>
      };

      const renderActions = (entity) => {
        if (!this.state?.controller?.controllers) {
          return;
        }

        return <div>
          <span> Actions : </span>
          {renderHealController(entity)}
          {renderOrderController(entity)}
        </div>
      };

      extraView = <>
        <MeterView entities={entities.filter((e) => e.team === 0)} trails={sim.trails} />
        <div className="sim-overlay-group-tabs">
          {spawnareas.map((area, i) => {
            let cls = 'sim-overlay-group-tab';
            if (i === this.state.currentRenderGroup) {
              cls += ' selected';
            }
            return <div key={i} className={cls} onClick={this.onChangeRenderGroup.bind(this, i)}>group #{area}</div>;
          })}
        </div>
        {prompts.map(this.renderPrompt.bind(this))}
        <div className="sim-overlay-entities-container">
          {groupEntities.map((e, i) => {
            return <div>
              <EntityView key={i} entity={e} sim={sim}
                entityOver={entityOver}
              />
              {renderActions(e)}
            </div>;
          })}
        </div>
      </>;
    }

    return <div key={area} className="sim-overlay-group">
      {canvas}
      {this.renderAreaControls(area)}
      {extraView}

      <div className="sim-overlay-journals">
        {journal.map((s, i) => <JournalItem key={i} item={s} />)}
      </div>

      {this.renderDebugFileExport()}
    </div>;
  }

  getJournal() {
    const { sim } = this.state;
    const logs = sim.journal.filter((e) => {
      if (e.ty === 'fire' && e.kill) {
        return true;
      }
      if (e.ty === 'perk') {
        return true;
      }
      if (e.ty.includes('throw') || e.ty.includes('heal')) {
        return true;
      }
      return false;
    });
    const journal = logs.slice(Math.max(0, logs.length - 10)).reverse();
    return journal;
  }

  simSetSpeed(simtps) {
    this.startTimer();
    this.setState({ simtps, paused: false });
  }

  render() {
    const { sim, entityOver, debugOptOverlay, showStats, embed, res, currentRenderGroup, ext_prev: ext, simtps } = this.state;
    const idx = opts.SIMTPS_OPTS.indexOf(simtps);

    if (!sim) {
      return;
    }

    if (embed) {
      if (res >= 0) {
        return;
      }
    }

    const { controls, extras } = this.renderUI();

    const { viewWidth: viewWidth0, viewHeight: viewHeight0 } = this.viewSize0();
    const { viewWidth, viewHeight } = this.viewSize();

    let statOverlay = null;
    if (showStats) {
      statOverlay = <div className="overlay">
        <StatView sim={sim} />
      </div>;
    }

    let overlay = null;
    if (debugOptOverlay) {
      let overlayCls = 'sim-overlay'
      if (embed) {
        overlayCls = 'sim-overlay-embed';
      }

      overlay = <>
        <div className={overlayCls}>
          {sim.actions.map(this.renderAction.bind(this))}
          {this.renderOverlayGroup(this.getSpawnAreas(), currentRenderGroup)}
        </div>
      </>;
    }

    let canvas = null;
    if (!this.embedWebview()) {
      canvas = <canvas
        width={viewWidth}
        height={viewHeight}
        style={{ width: viewWidth0, height: viewHeight0 }}
        ref={this.canvasRef}
        onMouseMove={this.canvasMouseMove.bind(this)}
      ></canvas>;
    }

    let simoverlay = null;
    if (!embed && this.show_overlay) {
      simoverlay = <SimOverlay data={ext}
        onSelectPrompt={(prompt_idx, option_idx) => {
          if (sim.prompts.length <= prompt_idx) {
            return;
          }

          const prompt = sim.prompts[prompt_idx];

          sim.controlSelectPrompt(prompt, option_idx);
          this.setState({ sim });
        }}
        onSendAction={(ty, args) => {
          this.onExtEvent({ ty, ...args });
        }}
        idx={idx}
      />;
    }

    return <>
      <EntityOverContext.Provider value={{ entityOver, onEntityOver: this.onEntityOver.bind(this) }}>
        {controls}
        {overlay}
        {canvas}
        {extras}
      </EntityOverContext.Provider>
      {simoverlay}
      {statOverlay}
    </>;
  }
}

function DivBtn(props) {
  const { name, onClick, disabled } = props;
  let { title } = props;
  if (!title) {
    const data_descr = ordersDescr.find(d => d.key === name);
    title = data_descr?.title ?? name;
  }
  return <button disabled={disabled} onClick={() => {
    if (!disabled) {
      onClick();
    }
  }}>{title}</button>;
}
