import source from '../downloaded/physicalcap.json' assert { type: 'json' };
import * as weights from './data_weights.mjs';


const list = [];
for (const row of source) {
  let { label, physical_cap } = row;
  list.push({
    label,
    physical_cap: +physical_cap,
  });
}

export function descr(data) {
  const { label, physical_cap } = data;
  return `grade: ${label}
최대 피지컬: ${physical_cap}`;
}

export function find(key) {
  return list.find(({ label }) => label === key);
}

export function sample(rng, areaNum, delta) {
  const label = weights.sample(rng, areaNum, 'physicalcap', delta);
  return find(label);
}
