import { contractDetails0, terminations } from '../../contract.mjs';

import { AgentContract } from '../anima/src/components/AgentContract';
import { ContractOption } from '../anima/src/components/ContractOption';


export const AgentContractWrapper = (props) => {
  const { agent, turn, onAgentTerminate } = props;

  if (!agent) {
    return null;
  }

  const { contract } = agent;
  const details = contractDetails0(contract, turn);
  const { terminationCost } = terminations(contract, turn);

  return (
    <>
      <AgentContract
        theredbuttonSiOnClick={() => onAgentTerminate && onAgentTerminate()}
        ptType={details.ty}
        ptCancelFee={`$ ${terminationCost.toLocaleString('en-US')}`}
        ptCost={details.turn_cost}
        ptHazardPay={details.mission_cost}
        ptRemaining={details.remaining}
        ptShareRate={details.profit_share_rate}
        override={
          <>
            {details.options.map((o) => o.value && <ContractOption
              key={o.name}
              ptName={o.name}
              ptDesc={o.desc}
            />)}
          </>
        }
      />
    </>
  );
};
