import source from '../downloaded/quest.json' assert { type: 'json' };


function parse_pairs(s) {
  const pairs = [];

  if (!s) {
    return pairs;
  }

  for (var line of s.replace(/\r/g, '').split('\n')) {
    if (line === '') {
      continue;
    }

    const pairArray = line.split(',').map((s) => s.trim());
    pairs.push({ key: pairArray[0], value: pairArray.length === 2 ? pairArray[1] : pairArray.slice(1) });
  }

  return pairs;
}

export const quests = [];

for (const row of source) {
  let { key, type, title, instruction, objective, reward, duration, description, prerequisite, on_start } = row;

  quests.push({
    key,
    type,
    title,
    instruction,
    objectives: parse_pairs(objective),
    rewards: parse_pairs(reward),
    duration: 0 | duration,
    description,
    prerequisites: parse_pairs(prerequisite),
    on_starts: parse_pairs(on_start),
  });
}
