import source from '../downloaded/recruitGroup.json' assert { type: 'json' };

function split(s) {
  if (!s || s === '') {
    return [];
  }
  return s.split(',').map((s) => s.trim());
}

export const recruitGroups = [];
for (const row of source) {
  const { id, size, companyRank, types, fixed_operators, prob_modifier, modifier, firearm, perks } = row;

  recruitGroups.push({
    id,
    size: parseInt(size),
    companyRank: parseInt(companyRank),
    types: split(types),
    fixed_operators: split(fixed_operators),
    prob_modifier: parseFloat(prob_modifier),
    modifiers: split(modifier),
    firearms: split(firearm),
    perks: split(perks),
  });
}
