import _ from 'lodash';
import React from 'react';
import { L } from './localization.mjs';
import './FigmaLobbyView.css';
import './FigmaListView.css';
import { TooltipContext, figmaTooltipEvents, FigmaTooltipView, renderTooltip } from './FigmaTooltipView.js';
import { TICK_PER_DAY, TICK_PER_WEEK, tickToDateTimeStrObj, tickToDateShort, TICK_PER_MONTH, tickToYear } from './tick.mjs';
import { tickTodddhhmmDMMMYYYY, tickTod } from './tick.mjs';
import { CONFIGS } from './presets_grind.mjs';

import { PortraitWrapper } from './PortraitsView';
import { getIntegrityData } from './data/google/processor/data_agentIntegrity.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { getCompanyStatusData } from './data/google/processor/data_companyStatus.mjs';
import { scheduleCEOs as data_scheduleCEOs } from './data/google/processor/data_scheduleCEO.mjs';
import { getTrustData } from './data/google/processor/data_agentTrust.mjs';
import { agentHasModifierEffect, agentModifierEffectValue } from './character2.mjs';
import { SoundButton, soundprops } from './sound.mjs';
import { missionObjectives as data_missionObjectives } from './data/google/processor/data_missionObjective.mjs';
import { questPresets as data_questPresets } from './data/google/processor/data_questPreset.mjs';

import { RoleBadge, RenderAgentSimple, RenderAgentDetail, evalCSSClass } from './RenderAgentSimple.js';
export { evalCSSClass } from './RenderAgentSimple.js';

import { MAIN_PASS_SCORE, MAIN_FAIL_SCORE_NORMAL, SUB_BONUS_SCORE } from './GrindParams.js';
import { getGearPower } from './Badges.js';

const DAYS_OF_WEEK_STR = ['FRI', 'SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU'];
const MONTHS_STR = ["JAN.", "FEB.", "MAR.", "APR.", "MAY.", "JUN.", "JUL,", "AUG.", "SEP.", "OCT.", "NOV.", "DEC."];
const DAYS_NUMBER_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const CLOCK_LINE = <div className='lobby-clock-line' />
const ICON_DATE_CHECK = <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" className='icon-date-check'>
  <path d="M25.705 24.0648L7.52881 9.16922" stroke="#F24646" strokeWidth="4" />
  <path d="M9.16934 25.7051L24.0649 7.5289" stroke="#F24646" strokeWidth="4" />
</svg>

export function LobbyHeader(props) {
  const { game } = props;
  const { turn } = game;

  return (
    <div className="lobby-header">
      <LobbyClock turn={game?.turn} />
      <LobbyCalendar2 turn={turn} deadline_weeks={game.config.deadline_weeks} />
      <LobbyCalendarLegend />
      <LobbyMissionObjectives milestones={game?.milestones} milestoneStates={game?.milestoneStates} />
    </div>
  );
}

function LobbyClock(props) {
  const { turn } = props;
  let timeString = '';
  if (!turn) {
    timeString = '1970 JAN. AM 07:00';
  } else {
    const { date, time } = tickToDateTimeStrObj(turn);
    timeString = tickToYear(turn) + " " + MONTHS_STR[Math.floor((turn / TICK_PER_MONTH) % 12)] + " " + time;
  }
  return (
    <div className="lobby-clock">
      <span className="lobby-clock-label">{timeString}</span>
      {CLOCK_LINE}
    </div>
  );
}

function LobbyCalendar(props) {
  const { game } = props;

  const dates = Array.from(Array(13), (_, i) => i).map(i => tickToDateShort(game.turn + (i + 1) * TICK_PER_DAY));
  const pending = game.pendings.find((p) => p.ty === 'mission');
  const pendingCount = Math.ceil((pending.turn - game.turn) / TICK_PER_DAY);
  /* checkpoint, mission */

  const calendarRuler = <div className="lobby-calendar-ruler">
    {dates.map((date, i) => {
      if (i + 1 === pendingCount) {
        return <span className="lobby-calendar-date highlight">{svg_icon_clipboard}</span>;
      } else if ([1, 2].includes(Math.floor(((game.turn + (i + 1) * TICK_PER_DAY) / TICK_PER_DAY) % 7))) {
        return <span className="lobby-calendar-date weekend">{date}</span>;
      }
      return <span className="lobby-calendar-date weekday">{date}</span>;
    })}
  </div>;

  return (
    <div className="lobby-calendar">
      {svg_icon_flag}
      {calendarRuler}
    </div>
  );
}

function LobbyCalendar2(props) {
  const { turn, deadline_weeks } = props

  function DateToSlot(props) {
    const { date, today, bgColor, fontColor, checked, j } = props
    const defaultFontColor = "#999";
    const defaultBgColor = "null"

    return <div key={date + ' ' + j} className='lobby-calendar2-dateslot' style={{
      background: bgColor ? bgColor : defaultBgColor,
      border: today && " 3px solid #FFF"
    }}>
      <span className='lobby-calendar2-dateslot-label' style={{ color: fontColor ? fontColor : defaultFontColor }}>{date}</span>
      {checked && ICON_DATE_CHECK}
    </div>
  }

  function MakeCalendar(props) {
    const { dates } = props;
    return dates.map((dateRow, i) => {
      const slots = dateRow.map((e, j) => DateToSlot({ ...e, j }))
      return <div key={i} className='lobby-calendar2-row'>{slots}</div>
    })
  }

  const dayPassed = Math.floor(turn / TICK_PER_DAY);
  const dates = [[
    { date: L('loc_ui_string_lobby_calendar_mon') },
    { date: L('loc_ui_string_lobby_calendar_tue') },
    { date: L('loc_ui_string_lobby_calendar_wed') },
    { date: L('loc_ui_string_lobby_calendar_thu') },
    { date: L('loc_ui_string_lobby_calendar_fri') },
    { date: L('loc_ui_string_lobby_calendar_sat') },
    { date: L('loc_ui_string_lobby_calendar_sun') },
  ]];
  let daysSum = 0

  let weeksSum = 0;

  const targetWeeks = Math.max(deadline_weeks, Math.ceil((dayPassed + 4) / 7));

  for (let i = 0; weeksSum < targetWeeks; i++) {
    daysSum += DAYS_NUMBER_MONTH[i];
    weeksSum = Math.ceil((daysSum + 4) / 7);
  }

  const allDates = [];
  for (let i = 0; i < weeksSum; i++) {
    const row = []
    for (let j = 0; j < 7; j++) {
      row.push({ date: "" });
    }
    allDates.push(row);
  }

  allDates[0][4]['fontColor'] = "black";
  allDates[0][4]['bgColor'] = "#5B9AA8";

  for (let i = 0; i < allDates.length; i++) {
    allDates[i][5]['fontColor'] = "#C33E3E";
    allDates[i][6]['fontColor'] = "#C33E3E";
  }
  for (let i = 1; i < deadline_weeks; i++) {
    allDates[i][4]['fontColor'] = "black";
    allDates[i][4]['bgColor'] = "#FF7A00";
  }

  allDates[deadline_weeks][4]['fontColor'] = "black";
  allDates[deadline_weeks][4]['bgColor'] = "#F7BA1C";


  let month = 0;
  let sumOfDays = DAYS_NUMBER_MONTH[month];
  for (; dayPassed >= sumOfDays; month++) {
    sumOfDays += DAYS_NUMBER_MONTH[month + 1];
  }

  let daysOfPassedMonth = 0;
  for (let i = 0; i < month; i++) {
    daysOfPassedMonth += DAYS_NUMBER_MONTH[i];
  }
  const weekPassed = Math.floor(daysOfPassedMonth / 7);
  const bumperDays = (4 + daysOfPassedMonth) % 7;
  const weeksOfMonth = Math.ceil((bumperDays + DAYS_NUMBER_MONTH[month]) / 7);


  for (let i = 0; i < weeksOfMonth; i++) {
    dates.push(allDates[i + weekPassed]);
  }

  let rowNum = 1;
  for (let i = 1; i <= bumperDays + DAYS_NUMBER_MONTH[month];) {
    for (let j = 0; j < 7; j++) {
      if (i > bumperDays && i <= bumperDays + DAYS_NUMBER_MONTH[month]) {
        dates[rowNum][j]['date'] = (i - bumperDays)

      } else {
        dates[rowNum][j]['bgColor'] = null;
        dates[rowNum][j]['fontColor'] = null;
      }
      i++;
    }
    rowNum++;
  }

  let finished = false;
  let checkCount = 0;

  for (let i = 1; i < dates.length; i++) {
    for (let j = 0; j < 7; j++) {
      if (dates[i][j].date != "") {
        if (checkCount == dayPassed - daysOfPassedMonth) {
          dates[i][j]["today"] = true;
          finished = true;
          break;
        } else {
          dates[i][j]["checked"] = true;
        }
        checkCount++;
      }
    }
    if (finished) {
      break;
    }
  }

  return <div className="lobby-calendar2">
    <div className='lobby-calendar2-inner'>
      <MakeCalendar dates={dates} />
    </div>
  </div>
}

function LobbyCalendarLegend() {
  return <div className='lobby-calendarlegend'>
    <div className='lobby-calendarlegend-line'>
      <div className='lobby-calendarlegend-line-rect' style={{ background: "#5B9AA8" }} />
      <span className='lobby-calendarlegend-line-label' style={{ color: "#5B9AA8" }}>{L('loc_ui_string_lobby_calendar_due_wargame')}</span>
    </div>
    <div className='lobby-calendarlegend-line'>
      <div className='lobby-calendarlegend-line-rect' style={{ background: "#FF7A00" }} />
      <span className='lobby-calendarlegend-line-label' style={{ color: "#FF7A00" }}>{L('loc_ui_string_lobby_calendar_due_clientfeedback')}</span>
    </div>
    <div className='lobby-calendarlegend-line'>
      <div className='lobby-calendarlegend-line-rect' style={{ background: "#F7BA1C" }} />
      <span className='lobby-calendarlegend-line-label' style={{ color: "#F7BA1C" }}>{L('loc_ui_string_lobby_calendar_due_delivery')}</span>
    </div>
  </div>
}

function LobbyMissionObjectives(props) {
  const { milestones, milestoneStates } = props;

  const mains = [];
  const subs = [];

  for (let i = 0; i < milestones.length; i++) {
    const { key, ty } = milestones[i];
    const data = data_missionObjectives.find((d) => d.key === key);
    const milestoneState = milestoneStates[i];
    if (!data) {
      continue;
    }
    let cur = milestoneState.current;
    if (Math.round(cur) !== cur) {
      cur = cur.toFixed(1);
    }
    const body = <div key={i}><font color={milestoneState.pass ? 'green' : 'red'}>{L(data.desc, { count: +(data.condition_value) })}({cur}/{data.condition_value})</font></div>;

    if (ty === 'main') {
      mains.push(body);
    }
    else {
      subs.push(body);
    }
  }

  return <div className='lobby-missionObjectives'>
    {mains.length > 0 ? <p>{L('loc_ui_string_lobby_main_objective')}</p> : null}
    {mains}
    {subs.length > 0 ? <p>{L('loc_ui_string_lobby_bonus_objective')}</p> : null}
    {subs}
  </div>
}

export function LobbyStatusCash(props) {
  const { game } = props;
  const { turn } = game;

  const dayBeforeSalaryDay = 7 - ((Math.floor(turn / TICK_PER_DAY)) % 7);

  let balance = 0;
  let income = 0;
  let outgoing = 0;
  let income_trust = 0;


  if (!game) {
    balance = 5000;
    income = 4000;
    outgoing = -200;
    income_trust = 500;
  } else {
    balance = game.resources.balance;
    income = game.income;
    outgoing = -game.getWeeklyOutgoing();
    income_trust = game.getFinalIncome() - income;
  }
  const revenue = income + outgoing + income_trust;
  const newBalance = balance + revenue;
  const revenueSign = revenue >= 0 ? "+" : "-";
  const newBalanceSign = newBalance >= 0 ? "" : "-";
  const incomeTrustSign = income_trust >= 0 ? "" : "-";

  return (
    <div className="lobby-status-item-cash" >
      <div className="lobby-status-item-cash-header" >
        <span className="lobby-status-item-cash-header-label">{L('loc_ui_string_lobby_cash_balance')}</span>
        <span className="lobby-status-item-cash-header-value">$ {balance.toLocaleString('en-US')}</span>
      </div>
      <div className="lobby-status-item-cash-body">
        <div className="lobby-status-item-cash-detail">
          <span className="lobby-status-item-cash-label">{L('loc_ui_string_lobby_cash_expected_budget_change', { count: dayBeforeSalaryDay })}</span>
          <span className={"lobby-status-item-cash-value" + (revenue >= 0 ? " positive" : " negative")}>{revenueSign} $ {Math.abs(revenue).toLocaleString('en-US')}</span>
        </div>
        <div className="lobby-status-item-cash-detail">
          <span className="lobby-status-item-cash-label-desc">{L('loc_ui_string_lobby_cash_income')}</span>
          <span className="lobby-status-item-cash-value-desc positive">+ $ {Math.abs(income).toLocaleString('en-US')}</span>
        </div>
        <div className="lobby-status-item-cash-detail">
          <span className="lobby-status-item-cash-label-desc">{L('loc_ui_string_lobby_cash_income_trust')}</span>
          <span className={"lobby-status-item-cash-value-desc" + (income_trust >= 0 ? " positive" : " negative")}>{incomeTrustSign} $ {Math.abs(income_trust).toLocaleString('en-US')}</span>
        </div>
        <div className="lobby-status-item-cash-detail">
          <div className="lobby-status-item-cash-label-desc">{L('loc_ui_string_lobby_cash_expense')}</div>
          <div className="lobby-status-item-cash-value-desc negative">- $ {Math.abs(outgoing).toLocaleString('en-US')}</div>
        </div>
        <hr className="lobby-status-item-divider" />
        <div className="lobby-status-item-cash-detail">
          <div className="lobby-status-item-cash-label">{L('loc_ui_string_lobby_cash_expected_balance', { count: dayBeforeSalaryDay })}</div>
          <div className="lobby-status-item-cash-value">{newBalanceSign} $ {Math.abs(newBalance).toLocaleString('en-US')}</div>
        </div>
      </div>
    </div>
  );
}

export function LobbyStatusCondition(props) {
  const { game, onScheduleCEO, onClickPortrait, upperButton } = props;
  const { integrity, agents_avail_all } = game;

  let statusString = '-';
  let integrityString = '-';
  let conditionString = '-';
  let moodString = '-';
  let squadPowerString = '-';
  let statusOrder = '5';

  const schedule_id = 'meeting_person';
  const schedule = data_scheduleCEOs.find((d) => d.id === schedule_id);

  const agentInfos = [];

  const condition_average = agents_avail_all.reduce((acc, agent) => acc + agent.condition, 0) / agents_avail_all.length;
  const mood_average = agents_avail_all.reduce((acc, agent) => acc + agent.mood, 0) / agents_avail_all.length;
  const power_average = agents_avail_all.reduce((acc, agent) => acc + agent.power, 0) / agents_avail_all.length;
  const squad_agents_length = squads[0].agents.length;
  const squad_power_average = squad_agents_length > 0 ? squads[0].agents.reduce((acc, agent) => acc + agent.power + getGearPower(agent).power, 0) / squad_agents_length : 0;

  const integrityData = getIntegrityData(integrity);
  const companyStatusData = getCompanyStatusData(integrity, condition_average, mood_average, power_average);
  const conditionData = getConditionData(condition_average);
  const moodData = getMoodData(mood_average);
  const trustData = getTrustData(game.trust);

  const trustString = L(trustData.name) + ' (' + game.trust.toFixed(0) + ')';

  if (agents_avail_all.length > 0) {
    statusString = L(companyStatusData.desc);
    integrityString = L(integrityData.name) + ' (' + integrity.toFixed(1) + ')';
    conditionString = L(conditionData.name) + ' (' + condition_average.toFixed(1) + ')';
    moodString = L(moodData.name) + ' (' + mood_average.toFixed(1) + ')';
    squadPowerString = squad_power_average.toFixed(1);
    statusOrder = companyStatusData.order;

    const agents_sorted = agents_avail_all.sort((a, b) => b.alarm_level - a.alarm_level);
    for (let i = 0; i < Math.min(4, agents_sorted.length); i++) {
      const agent = agents_sorted[i];
      if (agent.alarm_level > 0) {
        agentInfos.push(
          <div className="lobby-status-item-agent" key={agent.idx}>
            <PortraitWrapper onClick={() => onClickPortrait(agent)} agent={{ name: agent.name, role: agent.role }} className="lobby-status-item-agent-img" />
            <div className="lobby-status-item-agent-name">
              <RoleBadge role={agent.role} isLabel={true} />
              <div className="lobby-status-item-agent-name-lower">{agent.name}</div>
            </div>
            <button disabled={game.isMeetingDisabled(agent)} onClick={() => onScheduleCEO(schedule, agent)} title={L('loc_data_longtext_schedule_ceo_' + schedule_id + '_desc')}
              className='lobby-status-item-agent-button'>
              <span className='lobby-status-item-agent-button-label'>
                {L('loc_data_string_schedule_ceo_' + schedule_id)}
              </span>
            </button>
          </div>)
      }
    }
  } else {
    statusString = L('loc_ui_string_lobby_condition_no_employee');
    integrityString = '-';
    conditionString = '-';
    moodString = '-';
    squadPowerString = '-';
  }

  return (
    <div className="lobby-status-item-condition">
      {upperButton}
      <div className="lobby-status-item-header">
        <div className="lobby-status-item-label">{L('loc_ui_string_lobby_condition_atmosphere')}</div>
        <div className={"lobby-status-item-value underline" + evalCSSClass('status', statusOrder)}>{statusString}</div>
      </div>
      <div className="lobby-status-item-body">
        <div className="lobby-status-item-detail">
          <div className="lobby-status-item-label desc">{L('loc_ui_string_lobby_condition_integrity')}</div>
          <div className={"lobby-status-item-value" + evalCSSClass('integrity', integrity)}>{integrityString}</div>
        </div>
        <div className="lobby-status-item-detail">
          <div className="lobby-status-item-label desc">{L('loc_ui_string_lobby_condition_trust')}</div>
          <div className={"lobby-status-item-value" + evalCSSClass('trust', game.trust)}>{trustString}</div>
        </div>
        <div className="lobby-status-item-detail">
          <div className="lobby-status-item-label desc">{L('loc_ui_string_lobby_condition_average_stamina')}</div>
          <div className={"lobby-status-item-value" + evalCSSClass('condition', condition_average)}>{conditionString}</div>
        </div>
        <div className="lobby-status-item-detail">
          <div className="lobby-status-item-label desc">{L('loc_ui_string_lobby_condition_average_mood')}</div>
          <div className={"lobby-status-item-value" + evalCSSClass('mood', mood_average)}>{moodString}</div>
        </div>
        {/*  */}
        <div className="lobby-status-item-detail">
          <div className="lobby-status-item-label desc">{L('loc_ui_string_lobby_condition_average_power')}</div>
          <div className="lobby-status-item-value">{squadPowerString}</div>
        </div>
        <div className="lobby-status-item-subitem">
          <div className="lobby-status-item-subheader">
            <div className="lobby-status-item-label">{L('loc_ui_string_lobby_condition_agents_alarm')}</div>
            <div className="lobby-status-item-label">{L('loc_dynamic_string_lobby_condition_agents_alarm', { count: agentInfos.length })}</div>
          </div>
          <div className="lobby-status-item-subbody">
            {agentInfos}
          </div>
        </div>
      </div>

    </div>
  );
}

const svg_icon_gear = <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
  <path d="M15.3837 0L13.3198 4.91204C12.9071 5.03587 12.5356 5.24226 12.1641 5.44865L7.25202 3.38477L4.28003 6.35675L6.34391 11.2688C6.13752 11.6816 5.97241 12.0118 5.8073 12.4246L0.895264 14.4884V18.6162L5.8073 20.6801C5.97241 21.0929 6.13752 21.4231 6.34391 21.8359L4.28003 26.7479L7.25202 29.7199L12.1641 27.656C12.5356 27.8211 12.9071 28.0275 13.3198 28.1926L15.3837 33.1047H19.5115L21.5754 28.1926C21.9469 28.0275 22.3596 27.8624 22.7311 27.656L27.6432 29.7199L30.6152 26.7479L28.5513 21.8359C28.7164 21.4644 28.9228 21.0516 29.0879 20.6801L33.9999 18.6162V14.4884L29.0879 12.4246C28.9641 12.0531 28.7577 11.6403 28.5513 11.2688L30.6152 6.35675L27.6432 3.38477L22.7311 5.44865C22.3596 5.28354 21.9469 5.07715 21.5754 4.91204L19.5115 0L15.3837 0ZM17.4476 10.3194C20.8736 10.3194 23.6392 13.085 23.6392 16.5111C23.6392 19.9371 20.8736 22.7027 17.4476 22.7027C14.0216 22.7027 11.2559 19.9371 11.2559 16.5111C11.2559 13.085 14.0216 10.3194 17.4476 10.3194Z" fill="#D0BD8B" />
</svg>;

const svg_icon_flag = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="31" viewBox="0 0 15 31" fill="none">
  <path d="M12.6346 0.0990934L14.5012 0.0979614V30.9681H12L12 17.4752L0.604974 8.4545L12.6346 0.0990934Z" fill="#F2E4B5" />
</svg>;

const svg_icon_clipboard = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28.1165 8.31854H32V35H8V8.31854H11.8835V11.6778H28.1165V8.31854ZM13.1252 30.8693L18.5208 23.4981L24.0223 25.0027L29.0828 16.137L25.8243 14.2652L22.2074 20.6022L17.0207 19.1837L10.0988 28.6398L13.1252 30.8693ZM25.6131 6V9.16673H14.3866V6H25.6131Z" fill="#F2E4B5" />
</svg>

const svg_icon_checkbanner = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M20.0001 34L8 24.7011V6H32V24.7011L20.0001 34ZM11.9064 18.8101L19.1943 25.792L28.9078 14.2268L25.9994 11.9175L18.8933 20.3785L14.5417 16.2096L11.9064 18.8101Z" fill="#F2E4B5" />
</svg>;

const svg_icon_targetscope = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.56393 22.064H3V18.936H6.57354C7.30196 13.0154 12.01 8.31908 17.9361 7.60838V4H21.0641V7.60838C26.99 8.31908 31.698 13.0154 32.4265 18.936H36V22.064H32.4361C31.7403 28.0222 27.0167 32.7576 21.0641 33.4713V37H17.9361V33.4713C11.9833 32.7576 7.25967 28.0222 6.56393 22.064ZM19.5 10.6402C14.0362 10.6402 9.60024 15.0762 9.60024 20.5398C9.60024 26.0037 14.0362 30.4397 19.5 30.4397C24.9638 30.4397 29.3998 26.0037 29.3998 20.5398C29.3998 15.0762 24.9638 10.6402 19.5 10.6402ZM19.5 13.6614C23.2964 13.6614 26.3784 16.7437 26.3784 20.5398C26.3784 24.3362 23.2964 27.4183 19.5 27.4183C15.7039 27.4183 12.6216 24.3362 12.6216 20.5398C12.6216 16.7437 15.7039 13.6614 19.5 13.6614ZM19.5 16.7864C17.4284 16.7864 15.7464 18.4682 15.7464 20.5398C15.7464 22.6117 17.4284 24.2935 19.5 24.2935C21.5716 24.2935 23.2536 22.6117 23.2536 20.5398C23.2536 18.4682 21.5716 16.7864 19.5 16.7864Z" fill="#F2E4B5" />
</svg>;

export function LobbyLeftDrawer(props) {
  const { game } = props;
  const pending = game.pendings.find((p) => p.ty === 'mission');
  const nextMission_remaining = Math.ceil((pending.turn - game.turn) / TICK_PER_DAY);
  const nextCheckpoint_remaining = Math.ceil((game.checkpoint_turn - game.turn) / TICK_PER_DAY);

  const items = [];
  items.push({
    ty: 'mission',
    data: {
      name: game.squads[0].config_key,
    },
  })
  items.push({
    ty: 'nextMission',
    data: {
      remaining: nextMission_remaining,
    },
  })

  return (
    <div className="lobby-left-drawer">
      {items.map((item) => LobbyLeftDrawerItem({ item }))}
    </div>
  );
}

function LobbyLeftDrawerItem(props) {
  const { item } = props;
  const { label, icon, value, title } = buildLobbyLeftDrawerItem(item);
  return (
    <div className="lobby-left-drawer-item" key={label + value} title={title}>
      <div className="lobby-left-drawer-item-type">
        <span className="lobby-left-drawer-item-icon">{icon}</span>
        <span className="lobby-left-drawer-item-label">{label}</span>
      </div>
      <div className="lobby-left-drawer-item-value">{value}</div>
    </div>
  );
}

function buildLobbyLeftDrawerItem(item) {
  let label = '';
  let value = '';
  let title = '';
  let icon = null;
  // i18n ignore 32
  switch (item.ty) {
    case 'mission':
      label = '현재 임무';
      icon = svg_icon_clipboard;
      value = <>{item.data?.name}</>;
      title = '현재 임무 이름입니다: 나중에 개선될 예정';
      break;
    case 'nextMission':
      label = '다음 모의훈련일';
      icon = svg_icon_clipboard;
      value = <><span className="lobby-left-drawer-item-value highlight">{item.data?.remaining ?? -1}</span>일 남음</>
      title = '모의훈련일에는 주어진 임무 상황을 상정한 모의 훈련을 진행합니다.\n모의 훈련을 통해서 스쿼드가 얼마나 잘 싸우는지를 확인하고\n편성은 적합한지, 훈련은 충분한지, 장비는 적절한지를 판단할 수 있습니다.';
      break;
    case 'nextCheckpoint':
      label = '의뢰인 참관훈련';
      icon = svg_icon_checkbanner;
      value = <><span className="lobby-left-drawer-item-value highlight">{item.data?.remaining ?? -1}</span>일 남음</>;
      title = '의뢰인 참관훈련에서는 모의훈련을 진행할 때 의뢰인이 진척을 확인합니다.\n이 훈련에서 전체 구간의 중간지점에 설정된 체크포인트까지 진행하지 못하면\n실망한 의뢰인이 의뢰를 중단하여 게임오버를 맞이하게 됩니다.';
      break;
    case 'final':
      label = '최종 검수';
      icon = svg_icon_targetscope;
      value = <>
        모의훈련일<span className="lobby-left-drawer-item-value highlight">{item.data?.remaining ?? -1}</span>회 남음
      </>;
      title = '최종 검수 훈련은 주어진 임무 상황 전 최종 점검입니다.\n모든 구간의 진행도를 100%로 채우지 못하면 게임 오버를 맞이하게 됩니다.\n최종 검수 후에는 실제 임무를 진행하며, 실제 임무에서 사망한 용병은 모의전과 달리 실제로 사망하니\n최대한 불안 요소를 없애고 최종 검수를 통과해야 합니다.';
      break;
    default:
      label = '떠든 사람';
      value = '정진명';
      title = '이게 표시되고 있으면 무언가가 잘못된 것입니다.';
  }
  return {
    label,
    icon,
    value,
    title,
  };
}

export function LobbySquadDrawer(props) {
  const { game, onClickPortrait } = props;
  return (
    <div className="lobby-squad-drawer">
      <div className="lobby-squad-drawer-header">
        <div className="lobby-squad-drawer-header-label">{L('loc_ui_string_menu_squad')}</div>
        <div className="lobby-squad-drawer-header-value">{game.squads[0].agents.length}/4</div>
      </div>
      <div className="lobby-squad-drawer-body">
        {game.squads[0].agents.map((agent, i) => <LobbySquadDrawerItem key={i} agent={agent} onClickPortrait={onClickPortrait} />)}
      </div>
    </div>
  );
}

function LobbySquadDrawerItem(props) {
  const { agent, onClickPortrait } = props;

  return <div className="lobby-squad-drawer-item">
    <div className="lobby-squad-drawer-item-right">
      <div className="lobby-squad-drawer-item-row">
        <RenderAgentSimple agent={agent} onClick={onClickPortrait} />
      </div>
      <RenderAgentDetail agent={agent} />
    </div>
  </div>;
}

const svg_icon_grade = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25">
  <image id="img_0" x="0" y="0" width="22" height="25" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDIyIDI1IiBmaWxsPSJub25lIj4NCiAgPHBhdGggZD0iTTExIDJMMTUuNDYyNSA0Ljc3MDcyTDIwLjA5MzMgNy4yNUwxOS45MjUgMTIuNUwyMC4wOTMzIDE3Ljc1TDE1LjQ2MjUgMjAuMjI5M0wxMSAyM0w2LjUzNzUgMjAuMjI5M0wxLjkwNjczIDE3Ljc1TDIuMDc1IDEyLjVMMS45MDY3MyA3LjI1TDYuNTM3NSA0Ljc3MDcyTDExIDJaIiBmaWxsPSIjMkQyODIzIi8+DQogIDxwYXRoIGQ9Ik0xMS4yNjM3IDEuNTc1MjJMMTEgMS40MTE0NkwxMC43MzYzIDEuNTc1MjJMNi4yODczNyA0LjMzNzQ5TDEuNjcwNzMgNi44MDkyTDEuMzk3MDUgNi45NTU3M0wxLjQwNjk5IDcuMjY2MDJMMS41NzQ3NCAxMi41TDEuNDA2OTkgMTcuNzM0TDEuMzk3MDUgMTguMDQ0M0wxLjY3MDczIDE4LjE5MDhMNi4yODczOCAyMC42NjI1TDEwLjczNjMgMjMuNDI0OEwxMSAyMy41ODg1TDExLjI2MzcgMjMuNDI0OEwxNS43MTI2IDIwLjY2MjVMMjAuMzI5MyAxOC4xOTA4TDIwLjYwMyAxOC4wNDQzTDIwLjU5MyAxNy43MzRMMjAuNDI1MyAxMi41TDIwLjU5MyA3LjI2NjAyTDIwLjYwMyA2Ljk1NTczTDIwLjMyOTMgNi44MDkyTDE1LjcxMjYgNC4zMzc0OUwxMS4yNjM3IDEuNTc1MjJaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC40Ii8+DQo8L3N2Zz4=" opacity="1" />
  <image id="img_1" x="2" y="3" width="18" height="19" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDE4IDE5IiBmaWxsPSJub25lIj4NCiAgPHBhdGggZD0iTTEuOTc2NTMgNS40NDQ5OUw5IDEuMzg5OTlMMTYuMDIzNSA1LjQ0NDk5VjEzLjU1NUw5IDE3LjYxTDEuOTc2NTMgMTMuNTU1VjUuNDQ0OTlaIiBzdHJva2U9IiM0NTNFMzYiIHN0cm9rZS13aWR0aD0iMiIvPg0KPC9zdmc+" opacity="1" />
</svg>

function LobbySquadDrawerItemWeaponIcon(props) {
  const { firearm } = props;
  const { firearm_ty, firearm_rate } = firearm;
  return (
    <div className="lobby-squad-drawer-item-icon-weapon">
      <div className="lobby-squad-drawer-item-icon-number">{svg_icon_grade}<span>{firearm_rate}</span></div>
      <div className="lobby-squad-drawer-item-icon text-icon">{firearm_ty.toUpperCase()}</div>
    </div>
  );
}

const buttonLabels = [
  { key: 'squad', label: 'loc_ui_string_menu_squad' },
  { key: 'mission', label: 'loc_ui_string_mission_plan_title' },

  { key: null },

  { key: 'recruits', label: 'loc_ui_string_menu_headhunter' },
  { key: 'agents', label: 'loc_ui_string_menu_employee_list' },
  { key: 'shop', label: 'loc_ui_string_menu_market' },
  { key: 'inventory', label: 'loc_ui_string_menu_armory' },

  { key: null },

  { key: 'call_superior', label: 'loc_data_string_schedule_ceo_call_superior' },
  // { key: 'mission_objective', label: 'loc_ui_string_missionbrief_contract_information' },
  { key: 'mission_final', label: 'loc_ui_string_lobby_button_next_mission_final' },
];

export function LobbyCenter(props) {
  const { game, onClickMenu, onClicks } = props;
  let idx = 0;
  const buttons = buttonLabels.map(({ key, label }) => {
    if (key === null) {
      return <hr />;
    }
    const onClick = onClicks[key] ?? null;
    const i = idx++;
    return <LobbyLeftFooterButton key={i} label={`[${i + 1}] ${L(label)}`} onClick={onClick} highlighted={game.highlight_button[`lobby_${key}`]} />;
  });

  return (
    <div className="lobby-center">
      {buttons}
    </div>
  );
}

function LobbyCenterButton(props) {
  const { label, onClick } = props;
  return (
    <SoundButton key={label} disabled={onClick === null} className="lobby-center-button" onClick={onClick}>{L(label)}</SoundButton>
  );
}

export function LobbyLeftFooter(props) {
  const { onClickMenu, onClicks } = props;
  const buttons = buttonLabels.map(({ key, label }, i) => {
    const onClick = onClicks[key];
    return <LobbyLeftFooterButton key={i} label={label} onClick={onClick} />;
  });

  return (
    <div className="lobby-left-footer">
      <div className="lobby-status-menu" onClick={onClickMenu}>{svg_icon_gear}</div>
      {/* {buttons} */}
    </div>
  );
}

function LobbyLeftFooterButton(props) {
  const { label, onClick, highlighted } = props;
  const highlighted_suffix = highlighted ? ' highlighted' : '';
  return (
    <SoundButton key={label} disabled={onClick === null} className={`lobby-left-footer-button${highlighted_suffix}`} onClick={onClick}>{L(label)}</SoundButton>
  );
}

export function LobbyRightFooter(props) {
  const { game, label, desc, buttonEnabled, highlighted, onClickNextAction, overrideNextActionButtons, mainButtonVisible, onClickMainButton, mainButtonLabel, mainButtonHighlighted, onClickFinalButton, finalEnabled } = props;

  let NextActionButtons = null;
  let newDesc = '';
  if (overrideNextActionButtons) {
    NextActionButtons = [];
    for (const actionButton of overrideNextActionButtons) {
      if (!actionButton) {
        continue;
      }
      NextActionButtons.push(<LobbyRightFooterButton
        key={actionButton.key}
        label={L(actionButton.label)}
        desc={L(actionButton.desc)}
        onClick={() => { game.highlight_button[actionButton.key] = false; actionButton.onClick(); }}
        enabled={actionButton.enabled && !game.disable_button[actionButton.key]}
        className={actionButton.className}
        cueList={actionButton.cueList}
        highlighted={game.highlight_button[actionButton.key]}
      />);
    }
    newDesc = overrideNextActionButtons[0].desc;
  }

  return <div className="lobby-right-footer">
    {
      onClickFinalButton ?
        <div className='lobby-right-footer-upper'>
          {<LobbyRightFooterButton className={'final'} label={L('loc_ui_string_lobby_button_next_mission_final')} desc={L('loc_ui_longtext_lobby_button_next_mission_final_desc')} onClick={onClickFinalButton} enabled={finalEnabled} />}
        </div> : null
    }
    <div className='lobby-right-footer-buttons'>
      {mainButtonVisible ? <LobbyRightFooterButton label={mainButtonLabel ?? L('loc_ui_string_lobby_button_next_back')} desc={L('loc_ui_longtext_lobby_button_next_back_desc')} onClick={onClickMainButton} enabled={true} highlighted={mainButtonHighlighted} /> : null}
      {NextActionButtons ?? <LobbyRightFooterButton label={label} desc={desc} onClick={onClickNextAction} enabled={buttonEnabled} highlighted={highlighted} />}
    </div>
  </div>;
}

const svg_icon_play = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <circle cx="20" cy="20" r="20" fill="#3D301C" />
  <path d="M29.5782 20.8566C30.2235 20.4678 30.2235 19.5322 29.5782 19.1434L15.266 10.521C14.5995 10.1195 13.75 10.5995 13.75 11.3776L13.75 28.6224C13.75 29.4005 14.5995 29.8805 15.266 29.479L29.5782 20.8566Z" fill="#DFA612" />
</svg>

function LobbyRightFooterButton(props) {
  const { label, desc, enabled, onClick, className, cueList, highlighted } = props;
  return <TooltipContext.Consumer>
    {(context) => {
      return <>
        <div
          {...figmaTooltipEvents(context, desc)}
        >
          <SoundButton
            key={label}
            className={"lobby-right-footer-button primary" + (enabled ? " enabled" : " disabled") + (highlighted ? " highlighted" : "") + (className ? " " + className : "")}
            disabled={!enabled}
            onClick={onClick}
            cueList={cueList ?? ['UI_Outgame_Button_Click_Yellow']}
          >
            <p className="action figma-tooltip-marker">{L(label)}</p>
          </SoundButton>
        </div>
      </>;
    }}
  </TooltipContext.Consumer>;
}

export function LobbyVideo(props) {
  const src = "video/transparent_bg_test.webm";
  let videoRef = React.createRef();
  const handleOnEnded = (e) => {
    let video = videoRef.current;
    video.playbackRate = -1;
    video.currentTime = video.duration;
    video.play();
  };
  return (
    <div className="lobby-video-overlay">
      <div className="lobby-video-container lobby-video-container-1">
        <video className="lobby-video-player" autoPlay loop muted>
          <source src={src} type="video/webm" />
        </video>
      </div>
      <div className="lobby-video-container lobby-video-container-2">
        <video className="lobby-video-player" autoPlay loop muted
          ref={videoRef}
          onEnded={handleOnEnded}
        >
          <source src={src} type="video/webm" />
        </video>
      </div>
    </div>
  );
}

export class LobbyView extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef();
    this.state = {
      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
      game: {
        turn: 7,
        resources: {
          balance: 1600,
        },
        config: {
          epoch_offset: 0,
          descr: 'loc_ui_string_lobby_condition_average_power',
          deadline_weeks: 4,
        },
        agents_avail_all: [
          {
            condition: 30,
            mood: 80,
            power: 20,
            modifier: [],
          }
        ],
        integrity: 70,
        trust: 30,
        squads: [
          {
            config_key: 'sabotage0'
          }
        ],
        milestones: CONFIGS.find((c) => c.key === 'sabotage0').milestones,
        milestoneStates: [
          {
            "key": "obj_proto_check_pass_3",
            "current": 0,
            "pass": false
          },
          {
            "key": "obj_proto_average_power_25",
            "current": 0,
            "pass": false
          },
          {
            "key": "obj_proto_survival_70",
            "current": 0,
            "pass": false
          },
          {
            "key": "obj_proto_agent_6",
            "current": 0,
            "pass": true
          }
        ],
        highlight_button: {},
      }
    };
  }

  render() {
    const { label, desc, buttonEnabled, overrideNextActionButtons } = this.props;
    const { game } = this.state;
    return (
      <TooltipContext.Provider value={{
        ctx: this.state.tooltipContext,
        setCtx: (ctx) => {
          this.setState({ tooltipContext: ctx });
        }
      }}>
        <div className="outgame-lobby" onMouseMove={(e) => {
          if (!e._tooltipmove) {
            this.setState({ tooltipContext: { ...this.state.tooltipContext, content: null } });
          }
        }}>

          <div className="lobby2-header">
            <Lobby2HeaderPrimary game={game} />
            <div className="lobby2-header-divider" />
            <Lobby2HeaderSecondary game={game} />
          </div>
          <Lobby2Clipboard game={game} />
          <LobbyVideo />
          <Lobby2SideMenu game={game} />
          <Lobby2Footer game={game} />
          {/* <LobbyLeftDrawer game={game} checkpoints={[]} />
          <LobbySquadDrawer game={game} />
          <LobbyLeftFooter game={game} onClickMenu={null} {...buttonLabels.map(({ key }) => ({ [key]: () => { } }))} />
          <LobbyRightFooter game={game} label={label} desc={desc} buttonEnabled={buttonEnabled} overrideNextActionButtons={overrideNextActionButtons} /> */}
        </div>
        {renderTooltip(this.tooltipRef)}
      </TooltipContext.Provider>
    );
  }
}

const svg_icon_gear2 = <svg className="lobby-header-primary-button-menu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M10.1538 0H13.8462L14.3584 3.07324C15.1692 3.28688 15.9364 3.60808 16.6438 4.02064L19.1797 2.20929L21.7905 4.82015L19.9792 7.35598C20.3919 8.06342 20.7131 8.8307 20.9268 9.64164L24 10.1538V13.8462L20.9268 14.3584C20.7131 15.1692 20.3919 15.9364 19.9794 16.6438L21.7909 19.1798L19.18 21.7907L16.644 19.9792C15.9366 20.3919 15.1693 20.7131 14.3584 20.9268L13.8462 24H10.1538L9.64164 20.9268C8.83072 20.7131 8.06345 20.3919 7.35602 19.9792L4.81997 21.7907L2.20911 19.1799L4.02062 16.6438C3.60807 15.9364 3.28688 15.1692 3.07324 14.3584L0 13.8462V10.1538L3.07324 9.64164C3.28691 8.8307 3.60815 8.06342 4.02077 7.35598L2.20946 4.82014L4.82031 2.20928L7.35621 4.02064C8.06359 3.60808 8.83079 3.28688 9.64164 3.07324L10.1538 0ZM12 15.6923C14.0392 15.6923 15.6923 14.0392 15.6923 12C15.6923 9.9608 14.0392 8.30769 12 8.30769C9.9608 8.30769 8.30769 9.9608 8.30769 12C8.30769 14.0392 9.9608 15.6923 12 15.6923Z" fill="#828282" />
</svg>;

export function Lobby2HeaderPrimary(props) {
  const { game, onClickMenu, onClickDetail, cueList } = props;
  const { turn } = game;
  const { epoch_offset, descr, deadline_weeks } = game.config;
  const { balance } = game.resources;

  let timeString = '';
  let ddd = 'Thu';
  let hhmm = '07:00';
  let D = '1';
  let MMM = 'Jan';
  let YYYY = '1970';

  ({ ddd, hhmm, D, MMM, YYYY } = tickTodddhhmmDMMMYYYY(turn + epoch_offset));

  const nextInspection = (((5 - tickTod(turn + epoch_offset)) % 7) + 7) % 7;
  const nextFinal = deadline_weeks * 7 - Math.floor((turn / 24));

  return <div className="lobby2-header-primary">
    <div className="lobby2-header-primary-left">
      <div className="lobby2-header-primary-menu" onClick={onClickMenu}>{svg_icon_gear2}</div>
      <div className="lobby2-header-primary-daytime">
        <span>{ddd}</span>
        <span>{hhmm}</span>
      </div>
      <div className="lobby2-header-primary-ddMMyyyy">
        <span>{D}</span>
        <span>{MMM}</span>
        <span>{YYYY}</span>
      </div>
    </div>
    <div className="lobby2-header-primary-center">
      <div className="lobby2-header-primary-mission-title">{L(descr)}</div>
      <div className="lobby2-header-primary-center-divider" />
      <div className="lobby2-header-primary-schedule">
        <span className="lobby2-header-primary-item-desc">Inspection</span>
        <span className="lobby2-header-primary-item-values">
          <span className="lobby2-header-primary-item-value">{nextInspection}</span>
          <span className="lobby2-header-primary-item-unit">days left</span>
        </span>
      </div>
      <div className="lobby2-header-primary-center-divider" />
      <div className="lobby2-header-primary-timelimit">
        <span className="lobby2-header-primary-item-desc">D-Day</span>
        <span className="lobby2-header-primary-item-values">
          <span className="lobby2-header-primary-item-value">{nextFinal}</span>
          <span className="lobby2-header-primary-item-unit">days left</span>
        </span>
      </div>
    </div>
    <div className="lobby2-header-primary-right">
      <div className="lobby2-header-primary-resource">
        <span>$ {balance.toLocaleString('en-US')}</span>
      </div>
      <div className="lobby2-header-primary-button-box">
        <SoundButton
          key="lobby2-header-primary-button-detail"
          className="lobby2-header-primary-button-detail"
          onClick={onClickDetail ? onClickDetail : () => { }}
          cueList={cueList ?? ['UI_Outgame_Button_Click_Yellow']}
          disabled={!onClickDetail}
        >
          <span>{L('loc_ui_string_popup_weekly_report')}</span>
        </SoundButton>
      </div>
    </div>
  </div >;
}

export function Lobby2HeaderSecondary(props) {
  const { game } = props;
  const { integrity, agents_avail_all, trust, squads } = game;

  let statusString = '-';
  let integrityString = '-';
  let integrityBadge = null;
  let moodString = '-';
  let moodBadge = null;
  let powerString = '-';
  let squadPowerBadge = '-';
  let statusOrder = '5';

  const condition_average = agents_avail_all.reduce((acc, agent) => acc + agent.condition, 0) / agents_avail_all.length;
  const mood_average = agents_avail_all.reduce((acc, agent) => acc + agent.mood, 0) / agents_avail_all.length;
  const power_average = agents_avail_all.reduce((acc, agent) => acc + agent.power + getGearPower(agent).power, 0) / agents_avail_all.length;
  const squad_agents_length = squads[0].agents.length;
  const squad_power_average = squad_agents_length > 0 ? squads[0].agents.reduce((acc, agent) => acc + agent.power + getGearPower(agent).power, 0) / squad_agents_length : 0;

  const integrityData = getIntegrityData(integrity);
  const companyStatusData = getCompanyStatusData(integrity, condition_average, mood_average, power_average);
  const moodData = getMoodData(mood_average);
  const trustData = getTrustData(trust);

  const trustString = L(trustData.name);
  const trustValue = trust.toFixed(0);

  if (agents_avail_all.length > 0) {
    statusString = L(companyStatusData.desc);
    integrityString = L(integrityData.name);
    integrityBadge = <div className="lobby2-header-secondary-item-value-badge">
      <span className="lobby2-header-secondary-item-value-value">{integrity.toFixed(1)}</span>
    </div>;
    moodString = L(moodData.name);
    moodBadge = <div className="lobby2-header-secondary-item-value-badge">
      <span className="lobby2-header-secondary-item-value-value">{mood_average.toFixed(1)}</span>
    </div>;
    squadPowerBadge = <div className="lobby2-header-secondary-item-value-badge">
      <span className="lobby2-header-secondary-item-value-value">{squad_power_average.toFixed(1)}</span>
    </div>;
    statusOrder = companyStatusData.order;
  } else {
    statusString = L('loc_ui_string_lobby_condition_no_employee');
  }

  // TODO: this.changeIntegrity(-1);
  const daily_integrity = _.sum(game.agents_avail_all.map((agent) => agentModifierEffectValue(agent, 'daily_integrity')));

  const tooltip = {
    'atmosphere': L("loc_ui_string_lobby_condition_atmosphere_tooltip"),
    'integrity': <div>
      <p>{L("loc_ui_string_lobby_condition_integrity_tooltip")}</p>
      <p>{L("loc_ui_string_lobby_condition_integrity_tooltip_diff")}<span className={evalCSSClass('value', daily_integrity)}>{daily_integrity}</span></p>
    </div >,
    'trust': L("loc_ui_string_lobby_condition_trust_tooltip"),
    'average_mood': L("loc_ui_string_lobby_condition_average_mood_tooltip"),
    'average_power': L("loc_ui_string_lobby_condition_average_power_tooltip"),
  };

  return <div className="lobby2-header-secondary">
    <TooltipContext.Consumer>
      {(context) => {
        return <div className="lobby2-header-secondary-item" {...figmaTooltipEvents(context, tooltip.atmosphere)}>
          <span className="lobby2-header-secondary-item-desc">{L('loc_ui_string_lobby_condition_atmosphere')}</span>
          <span className={"lobby2-header-secondary-item-value" + evalCSSClass('status', statusOrder)}>
            <span className="lobby2-header-secondary-item-value-desc">{statusString}</span>
          </span>
        </div>;
      }}
    </TooltipContext.Consumer>
    <div className="lobby2-header-secondary-divider" />
    <TooltipContext.Consumer>
      {(context) => {
        return <div className="lobby2-header-secondary-item" {...figmaTooltipEvents(context, tooltip.integrity)}>
          <span className="lobby2-header-secondary-item-desc">{L('loc_ui_string_lobby_condition_integrity')}</span>
          <span className={"lobby2-header-secondary-item-value" + evalCSSClass('integrity', integrity)}>
            <span className="lobby2-header-secondary-item-value-desc">{integrityString}</span>
            {integrityBadge}
          </span>
        </div>;
      }}
    </TooltipContext.Consumer>
    <div className="lobby2-header-secondary-divider" />
    <TooltipContext.Consumer>
      {(context) => {
        return <div className="lobby2-header-secondary-item" {...figmaTooltipEvents(context, tooltip.trust)}>
          <span className="lobby2-header-secondary-item-desc">{L('loc_ui_string_lobby_condition_trust')}</span>
          <span className={"lobby2-header-secondary-item-value" + evalCSSClass('trust', trust)}>
            <span className="lobby2-header-secondary-item-value-desc">{trustString}</span>
            <div className="lobby2-header-secondary-item-value-badge">
              <span className="lobby2-header-secondary-item-value-value">{trustValue}</span>
            </div>
          </span>
        </div>;
      }}
    </TooltipContext.Consumer>
    <div className="lobby2-header-secondary-divider" />
    <TooltipContext.Consumer>
      {(context) => {
        return <div className="lobby2-header-secondary-item" {...figmaTooltipEvents(context, tooltip.average_mood)}>
          <span className="lobby2-header-secondary-item-desc">{L('loc_ui_string_lobby_condition_average_mood')}</span>
          <span className={"lobby2-header-secondary-item-value" + evalCSSClass('mood', mood_average)}>
            <span className="lobby2-header-secondary-item-value-desc">{moodString}</span>
            {moodBadge}
          </span>
        </div>;
      }}
    </TooltipContext.Consumer>
    <div className="lobby2-header-secondary-divider" />
    <TooltipContext.Consumer>
      {(context) => {
        return <div className="lobby2-header-secondary-item" {...figmaTooltipEvents(context, tooltip.average_power)}>
          <span className="lobby2-header-secondary-item-desc">{L('loc_ui_string_lobby_condition_squad_average_power')}</span>
          <span className="lobby2-header-secondary-item-value">
            {squadPowerBadge}
          </span>
        </div>;
      }}
    </TooltipContext.Consumer>
  </div>;
}

const sideMenuItemLabels = [
  { key: 'timer', label: 'loc_dynamic_longtext_lobby_menu_next_inspection' },

  { key: null },

  { key: 'squad', label: 'loc_ui_string_menu_squad' },
  { key: 'mission', label: 'loc_ui_string_mission_plan_title' },
  { key: 'recruits', label: 'loc_ui_string_menu_headhunter' },
  { key: 'agents', label: 'loc_ui_string_menu_employee_list' },
  { key: 'shop', label: 'loc_ui_string_menu_market' },
  { key: 'inventory', label: 'loc_ui_string_menu_armory' },
  { key: 'call_superior', label: 'loc_data_string_schedule_ceo_call_superior' },
  // { key: 'mission_objective', label: 'loc_ui_string_missionbrief_contract_information' },
  { key: 'mission_final', label: 'loc_ui_string_lobby_button_next_mission_final' },
];

export function Lobby2SideMenu(props) {
  const { game, onClicks, focusedMenu } = props;
  const { turn, milestone_week } = game;
  const { epoch_offset } = game.config;

  const nextInspection0 = (((5 - tickTod(turn + epoch_offset)) % 7) + 7) % 7;
  const nextInspection = nextInspection0 !== 0 ? nextInspection0 :
    turn / 24 / 7 - milestone_week > 0 ? 0 : 7;

  const svg_side_menu_divider = (key) => <svg key={key} className="lobby2-side-menu-divider" xmlns="http://www.w3.org/2000/svg" width="66" height="4" viewBox="0 0 66 4" fill="none">
    <rect width="66" height="2" fill="url(#paint0_linear_749_16980)" />
    <rect y="2" width="66" height="2" fill="url(#paint1_linear_749_16980)" />
    <defs>
      <linearGradient id="paint0_linear_749_16980" x1="0" y1="1" x2="66" y2="1" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6E6B59" stopOpacity="0" />
        <stop offset="0.25" stopColor="#6E6B59" />
        <stop offset="0.75" stopColor="#6E6B59" />
        <stop offset="1" stopColor="#6E6B59" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_749_16980" x1="0" y1="3" x2="66" y2="3" gradientUnits="userSpaceOnUse">
        <stop stopColor="#151410" stopOpacity="0" />
        <stop offset="0.25" stopColor="#151410" />
        <stop offset="0.75" stopColor="#151410" />
        <stop offset="1" stopColor="#151410" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>;

  const menuItems = sideMenuItemLabels.map(({ key, label }, i) => {
    if (key === null) {
      return svg_side_menu_divider(`divider_${i}`);
    }
    const onClick = onClicks?.[key];
    return <div key={key} className={"lobby2-side-menu-item " + key + (onClick === null ? ' disabled' : '')} onClick={onClick}>
      <div className="lobby2-side-menu-item-label">
        <div className="lobby2-side-menu-item-label-bg" />
        <div className="lobby2-side-menu-item-label-content">{key === 'timer' ? L(label, { count: nextInspection }) : L(label)}</div>
      </div>
      <div className={"lobby2-side-menu-item-icon " + key + (key === 'timer'
        ? ' timer' + nextInspection.toFixed(0)
        : (key === focusedMenu ? ' current' : ''))}
      >
        {game.highlight_button[`lobby_${key}`] ? <Lobby2Highlight /> : null}
      </div>
    </div>;
  });

  return <div className="lobby2-side-menu">
    {menuItems}
  </div>;
}

export function Lobby2Goals(props) {
  const { states: milestoneStates, show_result } = props;

  const mains = [];
  const subs = [];

  const makeBody = (i, state) => {
    const { key, value, pass } = state;
    let { current } = state;
    if (Math.round(current) !== current) {
      current = current.toFixed(1);
    }
    return <div key={i} className={"lobby2-clipboard-section-goal-item" + (pass ? " pass" : "")}>
      <div className="lobby2-clipboard-section-goal-item-header">
        {pass
          ? <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19 35.8291C28.3888 35.8291 36 28.2179 36 18.8291C36 9.44026 28.3888 1.8291 19 1.8291C9.61116 1.8291 2 9.44026 2 18.8291C2 28.2179 9.61116 35.8291 19 35.8291ZM19.5417 27.924C23.8119 21.1211 26.406 18.0051 31.5884 12.9835L27.4131 8.67456C22.8619 13.0846 20.0924 16.2307 16.7269 21.2342C15.1232 19.4025 13.463 17.7891 10.9976 15.5902L7.00391 20.0679C10.559 23.2387 12.1767 24.9617 14.6184 28.1523L17.2444 31.5838L19.5417 27.924Z" fill="#A7E482" />
          </svg>
          : <div className="lobby2-clipboard-section-goal-item-bullet" />}
      </div>
      <div className="lobby2-clipboard-section-goal-item-body">
        <span className="lobby2-clipboard-section-goal-item-label">
          {L(`loc_data_longtext_mission_objective_${key}`, { value: +value })}
        </span>
        <span className="lobby2-clipboard-section-goal-item-label">
          :
        </span>
        <span className="lobby2-clipboard-section-goal-item-value">
          <span className="lobby2-clipboard-section-goal-item-value-cur">{current}</span>
          /{value}
        </span>
      </div>
    </div>;
  }

  for (let i = 0; i < milestoneStates.main.length; i++) {
    const body = makeBody(i, milestoneStates.main[i]);
    mains.push(body);
  }
  for (let i = 0; i < milestoneStates.sub.length; i++) {
    const body = makeBody(i, milestoneStates.sub[i]);
    subs.push(body);
  }

  let cls_main = 'lobby2-clipboard-section-header-box';
  let cls_sub = 'lobby2-clipboard-section-header-box';
  let label_main = L('loc_data_longtext_main_objective_desc', { pass_score: MAIN_PASS_SCORE, fail_score: -MAIN_FAIL_SCORE_NORMAL });
  let label_sub = L('loc_data_longtext_bonus_objective_desc', { bonus_score: SUB_BONUS_SCORE });
  if (show_result) {
    cls_main += ' result';
    cls_sub += ' result';

    let pass_main = milestoneStates.main.every((m) => m.pass);
    if (pass_main) {
      cls_main += ' pass';
      label_main = L('loc_data_longtext_main_objective_pass', { pass_score: MAIN_PASS_SCORE });
    } else {
      cls_main += ' fail';
      label_main = L('loc_data_longtext_main_objective_fail', { fail_score: -MAIN_FAIL_SCORE_NORMAL });
    }

    if (!pass_main) {
      label_sub = L('loc_data_longtext_bonus_objective_deactivate');
    }
    else {
      let pass_sub_count = milestoneStates.sub.filter((s) => s.pass).length;
      label_sub = L('loc_data_longtext_bonus_objective_count', { count: pass_sub_count });
      if (pass_sub_count > 0) {
        label_sub += L('loc_data_longtext_bonus_objective_score', { score: pass_sub_count * SUB_BONUS_SCORE });
        cls_sub += ' pass';
      }
    }
  }

  return <div className="lobby2-clipboard-section goals">
    <div className="lobby2-clipboard-section goal main">
      <div className="lobby2-clipboard-section-header">
        <span>{L('loc_ui_string_lobby_main_objective')}</span>
        <div className={cls_main}>
          <span>{label_main}</span>
        </div>
      </div>
      <div className="lobby2-clipboard-section-goal-list">
        {mains}
      </div>
    </div>
    <div className="lobby2-clipboard-section goal sub">
      <div className="lobby2-clipboard-section-header">
        <span>{L('loc_ui_string_lobby_bonus_objective')}</span>
        <div className={cls_sub}>
          <span>{label_sub}</span>
        </div>
      </div>
      <div className="lobby2-clipboard-section-goal-list">
        {subs}
      </div>
    </div>
  </div>;
}

export function Lobby2Quests(props) {
  const { game } = props;
  const { quests, turn } = game;
  const bodies = [];

  const makeBody = (i, quest) => {
    const { id, complete_condition, complete_condition_value, quest_giver_idx, start, term, generator_idx, target_ty } = quest;
    let { current_value } = quest;
    const preset = data_questPresets.find((d) => d.id === id);
    const remain_days = Math.ceil((start + term - turn) / 24);
    const duration_text = L('loc_dynamic_string_lobby_quest_remain_days', { count: remain_days });
    const quest_giver = game.agents_avail_all.find((a) => a.idx === quest_giver_idx);
    const target = target_ty === 'generator' ? game.agents_avail_all.find((a) => a.idx === generator_idx) : quest_giver;
    const title_text = L(preset.title);
    const desc_text = game.makeQuestDescText(quest);
    if (Math.round(current_value) !== current_value) {
      current_value = current_value.toFixed(1);
    }

    return <div key={i} className={"lobby2-clipboard-section-quest-item"}>
      <TooltipContext.Consumer>
        {(context) => {
          return <>
            <div
              {...figmaTooltipEvents(context, desc_text)}
            >
              <div className='lobby2-clipboard-section-quest-item-title'>
                <PortraitWrapper agent={{ name: quest_giver.name, role: quest_giver.role }} className="lobby-status-item-agent-img" />
                <span className="lobby2-clipboard-section-quest-item-label">
                  {title_text}
                </span>
              </div>
              <div className="lobby2-clipboard-section-quest-item-body">
                <div className="lobby2-clipboard-section-quest-item-body-header">
                  <div className="lobby2-clipboard-section-quest-item-bullet" />
                </div>
                <span className="lobby2-clipboard-section-quest-item-label">
                  {L(`loc_data_longtext_quest_objective_${complete_condition}`, { value: complete_condition_value, name_target: L(target?.name) })}({duration_text})
                </span>
                {
                  complete_condition_value ?
                    <>
                      <span className="lobby2-clipboard-section-goal-item-label">
                        :
                      </span>
                      <span className="lobby2-clipboard-section-goal-item-value">
                        <span className="lobby2-clipboard-section-goal-item-value-cur">{current_value}</span>
                        /{complete_condition_value}
                      </span>
                    </>
                    : null
                }
              </div>
            </div>
          </>;
        }}
      </TooltipContext.Consumer>;
    </div>;
  }

  for (let i = 0; i < quests.length; i++) {
    const body = makeBody(i, quests[i]);
    bodies.push(body);
  }

  return <div className="lobby2-clipboard-section goals">
    <div className="lobby2-clipboard-section goal main">
      <div className="lobby2-clipboard-section-header">
        <span>{L('loc_ui_string_lobby_quest')}</span>
      </div>
      <div className="lobby2-clipboard-section-goal-list">
        {bodies}
      </div>
    </div>
  </div>;
}

export function Lobby2Clipboard(props) {
  const { game } = props;
  const { config_key } = game.squads[0];
  const config = CONFIGS.find((c) => c.key === config_key);

  const { income, squad_size, objective } = config;
  const weeks = config.deadline_weeks;
  const states = game.getMilestoneStates();

  return <div className="lobby2-clipboard">
    <div className="lobby2-clipboard-inner">
      <div className="lobby2-clipboard-section summary">
        <div className="lobby2-clipboard-section-header">
          <span>{L('loc_ui_string_missionbrief_contract_information')}</span>
        </div>
        <div className="lobby2-clipboard-section-body">
          <div className="lobby2-clipboard-section-item-name">
            <div className="lobby2-clipboard-section-item-desc">
              <div className="lobby2-clipboard-section-item-desc-bullet" />
              <span className="lobby2-clipboard-section-item-desc-label">{L('loc_ui_string_missionbrief_mission_title')}:</span>
            </div>
            <span className="lobby2-clipboard-section-item-value-name">{L(config.descr)}</span>
          </div>
          <div className="lobby2-clipboard-section-item-subsection">
            <div className="lobby2-clipboard-section-item-objective">
              <div className="lobby2-clipboard-section-item-desc">
                <div className="lobby2-clipboard-section-item-desc-bullet" />
                <span className="lobby2-clipboard-section-item-desc-label">{L('loc_ui_string_missionbrief_mission_objective')}:</span>
              </div>
              <span className="lobby2-clipboard-section-item-value-objective">{L(objective.text)}</span>
            </div>
            <div className="lobby2-clipboard-section-details">
              <div className="lobby2-clipboard-section-detail-item">
                <div className="lobby2-clipboard-section-detail-item-desc-bullet" />
                <span className="lobby2-clipboard-section-detail-item-desc-label">{L('loc_ui_string_missionbrief_mission_term')}:</span>
                <span className="lobby2-clipboard-section-item-value">{L('loc_dynamic_string_missionbrief_weeks', { count: weeks })}</span>
              </div>
              <div className="lobby2-clipboard-section-detail-item">
                <div className="lobby2-clipboard-section-detail-item-desc-bullet" />
                <span className="lobby2-clipboard-section-detail-item-desc-label">{L('loc_ui_string_missionbrief_mission_reward')}:</span>
                <span className="lobby2-clipboard-section-item-value">{L('loc_dynamic_string_missionbrief_pay_per_week', { value: income.toLocaleString("en-US") })}</span>
              </div>
              <div className="lobby2-clipboard-section-detail-item">
                <div className="lobby2-clipboard-section-detail-item-desc-bullet" />
                <span className="lobby2-clipboard-section-detail-item-desc-label">{L('loc_ui_string_missionbrief_mission_squad_size')}:</span>
                <span className="lobby2-clipboard-section-item-value">{L('loc_dynamic_string_missionbrief_mission_squad_size', { count: squad_size })}</span>
              </div>
            </div>
          </div>
          {game.enable_final ? <img src="/img/lobby/UI_Stamp_Approved.png" className="lobby2-clipboard-stamp-approved" /> : null}
        </div>
      </div>
      {game.quests.length > 0 ?
        <div className="lobby2-clipboard-section-root">
          <Lobby2Goals states={states} style={"width=50%"} />
          <div className="lobby2-clipboard-section-divider" />
          <Lobby2Quests game={game} style={"width=50%"} />
        </div> :
        <Lobby2Goals states={states} />}

      <div className="lobby2-clipboard-section-border lobby2-clipboard-section-desc">
        {L(objective.descr)}
      </div>
    </div>
  </div >;
}

export function Lobby2Footer(props) {
  const { game, label, desc } = props;
  const { buttonEnabled, onClickNextAction, overrideNextActionButtons } = props;
  const { mainButtonVisible, onClickMainButton, mainButtonLabel, mainButtonHighlighted } = props;
  const { onClickFinalButton, finalEnabled } = props;
  const { highlighted } = props;

  let NextActionButtons = null;
  let newDesc = '';
  if (overrideNextActionButtons) {
    NextActionButtons = [];
    for (const actionButton of overrideNextActionButtons) {
      if (!actionButton) {
        continue;
      }
      NextActionButtons.push(<Lobby2PageButton
        key={actionButton.key}
        label={L(actionButton.label)}
        desc={L(actionButton.desc)}
        onClick={() => { game.highlight_button[actionButton.key] = false; actionButton.onClick(); }}
        enabled={actionButton.enabled && !game.disable_button[actionButton.key]}
        className={actionButton.className}
        cueList={actionButton.cueList}
        highlighted={game.highlight_button[actionButton.key]}
      />);
    }
    newDesc = overrideNextActionButtons[0].desc;
  }

  return <div className="lobby2-footer">
    {onClickFinalButton ?
      <Lobby2PageButton
        className={'final'}
        label={L('loc_ui_string_lobby_button_next_mission_final')}
        desc={L('loc_ui_longtext_lobby_button_next_mission_final_desc')}
        onClick={onClickFinalButton}
        enabled={finalEnabled}
      /> : null}
    {mainButtonVisible ?
      <Lobby2PageButton
        label={mainButtonLabel ?? L('loc_ui_string_lobby_button_next_back')}
        desc={L('loc_ui_longtext_lobby_button_next_back_desc')}
        onClick={onClickMainButton}
        enabled={true}
        highlighted={mainButtonHighlighted}
      /> : null}
    {NextActionButtons ??
      <Lobby2PageButton
        label={label}
        desc={desc}
        onClick={onClickNextAction}
        enabled={buttonEnabled}
        highlighted={highlighted}
      />}
  </div>;
}

export function Lobby2PageButton(props) {
  const { label, desc, enabled, onClick, className, cueList, highlighted } = props;
  const props0 = {
    key: label,
    className: "lobby2-page-button" + (enabled ? "" : " disabled") + (highlighted ? " highlighted" : "") + (className ? " " + className : ""),
    disabled: !enabled,
    onClick: enabled ? onClick : null,
    cueList: cueList ?? ['UI_Outgame_Button_Click_Yellow'],
  };

  return <TooltipContext.Consumer>
    {(context) => {
      return <>
        <div
          {...figmaTooltipEvents(context, L(desc))}
        >
          <div
            key={label}
            {...soundprops(props0)}
          >
            <div className="lobby2-page-button-emboss" />
            <div className="lobby2-page-button-background" />
            <div className="lobby2-page-button-outline">
              <span className={"lobby2-page-button-label" + (desc ? " figma-tooltip-marker" : "")}>{L(label)}</span>
            </div>
            {highlighted ? <Lobby2Highlight /> : null}
          </div>
        </div>
      </>;
    }}
  </TooltipContext.Consumer>;
}

export function Lobby2Highlight(props) {

  return <div className="lobby2-highlight">
    <div className="lobby2-highlight-shadow" />
    <div className="lobby2-highlight-background" />
    <div className="lobby2-highlight-stroke" />
    <div className="lobby2-highlight-glow" />
    <div className="lobby2-highlight-inner" />
  </div>;
}
