import source from '../downloaded/nationalities.json' assert { type: 'json' };

export const nationalities = [];
for (const row of source) {
  let { key, _nation_kor, nation, languages, background, weight } = row;

  nationalities.push({
    key,
    nation,
    _nation_kor,
    languages: languages.split(',').map((x) => x.trim()),
    background,
    weight: +weight,
  });
}

