import source from '../downloaded/char2nationalities.json' assert { type: 'json' };

export const nationalities = [];
for (const row of source) {
  let { key, name, weight } = row;

  nationalities.push({
    key: key,
    name: name,
    weight: +weight,
  });
}
