import { v2 } from "./v2.mjs";

// 스쿼드 순서 조작
class OrderController {
  constructor(simctrl) {
    this.sim = simctrl.sim;
    this.simctrl = simctrl;

    this.activate = false;
  }

  canOrder() {
    const { sim } = this;
    return !sim.isEngageArea();
  }

  onActivate() {
    if (!this.canOrder()) {
      this.onDeactivate();
      return;
    }
    if (this.simctrl.active_ctrl !== 'none') {
      return;
    }

    this.simctrl.timescale = 0;
    this.activate = true;

    this.simctrl.active_ctrl = 'order';
  }

  onDeactivate() {
    this.simctrl.timescale = 1;
    this.activate = false;
    this.simctrl.active_ctrl = 'none';
  }

  onAction(ev) {
    const { sim } = this;
    const { ty } = ev;

    if (ty === 'toggle-order') {
      if (this.activate) {
        this.onDeactivate();
      } else {
        this.onActivate();
      }
    } else if (ty === 'order-swap') {
      const { id1, id2 } = ev;
      const { entities } = this.sim;

      [entities[id1], entities[id2]] = [entities[id2], entities[id1]];
      sim.squadUpdate();
    }
  }

  onSerialize() {
    const { sim } = this;

    const serial = {};
    serial.activate = this.activate;
    const ally = sim.entities.filter((e) => e.team === 0);
    serial.btn_info = ally.map((e, idx) => {
      let activate = true;
      if (!this.activate || e.state === 'dead') {
        activate = false;
      }

      let up_idx = idx - 1;
      while (up_idx >= 0 && ally[up_idx].state === 'dead') {
        up_idx--;
      }
      const up_activate = up_idx >= 0;

      let down_idx = idx + 1;
      while (down_idx < ally.length && ally[down_idx].state === 'dead') {
        down_idx++;
      }
      const down_activate = down_idx < ally.length;

      return {
        activate,
        up_idx,
        up_activate,
        down_idx,
        down_activate,
      };
    });
    serial.can_order = this.canOrder();
    return { order_ctrl: serial };
  }
}

// 힐팩 수동 조작
class HealController {
  constructor(simctrl) {
    this.sim = simctrl.sim;
    this.simctrl = simctrl;

    this.activate = false;

    this.entity = null;
    this.heal = null;
  }

  canHeal() {
    const { sim } = this;
    return sim.nextHealPack() !== null;
  }

  onActivate() {
    if (!this.canHeal()) {
      return;
    }
    if (this.simctrl.active_ctrl !== 'none') {
      return;
    }

    this.activate = true;
    this.simctrl.timescale = 0;

    const { sim } = this;
    const { entity, heal } = sim.nextHealPack();
    this.entity = entity;
    this.heal = heal;

    this.simctrl.active_ctrl = 'heal';
  }

  onDeactivate() {
    this.activate = false;
    this.simctrl.timescale = 1;
    this.simctrl.active_ctrl = 'none';
  }

  isHealTarget(target) {
    const { entity } = this;

    if (target.state === 'dead' && !entity.perk2_medic_paramedics_advanced) {
      return false;
    }
    if (target.life === target.life_max) {
      return false;
    }
    if (target === entity && !entity.perk_commed_target_self && !entity._perk_commed_heal_self) {
      return false;
    }

    return true;
  }

  onHeal(target) {
    const { sim, entity } = this;
    sim.entityTryHeal(entity, target, {
      disposable: true,
      is_manual: true,
    });
    this.onDeactivate();
  }

  onAction(ev) {
    const { ty } = ev;
    const { sim } = this;

    if (ty === 'toggle-heal') {
      if (this.activate) {
        this.onDeactivate();
      } else {
        this.onActivate();
      }
    } else if (ty === 'heal') {
      if (ev.heal_target_idx !== undefined) {
        ev.heal_target = sim.entities[ev.heal_target_idx];
      }
      this.onHeal(ev.heal_target);
    }
  }

  onSerialize() {
    const { sim } = this;

    const serial = {};
    serial.activate = this.activate;
    serial.btn_activate = sim.entities.filter((e) => e.team === 0).map((e) => {
      if (!this.activate) {
        return false;
      }
      if (!this.isHealTarget(e)) {
        return false;
      }
      return true;
    });
    serial.can_heal = this.canHeal();
    return { heal_ctrl: serial };
  }
};

// TODO: 상속.. 대체로 별로 의미 없는 듯?
// - UI controller랑 시뮬레이션 컨트롤러가 분리된건지?
// - 아니면, 플레이어의 상호작용이 있는 control에서 대해, web controller랑 unreal controller를 따로 만드는건지?
class ThrowableController {
  constructor(simctrl) {
    this.sim = simctrl.sim;
    this.simctrl = simctrl;

    this.activate = false;

    this.target = null; // target_entity
    this.throwable = null;
    this.targetpos = new v2(0, 0);

    this.reserve_target = null;
  }

  canThrow() {
    const { sim } = this;
    if (!sim.nextThrowable()) {
      return false;
    }

    const { segment } = sim;
    if (segment && segment.use_throwable) {
      return false;
    }

    return true;
  }

  stateThrow(pos) {
    const { sim, target } = this;
    const { segment } = sim;
    if (segment && segment.use_throwable) {
      return 'disable';
    } else {
      return sim.canThrowThrowable(target, pos);
    }
  }

  cancelReserve() {
    if (!this.reserve_target) {
      return;
    }

    this.reserve_target.reserve_throwable = undefined;
    this.reserve_target = null;
  }

  onActivate() {
    if (!this.canThrow()) {
      return;
    }
    if (this.reserve_target) {
      this.cancelReserve();
      return;
    }
    if (this.simctrl.active_ctrl !== 'none') {
      return;
    }
    this.activate = true;
    this.simctrl.timescale = 0;
    this.simctrl.active_ctrl = 'throwable';
  }

  onDeactivate() {
    this.activate = false;
    this.simctrl.timescale = 1;
    this.simctrl.active_ctrl = 'none';
  }

  onThrow(entity, pos) {
    const { sim } = this;

    sim.entityTryThrowables(entity, pos);
    this.onDeactivate();
  }

  onReserve(entity, throwable, pos) {
    const { sim } = this;

    // 이미 예약한 오퍼레이터가 있는 경우
    this.cancelReserve();

    if (sim.entityReserveThrowable(entity, throwable, pos)) {
      this.reserve_target = entity;
      this.onDeactivate();
    }
  }

  onControllerTick() {
    const { sim, activate } = this;

    const ret = sim.nextThrowable();
    if (ret) {
      this.throwable = ret.throwable;
      this.target = ret.entity;
    } else {
      this.throwable = null;
      this.target = null;
      if (activate) {
        this.onDeactivate();
      }
    }

    // 예약된 오퍼레이터의 수류탄을 사용하면 비활성화합니다.
    if (this.reserve_target && !this.reserve_target.reserve_throwable) {
      this.cancelReserve();
    }
  }

  onMouseMove(ev) {
    const { activate } = this;
    const { worldpos } = ev;
    if (!activate) {
      return;
    }
    this.targetpos = worldpos;
  }

  onMouseDown(ev) {
    const { activate, target, throwable } = this;
    if (!activate) {
      return false;
    }

    if (target) {
      const { targetpos } = this;

      const throw_state = this.stateThrow(targetpos);

      if (throw_state === 'ok') {
        this.onThrow(target, targetpos);
      } else if (throw_state === 'no') {
        this.onReserve(target, throwable, targetpos);
      }
    }

    return false;
  }

  onAction(ev) {
    const { ty } = ev;
    if (ty === 'toggle-throwable') {
      if (this.activate) {
        this.onDeactivate();
      } else {
        this.onActivate()
      }
      return true;
    } else if (ty === 'pointer-click') {
      this.onMouseDown(ev);
    } else if (ty === 'pointer-move') {
      this.onMouseMove(ev);
    }

    return false;
  }

  onSerialize() {
    const ret = {};
    ret.activate = this.activate;
    ret.target = this.target;
    ret.throwable = this.throwable;
    ret.targetpos = this.targetpos;
    ret.reserve_target = this.reserve_target;
    ret.state = this.activate ? this.stateThrow(this.targetpos) : null;
    ret.can_throw = this.canThrow();

    return { throwable_ctrl: ret };
  }
};


// Sim 외부에서 조작하는 행동을 관리합니다. 주로 인게임 상호작용 관련 시스템을 다룹니다.
// SimView -> SimController -> sim
export class SimController {
  constructor(sim) {
    this.sim = sim;
    this.controllers = {
      throwable_ctrl: new ThrowableController(this),
      heal_ctrl: new HealController(this),
      order_ctrl: new OrderController(this),
    }

    this.timescale = 1.0;
    this.tick = 0;

    this.active_ctrl = 'none';
  }

  onAction(ev) {
    const { controllers } = this;
    if (ev?.ty === 'cancel') {
      for (const ctrl of Object.values(controllers)) {
        ctrl?.onDeactivate?.();
      }
    } else {
      for (const ctrl of Object.values(controllers)) {
        if (ctrl?.onAction && ctrl?.onAction(ev)) {
          return true;
        }
      }
    }

    return false;
  }

  onControllerTick() {
    const { controllers } = this;
    for (const ctrl of Object.values(controllers)) {
      if (ctrl?.onControllerTick) {
        ctrl?.onControllerTick();
      }
    }
  }

  onTick() {
    const { sim, timescale } = this;

    this.onControllerTick();
    this.onSerialize();
    this.tick += 1;

    if (timescale === 0) {
      return 'skip';
    }

    const ctrl_tps = 1 / timescale;
    if (this.tick % ctrl_tps === 0) {
      return sim.onTick();
    }
    return 'skip';
  }

  onSerialize() {
    let simctrl = {};

    for (const ctrl of Object.values(this.controllers)) {
      if (ctrl?.onSerialize) {
        simctrl = {
          ...simctrl,
          ...ctrl.onSerialize(),
        };
      }
    }

    this.sim.simctrl = simctrl;
  }
};
