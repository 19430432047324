import './App.css';
import React from 'react';

import { presets, preset_default } from './presets_testbed';
import { Simulator } from './sim';
import { Rng } from './rand';
import { SimView } from './SimView';
import { WinCounter } from './App';

export function TestbedSelector(props) {
  const { presets, onUpdate, disabled } = props;

  const [preset, setPreset] = React.useState(preset_default);
  const [variants, setVariants] = React.useState(presets[preset]());
  const [variant, setVariant] = React.useState(Object.keys(variants)[0]);

  const [initialize, setInitialize] = React.useState(false);
  if (!initialize) {
    setInitialize(true);
    if (!disabled) {
      onUpdate(variants[variant]);
    }
  }

  return <div className="box">
    <h1>presets: testbed</h1>
    <div>
      {Object.keys(presets).map((p) => {
        const cls = (!disabled && p === preset) ? 'selected' : '';
        return <button className={cls} onClick={() => {

          const variants = presets[p]();
          const variant = Object.keys(variants)[0];

          setPreset(p);
          setVariants(variants);
          setVariant(variant);

          onUpdate(variants[variant]);
        }} key={p}>{p}</button>;
      })}
    </div>
    <div>
      variants
      {Object.keys(variants).map((p) => {
        const cls = (!disabled && p === variant) ? 'selected' : '';
        return <button className={cls} onClick={() => {
          setVariant(p);
          onUpdate(variants[p]);
        }} key={p}>{p}</button>;
      })}
    </div>
  </div>;
}

export class Testbed extends React.Component {
  constructor(props) {
    super(props);

    const seed = Rng.randomseed();

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.restartTimer = null;

    this.keyBind = this.keyDown.bind(this);

    this.state = {
      simstate: null,
      config: null,
      seed,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyBind);
    this.onSimstateReset();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyBind);
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport() {
    const state = { ...this.state.simstate };
    state.seed = this.simRef.current.state.seed;

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport() {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({ simstate: state });
    this.simRef.current.restart();
  }

  onSeed(seed) {
    this.setState({
      seed,
    }, () => {
      this.simRef.current.restart();
    });
  }

  onUpdate(config) {
    const simstate = config.preset();
    this.setState({ config, simstate }, () => {
      this.onSimstateReset(this);
      this.simRef.current.restart();
      // window.localStorage.setItem('preset_selected_testbed', p);
    });
  }

  onSimstateReset() {
    const { config } = this.state;
    if (!config) {
      return;
    }

    const simview = this.simRef.current;
    const { onsim } = config;
    simview.restart(() => {
      if (onsim) {
        onsim(simview.state.sim);
      }
    });
  }

  onFinish(res) {
    const view = this.simRef.current;

    if (this.restartTimer !== null) {
      return;
    }

    if (res >= 0) {
      this.winCounterRef.current?.addWin(res);
    }

    const newSeed = Rng.randomseed();

    view.stopTimer();
    this.restartTimer = setTimeout(() => {
      this.setState({
        seed: newSeed,
      }, () => {
        view.restart();
        this.restartTimer = null;
        view.startTimer();
      });
    }, 1000);
  }

  onOfflineSim() {
    // TODO: change seed
    // const seed = this.state.seed;
    const seed = Rng.randomseed();
    const view = this.simRef.current;

    const sim = Simulator.create({ ...view.props, seed, m: this.props.m });
    // reset runtime seed
    const seed2 = Rng.randomseed();
    sim.rng = new Rng(seed2);

    // console.log('before', sim);
    const start = Date.now();
    while (sim.onTick() === -1 && sim.tick < 10000) { }
    const res = sim.onTick();
    if (res >= 0) {
      this.winCounterRef.current.addWin(res);
    }
    sim.free();
    const dt = Date.now() - start;
    console.log(`took ${dt}ms, ${(sim.tick * 1000 / dt).toFixed(1)}tps`, res, sim);

    return res;
  }

  onOfflineSimStat(samples) {
    for (let i = 0; i < samples; i++) {
      this.onOfflineSim();
    }
  }

  keyDown(ev) {
    if (ev.key === 'b') {
      this.onOfflineSim();
    } else if (ev.key === 'B') {
      this.onOfflineSimStat(100);
    }
  }

  render() {
    const { simstate, seed } = this.state;

    let simview = null;
    if (simstate) {
      simview = <SimView ref={this.simRef}
        m={this.props.m}
        seed={seed}
        debug={true}
        onFinish={(res) => this.onFinish(res)}
        simstate={simstate}
      />;
    }

    return <>
      <p>shortcuts: (r) restart / (R) restart with same map / (z) zoom / (Tab) change speed / ( ) pause/resume / (s) single-step</p>
      <TestbedSelector presets={presets} onUpdate={(config) => this.onUpdate(config)} />

      <div>
        save&load
        <button onClick={this.onExport.bind(this)}>export</button>
        <button onClick={this.onImport.bind(this)}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <button onClick={() => this.onSeed(seed)}>load seed</button>
      <input type="text" placeholder="seed" value={seed} onChange={(e) => this.setState({ seed: parseInt(e.target.value, 10) || 0 })}></input>
      <button onClick={() => { this.onSeed(Rng.randomseed()) }}> randomize </button>
      <button onClick={() => { this.onSeed(seed + 1) }}>&nbsp;+&nbsp;</button>
      <button onClick={() => { this.onSeed(seed - 1) }}>&nbsp;-&nbsp;</button>
      {simview}
      <WinCounter ref={this.winCounterRef} />
    </>;
  }
}
