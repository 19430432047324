import { StatList } from './StatList';

function AgentDetails(props) {
  const { character } = props;
  const { stats } = character;

  const style = {
    margin: '1em',
  };

  function getPortraitPath(id) {
    try {
      return require(`../data/portrait/${id}.jpg`);
    } catch (err) {
      return require(`../data/portrait/hui.jpeg`);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={style}>
        <img src={getPortraitPath(stats.id)} alt='X' style={{ width: 150 }} />
      </div >
      <StatList style={style} stats={stats} />
    </div >
  );
}

export default AgentDetails;
