/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PerkSlot = ({
  onMouseMove = "D",
  onMouseOut = "D",
  onMouseOver = "D",
  onClick = "D",
  label = "PERK",
  state,
  vEafe = "/anima/img/v-344-8eafe.svg",
  perkimg = "/anima/img/perkimg-sample-3.png",
  overlapGroupClassName,
  perkimgSample = "/anima/img/perkimg-sample5.png",
  v = "/anima/img/v1fb2a71d-3.svg",
  img = "/anima/img/perkimg-sample-3.png",
  className,
  vector = "/anima/img/vector-170.svg",
  frame = "/anima/img/frame.svg",
  vb = "/anima/img/vb-5-6c-6-1c-1.svg",
  vE = "/anima/img/v48146e38-3.svg",
  vf = "/anima/img/vf6ee8613-3.svg",
  vFceEc = "/anima/img/v5fce79ec-3.svg",
  v1 = "/anima/img/v90633387-3.svg",
  frameEc = "/anima/img/frame8ec93630-3.svg",
}) => {
  return (
    <div className={`perk-slot ${className}`}>
      {["branch-a", "branch-b", "completed", "locked", "unlocked"].includes(state) && (
        <div className={`rectangle-9 state-${state}`}>
          {state === "branch-a" && (
            <div className="rectangle-wrapper">
              <div className="rectangle-10" />
            </div>
          )}

          {state === "completed" && (
            <div className="frame-37">
              <div className="overlap-group-13">
                <img className="v-e" alt="V e" src={vE} />
                <img className="vf" alt="Vf" src={vf} />
                <img className="v" alt="V" src={vFceEc} />
                <img className="v-2" alt="V" src={v1} />
                <div className="rectangle-11" />
                <div className="rectangle-12" />
                <div className="rectangle-13" />
                <div className="rectangle-14" />
                <img className="frame-ec" alt="Frame" src={frameEc} />
              </div>
            </div>
          )}

          {["completed", "locked", "unlocked"].includes(state) && (
            <div className="bg-2">
              {["completed", "unlocked"].includes(state) && (
                <img
                  className={`v-fb-a-d ${state === "unlocked" ? overlapGroupClassName : undefined}`}
                  alt="V"
                  src={state === "unlocked" ? vEafe : v}
                />
              )}

              {state === "locked" && (
                <div className={`vector-wrapper ${overlapGroupClassName}`}>
                  <img className="vector-8" alt="Vector" src={vector} />
                </div>
              )}

              <div className="rectangle-15" />
            </div>
          )}

          {["completed", "locked"].includes(state) && <div className="PT-perkname">{label}</div>}

          {state === "completed" && (
            <>
              <div className="image-perk">
                <img className="perkimg-sample-2" alt="Perkimg sample" src={img} />
              </div>
              <div className="text-wrapper-6"></div>
              <div className="text-wrapper-6"></div>
              <div className="text-wrapper-6"></div>
              <div className="text-wrapper-6" onClick={() => typeof onClick === "function" ? onClick() : null} onMouseOut={(e) => typeof onMouseOut === "function" ? onMouseOut(e) : null} onMouseOver={(e) => typeof onMouseOver === "function" ? onMouseOver(e) : null} onMouseMove={(e) => typeof onMouseMove === "function" ? onMouseMove(e) : null} style={{zIndex: 20}}>D</div>
            </>
          )}

          {state === "unlocked" && (
            <>
              <div className="bg-3" />
              <div className="PT-perkname-bold">{label}</div>
            </>
          )}

          {["locked", "unlocked"].includes(state) && (
            <img className="f-f-a" alt="F f a" src={state === "locked" ? frame : "/anima/img/f-9-2f-5-7a-26.svg"} />
          )}

          {state === "unlocked" && (
            <>
              <div className="perkimg-wrapper">
                <img className="perkimg-sample-2" alt="Perkimg" src={perkimg} />
              </div>
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7" onClick={() => typeof onClick === "function" ? onClick() : null} onMouseOut={(e) => typeof onMouseOut === "function" ? onMouseOut(e) : null} onMouseOver={(e) => typeof onMouseOver === "function" ? onMouseOver(e) : null} onMouseMove={(e) => typeof onMouseMove === "function" ? onMouseMove(e) : null} style={{zIndex: 20}}>D</div>
            </>
          )}

          {state === "locked" && (
            <>
              <div className="img-wrapper">
                <img className="perkimg-sample-2" alt="Perkimg" src={perkimgSample} />
              </div>
              <img className="vb" alt="Vb" src={vb} />
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7"></div>
              <div className="text-wrapper-7" onClick={() => typeof onClick === "function" ? onClick() : null} onMouseOut={(e) => typeof onMouseOut === "function" ? onMouseOut(e) : null} onMouseOver={(e) => typeof onMouseOver === "function" ? onMouseOver(e) : null} onMouseMove={(e) => typeof onMouseMove === "function" ? onMouseMove(e) : null} style={{zIndex: 20}}>D</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

PerkSlot.propTypes = {
  onMouseMove: PropTypes.any,
  onMouseOut: PropTypes.any,
  onMouseOver: PropTypes.any,
  onClick: PropTypes.any,
  label: PropTypes.string,
  state: PropTypes.oneOf(["branch-x", "completed", "branch-a", "branch-b", "unlocked", "locked"]),
  vEafe: PropTypes.string,
  perkimg: PropTypes.string,
  perkimgSample: PropTypes.string,
  v: PropTypes.string,
  img: PropTypes.string,
  vector: PropTypes.string,
  frame: PropTypes.string,
  vb: PropTypes.string,
  vE: PropTypes.string,
  vf: PropTypes.string,
  vFceEc: PropTypes.string,
  v1: PropTypes.string,
  frameEc: PropTypes.string,
};
