import source from '../downloaded/staffAbility.json' assert { type: 'json' };


export const staffAbilities = [];
for (const row of source) {
    let { staff_ability_key, staff_ability_name, staff_ability_desc } = row;

    staffAbilities.push({
        key: staff_ability_key,
        name: staff_ability_name,
        desc: staff_ability_desc,
    });
}
