import React from 'react';
import './cohtml.js';
import { SimOverlay } from './SimOverlay.mjs';
import { localeGet, localeSet } from './localization.mjs';

export class GamefaceSimOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: localeGet(),
      data: null,
    };
    engine.on('Update', (data) => {
      data = JSON.parse(data);
      if (data.locale && data.locale !== this.state.locale) {
        localeSet(data.locale);
        this.setState({ locale: data.locale });
      }
      this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    if (data === null) {
      return null;
    }

    return <SimOverlay data={data}
      onSelectPrompt={(prompt_idx, option_idx) => {
        // TODO: gameface에서 controller action event를 보낼 때, 여기를 참고하면 됨
        engine.call('GamefaceCallback', JSON.stringify({ ty: 'select_prompt', prompt_idx, option_idx }));
      }}
      onSendAction={(ty, args) => {
        engine.call('GamefaceCallback', JSON.stringify({ ty, ...args }));
      }} />;
  }
}
