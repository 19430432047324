import { opts_local } from './opts.local.mjs';

// persistent properties
export const ENTITY_CONFIG_TMPL = {
  ty: 'agent',
  team: 0,
  life: 100,
  speed: 20,
  size: 2.5,

  vis_range: 400,
  vis_var: Math.PI / 3,

  // 색적 파라미터
  // vis_thres: 적에게 인지당하기 위해 필요한 awareness의 최소값. 낮을수록 빠르게 감지됩니다.
  // aware_mult: 적이 시야에 완전히 들어왔을 때, 매 프레임당 증가하는 awareness의 양.
  //   누적된 awareness가 vis_thres를 넘으면 적을 인지합니다.
  // aware_decay: 매 프레임당 awareness가 decay하는 양.
  vis_thres: 0.8,
  aware_mult: 0.1,
  aware_decay: 0.99,

  // 반응 속도
  //  - 피격에 대한 반응 속도
  response_time: 0.2,

  // 화기 스펙 시작
  firearm_ty: 'ar',
  firearm_ammo_max: 30,
  firearm_range: 320,
  firearm_aimvar_mult: 1,

  // 매 tick마다 이동으로 벌어질 수 있는 aim의 최대값입니다.
  firearm_aimvar_incr_move_cap: 0.01,

  // 매 tick마다 회전으로 벌어질 수 있는 aim의 최대값입니다.
  firearm_aimvar_incr_rot_cap: 0.3,

  // 한 사격 시퀀스 안에서의 사격 패턴. 예를 들어 firearm_shoot_pattern이 [0.1,
  // 0.1, 0.1]인 경우, 0.1초 간격으로 네 번 연속으로 사격합니다. 이후,
  // firearm_shoot_pattern_interval_sec 만큼 사격을 중지한 후 다음 사격
  // 시퀀스를 시작합니다.
  firearm_shoot_pattern: [0.2, 0.2],
  firearm_shoot_pattern_interval_sec: 0.5,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_penetrate: 33,
  // 매 사격에서 projectile의 방향은 다음 단계로 나뉘어 결정됩니다.
  //  - 먼저, aimvar (현재 agent의 aim을 표현하는 arc) 중 한 방향으로 총구의
  //  방향이 정해집니다. 해당 shoot에서 발사되는 모든 projectile의 기준 총구
  //  방향은 이 방향으로 고정됩니다.
  //  - 이후, 각 projectile이 총구 방향에서 얼마나 분산되는지 결정합니다. 이
  //  정도는 아래 firearm_projectile_aimvar 값에 따라 결정됩니다. 0인 경우,
  //  projectile은 항상 총구 방향으로 발사됩니다. PI/2인 경우, 총구 방향에서
  //  좌우 45도(PI/4) 사이로 발사됩니다.
  firearm_projectile_aimvar: Math.PI / 256,

  // 재장전 소요 시간
  firearm_reload_duration: 2.5,

  // 피격 판정에 이 만큼의 이득을 제공합니다. 예를 들어, 0.1인 경우 피격 확률이
  // 10%p 상승합니다.
  firearm_additional_hit_prob: 0,
  firearm_additional_crit_prob: 0,
  firearm_crit_mult: 1,

  // 아머가 남아있고 아머에 명중한 경우 체력을 손상시키지 않습니다.
  firearm_armor_stop: false,

  // 시뮬레이션에서는 대략 10m를 0.1초정도에 이동. 100m/s정도의 속도로 표현됨.
  // 시뮬레이션의 길이 단위는 10cm고, 아래 단위는 cm/s.
  firearm_projectile_speed: 1000,

  firearm_armor_degrade_over: 30,
  firearm_armor_degrade_under: 10,

  // agent가 목표물을 조준할 때, 조준 대상 방향과 현재 방향 사이의 각거리
  // (angular distance)에 따른 agent의 회전 속도를 정의합니다. 이 값을 조절하여
  // 조준 대상 방향과 현재 방향의 차이가 큰 경우 조준이 빠르게, 차이가 적은
  // 경우 조준이 느리게 진행되도록 조절할 수 있습니다.
  aim_rot_rules: [
    // { aimvar: Math.PI / 16, aimspeed: 0.01 },
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  // agent의 바라보는 방향과 진행 방향이 다를 때 이동 속도를 정의합니다. 이
  // 값을 조절하여 몇몇 시나리오에서 agent가 신중하게 움직이고, 위협에 빠르게
  // 대응할 수 있도록 할 수 있습니다.
  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  // projectile이 조준 대상에게 한 개 이상 맞을 확률이 아래 이상일 경우에만
  // 사격합니다.
  aim_samples_fire_thres: 0.0,

  // 화기 스펙 끝

  firearm_reload_idle_visibility_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  // 오랜 시간 움직이지 않고 가만히 있는 경우에 대한 최소 aim입니다.
  aimvar_hold_max: Math.PI / 256,
  // 매 사격마다 aim이 얼마나 벌어지는지 결정합니다.
  aimvar_incr_per_shoot: 0.4,

  // aimvar이 증가하는 수준, global multiplier
  aimvar_mult: 1.0,

  // 피격당했을 때 aim이 얼마나 벌어지는지 결정합니다.
  aimvar_incr_per_hit: 0.4,

  // 매 tick마다 아래 값 만큼 aimvar이 decay합니다. 낮을수록 aim이 빠르게 개선됩니다.
  aimvar_decay_per_tick: 0.9,

  // 방호복 내구도. 방호복에 피격하는 경우, life 대신 방호복의 내구도가
  // 소모됩니다. 방호복의 내구도가 0인 경우, 방호복에 피격하는 경우에도 life가
  // 감소합니다.
  armor: 0,

  // stats3
  // 명중 관련 스텟
  accuracy: 1.0,
  evasion: 0.0,
  reload_speed: 1.0,

  // behavioral options
  allow_crawl: false,
  allow_hide: false,
  allow_fire_control: false,
  allow_coordinated_fire: false,

  // 장애물 뒤에 숨어 교전합니다.
  allow_cover_edge: false,

  // 문 진입 전, 해당 문으로 진입하려고 하는 다른 agent를 기다립니다.
  allow_door_wait: true,

  // 엄폐하고 있고 사격당할 때, 다른 위치로 이동하지 않습니다.
  allow_uncover_on_fire: false,

  // 리더인 경우, follower를 기다립니다
  allow_wait_follower: true,

  // 팔로워인 경우, 리더 근처에 머뭅니다.
  allow_follow_leader: true,

  // 엄폐 이동 관련. 엄폐물까지의 거리가 아래보다 가까울 경우, crawl하는 대신 엄폐물까지 이동합니다.
  // TODO: 지금 고장나있을텐데?? movespeed_rules랑 동작이 충돌함.
  waypoint_dash_dist: 100,

  // cover, fire, cover-fire, capture, idle
  default_rule: 'mission',

  use_visibility: true,
  use_riskdir: false,
  riskdir_use_visibility_grid: false,

  spawnarea: 0,

  // 20230111 스텟 관련 파라미터
  // 판단력: aimtarget 업데이트 관련 파라미터
  // retarget 평가 주기 범위
  retarget_interval_range: [1.0, 2.0],
  // 정확한 대상 선정 확률
  retarget_accurate_prob: 1.0,

  hit_prob_stand: 1,
  hit_prob_obstructed: 1,
  // 엄폐: 이것만 opts에서 entity로 가져옴
  hit_prob_covered: 0.8,

  // 투척: 최대 투적 거리를 정합니다.
  throwable_max_dist: 250,

  throwable_score_direct: 2,
  throwable_score_indirect: 1,
  throwable_score_thres: 3,

  // 치명타 확률
  crit_prob: 0.03,

  // hard-coded perks
  perk_firstshoot_amp: false,
  perk_firstshoot_hit: false,
  perk_instant_reload: false,
  perk_kill_recover: false,
  perk_lastshoot_amp: false,
  perk_lastshoot_hit: false,
  perk_shoot_ignore_obstructed: false,
  // TODO: journal에 안 나옴
  // TODO: journal에 안 나옴
  perk_targetpref_low: false,
  perk_suppress: false,
  perk_reload_one_more: false,
  perk_aimtarget_incr_aimvar: false,
  perk_smg_fast_move: false,
  perk_armor_recover: false,

  // tree perks
  // grenadier
  perk_grenadier_throw_range: false,
  perk_grenadier_effect_range: false,
  perk_grenadier_effect_amount: false,
  perk_grenadier_shorter_blast: false,

  // desmar. 화기 종류 제한 있는 perk은 outgame에서 적당히 필터링할 것
  perk_desmar_distraction: false,
  perk_desmar_distraction_range: false,
  perk_desmar_damage: false,
  perk_desmar_aimspeed: false,
  perk_desmar_priority_defensive: false,
  perk_desmar_priority_offensive: false,

  // sg
  perk_sg_projectile: false,

  // sr
  perk_sr_critical: false,

  // commed
  perk_commed_target_self: false,
  perk_commed_workspeed: false,
  perk_commed_amount: false,
  perk_commed_powerup: false,
  perk_commed_buff: false,

  // strike
  perk_aim_together: false,
  perk_retarget_fast: false,
  perk_aim_execute: false,
  perk_hit_antiarmor: false,
  perk_hit_disruptive: false,
  perk_recoil_steady: false,
  perk_reduce_cover_effect: false,
  perk_pierce_moving_enemy: false,
  perk_piercing_bullet: false,
  perk_damage_standing: false,
  perk_damage_move_crawl: false,
  perk_lastshoot: false,

  // supress
  perk_miss_supress: false,
  perk_cover_steady: false,
  perk_hit_incr_aimvar: false,
  perk_moving_reload: false,
  perk_crawl_reload: false,
  perk_cover_reload: false,
  perk_vision_distance: false,
  perk_unidir_sense: false,
  perk_breach_seethrough: false,
  perk_cover_effect: false,
  perk_cover_dash: false,
  perk_after_cover: false,

  // aggro
  perk_armor_effect: false,
  perk_armor_first: false,
  perk_damp_aimvar_incr: false,
  perk_engage_dash: false,
  perk_move_cover: false,
  perk_targetpref_high: false,
  perk_recover_armor: false,
  perk_recover_life: false,
  perk_glancing_blow: false,
  perk_standing_evade: false,
  perk_moving_recoil: false,
  perk_cover_reload_evade: false,

  perk_egress_damage_damper: 0,
  perk_ingress_damage_damper: 0,

  // 던질 것 목록
  throwables: [],

  // 브리칭 차지
  attachables: [],

  // 회복 아이템 목록
  heals: [],
};

export const PARAMS = {
  // modifier params
  movestate_low_params: {
    multipliers: {
      speed: 0.8,
      aimvar_hold: 0.4,
      aimvar_mult: 0.4,
      vis_thres: 0.5,
    },
  },

  movestate_run_params: {
    multipliers: {
      speed: 1.4,
      aimvar_hold: 1.2,
      aimvar_mult: 1.2,
    },
  },

  limvis_params: {
    multipliers: {
      vis_range: 0.01,
      speed: 0.3,
    },
  },

  incr_speed_params: {
    multipliers: {
      speed: 10,
    },
  },

  stun_gr_params: {
    multipliers: {
      vis_range: 0.05,
      speed: 0,
      aimvar_hold: 1000.0,
      aimvar_hold_max: 1000.0,
    },
  },

  mortar_params: {
    multipliers: {
      speed: 0.35,
      firearm_additional_hit_prob: -20,
    },
  },

  swamp_params: {
    values: {
      allow_crawl: false,
    },
    multipliers: {
      speed: 0.7,
    },
  },
};

export const GOALSTATE_TMPL = {
  occupying_team: -1,
  occupy_tick: 0,
  owner: -1,
  count_team0: 0,
  count_team1: 0,
};

export const opts = {
  tps: 30,

  // TODO: mission, goal, eliminate
  SIMOVER_RULE: 'mission',

  SIMTPS: 30,
  SIMTPS_OPTS: [30, 60, 120, -1],

  GRID_SIZE: 10,
  GRID_VIS_PER_TICK: 0.1,
  GRID_VIS_OMNIDIR_PER_TICK: 0.001,

  VIS_DECAY_INTERVAL: 1.0,
  VIS_DECAY_PER_SEC: 0.1,

  GOAL_SIZE: 5,
  GOAL_RADIUS: 15,

  ENTITY_SPAWN_SIZE: 3,

  RESCUE_RADIUS: 50,

  GOAL_OCCUPY_DURATION: 4,

  ROUTE_WITH_SAMPLING: false,

  eps: 1,

  // 이 속도 이하로 움직일 수 없습니다
  MOVESPEED_MIN: 5,

  // 회전할 때 aim이 얼마나 벌어지는지를 결정합니다. 1인 경우, 회전하는 만큼 aim이 벌어집니다.
  AIMVAR_INCR_ROT_MULTIPLER: 10,

  // 이동할 때 aim이 얼마나 벌어지는지를 결정합니다. 1인 경우, 회전하는 만큼 aim이 벌어집니다.
  AIMVAR_INCR_MOVE_MULTIPLER: 0.1,


  HIT_PROB_HIDE: 0.01,
  HIT_PROB_CRAWL: 0.5,

  SMOKE_AIMVAR_MULT: 2,
  SMOKE_HIT_PROB_PANALTY: 0.4,
  THREAT_THRES_CRAWL: 0.5,

  // 피격 판정 방법을 정합니다. 현재 시뮬레이션이 2차원이고, projectile의 선이
  // 목표 agent의 hitbox (시각화에서 원으로 표현되는 범위) 에 닿으면 이후 피격
  // 판정을 진행합니다. 사격 원점으로부터 사격 대상까지의 거리가 멀어질 때
  // 명중률이 실제보다 높게 표현될 수 있는데, 현실 세계에서는 projectile이 수평
  // 방향/수직 방향으로 모두 분산되지만 시뮬레이션에서는 수평 방향으로만
  // 분산되기 때문입니다. 아래 파라미터를 조절해서 projectile이 3차원 공간에서
  // 흩어지는 것을 흉내낼 수 있습니다. 아래 파라미터는 다음과 같이 동작합니다.
  //  - 먼저, 명시된 횟수만큼 projectile 방향을 sampling합니다.
  //  - 이후, sampling 된 projectile 중 사격 대상과 제일 먼 projectile을 선택합니다.
  // 아래 값이 2일 경우, 현재 시뮬레이션이 3차원으로 그대로 확장된 경우를
  // 흉내낼 수 있습니다: 사격 반경이 원호(arc)가 아니라 사각뿔 (pyramid)로
  // 표현되고, 사격 대상이 원이 아니라 정육면제인 경우와 동일하게 동작합니다:
  // 이 경우 가로/세로 방향으로 projectile의 방향을 정하고, 가로/세로가 모두
  // 표적 안에 진입했을 경우 피격되었다고 생각할 수 있는데, 이는 가로/세로 중
  // projectile과 제일 먼 축이 표적 안으로 진입했을 때로 바꾸어 표현할 수
  // 있습니다.
  AIM_ITER_FIRE: 2,
  AIM_ITER_PROJECTILE: 2,

  TRAIL_DURATION: 0.2,
  TRAIL_HIT_DURATION: 0.5,
  SHOT_IDLE_DURATION: 2,
  HIDE_DURATION: 3,
  COVER_IDLE_DURATION: 5,

  IDLE_VIS_INTERVAL_RANGE: [0.3, 0.6],

  RISK_DIR_PERSISTENT_DURATION: 1,

  REROUTE_INTERVAL_RANGE: [1.0, 2.0],

  COVERPOINT_SAMPLE_DIST: 30,

  // 한 번 crawl하면 이 시간동안 일어날 수 없습니다.
  CRAWL_MIN_DURATION: 3,

  // perk_desmar_distraction 퍽에 의해 crawl된 경우 일어날 때까지 걸리는 시간입니다.
  DISTRACTION_CRAWL_DURATION: 4,

  RISKDIR_THRES: 0,

  EXPLORED_THRES: 10,

  AIM_SAMPLES_FIRE: 10,

  // 회전 속도를 없애고, 바로 원하는 방향을 바라봅니다.
  EXP_ROTATE_INSTANT: false,

  // 간단한 충돌 메커니즘으로 겹침을 줄입니다.
  EXP_SOFT_COLLISION: false,

  // 실외 환경에서 움직일 때 movespeed_rules를 적용하지 않습니다.
  EXP_OUTDOOR_IGNORE_MOVESPEED_RULES: true,

  // 교전 중 방어 위치를 덜 움직입니다.
  EXP_HOLD_POSITION: false,

  // reroute 주기가 동기화되지 않도록 jitter를 추가합니다.
  EXP_REROUTE_JITTER: true,

  // 현재 에이전트가 시야를 밝히는 공간 안에 적 agent가 진입한 경우에만 대상을 조준할 수 있습니다.
  // TODO: prompt00에서, 뒤에서 오는 위협을 감지하지 못하는 문제가 있어 임시로 끔
  EXP_AIMTARGET_VIS: false,

  // cover 판정에 실제로 가려졌냐가 아닌 방향을 사용합니다. coverpoint 생성 위치 전면에 있으면 cover 상태로 판정합니다.
  EXP_COVER_DIR: true,

  // etc
  PERF_BUF_SIZE: 30,

  CHECK_THROWABLE_INTERVAL_RANGE: [1, 1.5],

  IDLE_ALERT_INTERVAL_RANGE: [3, 5],

  // 같은 팀에서 투척물 투척 주기
  THROWABLE_INTERVAL: 10,

  // 리더가 팔로워와 거리를 조절하는 거리
  LEADER_SLOW_DIST: 50,

  // 리더가 팔로워를 기다리는 거리
  LEADER_WAIT_DIST: 150,

  // 팔로워가 리더를 따라가는 거리
  FOLLOWER_ALLOWED_DIST: 150,

  // 근거리의 두세 지점만 방문하길 반복하는 것을
  // 방지하기 위해 최근 n개까지 방문 지점을 저장해둡니다
  RECENT_VISITS_COUNT: 0,

  // 기본 mobility level
  MOBILITY_LEVEL_DEFAULT: 2,

  // 회복 thres
  HEAL_THRES_DEFAULT: 1,

  // 공격적
  // 회복 정책별 thres
  HEAL_THRES_AGGRESSIVE: 1,
  // 보수적
  // 회복 대상의 체력이 150인 경우, 150 * 0.7 = 75 아래로 체력이 떨어졌을 경우 회복합니다.
  HEAL_THRES_CONSERVATIVE: 0.7,

  STABLE_DAMAGE: true,
  STABLE_DAMAGE_FAKE: true,

  // aimtarget을 선택했지만 아래 시간동안 교전하지 않는 경우 aimtarget을 해제합니다.
  UNAIM_DURATION: 5,

  // 회복 반경
  HEAL_RADIUS: 20,

  // awareness
  USE_AWARE: true,

  FOG_OUT_TO_IN: true,

  // 수집 반경
  INTERACT_RADIUS: 10,

  // 수집 소요 시간
  INTERACT_DURATION: 5,

  UNAIM_PAUSE_DURATION: 2,

  COVER_EDGE_DIST: 100000,

  // cover에서 cover-fire로의 전환까지 기다리는 시간
  COVER_FIRE_DURATION: 7,

  // 실험: area governer
  AREA_GOVERNER_COVER_REORG: false,
  AREA_GOVERNER_COVER_REORG_DURATION: 3,

  PS_ENGAGE_PAUSE: false,

  // 플레이어 상태: 사기/발각도 기본값
  PS_MORALE_DEFAULT: 70,
  PS_UNCOVER_DEFAULT: 0,

  // 피해량 1당 사기 감소
  PS_MORALE_DAMAGE_MULT: 0,

  // 치명타마다 사기 증가
  PS_MORALE_CRIT: 3,
  // 사살마다 사기 증가량 stat을 곱해서 적용됨. stat이 10이고 mult가 1인경우 사살마다 10 증가
  PS_MORALE_MULT_KILL: 0.1,
  // 아군 죽을 때 마다 사기 감소량
  PS_MORALE_DEATH: 50,

  PS_MORALE_FALLEN_COEF: 25,


  // 명중하거나 명중하지 않은 사격당 발각도 증가량 multiplier. 데미지 33 사격 한 번에 0.33씩 증가
  PS_UNCOVER_DAMAGE_MULT: 0.01,
  // 교전중인 상태마다, 1초간 증가하는 발각도
  PS_UNCOVER_DURATION_ENGAGE_MULT: 3,
  // 수색중인 상태마다, 1초간 증가하는 발각도
  PS_UNCOVER_DURATION_SEARCH_MULT: 2,
  // 탬색중인 상태마다, 1초간 증가하는 발각도
  PS_UNCOVER_DURATION_IDLE_MULT: 0.1,

  // 응급처치 체력 회복량
  PS_FIRSTAID_HEAL: 10,
  // 긍급처치 발각도 증가량, 인원당
  PS_FIRSTAID_UNCOVER_INCR: 4,

  DEMO_DOOR_ENTRY: true,

  DOOR_ENTRY_DIST: 50,

  BREACHING_POINT_TOLERANCE: 1,
  WAIT_BREACHING_OVER: true,
  DOOR_ENTRY_DELAY: 10,

  // grind에서, 훈련당 평균 스텟 증가량
  GRIND_STAT_INCR_PER_TRAIN: 0.8,

  // grind에서 선택된 맵 이름 ['sabotage0', 'sawmill1_sabotage0']
  // key 값은 GrindView.js의 CONFIGS에 있어야 함
  GRIND_SELECTED_MAP: 'sawmill1_sabotage',

  // 기차놀이 중 팔로워의 목표 위치가 장애물 안에 있을 때의 처리
  // 벽에서 얼만큼 떨어진 지점을 목표 위치로 잡을 것인지
  EXP_FOLLOW_RAYCAST_TOLERANCE: 10,
  EXP_FOLLOW_DIST: 10,

  // cover-hold 룰 적용 시 장애물 최대 탐색 거리 기본값
  EXP_COVER_HOLD_MAX_DIST_DEFAULT: 100,

  // throwable 사용 시 아군에게 스턴/데미지 들어가지 않게 (false)
  EXP_THROWABLE_HIT_ALLIES: false,

  // 언리얼에서 트레일 수를 샘플링하기 위한 숫자입니다.
  // 총알을 n개 발사하면 n*x개 만큼 보입니다.
  TEST_TRAIL_SAMPLE_RATE: 1,

  // 트레일 명중 여부에 따라 방향을 재계산합니다
  TEST_TRAIL_RECONSTRUCT: true,

  // 빚맞은 트레일을 재구성할 때, 사격 대상과 적어도 이 배수만큼 떨어집니다.
  // 예를 들어 이 값이 2고, 대상의 크기가 0.3m인 경우, 0.6m 이상 빚맞습니다.
  TEST_TRAIL_RECONSTRUCT_MISS_DIST_MIN: 2,

  // 빚맞은 트레일을 재구성할 때, 사격 대상과 이 배수 이상 떨어지지 않습니다.
  // 예를 들어 이 값이 3고, 대상의 크기가 0.3m인 경우, 0.9m 이상 빚맞습니다.
  TEST_TRAIL_RECONSTRUCT_MISS_DIST_MAX: 3,


  ...opts_local,

  EXP_TRAIL_CUT_MISS: false,
  EXP_TRAIL_CUT_HIT: true,

  // 전투중 속도 배수 추가값
  // 전투중 판정 기준: 사격 대상이 있는가 (공격하려고 하는가)
  // 1인 경우 배수에 1이 더해집니다 (100% 더 빨라집니다)
  // 0인 경우 교전중에도 속도가 변하지 않습니다
  EXP_SPEED_MULT_ENGAGE: -0.40,

  // 교전 중 physical 스텟에 비례하여 증가하는 추가 이동 속도 배수
  EXP_SPEED_MULT_ENGAGE_PHY_MAX: 0.5,

  // 파이컷 전술을 활성화한 상태에서 비전투중 이동 속도 배수
  PIECUT_SPEED_MULT_NOT_ENGAGE: -0.5,

  // 전투중 조준 속도 배수 추가값
  EXP_AIMSPEED_MULT_ENGAGE: -0.5,

  DEBUG_APPLY_OPERATOR_PERKS: false,
  DEBUG_APPLY_STATS_PERKS: false,

  EXP_HALF_ENTRY: true,

  // 항상 피해 분산이 발동하도록 설정합니다.
  TEST_ALWAYS_DAMAGE_COVER: false,
  //전투에서 피해 분산 기능이 발동하려면 잃어야 하는 체력의 비율
  DAMAGE_COVER_ACTIVATE_LIFE_RATIO: 0.3,
  //피해 분산의 지속 시간
  DAMAGE_COVER_DURATION: 10,
  // 피해 분산 상황에서 돌격 시 최대로 이동할 거리
  DAMAGE_COVER_CHARGE_MAX_DISTANCE: 40,

  //용병 간 거리가 가까우면 스탯이 향상되는 기능입니다.
  EXP_CLOSE_DISATNCE_STAT_INCREASE: true,
  //[EXP_CLOSE_DISATNCE_STAT_INCREASE 관련] 가까운 거리에 대한 기준입니다.
  DISTANCE_CHECK_CLOSE: 10,
  //[EXP_CLOSE_DISATNCE_STAT_INCREASE 관련] 가까운 거리에 따른 데미지 상승률입니다.
  IF_CLOSE_DAMAGE_INCREASE_AMOUNT: 0,
  //[EXP_CLOSE_DISATNCE_STAT_INCREASE 관련] 가까운 거리에 따른 적의 체력 비례 데미지 상승치입니다.
  IF_CLOSE_DAMAGE_INCREASE_ENEMY_HP: 1,
  //[EXP_CLOSE_DISATNCE_STAT_INCREASE 관련] 가까운 거리에 따른 명중률 상승률입니다.
  IF_CLOSE_ACCURACY_INCREASE_AMOUNT: 1,

  // 항상 제압 사격이 발동하도록 설정합니다.
  TEST_ALWAYS_SUPRESSING: false,
  //제압 사격 최대 지속시간 입니다.
  SUPRESSING_DURATION: 60.0,
  //제압 사격 시, 적과 피해갈 최소 거리입니다.
  SUPRESSING_ENEMY_RADIUS: 0.0,
  // 브리칭과 투척물을 동시에 사용합니다.
  ALLOW_THROWABLE_ON_BREACHING: false,

  // Follow 거리에서 일정 거리 이상 멀어질 경우 일정 시간 빨리 움직입니다.
  FOLLOW_RUN_TOLERANCE: 10, // follow 거리에서 이만큼 더 멀어지면 빨리 움직이기 시작합니다.
  FOLLOW_RUN_TICKS: 30, // 빨리 움직이는 상태의 지속 시간입니다.

  // 리더가 뒤쳐져 있을 시 증가할 속도 배율입니다.
  FOLLOW_LEADER_SPEED_INCREASE: 1.5,
  // 오퍼레이터가 리더 앞에 있을 시 감소할 속도 배율입니다.
  FOLLOW_SPEED_DECREASE: 0.5,
  // 교전 시 최대 근접 가능 거리
  EXP_ENGAGE_MAXIMUM_NEAR_DISTANCE: 35,

  // cover-fire 상태에서 최대로 뒤로 물러설 수 있는 거리입니다.
  EXP_COVER_FIRE_BACKWARD_DISTANCE: 30,

  // 수류탄 투척을 결정할 때 피격 범위에 적이 존재해야하는 비율
  EXP_THROWABLE_ENEMY_PERCENT: 0.5,

  // 교전 시간이 일정 시간을 초과하면 스탯을 보정하는 기능입니다.
  EXP_EXCEED_ENGAGED_STAT_ADDITION: true,
  // 이 시간 이상 교전이 진행되면 스탯이 보정(Phase 1)됩니다.
  ENGAGED_STAT_ADDITION_PHASE1_TIME_LIMIT: 15,
  // 이 시간 이상 교전이 진행되면 스탯이 보정(Phase 2)됩니다.
  ENGAGED_STAT_ADDITION_PHASE2_TIME_LIMIT: 5,
  // 스탯 보정 시 감소되는 hit_prob_covered 입니다.
  ENGAGED_STAT_ADDITION_PROB_COVERED: 0.1,
  // 스탯 보정 시 감소되는 accuracy 입니다.
  ENGAGED_STAT_ADDITION_ACCURACY: 0.1,

  // 프롬프트 투척물 선택 지속시간
  PROMPT_THROWABLE_DURATION: 5,

  // 샷건 투사체 그룹 분할 수
  EXP_SHOTGUN_GROUP_COUNT: 3,
  // 샷건 투사체 그룹 간 발사 딜레이
  EXP_SHOTGUN_GROUP_DELAY: 0.1,

  // 아군 오퍼레이터 사망 시 스탯 분배 기능
  EXP_DEATH_STAT_DISTRIBUTE: false,
  EXP_DEATH_STAT_DISTRIBUTE_RATIO: {
    physical: 0.0, // 피지컬
    shooting: 0.6, // 사격술
    perception: 0.5, // 공간 지능
    tactical: 0.0, // 전술 지능
    mental: 0.0, // 정신력
  },
};

export const CONTROLS_TMPL = {
  // ['default', 'focus', 'aggressive', 'pair', 'control', 'supress']
  firepolicy: 'default',

  // 전술지능
  riskdir: false,
  throwable_cooking: false,

  // holdpos: false,
  // cover: false,

  // search: true,
  // mobility: opts.MOBILITY_LEVEL_DEFAULT,
  door_entry: false,
  damage_cover: false,

  //20240227 정진명: UI 작업상 스쿼드의 정책이 먼저 정해져야 해서 추가. 활성화된다고 agent의 행동이 바뀌지 않지만, agent의 행동을 바꿀 수 있는 UI를 염.
  throwopts: false,
  throw_idx: -1,
  breachopts: 'nobreach',
  breach_idx: -1,
  //20240227 정진명: healopts는 UI를 열지 않고 그냥 모든 용병의 heal을 동시에 설정하는 식으로 구현.
  healopts: opts.HEAL_THRES_DEFAULT,
};

export const AGENT_CONTROLS_TMPL = {
  throwable: false,
  attachable: {},
  // heal: opts.HEAL_THRES_DEFAULT,
  // firearm: 0,
}

export const ROLE_PRESETS = {
  vanguard: {
    // 앞으로 뛰어나갑니다
    perk_cover_dash: false,
    // 더 먼저 조준받습니다
    perk_targetpref_high: true,
    // 벽 뒤에 숨지 않습니다.
    allow_cover_edge: false,
  },
};
