import squarify from 'squarify';
import { v2 } from './v2.mjs';

const DEFAULT_WALL_WIDTH = 5;
const DEFAULT_DOOR_OFFSET = 10;
const DEFAULT_DOOR_SIZE = 30;
export const DEFAULT_SECTION_SIZE = 50;

const VERTICAL = 0;
const HORIZONTAL = 1;

class Rect {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  get x0() { return this.start.x }
  get y0() { return this.start.y }
  get x1() { return this.end.x }
  get y1() { return this.end.y }
}

class Segment extends Rect {
  constructor(start, end) {
    super(start, end);
    if ((start.x !== end.x) && (start.y !== end.y)) {
      throw new Error("not aabb");
    }
  }

  get type() {
    if (this.x0 === this.x1) {
      return VERTICAL;
    } else if (this.y0 === this.y1) {
      return HORIZONTAL;
    } else {
      throw new Error("not axis-aligned");
    }
  }

  intersection(other) {
    if (this.type !== other.type) {
      return null;
    }
    if (this.type === VERTICAL) {
      if (this.x0 !== other.x0) {
        return null;
      }
      const y0 = Math.max(this.y0, other.y0);
      const y1 = Math.min(this.y1, other.y1);
      if (y0 >= y1) {
        return null;
      }
      return new Segment(new v2(this.x0, y0), new v2(this.x0, y1));
    } else {
      if (this.y0 !== other.y0) {
        return null;
      }
      const x0 = Math.max(this.x0, other.x0);
      const x1 = Math.min(this.x1, other.x1);
      if (x0 >= x1) {
        return null;
      }
      return new Segment(new v2(x0, this.y0), new v2(x1, this.y0));
    }
  }
}

class Room extends Rect {
  constructor(start, end) {
    super(start, end);

    const tl = start;
    const tr = new v2(end.x, start.y);
    const bl = new v2(start.x, end.y);
    const br = end;

    this.edges = [
      new Segment(tl, tr),
      new Segment(bl, br),
      new Segment(tl, bl),
      new Segment(tr, br),
    ];
  }

  empty() {
    return this.start.x === this.end.x || this.start.y === this.end.y;
  }

  contacted(other) {
    for (const e0 of this.edges) {
      for (const e1 of other.edges) {
        const e = e0.intersection(e1);
        if (e) {
          return e;
        }
      }
    }
    return null;
  }

  findContact(rooms) {
    for (const room of rooms) {
      const contact = this.contacted(room);
      if (contact) {
        return contact;
      }
    }
    return null;
  }

  makeDoor(rooms, offset, size) {
    const contact = this.findContact(rooms);
    if (!contact) {
      return null;
    }
    const { start } = contact;
    if (contact.type === VERTICAL) {
      this.door = new Segment(start.add(new v2(0, offset)), start.add(new v2(0, offset + size)));
    } else {
      this.door = new Segment(start.add(new v2(offset, 0)), start.add(new v2(offset + size, 0)));
    }
    return this.door;
  }
}

export function fitToGrid(x, gridSize) {
  return Math.floor((x + gridSize / 2) / gridSize) * gridSize;
}

export function squarifyFitToGrid(rooms, container) {

  return squarify.default(rooms, container).map((v) => {
    const x0 = fitToGrid(v.x0, DEFAULT_SECTION_SIZE);
    const x1 = fitToGrid(v.x1, DEFAULT_SECTION_SIZE);
    const y0 = fitToGrid(v.y0, DEFAULT_SECTION_SIZE);
    const y1 = fitToGrid(v.y1, DEFAULT_SECTION_SIZE);
    return new Room(new v2(x0, y0), new v2(x1, y1));
  });
}

export class Structure {
  static create(rng, props) {
    let s = null;
    for (let i = 0; i < 100; i++) {
      s = new Structure(rng, props);
      if (s.failed) {
        continue;
      }
      // 입구 방향 정하기
      if (!isNaN(props.enterance)) {
        if (!s.enterance.intersection(s.container.edges[props.enterance])) {
          continue;
        }
      }
      break;
    }
    return s;
  }

  constructor(rng, props) {
    const { start, end } = props;

    let count = props.count ?? 0;

    const wall_width = props.wall_width ?? DEFAULT_WALL_WIDTH;
    const door_offset = props.door_offset ?? DEFAULT_DOOR_OFFSET;
    const door_size = props.door_size ?? DEFAULT_DOOR_SIZE;

    if (count === 0) {
      count = rng.integer(5, 10);
    }
    let rooms = [];
    for (let i = 0; i < count; i++) {
      rooms.push({ value: rng.integer(3, 10) });
    }

    const container = new Room(start, end);

    const squarifyTreemap = squarifyFitToGrid(rooms, container);
    for (const room of squarifyTreemap) {
      if (room.empty()) {
        this.failed = true;
        return;
      }
    }
    const edges = [];
    const n = squarifyTreemap.length;

    for (let i = 0; i < n; i++) {
      const r0 = squarifyTreemap[i];
      for (let j = i + 1; j < n; j++) {
        const r1 = squarifyTreemap[j];
        if (r0.contacted(r1)) {
          edges.push({ from: i, to: j });
          edges.push({ from: j, to: i });
        }
      }
    }

    edges.sort((a, b) => {
      if (a.from !== b.from) {
        return a.from - b.from;
      }
      return a.to - b.to;
    });

    //몇번째부터 시작인지
    const edgeN = [0,];
    for (let i = 1; i < edges.length; i++) {
      if (edges[i].from !== edges[i - 1].from) {
        edgeN.push(i);
      }
    }

    let reachables = 0;
    const reachablemap = new Uint8Array(squarifyTreemap.length);
    const merged = [];

    function liveDegree(idx) {
      let degree = 0;
      for (let i = edgeN[idx]; i < edgeN[idx === n ? edges.length : idx + 1]; i++) {
        if (edges[i].from === idx && !reachablemap[edges[i].to]) {
          degree += 1;
        }
      }
      return degree;
    }

    function markReachable(n) {
      if (!reachablemap[n]) {
        reachables += 1;
        reachablemap[n] = 1;
      }
    }

    function visit(idx) {
      merged.push(idx);
      markReachable(idx);
      for (const edge of edges) {
        if (edge.from === idx) {
          markReachable(edge.to);
        }
      }
    }

    function findMaxDegree() {
      // find max-degree node
      let max_idx = 0;
      let max_degree = 0;

      for (let i = 0; i < n; i++) {
        const degree = liveDegree(i);
        if (degree > max_degree) {
          max_degree = degree;
          max_idx = i;
        }
      }
      return max_idx;
    }

    let startIdx = findMaxDegree();
    visit(startIdx);

    while (reachables < n) {
      let max_idx = -1;
      let max_degree = -1;

      for (let i = 0; i < n; i++) {
        if (merged.includes(i) || !reachablemap[i]) {
          continue;
        }

        const degree = liveDegree(i);
        if (degree > max_degree) {
          max_degree = degree;
          max_idx = i;
        }
      }
      visit(max_idx);
    }

    const mergedRooms = merged.map((v) => squarifyTreemap[v]);

    let doors = [];
    const enterance = container.makeDoor(mergedRooms, door_offset, door_size);
    if (enterance) {
      // 첫 번째 문, 구조 밖으로 생성되어야 함.
      doors.push(enterance);
    } else {
      // TODO
      this.failed = true;
      return;
    }

    let walls = [];
    walls = walls.concat(makeWalls(container));

    for (const room of squarifyTreemap) {
      if (mergedRooms.includes(room)) {
        continue;
      }

      doors.push(room.makeDoor(mergedRooms, door_offset, door_size));
      walls = walls.concat(makeWalls(room));
    }

    function makeWalls(room) {
      function createRect0(start, end) {
        return new Rect(start, end.add(new v2(wall_width, wall_width)));
      }

      function createRect(edge) {
        // split wall if there's room
        const door = room.door;
        if (edge.intersection(door)) {
          return [
            createRect0(edge.start, door.start),
            createRect0(door.end, edge.end),
          ];
        }
        return [createRect0(edge.start, edge.end)];
      }

      let walls = [];
      for (const edge of room.edges) {
        walls = walls.concat(createRect(edge));
      }
      return walls;
    }

    const thickness = wall_width;
    doors = doors.map((d) => {
      return new Rect(d.start, d.end.add(new v2(thickness, thickness)));
    });


    const pos = start.add(end).mul(0.5);

    this.squarifyTreemap = squarifyTreemap;
    this.container = container;
    this.enterance = enterance;
    this.merged = merged;
    this.doors = doors;
    this.walls = walls;
    this.rooms = rooms;
    this.pos = pos;
  }
}

export function placeStructures(rng, opts) {
  const { pos: p, extent, count, heading } = opts;
  const pos = new v2(p.x, p.y);

  let rooms = [];
  if (rooms.length === 0) {
    for (let i = 0; i < count; i++) {
      rooms.push({ value: rng.integer(3, 5) });
    }
  }
  const tl = pos.sub(extent);
  const br = pos.add(extent);
  const container = { x0: tl.x, y0: tl.y, x1: br.x, y1: br.y };
  const squarifyTreemap = squarifyFitToGrid(rooms, container);

  function drop(a) {
    return a - a % DEFAULT_SECTION_SIZE;
  }

  let placements = [];
  for (let area of squarifyTreemap) {
    if (area.x1 - area.x0 < 110 || area.y1 - area.y0 < 110) {
      continue;
    }

    let dots = [
      new v2(area.x0, area.y0),
      new v2(area.x1, area.y0),
      new v2(area.x1, area.y1),
      new v2(area.x0, area.y1)];

    const pos = v2.centroid(dots);
    const dir = heading ?? rng.angle() / 6;
    let ratio = 1;
    for (const dot of dots) {
      const rotDot = dot.rot(pos, dir);
      if (rotDot.x < area.x0) {
        ratio = Math.min((area.x0 - pos.x) / (rotDot.x - pos.x), ratio);
      }
      if (rotDot.x > area.x1) {
        ratio = Math.min((area.x1 - pos.x) / (rotDot.x - pos.x), ratio);
      }
      if (rotDot.y < area.y0) {
        ratio = Math.min((area.y0 - pos.y) / (rotDot.y - pos.y), ratio);
      }
      if (rotDot.y > area.y1) {
        ratio = Math.min((area.y1 - pos.y) / (rotDot.y - pos.y), ratio);
      }
    }

    const width = drop((area.x1 - area.x0) * ratio);
    const height = drop((area.y1 - area.y0) * ratio);

    if (width <= 50 || height <= 50) {
      continue;
    }

    placements.push({
      pos: new v2(area.x0 + area.x1, area.y0 + area.y1).mul(0.5),
      offset: pos.sub(new v2(width, height).mul(0.5)),
      extent: new v2(width / 2, height / 2),
      dir,
    });
  }

  return placements;
}

export function createStructures(rng, placements, opts) {
  const structures = [];
  for (let placement of placements) {
    const { extent } = placement;
    const width = extent.x * 2;
    const height = extent.y * 2;

    if (width <= 50 || height <= 50) {
      continue;
    }

    const roomCountMax = Math.max(width, height) / 50;
    const roomCountMin = Math.min(width, height) / 50;
    const count = rng.integer(roomCountMin, roomCountMax);

    // TODO: 공간 크기에 따라 방 개수를 적절히 정하게 -chae
    const structure = Structure.create(rng, {
      ...opts,
      start: new v2(0, 0),
      end: new v2(width, height),
      count,
    });

    structures.push({
      ...placement,
      structure,
    });
  }

  return structures;
}
