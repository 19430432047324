import source from '../downloaded/briefs.json' assert { type: 'json' };
import { L } from '../../../localization.mjs';

function parse_brief(text) {
  const out = [];

  let last = {};
  for (const line of text.split('\n')) {
    if (line.startsWith('img:')) {
      if (last.text) {
        out.push(last);
        last = {};
      }
      last.img_src = line.slice(4);
      continue;
    }
    if (last.text) {
      out.push(last);
      last = {};
    }
    last.text = line;
  }
  out.push(last);

  return out;
}

export const briefs = [];
for (const row of source) {
  const { config, contentImage, brief } = row;
  briefs.push({
    config,
    contentImage,
    brief: parse_brief(L(brief)),
    brief_key: brief,
  });
}

export function briefByConfig(config) {
  const found = briefs.find((d) => d.config === config);
  if (found) {
    const { brief_key } = found;
    found.brief = parse_brief(L(brief_key));
  }
  return found;
}
