import source from '../downloaded/itemOptions.json' assert { type: 'json' };

export const options = [];
for (const row of source) {
  let { id, name, prob_generate, priority, item_type, item_group, ty } = row;
  const modifiers = [];
  if (row.modifier_0) {
    modifiers.push({
      modifier: row.modifier_0,
      value: Number(row.value_0),
    });
  }
  if (row.modifier_1) {
    modifiers.push({
      modifier: row.modifier_1,
      value: Number(row.value_1),
    });
  }
  if (row.modifier_2) {
    modifiers.push({
      modifier: row.modifier_2,
      value: Number(row.value_2),
    });
  }
  if (row.modifier_3) {
    modifiers.push({
      modifier: row.modifier_3,
      value: Number(row.value_3),
    });
  }

  options.push({
    id,
    name,
    prob_generate: Number(prob_generate),
    priority: Number(priority),
    item_type,
    item_group: item_group?.split(',').map(s => s.trim()) ?? [],
    ty,
    modifiers,
  });
}

options.sort((a, b) => a.priority - b.priority);
