import React, { createRef } from 'react';
import { Rng } from '../src/rand.mjs';
import { L } from './localization.mjs';
import './BusinessCardView.css';

const SVG_TEXT_HIGHLIGHTEN = `
<svg width="671" height="38" viewBox="0 0 671 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="671" height="38" fill="#272824"/>
<rect x="232" width="206" height="38" fill="url(#paint0_linear_1979_15736)"/>
<defs>
<linearGradient id="paint0_linear_1979_15736" x1="365.93" y1="-32.6115" x2="283.524" y2="-3.10128" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="0.169725" stop-color="white" stop-opacity="0.342109"/>
<stop offset="0.496113" stop-color="#DDDDDD"/>
<stop offset="0.833854" stop-color="white" stop-opacity="0.329728"/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
    `;

const SVG_TEXT_NORMAL = `
<svg width="671" height="38" viewBox="0 0 671 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="671" height="38" fill="#272824"/>
</svg>
`
const SVG_UNDERLINE = <svg width="300" height="1" viewBox="0 0 300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line y1="0.95" x2="400" y2="0.95" stroke="#272824" strokeWidth="10" />
</svg>;

export class BusinessCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesLoaded: 0,
      phase: 'opening',
      text: 'Michael McClane',
      isHovered: false,
    };
    this.baseImageRef = createRef();
    this.containerRef = createRef();
    this.multiplyImageRef = createRef();
    this.overlayImageRef = createRef();
    this.buttonRef = createRef();
    this.directiveRef = createRef();
    this.textareaRef = createRef();
    this.underlineRef = createRef();
    const seed = Rng.randomseed();
    this.rng = new Rng(seed);
    this.keyDown = this.onKeyDown.bind(this);
    this.clicked = this.onclick.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.isFocused = false;
    this.handleFocus = this.handleFocus.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

  }

  componentDidMount() {
    this.setAnimationDuration();
    this.adjustImageOpacity(0);
    this.adjustButtonOpacity(0);
    this.adjustDirectiveOpacity(0);
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('click', this.clicked);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('click', this.clicked);
  }

  handleImageLoad = () => {
    this.setState((prevState) => {
      const newImagesLoaded = prevState.imagesLoaded + 1;
      if (newImagesLoaded === 3) {
        this.changePhase();
      }
      return { imagesLoaded: newImagesLoaded };
    });
  };

  setAnimationDuration = () => {
    const multiplyImage = this.multiplyImageRef.current;
    const overlayImage = this.overlayImageRef.current;
    const randomDuration = this.rng.range(0.6, 1);
    const multiplyDuration = `${randomDuration}s`;
    const overlayDuration = `${randomDuration}s`;

    if (multiplyImage) {
      multiplyImage.style.animationDuration = multiplyDuration;
    }
    if (overlayImage) {
      overlayImage.style.animationDuration = overlayDuration;
    }
  }

  adjustImageOpacity(opacity, duration) {
    const container = this.containerRef.current;
    container.style.opacity = opacity;
    if (duration) {
      container.style.transition = `opacity ${duration}s ease-in-out`;
    }
  }

  adjustDirectiveOpacity(opacity, duration) {
    const directive = this.directiveRef.current;
    directive.style.opacity = opacity;
    if (duration) {
      directive.style.transition = `opacity ${duration}s ease-in-out`;
    }
  }

  adjustButtonOpacity(opacity, duration) {
    const Button = this.buttonRef.current;
    Button.style.opacity = opacity;
    if (duration) {
      Button.style.transition = `opacity ${duration}s ease-in-out`;
    }
  }

  adjustUnderlineOpacity(opacity, duration) {
    const Underline = this.underlineRef.current;
    Underline.style.opacity = opacity;
    if (duration) {
      Underline.style.transition = `opacity ${duration}s ease-in-out`;
    }
  }


  adjustImageScale(manualScale = 1, duration) {
    const baseImage = this.baseImageRef.current;
    const container = this.containerRef.current;

    if (baseImage && container) {
      const containerWidth = container.clientWidth;
      const imageWidth = baseImage.naturalWidth;
      const scale = containerWidth / imageWidth * manualScale;

      const children = container.children;
      Array.from(children).forEach(child => {
        child.style.transform = `scale(${scale})`;
        if (duration) {
          child.style.transition = `transform ${duration}s ease-in-out`;
        }
      });
    }
  };

  onKeyDown(_ev) {
    if (this.state.phase === 'waiting') {
      this.changePhase();
    }
  }

  onclick(_ev) {
    if (this.state.phase === 'waiting') {
      this.changePhase();
    }
  }

  changePhase() {
    if (this.state.phase === 'opening') {
      this.adjustImageScale(1.3);
      this.adjustImageOpacity(0);
      this.adjustImageScale(1, 2);
      this.adjustImageOpacity(1, 2);
      this.adjustUnderlineOpacity(0);
      setTimeout(() => {
        setTimeout(() => {
          this.adjustButtonOpacity(1, 1.5);
        }, 1500);
        this.adjustDirectiveOpacity(1, 1.5);
        setTimeout(() => {
          this.setState({ phase: 'waiting' });
        }, 3000);
      }, 2000);
    } else if (this.state.phase === 'waiting') {
      this.adjustImageScale(1.1, 1);
      this.adjustUnderlineOpacity(1, 1);
      setTimeout(() => {
        this.setState({ phase: 'writing' });
      }, 1000)
    } else if (this.state.phase === 'writing') {
      this.setState({ phase: 'ending' });
      this.adjustUnderlineOpacity(0, 0.4);
      this.adjustButtonOpacity(0, 0.4);
      setTimeout(() => {
        this.adjustImageScale(1, 1);
        this.adjustImageOpacity(0, 1);
        this.adjustDirectiveOpacity(0, 1);
        setTimeout(() => {
          this.props.onFinish();
        }, 1100);
      }, 500);
    }
  }

  render() {
    const { phase, isHovered, text } = this.state;

    const baseButtonStyle = {
      paddingLeft: "30px",
      paddingRight: "30px",
    }

    const colorButtonStyle = (text === ``) ?
      {
        border: "2px solid rgba(255, 255, 255, 0.5)",
        color: "rgba(255, 255, 255, 0.5)"
      } :
      (isHovered && phase === 'writing') ?
        {
          border: "2px solid yellow",
          color: "yellow"
        } :
        {
          border: "2px solid white",
          color: "white"
        };

    const cursorButtonStyle = (text !== `` && phase === 'writing') ?
      { cursor: 'pointer' } :
      { cursor: 'default' };


    const buttonStyle = Object.assign({}, baseButtonStyle, colorButtonStyle, cursorButtonStyle);

    return (
      <div className='card-root'>
        <div className='card-root-letterbox-upper'></div>
        <div className='card-root-letterbox-contents' ref={this.containerRef}>
          <div className="card-root-letterbox-contents-top-blur"></div>
          <div className='card-root-letterbox-contents-container'>
            <img
              className='card-root-letterbox-contents-container-image-base'
              src='/img/cutscene/BGI_Name_Company_Base.png'
              ref={this.baseImageRef}
              onLoad={this.handleImageLoad}
            />
            {this.HighlightedInput()}
            <img
              className='card-root-letterbox-contents-container-image-multiply'
              src='/img/cutscene/EFX_CC_Multiply.png'
              ref={this.multiplyImageRef}
              onLoad={this.handleImageLoad}
            />
            <img
              className='card-root-letterbox-contents-container-image-overlay'
              src='/img/cutscene/EFX_CC_Overlay.png'
              ref={this.overlayImageRef}
              onLoad={this.handleImageLoad}
            />
          </div>
          <div className="card-root-letterbox-contents-bottom-blur"></div>
        </div>
        <div className='card-root-letterbox-lower'>
          <div className='card-root-letterbox-lower-directive'
            ref={this.directiveRef}>
            {L('loc_ui_longtext_businesscard_directive')}
          </div>
        </div>
        <div className='card-root-continuebutton'
          ref={this.buttonRef}>
          <div className='card-root-continuebutton-label'
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onClick={this.handleConfirm}
            style={buttonStyle}
          >
            {L('loc_ui_longtext_businesscard_confirm')}
          </div>
        </div>
      </div>
    );
  }

  handleTextChange(e) {
    this.setState({ text: e.target.value });
  };

  adjustBackgroundSize() {
    if (this.textareaRef.current) {
      const height = this.textareaRef.current.scrollHeight;
      this.textareaRef.current.style.backgroundSize = `auto ${height}px`;
    }
  };

  handleFocus() {
    if (this.isFocused == false && this.state.phase === 'writing') {
      this.isFocused = true;
      this.setState({ text: "" });
    }
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  handleConfirm() {
    if (this.state.phase === 'writing' && this.state.text !== ``) {
      this.changePhase();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.phase !== prevState.phase) {

      const svgAsBackground = `url('data:image/svg+xml;utf8,${encodeURIComponent(
        this.state.phase === 'opening' || this.state.phase === 'waiting' ? SVG_TEXT_HIGHLIGHTEN : SVG_TEXT_NORMAL
      )}')`;

      this.textareaRef.current.style.background = svgAsBackground;
      this.textareaRef.current.style.WebkitBackgroundClip = "text";
      this.adjustBackgroundSize();
    }
  }


  HighlightedInput() {
    const { phase, text } = this.state;
    const isReadOnly = phase !== 'writing';

    const svgAsBackground = `url('data:image/svg+xml;utf8,${encodeURIComponent(
      phase === 'opening' || phase === 'waiting' ? SVG_TEXT_HIGHLIGHTEN : SVG_TEXT_NORMAL
    )}')`;
    this.adjustBackgroundSize();

    const textStyle = {
      background: svgAsBackground,
      WebkitBackgroundClip: "text",
    };
    const selectStyle = (phase === 'writing') ?
      {
        cursor: 'auto',
        userSelect: 'text',
        WebkitUserSelect: 'text',
        MozUserSelect: 'text',
        msUserSelect: 'text',

      } :
      {
        cursor: 'default',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none'
      };
    const textCursorStyle = (text.length < 20) ?
      {
        caretColor: `#272824`,
        animation: `blink - caret 1s step - end infinite`,
      } :
      {
        caretColor: `transparent`,
      };
    const style = Object.assign({}, textStyle, selectStyle, textCursorStyle);


    return (
      <div className="input-container"      >
        <textarea
          ref={this.textareaRef}
          className="highlighted-textarea"
          value={text}
          onChange={this.handleTextChange}
          style={style}
          spellCheck="false"
          maxLength='20'
          readOnly={isReadOnly}
          onFocus={this.handleFocus}
        />
        <div className='highlighted-underline'
          ref={this.underlineRef}>
          {SVG_UNDERLINE}
        </div>
      </div>
    );
  }
}
