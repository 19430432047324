import source from '../downloaded/centertypes.json' assert { type: 'json' };

export const centertypes = [];
for (const row of source) {
  let { name, ty, weight, slots_office, slots_production, slots_support, traits } = row;

  centertypes.push({
    name,
    ty,
    weight: +weight,
    slots_office: 0 | slots_office,
    slots_production: 0 | slots_production,
    slots_support: 0 | slots_support,
    traits: traits?.split(',').map(s => s.trim()) ?? [],
  });
}
