import source from '../downloaded/outloopUpgrades.json' assert { type: 'json' };

export const upgrades = [];

for (const row of source) {
  const { key, level, title, desc, result, result_value, result_loc_key, value_loc_key } = row;
  upgrades.push({
    key,
    level: parseInt(level),
    title,
    desc,
    result,
    result_value: parseInt(result_value),
    result_loc_key,
    value_loc_key,
  });
}
