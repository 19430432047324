/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PgbButton } from "../PgbButton";
import "./style.css";

export const PgbConA = ({
  PGBButtonPtLabel = "Buttonlabel31",
  PGBButtonOnMouseOut = "D",
  PGBButtonOnMouseOver = "D",
  PGBButtonVarState = "on",
  PGBButtonOnMouseMove = "D",
  PGBButtonOnClick = "onClick31",
}) => {
  return (
    <div className="PGB-con-a">
      <PgbButton
        className="PGB-button-a"
        onClick={PGBButtonOnClick}
        onClickDummyClassName="PGB-button-a-2"
        onMouseMove={PGBButtonOnMouseMove}
        onMouseMoveDummyClassName="PGB-button-a-2"
        onMouseOut={PGBButtonOnMouseOut}
        onMouseOutDummyClassName="PGB-button-a-2"
        onMouseOver={PGBButtonOnMouseOver}
        onMouseOverDummyClassName="PGB-button-a-2"
        overlapGroupClassName="PGB-button-a-2"
        pbShowSubText={false}
        perkgroupClassName="PGB-button-a-3"
        ptLabel={PGBButtonPtLabel}
        varState={PGBButtonVarState}
      />
    </div>
  );
};

PgbConA.propTypes = {
  PGBButtonPtLabel: PropTypes.string,
  PGBButtonOnMouseOut: PropTypes.any,
  PGBButtonOnMouseOver: PropTypes.any,
  PGBButtonVarState: PropTypes.string,
  PGBButtonOnMouseMove: PropTypes.any,
  PGBButtonOnClick: PropTypes.any,
};
