import source from '../downloaded/char2fixedOperators.json' assert { type: 'json' };

export const fixedOperators = {};

for (const row of source) {
  const { id, key, name, portraitUrl, potentialTier, Tier, role, nationality, backgroundName, backgroundDesc, age, callsign, modifier, perks_initial } = row
  fixedOperators[id] = {
    key,
    name,
    portraitUrl,
    potentialTier: +potentialTier,
    Tier: +Tier,
    role,
    nationality,
    backgroundName,
    backgroundDesc,
    age: +age,
    callsign,
    modifiers: modifier ? modifier.split(',').map((s) => s.trim()) : [],
    perks_initial: perks_initial ? perks_initial.split(',').map((s) => s.trim()) : [],
  };
}
