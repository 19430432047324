import React from 'react';
import { GrindView, GrindGame, ItemTitle } from './GrindView';
import { Simulator } from './sim';
import { Rng } from './rand.mjs';
import { opts } from './opts.mjs';
import './FigmaIntermissionView.css';

import { agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { modifiers as data_outloopModifiers } from './data/google/processor/data_outloopModifiers.mjs';
import { upgrades as data_outloopUpgrades } from './data/google/processor/data_outloopUpgrades.mjs';
import { points as data_outloopPoints } from './data/google/processor/data_outloopPoints.mjs';

import { RenderAgentSimple } from './FigmaListView.js';
import { evalCSSClass } from './FigmaLobbyView.js';
import { PowerIndicatorNumber } from './Badges.js';
import { FirearmItem2, EquipmentItem2, ThrowableItem2 } from './FigmaGearsView.js';
import { L } from './localization.mjs';

const svg_icon_heart = <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
  <path d="M15 9.26256C15 8.00677 13.0972 6 10.75 6C8.40279 6 6.5 8.17636 6.5 10.6185C6.5 11.7603 6.91589 12.801 7.59865 13.5855L15 23L22.4015 13.5854C23.0842 12.8008 23.5 11.7602 23.5 10.6185C23.5 8.17636 21.5972 6 19.25 6C16.9028 6 15 8.05701 15 9.26256Z" fill="#B1805C" />
</svg>;

const svg_icon_bullets = <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
  <path d="M8 10.75C8 9.05 8.89169 6.925 10.2292 6.5C11.5667 6.925 12.4584 9.05 12.4584 10.75V23.5H8V10.75Z" fill="#B1805C" />
  <path d="M13.7936 10.75C13.7936 9.05 14.6853 6.925 16.0229 6.5C17.3604 6.925 18.2521 9.05 18.2521 10.75V23.5H13.7936V10.75Z" fill="#B1805C" />
  <path d="M21.7708 6.5C20.4333 6.925 19.5416 9.05 19.5416 10.75V23.5H24V10.75C24 9.05 23.1083 6.925 21.7708 6.5Z" fill="#B1805C" />
</svg>;

const svg_icon_pentagon = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M23.9616 11.6008L13.5 4L3.03833 11.6008L7.03431 23.8992H19.9656L23.9616 11.6008ZM13 6.21737L5.22855 11.8636L8.24669 12.8167L13 9.36327V6.21737ZM7.88274 21.6563L5.02028 12.8465L8.00232 13.7882L9.7499 19.1667L7.88274 21.6563ZM10.8504 17.6995L9.75984 14.3432L12.6767 15.2643L10.8504 17.6995ZM13 11.2174V14.3178L10.0255 13.3784L13 11.2174ZM18.4243 22.3992L16.5155 19.8541H10.4844L8.57557 22.3992H18.4243ZM18.7532 12.8167L21.7713 11.8636L14 6.21737V9.36327L18.7532 12.8167ZM21.9796 12.8465L18.9976 13.7882L17.25 19.1667L19.1172 21.6563L21.9796 12.8465ZM16.9744 13.3784L14 14.3178V11.2174L16.9744 13.3784ZM14.3232 15.2643L16.1495 17.6995L17.2401 14.3432L14.3232 15.2643ZM13.5 15.8333L15.3905 18.3541H11.6094L13.5 15.8333Z" fill="#B1805C" />
</svg>;

const svg_icon_star = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
  <path d="M16.5 5L19.0275 11.5212L26.0106 11.9098L20.5895 16.3288L22.3779 23.0902L16.5 19.3L10.6221 23.0902L12.4105 16.3288L6.98944 11.9098L13.9725 11.5212L16.5 5Z" fill="#B1805C" />
</svg>;

const svg_icon_signing = <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
  <path d="M16.91 7.5L5.37083 18.7588L4.64278 22.3572L8.33081 21.6468L19.87 10.388C19.7447 9.54764 18.9772 7.79347 16.91 7.5Z" fill="#B1805C" />
  <path d="M4 25.5H22V23.0494H4V25.5Z" fill="#B1805C" />
</svg>;

function fillResult(grind_result) {
  const { game, sim, result } = grind_result;
  const { rng } = game;

  const agent_updates = [];

  for (const agent of game.agents_avail_all) {
    const entity = sim?.entities.find((e) => e.idx === agent.idx);

    let result = 'reserve';
    if (entity) {
      const dead = entity?.life <= 0;
      if (dead) {
        result = 'hurt';
      } else {
        result = 'fine';
      }
    }
    const { stats, modifiers } = data_outloopModifiers.find((m) => m.result === result);
    let modifier_key = 'no_mod';
    const target_modifiers = modifiers.filter((m) => !agent.modifier.find((mod) => mod.key === m.modifier_key));
    if (target_modifiers.length > 0) {
      modifier_key = rng.weighted_key(target_modifiers, 'weight').modifier_key;
    }
    const modifier = agentModifiers.find((m) => m.key === modifier_key);

    agent_updates.push({
      idx: agent.idx,
      result,
      stats,
      modifier,
    });
  }

  return {
    ...grind_result,
    agent_updates,
  };
}

export class FigmaIntermissionRoot extends React.Component {
  constructor(props) {
    super(props);

    const rng = new Rng();
    this.state = {
      rng,

      // brief, grind, debrief, intermission
      phase: 'brief',
      idx: 0,

      game: null,
      grind_result: null,

      // 이전 임무에서 이어진 용병. status=alive/leave
      agents: [],
      inventories: [],
      upgrades: [],
    };

    const game = new GrindGame();
    game.reset(opts.GRIND_SELECTED_MAP);

    const agents = game.recruit_listings.slice(0, 4);
    for (const agent of agents) {
      game.recruitAgent(agent);
      game.onAssign(0, agent);
    }
    const agents_additional = game.recruit_listings.slice(0, 6);
    for (const agent of agents_additional) {
      game.recruitAgent(agent);
    }
    game.renewMission(0);
    const sim = Simulator.create({ ...game.squads[0].mission_state.simstate, m: this.props.m });

    this.state = {
      // brief, grind, debrief, intermission
      phase: 'intermission',
      idx: 0,

      grind_result: fillResult({
        result: 'clear',
        game,
        sim,
      }),
      agents: [],
      inventories: [],
      upgrades: [],

      level: 0,
    };
  }
  render() {
    return <div className='figmaintermission-testroot'>
      <FigmaIntermissionView {...this.state}
        onAgentUpdate={() => { this.setState({ game: this.state.game }); }}
      />
    </div>;
  }
}

function FigmaIntermissionProgressBar(props) {
  const { current } = props;
  const phases = {
    'result': L('loc_ui_string_intermission_phase_result'),
    'upgrades': L('loc_ui_string_intermission_phase_upgrades'),
  };
  return <div className='figmaintermission-progressbar'>
    {Object.keys(phases).map((key, idx) => {
      return <div key={idx} className={`figmaintermission-progressbar-phase ${key === current ? 'active' : ''}`}>
        <div className='figmaintermission-progressbar-phase-order'>{idx + 1}</div>
        <div className='figmaintermission-progressbar-phase-desc'>{phases[key]}</div>
      </div>;
    })}
  </div>;
}

function FigmaIntermissionAgentResult(props) {
  const { agents, agent_updates, onAgentUpdate } = props;
  const agents_in_squad = agents.filter((a) => a.squad !== undefined);
  const agents_in_reserve = agents.filter((a) => a.squad === undefined);
  const agent_updates_in_squad = agent_updates.filter((au) => agents_in_squad.find((a) => a.idx === au.idx));
  const agent_updates_in_reserve = agent_updates.filter((au) => agents_in_reserve.find((a) => a.idx === au.idx));
  return <div className='figmaintermission-agentresult'>
    <FigmaIntermissionAgentList agents={agents_in_squad} squad={0} agent_updates={agent_updates_in_squad} onAgentUpdate={onAgentUpdate} />
    <FigmaIntermissionAgentList agents={agents_in_reserve} squad={undefined} agent_updates={agent_updates_in_reserve} onAgentUpdate={onAgentUpdate} />
  </div>;
}

function FigmaIntermissionAgentList(props) {
  const { agents, squad, agent_updates, onAgentUpdate } = props;
  let header = L('loc_ui_string_intermission_agents_reserve');
  if (squad !== undefined) {
    header = L('loc_ui_string_intermission_agents_dispatched');
  }
  return <div className='figmaintermission-agentlist'>
    <div className='figmaintermission-agentlist-header'>{header}</div>
    <div className='figmaintermission-agentlist-body'>
      {agents.map((agent, idx) => {
        const agent_update = agent_updates.find((au) => au.idx === agent.idx);
        return <FigmaIntermissionAgentRow idx={idx} agent={agent} agent_update={agent_update} onAgentUpdate={onAgentUpdate} />;
      })}
    </div>
  </div>;
}

function FigmaIntermissionAgentRow(props) {
  const { idx, agent, agent_update, onAgentUpdate } = props;
  const items = [];
  if (agent.squad !== undefined) {
    items.push(<div key='care_option' className='figmaintermission-agentrow-item'>
      <div className='figmaintermission-agentrow-item-title'>
        {svg_icon_star}
        {L('loc_ui_string_intermission_agent_care_option')}
      </div>
      <div className={'figmaintermission-agentrow-item-value'}>{agent_update.care_option_id ? L(`loc_data_string_agent_care_options_${agent_update.care_option_id}`) : '-'}</div>
    </div>)
    items.push(<div key='health' className='figmaintermission-agentrow-item'>
      <div className='figmaintermission-agentrow-item-title'>
        {svg_icon_heart}
        {L('loc_ui_string_intermission_agent_status')}
      </div>
      <div className={'figmaintermission-agentrow-item-value' + evalCSSClass('result', agent_update.result)}>{L('loc_data_string_intermission_agent_status_' + agent_update.result)}</div>
    </div>);
  }
  items.push(<div key='power' className='figmaintermission-agentrow-item'>
    <div className='figmaintermission-agentrow-item-title'>
      {svg_icon_bullets}
      {L('loc_ui_string_agent_power')}
    </div>
    <div className='figmaintermission-agentrow-item-value'>
      <div className='figmaintermission-agentrow-item-value-primary'>
        <PowerIndicatorNumber character={agent} />
      </div>
      <div className='figmaintermission-agentrow-item-value-secondary'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path d="M7.13398 4C7.51888 3.33333 8.48112 3.33333 8.86603 4L14.0622 13C14.4471 13.6667 13.966 14.5 13.1962 14.5H2.80385C2.03405 14.5 1.55292 13.6667 1.93782 13L7.13398 4Z" fill="#293A14" />
        </svg>
        {(agent_update.stats.map((stat, idx) => stat.value).reduce((a, b) => a + b, 0) / 5).toFixed(1)}
      </div>
    </div>
  </div>);
  items.push(<div key='real-power' className='figmaintermission-agentrow-item'>
    <div className='figmaintermission-agentrow-item-title'>
      {svg_icon_pentagon}
      {L('loc_ui_string_intermission_agent_stat_change')}
    </div>
    <div className={'figmaintermission-agentrow-item-value' + evalCSSClass('value', agent_update.stats[0]?.value)}>{agent_update.stats[0] ? `${L('loc_ui_string_agent_stat3_' + agent_update.stats[0].stat)} ${agent_update.stats[0].value > 0 ? '+' : ''}${agent_update.stats[0].value}` : '-'}</div>
  </div>)
  items.push(<div key='modifier' className='figmaintermission-agentrow-item'>
    <div className='figmaintermission-agentrow-item-title'>
      {svg_icon_star}
      {L('loc_ui_string_intermission_agent_new_note')}
    </div>
    <div className={'figmaintermission-agentrow-item-value' + evalCSSClass('modifier', agent_update.modifier?.key)}>{agent_update.modifier ? L(agent_update.modifier.name) : '-'}</div>
  </div>)
  items.push(<div key='renew' className='figmaintermission-agentrow-item'>
    <div className='figmaintermission-agentrow-item-title'>
      {svg_icon_signing}
      {L('loc_ui_string_intermission_agent_renew')}
    </div>
    <div className='figmaintermission-agentrow-item-value'>
      <GeneralToggleButton state={!(agent.willBeFired)} onClick={() => {
        if (!agent_update.isDead) {
          agent.willBeFired = (agent.willBeFired === undefined) ? true : !agent.willBeFired;
          onAgentUpdate(agent);
        }
      }} />
    </div>
  </div>)
  return <div key={idx} className='figmaintermission-agentrow'>
    <RenderAgentSimple agent={agent} i={idx} noTooltip={true} cls={'figmaintermission-agentrow-name'} />
    {items}
  </div>;
}

function GeneralToggleButton(props) {
  const { state, onClick } = props;
  return <div className='figma-general-toggle-button' onClick={onClick}>
    <div className={'figma-general-toggle-button-foreground' + (state ? ' active' : '')} />
  </div>;
}


function FigmaIntermissionUpgrades(props) {
  const { points_total, upgrades, completedUpgrades, level, onSelect } = props;

  return <div className='figmaintermission-upgrades'>
    <div className='figmaintermission-upgrades-header'>
      <div className='figmaintermission-upgrades-header-title'>{L('loc_ui_string_intermission_upgrade_points_available')}</div>
      <div className='figmaintermission-upgrades-header-value'>{L('loc_dynamic_string_intermission_upgrade_points_available', { count: points_total - upgrades.length })}</div>
    </div>
    <div className='figmaintermission-upgrades-body'>
      <div className='figmaintermission-upgrades-body-title'>{L('loc_ui_string_intermission_upgrade_list')}</div>
      <div className='figmaintermission-upgrades-skills-list'>
        {[0, 1, 2, 3, 4].map((idx) => {
          const upgrades_in_level = data_outloopUpgrades.filter((upgrade) => upgrade.level === idx);
          const row = upgrades_in_level.map((upgrade, idx) => {
            const isComplete = completedUpgrades.includes(upgrade.key);
            const isSelected = upgrades.includes(upgrade.key);
            const isLocked = !isComplete && !isSelected && (upgrade.level > level || points_total - upgrades.length <= 0);
            return <FigmaIntermissionUpgradeItem
              key={idx}
              upgrade={upgrade}
              onSelect={isLocked ? null : onSelect}
              isComplete={isComplete}
              isSelected={isSelected}
              isLocked={isLocked}
            />;
          });
          if (row.length < 2) {
            for (let i = row.length; i < 2; ++i) {
              row.push(<FigmaIntermissionUpgradeItem
                key={i}
                upgrade={undefined}
                onSelect={null}
                isComplete={false}
                isSelected={false}
                isLocked={true}
              />);
            }
          } else if (row.length > 2) {
            row = row.slice(0, 2);
          }
          return <div key={idx} className='figmaintermission-upgrades-skills-row'>
            <div className='fiamaintermission-upgrades-skills-row-header'>
              <div className='fiamaintermission-upgrades-skills-row-header-value'>{idx + 1}</div>
              <div className='fiamaintermission-upgrades-skills-row-header-desc'>{L('loc_ui_string_intermission_upgrade_level')}</div>
            </div>
            <div className='figmaintermission-upgrades-skills-row-body'>
              {row}
            </div>
          </div>;
        })}
      </div>
    </div>
  </div>;
}

function FigmaIntermissionUpgradeItem(props) {
  const { key, upgrade, onSelect, isComplete, isSelected, isLocked } = props;
  const isDummy = (upgrade === undefined);
  const title = isDummy ? L('loc_ui_string_common_locked') : L(upgrade.title);
  const result = isDummy ? L('loc_ui_longtext_common_locked_in_demo') : L(upgrade.result_loc_key);
  const result_value = isDummy ? '\u200b' : L(upgrade.value_loc_key, { value: upgrade.result_value });
  const lockOverlay = isLocked ?
    <div className='figmaintermission-upgrade-skills-item-overlay'>

    </div>
    : null;
  const svgLockIcon = <svg className="figmaintermission-upgrade-skills-item-lock" xmlns="http://www.w3.org/2000/svg" width="27" height="37" viewBox="0 0 27 37" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.15385 14.3889H0V37H27V14.3889H22.8462V9.25C22.8462 4.14137 18.6617 0 13.5 0C8.33826 0 4.15385 4.14137 4.15385 9.25V14.3889ZM9.34615 14.3889H17.6538V9.25C17.6538 6.9795 15.7941 5.13889 13.5 5.13889C11.2059 5.13889 9.34615 6.9795 9.34615 9.25V14.3889Z" fill="#76675A" />
  </svg>;
  const lockIcon = isLocked ? svgLockIcon : null;
  const svgCompleteIcon = <svg className="figmaintermission-upgrade-skills-item-complete" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <circle cx="15" cy="15" r="15" fill="#1ED5D5" />
    <path d="M6.50004 14.5L12.5 20L22.719 9.07635" stroke="#00641C" strokeWidth="6" />
  </svg>;
  const completeIcon = isComplete ? svgCompleteIcon : null;
  return <div
    key={key}
    className={'figmaintermission-upgrade-skills-row-item' + (isComplete ? ' complete' : '') + (isSelected ? ' selected' : '') + (isLocked ? ' locked' : '')}
    onClick={!isDummy && onSelect !== null ? () => onSelect(upgrade) : null}
  >
    <div className='figmaintermission-upgrade-skills-item-icon'>
      {lockIcon}
    </div>
    <div className='figmaintermission-upgrade-skills-item-body'>
      <div className='figmaintermission-upgrade-skills-item-title'>{title}</div>
      <div className='figmaintermission-upgrade-skills-item-effect'>
        <div className='figmaintermission-upgrade-skills-item-effect-ty'>{result}</div>
        <div className='figmaintermission-upgrade-skills-item-effect-value'>{result_value}</div>
      </div>
    </div>
    {lockOverlay}
    {completeIcon}
  </div>
}

export function FigmaIntermissionView(props) {
  const { grind_result, onNext, level, onAgentUpdate } = props;
  const { game, agent_updates } = grind_result;

  // result, points, upgrades
  const [phase, setPhase] = React.useState('result');
  const [upgrades, setUpgrades] = React.useState(props.upgrades);
  const [pointsTotal, setPointsTotal] = React.useState(0);

  const completedUpgrades = game.upgrades;

  return <div className='figmaintermission-root'>
    <div className='figmaintermission-header'>
      <div className='figmaintermission-title'>{L('loc_ui_string_intermission_mission_result')}</div>
      <div className='figmaintermission-subtitle'>{L('loc_ui_string_common_success')}</div>
    </div>
    <div className='figmaintermission-body'>
      <FigmaIntermissionProgressBar current={phase} />
      {(phase === 'result') &&
        <FigmaIntermissionAgentResult
          agents={game.agents_avail_all}
          agent_updates={agent_updates}
          onAgentUpdate={onAgentUpdate}
        />
      }
      {(phase === 'upgrades') &&
        <FigmaIntermissionUpgrades
          points_total={pointsTotal}
          upgrades={upgrades}
          completedUpgrades={completedUpgrades}
          level={level}
          onSelect={(upgrade) => {
            if (completedUpgrades.includes(upgrade.key)) {
              return;
            } else if (upgrades.includes(upgrade.key)) {
              upgrades.splice(upgrades.indexOf(upgrade.key), 1);
            } else {
              upgrades.push(upgrade.key);
            }
            setUpgrades([...upgrades]);
          }}
        />
      }
    </div>
    <div className='figmaintermission-footer'>
      <button
        className='figmamission-button-next'
        onClick={() => {
          if (phase === 'result') {
            setPointsTotal(1);
            setPhase('upgrades');
          }
          else if (phase === 'upgrades') {
            const newUpgrades = [...completedUpgrades, ...upgrades];
            onNext({ upgrades: newUpgrades });
          }
          onAgentUpdate(null);
        }}>
        {L('loc_ui_string_intermission_next')}
      </button>
    </div>
  </div>;
}
