import React, { useState } from 'react';
import { GrindView, GrindGame } from './GrindView.js';
import { AgentModifierBody } from './Badges.js';
import { Rng } from './rand.mjs';
import { L } from './localization.mjs';

import { recruitGroups as data_recruitGroups } from './data/google/processor/data_recruitGroup.mjs';
import { createCharacter2, addAgentModifier } from './character2.mjs';
import { potentialWeights as data_potentialWeights } from './data/google/processor/data_char2PotentialWeight.mjs';
import { roles } from './data/google/processor/data_char2roles.mjs';
import { operatorNames } from './data/google/processor/data_char2characterNames.mjs';
import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { modifiers as data_outloopModifiers } from './data/google/processor/data_outloopModifiers.mjs';
import { SoundButton } from './sound.mjs';

import { TooltipContext, renderTooltip } from './FigmaTooltipView.js';
import { FigmaListView } from './FigmaListView';

import { SaveImpl } from './SaveImpl';

const PRESET = 'sabotage1';
const AGENTS_COUNT = 3;

function fillResult(grind_result) {
  const { game, sim } = grind_result;
  const { rng } = game;

  const agent_updates = [];

  for (const agent of game.agents_avail_all) {
    const entity = sim?.entities.find((e) => e.idx === agent.idx);

    let result = 'reserve';
    if (entity) {
      const dead = entity?.life <= 0;
      if (dead) {
        result = 'hurt';
      } else {
        result = 'fine';
      }
    }
    const { stats, modifiers } = data_outloopModifiers.find((m) => m.result === result);
    let modifier_key = 'no_mod';
    const target_modifiers = modifiers.filter((m) => !agent.modifier.find((mod) => mod.key === m.modifier_key));
    if (target_modifiers.length > 0) {
      modifier_key = rng.weighted_key(target_modifiers, 'weight').modifier_key;
    }
    const modifier = data_agentModifiers.find((m) => m.key === modifier_key);

    agent_updates.push({
      idx: agent.idx,
      result,
      stats,
      modifier,
    });
  }

  return {
    ...grind_result,
    agent_updates,
  };
}

class DiceState {
  constructor(state) {
    if (state) {
      Object.assign(this, state);

      this.rng = new Rng();
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.rng = new Rng();

    this.phase = 'dice';

    this.game = null;
    this.grind_result = null;

    this.agents = [];
    this.inventories = [];
    this.upgrades = [];
    this.balance = 0;

    this.characterIds = Object.keys(operatorNames);
    this.onReroll();
  }

  onReroll() {
    const { rng } = this;

    this.agents = [];

    // TODO: initialize agents
    const data = data_recruitGroups.find((d) => d.id === `${PRESET}_default`);

    const { companyRank, perks } = data;
    const { modifiers } = data;

    for (let i = 0; i < AGENTS_COUNT; i++) {
      const potentialTierWeights = data_potentialWeights[companyRank - 1].potentialTierWeights;
      const potentialTier = rng.weighted_key(potentialTierWeights, 'weight').tier;

      let types = data.types;
      if (types[0] === 'every') {
        types = roles.map((r) => r.key).filter((r) => r !== 'scout');
      }

      let ty = rng.choice(types);
      const role = roles.find((r) => r.key === ty);

      let agent = null;
      while (!agent) {
        const id = rng.choice(this.characterIds);
        const { nationalityKey, name } = operatorNames[id];

        const opts = {
          id,
          rng,
          potentialTier,
          role,
          name,
          nationalityKey,
          perks,
        };
        agent = createCharacter2(opts);
        agent.state = 'alive';
        agent.idx = i;

        const key = rng.choice(modifiers);
        addAgentModifier(agent, key, 0);

        this.agents.push(agent);
      }
    }
  }

  onGrindResult(result) {
    this.phase = 'debrief';
    this.grind_result = fillResult(result);
  }

  instantiateGame(config_key) {
    const game = new GrindGame();

    // 인원 업데이트
    const names_known = this.agents.filter((a) => a.state === 'alive').map((a) => a.id);
    game.characterIds = game.characterIds.filter((id) => {
      return !names_known.includes(id);
    });

    for (const agent of this.agents) {
      game.agentDisarm(agent);
      if (agent.state === 'alive') {
        game.addAgentRecruit(agent);
        game.agent_idx = Math.max(game.agent_idx, agent.idx + 1);
      }
    }

    // 업그레이드 적용
    game.upgrades = this.upgrades.slice();
    game.reset(config_key);

    game.resources.balance += this.balance;
    // 장비 지급
    for (const item of this.inventories) {
      game.inventories.push(item);
    }
    return game;
  }
}

function FigmaCandidatesView(props) {
  const { characters, listHeader, renderDetailButtons, header, onClickButtonClose, additionalButton, OnClickBackgroundButton, onClickPerkUnlockButton, turn } = props;

  const [selected, onSelect] = useState(characters[0]);
  if (characters.indexOf(selected) === -1) {
    setTimeout(() => {
      onSelect(characters[0]);
    });
  }

  return <FigmaListView
    characters={characters}
    selected={selected}
    header={header}
    listHeader={listHeader}
    onSelect={(ch) => onSelect(ch)}
    renderDetailButtons={renderDetailButtons}
    onClickButtonClose={onClickButtonClose}
    additionalButton={additionalButton}
    OnClickBackgroundButton={OnClickBackgroundButton}
    onClickPerkUnlockButton={onClickPerkUnlockButton}
    renderNoteView={(ch) => {
      let note = null;
      const visible_modifiers = ch.modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);
      if (visible_modifiers.length > 0) {
        note = visible_modifiers.map((mod, i) => <>
          {i > 0 ? <span key={'span' + i} className='grind-agent-modifier grind-agent-modifier-separator'>,&nbsp;</span> : ''}
          <AgentModifierBody key={i} modifier={mod} turn={turn} />
        </>);
      }
      return note;
    }}
  />;
}

export class DiceView extends React.Component {
  constructor(props) {
    super(props);

    const saveimpl = new SaveImpl({
      prefix: 'proto_dice_save',
      processor: {
        onSave: this.onSave.bind(this),
        onLoad: this.onLoad.bind(this),
      },
    });
    let dicestate = new DiceState();

    this.tooltipRef = React.createRef();

    this.state = {
      saveimpl,
      dicestate,

      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
    };
  }

  onSave({ data: inner, descr }) {
    const { dicestate } = this.state;

    descr = L('loc_ui_string_save_mission') + `: ${descr}`;
    const data = JSON.stringify({ dicestate, inner });
    return { data, descr };
  }

  onLoad(data) {
    const { dicestate: dicestate0, inner } = JSON.parse(data);
    const dicestate = new DiceState(dicestate0);
    this.setState({ dicestate });
    return inner;
  }

  render() {
    const { m } = this.props;
    const { saveimpl, dicestate } = this.state;
    if (!dicestate) {
      return null;
    }
    const { phase, agents } = dicestate;

    switch (phase) {
      case 'dice': {
        const header = <>
          <div className="figmalist-header-title">{L('loc_ui_string_menu_employee_list')}</div>
          <div className="figmalist-header-sep" />
          <SoundButton className="grind-ceoschedule-button" onClick={() => {
            dicestate.onReroll();
            this.setState({ dicestate });
          }} title="reroll">
            <span className="grind-ceoschedule-button-label">re-roll</span>
          </SoundButton>

          <SoundButton className="grind-ceoschedule-button" onClick={() => {
            const game = dicestate.instantiateGame(PRESET);
            dicestate.phase = 'grind';
            dicestate.game = game;
            this.setState({ dicestate });
          }} title="reroll">
            <span className="grind-ceoschedule-button-label">start</span>
          </SoundButton>
        </>;

        const listHeader = null;

        return <TooltipContext.Provider value={{
          ctx: this.state.tooltipContext, setCtx: (ctx) => {
            this.setState({ tooltipContext: ctx });
          }
        }}>
          <FigmaCandidatesView
            characters={agents}
            renderDetailButtons={(agent) => {
              return [];
            }}
            header={header}
            listHeader={listHeader}
            additionalButton={(agent) => null}
            OnClickBackgroundButton={(agent) => {
              // this.overlayPush({ ty: 'AGENT_BACKGROUND', agent });
            }}
            onClickPerkUnlockButton={(character, type, key) => {
              // this.unlockAgentPerk(character, type, key)
            }}
            turn={0}
          />

          {renderTooltip(this.tooltipRef)}
        </TooltipContext.Provider >;
      }

      case 'grind': {
        const { game } = dicestate;
        return <GrindView m={m} game={game}
          saveimpl={saveimpl}
          enable_dialog={true}
          onDone={(result) => {
            dicestate.onGrindResult(result);
            this.setState({ dicestate });
          }} />;
      }
    }
  }
}
