import React, { useEffect, useState } from 'react';
import './FigmaGearsView.css'
import { firearms } from './presets_firearm.mjs';
import { throwables } from './presets_throwables.mjs';
import { gears_vest_bulletproof } from './presets_gear.mjs';
import { RoleBadge } from './RenderAgentSimple.js';
import { shopItemGroups as data_shopItemGroups } from './data/google/processor/data_shopItemGroup.mjs';
import { options as data_itemOptions } from './data/google/processor/data_itemOptions.mjs'
import { roles } from './data/google/processor/data_char2roles.mjs';
import { itemDescs } from './data/google/processor/data_itemDescs.mjs';
import { Rng } from './rand.mjs';
import { L } from './localization.mjs';
import { SoundButton, triggerSound } from './sound.mjs';
import { PortraitWrapper2 } from './PortraitsView.js';


const ICON_FILTER_FRAME = <svg className='figmalist-body-header-left-type-frame' xmlns="http://www.w3.org/2000/svg" width="69" height="85" viewBox="0 0 69 85" fill="none">
  <path d="M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z" fill="#3D2723" />
  <mask id="path-2-inside-1_1098_31257" fill="white">
    <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" />
  </mask>
  <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" fill="#331F1C" />
  <path d="M0 7C0 2.02944 4.02944 -2 9 -2H69V2H9C6.23858 2 4 4.23858 4 7H0ZM69 87H9C4.02944 87 0 82.9706 0 78H4C4 80.7614 6.23858 83 9 83H69V87ZM9 87C4.02944 87 0 82.9706 0 78V7C0 2.02944 4.02944 -2 9 -2V2C6.23858 2 4 4.23858 4 7V78C4 80.7614 6.23858 83 9 83V87ZM69 0V85V0Z" fill="#2F1D1A" mask="url(#path-2-inside-1_1098_31257)" />
</svg>

const ICON_FILTER_FRAME_HOVER = <svg className='figmalist-body-header-left-type-frame' xmlns="http://www.w3.org/2000/svg" width="69" height="85" viewBox="0 0 69 85" fill="none">
  <path d="M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z" fill="#60403A" />
  <mask id="path-2-inside-1_1098_31265" fill="white">
    <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" />
  </mask>
  <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" fill="#4E322D" />
  <path d="M0 7C0 2.02944 4.02944 -2 9 -2H69V2H9C6.23858 2 4 4.23858 4 7H0ZM69 87H9C4.02944 87 0 82.9706 0 78H4C4 80.7614 6.23858 83 9 83H69V87ZM9 87C4.02944 87 0 82.9706 0 78V7C0 2.02944 4.02944 -2 9 -2V2C6.23858 2 4 4.23858 4 7V78C4 80.7614 6.23858 83 9 83V87ZM69 0V85V0Z" fill="#492E2A" mask="url(#path-2-inside-1_1098_31265)" />
</svg>

const ICON_FILTER_FRAME_PRESS = <svg className='figmalist-body-header-left-type-frame' xmlns="http://www.w3.org/2000/svg" width="69" height="85" viewBox="0 0 69 85" fill="none">
  <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" fill="#3D2723" />
  <mask id="path-2-inside-1_1098_31273" fill="white">
    <path d="M4 7C4 3.13401 7.13401 0 11 0H69V85H11C7.13401 85 4 81.866 4 78V7Z" />
  </mask>
  <path d="M4 7C4 3.13401 7.13401 0 11 0H69V85H11C7.13401 85 4 81.866 4 78V7Z" fill="#261917" />
  <path d="M2 7C2 2.02944 6.02944 -2 11 -2H69V2H11C8.23858 2 6 4.23858 6 7H2ZM69 87H11C6.02944 87 2 82.9706 2 78H6C6 80.7614 8.23858 83 11 83H69V87ZM11 87C6.02944 87 2 82.9706 2 78V7C2 2.02944 6.02944 -2 11 -2V2C8.23858 2 6 4.23858 6 7V78C6 80.7614 8.23858 83 11 83V87ZM69 0V85V0Z" fill="#221411" mask="url(#path-2-inside-1_1098_31273)" />
</svg>

const ICON_FILTER_FRAME_SELECTED = <svg className='figmalist-body-header-left-type-frame' xmlns="http://www.w3.org/2000/svg" width="69" height="85" viewBox="0 0 69 85" fill="none">
  <path d="M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z" fill="#805842" />
  <mask id="path-2-inside-1_1098_31249" fill="white">
    <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" />
  </mask>
  <path d="M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z" fill="#5A3A29" />
  <path d="M0 7C0 2.02944 4.02944 -2 9 -2H69V2H9C6.23858 2 4 4.23858 4 7H0ZM69 87H9C4.02944 87 0 82.9706 0 78H4C4 80.7614 6.23858 83 9 83H69V87ZM9 87C4.02944 87 0 82.9706 0 78V7C0 2.02944 4.02944 -2 9 -2V2C6.23858 2 4 4.23858 4 7V78C4 80.7614 6.23858 83 9 83V87ZM69 0V85V0Z" fill="#553625" mask="url(#path-2-inside-1_1098_31249)" />
</svg>

const ICON_TOTAL = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M7.44444 4.72222C7.44444 6.22566 6.22566 7.44444 4.72222 7.44444C3.21878 7.44444 2 6.22566 2 4.72222C2 3.21878 3.21878 2 4.72222 2C6.22566 2 7.44444 3.21878 7.44444 4.72222Z" fill="#7F6053" />
  <path d="M7.44444 12.5C7.44444 14.0034 6.22566 15.2222 4.72222 15.2222C3.21878 15.2222 2 14.0034 2 12.5C2 10.9966 3.21878 9.77778 4.72222 9.77778C6.22566 9.77778 7.44444 10.9966 7.44444 12.5Z" fill="#7F6053" />
  <path d="M4.72222 23C6.22566 23 7.44444 21.7812 7.44444 20.2778C7.44444 18.7743 6.22566 17.5556 4.72222 17.5556C3.21878 17.5556 2 18.7743 2 20.2778C2 21.7812 3.21878 23 4.72222 23Z" fill="#7F6053" />
  <path d="M15.2222 4.72222C15.2222 6.22566 14.0034 7.44444 12.5 7.44444C10.9966 7.44444 9.77778 6.22566 9.77778 4.72222C9.77778 3.21878 10.9966 2 12.5 2C14.0034 2 15.2222 3.21878 15.2222 4.72222Z" fill="#7F6053" />
  <path d="M12.5 15.2222C14.0034 15.2222 15.2222 14.0034 15.2222 12.5C15.2222 10.9966 14.0034 9.77778 12.5 9.77778C10.9966 9.77778 9.77778 10.9966 9.77778 12.5C9.77778 14.0034 10.9966 15.2222 12.5 15.2222Z" fill="#7F6053" />
  <path d="M15.2222 20.2778C15.2222 21.7812 14.0034 23 12.5 23C10.9966 23 9.77778 21.7812 9.77778 20.2778C9.77778 18.7743 10.9966 17.5556 12.5 17.5556C14.0034 17.5556 15.2222 18.7743 15.2222 20.2778Z" fill="#7F6053" />
  <path d="M20.2778 7.44444C21.7812 7.44444 23 6.22566 23 4.72222C23 3.21878 21.7812 2 20.2778 2C18.7743 2 17.5556 3.21878 17.5556 4.72222C17.5556 6.22566 18.7743 7.44444 20.2778 7.44444Z" fill="#7F6053" />
  <path d="M23 12.5C23 14.0034 21.7812 15.2222 20.2778 15.2222C18.7743 15.2222 17.5556 14.0034 17.5556 12.5C17.5556 10.9966 18.7743 9.77778 20.2778 9.77778C21.7812 9.77778 23 10.9966 23 12.5Z" fill="#7F6053" />
  <path d="M20.2778 23C21.7812 23 23 21.7812 23 20.2778C23 18.7743 21.7812 17.5556 20.2778 17.5556C18.7743 17.5556 17.5556 18.7743 17.5556 20.2778C17.5556 21.7812 18.7743 23 20.2778 23Z" fill="#7F6053" />
</svg>

const ICON_TOTAL_HOVER = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M7.44444 4.72222C7.44444 6.22566 6.22566 7.44444 4.72222 7.44444C3.21878 7.44444 2 6.22566 2 4.72222C2 3.21878 3.21878 2 4.72222 2C6.22566 2 7.44444 3.21878 7.44444 4.72222Z" fill="#9A7E72" />
  <path d="M7.44444 12.5C7.44444 14.0034 6.22566 15.2222 4.72222 15.2222C3.21878 15.2222 2 14.0034 2 12.5C2 10.9966 3.21878 9.77778 4.72222 9.77778C6.22566 9.77778 7.44444 10.9966 7.44444 12.5Z" fill="#9A7E72" />
  <path d="M4.72222 23C6.22566 23 7.44444 21.7812 7.44444 20.2778C7.44444 18.7743 6.22566 17.5556 4.72222 17.5556C3.21878 17.5556 2 18.7743 2 20.2778C2 21.7812 3.21878 23 4.72222 23Z" fill="#9A7E72" />
  <path d="M15.2222 4.72222C15.2222 6.22566 14.0034 7.44444 12.5 7.44444C10.9966 7.44444 9.77778 6.22566 9.77778 4.72222C9.77778 3.21878 10.9966 2 12.5 2C14.0034 2 15.2222 3.21878 15.2222 4.72222Z" fill="#9A7E72" />
  <path d="M12.5 15.2222C14.0034 15.2222 15.2222 14.0034 15.2222 12.5C15.2222 10.9966 14.0034 9.77778 12.5 9.77778C10.9966 9.77778 9.77778 10.9966 9.77778 12.5C9.77778 14.0034 10.9966 15.2222 12.5 15.2222Z" fill="#9A7E72" />
  <path d="M15.2222 20.2778C15.2222 21.7812 14.0034 23 12.5 23C10.9966 23 9.77778 21.7812 9.77778 20.2778C9.77778 18.7743 10.9966 17.5556 12.5 17.5556C14.0034 17.5556 15.2222 18.7743 15.2222 20.2778Z" fill="#9A7E72" />
  <path d="M20.2778 7.44444C21.7812 7.44444 23 6.22566 23 4.72222C23 3.21878 21.7812 2 20.2778 2C18.7743 2 17.5556 3.21878 17.5556 4.72222C17.5556 6.22566 18.7743 7.44444 20.2778 7.44444Z" fill="#9A7E72" />
  <path d="M23 12.5C23 14.0034 21.7812 15.2222 20.2778 15.2222C18.7743 15.2222 17.5556 14.0034 17.5556 12.5C17.5556 10.9966 18.7743 9.77778 20.2778 9.77778C21.7812 9.77778 23 10.9966 23 12.5Z" fill="#9A7E72" />
  <path d="M20.2778 23C21.7812 23 23 21.7812 23 20.2778C23 18.7743 21.7812 17.5556 20.2778 17.5556C18.7743 17.5556 17.5556 18.7743 17.5556 20.2778C17.5556 21.7812 18.7743 23 20.2778 23Z" fill="#9A7E72" />
</svg>

const ICON_TOTAL_SELECTED = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M7.44444 4.72222C7.44444 6.22566 6.22566 7.44444 4.72222 7.44444C3.21878 7.44444 2 6.22566 2 4.72222C2 3.21878 3.21878 2 4.72222 2C6.22566 2 7.44444 3.21878 7.44444 4.72222Z" fill="#C3A079" />
  <path d="M7.44444 12.5C7.44444 14.0034 6.22566 15.2222 4.72222 15.2222C3.21878 15.2222 2 14.0034 2 12.5C2 10.9966 3.21878 9.77778 4.72222 9.77778C6.22566 9.77778 7.44444 10.9966 7.44444 12.5Z" fill="#C3A079" />
  <path d="M4.72222 23C6.22566 23 7.44444 21.7812 7.44444 20.2778C7.44444 18.7743 6.22566 17.5556 4.72222 17.5556C3.21878 17.5556 2 18.7743 2 20.2778C2 21.7812 3.21878 23 4.72222 23Z" fill="#C3A079" />
  <path d="M15.2222 4.72222C15.2222 6.22566 14.0034 7.44444 12.5 7.44444C10.9966 7.44444 9.77778 6.22566 9.77778 4.72222C9.77778 3.21878 10.9966 2 12.5 2C14.0034 2 15.2222 3.21878 15.2222 4.72222Z" fill="#C3A079" />
  <path d="M12.5 15.2222C14.0034 15.2222 15.2222 14.0034 15.2222 12.5C15.2222 10.9966 14.0034 9.77778 12.5 9.77778C10.9966 9.77778 9.77778 10.9966 9.77778 12.5C9.77778 14.0034 10.9966 15.2222 12.5 15.2222Z" fill="#C3A079" />
  <path d="M15.2222 20.2778C15.2222 21.7812 14.0034 23 12.5 23C10.9966 23 9.77778 21.7812 9.77778 20.2778C9.77778 18.7743 10.9966 17.5556 12.5 17.5556C14.0034 17.5556 15.2222 18.7743 15.2222 20.2778Z" fill="#C3A079" />
  <path d="M20.2778 7.44444C21.7812 7.44444 23 6.22566 23 4.72222C23 3.21878 21.7812 2 20.2778 2C18.7743 2 17.5556 3.21878 17.5556 4.72222C17.5556 6.22566 18.7743 7.44444 20.2778 7.44444Z" fill="#C3A079" />
  <path d="M23 12.5C23 14.0034 21.7812 15.2222 20.2778 15.2222C18.7743 15.2222 17.5556 14.0034 17.5556 12.5C17.5556 10.9966 18.7743 9.77778 20.2778 9.77778C21.7812 9.77778 23 10.9966 23 12.5Z" fill="#C3A079" />
  <path d="M20.2778 23C21.7812 23 23 21.7812 23 20.2778C23 18.7743 21.7812 17.5556 20.2778 17.5556C18.7743 17.5556 17.5556 18.7743 17.5556 20.2778C17.5556 21.7812 18.7743 23 20.2778 23Z" fill="#C3A079" />
</svg>

const ICON_FIREARM = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.755371 22.8387L4.62771 11.3871V9.64516C6.58164 9.22174 8.07452 7.90323 8.07452 5H29.7554L28.7554 12.5484H18.2431L16.5196 17.7742H11.7041L10.3724 24L0.755371 22.8387ZM12.0274 15.4516H14.8634L15.8209 12.5484H12.5213L12.0274 15.4516Z" fill="#7F6053" />
</svg>

const ICON_FIREARM_HOVER = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.755371 22.8387L4.62771 11.3871V9.64516C6.58164 9.22174 8.07452 7.90323 8.07452 5H29.7554L28.7554 12.5484H18.2431L16.5196 17.7742H11.7041L10.3724 24L0.755371 22.8387ZM12.0274 15.4516H14.8634L15.8209 12.5484H12.5213L12.0274 15.4516Z" fill="#9A7E72" />
</svg>

const ICON_FIREARM_SELECTED = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.755371 22.8387L4.62771 11.3871V9.64516C6.58164 9.22174 8.07452 7.90323 8.07452 5H29.7554L28.7554 12.5484H18.2431L16.5196 17.7742H11.7041L10.3724 24L0.755371 22.8387ZM12.0274 15.4516H14.8634L15.8209 12.5484H12.5213L12.0274 15.4516Z" fill="#C3A079" />
</svg>

const ICON_EQUIPMENT = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M4.53906 8.66377L6.59462 7.08189L6.65017 3.5H10.2891L10.8168 8.66377H17.2613L17.7891 3.5H21.4279L21.4835 7.08189L23.5391 8.66377V24.4345C20.5835 26.0869 15.9742 26.5 14.0391 26.5C12.1039 26.5 7.49462 26.0869 4.53906 24.4345V8.66377Z" fill="#7F6053" />
</svg>

const ICON_EQUIPMENT_HOVER = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M4.53906 8.66377L6.59462 7.08189L6.65017 3.5H10.2891L10.8168 8.66377H17.2613L17.7891 3.5H21.4279L21.4835 7.08189L23.5391 8.66377V24.4345C20.5835 26.0869 15.9742 26.5 14.0391 26.5C12.1039 26.5 7.49462 26.0869 4.53906 24.4345V8.66377Z" fill="#9A7E72" />
</svg>

const ICON_EQUIPMENT_SELECTED = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M4.53906 8.66377L6.59462 7.08189L6.65017 3.5H10.2891L10.8168 8.66377H17.2613L17.7891 3.5H21.4279L21.4835 7.08189L23.5391 8.66377V24.4345C20.5835 26.0869 15.9742 26.5 14.0391 26.5C12.1039 26.5 7.49462 26.0869 4.53906 24.4345V8.66377Z" fill="#C3A079" />
</svg>

const ICON_THROWABLE = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M9.95698 2.896L10.1883 7.51711C6.68671 8.76923 4.11869 11.9169 3.78322 15.6898H23.3327C23.0051 12.0055 20.5487 8.91751 17.1729 7.60851V5.92622H20.6166C21.8266 7.4161 23.6009 9.98452 24.4111 13.5921L25.9521 13.5018C25.9521 8.95644 23.4207 4.13236 22.3371 2.896H9.95698Z" fill="#7F6053" />
  <path d="M3.77637 17.2932H23.3395C22.9428 22.2369 18.715 26.1277 13.558 26.1277C8.40092 26.1277 4.17312 22.2369 3.77637 17.2932Z" fill="#7F6053" />
</svg>

const ICON_THROWABLE_HOVER = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M9.95698 2.896L10.1883 7.51711C6.68671 8.76923 4.11869 11.9169 3.78322 15.6898H23.3327C23.0051 12.0055 20.5487 8.91751 17.1729 7.60851V5.92622H20.6166C21.8266 7.4161 23.6009 9.98452 24.4111 13.5921L25.9521 13.5018C25.9521 8.95644 23.4207 4.13236 22.3371 2.896H9.95698Z" fill="#9A7E72" />
  <path d="M3.77637 17.2932H23.3395C22.9428 22.2369 18.715 26.1277 13.558 26.1277C8.40092 26.1277 4.17312 22.2369 3.77637 17.2932Z" fill="#9A7E72" />
</svg>

const ICON_THROWABLE_SELECTED = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M9.95698 2.896L10.1883 7.51711C6.68671 8.76923 4.11869 11.9169 3.78322 15.6898H23.3327C23.0051 12.0055 20.5487 8.91751 17.1729 7.60851V5.92622H20.6166C21.8266 7.4161 23.6009 9.98452 24.4111 13.5921L25.9521 13.5018C25.9521 8.95644 23.4207 4.13236 22.3371 2.896H9.95698Z" fill="#C3A079" />
  <path d="M3.77637 17.2932H23.3395C22.9428 22.2369 18.715 26.1277 13.558 26.1277C8.40092 26.1277 4.17312 22.2369 3.77637 17.2932Z" fill="#C3A079" />
</svg>

const ICON_UNCHECKED_RADIO = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect x="1" y="1" width="22" height="22" rx="11" fill="#1C160D" stroke="#625443" strokeWidth="2" />
</svg>

const ICON_CHECKED_RADIO = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect x="1" y="1" width="22" height="22" rx="11" fill="#1C160D" stroke="#625443" strokeWidth="2" />
  <circle cx="12" cy="12" r="7" fill="#DFA612" />
</svg>

const ICON_CALL_TO_ARMS_DEALER = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M10.0597 13.042C10.7992 14.3826 11.6515 15.6692 12.7582 16.8425C13.8684 18.023 15.249 19.0973 17.036 20.015C17.1685 20.0798 17.2939 20.0798 17.4067 20.0348C17.5768 19.97 17.7505 19.8278 17.9206 19.6569C18.0531 19.5237 18.2178 19.3114 18.3898 19.0793C19.0774 18.1705 19.9279 17.0422 21.1294 17.6073C21.1563 17.6199 21.176 17.6343 21.2029 17.6451L25.2103 19.961C25.2229 19.9682 25.2372 19.9808 25.2479 19.988C25.7762 20.3533 25.9946 20.9165 26 21.5554C26 22.205 25.7618 22.9356 25.4127 23.5528C24.9507 24.368 24.2702 24.9078 23.4859 25.2641C22.7392 25.6096 21.9084 25.795 21.1097 25.9137C19.8563 26.0991 18.6816 25.9803 17.4801 25.6096C16.3054 25.2443 15.1218 24.6415 13.829 23.8389L13.7341 23.7777C13.1414 23.4052 12.5003 23.0076 11.8718 22.5379C9.57261 20.7924 7.22865 18.2713 5.70123 15.4965C4.42091 13.1679 3.72256 10.654 4.10397 8.25889C4.31527 6.94526 4.87574 5.75039 5.85343 4.96222C6.70578 4.27121 7.85358 3.89332 9.33982 4.02648C9.50993 4.03907 9.66214 4.13805 9.74272 4.28561L12.3123 8.65118C12.6883 9.14064 12.7349 9.62651 12.529 10.1124C12.3589 10.5101 12.015 10.8772 11.5459 11.2191C11.408 11.3378 11.2433 11.4584 11.0696 11.5844C10.4948 12.0036 9.8412 12.4877 10.065 13.0563L10.0597 13.042Z" fill="#382500" />
</svg>

const ICON_CALL_TO_ARMS_DEALER_HOVER = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M10.0597 13.042C10.7992 14.3826 11.6515 15.6692 12.7582 16.8425C13.8684 18.023 15.249 19.0973 17.036 20.015C17.1685 20.0798 17.2939 20.0798 17.4067 20.0348C17.5768 19.97 17.7505 19.8278 17.9206 19.6569C18.0531 19.5237 18.2178 19.3114 18.3898 19.0793C19.0774 18.1705 19.9279 17.0422 21.1294 17.6073C21.1563 17.6199 21.176 17.6343 21.2029 17.6451L25.2103 19.961C25.2229 19.9682 25.2372 19.9808 25.2479 19.988C25.7762 20.3533 25.9946 20.9165 26 21.5554C26 22.205 25.7618 22.9356 25.4127 23.5528C24.9507 24.368 24.2702 24.9078 23.4859 25.2641C22.7392 25.6096 21.9084 25.795 21.1097 25.9137C19.8563 26.0991 18.6816 25.9803 17.4801 25.6096C16.3054 25.2443 15.1218 24.6415 13.829 23.8389L13.7341 23.7777C13.1414 23.4052 12.5003 23.0076 11.8718 22.5379C9.57261 20.7924 7.22865 18.2713 5.70123 15.4965C4.42091 13.1679 3.72256 10.654 4.10397 8.25889C4.31527 6.94526 4.87574 5.75039 5.85343 4.96222C6.70578 4.27121 7.85358 3.89332 9.33982 4.02648C9.50993 4.03907 9.66214 4.13805 9.74272 4.28561L12.3123 8.65118C12.6883 9.14064 12.7349 9.62651 12.529 10.1124C12.3589 10.5101 12.015 10.8772 11.5459 11.2191C11.408 11.3378 11.2433 11.4584 11.0696 11.5844C10.4948 12.0036 9.8412 12.4877 10.065 13.0563L10.0597 13.042Z" fill="#644E24" />
</svg>

const IMAGE_BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="269" height="90" viewBox="0 0 269 90" fill="none">
  <g filter="url(#filter0_f_437_7148)">
    <ellipse cx="110" cy="44.5" rx="89" ry="34.5" fill="#7B5E34" />
  </g>
  <defs>
    <filter id="filter0_f_437_7148" x="-49" y="-60" width="318" height="209" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="35" result="effect1_foregroundBlur_437_7148" />
    </filter>
  </defs>
</svg>

const GEAR_TIER1_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28 13L28 15L25.8913 15.6217C25.1713 20.9518 20.9518 25.1713 15.6217 25.8913L15 28L13 28L12.3783 25.8913C7.04822 25.1713 2.82872 20.9518 2.10865 15.6217L-6.55671e-07 15L-5.68248e-07 13L2.10865 12.3783C2.82872 7.04822 7.04822 2.82872 12.3783 2.10865L13 -6.55671e-07L15 -5.68248e-07L15.6217 2.10865C20.9518 2.82872 25.1713 7.04822 25.8913 12.3783L28 13Z" fill="#FFF1A7" />
</svg>

const GEAR_TIER1_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <circle cx="9" cy="9" r="8.5" fill="#AD7726" stroke="#A06B1C" />
</svg>

const GEAR_TIER2_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28 13L28 15L25.8913 15.6217C25.1713 20.9518 20.9518 25.1713 15.6217 25.8913L15 28L13 28L12.3783 25.8913C7.04822 25.1713 2.82872 20.9518 2.10865 15.6217L-6.55671e-07 15L-5.68248e-07 13L2.10865 12.3783C2.82872 7.04822 7.04822 2.82872 12.3783 2.10865L13 -6.55671e-07L15 -5.68248e-07L15.6217 2.10865C20.9518 2.82872 25.1713 7.04822 25.8913 12.3783L28 13Z" fill="#ECB86A" />
</svg>

const GEAR_TIER2_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <circle cx="9" cy="9" r="8.5" fill="#844C0B" stroke="#774305" />
</svg>

const GEAR_TIER3_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28 13L28 15L25.8913 15.6217C25.1713 20.9518 20.9518 25.1713 15.6217 25.8913L15 28L13 28L12.3783 25.8913C7.04822 25.1713 2.82872 20.9518 2.10865 15.6217L-6.55671e-07 15L-5.68248e-07 13L2.10865 12.3783C2.82872 7.04822 7.04822 2.82872 12.3783 2.10865L13 -6.55671e-07L15 -5.68248e-07L15.6217 2.10865C20.9518 2.82872 25.1713 7.04822 25.8913 12.3783L28 13Z" fill="#A4A7B0" />
</svg>

const GEAR_TIER3_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <circle cx="9" cy="9" r="8.5" fill="#41434B" stroke="#313338" />
</svg>

const GEAR_TIER4_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28 13L28 15L25.8913 15.6217C25.1713 20.9518 20.9518 25.1713 15.6217 25.8913L15 28L13 28L12.3783 25.8913C7.04822 25.1713 2.82872 20.9518 2.10865 15.6217L-6.55671e-07 15L-5.68248e-07 13L2.10865 12.3783C2.82872 7.04822 7.04822 2.82872 12.3783 2.10865L13 -6.55671e-07L15 -5.68248e-07L15.6217 2.10865C20.9518 2.82872 25.1713 7.04822 25.8913 12.3783L28 13Z" fill="#A86637" />
</svg>

const GEAR_TIER4_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <circle cx="9" cy="9" r="8.5" fill="#301A0A" stroke="#0D0702" />
</svg>

const GEAR_TIER5_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M28 13L28 15L25.8913 15.6217C25.1713 20.9518 20.9518 25.1713 15.6217 25.8913L15 28L13 28L12.3783 25.8913C7.04822 25.1713 2.82872 20.9518 2.10865 15.6217L-6.55671e-07 15L-5.68248e-07 13L2.10865 12.3783C2.82872 7.04822 7.04822 2.82872 12.3783 2.10865L13 -6.55671e-07L15 -5.68248e-07L15.6217 2.10865C20.9518 2.82872 25.1713 7.04822 25.8913 12.3783L28 13Z" fill="#7A7070" />
</svg>

const GEAR_TIER5_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <circle cx="9" cy="9" r="8.5" fill="#131212" stroke="black" />
</svg>

const IN_DETAIL_GEAR_TIER1_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16.7143L36.5 19.2857L33.7889 20.0851C32.8631 26.938 27.438 32.3631 20.5851 33.2889L19.7857 36L17.2143 36L16.4149 33.2889C9.56199 32.3631 4.13693 26.938 3.21112 20.0851L0.499999 19.2857L0.499999 16.7143L3.21112 15.9149C4.13693 9.06199 9.56199 3.63693 16.4149 2.71112L17.2143 -8.43005e-07L19.7857 -7.30605e-07L20.5851 2.71112C27.438 3.63693 32.8631 9.06199 33.7889 15.9149L36.5 16.7143Z" fill="#FFF1A7" />
</svg>

const IN_DETAIL_GEAR_TIER2_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16.7143L36.5 19.2857L33.7889 20.0851C32.8631 26.938 27.438 32.3631 20.5851 33.2889L19.7857 36L17.2143 36L16.4149 33.2889C9.56199 32.3631 4.13693 26.938 3.21112 20.0851L0.499999 19.2857L0.499999 16.7143L3.21112 15.9149C4.13693 9.06199 9.56199 3.63693 16.4149 2.71112L17.2143 -8.43005e-07L19.7857 -7.30605e-07L20.5851 2.71112C27.438 3.63693 32.8631 9.06199 33.7889 15.9149L36.5 16.7143Z" fill="#ECB86A" />
</svg>

const IN_DETAIL_GEAR_TIER3_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16.7143L36.5 19.2857L33.7889 20.0851C32.8631 26.938 27.438 32.3631 20.5851 33.2889L19.7857 36L17.2143 36L16.4149 33.2889C9.56199 32.3631 4.13693 26.938 3.21112 20.0851L0.499999 19.2857L0.499999 16.7143L3.21112 15.9149C4.13693 9.06199 9.56199 3.63693 16.4149 2.71112L17.2143 -8.43005e-07L19.7857 -7.30605e-07L20.5851 2.71112C27.438 3.63693 32.8631 9.06199 33.7889 15.9149L36.5 16.7143Z" fill="#A4A7B0" />
</svg>

const IN_DETAIL_GEAR_TIER4_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16.7143L36.5 19.2857L33.7889 20.0851C32.8631 26.938 27.438 32.3631 20.5851 33.2889L19.7857 36L17.2143 36L16.4149 33.2889C9.56199 32.3631 4.13693 26.938 3.21112 20.0851L0.499999 19.2857L0.499999 16.7143L3.21112 15.9149C4.13693 9.06199 9.56199 3.63693 16.4149 2.71112L17.2143 -8.43005e-07L19.7857 -7.30605e-07L20.5851 2.71112C27.438 3.63693 32.8631 9.06199 33.7889 15.9149L36.5 16.7143Z" fill="#A86637" />
</svg>

const IN_DETAIL_GEAR_TIER5_BACKGROUND = <svg className='gear-tier-background' xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5 16.7143L36.5 19.2857L33.7889 20.0851C32.8631 26.938 27.438 32.3631 20.5851 33.2889L19.7857 36L17.2143 36L16.4149 33.2889C9.56199 32.3631 4.13693 26.938 3.21112 20.0851L0.499999 19.2857L0.499999 16.7143L3.21112 15.9149C4.13693 9.06199 9.56199 3.63693 16.4149 2.71112L17.2143 -8.43005e-07L19.7857 -7.30605e-07L20.5851 2.71112C27.438 3.63693 32.8631 9.06199 33.7889 15.9149L36.5 16.7143Z" fill="#7A7070" />
</svg>

const IN_DETAIL_GEAR_TIER_FRAME = <svg className='gear-tier-frame' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <circle cx="12.5001" cy="11.9999" r="11.5714" fill="#422904" />
</svg>

const DISCOUNT_FRAME = <svg className='figmalist-body-cardboard-card-banner-frame' xmlns="http://www.w3.org/2000/svg" width="89" height="80" viewBox="0 0 89 80" fill="none">
  <g filter="url(#filter0_f_1268_76133)">
    <path d="M10 10H79V55L44.5 70L10 55V10Z" fill="#32411A" />
  </g>
  <path d="M10 10H79V55L44.5 70L10 55V10Z" fill="url(#paint0_linear_1268_76133)" />
  <path d="M13 10H76V53.0331L44.5 66.7287L13 53.0331V10Z" fill="url(#paint1_linear_1268_76133)" />
  <defs>
    <filter id="filter0_f_1268_76133" x="0" y="0" width="89" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1268_76133" />
    </filter>
    <linearGradient id="paint0_linear_1268_76133" x1="44.5" y1="14.5" x2="44.5" y2="70" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6A912B" />
      <stop offset="1" stop-color="#407F22" />
    </linearGradient>
    <linearGradient id="paint1_linear_1268_76133" x1="44.5" y1="14.5" x2="44.5" y2="70" gradientUnits="userSpaceOnUse">
      <stop stop-color="#4B6A17" />
      <stop offset="1" stop-color="#53740D" />
    </linearGradient>
  </defs>
</svg>

const DISCOUNT_VECTOR = <svg className='svg' xmlns="http://www.w3.org/2000/svg" width="80" height="21" viewBox="0 0 80 21" fill="none">
  <path d="M1.5 2L78.5 19" stroke="#DD4110" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const BACKGROUND_VECTOR = <svg className='figmalist-background-vector' xmlns="http://www.w3.org/2000/svg" width="1920" height="714" viewBox="0 0 1920 714" fill="none" style={{ pointerEvents: 'none' }}>
  <g className="path" opacity="0.4" filter="url(#filter0_f_1268_72250)">
    <path d="M-33.5 148L1934 -366.5V-243L-33.5 663.5V148Z" fill="#F9E3AB" />
  </g>
  <defs>
    <filter id="filter0_f_1268_72250" x="-83.5" y="-416.5" width="2067.5" height="1130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_1268_72250" />
    </filter>
  </defs>
</svg>

const DETAIL_IMAGE_BOTTOM_VECTOR = <svg className='detail-image-vector bottom-vector' xmlns="http://www.w3.org/2000/svg" width="504" height="8" viewBox="0 0 504 8" fill="none">
  <path d="M1 8V1H503V8" stroke="#7F634A" stroke-width="1.5" />
</svg>

const DETAIL_IMAGE_LEFT_VECTOR = <svg className='detail-image-vector left-vector' xmlns="http://www.w3.org/2000/svg" width="8" height="212" viewBox="0 0 8 212" fill="none">
  <path d="M0 1L7.00001 1L7 211L-9.17939e-06 211" stroke="#7F634A" stroke-width="1.5" />
</svg>

const SEP = <svg xmlns="http://www.w3.org/2000/svg" width="2" height="31" viewBox="0 0 2 31" fill="none">
  <path d="M1 0.5V30.5" stroke="#76664F" />
</svg>

export const GEAR_TIER1 = <div className='gear-tier'>
  {GEAR_TIER1_BACKGROUND}
  {GEAR_TIER1_FRAME}
  <span className='gear-tier1-label'>1</span>
</div>

export const GEAR_TIER2 = <div className='gear-tier'>
  {GEAR_TIER2_BACKGROUND}
  {GEAR_TIER2_FRAME}
  <span className='gear-tier2-label'>2</span>
</div>

export const GEAR_TIER3 = <div className='gear-tier'>
  {GEAR_TIER3_BACKGROUND}
  {GEAR_TIER3_FRAME}
  <span className='gear-tier3-label'>3</span>
</div>

export const GEAR_TIER4 = <div className='gear-tier'>
  {GEAR_TIER4_BACKGROUND}
  {GEAR_TIER4_FRAME}
  <span className='gear-tier4-label'>4</span>
</div>

export const GEAR_TIER5 = <div className='gear-tier'>
  {GEAR_TIER5_BACKGROUND}
  {GEAR_TIER5_FRAME}
  <span className='gear-tier5-label'>5</span>
</div>

const GEAR_TIERS = [
  GEAR_TIER1,
  GEAR_TIER2,
  GEAR_TIER3,
  GEAR_TIER4,
  GEAR_TIER5,
]

export const IN_DETAIL_GEAR_TIER1 = <div className='in-detail-gear-tier'>
  {IN_DETAIL_GEAR_TIER1_BACKGROUND}
  {IN_DETAIL_GEAR_TIER_FRAME}
  <span className='gear-tier1-label in-detail-gear-tier-label'>1</span>
</div>

export const IN_DETAIL_GEAR_TIER2 = <div className='in-detail-gear-tier'>
  {IN_DETAIL_GEAR_TIER2_BACKGROUND}
  {IN_DETAIL_GEAR_TIER_FRAME}
  <span className='gear-tier2-label in-detail-gear-tier-label'>2</span>
</div>

export const IN_DETAIL_GEAR_TIER3 = <div className='in-detail-gear-tier'>
  {IN_DETAIL_GEAR_TIER3_BACKGROUND}
  {IN_DETAIL_GEAR_TIER_FRAME}
  <span className='gear-tier3-label in-detail-gear-tier-label'>3</span>
</div>

export const IN_DETAIL_GEAR_TIER4 = <div className='in-detail-gear-tier'>
  {IN_DETAIL_GEAR_TIER4_BACKGROUND}
  {IN_DETAIL_GEAR_TIER_FRAME}
  <span className='gear-tier4-label in-detail-gear-tier-label'>4</span>
</div>

export const IN_DETAIL_GEAR_TIER5 = <div className='in-detail-gear-tier'>
  {IN_DETAIL_GEAR_TIER5_BACKGROUND}
  {IN_DETAIL_GEAR_TIER_FRAME}
  <span className='gear-tier5-label in-detail-gear-tier-label'>5</span>
</div>

const IN_DETAIL_GEAR_TIERS = [
  IN_DETAIL_GEAR_TIER1,
  IN_DETAIL_GEAR_TIER2,
  IN_DETAIL_GEAR_TIER3,
  IN_DETAIL_GEAR_TIER4,
  IN_DETAIL_GEAR_TIER5,
]

const ICON_LEFT_SIDE_RECT = <svg xmlns="http://www.w3.org/2000/svg" width="3" height="21" viewBox="0 0 3 21" fill="none">
  <path d="M0 3L3 0V21L0 18V3Z" fill="#DBC5A3" />
</svg>

const ICON_RIGHT_SIDE_RECT = <svg xmlns="http://www.w3.org/2000/svg" width="3" height="21" viewBox="0 0 3 21" fill="none">
  <path d="M3 3L0 0V21L3 18V3Z" fill="#DBC5A3" />
</svg>

const ICON_DISCOUNT = <svg xmlns="http://www.w3.org/2000/svg" width="78" height="19" viewBox="0 0 78 19" fill="none" className='icon_discount'>
  <path d="M0.5 1.5L77 17.5" stroke="#F82424" strokeWidth="3" />
</svg>

export class FigmaGearsRoot extends React.Component {
  constructor(props) {
    super(props)
    this.rng = new Rng(Rng.randomseed());

    this.nextItemId = 0;

    this.inventories = [];
    this.createInventoryItems();

    this.market_listings = [];
    this.createMarketItems();
  }

  createInventoryItems() {
    const start_items = [
      'firearm_ar_t5',
      'firearm_sg_t5',
      'firearm_smg_t5',
      'firearm_hg_t5_with_shield',
      'vest_bulletproof_aramid_t5',
      'throwable_impact_frag_t2',
    ]

    for (const key of start_items) {
      const ty = key.split('_')[0];

      if (ty === 'firearm') {
        const firearm_base = firearms.find((f) => f.key === key);
        if (firearm_base) {
          const firearm = { ...firearm_base, options: [] };
          this.addOption(firearm, 'firearm_option_rusty');
          this.inventories.push({ ty: 'firearm', buy_cost: firearm.buy_cost, sell_cost: firearm.sell_cost, firearm, id: this.nextItemId++ });
        }
      }
      else if (ty === 'vest') {
        const equipment_base = gears_vest_bulletproof.find((g) => g.key === key);
        if (equipment_base) {
          const equipment = { ...equipment_base, options: [] };
          this.addOption(equipment, 'vest_option_worn');
          this.inventories.push({ ty: 'equipment', buy_cost: equipment.buy_cost, sell_cost: equipment.sell_cost, equipment, id: this.nextItemId++ });
        }
      }
      else if (ty === 'throwable') {
        const throwable_base = throwables.find((t) => t.key === key);
        if (throwable_base) {
          const throwable = { ...throwable_base, options: [] };
          this.inventories.push({ ty: 'throwable', buy_cost: throwable.buy_cost, sell_cost: throwable.sell_cost, throwable, id: this.nextItemId++ });
        }
      }
    }
  }

  addOption(item, option_id) {
    const option = data_itemOptions.find((d) => d.id === option_id);
    for (const { modifier, value } of option.modifiers) {
      if (modifier === 'price') {
        item.buy_cost *= value;
        item.sell_cost *= value;
      }
      else if (item[modifier]) {
        item[modifier] *= value;
      }
    }
    item.options.push(option.name);
  }

  createMarketItems() {
    const { rng } = this;

    this.market_listings.splice(0, this.market_listings.length);

    const itemsIdx = [];
    let idx = 0;

    const addItem = (key) => {
      const ty = key.split('_')[0];
      if (ty === 'firearm') {
        const firearm_base = firearms.find((f) => f.key === key);
        if (firearm_base) {
          const firearm = { ...firearm_base, options: [] };
          this.applyRandomOption(firearm, 'firearm');
          const item = {
            ty: 'firearm',
            buy_cost: firearm.buy_cost,
            sell_cost: firearm.sell_cost,
            firearm: { ...firearm },
            idx: 0,
          };

          item.id = this.nextItemId++;
          this.market_listings.push(item);
        }
      }
      else if (ty === 'vest') {
        const equipment_base = gears_vest_bulletproof.find((g) => g.key === key);
        if (equipment_base) {
          const equipment = { ...equipment_base, options: [] };
          this.applyRandomOption(equipment, 'equipment');
          const item = {
            ty: 'equipment',
            buy_cost: equipment.buy_cost,
            sell_cost: equipment.sell_cost,
            equipment: { ...equipment },
            idx: 0,
          };

          item.id = this.nextItemId++;
          this.market_listings.push(item);
        }
      }
      else if (ty === 'throwable') {
        const throwable_base = throwables.find((t) => t.key === key);
        if (throwable_base) {
          const throwable = { ...throwable_base, options: [] };
          this.applyRandomOption(throwable, 'throwable');
          const item = {
            ty: 'throwable',
            buy_cost: throwable.buy_cost,
            sell_cost: throwable.sell_cost,
            throwable: { ...throwable },
            idx: 0,
          };

          item.id = this.nextItemId++;
          this.market_listings.push(item);
        }
      }

      itemsIdx.push(idx++);
    }

    const data = data_shopItemGroups.find((d) => d.id === 'level_0_0');
    if (!data) {
      return;
    }

    for (const key of data.always) {
      addItem(key);
    }
    addItem(rng.choice(data.pick_one_0));
    addItem(rng.choice(data.pick_one_1));

    for (let i = 0; i < 2; i++) {
      const idx = rng.integer(0, itemsIdx.length - 1);
      const saleItem = this.market_listings[itemsIdx[idx]];
      saleItem.origin_cost = saleItem.buy_cost;
      saleItem.buy_cost = Math.round(saleItem.buy_cost * 0.5);

      itemsIdx.splice(idx, 1);
    }
  }

  applyRandomOption(item, ty) {
    const { rng } = this;
    let options = [];
    if (ty === 'firearm') {
      options = data_itemOptions.filter((d) => d.item_type === 'firearm' && d.item_group.find((i) => i === item.firearm_ty));
    }
    else if (ty === 'equipment') {
      options = data_itemOptions.filter((d) => d.item_type === 'equipment');
    }
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            item.buy_cost *= value;
            item.sell_cost *= value;
          }
          else if (item[modifier]) {
            item[modifier] *= value;
          }
        }
        item.options.push(option.name);
        return;
      }
    }
  }

  render() {
    return <div className='figmalist-testroot'>
      <FigmaInventoryView items={this.inventories} />
    </div>
  }
}

export function FigmaMarketView(props) {
  const { items, buttonDisabled, balance, originalCostFunction, buyCostFunction, onSelectedChange, purchaseTriggered, isDealed } = props;
  const { subtitle, disableSchedule, scheduleTitle, scheduleName } = props
  const { onMarketListingPurchase, onClickButtonClose, onClickSchedule } = props;

  const [isHovering, setIsHovering] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  function handleHovering() {
    setIsHovering(prevState => !prevState);
  }

  function onSelectedItemChange(newSelectedItem) {
    setSelectedItem(newSelectedItem);
    onSelectedChange(newSelectedItem);
  }

  const header = <div className='figmalist-header-market'>
    <div className='figmalist-header-title-frame'>
      <div className='figmalist-header-title-rectangle' />
      <span className='figmalist-header-title-label'>{L('loc_ui_string_menu_market')}</span>
    </div>
    <SoundButton className={`figmalist-header-button`} disabled={disableSchedule} title={L(scheduleTitle)} cueList={['UI_Outgame_InsertShot_Meet']}
      onClick={() => onClickSchedule()} onMouseEnter={handleHovering} onMouseLeave={handleHovering} >
      {!disableSchedule && isHovering ? ICON_CALL_TO_ARMS_DEALER_HOVER : ICON_CALL_TO_ARMS_DEALER}
      <span className='figmalist-header-button-label'>{L(scheduleName)}</span>
    </SoundButton>
    {subtitle}
  </div>
  return <FigmaGearsView2 header={header} items={items} buy={true} originalCostFunction={originalCostFunction} costFunction={buyCostFunction} buttonDisabled={buttonDisabled}
    balance={balance} onClickTradeItem={onMarketListingPurchase} onClickButtonClose={onClickButtonClose} onSelectedItemChange={onSelectedItemChange} buyOrSellTriggered={purchaseTriggered} isDealed={isDealed} />
}

export function FigmaInventoryView(props) {
  const { items, buttonDisabled, balance, sellCostFunction, onSelectedChange, sellTriggered } = props;
  const { onSellItem, onClickButtonClose } = props;
  const [selectedItem, setSelectedItem] = useState(null);

  function onSelectedItemChange(newSelectedItem) {
    setSelectedItem(newSelectedItem);
    onSelectedChange(newSelectedItem);
  }

  const header = <div className='figmalist-header-inventory'>
    <div className='figmalist-header-title-frame'>
      <div className='figmalist-header-title-rectangle' />
      <span className='figmalist-header-title-label'>{L('loc_ui_string_menu_armory')}</span>
    </div>
  </div>
  return <FigmaGearsView2 header={header} items={items} buy={false} originalCostFunction={sellCostFunction} costFunction={sellCostFunction} buttonDisabled={buttonDisabled}
    balance={balance} onClickTradeItem={onSellItem} onClickButtonClose={onClickButtonClose} onSelectedItemChange={onSelectedItemChange} buyOrSellTriggered={sellTriggered} />
}

function getItemRate(item) {
  switch (item.ty) {
    case "firearm":
      return item.firearm.firearm_rate;
    case "equipment":
      return item.equipment.vest_rate;
    case "throwable":
      return item.throwable.throwable_rate
    default:
      return null;
  }
}

function GetTier(props) {
  const { rate } = props;
  return GEAR_TIERS[rate - 1];
}

function GetInDetailTier(props) {
  const { rate } = props;
  return IN_DETAIL_GEAR_TIERS[rate - 1];
}

function ItemBuy(props) {
  const { onClickTradeItem, buy, item } = props;
  let price = null
  const cost = buy ? item.buy_cost : item.sell_cost;
  const originCost = item.origin_cost;

  let title = null;
  let disabled = false;
  if (!onClickTradeItem) {
    disabled = true;
    title = L('loc_ui_longtext_market_cannot_sell');
  }

  if (originCost) {
    price = <div className='figmalist-body-list-item-detail-buy-price-discount'>
      <div className='figmalist-body-list-item-detail-buy-price-before'>
        <span className='figmalist-body-list-item-detail-buy-price-before-label'>$ {originCost.toLocaleString('en-US')}</span>
        {ICON_DISCOUNT}
      </div>
      <span className='figmalist-body-list-item-detail-buy-price-label'>$ {cost.toLocaleString('en-US')}</span>
    </div>
  } else {
    price = <span className='figmalist-body-list-item-detail-buy-price-label'>$ {cost.toLocaleString('en-US')}</span>
  }

  return <div className='figmalist-body-list-item-detail-buy'>
    {price}
    <button className='figmalist-body-list-item-detail-buy-btn' disabled={disabled} title={title} onClick={() => onClickTradeItem(item)}>
      <span className='figmalist-body-list-item-detail-buy-btn-label'>{buy ? L('loc_ui_string_market_buy') : L('loc_ui_string_market_sell')}</span>
    </button>
  </div>

}

function Item2(props) {
  const { itemCard, idx, selected, onSelect, willBeDisposed } = props;
  const { name, rate, ty, type, buy, original_cost, cost, key, owner, img_key } = itemCard;

  const isSale = buy && original_cost > cost;
  let discountRateComponent = null;
  if (isSale) {
    discountRateComponent = <div className='figmalist-body-cardboard-card-banner'>
      {DISCOUNT_FRAME}
      <span className='rate'>{Math.floor(cost / original_cost * 100) + '%'}</span>
      <span className='discount'>{L('loc_ui_string_market_discount')}</span>
    </div>;
  } else if (willBeDisposed && !selected) {
    discountRateComponent = <div className='figmalist-body-cardboard-card-banner-disposal'>
      <span>{L('loc_ui_string_intermission_disposal')}</span>
    </div>;
  }
  let disposalSelectedComponent = null;
  if (willBeDisposed) {
    disposalSelectedComponent = <div className='figmalist-body-cardboard-card-overlay'>
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="37" viewBox="0 0 27 37" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.15385 14.3889H0V37H27V14.3889H22.8462V9.25C22.8462 4.14137 18.6617 0 13.5 0C8.33826 0 4.15385 4.14137 4.15385 9.25V14.3889ZM9.34615 14.3889H17.6538V9.25C17.6538 6.9795 15.7941 5.13889 13.5 5.13889C11.2059 5.13889 9.34615 6.9795 9.34615 9.25V14.3889Z" fill="#B4ACA4" />
      </svg>
    </div>;
  }

  const priceLine = [];
  if (isSale) {
    priceLine.push(<div className='figmalist-body-cardboard-card-price-origin-frame'>
      <span key='origin' className='figmalist-body-cardboard-card-price-origin'>
        $ {original_cost}
      </span>
      {DISCOUNT_VECTOR}
    </div>);
  }
  priceLine.push(<span key='price' className={'figmalist-body-cardboard-card-price' + (buy ? '' : ' for-selling')}>{'$ ' + cost}</span>);

  return <div key={idx} className={'figmalist-body-cardboard-card' + (selected ? ' selected' : '') + (true ? ' disposal' : '')} onClick={() => {
    onSelect();
    triggerSound('UI_Outgame_Button_Click_Default');
  }}>
    <GearNameLine type={type} rate={rate} firearm_ty={ty} name={name} />
    <div className='figmalist-body-cardboard-card-item-image'>
      <div className='figmalist-body-cardboard-card-item-image-background' />
      <div className='figmalist-body-cardboard-card-item-image-rect' />
      <div className='figmalist-body-cardboard-card-item-image-upper-background'>
        <div className='figmalist-body-cardboard-card-item-image-grid' />
      </div>
      <img src={'/gears/' + descKey(img_key) + '.png'} alt='item' onDragStart={(e) => e.preventDefault()} />
      {discountRateComponent}
      {owner ? <div className='figmalist-body-cardboard-card-item-image-owner'>
        <div className='figmalist-body-cardboard-card-item-image-owner-portrait'>
          <PortraitWrapper2 agent={owner} className="portrait-frame-for-armory" />
        </div>
        <div className='figmalist-body-cardboard-card-item-image-owner-text-frame'>
          <div className='equipped-by'>{L('loc_ui_string_armory_equipped_by_agent')}</div>
          <div className='owner-name'>{L(owner.name)}</div>
        </div>
      </div>
        : <></>}
    </div>
    <div className='figmalist-body-cardboard-card-footer'>
      {priceLine}
    </div>
    {selected ? disposalSelectedComponent : null}
  </div>;
}


export function GearNameLine(props) {
  const { type, rate, firearm_ty, name, short } = props;

  function getTypeIcon(ty) {
    switch (ty) {
      case 'firearm': return ICON_FIREARM_SELECTED;
      case 'equipment': return ICON_EQUIPMENT_SELECTED;
      case 'throwable': return ICON_THROWABLE_SELECTED;
    }
  }

  return <div className='figmalist-body-gear-name-line'>
    {getTypeIcon(type)}
    <div className='figmalist-body-gear-name-line-vector' />
    <div className='tier-and-type-frame'>
      <GetTier rate={rate} />
      {firearm_ty ?
        <div className='figmalist-body-list-item-detail-inform-title-firearm-ty'>
          {ICON_LEFT_SIDE_RECT}
          <div className='figmalist-body-list-item-detail-inform-title-firearm-ty-frame'>
            <div className='figmalist-body-list-item-detail-inform-title-firearm-ty-label-frame'>
              <span className='figmalist-body-list-item-detail-inform-title-firearm-ty-label'>{firearm_ty.toUpperCase()}</span>
            </div>
          </div>
          {ICON_RIGHT_SIDE_RECT}
        </div> : null}
    </div>
    <div className='figmalist-body-gear-name-line-name-frame'>
      <span className='figmalist-body-gear-name-line-name'>{L([name + (short ? '_short' : ''), name])}</span>
    </div>
  </div>;
}

export function FirearmItem2(props) {
  const { item, type, original_cost, cost, idx, buy, selected, onSelect, willBeDisposed } = props;
  const itemCard = {
    name: L(item.firearm.firearm_name),
    rate: item.firearm.firearm_rate,
    type: type,
    ty: item.firearm.firearm_ty,
    buy: buy,
    original_cost,
    cost,
    key: item.firearm.key,
    img_key: item.firearm.img_key,
    owner: item.owner,
  };

  return <Item2 itemCard={itemCard} idx={idx} selected={selected} onSelect={onSelect} willBeDisposed={willBeDisposed} />
}

export function EquipmentItem2(props) {
  const { item, type, original_cost, cost, idx, buy, selected, onSelect, willBeDisposed } = props;
  const itemCard = {
    name: L(item.equipment.vest_name),
    rate: item.equipment.vest_rate,
    type: type,
    buy: buy,
    original_cost,
    cost,
    key: item.equipment.key,
    img_key: item.equipment.img_key,
    owner: item.owner,
  };

  return <Item2 itemCard={itemCard} idx={idx} selected={selected} onSelect={onSelect} willBeDisposed={willBeDisposed} />
}

export function ThrowableItem2(props) {
  const { item, type, original_cost, cost, idx, buy, selected, onSelect, willBeDisposed } = props;
  const itemCard = {
    name: L(item.throwable.throwable_name),
    rate: item.throwable.throwable_rate,
    type: type,
    buy: buy,
    original_cost,
    cost,
    key: item.throwable.key,
    img_key: item.throwable.img_key,
    owner: item.owner,
  };

  return <Item2 itemCard={itemCard} idx={idx} selected={selected} onSelect={onSelect} willBeDisposed={willBeDisposed} />
}

export function descKey(key) {
  const prefix = key?.split('_')[0] ?? '';
  switch (prefix) {
    case 'firearm':
    case 'vest':
      return key;
    case 'throwable':
      return key.substring(0, key.lastIndexOf('_'));
    default:
      return key;
  }
}

export function GearDetailView(props) {
  const { item, buy, buy_cost, onClickTradeItem, buttonDisabled, balance } = props;
  if (item === undefined || item === null) { return <div className='figmalist-body-gear-detail empty'></div>; }
  const { firearm, equipment, throwable } = item;
  const gear = firearm || equipment || throwable;
  let isFirearm = false;
  const ty = firearm ? 'firearm' : equipment ? 'equipment' : 'throwable';
  let rate = undefined;
  switch (ty) {
    case "firearm":
      isFirearm = true;
      rate = item.firearm.firearm_rate;
      break;
    case "equipment":
      rate = item.equipment.vest_rate;
      break;
    case "throwable":
      rate = item.throwable.throwable_rate;
      break;
  }
  const name = gear.firearm_name || gear.vest_name || gear.throwable_name;
  const key = descKey(gear.key);
  const img_key = descKey(gear.img_key);

  const title = (ty === 'firearm' ? L('loc_ui_string_market_detail_firearm') : ty === 'equipment' ? L('loc_ui_string_market_detail_equipment') : L('loc_ui_string_market_detail_throwable'));
  const descTitle = (ty === 'firearm' ? L('loc_ui_string_market_description_firearm') : ty === 'equipment' ? L('loc_ui_string_market_description_equipment') : L('loc_ui_string_market_description_throwable'));
  const descParagraph = L(itemDescs.find((item) => item.key === key).desc);

  return <div className='figmalist-body-gear-detail'>
    <div className='figmalist-body-gear-detail-img-stat'>
      <div className='figmalist-body-gear-detail-overview'>
        <div className='figmalist-body-gear-detail-title-frame'>
          <div className='inner-frame'>
            <div className='figmalist-body-gear-detail-title'>
              {title}: {L(name)}
            </div>
          </div>
        </div>
        <div className='figmalist-body-gear-detail-imagearea'>
          <div className='rect' />
          <div className='grid' />
          <div className='ellipse' />
          <img className='img' src={'/gears/' + img_key + '.png'} alt='item' onDragStart={(e) => e.preventDefault()} />
          <div className='inner-rect' />
          {isFirearm ? (
            <div className='figmalist-body-gear-detail-imagearea-type-tier-frame type-frame'>
              <div className='text'>
                Type
              </div>
              <div className='figmalist-body-list-item-detail-inform-title-firearm-ty'>
                {ICON_LEFT_SIDE_RECT}
                <div className='figmalist-body-list-item-detail-inform-title-firearm-ty-frame'>
                  <div className='figmalist-body-list-item-detail-inform-title-firearm-ty-label-frame'>
                    <span className='figmalist-body-list-item-detail-inform-title-firearm-ty-label'>{item.firearm.firearm_ty.toUpperCase()}</span>
                  </div>
                </div>
                {ICON_RIGHT_SIDE_RECT}
              </div>
            </div>
          ) : <></>}
          <div className='figmalist-body-gear-detail-imagearea-type-tier-frame tier-frame'>
            <div className='text'>
              Tier
            </div>
            <div className='tier'>
              <GetInDetailTier rate={rate} />
            </div>
          </div>
          {DETAIL_IMAGE_BOTTOM_VECTOR}
          {DETAIL_IMAGE_LEFT_VECTOR}
        </div>
      </div>
      <GearDetailSpecView gear={gear} ty={ty} />
    </div>
    <div className='figmalist-body-gear-detail-desc'>
      <div className='figmalist-body-gear-detail-desc-title'>
        <div className='title'>
          {descTitle}
        </div>
      </div>
      <div className='figmalist-body-gear-detail-desc-paragraph'>
        <div className='parag'>
          {descParagraph}
        </div>
      </div>
    </div>
  </div>
}

export function GearDetailSpecView(props) {
  const { gear, ty } = props;
  const specs = [];
  if (ty === 'firearm') {
    const damage = gear.firearm_projectile_damage;
    const penetrate = gear.firearm_projectile_penetrate;
    let fire_interval = gear.firearm_shoot_pattern_interval_sec;
    if (gear.firearm_shoot_pattern?.length > 0) {
      fire_interval = gear.firearm_shoot_pattern[0];
    }
    const rpm = (60 / fire_interval).toFixed(1);
    const reloadSpeed = gear.firearm_reload_duration;
    const ammo = gear.firearm_ammo_max;
    const rolesAvailable = roles
      .filter((r) => r.firearmAptitudes['firearmAptitude_' + gear.firearm_ty.toUpperCase()] < 6)
      .map((r) => r.key);
    const power = gear.firearm_expectedPower / 5;
    const indexOfScout = rolesAvailable.indexOf('scout');
    if (indexOfScout > -1) {
      rolesAvailable.splice(indexOfScout, 1);
    }
    const minRange = gear.firearm_range * gear.firearm_range_optimal_min / 10;
    const maxRange = gear.firearm_range * gear.firearm_range_optimal_max / 10;

    specs.push([{ label: L('loc_ui_string_common_compare_firearm_damage'), value: damage }, { label: L('loc_ui_string_common_compare_firearm_penetrate'), value: penetrate }]);
    specs.push([{ label: L('loc_ui_string_common_compare_firearm_firerate'), value: rpm + ' RPM' }, { label: L('loc_ui_string_common_compare_firearm_reload_speed'), value: reloadSpeed.toFixed(1) }]);
    let rolesValue = rolesAvailable.map((r, i) => <div className='figmalist-body-gear-detail-spec-item-value-badges-frame'><RoleBadge key={i} role={r} isLabel={true} /></div>);
    if (gear.key.endsWith('_with_shield')) {
      rolesValue = [<div className='figmalist-body-gear-detail-spec-item-value-badges-frame'><RoleBadge key={0} role='vanguard' isLabel={true} /></div>];
    }
    specs.push([{ label: L('loc_ui_string_common_compare_firearm_ammo'), value: ammo }, { label: L('loc_ui_string_squad_info_range_effective'), value: minRange.toFixed(0) + 'm ~ ' + maxRange.toFixed(0) + 'm' }]);
    specs.push([{ label: L('loc_ui_string_common_compare_gear_power'), value: power.toFixed(1) }]);
    specs.push([{ label: L('loc_ui_string_market_roles_available'), value: rolesValue }]);
  } else if (ty === 'equipment') {
    const durability = gear.vest_shield;
    const power = gear.gear_expectedPower / 5;
    specs.push([{ label: L('loc_ui_string_common_compare_equipment_durability'), value: durability.toFixed(0) },
    { label: L('loc_ui_string_common_compare_gear_power'), value: power.toFixed(1) }
    ]);
  } else if (ty === 'throwable') {
    const delay = gear.blast_delay;
    const range = gear.blasts.map((b) => b.blast_radius).reduce((a, b) => a > (b ?? 0) ? a : b, 1);
    const damage = gear.blasts.map((b) => b.blast_damage).reduce((a, b) => a > (b ?? 0) ? a : b, 1);
    specs.push([{ label: L('loc_ui_string_common_compare_throwable_range'), value: range.toFixed(0) }, { label: L('loc_ui_string_common_compare_throwable_delay'), value: delay.toFixed(1) + L('loc_ui_string_common_unit_second_short') }]);
    specs.push([{ label: L('loc_ui_string_common_compare_firearm_damage'), value: damage.toFixed(0) }]);
  } else {
    return null;
  }

  let vector_count = 0;
  const spec_length = specs.length;

  return <div className='figmalist-body-gear-detail-spec'>{specs.map((e, i) => {
    vector_count++;
    return <>
      <div key={i} className={ty != 'firearm' || vector_count < spec_length ? 'figmalist-body-gear-detail-spec-itemrow' : 'figmalist-body-gear-detail-spec-itemrow-equippable-roles'}>
        {e.map((spec, j) => {
          return <div key={j} className={ty != 'firearm' || vector_count < spec_length ? 'figmalist-body-gear-detail-spec-item' : 'figmalist-body-gear-detail-spec-item-equippable-roles'}>
            <span className='figmalist-body-gear-detail-spec-item-label'>{spec.label}</span>
            <span className={'figmalist-body-gear-detail-spec-item-value' + (Array.isArray(spec.value) ? ' badges' : '')}>{spec.value}</span>
          </div>;
        })}
      </div>
      {vector_count < spec_length ? <div className='sep-vector' /> : <></>}
    </>;
  })}</div>;
}

export class FigmaGearsView2 extends React.Component {
  constructor(props) {
    super(props);
    const filterOption = {
      firearm: true,
      equipment: true,
      throwable: true
    };
    const sortingOption = 'id';
    const selected = 0;
    const isHovering = false;
    const isPressed = false;
    const filterIdx = 0;

    this.state = { filterOption, sortingOption, selected, isHovering, filterIdx };
  }

  SortItems(items) {
    const { sortingOption, selected } = this.state;
    let sortedItems
    switch (sortingOption) {
      case 'id':
        sortedItems = items.sort((a, b) => b.id - a.id);
        break;
      case 'cost':
        sortedItems = items.sort((a, b) => b.original_buy_cost - a.original_buy_cost);
        break;
      case 'rate':
        sortedItems = items.sort((a, b) => getItemRate(b) - getItemRate(a));
        break;
      default:
        return null;
    }
    return sortedItems;
  }

  OnClickSortOption(sortingOption) {
    this.setState({ sortingOption });
  }

  OnClickFilterOption(ty) {
    const { filterOption } = this.state;
    switch (ty) {
      case 'total':
        filterOption.firearm = true;
        filterOption.equipment = true;
        filterOption.throwable = true;
        break;
      case 'firearm':
        if (!filterOption.firearm) filterOption.firearm = !filterOption.firearm;
        filterOption.equipment = false;
        filterOption.throwable = false;
        break;
      case 'equipment':
        if (!filterOption.equipment) filterOption.equipment = !filterOption.equipment;
        filterOption.firearm = false;
        filterOption.throwable = false;
        break;
      case 'throwable':
        if (!filterOption.throwable) filterOption.throwable = !filterOption.throwable;
        filterOption.firearm = false;
        filterOption.equipment = false;
        break;
    }
    this.setState(filterOption)
  }

  GetNumEach(i) {
    const { filterOption } = this.state;
    const prevFireArmState = filterOption.firearm;
    const prevEquipmentState = filterOption.equipment;
    const prevThrowableState = filterOption.throwable;


    filterOption.firearm = true;
    filterOption.equipment = false;
    filterOption.throwable = false;
    const numFireArm = (i.filter((e) => filterOption[e.ty])).length;
    filterOption.firearm = false;
    filterOption.equipment = true;
    filterOption.throwable = false;
    const numEquipment = (i.filter((e) => filterOption[e.ty])).length;
    filterOption.firearm = false;
    filterOption.equipment = false;
    filterOption.throwable = true;
    const numThrowable = (i.filter((e) => filterOption[e.ty])).length;
    const numTotal = numFireArm + numEquipment + numThrowable;

    filterOption.firearm = prevFireArmState;
    filterOption.equipment = prevEquipmentState;
    filterOption.throwable = prevThrowableState;

    return [numTotal, numFireArm, numEquipment, numThrowable];
  }

  componentDidMount() {
    const { items, onSelectedItemChange } = this.props;
    const { filterOption, selected } = this.state;
    const filteredItems = items.filter((e) => filterOption[e.ty]);
    const listItems = this.SortItems(filteredItems);
    onSelectedItemChange(listItems[selected]);
  }

  componentDidUpdate(prevProps) {
    const { items, onSelectedItemChange, buyOrSellTriggered, isDealed } = this.props;
    const { filterOption, selected } = this.state;
    const filteredItems = items.filter((e) => filterOption[e.ty]);
    const listItems = this.SortItems(filteredItems);
    if (prevProps.buyOrSellTriggered !== buyOrSellTriggered && buyOrSellTriggered || (prevProps.isDealed !== isDealed && isDealed)) {
      onSelectedItemChange(listItems[selected]);
    }
  }

  render() {
    const { header, items, buy, originalCostFunction, costFunction, buttonDisabled, balance, onSelectedItemChange, buyOrSellTriggered } = this.props;
    const { onClickTradeItem, onClickButtonClose } = this.props;
    const { filterOption, selected, isHovering, isPressed, filterIdx } = this.state;
    let filteredItems = items.filter((e) => filterOption[e.ty]);
    let listItems = this.SortItems(filteredItems);
    const numEach = this.GetNumEach(items);
    const numTotal = numEach[0];
    const numFireArm = numEach[1];
    const numEquipment = numEach[2];
    const numThrowable = numEach[3];

    return <div className='figmalist-root'>
      <div className='figmalist-header'>
        <div className='figmalist-header-inner'>
          {header}
        </div>
      </div>
      <div className='figmalist-body'>
        <div className='figmalist-body-content'>
          <div className='figmalist-body-inner'>
            <div className='figmalist-body-header'>
              <div className='figmalist-body-header-left'>
                <div className='figmalist-body-header-left-types'>
                  <div
                    className='figmalist-body-header-left-type'
                    onClick={() => {
                      this.OnClickFilterOption('total');
                      triggerSound('UI_Outgame_Button_Click_Default');
                      this.setState({ selected: 0, selectedItem: listItems[0] });
                      filteredItems = items.filter((e) => filterOption[e.ty]);
                      listItems = this.SortItems(filteredItems);
                      onSelectedItemChange(listItems[0]);
                    }}
                    onMouseEnter={() => { this.setState({ isHovering: true, filterIdx: 0 }) }}
                    onMouseLeave={() => { this.setState({ isHovering: false, filterIdx: 0 }) }}
                    onMouseDown={() => { this.setState({ isPressed: true, filterIdx: 0 }) }}
                    onMouseUp={() => { this.setState({ isPressed: false, filterIdx: 0 }) }}
                  >
                    {filterOption.firearm && filterOption.equipment && filterOption.throwable ? ICON_FILTER_FRAME_SELECTED : (isPressed && filterIdx == 0 ? ICON_FILTER_FRAME_PRESS : (isHovering && filterIdx == 0 ? ICON_FILTER_FRAME_HOVER : ICON_FILTER_FRAME))}
                    <div className='figmalist-body-header-left-type-title'>
                      {filterOption.firearm && filterOption.equipment && filterOption.throwable ? ICON_TOTAL_SELECTED : ((isPressed || isHovering) && filterIdx == 0 ? ICON_TOTAL_HOVER : ICON_TOTAL)}
                    </div>
                    <div className={filterOption.firearm && filterOption.equipment && filterOption.throwable ? 'figmalist-body-header-left-type-number-selected' : ((isPressed || isHovering) && filterIdx == 0 ? 'figmalist-body-header-left-type-number-hover' : 'figmalist-body-header-left-type-number')}>{numTotal}</div>
                  </div>
                  <div
                    className='figmalist-body-header-left-type'
                    onClick={() => {
                      this.OnClickFilterOption('firearm');
                      triggerSound('UI_Outgame_Button_Click_Default');
                      this.setState({ selected: 0, selectedItem: listItems[0] });
                      filteredItems = items.filter((e) => filterOption[e.ty]);
                      listItems = this.SortItems(filteredItems);
                      onSelectedItemChange(listItems[0]);
                    }}
                    onMouseEnter={() => { this.setState({ isHovering: true, filterIdx: 1 }) }}
                    onMouseLeave={() => { this.setState({ isHovering: false, filterIdx: 1 }) }}
                    onMouseDown={() => { this.setState({ isPressed: true, filterIdx: 1 }) }}
                    onMouseUp={() => { this.setState({ isPressed: false, filterIdx: 1 }) }}
                  >
                    {filterOption.firearm && !filterOption.equipment ? ICON_FILTER_FRAME_SELECTED : (isPressed && filterIdx == 1 ? ICON_FILTER_FRAME_PRESS : (isHovering && filterIdx == 1 ? ICON_FILTER_FRAME_HOVER : ICON_FILTER_FRAME))}
                    <div className='figmalist-body-header-left-type-title'>
                      {filterOption.firearm && !filterOption.equipment ? ICON_FIREARM_SELECTED : ((isPressed || isHovering) && filterIdx == 1 ? ICON_FIREARM_HOVER : ICON_FIREARM)}
                    </div>
                    <div className={filterOption.firearm && !filterOption.equipment ? 'figmalist-body-header-left-type-number-selected' : ((isPressed || isHovering) && filterIdx == 1 ? 'figmalist-body-header-left-type-number-hover' : 'figmalist-body-header-left-type-number')}>{numFireArm}</div>
                  </div>
                  <div
                    className='figmalist-body-header-left-type'
                    onClick={() => {
                      this.OnClickFilterOption('equipment');
                      triggerSound('UI_Outgame_Button_Click_Default');
                      this.setState({ selected: 0, selectedItem: listItems[0] });
                      filteredItems = items.filter((e) => filterOption[e.ty]);
                      listItems = this.SortItems(filteredItems);
                      onSelectedItemChange(listItems[0]);
                    }}
                    onMouseEnter={() => { this.setState({ isHovering: true, filterIdx: 2 }) }}
                    onMouseLeave={() => { this.setState({ isHovering: false, filterIdx: 2 }) }}
                    onMouseDown={() => { this.setState({ isPressed: true, filterIdx: 2 }) }}
                    onMouseUp={() => { this.setState({ isPressed: false, filterIdx: 2 }) }}
                  >
                    {filterOption.equipment && !filterOption.throwable ? ICON_FILTER_FRAME_SELECTED : (isPressed && filterIdx == 2 ? ICON_FILTER_FRAME_PRESS : (isHovering && filterIdx == 2 ? ICON_FILTER_FRAME_HOVER : ICON_FILTER_FRAME))}
                    <div className='figmalist-body-header-left-type-title'>
                      {filterOption.equipment && !filterOption.throwable ? ICON_EQUIPMENT_SELECTED : ((isPressed || isHovering) && filterIdx == 2 ? ICON_EQUIPMENT_HOVER : ICON_EQUIPMENT)}
                    </div>
                    <div className={filterOption.equipment && !filterOption.throwable ? 'figmalist-body-header-left-type-number-selected' : ((isPressed || isHovering) && filterIdx == 2 ? 'figmalist-body-header-left-type-number-hover' : 'figmalist-body-header-left-type-number')}>{numEquipment}</div>
                  </div>
                  <div
                    className='figmalist-body-header-left-type' onClick={() => {
                      this.OnClickFilterOption('throwable');
                      triggerSound('UI_Outgame_Button_Click_Default');
                      this.setState({ selected: 0, selectedItem: listItems[0] });
                      filteredItems = items.filter((e) => filterOption[e.ty]);
                      listItems = this.SortItems(filteredItems);
                      onSelectedItemChange(listItems[0]);
                    }}
                    onMouseEnter={() => { this.setState({ isHovering: true, filterIdx: 3 }) }}
                    onMouseLeave={() => { this.setState({ isHovering: false, filterIdx: 3 }) }}
                    onMouseDown={() => { this.setState({ isPressed: true, filterIdx: 3 }) }}
                    onMouseUp={() => { this.setState({ isPressed: false, filterIdx: 3 }) }}
                  >
                    {!filterOption.firearm && filterOption.throwable ? ICON_FILTER_FRAME_SELECTED : (isPressed && filterIdx == 3 ? ICON_FILTER_FRAME_PRESS : (isHovering && filterIdx == 3 ? ICON_FILTER_FRAME_HOVER : ICON_FILTER_FRAME))}
                    <div className='figmalist-body-header-left-type-title'>
                      {!filterOption.firearm && filterOption.throwable ? ICON_THROWABLE_SELECTED : ((isPressed || isHovering) && filterIdx == 3 ? ICON_THROWABLE_HOVER : ICON_THROWABLE)}
                    </div>
                    <div className={!filterOption.firearm && filterOption.throwable ? 'figmalist-body-header-left-type-number-selected' : ((isPressed || isHovering) && filterIdx == 3 ? 'figmalist-body-header-left-type-number-hover' : 'figmalist-body-header-left-type-number')}>{numThrowable}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='figmalist-body-cardboard'>
              {listItems.map((e, i) => {
                switch (e.ty) {
                  case 'firearm':
                    return <FirearmItem2 key={i} type={e.ty} item={e} idx={i} buy={buy} original_cost={originalCostFunction(e)} cost={costFunction(e)} selected={(i === selected)} onSelect={() => { this.setState({ selected: i, selectedItem: listItems[i] }); onSelectedItemChange(listItems[i]); }} />
                  case 'equipment':
                    return <EquipmentItem2 key={i} type={e.ty} item={e} idx={i} buy={buy} original_cost={originalCostFunction(e)} cost={costFunction(e)} selected={(i === selected)} onSelect={() => { this.setState({ selected: i, selectedItem: listItems[i] }); onSelectedItemChange(listItems[i]); }} />
                  case 'throwable':
                    return <ThrowableItem2 key={i} type={e.ty} item={e} idx={i} buy={buy} original_cost={originalCostFunction(e)} cost={costFunction(e)} selected={(i === selected)} onSelect={() => { this.setState({ selected: i, selectedItem: listItems[i] }); onSelectedItemChange(listItems[i]); }} />
                }
              })}
            </div>
          </div>
          <GearDetailView item={listItems[selected]} buy={buy} buy_cost={listItems[selected] ? costFunction(listItems[selected]) : null} onClickTradeItem={onClickTradeItem} buttonDisabled={buttonDisabled} balance={balance} />
        </div>
      </div>
      {BACKGROUND_VECTOR}
    </div>
  }
}
