import source from '../downloaded/eventCooldowns.json' assert { type: 'json' };

export const eventCooldowns = [];
for (const row of source) {
  const { category, cooldown_day, display_ty } = row;
  eventCooldowns[category] = parseInt(cooldown_day);
  eventCooldowns.push({
    category,
    cooldown_day: parseInt(cooldown_day),
    display_ty,
  });
}