import source from "../downloaded/char2characterNames.json" assert { type: 'json' };

export const operatorNames = {};

for (const row of source) {
  const { id, name, nationalityKey } = row;
  operatorNames[id] = {
    name,
    nationalityKey
  }
}
