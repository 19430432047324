import { items } from './data/google/processor/data_items.mjs'

export const gear_vest_bulletproof_nothing = {
  key: 'vest_bulletproof_nothing',
  img_key: 'vest_bulletproof_nothing',

  vest_name: 'loc_ui_string_common_protection_tier_0',
  vest_rate: 0,
  vest_armor: 0,
  vest_shield: 0,
  vest_cost: 0,
  gear_expectedPower: 0,
};

// 아라미드 타입 방탄복
export const gear_vest_bulletproof_aramid_t5 = {
  key: 'vest_bulletproof_aramid_t5',
  img_key: 'vest_bulletproof_aramid_t5',

  vest_ty: 'armor_t5',
  vest_name: 'loc_ui_string_common_protection_aramid_tier_5',
  vest_rate: 5,
  vest_armor: 2,
  vest_shield: 400,
  vest_cost: 0,
  gear_expectedPower: 400 / 44,
}
export const gear_vest_bulletproof_aramid_t4 = {
  key: 'vest_bulletproof_aramid_t4',
  img_key: 'vest_bulletproof_aramid_t4',

  vest_ty: 'armor_t4',
  vest_name: 'loc_ui_string_common_protection_aramid_tier_4',
  vest_rate: 4,
  vest_armor: 4,
  vest_shield: 700,
  vest_cost: 0,
  gear_expectedPower: 700 / 44,
}
export const gear_vest_bulletproof_aramid_t3 = {
  key: 'vest_bulletproof_aramid_t3',
  img_key: 'vest_bulletproof_aramid_t3',

  vest_ty: 'armor_t3',
  vest_name: 'loc_ui_string_common_protection_aramid_tier_3',
  vest_rate: 3,
  vest_armor: 6,
  vest_shield: 1000,
  vest_cost: 0,
  gear_expectedPower: 1000 / 44,
}
export const gear_vest_bulletproof_aramid_t2 = {
  key: 'vest_bulletproof_aramid_t2',
  img_key: 'vest_bulletproof_aramid_t2',

  vest_ty: 'armor_t2',
  vest_name: 'loc_ui_string_common_protection_aramid_tier_2',
  vest_rate: 2,
  vest_armor: 8,
  vest_shield: 1300,
  vest_cost: 0,
  gear_expectedPower: 1300 / 44,
}
export const gear_vest_bulletproof_aramid_t1 = {
  key: 'vest_bulletproof_aramid_t1',
  img_key: 'vest_bulletproof_aramid_t1',

  vest_ty: 'armor_t1',
  vest_name: 'loc_ui_string_common_protection_aramid_tier_1',
  vest_rate: 1,
  vest_armor: 10,
  vest_shield: 1600,
  vest_cost: 0,
  gear_expectedPower: 1600 / 44,
}

// 금속 타입 방탄복
export const gear_vest_bulletproof_metal_t5 = {
  key: 'vest_bulletproof_metal_t5',

  vest_ty: 'metal',
  vest_name: 'loc_ui_string_common_protection_metal_tier_5',
  vest_rate: 5,
  vest_armor: 17,
  vest_shield: 80,
  vest_cost: 0,
}
export const gear_vest_bulletproof_metal_t4 = {
  key: 'vest_bulletproof_metal_t4',

  vest_ty: 'metal',
  vest_name: 'loc_ui_string_common_protection_metal_tier_4',
  vest_rate: 4,
  vest_armor: 19,
  vest_shield: 70,
  vest_cost: 0,
}
export const gear_vest_bulletproof_metal_t3 = {
  key: 'vest_bulletproof_metal_t3',

  vest_ty: 'metal',
  vest_name: 'loc_ui_string_common_protection_metal_tier_3',
  vest_rate: 3,
  vest_armor: 22,
  vest_shield: 70,
  vest_cost: 0,
}
export const gear_vest_bulletproof_metal_t2 = {
  key: 'vest_bulletproof_metal_t2',

  vest_ty: 'metal',
  vest_name: 'loc_ui_string_common_protection_metal_tier_2',
  vest_rate: 2,
  vest_armor: 25,
  vest_shield: 70,
  vest_cost: 0,
}
export const gear_vest_bulletproof_metal_t1 = {
  key: 'vest_bulletproof_metal_t5',

  vest_ty: 'metal',
  vest_name: 'loc_ui_string_common_protection_metal_tier_1',
  vest_rate: 1,
  vest_armor: 27,
  vest_shield: 70,
  vest_cost: 0,
}

// 세라믹 타입 방탄복
export const gear_vest_bulletproof_ceramic_t5 = {
  key: 'vest_bulletproof_ceramic_t5',

  vest_ty: 'ceramic',
  vest_name: 'loc_ui_string_common_protection_ceramic_tier_5',
  vest_rate: 4,
  vest_armor: 50,
  vest_shield: 45,
  vest_cost: 0,
}
export const gear_vest_bulletproof_ceramic_t4 = {
  key: 'vest_bulletproof_ceramic_t4',

  vest_ty: 'ceramic',
  vest_name: 'loc_ui_string_common_protection_ceramic_tier_4',
  vest_rate: 3,
  vest_armor: 75,
  vest_shield: 50,
  vest_cost: 0,
}
export const gear_vest_bulletproof_ceramic_t3 = {
  key: 'vest_bulletproof_ceramic_t3',

  vest_ty: 'ceramic',
  vest_name: 'loc_ui_string_common_protection_ceramic_tier_3',
  vest_rate: 2,
  vest_armor: 100,
  vest_shield: 50,
  vest_cost: 0,
}
export const gear_vest_bulletproof_ceramic_t2 = {
  key: 'vest_bulletproof_ceramic_rate2',

  vest_ty: 'ceramic',
  vest_name: 'loc_ui_string_common_protection_ceramic_tier_2',
  vest_rate: 1,
  vest_armor: 125,
  vest_shield: 50,
  vest_cost: 0,
}
export const gear_vest_bulletproof_ceramic_t1 = {
  key: 'vest_bulletproof_ceramic_rate1',

  vest_ty: 'ceramic',
  vest_name: 'loc_ui_string_common_protection_ceramic_tier_1',
  vest_rate: 1,
  vest_armor: 150,
  vest_shield: 50,
  vest_cost: 0,
}


export const gears_vest_bulletproof = [
  gear_vest_bulletproof_nothing,

  gear_vest_bulletproof_aramid_t5,
  gear_vest_bulletproof_aramid_t4,
  gear_vest_bulletproof_aramid_t3,
  gear_vest_bulletproof_aramid_t2,
  gear_vest_bulletproof_aramid_t1,

  // gear_vest_bulletproof_metal_t5,
  // gear_vest_bulletproof_metal_t4,
  // gear_vest_bulletproof_metal_t3,
  // gear_vest_bulletproof_metal_t2,
  // gear_vest_bulletproof_metal_t1,

  // gear_vest_bulletproof_ceramic_t5,
  // gear_vest_bulletproof_ceramic_t4,
  // gear_vest_bulletproof_ceramic_t3,
  // gear_vest_bulletproof_ceramic_t2,
  // gear_vest_bulletproof_ceramic_t1,
]

for (const vest of gears_vest_bulletproof) {
  const item = items.find(i => i.key === vest.key);
  if (item) {
    vest.buy_cost = item.buy_cost;
    vest.sell_cost = item.sell_cost;
    vest.durability = item.durability;
  }
}

export const gear_nvd_t0 = {
  nvd_rate: 0,
  nvd_cost: 0,
  nvd_name: '없음',
}

// PVS-7C, FOV 45
export const gear_nvd_t1 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.5,
      aimvar_hold_max: 1.6,
      vis_var: 0.33,
      speed: 0.6,
    },
  },
  nvd_rate: 1,
  nvd_cost: 1000,
  nvd_name: 'PVS-7C',
}

// RPNVG, FOV 70
export const gear_nvd_t2 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.6,
      aimvar_hold_max: 1.5,
      vis_var: 0.58,
      speed: 0.6,
    },
  },
  nvd_rate: 2,
  nvd_cost: 2000,
  nvd_name: 'RPNVG'
}

// WFOV F-NVG, FOV 80
export const gear_nvd_t3 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.7,
      aimvar_hold_max: 1.35,
      vis_var: 0.66,
      speed: 0.6,
    },
  },
  nvd_rate: 3,
  nvd_cost: 3000,
  nvd_name: 'F-NVG'
}

// GPNVG-18, FOV 96
export const gear_nvd_t4 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.85,
      aimvar_hold_max: 1.1,
      vis_var: 0.8,
      speed: 0.6,
    },
  },
  nvd_rate: 4,
  nvd_cost: 4000,
  nvd_name: 'GPNVG-18'
}

export const gears_nvd = [
  gear_nvd_t0,
  gear_nvd_t1,
  gear_nvd_t2,
  gear_nvd_t3,
  gear_nvd_t4
]
