import source from '../downloaded/char2StatTier.json' assert { type: 'json' };

export const statTiers = [];

for (const row of source) {
  let { statTier, minValue, maxValue, pay_weekly, perks_initial_count } = row;
  statTiers.push({
    statTier: 0 | statTier,
    minValue: +minValue,
    maxValue: +maxValue,
    pay_weekly: +pay_weekly,
    perks_initial_count: +perks_initial_count,
  });
}
