import source from '../downloaded/scheduleAgent.json' assert { type: 'json' };

export const scheduleAgents = [];
for (const row of source) {
  let { id, type, name, desc, visible, condition_diff, mood_diff, effect, exp } = row;

  scheduleAgents.push({
    id,
    type,
    name,
    desc,
    visible: visible === 'TRUE',
    condition_diff: parseInt(condition_diff),
    mood_diff: parseInt(mood_diff),
    effect,
    exp: parseInt(exp),
  });
}
