import _ from 'lodash';
import React from 'react';
import { opts } from './opts';

export function StatViewSmall(props) {
  const { sim } = props;
  if (!sim) {
    return;
  }

  const { entities, trails } = sim;

  const header = [
    'Name',
    'Damage Dealt',
    'Damage Taken',
  ];

  function buildTabRow(entity) {
    const damage_done = _.sum(trails.filter((t) => t.source === entity && t.hit).map((t) => t.damage))
    const damage_taken = _.sum(trails.filter((t) => t.target === entity && t.hit).map((t) => t.damage))

    return [
      entity.name,
      damage_done.toFixed(0),
      damage_taken.toFixed(0),
    ];
  }

  const rows = [];
  const allies = entities.filter((e) => e.team === 0 && e.ty !== 'vip');
  for (const entity of allies) {
    rows.push(buildTabRow(entity));
  }

  return <table className="stat-small">
    <thead>
      <tr>{header.map((name, i) => <th key={i}>{name}</th>)}</tr>
    </thead>
    <tbody>
      {rows.map((row, i) => <tr key={i}>
        {row.map((cell, j) => <td key={j}>{cell}</td>)}
      </tr>)}
    </tbody>
  </table>;
}

export function StatView(props) {
  const { sim, selected } = props;
  if (!sim) {
    return;
  }

  const { entities, trails, journal } = sim;

  function ratio(val, total) {
    if (total === 0) {
      return (0).toFixed(2);
    } else {
      return (val / total * 100).toFixed(2);
    }
  }

  const hitsAll = trails.filter((t) => t.source.team !== 0);
  const firesAll = trails.filter((t) => t.source.team === 0);
  const killsAll = trails.filter((t) => t.source.team === 0 && t.kill);

  function buildTabHeader() {
    return [
      '이름',
      'life',
      '생존 시간(초)',
      '사격',
      '',
      '사격:유효',
      '',
      '사격:명중',
      '',
      '피격',
      '',
      '피격:유효',
      '',
      '피격:명중',
      '',
      '사살',
      '',
      `perk #1`,
      `발동`,
      `perk #2`,
      `발동`,
      `perk #3`,
      `발동`,
      `perk #4`,
      `발동`,
    ];
  }

  function buildTabRow(entity) {
    const fires = trails.filter((t) => t.source === entity);
    const effectiveFires = fires.filter((t) => t.valid);
    const validFires = fires.filter((t) => t.hit);

    const hits = trails.filter((t) => t.target === entity);
    const effectiveHits = hits.filter((t) => t.valid);
    const validHits = hits.filter((t) => t.hit);

    const kills = trails.filter((t) => t.source === entity && t.kill);
    const last = trails.filter((t) => t.target === entity && t.kill);

    return [
      entity.name,
      `${entity.life}/${entity.life_max}`,
      `${last.length > 0 ? (last[0].tick / opts.tps).toFixed(0) : ''}`,
      fires.length,
      `${ratio(fires.length, firesAll.length)}%`,
      effectiveFires.length,
      `${ratio(effectiveFires.length, fires.length)}%`,
      validFires.length,
      `${ratio(validFires.length, fires.length)}%`,
      hits.length,
      `${ratio(hits.length, hitsAll.length)}%`,
      effectiveHits.length,
      `${ratio(effectiveHits.length, hits.length)}%`,
      validHits.length,
      `${ratio(validHits.length, hits.length)}%`,
      kills.length,
      `${ratio(kills.length, killsAll.length)}%`,
    ];
  }

  const rows = [buildTabHeader()];
  const allies = entities.filter((e) => e.team === 0 && e.ty !== 'vip');
  for (const entity of allies) {
    const row = buildTabRow(entity);

    if (selected) {
      const ch = selected.find((ch) => ch.character.name === entity.name);
      for (let i = 0; i < ch.perks.length; i++) {
        const perk = ch.perks[i];
        if (perk) {
          row.push(perk.name);
          const count = journal.filter((t) => t.ty === 'perk' && t.entity === entity && t.perk === perk.key).length;
          row.push(count);
        } else {
          row.push('');
          row.push('');
        }
      }
    }

    rows.push(row);
  }

  function transpose(t) {
    const h = t.length;
    const w = t[0].length;

    const tab = [];
    for (let j = 0; j < w; j++) {
      const row = [];
      for (let i = 0; i < h; i++) {
        row.push(t[i][j]);
      }
      tab.push(row);
    }
    return tab;
  }

  const tab = transpose(rows);

  return <div>
    에이전트 통계
    <table>
      <tbody>
        {tab.map((row, i) => <tr key={i}>
          {row.map((cell, j) => <td key={j}>{cell}</td>)}
        </tr>)}
      </tbody>
    </table>
  </div>;
}
