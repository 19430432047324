/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { SwapDummy } from "../SwapDummy";
import "./style.css";

export const CipoCell = ({
  ptFirearmTy = "BFG",
  ptAgentHpCap = "/777",
  ptAgentHpVal = "333",
  ptAgentName = "NAME",
  ptAgentStaminaCap = "/777",
  ptAgentStaminaVal = "333",
  ptAgentStaminaRegen = "11.11",
  ptAgentStaminaIdx = "3",
  ptPerkEnable = "▲",
  ptTraining = "Training",
  className,
  override = (
    <SwapDummy
      className="swap-dummy-instance"
      dummyClassName="swap-dummy-2"
      overlapGroupClassName="design-component-instance-node"
    />
  ),
}) => {
  return (
    <div className={`CIPO-cell ${className}`}>
      <div className="mercenary">
        <div className="profile">
          <div className="overlap">
            {override}
            <div className="firearm-type">
              <div className="pt-firearm-ty">{ptFirearmTy}</div>
            </div>
            <div className="training">
              <div className="pt-training">{ptTraining}</div>
            </div>
            <div className="perk-enable">
              <div className="pt-perk-enable">{ptPerkEnable}</div>
            </div>
          </div>
        </div>
        <div className="pt-agent-name">{ptAgentName}</div>
        <div className="HP">
          <div className="pt-agent-hp-val">{ptAgentHpVal}</div>
          <div className="pt-agent-hp-cap">{ptAgentHpCap}</div>
        </div>
        <div className="stamina" title={`+${ptAgentStaminaRegen}/month`}>
          <div className="pt-agent-stamina-val"><span className={`pt-agent-stamina-val-${ptAgentStaminaIdx}`}>{ptAgentStaminaVal}</span> </div>
        </div>
      </div>
    </div>
  );
};

CipoCell.propTypes = {
  ptFirearmTy: PropTypes.string,
  ptAgentHpCap: PropTypes.string,
  ptAgentHpVal: PropTypes.string,
  ptAgentName: PropTypes.string,
  ptAgentStaminaVal: PropTypes.string,
  ptAgentStaminaCap: PropTypes.string,
  ptAgentStaminaRegen: PropTypes.string,
  ptAgentStaminaIdx: PropTypes.string,
  ptPerkEnable: PropTypes.string,
  ptTraining: PropTypes.string,
};
