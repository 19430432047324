import source from '../downloaded/agentModifiers.json' assert { type: 'json' };

export const agentModifiers = [];
for (const row of source) {
  let { key, visible, ty, category, name, duration, overwrite, alarm_level, change_schedule, block_meeting, desc, effects, effects_value } = row;

  agentModifiers.push({
    key,
    visible: visible === 'TRUE' ? true : false,
    ty,
    category,
    name,
    duration: duration === 'semiperma' ? duration : parseFloat(duration),
    overwrite: overwrite?.split(',').map((s) => s.trim()),
    alarm_level: alarm_level ? parseInt(alarm_level) : 0,
    change_schedule: change_schedule === 'TRUE',
    block_meeting: block_meeting === 'TRUE',
    desc,
    effects: effects ? effects.split(',').map((s) => s.trim()) : [],
    effects_value: effects_value ? effects_value.split(',').map((s) => s.trim()) : [],
  });
}
