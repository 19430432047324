import React from 'react';
import './FigmaGearExchangeView.css';
import { ICON_CLOSE, GetWeaponAptitudeIcon } from './FigmaListView';
import { GEAR_TIER1, GEAR_TIER2, GEAR_TIER3, GEAR_TIER4, GEAR_TIER5 } from './FigmaGearsView';
import { rolesBykey } from './data/google/processor/data_char2roles.mjs';
import { L } from './localization.mjs';
import { descKey } from './FigmaGearsView';
import { SoundButton, triggerSound } from './sound.mjs';
import { DEFAULT_FIREARM, DEFAULT_EQUIPMENT, DEFAULT_THROWABLE } from './character';

const ICON_ARROW = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M26 44.9449L51.5 30.2224L26 15.5V24.2224H1.5V36.2224H26V44.9449Z" fill="url(#paint0_linear_1288_15865)" />
  <defs>
    <linearGradient id="paint0_linear_1288_15865" x1="20.5" y1="32.5" x2="4.5" y2="32.5" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFE2AA" />
      <stop offset="1" stopColor="#FFE2AA" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>;

const ICON_BETTER = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M13 8L19.9282 20H6.0718L13 8Z" fill="#71C507" />
</svg>;

const ICON_WORSE = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M13 21L19.9282 9H6.0718L13 21Z" fill="#E92424" />
</svg>;

const ICON_STAY = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <rect x="8" y="13" width="10" height="3" fill="#967B44" />
</svg>;

const BACKGROUND = <svg xmlns="http://www.w3.org/2000/svg" width="380" height="249" fill="none" className='background'>
  <g filter="url(#a)"><ellipse cx="190" cy="124.5" fill="#6C4B24" rx="141" ry="54.5" /></g>
  <defs>
    <filter id="a" width="422" height="249" x="-21" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur result="effect1_foregroundBlur_1319_10348" stdDeviation="35" />
    </filter>
  </defs>
</svg>

const GEAR_TIERS = [
  GEAR_TIER1,
  GEAR_TIER2,
  GEAR_TIER3,
  GEAR_TIER4,
  GEAR_TIER5,
];

export class FigmaGearExchangeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIdx: 0
    }
  }

  EquipDetail(equip, equip_cur, equip_ty, agent) {
    const firearmAptitudeKey = {
      'hg': 'firearmAptitude_HG',
      'sg': 'firearmAptitude_SG',
      'smg': 'firearmAptitude_SMG',
      'ar': 'firearmAptitude_AR',
      'dmr': 'firearmAptitude_DMR',
    }

    function getFirearmAptitude(e) {
      if (e.shield && !['tank', 'vanguard'].includes(agent.role)) {
        return 6;
      }
      return rolesBykey(agent.role)['firearmAptitudes'][firearmAptitudeKey[e.firearm_ty]];
    }

    function compare(value, value_cur) {
      if (value > value_cur) {
        return { icon: ICON_BETTER, class: 'better' };
      } else if (value < value_cur) {
        return { icon: ICON_WORSE, class: 'worse' };
      } else {
        return { icon: ICON_STAY, class: '' };
      }
    }

    function makeOption(title, compare, value) {
      return <div className='figmagearexchange-body-detail-option'>
        <span className='figmagearexchange-body-detail-option-title'>
          {title}
        </span>
        <div className='figmagearexchange-body-detail-option-value'>
          {compare?.icon}
          <span className={`figmagearexchange-body-detail-option-value ` + compare?.class}>
            {value}
          </span>
        </div>
      </div>
    }

    const isCur = equip_cur == null;
    let main;

    const sep = <div className='figmagearexchange-body-detail-options-sep' />;
    const options = [sep];

    if (equip_ty == 'firearm') {
      if (equip == null) {
        main = <div className='figmagearexchange-body-detail-header-main-exception'>
          <span className='figmagearexchange-body-detail-header-main-exception-label'>
            {L('loc_ui_longtext_gearexchange_no_firearm_avail')}
          </span>
        </div>

        options.push(makeOption(L('loc_ui_string_role_tooltip_firearm_aptitudes'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_penetrate'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_firerate'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_reload_speed'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_ammo'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_squad_info_range_effective'), null, '-'));
        options.push(sep);

      } else {
        main = [
          <div className='figmagearexchange-body-detail-header-main-name'>
            {GEAR_TIERS[equip.firearm_rate - 1]}
            <div className='figmagearexchange-body-detail-header-main-name-ty'>
              <span className='figmagearexchange-body-detail-header-main-name-ty-label'>{equip.firearm_ty.toUpperCase()}</span>
            </div>
            <span className='figmagearexchange-body-detail-header-main-name-label'>{L(equip.firearm_name)}</span>
          </div>,
          <img src={'/gears/' + descKey(equip.img_key) + '.png'} alt='item' onDragStart={(e) => e.preventDefault()} />,
          BACKGROUND,
        ];
        /*
                  <div className='figmagearexchange-body-detail-header-main-name'>
                    {GEAR_TIERS[equip.firearm_rate - 1]}
                    <div className='figmagearexchange-body-detail-header-main-name-ty'>
                      <span className='figmagearexchange-body-detail-header-main-name-ty-label'>{equip.firearm_ty.toUpperCase()}</span>
                    </div>
                    <span className='figmagearexchange-body-detail-header-main-name-label'>{L(equip.firearm_name)}</span>
                  </div>,*/
        const firearmAptitude = getFirearmAptitude(equip);
        const power = equip.firearm_expectedPower / 5;
        const damage = equip.firearm_projectile_damage;
        const penetrate = equip.firearm_projectile_penetrate;
        let fire_interval = equip.firearm_shoot_pattern_interval_sec;
        if (equip.firearm_shoot_pattern?.length > 0) {
          fire_interval = equip.firearm_shoot_pattern[0];
        }

        const rpm = (60 / fire_interval).toFixed(1);
        const reloadSpeed = equip.firearm_reload_duration;
        const ammo = equip.firearm_ammo_max;

        const minRange = (equip.firearm_range * equip.firearm_range_optimal_min / 10).toFixed(1);
        const maxRange = (equip.firearm_range * equip.firearm_range_optimal_max / 10).toFixed(1);

        if (isCur || equip_cur.key == DEFAULT_FIREARM.key) {
          options.push(makeOption(L('loc_ui_string_role_tooltip_firearm_aptitudes'), null, <GetWeaponAptitudeIcon value={firearmAptitude} />));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), null, power.toFixed(1)));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), null, damage));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_penetrate'), null, penetrate));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_firerate'), null, rpm + ' rpm'));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_reload_speed'), null, reloadSpeed));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_ammo'), null, ammo));
          options.push(sep);
        } else {
          const firearmAptitudeCur = getFirearmAptitude(equip_cur);
          const powerCur = equip_cur.firearm_expectedPower / 5;
          const damageCur = equip_cur.firearm_projectile_damage;
          const penetrateCur = equip_cur.firearm_projectile_penetrate;
          let fire_intervalCur = equip_cur.firearm_shoot_pattern_interval_sec;
          if (equip_cur.firearm_shoot_pattern?.length > 0) {
            fire_intervalCur = equip_cur.firearm_shoot_pattern[0];
          }
          const rpmCur = 60 / fire_intervalCur;
          const reloadSpeedCur = equip_cur.firearm_reload_duration;
          const ammoCur = equip_cur.firearm_ammo_max;

          options.push(makeOption(L('loc_ui_string_role_tooltip_firearm_aptitudes'), compare(-firearmAptitude, -firearmAptitudeCur), <GetWeaponAptitudeIcon value={firearmAptitude} />));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), compare(power, powerCur), power.toFixed(1)));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), compare(damage, damageCur), damage));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_penetrate'), compare(penetrate, penetrateCur), penetrate));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_firerate'), compare(rpm, rpmCur), rpm + ' rpm'));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_reload_speed'), compare(reloadSpeed, reloadSpeedCur), reloadSpeed));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_ammo'), compare(ammo, ammoCur), ammo));
          options.push(sep);
        }
        options.push(makeOption(L('loc_ui_string_squad_info_range_effective'), null, minRange + 'm ~ ' + maxRange + 'm'));
        options.push(sep);
      }
    }

    if (equip_ty == 'throwable') {
      if (equip == null) {
        if (isCur) {
          main = <div className='figmagearexchange-body-detail-header-main-exception'>
            <span className='figmagearexchange-body-detail-header-main-exception-label'>
              {L('loc_ui_longtext_gearexchange_no_throwable_equipped')}
            </span>
          </div>;
        } else {
          main = <div className='figmagearexchange-body-detail-header-main-exception'>
            <span className='figmagearexchange-body-detail-header-main-exception-label'>
              {L('loc_ui_longtext_gearexchange_no_throwable_avail')}
            </span>
          </div>;
        }
        options.push(makeOption(L('loc_ui_string_common_compare_throwable_range'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_throwable_delay'), null, '-'));
        options.push(sep);
      } else {
        main = [
          <div className='figmagearexchange-body-detail-header-main-name'>
            {GEAR_TIERS[equip.throwable_rate - 1]}
            <span className='figmagearexchange-body-detail-header-main-name-label'>{L(equip.throwable_name)}</span>
          </div>,
          <img src={'/gears/' + descKey(equip.img_key) + '.png'} alt='item' onDragStart={(e) => e.preventDefault()} />,
          BACKGROUND
        ];

        const delay = equip.blast_delay;
        const range = equip.blasts.map((b) => b.blast_radius).reduce((a, b) => a > (b ?? 0) ? a : b, 1);
        const damage = equip.blasts.map((b) => b.blast_damage).reduce((a, b) => a > (b ?? 0) ? a : b, 1);

        if (isCur || equip_cur.key == 'throwable_none') {
          options.push(makeOption(L('loc_ui_string_common_compare_throwable_range'), null, range));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), null, damage));
          options.push(sep);

        } else {
          const rangeCur = equip_cur.blasts.map((b) => b.blast_radius).reduce((a, b) => a > (b ?? 0) ? a : b, 1);
          const damageCur = equip_cur.blasts.map((b) => b.blast_damage).reduce((a, b) => a > (b ?? 0) ? a : b, 1);
          options.push(makeOption(L('loc_ui_string_common_compare_throwable_range'), compare(range, rangeCur), range));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_firearm_damage'), compare(damage, damageCur), damage));
          options.push(sep);
        }
        options.push(makeOption(L('loc_ui_string_common_compare_throwable_delay'), null, delay));
        options.push(sep);
      }
    }

    if (equip_ty == 'equipment') {
      if (equip == null) {
        if (isCur) {
          main = <div className='figmagearexchange-body-detail-header-main-exception'>
            <span className='figmagearexchange-body-detail-header-main-exception-label'>
              {L('loc_ui_longtext_gearexchange_no_equipment_equipped')}
            </span>
          </div>;
        } else {
          main = <div className='figmagearexchange-body-detail-header-main-exception'>
            <span className='figmagearexchange-body-detail-header-main-exception-label'>
              {L('loc_ui_longtext_gearexchange_no_equipment_avail')}
            </span>
          </div>;
        }
        options.push(makeOption(L('loc_ui_string_common_compare_equipment_durability'), null, '-'));
        options.push(sep);
        options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), null, '-'));
        options.push(sep);
      } else {
        main = [
          <div className='figmagearexchange-body-detail-header-main-name'>
            {GEAR_TIERS[equip.vest_rate - 1]}
            <span className='figmagearexchange-body-detail-header-main-name-label'>{L(equip.vest_name)}</span>
          </div>,
          <img src={'/gears/' + descKey(equip.img_key) + '.png'} alt='item' onDragStart={(e) => e.preventDefault()} />,
          BACKGROUND
        ];
        const durability = equip.vest_shield;
        const power = equip.gear_expectedPower / 5;
        if (isCur || equip_cur.key == 'vest_bulletproof_nothing') {
          options.push(makeOption(L('loc_ui_string_common_compare_equipment_durability'), null, durability));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), null, power.toFixed(1)));
          options.push(sep);

        } else {
          const durabilityCur = equip_cur.vest_shield;
          const powerCur = equip_cur.gear_expectedPower / 5;
          options.push(makeOption(L('loc_ui_string_common_compare_equipment_durability'), compare(durability, durabilityCur), durability));
          options.push(sep);
          options.push(makeOption(L('loc_ui_string_common_compare_gear_power'), compare(power, powerCur), power.toFixed(1)));
          options.push(sep);
        }
      }
    }

    let label

    if (isCur) {
      label = <span className='figmagearexchange-body-detail-header-title-label cur'>{L('loc_ui_string_popup_agent_equipment_current')}</span>
    } else {
      label = <span className='figmagearexchange-body-detail-header-title-label'>{L('loc_ui_string_popup_agent_equipment_alternative_exists')}</span>
    }

    return <div className='figmagearexchange-body-detail'>
      <div className='figmagearexchange-body-detail-header'>
        <div className='figmagearexchange-body-detail-header-title'>
          {label}
        </div>
        <div className='figmagearexchange-body-detail-header-main'>
          {main}
        </div>
      </div>
      <div className='figmagearexchange-body-detail-options'>
        {options}
      </div>
    </div>
  }

  row(i, item, equip_ty, onClick, selected) {
    const { equip, owner } = item;
    let equip_rate;
    let firearm_ty = null;
    let equip_name;

    if (equip_ty == 'firearm') {
      equip_rate = GEAR_TIERS[equip.firearm_rate - 1];
      firearm_ty = <div className='figmagearexchange-body-list-body-row-firearmty'>
        <span className='figmagearexchange-body-list-body-row-firearmty-label'>
          {equip.firearm_ty.toUpperCase()}
        </span>
      </div>
      equip_name = L(equip.firearm_name);
    }
    if (equip_ty == 'throwable') {
      equip_rate = GEAR_TIERS[equip.throwable_rate - 1];
      equip_name = L(equip.throwable_name);
    }
    if (equip_ty == 'equipment') {
      equip_rate = GEAR_TIERS[equip.vest_rate - 1];
      equip_name = L(equip.vest_name);
    }

    return <SoundButton key={i} className={'figmagearexchange-body-list-body-row' + (selected ? " selected" : '')} onClick={onClick}>
      {equip_rate}
      {firearm_ty}
      <span className='figmagearexchange-body-list-body-row-name'>
        {equip_name}
        {owner ? L('loc_dynamic_string_agent_equipped_item', { name: owner.name }) : null}
      </span>
    </SoundButton>
  }

  onSelect(idx) {
    this.setState({ selectedIdx: idx })
  }

  render() {
    const { agent, equip_ty, equip_cur, equip_list } = this.props;
    const { onClickCancel, onClickExchange, onClickClear, onClickUnable } = this.props;
    const { selectedIdx } = this.state;

    const typelabel = L('loc_ui_string_common_' + equip_ty + '_full');

    const isHandEmpty = [DEFAULT_FIREARM.key, DEFAULT_EQUIPMENT.key, DEFAULT_THROWABLE.key].includes(equip_cur.key);
    const isInventoryEmpty = equip_list.length == 0;

    const buttons = [];
    buttons.push(
      <SoundButton key="cancel" className='figmagearexchange-footer-cancel-btn' onClick={onClickCancel}>
        <span className='figmagearexchange-footer-cancel-btn-label'>{L('loc_ui_string_gearexchange_cancel_equip')}</span>
      </SoundButton>);
    if (isInventoryEmpty) {
      buttons.push(
        <div key="unable" className='figmagearexchange-footer-exchange-btn-unable' onClick={() => {
          onClickUnable(L('loc_ui_longtext_gearexchange_no_' + equip_ty + '_avail'));
          triggerSound('UI_Outgame_Button_Click_Default');
        }} >
          <span className='figmagearexchange-footer-exchange-btn-label-unable'>{L('loc_ui_string_gearexchange_equipment_change')}</span>
        </div>);
    } else {
      buttons.push(
        <SoundButton key="exchange" className='figmagearexchange-footer-exchange-btn' cueList={['UI_Outgame_Equipment_Wear']} onClick={() => { onClickExchange(selectedIdx) }} >
          <span className='figmagearexchange-footer-exchange-btn-label'>{L('loc_ui_string_gearexchange_equipment_change')}</span>
        </SoundButton>);
    }
    if (isHandEmpty) {
      buttons.push(
        <div key="equip-unable" className='figmagearexchange-footer-clear-btn-unable' onClick={() => {
          onClickUnable(L('loc_ui_longtext_gearexchange_no_' + equip_ty + '_equipped'));
          triggerSound('UI_Outgame_Button_Click_Default');
        }}>
          <span className='figmagearexchange-footer-clear-btn-label-unable'>{L('loc_ui_string_gearexchange_unequip')}</span>
        </div >);
    } else {
      buttons.push(
        <SoundButton keye="equip-clear" className='figmagearexchange-footer-clear-btn' cueList={['UI_Outgame_Equipment_Unwear']} onClick={onClickClear}>
          <span className='figmagearexchange-footer-clear-btn-label'>{L('loc_ui_string_gearexchange_unequip')}</span>
        </SoundButton >);

    }

    return <div className='figmagearexchange-root'>
      <div className='figmagearexchange-header'>
        <span className='figmagearexchange-header-title'>{L('loc_dynamic_string_gearexchange_title', { name: agent.name, ty: typelabel })}</span>
        <SoundButton className="figmagearexchange-header-btn-close" onClick={onClickCancel} >
          {ICON_CLOSE}
        </SoundButton>

      </div>
      <div className='figmagearexchange-body'>
        <div className='figmagearexchange-body-cur'>
          {this.EquipDetail(isHandEmpty ? null : equip_cur, null, equip_ty, agent)}
        </div>
        {ICON_ARROW}
        <div className='figmagearexchange-body-list'>
          <div className='figmagearexchange-body-list-body'>
            <div className='figmagearexchange-body-list-body-title'>
              <span className='figmagearexchange-body-list-body-title-label'>
                {L('loc_dynamic_string_gearexchange_items_avail', { ty: typelabel })}
              </span>
            </div>
            <div className='figmagearexchange-body-list-body-rows'>
              {equip_list.map((item, i) => this.row(i, item, equip_ty, () => { this.onSelect(i) }, i == selectedIdx))}
            </div>
          </div>
          <div className='figmagearexchange-body-list-detail'>
            {this.EquipDetail(isInventoryEmpty ? null : equip_list[selectedIdx].equip, equip_cur, equip_ty, agent)}
          </div>
        </div>
      </div>
      <div className='figmagearexchange-footer'>
        {buttons}
      </div>
    </div >
  }
}
