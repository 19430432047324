import { entityFromStat3, stats3_const } from './stats3.mjs';
import {
  tmpl_firearm_hg_t2,
  tmpl_firearm_hg_t3,
  tmpl_firearm_hg_t4,
  tmpl_firearm_hg_t5,
  tmpl_firearm_hg_enemy_t2,
  tmpl_firearm_hg_enemy_t3,
  tmpl_firearm_hg_enemy_t4,
  tmpl_firearm_hg_enemy_t5,

  tmpl_firearm_ar_t1,
  tmpl_firearm_ar_t2,
  tmpl_firearm_ar_t3,
  tmpl_firearm_ar_t4,
  tmpl_firearm_ar_t5,
  tmpl_firearm_ar_enemy_t1,
  tmpl_firearm_ar_enemy_t2,
  tmpl_firearm_ar_enemy_t3,
  tmpl_firearm_ar_enemy_t4,
  tmpl_firearm_ar_enemy_t5,

  tmpl_firearm_smg_t1,
  tmpl_firearm_smg_t2,
  tmpl_firearm_smg_t3,
  tmpl_firearm_smg_t4,
  tmpl_firearm_smg_t5,
  tmpl_firearm_smg_enemy_t1,
  tmpl_firearm_smg_enemy_t2,
  tmpl_firearm_smg_enemy_t3,
  tmpl_firearm_smg_enemy_t4,
  tmpl_firearm_smg_enemy_t5,

  tmpl_firearm_dmr_t1,
  tmpl_firearm_dmr_t2,
  tmpl_firearm_dmr_t3,
  tmpl_firearm_dmr_t4,
  tmpl_firearm_dmr_t5,
  tmpl_firearm_dmr_enemy_t1,
  tmpl_firearm_dmr_enemy_t2,
  tmpl_firearm_dmr_enemy_t3,
  tmpl_firearm_dmr_enemy_t4,
  tmpl_firearm_dmr_enemy_t5,

  tmpl_firearm_sg_t1,
  tmpl_firearm_sg_t2,
  tmpl_firearm_sg_t3,
  tmpl_firearm_sg_t4,
  tmpl_firearm_sg_t5,
  tmpl_firearm_sg_enemy_t1,
  tmpl_firearm_sg_enemy_t2,
  tmpl_firearm_sg_enemy_t3,
  tmpl_firearm_sg_enemy_t4,
  tmpl_firearm_sg_enemy_t5,

  tmpl_firearm_hg_t3_with_shield,
  tmpl_firearm_hg_t4_with_shield,
  tmpl_firearm_hg_t5_with_shield,
  tmpl_firearm_hg_with_shield_enemy_t3,
  tmpl_firearm_hg_with_shield_enemy_t4,
  tmpl_firearm_hg_with_shield_enemy_t5,
  firearm_keys,
} from './presets_firearm.mjs';
import {
  gear_vest_bulletproof_aramid_t5,
  gear_vest_bulletproof_aramid_t4,
  gear_vest_bulletproof_aramid_t3,
  gear_vest_bulletproof_aramid_t2,
  gear_vest_bulletproof_aramid_t1,
} from './presets_gear.mjs';

const team1_base = {
  default_rule: { ty: 'idle', alert: false },
  team: 1,
  allow_door_wait: false,
};

const grunt_stats = {
  physical: 7, // + 8*(t/100) 15
  mental: 0,
  shooting: 2, // + 18*(t/100) 20
  perception: 3, // + 22*(t/100) 25
  tactical: 0,
};
const grunt_stats_max = {
  physical: 15,
  mental: 0,
  shooting: 20,
  perception: 25,
  tactical: 0,
};

const rifleman_stats = {
  physical: 7, // + 8*(t/100) 15
  mental: 0,
  shooting: 5, // + 45*(t/100) 50
  perception: 3, // + 27*(t/100) 30
  tactical: 0,
};
const rifleman_stats_max = {
  physical: 15,
  mental: 0,
  shooting: 50,
  perception: 30,
  tactical: 0,
};

const assault_stats = {
  physical: 7, // + 10*(t/100) 17
  mental: 0,
  shooting: 5, // + 15*(t/100) 20
  perception: 5, // + 55*(t/100) 60
  tactical: 0,
};
const assault_stats_max = {
  physical: 17,
  mental: 0,
  shooting: 20,
  perception: 60,
  tactical: 0,
};

const guard_stats = {
  physical: 9, // + 21*(t/100) 30
  mental: 0,
  shooting: 10, // + 40*(t/100) 50
  perception: 3, // + 17*(t/100) 20
  tactical: 0,
};
const guard_stats_max = {
  physical: 30,
  mental: 0,
  shooting: 50,
  perception: 20,
  tactical: 0,
};

const shield_stats = {
  physical: 11, // + 29*(t/100) 40
  mental: 0,
  shooting: 5, // + 15*(t/100) 20
  perception: 2, // + 13*(t/100) 15
  tactical: 0,
};
const shield_stats_max = {
  physical: 40,
  mental: 0,
  shooting: 20,
  perception: 15,
  tactical: 0,
};

const stat_preset = {
  grunt: {
    stats: grunt_stats,
    stats_max: grunt_stats_max,
  },
  rifleman: {
    stats: rifleman_stats,
    stats_max: rifleman_stats_max,
  },
  assault: {
    stats: assault_stats,
    stats_max: assault_stats_max,
  },
  guard: {
    stats: guard_stats,
    stats_max: guard_stats_max,
  },
  shield: {
    stats: shield_stats,
    stats_max: shield_stats_max,
  },
};

function getTierStats(base_stats, max_stats, tier) {
  const percentages = [0, 10, 22, 36, 53, 76, 100];
  const tier_stats = {
    physical: base_stats.physical + (max_stats.physical * (percentages[7 - tier] / 100)),
    mental: base_stats.mental,
    shooting: base_stats.shooting + (max_stats.shooting * (percentages[7 - tier] / 100)),
    perception: base_stats.perception + (max_stats.perception * (percentages[7 - tier] / 100)),
    tactical: base_stats.tactical,
    ingame_domain_key: 'enemy',
  };

  return tier_stats;
}

export function createEnemyPreset(role, tier, equipment = {}) {
  const { stats, stats_max } = stat_preset[role];
  let firearm = {};
  for (const equip of Object.values(equipment)) {
    firearm = {
      ...firearm,
      ...equip,
    }
  }
  return {
    ...entityFromStat3(getTierStats(stats, stats_max, tier), firearm),
    ...team1_base,
    role,
    tier,
    equipment,
  };
}
// grunt
export const grunt_t1 = {
  ...createEnemyPreset('grunt', 1, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t2 = {
  ...createEnemyPreset('grunt', 2, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t3 = {
  ...createEnemyPreset('grunt', 3, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t4 = {
  ...createEnemyPreset('grunt', 4, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t5 = {
  ...createEnemyPreset('grunt', 5, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t6 = {
  ...createEnemyPreset('grunt', 6, {
    tmpl_firearm_hg_enemy_t5,
  }),
};
export const grunt_t7 = {
  ...createEnemyPreset('grunt', 7, {
    tmpl_firearm_hg_enemy_t5,
  }),
};

// rifleman
export const rifleman_t1 = {
  ...createEnemyPreset('rifleman', 1, {
    tmpl_firearm_ar_enemy_t5,
    gear_vest_bulletproof_aramid_t5,
  }),
};
export const rifleman_t2 = {
  ...createEnemyPreset('rifleman', 2, {
    tmpl_firearm_ar_enemy_t5,
    gear_vest_bulletproof_aramid_t5,
  }),
};
export const rifleman_t3 = {
  ...createEnemyPreset('rifleman', 3, {
    tmpl_firearm_ar_enemy_t5,
    gear_vest_bulletproof_aramid_t5,
  }),
};
export const rifleman_t4 = {
  ...createEnemyPreset('rifleman', 4, {
    tmpl_firearm_ar_enemy_t5,
  }),
};
export const rifleman_t5 = {
  ...createEnemyPreset('rifleman', 5, {
    tmpl_firearm_ar_enemy_t5,
  }),
};
export const rifleman_t6 = {
  ...createEnemyPreset('rifleman', 6, {
    tmpl_firearm_ar_enemy_t5,
  }),
};
export const rifleman_t7 = {
  ...createEnemyPreset('rifleman', 7, {
    tmpl_firearm_ar_enemy_t5,
  }),
};

// assault
export const assault_t1 = {
  ...createEnemyPreset('assault', 1, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t2 = {
  ...createEnemyPreset('assault', 2, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t3 = {
  ...createEnemyPreset('assault', 3, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t4 = {
  ...createEnemyPreset('assault', 4, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t5 = {
  ...createEnemyPreset('assault', 5, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t6 = {
  ...createEnemyPreset('assault', 6, {
    tmpl_firearm_smg_enemy_t5,
  }),
};
export const assault_t7 = {
  ...createEnemyPreset('assault', 7, {
    tmpl_firearm_smg_enemy_t5,
  }),
};

// guard
export const guard_t1 = {
  ...createEnemyPreset('guard', 1, {
    tmpl_firearm_sg_enemy_t5,
    gear_vest_bulletproof_aramid_t2,
  }),
};
export const guard_t2 = {
  ...createEnemyPreset('guard', 2, {
    tmpl_firearm_sg_enemy_t5,
    gear_vest_bulletproof_aramid_t3,
  }),
};
export const guard_t3 = {
  ...createEnemyPreset('guard', 3, {
    tmpl_firearm_sg_enemy_t5,
    gear_vest_bulletproof_aramid_t4,
  }),
};
export const guard_t4 = {
  ...createEnemyPreset('guard', 4, {
    tmpl_firearm_sg_enemy_t5,
    gear_vest_bulletproof_aramid_t4,
  }),
};
export const guard_t5 = {
  ...createEnemyPreset('guard', 5, {
    tmpl_firearm_sg_enemy_t5,
    gear_vest_bulletproof_aramid_t5,
  }),
};
export const guard_t6 = {
  ...createEnemyPreset('guard', 6, {
    tmpl_firearm_sg_enemy_t5,
  }),
};
export const guard_t7 = {
  ...createEnemyPreset('guard', 7, {
    tmpl_firearm_sg_enemy_t5,
  }),
};

// shield
export const shield_t1 = {
  ...createEnemyPreset('shield', 1, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t2 = {
  ...createEnemyPreset('shield', 2, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t3 = {
  ...createEnemyPreset('shield', 3, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t4 = {
  ...createEnemyPreset('shield', 4, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t5 = {
  ...createEnemyPreset('shield', 5, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t6 = {
  ...createEnemyPreset('shield', 6, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};
export const shield_t7 = {
  ...createEnemyPreset('shield', 7, {
    tmpl_firearm_hg_with_shield_enemy_t5,
  }),
  perk_targetpref_high: true,
};

export const enemy_category = {
  Grunt: {
    Tier1: { grunt_t1 },
    Tier2: { grunt_t2 },
    Tier3: { grunt_t3 },
    Tier4: { grunt_t4 },
    Tier5: { grunt_t5 },
    Tier6: { grunt_t6 },
    Tier7: { grunt_t7 },
  },
  Rifleman: {
    Tier1: { rifleman_t1 },
    Tier2: { rifleman_t2 },
    Tier3: { rifleman_t3 },
    Tier4: { rifleman_t4 },
    Tier5: { rifleman_t5 },
    Tier6: { rifleman_t6 },
    Tier7: { rifleman_t7 },
  },
  Assault: {
    Tier1: { assault_t1 },
    Tier2: { assault_t2 },
    Tier3: { assault_t3 },
    Tier4: { assault_t4 },
    Tier5: { assault_t5 },
    Tier6: { assault_t6 },
    Tier7: { assault_t7 },
  },
  Guard: {
    Tier1: { guard_t1 },
    Tier2: { guard_t2 },
    Tier3: { guard_t3 },
    Tier4: { guard_t4 },
    Tier5: { guard_t5 },
    Tier6: { guard_t6 },
    Tier7: { guard_t7 },
  },
  Shield: {
    Tier1: { shield_t1 },
    Tier2: { shield_t2 },
    Tier3: { shield_t3 },
    Tier4: { shield_t4 },
    Tier5: { shield_t5 },
    Tier6: { shield_t6 },
    Tier7: { shield_t7 },
  }
};

export function getAllEnemies() {
  const ret = {};
  for (const [category, tiers] of Object.entries(enemy_category)) {
    for (const [tier, enemies] of Object.entries(tiers)) {
      for (const [name, enemy] of Object.entries(enemies)) {
        ret[name] = enemy;
      }
    }
  }
  return ret;
}