import localforage from 'localforage';

export class SaveImpl {
  constructor(props) {
    this.prefix = props?.prefix ?? `proto_grind_save`;
    this.processor = props?.processor;
  }

  async loadDescrs() {
    const { prefix } = this;
    const savedescrs = JSON.parse(await localforage.getItem(`${prefix}_descr`)) ?? [];
    return savedescrs;
  }

  async loadSlot(idx) {
    const { prefix } = this;
    let data = await localforage.getItem(`${prefix}_${idx}_data`);
    if (this.processor) {
      data = this.processor.onLoad(data, idx);
    }

    return data;
  }

  async saveSlot(idx, data, descr) {
    if (this.processor) {
      ({ data, descr } = this.processor.onSave({ data, descr }, idx));
    }

    const { prefix } = this;
    const savedescrs = await this.loadDescrs();
    savedescrs[idx] = descr;

    await localforage.setItem(`${prefix}_descr`, JSON.stringify(savedescrs));
    await localforage.setItem(`${prefix}_${idx}_data`, data);
    return savedescrs;
  }

  async discardSlot(idx) {
    if (this.processor?.onDiscard) {
      this.processor.onDiscard(idx);
    }
    const { prefix } = this;
    const savedescrs = await this.loadDescrs();
    delete savedescrs[idx];
    while (savedescrs.length > 0 && !savedescrs[savedescrs.length - 1]) {
      savedescrs.pop();
    }

    await localforage.setItem(`${prefix}_descr`, JSON.stringify(savedescrs));
    return savedescrs;
  }
}
