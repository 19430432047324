import source from '../downloaded/agentCareOptions.json' assert { type: 'json' };

export const agentCareOptions = [];
for (const row of source) {
  let { id, cost, additional_survival_prob, survive_result } = row;

  agentCareOptions.push({
    id,
    cost: parseInt(cost),
    additional_survival_prob: parseFloat(additional_survival_prob),
    survive_result,
  });
}
