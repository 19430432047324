export const soundDebugEvents = {
    'Cheer': 'event_debug_cheer',
    'Down': 'event_debug_down',
    'Fight': 'event_debug_fight',
    'Meet': 'event_debug_meet',
    'Punish': 'event_debug_punish',
    'Rain': 'event_debug_rain',
    'Rule': 'event_debug_rule',
    'Sick': 'event_debug_sick',
    'Sport': 'event_debug_sport',
    'Talk': 'event_debug_talk',
    'Unrule': 'event_debug_unrule',
    'Control': 'event_debug_control'
}

export const outgameEventCues = {
    'simulation': 'UI_Outgame_Event_Control',
    'train_cheer': 'UI_Outgame_Event_Cheer',
    'train_down': 'UI_Outgame_Event_Down',
    'train_sick': 'UI_Outgame_Event_Sick',
    'train_unrule': 'UI_Outgame_Event_Unrule',
    'talk_punish': 'UI_Outgame_Event_Punish',
    'train_fight': 'UI_Outgame_Event_Fight',
    'train_rule': 'UI_Outgame_Event_Rule',
    'rest_unrule': 'UI_Outgame_Event_Unrule',
    'rest_sick': 'UI_Outgame_Event_Sick',
    'rest_cheer': 'UI_Outgame_Event_Cheer',
    'rest_down': 'UI_Outgame_Event_Down',
    'talk_nice': 'UI_Outgame_Event_Talk',
    'talk_sad': 'UI_Outgame_Event_Talk',
    'talk_argue': 'UI_Outgame_Event_Talk',
    'train_rain': 'UI_Outgame_Event_Rain',
    'meet_dealer': 'UI_Outgame_Event_Meet',
    'talk_team': 'UI_Outgame_Event_Talk',
    'meet_scout': 'UI_Outgame_Event_Meet',
    'rest_rule': 'UI_Outgame_Event_Rule',
    'control': 'UI_Outgame_Event_Control',
    'rest_sport': 'UI_Outgame_Event_Sport',
    'tutorial': null
}

export const NoSoundEventIDs = [
    'event_ceo_recruit_first',
    'event_ceo_recruit',
    'event_ceo_deal'
]