import source from '../downloaded/outloopModifiers.json' assert { type: 'json' };

export const modifiers = [];

for (const row of source) {
  const { result, stat, stat_value, modifier, modifier_weight } = row;

  const stat_values = stat_value?.split(',').map((s) => parseInt(s));
  const modifier_weights = modifier_weight?.split(',').map((s) => parseInt(s));

  modifiers.push({
    result,
    stats: stat ? stat.split(',').map((s, i) => ({ stat: s.trim(), value: stat_values[i] })) : [],
    modifiers: modifier ? modifier.split(',').map((s, i) => ({ modifier_key: s.trim(), weight: modifier_weights[i] })) : [],
  });
}
