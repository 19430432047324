import source from '../downloaded/questPreset.json' assert { type: 'json' };
import { split } from './parseUtilities.mjs';

export const questPresets = [];
for (const row of source) {
  let { id,
    category,
    desc,
    quest_report,
    title,
    which_mission,
    which_week,
    generate_condition,
    generate_condition_value,
    questgiver_condition,
    questgiver_condition_value,
    weight,
    difficulty,
    target_ty,
    complete_condition,
    complete_condition_value,
    due_day,
    start_event,
    success_event,
    fail_event,
    end_event,
    troll_event } = row;

  questPresets.push({
    id,
    category,
    desc,
    quest_report,
    title,
    which_mission: split(which_mission),
    which_week: split(which_week),
    generate_condition,
    generate_condition_value,
    questgiver_condition,
    questgiver_condition_value,
    weight: parseFloat(weight),
    difficulty,
    target_ty,
    complete_condition,
    complete_condition_value,
    due_day: parseInt(due_day),
    start_event,
    success_event,
    fail_event,
    end_event,
    troll_event,
  });
}