import source from '../downloaded/ordersDescr.json' assert { type: 'json' };

export const ordersDescr = [];
for (const row of source) {
  const { key, title, descr } = row;
  ordersDescr.push({
    key,
    title,
    descr,
  });
}
