/// converts external obstacle format (from UE) to internal representation
import _ from 'lodash';

import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';
import { Rng } from './rand.mjs';

const min = [100, 100];
const max = [-100, -100];

const scale = 0.1;

function conv_rot(r) {
  return -r * Math.PI / 180;
}

export function convert_world(extobjs, simstate) {
  const obstacle_specs = convert(extobjs);

  function roundup(x, unit) {
    return Math.ceil(x / unit) * unit;
  }
  simstate.obstacle_specs = [];

  const world = obstacle_specs.find((s) => s.tags.includes('world'));
  let offset = new v2(0, 0);
  if (world) {
    simstate.world.width = roundup(world.extent.x * 2, opts.GRID_SIZE);
    simstate.world.height = roundup(world.extent.y * 2, opts.GRID_SIZE);
    simstate.world.offset = world.pos;
    offset = world.pos;
  }


  // setup
  for (const spec of obstacle_specs) {
    let { pos, extent } = spec;
    if (spec.tags.includes('world')) {
      continue;
    }
    pos = spec.pos.sub(offset);
    spec.pos = pos;

    const spawntag = spec.tags.find((t) => t.startsWith('spawn'));
    if (spawntag) {
      const idx = parseInt(spawntag.split('spawn')[1], 10);
      if (isNaN(idx) || idx >= simstate.spawnareas.length) {
        console.log('invalid spawn tag', spawntag);
        continue;
      }

      simstate.spawnareas[idx].pos = pos;
      simstate.spawnareas[idx].extent = extent;
      continue;
    }

    let s = null;
    if (spec.tags.includes('full')) {
      s = { ...spec, ty: 'full' };
    }
    if (spec.tags.includes('half')) {
      s = { ...spec, ty: 'half' };
    }
    if (s && s.tags.includes('nocover')) {
      s.no_coverpoint = true;
    }

    if (s) {
      simstate.obstacle_specs.push(s);
    }
  }

  return simstate;
}

export function convert(objects) {
  const obstacle_specs = [];

  for (const obj of objects) {
    for (let i = 0; i < 3; i++) {
      obj.min[i] *= obj.scale[i];
      obj.max[i] *= obj.scale[i];
    }

    let [minx, miny, minz] = obj.min;
    let [maxx, maxy, maxz] = obj.max;
    let [x, y] = obj.center;

    /*
    let ignore = false;
    for (let i = 0; i < 3; i++) {
      if (obj.max[i] - obj.min[i] < size[i]) {
        ignore = true;
        break;
      }
    }
    if (ignore) {
      continue;
    }
    if (maxx - minx < 50 && maxy - miny < 50) {
      continue;
    }
    */
    if (obj.name.toLowerCase().includes('tree')) {
      continue;
    }

    let heading = conv_rot(obj.rotation[2]);

    let x0 = (minx + maxx) / 2;
    let y0 = (miny + maxy) / 2;

    let centerx = Math.cos(heading) * x0 + Math.sin(heading) * y0;
    let centery = -1 * Math.sin(heading) * x0 + Math.cos(heading) * y0;

    centerx = x + centerx;
    centery = y + centery;
    let extentx = (maxx - minx) / 2;
    let extenty = (maxy - miny) / 2;

    /*
    if (extentx > 1000 || extenty > 1000) {
      continue;
    }
    */

    let ty = 'half';
    if ((maxz - minz) > 150) {
      ty = 'full';
    }

    min[0] = Math.min(x + minx);
    min[1] = Math.min(y + miny);

    max[0] = Math.max(x + maxx);
    max[1] = Math.max(y + maxy);
    obstacle_specs.push({
      ty,
      pos: new v2(centerx, centery).mul(scale),
      extent: new v2(extentx, extenty).mul(scale),
      heading,
      name: obj.name,
      tags: obj.tags,
      imported: true,
    });
  }

  return obstacle_specs;
}

export const SAMPLE = [{ "name": "StaticMeshActor_1", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-1020, -100, 0], "scale": [3.75, 1, 2], "rotation": [-0, 0, 0], "tags": ["sim", "half"] }, { "name": "StaticMeshActor_2", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [680, -110, 0], "scale": [3.75, 1, 2], "rotation": [-0, 0, 0], "tags": ["sim", "half"] }, { "name": "StaticMeshActor_3", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-170, -90, 0], "scale": [12, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }, { "name": "StaticMeshActor_4", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [100, 0, 0], "scale": [32.25, 51.25, 1], "rotation": [-0, 0, 0], "tags": ["sim", "world"] }, { "name": "StaticMeshActor_5", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-370, -2070, 0], "scale": [4, 4, 1], "rotation": [-0, 0, 0], "tags": ["sim", "spawn0"] }, { "name": "StaticMeshActor_6", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [0, 520, 0], "scale": [4, 4, 1], "rotation": [-0, 0, 0], "tags": ["sim", "spawn1"] }, { "name": "StaticMeshActor_7", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-850, -1700, 0], "scale": [23.75, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }, { "name": "StaticMeshActor_8", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [910, -1280, 0], "scale": [23.75, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }];


// serializes states for UE
const PARTS_ALL = [
  'body_01',
  'body_06',
  'body_07',
  'body_08',
  'body_09',
  // 'acc_gasmask_3ds',
  // 'acc_ammobelt',
  'acc_dogtag',
  // 'acc_avoncbrn',
  'acc_bowmanheadset',
  'acc_gasmask',
  'acc_goggles',
  'acc_headset',
  'acc_lashheadset',
  // 'acc_pvs7',
  // 'acc_headmount',
  'acc_sunglasses',
  'acc_kneepads',
  'acc_elbowpads',
  'armor_3marmor',
  'armor_3marmor_02',
  'armor_6b518',
  'armor_6b518_02',
  'armor_6b518_03',
  // 'armor_bodyarmormk2',
  // 'armor_bodyarmormk2_02',
  // 'armor_defender2',
  // 'armor_defender2_02',
  'armor_pasgt',
  'armor_pasgt_02',
  // 'armor_tgfaust',
  // 'armor_tgfaust_02',
  'backpack_alicebackpack', // vest
  // 'backpack_alicebackpack_02',
  // 'backpack_alicebackpack_acc',
  // 'backpack_bergen',
  // 'backpack_patrolpack',
  // 'backpack_rd54',
  'gloves_gloves_01',
  'gloves_gloves_02',
  'gloves_gloves_03',
  'headgear_altyn',
  'headgear_balaclava_01',
  'headgear_balaclava_02',
  'headgear_balaclava_03',
  'headgear_bandana_01',
  'headgear_beret',
  'headgear_booniehat_01',
  'headgear_baseballcap_01',
  'headgear_cowboyhat_01',
  'headgear_cowboyhat_02',
  'headgear_fieldcap_01',
  'headgear_ghilliebalaclava',
  'headgear_ghilliebalaclava_01',
  'headgear_headband_01',
  'headgear_keffiyeh_01',
  'headgear_m1helmet', // vest
  // 'headgear_mk6helmet_01',
  // 'headgear_mk6helmet_02',
  // 'headgear_mk6helmet_03',
  // 'headgear_pasgt',
  // 'headgear_protecthelmet',
  // 'headgear_slouchhat',
  'headgear_ssh68',
  // 'lowerbody_bdupants_01',
  // 'lowerbody_bdupants_02',
  // 'lowerbody_trousers_01',
  // 'lowerbody_florapants',
  // 'lowerbody_ghilliepants',
  // 'lowerbody_ghilliepants_01',
  // 'lowerbody_gorkapants',
  'lowerbody_male_underwear_01',
  // 'lowerbody_pants',
  // 'lowerbody_trousers',
  // 'lowerbody_wz2010',
  // 'lowerbody_wz2010_01',
  'upperbody_bdujacketfolded',
  'upperbody_bdujacketlong',
  'upperbody_bdujacket_npc',
  'upperbody_bdujacketshort',
  'upperbody_cs95_jacket',
  // 'upperbody_flora',
  // 'upperbody_ghilliejacket',
  // 'upperbody_ghilliejacket_01',
  // 'upperbody_gorka',
  'upperbody_jacket',
  'upperbody_male_sleeves',
  'upperbody_tshirt',
  'upperbody_tshirt_npc',
  'vests_6sh92', // vest
  'vests_alicevest', // vest
  // 'vests_assaultvest',
  // 'vests_bhi',
  'vests_gvest_01',
  // 'vests_gvest_02',
  // 'vests_kenguru3',
  // 'vests_lbv_vest',
  // 'vests_plce',
  'vests_strap',
  // 'body_malehostageoffice_04',
];

const PARTS_CONSMATICS0 = [
  'headgear_beret',
  'headgear_booniehat_01',
  'headgear_baseballcap_01',
  'headgear_cowboyhat_01',
  'headgear_cowboyhat_02',
  'headgear_fieldcap_01',
  // 'headgear_slouchhat',
];

const vests = [
  // none
  [],
  // 1
  ['armor_6b518', 'vests_alicevest', 'headgear_m1helmet', 'backpack_alicebackpack'],
  // 2
  ['armor_6b518', 'vests_alicevest', 'headgear_m1helmet', 'backpack_alicebackpack'],
  // 3
  ['armor_6b518', 'vests_alicevest', 'headgear_m1helmet'],
  // 4
  ['vests_alicevest', 'headgear_m1helmet'],
  // 5
  ['vests_6sh92'],
];

const FIXED_OPERATORS_OUTFITS = [
  {
    key: 'Indiana Jones',
    parts: [
      'body_invis',
      'headgear_jones_2',
      'upperbody_jones_2',
      'lowerbody_jones',
      'body_jones_hands',
      'body_jones_feet',
      'acc_jones_bag',
      'acc_jones_head',
    ],
    equipments: [
      'eq_jones_whip',
    ],
  },
  {
    key: 'Solid Snake',
    parts: [
      'body_invis',
      'vest_solidsnake',
      'head_solidsnake',
      'upperbody_solidsnake',
      'lowerbody_solidsnake',
      'hand_solidsnake',
    ],
    equipments: [
      'eq_snake_band',
      'eq_snake_bag',
    ],
  },
  {
    key: 'Sarah Connor',
    parts: [
      'body_sara',
      'headgear_sara',
      'lowerbody_sara',
      'upperbody_sara',
    ],
    equipments: [

    ],
  },
  {
    key: 'CHE',
    parts: [
      'body_che',
      'lowerbody_che',
      'upperbody_che',
    ],
    equipments: [
      'hair_9',
    ],
  },
  {
    key: 'PUBG',
    parts: [
      'body_invis',
      'body_pub',
      'vest_pub',
      'backpack_pub',
    ],
    equipments: [
      'headgear_pub',
    ],
  },
  {
    key: 'Rambo',
    parts: [
      'body_rambo',
      'acc_rambo',
      'lowerbody_rambo',
    ],
    equipments: [
      'eq_hair_band',
      'hair_8',
    ],
  },
  {
    key: 'Leon',
    parts: [
      'body_leon',
      'lowerbody_leon',
      'upperbody01_leon',
      'upperbody02_leon',
    ],
    equipments: [
      'headgear01_leon',
      'headgear02_leon',
      'acc_leon',
    ],
  },
  {
    key: 'Mark',
    parts: [
      'body_yun',
      'upperbody_yun',
      'lowerbody_yun',
    ],
    equipments: [
      'headgear_yun',
      'acc_yun',
    ],
  },
  {
    key: 'Anton Chigurh',
    parts: [
      'body_anton',
      'acc_anton_boots',
      'lowerbody_anton',
      'upperbody_anton',
    ],
    equipments: [
      'hair_10_anton',
    ],
  },
  {
    key: 'Golgo 13',
    parts: [
      'body_invis',
      'head_golgo',
      'upperbody_golgo',
      'lowerbody_golgo',
      'hand_golgo',
    ],
    equipments: [
      'acc_golgo',
    ],
  }
];

const partsCache = {};
function getParts(entity) {
  let { name, team } = entity;
  const { role } = entity;

  let name_split = name.split('"');
  if (name_split.length === 3) {
    name = name_split[1];
  }

  if (partsCache[name]) {
    return partsCache[name];
  }

  let parts = [];
  let equipments = [];
  let outfit_scale = 1;

  // body
  if (entity.fixedOperatorKey && FIXED_OPERATORS_OUTFITS.find((o) => o.key === entity.fixedOperatorKey)) {
    const fixedoutfit = FIXED_OPERATORS_OUTFITS.find((o) => o.key === entity.fixedOperatorKey);
    parts = fixedoutfit.parts;
    equipments = fixedoutfit.equipments;
  } else if (team === 0) {
    parts = [
      'upperbody_pointman',
      'lowerbody_pointman',
      ...vests[entity.vest_rate ?? 0],
    ];
    if (role === 'medic') {
      equipments.push('eq_healpack');
      parts = [
        'upperbody_medic',
        'lowerbody_medic',
        'acc_medic_kneepads',
        'acc_medic_boots',
      ];
    }
    if (role === 'breacher') {
      equipments.push('eq_wirecutter');
      equipments.push('eq_breacher_rope_0');
      equipments.push('eq_breacher_rope_1');
      equipments.push('eq_breacher_smoke_0');
      equipments.push('eq_breacher_smoke_1');
      parts = [
        'armor_breacher',
        'upperbody_breacher',
        'lowerbody_breacher',
        'backpack_patrolpack',
      ]
    }
    if (role === 'vanguard') {
      outfit_scale = 1.05;
      parts = [
        'armor_breacher',
        'lowerbody_bdupants_02',
      ];
    }
    if (role === 'sharpshooter') {
      parts = [
        'upperbody_sharpshooter',
        'lowerbody_sharpshooter',
        'headgear_sharpshooter',
      ];
    }

    const rng = new Rng(name);
    if (role === 'pointmain') {
      const cosmatics = rng.choice(PARTS_CONSMATICS0);
      parts.push(cosmatics);
    }

    const body = rng.choice(PARTS_ALL.filter(p => p.startsWith('body_0')));
    parts = [body, ...parts];
    equipments.push('hair_' + rng.integer(1, 7)); // Add hair
  } else {
    parts = [
      'upperbody_pointman',
      'lowerbody_pointman',
      ...vests[entity.vest_rate ?? 0],
    ];
    outfit_scale = 0.9819;
    // 키값은 임시
    if (role === 'enemy01') {
      parts = [
        'body_09',
        'upperbody_breacher',
        'lowerbody_trousers',
        'armor_pasgt',
      ];
      equipments = [
        'headgear_hat',
      ];
    }
    if (role === 'rifleman') {
      parts = [
        'body_09',
        'upperbody_bdujacketshort',
        'lowerbody_wz2010_01',
        'vests_lbv_vest',
        'headgear_booniehat_01',
        'backpack_patrolpack',
      ];
      equipments = [
        'acc_leon',
      ];
    }
    if (role === 'enemy03') {
      parts = [
        'body_09',
        'upperbody_gorka',
        'lowerbody_trousers_01',
        'headgear_pasgt',
        'acc_headmount',
        'vests_alicevest',
      ];
      equipments = [
        'headgear_headset',
      ];
    }
    if (role === 'shield') {
      parts = [
        'body_09',
        'headgear_protecthelmet',
        'upperbody_bdujacketlong',
        'lowerbody_breacher',
        'headgear_bandana_01',
        'acc_medic_kneepads',
        'acc_elbowpads',
        'gloves_gloves_01',
        'armor_6b518',
      ];
      equipments = [
      ];
    }
    if (role === 'grunt') {
      parts = [
        'body_09',
        'lowerbody_pants',
        'vests_gvest_01',
        'upperbody_jacket',
        'headgear_balaclava_01',
      ];
      equipments = [
      ];
    }
    if (role === 'enemy06') {
      parts = [
        'body_09',
        'lowerbody_pants',
        'vests_gvest_01',
        'headgear_balaclava_01',
      ];
      equipments = [
        'headgear_avoncbrn',
      ];
    }
    if (role === 'enemy07') {
      parts = [
        'body_09',
        'vests_plce',
        'upperbody_jacket',
        'lowerbody_pants',
        'headgear_fieldcap_01',
      ];
      equipments = [
      ];
    }
    if (role === 'assault') {
      parts = [
        'body_09',
        'vests_assaultvest',
        'lowerbody_pointman',
        'upperbody_male_sleeves',
        'headgear_bandana_01',
      ];
      equipments = [
        'acc_leon',
      ];
    }
    if (role === 'enemy09') {
      parts = [
        'body_09',
        'vests_6sh92',
        'headgear_beret',
        'lowerbody_wz2010',
        'upperbody_gorka',
      ];
      equipments = [
      ];
    }
    if (role === 'guard') {
      parts = [
        'body_09',
        'headgear_protecthelmet',
        'armor_tgfaust',
        'upperbody_bdujacketfolded',
        'lowerbody_vanguard',
        'backpack_rd54',
      ];
      equipments = [
        'headgear_pvs7',
      ];
    }
  }

  const obj = {
    parts,
    equipments,
    outfit_scale,
  };
  partsCache[name] = obj;
  return obj;
}

function extSerializeEntityBase(entity) {
  let { name, firearm_ty, is_vest, is_shield } = entity;
  const { life_max, team, callsign } = entity;
  const { role, mood } = entity;

  let name_split = name.split('"');
  if (name_split.length === 3) {
    name = name_split[1];
  }

  return {
    name,
    callsign,
    role,
    mood,
    team,

    life_max,
    firearm_ty,

    is_vest,
    is_shield,

    throwables_max: entity.throwables_max ?? 0,
    attachables_max: entity.attachables_max ?? 0,
    heals_max: entity.heals_max ?? 0,
  };
}

function extSerializeEntity(sim, entity, tfp) {
  let { name, pos, movespeed, state, waypoint, aimtarget } = entity;
  const { entities } = sim;
  const { life, ammo, firearm_ammo_max, aimdir, shield, shield_max, targetdoor, aimtargetmeta } = entity;
  waypoint = waypoint?.path?.map(({ pos }) => tfp(pos)) ?? [];
  let aimtarget_idx = -1;

  let name_split = name.split('"');
  if (name_split.length === 3) {
    name = name_split[1];
  }

  let ads = true;
  if (movespeed > 0.7) {
    ads = false;
  }
  if (aimtarget) {
    aimtarget_idx = entities.indexOf(aimtarget);
    aimtarget = tfp(aimtarget.pos);
  }
  if (state === 'dash') {
    ads = false;
  }

  const { parts, equipments, outfit_scale } = getParts(entity);

  const kills = sim.trails.filter((t) => t.source === entity && t.kill).length;
  const damage = sim.trails.filter((t) => t.source === entity && t.damage).reduce((acc, t) => acc + t.damage, 0);

  const aimvar = entity.aimvar * sim.entityAimvarMult(entity);

  // stun
  const stunned = !!entity.effects.find((e) => e.effect_ty === 'stun_gr' && e.expire_at >= sim.tick);

  // dooropen
  let targetdoor0 = false;
  if (targetdoor) {
    targetdoor0 = {
      pos: tfp(targetdoor.pos),
      doorname: targetdoor.doorstate.name,
    };
  }

  // heal target
  const { waypoint_rule } = entity;
  let heal_target_idx = -1;
  if (waypoint_rule.heal_target) {
    heal_target_idx = sim.entities.indexOf(waypoint_rule.heal_target);
  }

  const effects = [];
  if (sim.entityEffect(entity, 'stun_gr')) {
    effects.push('stun_gr');
  }

  // effects랑 합쳐야 되는 데 effect가 종속되어 있는 위치를 모두 찾지 못해서 buffs로 정의해둡니다.
  const buff_keys = [
    'life_atk_damage_rate_increase', // 대인 피해 증가
    'life_atk_damage_rate_reduction', // 대인 피해 감소

    'shield_atk_damage_rate_increase', // 대방탄 피해 증가
    'shield_atk_damage_rate_reduction', // 대방탄 피해 감소

    'evasion_rate_increase', // 회피율 증가
    'evasion_rate_reduction', // 회피율 증가

    'shootpattern_mult_increase', // 공격 속도 증가
    'shootpattern_mult_reduction', // 공격 속도 감소

    'accuracy_rate_increase', // 명중률 증가
    'accuracy_rate_reduction', // 명중률 감소

    'invincible', // 무적 상태
    'heal_amount', // 초당 회복량
  ];

  const buffs = {};
  for (const effect of entity.effects) {
    for (const key of buff_keys) {
      if (effect[key] !== undefined) {
        const value = effect[key];
        if (!value) {
          continue;
        }
        if (buffs[key] === undefined) {
          buffs[key] = 0;
        }
        buffs[key] += value;
      }
    }
  }

  let obj_base = {};
  return {
    ...obj_base,

    // cosmatics/UI
    parts,
    equipments,
    outfit_scale,

    pos: tfp(pos),
    // movespeed 단위 (10cm/tick)
    // 편의상 mm/s로 넘김
    movespeed: movespeed * 10 * sim.tps, // tps = 30
    life,
    state,
    effects,
    buffs,
    waypoint,
    aimtarget,
    aimtarget_idx,
    aimtargetmeta,

    ads,
    aimdir,
    aimvar,
    firearm_ammo_max,

    ammo,
    shield,
    shield_max,

    // UI
    kills,
    damage,

    crouch: ['covered', 'crawl', 'dash'].includes(state),
    stunned,

    throwables_cur: entity.throwables.filter((t) => t.name !== 'throwable_none').length ?? 0,
    attachables_cur: entity.attachables.length ?? 0,
    heals_cur: entity.heals.length ?? 0,

    targetdoor: targetdoor0,
    heal_target_idx,
  };
}

function extSerializeIndicators(sim, tfp) {
  const { tick, throwables, entities, goals } = sim;

  const indicators = [];
  for (const throwable of throwables) {
    if (throwable.blast_timer.expired(tick)) {
      continue;
    }

    let ty = 'throwable';
    const timer = throwable.blast_timer;
    let duration = timer.duration;
    let remain = timer.remain(tick);
    indicators.push({
      ty,
      pos: tfp(throwable.pos),

      duration,
      remain,
    });
  }

  for (const entity of entities) {
    let ty = 'heal';
    const timer = entity.healTick;
    if (timer.expired(tick)) {
      continue;
    }

    let duration = timer.duration;
    let remain = timer.remain(tick);

    indicators.push({
      ty,
      pos: tfp(entity.pos),

      duration,
      remain,
    });
  }

  for (const goal of goals) {
    let ty = 'goal';

    const state = goal.goalstate;
    if (state.owner >= 0 || state.count_team0 === 0) {
      continue;
    }

    let duration = sim.ticksFromSec(goal.occupy_dur ?? opts.GOAL_OCCUPY_DURATION);
    let remain = tick - state.occupy_tick;
    let { event_key } = goal;
    // if (!event_key) {
    //   event_key = false;
    // }

    indicators.push({
      ty,
      pos: tfp(goal.pos),
      name: goal.name,
      sound: goal.sound,
      event_key,

      duration,
      remain,
    });
  }

  for (const prompt of sim.pending_prompts) {
    if (!prompt.door) {
      continue;
    }
    const pos = tfp(prompt.door.pos);
    const { queue_at, expire_at } = prompt;

    const duration = expire_at - queue_at;
    const remain = expire_at - tick;

    indicators.push({
      ty: 'breach',
      pos,
      duration,
      remain,
    });
  }

  return indicators;
}

function ctrlSerializeState(sim) {
  const { simctrl } = sim;
  const serial = {};

  if (!simctrl) {
    return serial;
  }

  if (simctrl.throwable_ctrl) {
    const ctrl = simctrl.throwable_ctrl;

    serial.throwable_ctrl = {
      activate: ctrl.activate,
      target_idx: sim.entities.indexOf(ctrl.target),
      throwable: ctrl.throwable,
      targetpos: ctrl.targetpos,
      reserve_target_idx: sim.entities.indexOf(ctrl.reserve_target),
      state: ctrl.state,
      can_throw: ctrl.can_throw,
    };
  }

  if (simctrl.heal_ctrl) {
    const ctrl = simctrl.heal_ctrl;

    const allies = sim.entities.filter((e) => e.team === 0);
    const allies_idx = allies.map((e) => sim.entities.indexOf(e));

    serial.heal_ctrl = {
      activate: ctrl.activate,
      btn_activate: ctrl.btn_activate,
      allies_idx,
      can_heal: ctrl.can_heal,
    };
  }

  if (simctrl.order_ctrl) {
    const ctrl = simctrl.order_ctrl;

    serial.order_ctrl = {
      activate: ctrl.activate,
      btn_info: ctrl.btn_info,
      can_order: ctrl.can_order,
    };
  }

  return serial;
}

export function serializeState(sim, first, res, incremental = false) {
  const fogbuf = sim.teamVisibility(0);
  // encode fogbuf
  let vis = '';
  for (let i = 0; i < fogbuf.length; i++) {
    const code = Math.floor(Math.min(fogbuf[i], 0.99) * 10);
    vis += code;
  }

  const tfp = (pos) => {
    return pos.add(sim.world.offset).mul(10).round();
  }

  const { tick } = sim;
  const entities = sim.entities.map((entity, idx) => {
    const { pos, team, reloadTick, deadTick, spawnTick } = entity;
    const visible = fogbuf[sim.world.idx(sim.world.worldToGrid(pos))] > 0.6;

    let initiated = false;
    const rule = entity.waypoint_rule;
    if (entity.team !== 0 && rule.ty !== 'idle' && rule.tick > 0 && rule.tick === tick - 1) {
      initiated = true;
    }

    let obj = extSerializeEntityBase(entity);
    if (incremental) {
      const spawned = sim.tick === 0 || spawnTick === sim.tick - 1;
      if (!spawned && team !== 0 && !(deadTick === sim.tick - 1) && rule.ty === 'idle') {
        return { idx, pos: tfp(pos) };
      }

      if (!spawned) {
        obj = {};
      }
    }

    return {
      ...obj,
      ...extSerializeEntity(sim, entity, tfp),

      idx,
      visible,
      reload_triggered: reloadTick.triggered(tick) && tick != 0,
      reload_duration: reloadTick.duration,
      reload_remain: reloadTick.remain(tick),
      initiated,
      attach_triggered: entity.attachTick.triggered(tick) && tick != 0,
      heal_triggered: entity.healTick.triggered(tick) && tick != 0,
      heal_expired: entity.healTick.expired_exact(tick) && tick != 0,
    };
  });

  const trails = sim.trails.filter(({ ty, tick }) => {
    return ty === 'firearm' && tick === sim.tick - 1;
  }).map(({ source, target, dir, len, hit, kill, invis, crit }) => {
    const show_effect = target.armor === 0 && hit;

    return {
      source_idx: sim.entities.indexOf(source),
      target_idx: sim.entities.indexOf(target),
      dir,
      len: len * 10,
      hit: !!hit,
      kill: !!kill,
      crit,
      // UE에서 damage_life를 기반으로 이펙트를 보여줍니다.
      damage_life: 0 | show_effect,
      invis,
    };
  });

  const bubbles = sim.bubbles.map(({ msg, entity, timer }, idx) => {
    if (timer.expired(tick)) {
      return null;
    }
    return {
      idx,
      msg,
      entity_idx: sim.entities.indexOf(entity),
      remain: timer.remain(tick),
    };
  }).filter((item) => item !== null);

  const damageIndications = sim.damageIndications.map(({ damage, start_pos, entity, timer, first }, idx) => {
    if (timer.expired(tick)) {
      return null;
    }
    return {
      idx,
      damage,
      start_pos: tfp(start_pos),
      entity_idx: sim.entities.indexOf(entity),
      lapse: timer.lapse(tick),
      progress: timer.progress(tick),
      first,
    };
  }).filter((item) => item !== null);

  for (const indication of sim.damageIndications) {
    indication.first = false;
  }

  const throwables = sim.throwables.filter((throwable) => !throwable.attach).map((throwable) => { // 브리칭에 사용된 attachable은 언리얼에서 표시되지 않아야 함
    const {
      move_timer,
      blast_timer,
      entity,
      pos,
      start_pos,
      target_pos,
      blastareas,
    } = throwable;

    const targets = _.flatten(blastareas.map(({ entities }) => entities));

    const has_blast_ty = (ty) => {
      return throwable.throwable.blasts.find(({ blast_ty }) => blast_ty === ty);
    };

    let ty = 'base';
    if (has_blast_ty('damage') || has_blast_ty('effect-frag')) {
      ty = 'frag';
    } else if (has_blast_ty('effect')) {
      ty = 'stun';
    }

    // blast areas
    const { blasts } = throwable.throwable;
    const full_radius = blasts.reduce((acc, blast) => {
      if (blast.blast_expose_ty === 'full') {
        acc = Math.max(acc, blast.blast_radius);
      }
      return acc;
    }, 0);
    const half_radius = blasts.reduce((acc, blast) => {
      if (blast.blast_expose_ty === 'half') {
        acc = Math.max(acc, blast.blast_radius);
      }
      return acc;
    }, 0);

    return {
      ty,
      name: throwable.throwable.throwable_name,

      throw_at: move_timer.start,
      land_at: move_timer.end,
      blast_at: blast_timer.end,

      pos: tfp(pos),
      start_pos: tfp(start_pos),
      target_pos: tfp(target_pos),

      source_idx: sim.entities.indexOf(entity),
      target_indices: targets.map((t) => sim.entities.indexOf(t)),

      is_breaching: throwable.throwable.is_breaching,

      full_radius,
      half_radius,
    };
  });

  const pending_prompts = sim.prompts.map((p, idx) => {
    if (!p.pause) {
      return null;
    }

    return {
      idx,
      area: 0,
      expire_at: p.tick,
      queue_at: p.tick,
      prompt_options: p.prompt_options,
      pause: p.pause,
      dialog: p.dialog,
    };
  }).filter((p) => p !== null);

  let level_name_base = 'L_ShooterGym0';
  let level_names = ['L_ShooterGym_base'];
  if (sim.world.level_name_base) {
    level_name_base = sim.world.level_name_base;
  }
  if (sim.world.level_names) {
    level_names = sim.world.level_names;
  }

  const world = {
    level_name_base,
    level_names,
    width: sim.world.width,
    height: sim.world.height,
    grid_size: opts.GRID_SIZE * 10,
    grid_count_x: sim.world.grid_count_x,
    grid_count_y: sim.world.grid_count_y,
    offset: sim.world.offset.mul(10).round(),
    vis,
  };

  const obstacles = sim.obstacles.filter((o) => o.ty === 'door').map((obs) => {
    const { ty, pos, extent, heading, doorstate, wip, imported } = obs;
    let dooropen = false;
    let doorname = doorstate?.name ?? null;
    let dooropener = -1;
    let exploded = doorstate?.exploded ?? false;
    if (ty === 'door' && doorstate.open) {
      dooropen = true;
      dooropener = sim.entities.indexOf(doorstate.opener);
    }
    return {
      ty,
      pos: tfp(pos),
      extent: extent.mul(10).round(),
      heading,
      dooropen,
      doorname,
      dooropener,
      wip: !!wip,
      imported: !!imported,
      fullname: obs.fullname,
      exploded,
    };
  });

  const segments = sim.segmentProgress();
  const { segments_report } = sim;
  let clear = false;
  if (segments_report && segments_report.length > 1) {
    const target_report = segments_report[segments_report.length - 1];
    if (target_report && target_report.tick > 0 && target_report.killed >= target_report.enemies) {
      clear = true;
    }
  }

  const indicators = extSerializeIndicators(sim, tfp);

  const simctrl = ctrlSerializeState(sim);

  const resp = {
    res,
    first,
    tick,

    world,
    obstacles,
    entities,
    bubbles,
    damageIndications,
    trails,
    throwables,
    segments,

    indicators,

    pending_prompts,
    dialogs: sim.dialogs.filter((d) => {
      return d.timer.live(tick);
    }),
    clear,
    simctrl,
  };

  if (first) {
    resp.res = -2;
  }

  return resp;
}

export function mergeState(ext_prev, ext) {
  const entities = ext_prev.entities.slice();
  for (let i = 0; i < ext.entities.length; i++) {
    const entity_prev = entities[i] ?? {};
    entities[i] = {
      ...entity_prev,
      ...ext.entities[i],
    };
  }

  return {
    ...ext,
    entities,
  };
}
