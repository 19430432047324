import source from '../downloaded/work.json' assert { type: 'json' };

export const work = [];
for (const row of source) {
  const {
    work_key,
    work_type,
    work_name,
    work_desc,
    department,
    ability_ty,
    var1,
    var2,
    ability_diff_fixed,
    ability_diff_calc,
    work_effect_ty,
    on_finish_success,
    finish_success_rate_desc,
    finish_success_effect_desc,
    on_finish_fail,
    on_finish_repeat,
    on_finish_stop_timer,
    duration_base_days,
    duration_minimum_days
  } = row;

  work.push({
    work_key,
    work_type,
    work_name,
    work_desc,
    department,
    ability_ty,
    var1: var1.split(',').map((v) => v.trim()),
    var2: var2.split(',').map((v) => v.trim()),
    ability_diff_fixed: Number(ability_diff_fixed),
    ability_diff_calc,
    work_effect_ty,
    on_finish_success: on_finish_success.split(',').map((v) => v.trim()),
    finish_success_rate_desc,
    finish_success_effect_desc,
    on_finish_fail: on_finish_fail.split(',').map((v) => v.trim()),
    on_finish_repeat,
    on_finish_stop_timer,
    duration_base_days,
    duration_minimum_days,
  });
}
