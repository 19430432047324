import source from '../downloaded/char2aptitudeMultiplier.json' assert { type: 'json' };

export const aptitudeMultiplier = {}

for (const row of source) {
  let { id, Text, Value } = row
  aptitudeMultiplier[id] = {
    Text: Text,
    Value: +Value,
  }
}
