import source from '../downloaded/growthrate.json' assert { type: 'json' };

const _growthrate = [];
for (const row of source) {
  const { stat_min, stat_max, growth_bonus_rate, growth_reduction_rate } = row;
  _growthrate.push({
    stat_min: parseFloat(stat_min) || 0,
    stat_max: parseFloat(stat_max) || 0,
    growth_bonus_rate: parseFloat(growth_bonus_rate) || 0,
    growth_reduction_rate: parseFloat(growth_reduction_rate) || 0,
  });
}
export const growthrate = _growthrate.sort((a, b) => a.stat_min - b.stat_min).slice();


export function getGrowthRate(stat) {
  for (let i = 0; i < growthrate.length; i++) {
    const row = growthrate[i];
    if (stat <= row.stat_max) {
      return row;
    }
  }
  return growthrate[growthrate.length - 1];
}
