import _ from 'lodash';

import { Rng } from './rand.mjs';
import { names, names3 } from './names.mjs';
import { opts, CONTROLS_TMPL, AGENT_CONTROLS_TMPL, PARAMS } from './opts.mjs';
import { v2, dirnorm, dirnorm0, dircontains } from './v2.mjs';
import { createStructures, placeStructures } from './room.mjs';
import { World } from './world.mjs';
import { TickTimer } from './ticktimer.mjs';
import {
  Route,
  GeomUnion,
  UnionPoly,
  bisectEdge,
  checkcover,
  coverEdges,
  createObstacle,
  findReachables,
  geomContains,
  geomContainsCCW,
  geomSamplePoint,
  geomSamplePointWithinPolygon,
  obstructed,
  obstructed_t,
  onReachableGridWasm,
  onReachableGridWasmOp,
  overlapped,
  raycastVisibilityWasm,
  raycastWasm,
  routeNodeFromPos,
  routePathfind,
  routePathfindAll,
  routePathfindVisible,
  shrinkRect,
  breachPositions,
  createConvexHull,
} from './geom.mjs';
import { stats_const, updateEntityStat } from './stat.mjs';
import { presets } from './presets_obstacles.mjs';

import callsigns from './data/google/downloaded/char2callsign.json' assert { type: 'json' };
import { L } from './localization.mjs';
import { rolesPerksdata } from './data/google/processor/data_char2rolesPerks.mjs';
import { statsPerksdata } from './data/google/processor/data_char2statsPerks.mjs';
import { perk2Sets } from './presets_perk2.mjs';
import { primaryStatApply } from './stats3.mjs'

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}
const clamp = function (x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function lineToPointDist(p, dir, target) {
  return Math.abs(Math.cos(dir) * (p.x - target.x) + Math.sin(dir) * (p.y - target.y));
}

function projectile_vertvar(variance) {
  return Math.min(Math.PI / 128, variance / 3);
}

function remove_item_unique(array, key) {
  let cnt = 1;
  return array.filter((item) => item !== key || (cnt-- <= 0));
}

function findLast(array, compare) {
  for (let i = array.length - 1; i >= 0; i--) {
    const elem = array[i];
    if (compare(elem)) {
      return elem;
    }
  }
  return null;
}

function findLastIndexCommonElement(array1, array2, compare) {
  let sz = Math.min(array1.length, array2.length);
  let ret = -1;
  for (let i = 0; i < sz; i++) {
    if (compare(array1[array1.length - i - 1], array2[array2.length - i - 1])) {
      ret = i;
    } else {
      break;
    }
  }
  return ret;
}

function has_throwables(e) {
  if (!opts.ALLOW_THROWABLE_ON_BREACHING) {
    return false;
  }
  return e.controls['throwable'] && e.throwables ? e.throwables.length > 0 : false;
}
function has_attachables(e, door_name) {
  return e.controls['attachable'][door_name] && e.attachables ? e.attachables.length > 0 : false;
}

const is_breaching_candidate = (e, door_name) => {
  return has_throwables(e) || has_attachables(e, door_name);
};

const breaching_priority_compare = (door_name) => {
  return (a, b) => {
    if (a.state === 'dead') {
      return 1;
    }
    if (b.state === 'dead') {
      return -1
    }
    if (has_attachables(a, door_name)) {
      return -1;
    }
    if (has_attachables(b, door_name)) {
      return 1;
    }
    if (has_throwables(a)) {
      return -1;
    }
    if (has_throwables(b)) {
      return 1;
    }
    if (a.leader === b) {
      return 1;
    }
    return -1;

  }
}

const get_breacher = (breaching_rule, door_name) => {
  if (breaching_rule.ty !== 'breaching') {
    return null;
  }
  let breacher = breaching_rule.common.breacher;
  if (!breacher || breacher.state === 'dead') {
    breacher = [...breaching_rule.common.members].sort(breaching_priority_compare(door_name))[0];
    breaching_rule.common.breacher = breacher;
  }
  return breacher;
}

// eslint-disable-next-line
function projectile_dice2d(rng, pos, targetpos, dir, variance) {
  const aimdirmin = dir - variance;
  const aimdirmax = dir + variance;

  const firedir = rng.range(aimdirmin, aimdirmax);
  let dist = lineToPointDist(pos, firedir, targetpos);

  const vertvar = projectile_vertvar(variance);
  const firedir_v = rng.range(dir - vertvar, dir + vertvar);
  let dist_v = lineToPointDist(pos, firedir_v, targetpos);

  if (dist > dist_v) {
    return firedir;
  } else {
    return firedir_v;
  }
}

// n번 주사위를 굴려서 제일 안 좋은 방향을 샘플링합니다.
function projectile_dice(rng, pos, targetpos, dir, variance, runcount) {
  const aimdirmin = dir - variance;
  const aimdirmax = dir + variance;

  let maxdist = 0;
  for (let i = 0; i < runcount; i++) {
    const firedir = rng.range(aimdirmin, aimdirmax);
    const dist = lineToPointDist(pos, firedir, targetpos);
    if (dist > maxdist) {
      dir = firedir;
      maxdist = dist;
    }
  }
  return dir;
}

class Queue {
  constructor(capacity) {
    this.arr = [];
    this.capacity = capacity;
  }

  push(item) {
    this.arr.push(item);
    while (this.arr.length > this.capacity) {
      this.arr.splice(0, 1);
    }
  }

  includes(item) {
    return this.arr.includes(item);
  }
}

export const AREA_CONFIG_TMPL = {
  pos: new v2(60, 300),
  size: new v2(40, 200),
  heading: 0,
};

export const SHIELD_ENTITY_SIZE_MULT = 1.5;
export const SHIELD_BLOCK_RANGE = Math.PI;

function controlsInit(tick) {
  return {
    reorg: false,
    state: 'explore',
    reorgTimer: new TickTimer(tick, 0),
    ticks_reorg: 0,
  };
}

export function controlsDefault() {
  return { ...CONTROLS_TMPL };
}

export function agentControlsDefault(segment) {
  const controls = JSON.parse(JSON.stringify(AGENT_CONTROLS_TMPL));

  if (segment) {
    for (const door of segment.doors) {
      controls.attachable[door] = false;
    }
  }
  return controls;
}

const CONTROLS_DEFAULT_KEYS = Object.keys(controlsDefault());

export function controlsNew(tick) {
  return {
    ...controlsDefault(),
    ...controlsInit(tick),
  };
}

export class Entity {
  constructor(rng, tick, extra) {
    // other props
    this.name = rng.choice(names);
    if (extra.team === 0) {
      this.name = rng.choice(names3);
      this.callsign = rng.choice(callsigns).name;
    }

    // === life & armor ===
    this.life = extra.life;
    this.life_max = extra.life_max ?? this.life;

    if (extra.vest_armor) {
      extra.armor = extra.vest_armor;
    }
    this.armor = 0;
    this.armor_max = this.armor;

    this.shield = 0;
    this.shield_max = this.shield;
    this.shield_hit_prob = extra.shield_hit_prob ?? 0;
    this.shield_damage_max = extra.shield_damage_max ?? 0;

    this.is_vest = false;
    this.is_shield = false;

    if (extra.shield) {
      this.is_shield = true;
    }

    // 방탄복 실드
    if (extra.vest_shield) {
      if (!extra.shield) {
        extra.shield = 0;
      }
      extra.shield += extra.vest_shield;
      this.is_vest = true;
    }

    // 방패 실드
    if (extra.shield) {
      this.shield = extra.shield;
      this.shield_max = extra.shield;
    }

    // === ammo ===
    // TODO: explore demo
    //this.ammo_total = extra.ammo_total ?? 100000;

    this.firearm_ammo_max = extra.firearm_ammo_max;
    this.ammo = Math.min(this.firearm?.ammo_total ?? 100000, this.firearm_ammo_max);

    // === aim & movement ==
    this.pos = new v2(0, 0);
    this.gridpos = new v2(0, 0);

    // 이동 방향 reference. entityNavigate 등에서 정해짐
    this.dir = Math.PI / 2;
    // 현재 바라보는 방향
    this.aimdir = Math.PI / 2;
    this.debugaimdir = this.aimdir;
    this.aimvar = extra.aimvar_hold;
    this.aimmult = 1;
    this.aimtarget = null;
    this.aimtargetshoots = 0;

    // === states ===
    this.state = 'stand';
    this.moving = false;
    this.waypoint = null;
    this.waypoint_policy = extra.waypoint_policy ?? 'none';
    this.movestate = 'walk';
    this.movespeed = 0;
    this.recent_visits = new Queue(opts.RECENT_VISITS_COUNT);

    this.spawnTick = tick;
    const tick0 = tick - 1;

    // crawl하면 일정 시간 일어날 수 없습니다.
    this.crawlTick = new TickTimer(tick0, 0);

    // 사격 패턴 중 다음 사격 타이머
    this.shootPatternTick = new TickTimer(tick0, 0);
    this.shootPatternIdx = 0;

    this.reloadTick = new TickTimer(tick0, 0);
    this.reloadShootIdleTick = new TickTimer(tick0, 0);

    this.lastRouteTick = new TickTimer(tick0, 0);
    this.lastFollowingTick = new TickTimer(tick0, 0);
    this.lastRiskTick = new TickTimer(tick0, 0);

    this.checkThrowableTick = new TickTimer(tick0, 0);
    this.idleAlertTick = new TickTimer(tick0, 0);

    this.retargetTick = new TickTimer(tick0, 0);

    this.unaimTick = new TickTimer(tick0, 0);

    // healTick: 회복 끝나는 순간
    this.healTick = new TickTimer(tick0, 0);
    this.waitTick = new TickTimer(tick0, 0);
    this.collectTick = new TickTimer(tick0, 0);
    this.healtargetTick = new TickTimer(tick0, 0);

    this.breachTick = new TickTimer(tick0, 0);

    // 테스트: unaim pause
    this.unaimPauseTick = new TickTimer(tick0, 0);
    this.deadTick = 0;

    this.attachTick = new TickTimer(tick0, 0);

    this.followRunTick = new TickTimer(tick0, 0);

    // idle visibility tick
    this.idleVisTick = new TickTimer(tick0, 0);

    if (extra) {
      for (const key in extra) {
        this[key] = extra[key];
      }
    }

    if (opts.DEBUG_APPLY_OPERATOR_PERKS && this.team === 0) {
      const totalRealStat = Object.values(this._stat).reduce((a, b) => a + b, 0);

      for (const key in rolesPerksdata) {
        if (rolesPerksdata[key]["role"] === this.role) {
          if (+rolesPerksdata[key]["minStat"] <= totalRealStat) {
            this[key] = perk2Sets[key];
          }
        }
      }
    }

    if (opts.DEBUG_APPLY_STATS_PERKS && this.team === 0) {
      for (const key in statsPerksdata) {
        if (+statsPerksdata[key]["minStat"] <= this._stat[statsPerksdata[key]["stat"]]) {
          this[key] = perk2Sets[key];
        }
      }
    }

    // 퍽 적용
    // 체력단련 퍽
    if (extra.perk2_vanguard_physical_training) {
      this.life_max += this.perk2_vanguard_physical_training.life_amount;
      this.life += this.perk2_vanguard_physical_training.life_amount;
    }

    this.rules = [];
    if (typeof this.default_rule === 'string') {
      this.rules.push({ ty: this.default_rule, tick });
    } else {
      const rule = { ...this.default_rule, tick };
      this.rules.push(rule);
    }

    this.waypoints = extra.waypoints ?? null;

    // temperal effects
    this.effects = [];

    // awareness
    this.awareness = [];

    // posessions
    this.objects = [];

    // in-game icons
    this.icons = [];

    this.controls = agentControlsDefault();
    if (this.throwable) {
      this.controls['throwable'] = true;
    }
    if (this.attachable && this.attachable_doors) {
      for (const door_name of this.attachable_doors) {
        this.controls['attachable'][door_name] = true;
      }
    }
  }

  get waypoint_rule() {
    return this.rules[this.rules.length - 1];
  }

  has_rule(ty) {
    return this.rules.find((r) => r.ty === ty) !== undefined;
  }

  // ty
  // initiator: 교전 등이 발생했을 때의 상대
  // expires_at: expires_at 뒤에 없어짐
  // area: explore 할 때 explore할 영역
  // follow_target: 추적 대상. 구출한 뒤 인질에게 적용
  // rescue_target: 구출 대상
  // gather: 모이기
  // goal: cover-goal, capture-goal
  // interact: interact, interact-object
  //
  // args
  // leader: gather 등에서 사용
  // transient: control로 외부에서 들어온 명령
  // no_override : 해당 rule을 최상단으로 고정
  // hold_fire : cover-hide에서 사용
  push_rule(tick, rule) {
    if (this.waypoint_rule.no_override) {
      return;
    }
    this.mark_rule(false);
    this.rules.push({
      ...rule,
      tick,
    });
  }

  alter_rule(rule_next, rule_expected) {
    let last = this.waypoint_rule;
    if (last.ty === rule_expected) {
      last.ty = rule_next;
      this.mark_rule(false);
      return true;
    }
    return false;
  }

  pop_rule_chain(fn) {
    let found = this.rules.findIndex(fn);
    if (found > 0) {
      this.rules.splice(found);
    }
  }

  pop_rule() {
    if (this.rules.length === 1) {
      console.error("could not pop last rule");
      return null;
    }
    this.mark_rule(false);
    return this.rules.pop();
  }

  mark_rule(marked) {
    this._rule_marked = marked;
  }

  get rule_marked() {
    return this._rule_marked;
  }

  get rule_awaken() {
    if (this.rules.length < 2) {
      return false;
    }
    const base_ty = this.rules[0].ty;
    return base_ty === 'idle' && base_ty !== this.waypoint_rule.ty;
  }

  is_fire_rule() {
    return ['fire', 'cover-fire'].includes(this.waypoint_rule.ty);
  }

  add_effect(effect_ty) {
    const { effects } = this;
    let effect = effects.find((e) => e.effect_ty === effect_ty);
    if (!effect) {
      effect = { effect_ty };
      effects.push(effect);
    }
    return effect;
  }

  remove_effect(effect_ty) {
    this.effects = this.effects.filter((e) => e.effect_ty !== effect_ty);
  }
}

function opponentTeam(team) {
  if (team === 0) {
    return [1];
  } else {
    return [0];
  }
}

const MESSAGES = [
  '오늘 저녁은 뭐지',
  '무사히 돌아가야 할텐데',
  '신이시여',
  '덥고 끈적끈적해',
  '집에 가고 싶어',
  '언제까지 이렇게 살아야 하지',
  '무서워',
];

export class Simulator {
  static create(props) {
    const sim = new Simulator(props);

    sim.routes = new Route(props.m, sim);
    const { world, entities } = sim;

    if (props.seed_placement) {
      sim.rng = new Rng(props.seed_placement);
    }

    // spawn entities
    for (const config of props.entities) {
      const entity = sim.spawnEntity(config);
      if (!entity) {
        console.error('failed to spawn entity');
      }
      /*
      if (entity) {
        sim.entitySetMobilityLevel(entity, opts.MOBILITY_LEVEL_DEFAULT);
      }
      */
    }
    for (let i = 0; i < props.entities.length; i++) {
      const pe = props.entities[i];
      if (!isNaN(pe.leader)) {
        sim.entities[i].leader = sim.entities[pe.leader];
      }
      if (!isNaN(pe.fireleader)) {
        sim.entities[i].fireleader = sim.entities[pe.fireleader];
      }
    }

    // spawn objects
    const objects = props.objects ?? [];
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const pos = sim.spawnPos(object);
      sim.objects.push({ ...object, pos, owned: false, seq: i });
    }

    // 같은 팀끼리 grid를 공유합니다.
    for (const entity of entities) {
      let sharefn = null;
      if (!isNaN(entity.vis_group)) {
        sharefn = (e) => e.vis_group === entity.vis_group;
      }
      if (sharefn === null) {
        continue;
      }
      // 인지 정보를 공유합니다: 탐색, awareness
      const src = entities.find(sharefn);
      entity.grid_explore = src.grid_explore;
      entity.awareness = src.awareness;
    }

    for (const entity of entities) {
      if (!entity.use_visibility) {
        continue;
      }

      sim.entityUpdateGridOmniDir(entity, entity.pos, true);
    }

    if (world.exp_prepopulate_grid) {
      const old = opts.GRID_VIS_PER_TICK;
      opts.GRID_VIS_PER_TICK = 1;

      for (const entity of entities) {
        if (!entity.use_visibility) {
          continue;
        }

        if (entity.team !== 0) {
          continue;
        }

        for (let i = 0; i < 4; i++) {
          let x = world.width * i / 3 - world.width / 2;
          let y = world.height * i / 3 - world.height / 2;

          sim.entityUpdateGridOmniDir(entity, new v2(-world.width / 2 + 1, y));
          sim.entityUpdateGridOmniDir(entity, new v2(world.width / 2 - 1, y));

          sim.entityUpdateGridOmniDir(entity, new v2(x, -world.height / 2 + 1));
          sim.entityUpdateGridOmniDir(entity, new v2(x, world.height / 2 - 1));
        }
      }

      opts.GRID_VIS_PER_TICK = old;
    }

    if (sim.segments) {
      const { tick, segments, segments_report } = sim;
      const allies = entities.filter((e) => e.team === 0);
      const allies_total = allies.length;

      for (const ally of allies) {
        ally.report = {
          kill: 0,
          life: ally.life,
          life_max: ally.life_max,
          damage_done: {
            firearm: 0,
            throwable: 0,
          },
          damage_taken: {
            life: 0,
            shield: 0,
            armor: 0,
          },
          healed: 0,
        }
      }

      for (const segment of segments) {
        const { areas } = segment;
        const total = entities.filter((e) => e.team !== 0 && areas?.includes(e.spawnarea)).length;

        segments_report.push({
          enemies: total,
          killed: 0,
          tick,
          allies_total,
          survived: 0,
        })
      }
    }

    return sim;
  }

  constructor(props) {
    const { obstacle_specs } = props;
    const world = new World(props.world);
    let { seed } = props;
    let obstacles = [];
    const rng = new Rng(seed);

    const areas = props.spawnareas.map((area, i) => {
      const obs = createObstacle(area.pos, area.extent, area.heading, null, true, null);
      obs.name = `area #${i}`;
      if (area.effect_ty) {
        obs.name += ` (${area.effect_ty})`;
      }
      obs.areastate = {
        area,
        vacate: area.vacate ?? false,
        triggers: area.triggers ? area.triggers.slice() : [],
      };
      obs.idx = area.idx ?? i;
      return obs;
    });

    function sampleObstacle(width, height, extent_margin, ty, area) {
      for (let i = 0; i < 100; i++) {
        const pos = geomSamplePoint(rng, area);
        const rot = rng.angle();

        const obs = createObstacle(pos, new v2(width, height), rot, ty, false, extent_margin);
        if (obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
          continue;
        }

        return obs;
      }
      console.error('failed to sample obstacle');
      return null;
    }


    for (const area of areas) {
      if (area.areastate.area.structureopts === undefined) {
        continue;
      }
      const { count, obstacle_count, enterance, heading } = area.areastate.area.structureopts;

      // TODO: heading
      const pos = area.areastate.area.pos;
      const extent = area.areastate.area.extent;

      const placements = placeStructures(rng, { pos, extent, count, heading });
      area.areastate.placements = placements.map((p) => createObstacle(p.pos, p.extent, p.dir, null, true, null));

      const structures = createStructures(rng, placements, { enterance });
      area.areastate.structures = structures;

      for (const s of structures) {
        const { structure, extent, offset, dir } = s;
        const pos_s = structure.pos.add(offset);

        s.shape = createObstacle(pos_s, extent, dir, 'structure', true, null);

        for (const wall of structure.walls) {
          const { start, end } = wall;

          const pos = v2.lerp(start, end, 0.5).add(offset);
          const extent = end.sub(start).mul(0.5);

          obstacles.push(createObstacle(pos.rot(pos_s, dir), extent, dir, 'full', false, v2.unit(12)));
        }

        for (const room of structure.squarifyTreemap) {
          const tl = new v2(room.x0, room.y0);
          const br = new v2(room.x1, room.y1);
          const pos = v2.lerp(tl, br, 0.5).add(offset);
          const extent = br.sub(tl).mul(0.5);

          room.shape = createObstacle(pos.rot(pos_s, dir), extent, dir, 'room', false, null);
        }

        for (const wall of structure.doors) {
          const { start, end } = wall;
          const pos = v2.lerp(start, end, 0.5).add(offset);
          const extent = end.sub(start).mul(0.5);

          let extent_margin = new v2(1, 4);
          if (extent.y > extent.x) {
            extent_margin = new v2(4, 1);
          }

          const o = createObstacle(pos.rot(pos_s, dir), extent, dir, 'door', false, extent_margin);

          obstacles.push(o);
        }

        if (!obstacle_count) {
          continue;
        }

        const target_count = obstacles.length + obstacle_count;
        while (obstacles.length < target_count) {

          const width = rng.range(2, 4);
          const height = rng.range(5, 9);
          const obs = sampleObstacle(width, height, new v2(5, 5), 'half', s.shape);
          if (obs === null) {
            break;
          }
          obstacles.push(obs);
        }
      }
    }
    let door_idx = 0;
    // obstacles
    for (const obstacle_spec of obstacle_specs) {
      if (obstacle_spec.random === undefined) {
        // TODO: 개선해야 함
        let no_coverpoint = obstacle_spec.no_coverpoint ?? false;
        if (obstacle_spec.ty === 'fence') {
          no_coverpoint = true;
        }
        //성준 : 일부 맵에서 obstancle_spce의 pos나 extent 멤버가 존재하지 않습니다. presets_mission에서 정보를 추가하면 해결됩니다.
        const obs = createObstacle(v2.from(obstacle_spec.pos),
          v2.from(obstacle_spec.extent),
          obstacle_spec.heading,
          obstacle_spec.ty,
          no_coverpoint,
          obstacle_spec.extent_margin
        );
        if (obstacle_spec.ty === 'door') {
          obs.doorstate.group = obstacle_spec.doorgroup ?? null;
          obs.doorstate.name = obstacle_spec.doorname ?? null;
          obs.name = `door #${door_idx}`;
          obs.doorstate.idx = door_idx;
          door_idx++;
        }

        if (obstacle_spec.name) {
          obs.name = obstacle_spec.name;
        }
        if (obstacle_spec.imported) {
          obs.imported = obstacle_spec.imported;
        }
        if (obstacle_spec.wip) {
          obs.wip = obstacle_spec.wip;
        }
        obstacles.push(obs);
        continue;
      }

      const target_count = obstacles.length + obstacle_spec.random.count;
      const area_heading = obstacle_spec.heading ?? 0;
      const obstacle_area = createObstacle(
        obstacle_spec.pos,
        obstacle_spec.extent,
        area_heading,
        '',
        false,
        null);

      while (obstacles.length < target_count) {
        let ty = obstacle_spec.random.ty ?? 'full';
        if (ty === 'mixed') {
          ty = rng.choice(['full', 'half']);
        }

        let obs = null;
        if (obstacle_spec.random.presets) {
          let candidates = presets.filter((p) => {
            return ((ty === 'half') !== (p.extent[2] < 70));
          });
          let preset = rng.choice(candidates);
          const width = preset.extent[0] / 10;
          const height = preset.extent[1] / 10;

          obs = sampleObstacle(width, height, new v2(10, 10), ty, obstacle_area);
          if (obs) {
            obs.name = preset.name;
            obs.fullname = preset.fullname;
          }
        } else {
          const width = obstacle_spec.random.extent?.x ?? rng.range(4, 8);
          const height = obstacle_spec.random.extent?.y ?? rng.range(15, 50);

          obs = sampleObstacle(width, height, new v2(10, 10), ty, obstacle_area);
        }

        if (obs === null) {
          break;
        }
        if (areas.find((o) => o.areastate.vacate && overlapped(o.routepoints, obs.routepoints))) {
          continue;
        }

        obstacles.push(obs);
      }
    }

    const { GOAL_SIZE } = opts;
    const goals = [];
    for (let goal_idx = 0; goal_idx < props.goals.length; goal_idx++) {
      const g = props.goals[goal_idx];
      const area = areas.find((a) => a.idx === g.area);
      if (!area) {
        continue;
      }

      let goal = null;
      for (let i = 0; i < 100; i++) {
        const pos = geomSamplePoint(rng, area);
        const rot = rng.angle();

        // TDO:
        const obs = createObstacle(pos, new v2(GOAL_SIZE, GOAL_SIZE), rot, 'half', false, null);
        obs.ty = 'fence';
        if (!obstacles.find((o) => overlapped(o.polygon, obs.routepoints))) {
          goal = obs;
          break;
        }
      }
      goal.name = g.name;
      goal.sound = g.sound;
      goal.occupy_dur = g.occupy_dur;
      goal.event_key = g.event_key;
      goal.waypoint = !!g.waypoint;
      goal.goalstate = { ...opts.GOALSTATE_TMPL };
      goal.idx = goal_idx;
      goal.imported = true; // 언리얼에서 goal에 벽돌 숨기기

      goals.push(goal);
      obstacles.push(goal);
    }

    // spawn entities
    const tick = 0;
    this.placement_rooms = [];

    // hydration
    function hydrate_prompt_options(options) {
      return options.map((p) => {
        return {
          ...p, actions: p.actions.map((a) => {
            a = { ...a };
            if (a.actionrules) {
              a.actionrules = a.actionrules.map(dydrate_rule);
            }
            return a;
          })
        };
      });
    }
    function dydrate_rule(r) {
      r = { ...r };
      if (!isNaN(r.area)) {
        r.area = areas.find((a) => a.idx === r.area);
      }
      if (!isNaN(r.goal)) {
        r.goal = goals.find((g) => g.idx === r.goal);
      }
      if (r.prompt_options) {
        r.prompt_options = hydrate_prompt_options(r.prompt_options);
      }
      return r;
    };
    function hydrate_trigger(t) {
      t = { ...t };
      if (t.actionrules) {
        t.mission_rules = t.actionrules.map(dydrate_rule);
      }
      if (t.actionprompts) {
        t.actionprompts = {
          ...t.actionprompts,
          prompt_options: hydrate_prompt_options(t.actionprompts.prompt_options)
        };
      }
      if (t.actionentities) {
        t.actionentities = t.actionentities.map((e) => {
          return {
            ...e,
            default_rule: dydrate_rule(e.default_rule),
          };
        });
      }
      return t;
    }
    function filter_rule(r) {
      if (!isNaN(r.area) && !areas.find(a => a.idx === r.area)) {
        return false;
      }
      if (!isNaN(r.goal) && !goals.find(g => g.idx === r.goal)) {
        return false;
      }
      return true;
    }

    //성준 : 일부 맵에는 mission_rules 멤버가 존재하지 않습니다. preset_mission에서 mission_rules 관련 정보를 추가하면 해결됩니다.
    this.mission_rules = props.mission_rules.filter(filter_rule).map((r, i) => ({ mission_idx: i, ...dydrate_rule(r) }));
    this.segments = (props.segments ?? []).map(({ spawnareas, doors, triggers }, i) => {
      return {
        idx: i,
        clear: false,
        areas: spawnareas,
        doors,
        triggers,
      };
    });
    this.segment = this.segments[0];

    for (const area of areas) {
      area.areastate.triggers = area.areastate.triggers.map(hydrate_trigger);
    }

    this.world = world;
    this.seed = seed;
    this.rng = rng;
    this.tick = tick;
    this.tps = opts.tps;

    this.entities = [];
    this.throwables = [];
    this.objects = [];

    this.trails = [];
    this.blastareas = [];
    this.spawnareas = areas;
    this.obstacles = obstacles;
    this.goals = goals;
    this.routes = null;

    this.journal = [];
    this.journal.push = function (...args) {
      function lastDuplicateJournalIndex(list, item) {
        const arr = list.map((e, i) => ({ ...e, index: i })).filter((e) => e.ty === item.ty);
        if (item.ty !== 'perk') {
          return -1;
        }

        const subarr = arr.filter((e) => e.perk === item.perk);
        if (subarr.length === 0 || arr[arr.length - 1] !== subarr[subarr.length - 1]) {
          return -1;
        }
        const last = subarr[subarr.length - 1];
        const lastIndex = last.index;

        if (_.isEqual(last.targets, item.targets)) {
          return -1;
        }
        return lastIndex;
      }

      if (!this || this.length === 0) {
        Array.prototype.push.apply(this, args);
      } else {
        const i = lastDuplicateJournalIndex(this, args[0]);
        if (i === -1) {
          Array.prototype.push.apply(this, args);
        } else if (this[i].reps) {
          this[i].reps++;
        } else {
          this[i].reps = 2;
        }
      }
    };
    this.perfbuf = [];

    this.m = props.m;
    this.rebuildVisibility();

    this.pending_actions = [];

    // segment마다 spawnarea에 설정되어야 하는 controls입니다.
    // segment_controls_list[spawnarea][segment] = 현재 segment일 때, spawnarea의 controls는 어떻게 설정되어야 하는 정보입니다.
    this.segment_controls_list = [];
    if (props.segment_controls_list) {
      this.segment_controls_list = props.segment_controls_list;
      for (const segment_controls of this.segment_controls_list) {
        let { controls } = segment_controls;
        for (let i = 0; i < controls.length; i++) {
          controls[i] = {
            ...controls[i],
            ...controlsInit(tick),
          };
        }
      }
    }

    // user-provided controls
    // 각 spawnarea의 control 상태입니다.
    this.controls_list = [];
    this.controlsUpdate();

    // dialogs 따로 받기?
    this.pending_prompts = (props.pending_prompts ?? []).map((p) => {
      return {
        area: 0,
        expire_at: tick,
        queue_at: tick,
        prompt_options: [{ title: 'proceed', actions: [], }],
        pause: true,
        dialog: p.dialog.dialog,
      };
    });
    this.pending_events = [];

    // TODO: per-team?
    this.win = false;
    this.withdraw = false;

    // TODO: DEMO
    this.door_policy = [];

    this.blastareas = (props.blastareas ?? []).map((b) => {
      const { pos, blast_radius, blast_expose_ty, effect_ty } = b;
      let vismodel = null;
      switch (blast_expose_ty) {
        case 'full':
          vismodel = this.rr;
          break;
        case 'half':
          vismodel = this.rv;
          break;
        default:
          throw new Error(`unknown blast_expose_ty=${blast_expose_ty}`);
      }

      const vis = vismodel.triangulated.visibility(pos.x, pos.y, false);
      vis.limit(blast_radius);

      let ba = {
        pos,
        tick,
        expire_at: tick + this.ticksFromSec(3600),
        vis,
        entities: [],
        effect_ty,
      };
      return ba;
    });

    this.heals = [];
    this.bubbles = [];

    this.damageIndications = [];

    this.conversation_triggers = props.conversation_triggers ?? {};
    // 현재 진행되고 있는 대화 목록?
    this.conversations = [];

    // 아웃 게임에서 dialog trigger 정보
    this.dialog_triggers = props.dialog_triggers ?? [];
    // 아웃게임에서, 이벤트마다 발생하는 dialog 정보
    this.dialogs = [];

    this.stalkers = props.stalkers ?? [];

    // 투사체 발사 큐
    this.projectile_scheduler_queue = [];
    // 투사체 처리 큐
    this.fire_scheduler_queue = [];

    // TODO
    this.playerstats = {
      morale: opts.PS_MORALE_DEFAULT,
      uncover: opts.PS_UNCOVER_DEFAULT,

      loot: {
        count: 0,
      },
    };

    this.segments_report = [];
  }

  onStart() {
    const { entities } = this;
    for (const entity of entities) {
      if (entity.heals) {
        entity.heals_max = entity.heals.length;
      }
      if (entity.throwables) {
        entity.throwables_max = entity.throwables.filter(
          t => t.throwable_rate !== 0 &&
            t.key !== 'throwable_lockpick').length;
      }
      if (entity.attachables) {
        entity.attachables_max = entity.attachables.length;
      }
    }
  }

  free() {
    const t = this;
    for (const key of Object.keys(this).filter((k) => typeof this[k]?.free === 'function')) {
      t[key].free();
      t[key] = null;
    }

    for (const { vis } of this.blastareas) {
      vis.free();
    }

    for (const { vis } of this.entities) {
      vis?.free();
    }
  }
  tfp(pos) {
    return pos.add(this.world.offset).mul(10).round();
  }
  // 언리얼 이벤트 트리거 인터페이스
  InvokeCameraEventBreaching(center, radius) {
    window.ue?.connection?.functioncall(JSON.stringify(
      {
        functioncall: 'HandleEventBreaching',
        params: [this.tfp(center), radius * 10],
      }
    ));
  }
  InvokeCameraEventThrow(pos) {
    window.ue?.connection?.functioncall(JSON.stringify(
      {
        functioncall: 'HandleEventThrow',
        params: [this.tfp(pos)],
      }
    ));
  }
  InvokeCameraEventCrit(target_pos) {
    window.ue?.connection?.functioncall(JSON.stringify(
      {
        functioncall: 'HandleEventCrit',
        params: [this.tfp(target_pos)],
      }
    ));
  }
  OnSegmentCleared(segment_idx, segments_len, killed, survived) {
    window.ue?.connection?.functioncall(JSON.stringify(
      {
        functioncall: 'OnSegmentCleared',
        params: [segment_idx, segments_len, killed, survived],
      }
    ));
  }

  // choose onobstructed position with given config
  spawnPos(config) {
    const { rng, entities, obstacles, goals, placement_rooms } = this;
    const { GOAL_RADIUS } = goals;

    let pos = config.pos;
    if (pos) {
      return pos;
    }

    const area = this.spawnareas[config.spawnarea];
    const { structures } = area.areastate;

    let entity_collision_count = 10;

    while (!pos) {
      if (structures) {
        // sample random placement
        const sample = rng.integer(0, structures.length - 1);
        const { structure } = structures[sample];
        const maps = structure.squarifyTreemap;

        let room = (0 | placement_rooms[sample]);
        placement_rooms[sample] = (room + 1) % maps.length;

        const geom = maps[room].shape;
        pos = geomSamplePoint(rng, geom);
        if (pos === null) {
          return null;
        }
      } else {
        const inset = config.size;
        let polygon = area.polygon;
        if (inset) {
          polygon = shrinkRect(area.polygon, inset);
        }
        pos = geomSamplePointWithinPolygon(rng, polygon);
      }

      // TODO: goal에서 spawn 안 되도록
      const pos0 = pos;
      if (obstacles.find((o) => geomContains(pos0, o.routepoints))) {
        pos = null;
        continue;
      }
      if (findReachables(this.routes, pos0).length === 0) {
        pos = null;
        continue;
      }
      if (goals.find((g) => g.pos.dist(pos0) < GOAL_RADIUS)) {
        pos = null;
        continue;
      }

      // 다른 entity와 되도록 안 겹치도록

      if (entities.find((e) => e.pos.dist(pos) < opts.ENTITY_SPAWN_SIZE)) {
        if (entity_collision_count-- > 0) {
          pos = null;
          continue;
        } else {
          console.log(`accepting spawn collision: ${config.name}`);
        }
      }
    }

    if (!geomContains(pos, area.polygon)) {
      console.error(`Failed to create correct spawn location.`);
    }

    return pos;
  }

  spawnEntity(config) {
    const { rng, tick, world } = this;

    let pos = this.spawnPos(config);
    const area = this.spawnareas[config.spawnarea];

    config = { ...config };
    if (typeof config.default_rule === 'object') {
      const r = { ...config.default_rule };
      if (!isNaN(r.goal)) {
        r.goal = this.goals.find((g) => g.idx === r.goal);
      }
      if (!isNaN(r.area)) {
        r.area = this.spawnareas.find((s) => s.idx === r.area);
      }
      config.default_rule = r;
    }

    const entity = new Entity(rng, tick, config);
    entity.seq = this.entities.length;
    entity.pos = pos;
    entity.gridpos = world.worldToGrid(pos);

    // heading pos
    entity.dir = config.dir ?? area?.areastate?.area?.spawnheading ?? rng.angle();
    entity.aimdir = entity.dir;
    if (config.vis_group) {
      entity.vis_group = config.vis_group;
    }

    // visibility grid. 이 안에 있는 적을 탐색할 수 있습니다.
    entity.grid_vis = new Float32Array(world.grid_count);
    entity.grid_vis.fill(0);

    // explore grid. navigation에 사용합니다.
    entity.grid_explore = new Float32Array(world.grid_count);
    entity.grid_explore.fill(0);

    this.entities.push(entity);
    return entity;
  }

  rebuildVisibility() {
    const { m, rng, world, obstacles } = this;

    function buildGeom(obstacles) {
      return GeomUnion.from_obstacles(m, rng, obstacles);
    }

    // obstacleFilterVisible = obstacleFilterVisible | obstacleDoorClosed
    function obstacleFilterVisibleBase(o) {
      if (['half', 'fence', 'door'].includes(o.ty)) { return false; }
      return true;
    }

    // obstacleFilterReachable = obstacleFilterReachableBase | obstcleDoorClosed
    function obstacleFilterReachableBase(o) {
      if (['door'].includes(o.ty)) { return false; }
      return true;
    }

    // base geometries
    if (!this.union_visible_base) {
      this.union_visible_base = buildGeom(obstacles.filter(obstacleFilterVisibleBase));
    }
    if (!this.union_reachable_base) {
      this.union_reachable_base = buildGeom(obstacles.filter(obstacleFilterReachableBase));
    }
    // buildRoute, used in goem.mjs
    if (!this.rn) {
      this.rn = new UnionPoly(m, world, buildGeom(obstacles.filter((o) => o.ty !== 'door')), false);
    }
    // riskdir
    if (!this.rt) {
      this.rt = new UnionPoly(m, world, buildGeom(obstacles.filter((o) => o.ty === 'full')));
    }

    // dynamic geometries
    if (this.union_doors) { this.union_doors.free(); }
    this.union_doors = buildGeom(obstacles.filter((o) => o.ty === 'door' && !o.doorstate.open));

    // visibility
    if (this.rv) { this.rv.free(); }
    this.rv = new UnionPoly(m, world, this.union_visible_base.union(this.union_doors));

    // navigation reachability, used in goem.mjs
    if (this.rr) { this.rr.free(); }
    this.rr = new UnionPoly(m, world, this.union_reachable_base.union(this.union_doors));

  }

  debugchoosepoint(_pos, itemFunc, _cmpFunc, includes_routepints) {
    const { routes } = this;
    const items = [];

    for (let i = 0; i < routes.nodes.length; i++) {
      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const query = itemFunc(node.pos, node.obstacle, i);
      items.push({ node, query });
    }

    return items;
  }

  choosepoints(pos, itemFunc, cmpFunc, includes_routepints) {
    const { routes } = this;
    const items = [];

    const tryAddItem = (item) => {
      for (let i = 0; i < items.length; i++) {
        const item0 = items[i];
        const cmp = cmpFunc(item0, item);
        if (cmp > 0) {
          items[i] = item;
          return;
        }
        if (cmp < 0) {
          return;
        }
      }
      items.push(item);
    };

    const dists = routePathfindAll(routes, pos, true);

    for (let i = 0; i < routes.nodes.length; i++) {
      // prune unreachable points
      if (dists[i] === -1) {
        continue;
      }

      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const item = itemFunc(node.pos, node.obstacle, i);
      if (!item) {
        continue;
      }

      tryAddItem(item);
    }

    return items;
  }

  choosepoint(pos, itemFunc, cmpFunc, includes_routepints) {
    const { routes } = this;
    let item = null;

    for (let i = 0; i < routes.nodes.length; i++) {
      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const item2 = itemFunc(node.pos, node.obstacle, i);
      if (!item2) {
        continue;
      }

      // return true if second argument is better than the first one
      if (!item || cmpFunc(item, item2) > 0) {
        item = item2;
      }
    }

    return item;
  }


  choosefirepoint(e1, f) {
    const { routes } = this;

    return (f ?? this.choosepoint.bind(this))(e1.pos, (pos, obstacle) => {
      // TODO: dist: dijkstra
      let dist = pos.dist(e1.pos);
      if (dist < opts.eps) {
        return null;
      }

      const cover = checkcover(pos, e1.pos, routes);
      if (cover === 3) {
        // blocked, cannot fire
        return null;
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      if (b.cover < a.cover || (b.cover === a.cover && b.dist < a.dist)) {
        return 1;
      }
      return 0;
    }, true);
  }

  // 적이 보이지 않은 상태에서, 방향을 정해서 경계
  chooseguardpoint(entity, _f) {
    const { routes } = this;

    const res = this.riskdirDry(entity);
    const { samples_ray, sample_count, selected_idx } = res;

    const ray = samples_ray[selected_idx];
    const dist = entity.pos.dist(ray);
    const dir = selected_idx * Math.PI * 2 / sample_count;

    const dists = routePathfindAll(routes, entity.pos, true);
    const target_pos = entity.pos.add(v2.fromdir(dir).mul(Math.max(10, dist - 10)));

    return this.choosepoint(entity.pos, (pos, obstacle, i) => {
      let dist = dists[i];
      // 상대방을 사격할 수 있는 위치 중 하나를 고릅니다.
      const cover_offence = checkcover(pos, target_pos, routes);
      if (cover_offence === 3) {
        // skip blocked
        return null;
      }

      let cover = checkcover(target_pos, pos, routes);

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) { return 1; }
      if (b.dist > a.dist) { return -1; }
      return 0;
    });
  }

  // 적이 보이지 않은 상태에서, 방향을 정해서 경계
  choosereorgpoint(entity) {
    const { routes, entities } = this;
    const dists = routePathfindVisible(routes, entity.pos, entity.pos);

    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return this.choosepoint(entity.pos, (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist === -1) {
        return null;
      }

      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let cover = 1;
      if (obstacle.ty === 'half') {
        cover = 2;
      }
      if (entity.leader && entity.leader.state !== 'dead') {
        dist += entity.leader.pos.dist(pos);
      }
      // 낮을수록 좋음
      let score = (3 - cover) * 10 + dist;

      return {
        cover,
        dist,
        score,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 현재 위치에서 가까울수록 좋음
      if (b.score < a.score) { return 1; }
      if (b.score > a.score) { return -1; }
      return 0;
    });
  }

  // shooter on e1, target on e0, find coverpoint of *e0*
  // find coverpoint, accounting all enemies, able to fire to aimtarget
  choosecoverpoint(entity, max_dist) {
    const { routes } = this;

    const e1 = entity.aimtarget;
    const e1_pos = e1.pos;

    // 공격받고 있을 때 엄폐를 풀 수 있는지 여부를 통제
    const offenced = this.entityOffenced(entity);
    if (offenced) {
      const route_node = routeNodeFromPos(routes, entity.pos);
      if (route_node && route_node.is_coverpoint && !entity.allow_uncover_on_fire && !entity.allow_uncover_on_fire) {
        return null;
      }
    }

    const dists = routePathfindAll(routes, entity.pos);
    let cp = this.choosecoverpoint0(entity, max_dist, e1_pos, dists);

    let edges = null;
    let cp_edge = null;
    if (entity.allow_cover_edge) {
      edges = coverEdges(routes, entity.pos, e1.pos).filter((e) => {
        const { p_from, p_to, ub } = e;
        const pos = v2.lerp(p_from.pos, p_to.pos, ub);
        e.pos = pos;
        return e1.pos.dist(pos) < max_dist;
      });

      edges.sort((e0, e1) => {
        return e0.dist - e1.dist;
      });
      cp_edge = edges[0] ?? null;

      if (edges.length > 0) {
        const edge = edges[0];
        const { dist, pos } = edge;
        cp_edge = {
          cover: 2,
          edge,
          dist,
          pos,
        };
      }
    }

    if (cp && cp_edge) {
      // compare two candidates
      function score(cp) {
        return cp.cover - cp.dist / 100;
      }

      if (score(cp_edge) > score(cp)) {
        cp = cp_edge;
      }
      cp.edges = edges;
    }

    return cp;
  }

  entitySetMobilityLevel(entity, level) {
    /*
    // mobility level
    //  - 0: do not move, in any case
    //  - 1: do not move from coverpoint, under fire, riskdir on roaming
    //  - 2: as-is
    //  - 3: do not wait door breaching
    this.mobility_level = 2;
    */

    entity.mobility_level = level;

    // entity.allow_crawl = level < 3;
    entity.allow_door_wait = level < 3;
    // entity.use_riskdir = level < 3;
    entity.riskdir_use_visibility_grid = level < 2;
    entity.allow_uncover_on_fire = level > 2;

    entity.allow_follow_leader = level < 3;
    entity.allow_wait_follower = level < 3;

    entity.allow_fire_control = level < 2;

    switch (level) {
      case 1:
        entity.movestate = 'low';
        entity.aim_samples_fire_thres = 0.5;
        break;
      case 2:
        entity.movestate = 'walk';
        entity.aim_samples_fire_thres = 0.2;
        break;
      case 3:
        entity.movestate = 'run';
        entity.aim_samples_fire_thres = 0.0;
        break;
      default:
        throw new Error(`unknown entitySetMobilityLevel: ${level}`);
    }
  }

  entitySetDoorEntry(entity, value) {
    entity.door_entry = value;
  }

  static risk_cmp(r0, r1) {
    // return positive number if r0 is riskier than r1
    for (let i = 0; i < r0.length; i++) {
      if (r0[i] !== r1[i]) {
        return r0[i] - r1[i];
      }
    }
    return 0;
  }

  entityRiskEnemies(entity) {
    const { entities, world } = this;

    // calculate current risk
    const ot = opponentTeam(entity.team);
    return entities.filter((e) => {
      if (e === entity || !ot.includes(e.team) || e.state === 'dead') {
        return false;
      }
      if (entity.aimtarget === e) {
        return true;
      }
      if (entity.use_visibility) {
        const visible = entity.grid_vis[world.idx(e.gridpos)] > entity.vis_thres;
        if (!visible) {
          return false;
        }
      }
      return true;
    });
  }

  entityRisk(entity, pos) {
    const { routes } = this;
    if (!pos) {
      pos = entity.pos;
    }

    // calculate current risk
    const enemies = this.entityRiskEnemies(entity);
    const covers = [0, 0, 0, 0];
    for (const enemy of enemies) {
      let cover = checkcover(enemy.pos, pos, routes);
      covers[cover] += 1;
    }
    return covers;
  }

  choosecoverpoint0(entity, max_dist, e1_pos, dists) {
    const { entities, routes } = this;

    const enemies = this.entityRiskEnemies(entity);
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    const area = entity.waypoint_rule.area;


    // least risk position
    let ally_risks = [];
    if (!isNaN(entity.risk_rank)) {
      ally_risks = allies.map((e) => this.entityRisk(e));
    }

    let offenced = this.entityOffenced(entity);

    const itemFn = (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist === -1) {
        return null;
      }

      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let dist0 = pos.dist(e1_pos);
      if (dist0 > max_dist) {
        return null;
      }

      // area가 지정된 경우 해당 area만 탐색
      if (area && !geomContains(pos, area.polygon)) {
        return null;
      }

      if (entity.recent_visits.includes(i)) {
        return null;
      }

      // 상대방을 사격할 수 있는 위치 중 하나를 고릅니다.
      const cover_offence = checkcover(pos, e1_pos, routes);
      if (cover_offence === 3) {
        // skip blocked
        return null;
      }

      let cover = 3;
      for (const enemy of enemies) {
        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }
      let penalty = 0;
      if (!offenced) {
        penalty = this.entityRangePenalty(entity, dist0) ? 1 : 0;
      }

      // 위험도. 높을수록 안전함
      let risk_rank_delta = 0;
      if (!isNaN(entity.risk_rank)) {
        const risk = this.entityRisk(entity, pos);

        let risk_rank = 0;
        for (const ally_risk of ally_risks) {
          if (Simulator.risk_cmp(ally_risk, risk) > 0) {
            risk_rank += 1;
          }
        }
        risk_rank_delta = Math.abs(risk_rank - entity.risk_rank);
      }

      return {
        risk_rank_delta,
        cover,
        penalty,
        dist,
        pos,
        obstacle,
      };
    };

    const cmpFn = (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return 1;
      }
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // 낮을수록 좋음
      // // 20240402 임시로 비활성화: 세이브데이터...
      /*
      if (b.penalty < a.penalty) { return 1; }
      if (b.penalty > a.penalty) { return -1; }
      */

      // risk_rank_delta: 낮을수록 좋음
      if (b.risk_rank_delta < a.risk_rank_delta) { return 1; }
      if (b.risk_rank_delta > a.risk_rank_delta) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) { return 1; }
      if (b.dist > a.dist) { return -1; }
      return 0;
    };

    /*
    const candidates = this.choosepoints(entity.pos, itemFn, (a, b) => {
      // 작을수록 좋음
      const c0 = a.risk_rank_delta - b.risk_rank_delta;
      // 클수록 좋음
      const c1 = b.cover - a.cover;
      // 작을수록 좋음
      const c2 = a.dist - b.dist;

      if (c0 >= 0 && c1 >= 0 && c2 >= 0) {
        return 1;
      }
      else if (c0 <= 0 && c1 <= 0 && c2 <= 0) {
        return -1;
      }
      return 0;
    });
    candidates.sort(cmpFn);
    */

    return this.choosepoint(entity.pos, itemFn, cmpFn);
  }

  choosecoverholdpoint(entity, max_dist) {
    const { routes } = this;
    const dists = routePathfindAll(routes, entity.pos);
    return this.choosecoverholdpoint0(entity, max_dist, dists);
  }
  choosecoverholdpoint0(entity, max_dist, dists) {
    const { entities, routes } = this;

    const enemies = this.entityRiskEnemies(entity);
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    const area = entity.waypoint_rule.area;

    // least risk position
    let ally_risks = [];
    if (!isNaN(entity.risk_rank)) {
      ally_risks = allies.map((e) => this.entityRisk(e));
    }

    let offenced = this.entityOffenced(entity);

    const itemFn = (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist === -1) {
        return null;
      }

      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let dist0 = pos.dist(obstacle.pos);
      if (dist0 > max_dist) {
        return null;
      }

      // area가 지정된 경우 해당 area만 탐색
      if (area && !geomContains(pos, area.polygon)) {
        return null;
      }

      if (entity.recent_visits.includes(i)) {
        return null;
      }

      return {
        dist,
        pos,
        obstacle,
      };
    };

    const cmpFn = (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return 1;
      }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) { return 1; }
      if (b.dist > a.dist) { return -1; }
      return 0;
    };

    return this.choosepoint(entity.pos, itemFn, cmpFn);
  }

  choosehidepoint(entity, f) {
    const { tick, entities, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    const dists = routePathfindAll(routes, entity.pos);

    const throwables = this.throwables.filter((t) => !t.blast_timer.expired(tick));

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      if (dists[i] === -1) {
        return null;
      }

      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      const dist = pos.dist(entity.pos);
      let cover = 3;
      for (const enemy of enemies) {
        // 상대방의 firearm_range 밖에 있는 경우 무시
        const dist = enemy.pos.dist(pos);
        if (dist > enemy.firearm_range) {
          continue;
        }

        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      for (const t of throwables) {
        if (t.target_pos.dist(pos) > t.max_radius) {
          continue;
        }
        let cover_t = checkcover(t.target_pos, pos, routes);
        cover = Math.min(cover, cover_t);
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return 1;
      }
      return -1;
    });
  }

  choosenearestpoint(entity, f) {
    const { routes } = this;
    const { pos } = entity;

    const dists = routePathfindAll(routes, pos, true);

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist < 0) {
        return null;
      }

      return {
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return 1;
      }
      return -1;
    });
  }

  chooserescuepoint(entity, target, radius, f) {
    const dists = routePathfindAll(this.routes, entity.pos, false);

    const p = (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      if (target.pos.dist(pos) >= radius) {
        return null;
      }
      if (obstructed(target.pos, pos, this.obstacles)) {
        return null;
      }

      const p = {
        score: dists[i],
        pos,
        obstacle,
      };
      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });
    return p;
  }

  explorepointScoreFn(entity) {
    const { world, routes } = this;

    const area = entity.waypoint_rule.area;
    const initiator = entity.waypoint_rule.initiator;

    let dists = null;
    if (initiator) {
      dists = routePathfindAll(routes, initiator.pos, true);
    } else {
      dists = routePathfindAll(routes, entity.pos, true);
    }

    const range = this.entityVisRange(entity);
    const areaValid = (p) => {
      if (!area) {
        return true;
      }
      if (area.areastate.structures) {
        for (const s of area.areastate.structures) {
          if (geomContains(p, s.shape.polygon)) {
            return true;
          }
        }
        return false;
      } else {
        return geomContains(p, area.polygon);
      }
    };

    return (pos, obstacle, i) => {
      // area가 지정된 경우 해당 area만 탐색
      if (!areaValid(pos)) {
        return null;
      }

      if (isNaN(i)) {
        const node = routeNodeFromPos(routes, pos);
        if (isNaN(node?.idx)) {
          return null;
        }
        i = node.idx;
      }

      const dist = dists[i];
      if (dist < 0) {
        // unreachable
        return null;
      }

      let cover = 1;
      onReachableGridWasm(this.world, this.rv, pos, range, (idx) => {
        // area가 있는 경우, 해당 area의 탐색 여부만 확인합니다.
        if (area) {
          const world_pos = this.world.gridIdxToWorld(idx);
          if (!geomContains(world_pos, area.polygon)) {
            return false;
          }
        }
        cover += 1 - entity.grid_explore[idx];
        return true;
      });

      const normcover = cover / (world.width * world.height / Math.pow(opts.GRID_SIZE, 2));
      const normdist = Math.max(30, dist) / Math.sqrt(world.width * world.height);

      let score = 0;
      if (normdist > 0) {
        score = normcover / normdist;
      }

      if (entity.recent_visits.includes(i)) {
        score = -1000;
      }

      const p = {
        cover,
        dist,
        normcover,
        normdist: score,
        score,
        pos,
        obstacle,
      };
      return p;
    };
  }

  chooseexplorepoint(entity, scoreFn, f) {
    const { routes, entities } = this;

    const { leader } = entity;
    let dists_leader = null;
    if (entity.allow_follow_leader && leader && leader.vis && leader.state !== 'dead') {
      dists_leader = routePathfindAll(routes, leader.waypoint?.pos ?? leader.pos, true);

      if (this.entityWaypointDoor(leader)) {
        return leader.waypoint.cp;
      }
    }

    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    let maxcover = null;
    const p = (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (!leader && allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      const p = scoreFn(pos, obstacle, i);
      if (!p) {
        return null;
      }
      if (!maxcover || maxcover.cover < p.cover) {
        maxcover = p;
      }

      // leader로부터 line of sight가 확보되는 곳으로 이동해야 합니다.
      if (dists_leader) {
        if (dists_leader?.[i] > opts.FOLLOWER_ALLOWED_DIST) {
          p.score = -1000;
        }
      }

      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });

    if (p === null) {
      console.error('no explore point', entity.name);
      return null;
    }

    // HACK
    p.maxcover = maxcover;
    return p;
  }

  choosecapturepoint(entity, goal, f) {
    const { entities, goals } = this;
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      /*
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }
      if (obstacle.goalstate.owner >= 0) {
        return null;
      }
      */
      if (!goals.includes(obstacle)) {
        return null;
      }
      if (goal && obstacle && goal !== obstacle) {
        return null;
      }

      const dist = pos.dist(entity.pos);

      return {
        dist,
        score: -dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });
  }

  // goal을 지키는 위치를 찾습니다.
  choosecovergoalpoint(entity, goal, f) {
    const { entities, goals, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    // const goals_in_danger = enemies.map((e) => e.waypoint.obstacle);

    // p에서 goal을 얼마나 지킬 수 있는지.
    function goalscore(p, goal) {
      // TODO: CHEAT: 현재 적이 점령하려고 시도하는 goal만 지킵니다.
      /*
      if (!goals_in_danger.includes(goal)) {
        return 0;
      }
      */

      // 점수가 낮을수록 안 좋음. 점령된 goal을 지키지 않습니다.
      if (goal.goalstate.owner >= 0) {
        return 0;
      }

      const scores = goal.coverpoints.map(({ pos: gp }) => {
        const dist = gp.dist(p);
        if (dist >= entity.firearm_range) {
          // 거리가 멀어서 사격할 수 없는 경우
          return 0;
        }
        return 1;
      });

      return scores.reduce((a, b) => a + b, 0);
    }

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle) => {
      // goal에서 지키지 않습니다
      if (goals.includes(obstacle)) {
        return null;
      }
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      // score가 높을수록 coverage가 작음
      let score_goal = 0;
      if (goal) {
        score_goal = goalscore(pos, goal);
      } else {
        score_goal = goals.map((goal) => goalscore(pos, goal)).reduce((a, b) => a + b, 0);
      }
      score_goal = Math.min(20, score_goal);

      // 현재 위치에서 가까운 곳을 선호합니다.
      const dist = pos.dist(entity.pos);
      const score_dist = -dist / 200;

      // 현재 적의 위치에서 노출되지 않는 곳을 선호합니다.
      const score_enemy = enemies.map((e) => {
        return checkcover(e.pos, pos, routes) > 1 ? 1 : 0;
      }).reduce((a, b) => a + b, 0) * 4;

      const score = score_goal + score_dist + score_enemy;

      return {
        score_goal,
        score_dist,
        score_enemy,
        score,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // score가 큰 위치를 찾습니다.
      if (b.score > a.score) {
        return 1;
      }

      return -1;
    });
  }

  entityWaypointDoorDir(entity) {
    const { routes } = this;
    const path = entity.waypoint?.path;
    if (!path) {
      return null;
    }

    for (let i = 0; i < path.length - 1; i++) {
      const idx = path[i].idx;
      const idx1 = path[i + 1].idx;
      if (idx === -1 || idx1 === -1) {
        continue;
      }

      const node = routes.nodes[idx];
      const node1 = routes.nodes[idx1];
      if (node.is_coverpoint && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open) {
        const { pos, obstacle } = node;
        const dir = node1.pos.sub(node.pos).norm();
        const ray = raycastWasm(this.rv, node1.pos, [dir])[0].sub(pos);
        return {
          obstacle,
          pos,
          dir,
          ray,
        };
      }
    }
    return null;
  }

  entityWaypointDoor(entity) {
    const path = entity.waypoint?.path;
    if (!path) {
      return null;
    }

    for (const s of path) {
      const { idx } = s;
      if (idx === -1) {
        continue;
      }
      if (this.doorPathNode(idx)) {
        return idx;
      }
    }
    return null;
  }

  doorPathNode(idx) {
    const { routes } = this;
    if (!(idx >= 0)) {
      return false;
    }
    const node = routes.nodes[idx];
    if (!node) {
      return false;
    }
    return !node.is_coverpoint && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open;
  }

  doorStopNode(entity, idx) {
    const { routes } = this;
    if (!(idx >= 0)) {
      return false;
    }
    const node = routes.nodes[idx];
    if (!node) {
      return false;
    }
    if (node.obstacle.ty !== 'door' || node.obstacle.doorstate.open) {
      return false;
    }

    return !node.is_coverpoint;
  }

  nodeShouldStop(entity, node_idx) {
    if (this.doorShouldStop(entity, node_idx, true)) {
      return true;
    }
    if (this.leaderShouldStop(entity, node_idx)) {
      return true;
    }
    return false;
  }

  leaderShouldStop(entity, _node_idx) {
    if (!entity.allow_wait_follower) {
      return false;
    }

    const { entities } = this;
    const followers = entities.filter((e) => e.leader === entity && e.state !== 'dead');
    for (let follower of followers) {
      if (follower.movespeed === 0) {
        continue;
      }
      if (follower.pos.dist(entity.pos) > opts.LEADER_WAIT_DIST) {
        return true;
      }
    }
    return false;
  }

  doorShouldStop(entity, node_idx, visited) {
    const { routes, tick } = this;

    const node = routes.nodes[node_idx];
    const o = node.obstacle;

    // policy가 끝났는지 확인
    let policy = this.door_policy.find((p) => p.door === o);
    if (policy) {
      if (policy.throwable_ty && !policy.throwable) {
        // TODO: HACK: 나중에 고쳐야 함
        if (visited) {
          this.navigateOnVisitNode(entity, node_idx);
        }
        return true;
      } else if (!o.doorstate.opener) {
        o.doorstate.opener = entity;
        entity.targetdoor = o;
      }

      if (this.throwables.find((t) => !t.blast_timer.expired(tick) && t.throwable === policy.throwable)) {
        // 투척물을 던졌으면 폭발까지 기다렸다가 진입함
        return true;
      }
      return false;
    }

    if (!this.doorStopNode(entity, node_idx)) {
      return false;
    }

    if (o.doorstate.open) {
      return false;
    }

    // 빠른 진입
    if (!entity.allow_door_wait) {
      return false;
    }

    // 같은 문에서 진입을 기다리고 있을 때
    const breaching_rule = entity.rules.find((e) => e.ty === 'breaching');
    const door_name = o.doorstate.name;
    const waitings = breaching_rule ? [{ entity: get_breacher(breaching_rule, door_name), node: breaching_rule.common.dooredge, }] : this.doorWaitings(entity, o);
    if (!breaching_rule?.common?.door_entry) {
      for (const { entity: waiting, node } of waitings) {
        if (entity === waiting) {
          continue;
        }
        // 도착할 때 까지 기다림
        if (!waiting.pos.eq(node.pos)) {
          return true;
        }
      }
    } else {
      if (!breaching_rule.common.all_allies_ready && breaching_rule.breaching_ready) {
        return true;
      }
      if (!breaching_rule.common.all_allies_ready && !breaching_rule.breaching_ready) {
        return false;
      }
    }

    // check if there's a pending prompt with given door
    const p = this.pending_prompts.find((p) => p.door === o);
    if (p) {
      return true;
    }

    if (!visited) {
      return true;
    }

    const throwables = _.countBy(_.flatten(waitings.map((item) => item.entity.controls['throwable'] && item.entity.throwables ? item.entity.throwables.map((t) => t.throwable_name) : [])));
    const attachables = _.countBy(_.flatten(waitings.map((item) => item.entity.controls['attachable'][door_name] && item.entity.attachables ? item.entity.attachables.map((t) => t.throwable_name) : [])));

    // 던질 물건이 없는 경우, 바로 진입합니다.
    if ((Object.keys(throwables).length === 0 || !opts.ALLOW_THROWABLE_ON_BREACHING) && Object.keys(attachables).length === 0) {
      return false;
    }

    const prompt_duration = opts.PROMPT_THROWABLE_DURATION;
    const prompt_options = [
      {
        title: 'proceed', actions: [
          { action: 'open_door', actiondoorpolicy: { door: o } }
        ]
      }
    ];

    if (opts.ALLOW_THROWABLE_ON_BREACHING) {
      for (const throwable_ty of Object.keys(throwables)) {
        const count = throwables[throwable_ty];
        prompt_options.push(
          {
            title: `with ${throwable_ty} (${count})`, actions:
              [{ action: 'open_door', actiondoorpolicy: { door: o, throwable_ty, } }]
          }
        );
      }
    }

    for (const throwable_ty of Object.keys(attachables)) {
      const count = attachables[throwable_ty];
      prompt_options.push(
        {
          title: `with ${throwable_ty}, a (${count})`, actions:
            [{ action: 'open_door', actiondoorpolicy: { door: o, throwable_ty, attach: true, } }]
        }
      );
      entity.attachTick = new TickTimer(tick, 1);
      entity.targetdoor = o;
      const doordir = this.entityWaypointDoorDir(entity);
      const { pos, dir } = doordir;
      this.InvokeCameraEventBreaching(pos.add(dir.mul(50)), 10);
    }

    let default_idx = 0;
    if (prompt_options.length > 1 && entity.demo_trailer_throw) {
      default_idx = 1;
    }
    if (opts.ALLOW_THROWABLE_ON_BREACHING) {
      if (entity.controls['throwable']) {
        default_idx = 1;
      }
    }
    if (entity.controls['attachable'][door_name]) {
      default_idx = 1;
    }
    prompt_options[default_idx].default = true;

    this.pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(prompt_duration) + this.tick,
      queue_at: this.tick,
      prompt_options,
      door: o,
    });

    return true;
  }

  doorWaitings(entity, o) {
    const { entities, routes } = this;
    const obstaclestop = (o0, o1) => {
      if (o0 === o1) {
        return true;
      }
      const g0 = o0.doorstate.group;
      const g1 = o1?.doorstate?.group;
      if (g0 && g1 && g0 === g1) {
        return true;
      }
      return false;
    }

    return entities.map((e) => {
      if (e.team !== entity.team || e.state === 'dead' || !e.waypoint?.path) {
        return null;
      }
      for (const s of e.waypoint.path) {
        const { idx } = s;
        if (idx === -1) {
          continue;
        }
        const node = routes.nodes[idx];
        if (!obstaclestop(o, node?.obstacle)) {
          continue;
        }

        if (this.doorStopNode(e, idx)) {
          return { entity: e, node };
        }
      }
      return null;
    }).filter((item) => item !== null);
  }

  maybePopGroupRule(entity, cond) {
    entity.mark_rule(cond);
    if (!cond) {
      return;
    }

    const { ty } = entity.waypoint_rule;
    const groupEntities = this.entities.filter((e) => {
      if (e.team !== entity.team || e.state === 'dead' || e.ty === 'vip') {
        return false;
      }
      if (e.rules.find((r) => r.ty === ty)) {
        return true;
      }
      return false;
    });
    if (groupEntities.find((e) => e.waypoint_rule.ty !== ty || !e.rule_marked)) {
      return;
    }

    this.popGroupRule(entity);
  }

  popGroupRule(entity) {
    const { ty, prompt_duration, prompt_options, mission_idx } = entity.waypoint_rule;
    const groupEntities = this.entities.filter((e) => {
      if (e.team !== entity.team || e.state === 'dead' || e.ty === 'vip') {
        return false;
      }
      if (e.rules.find((r) => r.ty === ty)) {
        return true;
      }
      return false;
    });
    if (groupEntities.find((e) => e.waypoint_rule.ty !== ty)) {
      return;
    }

    for (const e of groupEntities) {
      e.pop_rule();
    }

    if (prompt_options) {
      this.pending_prompts.push({
        area: entity.spawnarea,
        expire_at: this.ticksFromSec(prompt_duration) + this.tick,
        queue_at: this.tick,
        prompt_options,
      });
    }

    // rule에 연관된 prompt가 있었던 경우 삭제합니다.
    if (isNaN(mission_idx)) {
      return;
    }
    this.pending_prompts = this.pending_prompts.filter((p) => p.mission_idx !== mission_idx);
  }

  maybeRescueVIP(entity) {
    const { journal, tick } = this;
    // TODO: check if VIP is discovered
    if (entity.waypoint_rule.ty !== 'explore') {
      return;
    }

    // TODO: 못 찾거나 이미 구출됨
    const target = this.entityFoundVIP(entity);
    if (!target || target.team === 0) {
      return;
    }

    // 이미 prompt가 나옴
    if (this.pending_prompts.find((p) => p.rescue_target === target)) {
      return;
    }
    if (this.entities.find((e) => e.waypoint_rule.rescue_target === target)) {
      return;
    }

    journal.push({ tick, ty: 'discover', entity, target });
    this.pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(3600) + tick,
      queue_at: tick,
      rescue_target: target,
      prompt_options: [
        {
          title: 'rescue', default: true, actions: [
            { action: 'rescue', rescue_target: target }
          ]
        }
      ],
    });
  }

  entityThrow(entity, throwable, target_pos, attach = false) {
    const { tick } = this;
    const moveDuration = this.ticksFromSec(throwable.throw_delay);
    if (entity.perk_grenadier_shorter_blast) {
      this.journal.push({ tick, ty: 'perk', perk: 'perk_grenadier_shorter_blast', entity, })
    }
    let blast_delay = entity.perk_grenadier_shorter_blast ? throwable.blast_delay - 1.0 : throwable.blast_delay;
    // 수류탄 쿠킹 퍽
    if (entity.perk2_common_intelligence_grenade_cooking) {
      blast_delay = entity.perk2_common_intelligence_grenade_cooking.blast_delay_seconds;
      blast_delay = Math.max(blast_delay, throwable.throw_delay); // blast delay should not be less than move delay
    }

    let blastDuration = this.ticksFromSec(blast_delay);
    if (this.controlGet(entity.spawnarea, 'throwable_cooking')) {
      blastDuration = Math.min(blastDuration, moveDuration);
    }

    const t = {
      throwable,
      max_radius: throwable.blasts.reduce((a, b) => Math.max(a, b.blast_radius), 0),
      move_timer: new TickTimer(tick, moveDuration),
      blast_timer: new TickTimer(tick + moveDuration, blastDuration),
      entity,
      pos: entity.pos,
      start_pos: entity.pos,
      target_pos,
      blastareas: [],
      attach,
    };

    this.throwables.push(t);

    this.onTriggerEntityThrow(entity, attach);
    return t;
  }

  throwableUpdate(throwable) {
    const { tick } = this

    if (!throwable.move_timer.expired(tick)) {
      const progress = throwable.move_timer.progress(tick);
      throwable.pos = v2.lerp(throwable.start_pos, throwable.target_pos, progress);
    } else if (throwable.move_timer.expired_exact(tick)) {
      this.InvokeCameraEventThrow(throwable.pos);
    }

    if (throwable.blast_timer.expired_exact(tick)) {
      this.entityHandleThrowable(throwable, 'throwable');
    }
  }

  entityHandleThrowable(throwable0, ty) {
    const { world, tick } = this;
    const { entity, throwable, pos } = throwable0;

    for (const blast of throwable.blasts) {
      let { blast_radius } = blast;
      const { blast_ty, blast_expose_ty } = blast;
      let vismodel = null;
      switch (blast_expose_ty) {
        case 'full':
          vismodel = this.rr;
          break;
        case 'half':
          vismodel = this.rv;
          break;
        default:
          throw new Error(`unknown blast_ty=${blast_expose_ty}`);
      }

      if (entity.perk_grenadier_effect_range) {
        blast_radius *= 1.5;
      }
      if (ty === 'throwable' && entity.perk2_common_vision_orbital_mechanics) {
        blast_radius *= entity.perk2_common_vision_orbital_mechanics.blast_radius_mult;
      }

      const entities = [];
      const vis = vismodel.triangulated.visibility(pos.x, pos.y, false);
      vis.limit(blast_radius);

      const ba = {
        pos,
        tick,
        expire_at: tick + this.ticksFromSec(3),
        vis,
        entities,
      };

      if (blast_ty === 'visibility') {
        // agent가 아니라 grid에 영향을 줌.
        onReachableGridWasmOp(this.world, vis, entity.grid_vis, 0, 1);
        onReachableGridWasmOp(this.world, vis, entity.grid_explore, 0, 1);
      } else {
        let entities_reachable = this.reachableEntities(vis);
        // throwable does not hit allies
        if (!opts.EXP_THROWABLE_HIT_ALLIES) {
          entities_reachable = entities_reachable.filter((e) => e.team != entity.team);
        }
        const { blast_effect_ty, blast_effect_duration } = blast;

        let effect_multiplier = 1;
        let additional_damage = 0;
        if (entity.perk_grenadier_effect_amount) {
          this.journal.push({ tick, ty: 'perk', perk: 'perk_grenadier_effect_amount', entity, })
          effect_multiplier = 1.25;
        }
        if (entity.perk2_breacher_explosive_science && throwable.attach) {
          this.journal.push({ tick, ty: 'perk', perk: 'perk2_breacher_explosive_science', entity, })
          additional_damage += entity.perk2_breacher_explosive_science.additional_atk_damage_amount;
        }

        for (const target of entities_reachable) {

          switch (blast_ty) {
            case 'damage': {
              //투척물에서는 penetrate 수치가 어떻게 되는가?
              if (entity.perk2_breacher_shield_disable && throwable.is_breaching) {
                entity.perk2_breacher_shield_disable.is_breaching = true;
              }

              const damage = 0;
              const penetrate = blast.blast_damage * effect_multiplier + additional_damage;

              const trail = {
                ty: 'throwable',
                pos,
                target_pos: target.pos,
                dir: target.pos.sub(pos).dir(),
                len: target.pos.dist(pos),
                source: entity,
                target,
                hit: true,
                threat_max: 0, // 수류탄의 경우 어떤 값으로 치리해야되는가?
                valid: true,
                kill: false,
                damage,
                damage_life: 0,
                penetrate,
                crit: false,
                penalty: false,
                invis: true,
              };

              const projectile_scheduler_data = {
                expires_at: tick,
                trail,
              };

              projectile_scheduler_data.fire_scheduler_data = {
                ty: 'throwable',
                throwable,
                expires_at: tick,
                entity,
                target,
                hit: true,
                prob: 1,
                kill: false,
                damage,
                penetrate,
                crit: false,
                trail,
              };

              this.projectile_scheduler_queue.push(projectile_scheduler_data);
              entities.push(target);
              break;
            }
            case 'effect-frag':
            case 'effect': {
              const expire_at = tick + this.ticksFromSec(blast_effect_duration * effect_multiplier);
              const found = target.effects.find((e) => e.effect_ty === blast_effect_ty);
              if (found) {
                found.expire_at = Math.max(found.expire_at, expire_at);
              } else {
                target.effects.push({
                  effect_ty: blast_effect_ty,
                  expire_at,
                });
                entities.push(target);
              }
              this.journal.push({ tick, ty: 'throw_effect', entity, throwable, target, effect_ty: blast_effect_ty })
              break;
            }
            case 'smoke':
              break;
            default:
              throw new Error(`unknown blast_ty=${blast_ty}`);
          }
        }

        if (blast_ty === 'smoke') {
          ba.expire_at = tick + this.ticksFromSec(blast_effect_duration);
          ba.effect_ty = 'smoke';
        }
      }

      throwable0.blastareas.push(ba);
      this.blastareas.push(ba);
    }
  }

  breachingOpenDoor(entity, breaching_rule, delay = 0) {
    if (!breaching_rule) {
      return;
    }

    if (entity.door_entry && entity.perk2_common_intelligence_dynamic_entry) {
      this.onTriggerEntityDynamicEntry_OpenDoor(entity);
    }
    breaching_rule.common.door_open_at = this.tick + delay;
    for (const member of breaching_rule.common.members) {
      if (member.state !== 'dead') {
        const member_rule = member.rules.find((e) => e.ty === 'breaching');
        member.breachTick = new TickTimer(this.tick, delay + member_rule.enter_delay);
      }
    }
  }

  navigateOnVisitNode(entity, node_idx) {
    if (!(node_idx >= 0)) {
      return false;
    }

    const { routes, rng, tick } = this;
    const node = routes.nodes[node_idx];
    const o = node.obstacle;

    if (this.doorStopNode(entity, node_idx)) {
      const breaching = entity.waypoint_rule.ty === 'breaching';
      // Do not open door before breaching ready
      if (breaching && !entity.waypoint_rule.breaching_ready) {
        return false;
      }
      const policy = this.door_policy.find((p) => p.door === o);

      // TODO: 문 열때 동작
      const doordir = this.entityWaypointDoorDir(entity);
      if (policy?.throwable_ty && doordir && doordir.obstacle === node.obstacle) {
        const { pos, dir, ray } = doordir;
        const throwdirmargin = rng.integer(-5, 5) * Math.PI / 180;
        const throwdir = new v2(dir.x * Math.cos(throwdirmargin) - dir.y * Math.sin(throwdirmargin), dir.x * Math.sin(throwdirmargin) + dir.y * Math.cos(throwdirmargin));
        const { throwable_ty } = policy;
        const throwables = (policy.attach ? entity.attachables : entity.throwables);
        const selected = throwables?.find((t) => t.throwable_name === throwable_ty);
        if (!selected) {
          // TODO: policy에 던지라고 했는데 나는 없음. 누군가 가지고 있기를 기도하자
          return false;
        }

        policy.throwable = selected;

        if (!policy.attach) {
          entity.throwables = remove_item_unique(throwables, selected);
          // 문을 열고 안으로 던집니다.
          const dist = Math.min(ray.len() * 0.8, selected.throw_range / 2);
          const p1 = pos.add(throwdir.mul(dist));

          this.journal.push({ tick, ty: 'throw_door', entity: entity, throwable: selected });
          this.entityThrow(entity, selected, p1);
        } else {
          entity.attachables = remove_item_unique(throwables, selected);
          selected.attach = true;
          // 문 바로 앞에서 터집니다.
          const dist = 50;
          const p1 = pos.add(throwdir.mul(dist));

          const t = this.entityThrow(entity, selected, p1, true);
          t.move_timer = new TickTimer(tick - 1, 0);
          t.blast_timer = new TickTimer(tick - 1, 0);
          t.pos = t.target_pos;

          this.entityHandleThrowable(t, 'attachable');
        }
        // MEMO: door open
        o.doorstate.open = true;
        o.doorstate.opener = entity;
        o.doorstate.exploded = policy.attach;
        entity.targetdoor = o;
        this.rebuildVisibility();
        if (breaching) {
          this.breachingOpenDoor(entity, entity.waypoint_rule, this.ticksFromSec(1));
        }
      } else {
        o.doorstate.open = true;
        o.doorstate.opener = entity;
        entity.targetdoor = o;
        this.rebuildVisibility();
        if (breaching) {
          this.breachingOpenDoor(entity, entity.waypoint_rule);
        }
      }
    }
  }

  entityRoutable(entity) {
    const { tick } = this;

    if (entity.waypoint_rule.ty === 'reorg') {
      return false;
    }

    // reroute 주기를 초과한 경우
    return entity.lastRouteTick.expired(tick);
  }

  entityNextWaypointFollow(entity) {
    if (!entity.waypoint) {
      return;
    }

    // 이전 route가 있을 때, 이 route를 일단 계속 따라갑니다.
    const wp = entity.waypoint;
    if (wp.pos.eq(entity.pos) && wp.path) {
      let path = wp.path.slice();
      while (path.length > 0 && !entity.pos.eq(path[0].pos)) {
        path = path.slice(1);
      }

      // path[0]은 현재 위치, 혹은 현재 위치까지의 path입니다.
      // path[1]은 다음 위치, 현재 위치에서 다음 위치까지의 경로입니다.

      let breaching = wp.breaching ?? false;
      if (path.length > 0) {
        // 현재 도착점에 대해
        const cur = path[0];
        const cur_idx = cur?.idx;
        // TODO: 문열기 정리하기
        if (cur_idx >= 0 && this.nodeShouldStop(entity, cur_idx)) {
          return;
        }
        const { routes } = this;

        if (wp.persistent && !wp.breaching && routes.nodes[cur_idx]?.obstacle?.ty === 'door') {
          breaching = true;
          // entity.breachTick = new TickTimer(this.tick, this.ticksFromSec(0.5 * wp.breach_idx));
        }

        this.navigateOnVisitNode(entity, cur_idx);
      }

      if (path.length > 1) {
        // 다음 목적지가 있는 경우 마저 이동합니다.
        const dest = path[1];
        entity.waypoint = {
          pos: dest.pos,
          cp: wp.cp,
          obstacle: wp.obstacle,
          path,
          breaching,
          breach_idx: wp.breach_idx,
          persistent: wp.persistent,
        };
      }
      // waypoint의 끝에 도착했을 때는 항상 reroute?
    }
  }

  entityNextWaypoint0(entity) {
    const { entities, routes } = this;
    const rule = entity.waypoint_rule;
    const allies = entities.filter((e) => e.team === entity.team && e !== entity && e.state !== 'dead');

    if (this.controlGet(entity.spawnarea, 'holdpos')) {
      return;
    }

    if (entity.waypoint) {
      const { persistent, path } = entity.waypoint;
      if (persistent && !entity.pos.eq(path[path.length - 1].pos)) {
        return;
      }
    }

    // TODO: milestone 3: 현재 적이 보이지 않지만 자리를 지키는게 우위를 지키는 경우
    // 현재 이전 waypoint에 도착한 경우
    if (opts.EXP_HOLD_POSITION && entity.waypoint && entity.waypoint.pos.eq(entity.pos)) {
      if (['cover', 'cover-fire'].indexOf(rule.ty) !== -1) {
        const pulls = entities.filter((e) => e.team !== entity.team && e.state !== 'dead' && e.waypoint_rule.ty?.indexOf('fire') > 0 && e.aimtarget === entity && entity.firearm_range > e.firearm_range);
        if (pulls.length > 0) {
          return;
        }
      }
    }

    let cp = null;
    let skips = [];
    if (rule.ty === 'patrol') {
      let waypoint = entity.waypoints[0];
      if (entity.pos.eq(waypoint)) {
        entity.waypoints = entity.waypoints.slice(1);
        entity.waypoints.push(waypoint);
        waypoint = entity.waypoints[0];
      }

      cp = {
        pos: waypoint,
      };
    }

    if (rule.ty === 'rescue') {
      cp = this.chooserescuepoint(entity, entity.waypoint_rule.rescue_target, opts.RESCUE_RADIUS);
    }
    if (rule.ty === 'heal') {
      cp = { pos: rule.heal_target.pos };
    }
    if (rule.ty === 'capture-goal') {
      cp = this.choosecapturepoint(entity, rule.goal);
    }
    if (rule.ty === 'cover-goal') {
      cp = this.choosecovergoalpoint(entity, rule.goal);
    }
    if (rule.ty === 'cover-charge') {
      cp = this.chooseNearEnemiesPoint(entity);
      if (cp.pos.dist(entity.waypoint_rule.start_pos) > opts.DAMAGE_COVER_CHARGE_MAX_DISTANCE) {
        cp = null;
      }

    }
    if (rule.ty === 'reload') {
      cp = this.choosehidepoint(entity);
    }
    if (rule.ty === 'gather') {
      cp = this.choosenearestpoint(rule.leader);
    }
    if (rule.ty === 'hide') {
      cp = this.choosehidepoint(entity);
    }
    if (rule.ty === 'interact-object') {
      cp = { pos: rule.object.pos };
    }
    if (rule.ty === 'breaching') {
      cp = rule.waypoint.cp;
      const path_door_idx = rule.waypoint.path.findIndex((p) => p?.edge?.door);
      // path_door_idx : 문 뒤 위치, path_door_idx - 1 : 문 앞 위치, path_door_idx - 2 : breach_ready_pos
      if (path_door_idx > 1) {
        rule.waypoint.path[path_door_idx - 2].pos = rule.breach_ready_pos;
      }
    }
    if (rule.ty === 'supressing') {
      const ret = this.chooseSupressingPoint(entity);
      cp = ret.cp;
      //바운딩 박스로 느슨하게 체크한 뒤 geomContrains를 체크하도록 하면 성능을 개선할 수 있습니다.
      for (let i = 0; i < routes.nodes.length; i++) {
        const pos = routes.nodes[i].pos;
        if (geomContains(pos, ret.hull)) {
          skips.push(i);
        }
      }
    }
    // cover-hold: 처음 cover 포인트로 이동 후 정지
    // ty: ''cover-hold
    // area: 커버 포인트 탐색 에리어
    // max_dist: 커버 포인트 탐색 최대 거리
    if (['cover-hold'].includes(rule.ty)) {
      if (rule.cp && rule.cp.pos.eq(entity.pos)) {
        // cover point arrived
        return;
      }
      cp = this.choosecoverholdpoint(entity, rule.max_dist ?? opts.EXP_COVER_HOLD_MAX_DIST_DEFAULT);
      rule.cp = cp;
    }

    if (entity.waypoint?.pos?.eq(entity.pos)) {
      // TODO: 문 진입 데모
      // explore 상태에서 목적지에 도착하고 re-route하는 경우, 진행할 수 없는 상황인지 검사해야 합니다.
      const cur = entity.waypoint.path?.find((p) => entity.pos.eq(p.pos));
      const dest_idx = cur?.idx;

      if (dest_idx >= 0) {
        if (this.nodeShouldStop(entity, dest_idx)) {
          return;
        }

        entity.recent_visits.push(dest_idx);
      }
    }

    // explore + area, explore + initiator
    if (rule.ty === 'explore') {
      const scoreFn = this.explorepointScoreFn(entity);
      cp = this.chooseexplorepoint(entity, scoreFn);
      // TODO: room hack
      if (cp?.maxcover) {
        this.maybePopGroupRule(entity, cp.maxcover.cover < opts.EXPLORED_THRES);
      }
    }

    //[피해 분산] 뒤로 가야하는 오퍼레이터가 엄폐 상태가 아닐 시 엄폐하도록 설정
    if (entity.state !== 'covered' && rule.ty === 'cover-hide') {
      if (entity.aimtarget) {
        cp = this.choosecoverpoint(entity, 100000);
      }
    }

    if (!['follow'].includes(rule.ty) && !cp) {
      if (entity.aimtarget) {
        // rule 1. 가장 가까운 엄호물
        // rule 2. 오랫동안 상대방을 사격할 수 없는 경우, 상대방이 닿는 곳 (이건 지금 loop에서 처리)
        // rule 3. 내 앞에서 내 상대를 제압하고 있는 아군이 있는 경우, 그 아군보다 더 가까운 곳으로 이동

        if (rule.ty === 'fire') {
          cp = this.choosefirepoint(entity.aimtarget);
        } else {
          const covering_entity = entities.find((e) => e.team === entity.team
            && e !== entity
            && e.state === 'covered'
            && e.aimtarget === entity.aimtarget
            && e.aimtargetshoots > 0);

          let max_dist = 100000;
          if (rule.ty === 'cover-fire') {
            const dist = entity.pos.dist(entity.aimtarget.pos);
            max_dist = Math.min(max_dist, Math.min(entity.firearm_range, dist + opts.EXP_COVER_FIRE_BACKWARD_DISTANCE));
          }

          if (covering_entity) {
            // TODO
            // max_dist = Math.min(max_dist, covering_entity.pos.dist(entity.aimtarget.pos));
          }
          cp = this.choosecoverpoint(entity, max_dist);
        }
      } else {
        // cp도 없고 aimtarget도 없음.
        // cp = this.chooseguardpoint(entity);
        cp = this.choosereorgpoint(entity);
      }
    }

    if (cp && !cp.pos.eq(entity.pos)) {
      // TODO: XXX: FIXME: 2인 방 진입 데모용
      const ally_doornodes = [];
      for (const e of allies) {
        const door = this.entityWaypointDoor(e);
        if (door) {
          ally_doornodes.push(door);
        }
      }

      const costfunc = (edge) => {
        const cost = edge.len;
        let weight = 1;

        if (ally_doornodes.includes(edge.from_idx)) {
          // TODO: 트레일러 데모
          return 1000000;
        }

        if (opts.ROUTE_WITH_SAMPLING) {
          // check if edge is covered
          const samples = 3;
          let covers = 0;
          for (let i = 0; i < samples; i++) {
            // should be deterministic
            let samplepos = v2.lerp(edge.from, edge.to, (i + 1) / (samples + 1));
            covers += checkcover(entity.aimtarget.pos, samplepos, routes);
          }
          weight = (samples - covers / 2) / (samples);
        }

        return cost * weight;
      };

      let path = routePathfind(routes, entity.pos, cp.pos, costfunc, skips, entity.allow_cover_edge);
      let persistent = false;

      let breach_path = this.entityPathBreach(entity, costfunc);
      if (breach_path) {
        path = breach_path;
        persistent = true;
      }

      if (path) {
        const idx = path[0]?.idx;
        // 현재 entity가 node 위에 있는 경우, 현재 위치에 대해 처리
        if (idx >= 0) {
          this.navigateOnVisitNode(entity, idx);
        }

        const dest = path.find((p) => p.len > 0);
        if (dest) {
          entity.waypoint = {
            pos: dest.pos,
            cp,
            rule_ty: rule.ty,
            obstacle: cp.obstacle,
            path,
            persistent,
          };
        } else if (!breach_path) {
          // TODO: breach_path의 path의 len이 0인 경우 오동작하지 않도록 임시로 조치합니다.
          console.error('failed to find route', entity.name);
          // routePathfind(routes, entity.pos, cp.pos, null, true);
          entity.waypoint = cp;
        }
      }

      if (['covered', 'hide'].includes(entity.state)) {
        entity.state = 'stand';
      }
    }
  }

  entityPathBreach(entity, costfunc) {
    const { routes } = this;

    if (entity.waypoint_rule.ty !== 'breaching') {
      return null;
    }

    const breaching_rule = entity.rules?.find((e) => e.ty === 'breaching');
    // MEMO: breaching door
    if (!breaching_rule) {
      return null;
    }
    if (!breaching_rule.common.door_entry) {
      return null;
    }
    const dooredge = breaching_rule.common.dooredge;
    if (!dooredge?.edge) {
      return null;
    }

    const { edge } = dooredge;
    const { obstacle } = edge;
    const { doorstate } = obstacle;

    const breach_idx = breaching_rule.breach_idx;

    if (doorstate.open) {
      if (breaching_rule.common.door_open_at < 0) {
        this.breachingOpenDoor(entity, breaching_rule);
      }
    }
    if (breaching_rule.breaching_ready || doorstate.open) {
      return routePathfind(routes, entity.pos, breaching_rule.breach_pos, costfunc, [], entity.allow_cover_edge);
    } else {
      return routePathfind(routes, entity.pos, breaching_rule.breach_ready_pos, costfunc, [], entity.allow_cover_edge);
    }
  }

  entityNextWaypoint(entity) {
    const { rng, tick } = this;

    if (entity.waypoint_rule.ty === 'idle') {
      return null;
    }

    // reroute 주기를 초과한 경우
    if (!this.entityRoutable(entity)) {
      this.entityNextWaypointFollow(entity);
      return;
    }

    let route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
    entity.lastRouteTick = new TickTimer(tick, this.ticksFromSec(route_interval));

    this.entityNextWaypoint0(entity);
  }

  entityFollowPoint(entity, dist) {
    // 경로가 있는 경우, 경로 뒤의 점을 찾습니다.

    if (!entity.waypoint?.path || !dist) {
      return null;
    }
    const { path } = entity.waypoint;
    // path[0]은 현재 위치, 혹은 현재 위치까지의 path입니다.
    // path[1]은 다음 위치, 현재 위치에서 다음 위치까지의 경로입니다.

    const p0 = path[0];
    const p1 = path[1];

    if (!(p0 && p1 && !p0.pos.eq(entity.pos))) {
      return null;
    }

    let rays;
    if (entity.cached_raycast_result && entity.cached_raycast_result.expire_at == this.tick) {
      rays = entity.cached_raycast_result.rays;
    } else {
      rays = raycastWasm(this.rr, entity.pos, [p0.pos.sub(p1.pos).norm()]);
      entity.cached_raycast_result = {
        rays,
        expire_at: this.tick,
      };
    }
    const ray = rays[0].sub(entity.pos);
    if (ray.len() - opts.EXP_FOLLOW_RAYCAST_TOLERANCE < dist) {
      return null;
    }

    const p = entity.pos;
    const p0p1 = p1.pos.sub(p0.pos);
    const p0p = p.sub(p0.pos);

    const t = p0p.len() / p0p1.len();
    const t_follow = t - dist / p0p1.len();

    const targetpos = p0.pos.add(p0p1.mul(t_follow));
    return targetpos;
  }

  entityLCAPoint(entity, target) {
    const { tick, routes } = this;

    const calcuate_path_lca = () => {
      const target_path = target.waypoint.path;
      const target_goal = target_path[target_path.length - 1].pos;
      const entity_path = routePathfind(routes, entity.pos, target_goal);

      if (!entity_path) {
        return null;
      }

      let idx = findLastIndexCommonElement(entity_path.map((p) => p.pos), target_path.map((p) => p.pos), (a, b) => a.eq(b));

      if (idx == -1) {
        return null;
      } else {
        return entity_path[entity_path.length - idx - 1].pos;
      }
    };

    const lca = calcuate_path_lca();
    if (!lca) {
      return null;
    }
    return lca;
  }

  ticksFromSec(seconds) {
    return Math.floor(this.tps * seconds);
  }

  entityEffect(entity, effect_ty) {
    const effects = this.entityEffects(entity);
    for (const effect of effects) {
      if (effect.effect_ty === effect_ty) {
        return effect;
      }
    }
    return null;
  }

  entityEffects(entity) {
    const { blastareas, spawnareas, tick } = this;

    const effects = [];
    effects.push({ effect_ty: `movestate_${entity.movestate}` });

    for (const area of spawnareas) {
      let effect_ty = area.areastate.area.effect_ty;
      if (area.areastate.area.structureopts) {
        effect_ty = 'indoor';
      }

      if (!effect_ty || !geomContains(entity.pos, area.polygon)) {
        continue;
      }

      effects.push({ ...area.areastate.area, effect_ty });
    }
    for (const { effect_ty, expire_at } of entity.effects) {
      if (expire_at <= tick) {
        continue;
      }
      effects.push({ effect_ty });
    }
    for (const ba of blastareas) {
      if (ba.expire_at <= tick) {
        continue;
      }
      if (typeof ba.effect_ty !== 'string') {
        continue;
      }

      const entities_reachable = this.reachableEntities(ba.vis);
      if (entities_reachable.includes(entity)) {
        effects.push(ba);
      }
    }

    return effects;
  }

  entityEffectParam(entity, key) {
    let val = entity[key];
    const effects = this.entityEffects(entity);
    for (const { effect_ty } of effects) {
      const param_key = `${effect_ty}_params`;
      const setval = PARAMS[param_key]?.values?.[key];
      if (setval !== undefined) {
        val = setval;
      }

      const mult = PARAMS[param_key]?.multipliers?.[key];
      if (!isNaN(mult)) {
        val *= mult;
      }
    }

    return val;
  }

  // effect area 구현 관련으로 추상화
  entityVisRange(entity) {
    return this.entityEffectParam(entity, 'vis_range');
  }

  // effect area 구현 관련으로 추상화
  entityAimvarHoldMax(entity) {
    return this.entityEffectParam(entity, 'aimvar_hold_max');
  }

  entitySpeed(entity) {
    const { entities, spawnareas, tick } = this;
    const speed_base = this.entityEffectParam(entity, 'speed') / this.tps;
    const movespeed_min = opts.MOVESPEED_MIN / this.tps;

    let speed_mult = 1.0;
    if (entity.perk_smg_fast_move) {
      speed_mult += 0.1;
    }
    if (entity.waypoint?.breaching) {
      speed_mult += 1.0;
    }
    if (!entity.aimtarget && entity.use_riskdir) {
      speed_mult += opts.PIECUT_SPEED_MULT_NOT_ENGAGE;
    }
    // 저격수의 위협 퍽
    if (entity.perk2_sharpshooter_threat) {
      const perk = entity.perk2_sharpshooter_threat;
      if (perk.expires_at && !perk.expires_at.expired(tick)) {
        speed_mult -= perk.speed_rate_reduction;
      }
    }

    switch (entity.state) {
      case 'dash':
        speed_mult += 0.25;
      case 'stand':
      case 'covered':
        break;
      case 'crawl':
      case 'hide':
      case 'dead':
        speed_mult = 0;
        break;
      default:
        throw new Error(`speed: invalid state: ${entity.state}`);
    }

    if (speed_mult > 0 && entity.aimtarget) {
      if (entity.team === 0) {
        speed_mult += (opts.EXP_SPEED_MULT_ENGAGE + entity.additional_speed_mult);
      }
      else {
        speed_mult += opts.EXP_AIMSPEED_MULT_ENGAGE;
      }
    }

    /*
    if (!entity.unaimPauseTick.expired(this.tick)) {
      speed_mult = 0;
    }
    */

    const speed = speed_base * speed_mult;

    // area filter: 실내일 때 느리게 움직이도록
    // TODO: 좀 더 일반적인 방법 필요: 넓은 공간을 탐색할 때 빠르게 움직이도록 하기?
    let indoor_mult = 1.0;
    for (const area of spawnareas) {
      if (!area.areastate.area.structureopts) {
        continue;
      }
      if (geomContains(entity.pos, area.polygon)) {
        // 50 -> 20
        indoor_mult = 0.8;
        break;
      }
    }

    if (entity.perk_engage_dash) {
      const offenced = this.entityOffenced(entity);
      if (offenced) {
        return speed * 1.5;
      }
    }

    // dir/aimdir을 조합해서 이동 속도를 정합니다.
    // abs(reldir), speedmult가 (0, 1), (Math.PI/2, 0)
    const reldir = dirnorm0(entity.dir - entity.aimdir);
    let multiplier = 1;
    // TODO: milestone 3: 실외에서는 movespeed_rules 적용 안 함
    /*
    if (!(opts.EXP_OUTDOOR_IGNORE_MOVESPEED_RULES && indoor_mult === 1.0)) {
      for (const item of entity.movespeed_rules) {
        if (reldir < item.reldir) {
          multiplier = item.multiplier;
        }
      }
    }
    */

    const followers = entities.filter((e) => {
      return e.leader === entity && e.state !== 'dead'
    });
    // leader인 경우
    if (entity.allow_wait_follower && followers.length > 0) {
      const centerofmass = followers.reduce((pos_acc, { pos }) => pos.add(pos_acc), v2.zero())
        .mul(1 / followers.length);

      // center of mass가 목적지보다 멀면 느리게 가야 함
      if (entity.waypoint) {
        const waypoint_pos = entity.waypoint.pos;
        // 양수면 entity가 앞, 음수면 뒤
        const delta_dist = centerofmass.dist(waypoint_pos) - entity.pos.dist(waypoint_pos);

        // const delta = centerofmass.sub(entity.pos);
        if (delta_dist > opts.LEADER_SLOW_DIST) {
          multiplier = multiplier * 0.5;
        }
      }
    }

    const movespeed = speed * multiplier * indoor_mult;
    if (movespeed === 0) {
      return movespeed;
    }
    return Math.max(movespeed, movespeed_min);
  }

  entityFoundVIP(entity) {
    let target = this.entities
      .filter(e => e.team === 2 && e.state !== 'dead')
      .find((e) => entity.grid_explore[this.world.idx(e.gridpos)] > 0.1);

    if (!target) {
      return null;
    }
    return target;
  }

  entityUpdateAimtarget(entity) {
    if (entity.team !== 0) {
      return this.entityUpdateAimtarget0(entity);
    }

    const { rng, entities } = this;
    if (rng.range(0, 1) < entity.retarget_accurate_prob) {
      return this.entityUpdateAimtarget0(entity);
    }

    // improper judgement
    const ot = opponentTeam(entity.team);
    const evalFn = this.entityTargetEvalFn(entity);
    const targets = entities
      .filter(e => ot.includes(e.team) && e.state !== 'dead')
      .map((target) => evalFn(entity, target))
      .filter((e) => e.visible)
      .sort((a, b) => a.dist - b.dist);


    if (targets.length > 0) {
      const target = targets[0];
      this.entitySetAimtarget(entity, target.entity);
      return true;
    } else {
      this.entitySetAimtarget(entity, null);
      return false;
    }
  }

  entityTargetEvalFn(entity) {
    const { world, routes, journal, tick } = this;

    const offenced = this.entityOffenced(entity);
    const obstacles = this.obstacles.filter((o) => o.ty === 'full' || (o.ty === 'door' && !o.doorstate.open));
    let perk_unidir_used = false;
    let perk2_intuition_used = false;

    const range = this.entityVisRange(entity);
    const vis_var = this.entityEffectParam(entity, 'vis_var');

    const initiator = entity.waypoint_rule.initiator;

    return (entity, other) => {
      const cover = checkcover(entity.pos, other.pos, routes);
      const dist = entity.pos.dist(other.pos);
      const reachable = dist < entity.firearm_range;
      let reason = null;
      let visible = true;

      if (entity.use_visibility) {
        visible = this.entityAware(entity, other);

        if (visible && opts.EXP_AIMTARGET_VIS) {
          // angle
          const dirvec = other.pos.sub(entity.pos);
          const dir = dirvec.dir();
          const reldir = dirnorm0(entity.aimdir - dir);

          if (Math.abs(reldir) > vis_var) {
            reason = 'invis';
            visible = false;
          }
        }
      }

      if (!world.exp_search) {
        if (other === initiator) {
          // 실내 데모용: 교전 시작한 상대는 항상 어디있는지 알아야 함
          reason = 'initiator';
          visible = true;
        }
        if (offenced && offenced.source === other) {
          // 나를 공격한 상대는 어디 있는지 알아야 함
          reason = 'offenced';
          visible = true;
        }
      }

      const targetobstructed = obstructed(entity.pos, other.pos, obstacles);
      if (!visible && dist < range
        && entity.perk_unidir_sense && !targetobstructed) {
        if (!perk_unidir_used) {
          journal.push({ tick, ty: 'perk', entity, perk: 'perk_unidir_sense' });
        }
        perk_unidir_used = true;
        reason = 'perk_unidir_sense';
        visible = true;
      }

      // 직감 퍽
      if (!visible && dist < range && entity.perk2_common_vision_intuition && !targetobstructed) {
        if (!perk2_intuition_used) {
          perk2_intuition_used = true;
          journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_vision_intuition' });
        }
        reason = 'perk2_common_vision_intuition';
        visible = true;
      }

      return {
        entity: other,
        cover,
        dist,
        reachable,
        reason,
        visible,
      };
    };
  }

  entityUpdateAimtarget0(entity) {
    // TODO: FIXME
    if (entity.ty === 'vip') {
      return false;
    }
    const pattern_ended = entity.shootPatternIdx === 0;
    if (!pattern_ended) {
      return false;
    }

    // rule에서 target을 명시한 경우
    const rule_target = entity.waypoint_rule.target;
    if (rule_target && rule_target.state !== 'dead') {
      entity.aimtarget = rule_target;
      entity.aimtargetshoots = 0;
      return false;
    }

    const { entities, rng } = this;

    if (entity.team === 1) {
      const ot = opponentTeam(entity.team);
      const evalFn = this.entityTargetEvalFn(entity);
      const targets = entities
        .filter(e => ot.includes(e.team) && e.state !== 'dead')
        .map((target) => evalFn(entity, target))
        .filter((e) => e.visible)
        .map((e) => e.entity);

      let target = null;
      const front = targets.filter((e) => e.squad_idx < 2);
      const back = targets.filter((e) => e.squad_idx >= 2);

      if (front && front.length > 0) {
        const pref_high = front.filter((e) => e.perk_targetpref_high);
        if (pref_high && pref_high.length > 0) {
          target = rng.choice(pref_high);
        } else {
          const pref_not = front.filter((e) => !e.perk_targetpref_high);
          target = rng.choice(pref_not);
        }
      } else {
        const pref_high = back.filter((e) => e.perk_targetpref_high);
        if (pref_high && pref_high.length > 0) {
          target = rng.choice(pref_high);
        } else {
          const pref_not = back.filter((e) => !e.perk_targetpref_high);
          target = rng.choice(pref_not);
        }
      }

      this.entitySetAimtarget(entity, target);
      return target !== null;
    }

    function basecmp(a, b) {
      const goala = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;
      const goalb = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;

      // 현재 닿는 상대를 aim
      if (a.reachable !== b.reachable) {
        return b.reachable - a.reachable;
      }

      // 정책에 따라: leader가 조준하고 있는 상대

      // goal이 낮은 상대를 aim
      if (goala !== goalb) {
        return goala - goalb;
      }

      // firearm_range가 높은 상대를 aim
      if (entity.perk_desmar_priority_defensive) {
        if (a.entity.firearm_range !== b.entity.firearm_range) {
          return b.entity.firearm_range - a.entity.firearm_range;
        }
      }

      // 체력 소모가 적은 상대를 aim
      if (entity.perk_desmar_priority_offensive) {
        const da = a.entity.life_max - a.entity.life;
        const db = b.entity.life_max - b.entity.life;

        if (da !== db) {
          return da - db;
        }
      }

      // 다음 조건일 때 cover가 낮은 상대를 aim
      if (a.cover !== b.cover) {
        // 둘 다 범위 안에 있는 경우
        if (a.reachable && b.reachable) {
          return a.cover - b.cover;
        }
      }
      return 0;
    }

    function evalTargetSortFn(a, b) {
      const cmp = basecmp(a, b);
      if (cmp) {
        return cmp;
      }

      // 거리가 가까운 상태를 aim
      return a.dist - b.dist;
    };

    const ot = opponentTeam(entity.team);
    const evalFn = this.entityTargetEvalFn(entity);
    const targets = entities
      .filter(e => ot.includes(e.team) && e.state !== 'dead')
      .map((target) => {
        // 같은 상대를 조준하고 있는 아군 목록
        let allies = [];
        if (entity.allow_coordinated_fire) {
          allies = entities
            .filter((e) => entity !== e && !ot.includes(e.team) && e.state !== 'dead' && e.aimtarget === target && !e.fireleader)
            .map((e) => this.entityTargetEvalFn(e)(e, target));
          allies.sort(evalTargetSortFn);
        }

        const res = evalFn(entity, target);
        res.ally_result = allies[0];
        return res;
      })
      .filter((e) => e.visible);

    function cmpAllyResult(a, b) {
      if (a === undefined && b !== undefined) {
        return -1;
      }
      if (a !== undefined && b === undefined) {
        return 1;
      }
      if (a === undefined && b === undefined) {
        return 0;
      }
      // ally로부터의 우선순위가 낮은 상대를 선호
      return evalTargetSortFn(b, a);
    }

    function cmpPerkCommonShieldBreaker(a, b) {
      if (a.shield > 0 && b.shield > 0) {
        return a.shield - b.shield;
      }
      if (a.shield > 0) {
        return -1;
      }
      if (b.shield > 0) {
        return 1;
      }
      return 0;
    }

    targets.sort((a, b) => {
      if (entity.perk2_common_shield_breaker) {
        // 장비 파괴자 퍽
        const perk_cmp = cmpPerkCommonShieldBreaker(a, b);
        if (perk_cmp) {
          return perk_cmp;
        }
      }

      if (a.entity.perk_targetpref_high !== b.entity.perk_targetpref_high) {
        return b.entity.perk_targetpref_high - a.entity.perk_targetpref_high;
      }
      if (a.entity.perk_targetpref_low !== b.entity.perk_targetpref_low) {
        return a.entity.perk_targetpref_low - b.entity.perk_targetpref_low;
      }

      const cmp = cmpAllyResult(a.ally_result, b.ally_result);
      if (cmp) {
        return cmp;
      }

      const cmp0 = basecmp(a, b);
      if (cmp0) {
        return cmp0;
      }

      // 거리가 가까운 상태를 aim
      return a.dist - b.dist;
    });

    const target = targets[0] ? targets[0].entity : null;
    this.entitySetAimtarget(entity, target);
    return target !== null;
  }

  entitySetAimtarget(entity, target) {
    const { tick, journal } = this;

    const pattern_ended = entity.shootPatternIdx === 0;

    if (entity.perk2_common_shooting_quickdraw && entity.aimtarget == null && target) {
      this.journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_shooting_quickdraw', targets: [target] });
      entity.quickdraw_first_bullet = true;
    }

    if (target && target !== entity.aimtarget) {
      journal.push({ tick, ty: 'aim', entity, target });
      entity.aimtarget = target;
      entity.aimtargetshoots = 0;

      entity.unaimTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_DURATION));
      entity.unaimPauseTick = new TickTimer(tick, 0)
    } else if (pattern_ended && entity.aimtarget && entity.aimtarget.state === 'dead') {
      this.entityUnaim(entity);
      entity.unaimPauseTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_PAUSE_DURATION));
    }
  }

  entityUnaim(entity) {
    const { tick, journal } = this;

    if (entity.aimtarget === null) {
      return;
    }

    // TODO: waypoint_rule을 보고, 최상위에 initiator가 현재 aimtarget이면 잊음
    if (entity.waypoint_rule.initiator === entity.aimtarget) {
      entity.pop_rule();
    }

    entity.aimtarget = null;
    entity.aimtargetshoots = 0;
    journal.push({ tick, ty: 'unaim', entity });
  }

  entityNavigateFollow(entity) {
    const { rng, routes, tick } = this;

    if (entity.waypoint_rule.ty !== 'follow') {
      return null;
    }

    const target = entity.waypoint_rule.follow_target;
    if (!target.is_fire_rule() && !target.waypoint_dir) {
      return null;
    }

    let targetpos0 = this.entityFollowPoint(target, entity.waypoint_rule.follow_dist);
    let follow_together = false;

    if (!this.leaderShouldStop(target) && target.waypoint_dir) {
      const half_plane = {
        origin: target.pos.sub(target.waypoint_dir.mul(entity.waypoint_rule.follow_dist / 2)),
        dir: target.waypoint_dir.normvec(),
      };

      if (!v2.inHalfPlane(half_plane.origin, half_plane.dir, entity.pos)) {
        if (!entity.lastFollowingTick.expired(tick)) {
          return null;
        }
        const lca_pos = this.entityLCAPoint(entity, target);
        if (lca_pos) {
          targetpos0 = lca_pos;
          follow_together = true;

          let route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
          entity.lastFollowingTick = new TickTimer(tick, this.ticksFromSec(route_interval));
        }
      }
    }

    if (!targetpos0) {
      return null;
    }

    // 이게 맞나??
    const path = routePathfind(routes, entity.pos, targetpos0);
    if (!path) {
      return null;
    }

    const route_indirect = entity.waypoint?.rule_ty === 'follow' && entity.waypoint?.path?.length > 2 && path.length > 2;
    if (route_indirect) {
      if (!entity.lastRouteTick.expired(tick)) {
        return null;
      }

      let route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
      entity.lastRouteTick = new TickTimer(tick, this.ticksFromSec(route_interval));
    }

    const dest = path.find((p) => p.len > 0);
    if (dest) {
      entity.waypoint = {
        pos: dest.pos,
        cp: { pos: targetpos0 },
        rule_ty: entity.waypoint_rule.ty,
        obstacle: null,
        path,
      };
    }
    entity.waypoint.follow_together = follow_together;

    return targetpos0;
  }

  entityNavigate(entity) {
    const { world, routes, tick, entities } = this;

    if (!entity.crawlTick.expired(tick) || !entity.healTick.expired(tick) || !entity.collectTick.expired(tick) || !entity.healtargetTick.expired(tick)) {
      entity.movespeed = 0;
      return;
    }
    if (!entity.breachTick.expired(tick) || !entity.waitTick.expired(tick)) {
      entity.movespeed = 0;
      return;
    }

    let targetpos_follow = this.entityNavigateFollow(entity);
    let targetpos0 = targetpos_follow;
    if (entity.waypoint?.pos) {
      targetpos0 = entity.waypoint.pos;
    }

    // TODO: edge에 머물러야 할 때, 사격 가능한 위치 찾기
    // TODO: waypoint.path.length 확인 나중에 고쳐야 함
    if (entity.waypoint?.path && entity.waypoint.path.length < 3 && entity.waypoint?.cp?.edge && entity.aimtarget) {
      const { p_from, p_to } = entity.waypoint.cp.edge;
      // lb: covering point, ub: shooting point
      const { ub } = bisectEdge(routes, p_from.pos, p_to.pos, entity.aimtarget.pos);

      const p = v2.lerp(p_from.pos, p_to.pos, ub);
      targetpos0 = p;
    }

    if (!targetpos0) {
      entity.movespeed = 0;
      return;
    }

    const d = targetpos0.sub(entity.pos);
    const dist = d.len();

    const effect = this.entityEffect(entity, 'slope');

    function gettargetpos(speed) {
      if (dist === 0 || dist < speed) {
        return targetpos0;
      }

      if (effect) {
        const { x, y } = effect.effect_slope_vec;
        const dir = new v2(x, y).norm();

        const inner = dir.inner(d.norm()) * 0.5;
        speed = (1 + inner) * speed;
      }

      let delta = d.mul(speed / dist);
      return entity.pos.add(delta);
    }

    // 이동 방향을 정합니다
    if (dist > 0) {
      entity.dir = dirnorm(d.dir());
    }

    let speed = this.entitySpeed(entity);
    // follow하는데 먼 경우 빨리 움직입니다
    if (targetpos_follow?.eq(targetpos0)) {
      const follow_dist = targetpos_follow.dist(entity.pos);
      if (follow_dist > speed + opts.FOLLOW_RUN_TOLERANCE) {
        entity.followRunTick = new TickTimer(tick, opts.FOLLOW_RUN_TICKS);
      }
      if (!entity.followRunTick.expired(tick)) {
        const maxspeed = follow_dist - speed;
        if (maxspeed < speed * 0.5) {
          entity.followRunTick = new TickTimer(tick, 0);
        }
        speed += Math.min(maxspeed, speed * 0.5);
      }
    }

    // aimtarget과 일정 거리 이상 가까워지지 않게 합니다.
    if (entity.aimtarget &&
      entity.aimtarget.aimtarget === entity &&
      entity.pos.dist(entity.aimtarget.pos) <= opts.EXP_ENGAGE_MAXIMUM_NEAR_DISTANCE) {
      speed = 0;
    }

    // 사격하지 않고 heal 하고 있는 오퍼레이터가 있다면 다같이 멈춥니다.
    if (!entity.aimtarget && entity.waypoint_rule.ty !== 'heal' &&
      entities.find((e) =>
        e !== entity &&
        e.state !== 'dead' &&
        e.team === entity.team &&
        e.waypoint_rule.ty === 'heal')) {
      speed = 0;
    }

    const breaching_rule = entity.rules.find((e) => e.ty === 'breaching');
    if (breaching_rule && breaching_rule.common.door_entry) {
      if (breaching_rule.breaching_ready && !breaching_rule.common.all_allies_ready) {
        // ready for breaching, waiting for other entities ready
        speed = 0;
      }
      const door_name = breaching_rule.common.dooredge.edge.door.doorstate.name;
      if (breaching_rule.common.all_allies_ready && breaching_rule.common.door_open_at < 0 && get_breacher(breaching_rule, door_name) !== entity) {
        // ready for breaching, waiting for breacher opening door
        speed = 0;
      }
    }

    let targetpos = gettargetpos(speed);
    let dash = false;

    if (entity.perk_cover_dash && entity.waypoint?.cp) {
      const { cp } = entity.waypoint;

      if (!cp.edge && !isNaN(cp.cover) && cp.cover >= 2 && !cp.pos.eq(entity.pos)) {
        const offenced = this.entityOffenced(entity);
        if (offenced || entity.aimtarget) {
          speed = 2.0 * entity.speed / this.tps;
          targetpos = gettargetpos(speed);
          if (!cp._perk_cover_dash) {
            this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_cover_dash' });
            this.bubblePush(entity, L('loc_data_longtext_ingame_bubble_hardcoded_3'));
          }
          cp._perk_cover_dash = true;
          dash = true;
        }
      }
    }

    // check collision
    if (opts.EXP_SOFT_COLLISION) {
      for (const other of this.entities) {
        if (other === entity) {
          continue;
        }
        const entitydist = targetpos.dist(other.pos);
        if (entitydist < entity.size + other.size && entity.name.localeCompare(other.name) > 0) {
          speed = speed / 2;
          targetpos = gettargetpos(speed);
        }
      }
    }

    // 기차놀이 시 리더 앞에 오퍼레이터가 먼저 가고 있는 경우
    if (!entity.leader) {
      const is_follow_together = this.entities.filter((e) => e.state !== 'dead' &&
        e.spawnarea === entity.spawnarea &&
        e.waypoint &&
        e.waypoint.follow_together).length > 0;

      if (is_follow_together) {
        speed *= 1.5;
        targetpos = gettargetpos(speed);
      }
    }

    // 기차놀이 시 리더의 앞에 있는 경우
    if (entity.waypoint.follow_together && entity.waypoint_rule.follow_target) {
      const target = entity.waypoint_rule.follow_target;
      const follow_point = this.entityFollowPoint(target, entity.waypoint_rule.follow_dist);
      if (follow_point) {
        const maxspeed = entity.pos.dist(follow_point) - speed;
        speed = this.entitySpeed(entity);
        speed = Math.max(0, speed - Math.min(maxspeed, speed * (1 - opts.FOLLOW_SPEED_DECREASE)));

        targetpos = gettargetpos(speed);
      }
    }

    targetpos = gettargetpos(speed);

    const movedist = entity.pos.dist(targetpos);
    entity.pos = targetpos;
    entity.gridpos = world.worldToGrid(entity.pos);

    if (movedist > 0) {
      entity.state = dash ? 'dash' : 'stand';
      entity.moving = true;
      entity.aimtargetshoots = 0;
      entity.movespeed = speed;
    } else {
      if (entity.state !== 'covered') {
        entity.state = 'stand';
      }
      entity.moving = false;
      entity.movespeed = 0;
    }

    if (entity.pos === targetpos0) {
      let stopped = false;
      // 재장전중에는 waypoint에서 멈춥니다 -> 비활성화 합니다.
      // if (!entity.reloadTick.expired(tick)) {
      //   stopped = true;
      // }
      if (breaching_rule) {
        if (breaching_rule.breach_ready_pos && !breaching_rule.breaching_ready && breaching_rule.breach_ready_pos.dist(entity.pos) <= opts.BREACHING_POINT_TOLERANCE) {
          breaching_rule.breaching_ready = true;
          if (!breaching_rule.common.all_allies_ready) {
            const not_ready_allies = breaching_rule.common.members.filter((e) => {
              if (e.state === 'dead') {
                return false;
              }
              const br = e.rules?.find((r) => r.ty === 'breaching');
              if (br) {
                return !br.breaching_ready;
              }
              return false;
            });
            breaching_rule.common.all_allies_ready = not_ready_allies.length == 0;
          }
        }
        if (breaching_rule.breach_pos && !breaching_rule.breaching_over && breaching_rule.breach_pos.dist(entity.pos) <= opts.BREACHING_POINT_TOLERANCE) {
          breaching_rule.breaching_over = true;
          if (!breaching_rule.common.all_allies_over) {
            const all_allies_over = breaching_rule.common.members.filter((e) => {
              const rule = e.rules.find((r) => r.ty === 'breaching');
              if (rule && e.state !== 'dead') {
                return !rule.breaching_over;
              }
              return false;
            }).length == 0;
            breaching_rule.common.all_allies_over = all_allies_over;
          }
        }
      }

      if (!stopped) {
        this.entityNextWaypoint(entity);
      }
    }

    const aimvar_mult = this.entityEffectParam(entity, 'aimvar_mult');
    // firearm_aimvar_incr_move_cap는 tps=30 기준으로 만들어져 있습니다.
    const firearm_aimvar_incr_move_cap = entity.firearm_aimvar_incr_move_cap * 30 / opts.tps;
    entity.aimmult += Math.min(firearm_aimvar_incr_move_cap,
      entity.movespeed * opts.AIMVAR_INCR_MOVE_MULTIPLER * aimvar_mult);
  }

  entityUpdateAim(entity, targetdir) {
    entity.debugaimdir = targetdir;

    if (opts.EXP_ROTATE_INSTANT) {
      entity.aimdir = targetdir;
      return;
    }

    const aimdelta0 = dirnorm0(targetdir - entity.aimdir);

    const rule = entity.aim_rot_rules.find((a) => a.aimvar > Math.abs(aimdelta0));

    if (!rule) {
      return;
    }

    let aimspeed_mult = 1;
    if (entity.perk_smg_fast_move) {
      aimspeed_mult += 1.1;
    }
    if (entity.perk2_common_shooting_quickdraw && entity.quickdraw_first_bullet) {
      aimspeed_mult += entity.perk2_common_shooting_quickdraw.aimspeed_mult;
    }

    if (entity.aimtarget) {
      aimspeed_mult += opts.EXP_AIMSPEED_MULT_ENGAGE;
    }

    // angular speed?
    // aimspeed는 tps=30 기준으로 만들어져 있습니다.
    let aimspeed = (rule.aimspeed * 30) / opts.tps * aimspeed_mult;

    const aimdelta = clamp(aimdelta0, -aimspeed, aimspeed);
    entity.aimdir = dirnorm(entity.aimdir + aimdelta);

    const aimvar_mult = this.entityEffectParam(entity, 'aimvar_mult');

    // aimdelta만큼 aim이 흐트러짐
    // firearm_aimvar_incr_rot_cap는 tps=30 기준으로 만들어져 있습니다.
    const firearm_aimvar_incr_rot_cap = entity.firearm_aimvar_incr_rot_cap * 30 / opts.tps;
    entity.aimmult += Math.min(firearm_aimvar_incr_rot_cap,
      Math.abs(aimdelta) * opts.AIMVAR_INCR_ROT_MULTIPLER * aimvar_mult);
  }

  entityCalculateAimNotAimtarget(entity) {
    const { tick, routes } = this;

    if (entity.waypoint_rule.ty === 'cover-hold') {
      const rule = entity.waypoint_rule;
      if (rule.use_spawnheading && rule.area) {
        if (rule.cp && rule.cp.pos.eq(entity.pos)) {
          const now_area = rule.area.areastate.area;
          return now_area.spawnheading;
        }
      }
    }

    let dir = entity.dir;
    if (entity.use_riskdir) {
      let res = null;

      if (entity.movespeed > 0) {
        res = this.riskdir(entity);
      } else if (entity.waypoint_rule.alert ?? false) {
        // 프롬프트 데모: 총성이 들리면 잠재적 위협 방향을 바라봅니다.

        if (!entity._alert_res || entity.idleAlertTick.expired(tick)) {
          entity._alert_res = this.riskdirDry(entity);
          entity.idleAlertTick = new TickTimer(tick, this.ticksFromSec(...opts.IDLE_ALERT_INTERVAL_RANGE));
        }
        res = entity._alert_res;
      }

      let selected_val = res?.selected_val ?? 0;

      if (selected_val > opts.RISKDIR_THRES) {
        dir = res.selected_dir;
        entity.lastRiskTick = new TickTimer(tick, this.ticksFromSec(opts.RISK_DIR_PERSISTENT_DURATION));
      } else {
        // 자유 공간을 탐험중

        // 실내 데모용: 문 진입시 동작
        if (entity.waypoint?.path) {
          for (const p of entity.waypoint.path) {
            if (p.idx === -1) {
              continue;
            }
            const node = routes.nodes[p.idx];
            if (node.obstacle?.ty === 'door' && this.doorShouldStop(entity, p.idx, false)) {
              // 경로에 열어야 하는 문이 있는 경우
              let d = node.pos.sub(entity.pos);
              if (d.len() < 30) {
                dir = node.obstacle.pos.sub(entity.pos).dir();
              }
              break;
            }
          }
        }
      }
    }
    return dir;
  }

  entityHandleFireSim(entity, samples, rng) {
    const target = entity.aimtarget;
    const targetSize = target.shield > 0 ? target.size * SHIELD_ENTITY_SIZE_MULT : target.size;
    const variance = this.entityAimvar(entity);

    let projectile_aimvar = entity.firearm_projectile_aimvar;
    let projectile_per_shoot = entity.firearm_projectile_per_shoot;

    if (entity.perk_sg_projectile) {
      projectile_aimvar *= 1.5;
      projectile_per_shoot *= 2;
    }

    let samples_hits = 0;
    for (let j = 0; j < samples; j++) {
      const firedir = projectile_dice(rng, entity.pos, target.pos, entity.aimdir, variance, opts.AIM_ITER_FIRE);
      let hits = 0;

      for (let i = 0; i < projectile_per_shoot; i++) {
        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir, projectile_aimvar, opts.AIM_ITER_PROJECTILE);

        const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
        if (dist < targetSize) {
          hits += 1;
        }
      }
      if (hits > 0) {
        samples_hits += 1;
      }
    }

    return samples_hits / samples;
  }

  entityExposed(entity, pos) {
    const { entities } = this;

    const ot = opponentTeam(entity.team);
    for (const e of entities) {
      if (!ot.includes(e.team) || e.state === 'dead') {
        continue;
      }

      const { world } = this;
      const gridpos = world.worldToGrid(pos);
      const grid = e.grid_vis[world.idx(gridpos)];
      if (grid > entity.vis_thres) {
        return true;
      }
    }
    return false;
  }

  // fire control이 의미가 있는지 확인합니다
  entityFireControl(entity) {
    const { entities } = this;

    // 조준 상대가 없는 경우 무시합니다.
    /*
    if (!entity.aimtarget) {
      return false;
    }
    */
    // 이미 교전중인 경우
    /*
    if (!entity.reloadShootIdleTick.expired(tick)) {
      return false;
    }
    */

    // 조준당한 경우 응사해야 합니다.
    if (entities.find((e) => e.aimtarget === entity && e.state !== 'dead')) {
      return false;
    }

    // 현재 위치/이동 목적지가 적의 시야에 노출된 경우 fire control이 의미 없습니다.
    if (this.entityExposed(entity, entity.pos)) {
      return false;
    }
    if (entity.waypoint && this.entityExposed(entity, entity.waypoint.pos)) {
      return false;
    }

    return true;
  }

  // target 등을 고려한 aimvar
  entityAimvar(entity) {
    return entity.aimvar & this.entityAimvarMult(entity);
  }

  entityAimvarMult(entity) {
    let mult = 1;
    let { firearm_aimvar_mult, aimtarget } = entity;

    mult *= firearm_aimvar_mult;
    if (aimtarget && this.entityEffect(aimtarget, 'smoke')) {
      mult *= opts.SMOKE_AIMVAR_MULT;
    }
    return mult;

  }

  // fire control 상태에서 준비되었는지
  entityFireControlReady(entity, rng) {
    if (!entity.aimtarget) {
      return false;
    }

    // waypoint에 도착했는지
    if (entity.waypoint?.cp) {
      const { cp } = entity.waypoint;
      if (!cp.edge && cp.cover >= 2 && !entity.pos.eq(cp.pos)) {
        return false;
      }
    }

    // aim이 충분히 정교한지
    const simres = this.entityHandleFireSim(entity, opts.AIM_SAMPLES_FIRE, rng);
    if (entity.aimmult > 0.01 && simres < entity.aim_samples_fire_thres) {
      return false;
    }

    return true;
  }

  // true: 사격 불가
  entityFireControlled(entity, rng) {
    // 사용하지 않음
    return false;
    // coordinated fire control
    // 시작: 아군이 entityFireControl=true 인 상태로 시작.
    // 중간: entityFireControl=true, entityFireControlReady=true 일 때까지 기다림
    // 사격 시작, 아군 중 한 명이 entityFireControl=false가 됨, fire control이 유효하지 않은 상태가 되어 사격 시작
    const { entities } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => !ot.includes(e.team) && e.state !== 'dead' && e.ty !== 'vip');

    // 아군 중 한명이라도 fire control이 의미 없는 상황이라면 사격합니다.
    if (allies.find((e) => !this.entityFireControl(e))) {
      return false;
    }

    // 모두 fire control이 의미 있지만, 사격 준비되지 않은 아군이 있습니다.
    if (allies.find((e) => !this.entityFireControlReady(e, rng))) {
      return true;
    }

    // 사격합니다.
    return false;
  }

  pushJournal(item) {
    const { journal } = this;
    if (journal.length === 0) {
      journal.push(item);
      return;
    }
    const last = journal[journal.length - 1];
    if (last.perk === item.perk && journal.entity === item.entity) {
      // dedup
      return;
    }
    journal.push(item);
  }

  entityCalculateDamage(entity, target, damage, stable_mult) {
    if (target.invulnerable) {
      return 0;
    }

    if (entity.perk_egress_damage_damper > 0) {
      damage = Math.floor(damage / 2);
      entity.perk_egress_damage_damper -= 1;
    } else if (target.perk_ingress_damage_damper > 0) {
      damage = Math.floor(damage / 2);
      target.perk_ingress_damage_damper -= 1;
    }

    if (opts.STABLE_DAMAGE) {
      if (opts.STABLE_DAMAGE_FAKE) {
        if (target._damage_acc === undefined) {
          target._damage_acc = 0;
        }
        target._damage_acc += damage * stable_mult;
        if (target._damage_acc < damage && target._damage_acc < target.armor + target.life) {
          return 0;
        }
        target._damage_acc -= damage;
      } else {
        damage *= stable_mult;
      }
    }
    return damage;
  }

  entityOnDamage(entity, target, damage, penetrate, ty, options) {
    let hit = false;
    let kill = false;

    const { rng, tick } = this;

    // 실드와 관계 없이 데미지도 적용하는 옵션 -> 대방탄, 대인 피해량 모두 적용
    const option_with_damage = options && options.includes('with-damage');
    // 로스된 대방탄 피해량이 대인 피해량으로 변환될 수 있는 옵션
    const option_penetrate_loss_damage = options && options.includes('penetrate-loss-damage');
    // 즉사 옵션
    const option_instant_kill = options && (options.includes('instant-kill') || options.includes('head-shot'));
    // 실드 무시 옵션 -> 실드 존재 여부 상관 없이 대인 피해량 적용
    const option_ignore_shield = options && options.includes('ignore-shield');

    let hit_shield = target.shield > 0;

    if (target.piecutBuffTick && !target.piecutBuffTick.expired(tick)) {
      damage = Math.max(damage * (1 - target.perk2_common_intelligence_piecut.perk2_common_intelligence_piecutall_damage_reduction), 0);
    }
    // 근육 방탄판 퍽
    if (target.perk2_vanguard_muscle_bulletproof && target.shield === 0) {
      damage = Math.max(damage * (1 - target.perk2_vanguard_muscle_bulletproof.life_damage_reduction), 0);
      this.journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_vanguard_muscle_bulletproof' });
    }
    // 방패 숙달 퍽
    if (target.perk2_vanguard_shield_mastery && target.shield > 0) {
      penetrate = Math.max(penetrate * (1 - target.perk2_vanguard_shield_mastery.shield_damage_reduction), 0);
      this.journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_vanguard_shield_mastery' });
    }
    // 정신력에 따른 피해량 감소
    if (target.damage_reduction) {
      damage = Math.max(damage * (1 - target.damage_reduction), 0);
    }

    if (target.penetrate_reduction) {
      penetrate = Math.max(penetrate * (1 - target.penetrate_reduction), 0);
    }

    // 실드 무시 옵션
    if (option_ignore_shield) {
      hit_shield = false;
    }

    // 최대효율 활용 퍽
    if (target.perk2_vanguard_full_efficiency && target.perk2_vanguard_full_efficiency.expires_at) {
      if (!target.perk2_vanguard_full_efficiency.expires_at.expired(tick)) {
        damage = 0;
        penetrate = 0;
      }
    }

    // 전열 수비 퍽
    if (target.perk2_breacher_frontline) {
      const perk = target.perk2_breacher_frontline;
      if (perk.expires_at && !perk.expires_at.expired(tick)) {
        damage = Math.max(damage * (1 - perk.activation_damage_reduction), 0);
        penetrate = Math.max(penetrate * (1 - perk.activation_penetrate_reduction), 0);
      }
    }

    // 방탄 무효화
    if (entity.perk2_breacher_shield_disable && entity.perk2_breacher_shield_disable.is_breaching) {
      penetrate = target.shield_max * entity.perk2_breacher_shield_disable.shield_break_rate;
      entity.perk2_breacher_shield_disable.is_breaching = false;
      hit_shield = true;
    }

    let damage0 = damage;
    const penetrate0 = penetrate;

    let damage_shield = 0;
    let damage_armor = 0;
    let damage_life = 0
    let damage_total = 0;
    let stop = false;
    hit = true;

    if (option_instant_kill) {
      damage_life = target.life;
      damage_total = damage_life;
      target.life = 0;

      if (target.team === 0 && target.report) {
        target.report.damage_taken.life += damage_life;
      }
      if (target.life === 0) {
        if (target.state !== 'dead') {
          this.entityDown(target);
        }
        kill = true;
      }

      if (entity.team === 0 && entity.report) {
        entity.report.damage_done[ty] += damage_life;
        if (kill) {
          entity.report.kill++;
        }
      }

      // 헤드 샷 발동
      if (options.includes('head-shot')) {
        const perk = entity.perk2_sharpshooter_threat;
        if (perk && perk.speed_rate_reduction) {
          const duration = this.ticksFromSec(5);
          const speed_mult = perk.speed_rate_reduction;
          const enemies = this.entities.filter((e) => e.state !== 'dead' && e.team === target.team);
          for (const enemy of enemies) {
            if (enemy.pos.dist(target.pos) > 50) {
              continue;
            }

            if (!enemy.perk2_sharpshooter_threat) {
              enemy.perk2_sharpshooter_threat = {};
            }

            const perk = enemy.perk2_sharpshooter_threat;
            perk.expires_at = new TickTimer(tick, duration);
            perk.speed_rate_reduction = speed_mult;

            const effect = enemy.add_effect('perk2_sharpshooter_threat');
            effect.expire_at = tick + duration;
            effect.speed_rate_reduction = speed_mult;
          }
        }
      }

      if (target.team === 1) {
        this.damageIndicationPush(target, damage_total);
      }

      return {
        kill,
        hit,
        damage: damage_life,
        penetrate: 0,
        damage_shield: 0,
        damage_armor: 0,
        damage_life,
        stop,
      };
    }

    if (hit_shield) {
      // 실드 충전 퍽
      if (target.perk2_breacher_shield_regen) {
        const perk = target.perk2_breacher_shield_regen;
        if (perk.charge_shield && perk.charge_shield > 0) {
          const diff = Math.min(penetrate, perk.charge_shield);

          damage_total += diff;
          damage_shield += diff;
          penetrate -= diff;
          target.shield -= diff;
          perk.charge_shield -= diff;
        }
      }
      // 긴급 구호 퍽
      if (target.perk2_medic_emergency_rescue) {
        const perk = target.perk2_medic_emergency_rescue;
        if (perk.charge_shield && perk.charge_shield > 0) {
          const diff = Math.min(perk.charge_shield, penetrate);

          damage_total += diff;
          damage_shield += diff;
          penetrate -= diff;
          target.shield -= diff;
          perk.charge_shield -= diff;
        }
      }

      const diff = Math.min(penetrate, target.shield);

      damage_total += diff;
      if (!option_penetrate_loss_damage && penetrate > target.shield) {
        damage_total += penetrate - target.shield;
      }

      damage_shield += diff;
      penetrate -= diff;
      target.shield -= diff;

      if (target.team === 0 && target.report) {
        target.report.damage_taken.shield += damage_shield;
      }

      // 최대효율 활용 퍽
      if (target.perk2_vanguard_full_efficiency && damage_shield > 0 && target.shield === 0) {
        const { duration_seconds } = target.perk2_vanguard_full_efficiency;
        target.perk2_vanguard_full_efficiency.expires_at = new TickTimer(tick, this.ticksFromSec(duration_seconds));
        this.journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_vanguard_full_efficiency' });

        const effect = target.add_effect('perk2_vanguard_full_efficiency');
        effect.expire_at = tick + this.ticksFromSec(duration_seconds);
        effect.invincible = true;
      }
    }
    if (option_penetrate_loss_damage) {
      damage += penetrate;
      damage0 += penetrate;
    }
    if (!hit_shield || option_with_damage) {

      stop = (damage_armor > 0 && entity.firearm_armor_stop);
      if (!stop) {
        if (target.perk2_common_physical_regist) {
          this.journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_physical_regist', targets: [target] });
          damage = Math.max(damage * (1 - target.perk2_common_physical_regist.all_damage_reduction), 0);
        }
        const diff = Math.min(target.life, damage);
        damage_life = diff;
        damage_total += diff;
        if (damage > target.life) {
          damage_total += damage - target.life;
        }
        if (damage_life >= target.life && target.perk2_common_physical_power_overwhelming) {
          if (!target.invincTick) {
            this.journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_physical_power_overwhelming', targets: [target] });

            const duration = this.ticksFromSec(target.perk2_common_physical_power_overwhelming.duration_seconds);
            target.invincTick = new TickTimer(tick, duration);

            const effect = target.add_effect('perk2_common_physical_power_overwhelming');
            effect.expire_at = tick + duration;
            effect.invincible = true;
          }
          if (!target.invincTick.expired(this.tick)) {
            damage_life = target.life - 1;
          }
        }

        damage -= damage_life;
        target.life = target.life - damage_life;
        if (target.perk2_common_mental_survival_instinct) {
          const thres = target.perk2_common_mental_survival_instinct.life_threshold;
          if (target.life <= target.life_max * thres && !target.avoidanceTick) {
            const duration = this.ticksFromSec(target.perk2_common_mental_survival_instinct.duration_seconds);
            target.avoidanceTick = new TickTimer(this.tick, duration);

            const effect = target.add_effect('perk2_common_mental_survival_instinct');
            effect.expire_at = tick + duration;
            effect.accuracy_mult = target.perk2_common_mental_survival_instinct.evasion_rate_increase;
          }
        }

        if (target.team === 0 && target.report) {
          target.report.damage_taken.life += damage_life;
        }

        if (entity.perk_hit_antiarmor) {
          this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_hit_antiarmor', targets: [target] });
          target.armor = Math.max(0, target.armor - damage_life);
        }
      }

      if (target.life === 0) {
        if (target.state !== 'dead') {
          this.entityDown(target);
        }
        kill = true;
      }
    }

    // 포식자 퍽
    if (entity.perk2_common_return_ammo_on_kill && kill) {
      const ammo_addtive = Math.ceil(entity.ammo * entity.perk2_common_return_ammo_on_kill.ammo_mult);
      entity.ammo = Math.min(entity.firearm_ammo_max, entity.ammo + ammo_addtive);
    }
    // 실드 충전 퍽
    if (entity.perk2_breacher_shield_regen && hit) {
      const perk = entity.perk2_breacher_shield_regen;
      if (perk.charge_shield === undefined) {
        perk.charge_shield = 0;
        perk.charge_shield_max = 0;
      }
      perk.charge_shield += perk.shield_amount;
      perk.charge_shield_max += perk.shield_amount;

      entity.shield += perk.shield_amount;
      entity.shield_max += perk.shield_amount;
    }
    // 블러드 레이지 퍽
    if (entity.perk2_pointman_blood_rage && damage_life > 0) {
      if (!entity.perk2_pointman_blood_rage.activation_mult) {
        entity.perk2_pointman_blood_rage.activation_mult = 0;
      }
      entity.perk2_pointman_blood_rage.activation_mult += entity.perk2_pointman_blood_rage.shootpattern_mult_increase;

      const effect = entity.add_effect('perk2_pointman_blood_rage');
      effect.shootpattern_mult_increase = entity.perk2_pointman_blood_rage.activation_mult;
    }
    // 전투 고양 퍽
    if (entity.perk2_pointman_battle_excite && damage_shield > 0) {
      if (!entity.perk2_pointman_battle_excite.activation_mult) {
        entity.perk2_pointman_battle_excite.activation_mult = 0;
      }
      entity.perk2_pointman_battle_excite.activation_mult += entity.perk2_pointman_battle_excite.shootpattern_mult_increase;

      const effect = entity.add_effect('perk2_pointman_battle_excite');
      effect.shootpattern_mult_increase = entity.perk2_pointman_battle_excite.activation_mult;
    }
    // 명경지수 퍽
    if (damage_shield > 0 || damage_armor > 0 || damage_life > 0) {
      if (target.perk2_common_mental_calmstate) {
        if (rng.range(0, 1) < target.perk2_common_mental_calmstate.activation_prob) {
          target.perk2_common_mental_calmstate.activation = true;

          const effect = target.add_effect('perk2_common_mental_calmstate');
          effect.evasion_rate_increase = 0.2;
        }
      }
    }

    // 충격과 공포 퍽
    if (entity.perk2_breacher_shock_and_awe && entity.firearm_ty === 'sg' && hit) {
      const expire_at = tick + this.ticksFromSec(entity.perk2_breacher_shock_and_awe.duration_seconds);
      const found = target.effects.find((e) => e.effect_ty === 'stun_gr');
      if (found) {
        found.expire_at = Math.max(found.expire_at, expire_at);
      } else {
        target.effects.push({
          effect_ty: 'stun_gr',
          expire_at,
        });
      }
    }

    if (entity.team === 0 && entity.report) {
      entity.report.damage_done[ty] += (damage0 - damage) + (penetrate0 - penetrate);
      if (kill) {
        entity.report.kill++;
      }
    }

    if (target.team === 1) {
      this.damageIndicationPush(target, damage_total);
    }

    return {
      kill,
      hit,
      damage: damage0 - damage,
      penetrate: penetrate0 - penetrate,
      damage_shield,
      damage_armor,
      damage_life,
      stop,
    };
  }

  entityDown(entity) {
    const { tick } = this;

    entity.state = 'dead';
    entity.deadTick = tick;
    this.entityUnaim(entity);

    if (entity.reserve_throwable) {
      entity.reserve_throwable = undefined;
    }

    if (entity.team === 0) {
      const features = this.controlsFeatures();
      if (!features.riskdir) {
        this.controlSet(0, 'riskdir', false);
      }
      if (!features.door_entry) {
        this.controlSet(0, 'door_entry', false);
      }
      if (!features.throwable_cooking) {
        this.controlSet(0, 'throwable_cooking', false);
      }

      // 아군 오퍼레이터 사망 시 스탯 분배 기능
      if (opts.EXP_DEATH_STAT_DISTRIBUTE) {
        const allies = this.entities.filter((e) => e.team === 0 && e.state !== 'dead');
        const len = allies.length;
        const bonus_stat_ratio = opts.EXP_DEATH_STAT_DISTRIBUTE_RATIO;
        for (const ally of allies) {
          const { _stat } = ally;
          _stat.physical += entity._stat.physical * bonus_stat_ratio.physical / len;
          _stat.mental += entity._stat.mental * bonus_stat_ratio.mental / len;
          _stat.shooting += entity._stat.shooting * bonus_stat_ratio.shooting / len;
          _stat.perception += entity._stat.perception * bonus_stat_ratio.perception / len;
          _stat.tactical += entity._stat.tactical * bonus_stat_ratio.tactical / len;

          primaryStatApply(ally, ally._stat, ally._firearm_stat, true);
        }
      }

      this.onTriggerAllyDead();
    }
  }

  entityRangePenalty(entity, dist) {
    const { firearm_range } = entity;
    let { firearm_range_optimal_min, firearm_range_optimal_max } = entity;
    // 웨폰 마스터 퍽
    if (entity.perk2_sharpshooter_weapon_master) {
      firearm_range_optimal_min = 0;
      firearm_range_optimal_max = 1;
    }
    if (dist < firearm_range * firearm_range_optimal_min ||
      dist > firearm_range * firearm_range_optimal_max) {
      return true;
    }
    return false;
  }

  entitySampleRayDir(entity, target, hit) {
    const { rng } = this;

    const d = target.pos.sub(entity.pos);
    const dist = d.len();
    if (dist === 0) {
      return rng.range(0, Math.PI * 2);
    }

    const targetdir = dirnorm(d.dir());

    const aim_arc_len = target.size / dist;
    let aimdirmin = targetdir;
    let aimdirmax = targetdir + aim_arc_len / 2;
    if (!hit) {
      aimdirmin = targetdir + aim_arc_len * (0.5 + opts.TEST_TRAIL_RECONSTRUCT_MISS_DIST_MIN);
      aimdirmax = targetdir + aim_arc_len * (0.5 + opts.TEST_TRAIL_RECONSTRUCT_MISS_DIST_MAX);
    }

    let aimdir = rng.range(aimdirmin, aimdirmax);
    if (rng.range(0, 1) < 0.5) {
      aimdir = entity.aimdir - (aimdir - entity.aimdir);
    }
    return dirnorm(aimdir);
  }

  // 패턴 당 퍽 적용 시작
  entityHandleFireShootPatternStarted(entity, target, args) {
    entity.shootPatternPerk = {};
    const { shootPatternPerk } = entity;

    const { rng, journal, tick } = this;

    const pattern = entity.firearm_shoot_pattern;
    const pattern_len = pattern.length + 1;

    const target_dist = entity.pos.dist(target.pos);

    // 거리가 가까우면 스탯이 상승하는 시스템
    {
      shootPatternPerk.close_distance = {};
      const { close_distance } = shootPatternPerk;

      if (opts.EXP_CLOSE_DISATNCE_STAT_INCREASE) {
        if (entity.team === 0 && target_dist < opts.DISTANCE_CHECK_CLOSE) {
          const ratio = (opts.DISTANCE_CHECK_CLOSE - target_dist) / opts.DISTANCE_CHECK_CLOSE;

          // 데미지
          close_distance.damage_mult = ratio * (opts.IF_CLOSE_DAMAGE_INCREASE_AMOUNT);
          {
            const dmg = ratio * opts.IF_CLOSE_DAMAGE_INCREASE_ENEMY_HP * target.life_max;
            const div_count = pattern_len * entity.firearm_projectile_per_shoot;
            close_distance.damage_amount = div_count !== 0 ? (dmg / div_count) : 0;
          }

          // 명중률
          close_distance.accuracy = ratio * opts.IF_CLOSE_ACCURACY_INCREASE_AMOUNT;
        }
      }
    }

    // 명경지수 퍽
    {
      if (entity.perk2_common_mental_calmstate && entity.perk2_common_mental_calmstate.activation) {
        shootPatternPerk.perk2_common_mental_calmstate = {};

        const { perk2_common_mental_calmstate } = shootPatternPerk;
        perk2_common_mental_calmstate.evasion_rate_increase = 0.2;

        entity.perk2_common_mental_calmstate.activation = false;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_mental_calmstate' });
        entity.remove_effect('perk2_common_mental_calmstate');
      }
    }

    // 조준사격 퍽
    {
      if (entity.perk2_sharpshooter_aim_down_sights && entity.firearm_ty === 'dmr') {
        if (rng.range(0, 1) < entity.perk2_sharpshooter_aim_down_sights.activation_prob) {
          shootPatternPerk.perk2_sharpshooter_aim_down_sights = {};
          this.pushJournal({ tick, ty: 'perk', entity, perk: 'perk2_sharpshooter_aim_down_sights' });
        }
      }
    }

    // 부싯돌 퍽
    {
      // 재장전 후 첫 탄인가?
      const first_shoot = entity.ammo === entity.firearm_ammo_max;
      const perk = entity.perk2_common_first_shot_crit;

      if (perk && first_shoot) {
        shootPatternPerk.perk2_common_first_shot_crit = {};
      }
    }

    // 일발역전 퍽
    {
      const perk = entity.perk2_common_last_shot_crit;
      if (perk && entity.ammo <= pattern_len) {
        shootPatternPerk.perk2_common_last_shot_crit = {};
      }
    }

    // 충분한 보급 퍽
    {
      if (entity.perk2_common_half_over_mag && entity.ammo * 2 >= entity.firearm_ammo_max) {
        shootPatternPerk.perk2_common_half_over_mag = {};
      }
    }
  }

  // 패턴 당 퍽 적용 종료
  entityHandleFireShootPatternEnded(entity, target, args) {
    const { shootPatternPerk } = entity;
    if (!shootPatternPerk) {
      return;
    }

    if (shootPatternPerk.perk2_common_first_shot_crit) {
      entity.remove_effect('perk2_common_first_shot_crit');
    }

    if (shootPatternPerk.perk2_common_last_shot_crit) {
      entity.remove_effect('perk2_common_last_shot_crit');
    }

    entity.shootPatternPerk = {};
  }

  entityHandleFire_Prob(entity, target, args) {
    const { tick, journal, rng } = this;
    const { cover, effect, controls, controls0 } = args;
    const target_dist = entity.pos.dist(target.pos);

    const { shootPatternPerk } = entity;

    // ----- check_cover_prob -----
    let check_cover_prob = true;
    if (entity.perk_piercing_bullet) {
      if (!entity.perk_lastshoot || entity.ammo > 0) {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_piercing_bullet', targets: [target] });
      }
      check_cover_prob = false;
    }
    if (entity.shield > 0) {
      check_cover_prob = false;
    }
    if (entity.perk_lastshoot && entity.ammo === 0) {
      check_cover_prob = false;
    }
    // 조준 사격 퍽
    if (shootPatternPerk.perk2_sharpshooter_aim_down_sights) {
      check_cover_prob = false;
    }

    // ----- prob -----
    let prob = target.hit_prob_stand;
    if (target.perk_move_evade && target.movespeed > 0) {
      prob *= 0.9;
    }

    // ----- about check_cover_prob -----
    if (check_cover_prob) {
      let effcover = cover;
      if (target.perk_move_cover && target.movespeed > 0) {
        journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_move_cover' });
        effcover = Math.max(effcover, 2);
      }

      if (target.perk_standing_evade && effcover === 0) {
        journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_standing_evade' });
        prob *= 0.5;
      }

      if (effcover === 1) {
        // obstructed
        let ignore_obs = false;
        if (entity.perk_shoot_ignore_obstructed) {
          this.pushJournal({ tick, ty: 'perk', entity, perk: 'perk_shoot_ignore_obstructed' });
          ignore_obs = true;
        } else if (entity.perk_pierce_moving_enemy && target.movespeed > 0) {
          // TODO: 가려짐 효과 무시
          ignore_obs = true;
        }
        if (!ignore_obs) {
          prob = target.hit_prob_obstructed;
        }
      } else if (effcover === 2) {
        if (target.state === 'hide') {
          prob = opts.HIT_PROB_HIDE;
        } else {
          prob = target.hit_prob_covered;

          // 교전 시간이 일정 시간 초과 시 스탯이 보정되는 기능입니다.
          if (opts.EXP_EXCEED_ENGAGED_STAT_ADDITION) {
            let engaged_sec = this.controls(entity.spawnarea).engaged_duration;
            if (entity.team === 0 && engaged_sec >= opts.ENGAGED_STAT_ADDITION_PHASE1_TIME_LIMIT) {
              const prob_increased = Math.floor(engaged_sec - opts.ENGAGED_STAT_ADDITION_PHASE1_TIME_LIMIT) * opts.ENGAGED_STAT_ADDITION_PROB_COVERED;
              prob += prob_increased;
            }
          }
          if (target.perk2_common_vision_effective_cover) {
            // this.journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_vision_effective_cover', targets: [target] }); // 로그 너무 많이 찍힘
            prob = Math.max(prob - target.perk2_common_vision_effective_cover.evasion_rate_increase, 0);
          }
          if (entity.perk_reduce_cover_effect) {
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_reduce_cover_effect', targets: [target] });
            prob *= 1.5;
          }
        }
      } else if (effcover === 3) {
        // blocked
        prob = 0;
      }
      if (target.state === 'crawl') {
        prob = opts.HIT_PROB_CRAWL;
      }

      if (target.perk_cover_effect) {
        prob *= 0.5;
        journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_cover_effect', targets: [entity] });
      }
    }

    // 총기 종류에 따른 명중 확률 보정 적용
    prob += entity.firearm_additional_hit_prob;

    // ----- accuracy -----
    let accuracy = entity.accuracy;

    // 기동사격 숙달 퍽
    if (entity.perk2_pointman_manoeuvre_shooting && entity.movespeed > 0) {
      accuracy *= entity.perk2_pointman_manoeuvre_shooting.accuracy_rate_increase;
    }

    // 테스트용 전탄사격 퍽
    if (entity.perk2_test_fire_all && !entity.perk2_test_fire_all.denied_firearm_tys.includes(entity.firearm_ty)) {
      accuracy *= entity.perk2_test_fire_all.accuracy_mult;
    }

    // 정확도 강화 퍽
    if (entity.perk2_common_increase_acc) {
      accuracy += entity.perk2_common_increase_acc.accuracy_rate_increase;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_common_increase_acc' });
    }
    // 충분한 보급 퍽
    if (shootPatternPerk.perk2_common_half_over_mag) {
      accuracy += entity.perk2_common_half_over_mag.accuracy_rate_increase;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_common_half_over_mag' });
    }
    // 명경지수 퍽
    if (shootPatternPerk.perk2_common_mental_calmstate) {
      const { perk2_common_mental_calmstate } = shootPatternPerk;
      if (perk2_common_mental_calmstate.evasion_rate_increase) {
        accuracy += perk2_common_mental_calmstate.evasion_rate_increase;
      }
    }
    // 기분파 퍽
    if (entity.perk2_common_increase_acc_per_mood) {
      const perk = entity.perk2_common_increase_acc_per_mood;
      if (perk.mood >= 40) {
        accuracy += 0.05;
        accuracy += Math.max(0, Math.floor((perk.mood - 40)) / 20) * perk.accuracy_rate_increase;
      }
    }

    // 교전 시간이 일정 시간 초과 시 스탯이 보정되는 기능입니다.
    if (opts.EXP_EXCEED_ENGAGED_STAT_ADDITION) {
      const engaged_sec = this.controls(entity.spawnarea).engaged_duration;
      if (entity.team === 0) {
        const remain = Math.max(0.0, 1 - target.hit_prob_covered);
        const sec = remain / opts.ENGAGED_STAT_ADDITION_PROB_COVERED;
        const phase2_time = engaged_sec - sec - opts.ENGAGED_STAT_ADDITION_PHASE1_TIME_LIMIT;
        if (phase2_time >= opts.ENGAGED_STAT_ADDITION_PHASE2_TIME_LIMIT) {
          const accuracy_increased = Math.floor(phase2_time - opts.ENGAGED_STAT_ADDITION_PHASE2_TIME_LIMIT + 1) * opts.ENGAGED_STAT_ADDITION_ACCURACY;
          accuracy += accuracy_increased;
        }
      }
    }

    // 거리가 가까우면 스탯이 상승하는 시스템
    if (shootPatternPerk.close_distance) {
      if (shootPatternPerk.close_distance.accuracy) {
        accuracy += shootPatternPerk.close_distance.accuracy;
      }
    }

    // 효과적인 제압
    if (controls0.firepolicy === 'supress') {
      const allies_aimtarget = this.entities.filter((e) => e.aimtarget === entity);
      const has_perk_allies = allies_aimtarget.filter((e) => e.perk2_common_strategy_supress_decrease_avoid);
      const sum_accuracy = has_perk_allies.reduce((ret, e) => ret + e.perk2_common_strategy_supress_decrease_avoid.accuracy_rate_reduction, 0);
      if (sum_accuracy > 0) {
        accuracy -= sum_accuracy;
        for (const ally of has_perk_allies) {
          journal.push({ tick, ty: 'perk', entity: ally, perk: 'perk2_common_strategy_supress_decrease_avoid', targets: [entity] });
        }
      }
    }

    // ---- evasion -----
    let evasion = target.evasion;

    // 생존본능 퍽
    if (target.perk2_common_mental_survival_instinct && target.avoidanceTick && !target.avoidanceTick.expired(tick)) {
      evasion += target.perk2_common_mental_survival_instinct.evasion_rate_increase;
    }

    // 신중함 퍽
    if (target.perk2_common_half_under_mag && target.ammo * 2 < target.firearm_ammo_max) {
      evasion += target.perk2_common_half_under_mag.evasion_rate_increase;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_common_half_under_mag' });
    }

    // 회피 강화 퍽
    if (target.perk2_common_increase_avoid) {
      evasion += target.perk2_common_increase_avoid.evasion_rate_increase;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk2_common_increase_avoid' });
    }

    // 다이나믹 엔트리 퍽
    if (target.perk2_common_intelligence_dynamic_entry && target.breachBuffTick && !target.breachBuffTick.expired(tick)) {
      evasion -= target.perk2_common_intelligence_dynamic_entry.evasion_rate_reduction;
    }

    // 표적 지정 퍽
    if (entity.perk2_common_strategy_pair_increase_acc && controls.firepolicy === 'pair') {
      const allies = this.entities.filter((e) => e.state !== 'dead' && e.team === entity.team && e.aimtarget === target);
      let evasion_mult = 0;
      for (const ally of allies) {
        const perk = ally.perk2_common_strategy_pair_increase_acc;
        if (!perk) {
          continue;
        }
        evasion_mult += perk.evasion_rate_reduction;
      }

      if (allies.length > 1) {
        evasion -= evasion_mult;
      }
    }

    evasion = clamp(evasion, 0, 1);

    // accuracy/evation 보정
    // accuracy 0.9, evation 0.1일 때 0.2 만큼 줄어야 함
    prob += accuracy - (evasion + 1.0);

    if (effect) {
      prob -= opts.SMOKE_HIT_PROB_PANALTY;
    }

    if (target.perk_cover_reload_evade && !target.reloadTick.expired(tick)) {
      prob = 0;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_cover_reload_evade' });
    } else {
      if (entity.perk_firstshoot_hit && entity.ammo === entity.firearm_ammo_max - 1) {
        prob = 1;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_firstshoot_hit' });
      }
      if ((entity.perk_lastshoot || entity.perk_lastshoot_hit) && entity.ammo === 0) {
        prob = 1;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot_hit' });
      }
    }

    // 총기 적성 | 최종 명중률 멀티플라이어
    if (entity.firearm_aptitude_accuracy_multiplier) {
      prob *= entity.firearm_aptitude_accuracy_multiplier;
    }

    prob = clamp(prob, 0, 1);

    return {
      prob,
      accuracy,
    };
  }

  entityHandleFire_Damage(entity, target, args) {
    const { journal, tick, rng } = this;
    const { hit, accuracy, stable_mult, cover, controls, controls0 } = args;

    const { shootPatternPerk } = entity;

    const d = target.pos.sub(entity.pos);
    const dist = d.len();
    const target_dist = entity.pos.dist(target.pos);

    let damage = 0;
    let penetrate = 0;
    let penalty = false;
    let crit = false;

    if (hit) {
      damage = entity.firearm_projectile_damage + (entity.additional_damage ?? 0);
      penetrate = entity.firearm_projectile_penetrate;

      // ----- crit, crit_prob -----
      let crit_prob = entity.crit_prob;
      if (entity.perk_critical_add) {
        crit_prob += 0.02;
      }
      if (entity.perk2_vanguard_mozambique_drill && entity.firearm_ty === 'hg') {
        if (entity.shootPatternIdx == entity.perk2_vanguard_mozambique_drill.shoot_pattern.length) {
          journal.push({ tick, ty: 'perk', entity, perk: 'perk2_vanguard_mozambique_drill', targets: [target] });
          crit_prob += entity.perk2_vanguard_mozambique_drill.crit_prob;
          damage *= entity.firearm_crit_mult + entity.perk2_vanguard_mozambique_drill.crit_damage_rate;
        }
      }
      // 죽거나 죽이거나 퍽
      if (entity.perk2_common_mental_kill_or_be_killed && entity.life <= entity.life_max * entity.perk2_common_mental_kill_or_be_killed.life_threshold) {
        crit_prob += entity.perk2_common_mental_kill_or_be_killed.crit_prob;
      }
      // 조준 사격 숙달 퍽
      if (entity.perk2_sharpshooter_ads_mastery) {
        crit_prob += entity.perk2_sharpshooter_ads_mastery.crit_prob;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_sharpshooter_ads_mastery', targets: [target] });
      }
      if (entity.team == 0) {
        if (accuracy > 1) {
          crit_prob += (accuracy - 1);
        }
        crit_prob += entity.firearm_additional_crit_prob;
      }
      if (entity.team !== 0) {
        crit_prob = 0;
      }
      if (rng.range(0, 1) < crit_prob * stable_mult) {
        crit = true;
      }
      if (target.perk_glancing_blow && rng.range(0, 1) < 0.2) {
        crit = false;
      }

      penalty = this.entityRangePenalty(entity, dist);
      if (penalty) {
        damage *= entity.firearm_range_penalty_mult;
        crit = false;
      }

      // 부싯돌 퍽
      if (shootPatternPerk.perk2_common_first_shot_crit) {
        crit = true;
      }

      if (crit) {
        damage = damage * entity.firearm_crit_mult;
        this.InvokeCameraEventCrit(target.pos);
      }

      let damage_mult = 1;
      let penetrate_mult = 1;

      // ----- damage and penetrate mult -----
      // 트리거 해피 퍽
      if (entity.perk2_common_strategy_aggressive_dmg) {
        if (controls && controls.firepolicy === 'aggressive') {
          damage_mult += entity.perk2_common_strategy_aggressive_dmg.all_atk_damage_rate_increase;
          penetrate_mult += entity.perk2_common_strategy_aggressive_dmg.all_atk_damage_rate_increase;
          journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_strategy_aggressive_dmg', targets: [target] });
        }
      }
      if (shootPatternPerk.perk2_common_last_shot_crit) {
        damage_mult += entity.perk2_common_last_shot_crit.all_atk_damage_rate_increase;
        penetrate_mult += entity.perk2_common_last_shot_crit.all_atk_damage_rate_increase;
      }
      // 부싯돌 퍽
      if (shootPatternPerk.perk2_common_first_shot_crit) {
        damage_mult += entity.perk2_common_first_shot_crit.all_atk_damage_rate_increase;
        penetrate_mult += entity.perk2_common_first_shot_crit.all_atk_damage_rate_increase;
      }
      if (entity.perk2_medic_stimpack) {
        const perk = entity.perk2_medic_stimpack;
        if (perk.activate_damage_mult) damage_mult += perk.activate_damage_mult;
        if (perk.activate_penetrate_mult) penetrate_mult += perk.activate_penetrate_mult;
      }
      // 화력 강화 퍽
      if (entity.perk2_common_increase_dmg) {
        damage_mult += entity.perk2_common_increase_dmg.all_atk_damage_rate_increase;
        penetrate_mult += entity.perk2_common_increase_dmg.all_atk_damage_rate_increase;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_increase_dmg', targets: [target] });
      }
      // 다이나믹 엔트리 퍽
      if (target.perk2_common_intelligence_dynamic_entry && target.breachBuffTick && !target.breachBuffTick.expired(tick)) {
        damage_mult += target.perk2_common_intelligence_dynamic_entry.all_atk_damage_rate_increase;
        penetrate_mult += target.perk2_common_intelligence_dynamic_entry.all_atk_damage_rate_increase;
      }
      // 고속 탄자 퍽
      if (entity.perk2_sharpshooter_high_velocity) {
        damage_mult += entity.perk2_sharpshooter_high_velocity.all_atk_damage_rate_increase;
        penetrate_mult += entity.perk2_sharpshooter_high_velocity.all_atk_damage_rate_increase;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_sharpshooter_high_velocity', targets: [target] });
      }

      // ----- damge_mult -----
      if (entity.perk_damage_standing && cover === 0 && target.movespeed === 0) {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_damage_standing', targets: [target] });
        damage_mult += 0.15;
      }

      if (entity.perk_damage_move_crawl && (target.movespeed > 0 || target.state === 'crawl')) {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_damage_move_crawl', targets: [target] });
        damage_mult += 0.15;
      }

      if (entity.perk_lastshoot && entity.ammo === 0) {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot', targets: [target] });
        damage_mult += 0.50;
      }
      if (entity.perk2_pointman_ar_specialization) {
        damage_mult += entity.perk2_pointman_ar_specialization.life_atk_damage_rate_increase;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_pointman_ar_specialization', targets: [target] });
      }

      //거리가 가까우면 데미지가 상승하는 기능
      if (shootPatternPerk.close_distance) {
        if (shootPatternPerk.close_distance.damage_mult) {
          damage_mult += shootPatternPerk.close_distance.damage_mult;
        }
      }

      // ----- damage -----
      damage = Math.floor(damage * damage_mult);


      // 먼저, 확률적 피해량 모델을 적용합니다.
      if (entity.firearm_projectile_damage_prob) {
        let damage_prob = rng.weighted_key(entity.firearm_projectile_damage_prob, 'weight');
        damage = Math.floor(damage * damage_prob.multiplier);
      }
      if (entity.perk2_sharpshooter_snipe_mastery && entity.firearm_ty === 'dmr') {
        damage = Math.floor(damage * entity.perk2_sharpshooter_snipe_mastery.all_atk_damage_rate_increase);
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_sharpshooter_snipe_mastery' });
      }

      if (entity.perk_desmar_damage && entity.firearm_ty === 'dmr') {
        damage = Math.floor(damage * 1.2);
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_damage' });
      }

      if (entity.perk_firstshoot_amp && entity.ammo === entity.firearm_ammo_max - 1) {
        damage += damage;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_firstshoot_amp' });
      }
      if (entity.perk_lastshoot_amp && entity.ammo === 0) {
        damage += damage;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot_amp' });
      }

      if (entity.perk_sr_critical && rng.range(0, 1) < 0.2) {
        damage += damage;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_sr_critical', targets: [target] });
      }

      //거리가 가까우면 데미지가 상승하는 기능
      if (shootPatternPerk.close_distance) {
        if (shootPatternPerk.close_distance.damage_amount) {
          damage += shootPatternPerk.close_distance.damage_amount;
        }
      }

      // ----- penetrate ----
      if (entity.perk2_pointman_smg_specialization) {
        penetrate_mult += entity.perk2_pointman_smg_specialization.shield_atk_damage_rate_increase;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_pointman_smg_specialization', targets: [target] });
      }
      // 장비 파괴자 퍽
      if (entity.perk2_common_shield_breaker) {
        penetrate_mult += entity.perk2_common_shield_breaker.shield_atk_damage_rate_increase;
      }

      penetrate = Math.floor(penetrate * penetrate_mult);
    } else {
      damage = entity.firearm_projectile_damage + (entity.additional_damage ?? 0);
      penetrate = entity.firearm_projectile_penetrate;
    }

    return {
      damage,
      penetrate,
      penalty,
      crit,
    }
  }

  entityHandleFire_NextShootSeconds(entity) {
    // 모잠비크 드릴 퍽
    let pattern = entity.firearm_shoot_pattern;
    if (entity.perk2_vanguard_mozambique_drill && entity.firearm_ty === 'hg') {
      pattern = entity.perk2_vanguard_mozambique_drill.shoot_pattern;
    }
    // 전탄사격 테스트용 퍽
    if (entity.perk2_test_fire_all && !entity.perk2_test_fire_all.denied_firearm_tys.includes(entity.firearm_ty)) {
      pattern = entity.perk2_test_fire_all.shoot_pattern;
    }

    let nextShootSeconds = entity.firearm_shoot_pattern_interval_sec;
    if (entity.shootPatternIdx < pattern.length) {
      nextShootSeconds = pattern[entity.shootPatternIdx];

      entity.shootPatternIdx += 1;
    } else {
      entity.shootPatternIdx = 0;
    }
    // 전탄사격 테스트용 퍽
    if (entity.perk2_test_fire_all && !entity.perk2_test_fire_all.denied_firearm_tys.includes(entity.firearm_ty)) {
      nextShootSeconds = entity.perk2_test_fire_all.shootpattern_mult;
    }

    // 다표적 교전 퍽
    if (entity.perk2_pointman_multitarget_engagement) {
      nextShootSeconds /= entity.perk2_pointman_multitarget_engagement.shootpattern_mult_increase;
      // const maxReduceMult = 0.5
      // nextShootSeconds *= maxReduceMult + ((1 - maxReduceMult) - (1 - maxReduceMult) * entity._stat.shooting / 100);
    }
    // 사격 숙련 퍽
    if (entity.perk2_sharpshooter_shoot_mastery && entity.firearm_ty === 'dmr') {
      nextShootSeconds /= entity.perk2_sharpshooter_shoot_mastery.shootpattern_mult_increase;
    }
    // 헤드 샷 퍽
    if (entity.perk2_sharpshooter_headshot) {
      nextShootSeconds *= entity.perk2_sharpshooter_headshot.shootpattern_mult_reduction;
    }
    // 전투 고양 퍽
    if (entity.perk2_pointman_battle_excite) {
      const mult = entity.perk2_pointman_battle_excite.activation_mult;
      if (mult) {
        nextShootSeconds /= (1 + mult);
      }
    }

    return { nextShootSeconds };
  }

  entityHandleFire(entity) {
    const { rng, tick, routes, journal, trails } = this;
    const controls = this.controls(entity.spawnarea);
    const controls0 = this.controls(0);
    const obstacles = this.obstacles.filter((o) => o.ty === 'full');

    const target = entity.aimtarget;
    const d = target.pos.sub(entity.pos);
    const dist = d.len();
    const targetdir = dirnorm(d.dir());

    entity.aimtargetmeta = {
      optimal: !this.entityRangePenalty(entity, dist),
      dist: dist,
    };

    //피해 분산 상황에서 뒤로 가는 포지션이면 사격 중지
    if (entity.waypoint_rule.hold_fire) {
      return { aimvalid: false };
    }

    // 만약 fire_scheduler_queue에 entity, target에 대한 처리를 기다리고 있을 경우
    if (
      this.fire_scheduler_queue.find((element, idx, arr) => {
        return element.entity === entity && element.target === target;
      }) != undefined
    ) {
      this.entityUpdateAim(entity, targetdir);
      // dash 상태에서는 사격 불가
      return { aimvalid: false };
    }

    if (entity.state === 'dash' || this.entityEffect(entity, 'stun_gr')) {
      this.entityUpdateAim(entity, entity.dir);
      // dash 상태에서는 사격 불가
      return { aimvalid: false };
    } else {
      this.entityUpdateAim(entity, targetdir);
    }

    const effect = this.entityEffect(target, 'smoke');

    const aimvar_mult = this.entityAimvarMult(entity);
    const variance = entity.aimvar * aimvar_mult;
    const aimdirmin = entity.aimdir - variance;
    const aimdirmax = entity.aimdir + variance;

    // 사격 통제 상태.
    if (entity.waypoint_rule.ty === 'reorg') {
      return { aimvalid: false };
    }
    if (!entity.healTick.expired(tick) || !entity.collectTick.expired(tick)) {
      return { aimvalid: false };
    }
    if (entity.shootPatternIdx === 0 && entity.allow_fire_control && this.entityFireControlled(entity, rng)) {
      return { aimvalid: false };
    }

    const cover = checkcover(entity.pos, target.pos, routes);

    let aimvalid = dircontains(targetdir, aimdirmin, aimdirmax) && dist < entity.firearm_range && cover !== 3;

    let entity_stopped = entity.state === 'crawl';
    if (!entity_stopped && entity.waypoint) {
      entity_stopped = entity.movespeed === 0;
    }

    let firearm_has_ammo = (entity.firearm?.ammo_total ?? 1) > 0;

    // TODO
    if (entity.reloadTick.expired(tick) // cannot shoot during reload
      && entity.shootPatternTick.expired(tick)
      && entity.ammo > 0
      && firearm_has_ammo
      && aimvalid
      && entity.state !== 'hide'
      && (entity.firearm_ty !== 'sr' || entity_stopped)) {

      if (entity.shootPatternIdx === 0) {
        this.entityHandleFireShootPatternStarted(entity, target);
      }

      const { shootPatternPerk } = entity;

      // shoot
      entity.aimtargetshoots += 1;
      if (entity.perk2_common_shooting_quickdraw) {
        entity.quickdraw_first_bullet = false;
      }

      let { aimvar_incr_per_shoot } = entity;
      if (entity.perk_cover_steady && entity.state === 'cover') {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_cover_steady' });
        aimvar_incr_per_shoot *= 0.8;
      }
      entity.aimmult += aimvar_incr_per_shoot;

      entity.ammo -= 1;
      if (entity.firearm) {
        entity.firearm.ammo_total -= 1;
      }

      const firedir = projectile_dice(rng, entity.pos, target.pos, entity.aimdir, variance, opts.AIM_ITER_FIRE);
      const target_dist = entity.pos.dist(target.pos);

      let perk_suppress_logged = false;

      entity.reloadShootIdleTick = new TickTimer(tick, this.ticksFromSec(entity.firearm_reload_idle_duration));

      let projectile_aimvar = entity.firearm_projectile_aimvar;
      let projectile_per_shoot = entity.firearm_projectile_per_shoot;

      if (entity.perk_sg_projectile) {
        projectile_aimvar *= 1.5;
        projectile_per_shoot *= 2;
      }

      const targetSize = target.shield > 0 ? target.size * SHIELD_ENTITY_SIZE_MULT : target.size;

      // multiple projectiles per shoot
      const trail_sample_rate = opts.TEST_TRAIL_SAMPLE_RATE ?? 1;
      let sample = 1; // include first trail

      // 사격, projectile당 명중/피해량 계산 등을 진행함.
      for (let i = 0; i < projectile_per_shoot; i++) {
        let invis = true;
        if (sample >= 1 || trail_sample_rate == 1) {
          invis = false;
          sample -= 1;
        }
        sample += trail_sample_rate;

        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir, projectile_aimvar, opts.AIM_ITER_PROJECTILE);

        const aim_arc_len = (variance + projectile_aimvar) * target_dist;
        let stable_mult = 1;
        if (opts.STABLE_DAMAGE) {
          stable_mult = Math.min(Math.pow(targetSize / aim_arc_len, 2), 1);
        }

        const variance_min = entity.aimvar_hold_max * aimvar_mult;
        const aim_arc_len_min = (variance_min + projectile_aimvar) * target_dist;
        const stable_mult_max = Math.min(Math.pow(targetSize / aim_arc_len_min, 2), 1);

        const projectile_end = entity.pos.add(v2.fromdir(projectile_dir).mul(entity.firearm_range));
        const t = obstructed_t(entity.pos, projectile_end, obstacles);
        const ray_len = t * entity.firearm_range;

        let valid = false;
        let hit = false;
        let kill = false;

        let { aimvar_incr_per_hit } = entity;
        if (entity.perk_hit_incr_aimvar) {
          aimvar_incr_per_hit *= 2;
        }

        if (target.perk_damp_aimvar_incr && target.firearm_ty !== 'sr') {
          aimvar_incr_per_hit *= 0.1;
        }

        // TODO: HACK: dmr/sr의 경우 명중과 무관하게 supression이 발생합니다.
        if (!['sr', 'dmr'].includes(entity.firearm_ty)) {
          aimvar_incr_per_hit *= stable_mult;
        }

        let aimvar_incr_per_hit_mult = 1.0;
        if (target.perk_aimvar_incr_m5) {
          aimvar_incr_per_hit_mult -= 0.15;
        }
        if (target.perk_aimvar_incr_m10) {
          aimvar_incr_per_hit_mult -= 0.15;
        }

        let aimvar_suppress_mult = 1.0;
        if (target.perk_suppress_m5) {
          aimvar_suppress_mult -= 0.05;
        }
        if (target.perk_suppress_incr_m10) {
          aimvar_suppress_mult -= 0.1;
        }

        let hit0 = ray_len > target_dist && target.life > 0;
        if (!opts.STABLE_DAMAGE) {
          const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
          hit0 = hit0 && dist < targetSize;
        }

        let damage = 0;
        let penetrate = 0;
        let penalty = false;
        let crit = false;

        let { prob, accuracy } = this.entityHandleFire_Prob(entity, target, {
          cover,
          effect,
          controls,
          controls0,
        });

        // 일발역전 퍽
        if (shootPatternPerk.perk2_common_last_shot_crit) {
          prob = 1;
          hit0 = true;
        }

        if (hit0) {
          valid = true;
          hit = rng.range(0, 1) < prob;

          // 충격탄 퍽
          let activate_perk2_sharpshooter_high_grain = false;
          if (entity.perk2_sharpshooter_high_grain && !hit) {
            hit = true;
            activate_perk2_sharpshooter_high_grain = true;
          }

          ({ damage, penetrate, penalty, crit } = this.entityHandleFire_Damage(entity, target, {
            hit,
            accuracy,
            stable_mult,
            cover,
            controls,
            controls0,
          }));

          if (activate_perk2_sharpshooter_high_grain) {
            damage *= entity.perk2_sharpshooter_high_grain.all_atk_damage_rate_increase;
            penetrate *= entity.perk2_sharpshooter_high_grain.all_atk_damage_rate_increase;
          }
        } else if (entity.perk_suppress) {
          target.aimmult += aimvar_incr_per_hit * aimvar_suppress_mult;

          if (!perk_suppress_logged) {
            this.pushJournal({ tick, ty: 'perk', entity, perk: 'perk_suppress' });
          }
          perk_suppress_logged = true;
        }

        const DISTRACTION_CRAWL_DURATION = opts.DISTRACTION_CRAWL_DURATION;
        if (entity.perk_desmar_distraction) {
          if (!entity.perk_desmar_distraction_range) {
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_distraction', targets: [target] });
          }
          this.entityEnterCrawl(target, DISTRACTION_CRAWL_DURATION);
        }

        if (entity.perk_desmar_distraction_range) {
          const reachable_entities = this.reachableEntities(target.vis).filter((e) => e.team === target.team && e.pos.dist(target.pos) < 100);
          journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_distraction_range', targets: [target, ...reachable_entities] });
          for (const dist_target of reachable_entities) {
            this.entityEnterCrawl(dist_target, DISTRACTION_CRAWL_DURATION);
          }
        }

        const threat_max = prob * stable_mult_max;

        // 피해량을 통해, stable_mult 피격 판정을 일찍 수행합니다.
        damage = this.entityCalculateDamage(entity, target, damage, stable_mult);
        if (damage === 0) {
          hit = false;
        }

        let len = ray_len;
        if (!hit && opts.EXP_TRAIL_CUT_MISS) {
          len = target_dist;
        }
        if (hit && opts.EXP_TRAIL_CUT_HIT) {
          len = target_dist;
        }
        let dir = projectile_dir;
        if (opts.TEST_TRAIL_RECONSTRUCT) {
          dir = this.entitySampleRayDir(entity, target, hit);
        }

        const proj_delay = this.ticksFromSec((i % opts.EXP_SHOTGUN_GROUP_COUNT) * opts.EXP_SHOTGUN_GROUP_DELAY);

        const trail = {
          ty: 'firearm',
          pos: entity.pos,
          target_pos: target.pos,
          dir,
          len,
          source: entity,
          target,
          hit,
          threat_max,
          valid,
          kill,
          damage: 0,
          damage_life: 0,
          penetrate: 0,
          crit,
          penalty,
          invis,
        };

        const projectile_scheduler_data = {
          expires_at: tick + proj_delay,
          trail,
        };

        if (hit) {
          let sec_bullet = dist / entity.firearm_projectile_speed;
          let tick_frame_delay = Math.round(sec_bullet * this.tps);

          projectile_scheduler_data.fire_scheduler_data = {
            ty: 'firearm',
            expires_at: tick + proj_delay + tick_frame_delay,
            entity,
            target,
            hit,
            prob,
            kill,
            damage,
            penetrate,
            crit,
            trail,
          };
        }

        this.projectile_scheduler_queue.push(projectile_scheduler_data);
        this.onTriggerEntityHandleFire(entity);
      }

      if (entity.shootPatternIdx === entity.firearm_shoot_pattern.length) {
        this.entityHandleFireShootPatternEnded(entity, target);
      }

      const { nextShootSeconds } = this.entityHandleFire_NextShootSeconds(entity);

      const shoot_tick = this.ticksFromSec(nextShootSeconds);
      entity.shootPatternTick = new TickTimer(tick, shoot_tick);
    }

    return { aimvalid };
  }

  riskdirDry(entity) {
    const { tick, world } = this;
    const { grid_vis } = entity;

    const grid = new Float32Array(world.grid_count);
    grid.set(grid_vis);

    const vismodel = this.rt;
    let res = this.riskdirWithSampler(entity, entity.pos, vismodel, grid, this.riskdirSample.bind(this));

    if (res.selected_val < 1) {
      res = this.riskdirWithSampler(entity, entity.pos, vismodel, grid, (entity, pos, samples_count, samples_weighted) => {
        this.riskdirSample(entity, pos.add(new v2(-10, -10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(10, -10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(-10, 10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(10, 10)), samples_count, samples_weighted, vismodel, grid);
      });
    }

    return res;
  }

  riskdirSample(entity, pos, samples_count, samples_weighted, vismodel, grid) {
    vismodel = vismodel ?? this.rv;

    let range = this.entityVisRange(entity);
    let advance = 0;

    if (!pos) {
      const { dir, movespeed } = entity;
      advance = movespeed * this.ticksFromSec(1);
      pos = entity.pos.add(v2.fromdir(dir).mul(advance));
      range = range - advance * 4;
    }

    const sample_count = samples_count.length;
    // (-PI, PI) -> (0, 2PI) -> [0, sample_count)
    const sample_size = Math.PI * 2 / sample_count;

    onReachableGridWasm(this.world, vismodel, pos, range, (idx) => {
      const val = grid[idx];
      if (val === 1) {
        return true;
      }

      // angle
      const world_pos = this.world.gridIdxToWorld(idx);
      const dirvec = world_pos.sub(pos);
      const dir = dirnorm(dirvec.dirfast());
      const diridx = Math.floor(dir / (Math.PI * 2) * sample_count + sample_size / 2);

      const longitudal_mult = lerp(1, 0, dirvec.len() / range);
      samples_count[diridx] += 1;
      // 총 위협량보다는 각위협량이 더 중요한 듯? 원뿔 부피는 거리 제곱에 비래하니까 n^2로
      samples_weighted[diridx] += (1 - val) * longitudal_mult * longitudal_mult;
      return true;
    });
  }

  riskdir(entity, pos) {
    if (!pos) {
      const { dir, movespeed } = entity;
      const advance = movespeed * this.ticksFromSec(1);
      pos = entity.pos.add(v2.fromdir(dir).mul(advance));
    }

    const { grid_vis, grid_explore, riskdir_use_visibility_grid } = entity;
    const grid = riskdir_use_visibility_grid ? grid_vis : grid_explore;

    return this.riskdirWithSampler(entity, pos, this.rv, grid, this.riskdirSample.bind(this));
  }

  riskdirWithSampler(entity, pos, vismodel, grid, sampler_fn) {
    const sample_count = 32;
    // (-PI, PI) -> (0, 2PI) -> [0, sample_count)
    const samples_count = new Float32Array(sample_count);
    const samples_weighted = new Float32Array(sample_count);

    const rays = [];
    for (let i = 0; i < sample_count; i++) {
      const dir = (i / sample_count) * Math.PI * 2;
      const vec = v2.fromdir(dir);
      rays.push(vec);
    }
    const samples_ray = raycastWasm(vismodel, pos, rays);

    sampler_fn(entity, pos, samples_count, samples_weighted, vismodel, grid);

    const samples = samples_weighted;

    let maxidx = 0;
    let maxval = samples[0];

    for (let i = 0; i < sample_count; i++) {
      if (samples[i] > maxval) {
        maxval = samples[i];
        maxidx = i;
      }
    }

    return {
      pos,
      sample_count,
      samples,
      samples_ray,

      selected_idx: maxidx,
      selected_val: maxval,
      selected_dir: (maxidx / sample_count) * Math.PI * 2,
    };
  }

  entityUpdateGridOmniDir(entity, pos, update_vis, vismodel) {
    const { grid_vis, grid_explore } = entity;
    const range = this.entityVisRange(entity);

    vismodel = vismodel ?? this.rv;

    let out_to_in = false;
    if (entity.team === 0 && opts.FOG_OUT_TO_IN) {
      out_to_in = true;
    }
    const vis = vismodel.triangulated.visibility(pos.x, pos.y, out_to_in);
    vis.limit(range);

    if (update_vis) {
      onReachableGridWasmOp(this.world, vis, grid_vis, 0, 1);
    }
    onReachableGridWasmOp(this.world, vis, grid_explore, 0, 1);
  }

  entityUpdateGrid(entity, pos) {
    const { rng } = this;
    if (!pos) {
      pos = entity.pos;
    }

    if (entity.waypoint_rule.ty === 'idle') {
      if (!entity.idleVisTick.expired(this.tick)) {
        return;
      }
      const vis_interval = rng.range(...opts.IDLE_VIS_INTERVAL_RANGE);
      const vis_ticks = this.ticksFromSec(vis_interval);
      entity.idleVisTick = new TickTimer(this.tick, vis_ticks);
    }

    const { grid_vis, grid_explore } = entity;
    const vis_var = this.entityEffectParam(entity, 'vis_var');
    const range = this.entityVisRange(entity);

    let out_to_in = false;
    if (entity.team === 0 && opts.FOG_OUT_TO_IN) {
      out_to_in = true;
    }

    const vis = this.rv.triangulated.visibility(pos.x, pos.y, out_to_in);
    vis.limit(range);

    let p0 = entity.pos.add(v2.fromdir(entity.aimdir - vis_var));
    let p1 = entity.pos.add(v2.fromdir(entity.aimdir + vis_var));
    vis.clip([p0.x, p0.y, p1.x, p1.y]);

    onReachableGridWasmOp(this.world, vis, grid_vis, 0, 1);
    onReachableGridWasmOp(this.world, vis, grid_explore, 0, 1);

    // cache latest visibility
    if (entity.vis) {
      entity.vis.free();
      entity.vis = null
    }
    entity.vis = vis;

    // update awareness
    for (const e of this.entities) {
      if (entity.seq === e.seq || entity.team === e.team) {
        continue;
      }
      const vis = entity.grid_vis[this.world.idx(e.gridpos)];
      let awareness = entity.awareness[e.seq];
      if (isNaN(awareness)) {
        awareness = 0;
      }
      entity.awareness[e.seq] = (awareness + vis * entity.aware_mult) * entity.aware_decay;
    }
  }

  entityVisible(entity, pos) {
    const { world } = this;
    const gridpos = world.worldToGrid(pos);
    return entity.grid_vis[world.idx(gridpos)] > 0.1;
  }

  entityAware(entity, e) {
    const { world } = this;
    if (opts.USE_AWARE) {
      return entity.awareness[e.seq] > e.vis_thres;
    } else {
      return entity.grid_vis[world.idx(e.gridpos)] > e.vis_thres;
    }
  }

  entityVisibleAllies(entity) {
    return this.entities.filter((e) => {
      if (e === entity || e.team !== entity.team || e.state === 'dead') {
        return false;
      }

      if (entity.use_visibility) {
        if (entity.grid_explore[this.world.idx(e.gridpos)] < e.vis_thres) {
          return false;
        }
      }
      return true;
    });
  }

  entityVisibleOpponents(entity) {
    const ot = opponentTeam(entity.team);
    return this.entities.filter((e) => {
      if (e === entity || !ot.includes(e.team) || e.state === 'dead') {
        return false;
      }

      if (entity.use_visibility) {
        if (!this.entityAware(entity, e)) {
          return false;
        }
      }
      return true;
    });
  }

  entityReload(entity) {
    const { journal } = this;
    if (!entity.reloadTick.expired(this.tick)) {
      // 이미 재장전중. 무시합니다.
      return;
    }

    const { tick } = this;
    if (entity.perk_instant_reload) {
      entity.reloadTick = new TickTimer(tick, 1);
      journal.push({ tick, ty: 'perk', entity, perk: 'perk_instant_reload' });
    } else {
      let { firearm_reload_duration } = entity;
      if (entity.perk_crawl_reload && entity.state === 'cover') {
        firearm_reload_duration *= 0.5;
      }
      if (entity.perk2_breacher_quadloading && entity.firearm_ty === 'sg') {
        firearm_reload_duration /= entity.perk2_breacher_quadloading.reload_speed_mult;
        journal.push({ tick, ty: 'perk', entity, perk: 'perk2_breacher_quadloading' });
      }

      const reload_duration = firearm_reload_duration * entity.reload_speed;
      entity.reloadTick = new TickTimer(tick, this.ticksFromSec(reload_duration));

      this.onTriggerEntityReload(entity);
    }
    entity.shootPatternIdx = 0; // reset shoot pattern

    // TODO: 실내 데모
    // entity.push_rule(tick, { ty: 'reload' });
  }

  entityOffenced(entity, filterFn) {
    const { tick, trails } = this;

    const response_tick = this.ticksFromSec(entity.response_time);

    const last_attacked = findLast(trails, (t) => {
      if (t.source.state === 'dead') {
        return false;
      }
      // 반응하지 못한 사격
      if (t.tick > tick - response_tick) {
        return false;
      }
      if (t.target !== entity) {
        return false;
      }
      if (filterFn && !filterFn(t)) {
        return false;
      }
      return true;
    });
    const last_attacked_tick = last_attacked ? last_attacked.tick : -1000;

    if (tick - last_attacked_tick < this.ticksFromSec(opts.SHOT_IDLE_DURATION)) {
      return last_attacked;
    }
    return null;
  }

  entityTransferKnowledge(entity) {
    const { entities } = this;
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');

    for (const e of allies) {
      if (entity.grid_explore === e.grid_explore) {
        // already sharing
        continue;
      }

      const len = entity.grid_explore.length;
      for (let i = 0; i < len; i++) {
        const v = Math.max(e.grid_explore[i], entity.grid_explore[i]);
        e.grid_explore[i] = v;
        entity.grid_explore[i] = v;
      }
    }
  }

  entityCoverPoilcy(entity) {
    if (entity.firearm_ty === 'sr') {
      return 'cover';
    }
    if (entity.team !== 0) {
      return 'cover-fire';
    }
    return this.controlGet(entity.spawnarea, 'cover') ? 'cover' : 'cover-fire';
  }

  visibleNodes(vis, nodes) {
    const coords = [];
    for (const { pos: { x, y } } of nodes) {
      coords.push(x);
      coords.push(y);
    }

    return vis.visible(coords);
  }

  reachableEntities(vis) {
    const { entities } = this;
    const coords = [];
    for (const { pos: { x, y } } of entities) {
      coords.push(x);
      coords.push(y);
    }

    const visibles = vis.visible(coords);
    return entities.filter((_e, i) => visibles[i] > 0);
  }

  entityTryThrowables(entity, throwable_pos) {
    const { throwables } = entity;
    const { segment } = this;

    const throwable_damage = throwables.find((t) => {
      return t.blasts.find((b) => b.blast_ty === 'damage' || b.blast_effect_ty === 'stun_gr');
    });
    if (throwable_damage) {
      if (this.entityTryThrowable(entity, throwable_damage, throwable_pos)) {
        segment.use_throwable = true;
        return true;
      }
    }

    const smoke = throwables.find((t) => {
      return t.blasts.find((b) => b.blast_ty === 'damage') === undefined;
    });
    if (smoke) {
      this.entityTryThrowableSmoke(entity, smoke);
      return true;
    }

    return false;
  }

  entityTryThrowableSmoke(entity, throwable) {
    // smoke는 다음 상황에서 던집니다
    //  - aimtarget이 사거리 밖에 있고
    //  - aimtarget의 사거리가 나의 사거리보다 길고 현재 위협받고 있음

    let max_blast_radius = _.max(throwable.blasts.map((b) => b.blast_radius));
    // TODO:
    /*
    if (entity.perk_grenadier_effect_range) {
      max_blast_radius *= 1.5;
    }
    */

    // sample direction
    const { aimdir, pos, vis, aimtarget } = entity;

    // 현재 교전 가능
    if (aimtarget) {
      if (aimtarget.pos.dist(entity.pos) < entity.firearm_range) {
        return;
      }
      // 사거리 차이가 크게 나지 않음
      if (aimtarget.firearm_range - entity.firearm_range < 50) {
        return;
      }
    }

    // 공격받지 않으면 무시
    const offenced = this.entityOffenced(entity);
    if (!offenced) {
      return;
    }

    const dir = v2.fromdir(aimdir);

    const ray = raycastVisibilityWasm(vis, [dir])[0].sub(pos);

    let { throwable_max_dist } = entity;
    if (entity.perk_grenadier_throw_range) {
      throwable_max_dist *= 1.2;
    }

    // arbitrary value
    const dist_min = 50;
    const dist_max = Math.min(ray.len() - 20, throwable_max_dist);

    for (let dist = dist_max; dist >= dist_min; dist -= 50) {
      const p1 = pos.add(dir.mul(dist));

      const vis_throwable = this.rv.triangulated.visibility(p1.x, p1.y, false);
      vis_throwable.limit(max_blast_radius);
      const vis_entities = this.reachableEntities(vis_throwable);

      // const ally_count = vis_entities.filter((e) => e.team === entity.team && e.state !== 'dead').length;
      const enemy_affected = vis_entities.filter((e) => e.team !== entity.team && e.state !== 'dead')
      const enemy_count = enemy_affected.length;

      vis_throwable.free();

      if (enemy_count > 0) {
        return;
      }

      entity.throwables = remove_item_unique(entity.throwables, throwable);
      this.journal.push({ tick: this.tick, ty: 'throw_general', entity, throwable, targets: enemy_affected, });
      this.entityThrow(entity, throwable, p1);
      // this.entityHandleThrowable(entity, throwable, p1);
      break;
    }
  }

  calculateMaxBlastRadius(entity, throwable) {
    let max_blast_radius = _.max(throwable.blasts.map((b) => b.blast_radius));
    if (entity.perk_grenadier_effect_range) {
      max_blast_radius *= 1.5;
    }
    if (entity.perk2_common_vision_orbital_mechanics) {
      max_blast_radius *= entity.perk2_common_vision_orbital_mechanics.blast_radius_mult;
    }
    return max_blast_radius;
  }

  // 오퍼레이터가 수류탄을 던질 위치를 예약합니다.
  // 수류탄 위치 수동 조작에서 사용하며 해당 명령이 최우선으로 작동합니다.
  entityReserveThrowable(entity, throwable, pos) {
    if (!throwable) {
      return false;
    }
    const { entities } = this;
    if (entities.find((e) => e !== entity && e.team === entity.team && e.reserve_throwable)) {
      return false;
    }

    entity.reserve_throwable = {
      pos,
      throwable,
    };
    return true;
  }

  entityUpdateReserveThrowable(entity) {
    const { reserve_throwable } = entity;
    if (!reserve_throwable) {
      return;
    }

    const { segment } = this;
    if (segment && segment.use_throwable) {
      return;
    }

    const { pos, throwable } = reserve_throwable;
    if (this.canThrowThrowable(entity, pos) === 'ok') {
      if (this.entityTryThrowables(entity, pos)) {
        entity.reserve_throwable = undefined;
      }
    }
  }

  // yes : available
  // no : not reachable
  // blocked : pos in obstacle
  canThrowThrowable(entity, pos) {
    const obs = this.obstacles.filter((o) => {
      if (['full', 'half'].includes(o.ty)) {
        return true;
      }
      if (o.ty === 'door' && !o.doorstate.open) {
        return true;
      }
      return false;
    });
    for (const o of obs) {
      if (geomContains(pos, o.polygon)) {
        return 'blocked';
      }
    }

    const dist = entity.pos.dist(pos);

    let { throwable_max_dist } = entity;
    if (entity.perk_grenadier_throw_range) {
      throwable_max_dist *= 1.2;
    }
    if (dist > throwable_max_dist) {
      return 'no';
    }

    if (obstructed(entity.pos, pos, obs.filter((o) => {
      if (o.ty === 'half') {
        return false;
      }
      return true;
    }))) {
      return 'no';
    }

    return 'ok';
  }

  entityTryThrowable(entity, throwable, throwable_pos) {
    const { allow_ally_hit } = throwable;
    let max_blast_radius = this.calculateMaxBlastRadius(entity, throwable);

    let dist_min = max_blast_radius * 1.5;
    if (allow_ally_hit) {
      dist_min = 0;
    }

    const onThrow = (pos, enemy_affected = []) => {
      entity.throwables = remove_item_unique(entity.throwables, throwable);
      this.journal.push({ tick: this.tick, ty: 'throw_general', entity, throwable, targets: enemy_affected, });
      if (entity.perk_grenadier_throw_range && dist * 1.2 > throwable_max_dist) {
        this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_grenadier_throw_range', entity, });
      }
      this.entityThrow(entity, throwable, pos);
    };

    // 투척물을 투척할 위치가 정해져 있을 경우
    if (throwable_pos !== undefined) {
      if (this.canThrowThrowable(entity, throwable_pos) === 'ok') {
        onThrow(throwable_pos);
        return true;
      } else {
        return false;
      }
    }

    // sample direction
    const { aimdir, pos, vis } = entity;

    const dir = v2.fromdir(aimdir);

    const ray = raycastVisibilityWasm(vis, [dir])[0].sub(pos);
    if (!allow_ally_hit && ray.len() < max_blast_radius) {
      return false;
    }

    let { throwable_max_dist } = entity;
    if (entity.perk_grenadier_throw_range) {
      throwable_max_dist *= 1.2;
    }

    const dist_max = Math.min(ray.len() - 20, throwable_max_dist);

    for (let dist = dist_max; dist >= dist_min; dist -= 50) {
      const p1 = pos.add(dir.mul(dist));

      const vis_throwable = this.rv.triangulated.visibility(p1.x, p1.y, false);
      const block_throwable = this.rr.triangulated.visibility(p1.x, p1.y, false);

      vis_throwable.limit(max_blast_radius);
      block_throwable.limit(max_blast_radius);

      const ally_count = this.reachableEntities(vis_throwable).filter((e) => e.team === entity.team && e.state !== 'dead').length;
      const enemy_direct = this.reachableEntities(block_throwable).filter((e) => e.team !== entity.team && e.state !== 'dead');
      const enemy_direct_count = enemy_direct.length;
      const enemy_indirect = this.reachableEntities(vis_throwable).filter((e) => e.team !== entity.team && e.state !== 'dead');
      const enemy_indirect_count = enemy_indirect.length;
      const enemy_affected = _.uniq([...enemy_direct, ...enemy_indirect]);
      vis_throwable.free();
      block_throwable.free();

      // 양심이 있으면 프래깅은 하지 맙시다
      if (!allow_ally_hit && ally_count > 0) {
        continue;
      }

      const { throwable_score_direct, throwable_score_indirect, throwable_score_thres } = entity;

      let flag_throw = true;

      if (this.segment) {
        const segment_enemies = this.segmentEnemies(this.segment);
        if (segment_enemies) {
          const segment_enemy_count = segment_enemies.length;
          if (enemy_affected.length < opts.EXP_THROWABLE_ENEMY_PERCENT * segment_enemy_count) {
            flag_throw = false;
          } else {
            console.log(`throwable | enemies : ${enemy_affected.length} | segments enemies ${segment_enemy_count} `);
          }
        }
      }

      const score = throwable_score_direct * enemy_direct_count + throwable_score_indirect * enemy_indirect_count;
      if (score < throwable_score_thres) {
        flag_throw = false;
      }

      if (flag_throw) {
        onThrow(p1, enemy_affected);
        return true;
      }
    }
    return false;
  }

  teamVisibility(team) {
    const { world, entities } = this;

    const fogbuf = new Float32Array(world.grid_count);
    for (const entity of entities) {
      if (entity.team !== team || entity.state === 'dead') {
        continue;
      }
      const { grid_vis, grid_explore } = entity;
      for (let i = 0; i < world.grid_count; i++) {
        fogbuf[i] = Math.max(Math.max(fogbuf[i], grid_explore[i] * 0.5), grid_vis[i]);
      }
    }
    return fogbuf;
  }

  visibilityAt(entity, pos) {
    const { world } = this;
    const gridpos = world.worldToGrid(pos);
    return entity.grid_vis[world.idx(gridpos)] ?? NaN;
  }

  teamVisibilityAt(team, pos) {
    const { world, entities } = this;

    let val = 0.0;
    for (const entity of entities) {
      if (entity.team !== team || entity.state === 'dead') {
        continue;
      }
      const gridpos = world.worldToGrid(pos);
      val = Math.max(val, entity.grid_vis[world.idx(gridpos)]);
    }
    return val;
  }

  // entity가 target을 회복하는 조건
  isNeedHeal(entity, target) {
    const heal_thres = this.controlGet(entity.spawnarea, 'healopts');
    if (target.team !== entity.team || target.life === target.life_max) {
      return false;
    }
    // 고급 구급학 퍽
    // 퍽이 있는 경우 죽은 아군 포함, 없을 경우 죽은 아군 제외
    if (target.state === 'dead' && !entity.perk2_medic_paramedics_advanced) {
      return false;
    }
    if (target === entity && !entity.perk_commed_target_self && !entity._perk_commed_heal_self) {
      return false;
    }

    // 방탄 수선 퍽
    if (target.perk2_vanguard_shield_repair) {
      if (target.shield >= target.shield_max * heal_thres) {
        return false;
      }
    } else {
      if (target.life >= target.life_max * heal_thres) {
        return false;
      }
    }
    return true;
  }

  onTickHeal0(entity) {
    const { tick, entities } = this;
    const { heal_target, is_manual } = entity.waypoint_rule;
    const allies = entities.filter((e) => e.spawnarea === entity.spawnarea);

    const [h] = entity.heals;

    if (!is_manual) {
      // 고급 구급학 퍽
      // TODO: 죽은 리더 살렸을 경우 rule 꼬일 수 있음
      if (entity.heals.length === 0 || (!entity.perk2_medic_paramedics_advanced && heal_target.state === 'dead')) {
        entity.pop_rule();
        return;
      }

      // 체력이 부족해진 경우 취소
      if (entity.perk_commed_risk && heal_target.life <= h.heal_amount / 2) {
        entity.pop_rule();
        return;
      }

      if (!this.isNeedHeal(entity, heal_target)) {
        entity.pop_rule();
        return;
      }
    }

    const entity_idx = allies.indexOf(entity);

    // 만약 앞선 스쿼드 인덱스의 메딕의 heal_taregt인 경우 취소
    if (allies.find((e, idx) =>
      idx < entity_idx &&
      e.waypoint_rule.ty === 'heal' &&
      e.waypoint_rule.heal_target === heal_target)) {
      return;
    }

    // 앞선 스쿼드 인덱스에 힐팩이 남아 있는 메딕이 존재하는 경우 취소
    if (allies.find((e, idx) =>
      idx < entity_idx &&
      e.waypoint_rule.ty === 'heal' &&
      e.heals &&
      e.heals.length > 0)) {
      return;
    }

    if (entity.pos.dist(heal_target.pos) > opts.HEAL_RADIUS) {
      return;
    }

    if (entity.healTick.expired_exact(tick)) {
      let heal_additive = 0.0;
      if (entity.perk_commed_amount) {
        this.journal.push({ tick, ty: 'perk', perk: 'perk_commed_amount', entity, heal_target, });
        heal_additive += 0.2;
      }
      if (entity.perk_commed_risk) {
        heal_additive += 1.0;
      }
      if (entity.perk2_medic_paramedics_elementary) {
        this.journal.push({ tick, ty: 'perk', perk: 'perk2_medic_paramedics_elementary', entity, heal_target, });
        heal_additive += entity.perk2_medic_paramedics_elementary.heal_rate;
      }

      if (heal_target.perk_healed_amount_5) {
        heal_additive += 0.05;
      }
      if (heal_target.perk_healed_amount_15) {
        heal_additive += 0.15;
      }
      if (heal_target.perk2_common_physical_healthy_body) {
        this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk2_common_physical_healthy_body', entity, heal_target, });
        heal_additive += heal_target.perk2_common_physical_healthy_body.heal_rate;
      }

      // 한 번만 스스로 치료할 수 있어야 합니다.
      if (entity === heal_target) {
        entity._perk_commed_heal_self = true;
      }

      if (entity.perk_commed_buff) {
        if (heal_target._stat._stat && heal_target._firearm_stat) {
          const stat = heal_target._stat._stat;
          const stats_next = stats_const(stat + 2);

          this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_commed_buff', entity, heal_target, });
          updateEntityStat(heal_target, stats_next, heal_target._firearm_stat);
        }
      }

      // 체력 비례 회복량 (추가 퍽들은 합연산으로 적용됩니다.)
      let heal_amount = heal_target.life_max * (h.heal_amount + heal_additive);
      let prevLife = heal_target.life;
      let nowLife = Math.min(heal_target.life_max, heal_target.life + heal_amount);

      // 방탄 수선
      if (heal_target.perk2_vanguard_shield_repair) {
        heal_amount = heal_target.shield_max * (h.heal_amount + heal_additive);
        prevLife = heal_target.shield;
        nowLife = Math.min(heal_target.shield_max, heal_target.shield + heal_amount);
        heal_target.shield = nowLife;
      } else {
        heal_target.life = nowLife;
      }

      this.heals.push({ source: entity, heal_target, heal_amount: nowLife - prevLife });
      entity.heals.splice(0, 1);
      entity.report.healed += nowLife - prevLife;

      // 일회성으로 사용하는 경우
      if (entity.waypoint_rule.disposable) {
        entity.pop_rule();
      }
      // TODO
      if (entity.name === heal_target.name) {
        this.journal.push({ tick: this.tick, ty: 'heal_self', entity, heal_target });
      } else {
        this.journal.push({ tick: this.tick, ty: 'heal_start', entity, heal_target });
      }
      //entity.pop_rule();
      if (entity.perk2_medic_paramedics_advanced && heal_target.state === 'dead') {
        heal_target.state = 'stand';
        heal_target.healtargetTick = new TickTimer(tick, this.ticksFromSec(5)); // 부활 시 애니메이션 연출 구간 확보
      }

      this.onTriggerEntityHealed(heal_target);
    } else if (entity.healTick.expired(tick)) {
      let duration_multiplier = 1.0;
      if (entity.perk_commed_workspeed) {
        this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_commed_workspeed', entity, heal_target, });
        duration_multiplier = 0.8;
      }

      const duration = this.ticksFromSec(h.heal_duration * duration_multiplier);

      entity.healTick = new TickTimer(tick, duration);
      // 처음 시작
      const allies = this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === entity.spawnarea);

      const area = entity.spawnarea;
      const control = this.controls(area);
      if (control.state !== 'engage') {
        for (const ally of allies) {
          ally.waitTick = new TickTimer(tick, duration + this.ticksFromSec(1));
        }
      }
    }
  }

  onTickHeal(entity) {
    const { ty } = entity.waypoint_rule;
    if (ty === 'heal') {
      this.onTickHeal0(entity);
    }
  }

  entityTryHeal(entity, target, args = {}) {
    const disposable = args.disposable ?? false;
    const is_manual = args.is_manual ?? false;
    const { tick } = this;
    entity.push_rule(tick, { ty: 'heal', heal_target: target, disposable, is_manual });
  }

  maybeHeal(entity) {
    if (entity.heals.length === 0) {
      return;
    }

    const { tick, entities } = this;
    const candidates = entities.filter((e) => this.isNeedHeal(entity, e));

    candidates.sort((a, b) => {
      if (a.life !== b.life) {
        return a.life - b.life;
      }
      return entity.pos.dist(a.pos) - entity.pos.dist(b.pos);
    });
    candidates.reverse();

    for (const target of candidates) {
      this.entityTryHeal(entity, target);
    }
  }

  nextHealPack(area = 0) {
    const entities = this.entities.filter((e) => e.spawnarea === area && e.state !== 'dead');
    const entity = entities.find((e) => e.heals && e.heals.length > 0);
    if (entity) {
      return {
        entity,
        heal: entity.heals[0],
      };
    }
    return null;
  }

  onTickBreach(entity) {
    const { tick } = this;

    if (entity.breachTick.expired_exact(tick)) {
      if (entity.door_entry && entity.perk2_common_intelligence_dynamic_entry) {
        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk2_common_intelligence_dynamic_entry', targets: [entity] });

        const perk = entity.perk2_common_intelligence_dynamic_entry;
        const duration = this.ticksFromSec(perk.duration_seconds);
        entity.breachBuffTick = new TickTimer(tick, duration);

        const effect = entity.add_effect('perk2_common_intelligence_dynamic_entry');
        effect.expire_at = tick + duration;
        effect.evasion_rate_reduction = perk.evasion_rate_reduction;
        effect.life_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
        effect.shield_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
      }
    }
  }

  entityUpdateAimvar(entity) {
    const { entities } = this;

    // aimvar_decay_per_tick은 tps=30을 기준으로 만들어져 있음
    let decay_mult = 1.0;
    if (entity.aimtarget && entity.perk_desmar_aimspeed && entity.aimtarget.life === entity.aimtarget.life_max) {
      decay_mult += 1.0;
      this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_desmar_aimspeed' });
    }
    if (entity.perk_stationary_aimspeed && entity.movespeed === 0) {
      decay_mult += 0.1;
    }

    const { aimtarget } = entity;
    if (aimtarget) {
      if (entity.perk_aim_together) {
        let allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state === 'covered' && e.aimtarget === aimtarget);
        if (allies.length > 0) {
          this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_aim_together', targets: [aimtarget] });
          decay_mult += 0.5;
        }
      }

      if (entity.perk_aim_execute && aimtarget.life < aimtarget.life_max) {
        this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_aim_execute', targets: [aimtarget] });
        decay_mult += 0.5;
      }
    }

    let aimvar_decay = Math.pow(entity.aimvar_decay_per_tick, 30 / opts.tps);
    aimvar_decay = 1 - (1 - aimvar_decay) * decay_mult;
    entity.aimmult = entity.aimmult * aimvar_decay;

    let aimvar_hold_mult = 1;
    let aimvar_hold_max_mult = 1;
    if (entity.perk_aimvar_5) {
      aimvar_hold_mult -= 0.05;
    }
    if (entity.perk_aimvar_10) {
      aimvar_hold_mult -= 0.1;
    }
    if (entity.perk_aimvar_max_5) {
      aimvar_hold_max_mult -= 0.05;
    }
    if (entity.perk_aimvar_max_10) {
      aimvar_hold_max_mult -= 0.1;
    }

    let aimvar_hold = entity.aimvar_hold * aimvar_hold_mult;
    let aimvar_hold_max = this.entityEffectParam(entity, 'aimvar_hold_max') * aimvar_hold_max_mult;


    const aims = entities.filter((e) => e.perk_aimtarget_incr_aimvar && !['sr', 'dmr'].includes(e.firearm_ty) && e.aimtarget === entity && e.state !== 'dead');
    if (aims.length > 0) {
      aimvar_hold_max *= 1.25;
    }
    entity.aimvar = lerp(aimvar_hold_max, aimvar_hold, entity.aimmult);
  }

  entityEnterCrawl(entity, seconds) {
    seconds = seconds ?? opts.CRAWL_MIN_DURATION;
    if (entity.perk_engage_dash) {
      return;
    }
    if (entity.state === 'dead') {
      return;
    }

    const { tick } = this;
    entity.state = 'crawl';
    entity.moving = false;

    entity.crawlTick = new TickTimer(tick, this.ticksFromSec(seconds));
  }

  nextThrowable(area = 0) {
    const entities = this.entities.filter((e) => e.spawnarea === area && e.state !== 'dead');
    const t1 = entities.find((e) => e.controls['throwable'] && e.throwables && e.throwables.length > 0);
    if (t1) {
      return {
        entity: t1,
        throwable: t1.throwables[0],
      };
    }

    const t2 = entities.find((e) => e.throwables && e.throwables.length > 0);
    if (t2) {
      return {
        entity: t2,
        throwable: t2.throwables[0],
      };
    }

    return null;
  }

  entityUpdateThrowable(entity) {
    const { tick, segment, entities } = this;
    if (!entity.controls['throwable']) {
      return;
    }

    if (segment && segment.use_throwable) {
      return;
    }

    if (entity.reserve_throwable) {
      return;
    }

    if (entities.find((e) => e.team === entity.team && e.reserve_throwable)) {
      return;
    }

    if (!entity.checkThrowableTick.expired(tick)) {
      return;
    }
    entity.checkThrowableTick = new TickTimer(tick, this.ticksFromSec(...opts.CHECK_THROWABLE_INTERVAL_RANGE));

    const team_throwables = this.throwables.filter((t) => t.entity.team === entity.team);
    if (team_throwables.find((t) => !t.blast_timer.expired(tick))) {
      return;
    }

    // TODO: throwable
    this.entityTryThrowables(entity);
  }

  entityEngaged(entity, initiator, transient) {
    const { tick } = this;

    const cover_ty = this.entityCoverPoilcy(entity);
    entity.push_rule(tick, { ty: cover_ty, initiator, transient });

    const leader = entity.leader ?? entity;
    const team_members = this.entities.filter((e) => e.leader === leader);

    for (const member of team_members) {
      const rule_ty = member.waypoint_rule.ty;
      if (!['cover', 'cover-fire'].includes(rule_ty)) {
        member.push_rule(tick, { ty: this.entityCoverPoilcy(member), initiator, transient: true });
      }
    }
  }

  target_door_in_dist(target, dist) {
    if (!target) {
      return null;
    }
    const door_idx = this.entityWaypointDoor(target);
    if (!door_idx) {
      return null;
    }
    const door = this.routes.nodes[door_idx];
    if (!door) {
      return null;
    }
    if (door.obstacle.pos.dist(target.pos) < dist) {
      return door;
    }
    return null;
  }

  entityUpdateBreach(entity) {
    const { tick, entities } = this;
    // TODO: follow-breaching
    // MEMO: breaching rule
    const breaching_rule = entity.rules.find((e) => e.ty === 'breaching');
    if (breaching_rule) {
      return;
    }

    if (entity.leader && entity.leader !== entity) {
      return;
    }


    const waypoint_door = this.target_door_in_dist(entity, opts.DOOR_ENTRY_DIST);
    if (!waypoint_door) {
      return;
    }
    const dooredge_idx = entity.waypoint.path.findIndex((e) => e.edge?.door && !e.edge.obstacle.doorstate.open);
    if (dooredge_idx === -1) {
      return;
    }
    const dooredge = entity.waypoint.path[dooredge_idx];

    const followers = entities.filter((e) => e.leader === entity && e.state !== 'dead' && e !== entity); // exclude self
    if (followers.find((e) => e.rules.find((r) => r.ty === 'breaching'))) {
      return;
    }

    const door = dooredge.edge.door;
    const door_name = door.doorstate.name;
    let door_entry = !!entity.perk2_common_intelligence_dynamic_entry && entity.door_entry; // 리더가 다이나믹 엔트리 퍽을 가지고 있고, 전술 설정에서 도어 엔트리를 사용 설정해야 다이나믹 엔트리 사용

    let breach_half = false;
    let members = (followers.length == 0 ? [entity, ...this.entityVisibleAllies(entities)] : [entity, ...followers]);
    const breacher = [...members].sort(breaching_priority_compare(door_name))[0];

    // 일반 진입: entry action이 없는 경우
    if (!door_entry && !is_breaching_candidate(breacher, door_name)) {
      return;
    }

    // half entry: entry가 있는 경우
    if (opts.EXP_HALF_ENTRY && is_breaching_candidate(breacher, door_name)) {
      door_entry = true;
      breach_half = true;
    }

    const breach_positions = breachPositions(this.rr, door, dooredge.pos, members.length, false);
    const invert_dooredge_pos = entity.waypoint.path[dooredge_idx - 1].pos;
    const breach_ready_positions = breachPositions(this.rr, door, invert_dooredge_pos, members.length, false);
    const common = {
      dooredge,
      all_allies_ready: false,
      all_allies_over: false,
      breach_half,
      breacher,
      door_entry,
      members,
      breach_positions,
      breach_ready_positions,
      door_open_at: -1,
    };

    const cmp = (p, q) => {
      return dirnorm0(p.sub(door.pos).dir()) - dirnorm0(q.sub(door.pos).dir());
    };

    breach_positions.sort(cmp);
    breach_ready_positions.sort(cmp);

    const rev_idx = Array.from({ length: members.length }, (v, i) => i);
    rev_idx.sort((p, q) => cmp(members[p].pos, members[q].pos));

    const idx = members.indexOf(entity);
    const rule = {
      ty: 'breaching',
      waypoint: { ...entity.waypoint },
      breach_idx: members.indexOf(entity),
      breach_ready_pos: breach_ready_positions[rev_idx.indexOf(idx)],
      breach_pos: breach_positions[rev_idx.length - 1 - rev_idx.indexOf(idx)],
      breaching_ready: !door_entry, // !door_entry does not check breach_ready_pos
      breaching_over: false,
      enter_delay: members.indexOf(entity) * opts.DOOR_ENTRY_DELAY,
      common,
    };
    entity.push_rule(tick, rule);
    for (const f of followers) {
      const idx = members.indexOf(f);
      f.push_rule(tick, {
        ...rule,
        breach_idx: idx,
        breach_ready_pos: breach_ready_positions[rev_idx.indexOf(idx)],
        breach_pos: breach_positions[rev_idx.length - 1 - rev_idx.indexOf(idx)],
        enter_delay: idx * opts.DOOR_ENTRY_DELAY,
        follow_target: entity
      });
    }
  }

  entityUpdateEffectPerk(entity) {
    const { entities } = this;

    // 방패 숙달 퍽
    if (entity.perk2_vanguard_shield_mastery) {
      const perk = entity.perk2_vanguard_shield_mastery;

      if (entity.shield > 0) {
        const effect = entity.add_effect('perk2_vanguard_shield_mastery');
        effect.shield_damage_reduction = perk.shield_damage_reduction;
      } else {
        entity.remove_effect('perk2_vanguard_shield_mastery');
      }
    }

    // 근육 방탄판 퍽
    if (entity.perk2_vanguard_muscle_bulletproof) {
      const perk = entity.perk2_vanguard_muscle_bulletproof;

      if (entity.shield > 0) {
        entity.remove_effect('perk2_vanguard_muscle_bulletproof');
      } else {
        const effect = entity.add_effect('perk2_vanguard_muscle_bulletproof');
        effect.life_damage_reduction = perk.life_damage_reduction;
      }
    }

    // 죽거나 죽이거나 퍽
    if (entity.perk2_common_mental_kill_or_be_killed) {
      const perk = entity.perk2_common_mental_kill_or_be_killed;

      if (entity.life <= entity.life_max * perk.life_threshold) {
        const effect = entity.add_effect('perk2_common_mental_kill_or_be_killed');
        effect.crit_prob = perk.crit_prob;
      } else {
        entity.remove_effect('perk2_common_mental_kill_or_be_killed');
      }
    }

    // 충분한 보급 퍽
    if (entity.perk2_common_half_over_mag) {
      const perk = entity.perk2_common_half_over_mag;

      if (entity.ammo * 2 >= entity.firearm_ammo_max) {
        const effect = entity.add_effect('perk2_common_half_over_mag');
        effect.accuracy_rate_increase = perk.accuracy_rate_increase;
      } else {
        entity.remove_effect('perk2_common_half_over_mag');
      }
    }

    // 신중함 퍽
    if (entity.perk2_common_half_under_mag) {
      const perk = entity.perk2_common_half_under_mag;

      if (entity.ammo * 2 < entity.firearm_ammo_max) {
        const effect = entity.add_effect('perk2_common_half_under_mag');
        effect.evasion_rate_increase = perk.evasion_rate_increase;
      } else {
        entity.remove_effect('perk2_common_half_under_mag');
      }
    }

    // 트리거 해피 퍽
    if (entity.perk2_common_strategy_aggressive_dmg) {
      const perk = entity.perk2_common_strategy_aggressive_dmg;

      const controls = this.controls(entity.spawnarea);
      if (controls && controls.firepolicy === 'aggressive') {
        const effect = entity.add_effect('perk2_common_strategy_aggressive_dmg');
        effect.life_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
        effect.shield_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
      } else {
        entity.remove_effect('perk2_common_strategy_aggressive_dmg');
      }
    }

    // 효과적인 제압 퍽
    {
      const enemies = entities.filter((e) =>
        e.state !== 'dead' &&
        e.team !== entity.team &&
        e.perk2_common_strategy_supress_decrease_avoid);
      if (enemies.length > 0) {
        const enemy_area = enemies[0].spawnarea;
        const controls = this.controls(enemy_area);
        if (true || controls.firepolicy === 'supress') {
          const sum_accuracy = enemies.reduce((ret, e) => ret + e.perk2_common_strategy_supress_decrease_avoid.accuracy_rate_reduction, 0);

          const effect = entity.add_effect('perk2_common_strategy_supress_decrease_avoid');
          effect.accuracy_rate_reduction = sum_accuracy;
        }
      } else {
        entity.remove_effect('perk2_common_strategy_supress_decrease_avoid');
      }
    }

    // 표적 지정 퍽
    {
      const enemies = entities.filter((e) =>
        e.state !== 'dead' &&
        e.team !== entity.team &&
        e.perk2_common_strategy_pair_increase_acc);
      if (enemies.length > 1) {
        const enemy_area = enemies[0].spawnarea;
        const controls = this.controls(enemy_area);

        if (controls.firepolicy === 'pair') {
          const sum_evasion = enemies.reduce((ret, e) => ret + e.perk2_common_strategy_pair_increase_acc.evasion_rate_reduction, 0);

          const effect = entity.add_effect('perk2_common_strategy_pair_increase_acc');
          effect.evasion_rate_reduction = sum_evasion;
        }
      } else {
        entity.remove_effect('perk2_common_strategy_pair_increase_acc');
      }
    }

    // 부싯돌 퍽
    {
      // 재장전 후 첫 탄인가?
      const first_shoot = entity.ammo === entity.firearm_ammo_max;
      const perk = entity.perk2_common_first_shot_crit;

      if (perk) {
        if (first_shoot) {
          const effect = entity.add_effect('perk2_common_first_shot_crit');
          effect.life_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
          effect.shield_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
        } else {
          entity.remove_effect('perk2_common_first_shot_crit');
        }
      }
    }

    // 일발역전 퍽
    {
      const perk = entity.perk2_common_last_shot_crit;
      const pattern_len = entity.firearm_shoot_pattern.length + 1;
      if (perk) {
        if (entity.ammo <= pattern_len) {
          const effect = entity.add_effect('perk2_common_last_shot_crit');
          effect.life_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
          effect.shield_atk_damage_rate_increase = perk.all_atk_damage_rate_increase;
        } else {
          entity.remove_effect('perk2_common_last_shot_crit');
        }
      }
    }
  }

  entityUpdate(entity) {
    if (entity.state === 'dead') {
      return;
    }
    if (entity.waypoint_rule.ty === 'dummy') {
      // preset_aimvar 테스트용
      this.entityUpdateAimvar(entity);
      return;
    }

    const { tick, rng, routes, goals, entities, world } = this;

    if (entity.avoidanceTick && entity.avoidanceTick.expired(tick)) {
      delete entity.avoidanceTick;
    }

    if (entity.invincTick && entity.invincTick.expired(tick)) {
      delete entity.invincTick;
    }

    const route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
    const reroute_ticks = this.ticksFromSec(route_interval);
    let reroute = entity.waypoint_rule.ty !== 'explore' && tick % reroute_ticks === 0;

    let reload = entity.ammo === 0;

    if (entity.use_visibility) {
      this.entityUpdateGrid(entity);
    }

    while ((entity.waypoint_rule.expires_at ?? tick) < tick) {
      const rule = entity.waypoint_rule;
      console.log('rule expired', rule);

      if (rule.prev_perks) {
        for (const perk of Object.keys(rule.prev_perks)) {
          entity[perk] = rule.prev_perks[perk];
        }
      }
      entity.pop_rule();
    }

    // aim한 상대와 주어진 시간동안 교전하지 못한 경우, aim을 해제합니다.
    if (entity.unaimTick.expired_exact(tick)) {
      this.entityUnaim(entity);
    }

    // specify target
    let update_aimtarget = entity.retargetTick.expired(tick)
      || entity.aimtarget === null;
    if (entity.fireleader && entity.fireleader.state !== 'dead') {
      const priotarget = entity.fireleader?.aimtarget;
      if (priotarget && priotarget !== entity.aimtarget) {
        this.entitySetAimtarget(entity, priotarget);
        entity.lastRouteTick = new TickTimer(tick, 0);
      }
      update_aimtarget = false;
    }
    if (entity.aimtarget?.state === 'dead') {
      update_aimtarget = true;
    }
    // 장비 파괴자 퍽
    if (entity.perk2_common_shield_breaker &&
      entity.aimtarget?.shield === 0 &&
      this.entityEngageEnemies(entity.team).find((e) => e.shield > 0)
    ) {
      update_aimtarget = true;
    }
    if (update_aimtarget) {
      if (this.entityUpdateAimtarget(entity)) {
        // reset route. allow_cover_edge용
        entity.lastRouteTick = new TickTimer(tick, 0);
      }
      entity.retargetTick = new TickTimer(tick,
        this.ticksFromSec(rng.range(...entity.retarget_interval_range)));
    }

    this.onTickHeal(entity);

    // 아드레 날린 퍽
    if (entity.perk2_medic_adrenaline) {
      const perk = entity.perk2_medic_adrenaline;
      if (perk.started_tick && perk.started_tick !== -1) {
        const check = (tick - perk.started_tick) % this.ticksFromSec(1);
        if (check === 0) {
          entity.life = Math.min(entity.life_max, entity.life + perk.activate_heal_amount);
        }
      }
    }

    this.onTickBreach(entity);
    this.entityUpdateReserveThrowable(entity);
    this.entityUpdateThrowable(entity);
    this.entityUpdateEffectPerk(entity);

    // const rescue_target_remain = entities.find((e) => e.team !== 0 && e.ty === 'vip');
    if (entity.waypoint_rule.ty === 'rescue' && entity.waypoint_rule.rescue_target.team === entity.team) {
      // TODO
      entity.pop_rule();
    }

    let waypoint_near = false;
    if (entity.waypoint) {
      // waypoint가 있는 경우
      const wp_dist = entity.waypoint.pos.dist(entity.pos);
      waypoint_near = wp_dist < entity.waypoint_dash_dist;
    }

    // 최근에 공격받은 적 있는지
    const offenced = this.entityOffenced(entity);

    // 시야 밖에서 공격받은 적 있는지
    const offenced_invis = this.entityOffenced(entity, (t) => {
      return !this.entityAware(entity, t.source);
    });

    // 위협적인 사격을 받은 적 있는지
    const offenced_crawl = this.entityOffenced(entity, (t) => {
      return t.threat_max >= opts.THREAT_THRES_CRAWL;
    });

    const allow_crawl = this.entityEffectParam(entity, 'allow_crawl');
    if ((offenced_invis || allow_crawl) && ['crawl', 'stand'].includes(entity.state)) {
      if (offenced_crawl && !waypoint_near) {
        this.entityEnterCrawl(entity);
      }
    }

    // 오랜 시간 공격받지 않았을 경우, 다시 일어나서 움직입니다.
    if (entity.state === 'crawl' && entity.crawlTick.expired(tick)) {
      entity.state = 'stand';
    }

    if (entity.waypoint_rule.ty === 'cover' && entity.aimtarget) {
      const dist = entity.pos.dist(entity.aimtarget.pos);
      const exposed = entity.firearm_range < dist && entity.aimtarget.firearm_range > dist;
      let transit = false;

      // 반격할 수 없는 상황에 오래 노출된 경우, 정책을 바꿉니다.
      if (entity.movespeed === 0 && exposed) {
        transit = true;
      }
      // 교전을 시작할 수 없는 경우
      if (tick - entity.waypoint_rule.tick > this.ticksFromSec(opts.COVER_FIRE_DURATION)) {
        transit = true;
      }
      const progress = entities.find((e) => e.state !== 'dead' && e.movespeed > 0);
      if (!progress) {
        transit = true;
      }
      // 지켜야 할 지역이 있는 경우
      if (entity.waypoint_rule.area) {
        transit = false;
      }

      if (transit) {
        entity.push_rule(tick, { ty: 'cover-fire', initiator: entity.aimtarget });
        reroute = true;
      }
    }

    if (entity.waypoint?.cp && entity.waypoint.pos.eq(entity.pos)) {
      if (entity.waypoint.cp.obstacle?.ty === 'half' && !['covered', 'hide'].includes(entity.state)) {
        entity.state = 'covered';
      }
    }

    const opponents = this.entityVisibleOpponents(entity);
    if (entity.waypoint_rule.ty === 'idle' && entity.ty !== 'vip') {
      if (opponents.length > 0) {
        entity.push_rule(tick, { ty: 'cover-fire', initiator: opponents[0] });
        reroute = true;

        this.onTriggerEntityAwareEnemy(entity);
      }
    }

    // TODO: 하드코딩 고치기
    /*
    if (entity.waypoint_rule.ty === 'idle' && !entity.waypoint_rule.alert && this.playerstats.uncover >= 25) {
      entity.push_rule(tick, { ty: 'idle', alert: true });
    }
    */

    const allies = [...this.entityVisibleAllies(entity), entity];
    if (entity.waypoint_rule.ty === 'idle') {
      // idle awake
      // TODO: handle multiple offencer?
      const offenced = allies.map((e) => this.entityOffenced(e)).find((e) => e !== null);
      const initiated = allies.find((e) => e.waypoint_rule.initiator);
      if (offenced) {
        // check visibility
        const visible = this.entityAware(entity, offenced.source);

        if (!world.exp_search || visible) {
          entity.push_rule(tick, { ty: 'cover-fire', initiator: offenced.source });
          reroute = true;
          this.onTriggerEntityAwareAllyDamaged(entity);
        } else {
          // TODO
          const initiator = offenced.source;
          entity.push_rule(tick, { ty: 'explore', initiator });
          entity.push_rule(tick, { ty: 'hide', initiator, expires_at: tick + this.ticksFromSec(10) });
          reroute = true;
        }
      }
      if (initiated && !reroute) {
        entity.push_rule(tick, { ty: 'explore', initiator: initiated.waypoint_rule.initiator });
        reroute = true;
      }
    }

    const cover_ty = this.entityCoverPoilcy(entity);
    if (offenced && ['explore', 'hide'].includes(entity.waypoint_rule.ty)) {
      const visible = this.entityAware(entity, offenced.source);
      if (!world.exp_search || visible) {
        this.entityEngaged(entity, offenced.source, false);
        reroute = true;
      }
    }

    if (offenced && ['follow', 'capture-goal'].includes(entity.waypoint_rule.ty) && entity.ty !== 'vip') {
      entity.push_rule(tick, { ty: 'cover-fire', initiator: offenced.source });
      reroute = true;
    }

    let last_rule = entity.waypoint_rule.ty;

    if (entity.waypoint_rule.initiator) {
      const { initiator } = entity.waypoint_rule;
      const lost = initiator.state === 'dead' || !this.entityAware(entity, initiator);
      if (lost) {
        if (entity.aimtarget) {
          // 교전이 아직 끝나지 않은 경우
          entity.waypoint_rule.initiator = entity.aimtarget;
        } else {
          entity.pop_rule();
        }
      }
    }
    if (entity.aimtarget && entity.aimtarget.state !== 'dead' && ['explore', 'hide', 'capture-goal'].includes(entity.waypoint_rule.ty)) {
      this.entityEngaged(entity, entity.aimtarget, false);
    }

    if (last_rule !== entity.waypoint_rule.ty) {
      if (entity.waypoint_rule.ty === 'explore') {
        this.onTriggerEntityStateChanged('explore');
      } else {
        this.onTriggerEntityStateChanged('engage');
      }
    }

    function entityInGoal(entity, target_goal) {
      const goal = entity.waypoint_rule.goal;
      if (!goal || target_goal !== goal) {
        return false;
      }
      return goal && goal.pos.dist(entity.pos) < opts.GOAL_RADIUS;
    }

    // TODO: 미션 시나리오 만들기
    if (entity.waypoint_rule.ty === 'mission') {
      for (const rule of this.mission_rules) {
        entity.push_rule(tick, { ...rule });
      }
    }

    // TODO: 투척물 관련
    if (!['cover', 'cover-fire', 'hide'].includes(entity.waypoint_rule.ty)) {
      const t = this.throwables.find((t) => !t.blast_timer.expired(tick) && this.entityVisible(entity, t.pos));
      if (t) {
        entity.push_rule(tick, { ty: 'hide', source: t.pos, expires_at: tick + this.ticksFromSec(5) });
        this.onTriggerEntityAwareThrowable(entity);
      }
    }

    // TODO
    if (entity.waypoint_rule.ty === 'interact') {
      const object = this.objects[entity.waypoint_rule.object];
      const chaser = this.entities.find((e) => {
        const r = e.waypoint_rule;
        return e.state !== 'dead' && r.ty === 'interact-object' && r.object === object;
      });
      if (!chaser) {
        if (this.entityVisible(entity, object.pos)) {
          entity.push_rule(tick, { ty: 'interact-object', object });
        } else {
          entity.push_rule(tick, { ty: 'explore', area: this.spawnareas[object.spawnarea] });
        }
      } else {
        entity.push_rule(tick, { ty: 'cover' });
      }
    }

    // TODO: execution order
    if (entity.waypoint_rule.ty === 'interact-object' && entity.waypoint_rule.object.pos.dist(entity.pos) < opts.INTERACT_RADIUS) {
      // 수집 완료
      if (entity.collectTick.expired_exact(tick)) {

        const { playerstats } = this;
        const { loot } = playerstats;
        const object = entity.waypoint_rule.object;
        object.owned = true;
        entity.objects.push(object);

        loot.count += 1;

        const entities = this.entities.filter((e) => e.state !== 'dead' && e.team === entity.team);
        for (const e of entities) {
          e.pop_rule_chain((r) => r.ty === 'interact' && r.object === object.seq);
        }
      } else if (!entity.collectTick.expired(tick)) {
        // 수집중
      } else {
        // 수집 시작
        entity.collectTick = new TickTimer(tick, this.ticksFromSec(opts.INTERACT_DURATION));
      }
    }

    // TODO: explore: 건물 있는 경우 건물을 순차적으로 탐색하기
    /*
    if (entity.waypoint_rule.ty === 'explore'
      && entity.waypoint_rule.area?.areastate?.structures) {
      const { area } = entity.waypoint_rule;
      entity.pop_rule();

      for (const s of area.areastate.structures) {
        // 개별 방 수색
        entity.push_rule(tick, { ty: 'explore', area: s.shape });
      }

      this.entityNextWaypoint(entity);
    }
    */

    if (entity.waypoint_rule.ty === 'follow-leader') {
      if (entity.leader?.state === 'dead') {
        const cur_leader = entity.leader;
        const followers = entities.filter((e) => e.leader === cur_leader && e.state !== 'dead');
        if (followers.length > 0) {
          // find new leader
          const next_leader = followers[0];
          next_leader.rules = cur_leader.rules.map((r) => ({ ...r }));
          next_leader.leader = null;

          // propagate to followers
          for (const follower of followers) {
            if (follower !== next_leader) {
              follower.leader = next_leader;
            }
          }
        }
      }
      const leader = entity.leader;
      if (!leader) {
        if (entity.waypoint_rule.ty === 'follow-leader') {
          entity.pop_rule();
        }
      } else {
        const followers = entities.filter((e) => e.leader === leader);
        const order = followers.findIndex((e) => e === entity) + 1;
        entity.push_rule(tick, { ty: 'follow', follow_target: leader, follow_dist: order * opts.EXP_FOLLOW_DIST });
      }
    }

    if (entity.waypoint_rule.ty === 'follow') {
      if (!entity.waypoint_rule.follow_target || entity.waypoint_rule.follow_target?.state === 'dead') {
        entity.pop_rule();
      }
      if (entity.aimtarget) {
        this.entityEngaged(entity, entity.aimtarget, true);
      }
    }

    this.entityUpdateBreach(entity);

    if (entity.waypoint_rule.ty === 'breaching') {
      const common = entity.waypoint_rule.common;
      const breach_open = common.dooredge?.edge.obstacle.doorstate.open;
      if (common.breach_half && breach_open) {
        entity.pop_rule();
      }

      if (common.door_entry) {
        if (common.all_allies_over || (entity.waypoint_rule.breaching_over && !opts.WAIT_BREACHING_OVER)) {
          entity.pop_rule();
        }
      } else if (breach_open) {
        entity.pop_rule();
      }
    }

    if (entity.waypoint_rule.ty === 'capture') {
      // capture: choose goal
      const p = this.choosecapturepoint(entity, null);
      if (p && p.obstacle) {
        entity.push_rule(tick, { ty: 'capture-goal', goal: p.obstacle });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule(tick, { ty: cover_ty });
      }
    }
    if (entity.waypoint_rule.ty === 'capture-goal') {
      const { goal } = entity.waypoint_rule;

      let captured = false;
      if (goal.waypoint) {
        // waypoint인 경우, 모든 구성원이 goal에 도착할 때 까지 기다립니다
        captured = entityInGoal(entity, goal);
      } else {
        captured = goal.goalstate.owner >= 0;
      }

      this.maybePopGroupRule(entity, captured);

      // goal에 나보다 먼저 들어가 있는 상대가 있는 경우
      const opponent = entities.find((e) => e.state !== 'dead' && e.team !== entity.team && entityInGoal(e, goal));
      if (opponent) {
        entity.push_rule(tick, { ty: 'cover-fire', target: opponent });
      }
    }

    if (entity.waypoint_rule.ty === 'gather') {
      this.maybePopGroupRule(entity, entity.waypoint_rule.leader.pos.dist(entity.pos) < 20);
    }

    if (entity.waypoint_rule.ty === 'cover-capture') {
      const nearest = goals.slice();
      nearest.sort((a, b) => entity.pos.dist(a.pos) - entity.pos.dist(b.pos));
      if (nearest.length > 0) {
        entity.push_rule(tick, { ty: 'cover-goal', goal: nearest[0] });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule(tick, { ty: cover_ty });
      }
    }

    // reload
    let ammo_left = entity.firearm_ammo_max;
    if (entity.firearm) {
      ammo_left = Math.min(ammo_left, entity.firearm.ammo_total);
    }
    if (entity.reloadTick.expired_exact(tick)) {
      entity.ammo = ammo_left;

      if (entity.perk_reload_one_more) {
        entity.ammo += 1;
        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_reload_one_more' });
      }

      if (entity.perk_armor_recover && entity.armor === 0 && !entity._perk_armor_recovered) {
        entity.armor = entity.armor_max;
        entity._perk_armor_recovered = true;

        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_armor_recover' });
      }
      // TODO: 실내 데모
      // console.assert(entity.waypoint_rule.ty === 'reload');
      // entity.pop_rule();
    }

    //특정 퍽이 없는 아군 유닛은 잔탄이 남아 있으면 재장전을 하지 않도록 막아놓음
    const tactical_reload = entity.team > 0 || typeof entity.perk2_common_shooting_tactical_reload === 'object';
    if (entity.ammo < ammo_left || entity.ammo === 0) {
      if (tactical_reload && entity.reloadTick.expired(tick) && entity.reloadShootIdleTick.expired(tick)) {
        // 유효한 조준을 유지하는 동안 재장전하지 않습니다. aim_samples_fire_thres가 너무 높으면 발생
        if (!(entity.aimtarget && checkcover(entity.pos, entity.aimtarget.pos, routes) !== 3)) {
          reload = true;
        }
      }

      if (!entity.aimtarget && entity.lastRiskTick.expired(tick) && entity.waypoint) {
        const r = this.riskdir(entity, entity.waypoint.pos);
        if (r.selected_val === 0) {
          if (tactical_reload) {
            reload = true;
          }

          if (entity.team === 0 && world.exp_transfer_knowledge) {
            this.entityTransferKnowledge(entity);
          }
        }
      }
    }

    if (reload && entity.ammo < ammo_left || entity.ammo === 0) {
      this.entityReload(entity);
      // TODO: 실내 데모
      // reroute = true;
    }

    if (!entity.waypoint || reroute) {
      /*
      if (this.entityUpdateAimtarget(entity)) {
        // reset route. allow_cover_edge용
        entity.lastRouteTick = new TickTimer(tick, 0);
      }
      */
      this.entityNextWaypoint(entity);
    }

    entity.waypoint_dir = undefined;
    if (entity.waypoint && entity.waypoint.path) {
      const path = entity.waypoint.path;
      if (path.length > 1 && !entity.pos.eq(path[path.length - 1].pos)) {
        entity.waypoint_dir = path[1].pos.sub(path[0].pos).norm();
      }
    }

    let navigate = true;

    this.entityUpdateAimvar(entity);

    // TODO: rule === 'fire'에서 재장전이 필요한 경우 대기합니다.
    if (entity.ammo === 0 && entity.waypoint_rule.ty === 'fire') {
      navigate = false;
    }

    // 기본적으로 진행 방향을 봅니다.
    if (!entity.aimtarget) {
      const dir = this.entityCalculateAimNotAimtarget(entity);
      if (dir !== null) {
        this.entityUpdateAim(entity, dir);
      }
    }

    // aim
    // 시체에 대고도 가끔 쏩니다.
    if (entity.aimtarget) {
      const { aimvalid } = this.entityHandleFire(entity);
      // TODO: rule === 'fire'에서 상대를 사격할 수 있는 상태인 경우 정지합니다.
      if (entity.waypoint_rule.ty === 'fire' && aimvalid) {
        navigate = false;
      }
    } else {
      entity.shootPatternIdx = 0;
    }
    if (navigate) {
      this.entityNavigate(entity);
    }
  }

  changeSquadLeader(area = 0) {
    const entities = this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === area);
    if (entities.length === 0) {
      return;
    }

    const next_leader = entities[0];
    const cur_leader = entities.find((e) => !e.leader);
    const len = entities.length;

    const temp_rule = [...cur_leader.rules.map((r) => ({ ...r }))];
    cur_leader.rules = next_leader.rules.map((r) => ({ ...r }));
    next_leader.rules = temp_rule;
    next_leader.leader = null;

    for (let i = 1; i < len; i++) {
      entities[i].leader = next_leader;
    }

    if (next_leader.waypoint_rule.ty === 'follow-leader') {
      next_leader.pop_rule();
    }

    for (let i = 1; i < len; i++) {
      const entity = entities[i];
      const followers = entities.filter((e) => e.leader === next_leader);
      const order = followers.findIndex((e) => e === entity) + 1;

      if (entity.has_rule('follow')) {
        const follow_rule = entity.rules.find((r) => r.ty === 'follow');
        follow_rule.follow_target = next_leader;
        follow_rule.follow_dist = order * opts.EXP_FOLLOW_DIST;
      }

      if (entity.waypoint_rule !== 'follow-leader') {
        continue;
      }
      entity.pop_rule();
      entity.push_rule(tick, { ty: 'follow', follow_target: leader, follow_dist: order * opts.EXP_FOLLOW_DIST });
    }
  }

  squadUpdate(area = 0) {
    this.changeSquadLeader(area);
  }

  onTickAreas() {
    const { entities } = this;
    let areas = [];
    for (let i = 0; i < entities.length; i++) {
      const { team, spawnarea } = entities[i];
      if (team !== 0 || spawnarea < 0) {
        continue;
      }
      if (!areas.includes(spawnarea)) {
        areas.push(spawnarea);
      }
    }

    for (const area of areas) {
      this.onTickArea(area);
    }
  }

  //aimtarget 여부가 아닌 segments 기반으로 할 수도 있습니다.
  entityEngageEnemies(team) {
    return this.entities.filter((e) => e.state !== 'dead' && e.team !== team && e.aimtarget);
  }

  //제압 사격 시 돌진하는 오퍼레이터가 속해야 하는 반평면을 반환합니다.
  calculateSupressingHalfPlane(entities, enemies) {
    const mid_entities = v2.centroid(entities.map((e) => e.pos));
    const mid_enemies = v2.centroid(enemies.map((e) => e.pos));

    return {
      origin: mid_enemies,
      dir: mid_enemies.sub(mid_entities).normvec(),
    }
  }

  //제압 사격 시 아군과 적의 정면에 대한 바운딩 박스를 반환합니다.
  calculateSupressingPoylgon(entities, enemies) {
    let poses = [];
    for (let i = entities.length - 3; i >= 0; i--) {
      poses.push(entities[i].pos);
    }
    for (const enemy of enemies) {
      poses.push(enemy.pos);
    }

    let hull = createConvexHull(poses);
    const mid = v2.centroid(hull);
    for (let i = 0; i < hull.length; i++) {
      const dir = hull[i].sub(mid).norm();
      hull[i] = hull[i].add(dir.mul(opts.SUPRESSING_ENEMY_RADIUS));
    }

    return hull;
  }

  //제압 사격 시 돌진할 위치를 반환합니다.
  chooseSupressingPoint(entity) {
    const { routes, entities } = this;

    const allies = entities.filter((e) => e !== entity && e.state !== 'dead')
      .filter((e) => e.team === entity.team && e.spawnarea === entity.spawnarea);
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    const enemies = this.entityEngageEnemies(entity.team);

    const { origin, dir } = this.calculateSupressingHalfPlane(allies, enemies);
    const hull = this.calculateSupressingPoylgon(allies, enemies);

    const dists = routePathfindAll(routes, entity.pos);

    const itemFn = (pos, obstacle, i) => {
      if (dists[i] === -1) {
        return null;
      }

      if (v2.inHalfPlane(origin, dir, pos)) {
        return null;
      }

      if (geomContains(pos, hull)) {
        return null;
      }

      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let cover = 3;
      for (const enemy of enemies) {
        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      return {
        pos,
        cover,
        obstacle,
        dist: dists[i],
      }
    };

    const cmpFn = (a, b) => {
      if (a.cover < b.cover) { return -1; }
      if (a.cover > b.cover) { return 1; }

      if (a.dist < b.dist) { return -1; }
      if (a.dist > b.dist) { return 1; }
      return 0;
    };

    return {
      cp: this.choosepoint(entity.pos, itemFn, cmpFn),
      hull,
    };
  }

  //제압 사격 관련 틱을 처리합니다.
  onTickSupressing(entities, controls) {
    if (!opts.TEST_ALWAYS_SUPRESSING && controls.firepolicy !== 'supress') {
      return;
    }

    const len = entities.length;
    if (len < 3) {
      return;
    }

    const is_expire_supressing = () => {
      if (controls.state !== 'engage') {
        return true;
      }

      for (const entity of entities) {
        const rule = entity.waypoint_rule;
        if (rule.ty === 'supressing' && entity.waypoint.cp) {
          if (entity.pos.dist(entity.waypoint.cp.pos) >= opts.eps * 5) {
            return false;
          }
        }
      }

      return true;
    };

    //매 틱마다 최단 경로를 구해보기 때문에 성능 이슈가 있을 수 있을 수 있습니다.
    const can_supressing = (upper_entity, lower_entity) => {
      const entities = [upper_entity, lower_entity];
      for (const entity of entities) {
        const { routes } = this;
        const { cp, hull } = this.chooseSupressingPoint(entity);

        if (!cp) {
          return false;
        }

        let skips = [];
        for (let i = 0; i < this.routes.nodes.length; i++) {
          const pos = this.routes.nodes[i].pos;
          //바운딩 박스로 느슨하게 체크한 뒤 geomContrains를 체크하도록 하면 성능을 개선할 수 있습니다.
          if (geomContains(pos, hull)) {
            skips.push(i);
          }
        }
        if (!routePathfind(routes, entity.pos, cp.pos, undefined, skips, entity.allow_cover_edge)) {
          return false;
        }
      }
      return true;
    }

    if (is_expire_supressing()) {
      for (const entity of entities) {
        const rule = entity.waypoint_rule;
        if (rule.ty === 'supressing') {
          rule.expires_at = this.tick;
        }
      }
    }

    if (controls.state === 'engage' && !controls.used_supressing) {
      const team0 = entities[0].team;
      const enemies = this.entityEngageEnemies(team0);

      //enemis가 2명 이상 있을 때 발동합니다.
      if (enemies.length > 1) {
        //기획 상 뒷 번호에 있는 오퍼레이터는 공격력이 높으므로 돌진해야할 대상입니다.
        const chargers = [entities[len - 1], entities[len - 2]];
        const tick_duration = this.tick + this.ticksFromSec(opts.SUPRESSING_DURATION);
        if (can_supressing(chargers[0], chargers[1])) {
          console.log(`supressing: ${chargers[0].name} | ${chargers[1].name} `);

          for (const charger of chargers) {
            charger.push_rule(this.tick,
              {
                ty: 'supressing',
                expires_at: tick_duration,
                persistent: false,
                no_override: true,
                hold_fire: true,
                prev_perks: {
                  perk_targetpref_low: charger.perk_targetpref_low,
                }
              });
            charger.perk_targetpref_low = true;

            this.bubblePush(charger, L('제압 사격'));
          }

          controls.used_supressing = true;
        }
      }
    }
  }

  //피해 분산에서 해당되는 오퍼레이터를 선택합니다.
  chooseEntityDamageCover(entities, controls) {
    const delta = (e) => controls.prev_battle_op_lifes[e.name] - e.life;

    let [front, back] = [null, null];

    if (entities.length >= 2) {
      back = entities.reduce((ret, e) => {
        return delta(ret) * e.life_max < delta(e) * ret.life_max ? e : ret;
      });
      if (back && delta(back) < opts.DAMAGE_COVER_ACTIVATE_LIFE_RATIO * back.life_max) {
        back = null;
      }

      front = entities.reduce((ret, e) => {
        return e !== back && ret.life < e.life ? e : ret;
      });
      if (front === back) {
        front = null;
      }
    }

    return {
      front,
      back,
    }
  }

  //피해 분산 상황에서 앞으로 갈 위치를 선택합니다.
  chooseNearEnemiesPoint(entity) {
    const { routes } = this;

    const alive_entities = this.entities.filter((e) => e.state !== 'dead');

    const allies = alive_entities.filter((e) => e.team === entity.team && e.spawnarea === entity.spawnarea);
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    const area = entity.waypoint_rule.area;

    const enemies = this.entityEngageEnemies(entity.team);
    const targets = allies.concat(enemies);

    const mid = v2.centroid(targets.map((e) => e.pos));
    const dists = routePathfindAll(routes, entity.pos);

    return this.choosepoint(entity, (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist === -1) {
        return null;
      }

      if (pos.dist(entity.waypoint_rule.start_pos) > opts.DAMAGE_COVER_CHARGE_MAX_DISTANCE) {
        return null;
      }

      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      // area가 지정된 경우 해당 area만 탐색
      if (area && !geomContains(pos, area.polygon)) {
        return null;
      }

      return {
        pos,
        dist,
        obstacle,
        score: mid.dist(pos),
      }
    }, (a, b) => {
      if (a.score < b.score) { return -1; }
      if (a.score > b.score) { return 1; }
      return 0;
    }, true);
  }

  onTickDamageCover(entities, controls) {
    if (!opts.TEST_ALWAYS_DAMAGE_COVER && !controls.damage_cover) {
      return;
    }

    if (controls.state !== 'engage') {
      for (const entity of entities) {
        const rule = entity.waypoint_rule;
        if (rule.ty === 'cover-charge' || rule.ty === 'cover-hide') {
          rule.expires_at = this.tick;
        }
      }
    }

    if (controls.state === 'engage' && !controls.used_damage_distribution) {
      const { front: front_entity, back: back_entity } = this.chooseEntityDamageCover(entities, controls);
      if (!front_entity || !back_entity) {
        return;
      }

      console.log(`execute Damage Cover | back : ${back_entity.name} | front : ${front_entity.name} `);

      const tick_duration = this.tick + this.ticksFromSec(opts.DAMAGE_COVER_DURATION);

      front_entity.push_rule(this.tick,
        {
          ty: 'cover-charge',
          expires_at: tick_duration,
          persistent: false,
          no_override: true,
          prev_perk_targetpref_high: front_entity.perk_targetpref_high,
          prev_perk_targetpref_low: front_entity.perk_targetpref_low,
          start_pos: front_entity.pos,
        });
      front_entity.perk_targetpref_high = true;

      back_entity.push_rule(this.tick,
        {
          ty: 'cover-hide',
          expires_at: tick_duration,
          persistent: false,
          no_override: true,
          hold_fire: true,
          prev_perk_targetpref_high: back_entity.perk_targetpref_high,
          prev_perk_targetpref_low: back_entity.perk_targetpref_low,
        });
      back_entity.perk_targetpref_low = true;

      this.bubblePush(front_entity, L('피해 분산'));
      this.bubblePush(back_entity, L('피해 분산'));

      controls.used_damage_distribution = true;
    }
  }

  isEngageArea(area = 0) {
    const controls = this.controls(area);
    return controls.state === 'engage';
  }

  // 힐팩을 소모하는 퍽
  onTickHealPackPerk(area, controls, entities) {
    const { tick } = this;
    const has_heals = (entity) => entity.heals && entity.heals.length > 0;

    const use_heals_entities = [];

    // 아드레날린 퍽
    {
      const heal_thres = this.controlGet(area, 'healopts');
      if (heal_thres > 0) {
        let sum_heal_amount = 0;
        for (const entity of entities) {
          const perk = entity.perk2_medic_adrenaline;
          if (!perk) {
            continue;
          }
          if (perk.heal_amount && has_heals(entity)) {
            use_heals_entities.push(entity);
            sum_heal_amount += perk.heal_amount;
          }
        }
        for (const entity of entities) {
          if (!entity.perk2_medic_adrenaline) {
            entity.perk2_medic_adrenaline = {};
          }
          const perk = entity.perk2_medic_adrenaline;
          perk.activate_heal_amount = sum_heal_amount;
          perk.started_tick = tick;

          const effect = entity.add_effect('perk2_medic_adrenaline');
          effect.heal_amount = sum_heal_amount;
        }
      }
    }
    // 스팀팩
    {
      const heal_thres = this.controlGet(area, 'healopts');
      if (heal_thres > 0) {
        let sum_damage_mult = 0;
        let sum_penetrate_mult = 0;
        for (const entity of entities) {
          const perk = entity.perk2_medic_stimpack;
          if (!perk) {
            continue;
          }
          if (perk.all_atk_damage_rate_increase && has_heals(entity)) {
            use_heals_entities.push(entity);
            sum_damage_mult += perk.all_atk_damage_rate_increase;
            sum_penetrate_mult += perk.all_atk_damage_rate_increase;
          }
        }
        for (const entity of entities) {
          if (!entity.perk2_medic_stimpack) {
            entity.perk2_medic_stimpack = {};
          }
          const perk = entity.perk2_medic_stimpack;
          perk.activate_damage_mult = sum_damage_mult;
          perk.activate_penetrate_mult = sum_penetrate_mult;

          const effect = entity.add_effect('perk2_medic_stimpack');
          effect.life_atk_damage_rate_increase = sum_damage_mult;
          effect.shield_atk_damage_rate_increase = sum_penetrate_mult;
        }
      }
    }

    const uniques = _.uniq(use_heals_entities);
    for (const entity of uniques) {
      entity.heals.splice(0, 1);
    }
  }

  onTickAreaEngageStarted(area, controls, entities) {
    const { tick, segment } = this;

    console.log(`engage-start`);
    this.onTriggerSquadEngageStart(area);

    if (segment) {
      this.onTriggerSegmentStart(segment.idx);
    }

    // damage-cover
    {
      controls.used_damage_distribution = false;

      controls.prev_battle_op_lifes = {};
      for (const entity of entities) {
        controls.prev_battle_op_lifes[entity.name] = entity.life;
      }
    }
    // supressing
    {
      controls.used_supressing = false;
    }
    // 전열 수비 퍽
    {
      let sum_damage_reduction = 0;
      let sum_penetrate_reduction = 0;
      for (const ally of entities) {
        if (!ally.perk2_breacher_frontline) {
          ally.perk2_breacher_frontline = {};
        }
        const perk = ally.perk2_breacher_frontline;

        perk.expires_at = new TickTimer(tick, this.ticksFromSec(8));

        if (perk.all_damage_reduction) {
          sum_damage_reduction += perk.all_damage_reduction;
          sum_penetrate_reduction += perk.all_damage_reduction;
        }
      }
      for (const ally of entities) {
        const perk = ally.perk2_breacher_frontline;
        perk.activation_damage_reduction = sum_damage_reduction;
        perk.activation_penetrate_reduction = sum_penetrate_reduction;

        const effect = ally.add_effect('perk2_breacher_frontline');
        effect.expire_at = tick + this.ticksFromSec(8);
        effect.life_damage_reduction = sum_damage_reduction;
        effect.shield_damage_reduction = sum_penetrate_reduction;
      }
    }

    this.onTickHealPackPerk(area, controls, entities);

    // 전투 고양 퍽 초기화
    {
      const allies = this.entities.filter((e) => e.spawnarea === area);
      for (const ally of allies) {
        if (ally.perk2_pointman_battle_excite) {
          ally.perk2_pointman_battle_excite.activation_mult = 0;
        }
      }
    }

    // 블러드 레이지 퍽 초기화
    {
      const allies = this.entities.filter((e) => e.spawnarea === area);
      for (const ally of allies) {
        if (ally.perk2_pointman_blood_rage) {
          ally.perk2_pointman_blood_rage.activation_mult = 0;
        }
      }
    }

    // 긴급 구호 퍽
    {
      const heal_thres = this.controlGet(area, 'healopts');
      if (heal_thres > 0) {
        let sum_shield = 0;
        for (const entity of entities) {
          const perk = entity.perk2_medic_emergency_rescue;
          if (!perk) {
            continue;
          }
          if (perk.shield_amount && entity.heals && entity.heals.length > 0) {
            entity.heals.splice(0, 1);
            sum_shield += perk.shield_amount;
          }
        }
        for (const entity of entities) {
          if (!entity.perk2_medic_emergency_rescue) {
            entity.perk2_medic_emergency_rescue = {};
          }
          const perk = entity.perk2_medic_emergency_rescue;
          perk.charge_shield = sum_shield;
          entity.shield += sum_shield;

          perk.charge_shield_max = sum_shield;
          entity.shield_max += sum_shield;
        }
      }
    }

    {
      const allies = this.entities.filter((e) => e.team === 0 && e.state !== 'dead');
      for (const ally of allies) {
        if (ally.use_riskdir && typeof ally.perk2_common_intelligence_piecut === 'object') {
          this.journal.push({ tick, ty: 'perk', entity: ally, perk: 'perk2_common_intelligence_piecut', targets: [ally] });

          const perk = ally.perk2_common_intelligence_piecut;
          const duration = this.ticksFromSec(perk.duration_seconds);
          ally.piecutBuffTick = new TickTimer(tick, duration);

          const effect = ally.add_effect('perk2_common_intelligence_piecut');
          effect.expire_at = tick + duration;
          effect.life_damage_reduction = perk.all_damage_reduction;
          effect.shield_damage_reduction = perk.all_damage_reduction;
        }
      }
    }
  }

  onTickAreaEngageEnded(area, controls, entities) {
    const { tick } = this;

    console.log(`engage-ended`);
    this.onTriggerSquadEngageEnd(area);

    const engaged = this.entities.filter((e) => e.team !== 0 && e.state === 'dead' && !e._engaged);
    for (const entity of engaged) {
      entity._engaged = true;
    }

    for (const entity of entities) {
      this.maybeRescueVIP(entity);
      this.maybeHeal(entity);
    }

    // 실드 충전 퍽 초기화
    {
      for (const ally of entities) {
        if (ally.perk2_breacher_shield_regen) {
          const perk = ally.perk2_breacher_shield_regen;
          if (perk.charge_shield !== undefined) {
            ally.shield -= perk.charge_shield;
            perk.charge_shield = 0;

            ally.shield_max -= perk.charge_shield_max;
            perk.charge_shield_max = 0;
          }
        }
      }
    }
    // 아드레날린 퍽
    {
      for (const entity of entities) {
        const perk = entity.perk2_medic_adrenaline;
        if (perk && perk.activate_heal_amount) {
          perk.activate_heal_amount = 0;
          perk.started_tick = -1;

          entity.remove_effect('perk2_medic_adrenaline');
        }
      }
    }
    // 스팀팩
    {
      for (const entity of entities) {
        const perk = entity.perk2_medic_stimpack;
        if (perk) {
          if (perk.activate_damage_mult) {
            perk.activate_damage_mult = 0;
          }
          if (perk.activate_penetrate_mult) {
            perk.activate_penetrate_mult = 0;
          }

          entity.remove_effect('perk2_medic_stimpack');
        }
      }
    }
    // 긴급 구호 퍽
    {
      for (const entity of entities) {
        const perk = entity.perk2_medic_emergency_rescue;
        if (perk && perk.charge_shield !== undefined) {
          entity.shield -= perk.charge_shield;
          perk.charge_shield = 0;

          entity.shield_max -= perk.charge_shield_max;
          perk.charge_shield_max = 0;
        }
      }
    }
    // 블러드 레이지 퍽 초기화
    {
      const allies = this.entities.filter((e) => e.spawnarea === area);
      for (const ally of allies) {
        if (ally.perk2_pointman_blood_rage) {
          ally.perk2_pointman_blood_rage.activation_mult = 0;
          ally.remove_effect('perk2_pointman_blood_rage');
        }
      }
    }
    // 전투 고양 퍽 초기화
    {
      const allies = this.entities.filter((e) => e.spawnarea === area);
      for (const ally of allies) {
        if (ally.perk2_pointman_battle_excite) {
          ally.perk2_pointman_battle_excite.activation_mult = 0;
          ally.remove_effect('perk2_pointman_battle_excite');
        }
      }
    }

    if (opts.PS_ENGAGE_PAUSE) {
      this.pending_prompts.push({
        area,
        expire_at: tick,
        queue_at: tick,
        pause: true,
        prompt_options: [
          {
            title: `응급처치`, actions: [
              { action: 'firstaid', area },
            ],
          },
          {
            title: `임무진행`, default: true, actions: [
              { action: 'reorg', area },
            ],
          },
          {
            title: `귀환`, actions: [
              { action: 'withdraw', area },
            ],
          },
        ],
      });
    }
  }

  onTickArea(area) {
    const { tick } = this;

    const entities = this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === area);
    const controls = this.controls(area);

    const last_state = controls.state;
    controls.state = this.areaState(area);

    //engage started
    if (last_state !== 'engage' && controls.state === 'engage') {
      controls.engaged_duration = 0;
      controls.engage_started = true;

      this.onTickAreaEngageStarted(area, controls, entities);
    } else {
      controls.engage_started = false;
    }

    // 분대 단위 perk 처리하는 함수들입니다.
    // 만약 engage_started, engage_ended에 어떠한 처리를 하고 싶다면 onTickArea에서 처리해야합니다.
    // engage_started, engage_ended 상황에서 controls.perk이 활성화되어 있지 않을 수 있기 때문입니다.
    this.onTickDamageCover(entities, controls);
    this.onTickSupressing(entities, controls);

    if (controls.state === 'engage') {
      controls.engaged_duration += 1.0 / opts.tps;
    }

    if (last_state === 'engage' && controls.state !== 'engage') {
      controls.engaged_duration = 0;
      controls.engage_ended = true;

      this.onTickAreaEngageEnded(area, controls, entities);
    } else {
      controls.engage_ended = false;
    }

    let tick_mult = opts.PS_UNCOVER_DURATION_IDLE_MULT;
    if (controls.state === 'engage') {
      let player_count = entities.length;
      let enemy_count = this.entities.filter((e) => e.team !== 0).length;
      tick_mult = opts.PS_UNCOVER_DURATION_ENGAGE_MULT * player_count / enemy_count;
    } else if (entities.find((e) => e.waypoint_rule.ty.startsWith('interact'))) {
      tick_mult = opts.PS_UNCOVER_DURATION_SEARCH_MULT;
    }

    let { playerstats } = this;
    playerstats.uncover = Math.min(playerstats.uncover + (1 / 30 * tick_mult), 100);

    if (controls.state === 'reorg' && controls.reorgTimer?.expired(tick)) {
      controls.reorg = false;
    }

    if (opts.AREA_GOVERNER_COVER_REORG) {
      // 교전 후 reorg
      if (engage_ended) {
        controls.reorg = true;
      }

      if (last_state !== 'reorg' && controls.state === 'reorg') {
        controls.reorgTimer = new TickTimer(tick, this.ticksFromSec(opts.AREA_GOVERNER_COVER_REORG_DURATION));
      }
    }

    if (controls.state.includes('reorg')) {
      controls.ticks_reorg += 1;
    }

    // TODO:
    for (const stalker of this.stalkers.filter((s) => s.tick === controls.ticks_reorg)) {
      const entity = this.spawnEntity(stalker.entity);
      entity.spawnarea = -1;
      entity.push_rule(tick, { ty: 'explore' });
    }

    // reorg 관련 처리
    if (controls.reorg) {
      for (const entity of entities) {
        if (!entity.has_rule('reorg')) {
          entity.push_rule(tick, { ty: 'reorg' });
          this.entityNextWaypoint0(entity, false);
        }
      }

      // reorg 중 교전이 발생한 경우
      const initiator = this.entities.find((e) => e.state !== 'dead' && entities.includes(e.aimtarget));
      if (initiator) {
        for (const entity of entities) {
          if (entity.waypoint_rule.ty === 'reorg') {
            const rule_next = this.entityCoverPoilcy(entity);
            entity.push_rule(tick, { ty: rule_next, initiator, transient: true });
          }
        }
      }
    } else {
      for (const entity of entities) {
        entity.pop_rule_chain((r) => r.ty === 'reorg');
      }
    }
  }

  areaState(spawnarea) {
    const entities = this.entities.filter((e) => e.spawnarea === spawnarea);

    let engaged = this.entities.find((e) => e.state !== 'dead' && entities.includes(e.aimtarget)) !== undefined;
    if (!engaged && entities.find((e) => e.state !== 'dead' && e.aimtargetshoots > 0)) {
      engaged = true;
    }

    let covering = this.controls(spawnarea).reorg;
    let covered = this.controlReorgWait(spawnarea);

    let state = 'explore';
    if (engaged) {
      state = 'engage';
    } else if (covering) {
      state = 'reorg0';
      if (covered) {
        state = 'reorg';
      }
    }

    return state;
  }

  // areaState(spawnarea) {
  //   const controls = this.controls(spawnarea);
  //   const entities = this.entities.filter((e) => e.spawnarea === spawnarea);

  //   let engaged = false;
  //   if (this.entities.find((e) => e.state !== 'dead' && entities.includes(e.aimtarget))) {
  //     engaged = true;
  //   }
  //   if (entities.find((e) => e.state !== 'dead' && e.aimtargetshoots > 0)) {
  //     engaged = true;
  //   }
  //   if (entities.find((e) => e.state !== 'dead' && e.aimtarget && e.aimtarget.state !== 'dead')) {
  //     engaged = true;
  //   }

  //   if (engaged) {
  //     if (!controls.engage_segment_idx && this.segment) {
  //       controls.engage_segment_idx = this.segment.idx;
  //     }
  //   } else {
  //     const { engage_segment_idx } = controls;
  //     if (engage_segment_idx) {
  //       if (!this.segments[engage_segment_idx].clear && entities.find((e) => e.is_fire_rule())) {
  //         engaged = true;
  //       } else {
  //         delete controls.engage_segment_idx;
  //       }
  //     }
  //   }

  //   let covering = this.controls(spawnarea).reorg;
  //   let covered = this.controlReorgWait(spawnarea);

  //   let state = 'explore';
  //   if (engaged) {
  //     state = 'engage';
  //   } else if (covering) {
  //     state = 'reorg0';
  //     if (covered) {
  //       state = 'reorg';
  //     }
  //   }

  //   return state;
  // }

  entityUpdateIcon(entity) {
    const { tick, trails } = this;

    const icons = [];

    if (entity.objects.length > 0) {
      icons.push('briefcase');
    }

    const dead = entity.state === 'dead';

    if (dead) {
      const trail = trails.find((trail) => trail.target === entity && trail.kill);
      if (trail && trail.crit) {
        icons.push('skull');
      }
    }

    if (!dead) {
      const rule_ty = entity.waypoint_rule.ty;

      if (entity.heals.length > 0) {
        icons.push('heal');
      }
      if (entity.throwables.length > 0) {
        icons.push('granade');
      }
      if (entity.attachables.length > 0) {
        // TODO: attachable icon
        icons.push('granade');
      }
      if (entity.aimtarget?.perk_targetpref_high) {
        icons.push('aim');
      }

      if (entity.movestate === 'low') {
        icons.push('alert');
      }

      if (rule_ty === 'explore') {
        icons.push('explore');
      } else if (rule_ty === 'idle') {
        icons.push('idle');
      } else if (rule_ty === 'reorg') {
        icons.push('gather');
      } else if (entity.moving) {
        if (entity.state === 'stand') {
          icons.push('walk');
        } else if (entity.state === 'dash') {
          icons.push('run');
        }
      }

      if (entity.state === 'covered') {
        icons.push('obstruct');
      }
      if (entity.state === 'crawl') {
        icons.push('crawl');
      }

      if (!entity.reloadTick.expired(tick)) {
        icons.push('reload');
      }

      if (this.entityEffect(entity, 'limvis')) {
        icons.push('blind');
      }

      if (entity.aimtarget && this.entityRangePenalty(entity, entity.pos.dist(entity.aimtarget.pos))) {
        icons.push('blind');
      }
    }
    entity.icons = icons;
  }

  triggerAction(action) {
    const { rng, tick } = this;
    switch (action.action) {
      case 'push_rule':
        const { actionrules } = action;
        let actiontargets = action.actiontargets;
        if (!actiontargets) {
          actiontargets = [action.actiontarget];
        }

        const entities = this.entities.filter((e) => {
          if (e.state === 'dead') {
            return false;
          }
          for (const actiontarget of actiontargets) {
            if (actiontarget.team !== undefined && actiontarget.team === e.team) {
              return true;
            }
            if (actiontarget.group !== undefined && actiontarget.group === e.group) {
              return true;
            }
            if (actiontarget.spawnarea !== undefined && actiontarget.spawnarea === e.spawnarea) {
              return true;
            }
            if (actiontarget.spawnarea_cover !== undefined) {
              const area = this.spawnareas[actiontarget.spawnarea_cover];
              if (geomContains(e.pos, area.polygon)) {
                return true;
              }
            }
          }
          return false;
        });

        const rules = actionrules.map((r) => {
          r = { ...r };
          if (!isNaN(r.area)) {
            r.area = this.spawnareas.find((s) => s.idx === r.area);
          }
          if (!isNaN(r.goal)) {
            r.goal = this.goals.find((g) => g.idx === r.goal);
          }
          return r;
        });

        for (const entity of entities) {
          for (const r of rules) {
            entity.push_rule(tick, { ...r });
          }

          this.entityNextWaypoint(entity);
        }
        break;

      case 'spawn_entity':
        for (const config of action.actionentities) {
          this.spawnEntity(config);
        }
        break;

      case 'spawn_object': {
        const spec = { spawnarea: action.actionarea, size: 0, name: 'hidden object' };
        const pos = this.spawnPos(spec);
        const seq = this.objects.length;
        const object = { ...spec, pos, owned: false, seq };
        this.objects.push(object);

        // TODO
        const area = this.entities.find((e) => e.team === 0 && e.state !== 'dead').spawnarea;
        const search = this.controls(area).search;

        const prompt_options = [
          { title: 'skip', default: !search, actions: [] },
          {
            title: 'investigate',
            default: search,
            actions: [
              {
                action: 'push_rule',
                actiontarget: { ty: 'entity', team: 0 },
                actionrules: [{ ty: 'interact', object: seq }],
              }
            ],
          },
        ];

        this.pending_prompts.push({
          area: area,
          expire_at: this.ticksFromSec(4) + this.tick,
          queue_at: this.tick,
          prompt_options,
        });
      }
        break;


      case 'push_prompt':
        const prompts = action.actionprompts;
        const { prompt_duration, prompt_options } = prompts;

        this.pending_prompts.push({
          // TODO
          area: 0,
          expire_at: this.ticksFromSec(prompt_duration) + this.tick,
          queue_at: this.tick,
          prompt_options,
        });
        break;

      case 'open_door':
        const { actiondoorpolicy } = action;
        this.door_policy.push(actiondoorpolicy);
        break;

      case 'rescue': {
        const { rescue_target } = action;
        // 아무나 찍어서
        const entity = this.entities.find((e) => e.team === 0 && e.state !== 'dead');

        // 이미 다른 entity가 구출중임
        if (entity) {
          entity.push_rule(tick, { ty: 'rescue', rescue_target });
        }
        break;
      }

      // TODO: ingameaction: 일시정지 후 선택 데모
      case 'firstaid': {
        const { area } = action;
        const entities = this.entities.filter((e) => e.spawnarea === area && e.state !== 'dead');
        let uncover_incr = 0;
        for (const entity of entities) {
          entity.life = Math.min(entity.life + opts.PS_FIRSTAID_HEAL, entity.life_max);
          uncover_incr += opts.PS_FIRSTAID_UNCOVER_INCR;
        }
        const { playerstats } = this;
        playerstats.uncover = Math.min(playerstats.uncover + uncover_incr, 100);
        break;
      }

      case 'reorg': {
        const controls = this.controls(action.area);
        controls.reorg = true;
        controls.reorgTimer = new TickTimer(tick, this.ticksFromSec(opts.AREA_GOVERNER_COVER_REORG_DURATION));
        break;
      }

      case 'withdraw': {
        this.withdraw = true;
        break;
      }

      case 'withdraw_rule':
        this.popGroupRule(action.entity);
        break;

      default:
        throw new Error(`unknown action: ${action.action} `);
    }
  }

  get prompts() {
    return this.pending_prompts;
  }

  get actions() {
    return this.pending_actions;
  }

  controlSelectPrompt(prompts, i) {
    this.pending_prompts = this.pending_prompts.filter((p) => p !== prompts);

    const selected = prompts.prompt_options[i];
    for (const action of selected.actions) {
      this.triggerAction(action);
    }
  }

  controlRule(spawnarea, ty) {
    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }
      entity.push_rule({ ty, transient: true });
    }
  }

  controls(spawnarea) {
    const { tick } = this;
    if (this.controls_list[spawnarea] === undefined) {
      this.controls_list[spawnarea] = controlsNew(tick);
    }
    return this.controls_list[spawnarea];
  }

  controlsUpdate() {
    if (!this.segment) {
      return;
    }

    const idx = this.segment.idx;
    for (let i = 0; i < this.segment_controls_list.length; i++) {
      const { controls, entity_controls } = this.segment_controls_list[i];
      if (idx < controls.length) {
        this.controlsSet(i, controls[idx]);
      }

      for (const ec of entity_controls) {
        if (idx < ec.controls.length) {
          this.entityControlsSet(ec.idx, ec.controls[idx]);
        }
      }
    }
  }

  controlsFeatures() {
    const { entities } = this;

    const allies = entities.filter((e) => e.team === 0 && e.state !== 'dead');

    const heal = this.heals.length > 0 || entities.find((e) => (e.heals && e.heals.length > 0)) !== undefined;
    const throwable = this.throwables.length > 0 || entities.find((e) => (e.throwables && e.throwables.length > 0)) !== undefined;

    // 전술 지능 관련 추가 필요
    const riskdir = allies.find((e) => typeof e.perk2_common_intelligence_piecut === 'object') !== undefined;
    const door_entry = allies.find((e) => typeof e.perk2_common_intelligence_dynamic_entry === 'object') !== undefined;
    const throwable_cooking = allies.find((e) => typeof e.perk2_common_intelligence_grenade_cooking === 'object') !== undefined;

    return {
      heal,
      throwable,

      riskdir,
      door_entry,
      throwable_cooking,
    };
  }

  getEntityControlsFeature(entity) {
    const heal = (entity.heals !== undefined) && entity.heals.length > 0;
    const throwable = (entity.throwables !== undefined) && entity.throwables.length > 0;
    const attachable = (entity.attachables !== undefined) && entity.attachables.length > 0;

    return {
      heal,
      throwable,
      attachable,
    };
  }

  controlsGet(spawnarea) {
    const orig = this.controls(spawnarea);
    const controls = {};
    for (const key of CONTROLS_DEFAULT_KEYS) {
      controls[key] = orig[key];
    }
    return controls;
  }

  controlsSet(spawnarea, opts) {
    if (!opts) {
      return;
    }
    for (const [key, value] of Object.entries(opts)) {
      this.controlSet(spawnarea, key, value);
    }

    this.onControlsChanged(spawnarea);
  }

  entityControlsSet(entity_idx, controls) {
    if (!controls) {
      return;
    }
    const entity = this.entities.find((e) => e.idx === entity_idx);
    if (!entity) {
      return;
    }
    entity.controls = controls;
  }

  controlSet(spawnarea, key, value) {
    const controls = this.controls(spawnarea);
    controls[key] = value;

    /*
    if (key === 'cover') {
      if (value) {
        this.controlSetCoverPolicy(spawnarea, 'cover');
      } else {
        this.controlSetCoverPolicy(spawnarea, 'cover-fire');
      }
    }
    */
    // mobility level
    if (key === 'mobility') {
      this.controlMobilityLevel(spawnarea, 0 | value);
    } else if (key === 'riskdir') {
      this.controlRiskdir(spawnarea, value);
    } else if (key === 'win') {
      this.onDebugWin();
    } else if (key === 'withdraw') {
      this.withdraw = 1;
    } else if (key === 'door_entry') {
      this.controlDoorEntry(spawnarea, value);
    } else if (key === 'firepolicy') {
      // ['default', 'focus', 'aggressive', 'pair', 'control']
      // 영향을 주는 파라미터: fireleader, allow_coordinated_fire
      // 초기화합니다.
      const entities = this.entities.filter((e) => e.spawnarea === spawnarea);

      for (const entity of entities) {
        delete entity.fireleader;
        delete entity.perk2_test_fire_all;
        entity.allow_coordinated_fire = false;
      }

      if (value === 'focus') {
        const leader = entities[0];
        for (const entity of entities) {
          if (entity === leader) {
            continue;
          }
          entity.fireleader = leader;
        }
      } else if (value === 'aggressive') {
        for (const entity of entities) {
          entity.perk2_test_fire_all = perk2Sets.perk2_test_fire_all;
        }
      } else if (value === 'pair') {
        const pairs = Math.floor(entities.length / 2);
        for (let i = 0; i < pairs; i++) {
          const leader = entities[i];
          const follower = entities[pairs + i];
          leader.allow_coordinated_fire = true;
          follower.fireleader = leader;
        }
      } else if (value === 'control') {
        for (const entity of entities) {
          entity.allow_coordinated_fire = true;
        }
      }
    }
  }

  controlGet(spawnarea, key) {
    const controls = this.controls(spawnarea);
    return controls[key];
  }

  controlSetCoverPolicy(spawnarea, value) {
    const { tick } = this;

    const rule_prev = this.control_cover_policy
    if (rule_prev === value) {
      return;
    }

    this.control_cover_policy = value;

    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }

      const rule_next = this.entityCoverPoilcy(entity);

      if (!entity.alter_rule(rule_next, rule_prev)) {
        entity.push_rule(tick, { ty: rule_next, transient: true });
        entity.lastRouteTick = new TickTimer(this.tick, 0);
        this.entityNextWaypoint(entity);
      }
    }
  }

  controlGather(spawnarea) {
    const { tick } = this;

    const entities = this.entities.filter((e) => e.spawnarea === spawnarea);
    const leader = entities[0];
    for (const entity of entities) {
      if (entity.waypoint_rule !== 'gather') {
        entity.push_rule(tick, { ty: 'gather', leader, transient: true });
      }
    }
    this.controlSet(spawnarea, 'gather', true);
  }

  controlClear(spawnarea) {
    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }

      // remove all transient rules
      while (entity.waypoint_rule.transient) {
        entity.pop_rule();
      }
    }
    this.controlSet(spawnarea, 'cover', false);
  }

  controlMobilityLevel(spawnarea, level) {
    for (const entity of this.entities) {
      if (entity.spawnarea === spawnarea) {
        this.entitySetMobilityLevel(entity, level);
      }
    }
  }

  controlRiskdir(spawnarea, value) {
    for (const entity of this.entities) {
      if (entity.spawnarea === spawnarea) {
        entity.use_riskdir = value;
      }
    }
  }

  controlDoorEntry(spawnarea, value) {
    for (const entity of this.entities) {
      if (entity.spawnarea === spawnarea) {
        this.entitySetDoorEntry(entity, value);
      }
    }
  }

  controlReorgWait(spawnarea) {
    const controls = this.controls(spawnarea);
    if (!controls.reorg) {
      return false;
    }
    return _.every(this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === spawnarea), (e) => e.movespeed === 0);
  }

  onTick() {
    const idx = this.tick % opts.PERF_BUF_SIZE;
    const start = Date.now();
    const res = this.onTick0();
    const dt = Date.now() - start;

    this.perfbuf[idx] = { start, dt };
    return res;
  }

  simover() {
    const { world, goals, entities, win, withdraw } = this;

    if (win) {
      return 0;
    }
    if (withdraw) {
      return 1;
    }


    // 패배 조건: 사기, 발각
    /*
    if (this.playerstats.morale <= 0 || this.playerstats.uncover >= 100) {
      return 1;
    }
    */

    // simover check: occupy
    const t0win = goals.filter((e) => e.goalstate.owner !== 0).length === 0;
    if (goals.length > 0 && world.simover_rule === 'goal' && t0win) {
      return 0;
    }

    // simover check: eliminated
    const t0dead = entities.filter((e) => e.team === 0 && e.state !== 'dead').length === 0;
    const t1dead = entities.filter((e) => e.spawnarea >= 0 && e.team === 1 && e.state !== 'dead').length === 0;

    if (t0dead) {
      return 1;
    }

    if (world.simover_rule === 'eliminate' && t1dead) {
      return 0;
    }

    if (world.simover_rule === 'mission') {
      if (t0win && t1dead) {
        return 0;
      }
      if (t0dead) {
        return 1;
      }
    }
    return -1;
  }

  onTickUpdateVis() {
    const { tick, entities } = this;

    const interval = this.ticksFromSec(opts.VIS_DECAY_INTERVAL);
    const decay = opts.VIS_DECAY_PER_SEC * opts.VIS_DECAY_INTERVAL;

    for (const entity of entities) {
      let grid = entity.grid_vis;
      let tick_decayed = grid.tick_decayed ?? 0;

      if (tick - tick_decayed < interval) {
        continue;
      }

      // decay
      grid.tick_decayed = tick;
      for (let i = 0; i < grid.length; i++) {
        grid[i] = Math.max(0, grid[i] - decay);
      }
    }
  }

  onTickUpdateVIP() {
    const { tick, entities } = this;

    const vips = entities.filter((e) => e.ty === 'vip');
    for (const vip of vips) {
      if (vip.waypoint_rule.ty === 'idle') {
        const nearby = entities.find((e) => e.state !== 'dead'
          && e.team === 0
          && e.pos.dist(vip.pos) < opts.RESCUE_RADIUS
          && !obstructed(vip.pos, e.pos, this.obstacles));

        if (nearby) {
          vip.team = nearby.team;
          vip.push_rule(tick, { ty: 'follow', follow_target: nearby, follow_dist: 30 });

          this.pending_prompts = this.pending_prompts.filter((p) => p.rescue_target !== vip);
        }
      }
    }
  }

  onTickUpdatePrompts() {
    const { tick } = this;

    this.pending_prompts.sort((a, b) => a.expire_at - b.expire_at);

    if (this.pending_prompts.find((p) => p.pause)) {
      return true;
    }

    while (this.pending_prompts.length > 0 && this.pending_prompts[0].expire_at <= tick) {
      const prompts = this.pending_prompts[0];
      const { prompt_options } = prompts;

      const def = prompt_options.find((p) => p.default);
      this.controlSelectPrompt(prompts, prompt_options.indexOf(def));
    }
    return false;
  }

  onTriggerDialogs(condition) {
    const { dialog_triggers, tick } = this;

    const triggers = dialog_triggers.filter((e) => e.condition === condition);
    if (!triggers) {
      return;
    }

    for (const trigger of triggers) {
      const { action } = trigger;
      if (action === 'dialog') {
        const { actiondialogs } = trigger;
        const duration = this.tps * 3;

        let delay = 0;
        for (const dialog of actiondialogs) {
          this.dialogs.push({
            timer: new TickTimer(tick + delay, duration),
            name: dialog.name,
            text: dialog.text,
          });
          delay += duration;
        }
      }
    }
  }

  onTriggerBottomDialog(condition) {
    const { dialog_triggers, tick } = this;

    const triggers = dialog_triggers.filter((e) => e.condition === condition);
    if (!triggers) {
      return;
    }

    for (const trigger of triggers) {
      const { action } = trigger;
      if (action === 'bottom-dialog') {
        const { actiondialogs } = trigger;

        this.pending_prompts.push({
          pause: true,
          prompt_options: [
            {
              title: "proceed",
              actions: []
            }
          ],
          expire_at: 0,
          dialog: actiondialogs,
        });
      }
    }
  }

  onTriggerConversation(trigger, args = {}) {
    const convs = this.conversation_triggers[trigger];
    if (!convs) {
      return;
    }
    let participants = args.participants ?? [];
    if (participants.length === 0) {
      participants = this.entities.filter((e) => e.state !== 'dead' && e.team === 0);
    }
    const condition_rule = args.condition_rule ?? [];

    const idx = Math.floor(Math.random() * convs.length);
    const conv = [...convs[idx]];
    const len = conv.length;
    for (let i = 0; i < len; i++) conv[i].idx = i;

    const { conversations, tick } = this;
    conversations.push({
      start_at: tick,
      actor: args.actor,
      participants,
      condition_rule,
      conversation: conv,
      valid: true,
    });
  }

  // 스쿼드가 탐색하고 있을 때 호출됩니다.
  onTriggerSquadExplore() {
    const { entities } = this;
    const ally = entities.filter((e) => e.team === 0 && e.state !== 'dead');
    // 평상 시 대화
    {
      this.onTriggerConversation('squad-explore', {
        participants: ally,
        condition_rule: ['explore', 'follow'],
      });
    }
    // 다이나믹 엔트리 퍽
    {
      const entity = ally.find((e) => e.perk2_common_intelligence_piecut);
      if (entity) {
        this.onTriggerConversation('entity-explore-perk2_common_intelligence_piecut', {
          actor: entity,
        });
      }
    }
  }

  // 아군 오퍼레이터가 사망할 때 호출됩니다.
  onTriggerAllyDead() {
    this.onTriggerConversation('entity-ally-dead');
  }

  // ----- Entity Trigger ------

  // 오퍼레이터가 다이나믹 엔트리 상태에서 문을 열 때 호출됩니다.
  onTriggerEntityDynamicEntry_OpenDoor(entity) {
    this.onTriggerConversation('entity-door-perk2_common_intelligence_dynamic_entry', {
      actor: entity,
    })
  }

  // 오퍼레이터가 사격할 때 호출됩니다.
  onTriggerEntityHandleFire(entity) {

  }

  // 오퍼레이터가 투척물을 투척할 때 호출됩니다.
  onTriggerEntityThrow(entity, attach) {
    this.onTriggerConversation(attach ? 'entity-throw-attachable' : 'entity-throw-throwable', {
      actor: entity,
    });
  }

  // 오퍼레이터가 데미지를 받을 때 호출됩니다.
  onTriggerEntityDamaged(entity) {
    const { rng } = this;
    if (rng.range(0, 1) < 0.5) {
      this.onTriggerConversation('entity-damaged', {
        actor: entity,
      });
    }
  }

  // 오퍼레이터가 다른 오퍼레이터를 사살할 때 호출됩니다.
  onTriggerEntityKill(entity, crit) {
    this.onTriggerConversation(crit ? 'entity-kill-crit' : 'entity-kill', {
      actor: entity,
    });
  }

  // 오퍼레이터가 재장전할 때 호출됩니다.
  onTriggerEntityReload(entity) {
    this.onTriggerConversation('entity-reload', {
      actor: entity,
    });
  }

  // 오퍼레이터가 치료를 받을 때 호출됩니다.
  onTriggerEntityHealed(entity) {
    this.onTriggerConversation('entity-healed', {
      actor: entity,
    });
  }

  // 오퍼레이터가 적을 발견할 때 호출됩니다.
  onTriggerEntityAwareEnemy(entity) {
    this.onTriggerConversation('entity-aware-enemy', {
      actor: entity,
    });
  }

  // 오퍼레이터가 아군이 피해를 받는 상황을 볼 때 호출됩니다.
  onTriggerEntityAwareAllyDamaged(entity) {
    this.onTriggerConversation('entity-aware-ally-damaged', {
      actor: entity,
    });
  }

  // 오퍼레이터 특정 상태가 되면 호출됩니다.
  onTriggerEntityStateChanged(entity, state) {
    this.onTriggerConversation(`entity-state-${state}`, {
      actor: entity,
    });
  }

  // 오퍼레이터가 투척물을 발견했을 때 호출됩니다.
  onTriggerEntityAwareThrowable(entity) {
    this.onTriggerConversation('entity-aware-throwable', {
      actor: entity,
    });
  }

  // 미션을 클리어했을 때 호출됩니다.
  onTriggerMissionCompleted(entity) {
    this.onTriggerConversation('entity-mission-completed', {
      actor: entity,
    });
  }

  // 시뮬레이션이 시작할 때 호출됩니다.
  onTriggerStart() {
    this.onTriggerBottomDialog('start');
    this.onTriggerDialogs(`start`);
  }

  // 스쿼드가 전투 시작했을 때 호출됩니다.
  onTriggerSquadEngageStart(area) {
    const controls = this.controls(area);

    // 제압 사격
    if (controls.firepolicy === 'supress') {
      this.onTriggerConversation('entity-fire-supress');
    }

    // 
    if (controls.firepolicy === 'aggressive') {
      this.onTriggerConversation('entity-fire-aggressive');
    }

    // 
    if (controls.firepolicy === 'control') {
      this.onTriggerConversation('entity-fire-control');
    }

    // 
    if (controls.firepolicy === 'pair') {
      this.onTriggerConversation('entity-fire-pair');
    }
  }

  // 스쿼드가 전투 종료했을 때 호출됩니다.
  onTriggerSquadEngageEnd(area) {

  }

  // ----- Segment Trigger ------

  // 특정 세그먼트가 시작할 때 호출됩니다.
  onTriggerSegmentStart(segment_idx) {
    this.onTriggerBottomDialog(`segment-start-${segment_idx}`);
    this.onTriggerDialogs(`segment-start-${segment_idx}`);
  }

  // 특정 세그먼트가 종료될 때 호출됩니다.
  onTriggerSegmentClear(segment_idx) {
    this.onTriggerBottomDialog(`segment-clear-${segment_idx}`);
    this.onTriggerDialogs(`segment-clear-${segment_idx}`);
  }

  // ----- Checkpoint Trigger ------

  // 특정 체크 포인트를 점령 시작할 때 호출됩니다.
  onTriggerGoalStart(goal_idx) {
    this.onTriggerBottomDialog(`checkpoint-start-${goal_idx}`);
    this.onTriggerDialogs(`checkpoint-start-${goal_idx}`);
  }

  // 특정 체크 포인트를 점령 완료할 때 호출됩니다.
  onTriggerGoalClear(goal_idx) {
    this.onTriggerBottomDialog(`checkpoint-clear-${goal_idx}`);
    this.onTriggerDialogs(`checkpoint-clear-${goal_idx}`);
  }

  onTickUpdateTriggers() {
    const { tick, entities, trails, blastareas } = this;

    const { pending_actions } = this;
    // update triggers
    for (const area of this.spawnareas) {
      area.areastate.triggers = area.areastate.triggers.filter((t) => {
        const execute_at = tick + (t.actiondelay ?? 0);
        switch (t.condition) {
          case 'enter':
            if (entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team)) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          case 'enter1': {
            // 구역에 진입했으면서, 교전 중이 아닌 경우
            const found = entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team);
            if (found && this.controls(found.spawnarea).state !== 'engage') {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;
          }

          case 'leave':
            const found = entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team);
            // TODO: 멀쩡하게 만들기
            if (!t._entered && found) {
              t._entered = true;
            }
            if (t._entered && !found) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }

            return true;

          case 'fire':
            // TODO: make it efficient
            if (trails.find((t) => geomContains(t.pos, area.polygon))) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          case 'blast':
            if (blastareas.find((e) => geomContains(e.pos, area.polygon))) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          default:
            throw new Error("unknown trigger condition: " + t.condition);
        }
      });
    }

    pending_actions.sort((a, b) => a.execute_at - b.execute_at);

    while (pending_actions.length > 0 && pending_actions[0].execute_at <= tick) {
      const { trigger } = pending_actions.shift();
      this.triggerAction(trigger);
    }
  }

  onTickUpdateGoals() {
    const { tick, goals, entities } = this;

    for (let i = 0; i < goals.length; i++) {
      const goal = goals[i];
      if (goal.waypoint) {
        continue;
      }

      const state = goal.goalstate;
      const candidates = entities.filter((e) => e.state !== 'dead' && goal.pos.dist(e.pos) < opts.GOAL_RADIUS);
      state.count_team0 = candidates.filter((e) => e.team === 0).length;
      state.count_team1 = candidates.filter((e) => e.team === 1).length;

      if (state.owner >= 0) {
        continue;
      }

      let occupying_team = -1;
      if (state.count_team0 > 0) {
        occupying_team = 0;
      }

      if (occupying_team !== state.occupying_team) {
        state.occupying_team = occupying_team;
        if (occupying_team >= 0) {
          state.occupy_tick = tick;
          this.onTriggerGoalStart(i);
        }
      }

      const remain_tick = this.ticksFromSec(goal.occupy_dur ?? opts.GOAL_OCCUPY_DURATION);
      if (occupying_team >= 0 && tick - state.occupy_tick > remain_tick) {
        state.owner = state.occupying_team;
        this.onTriggerGoalClear(i);
      }
    }
  }

  onControlsChanged(spawnarea) {
    if (spawnarea > 0) {
      return;
    }

    const controls = this.controls(spawnarea);
  }

  onTickSegments() {
    const { segments, entities } = this;
    if (!segments) {
      return;
    }
    const allies = entities.filter((e) => e.team === 0);
    for (const ally of allies) {
      //성준 : 구조된 용병은 report 멤버가 존재하지 않습니다. 아래와 같이 수정하면 컴파일에러가 발생하지는 않지만, 용병들이 멈추어 게임이 멈춥니다.
      if (ally.report) {
        ally.report.life = ally.life;
      }
    }

    for (let i = 0; i < segments.length; i++) {
      const { clear, areas } = segments[i];
      if (clear) {
        continue;
      }
      const total = entities.filter((e) => e.team !== 0 && areas?.includes(e.spawnarea)).length;
      const killed = entities.filter((e) => e.team !== 0 && e.state === 'dead' && areas?.includes(e.spawnarea)).length;
      const allies_survived = entities.filter((e) => e.team === 0 && e.state !== 'dead').length;

      if (total === killed) {
        const { tick } = this;
        this.OnSegmentCleared(i, segments.length, killed, allies_survived);
        segments[i].clear = true;
        this.pending_events.push({
          tick,
          ty: 'segment_clear',
          segment: i,
        });
        this.onTriggerSegmentClear(i);

        if (i < segments.length - 1) {
          this.segment = segments[i + 1];
          this.controlsUpdate();
        }

        const allies = entities.filter((e) => e.team === 0);
        const allies_detail = {};
        for (const ally of allies) {
          allies_detail[ally.idx] = JSON.parse(JSON.stringify(ally.report));
        }
        this.segments_report[i].allies_detail = allies_detail;
      }

      const report = this.segments_report[i];
      report.killed = killed;
      report.tick = this.tick;
      report.survived = allies_survived;

      return;
    }
  }

  onDebugWin() {
    const { segments, entities } = this;

    this.win = 1;

    // TODO: 모든 적을 죽입니다.
    for (const entity of entities) {
      if (entity.team === 1) {
        entity.state = 'dead';
        entity.life = 0;
      }
    }

    if (!segments) {
      return;
    }

    const allies = entities.filter((e) => e.team === 0);
    for (const ally of allies) {
      //성준 : 구조된 용병은 report 멤버가 존재하지 않습니다. 아래와 같이 수정하면 컴파일에러가 발생하지는 않지만, 용병들이 멈추어 게임이 멈춥니다.
      if (ally.report) {
        ally.report.life = ally.life;
      }
    }

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];

      const { areas } = segment;
      const total = entities.filter((e) => e.team !== 0 && areas?.includes(e.spawnarea)).length;
      const killed = total;
      const allies_survived = entities.filter((e) => e.team === 0 && e.state !== 'dead').length;

      segment.clear = true;
      const allies = entities.filter((e) => e.team === 0);
      const allies_detail = {};
      for (const ally of allies) {
        allies_detail[ally.idx] = JSON.parse(JSON.stringify(ally.report));
      }

      const report = this.segments_report[i];
      report.allies_detail = allies_detail;
      report.killed = killed;
      report.tick = this.tick;
      report.survived = allies_survived;
    }
  }

  segmentProgress() {
    const { segments, entities } = this;
    if (!segments) {
      return [];
    }

    let progress = [];
    for (let i = 0; i < segments.length; i++) {
      const { areas } = segments[i];
      const total = entities.filter((e) => e.team !== 0 && areas?.includes(e.spawnarea)).length;
      const killed = entities.filter((e) => e.team !== 0 && e.state === 'dead' && areas?.includes(e.spawnarea)).length;
      progress.push({
        total,
        killed,
        progress: killed / total,
      });
    }
    return progress;
  }

  onTickPrompt() {
    // mission rule에 따른 prompt를 추가합니다.
    this.maybePushMissionRulePrompt();
  }

  maybePushMissionRulePrompt() {
    const { entities, tick, pending_prompts } = this;
    const entity = entities.find((e) => e.team === 0 && e.state !== 'dead');
    if (!entity) {
      return;
    }
    const { ty, mission_idx } = entity.waypoint_rule;

    if (!(mission_idx > 0)) {
      return;
    }

    if (pending_prompts.find((p) => p.mission_idx === mission_idx)) {
      return;
    }

    pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(3600) + tick,
      queue_at: tick,
      mission_idx,
      prompt_options: [
        {
          title: `withdraw ${ty} `, default: true, actions: [
            { action: 'withdraw_rule', mission_idx, entity }
          ]
        }
      ],
    });
  }

  convertEntity(entity, team) {
    const { entities } = this;
    const tmpl = entities.find((e) => e.team === team && e.state !== 'dead');

    entity.team = team;
    entity.rules = tmpl.rules.map((r) => ({ ...r }));
  }

  damageIndicationPush(entity, damage) {
    const { tick, damageIndications } = this;
    const rng = (min, max) => Math.random() * (max - min) + min;
    const radius = 2.5;
    const noise = new v2(rng(-radius, radius), rng(-radius, radius));
    damageIndications.push({
      damage,
      start_pos: entity.pos.add(noise),
      entity,
      timer: new TickTimer(tick, this.ticksFromSec(2.0)),
      first: true,
    });
  }

  damageIndicationUpdate() {
    const { tick, damageIndications } = this;
    while (damageIndications.length > 0 && damageIndications[0].timer.expired(tick)) {
      damageIndications.splice(0, 1);
    }
  }

  bubblePush(entity, msg) {
    const { tick, bubbles } = this;
    bubbles.push({
      msg,
      entity,
      timer: new TickTimer(tick, this.ticksFromSec(2.0)),
    });
  }

  bubbleUpdate() {
    const { rng, tick, bubbles, conversations } = this;
    if (bubbles.length > 0 && !bubbles[bubbles.length - 1].timer.expired(tick)) {
      return;
    }

    let sample = true;

    if (conversations.find((c) => c.valid)) {
      sample = false;
    }

    if (rng.next(0, 1) > 0.01) {
      sample = false;
    }

    if (sample) {
      this.onTriggerSquadExplore();
    }

    // handle ongoing conversation
    const len = conversations.length;
    for (let i = len - 1; i >= 0; i--) {
      const conv = conversations[i];

      const { start_at, participants, conversation, valid, condition_rule, actor } = conv;
      if (!valid) {
        continue;
      }

      let par = participants.filter((e) => e.state !== 'dead');
      if (condition_rule.length > 0) {
        par = par.filter((e) => condition_rule.includes(e.waypoint_rule.ty));
      }

      const delay = this.ticksFromSec(2);
      const remain = conversation.filter((c) => start_at + c.idx * delay >= tick);
      if (remain.length === 0) {
        conv.valid = false;
        continue;
      }

      const { idx, name, text } = remain[0];
      let speaker = null;
      if (name === '#actor') {
        if (!actor || actor.state === 'dead') {
          conv.valid = false;
          continue;
        }
        speaker = actor;
      } else if (name.startsWith('#dynamic-sq-')) {
        const entity_idx = parseInt(name.split('#dynamic-sq-')[1]);
        if (entity_idx >= par.length) {
          conv.valid = false;
          continue;
        }
        speaker = participants[entity_idx];
      }

      if (start_at + delay * idx === tick) {
        this.bubblePush(speaker, text);
      }
    }
  }

  paused() {
    if (this.onTickUpdatePrompts()) {
      return true;
    }
    return false;
  }

  onTickProjectileScheduler() {
    const { tick, projectile_scheduler_queue, fire_scheduler_queue } = this;
    projectile_scheduler_queue.sort((a, b) => a.expires_at - b.expires_at);

    while (projectile_scheduler_queue.length > 0) {
      const { expires_at, trail, fire_scheduler_data } = projectile_scheduler_queue[0];
      if (tick < expires_at) {
        break;
      }
      projectile_scheduler_queue.splice(0, 1);
      trail.tick = tick;

      this.trails.push(trail);
      if (fire_scheduler_data) {
        fire_scheduler_queue.push(fire_scheduler_data);
      }
    }
  }

  onTickFireScheduler() {
    const { tick, rng, playerstats, fire_scheduler_queue } = this;

    fire_scheduler_queue.sort((a, b) => a.expires_at - b.expires_at);

    while (fire_scheduler_queue.length > 0) {
      let {
        ty,
        expires_at,
        entity,
        target,
        hit,
        prob,
        kill,
        damage,
        penetrate,
        crit,
        trail,
        throwable,
      } = fire_scheduler_queue[0];
      if (tick < expires_at) {
        break;
      }
      fire_scheduler_queue.splice(0, 1);

      //만약 이벤트를 처리할 시점에 target이 죽었다면 projectile에 대한 처리를 무시.
      if (entity.state === 'dead' || target.life <= 0) {
        continue;
      }

      let options = [];
      // 헤드 샷 퍽
      if (ty === 'firearm' && entity.perk2_sharpshooter_headshot && crit) {
        options = ['head-shot'];
      }
      if (ty === 'throwable') {
        options = ['with-damage', 'penetrate-loss-damage'];
      }

      // TODO:
      const res = this.entityOnDamage(entity, target, damage, penetrate, ty, options);
      let damage_life = 0;
      let stop = false;
      // TODO: stop 여전히 필요하면 새로 구현해야 합니다.
      ({ hit, kill, damage, penetrate, damage_life, stop } = res);

      switch (ty) {
        case 'firearm':
          this.journal.push({ tick, ty: 'fire', entity, target, hit, prob, kill });
          break;
        case 'throwable':
          break;
      }

      if (entity.perk_kill_recover && kill) {
        const life_next = Math.min(entity.life + 11, entity.life_max);
        if (life_next !== entity.life) {
          entity.life = life_next;
          this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_kill_recover', });
        }
      }

      {
        // playerstats
        let { morale } = playerstats;

        const team_entities = this.entities.filter((e) => e.team === 0);

        // 피격시
        if (hit) {
          if (entity.team === 0) {
            // 플레이어가 피격한 경우
          } else {
            // 플레이어가 피격당한 경우
            morale -= (damage * opts.PS_MORALE_DAMAGE_MULT) / team_entities.length;
          }
        }

        if (crit) {
          morale += opts.PS_MORALE_CRIT;
        } else if (kill) {
          if (entity.team === 0) {
            // 플레이어가 사살한 경우
            morale += 2; //(target._stat?._stat ?? 5) * opts.PS_MORALE_MULT_KILL;
          } else {
            // 플레이어가 사살당한 경우

            const team_overall = team_entities.reduce((acc, e) => acc + (e._stat?._stat ?? 5), 0);
            const team_level = team_entities.reduce((acc, e) => acc + (e.level ?? 1), 0);
            const team_mentality = (team_overall + team_level * 2) / team_entities.length;

            let value = ((((entity._stat?._stat ?? 5) + (entity.level ?? 1) * 2) / team_mentality) * opts.PS_MORALE_FALLEN_COEF) / Math.sqrt(team_entities.length);
            morale -= value;
          }
        }

        playerstats.morale = clamp(morale, 0, 100);
        entity.unaimTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_DURATION));
      }

      {
        // trail 업데이트.
        [trail.hit, trail.kill, trail.damage, trail.penetrate, trail.damage_life] = [hit, kill, damage, penetrate, damage_life,];

        if (kill) {
          if (ty === 'throwable') {
            this.journal.push({ tick, ty: 'throw_kill', entity, throwable, target });
          }
          this.pending_events.push({ tick, ty: 'dead', trail, });
        }

        if (!kill && hit) {
          this.onTriggerEntityDamaged(target);
        }

        if (kill) {
          this.onTriggerEntityKill(entity, crit);
        }
      }
    }
  }

  onTickFire() {
    this.onTickProjectileScheduler();
    this.onTickFireScheduler();
  }

  segmentEnemies(segment) {
    if (!segment || !segment.areas) {
      return null;
    }

    return this.entities.filter((e) => e.state !== 'dead' && segment.areas.includes(e.spawnarea));
  }

  onTick0() {
    const { entities, throwables, bubbles } = this;

    if (this.tick === 1 && !this.start) {
      this.onTriggerStart();
      this.start = true;
    }

    // simover check
    let res = this.simover();
    if (res >= 0) {
      // TODO: 멀쩡하게 만들어야 함
      const bubble_last = bubbles[bubbles.length - 1];
      if (bubble_last?.tick !== this.tick) {
        const entity = entities.find((e) => e.team === 0 && e.state !== 'dead');
        if (entity) {
          this.onTriggerMissionCompleted(entity);
        }
      }
      return res;
    }

    if (this.paused()) {
      return -1;
    }

    if (this.tick === 0) {
      this.onStart();
    }

    // lazy apply fire
    this.onTickFire();

    // decay grid_vis
    this.onTickUpdateVis();

    // update entities
    const allies = entities.filter((e) => e.team === 0);
    for (let i = 0; i < allies.length; i++) {
      allies[i].squad_idx = i;
    }
    for (const entity of entities) {
      this.entityUpdate(entity);
    }
    // update teams
    this.onTickAreas();

    for (const entity of entities) {
      this.entityUpdateIcon(entity);
    }
    this.bubbleUpdate();
    this.damageIndicationUpdate();

    // update objects
    for (const t of throwables) {
      this.throwableUpdate(t);
    }

    // handle mission rules
    this.onTickPrompt();

    // update vips
    this.onTickUpdateVIP();

    // update triggers
    this.onTickUpdateTriggers();

    // update goals
    this.onTickUpdateGoals();

    this.onTickSegments();

    this.tick += 1;

    return -1;
  }
}
