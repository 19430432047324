import './App.css';
import React from 'react';

import { presets } from './presets_mission';
import { presets as presets_testbed } from './presets_testbed';
import { Rng } from './rand';
import { SimView } from './SimView';

import { PresetSelector } from './App';
import { TestbedSelector } from './Testbed';

export class UETest extends React.Component {
  constructor(props) {
    super(props);

    const seed = Rng.randomseed();

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.restartTimer = null;

    this.onExport = this.onExport.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onSeed = this.onSeed.bind(this);
    this.onFinish = this.onFinish.bind(this);

    this.state = {
      presets,
      simstate: null,

      testbed: false,

      seed,
      started: false,

      invis: false,
      mute_bgm: false,
      invincible: false,
    };
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport() {
    const state = {
      seed: this.simRef.current.state.seed,
      simstate: { ...this.state.simstate },
    }

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport() {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({
      seed: state.seed,
      simstate: state.simstate,
    }, () => {
      // this.simRef.current.restart();
    });
  }

  onSeed(seed) {
    const { config } = this.state;
    this.setState({
      seed,
      simstate: config.preset(seed),
    }, () => {
      // this.simRef.current.restart();
    });
  }

  /*
  onPreset(p, invis, invincible) {
    const { presets, seed } = this.state;
    const simstate = presets[p](seed);

    if (p === 'ext') {
      // pull objects from ue
      let spec = SAMPLE;
      if (window.ueobs) {
        spec = JSON.parse(window.ueobs[0]);
      }

      simstate.obstacle_specs = [];
      extobj.convert_world(spec, simstate);
    }

    this.onSimState(simstate, invis, invincible);
  }
  */

  onConfig(config, invis, invincible) {
    const { seed } = this.state;
    const simstate = config.preset(seed);
    if (invis) {
      for (const o of simstate.obstacle_specs) {
        o.imported = true;
      }
    }

    if (invincible) {
      simstate.entities = simstate.entities.map((e) => {
        if (e.team == 0) {
          e.life = 10000;
        }
        return e;
      })
    }

    this.setState({
      config,
      simstate,
      invis,
      invincible,
    });
  }

  onStart() {
    this.setState({ started: true });
  }

  onFinish(res) {
    const view = this.simRef.current;
    view?.stopTimer();

    this.setState({ started: false });
  }

  render() {
    const { invis, mute_bgm, config, simstate, testbed, seed, started, invincible } = this.state;

    if (started && simstate) {
      return <SimView ref={this.simRef}
        m={this.props.m}
        seed={seed}
        debug={false}
        onFinish={(res) => this.onFinish(res)}
        autoscroll={true}
        embed={true}
        mute_bgm={mute_bgm}
        viewWidth={360}
        viewHeight={360}
        simstate={simstate}
      />;
    }

    return <>
      <p>shortcuts: (r) restart / (R) restart with same map / (z) zoom / (Tab) change speed / ( ) pause/resume / (s) single-step</p>
      <PresetSelector presets={presets} disabled={testbed}
        onPreset={(preset, cb) => {
          const config = {
            preset: presets[preset],
          };
          this.setState({ testbed: false });
          this.onConfig(config, invis, invincible);
          cb?.();
        }} />
      <TestbedSelector presets={presets_testbed} disabled={!testbed}
        onUpdate={(config) => {
          this.setState({ testbed: true });
          this.onConfig(config, invis, invincible);
        }} />
      <div>
        save&load
        <button onClick={this.onExport}>export</button>
        <button onClick={this.onImport}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <button onClick={() => { this.onSeed(seed) }}>reload with seed:</button>
      <input type="text" placeholder="seed" value={seed} onChange={(e) => this.setState({ seed: parseInt(e.target.value, 10) || 0 })}></input>
      <button onClick={() => { this.onSeed(Rng.randomseed()) }}> randomize </button>
      <button onClick={() => { this.onSeed(seed + 1) }}>&nbsp;+&nbsp;</button>
      <button onClick={() => { this.onSeed(seed - 1) }}>&nbsp;-&nbsp;</button>

      <br />
      <input type="checkbox" checked={invis} onChange={(e) => {
        this.onConfig(config, e.target.checked, invincible);
      }}></input>invis?
      <input type="checkbox" checked={mute_bgm} onChange={(e) => {
        this.setState({ mute_bgm: e.target.checked });
      }}></input>mute?
      <input type="checkbox" checked={invincible} onChange={(e) => {
        this.onConfig(config, invis, e.target.checked);
      }}></input>invincible?
      |
      <button onClick={() => { this.onStart() }}>start</button>
    </>;
  }
}
