import React from 'react';
import { AgentCard } from './AgentCard';
import {
  tmpl_firearm_ar_t5,
  tmpl_firearm_sg_t5,
  tmpl_firearm_smg_t5,
  tmpl_firearm_dmr_t5,
} from '../presets_firearm';
import { gears_vest_bulletproof } from '../presets_gear';
import { agents } from '../agents';
import ResourceContext from './ResourceContext';
import update from 'react-addons-update';
import { STAT_DESCR, FIREARM_CATS } from '../stat';
import { AgentDetailDialog } from './AgentDetailDialog';

export class HireView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      characters: props.initialState?.characters ?? this.initialCharacters(),
      resource: props.initialState?.resource ?? props.resource ?? 8000,
      order: 'asc',
      orderBy: 'nothing',
      select: "aver"
    };
  }

  reset() {
    this.setState({
      characters: this.initialCharacters(),
      resource: this.props.resource ?? 8000,
    });
  }

  initialCharacters() {
    return agents.map((stats, i) => {
      const scoreBestFirearm = stats.stat_firearm_level.reduce((a, b) => Math.max(a, b), 0);
      const typeBestFirearmIndex = stats.stat_firearm_level.indexOf(scoreBestFirearm);
      const typeBestFirearm = ['저격소총', '지정사수소총', '돌격소총', '기관단총', '샷건'][typeBestFirearmIndex];

      const weapon = [
        tmpl_firearm_dmr_t5,
        tmpl_firearm_ar_t5,
        tmpl_firearm_smg_t5,
        tmpl_firearm_sg_t5,
      ][typeBestFirearmIndex];
      let score = 0;
      let scoreCount = 0;
      for (const key of Object.keys(stats)) {
        if (!key.startsWith('stat_aim')) {
          continue;
        }
        score += stats[key];
        scoreCount += 1;
      }

      return {
        character: {
          name: stats.name,
          descr: stats.descr,
          cost: stats.cost,
          score: Math.round(score / scoreCount),
          typeBestFirearm,
          scoreBestFirearm: Math.round(scoreBestFirearm),
          stats,
        },
        no: i,
        hired: false,
        weapon,
        equipment: gears_vest_bulletproof[0],
        perks: [],
        open: false,
        extraCost: 0,
      };
    });
  }

  onHire(ch) {
    let { characters, resource } = this.state;
    if (ch.character.cost > resource) {
      return;
    }
    const idx = characters.indexOf(ch);
    this.setState({
      resource: resource - ch.character.cost,
      characters:
        update(characters, {
          [idx]: {
            hired: { $set: true },
          }
        }),
    });
  }

  onFire(ch) {
    let { characters, resource } = this.state;
    const scoreBestFirearm = ch.character.stats.stat_firearm_level.reduce((a, b) => Math.max(a, b), 0);
    const typeBestFirearmIndex = ch.character.stats.stat_firearm_level.indexOf(scoreBestFirearm);
    const weapon = [
      tmpl_firearm_dmr_t5, tmpl_firearm_dmr_t5, tmpl_firearm_ar_t5, tmpl_firearm_smg_t5, tmpl_firearm_sg_t5
    ][typeBestFirearmIndex];

    const idx = characters.indexOf(ch);

    this.setState({
      resource: resource + ch.extraCost + ch.character.cost,
      characters:
        update(characters, {
          [idx]: {
            hired: { $set: false },
            weapon: { $set: weapon },
            equipment: { $set: gears_vest_bulletproof[0] },
            perks: { $set: [] },
            extraCost: { $set: 0 },
          }
        }),
    })
  }

  // TODO: perk을 추가할 수 있는지도 아래에서 알 수 있어야 함?
  onPerkSelect(ch, perk) {
    // TODO: validate schedule
    // TODO: validate resource
    const { resource, characters } = this.state;

    let used_days = 0;
    for (let i = 0; i < ch.perks.length; i++) {
      used_days = ch.perks[i].term;
    }

    if (used_days + perk.term > 28) {
      return false;
    }

    let newResource = resource;
    if (newResource < perk.cost) {
      return false;
    }

    const idx = characters.indexOf(ch);

    this.setState({
      characters:
        update(characters, {
          [idx]: {
            perks: { $push: [perk] },
            extraCost: { $set: ch.extraCost + perk.cost },
          }
        }),
      resource: resource - perk.cost,
    });
  }

  onPerkUnselect(ch, perk) {
    const { resource, characters } = this.state;
    if (!ch.perks.includes(perk)) {
      return;
    }
    const newPerks = ch.perks.filter((p) => p !== perk);
    const idx = characters.indexOf(ch);

    this.setState({
      characters:
        update(characters, {
          [idx]: {
            perks: { $set: newPerks },
            extraCost: { $set: ch.extraCost - perk.cost },
          }
        }),
      resource: resource + perk.cost,
    });
  }

  onWeapon(ch, weapon) {
    const { resource, characters } = this.state;
    const preWeapon = ch.weapon;
    const idx = characters.indexOf(ch);
    this.setState({
      characters:
        update(characters, {
          [idx]: {
            weapon: { $set: weapon },
            extraCost: { $set: ch.extraCost - preWeapon.firearm_cost + weapon.firearm_cost },
          }
        }),
      resource: resource + preWeapon.firearm_cost - weapon.firearm_cost,
    })
  }

  onEquip(ch, equip) {
    const { resource, characters } = this.state;
    const preEquip = ch.equipment;
    const idx = characters.indexOf(ch);
    this.setState({
      characters: update(characters, {
        [idx]: {
          equipment: { $set: equip },
          extraCost: { $set: ch.extraCost - preEquip.vest_cost + equip.vest_cost },
        }
      }),
      resource: resource + preEquip.vest_cost - equip.vest_cost,
    })
  }

  onOpen(ch, isOpen) {
    const { characters } = this.state;
    const idx = characters.indexOf(ch);
    this.setState({
      characters: update(characters, {
        [idx]: {
          open: { $set: isOpen }
        }
      }),
    })
  }

  sort(a, b) {
    const { order } = this.state;
    if (order === 'asc') {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    } else {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    }
  }

  render() {
    const { characters, resource, order, orderBy, select } = this.state;
    let sortedCharacters = [...characters].sort((a, b) => {
      if (select === "aver" || orderBy === "cost") {
        return this.sort(a.character[orderBy], b.character[orderBy])
      }
      if (select === "general") {
        return this.sort(a.character.stats[orderBy], b.character.stats[orderBy]);
      }
      return this.sort(a.character.stats.stat_firearm_level[orderBy], b.character.stats.stat_firearm_level[orderBy])
    })

    sortedCharacters = sortedCharacters.sort((a, b) => {
      if (a.hired === b.hired) return 0;
      if (a.hired) return -1
      return 1;
    })

    const headCell = () => {
      const headCellName = [['비용', 'cost', true], ['state', 'state', false], ['perk', 'perk', false], ['action', 'action', false]];
      let addHead;
      if (select === "aver") {
        addHead = [['평가 점수', 'score', true], ['최대 숙련 화기', 'typeBestFirearm', true], ['숙련', 'scoreBestFirearm', true]];
      }
      else if (select === "general") {
        addHead = Object.keys(STAT_DESCR).map((key) => {
          return [STAT_DESCR[key], key, true];
        });
      }
      else {
        addHead = FIREARM_CATS.map((cat, i) => {
          return [cat, i, true];
        })
      }
      return [...addHead, ...headCellName];
    }

    const onRequestSort = (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property
      })
    };

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const handleSelectChange = (event) => {
      this.setState({
        select: event.target.value
      })
    };

    const selectBox = () => {
      const { select } = this.state;
      return (
        <form>
          <select
            value={select}
            onChange={handleSelectChange}
          >
            <option value="aver">종합</option>
            <option value="general">일반</option>
            <option value="firearm">화기</option>
          </select>
          <button onClick={this.reset.bind(this)}>초기화</button>
        </form >
      );
    }

    return (
      <ResourceContext.Provider value={resource} >
        <div>
          <div>resource={resource}</div>
          <button onClick={() => {
            window.localStorage.setItem("resource", JSON.stringify(resource));
            window.localStorage.setItem("characters", JSON.stringify(characters));
          }}>저장</button>
          <button onClick={() => {
            const characters = JSON.parse(window.localStorage.getItem("characters"));
            const resource = JSON.parse(window.localStorage.getItem("resource"));
            if (!characters || !resource) return;
            this.setState({
              characters, resource
            });
          }}>불러오기</button>
          <table width={'100%'}>
            <thead>
              <tr>
                <td>
                  {selectBox()}
                </td>
                <td>
                  이름
                </td>
                {headCell().map(([title, name, sortAvailable]) => {
                  if (sortAvailable) {
                    return (
                      <td key={name} onClick={createSortHandler(name)}>
                        {title}
                      </td>);
                  }
                  return (
                    <td key={name}>
                      {title}
                    </td>
                  )
                })}
              </tr>
            </thead>

            <tbody>
              {sortedCharacters.map((x, i) => {
                const hired = x.hired;
                const available = x.character.cost <= resource;

                return <AgentCard key={i} ch={x} hired={hired} select={select}
                  available={available} resource={resource}
                  onHire={this.onHire.bind(this)}
                  onFire={this.onFire.bind(this)}
                  onPerkSelect={this.onPerkSelect.bind(this)}
                  onPerkUnselect={this.onPerkUnselect.bind(this)}
                  onWeapon={this.onWeapon.bind(this)}
                  onEquip={this.onEquip.bind(this)}
                  onOpen={this.onOpen.bind(this)}
                />;
              })}
            </tbody>
          </table>
        </div>
        {sortedCharacters.map((x, i) => {
          return <AgentDetailDialog key={i} ch={x} open={x.open}
            handleClose={() => this.onOpen(x, false)} onHire={this.onHire.bind(this)}
            onPerkSelect={this.onPerkSelect.bind(this)}
            onPerkUnselect={this.onPerkUnselect.bind(this)}
            onWeapon={this.onWeapon.bind(this)}
            onEquip={this.onEquip.bind(this)}
            onOpen={this.onOpen.bind(this)}
          />
        })}
      </ResourceContext.Provider >
    )
  }
};

