/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PgbButton } from "../PgbButton";
import { PgbSplitter } from "../PgbSplitter";
import "./style.css";

export const PgbConC = ({
  PGBButtonPtLabel = "Buttonlabel31",
  PGBButtonOnMouseOut = "D",
  PGBButtonOnMouseOver = "D",
  PGBButtonVarState = "on",
  PGBButtonOnMouseMove = "D",
  PGBButtonOnClick = "onClick31",
  PGBButtonPtSubLabel = "ButtonSubLabel32",
  PGBButtonOnMouseOver1 = "D",
  PGBButtonVD,
  PGBButtonOnClick1 = "onClick32",
  PGBButtonOnMouseOut1 = "D",
  PGBButtonVarState1 = "on",
  PGBButtonV,
  PGBButtonPtLabel1 = "Buttonlabel32",
  PGBButtonOnMouseMove1 = "D",
  PGBButtonPtSubLabel1 = "ButtonSubLabel33",
  PGBButtonOnMouseOver2 = "D",
  PGBButtonImg,
  PGBButtonOnClick2 = "onClick33",
  PGBButtonOnMouseOut2 = "D",
  PGBButtonVarState2 = "on",
  PGBButtonVAfCda,
  PGBButtonPtLabel2 = "Buttonlabel33",
  PGBButtonOnMouseMove2 = "D",
  className,
  PGBButtonVA = "/anima/img/v320cff74-2.svg",
  PGBButtonVCff = "/anima/img/v320cff74-2.svg",
  PGBButtonVA1 = "/anima/img/v320cff74-3.svg",
  PGBButtonVCff1 = "/anima/img/v320cff74-3.svg",
}) => {
  return (
    <div className={`PGB-con-c ${className}`}>
      <PgbButton
        className="PGB-button-instance"
        onClick={PGBButtonOnClick}
        onClickDummyClassName="PGB-button-2"
        onMouseMove={PGBButtonOnMouseMove}
        onMouseMoveDummyClassName="PGB-button-2"
        onMouseOut={PGBButtonOnMouseOut}
        onMouseOutDummyClassName="PGB-button-2"
        onMouseOver={PGBButtonOnMouseOver}
        onMouseOverDummyClassName="PGB-button-2"
        overlapGroupClassName="PGB-button-2"
        pbShowSubText={false}
        perkgroupClassName="PGB-button-3"
        ptLabel={PGBButtonPtLabel}
        varState={PGBButtonVarState}
      />
      <PgbSplitter className="PGB-splitter-instance" />
      <PgbButton
        className="PGB-button-instance"
        onClick={PGBButtonOnClick1}
        onClickDummyClassName="PGB-button-2"
        onMouseMove={PGBButtonOnMouseMove1}
        onMouseMoveDummyClassName="PGB-button-2"
        onMouseOut={PGBButtonOnMouseOut1}
        onMouseOutDummyClassName="PGB-button-2"
        onMouseOver={PGBButtonOnMouseOver1}
        onMouseOverDummyClassName="PGB-button-2"
        overlapGroupClassName="PGB-button-2"
        perkgroupClassName="PGB-button-3"
        ptLabel={PGBButtonPtLabel1}
        ptSubLabel={PGBButtonPtSubLabel}
        v={PGBButtonV}
        vA={PGBButtonVA}
        vCff={PGBButtonVCff}
        vClassName="PGB-button-5"
        vD={PGBButtonVD}
        vDClassName="PGB-button-4"
        varState={PGBButtonVarState1}
      />
      <PgbSplitter className="PGB-splitter-instance" />
      <PgbButton
        className="PGB-button-c"
        onClick={PGBButtonOnClick2}
        onClickDummyClassName="PGB-button-2"
        onMouseMove={PGBButtonOnMouseMove2}
        onMouseMoveDummyClassName="PGB-button-2"
        onMouseOut={PGBButtonOnMouseOut2}
        onMouseOutDummyClassName="PGB-button-2"
        onMouseOver={PGBButtonOnMouseOver2}
        onMouseOverDummyClassName="PGB-button-2"
        overlapGroupClassName="PGB-button-2"
        perkgroupClassName="PGB-button-3"
        ptLabel={PGBButtonPtLabel2}
        ptSubLabel={PGBButtonPtSubLabel1}
        v={PGBButtonVAfCda}
        vA={PGBButtonVA1}
        vCff={PGBButtonVCff1}
        vClassName="PGB-button-5"
        vD={PGBButtonImg}
        vDClassName="PGB-button-4"
        varState={PGBButtonVarState2}
      />
    </div>
  );
};

PgbConC.propTypes = {
  PGBButtonPtLabel: PropTypes.string,
  PGBButtonOnMouseOut: PropTypes.any,
  PGBButtonOnMouseOver: PropTypes.any,
  PGBButtonVarState: PropTypes.string,
  PGBButtonOnMouseMove: PropTypes.any,
  PGBButtonOnClick: PropTypes.any,
  PGBButtonPtSubLabel: PropTypes.string,
  PGBButtonOnMouseOver1: PropTypes.any,
  PGBButtonVD: PropTypes.string,
  PGBButtonOnClick1: PropTypes.any,
  PGBButtonOnMouseOut1: PropTypes.any,
  PGBButtonVarState1: PropTypes.string,
  PGBButtonV: PropTypes.string,
  PGBButtonPtLabel1: PropTypes.string,
  PGBButtonOnMouseMove1: PropTypes.any,
  PGBButtonPtSubLabel1: PropTypes.string,
  PGBButtonOnMouseOver2: PropTypes.any,
  PGBButtonImg: PropTypes.string,
  PGBButtonOnClick2: PropTypes.any,
  PGBButtonOnMouseOut2: PropTypes.any,
  PGBButtonVarState2: PropTypes.string,
  PGBButtonVAfCda: PropTypes.string,
  PGBButtonPtLabel2: PropTypes.string,
  PGBButtonOnMouseMove2: PropTypes.any,
  PGBButtonVA: PropTypes.string,
  PGBButtonVCff: PropTypes.string,
  PGBButtonVA1: PropTypes.string,
  PGBButtonVCff1: PropTypes.string,
};
