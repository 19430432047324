import React from 'react';
import { colorOfDegree } from '../data/colors';
import './report.css'

export function BuildReport(props) {
  const rows = props.rows ?? [['임무 성공', 0, 40], ['VIP 부상', 1, -10], ['승리의 흥분', 0, 60], ['아군 사망', 1, -45], ['자원 절약', 0, 1]];
  const alphabetScore = props.alphabetScore ?? 'D';
  const score = props.score ?? 49;
  return (
    <>
      <h1>평가</h1>
      <div className="grid-report">
        <div>
          {rows.map((row, index) => {
            const [text, depth, value] = row;
            const align1 = (depth === 1) ? 'right' : 'center';
            return (
              <div key={text} className="grid-row" tyle={{ animationDelay: index * 0.7 + 's' }}>
                <div style={{ fontWeight: 'bold', textAlign: align1, fontSize: 22 }}>
                  {text}
                </div>
                <div style={{ textAlign: 'right', fontSize: 22 }}>
                  {value}
                </div >
              </div >
            )
          })}
          < div className="grid-row" style={{ animationDelay: rows.length * 0.7 + 's' }}>
            < div style={{ textAlign: 'center', fontSize: 22 }}>
              총
            </ div>
            < div style={{ textAlign: 'right', fontSize: 22 }}>
              {score}
            </ div>
          </ div>
        </div>
        < div style={{
          textAlign: 'right', visibility: 'hidden',
          alignItems: 'center', justifyContent: 'center', display: 'flex'
        }}>
          <span className="stamp" style={{ color: colorOfDegree[alphabetScore], animationDelay: rows.length * 0.7 + 's' }}>{alphabetScore}</span>
        </ div>
      </div >
    </>
  )
}
