import React, { useState } from 'react';
import { OutloopView } from './OutloopView';
import { triggerBgm, triggerSound } from './sound.mjs';
import { L, localeKeys, localeGet, localeSet } from './localization.mjs';
import { SoundButton } from './sound.mjs';
import { SaveImpl } from './SaveImpl';
import { AUTOSAVE_SLOTS } from './GrindView';
import { FigmaGeneralPopupView } from './FigmaPopupView';
import {
  FH1ButtonInline as ButtonInline,
} from './component/figma/fh1';
import { GameAnalytics } from 'gameanalytics';
import { BusinessCardView } from './BusinessCardView';
import { onclickWishlist, WishlistButton, CommunityButton } from './OutlinkViews';

import './TitleView.css';

function Btn(props) {
  const { name, label, onClick } = props;

  const [over, onOver] = useState(false);
  const color = over ? 'Red' : 'White';
  const soundCue = name === 'start' ? 'UI_Outgame_Button_Click_TitleStart' : 'UI_Outgame_Button_Click_Default';

  return <div className={`title-button title-button-${name}`}
    onClick={() => {
      onClick?.();
      triggerSound(soundCue);
    }}
    onMouseOver={() => {
      onOver(true);
      triggerSound('UI_Outgame_Button_Hovering');
    }}
    onMouseOut={() => { onOver(false); }}
  >
    <div className='title-button-ribbon'>
      <p>
        {L(label)}
      </p>
    </div>
  </div>;
}

// 기능 붙이려고 만든 임시 UI
function OptionsView(props) {
  const { settings } = props; // TODO: settings value from ue
  const [curLocale, setLocale] = useState(localeGet());

  const onChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    window.ue?.connection?.functioncall(JSON.stringify(
      {
        functioncall: 'SelectSettings',
        params: [id, value],
        callback: 'titleview?.uesettingsCallback',
      }
    ));
  };

  return <div className='title-options'>
    <div>
      <label>QUALITY PRESET</label>
      <select name='presets' id='selectpreset' className='title-options-select' onChange={onChange} value={settings?.preset ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>NORMAL</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>ANTI-ALIASING</label>
      <select name='presets' id='selectaa' className='title-options-select' onChange={onChange} value={settings?.aa ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>POST PROCESS</label>
      <select name='presets' id='selectpp' className='title-options-select' onChange={onChange} value={settings?.pp ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>SHADOWS</label>
      <select name='presets' id='selectshadows' className='title-options-select' onChange={onChange} value={settings?.shadows ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>EFFECTS</label>
      <select name='presets' id='selecteffects' className='title-options-select' onChange={onChange} value={settings?.effects ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>TEXTURES</label>
      <select name='presets' id='selecttex' className='title-options-select' onChange={onChange} value={settings?.tex ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>FOLIAGE</label>
      <select name='presets' id='selectfoliage' className='title-options-select' onChange={onChange} value={settings?.foliage ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>VIEW DISTANCE</label>
      <select name='presets' id='selectviewdistance' className='title-options-select' onChange={onChange} value={settings?.viewdistance ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>SHADING</label>
      <select name='presets' id='selectshading' className='title-options-select' onChange={onChange} value={settings?.shading ?? 1}>
        <option value='0'>LOW</option>
        <option value='1'>MID</option>
        <option value='2'>HIGH</option>
        <option value='3'>EPIC</option>
      </select>
    </div>
    <div>
      <label>VSYNC</label>
      <select name='presets' id='selectvsync' className='title-options-select' onChange={onChange} value={settings?.vsync ?? 1}>
        <option value={false}>OFF</option>
        <option value={true}>ON</option>
      </select>
    </div>
    <div>
      <label>DISPLAY</label>
      <select name='presets' id='selectdisplay' className='title-options-select' onChange={onChange} value={settings?.display ?? 1}>
        <option value='window'>WINDOW</option>
        <option value='full'>FULL</option>
        <option value='mix'>MIX</option>
      </select>
    </div>

    <div className="box">
      <p className="flex-config-title">{L('loc_ui_string_system_language')}</p>
      {localeKeys.map((locale) => {
        let cls = "mission-warning-overlay-btn2";
        if (locale === curLocale) {
          cls += " mission-warning-overlay-btn2-selected";
        }
        return <SoundButton key={locale} className={cls} onClick={() => {
          localeSet(locale);
          setLocale(locale);
        }}>{L('loc_ui_string_system_language_' + locale.toLowerCase())}</SoundButton>;
      })}
    </div>

    <div className="box">
      <p className="flex-config-title">Sound</p>
      <SoundButton key="mute-bgm" className={!!settings?.mute_bgm ? "mission-warning-overlay-btn2-selected" : "mission-warning-overlay-btn2"} onClick={() => {
        window.ue?.connection?.functioncall(JSON.stringify(
          {
            functioncall: 'SetMuteBgm',
            params: [!settings?.mute_bgm],
            callback: 'titleview?.uesettingsCallback',
          }
        ));
      }}>Mute BGM</SoundButton>
      <SoundButton key="mute-bgm" className={!!settings?.mute_sfx ? "mission-warning-overlay-btn2-selected" : "mission-warning-overlay-btn2"} onClick={() => {
        window.ue?.connection?.functioncall(JSON.stringify(
          {
            functioncall: 'SetMuteSfx',
            params: [!settings?.mute_sfx],
            callback: 'titleview?.uesettingsCallback',
          }
        ));
      }}>Mute SFX</SoundButton>
    </div>

    <div>
      <button onClick={() => {
        window.ue?.connection?.functioncall(JSON.stringify(
          {
            functioncall: 'ApplygraphicsSettings',
            callback: 'titleview?.uesettingsCallback',
          }
        ));
      }}>Apply</button>
    </div>
  </div>
}

export class TitleView extends React.Component {
  constructor(props) {
    super(props);
    this.overlayAnimationRef = React.createRef();

    this.keyDown = this.onKeyDown.bind(this);

    this.state = {
      // opening, title, intro, brief, options
      phase: 'opening',
      autoload: -1,
      readDemoNotification: false,
      overlayclicked: false,
      overlay: null,
    };
    this.settings = null;
    window.titleview = this;

  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
    this.waitForRefAndAddListener()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    if (this.overlayAnimationRef.current) {
      this.overlayAnimationRef.current.removeEventListener('animationend', this.handleAnimationEnd);
    }
  }

  waitForRefAndAddListener = () => {
    if (this.overlayAnimationRef.current) {
      this.overlayAnimationRef.current.addEventListener('animationend', this.handleAnimationEnd);
    } else {
      setTimeout(this.waitForRefAndAddListener, 100);
    }
  };

  handleAnimationEnd = () => {
    if (event.target === this.overlayAnimationRef.current) {
      this.setState({ readDemoNotification: true });
    }
  };


  uesettingsCallback(ret) {
    if (this.settings == null) {
      this.settings = {};
    }
    this.settings = {
      ...this.settings,
      ...ret,
    };
    this.setState(this.state);
  }

  onKeyDown(_ev) {
    const { phase, overlay } = this.state;
    if (phase === 'opening') {
      this.setState({ phase: 'title' });
    }
    if (phase === 'onboarding') {
      this.setState({ phase: 'businesscard' });
    }
    if (overlay) {
      this.setState({ overlay: null });
    }
  }

  renderLoad() {
    const { savedescrs } = this.state;

    let slotcount = Math.max(savedescrs.length, AUTOSAVE_SLOTS);
    let slots = [];
    for (let idx = 0; idx < slotcount; idx++) {
      const descr = savedescrs[idx];

      const loadButton = <>
        <ButtonInline onClick={() => {
          this.setState({ phase: 'outloop', autoload: idx });
        }}>{L('loc_ui_button_system_load')}</ButtonInline>
      </>;
      const discardButton = <ButtonInline onClick={async () => {
        const saveimpl = new SaveImpl({ prefix: 'proto_outloop_save' });
        const savedescrs = await saveimpl.discardSlot(idx);
        this.setState({ savedescrs });
      }}>{L('loc_ui_button_system_delete_save')}</ButtonInline>;

      if (!descr) {
        slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot_empty', { idx })}</div>);
        continue;
      }
      slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot', { idx, descr })} {loadButton} {discardButton}</div>);
    }

    return <>
      {slots}
    </>;
  }

  renderOverlayOption() {
    return <FigmaGeneralPopupView
      cls='figmapopup-general-title'
      title={L('loc_ui_title_menu_system')}
      buttons={[{ label: L('loc_ui_button_common_confirm'), onClick: () => { this.setState({ overlay: null }); } }]}
      className=""
    >
      <OptionsView settings={this.settings} />
    </FigmaGeneralPopupView>;
  }

  renderOverlayLoad() {
    return <FigmaGeneralPopupView
      cls='figmapopup-general-title'
      title={L('loc_ui_string_save_load')}
      buttons={[{ label: L('loc_ui_button_common_confirm'), onClick: () => { this.setState({ overlay: null }); } }]}
    >
      <div>{this.renderLoad()}</div>
    </FigmaGeneralPopupView>;
  }

  renderOverlay() {
    const { overlay } = this.state;
    if (overlay === null) {
      return null;
    }

    let content = null;
    if (overlay.ty === 'OPTION') {
      content = this.renderOverlayOption();
    } else if (overlay.ty === 'LOAD') {
      content = this.renderOverlayLoad();
    }

    if (content === null) {
      return null;
    }

    return <div className={`overlay-root`}>
      {content}
    </div>;
  }


  render() {
    const { m } = this.props;
    const { phase, autoload } = this.state;

    const overlay = this.renderOverlay();

    if (phase === 'outloop') {
      return <OutloopView m={m} autoload={autoload} onGameOver={() => {
        this.setState({ phase: 'title', autoload: -1 });
      }} />;
    } else if (phase === 'opening') {
      triggerBgm('Video_Opening', 'Play');
      return <div className="title-videoroot">
        <video
          src="https://public.5ml.io/pmc/PMM_2024_OP_Master_vp9.mp4"
          autoPlay muted
          onEnded={() => {
            this.setState({ phase: 'title' });
          }}
        />
      </div>;
    } else if (phase === 'onboarding') {
      triggerBgm('Video_Intro', 'Play');
      const locale = localeGet();
      const src = `https://public.5ml.io/pmc/SLB_Intro_animation_with_sub_bgm2_vp9_${locale}.mp4`;
      return <div className="title-videoroot">
        <video
          src={src}
          autoPlay muted
          onEnded={() => {
            this.setState({ phase: 'businesscard' });
          }}
        />
      </div>;
    } else if (phase === 'businesscard') {
      return <BusinessCardView onFinish={() => this.setState({ phase: 'outloop' })} />;
    }

    triggerBgm('BGM_Outgame_Title_Loop', 'Play');
    return <div className="title-root">
      <div className="title-left">
        <div className="title-logo">
          <img src="/img/title/PMM_Logo_Title_2.png" />
        </div>
        <div className="title-buttongroup">
          <div className="title-buttongroup-inner">
            <Btn name="start" label="New Game" onClick={() => {
              GameAnalytics.addDesignEvent(`newgame`);
              this.setState({ phase: 'onboarding' });
            }} />
            <Btn name="load" label="Load Game" onClick={() => {
              (async () => {
                const saveimpl = new SaveImpl({ prefix: 'proto_outloop_save' });
                const savedescrs = await saveimpl.loadDescrs();
                this.setState({ overlay: { ty: 'LOAD' }, savedescrs });
              })();
            }} />
            {/*<Btn name="extras" label="Extras" onClick={() => {
              return null;
            }} />*/}
            <Btn name="option" label="Option" onClick={() => {
              window.ue?.connection?.functioncall(JSON.stringify(
                {
                  functioncall: 'GetGraphicsSettings',
                  callback: 'titleview?.uesettingsCallback',
                }
              ));
              window.ue?.connection?.functioncall(JSON.stringify(
                {
                  functioncall: 'GetSoundSettings',
                  callback: 'titleview?.uesettingsCallback',
                }
              ));
              this.setState({ overlay: { ty: 'OPTION' } });
            }} />
          </div>
          <Btn name="exit" label="Exit" onClick={() => {
            window.ue?.connection?.onquit?.();
          }} />
        </div>
      </div>
      <div className="title-right" />
      <div className="title-footer">
        © 2024 All right reserved by 5minlab Corp.
      </div>
      <div className="title-side">
        <WishlistButton isLobby={true} eventType={`title`} />
        <CommunityButton isLobby={true} eventType={`title`} />
      </div>
      {!this.state.readDemoNotification &&
        this.renderDemoNotificationPopup({
          onConfirm: () => {
            this.setState({ overlayclicked: true });
          },
          clicked: this.state.overlayclicked
        })}

      {overlay}
    </div>;
  }

  renderDemoNotificationPopup(props) {
    return <div className={"title-overlay-root" + (this.state.overlayclicked ? ` clicked` : ``)} ref={this.overlayAnimationRef}>
      {!props.clicked &&
        <>
          <img className="title-overlay-image" src={`/prologue/ui_popup_img_wishlist.png`} onClick={() => { onclickWishlist(true); }} />
          <div
            className={"title-overlay-button"}
            onClick={props.onConfirm}>
            <div className="title-overlay-button-emboss" />
            <div className="title-overlay-button-background" />
            <div className="title-overlay-button-outline">
              <span className="title-overlay-button-label">continue</span>
            </div>
          </div>
        </>}

    </div>
  }
}


