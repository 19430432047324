import source from '../downloaded/missionWeeklyMilestone.json' assert { type: 'json' };
import { split } from './parseUtilities.mjs';

export const missionWeeklyMilestone = [];
for (const row of source) {
  let { mission_key, week, main_keys, main_values, sub_keys, sub_values } = row;

  missionWeeklyMilestone.push({
    mission_key,
    week: parseInt(week),
    main_keys: split(main_keys),
    main_values: split(main_values),
    sub_keys: split(sub_keys),
    sub_values: split(sub_values),
  });
}
export function getMissionMilestones(mission_key, week) {
  const data = missionWeeklyMilestone.find((m) => m.mission_key === mission_key && m.week === week);
  return data;
}
