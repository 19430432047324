import '../style.css';
import { useState } from 'react';

export function FH1WellSeg(props) {
  const [active, setActive] = useState(false);

  const { 'title': _, ...props0 } = props;
  return <div {...props0} className={`fh1-wellseg ${active ? 'fh1-active' : ''} ${props.selected ? 'fh1-selected' : ''} ${props.className ?? ''}`}
    onMouseDown={() => {
      setActive(true);
    }}
    onMouseUp={() => {
      setActive(false);
    }}
    onMouseEnter={() => {
      if (props0.onMouseEnter) {
        props0.onMouseEnter();
      }
    }}
    onMouseLeave={() => {
      if (props0.onMouseLeave) {
        props0.onMouseLeave();
      }
      setActive(false);
    }}
  >
    <div className='fh1-title'>{props.title}</div>
    <div className='fh1-content'>
      {props.children}
    </div>
    {props.overlay ? <div className="fh1-overlay"><h1>{props.overlay}</h1></div> : null}
  </div>;
}
