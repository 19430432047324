import source from '../downloaded/physical.json' assert { type: 'json' };


export const list = [];
for (const row of source) {
  let { level, life } = row;
  list.push({
    level: 0 | level,
    life: 0 | life,
  });
}

export function lifeMax(level) {
  return list.find(({ level: l }) => Math.floor(level) === l).life;
}
