/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PgbButton } from "../PgbButton";
import { PgbSplitter } from "../PgbSplitter";
import "./style.css";

export const PgbConB = ({
  PGBButtonPtLabel = "Buttonlabel31",
  PGBButtonOnMouseOut = "D",
  PGBButtonOnMouseOver = "D",
  PGBButtonVarState = "on",
  PGBButtonOnMouseMove = "D",
  PGBButtonOnClick = "onClick31",
  PGBButtonPtSubLabel = "ButtonSubLabel32",
  PGBButtonOnMouseOver1 = "D",
  PGBButtonVD,
  PGBButtonOnClick1 = "onClick32",
  PGBButtonOnMouseOut1 = "D",
  PGBButtonVarState1 = "on",
  PGBButtonV,
  PGBButtonPtLabel1 = "Buttonlabel32",
  PGBButtonOnMouseMove1 = "D",
}) => {
  return (
    <div className="PGB-con-b">
      <PgbButton
        className="PGB-button-6"
        onClick={PGBButtonOnClick}
        onClickDummyClassName="PGB-button-7"
        onMouseMove={PGBButtonOnMouseMove}
        onMouseMoveDummyClassName="PGB-button-7"
        onMouseOut={PGBButtonOnMouseOut}
        onMouseOutDummyClassName="PGB-button-7"
        onMouseOver={PGBButtonOnMouseOver}
        onMouseOverDummyClassName="PGB-button-7"
        overlapGroupClassName="PGB-button-7"
        pbShowSubText={false}
        perkgroupClassName="PGB-button-8"
        ptLabel={PGBButtonPtLabel}
        varState={PGBButtonVarState}
      />
      <PgbSplitter className="PGB-splitter-a" />
      <PgbButton
        className="PGB-button-6"
        onClick={PGBButtonOnClick1}
        onClickDummyClassName="PGB-button-7"
        onMouseMove={PGBButtonOnMouseMove1}
        onMouseMoveDummyClassName="PGB-button-7"
        onMouseOut={PGBButtonOnMouseOut1}
        onMouseOutDummyClassName="PGB-button-7"
        onMouseOver={PGBButtonOnMouseOver1}
        onMouseOverDummyClassName="PGB-button-7"
        overlapGroupClassName="PGB-button-7"
        perkgroupClassName="PGB-button-8"
        ptLabel={PGBButtonPtLabel1}
        ptSubLabel={PGBButtonPtSubLabel}
        v={PGBButtonV}
        vA="/anima/img/v320cff74-5.svg"
        vCff="/anima/img/v320cff74-5.svg"
        vClassName="PGB-button-b-2"
        vD={PGBButtonVD}
        vDClassName="PGB-button-b"
        varState={PGBButtonVarState1}
      />
    </div>
  );
};

PgbConB.propTypes = {
  PGBButtonPtLabel: PropTypes.string,
  PGBButtonOnMouseOut: PropTypes.any,
  PGBButtonOnMouseOver: PropTypes.any,
  PGBButtonVarState: PropTypes.string,
  PGBButtonOnMouseMove: PropTypes.any,
  PGBButtonOnClick: PropTypes.any,
  PGBButtonPtSubLabel: PropTypes.string,
  PGBButtonOnMouseOver1: PropTypes.any,
  PGBButtonVD: PropTypes.string,
  PGBButtonOnClick1: PropTypes.any,
  PGBButtonOnMouseOut1: PropTypes.any,
  PGBButtonVarState1: PropTypes.string,
  PGBButtonV: PropTypes.string,
  PGBButtonPtLabel1: PropTypes.string,
  PGBButtonOnMouseMove1: PropTypes.any,
};
