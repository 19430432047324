import _ from 'lodash';
import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';

import './RoguelikeView.css';
import { Rng } from './rand.mjs';
import { presets } from './presets_mission.mjs';
import {
  tmpl_firearm_hostage,
} from './presets_firearm.mjs';
import { entityFromStat } from './stat';
import { firearms } from './presets_firearm';
import { gears_vest_bulletproof } from './presets_gear';
import { throwables } from './presets_throwables';
import { modifiers, agendakeys } from './data/google/processor/data_modifiers.mjs';
import { marketItems } from './data/google/processor/data_market.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as agent_presets from './data/google/processor/data_presets.mjs';
import * as data_facilities from './data/google/processor/data_facilities.mjs';
import { centertypes } from './data/google/processor/data_centertypes.mjs';
import { centertraits } from './data/google/processor/data_centertraits.mjs';
import { work as data_work } from './data/google/processor/data_work.mjs';
import { regions } from './data/google/processor/data_forces.mjs';
import { onboardings } from './data/google/processor/data_missions.mjs';
import { unlocksByRenown } from './data/google/processor/data_renown.mjs';
import {
  createAgent,
  agentAvail,
  lifeRecoverMultiplier,
  agentGrowth,
  agentChangeApply,
  agentChangeDescr,
  agentMissionExp,
  DEFAULT_FIREARM,
  DEFAULT_EQUIPMENT,
  DEFAULT_THROWABLE,
} from './character';
import {
  instantiateMission,
  instantiateMissionEntities,
  createMilestonMission,
  createMission,
  missionUpdateRewards,
  MILESTONE_LEVEL_MULT,
} from './mission';
import {
  createSlot,
  createInstructor,
  slotCancel,
  tickSlots,
  addAvailabilityPending
} from './training';

import { WorldState2 } from './worldmap';

import { contractRenew } from './contract';
import { EFFECTS_DEFAULT } from './effects';
import { ResearchProgress, ResearchEffectLabel } from './ResearchView';
import { turnBalance } from './CashbookView';
import { DepartmentRoot, createDepartmentFromFacility, DEPARTMENT_ITEM_EXPIRE_DAYS } from './department.mjs';

import { TICK_PER_DAY, TICK_PER_WEEK, TICK_PER_MONTH, tickToDate, tickToMonth } from './tick.mjs';
import { firearm_ty_keys, generateSummary, perk2_keys } from './training.mjs';
import { agentStats2ChangeInfo, agentStats2TrainingInfo, agentStats2GaugeDecayInfo, applyAgentStats2GaugeDelta, mergeStats2GaugeDelta, tickToAge } from './character.mjs';
import { quests as data_quests } from './data/google/processor/data_quest.mjs';
import { options as data_itemOptions } from './data/google/processor/data_itemOptions.mjs'
import { getMissionCount } from './data/google/processor/data_missionGenerator.mjs'
import { L } from './localization.mjs';

Chart.register(...registerables);

export const ENABLE_RESEARCH = false;

const MISSION_LOSE_RENOWN_MULT = -0.5;


// aiden-87
const CONTRACT_OPTION_ADVANCED_MONTHLY_COST = 100;

// 매 회복당 비용
const COST_PER_HEAL = 5;

// LD: 아래 일 간격마다 한 번 씩 새 임무가 생성됩니다.
const DEPARTMENT_WORK_INTERVAL = TICK_PER_DAY;

export const AGENT_LISTING_COST = function (areaNum) {
  return Math.floor(Math.pow(2, areaNum) * 100);
}

const AGENT_LISTING_MAX = 4;

// LD: 시작 재화 량.
const INITIAL_RESOURCES = {
  // 자원
  resource: 5000,
  delayed_pays: [],
  renown: { level: 0, point: 1, max: false }
};
const DEBUG_INSTANT_UPGRADE = false;

const AREAS = [
  { idx: 0, name: 'Barranquilla', stability: 100, office: true, locked: false, cost: 100 },
  { idx: 1, name: 'Cali', stability: 70, office: false, locked: false, cost: 300 },
  { idx: 2, name: 'Medellín', stability: 40, office: false, locked: false, cost: 500 },
  { idx: 3, name: 'Bogotá', stability: 10, office: false, locked: false, cost: 500 }
];

export const STABILITY_LEVELS = [
  { idx: 3, max: 20, name: 'Warzone', officeCost: -1000, missionDuration: 7 },
  { idx: 2, max: 50, name: 'Hostile', officeCost: -500, missionDuration: 10 },
  { idx: 1, max: 80, name: 'Tense', officeCost: -300, missionDuration: 10 },
  { idx: 0, max: 100, name: 'Peaceful', officeCost: -100, missionDuration: 14 }
]

export const RENOWN = [
  { name: 'Level 1', max: 100, milestone: 'ship', milestoneNum: 0 },
  { name: 'Level 2', max: 200, milestone: 'bazaar', milestoneNum: 1 },
  { name: 'Level 3', max: 1000, milestone: 'bazaar', milestoneNum: 2 },
  { name: 'Level 4', max: 8000, milestone: 'embassy', milestoneNum: 3 },
  { name: 'Level 5', max: 15000, milestone: 'metro', milestoneNum: 4 },
];

export const RENOWN_TIER = [
  { min: 0, max: 99, name: '대부분 존재를 모름', tier: 0 },
  { min: 100, max: 299, name: '동네에 조금 알려짐', tier: 1 },
  { min: 300, max: 599, name: '동네를 주름잡음', tier: 2 },
  { min: 600, max: 999, name: '지역구 내에서 경쟁력 있음', tier: 3 },
  { min: 1000, max: 1499, name: '지역구를 대표함', tier: 4 },
  { min: 1500, max: 1999, name: '국가적으로 유명함', tier: 5 },
]

const FIREARM_TIER_MAX = _.max(firearms.map((f) => f.firearm_rate));
const COST_WORLDMAP_AREA = 5000;

// const FIREARM_TYPES = ['sg', 'smg', 'ar', 'dmr', 'sr', 'hg'];
const FIREARM_TYPES = ['sg', 'smg', 'ar', 'hg'];

// 연구가 활성화되어 있는 경우
if (ENABLE_RESEARCH) {
  for (const area of AREAS.slice(1)) {
    area.locked = true;
  }
}

function itemSellPrice(item) {
  return item.sell_cost;
}

export function createMarketItemTy(rng, ty, level, idx, global_modifier) {
  const items = marketItems.filter((i) => i.stability_level === level && i.ty === ty);
  const item = rng.choice(items);
  const temp = rng.integer(item.min, item.max);
  switch (ty) {
    case 'agent':
      throw new Error('unreachable');

    case 'firearm':
      {
        let firearm = rng.choice(firearms.filter((f) => f.firearm_rate === temp));
        if (global_modifier.find((m) => m.key === 'mod_global_12_shop_firearm')) {
          firearm = rng.choice(firearms.filter((f) => f.firearm_rate === Math.min(temp + 1, FIREARM_TIER_MAX)));
        }
        if (global_modifier.find((m) => m.key === 'mod_global_15_shop_firearm_2')) {
          firearm = rng.choice(firearms.filter((f) => f.firearm_rate === Math.min(temp + 2, FIREARM_TIER_MAX)));
        }
        firearm = { ...firearm, options: [] };

        const options = data_itemOptions.filter((d) => d.item_type === 'firearm' && d.item_group.find((i) => i === firearm.firearm_ty));
        for (const option of options) {
          if (rng.range(0, 1) <= option.prob_generate) {
            for (const { modifier, value } of option.modifiers) {
              if (modifier === 'price') {
                firearm.buy_cost *= value;
                firearm.sell_cost *= value;
              }
              else if (firearm[modifier]) {
                firearm[modifier] *= value;
              }
            }

            firearm.options.push(option.name);
          }
        }

        return {
          ty: 'firearm',
          buy_cost: firearm.buy_cost,
          sell_cost: firearm.sell_cost,
          firearm,
          idx,
        };
      }

    case 'equipment':
      {
        let equipment = gears_vest_bulletproof.find((e) => e.vest_rate === temp);
        if (global_modifier.find((m) => m.key === 'mod_global_13_shop_equipment')) {
          equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === Math.min(temp + 1, 4)));
        }
        if (global_modifier.find((m) => m.key === 'mod_global_16_shop_equipment_2')) {
          equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === Math.min(temp + 2, 4)));
        }
        equipment = { ...equipment, options: [] };

        const options = data_itemOptions.filter((d) => d.item_type === 'equipment');
        for (const option of options) {
          if (rng.range(0, 1) <= option.prob_generate) {
            for (const { modifier, value } of option.modifiers) {
              if (modifier === 'price') {
                equipment.buy_cost *= value;
                equipment.sell_cost *= value;
              }
              else if (equipment[modifier]) {
                equipment[modifier] *= value;
              }
            }

            equipment.options.push(option.name);
          }
        }

        return {
          ty: 'equipment',
          buy_cost: equipment.buy_cost,
          sell_cost: equipment.sell_cost,
          equipment,
          idx,
        };
      }

    case 'throwable':
      let throwable = throwables.find((t) => t.key === item.throwable);
      throwable = { ...throwable, options: [] };

      const options = data_itemOptions.filter((d) => d.item_type === 'throwable');
      for (const option of options) {
        if (rng.range(0, 1) <= option.prob_generate) {
          for (const { modifier, value } of option.modifiers) {
            if (modifier === 'price') {
              throwable.buy_cost *= value;
              throwable.sell_cost *= value;
            }
            else if (throwable[modifier]) {
              throwable[modifier] *= value;
            }
          }

          throwable.options.push(option.name);
        }
      }

      return {
        ty: 'throwable',
        buy_cost: throwable.buy_cost,
        sell_cost: throwable.sell_cost,
        throwable,
        idx,
      };
    default:
      throw new Error('not implemented', ty);
  }
}

function renderCost(props) {
  const { ty, resource } = props;
  const agents = props.agents ?? [];

  let msg = null;
  if (ty === 'share') {
    const agent = agents[0];
    msg = `${agent.name}에게 $${-resource}만큼 수익을 배분해 줍니다.(수익 배분율: ${(agent.contract.shareRatio * 100).toFixed(1)}%)`;
  } else if (ty === 'contract') {
    msg = `${agents[0].name}에게 $${-resource}만큼의 기본급을 지급합니다.`;
  } else if (ty === 'extra') {
    msg = `${agents[0].name}의 우대 의료비용으로 $${-resource}만큼을 지출합니다.`;
  } else if (props.delayed) {
    msg = `Mission reward $${props.resource} will be paid over 4 months.`;
  } else {
    msg = `$${props.resource}만큼을 획득했습니다.`;
  }
  return msg;
}


export function renderReward(reward, areas) {
  let render = [];
  for (const { ty, args } of reward) {
    switch (ty) {
      case 'resource':
        render.push(renderCost(args));
        break;
      case 'agent':
        render.push(agentChangeDescr(args));
        break;
      case 'destroyItems':
        render.push(`Items [] is destroyed.`)
        break;
      case 'deadAgent':
        render.push(`Agent ${args.agent.name} is dead.`);
        break;
      case 'getAgent':
        render.push(`New agent ${args.name} is recruited.`);
        break;
      case 'inventory':
        render.push(`Got ${args.name}.`)
        break;
      case 'globalModifier':
        render.push(`Acquired ${modifiers[args.key].name} (${args.term / TICK_PER_MONTH} months)`);
        break;
      case 'removeGlobal':
        render.push(`Removed [${modifiers[args].name}]`);
        break;
      case 'heal':
        render.push(`All agents are cured.`);
        break;
      case 'removeAgentModifier':
        render.push(`Removed [${modifiers[args.modifier].name}] from ${args.agent.name}.`)
        break;
      case 'mission':
        render.push(`A request will be added with [${args.modifier.map((m) => modifiers[m].name + ', ')}].`)
        break;
      case 'missionAll':
        render.push(`All current requests get [${args.modifier.map((m) => modifiers[m].name + ', ')}].`)
        break;
      case 'missionMilestone':
        render.push(`Next incoming mission has [${args.modifier.map((m) => modifiers[m].name + ', ')}].`)
        break;
      case 'ment':
        render.push(`${args}`);
        break;
      case 'area':
        if (args.area.stability_delta !== 0) {
          render.push(`Stability of ${areas[args.area.num].name} region changes by ${args.area.stability_delta}.`)
        }
        break;
      case 'renown':
        if (args.point) {
          render.push(`Gained ${args.point} reputation.`)
        }
        if (args.level) {
          render.push(`Gained new reputation level.`)
        }
        break;
      case 'removeMissions':
        render.push(`Removed all mission listings.`)
        break;
      case 'removeMarkets':
        render.push(`Removed all market listings.`)
        break;
      default:
        throw new Error('not implemented', ty);
    }
  }

  return render;
}

function missionCostTime(dist) {
  // const { dists } = world.distances0(world.qridx(src_idx));
  return Math.round(TICK_PER_DAY * 7 + dist * TICK_PER_DAY / 2);
}


export class RoguelikeGame {
  constructor(names, props) {
    if (props) {
      Object.assign(this, props);

      this.progress = new ResearchProgress(this.progress);
      this.departmentRoot = new DepartmentRoot(this.departmentRoot);

      if (!this.questTracker) {
        this.questTracker = {
          deactivated: data_quests.map((q) => {
            return {
              ...q,
              completed: false,
              notified_spawn: false,
              notified_complete: false,
            };
          }),
          onProgress: [],
          completed: [],
          prerequisites: {},
        };

        this.checkQuestPrerequisites();
      }
    } else {
      this.initialize(names);
    }

    this.syncWorldmap();
  }

  initialize(names) {
    const seed = Rng.randomseed();
    const rng = new Rng(seed);

    rng.shuffle(names);

    let agent_idx = 0;

    const agents = [];

    let resources = { ...INITIAL_RESOURCES }
    let cashbook = [];
    cashbook.push({ turn: -1, resource: resources.resource, agents: [] });

    const world = new WorldState2();
    let world_idx = world.centers.find((c) => world.storage[c.idx].centerstate.office)?.idx;


    const turn = 0;
    const stage = 1;

    const newbie = true;

    const global_modifier = [];

    for (const { vocation, contract_ty, power, potential_label, growthcap, physicalcap, stats2 } of agent_presets.presets) {
      const potentialData = pot.findByLabel(potential_label);
      const potential = rng.range(potentialData.potential_min, potentialData.potential_max);

      const idx = rng.integer(0, names.length - 1);
      const [name] = names.splice(idx, 1);

      const agent = createAgent(rng, name, agent_idx++,
        { power, growthcap, physicalcap, potential, vocation: [vocation], stats2, areaNum: 0, turn, global_modifier });
      agent.world_idx = world_idx;

      // 첫 계약
      agent.contract.ty = contract_ty;
      agent.contract = contractRenew(0, agent, {}, global_modifier);

      agent.life_max = Math.round(agent.stats2.toughness * 6 + 16);
      agent.life = agent.life_max;

      agents.push(agent);
      names = names.filter((n) => n !== name);
    }

    const areas = AREAS.map((area) => { return { ...area } });

    const milestone_missions = [];
    const missions = [];

    const slots = [
      /*
      createSlot(lastSlotIdx++, "stat"),
      createSlot(lastSlotIdx++, "perk"),
      createSlot(lastSlotIdx++, "aptitude"),
      */
    ];

    let lastInstructorIdx = 0;
    const instructors = [
      createInstructor(lastInstructorIdx++, `교관 1`, perk2_keys, firearm_ty_keys),
    ];

    this.seed = seed;
    this.rng = rng;
    this.turn = turn;

    this.stage = stage;
    this.resources = resources;
    this.cashbook = cashbook;
    this.agentbook = [];

    this.journals = [];

    this.pauseconfig = {
      facility_upgrade: true,
      agent_recover: false,
      department: false,

      training_finished: true,

      market: false,
      recruit: false,
      renown_changed: true,

      agent_dead: true,

      mission_start: true,
      mission_warning: true,

      department_market_staff: false,
      department_new_staff: false,
      department_market: false,
      department_department_market: false,
      department_department_recruit: false,
      department_intel: true,
    };

    this.notificationconfig = {
    };

    this.pendings = [];

    this.notifications = [];
    this.pending_popups = [];

    this.agents = agents;
    this.agent_idx = agent_idx;

    this.missions = missions;
    this.mission_modifier = [];
    this.milestone_missions = milestone_missions;

    let nextItemId = 0;
    this.inventories = [
      { ty: 'equipment', buy_cost: 100, sell_cost: 0, equipment: gears_vest_bulletproof[0], id: nextItemId++ },
      // { ty: 'firearm', cost: 100, equipment: gears_vest_bulletproof[0], id: nextItemId++ },
      // { ty: 'firearm', cost: 100, firearm: firearms.find((f) => f.firearm_ty === 'smg'), id: nextItemId++ }
    ];
    this.nextItemId = nextItemId;

    this.market_listings = [];
    this.recruit_listings = [];

    this.mission_selected = this.missions[0];

    this.state = null;
    this.mission_state = null;

    this.names = names;

    this.global_modifier = global_modifier;
    this.areas = areas;

    this.permadeath = true;

    this.newbie = newbie;

    this.progress = new ResearchProgress();

    this.questTracker = {
      deactivated: data_quests.slice(),
      onProgress: [],
      completed: [],
      prerequisites: {},
    };

    this.world = world;
    this.slots = slots;
    this.instructors = instructors;
    this.pendingTraining = null;
    this.trainingResults = [];

    const departmentRoot = new DepartmentRoot(null);
    departmentRoot.initWorldFacilities(world);
    this.departmentRoot = departmentRoot


    // TODO
    this.milestone_clears = 0;
    this.baseSelect(world_idx);

    //{ty, props}
    this.department_events = [];
    this.effects = { ...EFFECTS_DEFAULT };

    /*
    this.progress.effects_once = [
      { key: 'milestone_weaken', value: 1 },
      { key: 'mod_global_boom', value: 3 },
      { key: 'mod_personal_morale_all', value: 3 },
      { key: 'branch_open', value: 1 },
      { key: 'milestone_open', value: 1 },
      { key: 'train_slot', value: 1 },
      { key: 'cash_once', value: 100000 },
      { key: 'renown_once', value: 100 },
      { key: 'renown_once', value: 500 },
      { key: 'renown_once', value: 500 },
      { key: 'renown_once', value: 500 },
      { key: 'renown_once', value: 500 },
      { key: 'renown_once', value: 500 },
      { key: 'renown_once', value: 500 },
    ];

    this.progress.effects_acc = {
      'reward_renown_pos': 50,
      'reward_renown_neg': 50,

      'update_trade_day': 3,
      'renown_monthly': 3,
      'train_efficiency_all': 10,
      'train_duration_all': -10,

      'train_cost_all': -50,

      'hp_unable_recover': 10,
      'reward_firearm_prob': 10,
      'reward_equip_prob': 10,
      'reward_merc_overall': 10,
      'reward_cash_value': 10,
      'recruit_refresh_cost': 10,

      'mission_hyena': 10,
    };
    */

    // initialize world
    for (const { idx } of world.centers) {
      const data_type = rng.weighted_key(centertypes, 'weight');
      const data_trait = rng.choice(centertraits.filter((t) => data_type.traits.includes(t.ty)));

      const { centerstate } = world.storage[idx];
      const office = centerstate?.office ?? false;

      const { tier, forces } = regions[centerstate.name];
      centerstate.tier = tier;
      centerstate.forces = forces;

      centerstate.type = data_type;
      centerstate.trait = data_trait;

      if (rng.range(0, 1) < 0.3) {
        // local을 지역 tier보다 낮아야 함
        const local_candidates = data_facilities.facilitiesByTy0('local').filter((f) => f.tier <= tier);
        this.facilityAppend(idx, rng.choice(local_candidates).key);
      }

      {
        // 0티어만 나와야 함
        const support_candidates = data_facilities.facilitiesByTy0('support').filter((f) => f.tier === 0);
        this.facilityAppend(idx, rng.choice(support_candidates).key);
      }
    }

    // base
    this.areaUnlockSync(world_idx);
    this.areaBaseSync(world_idx);

    // TODO: testing
    // {
    //   const { centerstate: { facilities } } = world.storage[world_idx];
    //   this.forceUpdateFacility(world_idx, facilities[1], 'training_general1');
    // }

    this.checkQuestPrerequisites();

    // this.appendNewMission('시작', 0, world_idx);
    this.appendOnboardingMission(onboardings[0]);
  }

  getRenownTier() {
    const { resources } = this;
    const { renown } = resources;

    for (let i = 0; i < RENOWN_TIER.length; i++) {
      const tier = RENOWN_TIER[i];
      if (tier.min <= renown.point && tier.max >= renown.point) {
        return tier;
      }
    }

    return RENOWN_TIER[RENOWN_TIER.length - 1];
  }

  pauseSet(key, value) {
    this.pauseconfig[key] = value;
  }

  facilityAppend(idx, key) {
    const { rng, world } = this;
    const { centerstate } = world.storage[idx];

    const fid = idx * 10 + centerstate.facilities.length;

    const facility = { key, fid, state: 'idle', enabled: false };

    if (!data_facilities.facilityIsBase(key)) {
      const world_facility = rng.choice(world.facilitySamples({ idx }));
      facility.idx = world_facility.idx;
    }

    centerstate.facilities.push(facility);
    this.syncWorldmap();
  }

  facilityApplyEffect(idx, fid, { effect, value0, value1 }) {
    const { world } = this;
    const { centerstate: s } = world.storage[idx];

    if (effect === 'base_enable_facility_tier') {
      s.effects.base_tier = value0;
    } else if (effect === 'base_give_slot') {
      this.facilityBaseSlot(idx, value0, value1);
    } else if (effect === 'department') {
      // 부서는 별도로 구현되어 있음
    } else if (effect === 'district_enable_operation') {
      s.safehouse = true;
      this.syncWorldmap();
    } else if (effect === 'generate_trainslot') {
      this.facilityTrainSlot(idx, value0, value1);
    } else {
      this.facilityApplyEffectWithSign(idx, fid, { effect, value0, value1 }, 1);
    }
  }

  //특수 케이스만 별도 처리하고, 기본적으로 facilityApplyEffect의 반대 동작을 수행하도록 한다
  facilityRevertEffect(idx, fid, prev_effect, next_effects) {
    const { world } = this;
    const { centerstate: s } = world.storage[idx];
    const { effect, value0, value1 } = prev_effect;

    if (effect === 'base_enable_facility_tier') {
      //기본 거점 시설은 철거/비활성화를 고려하지 않는다
    } else if (effect === 'base_give_slot') {
      //기본 거점 시설은 철거/비활성화를 고려하지 않는다
    } else if (effect === 'department') {
      // 부서는 별도로 구현되어 있음
    } else if (effect === 'district_enable_operation') {
      //기본 거점 시설은 철거/비활성화를 고려하지 않는다
    } else if (effect === 'generate_trainslot') {
      if (!next_effects.find((e) => e.effect === effect)) {
        this.facilityTrainSlot(idx, value0, 0);
      }
    } else {
      this.facilityApplyEffectWithSign(idx, fid, prev_effect, -1);
    }
  }

  facilityApplyEffectWithSign(idx, fid, { effect, value0, value1 }, sign_multiplier) {
    const { world } = this;
    const { centerstate: s } = world.storage[idx];

    if (effect === 'base_increase_mercenary_limit') {
      s.effects.agents_limit += value0 * sign_multiplier;
    } else if (effect === 'base_increase_mercenary_heal') {
      s.effects.recover_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'base_decrease_total_wage') {
      s.effects.wage_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'base_increase_staff_stat') {
      s.effects.staff_stats[value0] = (s.effects.staff_stats[value0] ?? 0) + value1 * sign_multiplier;
    } else if (effect === 'base_search_mercenary_background') {
      s.effects.recruit_backgrounds[value0] = (s.effects.recruit_backgrounds[value0] ?? 0) + value1 * sign_multiplier / 100;
    } else if (effect === 'base_increase_train_efficiency') {
      s.effects.train_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'base_increase_staff_stat_all') {
      s.effects.staff_stats['all'] = (s.effects.staff_stats['all'] ?? 0) + value1 * sign_multiplier;
    } else if (effect === 'district_first_aid') {
      s.effects.mission_recover_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'increase_mercenary_heal') {
      this.effects.recover_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'decrease_mercenary_wage') {
      this.effects.agent_wage_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'decrease_safehouse_cost') {
      this.effects.cost_safehouse_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'decrease_base_facility_cost') {
      this.effects.cost_facility_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'decrease_base_facility_time') {
      this.effects.duration_facility_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'district_increase_staff_stat') {
      s.effects.staff_stat += value0 * sign_multiplier;
    } else if (effect === 'district_increase_train_efficiency') {
      s.effects.train_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'district_increase_search_staff') {
      s.effects.staff_spawn_stat_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'district_decrease_base_facility_cost') {
      s.effects.cost_facility_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'district_decrease_base_facility_time') {
      s.effects.duration_facility_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'increase_operation_travel_speed') {
      this.effects.travel_speed_mult += value0 * sign_multiplier / 100;
    } else if (effect === 'generate_item') {
      this.effects.interval_items[value0] = (this.effects.interval_items[value0] ?? 0) + value1 * sign_multiplier;
    } else if (effect === 'generate_mercenary') {
      this.effects.interval_agents[value0] = (this.effects.interval_agents[value0] ?? 0) + value1 * sign_multiplier;
    } else if (effect === 'base_decrease_mission_time') {
      this.effects.mission_time_mult -= value0 * sign_multiplier / 100;
    } else if (effect === 'base_increase_stamina_recover') {
      this.effects.stamina_recover_mult += value0 * sign_multiplier / 100;
    }
    else {
      console.log(idx, effect, value0, value1, sign_multiplier);
      throw new Error(effect);
    }
  }

  facilityTrainSlot(fid, level, count) {
    let targetSlots = this.slots.filter((f) => Math.floor(f.idx / 10) === fid);
    const otherSlots = this.slots.filter((f) => Math.floor(f.idx / 10) !== fid);

    if (count > targetSlots.length) {
      for (let i = targetSlots.length; i < count; i++) {
        targetSlots.push(createSlot(fid * 10 + i, "stat", level));
      }
    }
    else if (count < targetSlots.length) {
      for (let i = targetSlots.length - 1; i >= count; i--) {
        const slot = targetSlots[i];
        slotCancel(slot);
      }
      targetSlots = targetSlots.slice(0, count);
    }

    for (const slot of targetSlots) {
      slot.level = level;
    }

    this.slots = otherSlots.concat(targetSlots);
    this.updateSlotsEffects();
  }

  facilityBaseSlot(idx, ty, slots) {
    const { world } = this;
    const { centerstate } = world.storage[idx];
    const slots_prev = centerstate.facilities.filter((f) => {
      return data_facilities.facilityByKey(f.key).ty0 === ty;
    }).length;
    const key = data_facilities.facilityDefault(ty).key;

    for (let i = slots_prev; i < slots; ++i) {
      this.facilityAppend(idx, key);
    }
  }

  syncWorldmap() {
    const { world } = this;

    // sync lock state
    const { centers, storage } = world;

    for (const center of centers) {
      const { centerstate } = storage[center.idx];
      if (centerstate.safehouse) {
        const neighbors = world.blockconns.filter((b) => b.from === center.idx);
        // unlock neighbors
        for (const { to } of neighbors) {
          storage[to].centerstate.locked = false;
        }
      }
    }

    {
      // sync missions
      const missions = [
        ...this.missions,
        ...this.milestone_missions,
        ...this.pendings.filter((p) => p.ty === 'mission').map((p) => {
          return {
            ...p.mission_state.mission,
            pending: true,
          };
        }),
      ];
      // TODO: 임무 가용 정보를 월드맵으로 넘기기
      world.missions = missions.map((m) => {
        m = { ...m };
        m.avail = this.missionAvailable(m);
        return m;
      });

      const changes = [];

      // facility
      for (const { idx } of centers) {
        const { centerstate } = storage[idx];
        const mode = world.centerMode(idx);

        for (let i = 0; i < centerstate.facilities.length; ++i) {
          const f = centerstate.facilities[i];
          const { ty0 } = data_facilities.facilityByKey(f.key);
          let enabled = f.enabled;
          if (ty0 === 'safehouse') {
            enabled = true;
          } else if (ty0 === 'local') {
            enabled = true;
          } else if (ty0 === 'support') {
            enabled = (mode === 0);
          } else {
            enabled = true;
          }

          if (f.enabled !== enabled) {
            const next = { ...f, enabled };
            centerstate.facilities[i] = next;
            changes.push({ prev: f, next });
          }
        }
      }

      for (const { prev, next } of changes) {
        this.onFacilityChanged(prev, next);
      }
    }
  }

  get tokens() {
    // TODO
    return this.milestone_clears + 1;
  }

  agentRenewalCount() {
    const { agents, turn } = this;
    return agents.filter(({ contract: { start, term } }) => start + term <= turn).length;
  }

  gameover() {
    const { turn, resources, questTracker } = this;
    if (resources.resource < 0) {
      return '잔고가 바닥났습니다.';
    }
    let quest = questTracker.onProgress.find((q) => q.expires_at < turn);
    if (quest) {
      return `제한 시간 안에 퀘스트를 완료하지 못했습니다: ${quest.title}`;
    }
    return null;
  }

  canProgress() {
    const { state, mission_state, department_events } = this;
    let avail = true;
    if (this.agentRenewalCount() !== 0) {
      avail = false;
    }
    if (state || mission_state) {
      avail = false;
    }
    if (department_events.length > 0) {
      avail = false;
    }

    return avail;
  }

  popDepartmentOverlay(overlay) {
    this.department_events = this.department_events.filter((o) => o !== overlay);
  }

  /**
   *
   * @param {*} ty contract/before/after/market
   * @param {*} msg
   * @param {*} turn
   * @param {*} journals
   * @returns
   */
  pushJournal(ty, msg, turn) {
    turn = turn ?? this.turn;
    const paused = this.pauseconfig[ty] ?? false;
    this.journals.push({ turn, ty, msg, paused });
    return paused;
  }

  /**
   *
   * @param {*} ty basic/compensation/select/event/market/contract
   * @param {*} resource
   */
  pushCashbook(props) {
    const { turn, resources, cashbook } = this;
    const { ty, resource } = props;
    const agents = props.agents ?? [];

    if (isNaN(resource)) {
      throw new Error(`error: ${resource}`);
    }

    this.pushJournal('', renderCost(props), turn);

    resources.resource += resource;
    this.triggerQuest('balance', resources.resource);

    // per-agent estimation
    for (const agent of agents) {
      const { contract } = agent;
      contract.balance += resource / agents.length;
    }

    const month = tickToMonth(turn);
    cashbook.push({
      turn: month,
      ty,
      resource,
      agents: agents.map(({ idx, name }) => ({ idx, name })),
    });
  }

  agentCashbookEntries(agent) {
    return this.cashbook.filter((item) => item.agents && item.resource && item.agents.find((a) => a.idx === agent.idx));
  }

  agentContribution(agent, TICK_PER_MONTH = 24 * 7 * 4) {
    const { turn } = this;
    const { contract } = agent;

    const acc_duration_month = (1 + turn - contract.contractAt) / TICK_PER_MONTH;
    if (acc_duration_month <= 0) {
      return 0;
    }

    const cashbook_agent = this.agentCashbookEntries(agent);
    const resources_in = _.sum(cashbook_agent.map(({ resource, agents }) => Math.max(0, resource / agents.length)));
    const resources_out = _.sum(cashbook_agent.map(({ resource, agents }) => Math.max(0, -resource / agents.length)));
    const contribution = Math.round((resources_in - resources_out) / acc_duration_month);
    return contribution;
  }

  missionApplyEffect(mission) {
    const { rng } = this;
    const { afterReward } = mission;

    afterReward.resource = Math.floor(afterReward.resource * (1 + this.researchEffectP('reward_cash_value')));

    if (this.researchEffect('reward_firearm_prob') > rng.range(0, 1)) {
      let max = _.max(firearms.map((f) => f.firearm_rate));
      afterReward.firearm = Math.min(afterReward.firearm + 1, max);
    }

    if (this.researchEffect('reward_equip_prob') > rng.range(0, 1)) {
      let max = _.max(gears_vest_bulletproof.map((f) => f.vest_rate));
      afterReward.equipment = Math.min(afterReward.equipment + 1, max);
    }

    const effects = [
      { effect_key: 'mission_firearm', modifier_key: 'mod_mission_1_firearm' },
      { effect_key: 'mission_equipment', modifier_key: 'mod_mission_2_equipment' },
      { effect_key: 'mission_throwable', modifier_key: 'mod_mission_3_throwable' },
      { effect_key: 'mission_militia', modifier_key: 'mod_mission_9_militia' },
      { effect_key: 'mission_hyena', modifier_key: 'mod_mission_4_hyena' },
    ];

    for (const { effect_key, modifier_key } of effects) {
      if (mission.modifier.includes(modifier_key)) {
        continue;
      }
      if (this.researchEffectP(effect_key) > rng.range(0, 1)) {
        mission.modifier.push(modifier_key);
      }
    }
  }

  appendOnboardingMission(data_onboarding) {
    const { rng, turn, global_modifier, missions, world, newbie } = this;
    const idxcenter = world.centers.find((c) => world.storage[c.idx].centerstate.office0)?.idx;

    let count = 0;
    const filter = (m, i) => {
      if (i === 0) {
        count = 0;
      }
      if (!m.stability_level.includes('온보딩') || m.newbie !== newbie) {
        return false;
      }
      count += 1;
      if (count === data_onboarding.index + 1) {
        return true;
      }
      return false;
    };

    const mission = createMission(rng, turn, newbie, 0, global_modifier, [], true, filter);
    this.missionApplyEffect(mission);

    // sample world mission
    {
      const { world_idx, renown } = data_onboarding;

      mission.renown_gain = renown;

      mission.p = world.qridx(world_idx);
      mission.idx = world_idx;
      mission.idxcenter = idxcenter;
      mission.cost = { time: missionCostTime(this.selected_world_idx_dists[world_idx]) };

      // sample client, target
      const { client, target } = world.centerSampleMission(rng, idxcenter);
      mission.client = client.name;
      mission.target = target.name;
    }

    missions.push(mission);

    this.syncWorldmap();
    return mission;
  }

  appendNewMission(level, areaNum, idx, subdiv, modifier) {
    const { rng, turn, global_modifier, missions, world } = this;

    // 온보딩 진행중에는 일반 임무 안 나옴
    if (onboardings.find((o) => o.tick >= turn)) {
      return null;
    }

    const s = world.storage[idx];
    const { centerstate } = s;

    const filter = m => {
      return m.stability_level.find((l) => l === level) && m.newbie === this.newbie;
    };

    const mission = createMission(rng, turn, this.newbie, areaNum, global_modifier, modifier, true, filter);
    this.missionApplyEffect(mission);

    // sample world mission
    {
      const center = { idx, p: world.qridx(idx) };
      const world_mission = world.onNewMission(center, subdiv);
      if (!world_mission) {
        return null;
      }

      const idxcenter = world.centerIdx(idx);

      mission.p = world_mission.p;
      mission.idx = world_mission.idx;
      mission.idxcenter = center.idx;
      mission.cost = { time: missionCostTime(this.selected_world_idx_dists[world_mission.idx]) };

      // sample client, target
      const { client, target } = world.centerSampleMission(rng, idxcenter);
      mission.client = client.name;
      mission.target = target.name;
    }

    missions.push(mission);

    this.syncWorldmap();
    return mission;
  }

  newMissionsCount() {
    const { world } = this;

    let count = 0;
    for (const { idx } of world.centers) {
      const { centerstate: { office } } = world.storage[idx];
      if (!office) {
        continue;
      }

      const f_office = world.facilitiesByCenterKey(idx, 'office');
      count += _.sum(f_office);
    }
    return count;
  }

  onTickMissionCenter(idx) {
    const { rng, turn, resources, world } = this;
    const { centerstate: { tier, unlocks } } = world.storage[idx];
    const center = world.centers.find((c) => c.idx === idx);

    const bonus_nego = world.facilityMaxBonusByKey(idx, 'negotiation');
    const bonus_command = world.facilityMaxByCenterKey(idx, 'command');

    let mults = [
      { expire_mult: 1, reward_mult: 1 },
      { expire_mult: 1.2, reward_mult: 1.1 },
      { expire_mult: 1.5, reward_mult: 1.3 },
    ][bonus_nego];

    let mults_command = [
      { cost: 1, prob: 0 },
      { cost: 0.85, prob: 0.15 },
      { cost: 0.70, prob: 0.3 },
    ][bonus_command];

    const counts = new Array(center.subdivisions.length);
    counts.fill(0);
    for (let subdiv = 0; subdiv < counts.length; subdiv++) {
      counts[subdiv] = rng.integer(0, 1);
    }
    if (_.sum(counts.slice(0, unlocks)) === 0) {
      const subdiv = rng.integer(0, unlocks - 1);
      counts[subdiv] += 1;
    }

    let count = 0;
    const missionCount = getMissionCount(rng, center.subdivisions.length);
    const subdivs = new Array(center.subdivisions.length).fill().map((arr, i) => i);
    rng.shuffle(subdivs);
    for (let i = 0; i < missionCount; i++) {
      const areaNum = rng.integer(0, Math.min(tier - 1, resources.renown.level));
      const level = STABILITY_LEVELS.find((l) => l.idx === areaNum).name;
      const subdiv = subdivs.pop();
      const mission = this.appendNewMission(level, areaNum, idx, subdiv, []);
      if (mission === null) {
        continue;
      }

      count += 1;
      mission.expires_at = turn + (mission.expires_at - turn) * mults.expire_mult;
      mission.resource = Math.round(mission.resource * mults.reward_mult);

      mission.cost.time = Math.round(mission.cost.time * mults_command.cost);
      if (rng.range(0, 1) < mults_command.prob) {
        mission.modifier.push(`mod_mission_8_weaken`);
      }
    }
    return count;
  }

  onTickMissions() {
    const { turn, world } = this;

    let count = 0;
    if (turn % (TICK_PER_WEEK * 2) === 0) {
      for (const { idx } of world.centers) {
        count += this.onTickMissionCenter(idx);
      }
    }

    return count;
  }

  areaBase(center, cost) {
    let { world } = this;
    const { storage } = world;
    const s = storage[center.idx];

    const { office } = s.centerstate;
    if (office) {
      return;
    }

    this.triggerQuest('build_office');
    this.pushCashbook({ ty: 'area', resource: cost });

    this.areaBaseSync(center.idx);
  }

  areaBaseSync(idx) {
    let { world } = this;
    const { storage } = world;
    const s = storage[idx];

    // TODO: 임시
    s.centerstate.locked = false;
    s.centerstate.office = true;
    this.facilityAppend(idx, 'base1');

    this.syncWorldmap();
  }

  areaUnlock(center, cost) {
    let { world } = this;
    const { storage } = world;
    const s = storage[center.idx];

    const { safehouse } = s.centerstate;
    if (safehouse) {
      return;
    }

    this.triggerQuest('build_safehouse');
    this.pushCashbook({ ty: 'area', resource: cost });
    this.areaUnlockSync(center.idx);
  }

  areaUnlockSync(idx) {
    const { world } = this;
    const { centers, storage } = world;

    const count = centers.filter((c) => storage[c.idx].office).length;
    this.researchConditionMax('branch_expand', count);

    // facility 추가
    this.facilityAppend(idx, 'safehouse1');
  }

  maxAgentsCount() {
    const { world } = this;
    let count = 0;
    for (const { idx } of world.centers) {
      count += this.baseAgentsCount(idx);
    }
    return count;
  }

  baseAgentsCount(idx) {
    const { world } = this;
    const { storage } = world;
    const s = storage[idx]?.centerstate;
    if (!s.safehouse) {
      return 0;
    }

    // 기본값
    let count = 4;

    if (s) {
      count += s.effects.agents_limit;
    }
    return count;
  }

  facilityReset(center, i) {
    const { world } = this;
    const { centerstate: { facilities } } = world.storage[center.idx];

    const f = facilities[i];
    const info = data_facilities.facilityByKey(f.key);
    if (info.ty0 !== 'base_sub') {
      return;
    }

    const next = {
      ...f,
      key: data_facilities.facilityDefault(info.ty0).key,
      state: 'idle',
    };

    // TODO: 시설 파괴
    this.facilityTrainSlot(f.fid, 0, 0);

    facilities[i] = next;
    this.onFacilityChanged(f, next);
  }

  facilityUpdate(center, i, info) {
    const { world, turn } = this;
    const { centerstate: { facilities } } = world.storage[center.idx];

    if (facilities[i].state !== 'idle') {
      return;
    }

    if (DEBUG_INSTANT_UPGRADE) {
      const next = {
        ...facilities[i],
        key: info.key,
      };

      this.onFacilityChanged(facilities[i], next);
      facilities[i] = next;
    } else {
      facilities[i] = {
        ...facilities[i],
        state: 'upgrading',
        next: info.key,
        ends_at: turn + info.duration * TICK_PER_DAY,
      };
    }
    this.pushCashbook({ ty: 'area', resource: -info.cost });

    this.triggerQuest('build_facility', info.key);

    this.syncWorldmap();
  }

  inventorySell(item) {
    this.inventories = this.inventories.filter((i) => i !== item);
    this.pushCashbook({ ty: 'market', resource: itemSellPrice(item) });
  }

  contractRenew(ty, agent, option) {
    const { turn, global_modifier } = this;
    let { nextItemId } = this;

    agent = this.agents.find((a) => a.idx === agent.idx);
    if (ty === null) {
      this.agentRemove0(agent);
    } else {
      agent.contract = contractRenew(turn, agent, option, global_modifier);
    }

    this.nextItemId = nextItemId;

    this.pushJournal('contract', agent.name + '와(과) 계약을 갱신했습니다.');
  }

  agentChangeApply(args) {
    const { turn, agents } = this;

    const { agent } = args;
    const a = agents.find((a) => a.idx === agent.idx);
    if (!a) {
      return;
    }

    const prev_point = a.perks.point;
    agentChangeApply(a, args, turn);
    if (a.perks.point > prev_point) {
      this.checkQuestPrerequisites('perkpoint_acquire');
    }
  }

  renownUp() {
    const { renown } = this.resources;
    const { max } = RENOWN[renown.level];
    const incr = max - renown.point;
    this.renownIncr(incr);
  }

  resourceUp() {
    this.pushCashbook({ ty: 'event', resource: 10000, agents: [] });
  }

  agentAcquirePerk(agent, perk) {
    if (!perk) {
      return;
    }
    agent.perks.point -= 1;
    agent.perks.list.push(perk);

    this.triggerQuest('acquire_perk');
  }

  agentRemove0(agent, destroyItem) {
    let { nextItemId } = this;
    const found = this.agents.find((a) => a === agent);
    if (!found) {
      return false;
    }

    if (!destroyItem) {
      if (agent.equipment.vest_name !== 'loc_ui_string_common_protection_tier_0') {
        this.inventories.push({ ty: 'equipment', buy_cost: agent.equipment.buy_cost, sell_cost: agent.equipment.sell_cost, equipment: agent.equipment, id: nextItemId++ });
      }
      if (agent.throwables[0].throwable_name !== 'loc_ui_string_common_granade_tier_0') {
        this.inventories.push({ ty: 'throwable', buy_cost: agent.throwables[0].buy_cost, sell_cost: agent.throwables[0].sell_cost, throwable: agent.throwables[0], id: nextItemId++ });
      }
      if (agent.throwables[1].throwable_name !== 'loc_ui_string_common_granade_tier_0') {
        this.inventories.push({ ty: 'throwable', buy_cost: agent.throwables[1].buy_cost, sell_cost: agent.throwables[1].sell_cost, throwable: agent.throwables[1], id: nextItemId++ });
      }
      this.inventories.push({ ty: 'firearm', buy_cost: agent.firearm.buy_cost, sell_cost: agent.firearm.sell_cost, firearm: agent.firearm, id: nextItemId++ });
    }

    this.nextItemId = nextItemId;
    const slot = this.slots.find(s => s.training?.agentIdx === agent.idx);
    if (slot) {
      this.onAgentCancelTraining(slot);
    }

    this.agents = this.agents.filter((a) => a !== agent);
    if (agent.name) {
      this.names.unshift(agent.name);
    }
    return true;
  }

  agentRemove(agent, destroyItem) {
    const { turn } = this;
    if (!this.agentRemove0(agent, destroyItem)) {
      return false;
    }
    return this.pushJournal('agent_dead', agent.name + '이(가) 사망했습니다.', turn);
  }


  agentAvail(agent) {
    const ratio = 0.5 + this.researchEffectP('hp_able_threshold');
    return agentAvail(agent, ratio);
  }

  agentTerminate(agent, cost) {
    if (!this.agentAvail(agent)) {
      return;
    }
    if (agent.state === 'mission') {
      return;
    }
    if (!this.agentRemove0(agent)) {
      return;
    }

    this.pushCashbook({ ty: 'basic', resource: -cost, agents: [agent] });
  }

  missionSelect(mission, force) {
    for (const agent of this.agents) {
      agent.spawnarea = 0;
    }
    if (!force && mission === this.mission_selected) {
      this.mission_selected = null;
    } else {
      this.mission_selected = mission;
    }
  }

  missionAvailable(mission) {
    const { world } = this;
    const { p, idx } = mission;
    if (!p) {
      return false;
    }
    const idxcenter = world.centerIdx(idx);
    if (!idxcenter) {
      return false;
    }
    const { partitions: { visited } } = world.centers.find(({ idx }) => idx === idxcenter);
    const { centerstate } = world.storage[idxcenter];
    if (!centerstate.safehouse) {
      return false;
    }
    const subdiv = visited[idx];
    if (subdiv >= centerstate.unlocks) {
      return false;
    }

    return true;
  }

  buildMissionState(props) {
    const { rng, turn, names, global_modifier } = this;
    const { agents } = props;

    let mission = { ...props.mission };
    const instantiated = instantiateMission(rng, mission);
    const { simstate } = instantiated;

    simstate.entities = instantiateMissionEntities(rng, turn, instantiated, agents, global_modifier);

    if (!mission.milestone) {
      for (const stats of instantiated.rescues) {
        const entity = entityFromStat(stats, tmpl_firearm_hostage);
        entity.spawnarea = 1;
        entity.team = 2;
        entity.default_rule = 'idle';
        entity.ty = 'vip';

        simstate.entities.push(entity);
      }
    }

    return {
      instantiated,
      mission,
      agents,
      simstate,
      names,
    };
  }

  serializeMission(mission_state) {
    return JSON.stringify({
      seed: mission_state.mission.seed,
      preset: mission_state.mission.ty,
      simstate: mission_state.simstate,
    });
  }

  missionQueue(props) {
    const { mission, agents, cost, expectation } = props;

    this.missions = this.missions.filter((m) => m !== mission);
    this.milestone_missions = this.milestone_missions.filter((m) => m !== mission);

    mission.expectation = expectation;

    const mission_state = this.buildMissionState(props);
    const serialized = this.serializeMission(mission_state);

    // use resources
    const cost_time_mult = this.effects?.mission_time_mult ?? 1;
    const cost_time = Math.ceil(cost.time * cost_time_mult);

    this.pushCashbook({ ty: 'basic', resource: -cost.resource, agents });

    this.pushJournal('', `${agents.length}명의 요원을 파견하였으며, 비용으로 $${cost.resource}만큼을 지출했습니다. 임무는 ${Math.floor(cost_time / 24)}일 후 시작됩니다.`);

    // days
    let turn = this.turn + cost_time;
    // eslint-disable-next-line
    while (this.pendings.find((p) => p.ty === 'mission' && p.turn === turn)) {
      turn += 1;
    }

    this.pendings.push({
      ty: 'mission',
      turn,
      mission_state,
    });
    this.pendings.sort((a, b) => a.turn - b.turn);

    this.serialized = serialized;
    this.mission_selected = null;

    for (const agent of agents) {
      agent.state = 'mission';
    }

    this.triggerQuest('dispatch_mission');

    this.syncWorldmap();
  }

  resetMissionConfig() {
    this.mission_selected = null;
  }

  appendMilestoneMission(milestoneNum, fresh) {
    const renown = RENOWN.find((r) => r.milestoneNum === milestoneNum);
    const { rng, turn, global_modifier, newbie, world } = this;

    const mission = createMilestonMission(rng, turn, renown, MILESTONE_LEVEL_MULT.length - 1, fresh);
    missionUpdateRewards(mission, rng, turn, global_modifier, newbie);
    this.missionApplyEffect(mission);

    const center = rng.choice(world.centers.filter(({ idx }) => world.storage[idx].centerstate.office));
    const subdiv = rng.integer(0, world.storage[center.idx].centerstate.unlocks - 1);
    const world_mission = world.onNewMission(center, subdiv);

    mission.p = world_mission.p;
    mission.idx = world_mission.idx;
    mission.idxcenter = center.idx;
    mission.cost = { time: missionCostTime(this.selected_world_idx_dists[mission.idx]) };

    this.milestone_missions.push(mission);
    this.syncWorldmap();
  }

  renownIncr(amount, mission) {
    const { turn, resources: { renown }, world } = this;

    let prev = renown.point;

    renown.point += amount;
    if (renown.point >= RENOWN[renown.level].max) {
      renown.point = RENOWN[renown.level].max;
      renown.max = true;
    }
    renown.point = Math.max(renown.point, 0);

    let diff = renown.point - prev;
    if (diff !== 0) {
      this.pushJournal('renown_changed', `명성이 ${diff}만큼 변화하였습니다.`, turn);
      this.setMilestoneMissionsFreshness(renown.point);
    }

    this.researchConditionMax('renown', renown.point);

    if (mission?.idxcenter) {
      // 임무 수행에 따른 지역 명성치
      const { idxcenter } = mission;
      const { centerstate } = world.storage[idxcenter];

      centerstate.renown += amount;
    }
    return diff;
  }

  setMilestoneMissionsFreshness(renown) {
    const { milestone_missions, areas } = this;

    const milestone_missions_with_office = milestone_missions.filter((mm) => {
      const area_num = mm.area.num;
      return areas.filter((area) => area.office).map((area) => area.idx).includes(area_num);
    });

    for (const mm of milestone_missions_with_office) {
      const target_renown = RENOWN.find((r) => r.milestoneNum === mm.area.num);
      const required_renown = target_renown.max;
      if (renown >= required_renown) {
        mm.fresh = true;
      }
    }
  }

  missionRewardChoices(mission_state) {
    const { global_modifier } = this;
    const { seed, afterReward } = mission_state.mission;
    const { equipment, firearm, resource, throwable } = afterReward;

    let opts = [];

    let disabledEquip = false;
    if (global_modifier.find((m) => m.key === 'mod_global_5_excess_firearm' || m.key === 'mod_global_7_excess_throwable')) {
      disabledEquip = true;
    }
    if (equipment) {
      opts.push({ ty: 'equipment', equipment, disabled: disabledEquip });
    }

    let disabledFire = false;
    if (global_modifier.find((m) => m.key === 'mod_global_6_excess_equipment' || m.key === 'mod_global_7_excess_throwable')) {
      disabledFire = true;
    }
    if (firearm) {
      opts.push({ ty: 'firearm', firearm, disabled: disabledFire });
    }

    if (resource) {
      opts.push({ ty: 'resource', resource, disabled: false });
    }

    let disabledThrow = false;
    if (global_modifier.find((m) => m.key === 'mod_global_6_excess_equipment' || m.key === 'mod_global_5_excess_firearm')) {
      disabledThrow = true;
    }
    if (throwable !== 'none') {
      opts.push({ ty: 'throwable', throwable, disabled: disabledThrow });
    }

    const rng = new Rng(seed);
    for (let i = 0; i < 2; i++) {
      const idx = rng.integer(0, opts.length - 1);
      opts.splice(idx, 1);
    }
    if (mission_state.mission.modifier.find((m) => m === 'mod_mission_4_hyena') && opts.length > 0) {
      opts = [rng.choice(opts)];
    }

    opts.push({ ty: 'none', disabled: false });
    return opts;
  }

  missionComplete() {
    const { turn, mission_state, agents, resources, areas } = this;
    let { nextItemId } = this;

    let getAgent = [];
    for (const { ty, args } of mission_state.reward) {
      switch (ty) {
        case 'resource':
          if (args.delayed) {
            resources.delayed_pays.push({ ty: args.ty, resource: Math.round(args.resource * 1.2 / 4), ends_at: dayjs(tickToDate(turn)).add(5, 'day') });
          } else {
            this.pushCashbook({ ty: args.ty, resource: args.resource, agents: args.agents });
          }
          break;
        case 'agent':
          this.agentChangeApply(args);
          break;
        case 'destroyItems':
          //let msg = `해당 아이템들이 파괴되었습니다.`;
          const msg = [];

          for (const { agent, ty, index } of args.items) {
            if (ty === 'firearm') {
              // msg += `\n${L(agent.firearm.firearm_name)}`;
              msg.push({ agent, ty, item: agent.firearm });

              const inventory_index = this.inventories.findIndex((i) => i.ty === 'firearm' && i.firearm.key === 'firearm_hg_t1');
              if (inventory_index >= 0) {
                const item = this.inventories[inventory_index];
                this.agentEquip(agent, item, 0);
                agent.firearm = item.firearm;
                this.inventories.splice(inventory_index, 1);
              }
              else {
                agent.firearm = DEFAULT_FIREARM;
              }
            }
            else if (ty === 'equipment') {
              //msg += `\n${L(agent.equipment.vest_name)}`;
              msg.push({ agent, ty, item: agent.equipment });

              const inventory_index = this.inventories.findIndex((i) => i.ty === 'equipment' && i.equipment.key === 'vest_bulletproof_nothing');
              if (inventory_index >= 0) {
                const item = this.inventories[inventory_index];
                agent.equipment = item.equipment;
                this.inventories.splice(inventory_index, 1);
              }
              else {
                agent.equipment = DEFAULT_EQUIPMENT;
              }
            }
            else if (ty === 'throwable') {
              //msg += `\n${L(agent.throwables[index].throwable_name)}`;
              msg.push({ agent, ty, item: agent.throwables[index] });

              const inventory_index = this.inventories.findIndex((i) => i.ty === 'throwable' && i.throwable.key === 'throwable_none');
              if (inventory_index >= 0) {
                const item = this.inventories[inventory_index];
                agent.throwables[index] = item.throwable;
                this.inventories.splice(inventory_index, 1);
              }
              else {
                agent.throwables[index] = DEFAULT_THROWABLE;
              }
            }
          }
          this.onEnqueueTyPopup('items_destory', msg);
          break;
        case 'deadAgent':
          this.agentRemove(args.agent, args.destroyItem);
          break;
        case 'getAgent':
          getAgent.push(args);
          break;
        case 'inventory':
          this.inventories.push({ ...args, id: nextItemId++ });
          if (args.ty === 'firearm') {
            this.checkQuestPrerequisites('firearm_acquire');
          }
          else if (args.ty === 'equipment') {
            this.checkQuestPrerequisites('armor_acquire');
          }
          this.nextItemId = nextItemId;
          break;
        case 'globalModifier':
          this.global_modifier = this.global_modifier.filter((m) => args.key !== m.key && !modifiers[args.key].overwrite.includes(m.key));
          this.global_modifier.push({ ...args, start: turn });
          if (args.key === 'mod_global_22_devastated_homeland' && areas[0].stability > 25) {
            areas[0].stability = 25;
          }
          break;
        case 'removeGlobal':
          this.global_modifier = this.global_modifier.filter((mod) => mod.key !== args);
          break;
        case 'heal':
          for (const a of agents) {
            a.life = a.life_max;
          }
          break;
        case 'removeAgentModifier':
          for (const a of agents) {
            if (a.idx === args.agent.idx) {
              a.modifier = a.modifier.filter((m) => m.key !== args.modifier)
            }
          }
          break;
        case 'mission':
          const areaNum = mission_state.mission.area.num;
          const level = STABILITY_LEVELS.find((l) => l.max >= areas[areaNum].stability).name;
          const idxcenter = this.world.centerIdx(mission_state.mission.idx);
          this.appendNewMission(level, areaNum, idxcenter, -1, args.modifier);
          break;
        case 'missionAll':
          for (const m of this.missions) {
            m.modifier.push(args.modifier);
          }
          break;
        case 'missionMilestone':
          for (const mod of args.modifier) {
            for (const milestone of this.milestone_missions.filter((m) => m.ty === RENOWN[resources.renown.level].milestone)) {
              milestone.modifier = milestone.modifier.filter((m) => m !== mod && !modifiers[mod].overwrite.includes(m));
              milestone.modifier.push(mod)
            }
          }
          break;
        case 'ment':
          break;
        case 'area':
          areas[args.area.num].stability += args.area.stability_delta;
          areas[args.area.num].stability = Math.min(areas[args.area.num].stability, 100);
          areas[args.area.num].stability = Math.max(0, areas[args.area.num].stability);
          if (args.key === 'mod_global_22_devastated_homeland' && areas[0].stability > 50) {
            areas[0].stability = 50;
          }
          break;
        case 'renown':
          if (args.point) {
            this.renownIncr(args.point, mission_state.mission);

            if (args.point > 0) {
              for (const agent of agents) {
                let found = mission_state.agents.find((a) => a.idx === agent.idx);
                if (!found) {
                  continue;
                }
                agent.mission_stats.renowns += args.point;
              }
            }
          }
          if (args.level) {
            resources.renown.level++;
            resources.renown.max = false;
          }
          break;
        case 'removeMissions':
          this.missions = [];
          break;
        case 'removeMarkets':
          this.market_listings = [];
          break;
        default:
          throw new Error('not implemented', ty);
      }
    }

    for (const agent of getAgent) {
      agents.push(agent);
    }

    if (mission_state.res === 0) {
      this.pushJournal('success', renderReward(mission_state.reward, areas));

      // 마일스톤 임무 달성
      if (mission_state?.mission?.milestone) {
        this.milestone_clears += 1;
        this.checkQuestPrerequisites('milestone_clear');
        this.triggerQuest('milestone_clear', this.milestone_clears);
      }
      if (mission_state?.mission?.milestone_last) {
        this.researchConditionMax('milestone_clear', mission_state.mission.area.num + 1);
      }
    } else if (mission_state.res === 1) {
      this.pushJournal('fail', renderReward(mission_state.reward, areas));
    } else {
      this.pushJournal('pass', renderReward(mission_state.reward, areas));
    }

    for (const a of agents) {
      if (a.modifier.find((m) => m.key === 'mod_agent_10_drug')) {
        a.life = a.life_max;
      }
    }

    this.mission_selected = null;
    this.mission_state = null;
    this.state = null;

    this.maybeShowPopup();
    this.syncWorldmap();
  }

  missionSpawnAreas() {
    const mission = this.state.mission_selected;
    if (!mission) {
      return [];
    }

    const { seed } = mission;
    const areas = presets[mission.ty](seed).spawnareas;
    //앞에서 부터 spawnarea가 된다는 가정하...
    return areas.filter((area) => area.spawn);
  }

  agentSpawnChange(agent) {
    const areas = this.missionSpawnAreas();
    agent.spawnarea = (agent.spawnarea + 1) % areas.length;
  }

  baseSelect(world_idx) {
    const { missions, world } = this;
    this.selected_world_idx = world_idx;
    this.selected_world_idx_dists = world.distances0(world.qridx(world_idx)).dists;

    for (const mission of missions) {
      mission.cost = { time: missionCostTime(this.selected_world_idx_dists[mission.idx]) };
    }
  }

  agentEquip(agent, item, num) {
    if (!this.inventories.find((i) => i === item)) {
      return;
    }
    if (item.ty === 'equipment' && item.equipment.vest_rate * 4 > agent.stats2.bravery) {
      return;
    }
    this.inventories = this.inventories.filter((i) => i !== item);

    const { ty, buy_cost, sell_cost } = item;
    let { nextItemId } = this;
    if (ty === 'firearm') {
      // convert current firearm into inventory item
      this.inventories.push({ ty: 'firearm', buy_cost: agent.firearm.buy_cost, sell_cost: agent.firearm.sell_cost, firearm: agent.firearm, id: nextItemId++ });
      agent.firearm = item.firearm;
      this.triggerQuest('equip_firearm_tier', item.firearm.firearm_rate);
      this.triggerQuest('replace_firearm');
      this.updateQuest('firearm_all')
    } else if (ty === 'equipment') {
      // convert current firearm into inventory item
      this.inventories.push({ ty: 'equipment', buy_cost: agent.equipment.buy_cost, sell_cost: agent.equipment.sell_cost, equipment: agent.equipment, id: nextItemId++ });
      agent.equipment = item.equipment;
      this.triggerQuest('replace_armor');
      this.updateQuest('armor_all');
    } else if (ty === 'throwable') {
      this.inventories.push({ ty: 'throwable', buy_cost: agent.throwables[num].buy_cost, sell_cost: agent.throwables[num].sell_cost, throwable: agent.throwables[num], id: nextItemId++ });
      agent.throwables[num] = item.throwable;
    } else {
      throw new Error(`unimplmented ty=${ty}`);
    }
    this.nextItemId = nextItemId;
  }

  agentDisarm(agent) {
    let { nextItemId } = this;

    if (agent.firearm.key !== 'firearm_hg_t1') {
      let item = null;
      const inventory_index = this.inventories.findIndex((i) => i.ty === 'firearm' && i.firearm.key === 'firearm_hg_t1');
      if (inventory_index >= 0) {
        item = this.inventories[inventory_index];
      }
      else {
        const firearm = DEFAULT_FIREARM;
        item = { ty: 'firearm', buy_cost: firearm.buy_cost, sell_cost: firearm.sell_cost, firearm, id: nextItemId++ };
        this.inventories.push(item);
      }
      this.agentEquip(agent, item, 0);
    }
    if (agent.equipment.key !== 'vest_bulletproof_nothing') {
      let item = null;
      const inventory_index = this.inventories.findIndex((i) => i.ty === 'equipment' && i.equipment.key === 'vest_bulletproof_nothing');
      if (inventory_index >= 0) {
        item = this.inventories[inventory_index];
      }
      else {
        const equipment = DEFAULT_EQUIPMENT;
        item = { ty: 'equipment', buy_cost: equipment.buy_cost, sell_cost: equipment.sell_cost, equipment, id: nextItemId++ };
        this.inventories.push(item);
      }
      this.agentEquip(agent, item, 0);
    }
    for (let i = 0; i < 1; i++) {
      if (agent.throwables[i].key !== 'throwable_none') {
        let item = null;
        const inventory_index = this.inventories.findIndex((i) => i.ty === 'throwable' && i.throwable.key === 'throwable_none');
        if (inventory_index >= 0) {
          item = this.inventories[inventory_index];
        }
        else {
          const throwable = DEFAULT_THROWABLE;
          item = { ty: 'throwable', buy_cost: throwable.buy_cost, sell_cost: throwable.sell_cost, throwable, id: nextItemId++ };
          this.inventories.push(item);
        }
        this.agentEquip(agent, item, i);
      }
    }
  }

  /// TRAINING

  onAgentCancelTraining(slot) {
    const { agents, turn } = this;
    const ev = slotCancel(slot);

    if (ev.training.result.cum) {
      this.onTrainCompleteEvent(ev);
      this.onTrainEndEvent(ev);
    } else {
      const agent = agents.find((a) => a.idx === ev.training.agentIdx);
      const { reason } = ev;
      let msg = `${agent.name}이(가) 훈련을 중단했습니다.(${slot.idx}번 훈련 슬롯, 훈련 종료 사유: ${reason}`;
      this.pushJournal('training_canceled', msg, turn);
    }
  }

  onTrainCompleteEvent({ training }) {
    const { agents } = this;
    const agent = agents.find((a) => a.idx === training.agentIdx);

    const { result } = training;
    this.trainingResults = [...this.trainingResults, { agent, result }];
  }

  onTrainEndEvent({ slot, training, reason }) {
    const { turn, agents } = this;
    const { result } = training;

    const agent = agents.find((a) => a.idx === training.agentIdx);

    let msg = `${agent.name}이(가) 훈련을 마쳤습니다.(${slot.idx}번 훈련 슬롯, 훈련 횟수: ${Object.entries(result.count).map(([key, value]) => `${key}=${value}`).join(", ")}, 훈련 종료 사유: ${reason}`;
    msg += `\n${agentChangeDescr(result.cum)}`;

    let paused = this.pushJournal('training_finished', msg, turn);
    this.onEnqueuePopup(msg);

    agent.state = null;

    return paused;
  }

  dismissNotification(notification) {
    this.notifications = this.notifications.filter((n) => n !== notification);
  }

  onFinish(sim, res) {
    const { rng, permadeath, agents, world } = this;

    const mission_state = { ...this.mission_state };
    mission_state.reward = [];

    this.triggerQuest('finish_mission');

    const resource = mission_state.mission.resource + mission_state.mission.extraResource;
    const mission_agents = agents.filter((a) => {
      if (mission_state.agents.find((ma) => !ma.temporary && a.idx === ma.idx)) {
        return true;
      }
      return false;
    });

    const win = res === 0;

    //미션 성공
    if (win) {
      this.checkQuestPrerequisites('mission_clear');

      const delayed = mission_state.mission.modifier.find((m) => m === 'mod_mission_10_delayed_pay');

      let point = mission_state.mission.renown_gain;
      if (mission_state.mission.modifier.find((m) => m === 'mod_mission_7_resent')) {
        point += RENOWN[this.resources.renown.level].max * 0.1;
      }
      if (point > 0) {
        point += point * this.researchEffectP(`reward_renown_pos`);
      } else if (point < 0) {
        point += point * this.researchEffectP(`reward_renown_neg`);
      }
      mission_state.reward.push({ ty: 'renown', args: { point, reason: 'mission_clear' } });

      // TODO: 기본 보상
      mission_state.reward.push({ ty: 'resource', args: { ty: 'basic', resource, agents: mission_agents, delayed } });

      const area = mission_state.mission.area;
      mission_state.reward.push({ ty: 'area', args: { area } });

      if (mission_state.mission.modifier.find((m) => m === 'mod_mission_1_firearm')) {
        const firearm = rng.choice(firearms.filter((f) => f.firearm_rate >= 2));
        mission_state.reward.push({
          ty: 'inventory',
          args: {
            ty: 'firearm',
            buy_cost: firearm.buy_cost,
            sell_cost: firearm.sell_cost,
            firearm,
            name: firearm.firearm_name,
          }
        });
      }
      if (mission_state.mission.modifier.find((m) => m === 'mod_mission_2_equipment')) {
        const equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate > 0));
        mission_state.reward.push({
          ty: 'inventory',
          args: {
            ty: 'equipment',
            buy_cost: equipment.buy_cost,
            sell_cost: equipment.sell_cost,
            equipment,
            name: equipment.vest_name,
          }
        });
      }
      if (mission_state.mission.modifier.find((m) => m === 'mod_mission_3_throwable')) {
        const throwable = rng.choice(throwables.slice(1));
        mission_state.reward.push({
          ty: 'inventory',
          args: {
            ty: 'throwable',
            buy_cost: throwable.buy_cost,
            sell_cost: throwable.sell_cost,
            throwable,
            name: throwable.throwable_name,
          }
        });
      }

      if (rng.range(0, 1) <= mission_state.mission.drop_item_prob) {
        const { drop_item_tier, drop_item_ty } = mission_state.mission;

        const add_firearm_to_reward = (firearm_ty, firearm_rate) => {
          const firearm = rng.choice(firearms.filter((f) => f.firearm_ty === firearm_ty && f.firearm_rate === firearm_rate));
          if (firearm) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'firearm',
                buy_cost: firearm.buy_cost,
                sell_cost: firearm.sell_cost,
                firearm,
                name: firearm.firearm_name,
              }
            });
          }
        }

        const add_equipment_to_reward = (vest_rate) => {
          const equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === vest_rate));
          if (equipment) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'equipment',
                buy_cost: equipment.buy_cost,
                sell_cost: equipment.sell_cost,
                equipment,
                name: equipment.vest_name,
              }
            });
          }
        }

        const add_throwable_to_reward = (throwable_rate) => {
          const throwable = rng.choice(throwables.filter((t) => t.throwable_rate === throwable_rate));
          if (throwable) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'throwable',
                buy_cost: throwable.buy_cost,
                sell_cost: throwable.sell_cost,
                throwable,
                name: throwable.throwable_name,
              }
            });
          }
        }

        if (drop_item_ty) {
          if (FIREARM_TYPES.includes(drop_item_ty)) {
            add_firearm_to_reward(drop_item_ty, drop_item_tier);
          }
          else if (drop_item_ty === 'equipment') {
            add_equipment_to_reward(drop_item_tier);
          }
          else if (drop_item_ty === 'throwable') {
            add_throwable_to_reward(drop_item_tier);
          }
        }
        else {
          const types = [
            { weight: 5, ty: 'firearm' },
            { weight: 4, ty: 'equipment' },
            { weight: 1, ty: 'throwable' },
          ];
          const ty = rng.weighted_key(types, 'weight').ty;
          if (ty === 'firearm') {
            add_firearm_to_reward(rng.choice(FIREARM_TYPES), drop_item_tier);
          }
          else if (ty === 'equipment') {
            add_equipment_to_reward(drop_item_tier);
          }
          else if (ty === 'throwable') {
            add_throwable_to_reward(drop_item_tier);
          }
        }
      }

      // TODO: 세력 점유율 처리
      const { idxcenter, client, target } = mission_state.mission;
      if (!mission_state.mission.milestone) {
        this.world.centerUpdateWeights(rng, idxcenter, client, target);
      }
    } else {
      // 미션실패
      let point = -Math.round(Math.abs(mission_state.mission.renown_gain * MISSION_LOSE_RENOWN_MULT));
      mission_state.reward.push({ ty: 'renown', args: { point, reason: 'mission_fail' } });

      if (mission_state.mission.milestone) {
        this.milestone_missions.push(mission_state.mission);
      }
    }

    const { entities, trails } = sim;
    const killsAll = trails.filter((t) => t.source.team === 0 && t.kill);

    const eliminated = !mission_agents.find((agent) => {
      const entity = entities.find((e) => e.idx === agent.idx);
      if (!entity) {
        return false;
      }
      return entity.life > 0;
    });

    let downs = 0;
    const targetItems = [];

    const decreaseDurability = (agent, item, ty, index) => {
      if (item.durability > 0) {
        item.durability -= 1;
        if (item.durability <= 0) {
          targetItems.push({ agent, ty, index });
        }
      }
    }

    for (const agent of mission_agents) {
      const entity = entities.find((e) => e.idx === agent.idx);
      if (!entity) {
        continue;
      }
      if (entity.life === 0) {
        downs += 1;
      }

      decreaseDurability(agent, agent.firearm, 'firearm', 0);
      decreaseDurability(agent, agent.equipment, 'equipment', 0);
      for (let i = 0; i < agent.throwables.length; i++) {
        const throwable = agent.throwables[i];
        decreaseDurability(agent, throwable, 'throwable', i);
      }
    }

    if (targetItems.length > 0) {
      mission_state.reward.push({ ty: 'destroyItems', args: { items: targetItems } });
    }

    for (const agent of mission_agents) {
      const entity = entities.find((e) => e.idx === agent.idx);
      if (!entity) {
        throw new Error('failed to find mission agent');
      }
      if (entity.temporary && entity.name) {
        this.names.unshift(entity.name);
        continue;
      }

      let args = { agent };

      if (win) {
        // aiden-2: 성공 보수
        const amount = Math.round(-1 * resource * agent.contract.shareRatio);
        if (amount) {
          mission_state.reward.push({ ty: 'resource', args: { ty: 'share', resource: amount, agents: [agent] } });
        }

        // 임무 성공시 참여 agent 성장
        args = agentGrowth(rng, agent, 'mission');
      }

      args.exp = agentMissionExp(agent, {
        kills: killsAll.filter((t) => t.source === entity).length,
        damage_done: _.sum(trails.filter((t) => t.source === entity && t.hit).map((t) => t.damage)),
        win,
        mission_exp: mission_state.mission.exp,
        agents_count: mission_agents.length,
      });

      args.stamina = agent.stamina - 1;

      const { mission_stats } = agent;
      mission_stats.count += 1;
      if (win) {
        mission_stats.wins += 1;
        agent.mission_stats.renowns += Math.max(0, mission_state.mission.renown_gain);
        mission_stats.contributions += Math.floor(resource / mission_agents.length);
      } else {
        mission_stats.loses += 1;
      }

      if (entity.life > 0) {
        agent.mission_stats.teamdowns += downs;
      }

      mission_stats.kills += killsAll.filter((t) => t.source === entity).length;
      mission_stats.damage_done.push(_.sum(trails.filter((t) => t.source === entity && t.hit).map((t) => t.damage)));
      mission_stats.damage_taken.push(_.sum(trails.filter((t) => t.target === entity && t.hit).map((t) => t.damage)));

      if (permadeath && entity.life <= 0) {
        mission_state.reward.push({ ty: 'deadAgent', args: { agent, destroyItem: !win } });

        const { turnCost, shareRatio } = agent.contract;
        let point = -Math.round((turnCost + shareRatio * 5000) * 0.01);
        mission_state.reward.push({ ty: 'renown', args: { point, reason: 'deadAgent', idx: agent.idx } });
      }

      // 휴식
      if (agent.life !== entity.life && entity.life > 0) {
        const b_recover = world.facilitiesByKey('medic');
        const recover_mult = 0.1 + b_recover[1] * 0.1;

        const missionIdx = mission_state?.mission?.idxcenter;
        let mission_recover_mult = 0;
        if (missionIdx) {
          const { world } = this;
          const { storage } = world;
          const s = storage[missionIdx].centerstate;

          mission_recover_mult = agent.life_max * s.effects.mission_recover_mult;
        }

        args.life = Math.min(agent.life_max, entity.life + agent.life * recover_mult + mission_recover_mult);
      }

      if (win) {
        if (!agent.contract.agenda && rng.range(0, 1) > 0.9) {
          args.agenda = rng.choice(agendakeys);
        }
      }

      mission_state.reward.push({ ty: 'agent', args });

      agent.contract.mission++;
      agent.state = null;
    }

    this.mission_state = { ...mission_state, sim, res };

    if (win) {
      this.state = 'reward';
      this.mission_state.reward_choices = this.missionRewardChoices(this.mission_state);
    } else {
      this.state = 'result';
    }
  }

  marketListingPurchase(item, cost) {
    if (this.resources.resource < cost) {
      return false;
    }

    this.market_listings = this.market_listings.filter((i) => i !== item);
    delete item.expires_at;
    this.inventories.push(item);
    if (item.ty === 'firearm') {
      this.checkQuestPrerequisites('firearm_acquire');
    }
    else if (item.ty === 'equipment') {
      this.checkQuestPrerequisites('armor_acquire');
    }
    this.pushCashbook({ ty: 'market', resource: -cost });
    return true;
  }

  updateAgentListing(areaNum, label_offsets, idx) {
    const { rng, turn, names, global_modifier } = this;

    let listings_other = this.recruit_listings.filter(({ areaNum: n }) => n !== areaNum);
    let listings = this.recruit_listings.filter(({ areaNum: n }) => n === areaNum);

    let listing_count = AGENT_LISTING_MAX + this.researchEffect('recruit_size');

    if (global_modifier.find(({ key }) => key === 'mod_global_4_youtuber')) {
      listing_count -= 1;
    }
    if (global_modifier.find(({ key }) => key === 'mod_global_11_platform_labor')) {
      listing_count += 2;
    }

    const label_offset = this.researchEffect('recruit_overall');

    const name = names.pop();
    const agent = createAgent(rng, name, this.agent_idx++,
      { areaNum, turn, global_modifier, label_offset, label_offsets });
    agent.world_idx = idx;

    agent.life_max = Math.round(agent.stats2.toughness * 6 + 16);
    agent.life = agent.life_max;

    if (global_modifier.find((m) => m.key === 'mod_global_10_high_manpower')) {
      agent.power += 1;
    }

    const listing = {
      ty: 'agent',
      areaNum,
      idx,
      agent,
      expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    }
    listings.push(listing);

    while (listings.length > listing_count) {
      const [item] = listings.splice(0, 1);
      if (item.agent.name) {
        names.unshift(item.agent.name);
      }
    }

    this.recruit_listings = listings_other.concat(listings);
    return listing;
  }

  agentListingCost(areaNum) {
    return AGENT_LISTING_COST(areaNum) * (1 + this.researchEffectP('recruit_refresh_cost'));
  }

  agentListingPurchase(agent) {
    // 이미 구매한 경우 적당히 처리
    if (this.agents.find((a) => a.idx === agent.idx)) {
      return;
    }

    this.recruit_listings = this.recruit_listings.filter(({ agent: a }) => a.idx !== agent.idx);

    const agents_cur = this.agents.filter((a) => a.world_idx === agent.world_idx);
    const agents_limit = this.baseAgentsCount(agent.world_idx);
    if (agent.world_idx <= 0 || agents_limit <= agents_cur.length) {
      agent.world_idx = 0;
    }

    delete agent.portrait;
    this.agents.push(agent);
    this.names = this.names.filter((n) => n !== agent.name);
    this.pushJournal('market', agent.name + '와(과) 계약했습니다.');
    this.checkQuestPrerequisites('agents_count');
  }

  missionSelectReward(ty, val) {
    const { names, rng, state, global_modifier, agents_selected, turn, mission_state } = this;
    if (state !== 'reward') {
      return;
    }

    let agent_idx = this.agent_idx;

    switch (ty) {
      case 'agent':
        const name = names.pop();
        const potential = pot.sample(rng, mission_state.mission.area.num);
        const power = val;
        const areaNum = mission_state.mission.area.num;
        const agent = createAgent(rng, name, agent_idx++,
          { power, potential, areaNum, turn: turn + 1, global_modifier });
        agent.world_idx = mission_state.mission.idx;

        this.agent_idx = agent_idx;

        if (mission_state.mission.modifier.find((m) => m === 'mod_mission_5_elite')) {
          agent.perks.point++;
        }

        mission_state.reward.push({ ty: 'getAgent', args: agent });
        break;
      case 'firearm':
        const firearm = rng.choice(firearms.filter((f) => f.firearm_rate === val));
        if (global_modifier.find((m) => m.key === 'mod_global_5_excess_firearm')) {
          for (let i = 0; i < 2; i++) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'firearm',
                buy_cost: firearm.buy_cost,
                sell_cost: firearm.sell_cost,
                firearm: { ...firearm },
                name: firearm.firearm_name,
              }
            });
          }
        } else {
          mission_state.reward.push({
            ty: 'inventory',
            args: {
              ty: 'firearm',
              buy_cost: firearm.buy_cost,
              sell_cost: firearm.sell_cost,
              firearm,
              name: firearm.firearm_name,
            }
          });
        }
        break;
      case 'equipment':
        const equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === val));
        if (global_modifier.find((m) => m.key === 'mod_global_6_excess_equipment')) {
          for (let i = 0; i < 2; i++) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'equipment',
                buy_cost: equipment.buy_cost,
                sell_cost: equipment.sell_cost,
                equipment: { ...equipment },
                name: equipment.vest_name,
              }
            });
          }
        } else {
          mission_state.reward.push({
            ty: 'inventory',
            args: {
              ty: 'equipment',
              buy_cost: equipment.buy_cost,
              sell_cost: equipment.sell_cost,
              equipment,
              name: equipment.vest_name,
            }
          });
        }
        break;
      case 'throwable':
        const throwable = rng.choice(throwables.filter((t) => t.throwable_name === val));
        if (global_modifier.find((m) => m.key === 'mod_global_7_excess_throwable')) {
          for (let i = 0; i < 2; i++) {
            mission_state.reward.push({
              ty: 'inventory',
              args: {
                ty: 'throwable',
                buy_cost: throwable.buy_cost,
                sell_cost: throwable.sell_cost,
                throwable: { ...throwable },
                name: val,
              }
            });
          }
        } else {
          mission_state.reward.push({
            ty: 'inventory',
            args: {
              ty: 'throwable',
              buy_cost: throwable.buy_cost,
              sell_cost: throwable.sell_cost,
              throwable,
              name: val,
            }
          });
        }
        break;
      case 'resource':
        mission_state.reward.push({ ty: 'resource', args: { ty: 'select', resource: val, agents: agents_selected } });
        break;
      case null:
        break;
      default:
        throw new Error('not implemented', ty);
    }

    this.state = 'event';
  }

  missionSelectEvent(e) {
    const { names, state, rng, agents, turn, mission_state, global_modifier } = this;
    if (state !== 'event') {
      return null;
    }

    let agent_idx = this.agent_idx;
    let term = (e?.length ?? 0) * TICK_PER_MONTH;

    if (e === null) {
    } else if (e.ty === 'global') {
      mission_state.reward.push({ ty: 'globalModifier', args: { term, key: e.modifier[0] } })
    } else if (e.ty === 'resource') {
      if (e.amount) {
        mission_state.reward.push({ ty: e.ty, args: { ty: 'event', resource: e.amount } });
      }
    } else if (e.ty === 'new_agent') {
      const name = names.pop();
      const power = e.amount;
      const power_offset = this.researchEffect('reward_merc_overall');
      const potential = pot.sample(rng, mission_state.mission.area.num);
      const areaNum = mission_state.mission.area.num;
      const agent = createAgent(rng, name, agent_idx++,
        { power, power_offset, potential, areaNum, turn: turn + 1, global_modifier });
      this.agent_idx += 1;

      if (agendakeys.includes(e.modifier[0])) {
        agent.contract.agenda = e.modifier;
      } else {
        agent.modifier.push({ key: e.modifier, start: turn, term });
      }
      mission_state.reward.push({ ty: 'getAgent', args: agent });
    } else if (e.ty === 'next_mission') {
      mission_state.reward.push({ ty: 'mission', args: { modifier: e.modifier } });
    } else if (e.ty === 'agents') {
      let selectAgent = [];
      let cnt = 0;
      while (selectAgent.length < Math.min(e.amount, agents.length) && cnt < 100) {
        cnt++;
        const idx = rng.choice(agents).idx;
        if (selectAgent.includes(idx)) {
          continue;
        }
        selectAgent.push(idx);
        const agent = agents.find((a) => a.idx === idx);
        mission_state.reward.push({ ty: 'agent', args: { agent, modifier: e.modifier, turn, term } });
      }
    } else if (e.ty === 'all_missions') {
      mission_state.reward.push({ ty: 'missionAll', args: { modifier: e.modifier, term } });
    } else if (e.ty === 'next_milestone_mission') {
      mission_state.reward.push({ ty: 'missionMilestone', args: { modifier: e.modifier } });
    } else if (e.ty === 'mission_agents') {
      let selectAgent = [];
      let cnt = 0;
      while (selectAgent.length < Math.min(e.amount, mission_state.agents.length) && cnt < 100) {
        cnt++;
        const idx = rng.choice(mission_state.agents).idx;
        const agent = agents.find((a) => a.idx === idx);
        if (selectAgent.includes(idx) || agent.state) {
          continue;
        }
        selectAgent.push(idx);
        mission_state.reward.push({ ty: 'agent', args: { agent, modifier: e.modifier, turn, term } });
      }
    } else if (e.ty === 'remove_global') {
      mission_state.reward.push({ ty: 'removeGlobal', args: e.modifier[0] });
    } else if (e.ty === 'heal') {
      mission_state.reward.push({ ty: 'heal' });
    } else if (e.ty === 'remove_agent') {
      mission_state.reward.push({ ty: 'removeAgentModifier', args: { agent: e.agent, modifier: e.modifier[0] } });
    } else if (e.ty === 'resource_per_agent') {
      mission_state.reward.push({ ty: 'resource', args: { ty: 'event', resource: agents.length * e.amount } });
    } else if (e.ty === 'renown') {
      mission_state.reward.unshift({ ty: 'renown', args: { level: 1 } });
    } else if (e.ty === 'remove_missions') {
      mission_state.reward.push({ ty: 'removeMissions' })
    } else if (e.ty === 'remove_markets') {
      mission_state.reward.push({ ty: 'removeMarkets' })
    } else if (e.ty === 'none') {
    } else {
      throw new Error(`unknown event: ${e.ty}`);
    }

    if (e?.cost) {
      mission_state.reward.push({ ty: 'resource', args: { ty: 'event', resource: -e.cost } });
    }

    this.state = 'result';
  }

  baseList() {
    const { world, agents } = this;
    const bases = world.centers.map((c) => {
      const { centerstate } = world.storage[c.idx];
      if (!centerstate.office) {
        return null;
      }
      return { idx: c.idx, agents: agents.filter((a) => a.world_idx === c.idx) };
    }).filter((s) => s !== null);
    return bases;
  }

  curAgents() {
    const { selected_world_idx } = this;
    return this.agents.filter((a) => a.world_idx === selected_world_idx);
  }

  get agents_selected() {
    const { world_idx } = this;
    return this.agents.filter((a) => {
      return a.world_idx === world_idx && a.state === null && this.agentAvail(a)
    });
  }

  // <barrack>
  get barrack_cap() {
    const { world } = this;
    return 10 + _.sum(world.facilitiesByKey('people'));
  }

  get barrack_usage() {
    const { agents } = this;
    return agents.length;
  }

  get barrack_vacancy() {
    const { barrack_usage, barrack_cap } = this;
    return barrack_cap - barrack_usage;
  }

  maybeShowPopup() {
    // show popups
    if (this.mission_state === null && this.pending_popups.length > 0) {
      let [popup] = this.pending_popups.splice(0, 1);
      this.onShowPopup(popup);
    }
  }

  onShowPopup({ ty, msg }) {
    if (ty) {
      this.state = ty;
      this.popup_msg = msg;
    } else {
      this.state = 'result';

      this.mission_state = { res: 2, reward: [] };
      this.mission_state.reward.push({ ty: 'ment', args: msg });
      this.mission_selected = null;
    }

  }

  onEnqueuePopup(msg) {
    this.pending_popups.push({ msg });
  }

  onEnqueueTyPopup(ty, msg) {
    this.pending_popups.push({ ty, msg });
  }

  // sync facility
  onFacilityChanged(prev, next) {
    // sync training
    {
      let slots = this.slots.slice();

      const prev_training_advanced = prev.key.startsWith('training_advanced');
      const next_training_advanced = next.key.startsWith('training_advanced');
      const prev_training_firearm = prev.key.startsWith('training_firearm');
      const next_training_firearm = next.key.startsWith('training_firearm');

      const slots0 = slots.filter((f) => Math.floor(f.idx / 10) === prev.fid);

      const advanced_level = (0 | (next.key.slice('training_advanced'.length)));
      const firearm_level = (0 | (next.key.slice('training_firearm'.length)));

      // advanced
      if (!prev_training_advanced && next_training_advanced) {
        slots.push(createSlot(next.fid * 10 + 0, "perk", advanced_level));
      }
      if (prev_training_advanced && !next_training_advanced) {
        for (const slot of slots0) {
          slotCancel(slot);
        }
        slots = slots.filter((d) => Math.floor(d.idx / 10) !== prev.fid);
      }
      if (prev_training_advanced && next_training_advanced) {
        for (const slot of slots0) {
          slot.level = advanced_level;
        }
      }

      // firearm
      if (!prev_training_firearm && next_training_firearm) {
        slots.push(createSlot(next.fid * 10 + 0, "aptitude", firearm_level));
      }
      if (prev_training_firearm && !next_training_firearm) {
        for (const slot of slots0) {
          slotCancel(slot);
        }
        slots = slots.filter((d) => Math.floor(d.idx / 10) !== prev.fid);
      }
      if (prev_training_firearm && next_training_firearm) {
        for (const slot of slots0) {
          slot.level = firearm_level;
        }
      }

      this.slots = slots;
      this.updateSlotsEffects();

      const upgrading = prev.state === 'upgrading' && prev.next === next.key;
      const next_facility = data_facilities.facilities.find((f) => f.key === next.key);
      const next_department_needed = next_facility.staff_heads + next_facility.staffs > 0;

      const { departmentRoot, turn } = this;

      const prev_department = departmentRoot.departments.find((d) => d.key === prev.key && d.fid === prev.fid);

      let needNotification = false;
      if (prev_department) {
        if (upgrading) {
          departmentRoot.upgradeDepartment(prev_department, next_facility);
        }
        else {
          departmentRoot.breakDepartment(prev_department);
        }

        needNotification = true;
      }
      if (next_department_needed && (!upgrading || !prev_department)) {
        const next_department = createDepartmentFromFacility(next.key, next.fid);
        departmentRoot.departments.push(next_department);

        needNotification = true;
      }
      if (needNotification) {
        const ev = { turn, ty: 'department' };
        this.notificationAddPending(ev);
      }
    }

    const idx = Math.floor(prev.fid / 10);
    // effect
    const updated = (prev.key !== next.key || prev.enabled !== next.enabled);
    if (updated) {
      const next_result = next.enabled ? data_facilities.facilityByKey(next.key).result : null;
      const prev_result = prev.enabled ? data_facilities.facilityByKey(prev.key).result : null;
      if (next_result) {
        for (const effect of next_result.effects) {
          this.facilityApplyEffect(idx, next.fid, effect);
        }
      }
      if (prev_result) {
        for (const effect of prev_result.effects) {
          this.facilityRevertEffect(idx, prev.fid, effect, next_result?.effects ?? []);
        }
      }
    }

    this.triggerQuest('finish_build_facility', next.key);
  }

  updateSlotsEffects() {
    const world = this.world;

    // sync training
    const slots = this.slots.map((slot) => {
      // ToDo: 시설 별로 어떤 시설에서 보너스 받았는지 알수있으어야할거같은디...
      const fid = Math.floor(slot.idx / 10);
      const cid = Math.floor(fid / 10);

      /*
      const train_duration_all = this.researchEffectP('train_duration_all');
      const train_efficiency_all = this.researchEffectP('train_efficiency_all');
      const train_efficiency_area = this.researchEffectP(`train_efficiency_${areaIdx + 1}`);
      const train_efficiency_physical = this.researchEffectP('train_efficiency_physical');
      */

      const train_efficiency_gym = [0, 0.2, 0.4][world.facilityMaxByCenterKey(cid, 'gym')];
      const train_efficiency_training_adjacent = [0, 0.2, 0.4][world.facilityMaxBonusByKey(cid, 'training_adjacent')];


      let effects = [];
      if (train_efficiency_gym) {
        effects.push({ key: 'train_efficiency_gym', efficiency: train_efficiency_gym });
      }
      if (train_efficiency_training_adjacent) {
        effects.push({ key: 'train_efficiency_training_adjacent', efficiency: train_efficiency_training_adjacent });
      }

      /*
      if (train_duration_all) {
        effects.push({ key: 'train_duration_all', duration: train_duration_all });
      }
      if (train_efficiency_all) {
        effects.push({ key: 'train_efficiency_all', efficiency: { all: train_efficiency_all } });
      }
      if (train_efficiency_physical) {
        effects.push({ key: 'train_efficiency_physical', efficiency: { stat: { toughness: train_efficiency_physical } } });
      }
      */

      slot.effects = effects;
      return slot;
    });

    this.slots = slots;
  }

  onTickSlots() {
    const { slots, turn, agents, instructors } = this;

    this.updateSlotsEffects();
    const events = tickSlots(slots, turn, agents, instructors);

    let pause = false;

    for (const ev of events) {
      if (ev.slot.training === null) {
        // 끝난 훈련 목록입니다
        //  - 한 번 보낸 훈련이 끝났을 때
        //  - 반복 훈련이 취소되었을 때
        //  - 반복 훈련을 더 진행할 수 없을 때 (스텟이 꽉 찬 경우 등)
        pause |= this.onTrainEndEvent(ev);
      }
      if (ev.result && ev.result.agent) {
        agentChangeApply(ev.result.agent, ev.result);
      }
      this.onTrainCompleteEvent(ev);
    }

    if (slots.length > 0) {
      const date = dayjs(tickToDate(this.turn));
      switch (date.month() + 1) {
        case 1:
        case 4:
        case 7:
        case 10:
          if (date.date() === 1 && date.hour() === 0 && date.minute() === 0 && date.second() === 0) {
            const summary = generateSummary(this.trainingResults);
            this.trainingResults = [];
            this.onEnqueueTyPopup('training_preview', summary);
            // 팝업이 나오면서 게임이 멈춰야 합니다.
            pause = true;
          }
          break;
        default:
          break;
      }
    }

    return pause;
  }

  forceUpdateFacility(idx, f, key) {
    const { world } = this;
    const { centerstate: { facilities } } = world.storage[idx];
    const index = facilities.indexOf(f);

    const next = {
      ...facilities[index],
      key,
    };

    this.onFacilityChanged(facilities[index], next);
    facilities[index] = next;
  }

  onTickWorld() {
    const { world, turn } = this;

    let updated = false;
    let paused = false;
    for (const center of world.centers) {
      const { centerstate: { name, facilities } } = world.storage[center.idx];

      for (let i = 0; i < facilities.length; i++) {
        const f = facilities[i];
        if (f.state === 'upgrading' && f.ends_at <= turn) {
          const next = {
            ...f,
            key: f.next,
            state: 'idle',
          };

          facilities[i] = next;
          this.onFacilityChanged(f, next);
          const f_name = data_facilities.facilities.find(({ key }) => f.key === key).name;
          const next_name = data_facilities.facilities.find(({ key }) => next.key === key).name;

          updated = true;
          paused |= this.pushJournal('facility_upgrade', `${L(name)} - ${L(f_name)} 시설이 ${L(next_name)} 시설로 업그레이드되었습니다.`, turn);
        }
      }
    }

    if (updated) {
      this.syncWorldmap();
    }

    return paused;
  }

  lifeRecoverMult(agent) {
    const { world } = this;
    const b_recover = world.facilitiesByKey('medic');

    let cost = COST_PER_HEAL;
    let mult0 = 1;
    if (agent.contract.option.advanced) {
      mult0 = 1.5;
      cost = COST_PER_HEAL * 3;
    }
    mult0 += _.sum(b_recover) * 0.05;

    const mult = lifeRecoverMultiplier(agent, mult0);
    cost *= (1 + this.researchEffectP('hp_cost'));

    return { mult, cost };
  }

  costRecover(agent) {
    const { cost: cost_per_heal, mult: recoverMult } = this.lifeRecoverMult(agent);

    // 임무 종료 후 체력이 최대값이 10% 아래로 떨어지지 않습니다.
    const days = Math.log(10) / Math.log(recoverMult);

    return {
      days,
      cost_per_heal,
    };
  }

  onTickAgent(agent) {
    const { turn, world, slots, effects } = this;

    let paused = false;

    agent.mission_stats.ticks += 1;

    // if (turn % TICK_PER_DAY === 0) {
    //   // 서순

    //   // TODO: nakwon: 훈련 게이지의 일별 변화: decay
    //   const stats2_decay_delta = agentStats2GaugeDecayInfo(agent, tickToAge(turn, agent.born_at));
    //   const stats2_gauge_delta = mergeStats2GaugeDelta(agent, tickToAge(turn, agent.born_at), [stats2_decay_delta.default, stats2_decay_delta.ageing]).delta;
    //   applyAgentStats2GaugeDelta(agent, stats2_gauge_delta, tickToAge(turn, agent.born_at));
    //   // TODO: nakwon: 훈련 게이지에 따른 일별 스텟 변화
    //   //const stats2_deltaInfo = agentStats2ChangeInfo(agent);
    //   const slot = slots.find(s => s.training?.agentIdx === agent.idx);
    //   const training = slot?.training;
    //   if (!training) {
    //     const stats2_deltaInfo = agentStats2TrainingInfo(agent, training);
    //     const stats2_delta = {};
    //     for (const key in stats2_deltaInfo.stats2) {
    //       stats2_delta[key] = stats2_deltaInfo.stats2[key].delta.sum;
    //     }
    //     const power_delta = stats2_deltaInfo.overall.delta.sum;
    //     agentChangeApply(agent, { power: power_delta, stats2: stats2_delta });
    //   }
    // }

    if (agent.relocate_at <= turn && agent.state === 'relocate') {
      agent.state = null;
    }

    if (turn % TICK_PER_DAY === 0 && agent.life < agent.life_max && agent.state === null) {
      const life_before = agent.life;

      let { cost: cost_per_heal, mult: recoverMult } = this.lifeRecoverMult(agent);
      if (!this.agentAvail(agent)) {
        recoverMult += this.researchEffectP('hp_unable_recover');
      }
      if (agent.world_idx) {
        const { storage } = world;
        const s = storage[agent.world_idx].centerstate;
        recoverMult += s.effects.recover_mult;
      }
      recoverMult += this.effects.recover_mult;

      let recoverAmount = Math.min(agent.life_max, agent.life * recoverMult) - agent.life;
      agent.life = Math.min(agent.life_max, agent.life * recoverMult);

      const life_diff = agent.life - life_before;

      agent.mission_stats.recovers += recoverAmount;
      agent.mission_stats.recover_days += 1;
      agent.mission_stats.recover_cost += Math.floor(life_diff * cost_per_heal);

      // this.pushCashbook({ ty: 'extra', resource: -Math.floor(life_diff * cost_per_heal), agents: [agent] });
      // if (agent.life >= agent.life_max) {
      //   paused |= this.pushJournal('agent_recover', `${agent.name}의 부상이 모두 치료되었습니다.`, turn);
      // }
    }

    if (turn % TICK_PER_DAY === 0 && agent.stamina < agent.stamina_max && agent.state === null) {
      const stamina_recover_mult = effects.stamina_recover_mult ?? 1;
      agent.stamina = Math.min(agent.stamina_max, agent.stamina + agent.stamina_regen_per_day * stamina_recover_mult);
    }

    agent.modifier = agent.modifier.filter(({ start, term }) => { return start + term >= turn });

    return paused;
  }

  notificationAddPending(pending) {
    this.notifications = this.notifications.filter((p) => p.ty !== pending.ty);
    this.notifications.push(pending);
  }

  researchEffect(key) {
    return this.progress.effects_acc[key] ?? 0;
  }

  researchEffectP(key) {
    return (this.progress.effects_acc[key] ?? 0) / 100.0;
  }

  onResearchEffectClaim(effect) {
    const { turn } = this;
    switch (effect.key) {
      case 'renown_once':
        this.renownIncr(effect.value);
        break;
      case 'cash_once':
        this.pushCashbook({ ty: 'event', resource: effect.value, agents: [] });
        break;
      case 'milestone_weaken':
        let mission = this.milestone_missions.find((m) => m.mission_level === effect.value);
        mission.modifier.push(`mod_mission_8_weaken`);
        break;
      case 'mod_global_boom':
        this.global_modifier.push({
          key: 'mod_global_1_boom',
          start: turn,
          term: TICK_PER_MONTH * effect.value,
        });
        break;
      case 'mod_personal_morale_all':
        for (const agent of this.agents) {
          agent.modifier.push({
            key: 'mod_agent_6_morale_high',
            start: turn,
            term: TICK_PER_MONTH * effect.value,
          });
        }
        break;
      case 'branch_open':
        this.areas[effect.value - 1].locked = false;
        break;
      case 'milestone_open':
        // TODO: 데이터를 고칠 것
        this.appendMilestoneMission(effect.value - 1, true);
        break;
      default:
        throw new Error(`unknown effect.key=${effect.key}`);
    }

    this.progress.onEffectClaim(effect);
  }

  onResearchResult(res) {
    if (res.length === 0) {
      return;
    }

    let msg = `research finished\n`;
    for (const { title, descr, effects } of res) {
      msg += `${title}: ${descr}\n보상: `;
      msg += effects.map((eff) => {
        return ResearchEffectLabel({ effect: eff });
      }).join(', ');
    }

    this.onEnqueuePopup(msg);
  }

  researchConditionMax(key, value) {
    let res = this.progress.conditionMax(key, value);
    this.onResearchResult(res);
    this.maybeShowPopup();
  }

  newCount(ty) {
    if (ty === 'market') {
      return this.newItemsCount();
    }
    if (ty === 'recruit') {
      return this.newRecruitCount();
    }
    return 0;
  }

  newItemsCount() {
    const { effects } = this;

    let count = 0;
    for (const [key, value] of Object.entries(effects.interval_items)) {
      count += value;
    }

    return count;
  }

  appendNewItems() {
    let { rng, global_modifier, nextItemId, turn } = this;
    const { effects } = this;

    let items = [];
    for (const [key, value] of Object.entries(effects.interval_items)) {
      for (let i = 0; i < value; i++) {
        const ty = rng.choice(['firearm', 'equipment', 'throwable']);
        const item = createMarketItemTy(rng, ty, key - 1, 0, global_modifier);
        item.id = nextItemId++;
        item.expires_at = turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY;
        this.market_listings.push(item);
        items.push(item);
      }
    }

    this.checkQuestPrerequisites('market_item');

    this.nextItemId = nextItemId;
    return items;
  }

  newRecruitCount() {
    const { effects } = this;

    let count = 0;
    for (const [key, value] of Object.entries(effects.interval_agents)) {
      count += value;
    }

    return count;
  }

  appendNewRecruitListings() {
    const { rng, world, turn, effects } = this;

    const idx = world.centers.find((c) => world.storage[c.idx].centerstate.office0)?.idx;

    const b_recruit = 0;
    const b_launge = 0;

    const recruits = [];
    for (const [key, value] of Object.entries(effects.interval_agents)) {
      for (let i = 0; i < value; i++) {
        // potential, growth, physical
        let offsets = [0, 0, 0];
        if (b_recruit === 1) {
          if (rng.range(0, 1) < 0.25) {
            offsets[2] += 1;
          }
        }
        if (b_recruit === 2) {
          if (rng.range(0, 1) < 0.25) {
            offsets[1] += 1;
          }
          if (rng.range(0, 1) < 0.5) {
            offsets[2] += 1;
          }
        }

        if (b_launge > 1) {
          offsets[0] += 1;
        }

        const listing = this.updateAgentListing(key - 1, offsets, idx);
        recruits.push(listing);

        if (b_launge > 0 && rng.range(0, 1) < 0.3) {
          let term = TICK_PER_MONTH * 3;
          agent.modifier.push({ key: 'mod_agent_6_morale_high', start: turn, term });
        }
      }
    }
    return recruits;
  }

  centerSelect(idx) {
    const { world } = this;
    const { centerstate } = world.storage[idx];
    if (!centerstate.office) {
      return;
    }

    this.baseSelect(idx);
  }

  onTickMonth() {
    const { turn, agents, world, cashbook, resources, departmentRoot } = this;

    const summary_agents = [];
    const summary_staffs = [];
    const summary_facilities = [];
    const summary_items = [];
    const summary_recruits = [];

    // expanses
    for (const agent of agents) {
      const { turnCost, option } = agent.contract;

      const bonuses = world.facilitiesByKey('people');
      let mult = 1 - bonuses[1] * 0.03;

      if (agent.world_idx) {
        const { storage } = world;
        const s = storage[agent.world_idx].centerstate;
        mult *= s.effects.wage_mult;
      }
      mult *= this.effects.agent_wage_mult

      const recoverCost = agent.mission_stats.recover_cost - agent.mission_stats_last.recover_cost;

      if (recoverCost > 0) {
        this.pushCashbook({ ty: 'extra', resource: -recoverCost, agents: [agent] });
      }

      if (turnCost) {
        this.pushCashbook({ ty: 'contract', resource: -Math.floor(turnCost * mult), agents: [agent] });
      }

      let compensationCost = 0;
      if (option.advanced) {
        compensationCost = Math.floor(CONTRACT_OPTION_ADVANCED_MONTHLY_COST * mult);
        this.pushCashbook({ ty: 'compensation', resource: -compensationCost, agents: [agent] });
      }

      summary_agents.push({
        agent,

        mission_stats: agent.mission_stats,
        mission_stats_last: agent.mission_stats_last,

        recoverCost,
        turnCost,
        compensationCost,
      });

      agent.mission_stats_last = JSON.parse(JSON.stringify(agent.mission_stats));
    }

    // renown. research 관련이라 일단 무시
    {
      this.renownIncr(this.researchEffect('renown_monthly'));

      const month = tickToMonth(turn);
      this.researchConditionMax('monthly_income', turnBalance(month - 1, cashbook));
    }

    //명성
    let { level } = resources.renown;
    let incr = Math.max(0, Math.floor(-1 * Math.pow(4, level)));
    const delta = this.renownIncr(incr);
    const summary_renown = { delta };

    // department
    if (departmentRoot.departments.filter(d => d.key !== 'temp').length > 0) {
      const { storage } = world;
      for (const staff of departmentRoot.staffs) {
        let wage_mult = 1.0;
        if (staff.department_fid >= 0) {
          const idx = Math.floor(staff.department_fid / 10);
          const s = storage[idx].centerstate;
          wage_mult *= s.effects.wage_mult;
        }
        const cost = Math.floor(staff.wage * wage_mult);
        this.pushCashbook({ ty: 'staff', resource: -cost, staffs: [staff] });

        summary_staffs.push({ staff, cost });
      }
    }

    for (const center of world.centers) {
      const { centerstate } = world.storage[center.idx];

      if (centerstate.office) {
        const { facilities } = centerstate;

        for (const facility of facilities) {
          const cost = data_facilities.facilities.find(({ key }) => facility.key === key).maintenance;
          this.pushCashbook({ ty: 'maintenance', resource: -cost, facilities: [facility] });

          summary_facilities.push({ facility, cost });
        }
      }
    }

    for (let i = 1; i < 5; i++) {
      const pending = { var1: i, var2: null, ability: 0, difficulty: 0 };
      const props = this.getDepartmentPendingSuccessProps('search_agent_tier', null, pending);
      props.needPopup = false;
      summary_recruits.push(this.new_recruit(props));
    }

    {
      const pending = { var1: 'hg', var2: this.rng.integer(2, 4), ability: 0, difficulty: 0 };
      const hg_props = this.getDepartmentPendingSuccessProps('source_gun_ty_tier', null, pending);
      hg_props.needPopup = false;
      summary_items.push(this.new_gun(hg_props));
    }

    for (let i = 1; i < 5; i++) {
      for (let j = 0; j < 2; j++) {
        const pending = { var1: i, var2: null, ability: 0, difficulty: 0 };
        const gun_props = this.getDepartmentPendingSuccessProps('source_gun_tier', null, pending);
        gun_props.needPopup = false;
        summary_items.push(this.new_gun(gun_props));
      }
    }

    for (let i = 1; i < 5; i++) {
      for (let j = 0; j < 2; j++) {
        const pending = { var1: i, var2: null, ability: 0, difficulty: 0 };
        const bulletproof_props = this.getDepartmentPendingSuccessProps('source_bulletproof_tier', null, pending);
        bulletproof_props.needPopup = false;
        summary_items.push(this.new_bulletproof(bulletproof_props));
      }
    }

    for (let i = 1; i < 4; i++) {
      for (let j = 0; j < 2; j++) {
        const pending = { var1: i, var2: null, ability: 0, difficulty: 0 };
        const granade_props = this.getDepartmentPendingSuccessProps('source_granade_tier', null, pending);
        granade_props.needPopup = false;
        summary_items.push(this.new_grenade(granade_props));
      }
    }

    this.checkQuestPrerequisites('market_item');

    // monthly events
    summary_items.push(...this.appendNewItems());
    summary_recruits.push(...this.appendNewRecruitListings());

    return {
      summary_agents,
      summary_staffs,
      summary_facilities,
      summary_items,
      summary_recruits,
      summary_renown,
    };
  }

  onTick() {
    let { resources, progress, cashbook, departmentRoot, department_events } = this;
    let paused = false;
    let badge = {};
    const { names } = this;

    if (this.gameover()) {
      return { paused: true, badge };
    }

    if (!this.canProgress()) {
      return { paused: true, badge };
    }

    if (this.turn % TICK_PER_MONTH === TICK_PER_MONTH - 1) {
      const msg = this.onTickMonth();
      this.onEnqueueTyPopup('monthly_report', msg);
    }

    this.turn += 1;
    const turn = this.turn;

    this.triggerQuest('progress_time');

    {
      const onboarding = onboardings.find((o) => o.tick === turn);
      if (onboarding) {
        this.appendOnboardingMission(onboarding);
      }
    }

    if (this.onTickWorld()) {
      paused = true;
    }

    departmentRoot.onTick(this);
    const { finishedPendings } = departmentRoot;
    for (const finishedPending of finishedPendings) {
      paused |= this.onDepartmentPendingFinished(turn, finishedPending, badge);
      finishedPendings.splice(0, finishedPendings.length);
    }

    if (department_events.length > 0) {
      // 팝업이 나오면서 게임이 멈춰야 합니다.
      paused = true;
    }

    const needExpire = (item) => item.expires_at <= turn && !item.blockExpire;

    //시설과 부서 스펙에서는 아이템 & 용병 & 직원 지원자별 타이머 존재
    this.market_listings = this.market_listings.filter((m) => !needExpire(m));

    const recruit_expires = this.recruit_listings.filter((r) => needExpire(r));
    const recruit_remains = this.recruit_listings.filter((r) => !needExpire(r));

    for (const item of recruit_expires) {
      if (item.agent.name) {
        names.unshift(item.agent.name);
      }
    }

    this.recruit_listings = recruit_remains;

    const department_recruit_expires = departmentRoot.recruit_listings.filter((r) => needExpire(r));
    const department_recruit_remains = departmentRoot.recruit_listings.filter((r) => !needExpire(r));

    for (const staff of department_recruit_expires) {
      if (staff.name) {
        names.unshift(staff.name);
      }
    }

    departmentRoot.recruit_listings = department_recruit_remains;

    if (turn % TICK_PER_DAY === 0) {
      // 연구
      const res = progress.onTick(1);
      this.onResearchResult(res);

      // 임무 생성
    }

    this.onTickMissions();

    const agents_dead = [];
    for (const agent of this.agents) {
      const turn0 = turn;
      if (agent.modifier.find((m) => m.key === 'mod_agent_10_drug' && m.start + m.term < turn0)) {
        agents_dead.push(agent);
      }
    }
    for (const agent of agents_dead) {
      if (this.onTickAgent(agent)) {
        paused |= this.agentRemove(agent, false);
      }
    }

    // 에이전트 업데이트
    for (const agent of this.agents) {
      paused |= this.onTickAgent(agent);
    }

    paused |= this.onTickSlots();

    // 지연된 보상
    resources.delayed_pays = resources.delayed_pays.filter(({ ends_at }) => ends_at.unix() > tickToDate(turn).getTime());
    for (const { ty, resource } of resources.delayed_pays) {
      this.pushCashbook({ ty, resource });
    }

    let needSync = false;
    // 임무 만료
    this.missions = this.missions.filter((m) => {
      if (m.expires_at < turn) {
        needSync = true;
        return false;
      }
      return true;
    });

    if (needSync) {
      this.syncWorldmap();
    }

    this.global_modifier = this.global_modifier.filter(({ start, term }) => {
      if (start + term < turn) {
        this.pushJournal('', 'A global modifier expired.', turn);
        // 무슨 모디파이어인지 보여주고 싶음.
        return false;
      }
      return true;
    });

    let pendings = this.pendings.slice();
    pendings.sort((a, b) => a.turn - b.turn);

    while (pendings.length > 0 && pendings[0].turn <= turn) {
      const ev = pendings.splice(0, 1)[0];

      if (ev.ty === 'mission') {
        this.pushJournal('mission', '임무를 시작합니다.', turn);
        // 항상 멈춥니다.
        paused = true;

        if (this.pauseconfig.mission_start) {
          this.state = 'mission_start';
        } else {
          this.state = 'mission';
        }
        this.mission_state = ev.mission_state;
      }
      else if (ev.ty === 'department') {
        this.checkDepartmentDailyWorks(badge);
        const ev0 = { turn: turn + DEPARTMENT_WORK_INTERVAL, ty: 'department' }
        pendings.push(ev0);
        pendings.sort((a, b) => a.turn - b.turn);
      } else {
        throw new Error(`unknown pending.ty=${ev.ty}`);
      }
    }

    this.pendings = pendings;

    this.onTickQuest();

    this.maybeShowPopup();

    return { paused, badge };
  }

  checkDepartmentDailyWorks(badge) {
    const { departmentRoot } = this;

    for (const department of departmentRoot.departments) {
      for (const pending of department.pendings) {
        if (pending.work_effect_ty === 'everyday') {
          const success_rate = pending.ability / pending.difficulty;
          if (departmentRoot.rng.range(0, 1) <= success_rate) {

            this.onDepartmentPendingSuccess(pending.work_key, department, pending, badge);
          }
          else {
            this.onDepartmentPendingFail(pending.work_key, department, pending, badge);
          }
        }
      }
    }
  }

  onDepartmentPendingFinished(turn, pending, badge) {
    const { departmentRoot } = this;
    const { work_key, ability, var1, var2, difficulty, repeatOption, stopTimerOption, department, work_effect_ty } = pending;
    const { rng, logs } = departmentRoot;

    const success_rate = ability / difficulty;

    const log = { turn, department_name: department.name, pending, growths: [] };

    let repeatWork = true;
    let stopTimer = false;
    if (repeatOption === 'never') {
      repeatWork = false;
    }
    if (repeatOption === 'always') {
      stopTimer = true;
    }

    if (work_effect_ty === 'everyday') {
      log.success = true;
      const growths = departmentRoot.growStaffs(pending);
      log.growths = growths;
      logs.splice(0, 0, log);
    }

    else {
      if (rng.range(0, 1) <= success_rate) {
        //success
        if (repeatOption === 'failed') {
          repeatWork = false;
        }
        else if (repeatOption === 'limit') {
          //TODO: 업무 종료 조건에 따라 반복 여부 결정
          repeatWork = true;
        }

        if (pending.work_effect_ty === 'on_finish') {
          this.onDepartmentPendingSuccess(work_key, department, pending, badge);
        }

        log.success = true;
        const growths = departmentRoot.growStaffs(pending);
        log.growths = growths;
        logs.splice(0, 0, log);
      }
      else {
        //fail
        if (stopTimerOption === 'failed') {
          stopTimer = true;
        }

        if (pending.work_effect_ty === 'on_finish') {
          this.onDepartmentPendingFail(work_key, department, pending, badge);
        }

        log.success = false;
        logs.splice(0, 0, log);
      }
    }

    if (repeatWork) {
      const pending = departmentRoot.createPending(work_key, department, var1, var2);
      department.pendings.push(pending);
    }
    else {
      stopTimer = true;
    }

    if (stopTimer) {
      const work = data_work.find((d) => d.work_key === work_key);
      stopTimer = this.pushJournal('department', `${department.name} 부서가 ${work.work_name} 업무를 마쳤습니다.`, turn);
    }

    this.triggerQuest('finish_department_work', work_key);

    return stopTimer;
  }

  onDepartmentPendingSuccess(work_key, department, pending, badge) {
    pending.total_count++;
    pending.success_count++;

    const props = this.getDepartmentPendingSuccessProps(work_key, department, pending);

    switch (work_key) {
      case 'search_agent_tier':
      case 'search_agent_possible':
        this.new_recruit(props);
        badge['RECRUIT'] = { new_badge: true };
        break;
      case 'recruit_agent':
        this.recruit_with_discount(props);
        break;
      case 'discount_renewal_agent':
        this.discount_agent_value(props);
        break;
      case 'search_staff':
        this.new_staff(props);
        break;
      case 'recruit_staff':
        this.recruit_staff(props);
        break;
      case 'source_gun_general':
      case 'source_gun_ty':
      case 'source_gun_tier':
      case 'source_gun_ty_tier':
        this.new_gun(props);
        badge['MARKET'] = { new_badge: true };
        break;
      case 'source_bulletproof_general':
      case 'source_bulletproof_tier':
        this.new_bulletproof(props);
        badge['MARKET'] = { new_badge: true };
        break;
      case 'source_granade_tier':
        this.new_grenade(props);
        badge['MARKET'] = { new_badge: true };
        break;
      case 'buy_item':
        this.buy_with_discount(props);
        break;
      case 'sell_item':
        this.sell_with_bonus(props);
        break;
      case 'do_training':
        this.train(props);
        break;
      case 'maintain_training':
        this.maintain_training(props);
        break;
      case 'search_contract':
        this.new_contract(props);
        badge['CONTRACTS'] = { new_badge: true };
        break;
      default:
        this.do_nothing(props);
        break;
    }
  }

  onDepartmentPendingFail(work_key, department, pending, badge) {
    pending.total_count++;

    const props = this.getDepartmentPendingFailProps(work_key, department, pending);

    switch (work_key) {
      case 'recruit_agent':
        this.recruit_with_discount(props);
        break;
      case 'buy_item':
        this.buy_with_discount(props);
        break;
      case 'sell_item':
        this.sell_with_bonus(props);
        break;
      case 'search_contract':
        this.new_contract(props);
        badge['CONTRACTS'] = { new_badge: true };
        break;
      default:
        this.do_nothing(props);
        break;
    }
  }

  getDepartmentPendingSuccessProps(work_key, department, pending) {
    const { departmentRoot } = this;
    const { rng } = departmentRoot;
    const { var1, var2, ability, difficulty } = pending;

    switch (work_key) {
      case 'search_agent_tier':
        return { power: 2 + Number(var1) * 4, level: Number(var1) - 1, fid: (department ? department.fid : -1) };
      case 'search_agent_possible':
        return { power: 2 + difficulty * 0.4, level: Math.min(Math.max((difficulty * 0.1) - 1, 0), 3), fid: (department ? department.fid : -1) };
      case 'recruit_agent':
        return { agent: var1, discount: Math.min(0.01 * ability, 0.2) };
      case 'discount_renewal_agent':
        return { agent: var1, discount: Math.min(0.005 * ability, 0.15) };
      case 'search_staff':
        return { power: ability <= 20 ? ability / 2 : (ability <= 45 ? 6 + ability / 5 : (ablility <= 80 ? (15 - 45 / 7) + ability / 7 : 20)) };
      case 'recruit_staff':
        return { staff: var1 };
      case 'source_gun_general':
        // return { ty: rng.choice(['sg', 'smg', 'ar', 'dmr', 'sr']), rates: { start: 1, end: Math.max(1, ability / 10) } };
        return { ty: rng.choice(['sg', 'smg', 'ar']), rates: { start: 1, end: Math.max(1, ability / 10) } };
      case 'source_gun_ty':
        return { ty: var1, rates: { start: 1, end: Math.max(1, ability / 10) } };
      case 'source_gun_tier':
        // return { ty: rng.choice(['sg', 'smg', 'ar', 'dmr', 'sr']), rates: { start: Number(var1), end: Number(var1) } };
        return { ty: rng.choice(['sg', 'smg', 'ar']), rates: { start: Number(var1), end: Number(var1) } };
      case 'source_gun_ty_tier':
        return { ty: var1, rates: { start: Number(var2), end: Number(var2) } };
      case 'source_bulletproof_general':
        return { rates: { start: 1, end: Math.max(1, ability / 10) } };
      case 'source_bulletproof_tier':
        return { rates: { start: Number(var1), end: Number(var1) } };
      case 'source_granade_tier':
        return { name: var1 };
      case 'buy_item':
        return { item: var1, discount: Math.min(0.01 * ability, 0.2) };
      case 'sell_item':
        return { item: var1, bonus: Math.min(0.01 * ability, 0.15) };
      case 'do_training':
        return { training: null };
      case 'maintain_training':
        return { fid: department.fid, amount: 1.5 + difficulty / 10 };
      case 'search_contract':
        const negotiate = departmentRoot.departmentTotalStat(department, 'negotiate');
        return { trainings: { start: Number(var1), end: Number(var1) }, bonus: Math.min(0.01 * negotiate, 0.2) };
      default:
        return null;
    }
  }

  getDepartmentPendingFailProps(work_key, _department, pending) {
    const { var1 } = pending;
    switch (work_key) {
      case 'recruit_agent':
        return { agent: var1, discount: 0 };
      case 'buy_item':
        return { item: var1, discount: 0 };
      case 'sell_item':
        return { item: var1, bonus: 0 };
      case 'search_contract':
        return { trainings: { start: Math.max(1, Number(var1) - 1), end: Math.min(Number(var1) + 1, 5) }, bonus: 0 };
      default:
        return null;
    }
  }

  //new_recruit: 고용할 수 있는 새 신병을 리스팅. 첫 번째 인자로 평균 전투력
  new_recruit(props) {
    const { level, fid, needPopup } = props;
    const { rng, turn, names, global_modifier, world } = this;

    const level_integer = Math.floor(level);
    const level_decimal = level - level_integer;
    const level_rng = rng.range(0, 1) < level_decimal ? 1 : 0;
    const level_rng_critical = rng.range(0, 1) < level_decimal - 0.75 ? 1 : 0;

    const areaNum = Math.min(level_integer + level_rng + level_rng_critical, 3);

    let listings_other = this.recruit_listings.filter(({ areaNum: n }) => n !== areaNum);
    let listings = this.recruit_listings.filter(({ areaNum: n }) => n === areaNum);

    let listing_count = AGENT_LISTING_MAX + this.researchEffect('recruit_size');

    if (global_modifier.find(({ key }) => key === 'mod_global_4_youtuber')) {
      listing_count -= 1;
    }
    if (global_modifier.find(({ key }) => key === 'mod_global_11_platform_labor')) {
      listing_count += 2;
    }

    const label_offset = this.researchEffect('recruit_overall');
    let idx, b_recruit, b_launge;

    if (fid >= 0) {
      idx = Math.floor(fid / 10);
      b_recruit = world.facilityMaxBonusByKey(idx, 'recruit_adjacent');
      b_launge = world.facilityMaxByCenterKey(idx, 'lounge');
    }
    else {
      idx = world.centers.find((c) => world.storage[c.idx].centerstate.office0)?.idx;
      b_recruit = 0;
      b_launge = 0;
    }

    let offsets = [0, 0, 0];
    if (b_recruit === 1) {
      if (rng.range(0, 1) < 0.25) {
        offsets[2] += 1;
      }
    }
    if (b_recruit === 2) {
      if (rng.range(0, 1) < 0.25) {
        offsets[1] += 1;
      }
      if (rng.range(0, 1) < 0.5) {
        offsets[2] += 1;
      }
    }

    if (b_launge > 1) {
      offsets[0] += 1;
    }

    const name = names.pop();
    const agent = createAgent(rng, name, this.agent_idx++,
      { areaNum, turn, global_modifier, label_offset, label_offsets: offsets });
    agent.world_idx = idx;

    agent.life_max = Math.round(agent.stats2.toughness * 6 + 16);
    agent.life = agent.life_max;

    if (global_modifier.find((m) => m.key === 'mod_global_10_high_manpower')) {
      agent.power += 1;
    }

    if (b_launge > 0 && rng.range(0, 1) < 0.3) {
      let term = TICK_PER_MONTH * 3;
      agent.modifier.push({ key: 'mod_agent_6_morale_high', start: turn, term });
    }

    let listing = {
      ty: 'agent',
      areaNum,
      idx,
      agent,
      expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    };
    listings.push(listing);

    const ev = { turn, ty: 'recruit' };
    this.notificationAddPending(ev);

    while (listings.length > listing_count) {
      const [item] = listings.splice(0, 1);
      if (item.agent.name) {
        names.unshift(item.agent.name);
      }
    }

    this.recruit_listings = listings_other.concat(listings);
    if (needPopup) {
      this.maybePushDepartmentEvent({ event_ty: 'department_recruit', agent });
    }
    return listing;
  }

  //recruit_with_discount: 고용 인터페이스를 여는데, 계약금/RS 비율에 첫 번째 인자만큼의 할인을 적용함
  recruit_with_discount(props) {
    const { agent, discount } = props;
    this.department_events.push({ ty: 'RECRUIT', agent, modifiers: { discount } });
  }

  //discount_agent_value: 용병의 기대 몸값(이후 재계약때 사용될 기준 수치)을 첫 번째 인자만큼 할인함
  discount_agent_value(props) {
    const { agent, discount } = props;
    if (agent.contract) {
      agent.contract.renew_mult *= (1 - discount);
    }
  }

  maybePushDepartmentEvent(ev) {
    const optkey = `department_${ev.event_ty}`;
    if (this.pauseconfig[optkey] ?? true) {
      this.department_events.push({ ty: 'DEPARTMENT', ...ev });
    }
  }

  //new_staff: [새 기능] 직원으로 고용할 수 있는 사람을 리스팅. 첫 번째 인자로 평균 능력치
  new_staff(props) {
    const { power } = props;
    const { departmentRoot, turn, names } = this;
    const name = names.pop();
    const staff = departmentRoot.addStaffToMarket(power, name, turn);

    const ev = { turn, ty: 'market_staff' };
    this.notificationAddPending(ev);
    this.maybePushDepartmentEvent({ event_ty: 'market_staff', staff });
  }

  //recruit_staff: [새 기능] 해당 직원을 고용함
  recruit_staff(props) {
    const { staff } = props;
    const { departmentRoot, turn } = this;
    departmentRoot.recruitStaff(staff);

    const ev = { turn, ty: 'new_staff' };
    this.notificationAddPending(ev);
    this.maybePushDepartmentEvent({ event_ty: 'new_staff', staff });
  }

  //new_gun: 첫 번째 인자 무기종의 무기 중 티어가 두 번째 인자 범위 안에 있는 총을 리스팅
  new_gun(props) {
    const { ty, rates, needPopup } = props;
    const { rng, market_listings, turn } = this;
    let { nextItemId } = this;
    //TODO: 업무 부서 idx 넣을 것
    const idx = 0;
    const firearm_rate = Math.min(rng.integer(rates.start, rates.end), FIREARM_TIER_MAX);
    let firearm = rng.choice(firearms.filter((f) => f.firearm_ty === ty && f.firearm_rate === firearm_rate));
    firearm = { ...firearm, options: [] };

    const options = data_itemOptions.filter((d) => d.item_type === 'firearm' && d.item_group.find((i) => i === firearm.firearm_ty));
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            firearm.buy_cost *= value;
            firearm.sell_cost *= value;
          }
          else if (firearm[modifier]) {
            firearm[modifier] *= value;
          }
        }

        firearm.options.push(option.name);
      }
    }

    const item = {
      ty: 'firearm',
      buy_cost: firearm.buy_cost,
      sell_cost: firearm.sell_cost,
      firearm,
      idx,
      id: nextItemId++,
      expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    };

    market_listings.push(item);
    this.nextItemId = nextItemId;

    const ev = { turn, ty: 'market' };
    this.notificationAddPending(ev);
    if (needPopup) {
      this.maybePushDepartmentEvent({ event_ty: 'market', item });
    }
    return item;
  }

  //new_bulletproof: 첫 번째 인자 범위 안에 있는 티어의 방탄복을 리스팅
  new_bulletproof(props) {
    const { rates } = props;
    const { rng, market_listings, turn } = this;
    let { nextItemId } = this;
    //TODO: 업무 부서 idx 넣을 것
    const idx = 0;
    const vest_rate = Math.min(rng.integer(rates.start, rates.end), 4);
    let equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === vest_rate));
    equipment = { ...equipment, options: [] };

    const options = data_itemOptions.filter((d) => d.item_type === 'equipment');
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            equipment.buy_cost *= value;
            equipment.sell_cost *= value;
          }
          else if (equipment[modifier]) {
            equipment[modifier] *= value;
          }
        }

        equipment.options.push(option.name);
      }
    }

    const item = {
      ty: 'equipment',
      buy_cost: equipment.buy_cost,
      sell_cost: equipment.sell_cost,
      equipment,
      idx,
      id: nextItemId++,
      expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    };

    market_listings.push(item);
    this.nextItemId = nextItemId;

    const ev = { turn, ty: 'market' };
    this.notificationAddPending(ev);
    this.maybePushDepartmentEvent({ event_ty: 'market', item });
    return item;
  }

  //new_grenade: 첫 번째 인자 종류의 수류탄을 리스팅
  new_grenade(props) {
    const { name } = props;
    const { market_listings, turn } = this;
    let { nextItemId } = this;
    //TODO: 업무 부서 idx 넣을 것
    const idx = 0;
    let throwable = throwables.find((t) => t.throwable_rate === Number(name));
    throwable = { ...throwable, options: [] };

    const options = data_itemOptions.filter((d) => d.item_type === 'throwable');
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            throwable.buy_cost *= value;
            throwable.sell_cost *= value;
          }
          else if (throwable[modifier]) {
            throwable[modifier] *= value;
          }
        }

        throwable.options.push(option.name);
      }
    }

    const item = {
      ty: 'throwable',
      buy_cost: throwable.buy_cost,
      sell_cost: throwable.sell_cost,
      throwable,
      idx,
      id: nextItemId,
      expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    };

    market_listings.push(item);
    this.nextItemId = nextItemId;

    const ev = { turn, ty: 'market' };
    this.notificationAddPending(ev);
    this.maybePushDepartmentEvent({ event_ty: 'market', item });
    return item;
  }

  //buy_with_discount: 아이템을 구입하되, 첫 번째 인자 비율만큼 할인한 가격에 구입한다.
  buy_with_discount(props) {
    const { item, discount } = props;
    const { market_listings, inventories } = this;

    const payback = Math.ceil(item.buy_cost * discount);

    const index = market_listings.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      market_listings.splice(index, 1);
      delete item.expires_at;
      inventories.push(item);
      if (item.ty === 'firearm') {
        this.checkQuestPrerequisites('firearm_acquire');
      }
      else if (item.ty === 'equipment') {
        this.checkQuestPrerequisites('armor_acquire');
      }
      this.pushCashbook({ ty: 'market', resource: payback });
    }

    if (item.ty === 'firearm') {
      this.triggerQuest('finish_buy_weapon');
    }
    this.triggerQuest('finish_buy_item');
  }

  //sell_with_bonus: 아이템을 구매가격의 50% + 첫 번째 인자 비율 가격만큼의 돈을 받고 판매한다.
  sell_with_bonus(props) {
    const { item, bonus } = props;
    const { inventories } = this;

    const cost = Math.floor(item.sell_cost * (1 + bonus));

    const index = inventories.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      inventories.splice(index, 1);
      this.pushCashbook({ ty: 'market', resource: cost });
    }
  }

  maintain_training(props) {
    const { fid, amount } = props;
    const { slots } = this;
    const department_slots = slots.filter((s) => Math.floor(s.idx / 10) === fid);
    for (const slot of department_slots) {
      addAvailabilityPending(slot, amount, 'maintain_training');
    }
  }

  //new_contract: 적의 훈련수준이 첫 번째 인자 구간 안에서 뽑히는 임무를 1개 생성. 보수 금액에 두 번째 인자%만큼의 보상을 더함
  new_contract(props) {
    const { trainings, bonus } = props;
    const { rng, turn, global_modifier, missions, world } = this;

    // sample world mission
    const center = rng.choice(world.centers.filter(({ idx }) => world.storage[idx].centerstate.office));

    const startAreaFilter = m => !world.storage[center.idx].office0 || (m.reward_throwable_type === 'none' && m.reward_resource_add_amount === 0 && m.reward_equipment_tier_max === 0 && m.reward_firearm_tier_max === 0);
    const filter = m => !m.stability_level.find((l) => l === '시작' || l === '마일스톤') && m.newbie === this.newbie && m.training === training && startAreaFilter(m);

    const training = rng.integer(trainings.start, trainings.end);
    const mission = createMission(rng, turn, this.newbie, center.idx, global_modifier, [], true, filter);

    this.missionApplyEffect(mission);

    const world_mission = world.onNewMission(center, -1);

    mission.p = world_mission.p;
    mission.idx = world_mission.idx;

    mission.resource = Math.round(mission.resource * (1 + bonus));
    missions.push(mission);

    const ev = { turn, ty: 'intel' };
    this.notificationAddPending(ev);
    this.maybePushDepartmentEvent({ event_ty: 'intel', mission });

    this.syncWorldmap();
  }

  do_nothing(_props) {
  }

  worldAreaState(idx) {
    const { world, resources } = this;
    const { centerstate } = world.storage[idx];
    const { safehouse, office, locked } = centerstate;

    if (office) {
      return null;
    }

    let cost = COST_WORLDMAP_AREA;
    if (!safehouse) {
      cost = Math.floor(cost * this.effects.cost_safehouse_mult);
    }

    let conditions = [];

    const mode = world.centerMode(idx);
    conditions.push({ done: cost <= resources.resource, label: L('loc_dynamic_string_region_facility_build_condition_cash', { value: cost }) });
    if (centerstate.tier > 1) {
      conditions.push({ done: centerstate.tier <= resources.renown.level + 1, label: L('loc_dynamic_string_region_facility_build_condition_milestone_contract', { value: centerstate.tier - 1 }) });
    }

    if (!safehouse) {
      conditions.push({ done: !locked, label: L('loc_ui_string_region_expand_condition_proximity') });
    } else {
      conditions.push({ done: mode === 0, label: L('loc_ui_string_region_expand_condition_stability') });
    }

    const disabled = !conditions.every((c) => c.done);

    if (!safehouse) {
      return {
        idx,
        conditions,
        action: 'safehouse',
        disabled,
        cost,
      };
    }

    if (!office) {
      return {
        idx,
        conditions,
        action: 'office',
        disabled,
        cost,
      };
    }

    return null;
  }


  //Quest(단기 목표) 관련
  checkQuestObjectiveCompleted(objective_key, objective_value) {
    switch (objective_key) {
      case 'dispatch_mission':
        {
          const { pendings } = this;
          for (const pending of pendings) {
            if (pending.ty === 'mission') {
              return true;
            }
          }
        }
        break;
      case 'build_facility':
        {
          const { world } = this;
          for (const center of world.centers) {
            const { centerstate } = world.storage[center.idx];

            if (centerstate.office) {
              const { facilities } = centerstate;

              for (const facility of facilities) {
                if (facility.key.includes(objective_value) || (facility.state === 'upgrading' && facility.next.includes(objective_value))) {
                  return true;
                }
              }
            }
          }
        }
        break;
      case 'finish_build_facility':
        {
          const { world } = this;
          for (const center of world.centers) {
            const { centerstate } = world.storage[center.idx];

            if (centerstate.office) {
              const { facilities } = centerstate;

              for (const facility of facilities) {
                if (facility.key.includes(objective_value)) {
                  return true;
                }
              }
            }
          }
        }
        break;
      case 'allocate_department_chief':
      case 'allocate_department_chief_persistent':
        {
          const { departmentRoot } = this;
          for (const department of departmentRoot.departments) {
            if (department.head_id >= 0 && (objective_value === 'any' || department.key.includes(objective_value))) {
              return true;
            }
          }
          break;
        }
      case 'allocate_department_staff':
      case 'allocate_department_staff_persistent':
        {
          const { departmentRoot } = this;
          for (const department of departmentRoot.departments) {
            if (department.members_id.length > 0 && (objective_value === 'any' || department.key.includes(objective_value))) {
              return true;
            }
          }
        }
        break;
      case 'department_work':
        {
          const { departmentRoot } = this;
          for (const department of departmentRoot.departments) {
            for (const pending of department.pendings) {
              if (pending.work_key.includes(objective_value)) {
                return true;
              }
            }
          }
        }
        break;
      case 'buy_weapon':
        {
          const { departmentRoot } = this;
          for (const department of departmentRoot.departments) {
            for (const pending of department.pendings) {
              if (pending.work_key === 'buy_item' && pending.var1.ty === 'firearm') {
                return true;
              }
            }
          }
        }
        break;
      case 'train':
        {
          const { slots } = this;
          for (const slot of slots) {
            if (slot.training && slot.training.agentIdx >= 0 && slot.ty === objective_value) {
              return true;
            }
          }
        }
        break;
      case 'build_safehouse':
        {
          const { world } = this;
          const { centers, storage } = world;
          if (centers.find((c) => storage[c.idx].centerstate?.safehouse && !storage[c.idx].centerstate?.office0 && !storage[c.idx].centerstate?.name === '보카그란데')) {
            return true;
          }
        }
        break;
      case 'build_office':
        {
          const { world } = this;
          const { centers, storage } = world;
          if (centers.find((c) => storage[c.idx].centerstate?.office && !storage[c.idx].centerstate?.office0 && !storage[c.idx].centerstate?.name === '보카그란데')) {
            return true;
          }
        }
        break;
      case 'stabilize_region_without_office':
        {
          const { world } = this;
          const { centers, storage } = world;
          if (centers.find((c) => storage[c.idx].centerstate?.safehouse && !storage[c.idx].centerstate?.office0 && world.centerMode(c.idx) === 0)) {
            return true;
          }
        }
        break;
      case 'stabilize_region_0':
        {
          const { world } = this;
          const { centers, storage } = world;
          if (centers.find((c) => storage[c.idx].centerstate?.office0 && world.centerMode(c.idx) === 0)) {
            return true;
          }
        }
        break;
      case 'equip_firearm_tier':
        {
          const { agents } = this;
          for (const agent of agents) {
            if (agent.firearm?.firearm_rate >= Number(objective_value)) {
              return true;
            }
          }
        }
        break;
      case 'armor_all':
        {
          const { agents } = this;
          for (const agent of agents) {
            if (agent.world_idx > 0 && agent.equipment?.vest_rate <= 0) {
              return false;
            }
          }
          return true;
        }
      case 'firearm_all':
        {
          const { agents } = this;
          for (const agent of agents) {
            if (agent.world_idx > 0 && agent.firearm?.firearm_ty === 'hg' && agent.firearm?.firearm_rate === 1) {
              return false;
            }
          }
          return true;
        }
      case 'finish_buy_weapon':
      case 'finish_department_work':
      case 'relocate_agent':
      default:
        break;
    }

    return false;
  }

  checkQuestPrerequisite(quest, prerequisite_key) {
    const { prerequisites } = quest;
    const { questTracker } = this;

    for (const { key, value } of prerequisites) {
      switch (key) {
        case 'quest_finish':
          if (!questTracker.completed.find((q) => q === value)) {
            return false;
          }
          break;
        case 'agents_count':
          if (this.agents.length < value) {
            return false;
          }
          break;
        case 'block_quest':
          return false;
        default:
          {
            const count = questTracker.prerequisites[key];
            if (count) {
              if (count < Number(value)) {
                return false;
              }
            }
            else {
              return false;
            }
          }
          break;
      }
    }

    return true;
  }

  checkQuestPrerequisites(prerequisite_key) {
    const { turn, questTracker } = this;
    const { deactivated, onProgress } = questTracker;

    if (prerequisite_key) {
      questTracker.prerequisites[prerequisite_key] = (questTracker.prerequisites[prerequisite_key] ?? 0) + 1;
    }

    for (let i = deactivated.length - 1; i >= 0; i--) {
      const deactivatedQuest = deactivated[i];
      if (!this.checkQuestPrerequisite(deactivatedQuest, prerequisite_key)) {
        continue;
      }

      // 새 퀘스트 발급
      const onProgressQuest = { ...deactivatedQuest };
      delete onProgressQuest.prerequisites;
      onProgressQuest.objectives = onProgressQuest.objectives.map((objective) => { return { ...objective, completed: false } });

      if (onProgressQuest.duration > 0) {
        onProgressQuest.expires_at = turn + onProgressQuest.duration;
      }

      for (const objective of onProgressQuest.objectives) {
        if (objective.completed) {
          continue;
        }
        if (this.checkQuestObjectiveCompleted(objective.key, objective.value)) {
          this.questObjectiveCompleted(onProgressQuest, objective.key);
        }
      }

      onProgress.splice(0, 0, onProgressQuest);

      for (const reward of onProgressQuest.on_starts) {
        this.applyQuestEffect(reward);
      }

      deactivated.splice(i, 1);
    }
  }

  updateQuest(objective_key) {
    const { questTracker } = this;
    const { onProgress } = questTracker;
    for (const quest of onProgress) {
      const objective = quest.objectives.find((objective) => objective.key === objective_key);
      if (!objective) {
        continue;
      }

      if (this.checkQuestObjectiveCompleted(objective.key, objective.value)) {
        this.questObjectiveCompleted(quest, objective_key);
      }
    }
  }

  questObjectiveCompleted(quest, objective_key) {
    const objective = quest.objectives.find((objective) => objective.key === objective_key);
    if (!objective) {
      return;
    }
    objective.completed = true;
    if (!quest.objectives.find((objective) => !objective.completed)) {
      quest.completed = true;
    }
  }

  onTickQuest() {
    this.updateQuest('stabilize_region_without_office');
    this.updateQuest('stabilize_region_0');
  }

  triggerQuest(objective_key, objective_value) {
    const { questTracker } = this;
    const { onProgress } = questTracker;
    for (const quest of onProgress) {
      const objective = quest.objectives.find((objective) => objective.key === objective_key);
      if (!objective || objective.completed) {
        continue;
      }

      let completed = false;
      switch (objective_key) {
        case 'balance':
          completed = (0 | objective.value) >= objective_value;
          break;
        case 'demo_00': {
          // 다섯(5) 명의 용병을 임무를 수행할 수 있는 상태로 준비시키세요.
          const avail = this.agents.filter((a) => a.state === null && this.agentAvail(a));
          completed = avail.length >= 5;
        }
          break;
        case 'demo_00_1': {
          const avail = this.agents.filter((a) => a.level.cur >= 3);
          completed = avail.length > 0;
        }
          break;
        case 'demo_02': {
          // 두(2) 명의 용병을, 2등급 이상의 무장을 장비하고 임무를 수행할 수 있는 상태로 준비시키세요.
          const avail = this.agents.filter((a) => {
            return a.state === null && this.agentAvail(a) && a.equipment?.vest_rate >= 2;
          });
          completed = avail.length >= 2;
        }
          break;
        case 'demo_03': {
          // 척탄병 퍽 그룹의 [기초 척탄 훈련] 퍽을 보유한 용병을 확보하세요.
          const avail = this.agents.filter((a) => a.perks.list.find((p) => p === 'perk_grenadier_base'));
          completed = avail.length > 0;
        }
          break;
        case 'build_facility':
        case 'finish_build_facility':
        case 'department_work':
        case 'finish_department_work':
          if (objective_value.includes(objective.value)) {
            completed = true;
          }
          break;
        case 'allocate_department_chief':
        case 'allocate_department_chief_persistent':
        case 'allocate_department_staff':
        case 'allocate_department_staff_persistent':
          if (objective.value === 'any' || objective_value.includes(objective.value)) {
            completed = true;
          }
          break;
        case 'train':
          if (objective.value === objective_value) {
            completed = true;
          }
          break;
        case 'equip_firearm_tier':
          if (Number(objective.value) >= objective_value) {
            completed = true;
          }
          break;
        case 'milestone_clear':
          if (Number(objective.value) >= objective_value) {
            completed = true;
          }
        case 'buy_weapon':
        case 'finish_buy_weapon':
        case 'dispatch_mission':
        case 'progress_time':
        case 'change_gamespeed':
        case 'finish_mission':
        case 'relocate_agent':
        case 'build_safehouse':
        case 'build_office':
        case 'stabilize_region_without_office':
        case 'acquire_perk':
        case 'replace_firearm':
        case 'replace_armor':
        case 'buy_item':
        case 'finish_buy_item':
          completed = true;
          break;
        default:
          break;
      }

      if (completed) {
        this.questObjectiveCompleted(quest, objective_key);
      }
    }
  }

  claimQuestReward(key) {
    const { questTracker } = this;
    const { onProgress, completed } = questTracker;
    const index = onProgress.findIndex((quest) => quest.key === key);
    if (index >= 0) {
      const quest = onProgress[index];

      for (const reward of quest.rewards) {
        this.applyQuestEffect(reward);
      }

      onProgress.splice(index, 1);
      completed.push(quest.key);

      this.checkQuestPrerequisites();
    }
  }

  applyQuestEffect(reward) {
    const { key, value } = reward;

    switch (key) {
      case 'resource':
        this.pushCashbook({ ty: 'quest', resource: Number(value) });
        break;
      case 'firearm':
        {
          const { rng } = this;
          let { nextItemId } = this;
          const firearm = rng.choice(firearms.filter((f) => f.firearm_ty === value[0] && f.firearm_rate === Number(value[1])));
          if (firearm) {
            this.inventories.push({
              ty: 'firearm',
              buy_cost: firearm.buy_cost,
              sell_cost: firearm.sell_cost,
              firearm,
              name: firearm.firearm_name,
              id: nextItemId++
            });
            this.nextItemId = nextItemId;
          }
        }
        break;
      case 'market_armor_tier':
        {
          const { rng, market_listings, turn } = this;
          let { nextItemId } = this;

          const idx = 0;
          const equipment = rng.choice(gears_vest_bulletproof.filter((e) => e.vest_rate === Number(value)));

          const item = {
            ty: 'equipment',
            buy_cost: equipment.buy_cost,
            sell_cost: equipment.sell_cost,
            equipment,
            idx,
            id: nextItemId++,
            expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
          };

          market_listings.push(item);
          this.nextItemId = nextItemId;

          const ev = { turn, ty: 'market' };
          this.notificationAddPending(ev);
        }
        break;
      case 'recruit_apply_tier':
        {
          const { world, rng, turn, names, global_modifier } = this;
          const areaNum = Number(value);

          let listings_other = this.recruit_listings.filter(({ areaNum: n }) => n !== areaNum);
          let listings = this.recruit_listings.filter(({ areaNum: n }) => n === areaNum);

          let listing_count = AGENT_LISTING_MAX + this.researchEffect('recruit_size');

          if (global_modifier.find(({ key }) => key === 'mod_global_4_youtuber')) {
            listing_count -= 1;
          }
          if (global_modifier.find(({ key }) => key === 'mod_global_11_platform_labor')) {
            listing_count += 2;
          }

          const label_offset = this.researchEffect('recruit_overall');
          const idx = world.centers.find((c) => world.storage[c.idx].centerstate.office0)?.idx;

          let offsets = [0, 0, 0];

          const name = names.pop();
          const agent = createAgent(rng, name, this.agent_idx++,
            { areaNum, turn, global_modifier, label_offset, label_offsets: offsets });
          agent.world_idx = idx;

          agent.life_max = Math.round(agent.stats2.toughness * 6 + 16);
          agent.life = agent.life_max;

          if (global_modifier.find((m) => m.key === 'mod_global_10_high_manpower')) {
            agent.power += 1;
          }

          listings.push({
            areaNum,
            idx,
            agent,
            expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
          });

          const ev = { turn, ty: 'recruit' };
          this.notificationAddPending(ev);

          while (listings.length > listing_count) {
            const [item] = listings.splice(0, 1);
            if (item.agent.name) {
              names.unshift(item.agent.name);
            }
          }

          this.recruit_listings = listings_other.concat(listings);
        }
        break;
      case 'milestone_open':
        this.appendMilestoneMission((0 | value) - 1, true);
        break;
      default:
        break;
    }
  }
}
