import * as React from 'react';
import { useState } from 'react';
import './FigmaView.css';
import { PortraitWrapper } from './PortraitsView';

const svg_firearm = <svg xmlns="http://www.w3.org/2000/svg" width="43" height="21" viewBox="0 0 43 21" fill="none">
  <path d="M0 5.45484L4.76744 0.692932H38.2326L43 5.45484V15.931L38.2326 20.6929H4.76744L0 15.931V5.45484Z" fill="#87A07C" />
</svg>;

const svg_state = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path opacity="0.4" d="M24.1433 0.692932H5.41028C2.64885 0.692932 0.410278 2.93151 0.410278 5.69294V24.4259C0.410278 27.1873 2.64886 29.4259 5.41028 29.4259H24.1433C26.9047 29.4259 29.1433 27.1873 29.1433 24.4259V5.69293C29.1433 2.93151 26.9047 0.692932 24.1433 0.692932Z" fill="black" />
  <rect x="1.41028" y="1.69293" width="26.733" height="26.733" rx="4" fill="#F8C134" />
  <path d="M4.03882 4.32152L7.37466 9.88125L5.70674 11.5492L12.9344 13.2171L11.2665 5.98944L9.59855 7.65735L4.03882 4.32152Z" fill="#130700" />
  <path d="M4.03882 25.7973L7.37466 20.2376L5.70674 18.5697L12.9344 16.9017L11.2665 24.1294L9.59855 22.4615L4.03882 25.7973Z" fill="#130700" />
  <path d="M25.5146 4.32152L22.1788 9.88125L23.8467 11.5492L16.619 13.2171L18.287 5.98944L19.9549 7.65735L25.5146 4.32152Z" fill="#130700" />
  <path d="M25.5146 25.7973L22.1788 20.2376L23.8467 18.5697L16.619 16.9017L18.287 24.1294L19.9549 22.4615L25.5146 25.7973Z" fill="#130700" />
  <path d="M14.7767 16.6319C15.6452 16.6319 16.3492 15.9279 16.3492 15.0594C16.3492 14.1909 15.6452 13.4869 14.7767 13.4869C13.9082 13.4869 13.2042 14.1909 13.2042 15.0594C13.2042 15.9279 13.9082 16.6319 14.7767 16.6319Z" fill="#130700" />
</svg>

const svg_health = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M9.78137 5.73797C9.78137 4.38902 7.65472 2.23337 5.03137 2.23337C2.40802 2.23337 0.281372 4.57119 0.281372 7.19454C0.281372 8.42099 0.746189 9.53888 1.50928 10.3817L9.78138 20.4945L18.0536 10.3815C18.8166 9.53873 19.2814 8.42092 19.2814 7.19454C19.2814 4.57119 17.1547 2.23337 14.5314 2.23337C11.908 2.23337 9.78137 4.44297 9.78137 5.73797Z" fill="#90CD2C" />
</svg>

const svg_protection = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M1.27444 5.40288L3.16644 0.672897H5.53143L6.00442 5.40288H13.5724L14.0454 0.672897H16.4104L18.3024 5.40288V18.9327C15.6536 20.4463 11.5227 20.8247 9.78841 20.8247C8.05408 20.8247 3.92323 20.4463 1.27444 18.9327V5.40288Z" fill="#879F9F" />
</svg>

const svg_grenade = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M6.45607 0.275925L5.78114 4.32551C2.78026 5.42278 0.579461 8.18115 0.291958 11.4874H17.0459C16.7652 8.25884 14.66 5.55272 11.767 4.40561V2.93138H14.7183C15.7552 4.237 17.2758 6.48776 17.9702 9.64918L19.2908 9.57001C19.2908 5.58683 17.1214 1.35938 16.1927 0.275925H6.45607Z" fill="#878E9F" />
  <path d="M6.45607 0.275925L5.78114 4.32551C2.78026 5.42278 0.579461 8.18115 0.291958 11.4874H17.0459C16.7652 8.25884 14.66 5.55272 11.767 4.40561V2.93138H14.7183C15.7552 4.237 17.2758 6.48776 17.9702 9.64918L19.2908 9.57001C19.2908 5.58683 17.1214 1.35938 16.1927 0.275925H6.45607Z" fill="#879F9F" />
  <path d="M0.286087 12.8926H17.0518C16.7118 17.2248 13.0885 20.6344 8.66895 20.6344C4.24935 20.6344 0.626103 17.2248 0.286087 12.8926Z" fill="#878E9F" />
  <path d="M0.286087 12.8926H17.0518C16.7118 17.2248 13.0885 20.6344 8.66895 20.6344C4.24935 20.6344 0.626103 17.2248 0.286087 12.8926Z" fill="#879F9F" />
</svg>

const svg_bullet = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M1.70642 6.40447C1.70642 4.51963 2.60777 2.16359 3.95979 1.69238C5.31182 2.16359 6.21316 4.51963 6.21316 6.40447V20.5407H1.70642V6.40447Z" fill="#878E9F" />
  <path d="M1.70642 6.40447C1.70642 4.51963 2.60777 2.16359 3.95979 1.69238C5.31182 2.16359 6.21316 4.51963 6.21316 6.40447V20.5407H1.70642V6.40447Z" fill="#879F9F" />
  <path d="M7.56285 6.40447C7.56285 4.51963 8.4642 2.16359 9.81622 1.69238C11.1682 2.16359 12.0696 4.51963 12.0696 6.40447V20.5407H7.56285V6.40447Z" fill="#878E9F" />
  <path d="M7.56285 6.40447C7.56285 4.51963 8.4642 2.16359 9.81622 1.69238C11.1682 2.16359 12.0696 4.51963 12.0696 6.40447V20.5407H7.56285V6.40447Z" fill="#879F9F" />
  <path d="M15.6264 1.69238C14.2744 2.16359 13.3731 4.51963 13.3731 6.40446V20.5407H17.8798V6.40446C17.8798 4.51963 16.9785 2.16359 15.6264 1.69238Z" fill="#878E9F" />
  <path d="M15.6264 1.69238C14.2744 2.16359 13.3731 4.51963 13.3731 6.40446V20.5407H17.8798V6.40446C17.8798 4.51963 16.9785 2.16359 15.6264 1.69238Z" fill="#879F9F" />
</svg>

const svg_kill = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 22" fill="none">
  <path d="M9.19295 11.7547C5.01361 16.9129 1.03607 22.1096 1.20282 22.5545C1.37604 23.0167 6.63074 18.4874 11.7335 13.7813C16.7485 17.7381 21.9925 21.6174 22.237 21.1462C22.4753 20.6871 18.4087 16.0268 14.1821 11.4979C18.0981 7.80415 21.3789 4.49375 21.3789 4.00064C21.3789 2.78813 19.2101 1.27879 18.4471 1.17365C18.1318 1.13021 15.0755 4.61788 11.6209 8.79009C8.36917 5.39942 5.54223 2.63883 5.17703 2.63764C4.25505 2.63466 2.63255 5.34747 2.54142 5.96556C2.50532 6.21046 5.51542 8.78817 9.19295 11.7547Z" fill="#FF3D3D" />
</svg>

const svg_scope = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26" fill="none">
  <path d="M15.868 12.9115C15.868 14.3929 14.6671 15.5938 13.1857 15.5938C11.7043 15.5938 10.5034 14.3929 10.5034 12.9115C10.5034 11.4301 11.7043 10.2292 13.1857 10.2292C14.6671 10.2292 15.868 11.4301 15.868 12.9115Z" fill="#FF9029" />
  <path fillRule="evenodd" clipRule="evenodd" d="M11.5818 0.415909H14.7896V3.29191C18.89 3.97048 22.1268 7.20728 22.8053 11.3076H25.6813V14.5154H22.8053C22.1268 18.6158 18.89 21.8526 14.7896 22.5312V25.4072H11.5818V22.5312C7.48146 21.8526 4.24465 18.6158 3.56609 14.5154H0.690094L0.690094 11.3076H3.56609C4.24466 7.20728 7.48146 3.97048 11.5818 3.29191V0.415909ZM11.5818 6.35231C9.14176 6.9469 7.22108 8.86757 6.62649 11.3076H6.64216L8.77761 12.9115L6.64216 14.5154H6.62649C7.22108 16.9555 9.14175 18.8762 11.5818 19.4707V19.4551L13.1857 17.3196L14.7896 19.4551V19.4707C17.2297 18.8762 19.1503 16.9555 19.7449 14.5154H19.7293L17.5938 12.9115L19.7293 11.3076H19.7449C19.1503 8.86758 17.2297 6.94691 14.7896 6.35231V6.36797L13.1857 8.50343L11.5818 6.36797V6.35231Z" fill="#FF9029" />
</svg>

const svg_leader = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none">
  <path d="M0.276733 0.442932H20.2767V17.4429L10.2767 24.9429L0.276733 17.4429V0.442932Z" fill="#00648E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M18.7767 1.94293H1.77673V16.6929L10.2767 23.0679L18.7767 16.6929V1.94293ZM20.2767 17.4429L10.2767 24.9429L0.276733 17.4429V0.442932H20.2767V17.4429Z" fill="#1E86C1" />
</svg>

export class IngameEntitySimple extends React.Component {
  render() {
    const { name, kills, life, life_max, firearm_ty } = this.props;

    const portrait = <PortraitWrapper agent={{ name }}
      className='ingameoverlay-portrait1'
    />;

    return <div className="ingameentity">
      <div className="ingameoverlay-bg">
      </div>
      <div className="ingameoverlay-name-bg">
        <div className="ingameoverlay-name-label">{name}</div>
        <div className="ingameoverlay-firearm">
          <div className="ingameoverlay-firearm-bg">
            {svg_firearm}
          </div>
          <div className="ingameoverlay-firearm-label">{firearm_ty.toUpperCase()}</div>
        </div>
      </div>
      <div className="ingameoverlay-status-bg">
        <div className="ingameoverlay-status-stats">
          <div className="ingameoverlay-status-stat-group">
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_health}
              </div>
              <div className="ingameoverlay-status-stat-progressbar">
                <SimpleProgressBar current={life} max={life_max} />
              </div>
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_kill}
              </div>
              <span className="ingameoverlay-status-stat-amount-inflict-kill">{kills}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ingameoverlay-portrait">
        {portrait}
        <div className="ingameoverlay-portrait-state">
          {true ? null : svg_state}
        </div>
      </div>
    </div>;
  }
}

export class IngameEntityFull extends React.Component {
  render() {
    const { name, kills } = this.props;

    const portrait = <PortraitWrapper agent={{ name }}
      className='ingameoverlay-portrait1'
    />;

    return <div className="ingameentity">
      <div className="ingameoverlay-bg">
      </div>
      <div className="ingameoverlay-name-bg">
        <div className="ingameoverlay-name-label">{name}</div>
        <div className="ingameoverlay-firearm">
          <div className="ingameoverlay-firearm-bg">
            {svg_firearm}
          </div>
          <div className="ingameoverlay-firearm-label">SMG</div>
        </div>
      </div>
      <div className="ingameoverlay-status-bg">
        <div className="ingameoverlay-status-stats">
          <div className="ingameoverlay-status-stat-group">
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_health}
              </div>
              <span className="ingameoverlay-status-stat-amount-health-current">50</span>/100
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_protection}
              </div>
              <span className="ingameoverlay-status-stat-amount-general-current">32</span>/45
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_grenade}
              </div>
              <span className="ingameoverlay-status-stat-amount-general-current">2</span>/3
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_bullet}
              </div>
              <span className="ingameoverlay-status-stat-amount-general-current">14</span>/40
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_kill}
              </div>
              <span className="ingameoverlay-status-stat-amount-inflict-kill">{kills}</span>
            </div>
            <div className="ingameoverlay-status-stat">
              <div className="ingameoverlay-status-stat-icon">
                {svg_scope}
              </div>
              <span className="ingameoverlay-status-stat-amount-inflict-damage">20</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ingameoverlay-portrait">
        {portrait}
        <div className="ingameoverlay-portrait-state">
          {true ? null : svg_state}
        </div>
      </div>
    </div>;
  }
}

class SimpleProgressBar extends React.Component {
  render() {
    const { current, max } = this.props;
    return <>
      <svg xmlns="http://www.w3.org/2000/svg" width="52" height="12" viewBox="0 0 52 12" fill="none">
        <path d="M51.2815 1.61653H0.281494V10.6165H51.2815V1.61653Z" fill="black" fill-opacity="0.5" />
        <rect x="2.28149" y="3.61653" width="47" height="5" fill="#3A7C06" fill-opacity="0.7" />
        <rect x="2.28149" y="3.61653" width={47 * current / max} height="5" fill="#90CD2C" fill-opacity="0.7" />
      </svg>
    </>
  }
}

export class IngameEntity extends React.Component {
  renderStat(ty, simple, current, max) {
    let icon = null;
    let value = null;

    switch (ty) {
      case "life":
        icon = svg_health;
        if (simple) {
          value = <div className="ingameoverlay-status-stat-progressbar">
            <SimpleProgressBar current={current} max={max} />
          </div>
        } else {
          value = <><span className="ingameoverlay-status-stat-amount-health-current">{current}</span>/{max}</>
        }
        break;
      case "protection":
        icon = svg_protection;
        value = <><span className="ingameoverlay-status-stat-amount-general-current">{current}</span>/{max}</>
        break;
      case "grenade":
        icon = svg_grenade;
        if (max > 0) {
          value = <><span className="ingameoverlay-status-stat-amount-general-current">{current}</span>/{max}</>
        } else {
          value = "-"
        }
        break;
      case "bullet":
        icon = svg_bullet;
        value = <><span className="ingameoverlay-status-stat-amount-general-current">{current}</span>/{max}</>
        break;
      case "kills":
        icon = svg_kill;
        value = <><span className="ingameoverlay-status-stat-amount-inflict-kill">{current}</span></>
        break;
      case "damage":
        icon = svg_scope;
        value = <><span className="ingameoverlay-status-stat-amount-inflict-damage">{current}</span></>
        break;
      default:
        console.log("IngameEntity: unknown stat type", ty);
        break;
    }

    return <div className="ingameoverlay-status-stat" key={ty}>
      <div className="ingameoverlay-status-stat-icon">
        {icon}
      </div>
      {value}
    </div>
  }

  renderLeaderIcon() {
    return <div className="ingameoverlay-leadericon">
      <div className="ingameoverlay-leadericon-icon">{svg_leader}</div>
      <div className="ingameoverlay-leadericon-label">L</div>
    </div>;
  }

  render() {
    const { agent, simple, state, selected, isLeader } = this.props;

    const { name } = agent;

    const portrait = <PortraitWrapper agent={{ name }}
      className='ingameoverlay-portrait1'
    />;

    const statsKey = simple ? ["life", "kills"] : ["life", "protection", "grenade", "bullet", "kills", "damage"];

    const stats = <div className="ingameoverlay-status-stats">
      {statsKey.map((ty) => this.renderStat(ty, simple, agent[ty], agent[ty + "_max"]))}
    </div>

    return <div className="ingameentity">
      <div className="ingameoverlay-bg">
      </div>
      <div className={"ingameoverlay-name-bg" + (selected ? " selected" : "")}>
        <div className="ingameoverlay-name-emtpy"></div>
        {isLeader ? this.renderLeaderIcon() : null}
        <div className={"ingameoverlay-name-label" + (selected ? " selected" : " unselected")}>{name}</div>
        <div className="ingameoverlay-firearm">
          <div className="ingameoverlay-firearm-bg">
            {svg_firearm}
          </div>
          <div className="ingameoverlay-firearm-label">{(agent.firearm_ty ?? 'N/A').toUpperCase()}</div>
        </div>
      </div>
      <div className="ingameoverlay-status-bg">
        {stats}
      </div>
      <div className="ingameoverlay-portrait">
        {portrait}
      </div>
    </div>;

    /*
        <div className="ingameoverlay-portrait-state">
          {state ? svg_state : null}
        </div>
      */
  }
}

const svg_prompt_panel_bg = <svg xmlns="http://www.w3.org/2000/svg" width="616" height="258" viewBox="0 0 616 258" fill="none">
  <path opacity="0.7" d="M69.8799 22L85.4093 0H530.591L546.12 22H616V258H0V22H69.8799Z" fill="#432015" />
</svg>

const svg_prompt_panel_line = <svg xmlns="http://www.w3.org/2000/svg" width="606" height="226" viewBox="0 0 606 226" fill="none">
  <path opacity="0.7" d="M62.5 0H0V226H606V0H543.5L540.5 4H602V222H4V4H65.5L62.5 0Z" fill="#FFCE95" />
</svg>

const svg_prompt_remaining_time_bg = <svg xmlns="http://www.w3.org/2000/svg" width="119" height="105" viewBox="0 0 119 105" fill="none">
  <g opacity="0.5" filter="url(#filter0_f_1_343)">
    <circle cx="59.5" cy="59.5" r="39.5" fill="#381212" />
  </g>
  <defs>
    <filter id="filter0_f_1_343" x="0" y="0" width="119" height="119" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1_343" />
    </filter>
  </defs>
</svg>

const svg_prompt_choice_unselected_bg = <svg xmlns="http://www.w3.org/2000/svg" width="546" height="41" viewBox="0 0 546 41" fill="none">
  <path d="M16 0H546V41H0V16L16 0Z" fill="#C2761D" />
</svg>

const svg_prompt_panel_title_bg = <svg xmlns="http://www.w3.org/2000/svg" width="464" height="34" viewBox="0 0 464 34" fill="none">
  <path d="M12 34L0 17L12 0H452L464 17L452 34H12Z" fill="#FAAE1A" />
</svg>

export class IngamePromptChoice extends React.Component {
  render() {
    const { label, selected } = this.props;

    return <>
      <div className={"ingameprompt-choice-bg" + (selected ? " selected" : "")}>
        <div className={"ingameprompt-choice" + (selected ? " selected" : " unselected")}>
          <div className={"ingameprompt-choice-label" + (selected ? " selected" : " unselected")}>
            {label}
          </div>
        </div>
      </div>
    </>;
  }
}

export class IngamePrompt extends React.Component {
  render() {
    return <div className="ingameprompt">
      <div className="ingameprompt-panel">
        <div className="ingameprompt-panel-bg">
          {svg_prompt_panel_bg}
        </div>
        <div className="ingameprompt-panel-line">
          {svg_prompt_panel_line}
        </div>
        <div className="ingameprompt-panel-title">
          {svg_prompt_panel_title_bg}
          <div className="ingameprompt-panel-title-label">
            Do we need to check the room?
          </div>
        </div>
        <div className="ingameprompt-choice-list">
          <IngamePromptChoice label="Ignore and rush." selected={true} />
          <IngamePromptChoice label="Shoot at sight." selected={false} />
          <IngamePromptChoice label="Why don't we have some tea time." selected={false} />
        </div>
      </div>
      <div className="ingameprompt-remaining-time">
        <div className="ingameprompt-remaining-time-description">
          <div className="ingameprompt-remaining-time-description-label">
            Time remaining
          </div>
        </div>
        <div className="ingameprompt-remaining-time-amount">
          <div className="ingameprompt-remaining-time-amount-label">
            5
          </div>
        </div>
      </div>
    </div>;
  }
}

export class IngamePopupButton extends React.Component {
  render() {
    const { label, level } = this.props;
    return <div className={"ingamepopup-button level" + level}>
      {label}
    </div>;
  }
}

export class IngamePopup extends React.Component {
  render() {
    return <div className="ingamepopup-bg">
      <div className="ingamepopup-panel-bg">
        <div className="ingamepopup-content">
          Are you sure you want to retreat from the mission area?
        </div>
        <div className="ingamepopup-button-group">
          <IngamePopupButton label="CANCEL" level="0" />
          <IngamePopupButton label="RETREAT" level="1" />
        </div>
      </div>
    </div>;
  }
}

const svg_morale = <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M17 32.7898C25.7204 32.7898 32.7898 25.7204 32.7898 17C32.7898 8.27955 25.7204 1.21023 17 1.21023C8.27955 1.21023 1.21023 8.27955 1.21023 17C1.21023 25.7204 8.27955 32.7898 17 32.7898ZM17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z" fill="url(#paint0_linear_96_688)" />
  <path fillRule="evenodd" clipRule="evenodd" d="M17 32.7898C25.7204 32.7898 32.7898 25.7204 32.7898 17C32.7898 8.27955 25.7204 1.21023 17 1.21023C8.27955 1.21023 1.21023 8.27955 1.21023 17C1.21023 25.7204 8.27955 32.7898 17 32.7898ZM17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z" fill="url(#paint1_linear_96_688)" />
  <path fillRule="evenodd" clipRule="evenodd" d="M17 26.899C22.4671 26.899 26.899 22.4671 26.899 17C26.899 11.5329 22.4671 7.10089 17 7.10089C11.5329 7.10089 7.10089 11.5329 7.10089 17C7.10089 22.4671 11.5329 26.899 17 26.899ZM17 29.3195C23.8039 29.3195 29.3195 23.8039 29.3195 17C29.3195 10.1961 23.8039 4.68042 17 4.68042C10.1961 4.68042 4.68042 10.1961 4.68042 17C4.68042 23.8039 10.1961 29.3195 17 29.3195Z" fill="url(#paint2_linear_96_688)" />
  <path fillRule="evenodd" clipRule="evenodd" d="M17 26.899C22.4671 26.899 26.899 22.4671 26.899 17C26.899 11.5329 22.4671 7.10089 17 7.10089C11.5329 7.10089 7.10089 11.5329 7.10089 17C7.10089 22.4671 11.5329 26.899 17 26.899ZM17 29.3195C23.8039 29.3195 29.3195 23.8039 29.3195 17C29.3195 10.1961 23.8039 4.68042 17 4.68042C10.1961 4.68042 4.68042 10.1961 4.68042 17C4.68042 23.8039 10.1961 29.3195 17 29.3195Z" fill="url(#paint3_linear_96_688)" />
  <path d="M21.6079 4.19446L8.87036 19.8856H15.0578L12.0086 29.4849L24.7461 13.7937H18.5587L21.6079 4.19446Z" fill="#00FF85" />
  <defs>
    <linearGradient id="paint0_linear_96_688" x1="17" y1="0" x2="17" y2="34" gradientUnits="userSpaceOnUse">
      <stop stop-color="#88F8FF" stop-opacity="0" />
      <stop offset="0.213542" stop-color="#88F8FF" stop-opacity="0.4" />
      <stop offset="0.364583" stop-color="#88F8FF" />
      <stop offset="0.651042" stop-color="#88F8FF" />
      <stop offset="0.807292" stop-color="#88F8FF" stop-opacity="0.4" />
      <stop offset="1" stop-color="#88F8FF" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint1_linear_96_688" x1="17" y1="0" x2="17" y2="34" gradientUnits="userSpaceOnUse">
      <stop stop-color="#00FF85" stop-opacity="0" />
      <stop offset="0.213542" stop-color="#00FF85" stop-opacity="0.4" />
      <stop offset="0.364583" stop-color="#00FF85" />
      <stop offset="0.651042" stop-color="#00FF85" />
      <stop offset="0.807292" stop-color="#00FF85" stop-opacity="0.4" />
      <stop offset="1" stop-color="#00FF85" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint2_linear_96_688" x1="17" y1="4.68042" x2="17" y2="29.3195" gradientUnits="userSpaceOnUse">
      <stop stop-color="#88F8FF" stop-opacity="0" />
      <stop offset="0.151042" stop-color="#88F8FF" stop-opacity="0" />
      <stop offset="0.302083" stop-color="#88F8FF" />
      <stop offset="0.697917" stop-color="#88F8FF" />
      <stop offset="0.848958" stop-color="#88F8FF" stop-opacity="0" />
      <stop offset="1" stop-color="#88F8FF" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint3_linear_96_688" x1="17" y1="4.68042" x2="17" y2="29.3195" gradientUnits="userSpaceOnUse">
      <stop stop-color="#00FF85" stop-opacity="0" />
      <stop offset="0.151042" stop-color="#00FF85" stop-opacity="0" />
      <stop offset="0.302083" stop-color="#00FF85" />
      <stop offset="0.697917" stop-color="#00FF85" />
      <stop offset="0.848958" stop-color="#00FF85" stop-opacity="0" />
      <stop offset="1" stop-color="#00FF85" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>

export class IngameStatusButton extends React.Component {
  render() {
    const { label, level } = this.props;
    return <div className={"ingamestatus-button level" + level}>
      {label}
    </div>;
  }
}

class MoraleProgressBar extends React.Component {
  render() {
    const { current, max } = this.props;
    return <>
      <svg xmlns="http://www.w3.org/2000/svg" width="240" height="11" viewBox="0 0 240 11" fill="none">
        <rect x="1" y="1.5" width="238" height="8" rx="4" fill="#3B3B3B" stroke="black" stroke-width="2" />
        <rect x="2" y="2.5" width={236 * current / max} height="6" rx="3" fill="#00FF85" />
      </svg>
    </>
  }
}

export class IngameStatus extends React.Component {
  render() {
    return <div className="ingamestatus-container">
      <div className="ingamestatus-morale">
        {svg_morale}
        <div className="ingamestatus-morale-progress-with-text">
          <div className="ingamestatus-morale-textgroup">
            <div className="ingamestatus-morale-text label">Team Morale:</div>
            <div className="ingamestatus-morale-text value">Confident</div>
          </div>
          <MoraleProgressBar current={7} max={10} />
        </div>
      </div>
      <IngameStatusButton label="RETREAT" level="1" />
    </div>
  }
}

export class IngamePolicyLogLabel extends React.Component {
  render() {
    const { label } = this.props;
    return <div className="ingamepolicy-log-label">
      {label}
    </div>
  }
}

const svg_policy_engage_offensive = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#FFC122" stroke="#FFDC5E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M11.9368 27.9833L9.24854 45.0087L22.8525 45.3835L23.5966 34.9658H32.2772L35.6848 27.8834L49.751 27.8041L50.2886 17.4097L19.1054 17.5889C18.6583 18.3935 17.4334 20.1803 16.0662 22.1293L13.3676 19.6466L11.3365 21.8544L14.3219 24.601C14.2141 24.7533 14.107 24.9045 14.0009 25.0541C13.2113 26.1688 12.4809 27.1997 11.9368 27.9833ZM30.7058 32.4658H23.7752L23.7828 32.359L25.6434 27.94L32.903 27.8991L30.7058 32.4658Z" fill="black" />
</svg>

const svg_policy_engage_defensive = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#C49233" stroke="#D6A74F" />
  <path fillRule="evenodd" clipRule="evenodd" d="M17.3069 18.1933L5.16344 30.4255L15.9752 38.6907L22.6752 30.6788L29.7141 35.7588L36.622 32.0101L48.0743 40.1776L54.5933 32.0637L29.2027 13.9599C28.3694 14.3507 26.3304 15.0827 24.0812 15.863L23.3459 12.2706L20.4069 12.8721L21.2203 16.8464C21.0437 16.9068 20.8684 16.9667 20.6948 17.026C19.4022 17.4678 18.2067 17.8763 17.3069 18.1933ZM29.9029 32.812L24.283 28.7561L24.3517 28.6739L28.4466 26.1795L34.3571 30.3948L29.9029 32.812Z" fill="black" />
</svg>

const svg_policy_search_active = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#FFC122" stroke="#FFDC5E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M12.5269 37.1423L25.0459 25.1522L25.3986 17.5703L31.3936 11.9279C36.9338 11.8581 47.0525 17.0722 47.6993 28.127L40.7626 35.7115L34.0648 34.7695L21.3253 46.6535C17.9751 46.7711 11.5395 45.1827 12.5269 37.1423ZM32.6426 14.6128C32.8203 18.6396 35.1344 25.9152 44.8699 26.7679C44.5145 22.8595 41.2991 16.0916 32.6426 14.6128Z" fill="black" />
</svg>

const svg_policy_search_inactive = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30C0.5 13.7076 13.7076 0.5 30 0.5C46.2924 0.5 59.5 13.7076 59.5 30Z" fill="#C49233" stroke="#D6A74F" />
  <path fillRule="evenodd" clipRule="evenodd" d="M12.5269 37.1423L25.0459 25.1522L25.3986 17.5703L31.3936 11.9279C36.9338 11.8581 47.0525 17.0722 47.6993 28.127L40.7626 35.7115L34.0648 34.7695L21.3253 46.6535C17.9751 46.7711 11.5395 45.1827 12.5269 37.1423ZM32.6426 14.6128C32.8203 18.6396 35.1344 25.9152 44.8699 26.7679C44.5145 22.8595 41.2991 16.0916 32.6426 14.6128Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM45.8218 49.3574C41.5121 52.8841 36.0032 55 30 55C16.1929 55 5 43.8071 5 30C5 23.9968 7.1159 18.4879 10.6426 14.1782L45.8218 49.3574ZM49.3573 45.8219L14.1781 10.6427C18.4878 7.11593 23.9968 5 30 5C43.8071 5 55 16.1929 55 30C55 36.0032 52.8841 41.5122 49.3573 45.8219Z" fill="#CC1C1C" />
</svg>

const svg_policy_aid_inactive = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#C49233" stroke="#D6A74F" />
  <path fillRule="evenodd" clipRule="evenodd" d="M10.2063 24.1416V45.182L48.8658 45.1193V24.3127L44.5893 18.1546H40.4155V11.1411H19.7856V18.3256H15.167L10.2063 24.1416ZM32.3209 24.4369H26.6544V29.6007H21.4906V35.2672H26.6544V40.431H32.3209V35.2672H37.4847V29.6007H32.3209V24.4369ZM36.7034 14.2079H24.0511V18.2774H36.7034V14.2079Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM45.8218 49.3574C41.5121 52.8841 36.0032 55 30 55C16.1929 55 5 43.8071 5 30C5 23.9968 7.1159 18.4879 10.6426 14.1782L45.8218 49.3574ZM49.3573 45.8219L14.1781 10.6427C18.4878 7.11593 23.9968 5 30 5C43.8071 5 55 16.1929 55 30C55 36.0032 52.8841 41.5122 49.3573 45.8219Z" fill="#CC1C1C" />
</svg>

const svg_policy_aid_essential = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#FFC122" stroke="#FFDC5E" />
  <path d="M33.1678 21.9667C34.6276 23.0038 35.7748 24.411 36.3846 26.0161H23.7676C24.3883 24.6092 25.5095 23.2874 27.0665 22.1045C25.0196 21.001 23.621 18.777 23.621 16.2133C23.621 12.5509 26.4751 9.58203 29.9958 9.58203C33.5166 9.58203 36.3707 12.5509 36.3707 16.2133C36.3707 18.6744 35.0818 20.8224 33.1678 21.9667Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M9.62354 26.0377H50.7438V46.6357H9.62354V26.0377ZM33.3364 28.2675H27.8223V33.2925H22.7973V38.8066H27.8223V43.8316H33.3364V38.8066H38.3614V33.2925H33.3364V28.2675Z" fill="black" />
</svg>

const svg_policy_aid_unlimited = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#FFC122" stroke="#FFDC5E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M9.60265 27.6692C9.91297 26.9749 10.3453 26.3014 10.8917 25.6554C11.4433 25.0033 12.1112 24.379 12.8874 23.7893C12.0946 23.3619 11.3989 22.7663 10.8442 22.0479C9.96684 20.9118 9.44189 19.4687 9.44189 17.8981C9.44189 14.2358 12.296 11.2668 15.8168 11.2668C19.3375 11.2668 22.1916 14.2358 22.1916 17.8981C22.1916 19.3703 21.7304 20.7305 20.9501 21.8308C20.4259 22.5699 19.7578 23.1917 18.9887 23.6515C19.735 24.1817 20.3996 24.8086 20.9525 25.5093C21.4739 26.1701 21.896 26.8965 22.1934 27.6692L22.2056 27.701H9.58909C9.59341 27.6903 9.59792 27.6798 9.60265 27.6692ZM17.6381 21.3923L15.9072 22.427L14.1365 21.4724C12.9416 20.8282 12.0741 19.494 12.0741 17.8981C12.0741 15.5907 13.8466 13.899 15.8168 13.899C17.787 13.899 19.5595 15.5907 19.5595 17.8981C19.5595 19.4293 18.7605 20.7212 17.6381 21.3923Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M50.6824 27.701L50.6703 27.6692C50.3728 26.8965 49.9508 26.1701 49.4294 25.5093C48.8765 24.8086 48.2119 24.1817 47.4656 23.6515C48.2347 23.1917 48.9028 22.5699 49.427 21.8308C50.2073 20.7305 50.6685 19.3703 50.6685 17.8981C50.6685 14.2358 47.8144 11.2668 44.2937 11.2668C40.7729 11.2668 37.9188 14.2358 37.9188 17.8981C37.9188 19.4687 38.4437 20.9118 39.3211 22.0479C39.8758 22.7663 40.5714 23.3619 41.3643 23.7893C40.5881 24.379 39.9202 25.0033 39.3686 25.6554C38.8221 26.3014 38.3899 26.9749 38.0795 27.6692L38.0654 27.701H50.6824ZM44.3841 22.427L46.115 21.3923C47.2374 20.7212 48.0364 19.4293 48.0364 17.8981C48.0364 15.5907 46.2639 13.899 44.2937 13.899C42.3235 13.899 40.5509 15.5907 40.5509 17.8981C40.5509 19.494 41.4185 20.8282 42.6134 21.4724L44.3841 22.427Z" fill="black" />
  <path d="M33.1679 23.6515C34.6277 24.6886 35.7749 26.0958 36.3847 27.701H23.7677C24.3884 26.294 25.5096 24.9722 27.0666 23.7893C25.0197 22.6858 23.6211 20.4618 23.6211 17.8981C23.6211 14.2358 26.4752 11.2668 29.9959 11.2668C33.5167 11.2668 36.3708 14.2358 36.3708 17.8981C36.3708 20.3592 35.0819 22.5072 33.1679 23.6515Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M9.62366 27.7225H50.7439V48.3205H9.62366V27.7225ZM33.3365 29.9523H27.8224V34.9773H22.7975V40.4914H27.8224V45.5164H33.3365V40.4914H38.3615V34.9773H33.3365V29.9523Z" fill="black" />
</svg>

const svg_policy_throwable_active = <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <circle cx="30" cy="30" r="29.5" fill="#FFC122" stroke="#FFDC5E" />
  <path d="M23.5954 11.2753C19.077 14.5913 11.7903 21.9249 11.9675 37.4337H14.219C14.4718 30.9203 17.495 21.5531 24.5322 17.5716H26.8432V20.5424C21.4933 22.2833 17.5313 27.2684 17.1079 33.2707H44.9611C44.5412 27.3186 40.6417 22.3667 35.3597 20.5868V11.4044L23.7029 11.2101L23.7012 11.198C23.6958 11.2019 23.6904 11.2059 23.6849 11.2098L23.6128 11.2086L23.5954 11.2753Z" fill="black" />
  <path d="M44.9644 35.3605H17.1047C17.6106 42.8697 23.6539 48.7987 31.0345 48.7987C38.4151 48.7987 44.4584 42.8697 44.9644 35.3605Z" fill="black" />
</svg>

const svg_policy_throwable_inactive = <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
  <circle cx="31" cy="30" r="29.5" fill="#C49233" stroke="#D6A74F" />
  <path d="M24.5954 11.2753C20.077 14.5913 12.7903 21.9249 12.9675 37.4337H15.219C15.4718 30.9203 18.495 21.5531 25.5322 17.5716H27.8432V20.5424C22.4933 22.2833 18.5313 27.2684 18.1079 33.2707H45.9611C45.5412 27.3186 41.6417 22.3667 36.3597 20.5868V11.4044L24.7029 11.2101L24.7012 11.198C24.6958 11.2019 24.6904 11.2059 24.6849 11.2098L24.6128 11.2086L24.5954 11.2753Z" fill="black" />
  <path d="M45.9644 35.3605H18.1047C18.6106 42.8697 24.6539 48.7987 32.0345 48.7987C39.4151 48.7987 45.4584 42.8697 45.9644 35.3605Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M60.9636 30C60.9636 46.5685 47.5322 60 30.9636 60C14.3951 60 0.963623 46.5685 0.963623 30C0.963623 13.4315 14.3951 0 30.9636 0C47.5322 0 60.9636 13.4315 60.9636 30ZM46.7854 49.3574C42.4757 52.8841 36.9668 55 30.9636 55C17.1565 55 5.96362 43.8071 5.96362 30C5.96362 23.9968 8.07952 18.4879 11.6062 14.1782L46.7854 49.3574ZM50.3209 45.8219L15.1417 10.6427C19.4514 7.11593 24.9604 5 30.9636 5C44.7707 5 55.9636 16.1929 55.9636 30C55.9636 36.0032 53.8477 41.5122 50.3209 45.8219Z" fill="#CC1C1C" />
</svg>

export class IngamePolicyButton extends React.Component {
  render() {
    const svgs = {
      "engage-offensive": svg_policy_engage_offensive,
      "engage-defensive": svg_policy_engage_defensive,
      "search-active": svg_policy_search_active,
      "search-inactive": svg_policy_search_inactive,
      "aid-inactive": svg_policy_aid_inactive,
      "aid-essential": svg_policy_aid_essential,
      "aid-unlimited": svg_policy_aid_unlimited,
      "throwable-active": svg_policy_throwable_active,
      "throwable-inactive": svg_policy_throwable_inactive,
    }
    const { group, value, handleClick } = this.props;
    return <button className={"ingamepolicy-button " + group + " " + value} onClick={() => handleClick(value)}>
      {svgs[value]}
    </button>;
  }
}

const INGAME_POLICY_GROUPS = {
  "engage": ["engage-offensive", "engage-defensive"],
  "search": ["search-active", "search-inactive"],
  "aid": ["aid-inactive", "aid-essential", "aid-unlimited"],
  "throwable": ["throwable-active", "throwable-inactive"],
}

export function IngamePolicyButtonGroup(props) {
  const [selecting, setSelecting] = useState(false);
  const { group, current, setCurrent } = props;

  function toggleGroupState(value) {
    if (selecting) {
      setCurrent(value);
      setSelecting(false);
    } else {
      setSelecting(true);
    }
  }

  function buildButtons() {
    return selecting ? INGAME_POLICY_GROUPS[group].map((value) => <IngamePolicyButton group={group} value={value} handleClick={toggleGroupState} />)
      : <IngamePolicyButton group={group} value={current} handleClick={toggleGroupState} />;
  }

  return <div className={"ingamepolicy-button-group " + group}>
    {buildButtons()}
  </div>

}

export function IngamePolicy() {
  const [policyEngage, setPolicyEngage] = useState("engage-defensive");
  const [policySearch, setPolicySearch] = useState("search-inactive");
  const [policyAid, setPolicyAid] = useState("aid-unlimited");
  const [policyThrowable, setPolicyThrowable] = useState("throwable-inactive");
  // i18n ignore 12
  return <div className="ingamepolicy-container">
    <div className="ingamepolicy-log-list">
      <div className="ingamepolicy-log">치료 정책이 <IngamePolicyLogLabel label="비활성" />에서 <IngamePolicyLogLabel label="중상자만 치료" />으로 변화했습니다.</div>
    </div>
    <div className="ingamepolicy-button-group-container">
      <IngamePolicyButtonGroup group="engage" current={policyEngage} setCurrent={setPolicyEngage} />
      <IngamePolicyButtonGroup group="search" current={policySearch} setCurrent={setPolicySearch} />
      <IngamePolicyButtonGroup group="aid" current={policyAid} setCurrent={setPolicyAid} />
      <IngamePolicyButtonGroup group="throwable" current={policyThrowable} setCurrent={setPolicyThrowable} />
    </div>
  </div>

}

export function OutgameHeader(props) {
  const { resource, level, level_tooltip, agents, agents_max, training, training_max, injured } = props;

  return <div className="outgameheader-container">
    <div className="outgameheader-item cash">
      <img className="outgameheader-icon cash" src='/img/overlay2/icon-cash.png' />
      <div className="outgameheader-value cash">{resource}</div>
    </div>
    <div className="outgameheader-item reputation">
      <img className="outgameheader-icon reputation" src='/img/overlay2/icon-reputation.png' />
      <div className="outgameheader-value reputation" title={level_tooltip}>{level}</div>
    </div>
    <div className="outgameheader-item agent">
      <img className="outgameheader-icon agent" src='/img/overlay2/icon-agent.png' />
      <div className="outgameheader-value agent">{agents}<span className="outgameheader-value limit agent">/{agents_max}</span></div>
    </div>
    <div className="outgameheader-item training">
      <img className="outgameheader-icon training" src='/img/overlay2/icon-training.png' />
      <div className="outgameheader-value training">{training}<span className="outgameheader-value limit training">/{training_max}</span></div>
    </div>
    <div className="outgameheader-item injured">
      <img className="outgameheader-icon injured" src='/img/overlay2/icon-injured.png' />
      <div className="outgameheader-value injured">{injured}</div>
    </div>
  </div>
}

export function OutgameAlarm(props) {
  const { alarms } = props;
  const panels = {
    "renew": "/img/overlay2/panel-green-default.png",
    "relocate": "/img/overlay2/panel-red-default.png",
  }
  const panels_hover = {
    "renew": "/img/overlay2/panel-green-focused.png",
    "relocate": "/img/overlay2/panel-red-focused.png",
  }
  const icons = {
    "renew": "/img/overlay2/icon-renew.png",
    "relocate": "/img/overlay2/icon-relocate.png",
  }
  const notifications = alarms.map(function (alarm, i) {
    return <div className={"outgamealarm-item " + alarm.ty} key={i}>
      <img className={"outgamealarm-item panel " + alarm.ty} src={panels[alarm.ty]} />
      <img className={"outgamealarm-item icon " + alarm.ty} src={icons[alarm.ty]} />
    </div>;
  })
  if (notifications.length === 0) {
    return null;
  }
  return <div className="outgamealarm-container">
    {notifications}
  </div>;
}

const outgame = true;

export class FigmaView extends React.Component {
  render() {
    if (!outgame) {
      const agents = [{
        name: "Francisco Mendoza",
        kills: 3,
      },
      {
        name: "Pedro Lira",
        kills: 2,

      },
      {
        name: "Alejandro Moreno",
        kills: 1,
      }];

      return <><div className="ingameoverlay-root">
        <IngameEntity agent={agents[0]} simple={false} state={true} selected={true} isLeader={true} />
        <IngameEntity agent={agents[1]} simple={false} state={false} selected={false} isLeader={false} />
        <IngameEntity agent={agents[2]} simple={true} state={true} selected={false} isLeader={false} />
      </div>
        <div className="ingameprompt-root">
          <IngamePrompt />
        </div>
        <div className="ingamepopup-root">
          <IngamePopup />
        </div>
        <div className="ingamestatus-root">
          <IngameStatus />
        </div>
        <div className="ingamepolicy-root">
          <IngamePolicy />
        </div>
      </>;
    } else {
      const props = {
        resource: 100,
        level: 3,
        agents: 3,
        agents_max: 5,
        training: 2,
        training_max: 3,
        injured: 1,
      };
      return <>
        <OutgameHeader {...props} />
        <OutgameAlarm alarms={[{ ty: "renew" }, { ty: "relocate" }]} />
      </>;
    }
  }

}
