import './App.css';
import React from 'react';

import { presets, preset_default } from './presets_mission.mjs';
import { Simulator } from './sim.mjs';
import { Rng } from './rand.mjs';
import { parseQuery } from './utils.mjs';
import { SimView } from './SimView';

export class WinCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wins: [0, 0],
    };
  };

  addWin(team) {
    const { wins } = this.state;
    wins[team] += 1;
    this.setState({ wins });
  };

  team0Winrate() {
    const { wins } = this.state;
    return (wins[0] === 0) ? 0 : wins[0] / (wins[0] + wins[1]);
  }

  render() {
    const { wins } = this.state;
    return <div>
      <p>Wins</p>
      <p>team0: {wins[0]}</p>
      <p>team1: {wins[1]}</p>
      <p>team0 winrate: {this.team0Winrate()}</p>
    </div>;
  }
}

function instantiate(p, seed) {
  const simstate = presets[p]({ seed });
  simstate.world.preset = p;
  return simstate;
}

export function PresetSelector(props) {
  const { presets, onPreset, disabled } = props;

  const [initialized, setInitialized] = React.useState(false);

  let preset0 = preset_default;
  if (!initialized) {
    let preset1 = window.localStorage.getItem('preset_selected_app');
    window.localStorage.removeItem('preset_selected_app');
    if (preset1) {
      preset0 = preset1;
    }

    const query = parseQuery(window.location.search);
    if (query.preset && presets[query.preset]) {
      preset0 = query.preset;
    }
  }

  const [preset, setPreset] = React.useState(preset0);
  const [search_term, onSearch] = React.useState('');

  if (!initialized) {
    setInitialized(true);
    if (!disabled) {
      onPreset(preset, () => {
        window.localStorage.setItem('preset_selected_app', preset);
      });
    }
  }

  const presets1 = Object.keys(presets).filter((p) => {
    if (search_term === '') {
      return true;
    }
    if (p === preset) {
      return true;
    }
    return p.includes(search_term);
  });

  return <div className="box">
    <h1>presets</h1>
    <input type="text" placeholder="search" value={search_term} onChange={(e) => {
      e.stopPropagation();
      onSearch(e.target.value);
    }}></input>
    <br />

    {presets1.map((p) => {
      const cls = (!disabled && p === preset) ? 'selected' : '';
      return <button className={cls} onClick={() => {
        setPreset(p);
        onPreset(p, () => {
          window.localStorage.setItem('preset_selected_app', p);
        });
      }} key={p}>{p}</button>;
    })}
  </div>;
}

export class App extends React.Component {
  constructor(props) {
    super(props);

    const seed = Rng.randomseed();

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();
    this.winCounterRef = React.createRef();

    this.restartTimer = null;

    this.keyBind = this.keyDown.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onSeed = this.onSeed.bind(this);
    this.onPreset = this.onPreset.bind(this);
    this.onFinish = this.onFinish.bind(this);

    this.state = {
      simstate: null,

      seed,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyBind);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyBind);
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport() {
    const state = {
      seed: this.simRef.current.state.seed,
      simstate: { ...this.state.simstate },
    }

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport() {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({
      seed: state.seed,
      simstate: state.simstate,
    }, () => {
      this.simRef.current.restart();
    });
  }

  onSeed(seed) {
    const { preset_selected } = this.state;
    this.setState({
      seed,
      simstate: instantiate(preset_selected, seed),
    }, () => {
      this.simRef.current.restart();
    });
  }

  onPreset(p, cb) {
    const { seed } = this.state;

    this.setState({
      preset_selected: p,
      simstate: instantiate(p, seed),
    }, () => {
      this.simRef.current.restart();
      cb?.();
    });
  }

  onFinish(res) {
    const view = this.simRef.current;

    if (this.restartTimer !== null) {
      return;
    }

    if (res >= 0) {
      this.winCounterRef.current?.addWin(res);
    }

    const newSeed = Rng.randomseed();

    view.stopTimer();
    this.restartTimer = setTimeout(() => {
      const { preset_selected } = this.state;
      this.setState({
        seed: newSeed,
        simstate: instantiate(preset_selected, newSeed),
      }, () => {
        view.restart();
        this.restartTimer = null;
        view.startTimer();
      });
    }, 1000);
  }

  onReload(seed) {
    let simstate = null;
    if (this.serializeRef.current.value) {
      const state = JSON.parse(this.serializeRef.current.value);
      simstate = state.simstate;
    } else {
      const { preset_selected } = this.state;
      simstate = instantiate(preset_selected, seed);
    }

    this.setState({ seed, simstate });
    return simstate;
  }

  onOfflineSim() {
    // TODO: change seed
    // const seed = this.state.seed;
    const seed = Rng.randomseed();
    const view = this.simRef.current;

    const sim = Simulator.create({ ...view.props, seed, m: this.props.m });
    // reset runtime seed
    const seed2 = Rng.randomseed();
    sim.rng = new Rng(seed2);

    // console.log('before', sim);
    const start = Date.now();
    while (sim.onTick() === -1 && sim.tick < 10000) { }
    const res = sim.onTick();
    if (res >= 0) {
      this.winCounterRef.current.addWin(res);
    }
    sim.free();
    const dt = Date.now() - start;
    console.log(`took ${dt}ms, ${(sim.tick * 1000 / dt).toFixed(1)}tps`, res, sim);

    return res;
  }

  onOfflineSimStat(samples) {
    for (let i = 0; i < samples; i++) {
      this.onOfflineSim();
    }
  }

  keyDown(ev) {
    if (ev.key === 'b') {
      this.onOfflineSim();
    } else if (ev.key === 'B') {
      this.onOfflineSimStat(100);
    }
  }

  render() {
    const { search_term, preset_selected, simstate, seed } = this.state;

    const presets1 = Object.keys(presets).filter((p) => {
      if (search_term === '') {
        return true;
      }
      if (p === preset_selected) {
        return true;
      }
      return p.includes(search_term);
    });

    let simview = null;

    if (simstate) {
      simview = <SimView ref={this.simRef} m={this.props.m}
        seed={seed}
        debug={true}
        onFinish={(res) => this.onFinish(res)}
        onReload={this.onReload.bind(this)}
        simstate={simstate}
      />;
    }

    return <>
      <p>
        shortcuts:
        (<strong>r</strong>)estart new seed
        /
        (<strong>R</strong>)estart same seed
        /
        (<strong>z</strong>)oom
        /
        (<strong>1/2/3</strong>) change speed
        /
        (<strong>space</strong>) pause·resume
        /
        (<strong>s</strong>)tep</p>
      <PresetSelector presets={presets} onPreset={(p, cb) => this.onPreset(p, cb)} />

      <div>
        save&load
        <button onClick={this.onExport}>export</button>
        <button onClick={this.onImport}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <button onClick={() => { this.onSeed(seed) }}>reload with seed:</button>
      <input type="text" placeholder="seed" value={seed} onChange={(e) => this.setState({ seed: parseInt(e.target.value, 10) || 0 })}></input>
      <button onClick={() => { this.onSeed(Rng.randomseed()) }}> randomize </button>
      <button onClick={() => { this.onSeed(seed + 1) }}>&nbsp;+&nbsp;</button>
      <button onClick={() => { this.onSeed(seed - 1) }}>&nbsp;-&nbsp;</button>
      {simview}
      <WinCounter ref={this.winCounterRef} />
    </>;
  }
}
