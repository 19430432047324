import React from 'react';

import './FigmaMissionResultView.css';

import { triggerSound } from './sound.mjs';
import { L } from './localization.mjs';
import { svg_icon_neutralized } from './FigmaIngameView.js';
import { opts } from './opts.mjs';
import { PortraitWrapper } from './PortraitsView';
import { RenderAgentSimple } from './RenderAgentSimple.js';
import { DetailButton, RoleBadge } from './FigmaListView.js';

export class FigmaMissionResultRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className='figmamissionresult-testroot'>
        <FigmaMissionResultView />
      </div>
    );
  }
}

const svg_tierIcon_background = <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5 1.78261L19.45 5.60885L25.6244 9.03261L25.4 16.2826L25.6244 23.5326L19.45 26.9564L13.5 30.7826L7.55 26.9564L1.37564 23.5326L1.6 16.2826L1.37564 9.03261L7.55 5.60885L13.5 1.78261Z" fill="#734D41" />
  <path d="M13.7704 1.36206L13.5 1.18815L13.2296 1.36206L7.29327 5.17948L1.13317 8.59534L0.866451 8.74324L0.875884 9.04807L1.09976 16.2826L0.875884 23.5171L0.866451 23.822L1.13317 23.9699L7.29326 27.3857L13.2296 31.2032L13.5 31.3771L13.7704 31.2032L19.7067 27.3857L25.8668 23.9699L26.1335 23.822L26.1241 23.5171L25.9002 16.2826L26.1241 9.04807L26.1335 8.74324L25.8668 8.59534L19.7067 5.17948L13.7704 1.36206Z" stroke="black" strokeOpacity="0.4" />
  <path d="M4.1077 10.5981L13.5 4.94952L22.8923 10.5981V21.9671L13.5 27.6157L4.1077 21.9671V10.5981Z" stroke="#88614B" stroke-width="2" />
</svg>;

function SvgTierIcon(props) {
  const { tier } = props;

  return <div className='figmamissionresult-personal-statistics-agent-tiericon'>
    <div className='figmamissionresult-personal-statistics-agent-tiericon-background'> {svg_tierIcon_background}</div>
    <div className='figmamissionresult-personal-statistics-agent-tiericon-text'> {tier}</div>
  </div>
}

const svg_icon_firearm_default = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
  <rect width="26" height="26" transform="translate(0 0.565216)" fill="#362E1A" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3.71729 18.4541L6.19631 11.7402V10.719C7.4472 10.4707 8.40292 9.69771 8.40292 7.99561L22.2828 7.99561L21.6426 12.4211H14.9127L13.8094 15.4848H10.7266L9.87399 19.1349L3.71729 18.4541ZM10.9335 14.1232H12.7491L13.3621 12.4211H11.2497L10.9335 14.1232Z" fill="#E3CD97" />
</svg>;

const svg_icon_armor_default = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
  <rect width="26" height="26" transform="translate(0 0.565216)" fill="#362E1A" />
  <path d="M17.59 5.66782L17.158 9.43818C17.446 10.2194 18.238 11.7819 19.318 12.1895V20.1379L18.022 21.4626L7.97812 21.4626L6.68213 20.1379V12.1896C7.76212 11.782 8.55411 10.2195 8.84211 9.43822L8.41011 5.66785L10.4621 6.17736L10.5701 9.33631C11.0959 10.4277 12.3042 10.6497 12.9988 10.6606C13.6931 10.6501 14.9036 10.4289 15.43 9.33628L15.538 6.17732L17.59 5.66782Z" fill="#E3CD97" />
</svg>;

const svg_icon_throwable_default = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
  <rect width="26" height="26" transform="translate(0 0.565216)" fill="#362E1A" />
  <path d="M9.93448 6.30887L10.079 9.19565C7.89156 9.97784 6.28733 11.9442 6.07777 14.3011H18.2902C18.0856 11.9996 16.551 10.0705 14.4422 9.25274V8.20183H16.5935C17.3494 9.13255 18.4577 10.737 18.9639 12.9907L19.9265 12.9342C19.9265 10.0948 18.3452 7.08122 17.6683 6.30887H9.93448Z" fill="#E3CD97" />
  <path d="M6.07349 15.3027H18.2945C18.0466 18.391 15.4055 20.8216 12.184 20.8216C8.96242 20.8216 6.32133 18.391 6.07349 15.3027Z" fill="#E3CD97" />
</svg>;

const svg_icon_firearm_dealed = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect width="24" height="24" transform="translate(0 0.282608)" fill="#032D54" />
  <path fillRule="evenodd" clipRule="evenodd" d="M1.66541 17.5492L4.33598 10.3166V9.21639C5.68353 8.94897 6.71309 8.11622 6.71309 6.28261L21.6654 6.28261L20.9757 11.05H13.7259L12.5373 14.3505H9.21628L8.29783 18.2826L1.66541 17.5492ZM9.43923 12.8836H11.3951L12.0554 11.05H9.77986L9.43923 12.8836Z" fill="#5DA5E7" />
</svg>;

const svg_icon_throwable_dealed = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect width="24" height="24" transform="translate(0 0.282608)" fill="#032D54" />
  <path d="M8.67103 4.83688L8.83274 8.06745C6.38481 8.94279 4.58954 11.1433 4.35501 13.7809H18.0218C17.7928 11.2053 16.0756 9.04646 13.7156 8.13134V6.95527H16.1231C16.969 7.99684 18.2093 9.79239 18.7758 12.3144L19.853 12.2513C19.853 9.07367 18.0834 5.7012 17.3259 4.83688H8.67103Z" fill="#5DA5E7" />
  <path d="M4.35022 14.9018H18.0266C17.7493 18.3579 14.7937 21.0779 11.1884 21.0779C7.5832 21.0779 4.62758 18.3579 4.35022 14.9018Z" fill="#5DA5E7" />
</svg>;

const svg_icon_health_damaged = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect width="24" height="24" transform="translate(0 0.282608)" fill="#4D0E0A" />
  <path fillRule="evenodd" clipRule="evenodd" d="M16.2926 4.28446L13 8.28261L15.0588 9.11594L13 13.2826L18.7647 8.28261L16.2941 7.44927L18.6169 5.36004C19.4697 6.17455 20.0098 7.36206 20.0098 8.62501C20.0098 9.70084 19.6179 10.6814 18.9746 11.4207L12 20.2924L5.02552 11.4209C4.38214 10.6816 3.99023 9.70091 3.99023 8.62501C3.99023 6.32368 5.78328 4.27284 7.99512 4.27284C10.207 4.27284 12 6.16388 12 7.34724C12 6.21121 13.793 4.27284 16.0049 4.27284C16.1016 4.27284 16.1976 4.27677 16.2926 4.28446Z" fill="#E05031" />
</svg>;

const svg_icon_health_healed = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M7.99512 4.37439C10.207 4.37439 12 6.26542 12 7.44879C12 6.31276 13.793 4.37439 16.0049 4.37439C18.2167 4.37439 20.0098 6.42523 20.0098 8.72655C20.0098 9.80239 19.6179 10.783 18.9746 11.5223L12 20.3939L5.02552 11.5224C4.38214 10.7831 3.99023 9.80246 3.99023 8.72655C3.99023 6.42523 5.78328 4.37439 7.99512 4.37439ZM11 10.1915V7.69153H13V10.1915H15.5V12.1915H13V14.6915H11V12.1915H8.5V10.1915H11Z" fill="#2E8A0E" />
</svg>;

const svg_icon_shield_damaged = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect width="24" height="24" transform="translate(0 0.282608)" fill="#540803" />
  <path fillRule="evenodd" clipRule="evenodd" d="M15.3114 4.26157L12 8.2826L14.0588 9.11593L12 13.2826L17.7647 8.2826L15.2941 7.44927L18.605 4.47124V10.4568C18.605 13.8703 15.6027 18.6739 12 20.3036C8.39728 18.6739 5.39502 13.8703 5.39502 10.4568V4.26157H15.3114Z" fill="#E05031" />
</svg>;

const svg_icon_armor_damaged = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect width="24" height="24" transform="translate(0 0.282608)" fill="#540803" />
  <path fillRule="evenodd" clipRule="evenodd" d="M6.61849 6.54098L5.13257 7.68805V19.1238C7.26908 20.322 10.601 20.6216 11.9999 20.6216C13.3988 20.6216 16.7308 20.322 18.8673 19.1238V10.5634L13.3726 15.3292L15.4315 11.1625L13.3726 10.3292L17.365 5.48131L17.3412 3.94366H14.7107L14.3292 7.68805H9.67066L9.28913 3.94366H6.65865L6.61849 6.54098ZM18.7647 7.60883L16.6668 9.49585L18.8673 10.2381V7.68805L18.7647 7.60883Z" fill="#E05031" />
</svg>;

const svg_icon_power = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M3.37036 13.1377L5.95104 2.38484L13.263 1.0945L10.2522 8.83653L15.8436 7.9763L6.38115 21.3098L8.53172 12.2774L3.37036 13.1377Z" fill="#E78B00" />
</svg>;

const svg_divider = <svg xmlns="http://www.w3.org/2000/svg" width="2" height="163" viewBox="0 0 2 163" fill="none">
  <path d="M1 0.782608V162.783" stroke="#3B301E" strokeWidth="2" />
</svg>;

const svg_icon_death = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="31" viewBox="0 0 28 31" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.08198 26.4561C4.0623 26.5973 4.04204 26.099 3.9592 24.0616C3.94292 23.6607 3.92416 23.2 3.88731 22.673C3.79865 21.4052 3.37817 20.8321 2.87034 20.14C2.09441 19.0827 1.11383 17.7464 0.797758 13.2267C-0.265995 -1.98458 26.6684 -3.86802 27.732 11.3433C28.048 15.863 27.2627 17.3231 26.6415 18.4782C26.2349 19.2342 25.8985 19.8598 25.9872 21.1277C26.024 21.6546 26.0696 22.1134 26.1092 22.5127C26.3108 24.5418 26.36 25.0381 24.3404 25.1793C21.9232 25.3484 22.0726 27.4851 22.1722 28.9096C22.2718 30.3341 19.0129 30.562 15.5598 30.8034C12.1068 31.0449 8.8478 31.2728 8.74819 29.8483C8.64858 28.4238 8.49916 26.2871 6.08198 26.4561ZM9.56562 23.7043C11.6575 23.558 13.2231 21.5785 13.0626 19.2829C12.9021 16.9874 11.0762 15.245 8.98432 15.3913C6.89245 15.5375 5.32682 17.5171 5.48734 19.8126C5.64786 22.1082 7.47376 23.8506 9.56562 23.7043ZM17.7521 25.8684C17.8042 26.6121 17.2862 27.3413 16.5109 27.3955C15.973 27.4331 15.5521 27.1516 15.275 26.7299C15.0593 27.186 14.6817 27.5234 14.1437 27.561C13.3683 27.6152 12.754 26.9651 12.702 26.2216C12.5907 24.6298 15.0366 23.3217 15.0366 23.3217C15.0366 23.3217 17.6361 24.2082 17.7521 25.8684ZM20.5077 22.9391C22.5995 22.7929 24.1651 20.8133 24.0046 18.5178C23.8441 16.2222 22.0182 14.4798 19.9263 14.6261C17.8345 14.7724 16.2689 16.7519 16.4294 19.0475C16.5899 21.343 18.4158 23.0854 20.5077 22.9391Z" fill="#981111" />
</svg>;

const svg_rectangle_death = <svg xmlns="http://www.w3.org/2000/svg" width="276" height="73" viewBox="0 0 276 73" fill="none">
  <path d="M0 20.9348L276 0.934814V52.9348L0 72.9348V20.9348Z" fill="#660000" />
</svg>;

const svg_icon_clock = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <circle cx="12.7778" cy="12.9348" r="9" stroke="#588BA1" strokeWidth="2" />
  <path d="M12.7778 13.4348L12.7778 5.93481" stroke="#588BA1" strokeWidth="2" />
  <path d="M12.7778 12.9348L9.77783 16.9348" stroke="#588BA1" strokeWidth="2" />
</svg>;

function SvgAgentNumber(props) {
  const { agentNum } = props;
  switch (agentNum) {
    case 1:
      return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50" fill="none">
        <path d="M20 48.3344L1.5 39.409V1.5H38.5V39.409L20 48.3344Z" fill="#AC9C71" stroke="#C9B787" stroke-width="3" />
        <path d="M16.6742 17.9304H13.9612V14.487L18.0047 10.4435H22.6481V27.2696H24.8394V32.1739H13.9612V27.2696H16.6742V17.9304Z" fill="#372923" />
      </svg>;
    case 2:
      return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="51" viewBox="0 0 40 51" fill="none">
        <path d="M20 48.8997L1.5 39.9743V2.06522H38.5V39.9743L20 48.8997Z" fill="#AC9C71" stroke="#C9B787" stroke-width="3" />
        <path d="M20.1532 27.1043H27.0401V32.7391H11.3879V27.6783L21.0662 18V16.5913L20.3619 15.887H18.0662L17.3619 16.5913V18.7826H11.3879V14.4783L14.8575 10.9826H23.5705L27.0401 14.4783V20.2174L20.1532 27.1043Z" fill="#372923" />
      </svg>;
    case 3:
      return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="51" viewBox="0 0 40 51" fill="none">
        <path d="M20 48.6823L1.5 39.7569V1.84784H38.5V39.7569L20 48.6823Z" fill="#AC9C71" stroke="#C9B787" stroke-width="3" />
        <path d="M18.1029 19.1391H20.8159L21.4942 18.4609V16.3739L20.8159 15.6696H17.6333L16.929 16.3739V17.8609H10.9551V14.2609L14.4246 10.7652H23.9986L27.4681 14.2609V20.7044L26.5551 21.6435L27.4681 22.5826V29.0522L23.9986 32.5217H14.4246L10.9551 29.0522V25.8696H16.929V26.9131L17.6333 27.6174H20.8159L21.4942 26.9391V24.8522L20.8159 24.1478H18.1029V19.1391Z" fill="#372923" />
      </svg>
    case 4:
      return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="51" viewBox="0 0 40 51" fill="none">
        <path d="M20 48.6823L1.5 39.7569V1.84784H38.5V39.7569L20 48.6823Z" fill="#AC9C71" stroke="#C9B787" stroke-width="3" />
        <path d="M26.4318 32.313H18.2927V27.8522H19.5971V25.7913H11.4058V19.713L20.1449 11H25.1275V21.3304H27.3971V25.7913H25.1275V27.8522H26.4318V32.313ZM19.3623 17.8348L16.1014 21.0957H19.3623V17.8348Z" fill="#372923" />
      </svg>
    default:
      return null;
  }
}

function svgIconCheckbox(filled) {
  return <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.27783" y="1.93481" width="28" height="28" rx="14" stroke="#694B34" strokeWidth="2" />
    {filled ? <>
      <circle cx="15.2778" cy="15.9348" r="11.5" fill="#BBCB09" stroke="#649300" />
      <path d="M9.27783 15.4348L13.7778 19.4348L21.2778 10.9348" stroke="#42801B" strokeWidth="4" />
    </> : null}
  </svg>;
}

function toMMSS(tick) {
  const seconds = Math.floor(tick / opts.tps);
  if (seconds >= 3600) {
    return '60:00';
  } else if (seconds <= 0) {
    return '00:00';
  }
  const mm = String(Math.floor(seconds / 60)).padStart(2, '0');
  const ss = String(seconds % 60).padStart(2, '0');
  return `${mm}:${ss}`;
}

function FigmaSegmentReportView(props) {
  const { segments_report, onSegmentSelected, selectedSegmentIndex, isCumulative } = props;

  const segment_panels = [];

  for (let i = 0; i < segments_report.length; i++) {
    const report = segments_report[i];
    const isActive = isCumulative ? i <= selectedSegmentIndex : selectedSegmentIndex === i;
    segment_panels.push(
      <div
        key={i}
        className={'figmamissionresult-segment-panel' + (isActive ? ' active' : '')}
        onClick={() => {
          onSegmentSelected(i);
          triggerSound('UI_Outgame_Button_Click_Default');
        }}
      >
        <div className={'figmamissionresult-segment-panel-title' + (isActive ? ' active' : '')}>{L('loc_ui_string_mission_plan_section_individual', { value: i + 1 })}</div>
        <div className='figmamissionresult-segment-panel-content'>
          <div className='figmamissionresult-segment-panel-content-row-1'>
            <div className='figmamissionresult-segment-panel-content-progress'>
              {svg_icon_neutralized}
              <span className='figmamissionresult-segment-panel-content-progress-achieved'>{report.killed}</span>
              <span className='figmamissionresult-segment-panel-content-progress-total'>/{report.enemies}</span>
            </div>
            <div className='figmamissionresult-segment-panel-content-divider' />
            <div className='figmamissionresult-segment-panel-content-time'>
              {svg_icon_clock}
              {toMMSS(report.tick)}
            </div>
          </div>
          <div className='figmamissionresult-segment-panel-content-row-2'>
            <span className='figmamissionresult-segment-panel-content-survival-desc'>{L('loc_ui_string_result_survival_rate')}</span>
            <span className={'figmamissionresult-segment-panel-content-survival-value' + (report.survived === report.allies_total ? ' survived' : '')}>{(report.survived * 100 / report.allies_total).toFixed(2)}%</span>
          </div>
        </div>
        <div className='figmamissionresult-segment-panel-checkbox'>
          {svgIconCheckbox(isActive)}
        </div>
      </div>
    );
  }

  return <div className='figmamissionresult-segment-report'>{segment_panels}</div>;
}

function throwables_text(agent, throwables) {
  const throwables_text = [];
  const throwables_all = agent.throwables.map(t => t.throwable_name);
  const throwables_used = throwables.filter(t => t.agent_idx === agent.idx).map(t => t.throwable_name);

  for (const throwable of throwables_used) {
    const idx = throwables_all.indexOf(throwable);
    if (idx > -1) throwables_all.splice(idx, 1);
    throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_used'));
  }

  for (const throwable of throwables_all) {
    if (throwable !== 'None') {
      throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_unused'));
    }
  }

  return throwables_text.join('\n');
}

function DeathIndicator(props) {
  const { report } = props;
  if (report.life > 0) {
    return null;
  }

  return <div className='figmamissionresult-personal-statistics-agent-deathindicator'>
    <div className='figmamissionresult-personal-statistics-agent-deathindicator-background' />
    <div className='figmamissionresult-personal-statistics-agent-deathindicator-foreground'>
      <div className='figmamissionresult-personal-statistics-agent-deathindicator-foreground-rectangle'> {svg_rectangle_death} </div>
      <div className='figmamissionresult-personal-statistics-agent-deathindicator-foreground-text'> {L('loc_ui_string_ingame_agentcard_kia')} </div>
      <div className='figmamissionresult-personal-statistics-agent-deathindicator-foreground-icon'> {svg_icon_death} </div>
    </div>
  </div>
}

function GaugeHeader(props) {
  const { damages, type } = props;

  const damageIcons = {
    'dealed': [svg_icon_firearm_dealed, svg_icon_throwable_dealed],
    'damaged': [svg_icon_health_damaged, svg_icon_shield_damaged, svg_icon_armor_damaged],
    'healed': [svg_icon_health_healed]
  }

  let components = [];
  for (let i = 0; i < damages.length; i++) {
    components.push(<div key={`${type}_${i}`} className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-textwithicon'>
      {damageIcons[type][i]}
      <span className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-text'> {damages[i].toFixed(0)}</span>
    </div>)
  }

  return <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-header'>
    {components}
  </div>
}

function Gauges(props) {
  const { damages, maxDamage, type } = props;

  const bgBorderColor = {
    'dealed': '#032D54',
    'damaged': '#4D0E0A',
    'healed': '#0D3001'
  };

  const fgColors = {
    'dealed': ['#0077E4', '#409EF5'],
    'damaged': ['#E63B16', '#FE6543', '#FFA96B'],
    'healed': ['#2E8A0E']
  }

  let beforeWidth = 0;
  let gauges = [];
  for (let i = 0; i < damages.length; i++) {
    const currentWidth = (damages[i] / maxDamage) * 100
    gauges.push(<div key={`${type}_${i}`} className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge-foreground'
      style={{ width: `${currentWidth}%`, left: `${beforeWidth}%`, background: fgColors[type][i] }} />);
    beforeWidth += currentWidth;
  }
  return <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge'>
    <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge-background'
      style={{ border: `1px solid ${bgBorderColor[type]}` }} />
    {gauges}
  </div>
}

function DamageIndicator(props) {
  const { damages, maxDamage, type } = props;

  const titleText = {
    'dealed': 'loc_ui_string_agent_statistics_damage_given',
    'damaged': 'loc_ui_string_agent_statistics_damage_taken',
    'healed': 'loc_ui_string_result_statistics_healed'
  };

  const titleColor = {
    'dealed': '#5DA5E7',
    'damaged': '#E16043',
    'healed': '#86B755'
  }

  const totalDamage = damages.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  return <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator'>
    <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-total'>
      <span className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-total-text'> {L(titleText[type])}</span>
      <span className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-total-amount'
        style={{ color: titleColor[type] }}>  {totalDamage.toFixed(0)} </span>
    </div>
    <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail'>
      <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-frame'>
        <GaugeHeader damages={damages} type={type} />
        <Gauges damages={damages} maxDamage={maxDamage} type={type} />
      </div>
    </div>
  </div>
}

function getDamagesOfAgent(segments_report, id, selectedSegmentIndex, isCumulative) {
  if (isCumulative || selectedSegmentIndex === 0) {
    const report = segments_report[selectedSegmentIndex].allies_detail[id];
    return {
      damages_done: [report.damage_done.firearm, report.damage_done.throwable],
      damages_taken: [report.damage_taken.life, report.damage_taken.shield, report.damage_taken.armor],
      damages_healed: [report.healed],
    }
  }

  const pre_report = segments_report[selectedSegmentIndex - 1].allies_detail[id];
  const report = segments_report[selectedSegmentIndex].allies_detail[id];
  const totaldamage_done_firearm = report.damage_done.firearm - pre_report.damage_done.firearm;
  const totaldamage_done_throwable = report.damage_done.throwable - pre_report.damage_done.throwable;
  const totaldamage_taken_life = report.damage_taken.life - pre_report.damage_taken.life;
  const totaldamage_taken_shield = report.damage_taken.shield - pre_report.damage_taken.shield;
  const totaldamage_taken_armor = report.damage_taken.armor - pre_report.damage_taken.armor;
  const totaldamage_healed = report.healed - pre_report.healed;
  return {
    damages_done: [totaldamage_done_firearm, totaldamage_done_throwable],
    damages_taken: [totaldamage_taken_life, totaldamage_taken_shield, totaldamage_taken_armor],
    damages_healed: [totaldamage_healed],
  }
}

function getKillOfAgent(segments_report, id, selectedSegmentIndex, isCumulative) {
  if (isCumulative || selectedSegmentIndex === 0) {
    const report = segments_report[selectedSegmentIndex].allies_detail[id];
    return report.kill;
  }

  const pre_report = segments_report[selectedSegmentIndex - 1].allies_detail[id];
  const report = segments_report[selectedSegmentIndex].allies_detail[id];

  return report.kill - pre_report.kill;
}

function FigmaPersonalStatisticsView(props) {
  const { segments_report, agent, id, selectedSegmentIndex, isCumulative, throwables, agentNum, maxDamages } = props;
  const report = segments_report[selectedSegmentIndex].allies_detail[id];

  const damages = getDamagesOfAgent(segments_report, id, selectedSegmentIndex, isCumulative);
  const gauge_healed = (damages.damages_healed > 0) ? <DamageIndicator damages={damages.damages_healed} maxDamage={maxDamages.damage_healed} type='healed' /> : null;

  return <div key={id} className='figmamissionresult-personal-statistics'>
    <div className='figmamissionresult-personal-statistics-agent-frame'>
      <div className='figmamissionresult-personal-statistics-agent'>
        <div className='figmamissionresult-personal-statistics-agent-up'>
          <div className='figmamissionresult-personal-statistics-agent-up-frame'>
            <RenderAgentSimple agent={agent} />
          </div>
        </div>
        <div className='figmamissionresult-personal-statistics-agent-down'>
          <div className='figmamissionresult-personal-statistics-agent-gears'>
            <div className='figmamissionresult-personal-statistics-agent-gears-firearm'>
              <div className='figmamissionresult-personal-statistics-agent-gears-firearm-icon'>
                {svg_icon_firearm_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-firearm-detail'>
                <div className='figmamissionresult-personal-statistics-agent-gears-firearm-role'>{agent.firearm.firearm_ty}</div>
                <div className='figmamissionresult-personal-statistics-agent-gears-firearm-name'>{L(agent.firearm.firearm_name)}</div>
              </div>
            </div>
            <div className='figmamissionresult-personal-statistics-agent-gears-armor'>
              <div className='figmamissionresult-personal-statistics-agent-gears-armor-icon'>
                {svg_icon_armor_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-armor-name'>
                {L(agent.equipment.vest_name)}
              </div>
            </div>
            <div className='figmamissionresult-personal-statistics-agent-gears-throwable'>
              <div className='figmamissionresult-personal-statistics-agent-gears-throwable-icon'>
                {svg_icon_throwable_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-throwable-name'>
                {throwables_text(agent, throwables)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeathIndicator report={report} />
    </div>
    <div className='figmamissionresult-personal-statistics-agentdetail'>
      <div className='figmamissionresult-personal-statistics-agentdetail-kill'>
        <div className='figmamissionresult-personal-statistics-agentdetail-kill-header'> {L('loc_ui_string_result_statistics_kill_count')}</div>
        <div className='figmamissionresult-personal-statistics-agentdetail-kill-content'>
          {getKillOfAgent(segments_report, id, selectedSegmentIndex, isCumulative)}
        </div>
      </div>
      <div className='figmamissionresult-personal-statistics-agentdetail-divider'> {svg_divider}</div>
      <div className='figmamissionresult-personal-statistics-agentdetail-dealing'>
        <div className='figmamissionresult-personal-statistics-agentdetail-dealing-frame'>
          <DamageIndicator damages={damages.damages_done} maxDamage={maxDamages.damage_done} type='dealed' />
          <DamageIndicator damages={damages.damages_taken} maxDamage={maxDamages.damage_taken} type='damaged' />
          {gauge_healed}
        </div>
      </div>
    </div>
  </div>
}

function FigmaPersonalExpView(props) {
  const { segments_report, agent, id, throwables, agentNum, exp, exp_max } = props;
  const report = segments_report[segments_report.length - 1].allies_detail[id];

  const columns = {
    'damage_done': {
      color: '#E63B16',
      suffix: 'dmg',
      label: L("loc_ui_string_agent_damage_done"),
    },
    'damage_taken': {
      color: '#0077E4',
      suffix: 'dmg',
      label: L("loc_ui_string_agent_damage_taken"),
    },
    'kill': {
      color: '#FF00FF',
      suffix: L("loc_ui_string_agent_kill_suffix"),
      label: L("loc_ui_string_agent_kill"),
    },
    'heal': {
      color: '#2E8A0E',
      suffix: 'pt',
      label: L("loc_ui_string_agent_heal"),
    },
    'survived': {
      color: '#FFFF00',
      suffix: L("loc_ui_string_agent_survived_suffix"),
      label: L("loc_ui_string_agent_survived"),
    },
    'squad': {
      color: '#FFA500',
      suffix: L("loc_ui_string_agent_squad_suffix"),
      label: L("loc_ui_string_agent_squad"),
    },
    'final': {
      color: '#9400D3',
      suffix: L("loc_ui_string_agent_final_suffix"),
      label: L("loc_ui_string_agent_final"),
    },
  };

  let beforeWidth = 0;
  let gauges = [];
  for (const [key, { value, max, origin, rank }] of Object.entries(exp.detail)) {
    const backgroundWidth = (max / exp_max) * 100;
    const currentWidth = (value / exp_max) * 100;
    const { color, suffix, label } = columns[key];
    gauges.push(<div key={key} className='figmamissionresult-personal-exp-gauge-background'
      style={{ width: `${backgroundWidth}%`, left: `${beforeWidth}%` }}>
      <div key={key} className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge-foreground'
        style={{ width: `${currentWidth}%`, background: color }}>
      </div>
      <span className='figmamissionresult-personal-exp-text' style={{ color }}>{label}</span>
      {origin ? <span className='figmamissionresult-personal-exp-text' style={{ color }}>{origin} {suffix} {rank >= 0 ? L('loc_dynamic_string_result_rank_ordinal', { count: rank + 1 }) : ''}</span> : null}
      <span className='figmamissionresult-personal-exp-amount'>{value} exp</span>
    </div>);
    beforeWidth += backgroundWidth;
  }

  return <div key={id} className='figmamissionresult-personal-statistics'>
    <div className='figmamissionresult-personal-statistics-agent-frame'>
      <div className='figmamissionresult-personal-statistics-agent'>
        <div className='figmamissionresult-personal-statistics-agent-up'>
          <div className='figmamissionresult-personal-statistics-agent-up-frame'>
            <SvgAgentNumber agentNum={agentNum} className='figmamissionresult-personal-statistics-agent-agentnumbericon' />
            <div className='figmamissionresult-personal-statistics-agent-up-agentdata'>
              <div className='figmamissionresult-personal-statistics-agent-portraitframe'>
                <PortraitWrapper agent={{ name: agent.name, role: agent.role }} className="portrait-frame-missionreport" />
              </div>
              <div className='figmamissionresult-personal-statistics-agent-up-roleandname'>
                <RoleBadge role={agent.role} noTooltip={true} isLabel={true} />
                <div className='figmamissionresult-personal-statistics-agent-up-name'>
                  <div className='figmamissionresult-personal-statistics-agent-up-tier'>
                    <SvgTierIcon tier={agent.tier} />
                  </div>
                  <div className='figmamissionresult-personal-statistics-agent-up-nametext-frame'>
                    <div className='figmamissionresult-personal-statistics-agent-up-nametext-text'>{agent.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='figmamissionresult-personal-statistics-agent-down'>
          <div className='figmamissionresult-personal-statistics-agent-gears'>
            <div className='figmamissionresult-personal-statistics-agent-gears-firearm'>
              <div className='figmamissionresult-personal-statistics-agent-gears-firearm-icon'>
                {svg_icon_firearm_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-firearm-detail'>
                <div className='figmamissionresult-personal-statistics-agent-gears-firearm-role'>{agent.firearm.firearm_ty}</div>
                <div className='figmamissionresult-personal-statistics-agent-gears-firearm-name'>{L(agent.firearm.firearm_name)}</div>
              </div>
            </div>
            <div className='figmamissionresult-personal-statistics-agent-gears-armor'>
              <div className='figmamissionresult-personal-statistics-agent-gears-armor-icon'>
                {svg_icon_armor_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-armor-name'>
                {L(agent.equipment.vest_name)}
              </div>
            </div>
            <div className='figmamissionresult-personal-statistics-agent-gears-throwable'>
              <div className='figmamissionresult-personal-statistics-agent-gears-throwable-icon'>
                {svg_icon_throwable_default}
              </div>
              <div className='figmamissionresult-personal-statistics-agent-gears-throwable-name'>
                {throwables_text(agent, throwables)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeathIndicator report={report} />
    </div>
    <div className='figmamissionresult-personal-statistics-agentdetail-dealing'>
      <div className='figmamissionresult-personal-statistics-agentdetail-dealing-frame'>
        <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail'>
          <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-frame'>
            <div className='figmamissionresult-personal-exp-gauge'>
              {gauges}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  /*
      <div className='figmamissionresult-personal-exp-frame'>
        {Object.entries(exp.detail).map(([key, { value, max, origin }]) => <div className='figmamissionresult-personal-statistics-agentdetail-damageIndicator'>
          <div className='figmamissionresult-personal-exp-detail'>
            <span className='figmamissionresult-personal-exp-text' style={{ color: columns[key].color }}>{key}</span>
            <span className='figmamissionresult-personal-exp-amount'>{origin}{columns[key].suffix} → {value} exp (max {max} exp)</span>
          </div>
        </div>)}
      </div>
  */


}

function FigmaReportStatisticsView(props) {
  function getMaxDamages(segments_report, entity_ids) {
    const lastIndex = segments_report.length - 1;
    const lastSegment = segments_report[lastIndex];
    let totalDamage_done = 0, totalDamage_dealed = 0, totalDamage_healed = 0;
    for (const id of entity_ids) {
      const report = lastSegment.allies_detail[id];
      totalDamage_done += report.damage_done.firearm + report.damage_done.throwable;
      totalDamage_dealed += report.damage_taken.life + report.damage_taken.shield + report.damage_taken.armor;
      totalDamage_healed += report.healed;
    }

    return {
      damage_done: totalDamage_done * 1.3,
      damage_taken: totalDamage_dealed * 1.3,
      damage_healed: totalDamage_healed * 1.3
    }
  }

  const { segments_report, selectedSegmentIndex, isCumulative, setIsCumulative, agents, entity_ids, throwables } = props;
  const maxDamages = getMaxDamages(segments_report, entity_ids);

  return <div className='figmamissionresult-report-statistics'>
    <div className='figmamissionresult-report-statistics-tabs'>
      <div
        className={'figmamissionresult-report-statistics-tab' + (isCumulative ? '' : ' active')}
        onClick={() => {
          setIsCumulative(false);
          triggerSound('UI_Outgame_Button_Click_Default');
        }}
      >{L('loc_ui_string_result_statistics_section')}</div>
      <div
        className={'figmamissionresult-report-statistics-tab' + (isCumulative ? ' active' : '')}
        onClick={() => {
          setIsCumulative(true);
          triggerSound('UI_Outgame_Button_Click_Default');
        }}
      >{L('loc_ui_string_result_statistics_cumulative')}</div>
    </div>
    <div className='figmamissionresult-report-statistics-content'>
      {entity_ids.map((id, index) =>
        <FigmaPersonalStatisticsView
          segments_report={segments_report}
          agent={agents.find(a => a.idx === id)}
          id={id}
          selectedSegmentIndex={selectedSegmentIndex}
          isCumulative={isCumulative}
          throwables={throwables}
          agentNum={index + 1}
          maxDamages={maxDamages}
        />)
      }
    </div>
  </div>;
}

function FigmaReportExpView(props) {
  function getMaxDamages(segments_report, entity_ids) {
    const lastIndex = segments_report.length - 1;
    const lastSegment = segments_report[lastIndex];
    let totalDamage_done = 0, totalDamage_dealed = 0, totalDamage_healed = 0;
    for (const id of entity_ids) {
      const report = lastSegment.allies_detail[id];
      totalDamage_done += report.damage_done.firearm + report.damage_done.throwable;
      totalDamage_dealed += report.damage_taken.life + report.damage_taken.shield + report.damage_taken.armor;
      totalDamage_healed += report.healed;
    }

    return {
      damage_done: totalDamage_done * 1.3,
      damage_taken: totalDamage_dealed * 1.3,
      damage_healed: totalDamage_healed * 1.3
    }
  }

  const { segments_report, agents, entity_ids, throwables, entity_exp } = props;
  const maxDamages = getMaxDamages(segments_report, entity_ids);

  // TODO: 그래프 안에서 스케일 다르면 이상해보임
  const exp_max = Math.max(...entity_ids.map(id => entity_exp[id].total.max));

  return <div className='figmamissionresult-report-statistics'>
    <div className='figmamissionresult-report-statistics-content'>
      {entity_ids.map((id, index) =>
        <FigmaPersonalExpView
          segments_report={segments_report}
          agent={agents.find(a => a.idx === id)}
          id={id}
          throwables={throwables}
          agentNum={index + 1}
          maxDamages={maxDamages}
          exp={entity_exp[id]}
          exp_max={exp_max}
        />)
      }
    </div>
  </div>;
}

export function FigmaMissionExpView(props) {
  const { game, squad, onClose } = props;

  const segments_report = squad.history[squad.history.length - 1].segments_report;
  const { entity_ids, throwables, entity_exp } = squad.history[squad.history.length - 1];

  return <div className='figmamissionresult-root'>
    <div className='figmamissionresult-title'>{L("loc_ui_string_result_mission_exp")}</div>
    <FigmaReportExpView
      segments_report={segments_report}
      agents={game.agents_avail_all}
      entity_ids={entity_ids}
      throwables={throwables}
      entity_exp={entity_exp}
    />
    {onClose ? <div className='figmamissionresult-segment-footer'>
      <DetailButton label={L('loc_ui_button_common_confirm')} onClick={onClose} />
    </div> : null}
  </div>;
}

export function FigmaMissionResultView(props) {
  const { game, squad, onClose } = props;
  const segments_report = squad.history[squad.history.length - 1].segments_report;

  let segmentIndexDefault = -1;
  for (segmentIndexDefault = 0; segmentIndexDefault < segments_report.length - 1; segmentIndexDefault++) {
    const { survived } = segments_report[segmentIndexDefault];
    if (survived === 0) {
      break;
    }
  }

  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(segmentIndexDefault);
  const [isCumulative, setIsCumulative] = React.useState(true);

  const { entity_ids, throwables, entity_exp } = squad.history[squad.history.length - 1];

  return (
    <div className='figmamissionresult-root'>
      <div className='figmamissionresult-title'>{L('loc_ui_string_result_mission_evaluation')}</div>
      <FigmaSegmentReportView
        segments_report={segments_report}
        onSegmentSelected={setSelectedSegmentIndex}
        selectedSegmentIndex={selectedSegmentIndex}
        isCumulative={isCumulative}
      />
      {selectedSegmentIndex >= 0 ?
        <FigmaReportStatisticsView
          segments_report={segments_report}
          selectedSegmentIndex={selectedSegmentIndex}
          isCumulative={isCumulative}
          setIsCumulative={setIsCumulative}
          agents={game.agents_avail_all}
          entity_ids={entity_ids}
          throwables={throwables}
        />
        :
        <FigmaReportExpView
          segments_report={segments_report}
          agents={game.agents_avail_all}
          entity_ids={entity_ids}
          throwables={throwables}
          entity_exp={entity_exp}
        />
      }
      {onClose ? <div className='figmamissionresult-segment-footer'>
        <DetailButton label={L('loc_ui_button_common_confirm')} onClick={onClose} />
      </div> : null}
    </div>
  );
}
