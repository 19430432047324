import React, { Component, useEffect, useState } from 'react';

import * as gcap from './data/google/processor/data_growthcap.mjs';
import * as pscap from './data/google/processor/data_physicalcap';
import * as agent_training from './data/google/processor/data_training.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as exps from './data/google/processor/data_exp.mjs';
import { perks2 } from './data/google/processor/data_perks2.mjs';
import * as data_stats from './data/google/processor/data_stats.mjs';

import { AgentPerkTree, AgentStatGuages, Stats2TooltipDigits } from './CharacterView';

import { Rng } from './rand.mjs';
import { names3 } from './names';
import { createAgent, agentAvail, agentGrowth, agentChangeApply, agentChangeDescr } from './character.mjs';
import { ProgressBar, roundsign } from './ProgressBar';
import {
  FH1ButtonTiny as Button,
  FH1WellSeg as WellSegment,
} from './component/figma/fh1';
import dayjs from 'dayjs';
import "./TrainingView.css";
import { facilities } from './data/google/processor/data_facilities.mjs';
import {
  trainInfo,
  createSlot,
  createInstructor,
  slotTypeUpdate,
  slotAutomationUpdate,
  slotCancel,
  trainStart,
  trainComplete,
  tickSlots,
} from './training';
import { DEBUG_Instructor_Duplicate_Allow, SessionSlot_Length, TrainingSessionsTemplate, agentStats2GaugeTrainInfo, firearm_ty_keys, generateSummary, perk2_keys, trainPending } from './training.mjs';
import { training2, training2Bykey } from './data/google/processor/data_training2.mjs';
import { STATS2_DESCR } from './stats2.mjs';
import { L } from './localization.mjs';

const AVAIL_RATIO = 1.0;

const TRAINING_REASON_TABLE = {
  'agent-not-assigned': 'loc_ui_longtext_training_reason_agent_not_assigned',
  'instructor-not-assigned': 'loc_ui_longtext_training_reason_instructor_not_assigned',
  // 'insufficient life': 'loc_ui_longtext_training_reason_insufficient_life',
  'power-cap': 'loc_ui_longtext_training_reason_power_cap',
  'power-thres': 'loc_ui_longtext_training_reason_power_thres',
  'physical-cap': 'loc_ui_longtext_training_reason_physical_cap',
  'physical-thres': 'loc_ui_longtext_training_reason_physical_thres',
  'stat2-thres': 'loc_ui_longtext_training_reason_stat2_thres',
  'perk-point': 'loc_ui_longtext_training_reason_perk_point',
  'perk-not-assigned': 'loc_ui_longtext_training_reason_perk_not_assigned',
  'agent-perk-already': 'loc_ui_longtext_training_reason_agent_perk_already',
  'instructor-perk-not-avail': 'loc_ui_longtext_training_reason_instructor_perk_not_avail',
  'agent-aptitude-already': 'loc_ui_longtext_training_reason_agent_aptitude_already',
  'instructor-aptitude-not-avail': 'loc_ui_longtext_training_reason_instructor_aptitude_not_avail',
  'session-not-assigned': 'loc_ui_longtext_training_reason_session_not_assigned',
}

const TRAINING_BONUS_TABLE = {
  'train_duration_all': 'loc_ui_string_training_efficiency_bonus_train_duration_all',
  'train_efficiency_all': 'loc_ui_string_training_efficiency_bonus_train_effeciency_all',
  'train_efficiency_training_adjacent': 'loc_ui_string_training_efficiency_bonus_train_effeciency_training_adjacent',
  'train_efficiency_gym': 'loc_ui_string_training_efficiency_bonus_train_effeciency_gym',

  /*
  'train_efficiency_physical': 'toughness 훈련 효율 ',
  */
}

function tickToDate(tick) {
  return new Date(1970, 0, 1, tick);
}
export function getTrainEfficiencyBonuses(trainGaugeInfo, data, main = true) {
  const value = data.sum;
  const beforeAvailability = data.beforeAvailability;
  const availability = trainGaugeInfo.mult.availability;
  const efficiency_mult = trainGaugeInfo.mult.sum;
  const facility_mult = trainGaugeInfo.mult.facility;
  // i18n ignore 4
  const efficiencyBonuses = [
    `${main ? '총합' : '훈련'}: ${roundsign(beforeAvailability, Stats2TooltipDigits)} * 관리도(${availability}%) = ${roundsign(value, Stats2TooltipDigits)}`,
    `기본 일일 변동치: ${roundsign(data.original, Stats2TooltipDigits)}`
  ];

  if (efficiency_mult !== 1) {
    if (facility_mult !== 1) {
      // i18n ignore 1
      efficiencyBonuses.push(`훈련 시설 효율 보너스 (+${((facility_mult) * 100).toFixed(1)}%): ${roundsign(data.facility - 1, Stats2TooltipDigits)}`);
    }
    efficiencyBonuses.push(...data.modifiers.map(({ key: modifier_key, efficiency, amount }) => {
      // i18n ignore 1
      return `${TRAINING_BONUS_TABLE[modifier_key]} 효율 보너스 (+${(efficiency * 100).toFixed(1)}%): ${roundsign(amount, Stats2TooltipDigits)}`
    }));
  }

  if (main) {
    efficiencyBonuses.splice(1, 0, `-----`);
  } else {
    for (let i = 1; i < efficiencyBonuses.length; i++) {
      efficiencyBonuses[i] = `(${efficiencyBonuses[i]})`;
    }
  }
  return efficiencyBonuses;
}

// components

function Modal(props) {
  const { children, onConfirm, onCancel } = props;

  return <div className="overlay-root" style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, background: "rgba(0,0,0,.6)" }}>
    <div className="overlay" style={{ position: "absolute", width: "fit-contents", height: "fit-contents", background: "white", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
      {/* <div className="modal-header">
        <h2>훈련 시작</h2>
      </div> */}
      <div className="modal-body">
        {children}
      </div>
      <div className="modal-footer">
        <Button onClick={() => { onConfirm() }}>Yes</Button>
        <Button onClick={() => { onCancel() }}>No</Button>
      </div>
    </div>
  </div>;
}

export function TrainSlot(props) {
  const { rng, slot, agents, slots, tick, instructors } = props;
  const { onAgentGrowth, onAgentGoTraining, onAgentCancelTrainingRecurrence, onAgentTrainingImmediate, onAgentCancelTraining, onPause, debugview } = props;

  const { level, ty, training } = slot;

  // state
  const [showForceTrainingConfirm, setShowForceTrainingConfirm] = useState(false);
  let [curAgent, setSelectedAgent] = useState(null);
  const agent = curAgent ?? (training?.agentIdx !== undefined ? agents.find(({ idx }) => idx === training.agentIdx) : null);
  // 임시코드
  if (agent && !agent.prevTrainingSessions) {
    agent.prevTrainingSessions = [...TrainingSessionsTemplate];
  }
  let [curInstructor, setSelectedInstructor] = useState(instructors[0]);
  let [curTrainingSessions, setTrainingSessions] = useState(agent?.prevTrainingSessions ?? TrainingSessionsTemplate);
  useEffect(() => {
    setTrainingSessions(agent?.prevTrainingSessions ?? TrainingSessionsTemplate);
  }, [agent]);
  let [curTrainingPerk, setTrainingPerk] = useState(null);

  useEffect(() => {
    if (showForceTrainingConfirm) {
      setShowForceTrainingConfirm(false);
    }
  }, [curAgent, showForceTrainingConfirm]);
  useEffect(() => {
    setTrainingAptitude(null);
    setTrainingPerk(null);
  }, [curAgent, curInstructor]);

  const newInstructor = instructors.filter(({ idx }) => {
    return DEBUG_Instructor_Duplicate_Allow || slots.find((slot) => slot.training?.instructorIdx === idx) === undefined;
  }).find(instructor => instructor.idx === curInstructor?.idx) ?? null;
  if (curInstructor !== newInstructor) {
    setSelectedInstructor(newInstructor);
  }

  const newAgent = agents.filter(({ idx }) => {
    return slots.find((slot) => !(slot.training?.ty === "stat") && slot.training?.agentIdx === idx) === undefined;
  }).find(agnet => agnet.idx === curAgent?.idx) ?? null;
  if (curAgent !== newAgent) {
    setSelectedAgent(newAgent);
  }

  if (curAgent === null && curTrainingPerk !== null) {
    setTrainingPerk(null);
  }
  if (curTrainingPerk !== null &&
    (curAgent === null || curAgent.perks.list.includes(curTrainingPerk))
  ) {
    setTrainingPerk(null);
  }
  let [curTrainingAptitude, setTrainingAptitude] = useState(null);
  if (curAgent === null && curTrainingAptitude !== null) {
    setTrainingAptitude(null);
  }
  if (curAgent !== null && curTrainingAptitude !== null && curAgent.vocation.includes(curTrainingAptitude)) {
    setTrainingAptitude(null);
  }

  const onChangeAgent = (e) => {
    const idx = parseInt(e.target.value);
    const agent = agents.find((a) => a.idx === idx) ?? null;
    setSelectedAgent(agent);
  };

  /*
  const onChangeInstructor = (e) => {
    const idx = parseInt(e.target.value);
    const instructor = instructors.find((a) => a.idx === idx) ?? null;
    setSelectedInstructor(instructor);
  };
  */

  const onChangeSessions = (e, i) => {
    const session = e.target.value;
    if (agent) {
      const newCurTrainingSessions = [...agent.prevTrainingSessions];
      newCurTrainingSessions[i] = session;
      setTrainingSessions(newCurTrainingSessions);
      agent.prevTrainingSessions = newCurTrainingSessions;
    }
  };

  const onAgentAcquirePerk = (_agent, perk) => {
    setTrainingPerk(perk.key);
  }

  // variables

  const isAutomation = slot.automation !== null && slot.automation.cancelled === false;

  let reason = [];

  const option = {
    "stat": curTrainingSessions,
    "perk": curTrainingPerk,
    "aptitude": curTrainingAptitude,
  }[ty];

  let info = trainInfo(curAgent, curInstructor, ty, option, level, slot.effects);
  if (info.avail === false) {
    reason.push(L(TRAINING_REASON_TABLE[info.reason]) ?? info.reason);
  }

  let automationReason = [];
  if (slot.automation === null) {
    if (slot.training !== null) {
      // i18n ignore 1
      automationReason.push("훈련을 취소하세요.");
    }
  }

  const disabled = (info === null) ||
    (ty === 'perk' && curTrainingPerk === null) ||
    (ty === 'aptitude' && curTrainingAptitude === null) ||
    (reason.length > 0);

  const duration = training ? (training.trainInfo.duration) : info.duration;

  const slotCap = (ty === 'stat' && (option === 'overall' || option === 'physical')) ? (info.data.train_power_cap) : (info.data.train_stats2_cap)

  // component
  const sessionSelectfakeTraining = training ? training :
    { ty: 'stat', option: curTrainingSessions };

  const trainGaugeInfo = agentStats2GaugeTrainInfo(sessionSelectfakeTraining, slot.level, slot.availability, slot.effects);
  const sessionSelect = ty === 'stat' ? (
    <div className="session-select">
      {Array.from({ length: SessionSlot_Length }).map((_, i) => (
        <div key={i}>
          <select disabled={isAutomation || slot.training || !agent} value={curTrainingSessions[i] ?? ''} onChange={(e) => onChangeSessions(e, i)}>
            <option key={''} value={''}>N/A</option>
            {agent ? training2.map(({ key, name }) => {
              return <option key={key} value={key}>{L(name)}</option>
            }) : null}
          </select>
          <ul style={{ display: 'inline' }}>
            {curTrainingSessions[i] ? Object.entries(training2Bykey(curTrainingSessions[i]))
              .filter(([key, value]) => Object.keys(STATS2_DESCR).includes(key) && value)
              .map(([key, _]) => {
                const data = trainGaugeInfo.sessions[i][key];
                const value = data.sum;
                const efficiencyBonuses = getTrainEfficiencyBonuses(trainGaugeInfo, data);

                return (<li key={key} style={{ display: 'inline', paddingRight: '5px' }}>{key}: <span>{roundsign(value, 2)}</span></li>);
              }) : null}
          </ul>
        </div>
      ))}
    </div>
  ) : null;


  const sessionGaugeView =
    (() => {
      if (ty !== 'stat') return null;

      if (!agent) return null;

      const fakeTraining = training ? (trainPending(agent) ? null : training) :
        { ty: 'stat', option: curTrainingSessions };

      const fakeSlot = {
        ...slot,
        training: fakeTraining,
      }

      return <>
        <div>
          <AgentStatGuages agent={agent} slots={slots} fakeSlot={fakeSlot} predict={!training} tick={tick} global_effects={{}} DEBUG />
        </div>
      </>
    })();

  const perkSelect = (
    <input type="text" value={perks2.find(perk => perk.key === (curTrainingPerk ?? training?.option))?.name ?? "N/A"} disabled />
  );

  const aptitudeSelect = curAgent !== null ? (
    <select disabled={slot.training} value={curTrainingAptitude ?? ""} onChange={(e) => setTrainingAptitude(e.target.value === "" ? null : e.target.value)}>
      <option value={""}>N/A</option>
      {firearm_ty_keys.filter(key => !curAgent.vocation.includes(key)).map((key) => <option key={key} value={key}>{key}</option>)}
    </select>
  ) : null;


  const agentSelectview = (
    <select disabled={isAutomation || slot.training} value={training?.agentIdx ?? curAgent?.idx ?? ""} onChange={onChangeAgent}>
      <option value={""}>N/A</option>
      {agents.filter(({ idx }) => {
        return slot.ty !== 'stat' || training?.agentIdx === idx || (slots.find((s) => s.training?.agentIdx === idx) === undefined);
      }).map((agent) => {
        const { idx, name, power } = agent;
        let reason = "";
        const on_training = slots.find((slot) => slot.training?.agentIdx === idx) !== undefined;
        if (on_training) {
          reason += L('loc_ui_string_training_cannot_select_agent_training');
        }
        if (agent.state === "mission") {
          reason += L('loc_ui_string_training_cannot_select_agent_mission');
        }
        else if (agent.state !== null) {
          reason += ` (${agent.state})`
        }
        if (!agentAvail(agent, AVAIL_RATIO)) {
          reason += L('loc_ui_string_training_cannot_select_agent_recovery');
        }

        const disabled = reason !== "";

        return <option key={idx} value={idx} disabled={disabled}>{name}(power:{power.toFixed(1)}){reason}</option>
      })}
    </select>
  );

  /*
  const instructorSelectView = (
    <select disabled={isAutomation || slot.training} value={training?.instructorIdx ?? curInstructor?.idx ?? ""} onChange={onChangeInstructor}>
      <option value={""}>N/A</option>
      {instructors.filter(({ idx }) => {
        return DEBUG_Instructor_Duplicate_Allow || training?.instructorIdx === idx || (slots.find((slot) => slot.training?.instructorIdx === idx || slot.automation?.instructorIdx === idx) === undefined);
      }).map((instructor) => {
        const { idx, name } = instructor;
        return <option key={idx} value={idx}>{name}</option>
      })}
    </select>
  );
  */

  const efficiencyBonuses = [];
  if (trainGaugeInfo.mult.all !== 1) {
    if (trainGaugeInfo.mult.facility !== 1) {
      // i18n ignore 1
      efficiencyBonuses.push(`훈련 시설 효율 보너스: +${((trainGaugeInfo.mult.facility) * 100).toFixed(2)}%`);
    }
    if (trainGaugeInfo.mult.effects.length !== 0) {
      efficiencyBonuses.push(...trainGaugeInfo.mult.effects
        .map(({ key, efficiency }) => {
          // i18n ignore 1
          return `${TRAINING_BONUS_TABLE[key]} 효율 보너스: +${(efficiency * 100).toFixed(2)}%`;
        }));
    }
  }
  // i18n ignore 1
  const efficiencyBonusView = <span title={efficiencyBonuses.join('\n')} style={{ background: 'grey' }}> 효율 보너스(전체): +${((trainGaugeInfo.mult.all - 1) * 100).toFixed(1)}%</span>;

  const capView = ty === 'stat' ? (
    <span>
      Cap: {slotCap.toFixed(1)}
    </span>
  ) : null;

  const statTyView = (
    <>
      (
      {capView}
      {trainGaugeInfo.mult.all !== 1 ? <>&nbsp;{efficiencyBonusView}</> : null}
      )
    </>
  );

  const availAbilityTextDict = {
    'decay_training': 'loc_ui_string_training_decay_training',
    'decay_normal': 'loc_ui_string_training_decay_normal',
    'maintain_training': 'loc_ui_string_training_maintain_training',
  }

  const { delta, availabilities } = slot.lastInfo ?? { delta: 0, availabilities: [] };
  const availabilityDetails = [
    L('loc_dynamic_string_training_task_maintain_result', { value: roundsign(delta, 0) }),
    L(`loc_ui_longtext_common_separator`),
  ];
  for (const { availability, desc_key } of availabilities) {
    availabilityDetails.push(`${L(availAbilityTextDict[desc_key])}: ${roundsign(availability, 0)}%`)
  }

  const availabilityView = (
    <span>
      {L('loc_dynamic_string_training_slot_condition', { value: roundsign(slot.availability, 0) })}<span title={availabilityDetails.join('\n')} >({roundsign(delta, 0)}%)</span>
    </span>
  )

  const headerNode = (
    <>
      <span>
        {
          ty === "stat" ? L(FacilitiesTypes.find(([ty, _name]) => ty === `training_general${level}`)[1]) :
            ty === "perk" ? L(FacilitiesTypes.find(([ty, _name]) => ty === `training_advanced1`)[1]) :
              ty === "aptitude" ? L(FacilitiesTypes.find(([ty, _name]) => ty === `training_firearm1`)[1]) :
                "(unknown)"
        }
      </span>
      {ty === 'stat' ? statTyView : null}
      {ty === 'stat' ? availabilityView : null}
    </>
  );

  const automationView = null;
  // const automationView = ty === "stat" ? (
  //   <>
  //     (
  //     <span title="해당 슬롯에 3개월마다 overall이 가장 낮은 용병 중에서 potential이 가장 높은 용병을 배치합니다.">자동 훈련</span>&nbsp;
  //     <input type="checkbox" checked={isAutomation} disabled={automationReason.length !== 0} onChange={(e) => { onSetSlotAutomation(slot, automationInstructor, e.target.checked, option) }} title={automationReason.join(", ")} />
  //     )
  //   </>
  // ) : null;

  const durationView = (
    <span>
      {/* duration: {Math.ceil(dayjs.duration(duration, 'hour').asDays())} */}
      <ProgressBar max={duration} cur={training ? (duration - (training.expires_at - tick)) : 0} width={"100px"} date />
    </span>
  );

  const selectOptionView =
    {
      "stat": sessionSelect,
      "perk": perkSelect,
      "aptitude": aptitudeSelect,
    }[ty];

  let debugTrainingInfoview = null;

  const pauseInfos = [];
  if (agent) {
    if (agent.state === "mission") {
      // i18n ignore 1
      pauseInfos.push("용병이 임무를 수행중인 동안 훈련이 중지됩니다!")
    }
    if (!agentAvail(agent, AVAIL_RATIO)) {
      // i18n ignore 1
      pauseInfos.push("용병이 부상에서 회복되는 동안 훈련이 중지됩니다!")
    }
  }

  const trainPauseInfoView = pauseInfos.map((info, i) => <p className='training-pauseinfo' key={i}>{info}</p>);

  // debug view
  const replacer = (_key, value) => {
    if (typeof value === 'number') {
      return value.toFixed(1);
    }
    return value;
  }
  if (onAgentTrainingImmediate && onAgentGrowth && ty === 'stat') {
    const growth = agentGrowth(rng, curAgent, option, info.train_mult, slotCap);
    const { agent, ...growth0 } = growth;
    debugTrainingInfoview = <div className="debug">
      <p>info={JSON.stringify(info, replacer)}</p>
      <p>growth={JSON.stringify(growth0, replacer)}</p>
      <div className="debug">
        <Button onClick={() => {
          if (training != null) {
            onAgentTrainingImmediate(slot);
          } else {
            onAgentGrowth(curAgent, growth);
          }
        }}>debug: growth</Button>
      </div>
    </div>;
  }

  // return before training view
  if (training === null) {
    let forceTrainingConfirm = null;
    if (showForceTrainingConfirm) {
      const agent = curAgent;
      if (curAgent === null) {
        return null;
      }
      if (curInstructor === null) {
        return null;
      }
      const prevTraining = slots.find((slot) => slot.training?.agentIdx === agent.idx);
      const { training } = prevTraining;
      const { expires_at, trainInfo: info } = training;
      const progressTick = tick - (expires_at - info.duration);
      const progressDay = progressTick / 24;
      // i18n ignore 16
      forceTrainingConfirm = (<Modal
        onConfirm={() => { onAgentGoTraining(slot, curAgent, curInstructor, ty, option, false, true) }}
        onCancel={() => { setShowForceTrainingConfirm(false) }}
      >
        <div>해당 용병은 일반 훈련 중이며, 훈련이 시작되면 {progressDay.toFixed(1)}일 치의 훈련은 초기화 됩니다.</div>
      </Modal>);
    }

    const goTrainingButtons = (
      <>
        <Button disabled={disabled} onClick={() => {
          if (curAgent) {
            if (slots.find((slot) => slot.training?.agentIdx === curAgent.idx) !== undefined) {
              setShowForceTrainingConfirm(true);
              onPause();
            } else {
              onAgentGoTraining(slot, curAgent, curInstructor, ty, option, false);
            }
          }
        }}>
          {disabled ? reason.join(", ") : L('loc_ui_button_training_start')}
        </Button>
        &nbsp;
        {/* {ty === "stat" && !disabled ?
          <Button disabled={disabled} onClick={() => {
            if (curAgent) {
              onAgentGoTraining(slot, curAgent, curInstructor, ty, option, true);
            }
          }}>
            {disabled ? reason : "L('loc_ui_button_training_start_repeat')"}
          </Button> : null} */}

        {ty === "perk" && curAgent != null ?
          <div>
            {ty === "perk" && curAgent != null && curAgent.perks.point !== 0 ? <AgentPerkTree agent={curAgent} onAgentAcquirePerk={onAgentAcquirePerk} /> : null}
          </div> :
          null
        }
      </>
    )

    const beforeTraining = (<WellSegment title={headerNode}>
      <div className='fh1-content-inner flex'>
        <div className='training-left flex-1'>
          <div>
            {durationView} {automationView}:
          </div>
          <div>
            {agentSelectview} {selectOptionView} {!isAutomation ? goTrainingButtons : null}
          </div>
          <div>
            {trainPauseInfoView}
          </div>
          <div>
            {debugTrainingInfoview}
            {debugview}
          </div>
          <div>
            {forceTrainingConfirm}
          </div>
        </div>
        <div className='training-right' style={{ flexBasis: "600px" }}>
          {sessionGaugeView}
        </div>
      </div>
    </WellSegment>);

    return beforeTraining;
  }
  // return after training view
  else {
    const { recurr, recurrCancelled } = training;
    const cancelTrainingView = (<Button onClick={() => { onAgentCancelTraining(slot) }}>{L('loc_ui_button_training_cancel_training_immediately')}</Button>);
    const willRecurr = recurr && !recurrCancelled;
    const cancelRecurrButton = willRecurr && (slot.automation === null || slot.automation.cancelled === true) ? (
      <>
        <Button onClick={() => { onAgentCancelTrainingRecurrence(slot) }}>
          {L('loc_ui_button_training_cancel_scheduled_training')}
        </Button>
      </>
    ) : null;
    const afterTraining = (
      <WellSegment title={headerNode}>
        <div className='box' style={{ display: 'flex' }}>
          <div className='training-left' style={{ flex: "1" }}>
            <div>
              {headerNode} {durationView} {automationView}:
            </div>
            <div>
              {agentSelectview} {selectOptionView} {cancelTrainingView} {cancelRecurrButton}
            </div>
            <div>
              <DispatchItemRecurrDelta delta={slot.training.result.cum} />
            </div>
            <div>
              {trainPauseInfoView}
            </div>
            <div>
              {debugTrainingInfoview}
              {debugview}
            </div>
          </div>
          <div className='training-right' style={{ flexBasis: "600px" }}>
            {sessionGaugeView}
          </div>
        </div>
      </WellSegment>
    )
    return afterTraining;
  }
}

function DispatchItemRecurrDelta(props) {
  const { delta } = props;
  const excludeKeys = ['agent'];

  if (!delta) {
    return;
  }
  const keys = Object.keys(delta);
  if (keys.length === 0) {
    return;
  }
  return <ul style={{ paddingLeft: '0px', display: 'inline' }}>
    {
      keys.map((key) => {
        const item = delta[key];
        if (!item || excludeKeys.includes(key)) {
          return null;
        } else if (typeof item === 'number') {
          const formatted = Intl.NumberFormat("en-US", { style: "decimal", signDisplay: 'always', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item);
          return <li key={key} style={{ display: 'inline', paddingRight: '10px', }}>{key} {formatted}</li>;
        } else if (typeof item === 'object') {
          if (!Object.values(item).some(x => x)) return null;
          return <>(<DispatchItemRecurrDelta delta={item} key={key} />)</>;
        } else {
          return null;
        }
      })
    }
  </ul>;
}

export class TrainingView extends React.Component {
  renderSlot(s, i) {
    const { rng, agents, slots, tick, instructors, debug } = this.props;
    const { onAgentGrowth, onSlotCat, onToggleAvail, onAgentGoTraining, onAgentCancelTrainingRecurrence, onAgentTrainingImmediate, onEfficencyChange, onAgentCancelTraining, onSetSlotAutomation, onPause } = this.props;

    const facilityEfficiency = agent_training.list.find(({ areaNum: n }) => n === (+s.level - 1)).train_mult;
    let efficiencyView = null;
    if (onEfficencyChange) {
      efficiencyView = <input type="number" value={s.efficiency} onChange={(e) => { onEfficencyChange(s, +e.target.value) }} />;
    }

    let debugview = null;
    if (onSlotCat) {
      // i18n ignore 11
      debugview = <div className="debug">
        <span>slot #{s.idx} | {s.ty === "stat" ? <>훈련 효율: {facilityEfficiency}</> : null} | 외부 효율: {efficiencyView} | </span>
        <span>
          <select onChange={(e) => e !== "" && onSlotCat(s, e.target.value)}>
            <option value="">다른 시설로 변경</option>
            {FacilitiesTypes.map(([ty, name]) =>
              <option key={ty} value={ty}>{name}으로 변경</option>
            )}
          </select>
        </span>
      </div>;
    }

    return <TrainSlot key={i} rng={rng} agents={agents} slots={slots} slot={s} tick={tick} instructors={instructors}
      onAgentGrowth={onAgentGrowth}
      onSlotCat={onSlotCat}
      onToggleAvail={onToggleAvail}
      onAgentGoTraining={onAgentGoTraining}
      onAgentCancelTrainingRecurrence={onAgentCancelTrainingRecurrence}
      onAgentTrainingImmediate={onAgentTrainingImmediate}
      onEfficencyChange={onEfficencyChange}
      onAgentCancelTraining={onAgentCancelTraining}
      onSetSlotAutomation={onSetSlotAutomation}
      onPause={onPause}
      debugview={debug ? debugview : null}
    />;
  }

  render() {
    const { slots } = this.props;

    return (
      <>
        {slots.map((x, i) => {
          return [
            this.renderSlot(x, i),
          ]
        })
        }
      </>
    );
  }
}

const TPS_BASE = 2;
const TPS_MULTS = [1, 3, 10, 20, 1000];

export function AgentsTrainingResult(props) {
  const { summary, trainedOn } = props;
  const { onAgentChangeCap, onAgentChangePot, onAgentRemove, onAgentState, onAgentHp, onAgentPerkPoint } = props;

  const stat2Keys = [...data_stats.stats_descr.map(({ key }) => key)];
  // i18n ignore 134
  return (
    <table>
      <thead>
        <tr>
          <th>성명</th>
          <th>{L('loc_ui_string_agent_life')}</th>
          <th>{L('loc_ui_string_agent_potential')}</th>
          <th>{L('loc_ui_string_agent_growth_cap')}</th>
          <th>physicalcap</th>
          <th>{L('loc_ui_string_agent_level')}</th>
          <th>{L('loc_ui_string_agent_experience')}</th>
          <th>{L('loc_ui_string_agent_weapon_qualification')}</th>
          <th>{L('loc_ui_string_agent_overall')}</th>
          <th>physical</th>
          {stat2Keys.map((key) => <th key={key}>{key}</th>)}
          {
            trainedOn ?
              <th>획득한 퍽</th> :
              <th>퍽</th>
          }
          {
            trainedOn ?
              <th>훈련한 무기</th> :
              null
          }
          {trainedOn ? <th>참여한 훈련</th> : null}
          <th>퍽 포인트</th>
          {trainedOn}
          {onAgentRemove ? <th>제거됨</th> : null}
          {onAgentState ? <th>용병 현황</th> : null}
        </tr>
      </thead>
      <tbody>
        {summary.map(({ agent, result }) => {

          const expData = exps.exps.find((e) => e.level === agent.level.cur);

          const gcapData = gcap.find(agent.growthcap);
          const power_cap = gcapData.power_cap;

          const pscapData = pscap.find(agent.physicalcap);
          const physical_cap = pscapData.physical_cap;

          const on_mission = agent.state === 'mission';

          let button = <Button onClick={() => {
            onAgentState(agent, 'mission');
          }}>대기중</Button>;

          if (on_mission) {
            button = <Button onClick={() => {
              onAgentState(agent, null);
            }}>
              임무중
            </Button>;
          }

          const agentHp = (
            <input type="number" style={{ width: "30px" }} value={agent.life} onChange={(e) => onAgentHp(agent, +e.target.value)} />
          )

          const agentPerkPoint = (
            <input type="number" style={{ width: "30px" }} value={agent.perks.point} onChange={(e) => onAgentPerkPoint(agent, +e.target.value)} />
          )

          const resultCount = [];
          if (trainedOn) {
            if (result.count.stat !== 0) {
              resultCount.push(`일반 훈련 X ${result.count.stat}`);
            }
            if (result.count.perk !== 0) {
              resultCount.push(`고등 훈련 X ${result.count.perk}`);
            }
            if (result.count.aptitude !== 0) {
              resultCount.push(`사격 훈련 X ${result.count.aptitude}`);
            }
          }

          return (
            <tr key={agent.idx}>
              <td>{agent.name}</td>
              <td>{
                onAgentHp ?
                  agentHp :
                  agent.life
              } / {agent.life_max}</td>
              <td>{pot.findByPotential(agent.potential).label} ({
                onAgentChangePot ?
                  <input type="number" style={{ width: "40px" }} value={agent.potential.toFixed(1)} onChange={(e) => onAgentChangePot(agent, +e.target.value)} /> :
                  agent.potential.toFixed(1)
              })</td>
              <td>{
                onAgentChangeCap ?
                  <input type="text" style={{ width: "20px" }} value={agent.growthcap} onChange={(e) => onAgentChangeCap(agent, 'growthcap', e.target.value)} /> :
                  agent.growthcap
              } ({power_cap.toFixed(1)})</td>
              <td>{
                onAgentChangeCap ?
                  <input type="text" style={{ width: "20px" }} value={agent.physicalcap} onChange={(e) => onAgentChangeCap(agent, 'physicalcap', e.target.value)} /> :
                  agent.physicalcap
              } ({physical_cap.toFixed(1)})</td>
              <td>{agent.level.cur}</td>
              <td>{agent.level.exp.toFixed(1)}/{expData.exp}</td>
              <td>{agent.power.toFixed(1)}{result?.cum?.power !== undefined ? ` (+${result.cum.power.toFixed(1)})` : null}</td>
              <td>{agent.physical.toFixed(1)}{result?.cum?.physical !== undefined ? ` (+${result.cum.physical.toFixed(1)})` : null}</td>
              {stat2Keys.map((key) => <td key={key}>{
                agent.stats2[key].toFixed(1)}{result?.cum?.stats2?.[key] !== undefined ? ` (+${result.cum.stats2[key].toFixed(1)})` : null}
              </td>)}
              <td>{
                trainedOn ?
                  result.cum.perk?.split(', ').map((key) => <span key={key}>{perks2.find((perk) => perk.key === key).name}</span>) :
                  agent.perks.list.map((key) => perks2.find((perk) => perk.key === key).name).join(', ')
              }</td>
              {trainedOn ? <td>{
                result.cum.vocation
              }</td> : null}
              {trainedOn ? <td>{resultCount.join(", ")}</td> : null}
              <td>{
                onAgentPerkPoint ?
                  agentPerkPoint :
                  agent.perks.point
              }</td>
              {onAgentRemove ? <td><Button onClick={() => { this.onAgentRemove(agent) }}>제거</Button></td> : null}
              {onAgentState ? <td>{button}</td> : null}
            </tr>
          )
        })}
        {/* overall: {power_cap.toFixed(1)} <input type="text" style={{ width: "50px" }} value={a.growthcap} onChange={(e) => this.onAgentChangeCap(a, 'growthcap', +e.target.value)} />
              physical: {physical_cap.toFixed(1)} <input type="text" style={{ width: "50px" }} value={a.physicalcap} onChange={(e) => this.onAgentChangeCap(a, 'physicalcap', +e.target.value)} />
              potential: <input type="number" style={{ width: "50px" }} value={a.potential} onChange={(e) => this.onAgentChangePot(a, +e.target.value)} /> */}
      </tbody>

    </table >
  );
}
// i18n ignore 6
const FacilitiesTypes = [
  ["training_general1", "일반 훈련장1"],
  ["training_general2", "일반 훈련장2"],
  ["training_general3", "일반 훈련장3"],
  ["training_general4", "일반 훈련장4"],
].map(([ty, _name]) => {
  const facility = facilities.find((f) => f.key === ty);
  return [ty, facility.name, facility];
});

export class TrainingRoot extends Component {
  constructor(props) {
    super(props);


    const rng = new Rng();

    const agents = new Array(5).fill(0).map((_, i) => {
      const agent = createAgent(rng, rng.choice(names3), i, {});
      agent.perks.point = 1;
      agent.perks.point_total = 1;
      return agent;
    });

    let lastAgentIdx = agents.length;

    let lastSlotIdx = 0;
    const slots = [
      createSlot(lastSlotIdx++, "stat", 1),
      createSlot(lastSlotIdx++, "perk", 1),
      createSlot(lastSlotIdx++, "aptitude", 1),
    ]

    let lastInstructorIdx = 0;
    // i18n ignore 3
    const instructors = [
      createInstructor(lastInstructorIdx++, `교관 1`, perk2_keys, firearm_ty_keys),
    ]

    this.state = {
      tick: 0,

      paused: true,
      simtps: TPS_BASE,

      rng,

      lastSlotIdx,
      lastAgentIdx,
      lastInstructorIdx,

      agents,
      slots,

      msgs: [],

      instructors,

      pendingTraining: null,
      trainingResults: [],

      showTrainingPreview: false,
    };
  }

  oneTimeInitialized = false;

  startTimer() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(this.onTimer, 1000 / 30);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onTimer = () => {
    if (!this.onTick()) {
      this.startTimer();
    }
  };

  onTick() {
    const { simtps } = this.state;
    let { paused, tick } = this.state;

    const now = Date.now();

    if (paused) {
      this.last_ms = now;
      return paused;
    }

    let max_tick = 100;
    while (this.last_ms < now) {
      this.last_ms += 1000 / simtps;
      if (this.onTickGame(tick++)) {
        paused = true;
      }

      if (paused) {
        break;
      }

      max_tick -= 1;
      if (max_tick <= 0) {
        break;
      }
    }

    this.setState({ tick });
    return paused;
  }

  onTickGame(tick) {
    let pause = false;
    const { showTrainingPreview } = this.state;

    if (showTrainingPreview) {
      this.setState({ trainingResults: [], showTrainingPreview: false });
    }

    if (this.onTickSlots(tick)) {
      this.setPaused(true);
      pause = true;
    }

    this.setState({ tick });

    return pause;
  }

  onKeydown(e) {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLButtonElement) return;

    if (e.key === " ") {
      const paused = !this.state.paused;

      this.setPaused(paused);
      e.preventDefault();

    } else if (e.key === "1") {
      this.setState({ simtps: TPS_BASE * TPS_MULTS[0] });
    } else if (e.key === "2") {
      this.setState({ simtps: TPS_BASE * TPS_MULTS[1] });
    } else if (e.key === "3") {
      this.setState({ simtps: TPS_BASE * TPS_MULTS[2] });
    } else if (e.key === "4") {
      this.setState({ simtps: TPS_BASE * TPS_MULTS[3] });
    } else if (e.key === "5") {
      this.setState({ simtps: TPS_BASE * TPS_MULTS[4] });
    }
  }

  setPaused(paused) {
    const { pendingTraining } = this.state;
    if (pendingTraining && paused === false) {
      return;
    }

    this.setState({ paused });
    if (paused) {
      this.stopTimer();
    } else {
      this.last_ms = Date.now();
      this.startTimer();
    }
  }

  componentDidMount() {
    if (this.oneTimeInitialized) return;
    document.addEventListener('keydown', this.onKeydown.bind(this));
    this.oneTimeInitialized = true;
  }

  componentWillUnmount() {
    // 맞나...?
    document.removeEventListener('keydown', this.onKeydown);
  }

  addSlot() {
    const { slots, slotIdx } = this.state;
    const slot = createSlot(slotIdx, "stat");
    this.setState({ slotIdx: slotIdx + 1, slots: [...slots, slot] });
  }

  onAgentGrowth(agent, growth) {
    const { agents } = this.state;

    const { tick } = this.state;
    agentChangeApply(agent, growth, tick);

    this.setState({ agents });
  }

  onSlotCat(slot, ty) {
    const { slots } = this.state;
    slotTypeUpdate(slot, ty);
    this.setState({ slots });
  }

  onAgentState(agent, state) {
    const { agents } = this.state;
    agent.state = state;

    this.setState({ agents });
  }

  onAgentHp(agent, life) {
    const { agents } = this.state;
    agent.life = life;
    this.setState({ agents });
  }

  onSlotAvail(slot, cat) {
    const { slots } = this.state;
    if (slot.avail.includes(cat)) {
      slot.avail = slot.avail.filter((c) => c !== cat);
    }
    else {
      slot.avail = [...slot.avail, cat];
    }
    this.setState({ slots });
  }

  onAgentPerkPoint(agent, point) {
    const { agents } = this.state;
    const diff = point - agent.perks.point;
    agent.perks.point += diff;
    agent.perks.point_total += diff;
    this.setState({ agents });
  }

  onAgentGoTraining(slot, agent, instructor, ty, option, recurr, forced = false) {
    const { tick, slots } = this.state;

    // 이미 다른 곳에서 훈련 중
    const prevSlot = slots.find((slot) => slot.training?.agentIdx === agent.idx);
    if (prevSlot !== undefined) {
      if (forced) {
        slotCancel(prevSlot);
        this.setState({ pendingTraining: null });
      } else {
        const pendingTraining = { slot, agent, instructor, ty, option, recurr };
        this.setPaused(true);
        this.setState({ pendingTraining });
        return;
      }
    }

    const { training: next, reason } = trainStart(tick, slot.level, agent, instructor, ty, option, slot.efficiency);
    if (reason) {
      alert(`unable to start training: ${reason}`);
    }
    if (recurr) {
      // set recurr data
      next.recurr = true;
      next.recurrCancelled = false;
    }
    slot.training = next;

    this.setState({ slots });
  }

  onAgentCancelTraining(slot) {
    const { slots, msgs, agents, tick } = this.state;
    const ev = slotCancel(slot);

    if (ev.training.result.cum) {
      this.onTrainCompleteEvent(ev);
      this.onTrainEndEvent(ev);
    } else {
      const agent = agents.find((a) => a.idx === ev.training.agentIdx);
      const { reason } = ev;
      let msg = `${agent.name} returned from training: slot=${slot.idx}, reason=${reason}`;
      msgs.push({
        tick,
        msg,
      });
    }
    this.setState({ slots, msgs });
  }

  onAgentCancelTrainingRecurrence(slot) {
    const { slots } = this.state;
    slot.training.recurrCancelled = true;
    this.setState({ slots });
  }

  onAgentTrainingImmediate(slot) {
    const { slots, tick, agents, instructors } = this.state;

    // build event from complete
    const { training: training_next, reason } = trainComplete(tick, slot, agents, instructors);

    const ev = {
      slot,
      training: slot.training,
      reason,
    };
    // 훈련이 종료된 경우
    if (reason) {
      this.onTrainEndEvent(ev);
    }
    this.onTrainCompleteEvent(ev);

    slot.training = training_next;

    this.setState({ slots });
  }

  onTrainCompleteEvent({ training }) {
    const { agents } = this.state;

    const agent = agents.find((a) => a.idx === training.agentIdx);

    const { result } = training;
    this.setState((state) => ({ trainingResults: [...state.trainingResults, { agent, result }] }))
  }

  onTrainEndEvent({ slot, training, reason }) {
    const { tick, agents, msgs } = this.state;
    /*
    if (!result.sent) {
      let msg = `${agent.name} returned from training.`;
      msg += `\n${agentChangeDescr(training.result.delta)}`;
      msg += `\nTraining repetition: ${training.result.count} times`;
      msg += `\nTraining repetition finished by: ${result.message}`;
      onEnqueuePopup(msg);

      slot.training = null;
    }
    */

    const { result } = training;
    const agent = agents.find((a) => a.idx === training.agentIdx);
    // i18n ignore 1
    let msg = `${agent.name}이(가) 훈련을 마쳤습니다.(${slot.idx}번 훈련 슬롯, 훈련 횟수: ${Object.entries(result.count).map(([key, value]) => `${key}=${value}`).join(", ")}, 훈련 종료 사유: ${reason}`;
    msg += `\n${agentChangeDescr(result.cum)}`;
    msgs.push({
      tick,
      msg,
    });

    this.setState({ msgs });
  }

  onStateChange(state) {
    if (state === 'result') {
      this.setPaused(true);
    }
  }

  onEnqueuePopup(_msg) {
  }

  onEfficencyChange(slot, efficiency) {
    const { slots } = this.state;
    slot.efficiency = efficiency;
    this.setState({ slots });
  }

  onTickSlots(tick) {
    const { slots, agents, instructors } = this.state;
    const events = tickSlots(slots, tick, agents, instructors);
    let pause = false;

    for (const slot of slots) {
      slot.effects = [];
    }

    for (const ev of events) {
      if (ev.slot.training === null) {
        // 끝난 훈련 목록입니다
        //  - 한 번 보낸 훈련이 끝났을 때
        //  - 반복 훈련이 취소되었을 때
        //  - 반복 훈련을 더 진행할 수 없을 때 (스텟이 꽉 찬 경우 등)
        this.onTrainEndEvent(ev);
        pause = true;
      }
      this.onTrainCompleteEvent(ev);
    }

    this.setState({ slots });

    // Training Preview
    const date = dayjs(tickToDate(tick));
    switch (date.month() + 1) {
      case 1:
      case 4:
      case 7:
      case 10:
        if (date.date() === 1 && date.hour() === 0 && date.minute() === 0 && date.second() === 0) {
          this.setState({ paused: true, showTrainingPreview: true });
          pause = true;
        }
        break;
      default:
        break;
    }

    return pause;
  }

  renderTrainingPreview() {
    const { trainingResults } = this.state;

    const summary = generateSummary(trainingResults);

    if (summary.length === 0) {
      // i18n ignore 3
      return <div>
        <h1>훈련 결과가 없습니다.</h1>
      </div>
    }

    return (
      <AgentsTrainingResult summary={summary} trainedOn />
    )
  }

  onSetSlotAutomation(slot, instructor, isEnable, option) {
    const { tick, agents, instructors, slots } = this.state;
    slotAutomationUpdate(slot, tick, agents, instructor, slots, instructors, isEnable, option);
    this.setState({ slots });
  }

  onAgentRemove(agent) {
    const { slots } = this.state;

    const slot = slots.find((slot) => slot.training?.agentIdx === agent.idx);
    if (slot) {
      // ToDo: 이거도 이벤트 처리 해줘야 하려나..
      slotCancel(slot);
    }

    this.setState((state) => ({ agents: state.agents.filter((a) => a.idx !== agent.idx) }));
  }

  onAgentAdd() {
    const { agents, lastAgentIdx, rng } = this.state;
    const agent = createAgent(rng, rng.choice(names3), lastAgentIdx + 1, {});
    this.setState({ agents: [...agents, agent], lastAgentIdx: lastAgentIdx + 1 });
  }

  onAgentChangeCap(agent, key, value) {
    const { agents } = this.state;
    value = value.toUpperCase();
    if (['S', 'A', 'B', 'C', 'D', 'E'].includes(value)) {
      agent[key] = value;
    }
    this.setState({ agents });
  }

  onAgentChangePot(agent, value) {
    const { agents } = this.state;
    agent.potential = value;
    this.setState({ agents });
  }

  onPause() {
    this.setPaused(true);
  }

  renderPendingTraining() {
    const { pendingTraining: { slot, agent, instructor, ty, option, recurr } } = this.state;
    const { tick, slots } = this.state;
    const prevTraining = slots.find((slot) => slot.training?.agentIdx === agent.idx);
    const { training } = prevTraining;
    const { expires_at, trainInfo: info } = training;

    const progressTick = tick - (expires_at - info.duration);
    const progressDay = progressTick / 24;
    // i18n ignore 11
    return (
      <div className="overlay-root" style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, background: "rgba(0,0,0,.6)" }}>
        <div classname="overlay" style={{ position: "absolute", width: "fit-contents", height: "fit-contents", background: "white", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <div>해당 용병은 일반 훈련 중이며, 훈련이 시작되면 {progressDay.toFixed(1)}일 치의 훈련은 초기화 됩니다.</div>
          <div>
            <Button onClick={() => { this.onAgentGoTraining(slot, agent, instructor, ty, option, recurr, true) }}>Yes</Button>
            <Button formethod="dialog" onClick={() => { this.setState({ pendingTraining: null }) }}>No</Button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { slots, agents, tick, rng, instructors, msgs, showTrainingPreview, pendingTraining } = this.state;
    // i18n ignore 64
    return <>
      {
        pendingTraining ?
          this.renderPendingTraining() : null
      }
      <div className="tick">
        Tick:&nbsp;
        {tick}&nbsp;
        <Button onClick={() => { this.onTick(tick + 1) }}>+</Button>
        <Button onClick={() => { this.onTick(tick - 1) }}>-</Button>
        <span> | </span>
        {dayjs(tickToDate(tick)).format("YYYY-MM-DD HH:00")}
        <span> | </span>
        <span> 플레이/일시정지: space, 배속: 1,2,3,4</span>
      </div>
      <div>
        <Button onClick={() => { this.addSlot() }}>슬롯 추가</Button>
      </div>

      {
        showTrainingPreview ?
          <div className='box'>
            <h1>Training Preview(일시정지가 풀리면 사라집니다)</h1>
            {this.renderTrainingPreview()}
          </div> : null
      }

      <div className="box">
        <TrainingView
          slots={slots} rng={rng} agents={agents} tick={tick} instructors={instructors}
          onAgentGrowth={this.onAgentGrowth.bind(this)}
          onSlotCat={this.onSlotCat.bind(this)}
          onToggleAvail={this.onSlotAvail.bind(this)}
          onAgentGoTraining={this.onAgentGoTraining.bind(this)}
          onAgentCancelTrainingRecurrence={this.onAgentCancelTrainingRecurrence.bind(this)}
          onAgentTrainingImmediate={this.onAgentTrainingImmediate.bind(this)}
          onEfficencyChange={this.onEfficencyChange.bind(this)}
          onAgentCancelTraining={this.onAgentCancelTraining.bind(this)}
          onSetSlotAutomation={this.onSetSlotAutomation.bind(this)}
          onPause={this.onPause.bind(this)}
          debug={true}
        />
      </div>

      <div className="box">
        <h1>agents</h1>
        <Button onClick={() => { this.onAgentAdd() }}>Add</Button>
        <AgentsTrainingResult
          summary={agents.map((agent) => ({ agent, result: {} }))}
          onAgentChangeCap={this.onAgentChangeCap.bind(this)}
          onAgentChangePot={this.onAgentChangePot.bind(this)}
          onAgentRemove={this.onAgentRemove.bind(this)}
          onAgentState={this.onAgentState.bind(this)}
          onAgentHp={this.onAgentHp.bind(this)}
          onAgentPerkPoint={this.onAgentPerkPoint.bind(this)}
        />
      </div>
      <div className="box">
        <h1>journal</h1>
        {msgs.map((m, i) => {
          return <pre key={i}>{dayjs(tickToDate(m.tick)).format("YYYY-MM-DD HH:00")} {m.msg}</pre>;
        })}
      </div>
    </>;
  }
}

export function TrainingListWidget(props) {
  const { game, onAgentPreview } = props;
  const { slots, turn } = game;

  const [showList] = useState(true);

  const slots0 = slots.filter((slot) => slot.training).sort((a, b) => a.training.expires_at - b.training.expires_at);
  if (slots0.length === 0) {
    return null;
  }

  let body;
  if (showList) {
    body = <div className='flexright-body'>
      {slots0.map(({ training: { agentIdx, expires_at } }) => {
        const agent = game.agents.find(x => x.idx === agentIdx);

        let pending = trainPending(agent);

        return <div className='flexright-body-item' key={agentIdx}>
          <div className='flexright-body-item-name' onClick={() => onAgentPreview(agent)}>{agent.name}</div>
          <div className='flexright-body-item-middle'></div>
          <div className='flexright-body-item-time'>
            {
              pending !== "" ?
                pending :
                dayjs.duration(expires_at - turn, 'hour').humanize()
            }
          </div>
        </div>
      })}
    </div>
  }
  // i18n ignore 10
  return <div className='flexright-trainings'>
    <div className='flexright-title'>
      <span className='flexright-btn' onClick={() => {
        this.setState({ showList: !showList })
      }}>{!showList ? '▶︎' : '▼'}</span>
      <div className='flexright-title-left'>훈련:{slots0.length}</div>
      <div className='flexright-title-right'>빈슬롯:{slots.length - slots0.length}</div>
    </div>
    {body}
  </div>
}
