import source from '../downloaded/centertraits.json' assert { type: 'json' };

export const centertraits = [];
for (const row of source) {
  let { name, ty, effect } = row;

  centertraits.push({
    name,
    ty,
    effect,
  });
}
