import source from '../downloaded/char2trainingCompletion.json' assert { type: 'json' };

export const trainingCompletions = [];
for (const row of source) {
  let { key, name, tier_1_weight, tier_2_weight, tier_3_weight, tier_4_weight, tier_5_weight } = row;

  trainingCompletions.push({
    key: key,
    name: name,
    tier_1_weight: +tier_1_weight,
    tier_2_weight: +tier_2_weight,
    tier_3_weight: +tier_3_weight,
    tier_4_weight: +tier_4_weight,
    tier_5_weight: +tier_5_weight
  })
}
