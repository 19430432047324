import source from '../downloaded/char2backgrounds.json' assert { type: 'json' };

export const backgrounds = {};
for (const row of source) {
  const { key, name, nationalityKey, tier_max, tier_min, desc, weight, fixed_trainingCompletion } = row;

  backgrounds[key] = {
    name: name,
    nationalityKey: nationalityKey?.split(',').map(s => (s.trim())) ?? [],
    tier_max: +tier_max,
    tier_min: +tier_min,
    desc,
    weight: +weight,
    fixed_trainingCompletion: fixed_trainingCompletion?.split(',').map(s => (s.trim())) ?? [],
  }
}
