import React from 'react';

export function HorizontalInfoView(props) {
  const { pairs } = props;
  let className = props.className ?? '';
  className += ' flex-horizontal-info';

  return <div className={className}>
    {
      pairs.map(({ key, value }, i) => {
        return <div key={i}>
          <div className="flex-horizontal-info-key">{key}</div>
          <div className="flex-horizontal-info-value">{value}</div>
        </div>;
      })
    }
  </div >;
}

export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
