import source from '../downloaded/dialogNpcs.json' assert { type: 'json' };

export const dialogNpcs = [];

for (const row of source) {
    const { key, name, portraitUrl } = row
    dialogNpcs.push({
        key,
        name,
        portraitUrl,
    });
}

export function dialogNpcByName(name) {
  return dialogNpcs.find(npc => npc.name === name);
}
