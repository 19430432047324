import React from 'react';
import { GameAnalytics } from 'gameanalytics';

import { L } from './localization.mjs';

const LINK_FORM = 'https://forms.gle/pfLSZgZivDj2US7G6';
const LINK_COMMUNITY = 'https://steamcommunity.com/app/2564320/discussions/';

export function onclickWishlist(eventType) {
  GameAnalytics.addDesignEvent(`outlink:steam:${eventType}`);
  window.ue?.connection?.open?.('https://store.steampowered.com/app/2564320');
}

export function WishlistButton(props) {
  const { isLobby, eventType } = props;
  return <button className={"title-side-button" + (isLobby ? ' lobby' : '')} onClick={() => { onclickWishlist(eventType); }}>
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 65 65" fill="#fff">
      <use href="#B" x=".5" y=".5" />
      <defs>
        <linearGradient id="A" x2="50%" x1="50%" y2="100%" y1="0%">
          <stop stopColor="#111d2e" offset="0%" />
          <stop stopColor="#051839" offset="21.2%" />
          <stop stopColor="#0a1b48" offset="40.7%" />
          <stop stopColor="#132e62" offset="58.1%" />
          <stop stopColor="#144b7e" offset="73.8%" />
          <stop stopColor="#136497" offset="87.3%" />
          <stop stopColor="#1387b8" offset="100%" />
        </linearGradient>
      </defs>
      <symbol id="B">
        <g>
          <path d="M1.305 41.202C5.259 54.386 17.488 64 31.959 64c17.673 0 32-14.327 32-32s-14.327-32-32-32C15.001 0 1.124 13.193.028 29.874c2.074 3.477 2.879 5.628 1.275 11.328z" fill="url(#A)" />
          <path d="M30.31 23.985l.003.158-7.83 11.375c-1.268-.058-2.54.165-3.748.662a8.14 8.14 0 0 0-1.498.8L.042 29.893s-.398 6.546 1.26 11.424l12.156 5.016c.6 2.728 2.48 5.12 5.242 6.27a8.88 8.88 0 0 0 11.603-4.782 8.89 8.89 0 0 0 .684-3.656L42.18 36.16l.275.005c6.705 0 12.155-5.466 12.155-12.18s-5.44-12.16-12.155-12.174c-6.702 0-12.155 5.46-12.155 12.174zm-1.88 23.05c-1.454 3.5-5.466 5.147-8.953 3.694a6.84 6.84 0 0 1-3.524-3.362l3.957 1.64a5.04 5.04 0 0 0 6.591-2.719 5.05 5.05 0 0 0-2.715-6.601l-4.1-1.695c1.578-.6 3.372-.62 5.05.077 1.7.703 3 2.027 3.696 3.72s.692 3.56-.01 5.246M42.466 32.1a8.12 8.12 0 0 1-8.098-8.113 8.12 8.12 0 0 1 8.098-8.111 8.12 8.12 0 0 1 8.1 8.111 8.12 8.12 0 0 1-8.1 8.113m-6.068-8.126a6.09 6.09 0 0 1 6.08-6.095c3.355 0 6.084 2.73 6.084 6.095a6.09 6.09 0 0 1-6.084 6.093 6.09 6.09 0 0 1-6.081-6.093z" />
        </g>
      </symbol>
    </svg>
    <div className="title-side-button-label">
      {L('loc_ui_string_lobby_wishlist_upper') + ' ' + L('loc_ui_string_lobby_wishlist_lower')}
    </div>
  </button>;
}

export function CommunityButton(props) {
  const { isLobby, eventType } = props;
  return <button className={"title-side-button" + (isLobby ? ' lobby' : '')} onClick={() => {
    window.ue?.connection?.open?.(LINK_FORM);
    GameAnalytics.addDesignEvent(`outlink:feedback:${eventType}`);
  }}>
    <div className="title-side-button-label">
      {L('loc_ui_string_lobby_community')}
    </div>
  </button>;
}
