import source from '../downloaded/agentModCom.json' assert { type: 'json' };

export const agentModComs = [];
for (const row of source) {
  const { ty, weight, tier, pt, key } = row;

  agentModComs.push({
    ty,
    weight: parseInt(weight),
    tier: parseInt(tier),
    point: parseInt(pt),
    key,
  });
}
