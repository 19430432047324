import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';

import Heap from 'heap';
import bounds from 'binary-search-bounds';

class Stats {
  constructor() {
    this.aabb_intersect = 0;
    this.obstructed0 = 0;
    this.obstructed0_t = 0;
    this.obstructed = 0;
    this.checkcover = 0;
    this.onReachableGridWasm = 0;
    this.onReachableGridWasmOp = 0;
    this.onReachableGridWasmOpPixels = 0;
    this.onReachableGridWasmApply = 0;
    this.onReachableGridWasmApplyPixels = 0;
    this.onReachableGridWasmApplySamples = 0;
    this.raycastWasm = 0;
  }
}

let stats = new Stats();

export function stats_populate() {
  const res = stats;
  stats = new Stats();
  return res;
}

export function segment_intersect(a, b, c, d) {
  return a < d && b > c;
}

export function aabb_intersect(a, b, c, d) {
  stats.aabb_intersect += 1;
  return segment_intersect(a.x, b.x, c.x, d.x) && segment_intersect(a.y, b.y, c.y, d.y);
}

function createPolyRect(x, y, w, h) {
  const tl = new v2(x - w / 2, y - h / 2);
  const bl = new v2(x - w / 2, y + h / 2);
  const tr = new v2(x + w / 2, y - h / 2);
  const br = new v2(x + w / 2, y + h / 2);
  return [tl, bl, br, tr];
}

export function createConvexHull(points) {
  let hull = [];

  //sort by counter clock-wise direction
  points.sort((a, b) => {
    if (a.x !== b.x) return a.x - b.x;
    return a.y - b.y;
  });
  const piv = points[0];
  points.splice(0, 1);
  points.sort((a, b) => {
    const cw = v2.ccw(piv, a, b);
    if (cw !== 0) {
      if (cw > 0) {
        return -1;
      } else {
        return 1;
      }
    }
    return piv.dist(a) - piv.dist(b);
  });
  points.splice(0, 0, piv);

  //construct convex hull
  for (const point of points) {
    while (hull.length > 1 && v2.ccw(hull[hull.length - 2], hull[hull.length - 1], point) <= 0) {
      hull.pop();
    }
    hull.push(point);
  }
  return hull;
}

/// 사각형인 polygon을 4방향으로 delta길이만큼 균등하게 확장합니다
export function expandRect(polygon, delta) {
  const center = v2.centroid(polygon);
  return polygon.map((p) => p.add(p.sub(center).norm().mul(delta)));
}

/// 사각형인 polygon을 4방향으로 delta길이만큼 균등하게 축소합니다
export function shrinkRect(polygon, delta) {
  return expandRect(polygon, -delta);
}

export function geomContains(p, polygon) {
  const p2 = new v2(p.x + 100000, p.y + 1);

  let count = 0;
  const l = polygon.length;
  for (let i = 0; i < l; i++) {
    const p3 = polygon[i];
    const p4 = polygon[(i + 1) % l];
    if (v2.intersect(p, p2, p3, p4)) {
      count += 1;
    }
  }
  return count % 2 === 1;
}

// 볼록 다각형 내 점이 있는 지 CCW 기반으로 테스트합니다.
export function geomContainsCCW(p, convex_polygon) {
  const poly = convex_polygon;
  const len = poly.length;

  let prev_state = null;
  for (let i = 0; i < len; i++) {
    const [p0, p1, p2] = [poly[i], poly[(i + 1) % len], p];

    const state = v2.ccw(p0, p1, p2, true);
    if (prev_state !== null) {
      if (prev_state !== state) {
        return false;
      }
    }
    prev_state = state;
  }

  return true;
}

export function geomSamplePoint(rng, obstacle) {
  const { polygon } = obstacle;
  return geomSamplePointWithinPolygon(rng, polygon);
}

export function geomSamplePointWithinPolygon(rng, polygon) {
  const minx = Math.min(...polygon.map(p => p.x));
  const maxx = Math.max(...polygon.map(p => p.x));
  const miny = Math.min(...polygon.map(p => p.y));
  const maxy = Math.max(...polygon.map(p => p.y));

  for (let i = 0; i < 100; i++) {
    const p = new v2(
      rng.range(minx, maxx),
      rng.range(miny, maxy)
    );

    if (geomContains(p, polygon)) {
      return p;
    }
  }
  console.error('failed to sample point', polygon);
  return null;
}

function samplePoints(p0, p1, n) {
  // p0부터 p1까지 n개의 점을 sampling합니다.
  // 1개인 경우 2등분의 가운데, 2개인 경우 3등분의 가운데, ..

  const l = [];
  for (let i = 0; i < n; i++) {
    const t = (i + 1) / (n + 1);
    l.push(v2.lerp(p0, p1, t));
  }
  return l
}

export function createObstacle(pos, extent, heading, ty, no_coverpoint, extent_margin) {
  const { x, y } = pos;
  const w = extent.x;
  const h = extent.y;
  if (!extent_margin) {
    if (['half', 'door'].includes(ty)) {
      extent_margin = new v2(5, 5);
    } else {
      extent_margin = new v2(10, 10);
    }
  }
  const mw = extent_margin.x;
  const mh = extent_margin.y;

  const tl = new v2(x - w - mw, y - h - mh);
  const bl = new v2(x - w - mw, y + h + mh);
  const tr = new v2(x + w + mw, y - h - mh);
  const br = new v2(x + w + mw, y + h + mh);

  let sample_dist = opts.COVERPOINT_SAMPLE_DIST;
  let nmin = 1;
  if (ty === 'full') {
    sample_dist *= 2;
  }

  const o = new v2(x, y);
  const routepoints = createPolyRect(x, y, (w + mw) * 2, (h + mh) * 2);

  const nx = Math.max(nmin, Math.floor(2 * h / sample_dist));
  const ny = Math.max(nmin, Math.floor(2 * w / sample_dist));

  let coverpoints = [];
  const add_coverpoints = (points, lookat) => {
    for (const pos of points) {
      coverpoints.push({ pos: pos.rot(o, heading), coverdir: lookat - heading });
    }
  };

  if (!no_coverpoint) {
    add_coverpoints(samplePoints(tl, bl, nx), Math.PI * 1 / 2);
    add_coverpoints(samplePoints(bl, br, ny), Math.PI * 0 / 2);
    add_coverpoints(samplePoints(br, tr, nx), Math.PI * 3 / 2);
    add_coverpoints(samplePoints(tr, tl, ny), Math.PI * 2 / 2);
  }

  const polygon = createPolyRect(x, y, 2 * w, 2 * h).map((p) => p.rot(o, heading));

  let expand = 0.1;
  if (ty === 'door') {
    expand = 2;
  }
  const polygon_expanded = createPolyRect(x, y, 2 * w + expand, 2 * h + expand).map((p) => p.rot(o, heading));

  const obs = {
    ty,
    pos,
    polygon,
    polygon_expanded,
    polygon_aabb: v2.polygonAABB(polygon),

    coverpoints,
    routepoints: routepoints.map((p) => p.rot(o, heading)),

    extent,
    heading,
  };
  if (ty === 'door') {
    obs.doorstate = { open: false, headings: [] };
  }
  return obs;
}

function routeEdges(routes, src_idx) {
  const { edges } = routes;

  const lb = bounds.gt(edges, { from_idx: src_idx, to_idx: -1 }, route_edge_cmp);
  const ub = bounds.gt(edges, { from_idx: src_idx, to_idx: 1000000 }, route_edge_cmp);
  return edges.slice(Math.abs(lb), Math.abs(ub));
}

export function findReachables(routes, p0) {
  // 초기 접근 가능 node를 찾음. navnet 대신 visnet을 사용합니다.
  const { sim, conn } = routes;
  return conn.reachables(sim.rr.triangulated, p0.x, p0.y);
}

export function routeNodeFromPos(routes, pos) {
  const node_idx = bounds.eq(routes.nodes, { pos }, (a, b) => a.pos.cmp(b.pos));
  if (node_idx === -1) {
    return null;
  }
  return routes.nodes[node_idx];
}

function pathfindNodes(routes, p0, costFunc) {
  const nodes = [];
  const entry0 = { cost: 0, len: 0, pos: p0, edge: null, p: null, idx: -1 };

  // find initial nodes: step 0, exact point
  const p0_node = routeNodeFromPos(routes, p0);
  if (p0_node !== null) {
    entry0.idx = p0_node.idx;
    nodes.push(entry0);
    return nodes;
  }

  const candidates = findReachables(routes, p0);

  // find initial edges
  for (const idx of candidates) {
    const node = routes.nodes[idx];

    // TODO: 문 옆으로 진입하도록 cheat
    if (node.obstacle.ty === 'door') {
      if (!node.obstacle.doorstate.open && node.is_coverpoint) {
        continue;
      }
    }
    const { pos } = node;

    const len = p0.dist(pos);
    const edge = { from: p0, to: pos, len, obstacle: null };
    const cost = costFunc(edge);
    const entry = { cost, len, pos, edge: null, p: entry0, idx };

    nodes.push(entry);
  }

  return nodes;
}

function pathfindHeap(routes, p0, costFunc) {
  // dijkstra
  const heap = new Heap((a, b) => {
    return a.cost - b.cost;
  });

  for (const node of pathfindNodes(routes, p0, costFunc)) {
    heap.push(node);
  }

  return heap;
}

// p0: obstructed
// p1: unobstructed
export function bisectEdge(routes, p0, p1, target, n) {
  let lb = 0;
  let ub = 1;
  n = n ?? 10;

  for (let i = 0; i < n; i++) {
    const mid = (lb + ub) / 2;
    const p = v2.lerp(p0, p1, mid);

    const o = obstructed(p, target, routes.obstacles);
    const oo = o && !(o?.ty === 'door' && o.doorstate.open);
    if (oo) {
      lb = mid;
    } else {
      ub = mid;
    }
  }
  return { lb, ub };
}

export function coverEdges(routes, source, target) {
  const obstructed_list = new Array(routes.nodes.length);
  const edges = [];

  // 판정에서 full더 먼저 걸리도록, door < full < half, ...
  const obstacles = routes.obstacles
    .filter((o) => {
      if (o.ty === 'full') {
        return true;
      }
      if (o.ty === 'door' && !o.doorstate.open) {
        return true;
      }
      return false;
    })
    .sort((o0, o1) => o0.ty.localeCompare(o1.ty));
  const dists = routePathfindAll(routes, source, true);
  for (let i = 0; i < routes.nodes.length; i++) {
    const node = routes.nodes[i];
    if (dists[i] < 0) {
      continue;
    }
    if (source.dist(node.pos) > opts.COVER_EDGE_DIST) {
      continue;
    }
    if (node.is_coverpoint) {
      continue;
    }
    const o = obstructed(target, node.pos, obstacles);
    obstructed_list[node.idx] = (o !== null);
  }

  for (const edge of routes.edges_shoot) {
    const { from_idx: idx0, to_idx: idx1 } = edge;
    const o0 = obstructed_list[idx0];
    const o1 = obstructed_list[idx1];
    if (o0 === undefined || o1 === undefined) {
      continue;
    }

    if (o0 && !o1) {
      edges.push({ idx_from: idx0, idx_to: idx1 });
    }
  }

  return edges.map((e) => {
    const { idx_from, idx_to } = e;
    const p_from = routes.nodes[idx_from];
    const p_to = routes.nodes[idx_to];

    const { lb, ub } = bisectEdge(routes, p_from.pos, p_to.pos, target, 5);
    const mid = (lb + ub) / 2;
    const p_mid = v2.lerp(p_from.pos, p_to.pos, mid);
    const dist = source.dist(p_mid);

    return { idx_from, idx_to, p_from, p_to, p_mid, dist, lb, ub };
  });
}

export function hostilityNetwork(routes, p0) {
  const mst = minimumSpanningTree(routes, p0);
  const closures = [];

  function find_elem(to_idx) {
    for (const elem of mst) {
      if (elem.edge.to_idx === to_idx) {
        return elem;
      }
    }
    return null;
  }

  function get_parents(elem) {
    const l = [];
    let cursor = elem;
    while (cursor.p && cursor.edge) {
      l.push(cursor.edge.from_idx);
      cursor = cursor.p;
    }
    return l;
  }

  for (const elem of mst) {
    if (!elem.leaf) {
      continue;
    }

    const visited = new Set(get_parents(elem));
    const edges = routeEdges(routes, elem.idx);
    edges.sort((a, b) => a.len - b.len);

    let max_connect = 0;
    for (const edge of edges) {
      if (edge.from_idx !== elem.edge.to_idx) {
        continue;
      }
      if (visited.has(edge.to_idx)) {
        continue;
      }
      closures.push(edge);
      max_connect += 1;
      if (max_connect === 1) {
        break;
      }

      let cursor = find_elem(edge.to_idx);
      if (!cursor) {
        continue;
      }
      // find children
      const q = [cursor];
      while (q.length > 0) {
        const cur = q.pop();
        const node = cur.edge.to_idx;
        if (visited.has(node)) {
          continue;
        }
        visited.add(node);
        for (const elem of mst) {
          if (node === elem.edge.from_idx) {
            q.push(elem);
          }
        }
      }

      while (cursor && cursor.p && cursor.edge) {
        let idx = cursor.edge.from_idx;
        if (visited.has(idx)) {
          break;
        }
        visited.add(idx);
        cursor = cursor.p;
      }
      // to_idx의 parent를 찾아야 합니다.
    }
  }

  // return mst.map((elem) => elem.edge);
  return closures.concat(mst.map((elem) => elem.edge));
}

export function minimumSpanningTree(routes, p0) {
  // dijkstra
  const heap = pathfindHeap(routes, p0, (edge) => edge.len);
  const visited = new Uint8Array(routes.nodes.length);
  const results = [];
  let visitedCount = 0;

  while (!heap.empty() && visitedCount < routes.nodes.length) {
    const elem = heap.pop();
    if (visited[elem.idx]) {
      continue;
    }
    visited[elem.idx] = 1;
    visitedCount += 1;

    if (elem.edge) {
      if (elem.p) {
        elem.p.leaf = false;
      }
      results.push(elem);
    }

    const edges = routeEdges(routes, elem.idx);
    for (const edge of edges) {
      if (visited[edge.to_idx]) {
        continue;
      }
      const entry = {
        cost: edge.len,
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
        leaf: true,
      };
      heap.push(entry);
    }
  }
  return results;
}

// skip elem if fn(elem) returns true
export function routePathfindFilter(routes, p0, fn) {
  // dijkstra
  const heap = pathfindHeap(routes, p0, (edge) => edge.len);
  const distance = new Float32Array(routes.nodes.length);
  distance.fill(-1);

  while (!heap.empty()) {
    const elem = heap.pop();
    if (distance[elem.idx] >= 0) {
      continue;
    }
    if (fn && fn(elem)) {
      continue;
    }
    distance[elem.idx] = elem.cost;

    const edges = routeEdges(routes, elem.idx);
    for (const edge of edges) {
      if (distance[edge.to_idx] >= 0) {
        continue;
      }
      const entry = {
        cost: elem.cost + edge.len,
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
      };
      heap.push(entry);
    }
  }
  return distance;
}

// p_vis에 모두 닿을 때 까지 찾는게 정석인데 임시로 우회함
export function routePathfindVisible(routes, p0, p_vis) {
  const map = new Int32Array(routes.nodes.length);
  for (const idx of findReachables(routes, p0)) {
    map[idx] = 1;
  }
  for (const idx of findReachables(routes, p_vis)) {
    map[idx] = 1;
  }

  return routePathfindFilter(routes, p0, (elem) => {
    if (map[elem.idx] === 0) {
      return true;
    }
    return false;
  });
}

export function routePathfindAll(routes, p0, open_door) {
  return routePathfindFilter(routes, p0, (elem) => {
    if (open_door) {
      return false;
    }
    const obs = routes.nodes[elem.idx].obstacle;
    if (obs.ty === 'door' && !obs.doorstate.open) {
      return true;
    }
    return false;
  });
}

// both p1 should be in route
export function routePathfind(routes, p0, p1, costFunc, skips, allow_cover_edge) {
  if (!costFunc) {
    costFunc = (edge) => {
      return edge.len;
    };
  }
  if (!skips) {
    skips = [];
  }

  let nodes = new Map();
  const p1_node = routeNodeFromPos(routes, p1);
  const p1_node_idx = p1_node?.idx ?? -1;
  if (p1_node === null) {
    if (!obstructed(p0, p1, routes.obstacles.filter((o) => {
      if (o.ty === 'door' && o.doorstate.open) {
        return false;
      }
      return true;
    }))) {
      // TODO: non-point to non-point
      const edge = { from: p0, to: p1, len: p0.dist(p1), obstacle: null };
      const entry0 = { cost: 0, len: 0, pos: p0, edge: null, p: null, idx: -1 };
      const entry = {
        cost: costFunc(edge),
        len: edge.len,
        pos: p1,
        edge: edge,
        p: entry0,
        idx: -1,
      };
      return [entry0, entry];
    } else {
      const entries = pathfindNodes(routes, p1, costFunc);
      for (const entry of entries) {
        nodes.set(entry.idx, entry);
      }
    }
  }

  // dijkstra
  const heap = pathfindHeap(routes, p0, costFunc);
  const visited = new Uint8Array(routes.nodes.length);

  while (!heap.empty()) {
    const elem = heap.pop();
    if (visited[elem.idx]) {
      continue;
    }
    if (skips.includes(elem.idx)) {
      continue;
    }
    visited[elem.idx] = 1;

    if (elem.idx === p1_node_idx) {
      const decoded = [];
      let cur = elem;
      while (cur !== null) {
        decoded.push(cur);
        cur = cur.p;
      }
      decoded.reverse();
      return decoded;
    }

    if (nodes.has(elem.idx)) {
      const node = nodes.get(elem.idx);

      // const entry = { cost, len, pos, edge: null, p: entry0, idx: i };
      const entry = {
        cost: elem.cost + node.cost,
        len: node.len,
        pos: node.p.pos,
        edge: node.edge,
        p: elem,
        idx: -1,
      };
      heap.push(entry);
    }

    const edges = routeEdges(routes, elem.idx);
    for (const edge of edges) {
      if (visited[edge.to_idx]) {
        continue;
      }
      const entry = {
        cost: elem.cost + costFunc(edge),
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
      };
      heap.push(entry);
    }
  }
  return [];
}

function route_edge_cmp(a, b) {
  const c0 = a.from_idx - b.from_idx;
  if (c0 !== 0) { return c0; }
  return a.to_idx - b.to_idx;
}

function obstructed0_t(a, b, obstacle) {
  stats.obstructed0_t += 1;

  let t_min = 1;
  if (!aabb_intersect(a.min(b), a.max(b), obstacle.polygon_aabb.tl, obstacle.polygon_aabb.br)) {
    return t_min;
  }

  const points = obstacle.polygon;
  const len = points.length;
  for (let i = 0; i < len; i++) {
    const p0 = points[i];
    const p1 = points[(i + 1) % len];
    const t = v2.intersect_t(a, b, p0, p1);
    const u = v2.intersect_t(p0, p1, a, b);
    if (t > 0 && u > 0 && u < 1 && t < t_min) {
      t_min = t;
    }
  }
  return t_min;
}

function obstructed0(a, b, obstacle) {
  stats.obstructed0 += 1;

  if (!aabb_intersect(a.min(b), a.max(b), obstacle.polygon_aabb.tl, obstacle.polygon_aabb.br)) {
    return false;
  }

  const points = obstacle.polygon;
  const len = points.length;
  for (let i = 0; i < len; i++) {
    const p0 = points[i];
    const p1 = points[(i + 1) % len];
    if (v2.intersect(a, b, p0, p1)) {
      return true;
    }
  }
  return false;
}

export function obstructed_t(a, b, obstacles) {
  stats.obstructed_t += 1;
  let t = 1;
  for (const obstacle of obstacles) {
    t = Math.min(t, obstructed0_t(a, b, obstacle));
  }
  return t;
}

export function obstructed(a, b, obstacles) {
  stats.obstructed += 1;

  for (const obstacle of obstacles) {
    if (obstructed0(a, b, obstacle)) {
      return obstacle;
    }
  }
  return null;
}

export function obstructed_all(a, b, obstacles, out) {
  for (const obstacle of obstacles) {
    if (obstructed0(a, b, obstacle)) {
      out.push(obstacle);
    }
  }
}

export class Route {
  constructor(m, sim) {
    const { obstacles, world, rn } = sim;

    const nodes = [];
    const edges = [];
    const edges_shoot = [];

    function isValidPoint(o, p) {
      for (const obstacle of obstacles) {
        if (obstacle === o) {
          continue;
        }
        if (geomContains(p, obstacle.polygon_expanded)) {
          return false;
        }
      }
      return true;
    }

    // node pruning
    for (const o of obstacles) {
      o.routepoints = o.routepoints.filter((p) => isValidPoint(o, p));
      o.coverpoints = o.coverpoints.filter((p) => isValidPoint(o, p.pos));
    }

    function addNode(p, obstacle, is_coverpoint) {
      let pos = p;
      let coverdir = 0;
      if (is_coverpoint) {
        pos = p.pos;
        coverdir = p.coverdir;
      }
      if (!world.valid_world(pos)) {
        return null;
      }
      const node = { pos, obstacle, is_coverpoint, coverdir };
      nodes.push(node);
      return node;
    }

    for (let i = 0; i < obstacles.length; i++) {
      const obstacle = obstacles[i];
      obstacle.coverpoint_nodes = obstacle.coverpoints.map((p) => addNode(p, obstacle, true));
      obstacle.routepoint_nodes = obstacle.routepoints.map((p) => addNode(p, obstacle, false));
    }
    nodes.sort((a, b) => a.pos.cmp(b.pos));

    // assign indices
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].idx = i;
    }

    const doors = obstacles.filter((o) => o.ty === 'door');
    function on_pair(i, j) {
      const n0 = nodes[i];
      const n1 = nodes[j];

      const n1_door_route = n1.obstacle.ty === 'door' && n1.is_coverpoint;
      if (n1_door_route && n0.obstacle !== n1.obstacle) {
        return;
      }

      const p0 = n0.pos;
      const p1 = n1.pos;

      const door = obstructed(p0, p1, doors);
      if (door !== null) {
        if (n0.obstacle !== door || n1.obstacle !== door) {
          return;
        }
        // connect routepoints only
        if (!n0.is_coverpoint || !n1.is_coverpoint) {
          return;
        }
      }

      const len = p0.dist(p1);
      if (len === 0) {
        return;
      }

      const obstacle = nodes[j].is_coverpoint ? nodes[j].obstacle : null;
      const edge = { from: p0, to: p1, from_idx: i, to_idx: j, len, obstacle, door };
      edges.push(edge);


      if (n0.obstacle === n1.obstacle && n0.obstacle?.ty === 'full') {
        edges_shoot.push(edge);
      }
    }

    const coords = [];
    for (const node of nodes) {
      coords.push(node.pos.x);
      coords.push(node.pos.y);
    }

    const conn = m.Connectivity.from(rn.triangulated, coords);
    const pairs = conn.connectivity();

    for (let idx = 0; idx < pairs.length / 2; idx++) {
      const i = pairs[idx * 2];
      const j = pairs[idx * 2 + 1];
      on_pair(i, j);
      on_pair(j, i);
    }

    edges.sort(route_edge_cmp);

    this.nodes = nodes;
    this.edges = edges;
    this.edges_shoot = edges_shoot;
    this.obstacles = obstacles;
    this.conn = conn;
    this.sim = sim;
    this.pairs = pairs;
    this.covers = [];
  }

  free() {
    this.conn.free();
  }
}

// shoot p1, from p0, what's cover state?
//  - 3: blocked
//  - 2: covered: p1 is in cover: p1 is coverpoint of obstacle and line-of-sight is covered.
//  - 1: obstructed: p1 is not in cover, obstacle in between p0 and p1
//  - 0: uncovered: no obstacle in middle
export function checkcover(p0, p1, routes) {
  stats.checkcover += 1;

  // blocked
  for (const o of routes.obstacles) {
    if (o.ty !== 'full' && (o.ty !== 'door' || o.doorstate.open)) {
      continue;
    }
    if (obstructed0(p0, p1, o)) {
      return 3;
    }
  }

  // covered
  const p1_node = routeNodeFromPos(routes, p1);
  const ignores = [];
  if (p1_node !== null && p1_node.obstacle.ty === 'half') {
    ignores.push(p1_node.obstacle);

    if (opts.EXP_COVER_DIR) {
      let dp0 = v2.fromdir(p1_node.coverdir);
      let dp1 = p1.sub(p0)
      if (dp0.inner(dp1) < 0) {
        return 2;
      }
    } else {
      if (obstructed0(p0, p1, p1_node.obstacle)) {
        return 2;
      }
    }
  }

  const p0_node = routeNodeFromPos(routes, p0);
  if (p0_node !== null) {
    ignores.push(p0_node.obstacle);
  }

  for (const o of routes.obstacles) {
    if (o.ty === 'half' && !ignores.includes(o) && obstructed0(p0, p1, o)) {
      return 1;
    }
  }

  return 0;
}


export function raycastVisibilityWasm(vis, rays) {
  const raybuf = new Float64Array(rays.length * 2);
  for (let i = 0; i < rays.length; i++) {
    const { x, y } = rays[i];
    raybuf[i * 2] = x;
    raybuf[i * 2 + 1] = y;
  }

  const res = vis.raycast(raybuf);

  const result = [];
  for (let i = 0; i < res.length; i += 2) {
    const x = res[i];
    const y = res[i + 1];
    result.push(new v2(x, y));
  }
  return result;
}

export function raycastWasm(poly, origin, rays) {
  stats.raycastWasm += 1;

  const vis = poly.triangulated.visibility(origin.x, origin.y);
  const res = raycastVisibilityWasm(vis, rays);
  vis.free();

  return res;
}

export function onReachableGridWasmOp(world, vis, buf, op, val) {
  stats.onReachableGridWasmOp += 1;
  const writes = vis.fill_op([-world.width / 2, -world.height / 2, world.width, world.height, opts.GRID_SIZE], buf, op, val);
  stats.onReachableGridWasmOpPixels += writes;
}


export function onReachableGridWasmApply(world, vis, fn) {
  const { grid_count, grid_count_x } = world;

  const buf = new Uint8Array(grid_count);
  const res = vis.fill([-world.width / 2, -world.height / 2, world.width, world.height, opts.GRID_SIZE], buf);
  let { min_x, min_y, max_x, max_y, count } = res;
  min_x = Math.round(min_x);
  min_y = Math.round(min_y);
  max_x = Math.round(max_x);
  max_y = Math.round(max_y);

  stats.onReachableGridWasmApply += 1;
  stats.onReachableGridWasmApplyPixels += (max_y - min_y) * (max_x - min_x);
  stats.onReachableGridWasmApplySamples += count;

  for (let y = min_y; y < max_y; y++) {
    const off = y * grid_count_x;
    for (let x = min_x; x < max_x; x++) {
      let idx = off + x;
      if (buf[idx] === 0) {
        continue;
      }
      fn(idx);
    }
  }

  res.free();
}

export function onReachableGridWasm(world, poly, pos, range, fn) {
  stats.onReachableGridWasm += 1;

  const vis = poly.triangulated.visibility(pos.x, pos.y, false);
  vis.limit(range);
  onReachableGridWasmApply(world, vis, fn);
  vis.free();
}

function add_obstacle_geom(m, rng, obstacle, sx) {
  const eps = 0.01;
  const x = obstacle.pos.x + rng.range(-eps, eps);
  const y = obstacle.pos.y + rng.range(-eps, eps);

  const sx0 = m.Simplical.from_rect_seglen(x, y, obstacle.extent.x, obstacle.extent.y, -obstacle.heading, 200);
  const sx1 = sx.union(sx0);

  sx0.free();
  sx.free();

  return sx1;
}

export class GeomUnion {
  constructor(m, rng, sx) {
    this.m = m;
    this.rng = rng;
    this.sx = sx ?? m.Simplical.new();
  }

  static from_obstacles(m, rng, obstacles) {
    const u = new GeomUnion(m, rng);
    for (const o of obstacles) {
      u.add(o);
    }
    return u;
  }

  add(obstacle) {
    const { m, rng } = this;
    this.sx = add_obstacle_geom(m, rng, obstacle, this.sx);
  }

  union(other) {
    const sx = this.sx.union(other.sx);
    return new GeomUnion(this.m, this.rng, sx);
  }

  free() {
    if (this.sx) {
      this.sx.free();
      this.sx = null;
    }
  }

  take() {
    const { sx } = this;
    this.sx = null;
    return sx;
  }
}

export class UnionPoly {
  constructor(m, world, union, fog_out_to_in = opts.FOG_OUT_TO_IN) {
    const sx_o = union.take();

    const sx_world = m.Simplical.from_rect(0, 0, world.width / 2, world.height / 2, 0, 10);
    let sx = sx_world.subtract(sx_o);

    if (fog_out_to_in) {
      const margin = 10;
      const sx_shell0 = m.Simplical.from_rect(0, 0, world.width / 2 + margin / 2, world.height / 2 + margin / 2, 0, 1);
      const sx_shell1 = m.Simplical.from_rect(0, 0, world.width / 2 + margin, world.height / 2 + margin, 0, 1);

      const sx_shell = sx_shell1.subtract(sx_shell0);
      sx_shell0.free();
      sx_shell1.free();

      sx = sx.union(sx_shell);
      sx_shell.free();
    }

    const triangulated = m.Triangulated.from(sx);
    sx_world.free();
    sx_o.free();

    this.sx = sx;
    this.triangulated = triangulated;
  }

  free() {
    this.sx?.free();
    this.sx = null;
    this.triangulated?.free();
    this.triangulated = null;
  }
}

export function overlapped(points0, points1) {
  let aabb0 = v2.polygonAABB(points0);
  let aabb1 = v2.polygonAABB(points1);

  if (!aabb_intersect(aabb0.tl, aabb0.br, aabb1.tl, aabb1.br)) {
    return;
  }

  for (let i = 0; i < points0.length; i++) {
    let p0 = points0[i];
    let p1 = points0[(i + 1) % points0.length];

    for (let j = 0; j < points1.length; j++) {
      let p2 = points1[j];
      let p3 = points1[(j + 1) % points1.length];

      if (v2.intersect(p0, p1, p2, p3)) {
        return true;
      }
    }
  }

  const not_contains0 = points0.find((p) => !geomContains(p, points1));
  const not_contains1 = points1.find((p) => !geomContains(p, points0));

  if (!not_contains0 || !not_contains1) {
    return true;
  }

  return false;
}

const sample_rays = (rays, obs, p1, count) => {
  const candidates = [];
  for (let i = 0; i < rays.length; i++) {
    const lineend = rays[i];
    if (geomContains(lineend, obs.polygon_expanded)) {
      continue;
    }
    const dir = p1.sub(lineend).norm();
    const p2 = lineend.add(dir.mul(5));
    candidates.push(p2);
  }

  const candidates2 = [];
  let mindist = 10;
  while (candidates2.length < count) {
    for (const c of candidates) {
      let near = false;
      for (const c2 of candidates2) {
        if (c2.dist(c) < mindist) {
          near = true;
          break;
        }
      }
      if (!near) {
        candidates2.push(c);
      }
    }
    mindist = mindist / 2;
    if (mindist < 2) {
      mindist = 0;
    }
  }
  candidates2.sort((a, b) => candidates.indexOf(a) - candidates.indexOf(b));

  return candidates2.slice(0, count);
}

export function breachPositions(vismodel, obs, pos, count, invert = false) {
  const sample_count = 32;
  const dir = invert ? obs.pos.sub(pos).norm() : pos.sub(obs.pos).norm();
  const p1 = pos.add(dir.mul(30));

  const left = [];
  const right = [];
  for (let i = 0; i < sample_count; i++) {
    const theta = (i / sample_count) * Math.PI / 2;
    const c = Math.cos(theta);
    const s = Math.sin(theta);
    const vec = new v2(
      -c * dir.x + s * dir.y,
      -s * dir.x - c * dir.y
    );
    left.push(vec);
  }
  for (let i = 0; i < sample_count; i++) {
    const theta = -1 * (i / sample_count) * Math.PI / 2;
    const c = Math.cos(theta);
    const s = Math.sin(theta);
    const vec = new v2(
      -c * dir.x + s * dir.y,
      -s * dir.x - c * dir.y
    );
    right.push(vec);
  }
  const left_ray = raycastWasm(vismodel, p1, left);
  const right_ray = raycastWasm(vismodel, p1, right);

  const count_right = Math.floor(count / 2);
  const count_left = count - count_right;
  // invert samples
  const samples_left = sample_rays(left_ray, obs, p1, count_left).reverse();
  const samples_right = sample_rays(right_ray, obs, p1, count_right).reverse();

  const candidates = [];
  for (let i = 0; i < count_left; i++) {
    candidates.push(samples_left[i]);
    if (samples_right[i]) {
      candidates.push(samples_right[i]);
    }
  }

  return candidates;
}
