import React from 'react';
import { perks } from '../perks';
import { colors } from '../data/colors';
import _ from 'lodash';

export function PerkList(props) {
  const { ch, resource } = props;
  const selected = ch.perks;

  function renderTable(table) {

    let used_days = 0;
    for (const perk of selected) {
      used_days += perk.term;
    }


    const renderRow = (perk) => {
      const { cost, term } = perk;

      let button = null;

      if (!selected.includes(perk)) {
        if (resource - cost < 0 || used_days + term > 28) {
          button = <button>불가</button>;
        } else {
          button = <button onClick={() => props.onPerkSelect(ch, perk)}>선택</button>;
        }
      } else {
        const colorIdx = selected.indexOf(perk);
        const cellColor = colorIdx === -1 ? 'white' : colors[colorIdx];
        button = <button style={{ backgroundColor: cellColor }}
          onClick={() => props.onPerkUnselect(ch, perk)}>해제</button>;
      }

      let availableFriearm = perk.available_firearm_ty.join(', ');
      if (availableFriearm.length === 0) availableFriearm = "all";

      return <tr key={perk.name}>
        <th>
          {perk.name}
        </th>
        <td align="left">{perk.descr}</td>
        <td align="center">{perk.term}일</td>
        <td align='center'>{availableFriearm}</td>
        <td align="center">{perk.cost}BTC</td>
        <td>
          {button}
        </td>
      </tr>;
    };

    return (
      <div style={{ width: '100%', height: 400, overflow: 'auto' }}>
        <table style={{ Width: 650 }} >
          <thead>
            <tr>
              <td>이름</td>
              <td align="left">기능?</td>
              <td align="center">기간</td>
              <td align='center'>이용화기</td>
              <td align="center">비용</td>
              <td align="center">action</td>
            </tr>
          </thead>
          <tbody>
            {table.map((perk) => renderRow(perk))}
          </tbody>
        </table>
      </div>
    );
  }

  const unselected = Object.values(perks).filter((p) => {
    for (const s of selected) {
      if (_.isEqual(s, p)) {
        return false;
      }
    }
    return true;
  });
  const sortPerks = [...selected, ...unselected];

  return (
    <>
      {renderTable(sortPerks)}
    </>
  );
}
