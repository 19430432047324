/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ContractOption } from "../ContractOption";
import { TheredbuttonSi } from "../TheredbuttonSi";
import "./style.css";

export const AgentContract = ({
  ptCancelFee = "$ CANCEL FEE",
  ptRemaining = "REMAINING",
  ptHazardPay = "$ HAZARD PAY",
  ptCost = "$ COST",
  ptShareRate = "SHARE RATE %",
  ptType = "TYPE",
  theredbuttonSiOverlapGroupClassName,
  override = (
    <ContractOption
      ptDesc="Option Description Blah Blah Foo Bar Option Description Blah Blah Foo Bar Option Description Blah Blah Foo Bar 123"
      ptName="Option_name"
    />
  ),
  theredbuttonSiOnClick = "D",
}) => {
  return (
    <div className="agent-contract">
      <TheredbuttonSi
        className="theredbutton-SI-instance"
        onClick={theredbuttonSiOnClick}
        overlapGroupClassName={theredbuttonSiOverlapGroupClassName}
        ptLabel="계약 중도 취소"
        style="S"
        varState="enabled"
      />
      <img className="lines" alt="Lines" src="/anima/img/lines.svg" />
      <div className="agentcard-item-2">
        <div className="title-20">계약 중도 취소 비용</div>
        <img className="dot-line-2" alt="Dot line" src="/anima/img/dotline.svg" />
        <div className="value-2">
          <div className="bulletproof-bold">{ptCancelFee}</div>
        </div>
      </div>
      <div className="overlap-10">
        <div className="contract-option-wrapper">{override}</div>
        <div className="contract-summary">
          <div className="overlap-11">
            <div className="table-titles">
              <div className="overlap-group-10">
                <img className="vector-6" alt="Vector" src="/anima/img/vector-190.svg" />
                <img className="vector-7" alt="Vector" src="/anima/img/vector-188.svg" />
                <div className="rectangle-2" />
              </div>
              <div className="rectangle-good" />
              <div className="overlap-12">
                <div className="title-21">계약 옵션</div>
              </div>
            </div>
            <div className="title-22">{ptType}</div>
            <div className="title-23">{ptCost}</div>
            <div className="title-24">{ptHazardPay}</div>
            <div className="title-25">{ptShareRate}</div>
            <div className="title-26">{ptRemaining}</div>
            <div className="title-27">계약 형태</div>
            <div className="title-28">기본급(월간)</div>
            <div className="title-29">위험 수당(임무 당)</div>
            <div className="title-30">수익 배분율(참여한 의뢰 성공시)</div>
            <div className="title-31">남은 계약 기간</div>
            <div className="title-32">계약 정보</div>
          </div>
        </div>
        <img className="lines-hor" alt="Lines hor" src="/anima/img/lineshor.svg" />
      </div>
    </div>
  );
};

AgentContract.propTypes = {
  ptCancelFee: PropTypes.string,
  ptRemaining: PropTypes.string,
  ptHazardPay: PropTypes.string,
  ptCost: PropTypes.string,
  ptShareRate: PropTypes.string,
  ptType: PropTypes.string,
  theredbuttonSiOnClick: PropTypes.any,
};
