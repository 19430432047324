import React, { useState } from 'react';

import { TICK_PER_WEEK } from './tick';
import { opts } from './opts.mjs';
import { L } from './localization.mjs';

import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { getIntegrityData } from './data/google/processor/data_agentIntegrity.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getTrustData } from './data/google/processor/data_agentTrust.mjs';
import { PortraitWrapper } from './PortraitsView';

import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';

import {
  FH1ButtonTiny as Button,
} from './component/figma/fh1';

import { FirearmItem2, EquipmentItem2, ThrowableItem2 } from './FigmaGearsView.js';

function color(n) {
  if (n === 0) {
    return 'cashbook-zero';
  } else if (n > 0) {
    return 'cashbook-pos';
  } else {
    return 'cashbook-neg';
  }
}

export function ValueSpan(props) {
  const { value } = props;
  const cls = `${props.className ?? ''} ${color(value)}`;
  let v = value.toFixed(0);

  if (v === '0') {
    return <span className={cls}>0</span>;
  }
  if (value > 0) {
    v = '+' + v;
  }

  return <span className={cls}>${v}</span>;

}

function WeeklyMeetingCompanyView(props) {
  const { game } = props;
  const { weeklyReports } = game;

  const currentReport = weeklyReports[weeklyReports.length - 1];
  const prevReport = weeklyReports[weeklyReports.length - 2];

  function GetSpan(props) {
    const { data, value, digit } = props;
    return value ? <span className={`flex-stamina-label-${data.idx}`}>{L(data.name)}({value.toFixed(digit)})</span> : <span>'-'</span>;
  }

  const {
    integrity,
    condition_average,
    mood_average,
  } = currentReport;

  const prevIntegrityData = getIntegrityData(prevReport.integrity);
  const prevConditionData = getConditionData(prevReport.condition_average);
  const prevMoodData = getMoodData(prevReport.mood_average);

  const currentIntegrityData = getIntegrityData(integrity);
  const currentConditionData = getConditionData(condition_average);
  const currentMoodData = getMoodData(mood_average);

  const trustData = getTrustData(game.trust);

  const trustString = L(trustData.name) + ' (' + game.trust.toFixed(0) + ')';

  // 기강으로부터의 주간 변화량:
  return <>
    <h2>{L('loc_ui_string_lobby_condition_integrity')}: <GetSpan data={prevIntegrityData} value={prevReport.integrity} digit={0} /> → <GetSpan data={currentIntegrityData} value={integrity} digit={0} /></h2>
    <h2>{L('loc_ui_string_lobby_condition_trust')}: {trustString} (<ValueSpan value={currentIntegrityData.weekly_trust} />)</h2>
    <h2>{L('loc_ui_string_lobby_condition_average_stamina')}: <GetSpan data={prevConditionData} value={prevReport.condition_average} digit={1} /> → <GetSpan data={currentConditionData} value={condition_average} digit={1} /></h2>
    <h2>{L('loc_ui_string_lobby_condition_average_mood')}: <GetSpan data={prevMoodData} value={prevReport.mood_average} digit={1} /> → <GetSpan data={currentMoodData} value={mood_average} digit={1} /></h2>
  </>;
}

function DiffText(prop) {
  const { diff } = prop;
  if (diff > 0) {
    return <font color='green'>+{diff}</font>;
  }
  else if (diff < 0) {
    return <font color='red'>-{-diff}</font>;
  }
  else {
    return <font>0</font>
  }
}

export function WeeklyMeetingView(props) {
  const { game } = props;
  const { agents_avail_all, weeklyReports, market_listings } = game;

  const tabs = [
    'company',
    // 'schedule',
    // 'balance',
    'agents',
    // 'train',
    'market',
    'recruit',
  ];
  const titles = [
    'loc_ui_string_popup_weekly_report_tab_company',
    // 'loc_ui_string_popup_weekly_report_tab_inspection',
    // 'loc_ui_string_popup_weekly_report_tab_resource',
    'loc_ui_string_popup_weekly_report_tab_agents',
    // 'loc_ui_string_popup_weekly_report_tab_trains',
    'loc_ui_string_popup_weekly_report_tab_market',
    'loc_ui_string_popup_weekly_report_tab_headhunter',
  ];

  const [tabIndex, setTabIndex] = useState(0);

  const tabButtons = [];
  if (tabIndex > 0) {
    tabButtons.push(<Button className='grind-weekly-report-leftbutton' onClick={() => setTabIndex(tabIndex - 1)}>◀</Button>);
  }
  tabButtons.push(<div className='grind-weekly-report-page'>{tabIndex + 1}/{tabs.length}</div>);
  if (tabIndex < tabs.length - 1) {
    tabButtons.push(<Button className='grind-weekly-report-rightbutton' onClick={() => setTabIndex(tabIndex + 1)}>▶</Button>);
  }

  const currentReport = weeklyReports[weeklyReports.length - 1];
  const prevReport = weeklyReports[weeklyReports.length - 2];

  const title = titles[tabIndex];
  let body;

  switch (tabs[tabIndex]) {
    case 'company': {
      function ResourceText(props) {
        const { resource } = props;
        if (resource > 0) {
          return <font color='green'>+${resource}</font>;
        }
        else {
          return <font color='red'>-${-resource}</font>;
        }
      }

      body = <>
        <div className="box">
          <h1>{L('loc_ui_string_popup_weekly_report_tab_resource')}</h1>
          <h2>{L('loc_ui_string_history_cash_balance')}: ${currentReport.balance - currentReport.income + currentReport.outgoing}</h2>
          <h2>{L('loc_ui_string_history_cash_expected_budget_change')}: <ResourceText resource={currentReport.income - currentReport.outgoing} /></h2>
          <h2>{L('loc_ui_string_history_cash_income')}: <ResourceText resource={currentReport.income} /></h2>
          <h2>{L('loc_ui_string_history_cash_expense')}: <ResourceText resource={-currentReport.outgoing} /></h2>
          <h2>{L('loc_ui_string_history_cash_expected_balance')}: ${currentReport.balance}</h2>
        </div>
        <div className="box">
          <h1>{L('loc_ui_string_popup_weekly_report_tab_company')}</h1>
          <WeeklyMeetingCompanyView {...props} />
        </div>
      </>;

      break;
    }
    case 'schedule': {
      const week = Math.ceil(game.turn / TICK_PER_WEEK);
      const squad = game.squads[0];
      let date = <div>
        {L('loc_ui_longtext_popup_weekly_report_inspection_weeks', { count: week, ordinal: true })}
      </div>;

      body =
        <div>
          {date}
          <h1>{L('loc_ui_string_mission_history_title')}</h1>
          <HistoryReportView history={squad.history} />
          <p>{L('loc_ui_string_history_report_all_tries')}</p>
          <HistoryView agents={game.agents_all} history={squad.history} limit={10} />
        </div>
      break;
    }
    case 'agents': {
      const tables = [];

      for (let i = 0; i < Math.ceil(agents_avail_all.length / 5); i++) {
        const portraits = [];
        const agent_names = [];
        const modifiers_prev = [];
        const modifiers_next = [];
        const power_diffs = [];

        for (let j = 5 * i; j < Math.min(5 * (i + 1), agents_avail_all.length); j++) {
          const agent = agents_avail_all[j];
          let prevAgentState = prevReport.agentStates.find((a) => a.idx === agent.idx);
          let prevModifier;
          if (prevAgentState) {
            prevModifier = prevAgentState.modifier;
          }
          else {
            prevAgentState = agent;
            prevModifier = agent.modifier.map((m) => m.key);
          }

          const modifiers_current = [];
          const modifiers_remove = [];
          const modifiers_next_bodies = [];

          for (let k = 0; k < agent.modifier.length; k++) {
            const mod = agent.modifier[k];
            modifiers_current.push(mod.key);
            if (mod.term !== 'semiperma' && mod.start + mod.term <= game.turn + TICK_PER_WEEK) {
              const data = data_agentModifiers.find((d) => d.key === mod.key);
              if (data.visible) {
                modifiers_next_bodies.push(<div key={k} title={data.desc}>{agentModifierText(mod, game.turn)}</div>);
              }
            }
          }

          for (const key of prevModifier) {
            const idx = modifiers_current.findIndex((m) => m === key);
            if (idx >= 0) {
              modifiers_current.splice(idx, 1);
            }
            else {
              modifiers_remove.push(key);
            }
          }

          const modifiers_prev_body = [];
          let idx = 0;
          for (const key of modifiers_current) {
            const data = data_agentModifiers.find((d) => d.key === key);
            if (data.visible) {
              modifiers_prev_body.push(<div key={idx++} title={data.desc}>+{L(data.name)}</div>);
            }
          }
          for (const key of modifiers_remove) {
            const data = data_agentModifiers.find((d) => d.key === key);
            if (data.visible) {
              modifiers_prev_body.push(<div key={idx++} title={data.desc}>-{L(data.name)}</div>);
            }
          }

          const prev_power = prevAgentState?.power ?? agent.power;
          const power_diff = (agent.power - prev_power).toFixed(1);

          portraits.push(<PortraitWrapper agent={agent} className="portrait-frame-small2" noRole={true} />);
          agent_names.push(agent.name);
          modifiers_prev.push(modifiers_prev_body);
          modifiers_next.push(modifiers_next_bodies);
          power_diffs.push(<><div>{prev_power.toFixed(1)} → {agent.power.toFixed(1)}</div><div><DiffText diff={power_diff} /></div></>);
        }

        tables.push(<table key={i}>
          <thead>
            <tr>
              <th> </th>
              {portraits.map((portrait, i) => <th key={i}>{portrait}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> </th>
              {agent_names.map((name, i) => <th key={i}>{name}</th>)}
            </tr>
            <tr>
              <th>{L('loc_ui_string_history_agents_summary')}</th>
              {modifiers_prev.map((mod, i) => <th key={i}>{mod}</th>)}
            </tr>
            <tr>
              <th>{L('loc_ui_string_history_agents_upcoming')}</th>
              {modifiers_next.map((mod, i) => <th key={i}>{mod}</th>)}
            </tr>
            <tr>
              <th>{L('loc_ui_string_agent_power')}</th>
              {power_diffs.map((power, i) => <th key={i}>{power}</th>)}
            </tr>
          </tbody>
        </table>);
      }

      body = <div>
        {tables.map((table, i) => <div key={i}>{table}</div>)}
      </div>;
      break;
    }
    case 'market': {
      const itemRate = (item) => {
        const ty = item.ty;
        if (ty === 'firearm') {
          return item.firearm.firearm_rate;
        }
        else if (ty === 'equipment') {
          return item.equipment.vest_rate;
        }
        else if (ty === 'throwable') {
          return item.throwable.throwable_rate;
        }

        return 0;
      }

      const items = market_listings.slice().sort((a, b) => itemRate(b) - itemRate(a));
      body = <div className='grind-weekly-report-market'>
        {
          items.map((item, i) => {
            const cost = game.getItemBuyCost(item.ty, item.original_buy_cost, item.buy_discount_percent);
            switch (item.ty) {
              case 'firearm':
                return <FirearmItem2 item={item} original_cost={item.original_buy_cost} cost={cost} idx={i} buy={true} selected={false} onSelect={() => null} />;
              case 'equipment':
                return <EquipmentItem2 item={item} original_cost={item.original_buy_cost} cost={cost} idx={i} buy={true} selected={false} onSelect={() => null} />;
              case 'throwable':
                return <ThrowableItem2 item={item} original_cost={item.original_buy_cost} cost={cost} idx={i} buy={true} selected={false} onSelect={() => null} />;
            }
          })
        }
      </div>
      break;
    }
    case 'recruit': {
      const recruit_listings = game.recruit_listings.slice().sort((a, b) => a.tier - b.tier);
      const tables = [];

      for (let i = 0; i < Math.ceil(recruit_listings.length / 5); i++) {
        const portraits = [];
        const agent_names = [];
        const agent_tiers = [];

        for (let j = 5 * i; j < Math.min(5 * (i + 1), recruit_listings.length); j++) {
          const agent = recruit_listings[j];

          portraits.push(<PortraitWrapper agent={agent} className="portrait-frame-small2" noRole={true} />);
          agent_names.push(agent.name);
          agent_tiers.push(agent.tier);
        }

        tables.push(<table>
          <thead>
            <tr>
              {portraits.map((portrait, i) => <th key={i}>{portrait}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {agent_names.map((name, i) => <th key={i}>{name}</th>)}
            </tr>
            <tr>
              {agent_tiers.map((tier, i) => <th key={i}>{L('loc_dynamic_string_agent_grade', { value: tier })}</th>)}
            </tr>
          </tbody>
        </table>);
      }

      body = <div>
        {tables.map((table, i) => <div key={i}>{table}</div>)}
      </div>;
      break;
    }
    default:
      break;
  }

  return <div className="box grind-weekly-report">
    <h1>{L(title)}</h1>
    <div className='box grind-weekly-report-body'>
      {body}
    </div>
    {tabButtons}
  </div>;
}

function HistoryReportView(props) {
  const { history } = props;

  const getReportInfo = (segments_report) => {
    for (let i = 0; i < segments_report.length; i++) {
      const report = segments_report[i];
      if (report.survived === 0) {
        return {
          last_segment: i,
          last_progress: report.enemies === 0 ? 1 : report.killed / report.enemies,
          tick: report.tick,
        };
      }
    }

    const last_segment = segments_report.length - 1;
    const last_report = segments_report[last_segment];

    return {
      last_segment,
      last_progress: last_report.enemies === 0 ? 1 : last_report.killed / last_report.enemies,
      tick: last_report.tick,
    };
  };

  let lastReportView = null;
  let lastReportIndex = -1;
  let bestReportView = null;
  let bestReportIndex = -1;
  if (history.length > 0) {
    lastReportIndex = history.length - 1;
    lastReportView = <SegmentReportView segments_report={history[lastReportIndex].segments_report} onSegmentSelected={(i) => { }} />;

    let bestReport = history[0].segments_report;
    bestReportIndex = 0;
    let bestReportInfo = getReportInfo(bestReport);
    for (let i = 1; i < history.length; i++) {
      const report = history[i].segments_report;
      const reportInfo = getReportInfo(report);

      if (reportInfo.last_segment > bestReportInfo.last_segment) {
        bestReport = report;
        bestReportIndex = i;
        bestReportInfo = reportInfo;
        continue;
      }
      else if (reportInfo.last_segment < bestReportInfo.last_segment) {
        continue;
      }

      if (reportInfo.last_progress > bestReportInfo.last_progress) {
        bestReport = report;
        bestReportIndex = i;
        bestReportInfo = reportInfo;
        continue;
      }
      else if (reportInfo.last_progress < bestReportInfo.last_progress) {
        continue;
      }

      if (reportInfo.tick < bestReportInfo.tick) {
        bestReport = report;
        bestReportIndex = i;
        bestReportInfo = reportInfo;
        continue;
      }
    }

    bestReportView = <SegmentReportView segments_report={bestReport} onSegmentSelected={(i) => { }} />;
  }

  return <>
    <p>{lastReportIndex >= 0 ? L('loc_dynamic_string_history_report_last', { value: lastReportIndex + 1 }) : null}</p>
    <p>{lastReportView}</p>
    <p>{bestReportIndex >= 0 ? L('loc_dynamic_string_history_report_best', { value: bestReportIndex + 1 }) : null}</p>
    <p>{bestReportView}</p>
  </>;
}

function SegmentReportView(props) {
  const { segments_report, onSegmentSelected } = props;
  const heads = [<th key={0}></th>];
  const body_progress = [<th key={0}>{L('loc_ui_string_history_report_progress')}</th>];
  const body_time = [<th key={0}>{L('loc_ui_string_history_report_time')}</th>];
  const body_surviveRate = [<th key={0}>{L('loc_ui_string_history_report_survival_rate')}</th>];
  for (let i = 0; i < segments_report.length; i++) {
    const report = segments_report[i];

    heads.push(<th key={i + 1} onClick={() => onSegmentSelected(i)}
      data-clickable="true">{L('loc_ui_string_mission_plan_section_individual', { value: i })}</th>);
    if (report.tick > 0) {
      body_progress.push(<th key={i + 1}>{report.killed}/{report.enemies}</th>);
      body_time.push(<th key={i + 1}>{Math.floor(report.tick / opts.tps)}{report.killed >= report.enemies ? '' : '(' + L('loc_ui_string_common_failure') + ')'}</th>);
      body_surviveRate.push(<th key={i + 1}>{(report.survived * 100 / report.allies_total).toFixed(2)}%</th>)
    }
    else {
      body_progress.push(<th key={i + 1}>-</th>);
      body_time.push(<th key={i + 1}>-</th>);
      body_surviveRate.push(<th key={i + 1}>-</th>)
    }
  }

  return <table>
    <thead>
      <tr>
        {heads}
      </tr>
    </thead>
    <tbody>
      <tr>
        {body_progress}
      </tr>
      <tr>
        {body_time}
      </tr>
      <tr>
        {body_surviveRate}
      </tr>
    </tbody>
  </table>
}

function HistoryView(props) {
  const { agents, history, limit } = props;
  const min = Math.max(0, history.length - limit);
  return <table>
    <thead>
      <tr>
        <th>{L('loc_ui_string_history_report_try')}</th>
        <th>{L('loc_ui_string_history_report_progress')}</th>
      </tr>
    </thead>
    <tbody>
      {history.map((h, i) => {
        if (i < min) {
          return null;
        }
        return <tr key={i}>
          <td>{i + 1}</td>
          <td>{(h.progress * 100).toFixed(1)}%</td>
        </tr>;
      }).reverse()}
    </tbody>
  </table>;
}

export function ReportView(props) {
  const { game, squad } = props;
  const segments_report = squad.history[squad.history.length - 1].segments_report;

  const [sortOpt, setSortOpt] = useState({ index: 0, isDesc: true });
  const [focusedAgent, setFocusedAgent] = useState(null);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(segments_report.length - 1);
  const [reportTab, setReportTab] = useState(0);

  const { entity_ids, throwables } = squad.history[squad.history.length - 1];

  const header_statistics = [
    'loc_ui_string_agent_name',
    '병과',
    'loc_ui_string_agent_level',
    'loc_ui_string_agent_overall',
    'loc_ui_string_common_firearm',
    'loc_ui_string_common_equipment',
    'loc_ui_string_common_throwable',
    'loc_ui_string_result_statistics_kill_count',
    'loc_ui_string_agent_statistics_damage_given',
    'loc_ui_string_agent_statistics_damage_taken',
  ];

  function entityToAgent(id) {
    return game.agents_avail_all.find((a) => a.idx === id);
  }

  function agentToFirearmName(agent) {
    return `${L(agent.firearm.firearm_name)}(${L(firearm_ty_name[agent.firearm.firearm_ty])}${L('loc_dynamic_string_common_item_tier').repeat(agent.firearm.firearm_rate)})`;
  }

  function buildStatistics(id) {
    const agent = entityToAgent(id);
    const report = segments_report[selectedSegmentIndex].allies_detail[id];

    const throwables_text = [];
    const throwables_all = agent.throwables.map(t => t.throwable_name);
    const throwables_used = throwables.filter(t => t.id === agent.idx).map(t => t.throwable.throwable_name);

    for (const throwable of throwables_used) {
      const idx = throwables_all.indexOf(throwable);
      if (idx > -1) throwables_all.splice(idx, 1);
      throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_used'));
    }

    for (const throwable of throwables_all) {
      if (throwable !== 'None') {
        throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_unused'));
      }
    }

    let kill = report.kill;

    let damage_done = { ...report.damage_done };
    let damage_taken = { ...report.damage_taken };

    let damage_done_all = 0;
    let damage_taken_all = 0;

    if (reportTab === 1 && selectedSegmentIndex > 0) {
      const prevReport = segments_report[selectedSegmentIndex - 1].allies_detail[id];
      kill -= prevReport.kill;

      for (const [key, value] of Object.entries(prevReport.damage_done)) {
        damage_done[key] -= value;
      }
      for (const [key, value] of Object.entries(prevReport.damage_taken)) {
        damage_taken[key] -= value;
      }
    }

    for (const value of Object.values(damage_done)) {
      damage_done_all += value;
    }
    for (const value of Object.values(damage_taken)) {
      damage_taken_all += value;
    }

    const damage_done_body = <>
      <p>{damage_done_all.toFixed(0)}</p>
      {Object.entries(damage_done).map(([key, value], i) => <p key={i}>└ {key} : {value.toFixed(0)}</p>)}
    </>
    const damage_taken_body = <>
      <p>{damage_taken_all.toFixed(0)}{report.life <= 0 ? ' (사망)' : ''}</p>
      {Object.entries(damage_taken).map(([key, value], i) => <p key={i}>└ {key} : {value.toFixed(0)}</p>)}
    </>
    return {
      key: agent.name,
      body: [
        <PortraitWrapper agent={{ name: agent.name, role: agent.role }} className="portrait-frame-small2" noRole={true} />,
        agent.role,
        agent.level.cur,
        agent.power.toFixed(1),
        agentToFirearmName(agent),
        L(agent.equipment.vest_name),
        throwables_text.join('\n'),
        kill,
        damage_done_body,
        damage_taken_body,
      ]
    };
  }

  const statistics = [];
  for (const id of entity_ids) {
    statistics.push(buildStatistics(id));
  }

  const segmentReport = <SegmentReportView segments_report={segments_report} onSegmentSelected={(i) => { setSelectedSegmentIndex(i); }} />;

  statistics.sort((a, b) => {
    const va = a[sortOpt.index];
    const vb = b[sortOpt.index];

    const mul = sortOpt.isDesc ? 1 : -1;

    if (vb > va) {
      return mul;
    }
    else if (va > vb) {
      return -mul;
    }
    else {
      return 0;
    }
  });

  const tab_stats = [
    <table className='statistics-small'>
      <thead>
        <tr>{header_statistics.map((name, i) => <th key={i} onClick={(_ev) => {
          setSortOpt({ index: i, isDesc: sortOpt.index === i ? !sortOpt.isDesc : true });
        }} data-clickable="true">{L(name)}</th>)}</tr>
      </thead>
      <tbody>
        {statistics.map((statistic, i) => <tr key={i} onClick={() => {
          const selected = game.agents_avail_all.find((a) => a.name === statistics[i].key);
          const next = selected === focusedAgent ? null : selected;
          setFocusedAgent(next);
        }} data-clickable="true">
          {statistic.body.map((cell, j) => <td key={j}>{cell}</td>)}
        </tr>)}
      </tbody>
    </table >,
  ];

  return <>
    <span className="mission-result-subtitle">{L('loc_ui_string_result_mission_evaluation')}</span>
    {segmentReport}

    <span className="mission-result-subtitle">{L('loc_ui_string_result_statistics_mission')}</span>

    <div>
      {segments_report.map((segments, i) => {
        let cls = "mission-result-tab";
        if (i === selectedSegmentIndex) {
          cls += " mission-result-tab-selected";
        }
        return <SoundButton key={i} onClick={() => setSelectedSegmentIndex(i)} className={cls}>구간 #{i}</SoundButton>;
      })}
    </div>
    <div>
      {['누적 통계', '구간 통계'].map((name, i) => {
        let cls = "mission-result-tab";
        if (i === reportTab) {
          cls += " mission-result-tab-selected";
        }
        return <SoundButton key={i} onClick={() => setReportTab(i)} className={cls}>{name}</SoundButton>;
      })}
    </div>
    {tab_stats}
  </>;
}

export function agentModifierText(modifier, turn) {
  const { start, term } = modifier;

  const data = data_agentModifiers.find((d) => d.key === modifier.key);
  const remainText = term === 'semiperma' || !turn ? '' : '(' + L('loc_dynamic_string_common_duration_days', { count: Math.ceil((start + term - turn) / 24) }) + ')';

  return `${L(data.name)}${remainText}`;
}


