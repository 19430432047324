// figma component에서 출력하기 편하게 convert 합니다

import _ from 'lodash';

import * as data_stats from './data/google/processor/data_stats';
import * as exps from './data/google/processor/data_exp.mjs';
import * as gcap from './data/google/processor/data_growthcap.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as pscap from './data/google/processor/data_physicalcap.mjs';
import { modifiers } from './data/google/processor/data_modifiers.mjs';
import { agentModifiers } from './data/google/processor/data_agentModifiers.mjs';

import { contractDetails, CONTRACT_OPTIONS } from './contract.mjs';
import { agentHasThrowableSlot1, agentHasThrowableSlot2, agentPowerModifiers, tickToAge } from './character.mjs';
import { PortraitWrapper } from './PortraitsView';
import { FirearmShape } from './component/anima/src/components/FirearmShape';

const IMMINENT_EXPIRATION_HOUR = 24 * 7;
const DUMMY_GAME = {
  turn: 0,
  agentContribution: (agent, TICK_PER_MONTH = 1) => 123,
  inventories: [
    { ty: 'equipment', equipment: { vest_name: 'vest1', vest_armor: 10 } },
    { ty: 'equipment', equipment: { vest_name: 'vest2', vest_armor: 21 } },
    { ty: 'equipment', equipment: { vest_name: 'vest2', vest_armor: 21 } },
    { ty: 'equipment', equipment: { vest_name: 'vest2', vest_armor: 22 } },
    { ty: 'equipment', equipment: { vest_name: 'vest3', vest_armor: 30 } },
    { ty: 'foo' },
  ],
};

export function convertAgent({ agent, selected = false, game = null, turn = 0, portrait = null, onAgentEquip = null }) {
  if (!agent) {
    return null;
  }

  turn = game?.turn ?? turn;
  game ??= DUMMY_GAME;
  portrait ??= <PortraitWrapper agent={agent} />;

  const { potential, power, growthcap, physicalcap, firearm, equipment, contract } = agent;
  const expData = exps.exps.find((e) => e.level === agent.level.cur);
  const exp_cap = expData?.exp ?? agent.level.exp;

  const remain_hour = contract.start + contract.term - turn;
  const imminent_expiration = remain_hour < IMMINENT_EXPIRATION_HOUR;

  const potentialData = pot.findByPotential(potential);
  const { label: grade } = potentialData;

  const overall = power;
  const gcapData = gcap.find(growthcap);
  const overall_cap = gcapData.power_cap;
  const { amount: overall_extra } = agentPowerModifiers(agent, turn);
  const overall_descr = 'TODO: 암튼 설명 overall';

  const physical_cap = pscap.find(physicalcap).physical_cap;
  const physical_extra = 0.0; // TODO
  const physical_grade = physicalcap;
  const physical_descr = 'TODO: 암튼 설명 physical';

  const growth_grade = growthcap;

  const firearms0 = game.inventories.filter((i) => i.ty === 'firearm');
  const firearms1 = [{ firearm }].concat(firearms0);
  const firearms2 = _.uniqBy(firearms1, ({ firearm }) => firearm.firearm_name + firearm.firearm_rate);
  const firearm_options = firearms2.map((item) => {
    const f = item.firearm;
    const text = f.firearm_name;
    const selected = firearm === f;
    const selectable = !selected;
    const onSelect = () => selectable && onAgentEquip && onAgentEquip(agent, item);
    return { text, selected, onSelect };
  });
  const firearm_shape_ty = `ty${agent.firearm?.firearm_ty?.toLowerCase()}-1`;
  const firearm_shape = <div style={{
    transform: 'scale(0.5)',
    position: 'absolute',
    top: -32,
    left: -110,
  }}>
    <FirearmShape className="firearm-shape-instance" ty={firearm_shape_ty} pbShowBg={false} />
  </div>;

  const equips0 = game.inventories.filter((i) => i.ty === 'equipment' && i.equipment.vest_rate * 4 <= agent.stats2.bravery);
  const equips1 = [{ equipment }].concat(equips0);
  const equips2 = _.uniqBy(equips1, ({ equipment }) => equipment.vest_name + equipment.vest_armor);
  const equipment_options = equips2.map((item) => {
    const e = item.equipment;
    const text = `${e.vest_name} (${e.vest_armor})`;
    const selected = equipment === e;
    const selectable = !selected;
    const onSelect = () => selectable && onAgentEquip && onAgentEquip(agent, item);
    return { text, selected, onSelect };
  });

  const perks_point_total = agent.perks.point_total;

  const mods = agent.modifier.map((m) => {
    const mod = { ...m };
    // const name = modifiers[mod.key].name;
    const data = agentModifiers.find((d) => d.key === mod.key);
    const name = data?.name;
    mod.name = name;
    return mod;
  });

  const contribution = game.agentContribution(agent);

  const stats2_descr = {};
  for (const { key, descr } of data_stats.stats_descr) {
    stats2_descr[`${key}_descr`] = descr;
  }

  const contract_details = contractDetails(contract, turn);
  const contract_options = Object.entries(contract.option).map(([k, v]) => {
    if (!v) {
      return null;
    }
    return CONTRACT_OPTIONS.find((o) => o.key === k).name;
  }).filter((a) => a);

  const age = tickToAge(turn, agent.born_at);

  return {
    ...agent,

    portrait,
    exp_cap,
    remain_hour,
    imminent_expiration,
    grade, selected,
    overall, overall_cap, overall_extra, overall_descr,
    physical_cap, physical_grade, physical_extra, physical_descr,
    growth_grade,
    firearm_options,
    firearm_shape,
    equipment_options,
    throwable_unlocked_1: agentHasThrowableSlot1(agent),
    throwable_unlocked_2: agentHasThrowableSlot2(agent),
    perks_point_total,
    modifier: mods,
    contribution,
    stats2_descr,
    contract_details,
    contract_options,

    age,
  };
}

export function convertAgents(agents, agent_selected, game = null, onAgentEquip = null) {
  return agents.map((agent) => convertAgent({
    agent,
    selected: agent_selected?.name === agent.name,
    game,
    onAgentEquip,
  }));
}
