import React from 'react';
import { GrindView, GrindGame, updateAgentSalary } from './GrindView';
import { CutSceneView } from './CutSceneView';
import { DialogView2 } from './DialogView';
import { FigmaIntermissionView } from './FigmaIntermissionView';
import { Simulator } from './sim';
import { Rng } from './rand.mjs';
import { opts } from './opts.mjs';
import { L, localeSet } from './localization.mjs';

import './OutloopView.css';

const DEBUG_INTERMISSION = false;
const DEBUG_LOCALE = 'en-US';

import { addAgentModifier, resetCharacter2 } from './character2.mjs';

import { agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { briefByConfig } from './data/google/processor/data_briefs.mjs';
import { modifiers as data_outloopModifiers } from './data/google/processor/data_outloopModifiers.mjs';
import { agentCareOptions as data_agentCareOptions } from './data/google/processor/data_agentCareOptions.mjs';
import { triggerBgm } from './sound.mjs';

import { SaveImpl } from './SaveImpl';
import { TICK_PER_WEEK } from './tick.mjs';
import { GameAnalytics } from 'gameanalytics';

import { renderTooltip, TooltipContext } from './FigmaTooltipView.js';
import { BriefingView } from './BriefingView.js';
const CONFIGS = [
  {
    config_key: 'sawmill1_sabotage0',
    debrief: 'loc_data_longtext_cutscene_debrief_0',
  },
  {
    config_key: 'sabotage0',
    debrief: 'loc_data_longtext_cutscene_debrief_1',
  }
];

function fillResult(grind_result) {
  const { game, sim } = grind_result;
  const { rng } = game;

  const agent_updates = [];

  for (const agent of game.agents_avail_all) {
    const entity = sim?.entities.find((e) => e.idx === agent.idx);

    let isDead = false;
    let result = 'reserve';
    let care_option_id = '';
    if (entity) {
      const dead = entity?.life <= 0;
      if (dead) {
        care_option_id = agent.care_option_id;
        const care_option = data_agentCareOptions.find((d) => d.id === care_option_id);
        const survival_prob = Math.min(agent.base_survival_prob + care_option.additional_survival_prob, 1);
        if (rng.next() < (survival_prob)) {
          result = care_option.survive_result;
        }
        else {
          result = 'dead';
          isDead = true;
          agent.willBeFired = true;
        }
      } else {
        result = 'fine';
      }
    }
    const { stats, modifiers } = data_outloopModifiers.find((m) => m.result === result);
    let modifier_key = 'no_mod';
    const target_modifiers = modifiers.filter((m) => !agent.modifier.find((mod) => mod.key === m.modifier_key));
    if (target_modifiers.length > 0) {
      modifier_key = rng.weighted_key(target_modifiers, 'weight').modifier_key;
    }
    const modifier = agentModifiers.find((m) => m.key === modifier_key);

    agent_updates.push({
      idx: agent.idx,
      result,
      stats,
      modifier,
      isDead,
      care_option_id,
    });
  }

  const res = {
    ...grind_result,
    agent_updates,
  };
  delete res.sim;
  return res;
}

class OutloopState {
  constructor(state) {
    if (state) {
      Object.assign(this, state);

      this.rng = new Rng();
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.rng = new Rng();

    this.phase = 'brief';
    this.idx = 0;

    this.game = null;
    this.grind_result = null;

    this.agents = [];
    this.inventories = [];
    this.upgrades = [];
    this.balance = 0;
  }

  onGrindResult(result) {
    this.phase = 'debrief';
    this.grind_result = fillResult(result);
  }

  onNext({ upgrades }) {
    const { idx, grind_result } = this;
    // commit changes

    const agents = this.agents.map(resetCharacter2);

    for (const agent of agents) {
      const agent_update = grind_result.agent_updates.find((a) => a.idx === agent.idx);
      if (agent_update) {
        const { stats, modifier } = agent_update;

        // 스텟 반영
        for (const s of stats) {
          agent.realStats[s.stat] += s.value;
        }

        // 모디파이어 반영
        if (modifier) {
          addAgentModifier(agent, modifier.key, 0);
        }
      }

      updateAgentSalary(agent, agents);

      agent.realStats0 = { ...agent.realStats };
      agent.willBeFired = undefined;
    }

    this.phase = 'brief';
    this.idx = idx + 1;
    this.game = null;
    this.grind_result = null;
    this.agents = agents;

    this.upgrades = upgrades;
  }

  instantiateGame(config_key) {
    const { idx } = this;

    GameAnalytics.addDesignEvent(`mission:${idx}:start`);
    const game = new GrindGame();

    // 인원 업데이트
    const isAgentRemain = (agent) => agent.state !== 'leave' && !agent.willBeFired;
    const names_known = this.agents.filter((a) => isAgentRemain(a)).map((a) => a.id);
    game.characterIds = game.characterIds.filter((id) => {
      return !names_known.includes(id);
    });

    for (const agent of this.agents) {
      game.agentDisarm(agent);
      if (isAgentRemain(agent)) {
        game.addAgentRecruit(agent);
        game.agent_idx = Math.max(game.agent_idx, agent.idx + 1);
      }
      delete agent.willBeFired;
    }

    // 업그레이드 적용
    game.upgrades = this.upgrades.slice();
    game.reset(config_key, idx);

    game.resources.balance += this.balance;
    // 장비 지급
    for (const item of this.inventories) {
      game.inventories.push(item);
    }
    return game;
  }
}

export class OutloopView extends React.Component {
  constructor(props) {
    super(props);

    const saveimpl = new SaveImpl({
      prefix: 'proto_outloop_save',
      processor: {
        onSave: this.onSave.bind(this),
        onLoad: this.onLoad.bind(this),
        onDiscard: this.onDiscard.bind(this),
      },
    });
    const { autoload } = props;
    let loopstate = new OutloopState();
    if (autoload >= 0) {
      loopstate.phase = 'grind';
    }

    this.tooltipRef = React.createRef();

    this.state = {
      saveimpl,
      loopstate,

      autoload,

      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
    };

    if (DEBUG_INTERMISSION) {
      const game = new GrindGame();
      game.reset(opts.GRIND_SELECTED_MAP);

      const agents = game.recruit_listings.slice(0, 4);
      for (const agent of agents) {
        game.recruitAgent(agent);
        game.onAssign(0, agent);
      }
      game.renewMission(0);
      const sim = Simulator.create({ ...game.squads[0].mission_state.simstate, m: this.props.m });

      loopstate.phase = 'intermission';
      loopstate.idx = 0;
      loopstate.grind_result = fillResult({
        result: 'clear',
        game,
        sim,
      });

      localeSet(DEBUG_LOCALE);
    }
  }

  onSave({ data: inner, descr }, idx) {
    const { loopstate } = this.state;

    descr = L('loc_ui_string_save_mission') + ` #${loopstate.idx + 1}: ${descr}`;
    const data = JSON.stringify({ loopstate, inner });
    return { data, descr };
  }

  onLoad(data, idx) {
    GameAnalytics.addDesignEvent(`load:${idx}`);
    const { loopstate: loopstate0, inner } = JSON.parse(data);
    const loopstate = new OutloopState(loopstate0);
    this.setState({ loopstate, autoload: -1 });
    return inner;
  }

  onDiscard(idx) {
    GameAnalytics.addDesignEvent(`discard:${idx}`);
  }

  renderIntermission() {
    const { loopstate } = this.state;
    if (!loopstate) {
      return null;
    }
    const { idx, grind_result, upgrades } = loopstate;


    let onNext = null;
    if (idx < CONFIGS.length - 1) {
      onNext = ({ upgrades }) => {
        const { loopstate } = this.state;
        loopstate.onNext({ upgrades });
        this.setState({ loopstate });
      };
    }
    return <TooltipContext.Provider value={{
      ctx: this.state.tooltipContext, setCtx: (ctx) => {
        this.setState({ tooltipContext: ctx });
      }
    }}>
      <div className='figmaintermission-lobby' onMouseMove={(e) => {
        if (!e._tooltipmove) {
          this.setState({ tooltipContext: { ...this.state.tooltipContext, content: null } });
        }
      }}>
        <FigmaIntermissionView grind_result={grind_result} onNext={(res) => {
          if (onNext) {
            onNext(res);
          } else {
            // i18n ignore 1
            alert(`다음 임무가 없습니다: ${idx} < ${CONFIGS.length - 1}`);
          }
        }} upgrades={upgrades} level={idx} onAgentUpdate={(agent) => {
          if (agent) {
            loopstate.agents = loopstate.agents.map((item => item.id === agent.id ? agent : item));
          }
          this.setState({ loopstate });
        }} />

        {renderTooltip(this.tooltipRef)}
      </div>;
    </TooltipContext.Provider >;

  }

  render() {
    const { m } = this.props;
    const { saveimpl, autoload, loopstate } = this.state;
    if (!loopstate) {
      return null;
    }
    const { phase, idx } = loopstate;
    const { config_key, debrief } = CONFIGS[idx];

    switch (phase) {
      case 'brief': {
        triggerBgm('BGM_Outgame_Briefing_Loop', 'Play');
        const { brief, contentImage } = briefByConfig(config_key);
        return <BriefingView data={brief} contentImage={contentImage} onDone={() => {
          const game = loopstate.instantiateGame(config_key);

          loopstate.phase = 'grind';
          loopstate.game = game;

          this.setState({ loopstate });
        }} />;
      }

      case 'grind': {
        const { game } = loopstate;
        return <GrindView m={m} game={game}
          autoload={autoload}
          saveimpl={saveimpl}
          enable_dialog={true}
          onGameOver={() => {
            this.props.onGameOver?.();
          }}
          onDone={(result) => {
            if (!result.sim) {
              const { game } = result;
              game.renewMission(0);
              const sim = Simulator.create({ ...game.squads[0].mission_state.simstate, m });
              result.sim = sim;
            }

            loopstate.onGrindResult(result);
            this.setState({ loopstate });
          }} />;
      }

      case 'debrief': {
        return <CutSceneView text={L(debrief)} onDone={() => {
          const { grind_result } = loopstate;
          const { game } = grind_result;

          let agents = game.agents_all.map((a) => {
            let state = 'leave';
            if (game.agents_avail_all.includes(a) && !a.willBeFired) {
              state = 'alive';
            }
            return { ...a, state };
          });

          loopstate.phase = 'intermission';
          loopstate.agents = agents;
          loopstate.upgrades = game.upgrades.slice();
          loopstate.inventories = game.inventories.slice();

          let remain_weeks;
          if (game.income_count) {
            remain_weeks = game.config.deadline_weeks - game.income_count;
          }
          //세이브 파일에 income_count 없는 경우 대응
          else {
            const checkpoints = game.squads[0].history.filter((h) => h.ty === 'checkpoint');
            if (checkpoints.length > 0) {
              remain_weeks = game.config.deadline_weeks - Math.floor(checkpoints[checkpoints.length - 1].turn / TICK_PER_WEEK);
            }
            else {
              remain_weeks = game.config.deadline_weeks - Math.floor(game.date / 7);
            }
          }
          loopstate.balance = game.resources.balance + remain_weeks * game.getFinalIncome();

          this.setState({ loopstate });
        }} />;
      }

      case 'intermission': {
        return this.renderIntermission();
      }
    }
  }
}
