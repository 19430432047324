import React from 'react';
import { PerkList } from "./PerkList";
import PropTypes from 'prop-types';
import { WeaponList } from './WeaponList';
import { EquipmentList } from './EquipmentList';
import ResourceContext from './ResourceContext';
import { colors } from '../data/colors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <div>{children}</div>
      }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ItemTab(props) {
  const { ch } = props;
  const { perks } = ch;
  // tabs
  const [value, setValue] = React.useState(0);
  const handleClick = (newValue) => {
    setValue(newValue);
  };

  const styles = ((color) =>
  ({
    display: 'inline-block',
    margin: 1,
    border: '1px solid',
    width: '15px',
    height: '15px',
    backgroundColor: color
  }));

  const cells = [];
  for (let i = 0; i < perks.length; i++) {
    let perk = perks[i];
    let cellColor = colors[i];

    for (let j = 0; j < perk.term; j++) {
      cells.push(<div key={cells.length} style={styles(cellColor)}></div>);
    }
  }
  while (cells.length < 28) {
    cells.push(<div key={cells.length} style={styles('transparent')}></div>);
  }

  return (<ResourceContext.Consumer>
    {(resource) => {
      return <>
        <div>resource = {resource} </div>
        <div>{cells}</div>

        <div style={{ width: '100%' }} >
          <button onClick={() => handleClick(0)} value={value}>Perk</button>
          <button onClick={() => handleClick(1)} value={value}>Weapon</button>
          <button onClick={() => handleClick(2)} value={value}>Equipment</button>
        </div>
        <TabPanel value={value} index={0}>
          <PerkList resource={resource} ch={ch}
            onPerkSelect={props.onPerkSelect}
            onPerkUnselect={props.onPerkUnselect}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WeaponList
            ch={ch}
            onWeapon={props.onWeapon}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EquipmentList
            ch={ch}
            onEquip={props.onEquip}
          />
        </TabPanel>
      </>;
    }}
  </ResourceContext.Consumer>
  );
}
