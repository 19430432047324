import React from 'react';
import { firearms } from '../presets_firearm';
import ResourceContext from './ResourceContext';
import _ from 'lodash';

function WeaponRow(props) {
  const { weapon: w, selected, onWeapon, ch } = props;
  let button = <ResourceContext.Consumer>
    {(resource) => {
      return <button onClick={() => onWeapon(ch, w)} disabled={resource + ch.weapon.firearm_cost - w.firearm_cost < 0}>Equip</button>
    }}
  </ResourceContext.Consumer>
  if (selected) {
    //TODO
    button = <button style={{ backgroundColor: 'red' }}>Equipped</button>;
  }

  return <tr key={w.firearm_name}>
    <th>
      {w.firearm_name}
    </th>
    <td align="left">{w.firearm_ty}</td>
    <td align="center">{w.firearm_rate}★</td>
    <td align="right">{w.firearm_cost}BTC</td>
    <td>
      {button}
    </td>
  </tr>;
}

export function WeaponList(props) {
  const { ch } = props;
  return (
    <div style={{ width: '100%', height: 400, overflow: 'auto' }}>
      <table style={{ Width: 650 }}>
        <thead>
          <tr>
            <td>이름</td>
            <td align="center">종류</td>
            <td align="center">랭크</td>
            <td align="center">비용</td>
            <td align="center"></td>
          </tr>
        </thead>
        <tbody>
          {firearms.map((w, i) => {
            // (ch.weapon, w, w === ch.weapon) chae-왜 이거 안되지?
            return <WeaponRow key={i} weapon={w} selected={_.isEqual(w, ch.weapon)}
              onWeapon={props.onWeapon} ch={ch} />;
          })}
        </tbody>
      </table>
    </div>
  );
}
