import './App.css';
import React from 'react';
import { Structure } from './room.mjs';
import { Rng } from './rand.mjs';
import { v2 } from './v2.mjs';

const canvasSize = 800;

class Make extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = this.initialState(props);
  }

  initialState(_props) {
    let structure = null;
    const rng = new Rng(Rng.randomseed());

    const start = v2.unit(50);
    const size = new v2(rng.integer(5, 7) * 100, rng.integer(5, 7) * 100);
    const end = start.add(size);

    while (structure === null) {
      structure = Structure.create(rng, { start, end, enterance: 3 });
    }
    return { structure };
  }

  componentDidMount() {
    this.renderCanvas();
  }

  render() {
    return (
      <>
        <canvas id="canvas" width={canvasSize} height={canvasSize}
          ref={this.canvasRef}
        ></canvas>
      </>
    );
  }

  renderCanvas() {
    if (this.state.structure === null) {
      return;
    }

    const { structure } = this.state;
    if (!structure) {
      return;
    }

    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.resetTransform();

    ctx.font = '14px monospace';
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    const { squarifyTreemap, merged, doors, walls } = structure;

    const draw = function (rect) {
      const { start, end } = rect;
      const size = end.sub(start);

      ctx.strokeRect(start.x, start.y, size.x, size.y);
    }

    for (let i = 0; i < squarifyTreemap.length; i++) {
      const rect = squarifyTreemap[i]
      if (merged.includes(i)) {
        ctx.fillStyle = 'gray';
        draw(rect);
      }
    }

    // ctx.fillStyle = "rgba(255, 0, 0, 0.3)";
    ctx.fillStyle = 'pink';
    ctx.strokeStyle = 'pink';
    for (const wall of walls) {
      ctx.strokeRect(wall.start.x, wall.start.y, wall.end.x - wall.start.x, wall.end.y - wall.start.y);
    }

    ctx.fillStyle = "yellow";
    ctx.strokeStyle = "yellow";
    for (let i = 0; i < doors.length; i++) {
      const door = doors[i];
      const size = door.end.sub(door.start);
      ctx.strokeRect(door.start.x, door.start.y, size.x + 3, size.y + 3);
      ctx.fillText(`door #${i}`, door.start.x, door.start.y + 20);
    }
  }
}

export class Rooms extends React.Component {
  render() {
    return <>
      <Make />
    </>;
  }
}
