import React from 'react';
import ReactDOM from 'react-dom/client';

import { GameAnalytics } from 'gameanalytics';

import './index.css';
import './gameface';
import Main from './Main';
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// HMR
module.hot?.accept();

if (process.env.NODE_ENV === "production" && !window.gameface) {
  Sentry.init({
    dsn: "https://0c76e3ef7ac540fa8880c33cf610c6d3@sentry.citrus.sh/42",
    release: process.env.SENTRY_VERSION,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

(() => {
  let enable_ga = false;
  let context = 'browser';
  // 빌드 과정에서, .env.production
  const branch = process.env.BRANCH ?? 'unknown';
  const commit_id = process.env.COMMIT_ID ?? 'unknown';
  if (window.ue) {
    // TODO: 잘 동작하는지 확인하기
    if (window.ue.editor) {
      // unreal editor context
      context = 'ue_editor';
    } else {
      // unreal build context
      context = 'ue';
      enable_ga = true;
    }
  }

  Sentry.setTag("context", context);
  Sentry.setTag("branch", branch);
  Sentry.setTag("commit_id", commit_id);
  Sentry.setTag("enable_ga", enable_ga);

  console.log(`enable_ga=${enable_ga}, context=${context}, branch=${branch}, commit_id=${commit_id}`);

  if (enable_ga) {
    const build = `${branch} ${commit_id}`;
    GameAnalytics.setEnabledInfoLog(true);
    GameAnalytics.configureBuild(build);
    GameAnalytics.initialize('5f8df7649cd44650fcc867c01bcc5d26', '7bc7479c50efa4006f8daef55a756c9d90efd30b');
    console.log(`build=${build}`);
  }

  // google analytics
  gtag("js", new Date());
  gtag("config", "G-729QT7RSEF", {
    custom_map: {
      // TODO: 브랜치 이름
      branch,
      context,
    }
  });
})();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);
