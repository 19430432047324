
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

import dataEncounter from './data/google/downloaded/localization-encounter.json' assert { type: 'json' };
import dataShop from './data/google/downloaded/localization-shop.json' assert { type: 'json' };
import dataUI from './data/google/downloaded/localization-ui.json' assert { type: 'json' };
import dataNarrative from './data/google/downloaded/localization-narrative.json' assert { type: 'json' };
import dataModifier from './data/google/downloaded/localization-modifier.json' assert { type: 'json' };
import characterNames from './data/google/downloaded/char2characterNames.json' assert { type: 'json' };
import fixedOperators from './data/google/downloaded/char2fixedOperators.json' assert { type: 'json' };

import { GAMEFACE } from './gameface.mjs';

const data = [...dataEncounter, ...dataShop, ...dataUI, ...dataNarrative, ...dataModifier];

const localeKeys0 = Object.keys(data[0]).filter(key => key !== 'key' && !key.startsWith('_'));
export const localeKeys = localeKeys0.map(key => key.split('-')[0]);

export const bundledResources = {};
for (const locale of localeKeys0) {
  const key = locale.split('-')[0];

  bundledResources[key] = {
    translation: data.reduce((acc, o) => {
      acc[o.key] = o[locale];
      return acc;
    }, {}),
  };
}

for (const { name, name_kr } of [...characterNames, ...fixedOperators]) {
  bundledResources.en.translation[name] = name;
  bundledResources.ko.translation[name] = name_kr;
}

let obj = i18next.use(ChainedBackend);

if (!GAMEFACE) {
  obj = obj.use(LanguageDetector);
}

obj.init({
  supportedLngs: ['en', 'ko'],
  fallbackLng: 'en',
  // ... your i18next config
  backend: {
    backends: [
      resourcesToBackend(bundledResources)
    ],
    interpolation: {
      skipOnVariables: false,
    }
  }
});

export function L(lkey, ctx) {
  if (typeof lkey === 'function') {
    return lkey();
  }
  return i18next.t(lkey, ctx);
}

export function localeSet(locale) {
  if (!GAMEFACE) {
    window.localStorage.setItem('i18nextLng', locale);
  }
  i18next.changeLanguage(locale);
}

export function localeGet() {
  return i18next.language;
}
