import source from '../downloaded/eventBranch.json' assert { type: 'json' };

export const eventBranches = [];
for (const row of source) {
  let { id, disable_with_condition, show_disable_reason, condition, condition_value, results, results_value, results_weight, desc, mouseover, keyword, } = row;

  eventBranches.push({
    id,
    disable_with_condition: disable_with_condition === 'TRUE',
    show_disable_reason: show_disable_reason === 'TRUE',
    condition: condition?.split(',').map((s) => s.trim()) ?? [],
    condition_value: condition_value?.split(',').map((s) => s.trim()) ?? [],
    results: results?.split(',').map((s) => s.trim()),
    results_value: results_value?.split(',').map((s) => s.trim()),
    results_weight: results_weight?.split(',').map((s) => parseFloat(s.trim()) ? parseFloat(s.trim()) : s.trim()),
    desc,
    mouseover,
    keyword,
  });
}
