import _ from 'lodash';
import dayjs from 'dayjs';
import * as React from 'react';

import './CharacterView.css';

import { firearm_tys } from './presets_firearm.mjs';
import * as data_traits from './data/google/processor/data_traits.mjs';
import { modifiers } from './data/google/processor/data_modifiers.mjs';
import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { names3, iconurl } from './names.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as gcap from './data/google/processor/data_growthcap.mjs';
import * as pscap from './data/google/processor/data_physicalcap.mjs';
import * as agent_physical from './data/google/processor/data_physical.mjs';
import * as data_stats from './data/google/processor/data_stats.mjs';
import * as agent_training from './data/google/processor/data_training.mjs';
import * as exps from './data/google/processor/data_exp.mjs';
import { perks2 } from './data/google/processor/data_perks2.mjs';
import { getFatigueData } from '././data/google/processor/data_fatigue.mjs'
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { Rng } from './rand.mjs';
import { TICK_PER_WEEK } from './tick.mjs';
import { round, roundsign } from './ProgressBar';
import {
  FH1ProgressBar as ProgressBar,
  FH1WellSeg as WellSegment,
  FH1ButtonInline as ButtonInline,
} from './component/figma/fh1';
import {
  createAgent,
  agentGrowth,
  agentChangeApply,
  agentBackgroundTitle,
  agentPowerModifiers,
  lifeRecoverMultiplier,
} from './character.mjs';

import {
  contractDetails,
  contractToString,
  CONTRACT_TY,
  CONTRACT_OPTIONS,
  AGENT_CONTRACT_COST,
} from './contract.mjs';

import { HorizontalInfoView } from './utils.mjs';
import { PortraitView, PortraitWrapper, PORTRAIT_PRESET_ZOOM } from './PortraitsView';
import { STATS2_DESCR, STATS2_TMPL_MAX } from './stats2.mjs';
import { Stats2GaugeDecrease, Stats2GaugeIncrease, Stats2GaugeMax, Stats2LowCap, agentStats2ChangeInfo, agentStats2TrainingInfo, agentStats2GaugeAgeCap, agentStats2GaugeDecayInfo, mergeStats2GaugeDelta, tickToAge } from './character.mjs';
import { agentStats2GaugeTrainInfo, trainPending } from './training.mjs';
import { getTrainEfficiencyBonuses } from './TrainingView';
import { FirearmLabel, GearLabel, ThrowableLabel, UtilityLabel } from './GearView';
import { L } from './localization.mjs';

import { rolesBykey } from './data/google/processor/data_char2roles.mjs';

import { agentModifierText } from './WeeklyMeetingView';

export { PORTRAIT_PRESET_ZOOM };

export const AGENT_TRAINING_CATS = [
  'overall', 'physical', // 'mission',
  ...data_stats.stats_descr.map(({ key }) => key)
];

export const Stats2TooltipDigits = 3;

function createCharacter(rng, opts) {
  const name = rng.choice(names3);

  return createAgent(rng, name, 0, { ...opts, global_modifier: [] });
}

function avg(l) {
  return l.reduce((a, b) => a + b, 0) / l.length;
}

export function agentMissionExp(agent, opts) {
  const { kills, damage_done, win, mission_exp, agents_count } = opts;
  const potData = pot.findByPotential(agent.potential);

  if (win) {
    return (mission_exp / agents_count + kills * 2 + damage_done / 50) * potData.exp_mult;
  } else {
    return (kills * 2 + damage_done / 50) * 0.5 * potData.exp_mult;
  }
}

export function agentChangeCummulate(a, b) {
  a ??= {};
  const excludeKeys = ['agent'];
  const ret = { agent: b['agent'] };

  for (const key of Object.keys(b)) {
    if (excludeKeys.includes(key) || b[key] === null) {
      continue;
    }

    const ty = typeof b[key];
    if (ty === 'number') {
      const val = a[key] ?? 0;
      ret[key] = val + b[key];
    } else if (ty === 'object') {
      const valA = a[key] ?? {};
      const valB = b[key];
      ret[key] = agentChangeCummulate(valA, valB);
    } else {
      console.error(`unknown agent change key type: ${key}, ${typeof key}`);
    }
  }
  return ret;
}

function statClass(value) {
  if (value >= 16) {
    return 'stat-high';
  }
  if (value >= 12) {
    return 'stat-mid';
  }
  return 'stat-low';
}

function statPanelty(agent, stat_key) {
  if (stat_key === 'toughness') {
    return 0;
  }

  let stat_mult = 0.5;
  if (agent.perk_reduce_penalty) {
    stat_mult = 0.35;
  }

  return (agent.life_max - agent.life) / agent.life_max * (1 - agent.stats2.toughness * 0.01) * stat_mult;
}

export function StatValue(agent, stat_key) {
  const base = agent.stats2[stat_key];
  const mod = _.sum(agent.traits.map((t) => t.stats2[stat_key]))

  const panelty = base * statPanelty(agent, stat_key);
  return base + mod - panelty;
}

export function agentEffectiveStat(agent) {
  const stats2 = {};
  for (const key of Object.keys(agent.stats2)) {
    stats2[key] = StatValue(agent, key);
  }
  return stats2;
}

export function agentAvail(agent, ratio) {
  const { life, life_max } = agent;
  return life >= Math.floor(life_max * ratio);
}

export function AgentRecoverDays(agent, life, life_max) {
  life = life ?? agent.life;
  life_max = life_max ?? agent.life_max;
  let recoverMult = lifeRecoverMultiplier(agent);
  if (agent.contract.option.advanced) {
    recoverMult = lifeRecoverMultiplier(agent, 1.5);
  }
  return Math.log(life_max / Math.max(life, 1)) / Math.log(recoverMult);
}

export function AgentStatValue(props) {
  const { agent, stat_key } = props;
  const base = agent.stats2[stat_key];
  let mod = 0;
  let title = [base.toFixed(1)];
  for (const { name, stats2 } of agent.traits) {
    const val = stats2[stat_key];
    if (val === 0) {
      continue;
    }
    mod += val;
    title.push(`${name}: ${val.toFixed(1)}`);
  }

  const panelty = -base * statPanelty(agent, stat_key);
  mod += panelty;
  title.push(`부상: ${panelty.toFixed(1)}`);

  const res = base + mod;
  const cls = statClass(res);

  return <span className={cls} title={title.join('\n')} > {base.toFixed(1)} {mod < 0 ? '' : '+'}{mod.toFixed(1)}</ span>;
}

export function gaugeView(gauge, delta, cap, size) {
  let color = 'white';
  let prefix = '';
  let value = 0;
  // ToDo: 범위 상수화
  if (gauge < Stats2GaugeDecrease) {
    color = 'red';
    prefix = L('loc_ui_string_agent_stat_loss');
    value = gauge;
  } else if (gauge > Stats2GaugeIncrease) {
    color = 'green';
    prefix = L('loc_ui_string_agent_stat_gain');
    value = gauge - Stats2GaugeIncrease;
  } else {
    color = 'white';
    prefix = L('loc_ui_string_agent_stat_stable');
    value = gauge - Stats2GaugeDecrease;
  }

  const deltaColor = delta.sum > 0 ? '#008000' :
    delta.sum < 0 ? '#800000' : 'white';
  const title = [
    delta.excess ?
      L(delta.excess > 0 ? 'loc_dynamic_string_agent_stat_gauge_delta_summary_excess_max' : 'loc_dynamic_string_agent_stat_gauge_delta_summary_excess_min', { value: roundsign(delta.sum, Stats2TooltipDigits), originalValue: roundsign(delta.sum + delta.excess, Stats2TooltipDigits), excessValue: roundsign(-1 * delta.excess, Stats2TooltipDigits) }) :
      L('loc_dynamic_string_agent_stat_delta_summary', { value: roundsign(delta.sum, Stats2TooltipDigits) }),
  ];
  if (delta.decay) {
    title.push(`기본 일일 변동치: ${roundsign(delta.decay, Stats2TooltipDigits)}`);
  }
  if (delta.decay_ageing) {
    title.push(`노화: ${roundsign(delta.decay_ageing, Stats2TooltipDigits)}`);
  }
  if (delta.training && delta.training.data.sum) {
    const efficiencyBonuses = getTrainEfficiencyBonuses(delta.training.trainGaugeInfo, delta.training.data, false);
    title.push(...efficiencyBonuses);
  }
  if (title.length !== 1) {
    title.splice(1, 0, `-----`,);
  }

  const text = (
    <>
      <span>{prefix} <span title={Stats2GaugeMax !== cap ? L('노화로 인한 최대 게이지: {{value}}', { value: round(cap, Stats2TooltipDigits) }) : null}>{round(value, 1)}</span><span style={{ color: deltaColor }} title={title.join('\n')}>({roundsign(delta.sum ?? 0, 2)})</span></span>
    </>
  )

  return <div style={{ display: "flex" }}>
    <div style={{ flex: "0 0 80px", marginRight: "10px" }}>
      <ProgressBar
        cur={gauge}
        growth={delta.sum}
        max={Stats2GaugeMax}
        cap={cap}
        size={size}
        bgcolor={color}
        lines={[{ value: 100 }, { value: 200 }]}
        text={''} />
    </div>
    {text}
  </div>

}

export function statView(stat2, deltaInfo, base, max, cap, size) {
  const delta = deltaInfo.delta.sum;

  const deltaClass = delta > 0 ? 'delta-positive' :
    delta < 0 ? 'delta-negative' : 'delta-zero';

  const tooltips = [
    L('loc_dynamic_string_agent_stat_delta_summary', { value: roundsign(delta, Stats2TooltipDigits) }),
  ];

  if (deltaInfo.delta.potential) {
    tooltips.push(L('loc_dynamic_string_agent_stat_delta_potential', { percent: round(deltaInfo.mult.potential * 100, 1), value: roundsign(deltaInfo.delta.potential, Stats2TooltipDigits) }));
  }
  if (deltaInfo.delta.growthrate) {
    tooltips.push(L('loc_dynamic_string_agent_stat_delta_growthrate', { percent: round(deltaInfo.mult.growthrate * 100, 1), value: roundsign(deltaInfo.delta.growthrate, Stats2TooltipDigits) }));
  }
  if (deltaInfo.delta.maxcap) {
    tooltips.push(L('loc_dynamic_string_agent_stat_delta_maxcap', { value: roundsign(deltaInfo.delta.maxcap, Stats2TooltipDigits) }));
  }
  if (deltaInfo.delta.mincap) {
    tooltips.push(L('loc_dynamic_string_agent_stat_delta_mincap', { value: roundsign(deltaInfo.delta.mincap, Stats2TooltipDigits) }));
  }

  if (tooltips.length !== 1) {
    tooltips.splice(1, 0, `-----`,);
  }

  const text = (
    <>
      <span>{round(stat2, 1)}<span className={deltaClass} title={tooltips.join('\n')}>({roundsign(delta ?? 0, 2)})</span>/{round(cap, 1)}</span>
    </>
  );

  return <div className='agent-stat-value'>
    <div className='agent-stat-progress'>
      <ProgressBar
        cur={stat2}
        growth={delta}
        max={max}
        size={size}
        cap={cap}
        lines={[{ value: base }]}
        text={''}
        classname='stat'
      />
    </div>
    {text}
  </div>

}
// 훈련/상세창에서 적당히 보여주기용
export function AgentStatGuages(props) {
  const { agent, slots, size = 200, predict = false, fakeSlot, tick, global_effects } = props;

  const [showGaugeHelp, setGaugeHelp] = React.useState(false);

  const gcapData = gcap.find(agent.growthcap);
  let power_cap = gcapData.power_cap;

  const decayInfo = agentStats2GaugeDecayInfo(agent, tickToAge(tick, agent.born_at));
  const slot = fakeSlot ?? slots?.find(s => s.training?.agentIdx === agent.idx);
  const training = slot?.training ?? { option: [] };
  const effects = slot ? [...slot.effects] : [];
  const trainGaugeInfo = agentStats2GaugeTrainInfo((!predict && (trainPending(agent)) ? null : training), slot?.level ?? 1, slot?.availability ?? 100, effects);
  let stats2_gauge_delta = mergeStats2GaugeDelta(agent, tickToAge(tick, agent.born_at), [decayInfo.default, decayInfo.ageing, trainGaugeInfo.summary]);
  if (props.stats2_gauge_delta) {
    stats2_gauge_delta = props.stats2_gauge_delta;
  }
  const nextStats2_gauge = Object.fromEntries(Object.keys(STATS2_DESCR).map((key) => {
    return [key, stats2_gauge_delta.delta[key] + agent.stats2_gauge[key]];
  }));
  let stats2_delta = agentStats2TrainingInfo(agent, training);
  const stats2_length = Object.keys(STATS2_DESCR).length;

  if (props.stats2_delta) {
    stats2_delta = props.stats2_delta;
  }

  const stats2_gauge_cap = agentStats2GaugeAgeCap(tickToAge(tick, agent.born_at));

  const stamina_recover_mult = global_effects?.stamina_recover_mult ?? 1;
  const stamina_regen_per_day = agent.stamina_regen_per_day * stamina_recover_mult;

  return <>
    {showGaugeHelp ? <div className='agent-stat-gauges-help'>
      <div className='agent-stat-gauges-help-inner'>
        <button className='agent-stat-gauges-help-closebutton' onClick={() => setGaugeHelp(false)}>x</button>
        <div className="agent-stat-gauges-help-title">{L('loc_ui_title_popup_agent_stat_sharpness')}</div>
        <div className="agent-stat-gauges-help-body">
          <img alt="" className="agent-stat-gauges-help-body-image" src='/img/training-tutorial.png' />
          <div className='agent-stat-gauges-help-body-text'>
            {L('loc_ui_longtext_agent_stat_sharpness')}
          </div>
        </div>
      </div></div > : <></>
    }
    <table className="agent-stat-gauges" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th style={{ width: "80px" }}>{L('loc_ui_string_agent_stat_type')}</th>
          <td style={{ width: "200px" }}>{L('loc_ui_string_agent_stat_value')}</td>
          {/* <td style={{ width: "200px" }}>{L('loc_ui_string_agent_sharpness')}{predict ? L('loc_ui_string_agent_sharpness_prediction') : null} <button onClick={() => setGaugeHelp(!showGaugeHelp)}>?</button></td> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{L('loc_ui_string_agent_stat_overall')}</th>
          <td>{statView(_.sum(Object.values(agent.stats2).filter(val => typeof val === 'number')) / stats2_length, stats2_delta.overall, _.sum(Object.values(agent.stats2_base)) * Stats2LowCap / stats2_length, 20, power_cap, size)}</td>
          {/* <td>{gaugeView(_.sum(Object.values(agent.stats2_gauge)) / stats2_length, { sum: _.sum(Object.values(stats2_gauge_delta.delta)) / stats2_length }, stats2_gauge_cap)}</td> */}
        </tr>
        {Object.entries(STATS2_DESCR).map(([key], i) => {
          const delta = {
            decay: decayInfo.default[key],
            decay_ageing: decayInfo.ageing[key],
            training: { trainGaugeInfo, data: trainGaugeInfo.detail[key] },
            sum: stats2_gauge_delta.delta[key],
            excess: stats2_gauge_delta.excess[key],
          };
          const { descr, descr_effect } = data_stats.stats_descr.find((d) => d.key === key);
          return <tr key={key}>
            <th title={`${L('loc_ui_longtext_agent_stat_' + key)}\n\n${L('loc_ui_longtext_agent_stat_' + key + "_effect")}`}>{L('loc_ui_string_agent_stat_' + key)}</th>
            <td>{statView(agent.stats2[key], stats2_delta.stats2[key], agent.stats2_base[key] * Stats2LowCap, STATS2_TMPL_MAX[key], agent.stats2_cap[key], size)}</td>
          </tr>;
        })}
        <tr>
          <th title={L('loc_ui_longtext_agent_stamina')}>{L('loc_ui_string_agent_stamina_short')}</th>
          <td title={L('loc_dynamic_string_agent_stamina', { value: (stamina_regen_per_day * 28).toFixed(1) })}>{agent.stamina_max}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export function AgentPerkTree(props) {
  const { agent, onAgentAcquirePerk, onAgentGetPerkPoint } = props;
  const perk_groups = [
    'common',
    agent.background_perk_group.perk_group,
    ...Array.from(new Set(agent.traits.map((t) => t.trait_perk_group))),
  ];

  const perkList0 = {};
  perks2.forEach(({ key }) => {
    if (agent.perks.list.find((p) => p === key)) {
      perkList0[key] = true;
    } else {
      perkList0[key] = false;
    }
  });

  const firearm_ty_keys = Object.keys(firearm_tys);

  const renderPerk = (perk) => {
    const unlocked = perk.groups.find((group) => perk_groups.includes(group));
    if (!unlocked) {
      return null;
    }

    const learned = perkList0[perk.key];
    let avail = false;
    if (!learned && agent.perks.point > 0) {
      if (perk.level <= (agent.perks.point_total - agent.perks.point) + 1) {
        avail = true;
      }
      if (perk.dep && !perkList0[perk.dep]) {
        avail = false;
      }
      if (firearm_ty_keys.includes(perk.role) && !agent.vocation.includes(perk.role)) {
        avail = false;
      }
    }

    let cls = 'stat-perk-off';
    if (learned) {
      cls = 'stat-perk-on';
    } else if (avail) {
      cls = 'stat-perk-avail';
    }

    return <span key={perk.key} className={cls} onClick={() => {
      if (!avail) {
        return;
      }
      if (onAgentAcquirePerk) {
        onAgentAcquirePerk(agent, perk);
      }
    }} title={L(perk.descr)}>{L(perk.name)}</span>;
  };

  function renderPerks(perks) {
    let perksByLevel = [];
    for (const perk of perks) {
      perksByLevel[perk.level] = perksByLevel[perk.level] ?? [];
      perksByLevel[perk.level].push(perk);
    }

    const rows = [];
    for (let i = 0; i < perksByLevel.length; i++) {
      const bylevel = perksByLevel[i];
      if (!bylevel) {
        continue;
      }

      let cols = [];
      for (const perk of bylevel) {
        let cell = (renderPerk(perk));
        if (cell) {
          cols.push(<div key={perk.key}>{cell}</div>);
        }
      }
      rows.push(<div className='agent-detail-perk-lv'>
        <div>lv. {i}</div>
        {cols}
      </div>);
    }

    return <div>
      {rows}
    </div>;
  }

  function renderPerkGroupDescription(perk_groups) {
    let rows = [];
    for (let i = 1; i < perk_groups.length; i++) {
      rows.push(<li>
        {agent.background_perk_group.perk_group === perk_groups[i] ? agent.background_perk_group.description : perk_groups[i]}
      </li>)
    }
    return <div>
      이 용병은
      <ul>{rows}</ul>
    </div>
  }
  let btn = null;
  if (onAgentGetPerkPoint) {
    btn = <button onClick={() => onAgentGetPerkPoint(agent)}>+</button>;
  }

  return <div className='agent-detail-perk'>
    <div className='agent-detail-perk-header'>
      <span>
        points used={agent.perks.point_total - agent.perks.point} avail={agent.perks.point}
        {btn} | groups={perk_groups.join(',')}
      </span>
    </div>
    {renderPerkGroupDescription(perk_groups)}
    {renderPerks(perks2)}
  </div >;
}

export function AgentStatView(props) {
  const { agent } = props;
  const { stats2 } = agent;

  const overall = avg(Object.values(stats2));

  const setAgentStamina = (diff) => {
    agent.stamina_max += diff;
    agent.stamina = Math.min(agent.stamina + diff, agent.stamina_max);
  }

  const setAgentStaminaRegen = (diff) => {
    agent.stamina_regen_per_day += (diff / 28);
  }

  return <div className="agent-detail-stat">
    <div><p>overall={overall.toFixed(1)}</p></div>
    {Object.entries(stats2).map(([key, val]) => {
      const { descr, descr_effect } = data_stats.stats_descr.find((d) => d.key === key);
      // const val = StatValue(agent, key);
      // <td><AgentStatValue agent={agent} stat_key={key} /></td>

      const stylePreserve = { whiteSpace: "pre-wrap" };

      return <div key={key}>
        <p>{key}={val.toFixed(1)}</p>
        <p style={stylePreserve}>{L(descr)}</p>
        <p style={stylePreserve}>{L(descr_effect)}</p>
      </div>;
    })}
    <div>
      <p>
        stamina_max={agent.stamina_max}
        {/* <button onClick={() => setAgentStamina(1)}>+1</button>
        <button onClick={() => setAgentStamina(0.1)}>+0.1</button>
        <button onClick={() => setAgentStamina(-0.1)}>-0.1</button>
        <button onClick={() => setAgentStamina(-1)}>-1</button> */}
      </p>
    </div>
    {/* <div><p>stamina_regen_per_day={agent.stamina_regen_per_day}</p></div> */}
    <div>
      <p>stamina_regen_per_month={agent.stamina_regen_per_day * 28}
        {/* <button onClick={() => setAgentStaminaRegen(1)}>+1</button>
        <button onClick={() => setAgentStaminaRegen(0.1)}>+0.1</button>
        <button onClick={() => setAgentStaminaRegen(-0.1)}>-0.1</button>
        <button onClick={() => setAgentStaminaRegen(-1)}>-1</button> */}
      </p>
    </div>
  </div>;
}

// ToDo: 지우기
export function agentTrainInfo(agent, cat, thres, life_ratio) {
  let duration = TICK_PER_WEEK * 4;
  if (!['overall', 'physical'].includes(cat)) {
    duration = TICK_PER_WEEK;
  }
  return {
    duration,
    ...agentTrainAvail(agent, cat, thres, life_ratio),
  };
}

function agentTrainAvail(agent, cat, thres, life_ratio) {
  const gcapData = gcap.find(agent.growthcap);
  const power_cap = gcapData.power_cap;

  if (agent.life / agent.life_max < life_ratio) {
    return {
      avail: false,
      reason: 'insufficient life',
    };
  }

  switch (cat) {
    case 'overall': {
      const reason = agent.power < thres ?
        (agent.power < power_cap ? 'ok' : 'power-cap') : 'power-thres';
      return {
        cur: agent.power,
        cap: thres,
        avail: agent.power < thres && agent.power < power_cap,
        reason,
      };
    }
    case 'physical':
      return {
        cur: agent.physical,
        cap: thres,
        avail: agent.physical < thres,
        reason: 'physical-thres',
      };
    case 'mission':
      return {
        cur: 0,
        cap: 0,
        avail: true,
        reason: 'ok',
      };
    default: {
      const reason = agent.stats2[cat] < thres ?
        (
          agent.power < power_cap ?
            'ok' :
            `power-cap (${agent.power.toFixed(2)} < ${power_cap})?`
        ) :
        `${cat}-thres (${agent.stats2[cat].toFixed(2)} < ${thres})?`;
      return {
        cur: agent.stats2[cat],
        cap: thres,
        avail: agent.stats2[cat] < thres && agent.power < power_cap,
        reason,
      };
    }
  }
}

function AgentTrainView(props) {
  const { agent, onAgentTrain } = props;

  const [curAreaNum, setAreaNum] = React.useState(0);

  const data = agent_training.list.find(({ areaNum }) => areaNum === curAreaNum);

  return <div className="seplist">
    <span>
      training:
      region=<select value={curAreaNum} onChange={(e) => setAreaNum(0 | e.target.value)}>
        {agent_training.list.map(({ areaNum }) => {
          return <option key={areaNum} value={areaNum}>{areaNum}</option>;
        })}
      </select>
    </span>

    <span>
      thres={data.train_power_cap}
    </span>

    <span>
      {AGENT_TRAINING_CATS.map((src) => {
        const { avail, reason } = agentTrainAvail(agent, src, data.train_power_cap, data.life_ratio);
        return <button key={src} disabled={!avail} title={reason}
          onClick={() => onAgentTrain(agent, src)}>{src}</button>;
      })}
    </span>
  </div>;
}

function Sep() {
  return <span className="sep" />;
}

export function NameView(props) {
  const { agent, onAgentDetail, additional } = props;

  // <button className='star'>⭐️</button>
  return <span className='agent-profile-name' onClick={(ev) => {
    if (onAgentDetail) {
      ev.preventDefault();
      ev.stopPropagation();
      onAgentDetail(agent);
    }
  }}>{agent.name} {additional ? <font className="agent-unavaiable">({additional})</font> : null} </span>;
}

export function AgentLife(props) {
  const { life, life_max } = props.agent;
  let cls;
  if (life === life_max) {
    cls = 'agent-life-max';
  } else if (life > 33) {
    cls = 'agent-life';
  } else {
    cls = 'agent-life-low';
  }
  return <><span className={cls}>♥{life.toFixed(0)}</span>/<span className='agent-max-life'>{life_max}</span></>
}

export function AgentStats(props) {
  const { agent } = props;

  let items = [];
  for (const key of Object.keys(agent.stats2)) {
    if (items.length !== 0) {
      items.push("/");
    }
    items.push(<span key={key} title={key}><AgentStatValue agent={agent} stat_key={key} /></span>);
  }

  return <>{items}</>;
}

export function AgentNature(props) {
  const { agent } = props;
  const { potential, growthcap, physicalcap } = agent;

  const potentialData = pot.findByPotential(potential);

  const gcapData = gcap.find(growthcap);
  const gcapDescr = `${L('loc_ui_string_agent_growth_cap')}: ${gcapData.power_cap}`;

  return <>
    {AgentPotential({ agent })}
    /
    {AgentGrowthCap({ agent })}
    /
    {AgentPhysicalCap({ agent })}
  </>;
}

export function AgentPotential(props) {
  const { agent } = props;
  const { potential } = agent;

  const potentialData = pot.findByPotential(potential);

  return <span className="potential" title={`${L('loc_ui_string_agent_potential')}: ${potential.toFixed(2)}\n${pot.descrPotential(potentialData)}`}>{potentialData.label}</span>;
}

export function AgentGrowthCap(props) {
  const { agent } = props;
  const { growthcap } = agent;

  const gcapData = gcap.find(growthcap);
  const gcapDescr = `${L('loc_ui_string_agent_growth_cap')}: ${gcapData.power_cap}`;

  return <span className="growthcap" title={gcapDescr}>{growthcap}</span>;
}

export function AgentPhysicalCap(props) {
  const { agent } = props;
  const { physicalcap } = agent;

  return <span className="physicalcap" title={`${pscap.descr(pscap.find(physicalcap))}`}>{physicalcap}</span>;
}

export function AgentItem(props) {
  const { agent, substate, turn, selected, gears, avail, reason } = props;
  const { onAgentDetail, onAgentToggle, onSpawnChange, onAgentSelect } = props;
  let state = props.state;

  const { contract, modifier, power } = agent;

  let buttons = [];

  if (onSpawnChange) {
    buttons.push(<button key='spawn' onClick={() => onSpawnChange(agent)}>tier #{agent.spawnarea}</button>)
  }

  const { option, agenda, ty } = contract;
  const cost = AGENT_CONTRACT_COST(agent);
  const contract_title = `Market Value=$${cost}

${contractToString(contract, turn)}
`;

  const modifier0 = [];
  if (agenda) {
    modifier0.push(<span key={agenda} title={L(modifiers[agenda].desc)}>{L(modifiers[agenda].name)}, </span>);
  }

  for (const { key, name, desc } of CONTRACT_OPTIONS) {
    if (option[key]) {
      modifier0.push(<span key={key} title={L(desc)}>{L(name)}</span>)
    }
  }

  for (const m of modifier) {
    // const { desc, name } = modifiers[m.key];
    const { visible, desc, name } = data_agentModifiers.find((d) => d.key === m.key);
    if (visible) {
      modifier0.push(<span key={m.key} title={L(desc)} >{agentModifierText(m, turn)}</span>);
    }
  }

  if (buttons.length > 0) {
    buttons = <><Sep />{buttons}</>;
  }

  let overlaycls = 'box-overlay';
  let onClick = null;
  if (onAgentSelect) {
    onClick = () => onAgentSelect(agent);
  } else if (state === null && onAgentToggle) {
    onClick = () => onAgentToggle(agent);
  }
  if (state === null && selected) {
    state = '선택됨';
    overlaycls += ' box-overlay-pos';
  }

  const { amount, label } = agentPowerModifiers(agent, turn);
  const expData = exps.exps.find((e) => e.level === agent.level.cur);

  // const fatigueData = getFatigueData(agent.stamina);
  const conditionData = getConditionData(agent.condition)
  const moodData = getMoodData(agent.mood);

  const info0 = [
    {
      key: <span>{L('loc_dynamic_string_agent_level', { level: agent.level.cur })}</span>,
      value: <span>
        (<span>{agent.level.exp.toFixed(1)}/{expData.exp}</span>)
      </span>,
    },
    {
      key: <span title={L('loc_ui_longtext_agent_overall')}>{L('loc_ui_string_agent_overall')}</span>,
      value: <div className="row">
        <span>{power.toFixed(1)}</span>
        <span className="agent-power-extra" title={label}>{(amount > 0 ? '+' : '') + amount.toFixed(1)}</span>
      </div>
    },
    {
      key: <span title={L('loc_ui_longtext_agent_life')}>{L('loc_ui_string_agent_life')}</span>,
      value: <AgentLife agent={agent} />
    },
    // {
    //   key: <span title={L('loc_ui_longtext_agent_stamina')}>{L('loc_ui_string_agent_stamina')}</span>,
    //   value: <span className={`flex-stamina-label-${fatigueData.idx}`} title={L(`loc_dynamic_string_agent_stamina`, { value: (agent.stamina_regen_per_day * 28).toFixed(1) })}>{`${L(fatigueData.name)}(${agent.stamina.toFixed(1)})`}</span>,
    // },
    {
      key: <span>스태미나</span>,
      value: <span className={`flex-stamina-label-${conditionData.idx}`}>{`${L(conditionData.name)}(${agent.condition.toFixed(1)})`}</span>
    },
    {
      key: <span>기분</span>,
      value: <span className={`flex-stamina-label-${moodData.idx}`}>{`${L(moodData.name)}(${agent.mood.toFixed(1)})`}</span>
    },
  ];

  const extras = [];
  if (modifier0.length > 0) {
    extras.push(<span key="modifiers">[{modifier0.map((mod, i) => [
      i > 0 && ", ",
      mod
    ])}]</span>);
  }
  if (agent.perks.point > 0) {
    extras.push(<span key="perkpoint" className="contract-agent-perkpoint-indicator">{L('loc_dynamic_string_agent_perk_points_available', { count: agent.perks.point })}</span>);
  }

  return <WellSegment
    className='box-overlayhost'
    selected={selected}
    title={
      <div className="agent-info-1">
        <NameView agent={agent} onAgentDetail={onAgentDetail} additional={avail ? null : reason} />
        {substate ? <span className="agent-substate">({substate})</span> : null}
        <>{extras}</>
      </div>
    }
    onClick={(ev) => {
      if (onClick) {
        ev.stopPropagation();
        onClick();
      }
    }}
  >
    <div className="fh1-content-inner agent-profile-top">
      <PortraitWrapper onClick={(ev) => {
        if (onAgentDetail) {
          ev.preventDefault();
          ev.stopPropagation();
          onAgentDetail(agent);
        }
      }} agent={agent} className="agent-thumbnail" />
      <div className="agent-info">
        <HorizontalInfoView pairs={info0} />
        <div className="agent-info-3">
          {gears}
        </div>
      </div>
    </div>
    {state ? <div className={overlaycls}><h1>{state}</h1></div> : null}
  </WellSegment>;
}

export class CharacterView extends React.Component {
  constructor(props) {
    super(props);

    const areaNum = 3;
    const count = 4;
    const rng = new Rng();
    this.state = {
      rng,
      areaNum,
      count,
      characters: new Array(count).fill(0).map(() => createCharacter(rng, { areaNum })),
    };
  }

  onRoll() {
    const { rng, areaNum, count } = this.state;
    const characters = new Array(count).fill(0).map(() => createCharacter(rng, { areaNum }));
    this.setState({ characters });
  }

  onChangeArea(e) {
    const areaNum = e.target.value | 0;
    this.setState({ areaNum }, () => this.onRoll());
  }

  onChangeCount(e) {
    const count = e.target.value | 0;
    this.setState({ count }, () => this.onRoll());
  }

  onAgentAcquirePerk(agent, perk) {
    const { characters } = this.state;

    const { perks } = agent;
    if (perks.point === 0) {
      return;
    }
    perks.point -= 1;
    agent.perks.list.push(perk.key);

    this.setState({ characters });
  }

  onAgentGetPerkPoint(agent) {
    const { characters } = this.state;
    const { perks } = agent;
    perks.point += 1;
    perks.point_total += 1;
    this.setState({ characters });
  }

  onAgentTrain(agent, src) {
    const { characters, rng } = this.state;

    const { power, stats2, physical, vocation } = agentGrowth(rng, agent, src, 1.0, 20);
    agent.power += power;
    for (const [key, value] of Object.entries(stats2)) {
      agent.stats2[key] += value;
    }
    agent.physical += physical;
    if (vocation) {
      agent.vocation.push(vocation);
    }
    agent.life_max = agent_physical.lifeMax(agent.physical);

    this.setState({ characters });
  }

  onAgentAmbitionComplete(agent) {
    const { characters } = this.state;

    agent.ambition_completed = true;
    agent.traits = [
      ...agent.traits.filter((t) => !agent.background.ambition_traits_lose.includes(t.key)),
      ...agent.background.ambition_traits_gain.map(data_traits.find),
    ];
    agent.modifier = [
      ...agent.modifier,
      ...agent.background.ambition_modifiers,
    ];

    this.setState({ characters });
  }

  onAgentLifeChange(agent, life) {
    const { characters } = this.state;
    agent.life = Math.min(agent.life_max, Math.max(0, agent.life + life));
    this.setState({ characters });
  }

  renderCharacter(agent, i) {
    const turn = 0;

    const { name } = agent;
    const { level, power, life, life_max, physical, potential, growthcap, physicalcap, vocation } = agent;
    const { nationality, language, background, traits, modifier, ambition, ambition_completed, contract } = agent;

    const potentialData = pot.findByPotential(potential);
    const gcapData = gcap.find(growthcap);
    const pscapData = pscap.find(agent.physicalcap);

    const recoverMult = lifeRecoverMultiplier(agent);
    const recoverDays = AgentRecoverDays(agent);

    const avail = (life / life_max) >= 0.5;

    const expData = exps.exps.find((e) => e.level === level.cur);

    const expAmount = 100;

    return <div className="box" key={i}>
      <img width="64" alt={name} src={iconurl(name)} />
      <AgentTrainView agent={agent} onAgentTrain={this.onAgentTrain.bind(this)} />

      <div className="seplist">
        <span>{name}</span>
        <span>level: {level.cur} ({level.exp}/{expData.exp})
          <button onClick={() => {
            agentChangeApply(agent, { exp: expAmount }, turn);
            this.setState({ characters: this.state.characters });
          }}>+{expAmount}</button>
        </span>

        <span>{L(nationality.nation)}</span>
        <span>{L(language)}</span>
        <span title={agentBackgroundTitle(background)}>{L(background.name)}</span>
        <span title={ambition.condition}>{L(ambition.name)}
          ({ambition_completed
            ? '달성'
            : <button onClick={() => this.onAgentAmbitionComplete(agent)}>달성하기</button>})</span>
      </div>
      <div className="seplist">
        <span className="seplist2">
          {traits.map((t) => <span key={t.key} title={t.descr}>{L(t.name)}</span>)}
        </span>
        <span className="seplist2">
          {modifier.map(({ key }) => {
            // const data = modifiers[key];
            const data = data_agentModifiers.find((d) => d.key === key);
            return data.visible ? <span key={data.key} title={data.desc}>{data.name}</span> : null;
          })}
        </span>
      </div>
      <div className="seplist">
        <span className="seplist2">
          {vocation.map((v) => {
            return <span key={v}>{v}</span>;
          })}
        </span>
        <span>overall={power.toFixed(1)}/{gcapData.power_cap}</span>
        <span>life={life}/{life_max} ({Math.ceil(recoverDays)}일후 회복, {recoverMult.toFixed(3)})</span>
        {avail ? <span className="agent-avail">전투가능</span> : <span className="agent-unavail">전투불능</span>}
        <span>physical={physical.toFixed(1)}/{pscapData.physical_cap}</span>
        <span className="seplist2">
          <span>{potentialData.label}</span>
          <span>{growthcap}</span>
          <span>{physicalcap}</span>
        </span>
        <span>
          <button onClick={() => this.onAgentLifeChange(agent, 1)}>+</button>
          <button onClick={() => this.onAgentLifeChange(agent, -1)}>-</button>
        </span>
      </div>

      <div className="seplist">
        {contractDetails(contract, 0).map(([key, value]) => {
          return <span className="seplist2" key={key}>{key}={value}</span>;
        })}
      </div>

      <AgentStatView agent={agent} />

      <AgentPerkTree agent={agent}
        onAgentAcquirePerk={this.onAgentAcquirePerk.bind(this)}
        onAgentGetPerkPoint={this.onAgentGetPerkPoint.bind(this)}
      />
    </div>;
  }

  render() {
    const { characters, areaNum, count } = this.state;

    return <div className="root">
      <div className="seplist">
        area=<select onChange={this.onChangeArea.bind(this)} defaultValue={areaNum}>
          {[0, 1, 2, 3].map((areaNum) => {
            return <option key={areaNum} value={areaNum}>{areaNum}</option>
          })}
        </select>
        count=<select onChange={this.onChangeCount.bind(this)} defaultValue={count}>
          {new Array(10).fill(0).map((_, i) => {
            i += 1;
            return <option key={i} value={i}>{i}</option>
          })}
        </select>
        <button onClick={this.onRoll.bind(this)}>Roll</button>
      </div>
      <div>
        {characters.map(this.renderCharacter.bind(this))}
      </div>
    </div>;
  }
}

export function AgentEquipButton(props) {
  const { readonly, agent, onAgentEquipAvail, onAgentEquipPopup, ty, index } = props;
  const { cur, avail, reason } = onAgentEquipAvail(agent, ty, index);

  return <ButtonInline label={cur} disabled={!avail} onClick={() => onAgentEquipPopup(agent, ty, index)} className='equip' />;
}


export function AgentItemGears(props) {
  const { readonly, agent, onAgentEquipAvail, onAgentEquipPopup, onAgentDisarm } = props;
  const { firearmLabel, gearLabel, throwableLabel, utilityLabel } = props;
  const { firearm, equipment, throwables, utilities } = agent;

  let firearmbutton = <FirearmLabel firearm={firearm} />;
  let equipmentbutton = <GearLabel equipment={equipment} />;
  let throwablebutton = <ThrowableLabel throwable={throwables[0]} />;
  let throwablebutton2 = <ThrowableLabel throwable={throwables[1]} />;
  let utilitybutton = <UtilityLabel utility={utilities[0]} />;

  function btn(ty, index) {
    return <AgentEquipButton readonly={readonly} agent={agent} onAgentEquipAvail={onAgentEquipAvail} onAgentEquipPopup={onAgentEquipPopup} ty={ty} index={index} />;
  }

  let disarmButton = null;
  if (onAgentDisarm) {
    disarmButton = <ButtonInline label={'LOC_TODO_무장 해제'} disabled={readonly} onClick={() => onAgentDisarm(agent)} className='equip' />;
  }

  if (!readonly) {
    firearmbutton = btn('firearm', 0);
    equipmentbutton = btn('equipment', 0);
    throwablebutton = btn('throwable', 0);
    throwablebutton2 = btn('throwable', 1);
    utilitybutton = btn('utility', 0);
  }

  if (firearmLabel) {
    return <div>
      <div className='agent-item-body'>{firearmbutton}</div>
    </div>
  }

  if (gearLabel) {
    return <div>
      <div className='agent-item-body'>{equipmentbutton}</div>
    </div>
  }

  if (throwableLabel) {
    return <div>
      <div className='row'>
        <div className='agent-item-body'>{throwablebutton}</div>
      </div>
    </div>
  }

  // TODO: 장비 규칙
  const gears = [
    { key: 'main', label: L('loc_ui_string_common_firearm'), view: firearmbutton },
    { key: 'equip', label: L('loc_ui_string_common_equipment'), view: equipmentbutton },
    // { key: 'throw0', label: L('loc_ui_string_common_throwable'), view: throwablebutton },
    // { key: 'util', label: '유틸', view: utilitybutton },
    // { key: 'disarm', label: '', view: disarmButton },
  ];

  if (['pointman'].includes(agent.role)) {
    gears.push({ key: 'throw0', label: L('loc_ui_string_common_throwable'), view: throwablebutton });
  }

  /*
  let filter = [];
  if (agent.role === 'assault') {
    filter = filter.concat(['equip', 'throw0', 'throw1', 'util']);
  } else if (agent.role === 'tank') {
    filter = filter.concat(['throw0', 'throw1', 'util']);
  } else if (agent.role === 'support') {
    filter = filter.concat(['equip', 'util']);
  }
  gears = gears.filter(({ key }) => !filter.includes(key));
  */

  return <>
    <div className='agent-profile-item'>
      {gears.map(({ key, label, view }) => {
        if (view === null) {
          return null;
        }
        return <div className='agent-profile-item-row' key={key}>
          <div className='agent-item-title'>{label}</div>
          <div className='agent-item-body'>{view}</div>
        </div>;
      })}
    </div>
  </>;
}


/*
<div>
<div>{JSON.stringify(nationality)}</div>
<div>{JSON.stringify(background)}</div>
<div>{JSON.stringify(traits)}</div>
<div>{JSON.stringify(ambition)}</div>
</div>
*/
