import React from 'react';
import './SimOverlay.css';
import {
  FigmaIngameAgentList,
  FigmaIngameProgressBoard,
  FigmaIngameCharacterHud,
  FigmaIngameCharacterHudLine,
  FigmaIngameAimLine,
  FigmaIngameBubble,
  FigmaIngameActionProgress,
  FigmaIngameDialog,
  FigmaIngameDamageIndication,
  FigmaIngameSpotMarker,
  FigmaIngameAlertMarker,
  FigmaIngameDeathMarker,
  FigmaIngameHeadshotMarker,
  FigmaIngameDirectionMarker,
} from './FigmaIngameView.js';
import { resolveName, DialogView } from './DialogView.js';

import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';
import { parseQuery } from './utils.mjs';
import {
  renderSymbolCircle,
  renderX,
} from './simrender.mjs';

function div(a, b) {
  if (b === 0) {
    return 0;
  }
  return a / b;
}

const OPTS = {
  force_repulse: {
    radius: 100,
    min_dist: 1,

    strength_anchor_self: 2,
    strength_anchor_other: 0.5,
    strength_pos_other: 10,

    use_bias: false,
    fixed: false,
    follow_dir: true,
  },

  fixed_bias_move: {
    radius: 0,
    min_dist: 1,

    strength_anchor_self: 0.5,
    strength_anchor_other: 1,
    strength_pos_other: 1,

    use_bias: true,
    fixed: false,
    follow_dir: true,
  },

  fixed_bias: {
    radius: 0,
    min_dist: 1,

    strength_anchor_self: 0.5,
    strength_anchor_other: 1,
    strength_pos_other: 1,

    use_bias: true,
    fixed: true,
    follow_dir: true,
  },

  fixed: {
    radius: 0,
    min_dist: 1,

    strength_anchor_self: 0.5,
    strength_anchor_other: 1,
    strength_pos_other: 1,

    use_bias: false,
    fixed: true,
    follow_dir: false,
  },

};

const OPTS_DEFAULT = OPTS.fixed;

function layout(views, opts) {
  const {
    radius,
    min_dist,
    strength_anchor_self,
    strength_anchor_other,
    strength_pos_other,
    use_bias,
    fixed,
  } = opts ?? OPTS_DEFAULT;

  for (const v of views) {
    const { pos, anchor } = v;
    const bias = use_bias ? v.bias : v2.zero();

    let force_center = v2.zero();

    const diff = anchor.add(bias).sub(pos);
    const dist = diff.len();
    if (dist > min_dist) {
      force_center = diff.norm().mul(dist - radius).mul(strength_anchor_self);
    }

    for (const v_other of views) {
      if (v === v_other) {
        continue;
      }
      for (const [pos_other, strength] of [
        [v_other.anchor, strength_anchor_other],
        [v_other.pos, strength_pos_other],
      ]) {
        const dist = pos.sub(pos_other).len();
        const force = pos.sub(pos_other).norm().mul(1 / Math.pow(Math.max(0.1, dist / radius), 2));
        force_center = force_center.add(force.mul(strength));
      }
    }
    v.speed = v.speed.add(force_center).mul(0.8);
  }

  for (const v of views) {
    const { speed, anchor } = v;
    if (fixed) {
      const bias = use_bias ? v.bias : v2.zero();
      v.pos = anchor.add(bias);
    } else {
      v.pos = v.pos.add(speed.mul(1 / 60));
    }
  }
}

export class SimOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.views = [];
    this.refs = {};

    this.opts = OPTS_DEFAULT;
    const query = parseQuery(window.location.search);
    this.query = query;
    if (query.layoutopts && OPTS[query.layoutopts]) {
      this.opts = OPTS[query.layoutopts];
    }

    this.tick = this.onTick.bind(this);
    this.compact = !!(query.compact ?? true);
    this.aimline = !!(query.aimline ?? true);

    this.canvasRef = React.createRef();

    this.state = {
      simtpsOptIdx: 0,
    };
  }

  componentDidMount() {
    this.timer = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.timer);
    this.timer = null;
  }

  onTick() {
    const { data } = this.props;
    if (data) {
      this.layout(data.entities);
    }

    this.timer = requestAnimationFrame(this.tick);
  }

  layout(entities) {
    // TODO: requestAnimationFrame?
    const { views, opts } = this;

    const visible = (e) => {
      if (e.team === 0) {
        return true;
      }
      if (e.state === 'dead') {
        return false;
      }
      return e.visible;
    }

    let reset = false;
    let anchors_prev = views.map((v) => v?.anchor);

    for (let i = 0; i < entities.length; i++) {
      const e = entities[i];
      if (!e.screenpos || !visible(e)) {
        views[i] = undefined;
        continue;
      }

      const { x, y } = e.screenpos;
      const anchor = new v2(x, y);

      let v = views[i];

      const idx = i % 4;
      const biasx = idx < 2 ? -1 : 1;
      const biasy = idx % 2 === 0 ? -1 : 1;
      if (!v) {
        v = views[i] = {
          pos: anchor,
          anchor,
          speed: new v2(0, 0),
          bias: new v2(biasx * 200, biasy * 100),
        };
        reset = true;
      }
      v.anchor = anchor;
    }

    let diff_vec = v2.zero();
    let diff_squared = 0;
    if (!reset) {
      let count = 0;
      for (let i = 0; i < views.length; i++) {
        if (!views[i] || !anchors_prev[i]) {
          continue;
        }
        const diff = views[i].anchor.sub(anchors_prev[i]);
        count += 1;
        diff_vec = diff_vec.add(diff);
        diff_squared += diff.len2();
      }
      diff_vec = diff_vec.mul(1 / count);
      diff_squared = Math.sqrt(diff_squared);
      if (diff_squared > 10) {
        reset = true;
      }
    }

    const views1 = views.filter((v) => v !== undefined);
    /*
    if (reset) {
      for (let i = 0; i < 200; i++) {
        layout(views1, opts);
      }
    }
    */

    // camera 이동만큼 옮깁니다.
    if (!reset && opts.follow_dir) {
      for (let i = 0; i < views1.length; i++) {
        views1[i].pos = views1[i].pos.add(diff_vec.mul(1 / 2));
      }
    }

    layout(views1, opts);

    this.views = views;
    this.setState({});
  }

  renderHud(e, view) {
    const { compact } = this;
    const { data } = this.props;
    const { entities } = data;

    const { idx } = e;

    let state = null;
    if (e.aimtarget) {
      state = 'aim';
    }
    let ammoPercent = e.ammo / e.firearm_ammo_max;
    if (e.reload_remain > 0) {
      state = 'reload';
      ammoPercent = (e.reload_duration - e.reload_remain) / e.reload_duration;
    }
    const isAlly = e.team === 0;
    const { pos } = view;

    let passiveState = e.state;
    if (e.effects.includes('stun_gr')) {
      passiveState = 'stunned';
    }

    const props = {
      isAlly,
      name: e.name,
      lifePercent: div(e.life, e.life_max),
      shieldPercent: div(e.shield, e.shield_max),
      shieldReason: e.shield_max > 0 ? null : 'unavailable',
      state,
      ammoPercent,
      pos,
      passiveState,
      compact,
      buffs: e.buffs,
    };

    let aimline = null;
    if (this.aimline && isAlly && e.aimtarget) {
      const target_screenpos = entities.find((other) => other.pos.x === e.aimtarget.x && other.pos.y === e.aimtarget.y);
      if (target_screenpos?.screenpos) {
        aimline = <FigmaIngameAimLine key={`aimline-${idx}`} from={v2.from(e.screenpos)} to={v2.from(target_screenpos.screenpos)} />;
      }
    }

    return <>
      <FigmaIngameCharacterHud key={`hud-${idx}`} {...props} />
      {!compact ? <FigmaIngameCharacterHudLine key={`hudline-${idx}`} {...view} isAlly={isAlly} /> : null}
      {aimline}
    </>;
  }

  renderHuds() {
    const { views } = this;
    const { data } = this.props;
    const { entities } = data;

    return entities.map((e, i) => {
      if (!e.screenpos || !views[i]) {
        return null;
      }
      const view = views[i];
      return this.renderHud(e, view);
    });
  }

  renderBubbles() {
    const { data } = this.props;
    const { entities, bubbles } = data;

    const rendered = [];
    return bubbles.map((b, i) => {
      if (rendered[b.entity_idx]) {
        return null;
      }
      rendered[b.entity_idx] = true;

      const e = entities.find((e) => e.idx === b.entity_idx);
      if (!e.screenpos) {
        return null;
      }
      return <FigmaIngameBubble key={`bubble-${i}`}
        isAlly={e.team === 0} message={b.msg}
        pos={e.screenpos}
      />;
    });
  }

  renderDirectionMarker() {
    const { data } = this.props;

    const { ui_dirMarkers } = data;
    if (!ui_dirMarkers) {
      return null;
    }

    return ui_dirMarkers.map((marker, idx) => {
      const { pos, outside_angle, is_out_side, args } = marker;
      const { color } = args;
      if (!is_out_side) {
        return null;
      }
      return <FigmaIngameDirectionMarker key={`directionMarker-${idx}`}
        pos={pos} angle={outside_angle} color={color}
      />
    });
  }

  renderDamageIndications() {
    const { data } = this.props;
    const { ui_damageIndicationMarkers } = data;
    if (!ui_damageIndicationMarkers) {
      return null;
    }

    return ui_damageIndicationMarkers.map((marker, idx) => {
      let { pos } = marker;
      const { damage, fadeProgress } = marker.args;

      pos = v2.from(pos).add(new v2(0, -50)).add(new v2(0, -fadeProgress * 50));
      return <FigmaIngameDamageIndication key={`damageIndication-${idx}`}
        pos={pos} damage={damage} opacity={1 - fadeProgress}
      />
    });
  }

  renderSpotMarker() {
    const { data } = this.props;
    const { ui_spotMarkers } = data;
    if (!ui_spotMarkers) {
      return null;
    }

    return ui_spotMarkers.map((marker, idx) => {
      const { angle, color, dist } = marker.args;
      let { img_screenpos, text_screenpos } = marker.args;
      img_screenpos = v2.from(img_screenpos).sub(new v2(40, 40));
      text_screenpos = v2.from(text_screenpos).add(new v2(0, 35));

      return <FigmaIngameSpotMarker key={`entityDir-${idx}`}
        angle={angle} img_screenpos={img_screenpos} text_screenpos={text_screenpos} color={color} dist={dist}
      />;
    });
  }

  renderAlertMarker() {
    const { data } = this.props;

    const { ui_alertMarkers } = data;
    if (!ui_alertMarkers) {
      return null;
    }

    return ui_alertMarkers.map((marker, idx) => {
      const { pos } = marker;
      return <FigmaIngameAlertMarker key={`alertMarker-${idx}`}
        pos={pos}
      />
    });
  }

  renderDeathMarker() {
    const { data } = this.props;
    const { ui_deathMarkers } = data;
    if (!ui_deathMarkers) {
      return null;
    }

    return ui_deathMarkers.map((marker, idx) => {
      const { pos, args } = marker;
      const { fadeProgress } = args;

      return <FigmaIngameDeathMarker key={`deathMarker-${idx}`}
        pos={pos} fadeout={1 - fadeProgress}
      />
    });
  }

  renderHeadshotMarker() {
    const { data } = this.props;
    const { ui_headshotMarkers } = data;
    if (!ui_headshotMarkers) {
      return null;
    }

    return ui_headshotMarkers.map((marker, idx) => {
      const { pos, args } = marker;
      const { progress } = args;

      return <FigmaIngameHeadshotMarker key={`headshotMarker-${idx}`}
        pos={pos} progress={progress}
      />
    });
  }

  renderIndicators() {
    const { data } = this.props;
    const { indicators } = data;
    // backward-compat
    if (!data.indicators) {
      return null;
    }
    return indicators.map((obj, i) => {
      // backward-compat
      if (!obj.screenpos) {
        return null;
      }
      const { duration, remain } = obj;
      const progress = 1 - remain / duration;
      return <FigmaIngameActionProgress key={`indicator-${i}`} ty={obj.ty} percent={progress} pos={obj.screenpos} />;
    });
  }

  renderDialogs() {
    const { data: { dialogs, entities } } = this.props;
    if (dialogs.length === 0) {
      return null;
    }
    const [dialog] = dialogs;
    const { name, text } = dialog;
    const names = entities.filter((e) => e.team === 0 && e.state !== 'dead').map((e) => e.name);

    const dynamicName = resolveName(name, names);
    // 현재 대사를 할 대상이 존재하지 않는 경우
    if (!dynamicName) {
      return null;
    }

    return <FigmaIngameDialog key="dialog"
      name={dynamicName}
      text={text}
    />;
  }

  renderThrowableControl(ctx, data) {
    const { activate, state, target, targetpos_screenpos, reserve_target } = data;

    const renderPointer = (pos1, pos2, color) => {
      ctx.lineWidth = 5;
      ctx.strokeStyle = color;

      ctx.beginPath();
      ctx.moveTo(pos1.x, pos1.y);
      ctx.lineTo(pos2.x, pos2.y);
      ctx.stroke();

      renderSymbolCircle(ctx, pos2, 5);
    };

    const colors = {
      disable: 'gray',
      ok: 'blue',
      no: 'green',
      blocked: `red`,
    };

    if (reserve_target) {
      renderPointer(reserve_target.screenpos, targetpos_screenpos, colors['no']);
    }

    if (!activate) {
      return;
    }

    if (target) {
      renderPointer(target.screenpos, targetpos_screenpos, colors[state]);
      if (state === 'blocked') {
        renderX(ctx, targetpos_screenpos, 10);
      }
    }
  }

  renderControllers() {
    const { data } = this.props;
    if (!data) {
      return;
    }

    const { simctrl } = data;
    if (!simctrl) {
      return;
    }

    const { canvasRef } = this;
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (simctrl.throwable_ctrl) {
      this.renderThrowableControl(ctx, simctrl.throwable_ctrl);
    }
  }

  componentDidUpdate(prevProps) {
    const { idx } = this.props;
    this.renderControllers();
    if (prevProps.idx !== idx)
      this.setState({ simtpsOptIdx: idx });
  }

  renderGameSpeedController() {
    const { simtpsOptIdx } = this.state;

    const onSpeedUp = () => {
      const curOptIdx = simtpsOptIdx;
      if (curOptIdx + 1 < opts.SIMTPS_OPTS.length) {
        this.props.onSendAction?.('sim-speed', { simtps_idx: curOptIdx + 1 });
        this.setState({ simtpsOptIdx: curOptIdx + 1 });
      }
    };

    const onSpeedDown = () => {
      const curOptIdx = simtpsOptIdx;
      if (curOptIdx > 0) {
        this.props.onSendAction?.('sim-speed', { simtps_idx: curOptIdx - 1 });
        this.setState({ simtpsOptIdx: curOptIdx - 1 });
      }
    };

    return <div className='sim-gamespeed-controller'>
      <div className='sim-gamespeed-controller-label'>GAME SPEED</div>
      <div className='sim-gamespeed-controller-buttons'>
        <svg className='speed-button' onClick={() => { onSpeedDown(); }} xmlns="http://www.w3.org/2000/svg" width="38" height="33" viewBox="0 0 38 33" fill="none">
          <rect className='button-rect1' x="2" y="2" width="34" height="27" rx="4" fill="#2C2A27" />
          <rect className='button-rect2' x="1" y="1" width="36" height="29" rx="5" stroke="#525C5C" strokeWidth="2" />
          <path className='button-path' d="M10.0527 13.7C10.0527 13.203 10.4533 12.8 10.9475 12.8H27.0527C27.5469 12.8 27.9475 13.203 27.9475 13.7V17.3C27.9475 17.7971 27.5469 18.2 27.0527 18.2H10.9475C10.4533 18.2 10.0527 17.7971 10.0527 17.3V13.7Z" fill="#B4C4C3" />
        </svg>
        <div className='xspeed'>{`x${simtpsOptIdx + 1}`}</div>
        <svg className='speed-button' onClick={() => { onSpeedUp(); }} xmlns="http://www.w3.org/2000/svg" width="38" height="33" viewBox="0 0 38 33" fill="none">
          <rect className='button-rect1' x="2" y="2" width="34" height="27" rx="4" fill="#2C2A27" />
          <rect className='button-rect2' x="1" y="1" width="36" height="29" rx="5" stroke="#525C5C" strokeWidth="2" />
          <path className='button-path' d="M22.5793 12.8C22.0851 12.8 21.6845 12.3971 21.6845 11.9V7.4C21.6845 6.90294 21.2839 6.5 20.7898 6.5H17.2108C16.7167 6.5 16.3161 6.90294 16.3161 7.4V11.9C16.3161 12.3971 15.9155 12.8 15.4214 12.8H10.0529C9.55879 12.8 9.1582 13.2029 9.1582 13.7V17.3C9.1582 17.7971 9.55879 18.2 10.0529 18.2H15.4214C15.9155 18.2 16.3161 18.6029 16.3161 19.1V23.6C16.3161 24.0971 16.7167 24.5 17.2108 24.5H20.7898C21.2839 24.5 21.6845 24.0971 21.6845 23.6V19.1C21.6845 18.6029 22.0851 18.2 22.5793 18.2H27.9477C28.4418 18.2 28.8424 17.7971 28.8424 17.3V13.7C28.8424 13.2029 28.4418 12.8 27.9477 12.8H22.5793Z" fill="#B4C4C3" />
        </svg>
      </div>
    </div>
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const { simSpeed_idx } = data;
    if (simSpeed_idx !== undefined && simSpeed_idx !== this.state.simtpsOptIdx) {
      this.setState({ simtpsOptIdx: simSpeed_idx });
    }

    const { entities, segments } = data;

    const allies = entities.filter((e) => e.team !== 1);
    const names = entities.filter((e) => e.team === 0 && e.state !== 'dead').map((e) => e.name);

    const canvas = <canvas
      width={window.innerWidth}
      height={window.innerHeight}
      ref={this.canvasRef}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
      }}
    />;

    const { heal_ctrl, order_ctrl, throwable_ctrl } = data?.simctrl;

    if (heal_ctrl) {
      heal_ctrl.btn_heal_event = (idx) => {
        this.props?.onSendAction('heal', { idx });
      }
      heal_ctrl.heal_btn_activate = () => {
        this.props?.onSendAction('toggle-heal');
      }
    }

    if (order_ctrl) {
      order_ctrl.btn_order_event = (id1, id2) => {
        this.props?.onSendAction('order-swap', { id1, id2 });
      }
      order_ctrl.order_btn_activate = () => {
        this.props?.onSendAction('toggle-order');
      }
    }

    if (throwable_ctrl) {
      throwable_ctrl.btn_throwable_event = () => {
        this.props?.onSendAction('toggle-throwable');
      }
    }

    return <>
      <FigmaIngameAgentList
        entities={allies}
        throwable_controller={throwable_ctrl}
        heal_ctrl={heal_ctrl}
        order_ctrl={order_ctrl}
      />
      {segments?.length > 0 ? <FigmaIngameProgressBoard segments={segments} /> : null}
      {this.renderHuds()}
      {this.renderBubbles()}
      {this.renderDamageIndications()}
      {this.renderSpotMarker()}
      {this.renderAlertMarker()}
      {this.renderDirectionMarker()}
      {this.renderDeathMarker()}
      {this.renderHeadshotMarker()}
      {this.renderIndicators()}
      {this.renderDialogs()}
      {this.renderGameSpeedController()}
      {canvas}

      {data.pending_prompts.map((p, idx) => {
        return <DialogView key={p.id} data={p.dialog} squadNames={names} auto={true} onDone={() => {
          this.props?.onSelectPrompt(idx, 0);
        }} />;
      })}
    </>;
  }
}
