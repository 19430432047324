import _ from 'lodash';
import source from '../downloaded/dialogs.json' assert { type: 'json' };
import { L } from '../../../localization.mjs';
import { split } from './parseUtilities.mjs';

function parse_dialog(dialog) {
  return dialog.split('\n').map((line) => {
    const [name, text] = line.split(':', 2).map((s) => s.trim());
    return { name, text };
  });
}

function zip(keys, values) {
  keys = split(keys);
  values = split(values);
  return _.zip(keys, values).map(([effect_key, effect_value]) => ({ effect_key, effect_value }));
}

export const dialogs = [];
for (const row of source) {
  const { idx, config, trigger, trigger_once, effect_only, dialog, condition_keys, condition_values, start_effect_keys, start_effect_values, end_effect_keys, end_effect_values } = row;
  dialogs.push({
    idx: parseInt(idx),
    config,
    trigger: split(trigger),
    trigger_once: trigger_once === 'TRUE',
    effect_only: effect_only === 'TRUE',
    dialog_key: dialog,
    // TODO
    dialog: '',
    condition_keys: split(condition_keys),
    condition_values: split(condition_values),
    start_effects: zip(start_effect_keys, start_effect_values),
    end_effects: zip(end_effect_keys, end_effect_values),
  });
}

export function dialogByConfigTrigger(config, trigger, additional_filter) {
  const found = dialogs.find((d) => (d.config === '' || d.config === config) && JSON.stringify(d.trigger) === JSON.stringify(trigger) && additional_filter(d));
  if (found) {
    const { dialog_key } = found;
    found.dialog = parse_dialog(L(dialog_key));
  }
  return found;
}

export function dialogByIdx(idx) {
  const found = dialogs.find((d) => d.idx === idx);
  if (found) {
    const { dialog_key } = found;
    found.dialog = parse_dialog(L(dialog_key));
  }
  return found;
}

