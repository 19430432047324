import source from '../downloaded/presetsStaff.json' assert { type: 'json' };

export const presetsStaff = [];
for (const row of source) {
    const {
        name,
        search,
        negotiate,
        bargain,
        train,
        manage,
        wage,
        cap_search,
        cap_negotiate,
        cap_bargain,
        cap_train,
        cap_manage,
        growth_search,
        growth_negotiate,
        growth_bargain,
        growth_train,
        growth_manage,
    } = row;

    const abilities = {
        'search': Number(search),
        'negotiate': Number(negotiate),
        'bargain': Number(bargain),
        'train': Number(train),
        'manage': Number(manage),
    };

    const caps = {
        'search': Number(cap_search),
        'negotiate': Number(cap_negotiate),
        'bargain': Number(cap_bargain),
        'train': Number(cap_train),
        'manage': Number(cap_manage),
    };

    const growths = {
        'search': Number(growth_search),
        'negotiate': Number(growth_negotiate),
        'bargain': Number(growth_bargain),
        'train': Number(growth_train),
        'manage': Number(growth_manage),
    };

    presetsStaff.push({
        name,
        abilities,
        caps,
        growths,
        wage: Number(wage),
    });
}
