import { split } from './parseUtilities.mjs';
import { L } from '../../../localization.mjs';
import source from '../downloaded/conversations.json' assert { type: 'json' };

function parse_conversation(conversation) {
  return conversation.split('\n').map((line) => {
    const [name, text] = line.split(':', 2).map((s) => s.trim());
    return { name, text };
  });
}

export function parse(dialog) {
  const out = [];
  let delay = 0;
  for (const line of dialog.split('\n')) {
    const splitidx = line.indexOf(',');
    if (splitidx === -1) {
      continue;
    }
    const idx = 0 | line.slice(0, splitidx);
    const text = line.slice(splitidx + 1).trim();
    out.push({
      idx,
      text,
      delay,
    });
    delay += 60;
  }
  return out;
}

export const conversations = [];
for (const row of source) {
  const { idx, trial, checkpoint, final, trigger, conversation } = row;
  const schedules = [];
  if (trial === 'TRUE') schedules.push('trial');
  if (checkpoint === 'TRUE') schedules.push('checkpoint');
  if (final === 'TRUE') schedules.push('final');
  conversations.push({
    idx: parseInt(idx),
    schedules,
    trigger: split(trigger),
    conversation_key: conversation,
    conversations: [],
  });
}

export function conversationsBySchedule(schedule) {
  const founds = conversations.filter((c) =>
    c.schedules.includes(schedule));
  for (const found of founds) {
    const { conversation_key } = found;
    found.conversations = parse_conversation(L(conversation_key));
  }
  return founds;
}

export function conversationsByScheduleTrigger(schedule, trigger) {
  const founds = conversations.filter((c) =>
    c.schedules.includes(schedule)
    && JSON.stringify(c.trigger) === JSON.stringify(trigger));
  for (const found of founds) {
    const { conversation_key } = found;
    found.conversations = parse_conversation(L(conversation_key));
  }
  return founds;
}