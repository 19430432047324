import source from "../downloaded/char2advices.json" assert { type: 'json' };

export const advices = {}

for (const advice of source) {
  advices[advice.key] = {
    id: +advice.id,
    warningLevel: +advice.warningLevel,
    warningColor: advice.warningColor,
    desc: advice.desc,
  }
}
