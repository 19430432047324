import source from '../downloaded/agentRelations.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { idx, more_or_equal, less, name, prob_success } = row;
  list.push({
    idx,
    min_relation: more_or_equal,
    max_relation: less,
    name,
    prob_success: parseFloat(prob_success),
  });
}

export function getRelationData(relation) {
  const data = list.find((e) => e.min_relation <= relation && e.max_relation > relation);
  return data ?? list[0];
}
