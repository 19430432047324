import source from '../downloaded/char2rolesPerks.json' assert { type: 'json' };

export const rolesPerksdata = {}

function split(s) {
  if (!s || s === '') {
    return [];
  }
  return s.split(',').map((s) => s.trim());
}

for (const row of source) {
  const { key, name, descr, role, minStat, require, overwrite, tags, level, perkPower } = row
  rolesPerksdata[key] = {
    name,
    descr,
    role,
    minStat: +minStat,
    requires: split(require),
    overwrites: split(overwrite),
    tags: split(tags),
    level: +level,
    perkPower: +perkPower,
  }
}
