import source from '../downloaded/training.json' assert { type: 'json' };


export const list = [];
for (const row of source) {
  let { areaNum, train_cost, train_mult, train_power_cap, train_stats2_cap, life_ratio } = row;
  list.push({
    areaNum: 0 | areaNum,
    train_cost: 0 | train_cost,
    train_mult: +train_mult,
    train_power_cap: 0 | train_power_cap,
    train_stats2_cap: 0 | train_stats2_cap,
    life_ratio: +life_ratio,
  });
}
