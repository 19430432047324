import source from '../downloaded/char2fixedBackgrounds.json' assert { type: 'json' };

export const fixedBackgrounds = {}

for (const row of source) {
  const { key, name, desc, fixed_trainingCompletion } = row

  fixedBackgrounds[key] = {
    name: name,
    desc,
    fixed_trainingCompletion: fixed_trainingCompletion?.split(',').map(s => (s.trim())) ?? [],
  }
}
