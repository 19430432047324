import source from '../downloaded/eventTypeScore.json' assert { type: 'json' };

export const eventTypeScores = {};
for (const row of source) {
  let { status, min, max, score } = row;
  if (!eventTypeScores[status]) {
    eventTypeScores[status] = [];
  }
  eventTypeScores[status].push({
    min: parseInt(min),
    max: parseInt(max),
    score: parseInt(score),
  });
}

export function getEventTypeScore(status, value) {
  const data = eventTypeScores[status].find((e) => e.min <= value && e.max >= value);
  return (data ?? eventTypeScores[status][0]).score;
}
