import source from '../downloaded/companyStatus.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { id, order, desc, integrity_max, integrity_min, avg_condition_max, avg_condition_min, avg_mood_max, avg_mood_min } = row;
  list.push({
    id,
    order: parseInt(order),
    desc,
    integrity_max: parseInt(integrity_max),
    integrity_min: parseInt(integrity_min),
    avg_condition_max: parseInt(avg_condition_max),
    avg_condition_min: parseInt(avg_condition_min),
    avg_mood_max: parseInt(avg_mood_max),
    avg_mood_min: parseInt(avg_mood_min),
  });
}

export function getCompanyStatusData(integrity, avg_condition, avg_mood) {
  const data = list.find((l) =>
    l.integrity_max >= integrity &&
    l.integrity_min <= integrity &&
    l.avg_condition_max >= avg_condition &&
    l.avg_condition_min <= avg_condition &&
    l.avg_mood_max >= avg_mood &&
    l.avg_mood_min <= avg_mood);
  return data ?? list[list.length - 1];
}
