import _ from 'lodash';

import callsigns from './data/google/downloaded/char2callsign.json' assert { type: 'json' };
import characterNames from './data/google/downloaded/char2characterNames.json' assert { type: 'json' };

import { nationalities } from './data/google/processor/data_char2nationalities.mjs';
import { backgrounds } from './data/google/processor/data_char2backgrounds.mjs';
import { trainingCompletions } from './data/google/processor/data_char2trainingCompletion.mjs';
import { ages } from './data/google/processor/data_char2ages.mjs';
import { potentialTiers } from './data/google/processor/data_char2PotentialTier.mjs';
import { statTiers } from './data/google/processor/data_char2StatTier.mjs';
import { statstoSecondaryStats } from './stats3.mjs';
import { statsPerksdata } from './data/google/processor/data_char2statsPerks.mjs';
import { rolesPerksdata } from './data/google/processor/data_char2rolesPerks.mjs';
import { trainingCompletionTierCount } from './data/google/processor/data_char2trainingCompletionTierCount.mjs';
import { aptitudeMultiplier } from './data/google/processor/data_char2aptitudeMultiplier.mjs';
import { roles } from './data/google/processor/data_char2roles.mjs';

import { firearms_tier1 } from './presets_firearm.mjs';
import { gears_vest_bulletproof } from './presets_gear.mjs';
import { throwables } from './presets_throwables.mjs';
import { utilities } from './presets_utilities.mjs';
import { fixedBackgrounds } from './data/google/processor/data_char2fixedBackgrounds.mjs';
import { fixedOperators } from './data/google/processor/data_char2fixedOperators.mjs';
import { throwable_breach_charge } from './presets_throwables.mjs';
import { utility_healthpack_small, utility_healthpack_big } from './presets_utilities.mjs';
import { perk2Sets } from './presets_perk2.mjs';
import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { TICK_PER_DAY } from './tick.mjs';

export const DEFAULT_FIREARM = firearms_tier1.find((f) => f.firearm_ty === 'hg');
export const DEFAULT_EQUIPMENT = gears_vest_bulletproof[0];
export const DEFAULT_THROWABLE = throwables[0];
export const DEFAULT_UTILITY = utilities[0];

function avg(list) {
  return list.reduce((a, b) => a + b, 0) / list.length;
}

String.prototype.format = function () {
  var formatted = this;
  for (var i = 0; i < arguments.length; i++) {
    var regexp = new RegExp('\\{' + i + '\\}', 'gi');
    formatted = formatted.replace(regexp, arguments[i]);
  }
  return formatted;
};

export function createFixedCharacter2(opts) {
  const { id, idx, key, rng, perks } = opts
  const found = Object.values(fixedOperators).find((op) => op.key === key);
  if (!found) {
    return null;
  }

  const { name, potentialTier, Tier, role, nationality, backgroundName, backgroundDesc, age, callsign, perks_initial } = found;

  const fixedrole = roles.find((r) => r.key === role)
  const fixedNationality = nationalities.find((r) => r.key === nationality)
  const fixedopts = {
    id,
    idx,
    name,
    role: fixedrole,
    potentialTier,
    statTier: Tier,
    nationality: fixedNationality,
    backgroundName,
    backgroundDesc,
    age,
    callsign,
    perks_initial,
    perks,
    rng
  }

  const character = createCharacter2(fixedopts);
  character.fixedOperatorKey = key;

  return character;
}

// TODO: 캐릭터 생성에서 받는 정보 정리 필요
export function createCharacter2(opts) {
  const character = createCharacterBase(opts);

  character.statsPerks = {};
  character.operatorPerks = {};

  if (opts.perks_initial) {
    let stat_blocks = [];
    let operator_blocks = [];
    for (const key of opts.perks_initial) {
      const type = key.split('_')[1] === 'common' ? 'stat' : 'operator';
      activateCharacter2Perk(character, type, key);
      if (type === 'stat') {
        stat_blocks = stat_blocks.concat(statsPerksdata[key].overwrites);
      }
      else {
        operator_blocks = operator_blocks.concat(rolesPerksdata[key].overwrites);
      }
    }

    while (stat_blocks.length > 0) {
      const key = stat_blocks.splice(0, 1)[0];
      character.statsPerks[key] = 'blocked';
      stat_blocks = stat_blocks.concat(statsPerksdata[key].overwrites);
    }
    while (operator_blocks.length > 0) {
      const key = operator_blocks.splice(0, 1)[0];
      character.operatorPerks[key] = 'blocked';
      operator_blocks = operator_blocks.concat(rolesPerksdata[key].overwrites);
    }

    for (const key in statsPerksdata) {
      if (!character.statsPerks[key]) {
        character.statsPerks[key] = 'deactivated';
      }
    }
    for (const key in rolesPerksdata) {
      if (rolesPerksdata[key]["role"] == character.role && !character.operatorPerks[key]) {
        character.operatorPerks[key] = 'deactivated';
      }
    }
  }
  else {
    for (const key in statsPerksdata) {
      if (!character.statsPerks[key]) {
        character.statsPerks[key] = 'deactivated';
      }
    }
    for (const key in rolesPerksdata) {
      if (rolesPerksdata[key]["role"] == character.role && !character.operatorPerks[key]) {
        character.operatorPerks[key] = 'deactivated';
      }
    }
    if (opts.perks) {
      const { rng, perks } = opts;
      const activateRandomPerk = () => {
        const pool = [];
        for (const key of perks) {
          const type = key.split('_')[1] === 'common' ? 'stat' : 'operator';
          if (type === 'stat') {
            if (character.statsPerks[key] === 'deactivated') {
              pool.push({ ty: 'stat', key });
            }
          }
          else {
            if (character.operatorPerks[key] === 'deactivated') {
              pool.push({ ty: 'stat', key });
            }
          }
        }
        for (const [key, value] of Object.entries(character.statsPerks)) {
          if (value === 'deactivated') {
            const requires = statsPerksdata[key].requires;
            if (requires.length > 0) {
              let upgradeable = true;
              for (const require_key of requires) {
                if (character.statsPerks[require_key] !== 'activated') {
                  upgradeable = false;
                }
              }
              if (upgradeable) {
                pool.push({ ty: 'stat', key });
              }
            }
          }
        }
        for (const [key, value] of Object.entries(character.operatorPerks)) {
          if (value === 'deactivated') {
            const requires = rolesPerksdata[key].requires;
            if (requires.length > 0) {
              let upgradeable = true;
              for (const require_key of requires) {
                if (character.operatorPerks[require_key] !== 'activated') {
                  upgradeable = false;
                }
              }
              if (upgradeable) {
                pool.push({ ty: 'operator', key });
              }
            }
          }
        }

        if (pool.length > 0) {
          const target = rng.choice(pool);
          activateCharacter2Perk(character, target.ty, target.key);
        }
      }

      const tierData = statTiers.find(s => s.statTier === character.tier);
      for (let i = 0; i < tierData.perks_initial_count; i++) {
        activateRandomPerk();
      }
    }
  }

  return character;
}

function statBase(rng, weights, sum) {
  // 스텟 분배
  let remain = sum;
  const values = [];
  const weights_sum = _.sum(weights);
  for (let i = 0; i < weights.length; i++) {
    const v = Math.floor(sum * weights[i] / weights_sum);
    values.push(v);
    remain -= v;
  }

  let i = 0;
  while (remain > 0) {
    values[i] += 1;
    remain -= 1;
  }

  return values;
}

function statVariant(rng, weights, sum, variation, cap) {
  const values = statBase(rng, weights, sum);

  const indices = rng.shuffle(Array.from(Array(values.length).keys()));
  const cut = rng.integer(2, weights.length - 2);

  const indices_up = indices.splice(0, cut);

  while (variation > 0) {
    variation -= 1;

    const idx_src = rng.choice(indices);
    const idx_dst = rng.choice(indices_up);
    if (values[idx_src] <= 1) {
      continue;
    }
    if (values[idx_dst] >= cap[idx_dst]) {
      continue;
    }
    values[idx_src] -= 1;
    values[idx_dst] += 1;
  }

  return values;
}

function statVariantMap(rng, weights, sum, variation, cap) {
  const values = statVariant(rng, Object.values(weights), sum, variation, Object.values(cap ?? {}));
  const result = {};
  let i = 0;
  for (const key of Object.keys(weights)) {
    result[key] = values[i];
    i += 1;
  }
  return result;
}

function createCharacterBase(opts) {
  let { id, idx, name, rng, role, potentialTier, statTier, nationalityKey, backgroundName, backgroundDesc, age, callsign } = opts;

  potentialTier = potentialTier == null || potentialTier == 0 ? rng.integer(1, 4) : potentialTier;
  const data_potentialTier = potentialTiers.find((e) => e.potentialTier === potentialTier);

  const potentialMin = data_potentialTier.minValue;
  const potentialMax = data_potentialTier.maxValue;
  const potential = rng.integer(potentialMin, potentialMax);
  const potentialStats = statVariantMap(rng, role.stat_weights, potential, rng.integer(0, Math.floor(potential / 10)));

  const potentialPower = avg(Object.values(potentialStats));

  const statTierWeights = data_potentialTier.statTierWeights;

  const tier = statTier == 0 || statTier == null ? +rng.weighted_key(statTierWeights, "weight")['tier'] : statTier;
  const data_statTier = statTiers.find((e) => e.statTier === tier);

  const statsMin = data_statTier.minValue;
  const statsMax = data_statTier.maxValue;
  const base_salary = data_statTier.pay_weekly;
  const base_payment = base_salary * 5;
  const totalStat = rng.integer(statsMin, statsMax);
  const realStats = statVariantMap(rng, role.stat_weights, totalStat, rng.integer(0, Math.floor(totalStat / 5)), potentialStats);

  const power = avg(Object.values(realStats));

  let nationality
  if (nationalityKey == null) {
    nationalityKey = characterNames.find((e) => e.name === name)?.nationalityKey;
  }

  if (nationalityKey == null) {
    nationality = rng.weighted_key(nationalities, "weight");
  } else {
    nationality = nationalities.find((e) => e.key == nationalityKey);
  }

  let background = null;
  if (backgroundName != null || backgroundDesc != null) {
    const nullBackground = Object.values(backgrounds).find((b) => b.nationalityKey === '');
    background = { name: backgroundName ?? nullBackground.name, desc: backgroundDesc ?? nullBackground.desc };
  } else {
    const background_candidates = Object.values(backgrounds).filter((b) => b.tier_max <= tier && b.tier_min >= tier && b.nationalityKey.includes(nationality.key));

    background = background ?? Object.values(backgrounds).find((b) => b.nationalityKey === '');
    if (background_candidates.length !== 0) {
      background = rng.weighted_key(background_candidates, 'weight');
    } else {
      // TODO: 항상 생성되어야 함
      background = rng.choice(Object.values(backgrounds));
    }
  }

  age = age ?? rng.integer(ages.ageMin, ages.ageMax);

  callsign = callsign ?? rng.choice(callsigns).name;

  // opts로 이름이 주어진 경우 강제로 설정합니다
  if (name == null) {
    const nameCadidates = characterNames.filter((n) => n.nationalityKey === nationality.key)
    if (nameCadidates !== 0) {
      name = rng.choice(nameCadidates).name;
    } else {
      name = "이름없음"
    }
  }

  const secondaryStats = statstoSecondaryStats(realStats);

  const firearmAptitudes = {};

  for (const key of Object.keys(role.firearmAptitudes)) {
    const newKey = key.replace("firearmAptitude_", "");
    firearmAptitudes[newKey] = {
      ...aptitudeMultiplier[role.firearmAptitudes[key]],
      id: role.firearmAptitudes[key],
    };
  }

  let attachables = [];
  if (role.key == 'breacher') {
    const charge = throwable_breach_charge;
    attachables = [charge];
  }

  let heals = [];
  if (role.key === 'medic') {
    const pack = utility_healthpack_small.heal;
    heals = [pack, pack, pack, pack, pack];
  }

  const ch = {
    // intrinsics
    id,
    idx,
    totalPotential: potential,
    potentialStats,
    potentialPower,
    potentialTier,
    tier,
    realStats,
    realStats0: { ...realStats },
    totalRealStat: totalStat,
    power,
    role: role.key,
    nationality: nationality.name,
    background: {
      name: background.name,
      nationalityKey: background.nationalityKey,
      desc: background.desc.format([name])
    },
    age: age,
    callsign: callsign,
    name,
    secondaryStats: secondaryStats,
    firearmAptitudes: firearmAptitudes,

    relation: 0,

    level: {
      cur: 1,
      exp: 0,
    },

    firearm: DEFAULT_FIREARM,
    equipment: DEFAULT_EQUIPMENT,
    throwables: [DEFAULT_THROWABLE],
    attachables,
    utilities: [DEFAULT_UTILITY],
    heals,
    spawnarea: 0,

    base_salary,
    salary: base_salary,
    base_payment,
    payment: base_payment,
    payment_discount: 0,
    history: {
      trial_kills: 0,
      checkpoint_kills: 0,
      final_kills: 0,
    },
  };

  return resetCharacter2(ch);
}

export function resetCharacter2(ch) {
  return {
    ...ch,

    squad: undefined,

    schedule: 'train_physical',
    schedule_type: 'train_default',
    condition: 100,
    condition_max: 100,
    mood: 50,
    mood_max: 100,
    mood_min: 0,
    train_effect: 100,

    modifier: ch.modifier ? ch.modifier.filter((m) => m.term === 'semiperma') : [],
  };
}

export function addAgentModifier(agent, key, start) {
  let updateSchedule = false;
  const data = data_agentModifiers.find((d) => d.key === key);
  if (data) {
    const mod = agent.modifier.find((m) => m.key === key);
    if (mod) {
      mod.start = start;
    }
    else {
      agent.modifier.push({ key, start, term: data.duration === 'semiperma' ? data.duration : data.duration * TICK_PER_DAY });
      for (let i = 0; i < data.effects.length; i++) {
        const effect_key = data.effects[i];
        if (effect_key === 'mood_max') {
          agent.mood = Math.min(agent.mood, agentMoodMax(agent));
        }
        else if (effect_key === 'mood_min') {
          agent.mood = Math.max(agent.mood, agentMoodMin(agent));
        }
        else if (effect_key === 'fix_schedule' || effect_key === 'block_schedule') {
          updateSchedule = true;
        }
      }
    }
    agent.modifier = agent.modifier.filter((m) => !data.overwrite.includes(m.key));
  }

  return updateSchedule;
}

export function removeAgentModifier(agent, key) {
  let updateSchedule = false;

  const idx = agent.modifier.findIndex((m) => m.key === key);
  if (idx < 0) {
    return false;
  }
  agent.modifier.splice(idx, 1);

  const data = data_agentModifiers.find((d) => d.key === key);

  if (data?.change_schedule) {
    updateSchedule = true;
  }
  for (let i = 0; i < data.effects.length; i++) {
    const effect_key = data.effects[i];
    if (effect_key === 'mood_max') {
      agent.mood = Math.min(agent.mood, agentMoodMax(agent));
    }
    else if (effect_key === 'mood_min') {
      agent.mood = Math.max(agent.mood, agentMoodMin(agent));
    }
    else if (effect_key === 'fix_schedule' || effect_key === 'block_schedule') {
      updateSchedule = true;
    }
  }

  return updateSchedule;
}

export function agentModifierEffectValue(agent, effect_key) {
  const { modifier } = agent;

  let effect_value = 0;
  for (const mod of modifier) {
    const data = data_agentModifiers.find((d) => d.key === mod.key);
    if (!data) {
      console.error(`agentModifier key not found: ${mod.key}`);
      continue;
    }
    for (let i = 0; i < data.effects.length; i++) {
      if (data.effects[i] === effect_key) {
        effect_value += parseFloat(data.effects_value[i]);
      }
    }
  }

  return effect_value;
}

export function agentHasModifierEffect(agent, effect_key) {
  const { modifier } = agent;

  for (const mod of modifier) {
    const data = data_agentModifiers.find((d) => d.key === mod.key);
    if (!data) {
      console.error(`agentModifier key not found: ${mod.key}`);
      continue;
    }
    for (let i = 0; i < data.effects.length; i++) {
      if (data.effects[i] === effect_key) {
        return true;
      }
    }
  }

  return false;
}

export function totalModifierEffectValue(targetAgent, agents_all, effect_key) {
  let effect_value = 0;
  effect_value += agentModifierEffectValue(targetAgent, effect_key)
  for (const agent of agents_all) {
    effect_value += agentModifierEffectValue(agent, `${effect_key}_all`);
  }
  return effect_value;
}

const agentBaseStatus = {
  mood_max: 100,
  mood_min: 0,
  condition_max: 100,
  condition_min: 0,
  relation_max: 100,
  relation_min: -100,
}

export function getAgentStatus(agent, key) {
  return agentBaseStatus[key] + agentModifierEffectValue(agent, key);
}

export function changeAgentStatus(agent, key, diff) {
  agent[key] = getExpectedAgentStatus(agent, key, diff);
}

export function getExpectedAgentStatus(agent, key, diff) {
  let final_diff = diff + agentModifierEffectValue(agent, `additional_${key}`);

  if (diff > 0) {
    final_diff += agentModifierEffectValue(agent, `additional_${key}_increase`);
  }
  else if (diff < 0) {
    final_diff += agentModifierEffectValue(agent, `additional_${key}_decrease`);
  }
  if (diff >= 0) {
    final_diff = Math.max(final_diff, 0);
    return Math.min(agent[key] + final_diff, getAgentStatus(agent, `${key}_max`));
  }
  else {
    final_diff = Math.min(final_diff, 0);
    return Math.max(agent[key] + final_diff, getAgentStatus(agent, `${key}_min`));
  }
}

export function agentMoodMax(agent) {
  return 100 + agentModifierEffectValue(agent, 'mood_max');
}

export function agentMoodMin(agent) {
  return agentModifierEffectValue(agent, 'mood_min');
}

export function changeAgentMood(agent, diff) {
  agent.mood = getExpectedAgentMood(agent, diff);
}

export function getExpectedAgentMood(agent, diff) {
  if (diff > 0) {
    return Math.min(agentMoodMax(agent), agent.mood + diff);
  }
  else {
    return Math.max(agentMoodMin(agent), agent.mood + diff);
  }
}

export function changeAgentRelation(agent, diff) {
  agent.relation = getExpectedAgentRelation(agent, diff);
}

export function getExpectedAgentRelation(agent, diff) {
  let final_diff = diff + agentModifierEffectValue(agent, 'additional_relation');
  if (diff >= 0) {
    final_diff = Math.max(final_diff, 0);
  }
  else {
    final_diff = Math.min(final_diff, 0);
  }

  return Math.min(Math.max(agent.relation + final_diff, -100), 100);
}

export function changeAgentCondition(agent, diff) {
  agent.condition = getExpectedAgentCondition(agent, diff);
}

export function getExpectedAgentCondition(agent, diff) {
  let final_diff = diff;
  if (diff < 0) {
    final_diff += agentModifierEffectValue(agent, 'additional_condition_decrease');
  }

  return Math.min(Math.max(agent.condition + final_diff, 0), agent.condition_max);
}

export function updadeCharacter2RealStats(character, opts) {
  const { role } = character;
  const { realStatsDiffs } = opts;

  let totalstat = 0;

  for (const element of Object.keys(character.realStats)) {
    character.realStats[element] += realStatsDiffs[element];
    totalstat += character.realStats[element];
  }

  character.power = avg(Object.values(character.realStats));

  character.secondaryStats = statstoSecondaryStats(character.realStats);

  return character;
}

export function activateCharacter2Perk(character, type, key) {
  if (type === 'stat') {
    character.statsPerks[key] = 'activated';

    if (statsPerksdata[key]) {
      const overwrites = statsPerksdata[key].overwrites;
      for (const overwrite_key of overwrites) {
        character.statsPerks[overwrite_key] = 'blocked';
      }
    } else {
      console.error(`statsPerks key not found: ${key}`);
    }
  }
  else if (type === 'operator') {
    character.operatorPerks[key] = 'activated';

    const overwrites = rolesPerksdata[key].overwrites;
    for (const overwrite_key of overwrites) {
      character.operatorPerks[overwrite_key] = 'blocked';
    }
  }

  if (key === 'perk2_breacher_efficient_explosive') {
    const charge = throwable_breach_charge;
    const num_attachables = perk2Sets[key].num_gadgets;
    character.attachables = [];
    for (let i = 0; i < num_attachables; i++) {
      character.attachables.push({ ...charge });
    }
  }
  else if (key === 'perk2_medic_paramedics_intermediate') {
    const pack = utility_healthpack_big.heal;
    const heal_count = perk2Sets[key].num_gadgets;
    character.heals = [];
    for (let i = 0; i < heal_count; i++) {
      character.heals.push({ ...pack });
    }
  }
  // 다재무능 퍽
  if (key === 'perk2_common_jack_of_all') {
    character.statsPerks[key] = 'activated';

    for (const weapon_key of ['AR', 'SMG', 'SG', 'DMR', 'HG']) {
      const new_id = 3; // grade 'B'
      character.firearmAptitudes[weapon_key] = {
        ...aptitudeMultiplier[new_id],
        id: new_id,
      }
    }
  }
  // perk2_common_mastery
  for (const weapon of ['ar', 'smg', 'sg', 'dmr']) {
    if (key === `perk2_common_${weapon}_mastery`) {
      const weapon_key = weapon.toUpperCase();
      // F는 등장하지 않습니다.
      const new_id = character.firearmAptitudes[weapon_key].id - 1;

      character.firearmAptitudes[weapon_key] = {
        ...aptitudeMultiplier[new_id],
        id: new_id,
      }
    }
  }
  // jack of all은 B로 고정이므로 perk2_common_mastery 이후 작동하도록 수정
}
