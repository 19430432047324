import source from '../downloaded/renown.json' assert { type: 'json' };

export const renowns = [];
for (const row of source) {
  const { unlocks, renown } = row;
  renowns.push({
    unlocks: 0 | unlocks,
    renown: 0 | renown,
  });
}

export function renownStep(renown) {
  for (let i = 0; i < renowns.length - 1; i++) {
    if (renowns[i + 1].renown > renown || i === renowns.length - 2) {
      return {
        next: renowns[i + 1],
        cur: renowns[i],
      };
    }
  }
  return null;
}

export function unlocksByRenown(renown) {
  for (const row of renowns) {
    if (row.renown > renown) {
      return row.unlocks;
    }
  }
  return renowns[renowns.length - 1].unlocks;
}
