import React from 'react';

import './RenderAgentSimple.css'

import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getRelationData } from './data/google/processor/data_agentRelations.mjs';
import { operatorExp as data_operatorExp } from './data/google/processor/data_operatorExp.mjs';
import { rolesBykey } from './data/google/processor/data_char2roles.mjs';

import { L } from './localization.mjs';

import { PortraitWrapper, PortraitWrapper2 } from './PortraitsView.js';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView.js';
import { TierBadge2, PowerIndicatorNumber } from './Badges.js';
export * from './Badges.js';

export const ICON_CLOSE = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path d="M26.3995 10.3432L22.1569 6.10052L16.5 11.7574L10.8431 6.10052L6.60051 10.3432L12.2574 16L6.60051 21.6569L10.8431 25.8995L16.5 20.2427L22.1569 25.8995L26.3995 21.6569L20.7426 16L26.3995 10.3432Z" fill="#3D301C" />
</svg>;

export function RenderAgentSimple(props) {
  const { agent, i, noTooltip, cls, onClick } = props;
  const { name, tier, role } = agent;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className={cls ? cls : "figmalist-body-list-row-name"} key={i}  {...figmaTooltipEvents(context, <RenderAgentDetail agent={agent} verbose />, 'agent')}>
        <div className="figmalist-body-list-row-name-img">
          <PortraitWrapper agent={{ name, role }} className="portrait-frame-small4" onClick={onClick ? () => onClick(agent) : null} noRole={true} />
        </div>

        <div className="figmalist-body-list-row-name-right">
          <div className="figmalist-body-list-row-name-upper">
            <RoleBadge role={role} noTooltip={noTooltip} isLabel={true} />
          </div>

          <div className="figmalist-body-list-row-name-lower">
            <TierBadge2 tier={tier ?? 5} />
            <span className="figmalist-body-list-row-name-lower-name">{L(name)}</span>
          </div>
        </div>
      </div>;
    }}
  </TooltipContext.Consumer>;
}

export function RenderAgentDetail(props) {
  const { agent, verbose } = props;
  let expMax;
  if (agent.level.cur <= data_operatorExp.length) {
    expMax = data_operatorExp[agent.level.cur - 1].expMax;
  }
  else {
    expMax = '∞';
  }

  return <div className="lobby-squad-drawer-right-body">
    {verbose ? <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{(!isNaN(agent.squad)) ? L("loc_ui_string_agent_included") : L("loc_ui_string_agent_waiting")}</div>
    </div> : null}
    <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_level')}</div>
      <div className="lobby-squad-drawer-item-value">{agent.level.cur}({agent.level.exp}/{expMax})</div>
    </div>
    <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_power_short')}</div>
      <div className="lobby-squad-drawer-item-value"><PowerIndicatorNumber character={agent} /></div>
    </div>
    <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_life_short')}</div>
      <div className="lobby-squad-drawer-item-value">{agent.secondaryStats.life}</div>
    </div>
    <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_stamina_short')}</div>
      <div className={"lobby-squad-drawer-item-value" + evalCSSClass('condition', agent.condition)}>{L(getConditionData(agent.condition).name)}({agent.condition})</div>
    </div>
    <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_mood_short')}</div>
      <div className={"lobby-squad-drawer-item-value" + evalCSSClass('mood', agent.mood)}>{L(getMoodData(agent.mood).name)}({agent.mood})</div>
    </div>
    {verbose ? <div className="lobby-squad-drawer-item-row">
      <div className="lobby-squad-drawer-item-label">{L('loc_ui_string_agent_relation_short')}</div>
      <div className={"lobby-squad-drawer-item-value" + evalCSSClass('relation', agent.relation)}>{L(getRelationData(agent.relation).name)}({agent.relation})</div>
    </div> : null}
  </div>;
}

export function RenderAgentCardEvent(props) {
  const { i, agent, OnClickBackgroundButton } = props;
  const { name, role } = agent;

  return <TooltipContext.Consumer>
    {(context) => {
      return <div className="figmapopup-content-agent" key={i} {...figmaTooltipEvents(context, <RenderAgentDetail agent={agent} />, 'agent')}>
        <div className="figmapopup-content-agent-background" />
        <div className="figmapopup-content-agent-shadow" />
        <PortraitWrapper2 agent={agent} className="portrait-frame-event" inSquad={agent.squad !== undefined && agent.squad !== null} />

        <div className="figmapopup-content-agent-names">
          <img className="figmapopup-content-agent-names-bg" src="/img/popup/UI_Popup_Name_Bg.png" />
          <span className="figmapopup-content-agent-names-name">{L(name)}</span>
        </div>

      </div>;
    }}
  </TooltipContext.Consumer>;
}

export function RoleBadge(props) {
  return <RoleBadge2 {...props} />;
}

export const roleIcons = {
  'pointman': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" fill="#308DC1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" fill="#308DC1" />
  </svg>,
  'sharpshooter': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2.73682 10.1754L8.49997 0L14.2631 10.1754L8.49997 16L2.73682 10.1754Z" fill="#A25CC3" />
  </svg>,
  'medic': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10 1H6V6H1V10H6V15H10V10H15V6H10V1Z" fill="#67BA33" />
  </svg>,
  'vanguard': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14 1.99999C9.43522 0.758635 6.88713 0.742053 2 1.99999V10.75L8 16L14 10.75V1.99999Z" fill="#DD9E3E" />
  </svg>,
  'breacher': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4641 0L15.7942 2.5L13.8163 5.92579L12.0843 4.92579L10.4587 7.74147C12.1314 9.46118 12.5351 12.1451 11.2763 14.3253C9.7575 16.9559 6.39375 17.8572 3.76314 16.3385C1.13253 14.8197 0.231216 11.4559 1.75 8.82532C3.00878 6.64505 5.5349 5.65268 7.86058 6.24147L11.4641 0ZM5.26314 13.7404C6.45887 14.4307 7.98785 14.021 8.6782 12.8253C9.36856 11.6296 8.95887 10.1006 7.76314 9.41025C6.56741 8.7199 5.03843 9.12959 4.34808 10.3253C3.65772 11.521 4.06741 13.05 5.26314 13.7404Z" fill="#A7928D" />
  </svg>,
  'scout': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z" fill="#A25CC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 3C3.5 3 0.5 7.5 0 9C0.5 10.5 3.5 15 9 15C14.5 15 17.5 10.5 18 9C17.5 7.5 14.5 3 9 3ZM13 9C13 11.2091 11.2091 13 9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9Z" fill="#A25CC3" />
  </svg>,
  'entire': <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M5.44444 2.72222C5.44444 4.22566 4.22566 5.44444 2.72222 5.44444C1.21878 5.44444 0 4.22566 0 2.72222C0 1.21878 1.21878 0 2.72222 0C4.22566 0 5.44444 1.21878 5.44444 2.72222Z" fill="#9F8967" />
    <path d="M5.44444 10.5C5.44444 12.0034 4.22566 13.2222 2.72222 13.2222C1.21878 13.2222 0 12.0034 0 10.5C0 8.99656 1.21878 7.77778 2.72222 7.77778C4.22566 7.77778 5.44444 8.99656 5.44444 10.5Z" fill="#9F8967" />
    <path d="M2.72222 21C4.22566 21 5.44444 19.7812 5.44444 18.2778C5.44444 16.7743 4.22566 15.5556 2.72222 15.5556C1.21878 15.5556 0 16.7743 0 18.2778C0 19.7812 1.21878 21 2.72222 21Z" fill="#9F8967" />
    <path d="M13.2222 2.72222C13.2222 4.22566 12.0034 5.44444 10.5 5.44444C8.99656 5.44444 7.77778 4.22566 7.77778 2.72222C7.77778 1.21878 8.99656 0 10.5 0C12.0034 0 13.2222 1.21878 13.2222 2.72222Z" fill="#9F8967" />
    <path d="M10.5 13.2222C12.0034 13.2222 13.2222 12.0034 13.2222 10.5C13.2222 8.99656 12.0034 7.77778 10.5 7.77778C8.99656 7.77778 7.77778 8.99656 7.77778 10.5C7.77778 12.0034 8.99656 13.2222 10.5 13.2222Z" fill="#9F8967" />
    <path d="M13.2222 18.2778C13.2222 19.7812 12.0034 21 10.5 21C8.99656 21 7.77778 19.7812 7.77778 18.2778C7.77778 16.7743 8.99656 15.5556 10.5 15.5556C12.0034 15.5556 13.2222 16.7743 13.2222 18.2778Z" fill="#9F8967" />
    <path d="M18.2778 5.44444C19.7812 5.44444 21 4.22566 21 2.72222C21 1.21878 19.7812 0 18.2778 0C16.7743 0 15.5556 1.21878 15.5556 2.72222C15.5556 4.22566 16.7743 5.44444 18.2778 5.44444Z" fill="#9F8967" />
    <path d="M21 10.5C21 12.0034 19.7812 13.2222 18.2778 13.2222C16.7743 13.2222 15.5556 12.0034 15.5556 10.5C15.5556 8.99656 16.7743 7.77778 18.2778 7.77778C19.7812 7.77778 21 8.99656 21 10.5Z" fill="#9F8967" />
    <path d="M18.2778 21C19.7812 21 21 19.7812 21 18.2778C21 16.7743 19.7812 15.5556 18.2778 15.5556C16.7743 15.5556 15.5556 16.7743 15.5556 18.2778C15.5556 19.7812 16.7743 21 18.2778 21Z" fill="#9F8967" />
  </svg>,
};

export function RoleBadge2(props) {
  const { role, hideIcon, isLabel, noTooltip } = props;
  const content = <BuildRoleDescription role={role} />;

  return <TooltipContext.Consumer>
    {(context) => {
      let events = {};
      if (!noTooltip) {
        events = figmaTooltipEvents(context, content, 'role');
      }
      return <div className={(isLabel ? 'figma-labelbadge-role ' : 'figma-badge2-role ') + role} {...events}>
        {isLabel ?
          <>
            {hideIcon ? null : roleIcons[role]}
            <span className={"figma-labelbadge-role-label" + (!noTooltip ? " figma-tooltip-marker" : '')}>{L('loc_ui_string_common_role_' + role)}</span>
          </>
          :
          <>
            {hideIcon ? null : roleIcons[role]}
            {<span className={"figma-badge2-role-label" + (!noTooltip ? " figma-tooltip-marker" : '')}>{L('loc_ui_string_common_role_' + role)}</span>}
          </>
        }
      </div>;
    }}
  </TooltipContext.Consumer>;
}

export function BuildRoleDescription(props) {
  const { role } = props;
  const roleData = rolesBykey(role);

  const weaponAptitudes = [];
  for (const key of ['SG', 'SMG', 'HG', 'AR', 'DMR']) {
    const weaponAptitude = [];
    const value = roleData.firearmAptitudes['firearmAptitude_' + key];
    weaponAptitude.push(<div className="figmalist-body-detail-box-firearmaptitude-ty">
      <span className="figmalist-body-detail-box-firearmaptitude-ty-label">{key}</span>
    </div>);
    weaponAptitude.push(<div className="figma-role-badge-tooltip-aptitude-icon">
      <GetWeaponAptitudeIcon value={value} />
    </div>);
    weaponAptitudes.push(<div className="figma-role-badge-tooltip-firearmaptitude">
      {weaponAptitude}
    </div>);
  }
  const positionAptitudes = [];
  for (const key of [0, 1, 2, 3]) {
    const positionAptitude = [];
    const value = roleData.descPositions[key];
    positionAptitude.push(<SvgBadgePosition value={key + 1} />);
    positionAptitude.push(<GetPositionAptitudeIcon value={value} />);
    positionAptitudes.push(<div className="figma-role-badge-tooltip-aptitude">
      {positionAptitude}
    </div>);
  }

  return <>
    <div className='figma-role-badge-tooltip-descr'>
      {L('loc_ui_longtext_common_role_' + roleData.key + '_descr')}
    </div>
    <div className='figma-role-badge-tooltip-info-group'>
      <div className='figma-role-badge-tooltip-info'>
        <div className='figma-role-badge-tooltip-info-label'>{L('loc_ui_string_role_tooltip_firearm_aptitudes')}</div>
        <div className='figma-role-badge-tooltip-info-value'>{weaponAptitudes}</div>
      </div>
      <div className='figma-role-badge-tooltip-info'>
        <div className='figma-role-badge-tooltip-info-label'>{L('loc_ui_string_role_tooltip_position_aptitudes')}</div>
        <div className='figma-role-badge-tooltip-info-value'>{positionAptitudes}</div>
      </div>
    </div>
  </>;
}

export function SvgBadgePosition(props) {
  const { value, overrideWidth = 22, overrideHeight = 28 } = props;
  return <svg xmlns="http://www.w3.org/2000/svg" width={overrideWidth} height={overrideHeight} viewBox="0 0 22 28" fill="none">
    <path d="M11 26.2285L1.5 21.6851V2.13043H20.5V21.6851L11 26.2285Z" fill="#AC9C71" stroke="#C9B787" stroke-width="3" />
    {value === 1 && <path d="M9.17076 10.4922H7.67859V8.59826L9.9025 6.37434H12.4564V15.6287H13.6616V18.3261H7.67859V15.6287H9.17076V10.4922Z" fill="#372923" />}
    {value === 2 && <path d="M11.0843 15.227H14.8721V18.3261H6.26343V15.5426L11.5865 10.2196V9.44478L11.1991 9.05739H9.93647L9.54908 9.44478V10.65H6.26343V8.28261L8.17169 6.36H12.9639L14.8721 8.28261V11.4391L11.0843 15.227Z" fill="#372923" />}
    {value === 3 && <path d="M9.95645 11.3352H11.4486L11.8217 10.9622V9.81435L11.4486 9.42696H9.69819L9.3108 9.81435V10.6322H6.02515V8.65218L7.93341 6.72957H13.1991L15.1073 8.65218V12.1961L14.6051 12.7126L15.1073 13.2291V16.7874L13.1991 18.6957H7.93341L6.02515 16.7874V15.037H9.3108V15.6109L9.69819 15.9983H11.4486L11.8217 15.6252V14.4774L11.4486 14.09H9.95645V11.3352Z" fill="#372923" />}
    {value === 4 && <path d="M14.5375 18.5809H10.061V16.1274H10.7784V14.9939H6.27319V11.6509L11.0797 6.85869H13.8201V12.5404H15.0684V14.9939H13.8201V16.1274H14.5375V18.5809ZM10.6493 10.6178L8.8558 12.4113H10.6493V10.6178Z" fill="#372923" />}
  </svg>;
}

const ICON_RECOMMENDED = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M18.0707 5.09369L21.1752 7.35721C22.5715 8.37523 23.1562 10.1748 22.625 11.819L21.4418 15.4814L20.2484 19.1335C19.7117 20.776 18.1809 21.8882 16.4529 21.8911L12.6041 21.8975L8.76205 21.8911C7.03408 21.8882 5.50331 20.776 4.96659 19.1335L3.77113 15.4751L2.58998 11.819C2.05875 10.1748 2.64345 8.37523 4.03971 7.35721L7.14966 5.08975L10.2618 2.83663C11.6614 1.8233 13.5535 1.82329 14.9532 2.83663L18.0707 5.09369Z" fill="#ED8A2D" />
  <path d="M11.4346 4.45663C12.1344 3.94996 13.0805 3.94996 13.7803 4.45663L16.8924 6.70976L19.997 8.97328C20.6951 9.48228 20.9874 10.382 20.7218 11.2042L19.5407 14.8602L18.3473 18.5123C18.0789 19.3335 17.3135 19.8896 16.4496 19.8911L12.6075 19.8975L8.76538 19.8911C7.9014 19.8896 7.13601 19.3335 6.86765 18.5123L5.67427 14.8602L4.49311 11.2042C4.2275 10.382 4.51985 9.48229 5.21798 8.97328L8.32252 6.70976L11.4346 4.45663Z" fill="#B26A26" />
  <path d="M12.1458 5.71745C12.3166 5.3068 12.8983 5.3068 13.0691 5.71745L14.6062 9.41304C14.6782 9.58616 14.841 9.70444 15.0279 9.71943L19.0176 10.0393C19.4609 10.0748 19.6407 10.6281 19.3029 10.9174L16.2632 13.5213C16.1208 13.6432 16.0586 13.8346 16.1021 14.017L17.0308 17.9103C17.134 18.3429 16.6633 18.6848 16.2838 18.453L12.868 16.3667C12.708 16.2689 12.5068 16.2689 12.3468 16.3667L8.93105 18.453C8.5515 18.6848 8.08088 18.3429 8.18407 17.9103L9.11276 14.017C9.15626 13.8346 9.09408 13.6432 8.95168 13.5213L5.91195 10.9174C5.57418 10.6281 5.75394 10.0748 6.19727 10.0393L10.187 9.71943C10.3739 9.70444 10.5367 9.58616 10.6087 9.41304L12.1458 5.71745Z" fill="#FFF7AC" />
</svg>;

const ICON_OK = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M13.5 18.5C16.8137 18.5 19.5 15.8137 19.5 12.5C19.5 9.18629 16.8137 6.5 13.5 6.5C10.1863 6.5 7.5 9.18629 7.5 12.5C7.5 15.8137 10.1863 18.5 13.5 18.5ZM13.5 21C18.1944 21 22 17.1944 22 12.5C22 7.80558 18.1944 4 13.5 4C8.80558 4 5 7.80558 5 12.5C5 17.1944 8.80558 21 13.5 21Z" fill="#71C507" />
</svg>;

const ICON_NOT_RECOMMENDED = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M12.7957 9L7.59957 18H17.9919L12.7957 9ZM13.6617 5.5C13.2768 4.83333 12.3146 4.83333 11.9297 5.5L4.13547 19C3.75057 19.6667 4.23169 20.5 5.00149 20.5H20.59C21.3598 20.5 21.8409 19.6667 21.456 19L13.6617 5.5Z" fill="#B2B1AD" />
</svg>;

const ICON_NOT_OK = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="white" />
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="white" />
  <path d="M6.36612 18.3869C5.87796 18.8751 5.87796 19.6665 6.36612 20.1547C6.85427 20.6429 7.64573 20.6429 8.13388 20.1547L13.25 15.0386L18.3869 20.1755C18.8751 20.6637 19.6665 20.6637 20.1547 20.1755C20.6429 19.6874 20.6429 18.8959 20.1547 18.4078L15.0178 13.2708L20.1547 8.13388C20.6429 7.64573 20.6429 6.85427 20.1547 6.36612C19.6665 5.87796 18.8751 5.87796 18.3869 6.36612L13.25 11.503L8.13389 6.38694C7.64573 5.89878 6.85428 5.89878 6.36612 6.38694C5.87797 6.87509 5.87797 7.66655 6.36612 8.1547L11.4822 13.2708L6.36612 18.3869Z" fill="#FE4040" />
</svg>;


export function GetWeaponAptitudeIcon(props) {
  const { value } = props;
  if (value <= 2) {
    return ICON_RECOMMENDED;
  } else if (value <= 3) {
    return ICON_OK;
  } else if (value <= 4) {
    return ICON_NOT_RECOMMENDED;
  } else {
    return ICON_NOT_OK;
  }
}

function GetPositionAptitudeIcon(value) {
  if (value === 1) {
    return ICON_RECOMMENDED;
  } else if (value === 2) {
    return ICON_OK;
  } else {
    return ICON_NOT_RECOMMENDED;
  }
}

export function evalCSSClass(ty, val) {
  switch (ty) {
    case 'status':
      if (parseInt(val) >= 12) {
        return '';
      } else if (parseInt(val) < 5) {
        return ' positive';
      } else if (parseInt(val) > 6) {
        return ' negative';
      }
      return '';
      break;
    case 'integrity':
      if (val <= 40) {
        return ' negative';
      } else if (val > 60) {
        return ' positive';
      }
      break;
    case 'condition':
      if (val < 40) {
        return ' negative';
      } else if (val >= 60) {
        return ' positive';
      }
      break;
    case 'mood':
      if (val <= 40) {
        return ' negative';
      } else if (val >= 60) {
        return ' positive';
      }
      break;
    case 'relation':
      if (val <= -50) {
        return ' negative';
      } else if (val > 50) {
        return ' positive';
      }
      break;
    case 'trust':
      if (val < 40) {
        return ' negative';
      } else if (val >= 60) {
        return ' positive';
      }
      break;
    // intermission
    case 'result':
      if (val === 'fine') {
        return ' positive';
      } else if (val === 'hurt') {
        return ' negative';
      }
      break;
    case 'value':
      if (val > 0) {
        return ' value positive';
      } else if (val < 0) {
        return ' value negative';
      }
      break;
    case 'modifier':
      if (['mod_agent_proud'].includes(val)) {
        return ' positive';
      } else if (['mod_agent_trauma', 'mod_agent_scar', 'mod_agent_disbelief', 'mod_agent_pess', 'mod_agent_cynical', 'mod_agent_lazy'].includes(val)) {
        return ' negative';
      }
      break;
    default:
      break;
  }
  return '';
}


