import source from '../downloaded/eventTypeProb.json' assert { type: 'json' };

const list = [];
for (const row of source) {
  let { grade, min, max, pos, pfeint, neutral, nfeint, neg } = row;
  list.push({
    grade,
    min: parseInt(min),
    max: parseInt(max),
    pos: parseInt(pos),
    pfeint: parseInt(pfeint),
    neutral: parseInt(neutral),
    nfeint: parseInt(nfeint),
    neg: parseInt(neg),
  });
}

export function getEventTypeProbData(score) {
  const data = list.find((e) => e.min <= score && e.max >= score);
  return data ?? list[0];
}
