import React from 'react';
import { STAT_DESCR, FIREARM_CATS } from '../stat';

const day = 28;
const sx = { margin: "0.5em" };

function PerkIcon(props) {
  const { perk, ch, onPerkUnselect } = props;
  const { descr, icon, name } = perk;
  const available = (perk.available_firearm_ty.length === 0 || perk.available_firearm_ty.includes(ch.weapon.firearm_ty));

  return (
    <div title={descr}>
      {available ?
        <img src={icon} alt="O" /> :
        <img src={icon} alt="X" onClick={() => onPerkUnselect(ch, perk)} />
      }{name}
    </div>);
}

function AgentStatsView(props) {
  const { ch, select } = props;
  const { character } = ch;
  const { name, cost, score, typeBestFirearm, scoreBestFirearm } = character;

  let perkDays = 0;
  for (const perk of ch.perks) {
    perkDays += perk.term;
  }

  const selecttd = () => {
    if (select === "aver") {
      return (
        <>
          <td>
            {score}
          </td>

          <td>
            {typeBestFirearm}
          </td>

          <td>
            {scoreBestFirearm}
          </td>
        </>
      )
    }
    if (select === "general") {
      return (
        <>
          {Object.keys(STAT_DESCR).map((key) => {
            return (
              <td key={key}>
                {ch.character.stats[key]}
              </td>
            )
          })}
        </>
      )
    }
    return (
      <>
        {Object.keys(FIREARM_CATS).map((cat, i) => {
          return (
            <td key={i}>
              {ch.character.stats.stat_firearm_level[i]}
            </td>
          )
        })}
      </>
    )
  }

  return <>
    <td>
      {name}
    </td>
    {selecttd()}
    <td>
      {cost} BTC
    </td>

    <td>
      {ch.hired ?
        <>
          <div>
            기간: {perkDays} / {day}
          </div>
          <div>
            추가비용: {ch.extraCost} BTC
          </div>
          <div>
            무기 : {ch.weapon.firearm_name} ({ch.weapon.firearm_ty}, {ch.weapon.firearm_rate}★)
          </div>
          <div>
            장비 : {ch.equipment.vest_name}
          </div>
        </> :
        <></>
      }
    </td>

  </>;
}


export function AgentCard(props) {

  const { ch, onHire, onFire, hired, available, onPerkUnselect, onOpen, select } = props;
  const { perks } = ch;
  const { cost } = ch.character;

  function renderButton() {
    if (hired) {
      return <button style={sx} onClick={() => onFire(ch)}>고용됨</button>;
    }

    if (!available) {
      return <button style={sx}>자원 부족</button>;
    }
    return <button style={sx} onClick={() => onHire(ch)}>고용하기: {cost}BTC</button>
  }

  function getPortraitPath(id) {
    try {
      return require(`../data/portrait/${id}.jpg`);
    } catch (err) {
      return require(`../data/portrait/hui.jpeg`);
    }
  }
  return <tr>
    <td>
      <img src={getPortraitPath(ch.no)} alt="portrait" width={30} />
    </td>
    <AgentStatsView ch={ch} select={select} />

    <td>
      <ul style={{ width: 200 }}>
        <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          {perks.map((p, i) => <PerkIcon onPerkUnselect={onPerkUnselect} perk={p} ch={ch} key={i} />)}
        </li>
      </ul>
    </td>

    <td>
      <button style={sx} onClick={() => onOpen(ch, true)} >상세 정보</button>
      {renderButton()}
    </td>
  </tr>
}

