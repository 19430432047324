import React, { createRef, useState, useEffect } from 'react';
import './BriefingView.css';

const BRIEFING_BACKGROUND = '/img/briefing/BGI_Briefing.png';
const LIGHT_OVERLAY_A = '/img/briefing/EFX_Top_Light_A_Overlay.png';
const LIGHT_OVERLAY_B = '/img/briefing/EFX_Top_Light_B_Overlay.png';
const TURN_ON_LIGHT = '/img/briefing/Turn On Light.png';

export class BriefingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesLoaded: 0,
      phase: 'loading',
      idx: 0,
      text: '',
      curImgSrc: '',
      images: {},
      isTypingDone: false,
    };

    this.containerRef = createRef();
    this.backgroundImageRef = createRef();
    this.shadeRef = createRef();
    this.textShadeRef = createRef();

    this.keyDown = this.onKeyDown.bind(this);
    this.clicked = this.onclick.bind(this);

  }

  componentDidMount() {
    this.setBrief();
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('click', this.clicked);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.imagesLoaded !== prevState.imagesLoaded) {
      this.checkLoading();
    }

    if (this.state.phase !== prevState.phase) {
      this.handlePhaseChange();
    }

    if (this.state.idx !== prevState.idx) {
      this.setBrief();
    }
    if (this.state.imgSrc !== prevState.imgSrc) {
      this.setState({ prevImgSrc: prevState.imgSrc });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('click', this.clicked);
  }


  onKeyDown(_ev) {
    if (_ev.key === 'Escape' || _ev.keyCode === 27) {
      this.setState({ phase: 'ending' });
    }
    this.handleClick();
  }

  onclick(_ev) {
    this.handleClick();
  }

  handleImageLoad() {
    this.setState((prevState) => ({
      imagesLoaded: prevState.imagesLoaded + 1,
    }));
  };

  checkLoading() {
    const { imagesLoaded } = this.state;
    if (imagesLoaded === 5) {
      this.setState({ phase: 'opening' });
    }
  }

  handlePhaseChange() {
    const { phase } = this.state;

    switch (phase) {
      case 'opening':
        this.adjustContainer(0, 1.2);
        setTimeout(() => {
          this.adjustContainer(1, 1, 2);
          setTimeout(() => {
            this.setState({ phase: 'waiting' });
          }, 2500);
        }, 1);
        break;
      case 'waiting':
        this.turnOnShade();
        setTimeout(() => {
          this.setState({ phase: 'briefing' });
        }, 1000);
        break;
      case 'ending':
        this.adjustContainer(0, 1.2, 1);
        setTimeout(() => {
          this.props.onDone();
        }, 1100);
        break;
      default:
        break;
    }
  }

  setBrief() {
    const { idx, curImgSrc } = this.state;
    const { data } = this.props;

    const hist = data.slice(0, idx + 1).reverse();
    const text = hist.find((item) => item.text)?.text ?? '';

    let text_end = '';
    if (idx === data.length - 1) {
      text_end = ' [END]';
    }
    const imgSrc = hist.find((item) => item.img_src)?.img_src ?? '';

    if (imgSrc && imgSrc !== curImgSrc) {
      this.addImage(imgSrc);
      this.popImage(curImgSrc);
      this.setState({ curImgSrc: imgSrc });
    }

    this.setState({ text: text + text_end, isTypingDone: false });
  }

  adjustContainer(opacity, manualScale, duration) {
    const container = this.containerRef.current;
    const backgroundImage = this.backgroundImageRef.current;

    const containerWidth = container.clientWidth;
    const imageWidth = backgroundImage.naturalWidth;
    const scale = (containerWidth / imageWidth) * manualScale;

    container.style.opacity = opacity;
    container.style.transform = `scale(${scale})`;

    if (duration) {
      container.style.transition = `opacity ${duration}s ease-in-out, transform ${duration}s ease-in-out`;
    }
  }

  addImage(newSrc) {
    this.setState((prevState) => {
      const images = { ...prevState.images };
      images[newSrc] = <FloatingImage src={newSrc} style={initialStyle} />;
      setTimeout(() => {
        this.setState((prevState) => {
          const updatedImages = { ...prevState.images };
          updatedImages[newSrc] = <FloatingImage src={newSrc} style={arrivedStyle} />;
          return { images: updatedImages };
        });
      }, 100);
      return { images };
    });
  }

  popImage(prevSrc) {
    if (prevSrc) {
      this.setState((prevState) => {
        const images = { ...prevState.images };
        if (prevSrc in images) {
          images[prevSrc] = <FloatingImage src={prevSrc} style={leavingStyle} />;
          setTimeout(() => {
            this.setState((prevState) => {
              const updatedImages = { ...prevState.images };
              delete updatedImages[prevSrc];
              return { images: updatedImages };
            });
          }, 600);
        }
        return { images };
      });
    }
  }
  turnOnShade() {
    const backgroundShade = this.shadeRef.current;
    const textShade = this.textShadeRef.current;

    backgroundShade.style.opacity = 0.4;
    textShade.style.backgroundColor = 'rgba(0,0,0,0.5)';
    textShade.style.backdropFilter = 'blur(4px)';
  }

  handleClick() {
    const { phase, idx, isTypingDone } = this.state;
    if (phase !== 'briefing') {
      return;
    }
    if (isTypingDone) {
      if (idx === this.props.data.length - 1) {
        this.setState({ phase: 'ending' });
      } else {
        this.setState({ idx: Math.min(idx + 1, this.props.data.length - 1) });
      }
    } else {
      this.setState({ isTypingDone: true });
    }
  };


  render() {
    const { phase, text, images, isTypingDone } = this.state;
    const { contentImage } = this.props;
    return (
      <div className="briefing-root">
        <div className='briefing-letterbox-upper' />
        <div className="briefing-image-container" ref={this.containerRef}>
          <img
            className="briefing-image-background"
            src={BRIEFING_BACKGROUND}
            ref={this.backgroundImageRef}
            onLoad={this.handleImageLoad.bind(this)}
          />
          <img
            className={`briefing-image-overlay-B${phase !== 'loading' ? ' twinkle' : ''}`}
            src={LIGHT_OVERLAY_B}
            onLoad={this.handleImageLoad.bind(this)}
          />
          <img
            className={`briefing-image-overlay-A${phase !== 'loading' ? ' twinkle' : ''}`}
            src={LIGHT_OVERLAY_A}
            onLoad={this.handleImageLoad.bind(this)}
          />
          <img
            className={`briefing-image-light${phase !== 'loading' ? ' twinkle' : ''}`}
            src={TURN_ON_LIGHT}
            onLoad={this.handleImageLoad.bind(this)}
          />
          <img className='briefing-image-contents'
            src={contentImage ? contentImage : ''}
            alt='NEED IMAGE'
            onLoad={this.handleImageLoad.bind(this)}
            onError={() => {
              console.log('콘텐츠 이미지 추가 필요');
              this.handleImageLoad();
            }}
          />
          <div className="briefing-background-shade" ref={this.shadeRef} />
        </div>
        <div className="briefing-content">
          <div className="briefing-contents-lower">
            <div className="briefing-contents-lower-shade" ref={this.textShadeRef} />
            {phase === 'briefing' && (
              <>
                <TypingEffect
                  text={text}
                  speed={100}
                  onDone={() => this.setState({ isTypingDone: true })}
                  isTypingDone={isTypingDone} />
                {
                  Object.values(images)
                }
              </>
            )}
          </div>
        </div>
        <div className='briefing-letterbox-lower' />
      </div>
    );
  }
}

const initialStyle = {
  opacity: `0.5`,
  transform: 'translate(-40%, -87%) scale(0.6)',
  zIndex: 2,
}

const arrivedStyle = {
  transform: 'translate(-50%, -87%) scale(0.6)',
  opacity: `1`,
  zIndex: 1,
}

const leavingStyle = {
  transform: 'translate(-60%, -87%) scale(0.6)',
  opacity: `0`,
  zIndex: 0,
}

const FloatingImage = ({ src, style }) => {
  const [curStyle, setCurStyle] = useState(style);
  useEffect(() => { setCurStyle(style) }
    , [style])
  return <div>
    <img className='briefing-contents-image' src={src} style={curStyle} key={src} />
  </div>
}

const TypingEffect = ({ text, speed, onDone, isTypingDone }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    let index = 0;
    const id = setInterval(() => {
      setDisplayedText(text.slice(0, index + 1));
      index++;
      if (index === text.length) {
        onDone?.();
        clearInterval(id);
      }
    }, speed);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [text, speed, onDone]);

  useEffect(() => {
    if (isTypingDone) {
      setDisplayedText(text);
      clearInterval(intervalId);
    }
  }, [isTypingDone, text, intervalId]);

  return <div className="briefing-contents-text" >{displayedText}</div>;
};
