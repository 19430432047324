import { TICK_PER_DAY } from "./tick.mjs";
import { L } from './localization.mjs';

const start_items = [
];

export const OPTS_DEFAULT = {
  preset_squad: false,

  lobby: {
    mission: true,
    recruits: true,
    agents: true,
    squad: true,
    shop: true,
    inventory: true,
    call_superior: true,
    mission_objective: true,
    mission_final: true,
  },

  disable_button: {},

  // 아이템 판매 가능 여부
  allow_sell: true,

  allow_meeting_headhunter: true,
  allow_fire_agent: true,

  modifier_effects_denylist: {},

  exp_weekly_plan: true,
};

const CONFIG_SAWMILL_SABOTAGE = {
  key: 'sawmill1_sabotage',

  descr: `loc_data_longtext_missionbrief_title_0`,

  plans: new Array(4).fill(0).map((_, week) => {
    return {
      week,
      map: `sawmill1_sabotage1_w${week}`,
      map_plan: `sawmill1_sabotage1_w${week}`,
      map_drill: `sawmill1_sabotage1_w${week}_drill`,
      // TODO
      title: () => L('loc_ui_string_plan_week_title',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sawmill1_sabotage0_title') }),
      desc: () => L('loc_ui_string_plan_week_desc',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sawmill1_sabotage0_desc') }),
      imgSrc: '/plan/icon_plan_break.png',
    };
  }),

  unlocks: [],

  start_items,

  squad_size: 4,

  start_balance: 5000,
  income: 3000,

  reward: 10000,
  tier: 5,

  epoch_offset: 202464,
  deadline_weeks: 4,
  final_deadline_days: 10,

  opts: OPTS_DEFAULT,

  objective: {
    text: 'loc_data_longtext_missionbrief_objective_0',
    descr: 'loc_data_longtext_missionbrief_description_0',
  },

  milestones: [
    { key: 'obj_proto_check_pass_1', ty: 'main' },
    { key: 'obj_proto_average_power_12', ty: 'main' },
    { key: 'obj_proto_survival_70', ty: 'sub' },
    { key: 'obj_proto_agent_6', ty: 'sub' },
  ],

  pendings: [
    { ty: 'tutorial_schedule', turn: 3 * TICK_PER_DAY + 7 },
  ]
};

const CONFIG_SABOTAGE = {
  key: 'sabotage0',

  descr: `loc_data_longtext_missionbrief_title_1`,

  plans: new Array(6).fill(0).map((_, week) => {
    return [{
      week,
      map: `sabotage0_w${week}`,
      map_drill: `sabotage0_w${week}_drill`,
      title: () => L('loc_ui_string_plan_week_title',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sabotage0_title') }),
      desc: () => L('loc_ui_string_plan_week_desc',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sabotage0_desc') }),
      imgSrc: '/plan/icon_plan_break.png',
    },
    {
      week,
      map: `sabotage1_w${week}`,
      map_drill: `sabotage1_w${week}_drill`,
      title: () => L('loc_ui_string_plan_week_title',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sabotage1_title') }),
      desc: () => L('loc_ui_string_plan_week_desc',
        { week: week + 1, text: L('loc_data_longtext_mission_plan_sabotage1_desc') }),
      imgSrc: '/plan/icon_plan_search.png',
    },
    ];
  }).flat(),

  unlocks: [],

  start_items,

  squad_size: 4,

  start_balance: 0,
  income: 2400,

  reward: 10000,
  tier: 5,

  epoch_offset: 203472,
  deadline_weeks: 8,
  final_deadline_days: 10,

  opts: OPTS_DEFAULT,

  stop_game_after_final: true,

  objective: {
    text: 'loc_data_longtext_missionbrief_objective_1',
    descr: 'loc_data_longtext_missionbrief_description_1',
  },

  milestones: [
    { key: 'obj_proto_check_pass_3', ty: 'main' },
    { key: 'obj_proto_average_power_25', ty: 'main' },
    { key: 'obj_proto_survival_70', ty: 'sub' },
    { key: 'obj_proto_agent_6', ty: 'sub' },
  ],
  pendings: [],
};

export const CONFIGS = [
  CONFIG_SABOTAGE,
  CONFIG_SAWMILL_SABOTAGE,

  {
    ...CONFIG_SAWMILL_SABOTAGE,
    key: 'sawmill1_sabotage1',
    resource_key: 'sawmill1_sabotage',

    start_balance: 6000,
    income: 3000,
  },

  {
    ...CONFIG_SABOTAGE,
    key: 'sabotage1',
    resource_key: 'sabotage0',

    start_balance: 6000,
    income: 3000,


  },

  {
    ...CONFIG_SAWMILL_SABOTAGE,
    key: 'sawmill1_sabotage0',

    start_balance: 1000,
    income: 2400,

    start_items: [],

    opts: {
      ...OPTS_DEFAULT,

      // config_key_own을 스쿼드에 넣은 상태로 시작합니다.
      'preset_squad': true,

      lobby: {
        ...OPTS_DEFAULT.lobby,
        recruits: false,
        agents: false,
        squad: false,
        shop: false,
        inventory: false,
        call_superior: false,
      },

      disable_button: {
        ...OPTS_DEFAULT.disable_button,
      },

      allow_sell: false,

      allow_meeting_headhunter: false,
      allow_fire_agent: true,

      modifier_effects_denylist: {
        block_mission: false,
      },
    },

    pendings: [],
  },

  {
    ...CONFIG_SAWMILL_SABOTAGE,
    key: 'soundtest',

    start_items: [
      'firearm_ar_t5',
      'firearm_sg_t5',
      'firearm_dmr_t5',
      'firearm_smg_t5',
      'vest_bulletproof_aramid_t5',
      'vest_bulletproof_metal_t5',
      'vest_bulletproof_ceramic_t5',
      'vest_bulletproof_aramid_t1',
      'throwable_frag_t3',
      'throwable_impact_frag_t2',
      'throwable_he_t2',
      'throwable_stun_t3',
    ],

    start_balance: 1000000,

    opts: {
      ...OPTS_DEFAULT,
      'preset_squad': true,
    },

    pendings: [],
  },

  {
    ...CONFIG_SABOTAGE,
    key: 'sabotage1',

    start_balance: 5000,

    opts: {
      ...OPTS_DEFAULT,
      'preset_squad': true,
    },
  },
];
