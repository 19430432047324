import React, { createRef } from 'react';
import './FigmaPopupView.css';
import './FigmaListView.css';
import './FigmaLobbyView.css';
import { RenderAgentSimple, RenderAgentCardEvent } from './RenderAgentSimple.js';

import { Rng } from './rand.mjs';

import { names4 } from './names';
import { createCharacter2 } from './character2.mjs';
import { roles } from './data/google/processor/data_char2roles.mjs';
import { triggerSound, SoundButton, soundprops } from './sound.mjs';
import { outgameEventCues, NoSoundEventIDs } from './soundData.mjs';
import { TooltipContext, figmaTooltipEvents } from './FigmaTooltipView.js';
import { L } from './localization.mjs';

const decoClip = <svg className='figmapopup-deco-clip' xmlns="http://www.w3.org/2000/svg" width="39" height="75" viewBox="0 0 39 75" fill="none">
  <path opacity="0.4" d="M31.8251 1.48314C25.4569 -0.375583 18.8208 3.39613 17.0029 9.9075L2.20016 62.9295C0.795202 67.9619 3.64614 73.2061 8.56791 74.6426C13.4897 76.0792 18.6185 73.1641 20.0235 68.1317L32.7329 22.6077C33.7249 19.0543 31.7119 15.3513 28.2365 14.3369C24.7612 13.3226 21.1396 15.3809 20.1476 18.9344L11.6248 49.4622L13.7341 50.0779L22.2569 19.55C22.9164 17.1877 25.324 15.8193 27.6344 16.4937C29.9448 17.168 31.2831 19.6297 30.6236 21.9921L17.9141 67.5161C16.8417 71.3573 12.9269 73.5824 9.17003 72.4859C5.41321 71.3893 3.23707 67.3865 4.30948 63.5452L19.1122 10.5232C20.5975 5.20293 26.0197 2.12118 31.223 3.63988C32.7986 4.09979 34.1736 4.92884 35.2836 6.00903H38.1945C36.6756 3.89396 34.4838 2.25917 31.8251 1.48314Z" fill="#624D41" />
  <path d="M16.0029 8.9075C17.8208 2.39613 24.4569 -1.37558 30.8251 0.483139C33.8894 1.37753 36.3333 3.41264 37.8418 6.00903C36.6264 6.00903 36.6264 6.00903 35.1807 6.00903C33.954 4.44061 32.2555 3.23313 30.223 2.63988C25.0197 1.12118 19.5975 4.20293 18.1122 9.52316L3.30949 62.5452C2.23707 66.3865 4.41321 70.3893 8.17004 71.4859C11.9269 72.5824 15.8417 70.3573 16.9141 66.5161L29.6236 20.9921C30.2831 18.6297 28.9448 16.168 26.6344 15.4937C24.324 14.8193 21.9164 16.1877 21.2569 18.55L12.7341 49.0779L10.6248 48.4622L19.1476 17.9344C20.1396 14.3809 23.7612 12.3226 27.2365 13.3369C30.7119 14.3513 32.7249 18.0543 31.7329 21.6077L19.0235 67.1317C17.6185 72.1641 12.4897 75.0792 7.56791 73.6426C2.64614 72.2061 -0.204797 66.9619 1.20016 61.9295L16.0029 8.9075Z" fill="#BEB2AA" />
</svg>;

export class FigmaPopupRoot extends React.Component {
  constructor(props) {
    super(props);

    const rng = new Rng();

    const characters = new Array(5).fill(0).map(() => {
      const name = rng.choice(names4);
      const role = rng.weighted_key(roles, "weight");
      return createCharacter2({ rng, name, role });
    });
    this.tooltipRef = React.createRef();
    this.state = {
      rng,
      characters,
      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },
    };
  }

  simple() {
    // i18n ignore 12
    return <FigmaPopupView
      ty={'simple'}
      title={'알림'}
      message={'명단을 보내 두었다.\n제멋대로인 놈들이야. 네 명 정도 데리고 착수하면 될 것 같군.\n\n-T-'}
      choices={[]}
      buttons={[
        {
          label: '확인',
          onClick: null,
        }
      ]}
    />;
  }

  eventAgents() {
    // i18n ignore 17
    return <FigmaPopupView
      ty={'eventAgents'}
      title={'Gets stronger as pushing hard'}
      message={'스카우트가 수화기를 내려놓으며 당신을 반깁니다.\n\
      "무슨 일이죠, 보스? 지금 멤버들이 마음에 안 드나요? 누가 사고를 쳤나요? 제가 필요한가요?"\n\
      스카우트는 전세계의 온갖 용병, 군사기업, 브로커로 구성된 인맥으로,\n\
      채용할만한 용병을 탐색해 보고하는 역할을 합니다.'}
      choices={[]}
      buttons={[
        {
          label: '확인',
          onClick: null,
        }
      ]}
      agents={this.state.characters}
      OnClickBackgroundButton={() => { }}
    />;
  }

  eventCEO() {
    // i18n ignore 17
    return <FigmaPopupView
      ty={'eventAgents'}
      title={'Gets stronger as pushing hard'}
      message={'스카우트가 수화기를 내려놓으며 당신을 반깁니다.\n\
      "무슨 일이죠, 보스? 지금 멤버들이 마음에 안 드나요? 누가 사고를 쳤나요? 제가 필요한가요?"\n\
      스카우트는 전세계의 온갖 용병, 군사기업, 브로커로 구성된 인맥으로,\n\
      채용할만한 용병을 탐색해 보고하는 역할을 합니다.'}
      choices={[]}
      buttons={[
        {
          label: '확인',
          onClick: null,
        }
      ]}
      agents={this.state.characters}
      OnClickBackgroundButton={() => { }}
    />;
  }

  render() {
    return <TooltipContext.Provider value={{
      ctx: this.state.tooltipContext,
      setCtx: (ctx) => {
        this.setState({ tooltipContext: ctx });
      }
    }}>
      <div className='figmapopup-testroot'>
        {this.simple()}
      </div>
    </TooltipContext.Provider>
  }
}

const photoCards = {
  'simulation': '/event_photo/popup_msg_from_control.png',
  'train_cheer': '/event_photo/event_photo_cheer.png',
  'train_down': '/event_photo/event_photo_down.png',
  'train_sick': '/event_photo/event_photo_sick.png',
  'train_unrule': '/event_photo/event_photo_unrule.png',
  'talk_punish': '/event_photo/event_photo_punish.png',
  'train_fight': '/event_photo/event_photo_fight.png',
  'train_rule': '/event_photo/event_photo_rule.png',
  'rest_unrule': '/event_photo/event_photo_unrule.png',
  'rest_sick': '/event_photo/event_photo_sick.png',
  'rest_cheer': '/event_photo/event_photo_cheer.png',
  'rest_down': '/event_photo/event_photo_down.png',
  'talk_nice': '/event_photo/event_photo_talk.png',
  'talk_sad': '/event_photo/event_photo_talk.png',
  'talk_argue': '/event_photo/event_photo_talk.png',
  'train_rain': '/event_photo/event_photo_rain.png',
  'meet_dealer': '/event_photo/event_photo_meet.png',
  'talk_team': '/event_photo/event_photo_talk.png',
  'meet_scout': '/event_photo/event_photo_meet.png',
  'rest_rule': '/event_photo/event_photo_rule.png',
  'control': '/event_photo/popup_msg_from_control.png',
  'rest_sport': '/event_photo/event_photo_sports.png',
  'demo_notification': '/event_photo/popup_msg_from_devs.png',
  'tutorial': null,
}

function CanTriggerSound(eventID) {
  return eventID != null ? !NoSoundEventIDs.includes(eventID) : false;
}

export function FigmaPopupChoice(props) {
  const { order, className, disabled, cueList, title, onClick, header, body, } = props;
  let cls = "grind-event-button";
  if (className) {
    cls += ` ${className}`;
  }
  return <TooltipContext.Consumer>
    {context => (
      <div className={'grind-event-button-base' + (disabled ? ' disabled' : '')}
        key={order}
        disabled={disabled}
        /*cueList={cueList}*/
        onClick={(e) => {
          !disabled && onClick(order);
        }}
        {...(title?.trim() ? figmaTooltipEvents(context, title) : null)}>
        <div className='grind-event-button-background' />
        <div {...soundprops(null)} className={cls}
        >
          {header ? <div className="grind-event-button-label-header">{header}</div> : null}
          <div className="grind-event-button-label-body">{body}</div>
        </div>
      </div>)}
  </TooltipContext.Consumer >;
}

export function FigmaGeneralPopupView(props) {
  const { title, buttons, cls, photoCard, children } = props;

  return <div className={cls}>
    <div className='figmapopup-deco-paper' />
    <div className='figmapopup-popup-content'>
      <div className='figmapopup-title'>
        <div className='figmapopup-title-underline' />
        <div className='figmapopup-title-text'>
          {title}
        </div>
      </div>
      <div className='figmapopup-body-frame'>
        <div className='figmapopup-body-glow-mask' />
        <div className='figmapopup-body-glow' />
        <div className='figmapopup-body-lines' />
        <div className='figmapopup-body-content'>
          {children}
        </div>
        {buttons && buttons.length > 0 ?
          <div className="figmapopup-general-buttongroup inline">
            {buttons.map((button, i) => {
              return <div {...soundprops(null)}
                key={i}
                className={"figmapopup-button" + (button.primary ? " primary" : "")}
                /*cueList={button.primary ? ['UI_Outgame_Button_Click_Yellow'] : null}*/
                onClick={button.onClick}>
                <div className="figmapopup-button-emboss" />
                <div className="figmapopup-button-background" />
                <div className="figmapopup-button-outline">
                  <span className="figmapopup-button-label">{button.label}</span>
                </div>
              </div>;
            })}
          </div>
          : null}
      </div>
      {decoClip}
    </div>
    {photoCard && <div className="figmapopup-photo">
      <img src={photoCard} onDragStart={(e) => e.preventDefault()} />
    </div>}
  </div >;
}

export class FigmaPopupView extends React.Component {
  constructor(props) {
    super(props);
    if (CanTriggerSound(props.eventID))
      triggerSound(outgameEventCues[props.scene] ?? null)
  }

  render() {
    const { ty, title, message, agents, choices, buttons, tags, scene, className, OnClickBackgroundButton } = this.props;
    let content = null;
    let photoCard = photoCards[scene] ?? null;
    let choiceButtons = null;

    switch (ty) {
      case 'system':
        content = <div className="figmapopup-content-event system">
          <div className="figmapopup-content-message system">
            {message}
          </div>
        </div>;
        break;
      case 'simple':
        content = <div className="figmapopup-content-event simple">
          <div className="figmapopup-content-message simple">
            {message}
          </div>
        </div>;
        break;
      case 'eventAgents':
        content = <div className="figmapopup-content-event">
          <div className="figmapopup-content-event-top">
            {agents?.length > 0 ?
              <div className="figmapopup-content-agents">{
                agents.filter((agent, i) => (i < 3))
                  .map((agent, i) => {
                    return <RenderAgentCardEvent key={i} i={i} agent={agent}
                      OnClickBackgroundButton={OnClickBackgroundButton} />;
                  })}</div>
              : null}
            <div className="figmapopup-content-message">{message}</div>
          </div>
        </div>;
        if (choices && choices.length > 0) {
          choiceButtons = <div className="figmapopup-content-event-choices">
            {choices.map((choice, i) => <FigmaPopupChoice order={i} {...choice} onClick={choice.onClick} />)}
          </div>;
        }
        break;
      case 'eventCEO':
        content = <div className="figmapopup-content-event">
          <div className="figmapopup-content-event-top">
            {agents?.length > 0 ?
              <div className="figmapopup-content-agents">{
                agents.filter((agent, i) => (i < 3))
                  .map((agent, i) => {
                    return <RenderAgentCardEvent key={i} i={i} agent={agent}
                      OnClickBackgroundButton={OnClickBackgroundButton} />;
                  })}</div>
              : null}
            <div className="figmapopup-content-message">{message}</div>
          </div>
        </div>;
        choiceButtons = <div className="figmapopup-content-event-choices">
          {choices.map((choice, i) => <FigmaPopupChoice order={i} {...choice} onClick={choice.onClick} />)}
        </div>;
        break;
      default:
        content = null;
        break;
    }

    let cls = 'figmapopup-root';
    if (photoCard) cls += ' with-image';
    if (className) cls += ' ' + className;
    return <div className={cls}>
      <div className="figmapopup-bg">
        <div className="figmapopup-bg-rect rect267" />
        <div className="figmapopup-bg-rect rect255" />
        <div className="figmapopup-bg-rect rect266" />
        <div className="figmapopup-bg-rect rect265" />
        <div className="figmapopup-bg-rect rect264" />
        <div className="figmapopup-bg-rect rect257" />
        <div className="figmapopup-bg-rect rect263" />
        <div className="figmapopup-bg-rect rect259" />
        <div className="figmapopup-bg-rect rect293" />
        <div className="figmapopup-bg-rect rect292" />
        <div className="figmapopup-bg-springs">
          {new Array(19).fill(0).map((_, i) => {
            return <div className="figmapopup-spring" key={i}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="52" viewBox="0 0 17 52" fill="none">
                <g filter="url(#filter0_i_1195_68981)">
                  <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 36)" fill="#665A4C" />
                </g>
                <circle cx="7.5" cy="7.5" r="7" transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 36)" stroke="#B7B9A1" />
                <rect width="43" height="9" rx="4.5" transform="matrix(-0.0986154 0.995126 0.995126 0.0986154 8.24023 4)" fill="#767D83" />
                <rect width="36" height="6" rx="3" transform="matrix(-0.0986154 0.995126 0.995126 0.0986154 9.13721 5.09375)" fill="#7F868B" />
                <rect width="5" height="4" rx="2" transform="matrix(-0.0986154 0.995126 0.995126 0.0986154 10.0337 6.18774)" fill="#90979C" />
                <defs>
                  <filter id="filter0_i_1195_68981" x="1" y="36" width="16.3" height="16.3" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="7" dy="7" />
                    <feGaussianBlur stdDeviation="0.65" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.222054 0 0 0 0 0.222054 0 0 0 0 0.222054 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1195_68981" />
                  </filter>
                </defs>
              </svg>
            </div>
          })}
        </div>
        {photoCard && <div className="figmapopup-photo">
          <img src={photoCard} onDragStart={(e) => e.preventDefault()} />
        </div>}
      </div>
      <div className="figmapopup-content">
        <div className="figmapopup-header">
          <div className="figmapopup-divider" />
          <span className="figmapopup-header-title">{title}</span>
          <div className="figmapopup-divider" />
        </div>

        <div className={`figmapopup-body ${ty}`}>
          {content}
          <div className="figmapopup-divider" />
          {choiceButtons}
        </div>

        {buttons && buttons.length > 0 ?
          <div className="figmapopup-buttongroup inline">
            {buttons.map((button, i) => {
              return <div {...soundprops(null)}
                key={i}
                className={"figmapopup-button" + (button.primary ? " primary" : "")}
                /*cueList={button.primary ? ['UI_Outgame_Button_Click_Yellow'] : null}*/
                onClick={button.onClick}>
                <div className="figmapopup-button-emboss" />
                <div className="figmapopup-button-background" />
                <div className="figmapopup-button-outline">
                  <span className="figmapopup-button-label">{button.label}</span>
                </div>
              </div>;
            })}
          </div>
          : null}
      </div>
    </div >
  }
}
