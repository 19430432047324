import React, { forwardRef } from 'react';
import './FigmaTooltipView.css';

const cur = {
  content: null,
  pos: null,
};

export const TooltipContext = React.createContext({
  cur,
  setCur: () => { },
});

const offset = 12;

const getZoom = () => {
  // TODO: 이러면 안됨
  let zoom = 1.0;
  let rootelem = document.getElementsByClassName('title-root0')[0];
  if (rootelem) {
    zoom = (+rootelem.style.zoom.replace('%', '')) / 100;
  }
  return zoom;
};
const getPos = (e) => {
  const zoom = getZoom();
  const x = e.clientX / zoom;
  const y = e.clientY / zoom;
  return { x, y };
};

export const figmaTooltipEvents = (context, content, ty) => {
  if (!context || !content) {
    return null;
  }

  const { ctx, setCtx } = context;
  if (!ctx || !setCtx) {
    return null;
  }
  return {
    onMouseLeave: (e) => {
      if (ctx.content === content) {
        setCtx({ ...ctx, content: null, ty: null });
      }
    },
    onMouseEnter: (e) => {
      setCtx({ ...ctx, content, ty, pos: getPos(e), target: e.target });
    },

    onMouseMove: (e) => {
      e.preventDefault();
      e.stopPropagation();

      e._tooltipmove = true;
      const { x, y } = getPos(e);
      const elem = ctx.ref.current;
      if (elem) {
        const { width, height } = elem.getBoundingClientRect();
        elem.style = `top: min(calc(100% - ${height}px), ${y + offset}px); left: min(calc(100% - ${width}px), ${x + offset}px);`;
      }

      if (ctx.content === null) {
        // trigger re-render
        setCtx({ ...ctx, content, ty, pos: getPos(e), target: e.target });
      } else {
        ctx.content = content;
        ctx.ty = ty;
      }
    },
  };
};

export const FigmaTooltipView = forwardRef((props, ref) => {
  const { content, pos, className } = props;
  if (!content) {
    return null;
  }

  const style = {};
  if (pos) {
    style.top = `${pos.y + offset}px`;
    style.left = `min(calc(100% - 390px), ${pos.x + offset}px)`;
  }

  let cls = 'figma-tooltip';
  if (className) {
    cls += ' ' + className;
  }

  return <div
    className={cls}
    style={style}
    ref={ref}
  >
    <div className="figma-tooltip-shadow" />
    <div className="figma-tooltip-body">
      <div className="figma-tooltip-body-header" />
      {content}
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
      <path d="M0 0.0323486L10 10.0323H0V0.0323486Z" fill="#A6551B" />
    </svg>
  </div>;
});

export const FigmaRoleBadgeTooltipView = forwardRef((props, ref) => {
  const { content, pos } = props;

  const style = {};
  if (pos) {
    style.top = (pos.y + offset) + 'px';
    style.left = 'min(calc(100% - 390px), ' + (pos.x + offset) + 'px';
  }

  return <div
    className="figma-tooltip figma-role-badge-tooltip"
    style={style}
    ref={ref}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M0 15V0H15L0 15Z" fill="#BEFFFB" />
    </svg>
    {content}
  </div>;

});

export const renderTooltip = (ref) => {
  return <TooltipContext.Consumer>
    {({ ctx }) => {
      if (!ctx) {
        return null;
      }
      const { content, ty, pos } = ctx;
      if (content === null) {
        return null;
      }

      if (ty === "role") {
        return <FigmaRoleBadgeTooltipView pos={pos} content={content} ref={ref} />;
      } else if (ty === "agent") {
        return <FigmaTooltipView className="agent" pos={pos} content={content} ref={ref} />;
      } else if (ty === "modifier") {
        return <FigmaTooltipView className="modifier" pos={pos} content={content} ref={ref} />;
      }

      return <FigmaTooltipView pos={pos} content={content} ref={ref} />;
    }}
  </TooltipContext.Consumer>;
};
