/* eslint-disable */
import { opts, ENTITY_CONFIG_TMPL } from './opts.mjs';
import { v2 } from './v2.mjs';
import { STATS_TMPL, STATS_TMPL_MAX, team0_STATS_TMPL, team1_STATS_TMPL, entityFromStat, stats_const } from './stat.mjs';
import { entityFromStat2, STATS2_TMPL, stats2_const, entityFromStat2WithBonus } from './stats2.mjs';
import { entityFromStat3, STATS3_TMPL, stats3_const } from './stats3.mjs';
import { agents } from './agents.mjs';
import * as presets_mission from './presets_mission.mjs';
import { preset_maze } from './randomPreset2.mjs'
import { utility_healthpack_big, utility_healthpack_small } from './presets_utilities.mjs';

import DOOR_TEST_LEVEL from './maps/map_door_testing.json' assert { type: 'json' };

const { markLeader } = presets_mission;

import {
  team0_tmpl_agent_dmr_mid,
  team0_tmpl_indoor_ar_high,
  team0_tmpl_agent_sg_high,
  team0_tmpl_agent_ar_low,
  team0_tmpl_agent_ar_mid,
  tmpl_bulletproof_low,
  team0_tmpl_agent_ar_high,

  enemy_tmpl_agent_ar_low,
} from './presets.mjs';

import * as presets_firearm from './presets_firearm.mjs';
const {
  tmpl_firearm_smg_t1,
  tmpl_firearm_smg_t2,
  tmpl_firearm_smg_t3,
  tmpl_firearm_smg_t4,
  tmpl_firearm_smg_t5,
  tmpl_firearm_ar_t1,
  tmpl_firearm_ar_t2,
  tmpl_firearm_ar_t3,
  tmpl_firearm_ar_t4,
  tmpl_firearm_ar_t5,
  tmpl_firearm_hg_t5,
  tmpl_firearm_hg_t5_with_shield,
  tmpl_firearm_dmr_t1,
  tmpl_firearm_dmr_t2,
  tmpl_firearm_dmr_t3,
  tmpl_firearm_dmr_t4,
  tmpl_firearm_dmr_t5,
  tmpl_firearm_sg_t1,
  tmpl_firearm_sg_t2,
  tmpl_firearm_sg_t3,
  tmpl_firearm_sg_t4,
  tmpl_firearm_sg_t5,
} = presets_firearm;

import {
  gear_nvd_t1,
  gear_nvd_t2,
  gear_nvd_t3,
  gear_nvd_t4,

  gear_vest_bulletproof_aramid_t5,
  gear_vest_bulletproof_aramid_t4,
  gear_vest_bulletproof_aramid_t3,
  gear_vest_bulletproof_aramid_t2,
  gear_vest_bulletproof_aramid_t1,

  gear_vest_bulletproof_metal_t5,
  gear_vest_bulletproof_metal_t4,
  gear_vest_bulletproof_metal_t3,
  gear_vest_bulletproof_metal_t2,
  gear_vest_bulletproof_metal_t1,

  gear_vest_bulletproof_ceramic_t5,
  gear_vest_bulletproof_ceramic_t4,
  gear_vest_bulletproof_ceramic_t3,
  gear_vest_bulletproof_ceramic_t2,
  gear_vest_bulletproof_ceramic_t1,
} from './presets_gear.mjs';

import {
  throwable_frag_t3,
  throwable_frag_t2,
  throwable_frag_t1,
  throwable_impact_frag_t2,
  throwable_impact_frag_t1,
  throwable_he_t2,
  throwable_he_t1,
  throwable_stun_t3,
  throwable_stun_t2,
  throwable_stun_t1,
  throwable_smoke,

  throwable_breach_charge,

  throwable_frag_demo,
  throwable_frag_mortar,
  throwable_frag_claymore,
} from './presets_throwables.mjs';

import {
  perk2_common_physical_regist,
  perk2_common_physical_healthy_body,
  perk2_common_physical_power_overwhelming,
  perk2_common_vision_intuition,
  perk2_common_vision_effective_cover,
  perk2_common_vision_orbital_mechanics,
  perk2_common_mental_survival_instinct,
  perk2_common_mental_calmstate,
  perk2_common_mental_kill_or_be_killed,
  perk2_common_intelligence_piecut,
  perk2_common_intelligence_dynamic_entry,
  perk2_common_intelligence_grenade_cooking,
  perk2_common_shooting_quickdraw,
  perk2_common_shooting_precision_shooting,
  perk2_pointman_manoeuvre_shooting,
  perk2_pointman_multitarget_engagement,
  perk2_pointman_spearhead,
  perk2_breacher_efficient_explosive,
  perk2_breacher_quadloading,
  perk2_breacher_explosive_science,
  perk2_vanguard_physical_training,
  perk2_vanguard_mozambique_drill,
  perk2_vanguard_effective_armor,
  perk2_sharpshooter_snipe_mastery,
  perk2_sharpshooter_shoot_mastery,
  perk2_sharpshooter_aim_down_sights,
  perk2_medic_paramedics_elementary,
  perk2_medic_paramedics_intermediate,
  perk2_medic_paramedics_advanced,
  perk2_common_strategy_supress_decrease_avoid,
  perk2_common_strategy_aggressive_dmg,
  perk2_common_return_ammo_on_kill,
  perk2_common_jack_of_all,
  perk2_breacher_shield_disable,
  perk2_breacher_shield_regen,
  perk2_vanguard_shield_repair,
  perk2_sharpshooter_weapon_master,
  perk2_sharpshooter_headshot,
  perk2_sharpshooter_threat,
  perk2_medic_emergency_rescue,
  perk2_pointman_blood_rage,
  perk2_pointman_battle_excite,
  perk2_common_half_under_mag,
  perk2_common_increase_acc,
  perk2_common_half_over_mag,
  perk2_common_increase_avoid,
  perk2_common_increase_dmg,
  perk2_breacher_frontline,
  perk2_vanguard_muscle_bulletproof,
  perk2_vanguard_full_efficiency,
  perk2_vanguard_shield_mastery,
  perk2_sharpshooter_high_velocity,
  perk2_sharpshooter_ads_mastery,
  perk2_pointman_ar_specialization,
  perk2_pointman_smg_specialization,
  perk2_common_strategy_pair_increase_acc,
  perk2_common_increase_acc_per_mood,
  perk2_common_last_shot_crit,
  perk2_common_first_shot_crit,
  perk2_common_shield_breaker,
  perk2_breacher_shock_and_awe,
  perk2_sharpshooter_high_grain,
  perk2_medic_adrenaline,
  perk2_medic_stimpack,
} from './presets_perk2.mjs';
/* eslint-enable */

function presetInvul(tb) {
  return {
    ...tb,
    preset: () => {
      const preset = tb.preset();
      for (const entity of preset.entities) {
        if (entity.team === 0) {
          entity.invulnerable = true;
        }
      }
      return preset;
    },
  };
}


function preset_stat_judgement() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor'](1000);
      preset.entities = preset.entities.filter((e) => e.team !== 0);
      const entity0 = entityFromStat(stats_const(15), tmpl_firearm_dmr_t1);
      preset.entities.push(entity0);
      return preset;
    }
  };
  const good = {
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);

      entity.retarget_interval_range = [3, 5];
      entity.retarget_accurate_prob = 0;

      return preset;
    }
  };

  return {
    poor,
    good,
  };
}

function preset_stat_cover() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor'](800);
      preset.entities = preset.entities.filter((e) => e.team !== 0);
      const entity0 = entityFromStat(stats_const(15), tmpl_firearm_ar_t1);
      preset.entities.push(entity0);
      return preset;
    },
  };
  const good = {
    ...poor,
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);
      entity.hit_prob_covered = 0.2;

      return preset;
    },
  };

  return {
    poor,
    good,
  };
}

function preset_mobility() {
  const m1 = {
    preset: () => {
      return presets_mission.presets['movetest_indoor']();
    },
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 1);
    },
  };
  const m2 = {
    ...m1,
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 2);
    },
  };
  const m3 = {
    ...m1,
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 3);
    },
  };

  return { m1, m2, m3 };
}

function override_entity(preset, override) {
  preset.entities = preset.entities.map((e) => {
    if (e.team !== 0) {
      return e;
    }
    return {
      ...e,
      ...override,
    };
  });
}

function preset_level() {
  const variants = {};

  for (let i = 5; i <= 20; i += 5) {
    const variant = {
      preset: () => {
        const preset = presets_mission.presets['movetest_indoor']();
        override_entity(preset, entityFromStat(stats_const(i), tmpl_firearm_smg_t3));
        return preset;
      },
    };
    variants[`level_${i}`] = variant;
  }

  return variants;
}

function preset_crit() {
  return {
    'nocrit': {
      preset: () => {
        const preset = presets_mission.presets['aimvar']();
        override_entity(preset, entityFromStat(stats_const(10), tmpl_firearm_ar_t3));
        preset.entities[0].crit_prob = 0;
        return preset;
      },
    },
    'crit': {
      preset: () => {
        const preset = presets_mission.presets['aimvar']();
        override_entity(preset, entityFromStat(stats_const(10), tmpl_firearm_ar_t3));
        preset.entities[0].crit_prob = 1;
        return preset;
      },
    },
  };
}

function preset_firearm_ty() {
  const types = ['ar_mid', 'ar_high', 'smg_mid', 'smg_high', 'sg_mid', 'sg_high'];
  const variants = {};

  for (const ty of types) {
    const variant = {
      preset: () => {
        const preset = presets_mission.presets['movetest_indoor']();
        const firearm = presets_firearm[`tmpl_firearm_${ty}`];
        override_entity(preset, entityFromStat(stats_const(15), firearm));
        return preset;
      },
    };
    variants[ty] = variant;
  }

  return variants;
}

function preset_stable_damage_preset(preset) {
  const on = {
    preset: () => {
      return presets_mission.presets[preset]();
    },
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = true;
      opts.STABLE_DAMAGE_FAKE = false;
    },
  };

  const off = {
    ...on,
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = false;
      opts.STABLE_DAMAGE_FAKE = false;
    },
  };

  const fake = {
    ...on,
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = true;
      opts.STABLE_DAMAGE_FAKE = true;
    },
  };

  return { on, off, fake };
}


function preset_stat_throwable() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), tmpl_firearm_ar_t1);
      entity0.throwables = [throwable_frag_t3];

      preset.entities.push(entity0);
      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const good = {
    ...poor,
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);
      entity.throwables = [throwable_frag_t3];

      entity.throwable_max_dist = 400;

      return preset;
    },
  };

  return {
    poor,
    good,
  };
}

function preset_riskrank() {
  const use_mixed = false;
  const f = presets_mission.preset_outdoor_riskrank;
  return {
    leaderrank: { preset: () => f({ use_riskrank: true, use_leader: true, use_mixed }) },
    def: { preset: () => f({ use_riskrank: false, use_leader: false, use_mixed }) },
    leader: { preset: () => f({ use_riskrank: false, use_leader: true, use_mixed }) },
    rank: { preset: () => f({ use_riskrank: true, use_leader: false, use_mixed }) },
  };
}

function preset_metro() {
  const nogroup = {
    preset: () => {
      const preset = presets_mission.presets['metro']();
      preset.entities = preset.entities.map((e) => ({ ...e }));
      return preset;
    },
  };

  const group = {
    preset: () => {
      const preset = nogroup.preset();
      preset.entities[1].leader = 0;
      preset.entities[2].leader = 0;
      preset.entities[3].leader = 0;

      return preset;
    },
  };

  const twogroup = {
    preset: () => {
      const preset = nogroup.preset();
      preset.entities[1].leader = 0;
      preset.entities[3].leader = 2;

      preset.entities[2].spawnarea = 1;
      preset.entities[3].spawnarea = 1;

      return preset;
    },
  };

  return {
    group,
    nogroup,
    twogroup,

    group_invul: presetInvul(group),
    nogroup_invul: presetInvul(nogroup),
    twogroup_invul: presetInvul(twogroup),
  };
}

function preset_outdoor_throw() {
  const base = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_t1);
      entity0.throwables = [throwable_frag_t3];
      const entity1 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_t1);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const throwrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_throw_range = true;

      return preset;
    },
  };
  const effectrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_range = true;

      return preset;
    },
  };
  const effectamount = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_amount = true;

      return preset;
    },
  };
  const shorterblast = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_shorter_blast = true;

      return preset;
    }
  };

  return {
    base,
    throwrange,
    effectrange,
    effectamount,
    shorterblast,
  };
}

function preset_indoor2_breach() {
  const base = {
    preset: () => {
      const preset = presets_mission.presets['indoor2']();
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_t1);
      entity0.throwables = [throwable_frag_t3];
      const entity1 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_t1);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const throwrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_throw_range = true;

      return preset;
    },
  };
  const effectrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_range = true;

      return preset;
    },
  };
  const effectamount = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_amount = true;

      return preset;
    },
  };
  const shorterblast = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_shorter_blast = true;

      return preset;
    }
  };

  return {
    base,
    throwrange,
    effectrange,
    effectamount,
    shorterblast,
  };
}

function preset_aimvar() {
  const variants = {};

  for (const firearm of presets_firearm.firearms_tier1) {
    variants[firearm.firearm_ty] = {
      preset: () => {
        const preset = presets_mission.preset_aimvar({ dist: firearm.firearm_range * 2 / 3 });
        preset.entities = preset.entities.filter((e) => e.team !== 0);
        const entity0 = entityFromStat(stats_const(15), firearm);
        preset.entities.push(entity0);
        return preset;
      },
    };
  }

  return variants;
}

function preset_desmar_test() {
  const defaultpreset = () => {
    const preset = presets_mission.presets['border_checkpoint_short']();

    const team0_ar = {
      ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_ar_t5),
      ...gear_vest_bulletproof_aramid_t1,
      spawnarea: 0,
    };

    const team1_tmpl_ar = {
      ...entityFromStat(stats_const(2), tmpl_firearm_ar_t5),
      default_rule: { ty: 'idle', alert: false },
      team: 1,
      spawnarea: 2,
    };

    preset.entities = [
      { ...team0_ar },
      { ...team0_ar, leader: 0 },
      { ...team0_ar, leader: 0 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      ...preset.entities.filter((e) => e.team !== 0),
    ];


    preset.spawnareas = [
      ...preset.spawnareas,
      { pos: new v2(218, 922), extent: new v2(18, 18), heading: 0, spawn: true, },
    ];

    return preset;
  };
  const noperk = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        spawnarea: 0,
      };

      preset.entities.push({ ...team0_dmr, leader: 0 });
      return preset;
    },
  };

  const baseperk = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  };

  const distraction = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_distraction: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const distraction_range = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_distraction: true,
        perk_desmar_distraction_range: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const damage = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_damage: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const aimspeed = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_aimspeed: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const priority_defensive = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_priority_defensive: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const priority_offensive = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_t5),
        ...gear_vest_bulletproof_aramid_t3,
        perk_desmar_priority_offensive: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  return {
    noperk,
    baseperk,
    distraction,
    distraction_range,
    damage,
    aimspeed,
    priority_defensive,
    priority_offensive,
  };
}

function preset_commed_test() {
  let basepreset = presets_mission.presets['indoor2']();
  basepreset.entities[0].throwables = [];
  basepreset.entities[1].throwables = [];
  const noperk = {
    preset: () => {
      return basepreset;
    },
  };

  const baseperk = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];

      return preset;
    },
  };

  const workspeed = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_workspeed = true;

      return preset;
    },
  };

  const count = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];

      return preset;
    },
  };

  const amount = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_amount = true;

      return preset;
    },
  };

  const target_self = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_target_self = true;

      return preset;
    },
  };

  const risk = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_risk = true;

      return preset;
    },
  };

  const buff = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_buff = true;

      return preset;
    },
  };

  return {
    noperk,
    baseperk,
    workspeed,
    count,
    amount,
    target_self,
    risk,
    buff,
  };
}

function preset_indoor_rescue() {
  const basecase = {
    preset: () => {
      let basepreset = presets_mission.presets['indoor_rescue']();
      return basepreset;
    },
  };

  const fragment = {
    preset: () => {
      const preset = basecase.preset();
      const entity0 = preset.entities.find((e) => e.team === 0);
      entity0.throwables = [throwable_frag_t3];
      return preset;
    }
  };

  const stun = {
    preset: () => {
      const preset = basecase.preset();
      const entity0 = preset.entities.find((e) => e.team === 0);
      entity0.throwables = [throwable_stun_t3];
      return preset;
    }
  }

  return {
    basecase,
    fragment,
    stun,
  };
}

function preset_desmar_priority() {
  let e0 = {
    ...entityFromStat(stats_const(15), tmpl_firearm_dmr_t1),

    firearm_projectile_damage: 10,
    firearm_projectile_penetrate: 10,
  };

  let e1 = {
    ...entityFromStat(stats_const(1), tmpl_firearm_smg_t5),
    waypoint_policy: 'defensive',

    team: 1,
    spawnarea: 1,
  };

  const _entities = () => {
    return [
      { ...e0 },
      { ...e1, life: 30 },
      { ...e1, life: 50 },
      { ...e1, firearm_range: 500, },
      { ...e1, _stat: { _stat: 10 } },
    ];
  };

  const basecase = {
    preset: () => {
      let preset = presets_mission.presets['outdoor']();
      preset.entities = _entities();
      return preset;
    },
  };

  const defensive = {
    preset: () => {
      let preset = basecase.preset();
      preset.entities[0].perk_desmar_priority_defensive = true;
      return preset;
    },
  };

  const offensive = {
    preset: () => {
      let preset = basecase.preset();
      preset.entities[0].perk_desmar_priority_offensive = true;
      return preset;
    },
  };

  return {
    basecase,
    defensive,
    offensive,
  };
}

function preset_perks_outdoor() {
  const outdoor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_t1);
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_t1);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
  }

  const aim_together = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_dmr_t1);
      entity0['perk_aim_together'] = true;
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_t1);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      preset.entities.map((e) => e.life = 1000);

      return preset;
    },
  }

  const aim_execute = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_dmr_t1);
      entity0['perk_aim_execute'] = true;
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_t1);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      preset.entities.map((e) => e.life = 1000);

      return preset;
    },
  }

  return {
    outdoor,
    aim_together,
    aim_execute,
  }
}

function preset_perks_compare() {

  const hit_antiarmor = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_hit_antiarmor: true,
      }

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  }

  const reduce_cover_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_reduce_cover_effect: true,
      }

      return preset;
    },
  }

  const piercing_bullet = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_piercing_bullet: true,
      }
      return preset;
    },
  }

  const pierce_moving_enemy = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        perk_pierce_moving_enemy: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_t5);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ]
      return preset;
    },
  }

  const damage_standing = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_damage_standing: true,
      }

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const damage_move_crawl = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_damage_move_crawl: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_t5);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const lastshoot = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_lastshoot: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const cover_steady = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const hit_incr_aimvar = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const cover_reload = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const damp_aimvar_incr = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t5;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_damp_aimvar_incr: true,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  }

  const engage_dash = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_t5);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_engage_dash: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const cover_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      preset.entities[2] = {
        ...preset.entities[2],
        perk_cover_effect: true,
      }
      return preset;
    },
  };

  const cover_dash = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_t3);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_cover_dash: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const armor_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t1;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        armor: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_armor_effect: true,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  };

  const armor_first = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t1;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        armor: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_armor_first: true,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  };

  const standing_evade = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');
      preset.entities[2] = {
        ...preset.entities[2],
        perk_standing_evade: true,
      }
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const move_cover = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_t5);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_move_cover: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const sg_projectile = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_sg_t5;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: 70 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        perk_sg_projectile: true,
      }
      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      }

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  }

  return {
    hit_antiarmor,
    reduce_cover_effect,
    piercing_bullet,
    pierce_moving_enemy,
    damage_standing,
    damage_move_crawl,
    lastshoot,
    cover_steady,
    hit_incr_aimvar,
    cover_reload,
    damp_aimvar_incr,
    engage_dash,
    cover_effect,
    cover_dash,
    armor_effect,
    armor_first,
    standing_evade,
    move_cover,
    sg_projectile,
  }
}

function preset_shield_compare() {

  const hit_shield = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_hg_t5_with_shield;
      const preset = presets_mission.presets['preset_shield_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const entity_tmpl_shield_base = entityFromStat3(STATS3_TMPL, tmpl_firearm_hg_t5_with_shield);
      const entity_tmpl_ar_base = entityFromStat3(STATS3_TMPL, tmpl_firearm_ar_t3);
      const team1_ar = {
        ...entity_tmpl_ar_base,
        team: 1,
        spawnarea: 0,

        life: 1000,
      };
      const team0_shield = {
        ...entity_tmpl_shield_base,
        team: 0,
        spawnarea: 2,
        firearm_range: 10,
        default_rule: { ty: 'cover-hold', use_spawnheading: true, area: 2 },

        life: 1000,
      };

      preset.entities = [
        team0_shield,
        team1_ar,
      ]

      return preset;
    }
  }

  const weapon_compare = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_hg_t5_with_shield;
      const preset = presets_mission.presets['preset_shield_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team1_tmpl_base = entityFromStat2(STATS2_TMPL, tmpl_firearm_hg_t5_with_shield);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        //shield: 1000,
      };

      preset.entities = [
        {
          ...entityFromStat2(STATS2_TMPL, tmpl_firearm_smg_t3),
          spawnarea: 0,
          life: 10000,
        },
        {
          ...entityFromStat2(STATS2_TMPL, tmpl_firearm_dmr_t3),
          spawnarea: 1,
          life: 10000,
        },
        {
          ...team1_tmpl,
        },
        {
          ...team1_tmpl,
          spawnarea: 3,
        },
      ]

      return preset;
    }
  }
  const shield_degree_compare = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_hg_t5_with_shield;
      const preset = presets_mission.presets['preset_shield_compare']({ dist: firearm.firearm_range * 2 / 3 });
      preset.spawnareas[2].spawnheading = 3;

      const entity_tmpl_shield_base = entityFromStat3(STATS3_TMPL, tmpl_firearm_hg_t5_with_shield);
      const entity_tmpl_ar_base = entityFromStat3(STATS3_TMPL, tmpl_firearm_ar_t3);
      const team1_ar = {
        ...entity_tmpl_ar_base,
        team: 1,
        spawnarea: 0,

        life: 1000,
      };
      const team0_shield = {
        ...entity_tmpl_shield_base,
        team: 0,
        spawnarea: 2,
        firearm_range: 10,
        default_rule: { ty: 'cover-hold', use_spawnheading: true, area: 2 },

        life: 1000,

        aim_rot_rules: [
          { aimvar: Math.PI / 2, aimspeed: 0 },
          { aimvar: Math.PI * 2, aimspeed: 0 },
        ],
      };

      preset.entities = [
        team0_shield,
        team1_ar,
      ]

      return preset;
    }
  }

  return {
    hit_shield,
    weapon_compare,
    shield_degree_compare,
  }
}

function preset_stat3_perk_compare() {
  const firearm = presets_firearm.tmpl_firearm_ar_t5;
  const preset = presets_mission.presets['preset_perk3_compare']({ dist: firearm.firearm_range * 2 / 3 });
  const entity = entityFromStat3(STATS3_TMPL, tmpl_firearm_ar_t5);
  const entity_sg = entityFromStat3(STATS3_TMPL, tmpl_firearm_sg_t5);
  const entity_smg = entityFromStat3(STATS3_TMPL, tmpl_firearm_smg_t5);
  const entity_dmr = entityFromStat3(STATS3_TMPL, tmpl_firearm_dmr_t5);

  const physical_regist = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          perk2_common_physical_regist,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const physical_power_overwhelming = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          perk2_common_physical_power_overwhelming,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
          life: 200
        },
      ]

      return preset;
    }
  };
  const vision_effective_cover = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          perk2_common_vision_effective_cover,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const mental_survival_instinct = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          perk2_common_mental_survival_instinct,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const mental_calmstate = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          perk2_common_mental_calmstate,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const mental_kill_or_be_killed = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_common_mental_kill_or_be_killed,

        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const shooting_quickdraw = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_common_shooting_quickdraw,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const shooting_precision_shooting = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_common_shooting_precision_shooting,
          ...gear_vest_bulletproof_metal_t1,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
          ...gear_vest_bulletproof_metal_t1,
        },
      ]

      return preset;
    }
  };
  const pointman_manoeuvre_shooting = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 1,
          crit_prob: 0,
          perk2_pointman_manoeuvre_shooting,
        },
        {
          ...entity,
          spawnarea: 3,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const pointman_multitarget_engagement = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_pointman_multitarget_engagement,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const common_last_shot_crit = {
    preset: () => {
      preset.entities = [
        {
          ...entity_smg,
          spawnarea: 0,
          crit_prob: 0,
          perk2_common_last_shot_crit,
        },
        {
          ...entity_sg,
          spawnarea: 2,
          team: 1,
          firearm_range: 10,
        },
      ]

      return preset;
    }
  };
  const breacher_quadloading = {
    preset: () => {
      preset.entities = [
        {
          ...entity_sg,
          spawnarea: 0,
          crit_prob: 0,
          perk2_breacher_quadloading,
          life: 200,
        },
        {
          ...entity_sg,
          spawnarea: 2,
          team: 1,
          life: 200,
        },
      ]

      return preset;
    }
  };
  const vanguard_physical_training = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_vanguard_physical_training,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const vanguard_mozambique_drill = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
          perk2_vanguard_mozambique_drill,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };
  const vanguard_effective_armor = {
    preset: () => {
      preset.entities = [
        {
          ...entity,
          spawnarea: 0,
          crit_prob: 0,
        },
        {
          ...entity,
          spawnarea: 2,
          team: 1,
          perk2_vanguard_effective_armor,

          ...gear_vest_bulletproof_metal_t1,
        },
      ]

      return preset;
    }
  };
  const sharpshooter_snipe_mastery = {
    preset: () => {
      preset.entities = [
        {
          ...entity_dmr,
          spawnarea: 0,
          crit_prob: 0,
          firearm_range_penalty_mult: 1,
          perk2_sharpshooter_snipe_mastery,

        },
        {
          ...entity_dmr,
          spawnarea: 2,
          team: 1,
          firearm_range_penalty_mult: 1,
        },
      ]

      return preset;
    }
  };
  const sharpshooter_shoot_mastery = {
    preset: () => {
      preset.entities = [
        {
          ...entity_dmr,
          spawnarea: 0,
          crit_prob: 0,
          firearm_range_penalty_mult: 1,
          perk2_sharpshooter_shoot_mastery,
        },
        {
          ...entity_dmr,
          spawnarea: 2,
          team: 1,
          firearm_range_penalty_mult: 1,
        },
      ]

      return preset;
    }
  };
  const sharpshooter_aim_down_sights = {
    preset: () => {
      preset.entities = [
        {
          ...entity_dmr,
          spawnarea: 0,
          crit_prob: 0,
          perk2_sharpshooter_aim_down_sights,
        },
        {
          ...entity_dmr,
          spawnarea: 2,
          team: 1,
        },
      ]

      return preset;
    }
  };

  return {
    physical_regist,
    physical_power_overwhelming,

    vision_effective_cover,

    mental_survival_instinct,
    mental_calmstate,
    mental_kill_or_be_killed,

    shooting_quickdraw,
    shooting_precision_shooting,

    pointman_manoeuvre_shooting,
    pointman_multitarget_engagement,
    common_last_shot_crit,

    breacher_quadloading,

    vanguard_physical_training,
    vanguard_mozambique_drill,
    vanguard_effective_armor,

    sharpshooter_snipe_mastery,
    sharpshooter_shoot_mastery,
    sharpshooter_aim_down_sights,



  }
}

function preset_stats_compare() {
  function preset_base(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? 20 : STATS2_TMPL.decision,
      bravery: args?.bravery ? 20 : STATS2_TMPL.bravery,
      focus: args?.focus ? 20 : STATS2_TMPL.focus,
      reaction: args?.reaction ? 20 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? 20 : STATS2_TMPL.toughness,
      precision: args?.precision ? 20 : STATS2_TMPL.precision,
    };

    const buildEntity = args?.bonus ? entityFromStat2WithBonus : entityFromStat2;

    const team0_tmpl = {
      ...buildEntity(controlStat, tmpl_firearm_ar_t5),
      spawnarea: 0,
      life: 1000,
    };

    const team0_test = {
      ...buildEntity(testStat, tmpl_firearm_ar_t5),
      spawnarea: 1,
      life: 1000,
    }

    const team1_tmpl_base = buildEntity(STATS2_TMPL, tmpl_firearm_ar_t5);
    const team1_tmpl = {
      ...team1_tmpl_base,
      team: 1,
      spawnarea: 2,

      life: 1000,
      default_rule: 'cover',
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_test, spawnarea: 1 },
        team1_tmpl,
        { ...team1_tmpl, spawnarea: 3 },
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  const decision = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const bravery = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precision = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decisionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const braveryWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focusWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reactionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughnessWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precisionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  return {
    decision,
    bravery,
    focus,
    reaction,
    toughness,
    precision,
    decisionWithBonus,
    braveryWithBonus,
    focusWithBonus,
    reactionWithBonus,
    toughnessWithBonus,
    precisionWithBonus,
  };
}

function preset_stat_bonus_compare() {

  function preset_base(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level - 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level - 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level - 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level - 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level - 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level - 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level : STATS2_TMPL.precision,
    };

    const team0_tmpl = {
      ...entityFromStat2WithBonus(controlStat, tmpl_firearm_ar_t5),
      spawnarea: 0,
      life: 1000,
    };

    const team0_test = {
      ...entityFromStat2WithBonus(testStat, tmpl_firearm_ar_t5),
      spawnarea: 1,
      life: 1000,
    }

    const team1_tmpl_base = entityFromStat2(STATS2_TMPL, tmpl_firearm_ar_t5);
    const team1_tmpl = {
      ...team1_tmpl_base,
      team: 1,
      spawnarea: 2,

      life: 1000,
      default_rule: 'cover',
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_test, spawnarea: 1 },
        team1_tmpl,
        { ...team1_tmpl, spawnarea: 3 },
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  function preset_enemy(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level - 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level - 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level - 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level - 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level - 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level - 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level : STATS2_TMPL.precision,
    };

    const team0_tmpl = {
      ...entityFromStat2WithBonus(STATS2_TMPL, presets_firearm.tmpl_firearm_dmr_t3),
      spawnarea: 0,
      life: 1000,
    };

    const team1_tmpl = {
      ...entityFromStat2(controlStat, tmpl_firearm_ar_t3),
      team: 1,
      spawnarea: 2,
    };
    const team1_test = {
      ...entityFromStat2(testStat, tmpl_firearm_ar_t3),
      team: 1,
      spawnarea: 3,

      life: 1000,
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_tmpl, spawnarea: 1 },
        team1_tmpl,
        team1_test,
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  const decision_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_16 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 16,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const bravery_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_16 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 16,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focus_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_20 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 20,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precision_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_t5;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction_12_enemy = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_t3;
      const preset = preset_enemy(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  return {
    'decision_4': decision_4,
    'decision_8': decision_8,
    'decision_12': decision_12,
    'decision_16': decision_16,
    'bravery_4': bravery_4,
    'bravery_8': bravery_8,
    'bravery_12': bravery_12,
    'bravery_16': bravery_16,
    'focus_8': focus_8,
    'reaction_12': reaction_12,
    'toughness_4': toughness_4,
    'toughness_8': toughness_8,
    'toughness_12': toughness_12,
    'toughness_20': toughness_20,
    'precision_4': precision_4,
    'reaction_12_enemy': reaction_12_enemy,
  }
}

function preset_stat3_compare() {
  const ally_stat = {
    physical: 10,
    mental: 10,
    shooting: 10,
    perception: 10,
    tactical: 10,
  };
  const enemy_stat = {
    physical: 10,
    mental: 10,
    shooting: 10,
    perception: 10,
    tactical: 10,
  };
  const firearm = presets_firearm.tmpl_firearm_ar_t5;
  const preset = presets_mission.presets['preset_perk3_compare']({ dist: firearm.firearm_range * 2 / 3 });
  const entity_ar = entityFromStat3(ally_stat, tmpl_firearm_ar_t5);
  const entity_ar_enemy = entityFromStat3(enemy_stat, tmpl_firearm_ar_t5);
  const entity_smg = entityFromStat3(ally_stat, tmpl_firearm_smg_t5);
  const entity_smg_enemy = entityFromStat3(enemy_stat, presets_firearm.tmpl_firearm_smg_enemy_t5);
  const entity_hg = entityFromStat3(ally_stat, tmpl_firearm_hg_t5);

  const entity_ar_long = entityFromStat3(ally_stat, tmpl_firearm_ar_t5);

  // 명중률로 인한 TTK 변인 통제
  entity_ar.hit_prob_covered = 2;
  entity_ar.hit_prob_obstructed = 2;
  entity_ar.hit_prob_stand = 2;
  entity_ar_enemy.hit_prob_covered = 2;
  entity_ar_enemy.hit_prob_obstructed = 2;
  entity_ar_enemy.hit_prob_stand = 2;

  // 명중률 극도로 낮춰서 교전 길어지도록 통제
  entity_ar_long.hit_prob_covered = 0.01;
  entity_ar_long.hit_prob_obstructed = 0.01;
  entity_ar_long.hit_prob_stand = 0.01;
  entity_ar_long.firearm_additional_crit_prob = 0;
  entity_ar_long.firearm_crit_mult = 20;

  const ar_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_ar,
          spawnarea: 0,
          crit_prob: 0,
          life: 500,
          aimvar_hold_max: Math.PI / 10000,
        },
        {
          ...entity_ar_enemy,
          spawnarea: 2,
          team: 1,
          life: 500,
          firearm_range: 1,
          aimvar_hold_max: Math.PI / 10000,
        },
      ]

      return preset;
    }
  };
  const smg_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_ar,
          spawnarea: 0,
          crit_prob: 0,
          aimvar_hold_max: Math.PI / 300,
        },
        {
          ...entity_smg_enemy,
          spawnarea: 2,
          team: 1,
          firearm_shoot_pattern: [],
          firearm_shoot_pattern_interval_sec: 0.05,
          firearm_projectile_damage: 1,
          firearm_projectile_penetrate: 1,
        },
      ]

      return preset;
    }
  };
  const close_hg_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_hg,
          spawnarea: 1,
          crit_prob: 0,
          life: 200,
        },
        {
          ...entity_hg,
          spawnarea: 3,
          team: 1,
          life: 200,
        },
      ]

      return preset;
    }

  };
  const cover_hold_rule = {
    preset: () => {
      preset.entities = [
        {
          ...entity_hg,
          spawnarea: 0,
          crit_prob: 0,
          life: 200,
          firearm_range: 100,
          vis_range: 100,

          //use_spawnheading : cover-hold 상태일 대 spawnheading으로 주시하는 방향으로 변경하는 기능에 대한 flag입니다.
          //area_idx : cover-hold 상태일 때 향하는 spawnarea의 index입니다.
          default_rule: { ty: 'cover-hold', use_spawnheading: true, area: 0 }
        },
        {
          ...entity_hg,
          spawnarea: 2,
          team: 1,
          life: 200,
          firearm_range: 100,
          vis_range: 100,
          default_rule: { ty: 'cover-hold' }
        },
      ];

      preset.spawnareas[0].extent = new v2(30, 30);

      return preset;
    }
  };
  const long_engage = {
    preset: () => {
      preset.entities = [
        {
          ...entity_ar_long,
          spawnarea: 0,
          crit_prob: 0,
          life: 500,
          aimvar_hold_max: Math.PI / 10000,

          firearm_projectile_damage: 1,
        },
        {
          ...entity_ar_long,
          spawnarea: 2,
          team: 1,
          life: 500,
          firearm_range: 1,
          aimvar_hold_max: Math.PI / 10000,
        },
      ]

      return preset;
    }
  };

  return {
    ar_compare,
    smg_compare,
    close_hg_compare,
    cover_hold_rule,
    long_engage,
  }
}

function preset_bulletproof_compare() {
  const ally_stat = {
    physical: 20,
    mental: 20,
    shooting: 20,
    perception: 20,
    tactical: 20,
  };
  const enemy_stat = {
    physical: 20,
    mental: 20,
    shooting: 20,
    perception: 20,
    tactical: 20,
  };
  const firearm = presets_firearm.tmpl_firearm_ar_t5;
  const preset = presets_mission.presets['preset_perk3_compare']({ dist: firearm.firearm_range * 2 / 3 });
  const entity_ar = entityFromStat3(ally_stat, tmpl_firearm_ar_t1);
  const entity_ar_enemy = entityFromStat3(enemy_stat, tmpl_firearm_ar_t3);
  const entity_smg = entityFromStat3(ally_stat, tmpl_firearm_smg_t5);
  const entity_smg_enemy = entityFromStat3(enemy_stat, presets_firearm.tmpl_firearm_smg_enemy_t5);
  const entity_hg = entityFromStat3(ally_stat, tmpl_firearm_hg_t5);
  const entity_shield = entityFromStat3(ally_stat, tmpl_firearm_hg_t5_with_shield);

  // 명중률로 인한 TTK 변인 통제
  entity_ar.hit_prob_covered = 1;
  entity_ar.hit_prob_obstructed = 1;
  entity_ar.hit_prob_stand = 1;
  entity_ar_enemy.hit_prob_covered = 1;
  entity_ar_enemy.hit_prob_obstructed = 1;
  entity_ar_enemy.hit_prob_stand = 1;

  const ar_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_ar,
          ...gear_vest_bulletproof_aramid_t5,
          spawnarea: 0,
          crit_prob: 0,
          life: 500,
          aimvar_hold_max: Math.PI / 10000,
        },
        {
          ...entity_ar_enemy,
          ...gear_vest_bulletproof_aramid_t1,
          spawnarea: 2,
          team: 1,
          life: 500,
          firearm_range: 1,
          aimvar_hold_max: Math.PI / 10000,
        },
      ]

      return preset;
    }
  };
  const shield_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_shield,
          spawnarea: 0,
          crit_prob: 0,
          life: 500,
          firearm_range: 1,
          aimvar_hold_max: Math.PI / 10000,
        },
        {
          ...entity_ar,
          spawnarea: 2,
          team: 1,
          life: 500,
          aimvar_hold_max: Math.PI / 10000,
        },
      ]

      return preset;
    }
  };
  const smg_compare = {
    preset: () => {
      preset.entities = [
        {
          ...entity_ar,
          ...gear_vest_bulletproof_aramid_t5,
          spawnarea: 0,
          crit_prob: 0,
          life: 500,
          aimvar_hold_max: Math.PI / 10000,
        },
        {
          ...entity_smg_enemy,
          ...gear_vest_bulletproof_aramid_t5,
          spawnarea: 2,
          team: 1,
          life: 500,
          firearm_range: 1,
          aimvar_hold_max: Math.PI / 10000,
        },
      ]

      return preset;
    }
  };

  return {
    ar_compare,
    smg_compare,
    shield_compare,
  }
}

function preset_sabotage0() {
  const stat_base = 18;
  const phy_training_days = 16;
  const shoot_training_days = 16;
  const tac_training_days = 0;
  const squad_set = 'design2';

  const training_set = {
    t_phy: {
      'physical': 1,
      'perception': 0.3,
      'mental': 0.7,
      'tactical': 0,
      'shooting': 0,

    },
    t_shoot: {
      'physical': 0,
      'perception': 0.7,
      'mental': 0.3,
      'tactical': 0,
      'shooting': 1,
    },
    t_tac: {
      'physical': 0.3,
      'perception': 0.7,
      'mental': 0,
      'tactical': 1,
      'shooting': 0,
    },
  };
  // const stat_train_weeks = (weeks) => {
  //   const training_days = 4;
  //   return opts.GRIND_STAT_INCR_PER_TRAIN * training_days * weeks;
  // };

  function set_training_stat(phy_mult, phy_day, shoot_mult, shoot_day, tac_mult, tac_day) {
    const stat = (phy_mult * phy_day) + (shoot_mult * shoot_day) + (tac_mult * tac_day);
    return stat;
  };

  const trained_stat = {
    physical: set_training_stat(training_set.t_phy['physical'], phy_training_days, training_set.t_shoot['physical'], shoot_training_days, training_set.t_tac['physical'], tac_training_days),
    perception: set_training_stat(training_set.t_phy['perception'], phy_training_days, training_set.t_shoot['perception'], shoot_training_days, training_set.t_tac['perception'], tac_training_days),
    mental: set_training_stat(training_set.t_phy['mental'], phy_training_days, training_set.t_shoot['mental'], shoot_training_days, training_set.t_tac['percmentaleption'], tac_training_days),
    tactical: set_training_stat(training_set.t_phy['tactical'], phy_training_days, training_set.t_shoot['tactical'], shoot_training_days, training_set.t_tac['tactical'], tac_training_days),
    shooting: set_training_stat(training_set.t_phy['shooting'], phy_training_days, training_set.t_shoot['shooting'], shoot_training_days, training_set.t_tac['shooting'], tac_training_days),
  }

  function preset_base(weeks) {
    return presets_mission.preset_sabotage_stat(['path0'], stat_base, trained_stat, squad_set);
  }

  const presets = {};
  for (let i = 0; i < 7; i++) {
    presets[`week${i}`] = {
      preset: () => { return preset_base(i); },
    };
  }
  return presets;
}


function preset_sawmill_sabotage1() {
  // 기본 스텟
  const stat_base = opts?.stat_base ?? 10;
  const squad_week = opts?.squad_week ?? 4;

  // const stat_train = opts.GRIND_STAT_INCR_PER_TRAIN * training_days * training_weeks;

  // const phy_training_days = 4;
  // const shoot_training_days = 8;
  // const tac_training_days = 0;

  // const training_weeks = 10;
  // const training_days = 4;

  const training_set = {
    t_phy: {
      'physical': 1,
      'perception': 0.3,
      'mental': 0.7,
      'tactical': 0,
      'shooting': 0,

    },
    t_shoot: {
      'physical': 0,
      'perception': 0.7,
      'mental': 0.3,
      'tactical': 0,
      'shooting': 1,
    },
    t_tac: {
      'physical': 0.3,
      'perception': 0.7,
      'mental': 0,
      'tactical': 1,
      'shooting': 0,
    },
  };

  // 훈련별 트레이닝 돌린 회수 (주차 기준)
  const training_week_set = {
    'week0': {
      'phy_tr': 0,
      'shoot_tr': 0,
      'tac_tr': 0,
    },
    'week1': {
      'phy_tr': 0,
      'shoot_tr': 4,
      'tac_tr': 0,
    },
    'week2': {
      'phy_tr': 4,
      'shoot_tr': 4,
      'tac_tr': 0,
    },
    'week3': {
      'phy_tr': 8,
      'shoot_tr': 4,
      'tac_tr': 0,
    },
    'week4': {
      'phy_tr': 16,
      'shoot_tr': 8,
      'tac_tr': 0,
    },
  }

  const trained_stat = {
    physical: (training_set.t_phy['physical'] * training_week_set[`week${squad_week}`]['phy_tr']) + (training_set.t_shoot['physical'] * training_week_set[`week${squad_week}`]['shoot_tr']) + (training_set.t_tac['physical'] * training_week_set[`week${squad_week}`]['tac_tr']),
    perception: (training_set.t_phy['perception'] * training_week_set[`week${squad_week}`]['phy_tr']) + (training_set.t_shoot['perception'] * training_week_set[`week${squad_week}`]['shoot_tr']) + (training_set.t_tac['perception'] * training_week_set[`week${squad_week}`]['tac_tr']),
    mental: (training_set.t_phy['mental'] * training_week_set[`week${squad_week}`]['phy_tr']) + (training_set.t_shoot['mental'] * training_week_set[`week${squad_week}`]['shoot_tr']) + (training_set.t_tac['mental'] * training_week_set[`week${squad_week}`]['tac_tr']),
    tactical: (training_set.t_phy['tactical'] * training_week_set[`week${squad_week}`]['phy_tr']) + (training_set.t_shoot['tactical'] * training_week_set[`week${squad_week}`]['shoot_tr']) + (training_set.t_tac['tactical'] * training_week_set[`week${squad_week}`]['tac_tr']),
    shooting: (training_set.t_phy['shooting'] * training_week_set[`week${squad_week}`]['phy_tr']) + (training_set.t_shoot['shooting'] * training_week_set[`week${squad_week}`]['shoot_tr']) + (training_set.t_tac['shooting'] * training_week_set[`week${squad_week}`]['tac_tr']),
  };

  // 스텟 조정
  const stat_weight = {
    'pointman': [1, 1, 1, 1, 1],
    'breacher': [1.25, 0.6, 1.2, 1.2, 0.75],
    'medic': [0.7, 0.7, 0.7, 0.7, 0.7],
    'vanguard': [1.25, 0.6, 1.2, 1.2, 0.75],
    'sharpshooter': [0.6, 1.1, 1.25, 0.8, 1.25],
    'scout': [0.8, 1.25, 1, 1.25, 0.7]
  };
  const stat_proj = (w, t_stat) => {
    return (stat_base + t_stat) * w;
  };
  const stat_ally = (ty) => {
    const weight = stat_weight[ty];

    return {
      physical: stat_proj(weight[0], trained_stat.physical),
      perception: stat_proj(weight[1], trained_stat.perception),
      mental: stat_proj(weight[2], trained_stat.mental),
      tactical: stat_proj(weight[3], trained_stat.tactical),
      shooting: stat_proj(weight[4], trained_stat.shooting),
    };
  };

  const ally_vanguard = stat_ally('vanguard');
  const ally_breacher = stat_ally('breacher');
  const ally_sharpshooter = stat_ally('sharpshooter');
  const ally_medic = stat_ally('medic');
  const ally_point = stat_ally('pointman');
  const ally_scout = stat_ally('scout');

  const team0_vang = {
    ...entityFromStat3(ally_vanguard, {
      ...tmpl_firearm_hg_t5_with_shield,
      shield: 350,
    }),
    spawnarea: 0,
    speed: 18,
    role: 'vanguard',
  };
  const team0_breacher = {
    ...entityFromStat3(ally_breacher, {
      ...tmpl_firearm_sg_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    attachables: [throwable_breach_charge],
    role: 'breacher',
    perk2_common_strategy_supress_decrease_avoid: {
      accuracy_rate_reduction: 0.05, // 명중률 감소율 (합연산)
    },
    perk2_common_intelligence_dynamic_entry: {

      duration_seconds: 5, // 효과 지속 시간
      evasion_rate_reduction: 0.1, // 회피 감소율 (덧셈)
      all_atk_damage_rate_increase: 0.2, // 데미지 증가율 (합연산)
    }
  };
  const team0_sharpshooter = {
    ...entityFromStat3(ally_sharpshooter, {
      ...tmpl_firearm_dmr_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    vis_range: 750,
    role: 'sharpshooter',
  };
  const team0_sharpshooter_ar = {
    ...entityFromStat3(ally_sharpshooter, {
      ...tmpl_firearm_ar_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    vis_range: 750,
    role: 'sharpshooter',
  };
  const team0_medic = {
    ...entityFromStat3(ally_medic, {
      ...tmpl_firearm_ar_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    heals: new Array(5).fill(utility_healthpack_small.heal),
    role: 'medic',
    perk2_medic_stimpack: {
      all_atk_damage_rate_increase: 0.03, // 모든 데미지 증가율 (합연산)
    },
    perk2_common_strategy_supress_decrease_avoid: {
      accuracy_rate_reduction: 0.05, // 명중률 감소율 (합연산)
    }
  };
  const team0_point = {
    ...entityFromStat3(ally_point, {
      ...tmpl_firearm_ar_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    throwable_score_direct: 1,
    throwable_score_indirect: 1,
    throwable_score_thres: 2,
    role: 'pointman',
    perk2_common_half_over_mag: {
      accuracy_rate_increase: 0.1, // 명중률 증가량 (합연산)
    },
    perk2_common_strategy_supress_decrease_avoid: {
      accuracy_rate_reduction: 0.05, // 명중률 감소율 (합연산)
    },
  };
  const team0_point_smg = {
    ...entityFromStat3(ally_point, {
      ...tmpl_firearm_smg_t5,
    }),
    spawnarea: 0,
    vis_var: Math.PI / 3,
    // throwable_score_direct: 1,
    // throwable_score_indirect: 1,
    // throwable_score_thres: 4,
    role: 'pointman',
  };

  const team0_week = {
    'w0': [
      { ...team0_point, },
      { ...team0_point, },
      { ...team0_point, },
      { ...team0_point, },
    ],
    'w1': [
      { ...team0_breacher, },
      { ...team0_point, },
      { ...team0_point, },
      { ...team0_point, },
    ],
    'w2': [
      { ...team0_breacher, },
      { ...team0_point, throwables: [throwable_he_t2], },
      { ...team0_point, },
      { ...team0_point, },
    ],
    'w3': [
      { ...team0_breacher, attachables: [throwable_he_t2] },
      { ...team0_point, throwables: [throwable_he_t2], },
      { ...team0_point, },
      { ...team0_medic, },
    ],
    'w4': [
      { ...team0_breacher, },
      { ...team0_point, throwables: [throwable_he_t2], perk2_common_intelligence_grenade_cooking },
      { ...team0_point, },
      { ...team0_point, },
    ],
  };

  function preset_base(entities) {
    const preset = presets_mission.preset_sawmill_sabotage(['base', 'drill'], {});
    return markLeader({
      ...preset,
      entities: [
        ...entities,
        ...preset.entities.filter((e) => e.team !== 0),
      ],
    }, true);
  }

  const presets = {};
  for (const [key, entities] of Object.entries(team0_week)) {
    presets[key] = {
      preset: () => {
        return preset_base(entities);
      },
    };
  }
  return presets;
}

function preset_sawmill_sabotage1_drill() {
  const presets = preset_sawmill_sabotage1();
  for (const [key, entries] of Object.entries(presets)) {
    presets[key] = {
      ...entries,
      preset: () => {
        return presets_mission.map_sawmill_drill(entries.preset());
      },
    };
  }
  return presets;
}

function preset_fireleader() {
  function preset_base() {
    // { squad_week: weeks }
    return presets_mission.preset_test_range_layer_base(['base']);
  }

  // const week = 3;
  const presets = {
    default: {
      preset: () => { return preset_base(); },
    },
    firelader: {
      preset: () => {
        const preset = preset_base();
        const entities = preset.entities.filter((e) => e.team === 0);
        for (const entity of entities) {
          entity.allow_coordinated_fire = true;
        }
        entities[2].fireleader = 0;
        entities[3].fireleader = 1;
        return preset;
      },
    },
  };
  return presets;
}

function preset_coordinated() {
  function preset_base() {
    return presets_mission.preset_test_range_layer_base(['base']);
  }

  const presets = {
    default: {
      preset: () => { return preset_base(); },
    },
    coordinated: {
      preset: () => {
        const preset = preset_base();
        for (const entity of preset.entities.filter((e) => e.team === 0)) {
          entity.allow_coordinated_fire = true;
        }

        return preset;
      },
    },
    coordinated2: {
      preset: () => {
        const preset = preset_base();
        for (const entity of preset.entities.filter((e) => e.team === 0)) {
          entity.allow_coordinated_fire = true;
        }

        return preset;
      },
    },
  };
  return presets;
}

function preset_entity_spawn_test() {
  function preset_base() {
    return presets_mission.preset_spawn_test_base(['base']);
  }

  const presets = {
    coordinated: {
      preset: () => {
        const preset = preset_base();
        for (const entity of preset.entities.filter((e) => e.team === 0)) {
          entity.allow_coordinated_fire = true;
        }

        return preset;
      },
    },
  };
  return presets;
}


function preset_sabotage0_op() {
  const stat_base = 20;
  const stat_train_weeks = (weeks) => {
    const training_days = 4;
    return opts.GRIND_STAT_INCR_PER_TRAIN * training_days * weeks;
  };
  function get_op_presets(stat_base = 20, stat_train = 10) {
    // 스텟 조정
    const stat_weight = {
      'pointman': [1, 1, 1, 1, 1],
      'breacher': [1.25, 0.6, 1.2, 1.2, 0.75],
      'medic': [0.7, 0.7, 0.7, 0.7, 0.7],
      'vanguard': [1.25, 0.6, 1.2, 1.2, 0.75],
      'sharpshooter': [0.6, 1.1, 1.25, 0.8, 1.25],
      'scout': [0.8, 1.25, 1, 1.25, 0.7]
    };
    const stat_proj = (w) => {
      return stat_base * w + stat_train;
    };
    const stat_ally = (ty) => {
      const weight = stat_weight[ty];

      return {
        physical: stat_proj(weight[0]),
        perception: stat_proj(weight[1]),
        mental: stat_proj(weight[2]),
        tactical: stat_proj(weight[3]),
        shooting: stat_proj(weight[4]),
      };
    };

    const ally_vanguard = stat_ally('vanguard');
    const ally_breacher = stat_ally('breacher');
    const ally_sharpshooter = stat_ally('sharpshooter');
    const ally_medic = stat_ally('medic');
    const ally_point = stat_ally('pointman');
    const ally_scout = stat_ally('scout');

    const team0_vang = {
      ...entityFromStat3(ally_vanguard, {
        ...tmpl_firearm_hg_t5_with_shield,
      }),
      spawnarea: 0,
      speed: 18,
    };
    const team0_breacher = {
      ...entityFromStat3(ally_breacher, {
        ...tmpl_firearm_smg_t5,
      }),
      spawnarea: 0,
      vis_var: Math.PI / 3,
      attachables: [throwable_breach_charge]
    };
    const team0_sharpshooter = {
      ...entityFromStat3(ally_sharpshooter, {
        ...tmpl_firearm_dmr_t5,
      }),
      spawnarea: 0,
      vis_var: Math.PI / 3,
      vis_range: 750
    };
    const team0_sharpshooter_ar = {
      ...entityFromStat3(ally_sharpshooter, {
        ...tmpl_firearm_ar_t5,
      }),
      spawnarea: 0,
      vis_var: Math.PI / 3,
      vis_range: 750
    };
    const team0_medic = {
      ...entityFromStat3(ally_medic, {
        ...tmpl_firearm_ar_t5,
      }),
      spawnarea: 0,
      vis_var: Math.PI / 3
    };
    const team0_point = {
      ...entityFromStat3(ally_point, {
        ...tmpl_firearm_ar_t5,
      }),
      spawnarea: 0,
      vis_var: Math.PI / 3,
      throwable_score_direct: 1,
      throwable_score_indirect: 1,
      throwable_score_thres: 4,
    };
    const team0_sg_sq1 = {
      ...entityFromStat2(stats2_const(10), {
        ...tmpl_firearm_sg_t5,
      }),
      life: 100,
      armor: 50,
      spawnarea: 0,
    };

    return [
      // 클리어 조합 (뱅 브 샤(ar) 샤(dmr))
      [
        { ...team0_vang, },
        { ...team0_breacher, },
        { ...team0_sharpshooter_ar, },
        { ...team0_sharpshooter },
      ],
      // 클리어 못하는 조합 (브브브샤)
      [
        { ...team0_breacher, },
        { ...team0_breacher, },
        { ...team0_breacher, },
        { ...team0_sharpshooter, },
      ],

      // 클리어 못하는 조합 (뱅프샤포)
      [
        { ...team0_vang, },
        { ...team0_breacher, },
        { ...team0_point, },
        { ...team0_sharpshooter, },
      ],

      // 클리어 조합 (뱅브샤뱅(smg))
      [
        { ...team0_vang, },
        { ...team0_breacher, },
        { ...team0_sharpshooter, },
        { ...team0_vang, },
      ],

      // 클리어 못하는 조합 (포포포포)
      [
        { ...team0_point, throwables: [throwable_impact_frag_t1] },
        { ...team0_point, throwables: [throwable_impact_frag_t1] },
        { ...team0_point, throwables: [throwable_impact_frag_t1] },
        { ...team0_point, throwables: [throwable_impact_frag_t1] },
      ],

      // 클리어 하는 조합 (뱅브샤메(smg))
      [
        { ...team0_vang, attachables: [throwable_stun_t1] },
        { ...team0_breacher, },
        { ...team0_sharpshooter, },
        { ...team0_medic, heals: [utility_healthpack_big.heal] },
      ],

      // 클리어 하는 조합 (뱅브샤메(smg))
      [
        { ...team0_vang, attachables: [throwable_stun_t3] },
        { ...team0_breacher, },
        { ...team0_sharpshooter, },
        { ...team0_medic, heals: [utility_healthpack_big.heal] },
      ],

      // 샷건 투사체 확인용
      [
        { ...team0_sg_sq1 },
        { ...team0_sg_sq1, },
        { ...team0_sg_sq1, },
        { ...team0_sg_sq1 },
      ],
    ];
  }
  const op_presets = get_op_presets(stat_base, stat_train_weeks(3)); // hard-coded weeks = 3
  function preset_base(idx) {
    return presets_mission.preset_sabotage_stat_op(['path0'], op_presets[idx]);
  }

  const presets = {};
  for (let i = 0; i < 8; i++) {
    presets[`preset${i}`] = {
      preset: () => { return preset_base(i); },
    };
  }
  return presets;
}

function preset_door_testing() {
  const preset = presets_mission.preset_from(DOOR_TEST_LEVEL);

  const team0_ar_sq1 = {
    ...entityFromStat2(stats2_const(10), presets_firearm.tmpl_firearm_smg_t1),
    spawnarea: 0,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(6), {
      ...tmpl_firearm_smg_t3,
    }),
    life: 1000,
    shield: 50,
    default_rule: { ty: 'idle', alert: false },
    spawnarea: 1,
    team: 1,
  };

  //check door wait
  const check0 = (map, value) => {
    for (const entity of map.entities) {
      entity.allow_door_wait = value;
    }
  };
  //attachable
  const check1 = (map, value) => {
    for (const entity of map.entities) {
      entity.attachable = value;
      if (!value) {
        entity.attachable = [];
        entity.attachable_doors = [];
      }
    }
    if (value) {
      map.entities[0].attachables = [throwable_breach_charge];
      map.entities[0].attachable_doors = ['door0'];
    }
  };
  //throwable
  const check2 = (map, value) => {
    for (const entity of map.entities) {
      entity.throwable = value;
      if (!value) {
        entity.throwables = [];
      }
    }
    if (value) {
      map.entities[0].throwables = [throwable_he_t1];
    }
  };
  //door_entry
  const check3 = (map, value) => {
    for (const entity of map.entities) {
      entity.perk2_common_intelligence_dynamic_entry = value;
      entity.door_entry = value;
    }
  };

  const checks = [check0, check1, check2, check3];

  let presets = {};
  for (let i = -1; i < (1 << 4); i++) {
    const map = {
      ...preset,

      world: {
        ...preset.world,
        simover_rule: 'eliminate',
      },

      entities: [
        { ...team0_ar_sq1, },
        { ...team0_ar_sq1, },
        { ...team0_ar_sq1, },
        { ...team0_ar_sq1, },
        { ...team1_tmpl, },
      ],

      mission_rules: [
        { ty: 'explore', },
      ],

      editor: true,

      segments: [
        { spawnareas: [1], doors: ['door0'], moveopt: false, },
      ]
    };

    if (i === -1) {
      presets[`dw : door_wait, at : attachable, th : throwable, de : door_entry`] = {
        preset: () => { return markLeader(map); }
      };
      continue;
    }

    let arr = [0, 0, 0, 0];
    for (let j = 0; j < 4; j++) {
      checks[j](map, (i & (1 << j)) > 0 ? true : false);
      if (i & (1 << j)) {
        arr[j] = 1;
      }
    }

    presets[`dw,at,th,de:[${arr[0]},${arr[1]},${arr[2]},${arr[3]}]`] = {
      preset: () => { return markLeader(map); },
    };
  }

  return presets;
}

export const presets = {
  'stat_judgement': preset_stat_judgement,
  'stat_cover': preset_stat_cover,
  'stat_throwable': preset_stat_throwable,
  'mobility': preset_mobility,
  'level': preset_level,
  'crit': preset_crit,
  'firearm_ty': preset_firearm_ty,
  'stable_damage_indoor2_trigger': () => preset_stable_damage_preset('indoor2_trigger'),
  'stable_damage_ship': () => preset_stable_damage_preset('ship'),
  'stable_damage_bazaar': () => preset_stable_damage_preset('bazaar'),
  'riskrank': preset_riskrank,
  'metro': preset_metro,
  'outdoor_throw': preset_outdoor_throw,
  'indoor2_breach': preset_indoor2_breach,
  'aimvar': preset_aimvar,
  'desmar_test': preset_desmar_test,
  'commed_test': preset_commed_test,
  'indoor_rescue': preset_indoor_rescue,

  'desmar_priority': preset_desmar_priority,
  'perks_outdoor': preset_perks_outdoor,
  'perks_compare': preset_perks_compare,
  'stats_compare': preset_stats_compare,
  'stat_bonus_compare': preset_stat_bonus_compare,
  'shield_compare': preset_shield_compare,
  'preset_stat3_perk_compare': preset_stat3_perk_compare,

  'stat3_compare': preset_stat3_compare,
  'bulletproof_compare': preset_bulletproof_compare,

  'sabotage0': preset_sabotage0,
  'sabotage0_op': preset_sabotage0_op,
  'sabotage_sawmill': preset_sawmill_sabotage1,
  'sabotage_sawmill_drill': preset_sawmill_sabotage1_drill,

  'fireleader': preset_fireleader,
  'coordinated': preset_coordinated,
  'door_testing': preset_door_testing,
  'entity_spawn_test': preset_entity_spawn_test,
};

export const preset_default = 'preset_stat3_perk_compare';
