import React from "react";
import { Estimation } from "./criterion.js"

class Row extends React.Component {
    isInput(row) {
        const { properties } = this.props;

        let check = false;
        properties.forEach((p) => {
            if (row[p] === null) return false;
            if (row[p].type === "input") check = true;
        })

        return check;
    }

    render() {
        const { row, index, calWinRate, removeRow, dupRow, properties, updateRow, editRow, storage } = this.props;

        let id = row.id;
        let returnRow = [];

        let buttons = (item, index) => {
            if (this.isInput.bind(this)(item)) return <button onClick={() => updateRow(item)}>Confirm</button>;
            else {
                return [
                    <button onClick={() => editRow(index, item)}>Edit</button>,
                    <button onClick={() => removeRow(item)}>Remove</button>,
                    <button onClick={() => dupRow(index)}>Duplicate</button>
                ];
            }
        };

        properties.forEach((p) => returnRow.push(row[p]));

        let stored = storage.filter(p => p.id === id)[0];
        if (stored === undefined) stored = row;

        return (
            <>
                <tr key={id}>
                    {returnRow.map((i, index) => <td key={index}>{i}</td>)}
                    <td key='winRate'>{calWinRate(id)}</td>
                    <td key='estimation'><Estimation data={stored} /></td>
                    <td key='Add/Remove button'>{buttons(row, index)}</td>
                </tr>
            </>
        );
    }
}

class ListViews extends React.Component {
    render() {
        const { rows, calWinRate, removeRow, dupRow, properties, updateRow, editRow, storage } = this.props;

        return (
            <>
                {rows.map((r, i) =>
                    <Row row={r} calWinRate={calWinRate} removeRow={removeRow} dupRow={dupRow} index={i} properties={properties}
                        updateRow={updateRow} editRow={editRow} storage={storage} />)}
            </>
        )
    }
}

export class SimBattleView extends React.Component {
    constructor(props) {
        super(props);

        const row = {
            id: -1,
            ty: 'Maze',
            threats: 2,
            training: 3,
            agents_count: 4,
            agents_count_sg: 5,
            agents_count_dmr: 1,
            agents_overall: 6,
            agents_equip_rate: 7,
        };

        this.state = {
            properties: Object.keys(row).filter((key) => key !== 'id'),
            rows: [row],
            storage: [],
            idCnt: 0,
            results: []
        }

        this.getIdCnt = this.getIdCnt.bind(this);
        this.storeToStorage = this.storeToStorage.bind(this);
        this.makeProperInput = this.makeProperInput.bind(this);
        this.calculateWinRate = this.calculateWinRate.bind(this);
        this.requestToServer = this.requestToServer.bind(this);
    }

    getIdCnt() {
        let id = this.state.idCnt;
        this.setState({ idCnt: id + 1 });
        return id;
    }

    makeProperInput(property, id, item) {
        // 0: number, 1: string
        const constraint = {
            id: 0,
            ty: 1,
            threats: 0,
            training: 0,
            agents_count: 0,
            agents_count_sg: 0,
            agents_count_dmr: 0,
            agents_overall: 0,
            agents_equip_rate: 0,
        }

        if (Object.keys(item).length === 0) {
            if (constraint[property] === 0) return <input type='number' key={property} defaultValue={null} onChange={(value) => this.storeToStorage(id, property, value.target.value)}></input>;
            else if (constraint[property] === 1) {
                return (
                    <>
                        <select id='Dropdown' onChange={(value) => this.storeToStorage(id, property, value.target.value)}>
                            <option value=''>Select an Option</option>
                            <option value='embassy short'>Embassy Short</option>
                            <option value='maze'>Maze</option>
                            <option value='indoor'>Indoor</option>
                            <option value='outdoor'>Outdoor</option>
                        </select>
                    </>
                )
            }
        }
        else {
            if (constraint[property] === 0) return <input type='number' key={property} defaultValue={item[property]} onChange={(value) => this.storeToStorage(id, property, value.target.value)}></input>;
            else if (constraint[property] === 1) {
                return (
                    <>
                        <select id='Dropdown' defaultValue={item[property]} onChange={(value) => this.storeToStorage(id, property, value.target.value)}>
                            <option value=''>Select an Option</option>
                            <option value='embassy short'>Embassy Short</option>
                            <option value='maze'>Maze</option>
                            <option value='indoor'>Indoor</option>
                            <option value='outdoor'>Outdoor</option>
                        </select>
                    </>
                )
            }
        }

        return <input type='number' key={property} onChange={(value) => this.storeToStorage(id, property, value.target.value)}></input>;
    }

    async requestToServer(item) {
        // Check the item is proper item
        for (const [, value] of Object.entries(item)) {
            if (value === "" || value === null) return;
        }

        const { storage, results } = this.state;

        let id = item.id;
        let stored = storage.filter((element) => element.id === id)[0];


        // Request the result to Server
        let obj = null;
        delete stored[id];
        let data = stored;
        let dict = {};

        const Url = 'http://sandbox-dl.5ml.io:8000/';
        const parameter = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data)
        }

        const res = await fetch(Url, parameter);
        obj = await res.json();
        dict['id'] = id;
        dict['obj'] = obj;

        let newResults = results.slice();
        newResults = newResults.filter((res) => res.id !== id);

        this.setState({ results: [...newResults, dict] });
    }

    async storeToStorage(id, property, value) {
        let storage = this.state.storage.slice();
        let item = null;

        storage.forEach((p) => { if (p.id === id) { item = p; } });

        if (item === null) console.log("Error: There is no such storage");

        item[property] = value;

        this.requestToServer(item);
        this.setState({ storage: storage });
    }

    addRow() {
        const { storage, rows, properties } = this.state;

        let id = this.getIdCnt();
        let newRow = {}
        newRow.id = id;
        properties.forEach((p) => newRow[p] = this.makeProperInput(p, id, {}));

        let newStorage = {}
        newStorage.id = id;
        properties.forEach((p) => newStorage[p] = null);

        this.setState({ rows: [...rows, newRow], storage: [...storage, newStorage] });
    }

    async updateRow(item) {

        const { rows, storage } = this.state;
        let id = item.id;

        let stored = storage.filter((element) => element.id === id)[0];
        let newRows = rows.map((element) => ((element.id === id) ? stored : element));
        let newStorage = storage.filter((element) => element.id !== id);

        this.requestToServer(item);
        this.setState({ rows: newRows, storage: newStorage });

    }

    removeRow(item) {
        let newRows = this.state.rows.filter((i) => i !== item);
        let newStorage = this.state.storage.filter((i) => i.id !== item.id)
        this.setState({ rows: newRows, storage: newStorage });
    }

    duplicateRow(index) {
        const row = this.state.rows[index];
        const properties = this.state.properties;

        let newRow = {};
        properties.forEach((p) => {
            if (row[p].type === 'input') newRow[p] = <input type='text' key={p}></input>;
            else newRow[p] = row[p];
        })

        let newRows = this.state.rows.slice();
        newRows.splice(index + 1, 0, newRow);
        this.setState({ rows: newRows });
    }

    editRow(index, item) {
        const { rows, storage, properties } = this.state;

        let id = this.getIdCnt();
        let newRow = {}
        let newRows = rows.filter((i) => i !== item);

        newRow.id = id;
        properties.forEach((p) => newRow[p] = this.makeProperInput(p, id, item));
        newRows.splice(index, 0, newRow);

        let newStorage = {}
        newStorage.id = id;
        properties.forEach((p) => newStorage[p] = item[p]);

        this.setState({ rows: newRows, storage: [...storage, newStorage] });
    }

    calculateWinRate(id) {

        let res = this.state.results.filter((p) => p.id === id)[0];

        if (res === undefined) return 'undefined';

        res = res.obj.class_name;

        return res;
    }

    render() {
        const { properties, rows, storage } = this.state;

        let header = (
            <thead>
                <tr>
                    {properties.map((p) =>
                        <th key={p}>{p}</th>
                    )}
                    <th>winrate</th>
                    <th>criterion</th>
                    <th><button onClick={this.addRow.bind(this)}>Add</button></th>
                </tr>
            </thead>
        );


        let body = (
            <tbody>
                <ListViews rows={rows} properties={properties} calWinRate={this.calculateWinRate} removeRow={this.removeRow.bind(this)}
                    dupRow={this.duplicateRow.bind(this)} updateRow={this.updateRow.bind(this)} editRow={this.editRow.bind(this)} storage={storage} />
            </tbody>
        )

        return (
            <table>
                {header}
                {body}
            </table>
        )
            ;
    }


}