import source from '../downloaded/itemDescs.json' assert { type: 'json' };

export const itemDescs = [];
for (const row of source) {
  const {
    key,
    desc,
  } = row;

  itemDescs.push({
    key,
    desc,
  });
}
