import source from '../downloaded/contractoverall.json' assert { type: 'json' };

export const contractoverall = [];
for (const row of source) {
  const { overall, mult_min, mult_max } = row;
  contractoverall.push({
    overall: 0 | overall,
    mult_min: +mult_min,
    mult_max: +mult_max,
  });
}
