import source from '../downloaded/char2PotentialTier.json' assert { type: 'json' };

export const potentialTiers = [];

for (const row of source) {
  let { potentialTier, minValue, maxValue, stat_1_weight, stat_2_weight, stat_3_weight, stat_4_weight, stat_5_weight } = row;
  const statTierWeights = [
    {
      tier: 1,
      weight: +stat_1_weight
    },
    {
      tier: 2,
      weight: +stat_2_weight
    },
    {
      tier: 3,
      weight: +stat_3_weight
    },
    {
      tier: 4,
      weight: +stat_4_weight
    },
    {
      tier: 5,
      weight: +stat_5_weight
    },
  ];

  potentialTiers.push({
    potentialTier: 0 | potentialTier,
    minValue: +minValue,
    maxValue: +maxValue,
    statTierWeights: statTierWeights
  });
}
