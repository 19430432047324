import React from 'react';
import _ from "lodash";

export function triggerSound(name) {
  if (!name)
    return;
  else if (window.ue) {
    try {
      window.ue.connection?.onsoundtrigger(name);
    } catch (e) {
    }
  } else {
    console.log(`playing sound cue: ${name}`);
  }
}

export function triggerBgm(name, type) {
  if (!name || !type) {
    return;
  }
  console.log('onbgmtrigger', name, type);
  if (!window.ue) {
    return;
  }
  try {
    window.ue.connection?.onbgmtrigger(name, type);
  } catch (e) {
  }
}

export function soundprops(props) {
  const props1 = {
    ...props,
    onMouseDown: (e) => {
      if (props?.onClick) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    onClick: (e) => {
      if (props?.onClick) {
        e.preventDefault();
        e.stopPropagation();

        if (props.cueList) {
          props.cueList.forEach(cue => {
            triggerSound(cue);
          });
        }
        else
          triggerSound('UI_Outgame_Button_Click_Default');

        props.onClick(e);
      }
    },
  };
  delete props1.cueList;
  return props1;
}

export function SoundButton(props) {
  return <button {...soundprops(props)} />;
}
