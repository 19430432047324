/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { PerkLevel } from "../PerkLevel";
import "./style.css";

export const PerkLevels = ({ className, override = <PerkLevel PTPerklevel="1" className="perk-level-instance" /> }) => {
  return <div className={`perk-levels ${className}`}>{override}</div>;
};
