import source from '../downloaded/trainResults.json' assert { type: 'json' };

export const trainResults = [];
for (const row of source) {
  let { key, exp_mult, stats_mult, injury } = row;
  trainResults.push({
    key,
    exp_mult: parseFloat(exp_mult),
    stats_mult: parseFloat(stats_mult),
    injury: injury === "TRUE",
  });
}
