import * as React from 'react';
import { Rng } from './rand.mjs';
import { Radar } from 'react-chartjs-2';

import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';

import { createCharacter2, createFixedCharacter2 } from './character2.mjs';
import { roles, rolesBykey } from './data/google/processor/data_char2roles.mjs';
import { statsPerksdata } from './data/google/processor/data_char2statsPerks.mjs';
import { rolesPerksdata } from './data/google/processor/data_char2rolesPerks.mjs';
import { names4, names5 } from './names';
import { PortraitWrapper } from './PortraitsView';

import './CharacterView2.css';
import { values } from 'lodash';
import { aptitudeMultiplier } from './data/google/processor/data_char2aptitudeMultiplier.mjs';

import { agentModifierText } from './WeeklyMeetingView';
import { fixedOperators } from './data/google/processor/data_char2fixedOperators.mjs';
import { L } from './localization.mjs';

export function BoldText(props) {
  const { text, fontsize } = props;

  const result = SumBoldText(text, [], fontsize)

  return <span key={result}>
    {result}
  </span>

}

function SumBoldText(remainText, total, fontsize) {
  if (remainText.indexOf("<b>") == -1) {
    total.push(<span>{remainText}</span>);
    return total;
  }

  if (remainText.indexOf("</b>") == -1) {
    return <span> 시트에 볼드 태크 닫힘이 제대로 있는지 확인해 주세요. </span>
  }

  const boldTextPrev = remainText.slice(0, remainText.indexOf("<b>"))
  const exceptBoldTextPrev = remainText.slice(remainText.indexOf("<b>") + 3,)
  const boldText = exceptBoldTextPrev.slice(0, exceptBoldTextPrev.indexOf("</b>"));
  const boldTextNext = exceptBoldTextPrev.slice(exceptBoldTextPrev.indexOf("</b>") + 4,);

  total.push(<span>{boldTextPrev}</span>);
  total.push(<b style={{ fontSize: fontsize }}>{boldText}</b>)

  return SumBoldText(boldTextNext, total, fontsize)
}

const CHART_OPTS = {
  events: [],

  responsive: false,
  animation: { duration: 0 },
  layout: { padding: 10 },
  plugins: {
    tooltips: { enabled: false },
  },
};

export const CHART_OPT_COMPACT = {
  ...CHART_OPTS,
  scales: {
    r: {
      min: 0,
      max: 100,
      ticks: {
        display: false,
      },
      pointLabels: {
        display: false,
      },
      angleLines: {
        color: "#675844"
      },
      grid: {
        color: "#675844"
      },
    },
  },
  plugins: {
    legend: false
  }
};

export const CHART_OPT_DETAIL = {
  ...CHART_OPTS,

  scales: {
    r: {
      ticks: {
        display: false,
      },
      grid: {
        color: "#675844"
      },
      angleLines: {
        color: "#675844"
      },
      min: 0,
      max: 100,
      pointLabels: {
        color: "rgba(158, 128, 87, 1)",
        font: {
          size: 13,
          weight: "bold",
        }
      }
    },
  },
  plugins: {
    legend: {
      position: "chartArea",
      align: "start",
      labels: {
        usePointStyle: true,
      }
    }
  }
}

export function characterChartData(character) {
  return {
    labels: [
      [L('loc_ui_string_agent_stat3_physical'), character.realStats.physical.toFixed(0)],
      [L('loc_ui_string_agent_stat3_perception'), character.realStats.perception.toFixed(0)],
      [L('loc_ui_string_agent_stat3_mental'), character.realStats.mental.toFixed(0)],
      [L('loc_ui_string_agent_stat3_tactical'), character.realStats.tactical.toFixed(0)],
      [L('loc_ui_string_agent_stat3_shooting'), character.realStats.shooting.toFixed(0)],
    ],
    datasets: [
      {
        label: L('loc_ui_string_agent_stat3_current'),
        data: [
          character.realStats.physical,
          character.realStats.perception,
          character.realStats.mental,
          character.realStats.tactical,
          character.realStats.shooting,
        ],
        fill: true,
        backgroundColor: 'rgba(245, 192, 54, 0.5)',
        borderColor: 'rgba(245, 192, 54, 1)',
        order: 0,
      },
      {
        label: L('loc_ui_string_agent_stat3_potential'),
        data: [
          character.potentialStats.physical,
          character.potentialStats.perception,
          character.potentialStats.mental,
          character.potentialStats.tactical,
          character.potentialStats.shooting,
        ],
        fill: false,
        backgroundColor: 'rgba(158, 128, 87, 0.5)',
        borderColor: 'rgba(158, 128, 87, 1)',
        order: 1,
      },
      {
        label: L('loc_ui_string_agent_stat3_expected'),
        data: [
          80 * rolesBykey(character.role).stat_weights.physical,
          80 * rolesBykey(character.role).stat_weights.perception,
          80 * rolesBykey(character.role).stat_weights.mental,
          80 * rolesBykey(character.role).stat_weights.tactical,
          80 * rolesBykey(character.role).stat_weights.shooting,
        ],
        fill: false,
        borderColor: 'rgba(120, 120, 120, 0.0)',
        pointBorderColor: 'rgba(94, 88, 79, 0.5)',
        pointBackgroundColor: 'rgba(94, 88, 79, 0.5)',
        order: 2,
      }
    ],
  };
}

export function CharacterCard(props) {
  const { character } = props;

  const data = characterChartData(character);

  const characterCardDiv = <div className="box" >
    <div style={{ overflow: "auto" }}>
      <div style={{ float: "left", marginRight: 20, marginBottom: 20 }}>
        <p style={{ fontSize: 25 }}>오퍼레이터 개요</p>
        <div style={{ display: "flex", flexDirection: "row" }}>

          <div>
            <table style={{ bgcolor: "white" }}>
              <tr>
                <th>
                  <p>name</p>
                </th>
                <td>
                  <p>{character.name}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>nationality</p>
                </th>
                <td>
                  <p>{character.nationality}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>age</p>
                </th>
                <td>
                  <p>{character.age}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>callsign</p>
                </th>
                <td>
                  <p>{character.callsign}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>role</p>
                </th>
                <td>
                  <p>{character.role}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>potential tier</p>
                </th>
                <td>
                  <p>{character.potentialTier}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>potential</p>
                </th>
                <td>
                  <p>{character.totalPotential}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>tier</p>
                </th>
                <td>
                  <p>{character.tier}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>background</p>
                </th>
                <td>
                  <p>{character.background.name}</p>
                </td>
              </tr>
            </table>
            <p>교육이수</p>
            {
              character.trainingCompletion.length != 0 ? character.trainingCompletion.map(TC => (<p>{TC.name}</p>)) : <p>이수한 교육 없음</p>
            }
          </div>

          <div >
            <Radar data={data} options={CHART_OPT_DETAIL} width="380px" height="250px" />
          </div>

          <div >
            <table>
              <tr>
                <td rowSpan={3}>
                  <strong style={{ color: "red" }}>피지컬</strong>
                </td>
                <td rowSpan={3}>
                  {character.realStats.physical.toFixed(0)}
                </td>
                <td>
                  <p>체력</p>
                </td>
                <td>
                  {character.secondaryStats.life.toFixed(0)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>회피</p>
                </td>
                <td>
                  {(character.secondaryStats.evasion * 100).toFixed(1)}%
                </td>
              </tr>
              <tr>
                <td>
                  <p>이동속도</p>
                </td>
                <td>
                  {character.secondaryStats.speed.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  <strong style={{ color: "red" }}>공간지각</strong>
                </td>
                <td rowSpan={2}>
                  {character.realStats.perception.toFixed(0)}
                </td>
                <td>
                  <p>시야</p>
                </td>
                <td>
                  {character.secondaryStats.vision.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>필드비전</p>
                </td>
                <td>
                  {character.secondaryStats.fieldvision.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>
                  <strong style={{ color: "red" }}>정신력</strong>
                </td>
                <td rowSpan={3}>
                  {character.realStats.mental.toFixed(0)}
                </td>
                <td>
                  <p>전장 적응력/사기</p>
                </td>
                <td>
                  {character.secondaryStats.adaptation.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>고통 내성</p>
                </td>
                <td>
                  {character.secondaryStats.tolerance.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>제압 내성</p>
                </td>
                <td>
                  {character.secondaryStats.resistance.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong style={{ color: "red" }}>전술지능</strong>
                </td>
                <td>
                  {character.realStats.tactical.toFixed(0)}
                </td>
                <td>
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>
                  <strong style={{ color: "red" }}>사격술</strong>
                </td>
                <td rowSpan={3}>
                  {character.realStats.shooting.toFixed(0)}
                </td>
                <td>
                  <p>조준 속도</p>
                </td>
                <td>
                  {character.secondaryStats.aimspeed.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>장전 속도</p>
                </td>
                <td>
                  {character.secondaryStats.reloadspeed.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td>
                  <p>명중률</p>
                </td>
                <td>
                  {(character.secondaryStats.accuracy * 100).toFixed(1)}%
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div style={{ float: "left", marginRight: 20, marginBottom: 20 }}>
        <p style={{ fontSize: 25 }}>오퍼레이터 퍽</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <strong style={{ color: "red" }}>피지컬 퍽</strong>
            {
              Object.keys(statsPerksdata).map(key => {
                if (statsPerksdata[key]["stat"] == "physical") {
                  if (character.statsPerks[key] === 'activated') {
                    return <p>-{statsPerksdata[key]["name"]} : {statsPerksdata[key]["descr"]}</p>
                  } else {
                    return <p><del>-{statsPerksdata[key]["name"]}</del> : 피지컬이 {Math.ceil(statsPerksdata[key]["minStat"] - character.realStats.physical)}부족합니다.</p>
                  }
                }
              })
            }
            <strong style={{ color: "red" }}>공간지각 퍽</strong>
            {
              Object.keys(statsPerksdata).map(key => {
                if (statsPerksdata[key]["stat"] == "perception") {
                  if (character.statsPerks[key] === 'activated') {
                    return <p>-{statsPerksdata[key]["name"]} : {statsPerksdata[key]["descr"]}</p>
                  } else {
                    return <p><del>-{statsPerksdata[key]["name"]}</del> : 공간지각이 {Math.ceil(statsPerksdata[key]["minStat"] - character.realStats.perception)} 부족합니다</p>
                  }
                }
              })
            }
            <strong style={{ color: "red" }}>정신력 퍽</strong>
            {
              Object.keys(statsPerksdata).map(key => {
                if (statsPerksdata[key]["stat"] == "mental") {
                  if (character.statsPerks[key] === 'activated') {
                    return <p>-{statsPerksdata[key]["name"]} : {statsPerksdata[key]["descr"]}</p>
                  } else {
                    return <p><del>-{statsPerksdata[key]["name"]}</del> : 정신력이 {Math.ceil(statsPerksdata[key]["minStat"] - character.realStats.mental)} 부족합니다</p>
                  }
                }
              })
            }
            <strong style={{ color: "red" }}>전술지능 퍽</strong>
            {
              Object.keys(statsPerksdata).map(key => {
                if (statsPerksdata[key]["stat"] == "tactical") {
                  if (character.statsPerks[key] === 'activated') {
                    return <p>-{statsPerksdata[key]["name"]} : {statsPerksdata[key]["descr"]}</p>
                  } else {
                    return <p><del>-{statsPerksdata[key]["name"]}</del> : 전술지능이 {Math.ceil(statsPerksdata[key]["minStat"] - character.realStats.tactical)} 부족합니다</p>
                  }
                }
              })
            }
            <strong style={{ color: "red" }}>사격술 퍽</strong>
            {
              Object.keys(statsPerksdata).map(key => {
                if (statsPerksdata[key]["stat"] == "shooting") {
                  if (character.statsPerks[key] === 'activated') {
                    return <p>-{statsPerksdata[key]["name"]} : {statsPerksdata[key]["descr"]}</p>
                  } else {
                    return <p><del>-{statsPerksdata[key]["name"]}</del> : 사격술이 {Math.ceil(statsPerksdata[key]["minStat"] - character.realStats.shooting)} 부족합니다</p>
                  }
                }
              })
            }
          </div>
          <div>
            <strong style={{ color: "red" }}>{character.role} 오퍼레이터 퍽</strong>
            {
              Object.keys(character.operatorPerks).map(key => {
                if (character.operatorPerks[key] === 'activated') {
                  return <p>-{rolesPerksdata[key]["name"]} : {rolesPerksdata[key]["descr"]}</p>
                } else {
                  return <p>-<del>{rolesPerksdata[key]["name"]}</del> : 종합 스탯이 {(rolesPerksdata[key]["minStat"] - character.totalRealStat).toFixed(1)} 부족합니다.</p>
                }
              })
            }
          </div>
        </div>
      </div>

      <div style={{ float: "left", marginBottom: 20 }}>
        <p style={{ fontSize: 25 }}>총기 적성</p>
        {
          Object.keys(character.firearmAptitudes).map(key => {
            return <p>{key} : {character.firearmAptitudes[key]["Text"]}</p>
          })
        }
      </div>
    </div>
    <div>
      <p style={{ fontSize: 25 }}>{L('loc_ui_string_agent_background')}</p>
      <p style={{ whiteSpace: 'pre-line' }}>
        <BoldText text={character.background.desc} fontsize={15} />
      </p>
    </div>
  </div >


  return characterCardDiv;
}

export function CharacterDetail(props) {
  const { character } = props;

  return <div>
    <p style={{ fontSize: 25 }}>{L('loc_ui_string_agent_background')}</p>
    <p style={{ whiteSpace: 'pre-line' }}>
      <BoldText text={L(character.background.desc)} fontsize={15} />
    </p>
  </div>

}

export function CharacterCardCompact(props) {
  const { character, footer, onAgentDetail, turn } = props;
  const { modifier } = character;

  const data = characterChartData(character);

  return <div className="character-card-compact">
    <div className="character-card-compact-header">
      <span className="character-card-compact-name">{character.name}</span>
      <span className="inline-vertical-separator" />
      <span className="character-card-compact-callsign">{character.callsign}</span>
      <span className="inline-vertical-separator" />
      <span>lv. {character.level.cur}</span>
      <span className="character-card-compact-note-small"> ({character.level.exp}/100) </span>
      {modifier.length > 0 ? <>
        <span className="inline-vertical-separator" />
        <span className="seplist2">
          {modifier.map((mod) => {
            const data = data_agentModifiers.find((d) => d.key === mod.key);
            if (data.visible) {
              return <span key={data.key} title={data.desc}>{agentModifierText(mod, turn)}</span>
            }
            else {
              return null;
            }
          })}
        </span>
      </> : null}
      <span className="inline-vertical-separator" />
      {onAgentDetail ? <button className="character-card-compact-detail-button" onClick={() => onAgentDetail(character)}>Detail</button> : null}
    </div>
    <div className="character-card-compact-body">
      <div className="character-card-compact-note">

        <PortraitWrapper agent={character} onClick={() => onAgentDetail(character)} className="portrait-frame-small2" />
        <p>
          <span >전투력</span>
          <span className="inline-vertical-separator" />
          <span >{character.power.toFixed(1)}</span>
          <span className="character-card-compact-note-small">/{character.potentialPower}</span>
        </p>
        <p>
          <span >생명력</span>
          <span className="inline-vertical-separator" />
          <span >{character.secondaryStats.life}</span>
          <span className="character-card-compact-note-small">/{character.secondaryStats.life}</span>
        </p>
        <p>
          <span >주급</span>
          <span className="inline-vertical-separator" />
          <span >${character.salary}</span>
        </p>
        <p>
          <span >스태미나</span>
          <span className="inline-vertical-separator" />
          <span >{character.condition.toFixed(1)}</span>
          <span className="inline-vertical-separator-black" />
          <span >기분</span>
          <span className="inline-vertical-separator" />
          <span >{character.mood.toFixed(1)}</span>
        </p>
      </div>

      <div className="character-card-compact-radar">
        <Radar data={data} options={CHART_OPT_COMPACT} width="150px" height="150px" />
      </div>
      <div className="character-card-compact-profile">
        <table className="character-card-compact-profile-table">
          <tbody>
            <tr>
              <th>피지컬</th>
              <td>
                {character.realStats.physical.toFixed(1)}
                <span className="character-card-compact-profile-small">/{character.potentialStats.physical}</span>
              </td>
            </tr>
            <tr>
              <th>공간지각</th>
              <td>
                {character.realStats.perception.toFixed(1)}
                <span className="character-card-compact-profile-small">/{character.potentialStats.perception}</span>
              </td>
            </tr>
            <tr>
              <th>정신력</th>
              <td>
                {character.realStats.mental.toFixed(1)}
                <span className="character-card-compact-profile-small">/{character.potentialStats.mental}</span>
              </td>
            </tr>
            <tr>
              <th>전술지능</th>
              <td>
                {character.realStats.tactical.toFixed(1)}
                <span className="character-card-compact-profile-small">/{character.potentialStats.tactical}</span>
              </td>
            </tr>
            <tr>
              <th>사격술</th>
              <td>
                {character.realStats.shooting.toFixed(1)}
                <span className="character-card-compact-profile-small">/{character.potentialStats.shooting}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {footer ? <div className="character-card-compact-footer">
      {footer}
    </div> : null}
  </div>;
}

export function CharacterCardCompactGrid(props) {
  const { characters } = props;

  return <div className="character-card-compact-grid">
    {characters.map((ch, i) => {
      return <CharacterCardCompact key={i} character={ch} />;
    })}
  </div>;
}

export class CharacterView2 extends React.Component {
  constructor(props) {
    super(props);
    const initialstate = {};
    const rng = new Rng(Rng.randomseed());
    for (const role of roles) {
      initialstate[role.name] = true;
    }
    const names = names4.concat(names5);
    this.state = {
      rng,
      rolesSelection: initialstate,
      names,
      characters: new Array(10).fill(0).map(() => {
        const role = rng.weighted_key(roles, "weight");
        const name = rng.choice(names);
        return createCharacter2({ rng, name, role });
      }),
      fixedPotentialTier: 0,
      fixedStatTier: 0,
    }
  }

  onRoll() {
    const { rng, names, rolesSelection, fixedPotentialTier, fixedStatTier } = this.state;
    const characters = new Array(10).fill(0).map(() => {
      const opts = { rng };

      opts.name = rng.choice(names);
      opts.role = rng.weighted_key(roles.filter((r) => rolesSelection[r.name]), "weight");
      if (fixedPotentialTier) {
        opts.potentialTier = fixedPotentialTier;
      }
      if (fixedStatTier) {
        opts.statTier = fixedStatTier;
      }
      return createCharacter2(opts);
    });
    this.setState({ characters });
  }

  onAddFixedOperator() {
    const { rng, characters } = this.state;

    const op = rng.choice(fixedOperators);
    characters.push(createFixedCharacter2({ key: op.key, rng: rng }));
    this.setState({ characters });
  }

  onRoleSelect(e) {
    const { rolesSelection } = this.state
    const role = e.target.value
    rolesSelection[role] = !rolesSelection[role]

    var flag = false;
    for (const key in rolesSelection) {
      flag = rolesSelection[key] || flag;
    }

    if (!flag) {
      rolesSelection[role] = true;
    }

    this.onRoll()
  }

  onChangeStatTier(e) {
    const fixedStatTier = e.target.value;
    this.setState({ fixedStatTier: fixedStatTier });
  }

  onChangePotentialTier(e) {
    const fixedPotentialTier = e.target.value;
    this.setState({ fixedPotentialTier: fixedPotentialTier });
  }

  render() {
    const { characters, rolesSelection, tierMax, tierMin } = this.state;
    return <div>
      <p>characters2
      </p>
      <p>
        {
          Object.keys(rolesSelection).map((key) => {
            return <label style={{ marginRight: 10 }}>{key}<input type='checkbox' value={key} onChange={this.onRoleSelect.bind(this)} checked={rolesSelection[key]} /></label>;
          })
        }
      </p>
      <p>
        Stats tier = <select defaultValue={tierMin} onChange={this.onChangeStatTier.bind(this)}>
          {
            [0, 1, 2, 3, 4, 5].map(element => {
              return <option key={element} value={element}>{element}</option>
            })
          }
        </select>
        Potentail tier = <select defaultValue={tierMax} onChange={this.onChangePotentialTier.bind(this)}>
          {
            [0, 1, 2, 3, 4].map(element => {
              return <option key={element} value={element}>{element}</option>
            })
          }
        </select>
        0이면 랜덤 수치, 그외의 숫자면 해당 숫자로 티어가 고정됩니다.
      </p>
      <button onClick={this.onRoll.bind(this)}>Roll</button>
      <button onClick={this.onAddFixedOperator.bind(this)}>고정 오퍼 추가</button>
      <CharacterCardCompactGrid characters={characters} />
      {characters.map((ch, i) => {
        return <CharacterCard key={i} character={ch} />;
      })}
    </div>
  }
}
