import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { SaveImpl } from './SaveImpl';

import './GrindView.css';

import { FirearmLabel, GearLabel, ThrowableLabel, UtilityLabel } from './GearView';
import { Rng } from './rand.mjs';
import { SimView } from './SimView';
import { gears_vest_bulletproof } from './presets_gear';
import { firearms } from './presets_firearm';
import { throwables } from './presets_throwables.mjs';
import { VIP_TMPL } from './presets_mission';
import { controlsDefault } from './sim';
import { parseQuery } from './utils.mjs';

import { v2 } from './v2';

import { createMarketItemTy } from './RoguelikeGame';
import { options as data_itemOptions } from './data/google/processor/data_itemOptions.mjs';
import { items as data_items } from './data/google/processor/data_items.mjs';
import { scheduleCEOs as data_scheduleCEOs } from './data/google/processor/data_scheduleCEO.mjs';
import { scheduleAgents as data_scheduleAgents } from './data/google/processor/data_scheduleAgent.mjs';
import { eventCores as data_eventCores } from './data/google/processor/data_eventCore.mjs';
import { eventBranches as data_eventBranches } from './data/google/processor/data_eventBranch.mjs';
import { agentModifiers as data_agentModifiers } from './data/google/processor/data_agentModifiers.mjs';
import { shopItemGroups as data_shopItemGroups } from './data/google/processor/data_shopItemGroup.mjs';
import { recruitGroups as data_recruitGroups } from './data/google/processor/data_recruitGroup.mjs';
import { potentialWeights as data_potentialWeights } from './data/google/processor/data_char2PotentialWeight.mjs';
import { agentComments as data_agentComments } from './data/google/processor/data_agentComment.mjs';
import { upgrades as data_outloopUpgrades } from './data/google/processor/data_outloopUpgrades.mjs';
import { agentModComs as data_agentModComs } from './data/google/processor/data_agentModCom.mjs';
import { agentCareOptions as data_agentCareOptions } from './data/google/processor/data_agentCareOptions.mjs';
import { eventCooldowns as data_eventCooldowns } from './data/google/processor/data_eventCooldowns.mjs';
import { getIntegrityData } from './data/google/processor/data_agentIntegrity.mjs';
import { getMoodData } from './data/google/processor/data_agentMoods.mjs';
import { getConditionData } from './data/google/processor/data_agentConditions.mjs';
import { dialogByConfigTrigger, dialogByIdx, dialogs as data_dialogs } from './data/google/processor/data_dialogs.mjs';
import { conversationsBySchedule } from './data/google/processor/data_conversations.mjs';
import { eventInstants as data_eventInstants } from './data/google/processor/data_eventInstant.mjs';
import { questPresets as data_questPresets } from './data/google/processor/data_questPreset.mjs';
import { CONFIGS } from './presets_grind.mjs';

import { TICK_PER_DAY } from './tick';
import { presets } from './presets_mission.mjs';
import { L, localeKeys, localeGet, localeSet } from './localization.mjs';
import {
  DEFAULT_FIREARM,
  DEFAULT_EQUIPMENT,
  DEFAULT_THROWABLE,
} from './character';
import { firearm_none } from './presets_firearm.mjs';
import { perk2_tactical_bonus_display, perk2_tactical_bonus_types } from './presets_perk2.mjs';
import {
  instantiateMission,
  instantiateMissionEntities,
  createMission,
} from './mission';
import {
  FH1ButtonTiny as Button,
  FH1ButtonInline as ButtonInline,
} from './component/figma/fh1';
import { agentControlsDefault } from './sim.mjs';
import { opts } from './opts.mjs';

import {
  activateCharacter2Perk, addAgentModifier, agentHasModifierEffect, agentModifierEffectValue, totalModifierEffectValue,
  changeAgentStatus, getExpectedAgentStatus,
  createCharacter2, createFixedCharacter2, removeAgentModifier, updadeCharacter2RealStats
} from './character2.mjs';
import { roles, rolesBykey } from './data/google/processor/data_char2roles.mjs';
import { operatorNames } from './data/google/processor/data_char2characterNames.mjs';

import { DialogView } from './DialogView';
import { WeeklyMeetingView } from './WeeklyMeetingView';
import { WishlistButton, CommunityButton } from './OutlinkViews';
import { AgentModifierIcon, AgentModifierBody, getGearPower } from './Badges.js';

import { Lobby2HeaderPrimary, Lobby2HeaderSecondary, Lobby2SideMenu, Lobby2Goals, Lobby2Clipboard, Lobby2Footer, LobbyRightFooter } from './FigmaLobbyView.js';

import { FigmaListView, FigmaListBody, ListRowPlan, DetailButton, RadioButton, ICON_CLOSE, Detailview, DetailView2 } from './FigmaListView';
import { FigmaRecruitView2Wrapper, FigmaAgentsView2Wrapper } from './FigmaListView';
import { FigmaSquadView2Wrapper } from './FigmaSquadView';
import { FigmaPopupView } from './FigmaPopupView';
import { FigmaInventoryView, FigmaMarketView } from './FigmaGearsView.js';
import { FigmaPlanPopupView, MakePlanStrategyHeaders } from './FigmaPlanView';
import { statsPerksdata } from './data/google/processor/data_char2statsPerks.mjs';
import { rolesPerksdata } from './data/google/processor/data_char2rolesPerks.mjs';
import { fixedOperators } from './data/google/processor/data_char2fixedOperators.mjs';
import { FigmaGearExchangeView } from './FigmaGearExchangeView.js';
import { FigmaAgentReportView } from './FigmaAgentReportView.js';
import { TooltipContext, figmaTooltipEvents, renderTooltip } from './FigmaTooltipView.js';
import { FigmaMissionResultView, FigmaMissionExpView } from './FigmaMissionResultView.js';
import { getTrustData } from './data/google/processor/data_agentTrust.mjs';
import { getRelationData } from './data/google/processor/data_agentRelations.mjs';
import { getItemSaleData } from './data/google/processor/data_shopItemSale.mjs';
import { operatorExp as data_operatorExp } from './data/google/processor/data_operatorExp.mjs';
import { trainResults as data_trainResults } from './data/google/processor/data_trainResults.mjs';
import { eventScenarios as data_eventScenarios } from './data/google/processor/data_eventScenario.mjs';
import { eventPresets as data_eventPresets } from './data/google/processor/data_eventPreset.mjs';
import { getEventTypeProbData } from './data/google/processor/data_eventTypeProb.mjs';
import { getEventTypeScore } from './data/google/processor/data_eventTypeScore.mjs';
import { getMissionMilestones } from './data/google/processor/data_missionWeeklyMilestone.mjs';

import { triggerSound, SoundButton, triggerBgm, soundprops } from './sound.mjs';
import { soundDebugEvents } from './soundData.mjs';

import { OPTS_DEFAULT } from './presets_grind.mjs';
import { GameAnalytics, EGAProgressionStatus } from 'gameanalytics';

const TPS_BASE = TICK_PER_DAY;
const AUTOSTART = true;
const AUTOLOAD = -1;
const SURVIVAL_PROB_MENTAL_MULT = 0.003;
const SURVIVAL_PROB_BONUS_MEDIC = 0.3;

import { MAIN_PASS_SCORE, MAIN_FAIL_SCORE_NORMAL, MAIN_FAIL_SCORE_LAST, SUB_BONUS_SCORE } from './GrindParams.js';

const QUEST_RESUPPLY_DURATION = 7;
const QUEST_RESUPPLY_AMOUNT = 2;

export const AUTOSAVE_SLOTS = 4;

const NORMAL_EVENT_CATEGORIES = ['daily_neutral', 'bonus_mood', 'bonus_stamina', 'bonus_relation', 'bonus_integrity', 'bonus_trust', 'penalty_mood', 'penalty_stamina', 'penalty_relation', 'penalty_integrity', 'penalty_trust', 'deepen_relation_pos', 'deepen_relation_neg', 'accident_stamina', 'dilemma_mood', 'dilemma_trust', 'dilemma_dispute', 'dilemma_stamina', 'dilemma_relation', 'dilemma_break'];
const AGENT_EVENT_TURNS = [9, 11, 13, 15, 17];

const SCHEDULES_PRIORITY = {
  'trigger_day': -3,
  'check_quest_deadline': -2,
  'resupply_quest': -1,
  'init_quest': 0,
  'balance': 2,
  'market': 3,
  'weekly_meeting': 4,
  'milestone_notice': 5,
  'mission_brief': 1,
  'final_brief': 1,
  'agents': 5,
  'agentEvents': 6,
  'agentGrowth': 7,
  'agentReport': 8,
  'checkEventSeason': 9,
}

const SCHEDULES_EVERYDAY = [
  { ty: 'trigger_day', turn: 7 },
  { ty: 'check_quest_deadline', turn: 7 },
  { ty: 'init_quest', turn: 7 },
];

const SCHEDULES_FRIDAY = [
  ...SCHEDULES_EVERYDAY,
  { ty: 'mission_brief', turn: 7 },
  { ty: 'balance', turn: 7 },
  { ty: 'market', turn: 7 },
  { ty: 'weekly_meeting', turn: 7 },
  { ty: 'milestone_notice', turn: 7 },
];

const SCHEDULES_NORMAL = [
  ...SCHEDULES_EVERYDAY,
  { ty: 'agents', turn: 7 },
  { ty: 'checkEventSeason', turn: 8 },
  { ty: 'agentGrowth', turn: 18 },
  { ty: 'agentReport', turn: 18 },
];

const SCHEDULES_FINAL = [
  ...SCHEDULES_EVERYDAY,
  { ty: 'final_brief', turn: 7 },
]

const SCHEDULES_WEEK = [
  SCHEDULES_FRIDAY,
  SCHEDULES_NORMAL,
  SCHEDULES_NORMAL,
  SCHEDULES_NORMAL,
  SCHEDULES_NORMAL,
  SCHEDULES_NORMAL,
  SCHEDULES_NORMAL,
];

const data_trainEffect = {
  train_physical: {
    physical: 1,
    perception: 0.3,
    mental: 0.7,
    tactical: 0,
    shooting: 0,
  },
  train_shooting: {
    physical: 0,
    perception: 0.7,
    mental: 0.3,
    tactical: 0,
    shooting: 1,
  },
  train_tactical: {
    physical: 0.3,
    perception: 0.7,
    mental: 0,
    tactical: 1,
    shooting: 0,
  },
}

const MISSION_DUMMYFILL = {
  difficulty: 1,
  training: 2,
  threats_min: 35,
  threats_max: 35,
  mission_level: 1,
};

const EVENT_SCENARIO_STATUS_WEIGHTS = {
  'neg': [{ status: 'mood', weight: 6 }, { status: 'integrity', weight: 4 }],
  'pos': [{ status: 'mood', weight: 6 }, { status: 'integrity', weight: 4 }],
  'neutral': [{ status: 'friendship', weight: 4 }, { status: 'hardchoice', weight: 3 }, { status: 'request', weight: 3 }],
  'nfeint': [{ status: 'mood', weight: 6 }, { status: 'integrity', weight: 4 }],
  'pfeint': [{ status: 'mood', weight: 6 }, { status: 'integrity', weight: 4 }],
};

const MISSION_FINISH_EXP_BASE = 100;
const MISSION_SURVIVED_EXP = 10;
const FINAL_MISSION_ADDITIONAL_EXP_MULTIPLIER = 20;
const MISSION_RANK_EXP = {
  damage_done: [25, 20, 10, 0],
  damage_taken: [25, 20, 10, 0],
  kill: [25, 20, 10, 0],
  heal: [25, 20, 10, 0],
};

function inventoryItem(key) {
  let item;

  const ty = key.split('_')[0];
  if (ty === 'firearm') {
    const firearm_base = firearms.find((f) => f.key === key);
    if (firearm_base) {
      const firearm = { ...firearm_base, options: [] };
      item = {
        ty: 'firearm',
        original_buy_cost: firearm.buy_cost,
        original_sell_cost: firearm.sell_cost,
        buy_discount_percent: 0,
        firearm: { ...firearm },
      };
    }
  }
  else if (ty === 'vest') {
    const equipment_base = gears_vest_bulletproof.find((g) => g.key === key);
    if (equipment_base) {
      const equipment = { ...equipment_base, options: [] };
      item = {
        ty: 'equipment',
        original_buy_cost: equipment.buy_cost,
        ogirinal_sell_cost: equipment.sell_cost,
        buy_discount_percent: 0,
        equipment: { ...equipment },
      };
    }
  }
  else if (ty === 'throwable') {
    const throwable_base = throwables.find((t) => t.key === key);
    if (throwable_base) {
      const throwable = { ...throwable_base, options: [] };
      item = {
        ty: 'throwable',
        ogirinal_buy_cost: throwable.buy_cost,
        ogirinal_sell_cost: throwable.sell_cost,
        buy_discount_percent: 0,
        throwable: { ...throwable },
      };
    }
  }
  else {
    console.error(`unknown item type : ${ty}`);
    return;
  }

  return item;
}

function keyResource(config_key, ty) {
  const config = CONFIGS.find((c) => c.key === config_key);
  if (!config) {
    return;
  }
  config_key = config.resource_key ?? config_key;
  return `${config_key}_${ty}`;
}

export function updateAgentSalary(agent, agents_avail_all) {
  agent.salary = Math.round(agent.base_salary * (1 + totalModifierEffectValue(agent, agents_avail_all, 'additional_salary_percent') / 100));
}

const TURN_START = 7;

export class GrindGame {
  constructor(state) {
    if (state) {
      Object.assign(this, state);
      if (!this.events) {
        this.events = [];
      }
      else {
        for (const event of this.events) {
          if (event.ty === 'eventAgents') {
            this.linkAgents(event.value.targetAgents);
          }
          else if (event.ty === 'eventCEO') {
            if (event.value.target) {
              const agent = this.agents_all.find((a) => a.idx === event.value.target.idx);
              if (agent) {
                event.value.target = agent;
              }
            }
          }
        }
      }
      if (this.agents_all) {
        this.linkAgents(this.agents);
        this.linkAgents(this.agents_avail_all);
        this.linkAgents(this.recruit_listings);
        if (this.squads) {
          for (const squad of this.squads) {
            this.linkAgents(squad.agents);
          }
        }

        for (const squad of this.squads) {
          const { config_key } = squad;
          for (const agent of squad.agents) {
            this.initAgentControls(agent, config_key);
          }
        }

        for (const agent of this.agents_all) {
          if (agent.firearms) {
            agent.firearm = agent.firearms[0];
            delete agent.firearms;
          }
        }
      }
      if (this.eventInstants) {
        for (const instant of this.eventInstants) {
          if (instant.agent) {
            const agent = this.agents_all.find((a) => a.idx === instant.agent.idx);
            instant.agent = agent;
          }
        }
      }
      else {
        this.eventInstants = [];
      }
      if (this.seasonEventInfo) {
        this.linkAgents(this.seasonEventInfo.targetAgents);
      }
    } else {
      this.initialize();
    }
  }

  linkAgents(agents) {
    const { agents_all } = this;
    if (!agents) {
      return;
    }

    for (let i = 0; i < agents.length; i++) {
      const agent = agents_all.find((a) => a.idx === agents[i].idx);
      if (agent) {
        agents[i] = agent;
      }
    }
  }

  initialize() {
    this.date = 0;
    this.turn = TURN_START;
    this.rng = new Rng(Rng.randomseed());

    this.characterIds = Object.keys(operatorNames).concat(Object.keys(fixedOperators));
    this.rng.shuffle(this.characterIds);

    // 스쿼드 안 들어간 용병
    this.agents = [];
    // 상점 포함 모든 용병
    this.agents_all = [];
    // 내가 보유한 용병
    this.agents_avail_all = [];

    this.recruit_listings = [];

    this.agent_idx = 0;
    this.global_modifier = [];

    this.resources = {
      balance: 0,
      agent_piece: 8,
    };
    this.income = 0;

    //아웃루프 진입할 때 남은 주간 지원금 한번에 지급하기 위해 그동안 지원금 지급 횟수 기록해둠
    this.income_count = 0;

    this.nextItemId = 0;
    this.inventories = [];

    this.market_listings = [];

    this.configs_unlocked = [opts.GRIND_SELECTED_MAP];
    this.squads = [];

    this.createEmptySquad();

    this.events = [];
    this.popups = [];

    this.pendings = [];

    this.schedule_key = '';

    this.checkpoint_pass_count = 0;
    this.last_checkpoint_passed = false;

    //trial, checkpoint, final
    this.mission_schedule_ty = '';
    this.milestone_week = 1;

    this.enable_final = false;
    this.enable_dialog = false;
    this.integrity = 50;
    this.trust = 50;
    this.triggered_event_ids = [];
    this.triggered_event_agents = [];
    this.agentStates = [];
    this.schedule_override = null;
    this.ceoSchedules = [];
    this.recruit_waitings = [];

    // 글로벌 업그레이드 목록
    this.upgrades = [];

    this.attentions = {
      new_agents: [],
      new_recruit_waitings: [],
      new_assigned_agents: [],
      new_unassigned_agents: [],
      new_items: [],
    };

    this.tutorials_seen = [];

    // 로비 버튼 언락 상태
    this.lobby = {};
    this.disable_button = {};
    this.highlight_button = {};

    this.triggered_dialogs = [];

    this.last_mission = {};

    this.debug_eventCounts = {};

    this.gameover = false;

    this.seasonEventInfo = {
      day: 0,
      type: 'normal',
      eventScenario: null,
      targetAgents: [],

      prev_season_type: 'normal',
      season_type_strikes_count: 0,
      delayed: false,
      delayed_event_count: 0,
      passed_event_count: 0,
    };

    this.eventInstants = [];
    this.dispatch_success_prob = 0;
    this.dispatch_reward_mult = 0;

    this.block_call_superior = false;
    this.event_cooldowns = {};

    this.max_quest = 1;
    this.curr_quest = 0;
    this.quests = [];

    this.enable_quest = false;
  }

  reset(config_key, idx) {
    this.missionIdx = idx ?? 0;
    GameAnalytics.addProgressionEvent(EGAProgressionStatus.Start, `scenario`, `mission${this.missionIdx}`, `day${this.date}`);

    const config = CONFIGS.find((c) => c.key === config_key);

    // 가지고 시작하는 용병
    this.addRecruitListings(keyResource(config_key, `own`));
    const own_agents = this.recruit_listings.splice(0);
    for (const agent of own_agents) {
      this.recruitAgent(agent, false);
    }

    this.squadSetConfig(0, config_key);

    this.createInventoryItems();
    this.createMarketItems();
    this.addRecruitListings(keyResource(config_key, `initial`));
    this.resetAgentStates();

    this.checkpoint_pass_count = 0;
    this.last_checkpoint_passed = false;
    //TODO: milestone
    this.milestones = config.milestones;
    this.milestone_week = 1;

    this.resources.balance = config.start_balance;
    this.income_count = 0;

    if (config.opts?.preset_squad) {
      for (const agent of own_agents) {
        this.onAssign(0, agent);
      }
    }

    this.initSchedules(config);
    this.checkSchedules();

    this.weeklyReports = [];
    this.updateWeeklyReports();

    this.lobby = { ...config.opts.lobby };
    this.disable_button = { ...config.opts.disable_button };
  }

  get config() {
    return CONFIGS.find((c) => c.key === this.squads[0].config_key);
  }

  triggerGameOver(clear, reason) {
    if (!clear) {
      GameAnalytics.addDesignEvent(`mission:${this.missionIdx}:gameover:${reason}`);
      this.dialogMaybe(['gameover']);
    }

    this.gameover = true;
    this.popups.push({ ty: 'GAMEOVER', result: clear ? 'clear' : 'fail', reason });
  }

  getUpgradeResultValue(result_key) {
    const { upgrades } = this;

    let result_value = 0;
    for (const upgrade of upgrades) {
      const data = data_outloopUpgrades.find((d) => d.key === upgrade);
      if (!data) {
        console.error(`outloopUpgrade key not found: ${upgrade}`);
        continue;
      }
      if (data.result === result_key) {
        result_value += data.result_value;
      }
    }

    return result_value;
  }

  getWeeklyOutgoing() {
    const { agents_avail_all } = this;
    let outgoing = 0;

    for (const agent of agents_avail_all) {
      outgoing += agent.salary;
    }

    return outgoing;
  }

  updateWeeklyReports() {
    const { integrity, income, agents_avail_all, agentStates, resources } = this;

    const report = {
      integrity,
      income,
      outgoing: this.getWeeklyOutgoing(),
      condition_average: agents_avail_all.length > 0 ? agents_avail_all.map((a) => a.condition).reduce((a, b) => a + b) / agents_avail_all.length : null,
      mood_average: agents_avail_all.length > 0 ? agents_avail_all.map((a) => a.mood).reduce((a, b) => a + b) / agents_avail_all.length : null,
      agentStates: agentStates.slice(),
      balance: resources.balance,
    };

    this.weeklyReports.push(report);
  }

  resetAgentStates() {
    const { agentStates, agents_all } = this;
    agentStates.splice(0, agentStates.length);
    for (const agent of agents_all) {
      agentStates.push({
        idx: agent.idx,
        power: agent.power,
        condition: agent.condition,
        mood: agent.mood,
        modifier: agent.modifier.map((m) => m.key),
        statsPerks: { ...agent.statsPerks },
        operatorPerks: { ...agent.operatorPerks },
        exp: agent.level.exp,
        level: agent.level.cur,
      })
    }
  }

  getAgentPayment(agent) {
    return Math.round(agent.base_payment * (1 + totalModifierEffectValue(agent, this.agents_avail_all, 'additional_contract_percent') / 100) + agent.payment_discount)
  }

  getItemBuyCost(item_ty, original_buy_cost, buy_discount_percent) {
    let additional_buy_cost_percent = 0;
    for (const agent of this.agents_avail_all) {
      additional_buy_cost_percent += agentModifierEffectValue(agent, 'additional_buy_cost_percent');
      additional_buy_cost_percent += agentModifierEffectValue(agent, `additional_buy_cost_percent_${item_ty}`);
    }

    return Math.round(original_buy_cost * (1 + additional_buy_cost_percent / 100) * (1 - buy_discount_percent / 100));
  }

  getItemSellCost(item_ty, original_sell_cost) {
    let additional_sell_cost_percent = 0;
    for (const agent of this.agents_avail_all) {
      additional_sell_cost_percent += agentModifierEffectValue(agent, 'additional_sell_cost_percent');
      additional_sell_cost_percent += agentModifierEffectValue(agent, `additional_sell_cost_percent_${item_ty}`);
    }

    return Math.round(original_sell_cost * (1 + additional_sell_cost_percent / 100));
  }

  createInventoryItems() {
    const { start_items } = this.config;
    for (const key of start_items) {
      let item;
      const ty = key.split('_')[0];

      if (ty === 'firearm') {
        const firearm_base = firearms.find((f) => f.key === key);
        if (firearm_base) {
          const firearm = { ...firearm_base, options: [] };
          item = { ty: 'firearm', original_buy_cost: firearm.buy_cost, original_sell_cost: firearm.sell_cost, buy_discount_percent: 0, firearm, id: this.nextItemId++ };
        }
      }
      else if (ty === 'vest') {
        const equipment_base = gears_vest_bulletproof.find((g) => g.key === key);
        if (equipment_base) {
          const equipment = { ...equipment_base, options: [] };
          item = { ty: 'equipment', original_buy_cost: equipment.buy_cost, original_sell_cost: equipment.sell_cost, buy_discount_percent: 0, equipment, id: this.nextItemId++ };
        }
      }
      else if (ty === 'throwable') {
        const throwable_base = throwables.find((t) => t.key === key);
        if (throwable_base) {
          const throwable = { ...throwable_base, options: [] };
          item = { ty: 'throwable', original_buy_cost: throwable.buy_cost, original_sell_cost: throwable.sell_cost, buy_discount_percent: 0, throwable, id: this.nextItemId++ };
        }
      }
      else {
        console.error(`unknown item type : ${ty}`);
        continue;
      }

      this.inventories.push(item);
    }
  }

  createMarketItem(key, onCreateSuccess) {
    if (!key) {
      return;
    }
    let item = null;

    const ty = key.split('_')[0];
    if (ty === 'firearm') {
      const firearm_base = firearms.find((f) => f.key === key);
      if (firearm_base) {
        const firearm = { ...firearm_base, options: [] };
        this.applyRandomOption(firearm, 'firearm');
        item = {
          ty: 'firearm',
          original_buy_cost: firearm.buy_cost,
          original_sell_cost: firearm.sell_cost,
          buy_discount_percent: 0,
          firearm: { ...firearm },
        };
      }
    }
    else if (ty === 'vest') {
      const equipment_base = gears_vest_bulletproof.find((g) => g.key === key);
      if (equipment_base) {
        const equipment = { ...equipment_base, options: [] };
        this.applyRandomOption(equipment, 'equipment');
        item = {
          ty: 'equipment',
          original_buy_cost: equipment.buy_cost,
          original_sell_cost: equipment.sell_cost,
          buy_discount_percent: 0,
          equipment: { ...equipment },
        };
      }
    }
    else if (ty === 'throwable') {
      const throwable_base = throwables.find((t) => t.key === key);
      if (throwable_base) {
        const throwable = { ...throwable_base, options: [] };
        this.applyRandomOption(throwable, 'throwable');
        item = {
          ty: 'throwable',
          original_buy_cost: throwable.buy_cost,
          original_sell_cost: throwable.sell_cost,
          buy_discount_percent: 0,
          throwable: { ...throwable },
        };
      }
    }

    if (!item) {
      console.error(`unknown item key : ${key}`);
      return;
    }

    item.id = this.nextItemId++;
    this.market_listings.push(item);
    onCreateSuccess(item);
    return;
  }

  createMarketItems() {
    const { squads } = this;
    const squad = squads[0];

    this.createMarketItemsWithId(keyResource(squad.config_key, '0'));
  }

  createMarketItemsWithId(id) {
    const { rng } = this;

    this.market_listings.splice(0, this.market_listings.length);

    const itemsIdx = [];
    let idx = 0;

    const onCreateSuccess = () => itemsIdx.push(idx++);
    const data = data_shopItemGroups.find((d) => d.id === id);
    if (!data) {
      return;
    }

    for (const key of data.always) {
      this.createMarketItem(key, onCreateSuccess);
    }
    this.createMarketItem(rng.choice(data.pick_one_0), onCreateSuccess);
    this.createMarketItem(rng.choice(data.pick_one_1), onCreateSuccess);

    const allItems = data.always.concat(data.pick_one_0).concat(data.pick_one_1);

    const types = ['firearm', 'vest', 'throwable'];
    for (const ty of types) {
      let count = 0;
      for (const agent of this.agents_avail_all) {
        count += agentModifierEffectValue(agent, `additional_market_item_${ty}`);
      }
      for (let i = 0; i < count; i++) {
        this.createMarketItem(rng.choice(allItems), onCreateSuccess);
      }
    }

    const saleData = getItemSaleData(this.market_listings.length);
    const saleItems = saleData.sale_items + this.getUpgradeResultValue('item_sale');
    for (let i = 0; i < saleItems; i++) {
      const idx = rng.integer(0, itemsIdx.length - 1);
      const saleItem = this.market_listings[itemsIdx[idx]];
      saleItem.buy_discount_percent = 50;

      itemsIdx.splice(idx, 1);
    }
  }

  getRandomItem(rate) {
    const { rng } = this;
    const items = [];

    for (const data of data_items) {
      const key = data.key;
      const item = inventoryItem(key);
      if (item && (item.firearm?.firearm_rate === rate || item.equipment?.vest_rate === rate || item.throwable?.throwable_rate === rate)) {
        this.applyRandomOption(item);
        items.push(item);
      }
    }

    if (items.length > 0) {
      const item = rng.choice(items);

      item.id = this.nextItemId++;
      this.inventories.push(item);
    }
  }

  getRandomMarketItem() {
    const { rng, market_listings } = this;
    if (market_listings.length <= 0) {
      return;
    }

    const item = rng.choice(market_listings);
    if (item) {
      this.marketListingPurchase(item, 0);
    }
  }

  getMarketItemFilter(filter_key) {
    switch (filter_key) {
      case 'ar_dmr':
        return (key) => {
          const firearm_ty = key.split('_')[1];
          return firearm_ty === 'ar' || firearm_ty === 'dmr';
        }
      case 'sg_smg':
        return (key) => {
          const firearm_ty = key.split('_')[1];
          return firearm_ty === 'sg' || firearm_ty === 'smg';
        }
      case 'bulletproof_throwable':
        return (key) => {
          const item_ty = key.split('_')[0];
          return item_ty === 'gear' || item_ty === 'throwable';
        }
      case 'all':
        return () => true;
      default:
        console.error(`not implemented item filter key - ${filter_key}`);
        return () => true;
    }
  }

  addRandomMarketItem(filter_key) {
    const { rng, squads } = this;
    const squad = squads[0];

    const data = data_shopItemGroups.find((d) => d.id === keyResource(squad.config_key, '0'));
    if (!data) {
      return;
    }

    const itemFilter = this.getMarketItemFilter(filter_key);
    const itemPool = data.always.concat(data.pick_one_0, data.pick_one_1).filter(itemFilter);
    if (itemPool.length > 0) {
      this.createMarketItem(rng.choice(itemPool), () => { });
    }
  }

  getItem(key) {
    const data_item = data_items.find((item) => item.key === key);
    const item = inventoryItem(data_item.key);
    if (item) {
      item.id = this.nextItemId++;
      this.inventories.push(item);
    }
  }

  resetRecruitListing(ids) {
    const { recruit_waitings, characterIds, squads } = this;
    const squad = squads[0];

    const listings = [];
    for (const r of this.recruit_listings) {
      characterIds.push(r.id);
    }
    this.recruit_listings = listings;
    this.addRecruitListings(ids ?? [
      keyResource(squad.config_key, `w${this.weeks()}`),
      keyResource(squad.config_key, `default`),
    ]);
  }

  createAgentById(data, id, role, potentialTier, perks) {
    const { rng } = this;
    const { prob_modifier, modifiers } = data;

    let agent = null;
    if (id in fixedOperators) {
      const opts = {
        id,
        key: fixedOperators[id].key,
        rng,
        perks,
      };
      agent = createFixedCharacter2(opts);
    } else {
      const { nationalityKey, name } = operatorNames[id];

      const opts = {
        id,
        rng,
        potentialTier,
        role,
        name,
        nationalityKey,
        perks,
      };
      agent = createCharacter2(opts);

      const addModifier = (ty) => {
        let pool = data_agentModComs.filter((d) => d.ty === ty);
        if (pool.length <= 0) {
          return;
        }
        const target = rng.weighted_key(pool, 'weight');
        this.addModifier(agent, target.key);
        return;
      }

      const addRandomTierModifier = (ty) => {
        let tiers = [
          { weight: 1, tier: 1 },
          { weight: 6, tier: 2 },
          { weight: 6, tier: 3 },
          { weight: 6, tier: 4 },
          { weight: 1, tier: 5 },
        ];

        while (tiers.length > 0) {
          const tier = rng.weighted_key(tiers, 'weight').tier;
          tiers = tiers.filter((t) => t.tier !== tier);

          let pool = data_agentModComs.filter((d) => d.ty === ty && d.tier === tier);
          if (pool.length <= 0) {
            continue;
          }
          const target = rng.weighted_key(pool, 'weight');
          this.addModifier(agent, target.key);
          return;
        }
      }

      addModifier('identity');
      if (rng.next() < 0.5) {
        addRandomTierModifier('brain');
      }
      if (rng.next() < 0.5) {
        addRandomTierModifier('body');
      }
      if (rng.next() < 0.5) {
        addModifier('misc');
      }
    }
    if (!agent) {
      return null;
    }

    agent.idx = this.agent_idx++;

    // firearms
    if (data.firearms.length > 0) {
      const firearm_key = rng.choice(data.firearms);
      const firearm = firearms.find((f) => f.key === firearm_key);
      agent.firearm = { ...firearm };
    }
    return agent;
  }

  addRecruitListings(ids) {
    if (typeof ids === 'string') {
      ids = [ids]
    }
    const { characterIds, rng, recruit_listings, agents_all } = this;

    let data = null;
    for (const id of ids) {
      data = data_recruitGroups.find((d) => d.id === id);
      if (data) {
        break;
      }
    }
    if (!data) {
      return;
    }

    const { companyRank, perks } = data;
    const size = data.size + this.getUpgradeResultValue('recruit_size');
    const potentialTierWeights = data_potentialWeights[companyRank - 1].potentialTierWeights;

    let size_fixed = data.fixed_operators.length;
    let size_gen = Math.max(0, size - size_fixed);

    for (let i = 0; i < size_fixed; i++) {
      const id = data.fixed_operators[i];
      const { role, potentialTier, modifiers } = fixedOperators[id];
      if (!characterIds.indexOf(id) === -1) {
        size_gen++;
        continue;
      }
      const agent = this.createAgentById(data, id, role, potentialTier, perks);
      if (!agent) {
        size_gen++;
        continue;
      }

      for (const modifier of modifiers) {
        this.addModifier(agent, modifier);
      }

      const characterIdx = characterIds.indexOf(id);
      characterIds.splice(characterIdx, 1);

      recruit_listings.push(agent);
      agents_all.push(agent);
    }

    let types_list = [];
    for (let i = 0; i < size_gen; i++) {
      let types = data.types;
      if (types[0] === 'every') {
        types = roles.map((r) => r.key).filter((r) => r !== 'scout');
      }

      let ty = rng.choice(types);
      const loop = Math.floor(size_gen / types.length);
      if (i < loop * types.length) {
        ty = types[Math.floor(i / loop)];
      }
      types_list.push(ty);
    }
    rng.shuffle(types_list);


    for (let i = 0; i < size_gen; i++) {
      const potentialTier = rng.weighted_key(potentialTierWeights, 'weight').tier;

      const ty = types_list[i];
      const role = roles.find((r) => r.key === ty);

      let agent = null;
      while (!agent) {
        const idCandidate = characterIds.filter((e) => !fixedOperators[e]);
        if (idCandidate.length <= 0) {
          break;
        }

        const id = rng.choice(idCandidate);
        agent = this.createAgentById(data, id, role, potentialTier, perks);
      }
      if (!agent) {
        break;
      }

      const idIdx = characterIds.indexOf(agent.id);
      characterIds.splice(idIdx, 1);

      recruit_listings.push(agent);
      agents_all.push(agent);
    }
  }

  canProgress() {
    const { schedule_key } = this;

    if (schedule_key !== '') {
      return false;
    }

    return true;
  }

  checkEventCondition(agent, event, condition, condition_value, targets) {
    const getAgentSchedule = (agent) => {
      if (this.schedule_override) {
        const selectable = this.getSelectableSchedules(agent).find((s) => s.id === this.schedule_override);
        if (selectable) {
          return selectable.type;
        }
      }
      return agent.schedule_type;
    }

    if (this.triggered_event_ids.includes(event.id)) {
      return false;
    }
    if (getAgentSchedule(agent) !== event.trigger) {
      return false;
    }
    if (targets) {
      for (const target of targets) {
        if (getAgentSchedule(agent) !== getAgentSchedule(target)) {
          return false;
        }
      }
    }
    const targetAgents = this.agents_avail_all.filter((a) => getAgentSchedule(a) === event.trigger);
    if (!targets && targetAgents.length < event.pick_agents?.length) {
      return false;
    }

    if (event.pick_agents) {
      if (targets) {
        if (event.pick_agents.length !== targets.length) {
          return false;
        }
        for (let i = 0; i < event.pick_agents.length; i++) {
          const pick_agent = event.pick_agents[i];
          if (pick_agent === 'triggered_by' && pick_agent === 'random_agent' && pick_agent === 'inherit') {
            continue;
          }
          const splits = pick_agent.split('_');
          if (splits[0] === 'mod') {
            if (!targets[i].modifier.find((m) => m.key === pick_agent)) {
              return false;
            }
          }
        }
      }
      else {
        for (const pick_agent of event.pick_agents) {
          if (pick_agent === 'triggered_by' && pick_agent === 'random_agent' && pick_agent === 'inherit') {
            continue;
          }
          const splits = pick_agent.split('_');
          if (splits[0] === 'mod') {
            const target = targetAgents.find((a) => a.idx !== agent.idx && a.modifier.find((m) => m.key === pick_agent));
            if (!target) {
              return false;
            }
          }
        }
      }
    }

    if (!condition || condition.length === 0) {
      return true;
    }

    // 특정 modifier를 붙일 수 없는 경우, 해당 modifier 선택지만 있는 이벤트가 나오지 않도록 만듧니다.
    const { config } = this;

    const eventBranches0 = data_eventBranches.filter((d) => event.branch.includes(d.id));
    const eventBranches = eventBranches0.filter((b) => {
      const denied = b.results.find((r, i) => {
        const { effect_id } = this.parseResult(r, []);
        if (effect_id !== 'add_modifier') {
          return false;
        }

        const { effects } = data_agentModifiers.find((d) => d.key === b.results_value[i]);
        const denied = effects.find((e) => config.opts.modifier_effects_denylist[e]);
        if (denied) {
          return true;
        }
        return false;
      });
      return !denied;
    });
    if (eventBranches.length === 0) {
      return false;
    }

    return this.checkConditions(agent, condition, condition_value);
  }

  checkBranchCondition(agent, branch, targetAgents) {
    if (!this.checkConditions(agent, branch.condition, branch.condition_value)) {
      return { passed: false, reason: 'condition' };
    }

    const { results, results_value } = branch;
    if (!results) {
      return { passed: true };
    }

    let balance = this.resources.balance;
    const prev_balance = balance;

    for (let i = 0; i < results.length; i++) {
      const { effect_targets, effect_id } = this.parseResult(results[i], targetAgents)

      if (effect_id === 'money') {
        balance += parseInt(results_value[i]);
      }
      else if (effect_id === 'money_all') {
        balance += parseInt(results_value[i]) * this.agents_avail_all.length;
      }
      else if (effect_id === 'salary_check') {
        for (const target of effect_targets) {
          balance += Math.round(target.salary * parseFloat(results_value[i]));
        }
      }
      else if (effect_id === 'payment_check') {
        for (const target of effect_targets) {
          const payment = this.getAgentPayment(target);
          balance -= payment;
        }
      }
      else if (effect_id === 'add_payment') {
        for (const target of effect_targets) {
          const diff = Math.round(target.salary * parseFloat(results_value[i]));
          const payment = this.getAgentPayment(target);
          if (diff + payment < 0) {
            return false;
          }
          balance -= diff;
        }
      }
      else if (effect_id === 'add_random_market_item') {
        const { squads } = this;
        const squad = squads[0];

        const data = data_shopItemGroups.find((d) => d.id === keyResource(squad.config_key, '0'));
        if (!data) {
          return { passed: false, reason: 'market_item' };
        }

        const itemFilter = this.getMarketItemFilter(results_value[i]);
        const itemPool = data.always.concat(data.pick_one_0, data.pick_one_1).filter(itemFilter);
        if (itemPool.length <= 0) {
          return { passed: false, reason: 'market_item' };
        }
      }
    }

    //선택지의 결과로 보유 현금이 감소하면서 음수로 떨어질 경우 해당 선택지를 비활성화한다
    return { passed: (prev_balance <= balance) || (balance >= 0), reason: 'money' };
  }

  checkConditions(agent, conditions, condition_values) {
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i];
      const condition_value = (condition === 'agent_modifier' || condition === 'agent_not_modifier') ? condition_values[i] : parseInt(condition_values[i]);
      if (!this.checkCondition(agent, condition, condition_value)) {
        return false;
      }
    }
    return true;
  }

  checkCondition(agent, condition, condition_value) {
    switch (condition) {
      case 'condition_lower_than':
        return agent.condition < condition_value;
      case 'condition_higher_than':
        return agent.condition > condition_value;
      case 'mood_lower_than':
        return agent.mood < condition_value;
      case 'mood_higher_than':
        return agent.mood > condition_value;
      case 'agent_modifier':
        return agent.modifier.find((m) => m.key === condition_value);
      case 'agent_not_modifier':
        return !agent.modifier.find((m) => m.key === condition_value);
      case 'integrity_lower_than':
        return this.integrity < condition_value;
      case 'integrity_higher_than':
        return this.integrity > condition_value;
      case 'trust_lower_than':
        return this.trust < condition_value;
      case 'trust_higher_than':
        return this.trust > condition_value;
      case 'checkpoint_closer_than':
        // TODO
        // return this.checkpoint_index < checkpoints.length && (this.checkpoint_turn - this.turn) < condition_value * TICK_PER_DAY;
        return false;
      case 'money_more_than':
        return this.resources.balance > condition_value;
      case 'money_less_than':
        return this.resources.balance < condition_value;
      case 'avgmood_lower_than':
        return this.agents_avail_all.length > 0 && this.getAverageMood() < condition_value;
      case 'avgmood_higher_than':
        return this.agents_avail_all.length > 0 && this.getAverageMood() > condition_value;
      case 'relation_lower_than':
        return agent.relation < condition_value;
      case 'relation_higher_than':
        return agent.relation > condition_value;
      case 'nego_not_triggered':
        return agent.payment_discount === 0;
      case 'market_not_empty':
        return this.market_listings.length > 0;
      case '':
        return true;
      default:
        console.error(`not implemented event condition key - ${condition}`);
        return false;
    }
  }

  getRandomMissionEvent(mission_ty, condition, squad) {
    const { rng } = this;
    const events = rng.shuffle(data_eventCores.filter((e) => e.trigger_group === 'after_simul' && e.trigger === mission_ty && e.condition.includes(condition)));
    for (const event of events) {
      if (rng.range(0, 1) <= event.prob) {
        const targetAgents = this.selectEventTargets(null, event, [], squad.agents);
        this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
        return;
      }
    }
  }

  selectEventTargets(agent, event, prev_targets, target_agents) {
    const { agents_avail_all, rng } = this;

    const getAgentSchedule = (agent) => {
      if (this.schedule_override) {
        const selectable = this.getSelectableSchedules(agent).find((s) => s.id === this.schedule_override);
        if (selectable) {
          return selectable.type;
        }
      }
      return agent.schedule_type;
    }

    let targetAgents = target_agents ? target_agents.slice : agents_avail_all.filter((a) => (event.trigger_group !== 'schedule_agent' || getAgentSchedule(a) === event.trigger) && a.idx !== agent?.idx);
    const targets = [];
    let inherit_idx = 0;
    let target = null;
    for (const pick_agent of event.pick_agents) {
      const splits = pick_agent.split('_');
      if (splits[0] === 'mod') {
        const agentGroup = targetAgents.filter((a) => a.idx !== agent.idx && a.modifier.find((m) => m.key === pick_agent));
        if (agentGroup.length > 0) {
          target = rng.choice(agentGroup);
        }
      }
      else if (splits[0] === 'relation') {
        if (splits[1] === 'higher') {
          const condition_value = parseFloat(splits[3]);
          target = rng.shuffle(targetAgents).find((a) => a.relation > condition_value);
        }
        else if (splits[1] === 'lower') {
          const condition_value = parseFloat(splits[3]);
          target = rng.shuffle(targetAgents).find((a) => a.relation < condition_value);
        }
      }
      else if (splits[1] === 'lowest') {
        targetAgents.sort((a, b) => a[splits[0]] - b[splits[0]]);
        target = targetAgents[0];
      }
      else if (splits[1] === 'highest') {
        targetAgents.sort((a, b) => b[splits[0]] - a[splits[0]]);
        target = targetAgents[0];
      }
      else {
        switch (pick_agent) {
          case 'triggered_by':
            target = agent;
            break;
          case 'random_agent':
            target = rng.choice(targetAgents);
            break;
          case 'inherit':
            target = prev_targets[inherit_idx++]
            break;
          default:
            break;
        }
      }
      if (target) {
        targets.push(target);
        targetAgents = targetAgents.filter((a) => a !== target);
      }
    }

    return targets;
  }

  parseResult(result, targets) {
    let effect_targets = targets;
    let effect_id = result;
    const splits = result.split('_');
    if (splits[0] === 'agent') {
      if (splits[1] === 'all') {
        effect_targets = this.agents_avail_all;
      }
      else {
        effect_targets = [targets[parseInt(splits[1])]];
      }
      splits.splice(0, 2);
      effect_id = splits.join('_');
    }

    return { effect_targets, effect_id };
  }

  applyEventBranchEffect(branch, targets, parent_event) {
    const { results, results_value } = branch;
    if (!results) {
      return;
    }

    for (let i = 0; i < results.length; i++) {

      const { effect_targets, effect_id } = this.parseResult(results[i], targets)

      if (effect_id === 'trigger_event') {
        let { results_weight } = branch;
        if (results_weight[0] === 'agentRelations') {
          const relationData = getRelationData(effect_targets[0].relation);
          if (relationData) {
            results_weight = [relationData.prob_success, 1 - relationData.prob_success];
          }
        }
        const event_id = results_value[this.rng.weighted(results_weight)];
        const event = data_eventCores.find((e) => e.id === event_id);
        if (event) {
          const src_ty = parent_event.src_ty ?? parent_event.ty;
          const targetAgents = this.selectEventTargets(null, event, targets);
          this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents, triggerSoundOnEvent: false }, src_ty });
        }
      }
      else if (effect_id === 'integrity') {
        this.changeIntegrity(parseFloat(results_value[i]));
      }
      else if (effect_id === 'update_shop') {
        this.createMarketItems();
      }
      else if (effect_id === 'money') {
        this.resources.balance += parseInt(results_value[i]);
      }
      else if (effect_id === 'money_all') {
        this.resources.balance += parseInt(results_value[i]) * this.agents_avail_all.length;
      }
      else if (effect_id === 'weekly_income_money') {
        this.resources.balance += Math.round(this.income * parseFloat(results_value[i]));
      }
      else if (effect_id === 'add_recruit') {
        this.addRecruitListings(results_value[i]);
      }
      else if (effect_id === 'get_random_item') {
        this.getRandomItem(parseInt(results_value[i]));
      }
      else if (effect_id === 'get_item') {
        this.getItem(results_value[i]);
      }
      else if (effect_id === 'lobby') {
        this.lobby[results_value[i]] = true;
      }
      else if (effect_id === 'trust') {
        this.changeTrust(parseFloat(results_value[i]));
      }
      else if (effect_id === 'get_random_market_item') {
        this.getRandomMarketItem();
      }
      else if (effect_id === 'recruit_token') {
        this.resources.agent_piece += parseInt(results_value[i]);
      }
      else if (effect_id === 'block_ceo_schedule') {
        let schedule_id = results_value[i];
        const schedule = data_scheduleCEOs.find((d) => d.id === schedule_id);
        if (schedule?.target === 'agent' && effect_targets?.length > 0) {
          schedule_id = `${results_value[i]}_${effect_targets[0].idx}`;
        }

        this.ceoSchedules.push(schedule_id);
      }
      else if (effect_id === 'release_ceo_schedule') {
        let schedule_id = results_value[i];
        const idx = this.ceoSchedules.findIndex((s) => s === schedule_id);
        if (idx >= 0) {
          this.ceoSchedules.splice(idx, 1);
        }
      }
      else if (effect_id === 'add_random_market_item') {
        this.addRandomMarketItem(results_value[i])
      }
      else if (effect_id === 'gameover') {
        this.triggerGameOver(false, 'event');
      }
      else if (effect_id === 'set_dispatch_success_prob') {
        this.dispatch_success_prob = parseFloat(results_value[i]);
      }
      else if (effect_id === 'set_dispatch_reward_mult') {
        this.dispatch_reward_mult = parseFloat(results_value[i]);
      }
      else if (effect_id === 'get_dispatch_reward') {
        this.resources.balance += Math.round(this.income * this.dispatch_reward_mult);
      }
      else if (effect_id === 'end_scenario') {
        this.endEventScenario();
      }
      else if (effect_id === 'start_scenario') {
        this.endEventScenario();
        this.startEventScenario(results_value[i], targets.length > 0 ? targets[0] : null);
      }
      else if (effect_id === 'block_call_superior') {
        this.block_call_superior = true;
      }
      else if (effect_id === 'quest_accept') {
        this.acceptQuest();
      }
      else if (effect_id === 'quest_refuse') {
        this.refuseQuest();
      }
      else if (effect_id === 'quest_fail') {
        this.failQuest();
      }
      else {
        for (const target of effect_targets) {
          this.applyEffect(target, effect_id, results_value[i]);
        }
      }
    }

    this.chooseEventInstant();

    this.updateQuests();
  }

  applyEffect(agent, effect_id, effect_value) {
    switch (effect_id) {
      case 'condition':
        this.changeCondition(agent, parseFloat(effect_value));
        break;
      case 'mood':
        this.changeMood(agent, parseFloat(effect_value));
        break;
      case 'change_schedule':
        const data = data_scheduleAgents.find((d) => d.id === effect_value);
        agent.schedule = data.id;
        agent.schedule_type = data.type;
        break;
      case 'train_effect':
        agent.train_effect += parseFloat(effect_value);
        break;
      case 'add_modifier':
        this.addModifier(agent, effect_value);
        if (!this.agentAvail(agent)) {
          const squadIdx = this.squads.findIndex((s) => s.agents.find((a) => a.idx === agent.idx));
          if (squadIdx >= 0) {
            this.onUnassign(squadIdx, agent, 'modifier');
          }
        }
        break;
      case 'remove_modifier':
        this.removeModifier(agent, effect_value);
        break;
      case 'remove_negative_modifiers':
        this.removeAllNegativeModifiers(agent);
        break;
      case 'recruit_with_delay':
        const { recruit_listings, recruit_waitings, turn } = this;
        const idx = recruit_listings.findIndex((r) => r.idx === agent.idx);
        if (idx >= 0) {
          const delay = parseFloat(effect_value);
          if (delay > 0) {
            recruit_waitings.push({ agent: recruit_listings[idx], turn: turn + parseFloat(effect_value) * TICK_PER_DAY });
            this.recruit_listings = this.recruit_listings.filter((a) => a.idx !== agent.idx);
          }
          else {
            this.recruitAgent(agent);
          }
        }
        break;
      case 'relation':
        this.changeRelation(agent, parseFloat(effect_value));
        break;
      case 'salary_check':
        this.resources.balance += Math.round(agent.salary * parseFloat(effect_value));
        break;
      case 'add_payment':
        agent.payment_discount += Math.round(agent.salary * parseFloat(effect_value));
        break;
      case 'payment_check':
        const payment = this.getAgentPayment(agent);
        this.resources.balance -= payment;
        break;
      case 'fire':
        this.fireAgent(agent);
        break;
      case 'set_condition':
        agent.condition = parseInt(effect_value);
        break;
      default:
        console.error(`not implemented event effect key - ${effect_id}`);
        break;
    }
  }

  onTick() {
    const { rng, agents_avail_all, recruit_waitings, recruit_listings } = this;
    let paused = false;

    if (!this.canProgress()) {
      return { paused: true };
    }

    this.turn += 1;

    const next_date = Math.floor(this.turn / TICK_PER_DAY);
    if (next_date !== this.date) {
      this.changeDate(next_date);
    }
    for (let i = recruit_waitings.length - 1; i >= 0; i--) {
      const waiting = recruit_waitings[i];
      if (waiting.turn <= this.turn) {
        this.recruitAgent(waiting.agent);
        recruit_waitings.splice(i, 1);
      }
    }
    for (const agent of agents_avail_all) {
      const modifier = agent.modifier.slice();
      for (const mod of modifier) {
        if (mod.term !== 'semiperma' && mod.start + mod.term <= this.turn) {
          this.removeModifier(agent, mod.key);
        }
      }
    }

    paused = this.checkSchedules();

    return { paused };
  }

  setTurn(turn) {
    this.turn = turn;

    this.pendings = this.pendings.filter((p) => p.turn >= turn);

    const next_date = Math.floor(this.turn / TICK_PER_DAY);
    if (next_date !== this.date) {
      for (let i = this.date + 1; i <= next_date; i++) {
        this.changeDate(i);
      }
    }
  }

  changeDate(next_date) {
    GameAnalytics.addProgressionEvent(EGAProgressionStatus.Complete, `scenario`, `mission${this.missionIdx}`, `day${this.date}`);

    this.date = next_date;
    GameAnalytics.addProgressionEvent(EGAProgressionStatus.Start, `scenario`, `mission${this.missionIdx}`, `day${this.date}`);

    if (!this.enable_final) {
      this.addSchedules(next_date + SCHEDULES_WEEK.length);
    }

    this.changeIntegrity(0);

    this.triggered_event_ids.splice(0, this.triggered_event_ids.length);
    this.triggered_event_agents.splice(0, this.triggered_event_agents.length);
    this.ceoSchedules.splice(0, this.ceoSchedules.length);
  }

  initSchedules(config) {
    this.addSchedules(0, SCHEDULES_NORMAL);

    for (let i = 1; i <= SCHEDULES_WEEK.length; i++) {
      this.addSchedules(i);
    }

    if (config.pendings) {
      for (const pending of config.pendings) {
        this.pendings.push(pending);
      }
    }
    this.pendings.push({ ty: 'resupply_quest', turn: 7 + TICK_PER_DAY * QUEST_RESUPPLY_DURATION });

    this.pendings.sort((a, b) => SCHEDULES_PRIORITY[a.ty] - SCHEDULES_PRIORITY[b.ty]);
    this.pendings.sort((a, b) => a.turn - b.turn);
  }

  addSchedules(date, schedules) {
    const target_schedules = schedules ?? SCHEDULES_WEEK[date % SCHEDULES_WEEK.length];
    for (const schedule of target_schedules) {
      this.pendings.push({ ...schedule, turn: schedule.turn + date * TICK_PER_DAY });
    }
  }

  setFinalSchedules() {
    const { date, config } = this;

    //파견 결과 이벤트를 제외한 모든 기존 일정 제거
    this.pendings = this.pendings.filter((p) => (p.turn / TICK_PER_DAY - date) < 1 || (p.ty === 'agentEvents' && p.eventType === 'dispatch_result'));
    for (let i = 1; i < config.final_deadline_days; i++) {
      //실전 준비 기간에는 금요일 일정을 훈련 일정으로 대체
      this.addSchedules(date + i, SCHEDULES_NORMAL);
    }
    this.addSchedules(date + config.final_deadline_days, SCHEDULES_FINAL);

    this.pendings.sort((a, b) => SCHEDULES_PRIORITY[a.ty] - SCHEDULES_PRIORITY[b.ty]);
    this.pendings.sort((a, b) => a.turn - b.turn);
  }

  getFinalIncome() {
    const { income, trust } = this;
    const trustData = getTrustData(trust);

    return Math.round(income * trustData.income_multiplier);
  }

  checkSchedules() {
    let paused = false;
    while (this.schedule_key === '' && this.pendings.length > 0) {
      const pending = this.pendings[0];

      if (pending.turn > this.turn) {
        return paused;
      }
      this.pendings.splice(0, 1);

      switch (pending.ty) {
        case 'trigger_day': {
          this.dialogMaybe(['days', `${this.days()}`]);
          this.checkConditionalDialogs();
          break;
        }
        case 'check_quest_deadline':
          {
            if (this.enable_quest) {
              this.checkQuestDeadlines();
            }
            break;
          }
        case 'init_quest':
          {
            if (this.enable_quest) {
              const { max_quest, quests, rng } = this;
              if (max_quest > quests.length) {
                if (rng.next() <= 0.8) {
                  this.initQuest();
                }
              }
            }
            break;
          }
        case 'resupply_quest':
          {
            if (this.enable_quest) {
              this.resupplyQuest();
              this.pendings.push({ ty: 'resupply_quest', turn: pending.turn + TICK_PER_DAY * QUEST_RESUPPLY_DURATION });
            }
            break;
          }
        case 'balance':
          {
            const { resources, agents_avail_all } = this;
            resources.balance += this.getFinalIncome();
            for (const agent of agents_avail_all) {
              resources.balance -= agent.salary;
            }
            this.income_count++;
            break;
          }
        case 'market':
          this.createMarketItems();
          this.resetRecruitListing();
          break;
        case 'mission_brief':
          this.schedule_key = 'mission_brief';
          paused = true;
          break;
        case 'final_brief':
          this.schedule_key = 'final_brief';
          this.last_day = true;
          paused = true;
          break;
        case 'milestone_notice':
          this.resetMilestoneValues();
          const data = getMissionMilestones(this.config.key, this.milestone_week);
          if (data) {
            this.popups.push({ ty: 'MILESTONE_NOTICE', data, stopTimer: true });
          }
          break;
        case 'agents':
          this.schedule_key = 'agents';
          this.schedule_override = null;
          this.resetAgentStates();
          if (!this.focusLobby) {
            this.dialogMaybe(['train', `${this.date}`]);
          }
          paused = true;
          break;
        case 'checkEventSeason':
          this.checkEventSeason();
          break;
        case 'agentEvents':
          const { rng, debug_eventCounts, seasonEventInfo } = this;

          const delayScenarioEvent = (reason) => {
            seasonEventInfo.delayed = true;
            seasonEventInfo.delayed_event_count++;
            selectNormalEvent();
          };

          const selectNormalEvent = () => {
            const agents = rng.shuffle(this.agents_avail_all.slice());
            agentsLoop: for (const agent of agents) {
              if (this.triggered_event_agents.find((idx) => idx === agent.idx)) {
                continue;
              }
              const events = rng.shuffle(data_eventCores.filter((e) => NORMAL_EVENT_CATEGORIES.includes(e.category) && this.checkEventCondition(agent, e, e.condition, e.condition_value)));
              for (const event of events) {
                const targetAgents = this.selectEventTargets(agent, event, []);
                if (targetAgents.length === event.pick_agents.length) {
                  paused = true;
                  debug_eventCounts[event.category] = (debug_eventCounts[event.category] ?? 0) + 1;
                  this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
                  this.triggered_event_ids.push(event.id);
                  this.triggered_event_agents.push(targetAgents[0].idx);
                  this.schedule_key = 'event_agent';
                  break agentsLoop;
                }
              }
            }
          }
          if (pending.eventType === 'dispatch') {
            const events = rng.shuffle(data_eventCores.filter((e) => e.trigger_group === 'dispatch'));
            if (events.length <= 0) {
              break;
            }
            const event = events[0];
            const agents = this.agents_avail_all.filter((a) => this.agentAvail(a));
            if (agents.length < event.pick_agents.length) {
              break;
            }

            rng.shuffle(agents);
            paused = true;
            debug_eventCounts[event.category] = (debug_eventCounts[event.category] ?? 0) + 1;
            this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: agents.slice(0, event.pick_agents.length) } });
            this.schedule_key = 'event_agent';
            this.dialogMaybe(['dispatch']);
            break;
          }
          else if (pending.eventType === 'dispatch_result') {
            const trigger_key = (rng.next() <= this.dispatch_success_prob) ? 'success' : 'failed';
            const events = rng.shuffle(data_eventCores.filter((e) => e.trigger_group === 'dispatch_result' && e.trigger === trigger_key));
            if (events.length <= 0) {
              break;
            }
            const event = events[0];
            const agents = this.agents_avail_all.filter((a) => a.modifier.find((m) => m.key === 'mod_agent_dispatch'));
            if (agents.length !== event.pick_agents.length) {
              break;
            }
            debug_eventCounts[event.category] = (debug_eventCounts[event.category] ?? 0) + 1;
            this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: agents } });
            this.schedule_key = 'event_agent';
            break;
          }
          else if (pending.eventType === 'scenario' && !seasonEventInfo.delayed) {
            const { tags, agents } = data_eventPresets[pending.preset];
            const targetAgents = [];
            const randomAgentIdxs = [];
            for (let i = 0; i < agents.length; i++) {
              const agent_key = agents[i];
              if (agent_key === 'lead') {
                targetAgents.push(seasonEventInfo.targetAgents[0]);
              }
              else if (agent_key.substring(0, 4) === 'side') {
                targetAgents.push(seasonEventInfo.targetAgents[parseInt(agent_key[4]) + 1]);
              }
              else {
                randomAgentIdxs.push(i);
              }
            }
            for (const idx of randomAgentIdxs) {
              const agent_key = agents[idx];
              let remains;
              if (agent_key === 'random') {
                remains = this.agents_avail_all.filter((a) => !targetAgents.find((target) => target.idx === a.idx));
              }
              else {
                remains = this.agents_avail_all.filter((a) => !seasonEventInfo.targetAgents.find((target) => target.idx === a.idx) && !targetAgents.find((target) => target.idx === a.idx));
              }
              if (remains.length > 0) {
                targetAgents.push(rng.choice(remains));
              }
            }
            if (agents.length > targetAgents.length) {
              // i18n ignore 1
              delayScenarioEvent(`이벤트에 참여할 용병의 수가 모자라 시즌 이벤트 프리셋(${pending.preset})이 미뤄졌습니다.`);
              break;
            }
            const getAgentSchedule = (agent) => {
              if (this.schedule_override) {
                const selectable = this.getSelectableSchedules(agent).find((s) => s.id === this.schedule_override);
                if (selectable) {
                  return selectable.type;
                }
              }
              return agent.schedule_type;
            }
            for (const target of targetAgents) {
              if (getAgentSchedule(targetAgents[0]) !== getAgentSchedule(target)) {
                // i18n ignore 1
                delayScenarioEvent(`${targetAgents[0].name}과 ${target.name}의 일정이 달라 시즌 이벤트 프리셋(${pending.preset})이 미뤄졌습니다.`);
                break;
              }
              if (!this.agents_avail_all.find((a) => a.idx === target.idx)) {
                // i18n ignore 1
                delayScenarioEvent(`${target.name}가 보유 용병 목록에 없어 시즌 이벤트 프리셋(${pending.preset})이 미뤄졌습니다.`);
                break;
              }
            }

            const checkFilters = (event) => {
              for (const tag of tags) {
                if (!event.tags.includes(tag)) {
                  return false;
                }
              }
              return true;
            }
            const events = rng.shuffle(data_eventCores.filter((e) => checkFilters(e) && this.checkEventCondition(targetAgents[0], e, e.condition, e.condition_value, targetAgents)));
            if (events.length === 0) {
              // i18n ignore 1
              delayScenarioEvent(`시즌 이벤트 프리셋(${pending.preset})에 발동 가능한 이벤트가 없어 시즌 이벤트가 미뤄졌습니다.`);
              break;
            }

            if (!seasonEventInfo.delayed) {
              const event = events[0];
              paused = true;
              debug_eventCounts[event.category] = (debug_eventCounts[event.category] ?? 0) + 1;
              this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
              this.triggered_event_ids.push(event.id);
              this.triggered_event_agents.push(targetAgents[0].idx);
              this.schedule_key = 'event_agent';
              seasonEventInfo.passed_event_count++;
              break;
            }
          }

          selectNormalEvent();
          break;
        case 'agentGrowth':
          this.applyAgentSchedule();
          if (this.events.length > 0) {
            this.schedule_key = 'event_agent';
          }
          break;
        case 'agentReport':
          paused = true;
          this.popups.push({ ty: 'AGENT_REPORT', stopTimer: true });
          break;
        case 'weekly_meeting': {
          paused = true;
          triggerSound('UI_Outgame_Report_Weekly');
          for (const agent of this.agents_avail_all) {
            this.changeRelation(agent, 1);
          }
          const { weekly_trust } = getIntegrityData(this.integrity);
          this.changeTrust(weekly_trust);
          this.updateWeeklyReports();

          this.block_call_superior = false;

          const prev_enable_final = this.enable_final;

          this.popups.push({
            ty: 'WEEKLY_MEETING',
            trigger_enable_final: !prev_enable_final && this.enable_final,
            stopTimer: true
          });

          this.dialogMaybe(['weekly_meeting', `${this.weeks() - 1}`]);

          this.chooseEventInstant();
          break;
        }
        default:
          break;
      }
    }

    this.updateQuests();

    return paused;
  }

  debugTriggerEvent(event_id) {
    const { rng } = this;
    const agents = rng.shuffle(this.agents_avail_all.slice());

    const event = data_eventCores.find((e) => e.id === event_id);
    if (!event) {
      return false;
    }

    for (const agent of agents) {
      const targetAgents = this.selectEventTargets(agent, event, []);
      if (targetAgents.length === event.pick_agents.length) {
        this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
        this.triggered_event_ids.push(event.id);
        this.schedule_key = 'event_agent';
        return true;
      }
    }
    return false;
  }

  days() {
    return Math.floor((this.turn - TURN_START) / TICK_PER_DAY);
  }

  weeks() {
    const day = this.days();
    return Math.floor(day / 7) + 1;
  }

  // 평가전 수행 횟수
  weeks2() {
    return this.milestone_week - 1;
  }

  conversationData(schedule) {
    const { enable_dialog } = this;
    if (!enable_dialog) {
      return null;
    }

    return conversationsBySchedule(schedule);
  }

  dialogData(trigger) {
    const { enable_dialog, squads, triggered_dialogs } = this;
    if (!enable_dialog) {
      return null;
    }
    const squad = squads[0];

    const config_key = squad.config_key;

    const trigger_once_filter = (dialog) => {
      if (dialog.trigger_once) {
        if (triggered_dialogs.includes(dialog.idx)) {
          return false;
        }
      }
      if (!this.checkDialogCondition(dialog)) {
        return false;
      }
      return true;
    };

    return dialogByConfigTrigger(config_key, trigger, trigger_once_filter);
  }

  checkConditionalDialogs() {
    const { integrity, trust, agents_avail_all } = this;

    const integrityData = getIntegrityData(integrity);
    if (integrityData.idx < 2) {
      this.dialogMaybe(['low_integrity']);
    }

    const trustData = getTrustData(trust);
    if (trustData.idx < 2) {
      this.dialogMaybe(['low_trust']);
    }

    for (const agent of agents_avail_all) {
      const conditionData = getConditionData(agent.condition);
      if (conditionData.idx < 2) {
        this.dialogMaybe(['low_condition']);
      }

      const moodData = getMoodData(agent.mood);
      if (moodData.idx < 2) {
        this.dialogMaybe(['low_mood']);
      }

      for (const mod of agent.modifier) {
        if (mod.term !== 'semiperma' && data_agentModifiers.find((m) => m.key === mod.key).category === 'bad') {
          this.dialogMaybe(['bad_modifier']);
          break;
        }
      }
    }
  }

  dialogMaybe(trigger, cb) {
    const { triggered_dialogs } = this;
    const squad = this.squads[0];

    const dialog = this.dialogData(trigger);
    if (!dialog) {
      cb?.();
      return;
    }

    triggered_dialogs.push(dialog.idx);

    const result = this.applyDialogEffects(dialog.start_effects);

    if (dialog.effect_only) {
      this.applyDialogEffects(dialog.end_effects);
      cb?.();
    }
    else {
      this.events.push({
        ty: 'dialog',
        result,
        dialog,
        squadNames: squad.agents.map((a) => a.name),
        cb: () => {
          this.applyDialogEffects(dialog.end_effects);

          // 여러 다이얼로그가 하나의 trigger에 묶여 있는 경우
          const dialog_next = this.dialogData(trigger);
          if (dialog_next) {
            this.dialogMaybe(trigger, cb);
          } else {
            cb?.();
          }
        },
      });
    }

    return result;
  }

  checkDialogCondition(dialog) {
    const { condition_keys, condition_values } = dialog;
    for (let i = 0; i < condition_keys.length; i++) {
      const condition_key = condition_keys[i];
      const condition_value = condition_values[i];

      switch (condition_key) {
        case 'day_after':
          {
            if (this.date <= parseInt(condition_value)) {
              return false;
            }
          }
          break;
        case 'dialog_triggered':
          {
            const targetData = dialogByIdx(parseInt(condition_value));
            if (!this.triggered_dialogs.includes(targetData.idx)) {
              return false;
            }
          }
          break;
        case 'dialog_not_triggered':
          {
            const targetData = dialogByIdx(parseInt(condition_value));
            if (this.triggered_dialogs.includes(targetData.idx)) {
              return false;
            }
          }
          break;
        default:
          console.error(`not implemented dialog condition key - ${condition_key}`);
          break;
      }
    }
    return true;
  }

  applyDialogEffects(effects) {
    const result = {};

    for (const { effect_key, effect_value } of effects) {
      switch (effect_key) {
        case 'trigger_dialog':
          this.dialogMaybe([effect_value]);
          break;
        case 'focus_lobby':
          result.focusLobby = true;
          break;
        case 'disable_button':
          {
            const splits = effect_value.split('_');
            if (splits[0] === 'lobby' && splits[1] !== 'next') {
              this.lobby[splits[1]] = false;
            }
            else {
              this.disable_button[effect_value] = true;
            }
          }
          break;
        case 'enable_button':
          {
            const idx = effect_value.indexOf('_');
            const key = effect_value.substring(0, idx);
            const value = effect_value.substring(idx + 1);
            if (key === 'lobby' && value !== 'next') {
              this.lobby[value] = true;
            }
            else {
              this.disable_button[effect_value] = false;
            }
          }
          break;
        case 'highlight_button':
          this.highlight_button[effect_value] = true;
          break;
        case 'trigger_event':
          const eventCore = data_eventCores.find((d) => d.id === effect_value);
          if (!eventCore) {
            console.error(`eventCore not found: ${effect_value}`);
            continue;
          }
          this.events.push({ ty: 'eventCEO', value: { eventCore } });
          break;
        case 'update_recruit':
          this.resetRecruitListing([effect_value]);
          break;
        case 'update_shop':
          this.createMarketItemsWithId(effect_value);
          break;
        default:
          console.error(`not implemented dialog effect key - ${effect_key}`);
          break;
      }
    }

    return result;
  }

  addModifier(agent, key) {
    const updateSchedule = addAgentModifier(agent, key, this.turn);
    if (updateSchedule) {
      this.updateAgentSchedule(agent);
    }
    this.updateAgentAlarmLevel(agent);
    updateAgentSalary(agent, this.agents_avail_all);
  }

  removeModifier(agent, key) {
    const updateSchedule = removeAgentModifier(agent, key);
    if (updateSchedule) {
      this.updateAgentSchedule(agent);
    }
    this.updateAgentAlarmLevel(agent);
    updateAgentSalary(agent, this.agents_avail_all);
  }

  removeAllNegativeModifiers(agent) {
    const modifier = [...agent.modifier];
    for (const mod of modifier) {
      if (mod.term !== 'semiperma' && data_agentModifiers.find((m) => m.key === mod.key).category === 'bad') {
        this.removeModifier(agent, mod.key);
      }
    }
  }

  updateAgentSchedule(agent) {
    const schedules = this.getSelectableSchedules(agent);

    if (schedules.length > 0 && !schedules.find((s) => s.id === agent.schedule)) {
      agent.schedule = schedules[0].id;
      agent.schedule_type = schedules[0].type;
    }
  }

  updateAgentAlarmLevel(agent) {
    let alarm_level = 0;
    for (const m of agent.modifier) {
      const data = data_agentModifiers.find((d) => d.key === m.key);
      if (data) {
        alarm_level = Math.max(alarm_level, data.alarm_level);
      }
    }

    agent.alarm_level = alarm_level;
  }

  getSelectableSchedules(agent) {
    let schedules = data_scheduleAgents.filter((d) => d.visible);
    for (const mod of agent.modifier) {
      const data = data_agentModifiers.find((d) => d.key === mod.key);
      for (let i = 0; i < data.effects.length; i++) {
        const effect = data.effects[i];
        if (effect === 'block_schedule') {
          schedules = schedules.filter((s) => s.type !== data.effects_value[i]);
        }
        else if (effect === 'fix_schedule') {
          const schedule = data_scheduleAgents.find((d) => d.id === data.effects_value[i]);
          schedules = [schedule];
        }
      }
    }

    return schedules;
  }

  agentAvail(agent) {
    if (agentHasModifierEffect(agent, 'block_mission')) {
      return false;
    }
    return true;
  }

  applyAgentSchedule() {
    const { agents_avail_all, rng } = this;
    const getAgentSchedule = (agent) => {
      if (this.schedule_override) {
        const selectable = this.getSelectableSchedules(agent).find((s) => s.id === this.schedule_override);
        if (selectable) {
          return selectable.id;
        }
      }
      return agent.schedule;
    }

    let integrity_diff = 0;
    let schedule_condition_diff = 0;

    for (let agent of agents_avail_all) {
      const schedule = getAgentSchedule(agent);
      agent.train_result = '';
      agent.last_schedule = null;

      let result;
      const data = data_scheduleAgents.find((d) => d.id === schedule);
      schedule_condition_diff = data.condition_diff;
      if (data.type === 'train_default') {
        const conditionData = getConditionData(agent.condition);
        const moodData = getMoodData(agent.mood);
        if (rng.range(0, 1) < conditionData.prob_train_success) {
          agent.train_result = rng.range(0, 1) < moodData.prob_train_great ? 'great' : 'good';
        }
        else {
          agent.train_result = rng.range(0, 1) < moodData.prob_train_bad ? 'bad' : 'not_bad';
        }

        result = data_trainResults.find((d) => d.key === agent.train_result);

        const trainEffect = data_trainEffect[data.id];
        const realStatsDiffs = {};

        if (result.stats_mult > 0) {
          for (const element of Object.keys(agent.realStats)) {
            const train_effect = Math.max(((agent.train_effect + totalModifierEffectValue(agent, this.agents_avail_all, 'train_effect') + totalModifierEffectValue(agent, this.agents_avail_all, `train_effect_${data.id}`)) / 100)
              * (1 + this.getUpgradeResultValue('train_effect') / 100), 0);
            const statDiff = trainEffect[element] * result.stats_mult * train_effect;
            realStatsDiffs[element] = Math.min(agent.potentialStats[element] - agent.realStats[element], statDiff);
          }
          agent = updadeCharacter2RealStats(agent, { realStatsDiffs });
        }
        if (result.injury) {
          agent.last_schedule = data.id;
          const events = data_eventCores.filter((e) => e.trigger_group === 'injury' && this.checkConditions(agent, e.condition, e.condition_value));
          if (events.length > 0) {
            const event = rng.choice(events);
            const targetAgents = [agent];
            this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
          }
        }

        schedule_condition_diff += totalModifierEffectValue(agent, this.agents_avail_all, 'additional_train_condition');
      }

      this.changeCondition(agent, schedule_condition_diff);
      this.changeCondition(agent, totalModifierEffectValue(agent, this.agents_avail_all, 'daily_condition'));
      this.changeMood(agent, data.mood_diff);
      this.changeMood(agent, totalModifierEffectValue(agent, this.agents_avail_all, 'daily_mood'));
      this.addAgentExp(agent, data.exp * (result ? result.exp_mult : 1));

      agent.train_effect = 100;

      integrity_diff += agentModifierEffectValue(agent, 'daily_integrity');
    }

    this.changeIntegrity(integrity_diff);

    this.chooseEventInstant();
  }

  addAgentExp(agent, exp) {
    const exp_multiplier = 1 + totalModifierEffectValue(agent, this.agents_avail_all, 'additional_exp_percent') / 100;
    if (agent.level.cur <= data_operatorExp.length) {
      const expData = data_operatorExp[agent.level.cur - 1];
      agent.level.exp += Math.round(exp * exp_multiplier);
      if (agent.level.exp >= expData.expMax) {
        agent.level.cur++;
        agent.level.exp -= expData.expMax;

        const perks = this.makePerkSelectList(agent, []);
        this.popups.push({ ty: 'AGENT_PERK_ACQUIRE', agent_idx: agent.idx, perks, remainReroll: 1 + totalModifierEffectValue(agent, this.agents_avail_all, 'additional_perk_reroll'), stopTimer: true });
      }
    }
  }

  makePerkSelectList(agent, prev_perks) {
    const { rng } = this;

    const perks = [];
    let upgrade_perks_count, new_perks_count;
    const upgrade_perks_pool = [];
    const new_perks_pool = [];

    for (const [key, value] of Object.entries(agent.statsPerks)) {
      if (value === 'deactivated' && !prev_perks.find((d) => d.key === key)) {
        const requires = statsPerksdata[key].requires;
        if (requires.length > 0) {
          let upgradeable = true;
          for (const require_key of requires) {
            if (agent.statsPerks[require_key] !== 'activated') {
              upgradeable = false;
            }
          }
          if (upgradeable) {
            upgrade_perks_pool.push({ ty: 'stat', key });
          }
        }
        else {
          new_perks_pool.push({ ty: 'stat', key });
        }
      }
    }
    for (const [key, value] of Object.entries(agent.operatorPerks)) {
      if (value === 'deactivated' && !prev_perks.find((d) => d.key === key)) {
        const requires = rolesPerksdata[key].requires;
        if (requires.length > 0) {
          let upgradeable = true;
          for (const require_key of requires) {
            if (agent.operatorPerks[require_key] !== 'activated') {
              upgradeable = false;
            }
          }
          if (upgradeable) {
            upgrade_perks_pool.push({ ty: 'operator', key });
          }
        }
        else {
          new_perks_pool.push({ ty: 'operator', key });
        }
      }
    }

    rng.shuffle(upgrade_perks_pool);
    rng.shuffle(new_perks_pool);

    if (new_perks_pool.length < 2) {
      upgrade_perks_count = Math.min(3 - new_perks_pool.length, upgrade_perks_pool.length);
    }
    else {
      upgrade_perks_count = Math.min(1, upgrade_perks_pool.length);
    }
    for (let i = 0; i < upgrade_perks_count; i++) {
      perks.push(upgrade_perks_pool[i]);
    }

    new_perks_count = Math.min(3 - upgrade_perks_count, new_perks_pool.length);
    for (let i = 0; i < new_perks_count; i++) {
      perks.push(new_perks_pool[i]);
    }

    if (perks.length < 3) {
      rng.shuffle(prev_perks);
      for (let i = 0; i < Math.min(prev_perks.length, 3 - perks.length); i++) {
        perks.push(prev_perks[i]);
      }
    }

    return perks;
  }

  getAgentScheduleDiffs(agent) {
    const getAgentSchedule = (agent) => {
      if (this.schedule_override) {
        const selectable = game.getSelectableSchedules(agent).find((s) => s.id === this.schedule_override);
        if (selectable) {
          return selectable.id;
        }
      }
      return agent.schedule;
    }

    const schedule = getAgentSchedule(agent);

    const diffs = {};

    const data = data_scheduleAgents.find((d) => d.id === schedule);
    if (data.type === 'train_default') {
      const trainEffect = data_trainEffect[data.id];
      const realStatsDiffs = {};
      const moodData = getMoodData(agent.mood);
      for (const element of Object.keys(agent.realStats)) {
        const train_effect = Math.max(((agent.train_effect + totalModifierEffectValue(agent, this.agents_avail_all, 'train_effect') + totalModifierEffectValue(agent, this.agents_avail_all, `train_effect_${data.id}`)) / 100)
          * (1 + this.getUpgradeResultValue('train_effect') / 100), 0);
        const statDiff = trainEffect[element] * moodData.train_effect * train_effect;
        realStatsDiffs[element] = Math.min(agent.potentialStats[element] - agent.realStats[element], statDiff);
      }
      diffs.realStatsDiffs = realStatsDiffs;
    }

    diffs.condition = getExpectedAgentStatus(agent, 'condition', data.condition_diff + totalModifierEffectValue(agent, this.agents_avail_all, 'additional_train_condition')) - agent.condition;
    diffs.mood = getExpectedAgentStatus(agent, 'mood', data.mood_diff) - agent.mood;
    diffs.level = data.exp;

    return diffs;
  }

  applyRandomOption(item, ty) {
    if (!ty) {
      for (const key of ['firearm', 'equipment', 'throwable']) {
        if (item[key]) {
          ty = key;
          item = item[key];
          break;
        }
      }
    }

    const { rng } = this;
    let options = [];
    if (ty === 'firearm') {
      options = data_itemOptions.filter((d) => d.item_type === 'firearm' && d.item_group.find((i) => i === item.firearm_ty));
    }
    else if (ty === 'equipment') {
      options = data_itemOptions.filter((d) => d.item_type === 'equipment');
    }
    for (const option of options) {
      if (rng.range(0, 1) <= option.prob_generate) {
        for (const { modifier, value } of option.modifiers) {
          if (modifier === 'price') {
            item.original_buy_cost *= value;
            item.original_sell_cost *= value;
          }
          else if (item[modifier]) {
            item[modifier] *= value;
          }
        }
        item.options.push(option.name);
        return;
      }
    }
  }

  addOption(item, option_id) {
    const option = data_itemOptions.find((d) => d.id === option_id);
    for (const { modifier, value } of option.modifiers) {
      if (modifier === 'price') {
        item.original_buy_cost *= value;
        item.original_sell_cost *= value;
      }
      else if (item[modifier]) {
        item[modifier] *= value;
      }
    }
    item.options.push(option.name);
  }

  onMissionFinish(squadIdx, res, sim, onDone) {
    const { squads, turn, mission_schedule_ty } = this;
    const squad = squads[squadIdx];
    const { agents, mission_state, history } = squad;

    // const { entities } = sim;
    let entities, progress, segments_report, clear, throwables;
    if (!sim) {
      entities = [];
      progress = 0;
      segments_report = [];
      clear = res === 0;
      throwables = [];
    }
    else {
      entities = sim.entities;
      const remain = entities.filter((a) => a.team === 1 && a.state !== 'dead').length;
      progress = 1 - remain / mission_state.threats.length;
      segments_report = sim.segments_report;
      clear = sim.simover() === 0;
      throwables = sim.throwables.map((t) => { return { throwable_name: t.throwable.throwable_name, agent_idx: t.entity.idx } });
    }

    const allies = entities.filter((e) => e.team === 0);
    for (const report of segments_report) {
      if (!report.allies_detail) {
        const allies_detail = {};
        for (const ally of allies) {
          allies_detail[ally.idx] = ally.report;
        }
        report.allies_detail = allies_detail;
      }
    }

    for (const ally of allies) {
      const agent = agents.find((a) => a.idx === ally.idx);
      if (agent.history === undefined) {
        agent.history = {
          trial_kills: 0,
          checkpoint_kills: 0,
          final_kills: 0,
        };
      }
      switch (mission_schedule_ty) {
        case 'trial':
          agent.history.trial_kills += ally.report.kill;
          break;
        case 'checkpoint':
          agent.history.checkpoint_kills += ally.report.kill;
          break;
        case 'final':
          agent.history.final_kills += ally.report.kill;
          break;
      }
    }

    //TODO: gameover
    if (mission_schedule_ty === 'final') {
      if (this.config.stop_game_after_final) {
        this.triggerGameOver(clear, 'final_finish');
      }
      else {
        if (!clear) {
          this.triggerGameOver(false, 'final_finish');
        } else {
          for (const agent of this.agents_avail_all) {
            this.changeRelation(agent, 5);
          }

          if (onDone) {
            const dead_allies = allies.filter((a) => a.life <= 0);
            if (dead_allies.length > 0) {
              const medic_survived = allies.find((a) => a.role === 'medic' && a.life > 0);
              const deads = [];
              for (const ally of dead_allies) {
                const agent = this.agents_avail_all.find((a) => a.idx === ally.idx);
                agent.base_survival_prob = agent.realStats.mental * SURVIVAL_PROB_MENTAL_MULT + (medic_survived ? SURVIVAL_PROB_BONUS_MEDIC : 0);
                agent.care_option_id = 'no_care';
                deads.push(agent);
              }
              this.popups.push({ ty: 'FINAL_DEAD_CARE', deads, clear, sim });
            }
            else {
              onDone({
                result: clear ? 'clear' : 'fail',
                game: this,
                sim,
              });
            }
          }
        }
      }
    }

    else if (clear && allies.find((a) => a.state === 'dead')) {
      this.dialogMaybe(['trial_after_dead']);
    }

    if (mission_schedule_ty !== '') {
      if (res === 0) {
        this.dialogMaybe([`${mission_schedule_ty}_pass`]);
      }
      else {
        this.dialogMaybe([`${mission_schedule_ty}_fail`]);
      }
    }

    const entity_ids = entities.filter((e) => e.team === 0).map((e) => e.idx);
    const gears = entity_ids.map((idx) => {
      const agent = agents.find((e) => e.idx === idx);
      return {
        equipment_key: agent.equipment.key,
        firearm_key: agent.firearm.key,
        throwable_key: agent.throwables[0].key,
      };
    });

    const entity_exp = this.calculateMissionExp(mission_schedule_ty, segments_report, allies);
    history.push({
      ty: mission_schedule_ty,
      turn,
      res,
      mission: squad.config_key,
      progress,
      segments_report,
      entity_ids: entity_ids,
      // TODO: 세이브 고장냄
      throwables,
      gears,
      plan_chosen: squad.current_plan,
      controls_list: JSON.parse(JSON.stringify(squad.plan_controls_list[squad.current_plan])),
      plan_controls_list: JSON.parse(JSON.stringify(squad.plan_controls_list)),
      entity_exp,
    });

    this.chooseEventInstant();
  }

  calculateMissionExp(mission_schedule_ty, segments_report, allies) {
    let entity_exp = {};

    if (mission_schedule_ty === 'trial') {
      return entity_exp;
    }

    const lastIndex = segments_report.length - 1;
    const lastSegment = segments_report[lastIndex];

    let squad_survived_index = 0;
    for (let i = 0; i < allies.length; i++) {
      const ally = allies[i];
      const { idx } = ally;

      const detail = {};

      const report = lastSegment.allies_detail[idx];
      const damage_done = report.damage_done.firearm + report.damage_done.throwable;
      const damage_taken = report.damage_taken.life + report.damage_taken.shield + report.damage_taken.armor;

      for (const [key, table] of Object.entries(MISSION_RANK_EXP)) {
        const exp_max = table[0];
        let rank = table.length - 1;
        let origin_value = 0;
        switch (key) {
          case 'damage_done':
            origin_value = damage_done;
            break;
          case 'damage_taken':
            origin_value = damage_taken;
            break;
          case 'kill':
            origin_value = report.kill;
            break;
          case 'heal':
            origin_value = report.healed;
            break;
          default:
            console.error(`not implemented exp type : ${key}`);
            break;
        }

        // calculate rank
        for (let j = 0; j < i; j++) {
          const target_detail = entity_exp[allies[j].idx].detail[key];
          if (target_detail.origin > 0 && target_detail.origin >= origin_value) {
            target_detail.rank--;
            target_detail.value = table[target_detail.rank];
          }
          if (origin_value > 0 && target_detail.origin <= origin_value) {
            rank--;
          }
        }

        detail[key] = { value: table[rank], max: exp_max, origin: origin_value.toFixed(0), rank };
      }

      let deadIndex = lastIndex;
      for (; deadIndex >= squad_survived_index; deadIndex--) {
        if (segments_report[deadIndex].allies_detail[idx].life > 0) {
          squad_survived_index = deadIndex;
          if (deadIndex >= lastIndex) {
            detail.survived = { value: MISSION_SURVIVED_EXP, max: MISSION_SURVIVED_EXP, origin: true };
          }
          else {
            detail.survived = { value: 0, max: MISSION_SURVIVED_EXP, origin: false };
          }
          break;
        }
      }

      entity_exp[idx] = { detail };
    }

    const squad_exp = Math.floor(MISSION_FINISH_EXP_BASE * (squad_survived_index + 1) / segments_report.length);
    let final_mission_exp = 0;
    let final_remain = 0;
    if (mission_schedule_ty === 'final') {
      const deadline = this.config.deadline_weeks * 7;
      final_remain = deadline - this.date;
      final_mission_exp = Math.floor(MISSION_FINISH_EXP_BASE * FINAL_MISSION_ADDITIONAL_EXP_MULTIPLIER * final_remain * 2 / deadline);
    }

    for (const ally of allies) {
      const { idx } = ally;
      const exp = entity_exp[idx];

      exp.squad = { value: squad_exp, max: MISSION_FINISH_EXP_BASE, origin: squad_survived_index + 1 };
      if (mission_schedule_ty === 'final') {
        exp.final = { value: final_mission_exp, max: final_mission_exp, origin: final_remain };
      }

      let exp_total = 0;
      let exp_max_total = 0;
      for (const info of Object.values(exp.detail)) {
        exp_total += info.value;
        exp_max_total += info.max;
      }

      exp.total = { value: exp_total, max: exp_max_total };
    }

    return entity_exp;
  }

  finishMissionSchedule(res) {
    // 모의전에서 임무 클리어한 경우
    if (this.mission_schedule_ty === 'checkpoint' && res === 0) {
      this.checkpoint_pass_count += 1;
      //last_checkpoint_passed는 주간 미션 갱신 시점에 false로 초기화
      this.last_checkpoint_passed = true;
    }
  }

  resetMilestoneValues() {
    this.last_checkpoint_passed = false;
  }

  updateMilestoneStates() {
    if (this.enable_final) {
      return;
    }

    const { trust } = this;

    const main_fail_score = this.milestone_week >= this.config.deadline_weeks ? MAIN_FAIL_SCORE_LAST : MAIN_FAIL_SCORE_NORMAL;

    const states = this.getMilestoneStates();
    const scores = { main: 0, sub: 0 };

    let totalScore = 0;
    let main_pass = true;
    for (const { pass } of states.main) {
      if (!pass) {
        main_pass = false;
        break;
      }
    }
    if (!main_pass) {
      totalScore += main_fail_score;
      scores.main = main_fail_score;
    }
    else {
      totalScore += MAIN_PASS_SCORE;
      scores.main = MAIN_PASS_SCORE;

      for (const { pass } of states.sub) {
        if (pass) {
          totalScore += SUB_BONUS_SCORE;
          scores.sub += SUB_BONUS_SCORE;
        }
      }
    }
    this.changeTrust(totalScore);

    this.milestone_week++;
    if (this.milestone_week > this.config.deadline_weeks) {
      this.enable_final = true;
      this.setFinalSchedules();
    }

    return { prev_trust: trust, new_trust: this.trust, states, scores };
  }

  getMilestoneStates() {
    const { config, milestone_week, agents_avail_all, squads } = this;
    const data = getMissionMilestones(config.key, milestone_week);

    const states = { main: [], sub: [] };
    if (!data) {
      return states;
    }

    const checkObjective = (key, value) => {
      let current = 0;
      let pass = true;

      switch (key) {
        case 'checkpoint_pass_count':
          {
            current = this.checkpoint_pass_count;
            pass = current >= parseInt(value);
            break;
          }
        case 'last_checkpoint_passed':
          {
            current = this.last_checkpoint_passed ? 1 : 0;
            pass = current === parseInt(value);
            break;
          }
        case 'last_survival_rate':
          {
            const squad = squads[0];
            if (squad.history.length === 0) {
              current = 0;
              pass = false;
              break;
            }
            const segments_report = squad.history[squad.history.length - 1].segments_report;
            if (segments_report.length === 0) {
              current = 0;
              pass = false;
              break;
            }
            const report = segments_report[segments_report.length - 1];
            current = Math.floor(report.survived * 100 / report.allies_total);
            pass = current >= parseFloat(value);
            break;
          }
        case 'integrity_higher_equal':
          {
            current = integrity;
            pass = current >= parseFloat(value);
            break;
          }
        case 'average_power_higher_equal':
          {
            const squad_agents_length = squads[0].agents.length;
            current = squad_agents_length > 0 ? squads[0].agents.reduce((acc, agent) => acc + agent.power + getGearPower(agent).power, 0) / squad_agents_length : 0;
            pass = current >= parseFloat(value);
            break;
          }
        case 'trust_higher_equal':
          {
            current = trust;
            pass = current >= parseFloat(value);
            break;
          }
        case 'agent_less_equal':
          {
            current = agents_avail_all.length;
            pass = current <= parseFloat(value);
            break;
          }
        case 'money_higher_equal':
          {
            current = this.resources.balance;
            pass = current >= parseInt(value);
            break;
          }
        default:
          {
            console.error(`not implemented mission objective condition - ${key}`);
            break;
          }
      }

      return { key, value, current, pass };
    }

    for (let i = 0; i < data.main_keys.length; i++) {
      const result = checkObjective(data.main_keys[i], data.main_values[i]);
      states.main.push(result);
    }
    for (let i = 0; i < data.sub_keys.length; i++) {
      const result = checkObjective(data.sub_keys[i], data.sub_values[i]);
      states.sub.push(result);
    }

    return states;
  }

  squadDisband(squadIdx) {
    const { agents } = this.squads[squadIdx];
    for (const agent of agents) {
      agent.state = null;
      this.agents.push(agent);
    }

    this.squads.splice(squadIdx, 1);
  }

  getPlans(config_key) {
    const { plans, opts } = CONFIGS.find((c) => c.key === config_key);
    if (opts.exp_weekly_plan) {
      return this.getWeekPlans(config_key, this.weeks2());
    }
    return plans;
  }

  getWeekPlans(config_key, week) {
    const { plans } = CONFIGS.find((c) => c.key === config_key);
    const max_week = _.max(plans.map((p) => p.week));
    week = Math.min(max_week, week);
    return plans.filter((p) => p.week === week);
  }

  getPlan(config_key, plan_idx) {
    const plans = this.getPlans(config_key);
    return plans[plan_idx ?? 0];
  }

  getSegments(config_key, plan_idx) {
    return this.getPreset(config_key, plan_idx)?.segments;
  }

  getPlanPreset(config_key, plan_idx) {
    if (!config_key) {
      return null;
    }

    const map = this.getPlan(config_key, plan_idx).map;
    let preset = presets[map];
    if (typeof preset === 'function') {
      preset = preset({ plan: true });
    }

    return preset;
  }

  getPrevWeekPlanPreset(config_key, plan_idx) {
    const { opts } = CONFIGS.find((c) => c.key === config_key);
    const prevWeek = this.weeks2() - 1;
    if (!config_key || prevWeek < 0) {
      return null;
    }
    if (!opts.exp_weekly_plan) {
      return this.getPlanPreset(config_key, plan_idx);
    }

    const map = this.getWeekPlans(config_key, prevWeek)[plan_idx ?? 0].map;
    let preset = presets[map];
    if (typeof preset === 'function') {
      preset = preset({ plan: true });
    }

    return preset;
  }

  getPlanPresetDiff(config_key, plan_idx) {
    const added = [];
    const updated = [];

    if (this.weeks2() === 0) {
      return { added, updated };
    }

    const preset = this.getPlanPreset(config_key, plan_idx);
    const prev_week_preset = this.getPrevWeekPlanPreset(config_key, plan_idx);

    const enemies = preset.entities.filter((e) => e.team !== 0);
    const prev_week_enemies = prev_week_preset.entities.filter((e) => e.team !== 0);

    let enemy_start_idx = 0;
    let prev_week_enemy_start_idx = 0;

    for (let i = 0; i < preset.enemies_info.length; i++) {
      const info = preset.enemies_info[i];
      const prev_week_info = prev_week_preset.enemies_info.length > i ? prev_week_preset.enemies_info[i] : [];
      for (let idx = 0; idx < info.length; idx++) {
        const enemy_idx = enemy_start_idx + idx;
        const prev_week_enemy_idx = prev_week_enemy_start_idx + idx;
        const enemy = enemies[enemy_idx];
        if (idx < prev_week_info.length) {
          const enemy_prev = prev_week_enemies[prev_week_enemy_idx];

          const firearm_updated = enemy.firearm_name !== enemy_prev.firearm_name;
          const equipment_updated = enemy.vest_name !== enemy_prev.vest_name;

          if (firearm_updated || equipment_updated) {
            updated.push({ enemy, enemy_prev, enemy_idx, prev_week_enemy_idx, firearm_updated, equipment_updated });
          }
        }
        else {
          added.push({ enemy, enemy_idx });
        }
      }

      enemy_start_idx += info.length;
      prev_week_enemy_start_idx += prev_week_info.length;
    }

    return { added, updated };

  }

  getPreset(config_key, plan_idx) {
    if (!config_key) {
      return null;
    }

    const map = this.getPlan(config_key, plan_idx).map;
    let preset = presets[map];
    if (typeof preset === 'function') {
      preset = preset();
    }

    return preset;
  }

  resetSquadControls(squadIdx) {
    const { squads } = this;
    const squad = squads[squadIdx];

    squad.plan_controls_list = [];
    for (const agent of squad.agents) {
      agent.plan_controls_list = [];
    }

    this.initSquadControls(squadIdx);
  }

  initAgentControls(agent, config_key) {
    const plans = this.getPlans(config_key);
    const squadIdx = 0;
    const squad = this.squads[squadIdx];

    const ifResume = agent.plan_controls_list
      && agent.plan_controls_list.length === squad.plan_controls_list.length
      && agent.plan_controls_list[0].length === squad.plan_controls_list[0].length;
    const plan_controls_list = [];
    for (let plan_idx = 0; plan_idx < plans.length; plan_idx++) {
      const segments = this.getSegments(config_key, plan_idx);

      const l = [];
      for (let i = 0; i < segments.length; i++) {
        l.push(agentControlsDefault(segments[i]));
        if (agent.idx === squad.plan_controls_list[plan_idx][i].throw_idx) {
          l[i].throwable = true;
        }
        if (agent.idx === squad.plan_controls_list[plan_idx][i].breach_idx) {
          l[i].attachable = { [segments[i].doors[0]]: true };
        }
        if (agent.role === 'medic' && squad.plan_controls_list[plan_idx][i].healopts > -1) {
          l[i].heal = squad.plan_controls_list[plan_idx][i].healopts;
        }
      }
      plan_controls_list.push(l);
    }

    agent.plan_controls_list = plan_controls_list;
  }

  initSquadControls(squadIdx) {
    const squad = this.squads[squadIdx];
    const { agents, config_key } = squad;
    const plans = this.getPlans(config_key);

    const plan_controls_list = [];

    for (let plan_idx = 0; plan_idx < plans.length; plan_idx++) {
      const segments = this.getSegments(config_key, plan_idx);
      const l = [];
      for (let i = 0; i < segments.length; i++) {
        l.push(controlsDefault());
      }

      plan_controls_list.push(l);
    }
    squad.plan_controls_list = plan_controls_list;

    for (const agent of agents) {
      this.initAgentControls(agent, config_key);
    }
  }

  squadControlsList(squadIdx) {
    const squad = this.squads[squadIdx];
    let { current_plan, plan_controls_list } = squad;

    // TODO: fallback
    if (!plan_controls_list?.[current_plan]) {
      console.error('squad.plan_controls_list uninitialized');
      this.initSquadControls(squadIdx);
      ({ plan_controls_list } = squad);
    }

    return plan_controls_list?.[current_plan];
  }

  agentControlsList(squad, agent) {
    const { config_key, current_plan } = squad;
    let { plan_controls_list } = agent;
    if (!plan_controls_list) {
      console.error('agent.plan_controls_list uninitialized');
      initAgentControls(agent, config_key);
      ({ plan_controls_list } = agent);
    }

    return plan_controls_list?.[current_plan];
  }

  renewMission(squadIdx) {
    const { rng, turn, global_modifier, squads, resources, mission_schedule_ty } = this;
    const squad = squads[squadIdx];
    const { agents, current_plan } = squad;

    const config0 = CONFIGS.find((c) => c.key === squad.config_key);
    let plan_idx = 0;
    if (['sabotage0', 'sawmill1_sabotage0'].includes(squad.config_key) && typeof current_plan === 'number') {
      plan_idx = current_plan;
    }

    const plan = this.getPlan(squad.config_key, plan_idx);
    const maps = [];
    if (mission_schedule_ty !== 'final') {
      maps.push(plan.map_drill);
    } else {
      maps.push(plan.map);
    }

    let config = {
      ...config0,
      maps,
      stability_level: ["Peaceful"],
      reward_firearm_tier_min: 0, reward_firearm_tier_max: 0,
      reward_equipment_tier_min: 0, reward_equipment_tier_max: 0,
      reward_throwable_type: 'none', reward_resource_add_amount: 0,
      total_contribution_rate: 0.2, event_group: 'initial', renown_gain: 0,
      drop_item_tier: 0, drop_item_prob: 0, drop_item_ty: '',
    };

    const config_mission = { ...MISSION_DUMMYFILL, ...config };
    const mission = createMission(rng, turn, false, 0, global_modifier, [], true, config_mission);
    const instantiated = instantiateMission(rng, mission);
    const { simstate } = instantiated;

    const dialog_key = this.mission_schedule_ty === 'checkpoint' ? "checkpoint_ingame_init" : "ingame_init";
    const dialog = this.dialogData([dialog_key]);
    if (dialog) {
      this.triggered_dialogs.push(dialog.idx);
      this.applyDialogEffects(dialog.start_effects);

      if (dialog.effect_only) {
        this.applyDialogEffects(dialog.end_effects);
      }
      else {
        simstate.pending_prompts = [{
          dialog
        }];
      }
    }

    let schedule = this.mission_schedule_ty;

    // ----- dialog, bottom-dialog -----
    if (!simstate.dialog_triggers) {
      simstate.dialog_triggers = [];
    }
    const { dialog_triggers, segments, goals } = simstate;

    const push_dialog = (trigger, condition) => {
      const dialog = this.dialogData(trigger);
      if (dialog) {
        dialog_triggers.push({
          condition,
          action: 'dialog',
          actiondialogs: dialog.dialog,
        });
      }
    };

    const push_bottom_dialog = (trigger, condition) => {
      const bottom_dialog = this.dialogData(trigger);
      if (bottom_dialog) {
        dialog_triggers.push({
          condition,
          action: 'bottom-dialog',
          actiondialogs: bottom_dialog.dialog,
        });
      }
    };

    // start
    push_dialog([`ingame_dialog_${schedule}`, `start`], `start`);
    push_bottom_dialog([`ingame_conversation_${schedule}`, `start`], `start`);

    // checkpoint_start, checkpoint_clear
    for (let i = 0; i < goals.length; i++) {
      push_dialog([`ingame_dialog_${schedule}`, `checkpoint_start`, `${i}`], `checkpoint-start-${i}`);
      push_dialog([`ingame_dialog_${schedule}`, `checkpoint_clear`, `${i}`], `checkpoint-clear-${i}`);

      push_bottom_dialog([`ingame_conversation_${schedule}`, `checkpoint_start`, `${i}`], `checkpoint-start-${i}`);
      push_bottom_dialog([`ingame_conversation_${schedule}`, `checkpoint_clear`, `${i}`], `checkpoint-clear-${i}`);
    }

    // segment-start, segment-clear
    for (let i = 0; i < segments.length; i++) {
      push_dialog([`ingame_dialog_${schedule}`, `segment_start`, `${i}`], `segment-start-${i}`);
      push_dialog([`ingame_dialog_${schedule}`, `segment_clear`, `${i}`], `segment-clear-${i}`);

      push_bottom_dialog([`ingame_conversation_${schedule}`, `segment_start`, `${i}`], `segment-start-${i}`);
      push_bottom_dialog([`ingame_conversation_${schedule}`, `segment_clear`, `${i}`], `segment-clear-${i}`);
    }

    // ----- conversation -----
    if (!simstate.conversation_triggers) {
      simstate.conversation_triggers = {};
    }
    const { conversation_triggers } = simstate;

    const conversation_data = this.conversationData(schedule);
    for (const data of conversation_data) {
      const trigger = data.trigger[0];
      if (!conversation_triggers[trigger]) {
        conversation_triggers[trigger] = [];
      }
      conversation_triggers[trigger].push(data.conversations);
    }

    simstate.entities = instantiateMissionEntities(rng, turn, instantiated,
      agents, global_modifier, resources, mission_schedule_ty);
    for (const o of simstate.obstacle_specs) {
      o.imported = true;
    }

    const entities_ally = simstate.entities.filter((e) => e.team === 0);
    if (entities_ally.length > 0) {
      const [leader, ...others] = entities_ally;
      leader.default_rule = 'mission';
      for (const other of others) {
        other.leader = simstate.entities.findIndex((e) => e === leader);
        other.default_rule = 'follow-leader';
      }
    }

    // rescue
    for (let i = 0; i < simstate.spawnareas.length; i++) {
      const s = simstate.spawnareas[i];
      if (!s.rescue) {
        continue;
      }
      if (this.agent_idx < 4 || rng.range(0, 1) < 0.2) {
        const name = rng.choice(this.characterIds);
        simstate.entities.push({ ...VIP_TMPL, name, spawnarea: i });
      }
    }

    squad.mission_state = instantiated;
  }

  agentEquip(agent, item, num) {
    if (!this.inventories.find((i) => i === item)) {
      return;
    }

    const index = this.inventories.findIndex((i) => i === item);
    if (index >= 0) {
      this.inventories.splice(index, 1);
    }

    const { ty } = item;
    let { nextItemId } = this;
    if (ty === 'firearm') {
      // convert current firearm into inventory item
      this.inventories.push({ ty: 'firearm', original_buy_cost: agent.firearm.buy_cost, original_sell_cost: agent.firearm.sell_cost, firearm: agent.firearm, id: nextItemId++, buy_discount_percent: 0 });
      agent.firearm = item.firearm;
    } else if (ty === 'equipment') {
      // convert current firearm into inventory item
      this.inventories.push({ ty: 'equipment', original_buy_cost: agent.equipment.buy_cost, original_sell_cost: agent.equipment.sell_cost, equipment: agent.equipment, id: nextItemId++, buy_discount_percent: 0 });
      agent.equipment = item.equipment;
    } else if (ty === 'throwable') {
      this.inventories.push({ ty: 'throwable', original_buy_cost: agent.throwables[num].buy_cost, original_sell_cost: agent.throwables[num].sell_cost, throwable: agent.throwables[num], id: nextItemId++, buy_discount_percent: 0 });
      agent.throwables[num] = item.throwable;

    } else if (ty === 'utility') {
      this.inventories.push({ ty: 'utility', original_buy_cost: agent.utilities[num].buy_cost, original_sell_cost: agent.utilities[num].sell_cost, utility: agent.utilities[num], id: nextItemId++, buy_discount_percent: 0 });
      agent.utilities[num] = item.utility;
    }
    else {
      throw new Error(`unimplmented ty=${ty}`);
    }
    this.dialogMaybe(['agent_equip', ty]);
    this.nextItemId = nextItemId;
  }

  returnItem(ty, obj) {
    const id = this.nextItemId++;
    const item = { ty, original_buy_cost: obj.buy_cost, original_sell_cost: obj.sell_cost, [ty]: obj, id, buy_discount_percent: 0 };
    this.inventories.push(item);
    return item;
  }

  agentDisarm(agent) {
    this.agentDisarmEquipment(agent);
    this.agentDisarmFirearm(agent);
    this.agentDisarmThrowables(agent);
  }

  agentDisarmFirearm(agent) {
    const item = this.returnItem('firearm', agent.firearm);
    agent.firearm = DEFAULT_FIREARM;
    return item;
  }

  agentDisarmThrowables(agent) {
    for (let i = 0; i < agent.throwables.length; i++) {
      this.agentDisarmThrowable(agent, i);
    }
    agent.throwables = [DEFAULT_THROWABLE];
  }

  agentDisarmThrowable(agent, equip_idx) {
    const item = this.returnItem('throwable', agent.throwables[equip_idx]);
    agent.throwables[equip_idx] = DEFAULT_THROWABLE;
    this.dialogMaybe(['agent_unequip', 'throwable']);
    return item;
  }

  agentDisarmEquipment(agent) {
    const item = this.returnItem('equipment', agent.equipment);
    agent.equipment = DEFAULT_EQUIPMENT;
    return item;
  }

  marketListingPurchase(item, cost) {
    const { resources } = this;

    if (resources.balance < cost) {
      return false;
    }
    resources.balance -= cost;

    this.market_listings = this.market_listings.filter((i) => i !== item);
    this.inventories.push(item);

    this.attentions.new_items.push(item);

    return true;
  }

  onUnassign(squadIdx, agent, reason) {
    const squad = this.squads[squadIdx];

    squad.agents = squad.agents.filter((a) => a !== agent);
    this.agentDisarm(agent);
    agent.squad = undefined;
    this.agents.push(agent);

    this.attentions.new_unassigned_agents.push({ agent, reason });

    this.dialogMaybe(['squad_unassign', agent.role]);
  }

  onAssign(squadIdx, agent) {
    const { squads } = this;
    const squad = squads[squadIdx];
    const { agents, config_key } = squad;

    agent.squad = squadIdx;
    agents.push(agent);

    /* TODO
    if (agents.length === 1) {
      this.attentions.new_assigned_agents.push(agent);
    }
    */

    this.initAgentControls(agent, config_key);

    this.agents = this.agents.filter((a) => a !== agent);

    this.dialogMaybe(['squad_assign', agent.role]);
  }

  changeAgentOrder(squadIdx, prevIndex, newIndex) {
    const { squads } = this;
    const squad = squads[squadIdx];
    const { agents } = squad;

    [agents[prevIndex], agents[newIndex]] = [agents[newIndex], agents[prevIndex]];
  }

  exchangeAgent(squadIdx, agent, index) {
    const { squads } = this;
    const squad = squads[squadIdx];
    const { config_key } = squad;

    this.onUnassign(squadIdx, squad.agents[index], 'player');
    this.onAssign(squadIdx, agent);
    this.changeAgentOrder(squadIdx, index, squad.agents.length - 1);
  }

  squadSetConfig(squadIdx, config_key) {
    const { squads } = this;
    const squad = squads[squadIdx];

    const config = CONFIGS.find((c) => c.key === config_key);

    squad.config_key = config_key;
    squad.size = config.squad_size;

    this.income = config.income;

    this.initSquadControls(squadIdx);
  }

  onSquadCreate(agent) {
    this.agents = this.agents.filter((a) => a.idx !== agent.idx);
    this.squads.push({
      config_key: null,
      agents: [agent],
      history: [],
      state: 'stop',
      size: 4,
      current_plan: 0,
      plan_controls_list: [],
    });
  }

  createEmptySquad() {
    this.squads.push({
      config_key: null,
      agents: [],
      history: [],
      state: 'stop',
      current_plan: 0,
      plan_controls_list: [],
    });
  }

  squadStart(squadIdx, config_key) {
    const squad = this.squads[squadIdx];
    squad.config_key = config_key;
    squad.state = 'mission';

    this.renewMission(squadIdx);
  }

  getAverageMood() {
    const { agents_avail_all } = this;
    if (agents_avail_all.length === 0) {
      return 0;
    }

    let mood_total = 0;
    for (const agent of agents_avail_all) {
      mood_total += agent.mood;
    }

    return mood_total / agents_avail_all.length;
  }

  addAgentRecruit(agent) {
    const { recruit_listings, agents_all } = this;
    recruit_listings.push(agent);
    agents_all.push(agent);
  }

  addAgent(agent) {
    const { agents, agents_avail_all } = this;
    agents.push(agent);
    agents_avail_all.push(agent);
  }

  recruitAgent(agent, needAttention = true) {
    this.addAgent(agent);

    this.recruit_listings = this.recruit_listings.filter((a) => a.idx !== agent.idx);
    if (needAttention) {
      this.attentions.new_agents.push(agent);
    }
  }

  fireAgent(agent) {
    const squadIdx = this.squads.findIndex((s) => s.agents.find((a) => a.idx === agent.idx));
    if (squadIdx >= 0) {
      this.onUnassign(squadIdx, agent, 'fire');
    }

    agent.payment_discount = 0;
    this.recruit_listings.push(agent);
    this.agents = this.agents.filter((a) => a !== agent);
    this.agents_avail_all = this.agents_avail_all.filter((a) => a !== agent);
    this.resources.agent_piece++;
  }

  checkSquadPresetable = (agent_ids, gears) => {
    const reasons = [];
    for (const idx of agent_ids) {
      const agent = this.agents_avail_all.find((a) => a.idx === idx);
      if (!agent) {
        reasons.push({ ty: 'agent', agentIdx: idx });
      } else if (!this.agentAvail(agent)) {
        reasons.push({ ty: 'agent', agentIdx: idx });
      }
    }
    const allInventories = this.inventories.slice();
    this.squads[0].agents.forEach((a) => {
      allInventories.push({ ty: 'firearm', firearm: a.firearm });
      allInventories.push({ ty: 'equipment', equipment: a.equipment });
      allInventories.push({ ty: 'throwable', throwable: a.throwables[0] });
    });
    const allInventoriesKeys = allInventories.map((item) => item[item.ty].key)
      .filter((key) => key !== 'vest_bulletproof_nothing' && key !== 'throwable_none');
    for (const gear of gears) {
      if (!allInventoriesKeys.includes(gear.firearm_key)) {
        reasons.push({ ty: 'firearm', itemKey: gear.firearm_key });
        break;
      } else {
        allInventoriesKeys.splice(allInventoriesKeys.indexOf(gear.firearm_key), 1);
      }
      if (gear.equipment_key !== 'vest_bulletproof_nothing') {
        if (!allInventoriesKeys.includes(gear.equipment_key)) {
          reasons.push({ ty: 'equipment', itemKey: gear.equipment_key });
          break;
        } else {
          allInventoriesKeys.splice(allInventoriesKeys.indexOf(gear.equipment_key), 1);
        }
      }
      if (gear.throwable_key !== 'throwable_none') {
        if (!allInventoriesKeys.includes(gear.throwable_key)) {
          reasons.push({ ty: 'throwable', itemKey: gear.throwable_key });
          break;
        } else {
          allInventoriesKeys.splice(allInventoriesKeys.indexOf(gear.throwable_key), 1);
        }
      }
    }
    return { res: (reasons.length <= 0), reasons, };
  }

  checkEventSeason() {
    const { seasonEventInfo, pendings, date, rng } = this;
    let startSeasonEvent = false;

    seasonEventInfo.day++;
    seasonEventInfo.delayed = false;

    if (seasonEventInfo.type === 'normal') {
      if (seasonEventInfo.day < 1) {
        startSeasonEvent = false;
      }
      else if (seasonEventInfo.day >= 4) {
        startSeasonEvent = true;
      }
      else {
        const prob = 0.1 * Math.pow(3, seasonEventInfo.day - 1);
        startSeasonEvent = rng.next() < prob;
      }

      if (startSeasonEvent) {
        const next_type = this.selectNextEventType();

        const event_status = rng.weighted_key(EVENT_SCENARIO_STATUS_WEIGHTS[next_type], 'weight').status;
        let result = this.selectEventScenario(next_type, event_status);
        if (!result) {
          result = this.selectEventScenario(next_type, 'default');
        }

        if (result) {
          seasonEventInfo.day = 1;
          seasonEventInfo.type = next_type;
          seasonEventInfo.prev_season_type = next_type;
        }
      }
    }
    else {
      if (seasonEventInfo.eventScenario.duration + seasonEventInfo.delayed_event_count < seasonEventInfo.day) {
        seasonEventInfo.day = 1;
        seasonEventInfo.type = 'normal';
        seasonEventInfo.delayed_event_count = 0;
        seasonEventInfo.passed_event_count = 0;
      }
      else if (seasonEventInfo.delayed_event_count >= 3) {
        seasonEventInfo.day = 2;
        seasonEventInfo.type = 'normal';
        seasonEventInfo.delayed_event_count = 0;
        seasonEventInfo.passed_event_count = 0;
      }
    }

    const turns = AGENT_EVENT_TURNS.slice();

    //수요일 첫 이벤트 타이밍에는 파견 이벤트가 고정 발생
    if (date % 7 === 5) {
      pendings.push({ ty: 'agentEvents', eventType: 'dispatch', turn: turns[0] + date * TICK_PER_DAY });
      turns.splice(0, 1);
    }
    //토요일 마지막 이벤트 타이밍에는 파견 결과 이벤트가 고정 발생
    else if (date % 7 === 1) {
      pendings.push({ ty: 'agentEvents', eventType: 'dispatch_result', turn: turns[turns.length - 1] + date * TICK_PER_DAY });
      turns.splice(turns.length - 1, 1);
    }
    rng.shuffle(turns);

    if (seasonEventInfo.type === 'normal') {
      pendings.push({ ty: 'agentEvents', eventType: 'normal', turn: turns[0] + date * TICK_PER_DAY });
      if (rng.next() < 0.25) {
        pendings.push({ ty: 'agentEvents', eventType: 'normal', turn: turns[1] + date * TICK_PER_DAY });
      }
      pendings.sort((a, b) => SCHEDULES_PRIORITY[a.ty] - SCHEDULES_PRIORITY[b.ty]);
      pendings.sort((a, b) => a.turn - b.turn);
    }
    else {
      const { event_presets, event_preset_day } = seasonEventInfo.eventScenario;
      const { passed_event_count, delayed_event_count } = seasonEventInfo;
      for (let i = passed_event_count; i < event_presets.length; i++) {
        const day = parseInt(event_preset_day[i]) + delayed_event_count;
        if (day === seasonEventInfo.day) {
          pendings.push({ ty: 'agentEvents', eventType: 'scenario', preset: event_presets[i], turn: turns[0] + date * TICK_PER_DAY });
        }
      }
      pendings.sort((a, b) => SCHEDULES_PRIORITY[a.ty] - SCHEDULES_PRIORITY[b.ty]);
      pendings.sort((a, b) => a.turn - b.turn);
    }
  }

  endEventScenario() {
    const { pendings, seasonEventInfo } = this;

    if (seasonEventInfo.type !== 'normal') {
      seasonEventInfo.day = 1;
      seasonEventInfo.type = 'normal';
      seasonEventInfo.delayed_event_count = 0;
      seasonEventInfo.passed_event_count = 0;

      this.pendings = pendings.filter((p) => p.ty !== 'agentEvents' || p.eventType !== 'scenario');
    }
  }

  startEventScenario(id, lead) {
    const { seasonEventInfo, rng } = this;
    const scenario = data_eventScenarios.find((d) => d.id === id);
    if (scenario) {
      const targetAgents = this.makeScenarioTargets(scenario, lead);
      if (targetAgents.length < scenario.side_num + 1) {
        return;
      }
      seasonEventInfo.type = scenario.type;
      seasonEventInfo.eventScenario = scenario;
      seasonEventInfo.targetAgents = targetAgents;
    }
    else {
      const next_type = this.selectNextEventType();
      const event_status = rng.weighted_key(EVENT_SCENARIO_STATUS_WEIGHTS[next_type], 'weight').status;
      let result = this.selectEventScenario(next_type, event_status, lead);
      if (!result) {
        result = this.selectEventScenario(next_type, 'default', lead);
        if (!result) {
          return;
        }
      }
      seasonEventInfo.type = next_type;
    }
    seasonEventInfo.day = 0;
    seasonEventInfo.prev_season_type = scenario.type;
  }

  selectNextEventType() {
    const { seasonEventInfo, rng } = this;
    const { prev_season_type } = seasonEventInfo;

    let totalScore = 0;
    totalScore += getEventTypeScore('integrity', this.integrity);
    totalScore += getEventTypeScore('trust', this.trust);
    for (const agent of this.agents_avail_all) {
      totalScore += getEventTypeScore('mood', agent.mood);
      totalScore += getEventTypeScore('relation', agent.relation);
    }

    const data = getEventTypeProbData(totalScore);

    const seasonTypes = ['pos', 'pfeint', 'neutral', 'nfeint', 'neg'];
    const weights = [];
    let totalProb = 0;
    let next_type;
    for (const type of seasonTypes) {
      let prob;
      if (seasonEventInfo.season_type_strikes_count >= 2 && prev_season_type === type.substring(type.length - 3, type.length)) {
        prob = 0;
      }
      else {
        prob = data[type];
      }
      totalProb += prob;
      weights.push({ type, prob });
    }

    if (totalProb === 0) {
      if (prev_season_type === 'pos') {
        next_type = 'nfeint';
      }
      else {
        next_type = 'pfeint';
      }
    }
    else {
      next_type = rng.weighted_key(weights, 'prob').type;
    }

    if (prev_season_type === next_type) {
      seasonEventInfo.season_type_strikes_count++;
    }
    else {
      seasonEventInfo.season_type_strikes_count = 0;
    }

    return next_type;
  }

  selectEventScenario(type, status, lead) {
    const { rng, seasonEventInfo } = this;

    const scenarios = rng.shuffle(data_eventScenarios.filter((e) => e.type === type && e.status === status));
    for (const scenario of scenarios) {
      const targetAgents = this.makeScenarioTargets(scenario, lead);
      if (targetAgents.length < scenario.side_num + 1) {
        continue;
      }

      seasonEventInfo.eventScenario = scenario;
      seasonEventInfo.targetAgents = targetAgents;
      return true;
    }

    return false;
  }

  makeScenarioTargets(scenario, lead) {
    const { agents_avail_all, rng } = this;
    const targetAgents = [];

    const addScenarioTarget = (targetAgents, conditions, values) => {
      for (const agent of rng.shuffle(agents_avail_all.slice())) {
        if (targetAgents.find((a) => a.idx === agent.idx)) {
          continue;
        }
        let condition_check_passed = true;
        for (let i = 0; i < conditions.length; i++) {
          const condition = conditions[i];
          const value = values[i];

          switch (condition) {
            case 'mood_lower_than':
              if (agent.mood >= parseInt(value)) {
                condition_check_passed = false;
              }
              break;
            case 'mood_higher_than':
              if (agent.mood <= parseInt(value)) {
                condition_check_passed = false;
              }
              break;
            default:
              console.error(`not implemented eventScenario condtion key - ${condition}`);
              break;
          }
        }
        if (condition_check_passed) {
          targetAgents.push(agent);
          return;
        }
      }
    }

    if (lead) {
      targetAgents.push(lead);
    }
    else {
      addScenarioTarget(targetAgents, scenario.lead_cond, scenario.lead_value);
    }
    for (let i = 0; i < scenario.side_num; i++) {
      addScenarioTarget(targetAgents, scenario[`side${i}_cond`], scenario[`side${i}_value`]);
    }

    return targetAgents;
  }

  changeTrust(diff) {
    const { trust } = this;

    let trust_after = trust + diff;
    if (trust_after <= 0) {
      this.triggerGameOver(false, 'trust_low');
    }
    else if (trust_after < 20) {
      this.popups.push({ ty: 'GAMEOVER-WARNING' });
    }
    this.trust = Math.min(trust_after, 100);

    this.checkEventInstant(null, 'trust', trust, this.trust);
  }

  changeIntegrity(diff) {
    const { integrity } = this;
    this.integrity = Math.min(Math.max(integrity + diff, 0), 100);
    this.checkEventInstant(null, 'integrity', integrity, this.integrity);
  }

  changeRelation(agent, diff) {
    const { relation } = agent;
    changeAgentStatus(agent, 'relation', diff);
    this.checkEventInstant(agent, 'relation', relation, agent.relation);
  }

  changeMood(agent, diff) {
    const { mood } = agent;
    changeAgentStatus(agent, 'mood', diff);
    this.checkEventInstant(agent, 'mood', mood, agent.mood);
  }

  changeCondition(agent, diff) {
    const { condition } = agent;
    changeAgentStatus(agent, 'condition', diff);
    this.checkEventInstant(agent, 'condition', condition, agent.condition);
  }

  checkEventInstant(agent, status, prev_value, new_value) {
    const { eventInstants } = this;
    const datas = data_eventInstants.filter((d) => d.status === status);
    for (const data of datas) {
      const { condition, condition_value } = data;
      if (condition === 'lower_than') {
        if (prev_value >= condition_value && new_value < condition_value) {
          eventInstants.push({ data, agent });
        }
      }
      else if (condition === 'higher_than') {
        if (prev_value <= condition_value && new_value > condition_value) {
          eventInstants.push({ data, agent });
        }
      }
    }
  }

  chooseEventInstant() {
    const { rng, eventInstants, agents_avail_all, debug_eventCounts } = this;
    if (eventInstants.length === 0) {
      return;
    }

    rng.shuffle(eventInstants);
    eventInstants.sort((a, b) => a.data.priority - b.data.priority);
    instantLoop: for (const instant of eventInstants) {
      const { data, agent } = instant;
      const target_agent = agent ?? rng.choice(agents_avail_all);

      const events = rng.shuffle(data_eventCores.filter((e) => e.category === data.category && this.checkEventCondition(target_agent, e, e.condition, e.condition_value)));
      for (const event of events) {
        const targetAgents = this.selectEventTargets(target_agent, event, []);
        if (targetAgents.length === event.pick_agents.length) {
          debug_eventCounts[event.category] = (debug_eventCounts[event.category] ?? 0) + 1;
          this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents } });
          this.triggered_event_ids.push(event.id);
          break instantLoop;
        }
      }
    }

    eventInstants.splice(0, eventInstants.length);
  }

  isMeetingDisabled(agent) {
    const { ceoSchedules, events, lobby } = this;
    return ceoSchedules.includes('meeting_person') || events.length > 0 || agentHasModifierEffect(agent, 'block_meeting') || !lobby.agents;
  }

  initQuest() {
    const { rng, agents_avail_all, quests, missionIdx, milestone_week } = this;

    const getAgentValue = (target_agent, value_key) => {
      if (value_key === 'condition') {
        return target_agent.condition;
      }
      else if (value_key === 'mood') {
        return target_agent.mood;
      }
      else if (value_key === 'relation') {
        return target_agent.relation;
      }
      //stats
      else {
        return target_agent.realStats[value_key];
      }
    }

    const checkGenerateCondition = (condition, condition_value, target_agent) => {
      const splits = condition.split('_');
      if (splits[0] === 'modifier') {
        const has_modifier = agents_avail_all.find((a) => a.modifier.find((m) => m.key === condition_value)) ? true : false;
        if (splits[1] === 'yes') {
          return has_modifier;
        }
        else {
          return !has_modifier;
        }
      }
      else if (splits[0] === 'agent') {
        if (condition === 'agent_modifier') {
          return target_agent.modifier.find((m) => m.key === condition_value) ? true : false;
        }
        else if (condition === 'agent_not_modifier') {
          return target_agent.modifier.find((m) => m.key === condition_value) ? false : true;
        }
      }
      else if (splits[0] === 'role') {
        const current_value = agents_avail_all.filter((a) => a.role === splits[1]).length;
        const comparer_key = splits[2];
        if (comparer_key === 'lower') {
          return current_value < parseFloat(condition_value);
        }
        else if (comparer_key === 'higher') {
          return current_value > parseFloat(condition_value);
        }
        else if (comparer_key === 'noless') {
          return current_value >= parseFloat(condition_value);
        }
        else if (comparer_key === 'nomore') {
          return current_value <= parseFloat(condition_value);
        }
      }
      else {
        let targets = agents_avail_all;
        let getCurrentValue, getTargetValue;
        let current_value;
        if (splits[0] === 'avg') {
          getCurrentValue = () => {
            if (targets.length === 0) {
              return 0;
            }

            let sum = 0;
            for (const target of targets) {
              sum += getTargetValue(target);
            }
            return sum / targets.length;
          };

          splits.splice(0, 1);
        }
        else if (splits[0] === 'lowest') {
          getCurrentValue = () => {
            if (targets.length === 0) {
              return 0;
            }
            let lowest = Number.MAX_VALUE;
            for (const target of targets) {
              lowest = Math.min(lowest, getTargetValue(target));
            }
            return lowest;
          }

          splits.splice(0, 1);
        }
        else if (splits[0] === 'highest') {
          getCurrentValue = () => {
            if (targets.length === 0) {
              return 0;
            }
            let highest = Number.MIN_VALUE;
            for (const target of targets) {
              highest = Math.max(highest, getTargetValue(target));
            }
            return highest;
          }

          splits.splice(0, 1);
        }
        else {
          getCurrentValue = () => {
            return getTargetValue(target_agent);
          }
        }

        const value_key = splits[0];
        //global values
        if (value_key === 'money') {
          current_value = this.resources.balance;
        }
        else if (value_key === 'integrity') {
          current_value = this.integrity;
        }
        else if (value_key === 'trust') {
          current_value = this.trust;
        }
        //agent values
        else {
          getTargetValue = (target) => getAgentValue(target, value_key);
          current_value = getCurrentValue();
        }

        const comparer_key = splits[1];

        if (comparer_key === 'lower') {
          return current_value < parseFloat(condition_value);
        }
        else if (comparer_key === 'higher') {
          return current_value > parseFloat(condition_value);
        }
        else if (comparer_key === 'noless') {
          return current_value >= parseFloat(condition_value);
        }
        else if (comparer_key === 'nomore') {
          return current_value <= parseFloat(condition_value);
        }
      }
    }

    const getTargetAgent = (condition, condition_value, agents_pool) => {
      if (agents_pool.length === 0) {
        return null;
      }
      let targets = [];
      if (condition === 'agent_modifier') {
        targets = agents_pool.filter((a) => a.modifier.find((m) => m.key === condition_value));
      }
      else if (condition === 'agent_not_modifier') {
        targets = agents_pool.filter((a) => !a.modifier.find((m) => m.key === condition_value));
      }
      else {
        const splits = condition.split('_');

        if (splits[0] === 'highest') {
          let highest_value = Number.MIN_VALUE;
          for (const agent of agents_pool) {
            const value = getAgentValue(agent, splits[1]);
            if (value > highest_value) {
              targets = [agent];
              highest_value = value;
            }
            else if (value === highest_value) {
              targets.push(agent);
            }
          }
        }
        else if (splits[0] === 'lowest') {
          let lowest_value = Number.MAX_VALUE;
          for (const agent of agents_pool) {
            const value = getAgentValue(agent, splits[1]);
            if (value < lowest_value) {
              targets = [agent];
              lowest_value = value;
            }
            else if (value === lowest_value) {
              targets.push(agent);
            }
          }
        }
        else if (splits[0] === 'am' && splits[1] === 'role') {
          targets = agents_pool.filter((a) => a.role === splits[2]);
        }
        else {
          let agentFilter;
          const comparer_key = splits[1];
          if (comparer_key === 'lower') {
            agentFilter = (agent) => getAgentValue(agent, splits[0]) < parseFloat(condition_value);
          }
          else if (comparer_key === 'higher') {
            agentFilter = (agent) => getAgentValue(agent, splits[0]) > parseFloat(condition_value);
          }
          else if (comparer_key === 'noless') {
            agentFilter = (agent) => getAgentValue(agent, splits[0]) >= parseFloat(condition_value);
          }
          else if (comparer_key === 'nomore') {
            agentFilter = (agent) => getAgentValue(agent, splits[0]) <= parseFloat(condition_value);
          }
          targets = agents_pool.filter(agentFilter);
        }
      }

      if (targets.length === 0) {
        return null;
      }
      else {
        return rng.choice(targets);
      }
    }

    const presets = data_questPresets.filter((d) => !quests.find((q) => q.generate_condition === d.generate_condition));
    const weights = presets.map((p) => p.weight);

    while (presets.length > 0) {
      const random_index = rng.weighted(weights);
      const preset = presets[random_index];
      presets.splice(random_index, 1);
      weights.splice(random_index, 1);

      const { id, which_mission, which_week, generate_condition, generate_condition_value, complete_condition, complete_condition_value, questgiver_condition, questgiver_condition_value, target_ty } = preset;

      if (which_mission[0] !== 'all' && !which_mission.find((idx) => parseInt(idx) === missionIdx + 1)) {
        continue;
      }
      if (which_week[0] !== 'all' && !which_week.find((week) => parseInt(week) === milestone_week)) {
        continue;
      }
      if (checkGenerateCondition(preset.generate_condition, preset.generate_condition_value, null)) {
        const target_agent = getTargetAgent(preset.questgiver_condition, preset.questgiver_condition_value, this.agents_avail_all.filter((a) => !quests.find((q) => q.quest_giver_idx === a.idx)));
        if (target_agent) {
          const event = data_eventCores.find((d) => d.id === preset.start_event);
          if (event) {
            const quest = {
              id,
              generate_condition,
              complete_condition,
              complete_condition_value,
              target_ty,
              quest_giver_idx: target_agent.idx,
              start: this.turn,
              term: preset.due_day * TICK_PER_DAY
            };
            quests.push(quest);

            if (preset.target_ty === 'generator') {
              if (preset.generate_condition === 'modifier_yes') {
                quest.generator_idx = rng.choice(agents_avail_all.filter((a) => a.modifier.find((m) => m.key === preset.generate_condition_value))).idx;
              }
              else if (preset.generate_condition === 'modifier_no') {
                quest.generator_idx = rng.choice(agents_avail_all.filter((a) => !a.modifier.find((m) => m.key === preset.generate_condition_value))).idx;
              }
              else {
                quest.generator_idx = getTargetAgent(preset.generate_condition, preset.generate_condition_value, this.agents_avail_all).idx;
              }
            }

            const state = this.getQuestState(quest);
            quest.current_value = state.current;
            const value_splits = quest.complete_condition_value.split('_');
            if (value_splits.length > 0 && value_splits[0] === 'plus') {
              quest.complete_condition_value = parseFloat(value_splits[1]) + state.current;
            }

            this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: [target_agent], quest_id: id } });
            return;
          }
        }
      }
    }
  }

  updateQuests() {
    const { quests, agents_avail_all, agents_all } = this;
    for (let i = quests.length - 1; i >= 0; i--) {
      const quest = quests[i];
      const preset = data_questPresets.find((d) => d.id === quest.id);
      let quest_giver = agents_avail_all.find((a) => a.idx === quest.quest_giver_idx);
      if (!quest_giver) {
        quest_giver = agents_all.find((a) => a.idx === quest.quest_giver_idx);
        const event = data_eventCores.find((d) => d.id === preset.end_event);
        quests.splice(i, 1);
        if (quest_giver && event) {
          this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: [quest_giver] } });
          continue;
        }
      }
      else if (preset.target_ty === 'generator' && preset.complete_condition !== 'fire') {
        const generator = agents_avail_all.find((a) => a.idx === quest.generator_idx);
        if (!generator) {
          quests.splice(i, 1);
          const event = data_eventCores.find((d) => d.id === preset.end_event);
          if (event) {
            this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: [quest_giver] } });
            continue;
          }
        }
      }
      const state = this.getQuestState(quest);
      if (state.pass) {
        const event = data_eventCores.find((d) => d.id === preset.success_event);
        if (event) {
          this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: [quest_giver] } });
        }

        this.max_quest = Math.max(this.max_quest - 1, 1);
        quests.splice(i, 1);
      }
      else {
        quest.current_value = state.current;
      }
    }
  }

  checkQuestDeadlines() {
    const { quests, turn } = this;
    for (let i = quests.length - 1; i >= 0; i--) {
      const quest = quests[i];
      if (quest.start + quest.term <= turn) {
        const preset = data_questPresets.find((d) => d.id === quest.id);
        const event = data_eventCores.find((d) => d.id === preset.fail_event);
        const quest_giver = this.agents_avail_all.find((a) => a.idx === quest.quest_giver_idx);
        if (event) {
          this.events.push({ ty: 'eventAgents', value: { eventCore: event, targetAgents: [quest_giver] } });
        }

        this.max_quest = Math.max(this.max_quest - 1, 1);
        this.schedule_key = 'check_quest_deadline';
        quests.splice(i, 1);
      }
    }
  }

  getQuestStates() {
    const states = [];

    for (const quest of this.quests) {
      states.push(this.getQuestState(quest));
    }
    return states;
  }

  getQuestState(quest) {
    const { agents_avail_all } = this;
    const { id, complete_condition, complete_condition_value, quest_giver_idx, generator_idx } = quest;
    const preset = data_questPresets.find((d) => d.id === id);
    let pass = false;
    let current_value = 0;

    const target_agent_idx = preset.target_ty === 'generator' ? generator_idx : quest_giver_idx;
    const target_agent = agents_avail_all.find((a) => a.idx === target_agent_idx);

    const getAgentValue = (target_agent, value_key) => {
      if (value_key === 'condition') {
        return target_agent.condition;
      }
      else if (value_key === 'mood') {
        return target_agent.mood;
      }
      else if (value_key === 'relation') {
        return target_agent.relation;
      }
      //stats
      else {
        return target_agent.realStats[value_key];
      }
    }

    if (complete_condition === 'agent_modifier') {
      pass = target_agent.modifier.find((m) => m.key === complete_condition_value) ? true : false;
    }
    else if (complete_condition === 'agent_not_modifier') {
      pass = target_agent.modifier.find((m) => m.key === complete_condition_value) ? false : true;
    }
    else if (complete_condition === 'modifier_yes') {
      pass = agents_avail_all.find((a) => a.modifier.find((m) => m.key === complete_condition_value)) ? true : false;
    }
    else if (complete_condition === 'modifier_no') {
      pass = agents_avail_all.find((a) => a.modifier.find((m) => m.key === complete_condition_value)) ? false : true;
    }
    else if (complete_condition === 'fire') {
      pass = agents_avail_all.find((a) => a.idx === target_agent_idx) ? false : true;
    }
    else {
      const splits = complete_condition.split('_');
      let targets = agents_avail_all;
      let getCurrentValue, getTargetValue;
      if (splits[0] === 'avg') {
        getCurrentValue = () => {
          if (targets.length === 0) {
            return 0;
          }

          let sum = 0;
          for (const target of targets) {
            sum += getTargetValue(target);
          }
          return sum / targets.length;
        };

        splits.splice(0, 1);
      }
      else if (splits[0] === 'lowest') {
        getCurrentValue = () => {
          if (targets.length === 0) {
            return 0;
          }
          let lowest = Number.MAX_VALUE;
          for (const target of targets) {
            lowest = Math.min(lowest, getTargetValue(target));
          }
          return lowest;
        }

        splits.splice(0, 1);
      }
      else if (splits[0] === 'highest') {
        getCurrentValue = () => {
          if (targets.length === 0) {
            return 0;
          }
          let highest = Number.MIN_VALUE;
          for (const target of targets) {
            highest = Math.max(highest, getTargetValue(target));
          }
          return highest;
        }

        splits.splice(0, 1);
      }
      else if (splits[0] === 'role') {
        const current_value = agents_avail_all.filter((a) => a.role === splits[1]).length;
        const comparer_key = splits[2];
        if (comparer_key === 'lower') {
          pass = current_value < parseFloat(complete_condition_value);
        }
        else if (comparer_key === 'higher') {
          pass = current_value > parseFloat(complete_condition_value);
        }
        else if (comparer_key === 'noless') {
          pass = current_value >= parseFloat(complete_condition_value);
        }
        else if (comparer_key === 'nomore') {
          pass = current_value <= parseFloat(complete_condition_value);
        }

        return { id: quest.id, value: complete_condition_value, current: current_value, pass };
      }
      else {
        getCurrentValue = () => {
          return getTargetValue(target_agent);
        }
      }

      const value_key = splits[0];
      //global values
      if (value_key === 'money') {
        current_value = this.resources.balance;
      }
      else if (value_key === 'integrity') {
        current_value = this.integrity;
      }
      else if (value_key === 'trust') {
        current_value = this.trust;
      }
      //agent values
      else {
        getTargetValue = (target) => getAgentValue(target, value_key);
        current_value = getCurrentValue();
      }

      const comparer_key = splits[1];

      if (comparer_key === 'lower') {
        pass = current_value < parseFloat(complete_condition_value);
      }
      else if (comparer_key === 'higher') {
        pass = current_value > parseFloat(complete_condition_value);
      }
      else if (comparer_key === 'noless') {
        pass = current_value >= parseFloat(complete_condition_value);
      }
      else if (comparer_key === 'nomore') {
        pass = current_value <= parseFloat(complete_condition_value);
      }
    }

    return { id: quest.id, value: complete_condition_value, current: current_value, pass };
  }

  acceptQuest() {
  }

  refuseQuest() {
    const { quests } = this;
    quests.splice(quests.length - 1, 1);
    this.max_quest = Math.max(this.max_quest - 1, 1);
  }

  failQuest() {
    this.schedule_key = '';
  }

  resupplyQuest() {
    this.max_quest = Math.min(this.max_quest + QUEST_RESUPPLY_AMOUNT, 3);
  }

  makeQuestDescText(quest) {
    const { turn, agents_avail_all } = this;
    const { id, complete_condition_value, quest_giver_idx, start, term, generator_idx, target_ty } = quest;
    const preset = data_questPresets.find((d) => d.id === id);
    let { current_value } = quest;
    const remain_days = Math.ceil((start + term - turn) / 24);
    const quest_giver = agents_avail_all.find((a) => a.idx === quest_giver_idx);
    const target = target_ty === 'generator' ? agents_avail_all.find((a) => a.idx === generator_idx) : quest_giver;
    const title_text = L(preset.title);
    if (Math.round(current_value) !== current_value) {
      current_value = current_value.toFixed(1);
    }
    const quest_report_text = L(preset.quest_report, { name_target: L(target?.name), current: current_value });
    const desc_text = L(preset.desc,
      {
        quest_title: title_text,
        obj: complete_condition_value,
        questgiver: L(quest_giver.name),
        due_day: remain_days,
        name_target: L(target.name),
        quest_report: quest_report_text,
        interpolation: { escapeValue: false },
      });

    return desc_text;
  }
}

export function ItemTitle(props) {
  const { item } = props;
  const { ty } = item;

  let detail = null;

  switch (ty) {
    case 'equipment':
      const { equipment } = item;
      detail = <GearLabel equipment={equipment} />;
      item_rate = equipment.vest_rate;
      break;
    case 'firearm':
      const { firearm } = item;
      item_rate = firearm.firearm_ty === 'hg' ? 0 : firearm.firearm_rate;
      detail = <FirearmLabel firearm={firearm} />;
      break;
    case 'throwable':
      const { throwable } = item;
      item_rate = throwable.throwable_rate;
      detail = <ThrowableLabel throwable={throwable} />;
      break;
    case 'utility':
      const { utility } = item;
      item_rate = utility.utility_rate;
      detail = <UtilityLabel utility={utility} />;
      break;
    default:
      detail = <span>{item.value}</span>
      break;
  }

  return <div className='listing-item-desc'>{detail}</div>;
}

function GrindAgentsScheduleView(props) {
  const { game, onSelectChanged, onClickPortrait } = props;
  const { conditionInfo, onScheduleCEO } = props;
  const { agents_avail_all } = game;

  const [scheduleForAll, setScheduleForAll] = useState('');

  const schedule_options_all = [];
  const schedule_options_all_for_radio = [];
  schedule_options_all.push(<option key={0} value={null}>{L("loc_data_string_schedule_selection")}</option>);

  for (let i = 0; i < data_scheduleAgents.length; i++) {
    const schedule = data_scheduleAgents[i];
    if (!schedule.visible) {
      continue;
    }
    schedule_options_all.push(<option key={i} value={schedule.id}>{L('loc_data_string_schedule_agent_' + schedule.id)}</option>);
    schedule_options_all_for_radio.push({ key: schedule.id, label: L('loc_data_string_schedule_agent_' + schedule.id), disable: false });
  }

  ////////////////////////
  function ChangeAllSchedule(option) {
    const schedule = data_scheduleAgents.find((d) => d.id === option.key);
    for (const agent of agents_avail_all) {
      const schedule_selectable = game.getSelectableSchedules(agent);
      if (schedule_selectable.find((s) => s.id === option.key)) {
        agent.schedule = option.key;
        agent.schedule_type = schedule.type;
      }
    }
    setScheduleForAll(option.key);
    onSelectChanged();
  }

  return <div className='grind-allschedule'>
    <div className='grind-allschedule-header'>
      <AgentsButton conditionInfo={conditionInfo} onScheduleCEO={onScheduleCEO} />
      <div className='grind-allschedule-radios'>
        <span className='grind-allschedule-radios-label'>{L('loc_ui_string_schedule_agent_everyone')}</span>
        {
          schedule_options_all_for_radio.map((option, i) => {
            return <RadioButton key={i} option={option} checked={scheduleForAll == option.key} onClick={() => {
              ChangeAllSchedule(option);
              triggerSound('UI_Outgame_Button_Click_Default');
            }} />
          })
        }
      </div>
    </div>

    <div className='grind-allschedule-body'>
      <FigmaListBody characters={agents_avail_all}
        renderRowExtra={(ch) => {
          const schedule_selectable = game.getSelectableSchedules(ch);
          const options = data_scheduleAgents.filter((s) => s.visible).map((schedule) => {
            const { id } = schedule;
            const disabled = !schedule_selectable.find((s) => s.id === id);
            return { key: id, label: L('loc_data_string_schedule_agent_' + id), disabled };
          });
          const selected = ch.schedule;
          const comments = data_agentComments.filter((d) => d.schedule === ch.schedule_type && game.checkConditions(ch, d.condition, d.condition_value));
          let comment = comments.length > 0 ? L(comments[0].comment) : '';

          let success_rate = ''
          if (ch.schedule_type === 'train_default') {
            const conditionData = getConditionData(ch.condition);
            success_rate = L('loc_dynamic_string_schedule_agent_success_rate', { value: (conditionData.prob_train_success * 100).toFixed(1) });
          }

          return <ListRowPlan comment={comment} success_rate={success_rate} options={options} selected={selected} onSelect={(key) => {
            const schedule = data_scheduleAgents.find((d) => d.id === key);
            ch.schedule = key;
            ch.schedule_type = schedule.type;
            if (key != scheduleForAll) {
              setScheduleForAll('');
            }
            onSelectChanged();
          }} />;
        }}
        renderNoteView={(ch) => {
          let note = null;
          const visible_modifiers = ch.modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);
          if (visible_modifiers.length > 0) {
            note = visible_modifiers.map((mod, i) => <>
              {i > 0 ? <span key={'span' + i} className='grind-agent-modifier grind-agent-modifier-separator'>,&nbsp;</span> : ''}
              <AgentModifierBody key={i} modifier={mod} turn={game.turn} />
            </>);
          }

          const agent = ch;
          const schedule_id = 'meeting_person';
          const schedule_ceo = data_scheduleCEOs.find((d) => d.id === schedule_id);
          const disabled = game.isMeetingDisabled(agent);
          const btn_meeting = <ButtonInline disabled={disabled} onClick={() => {
            onScheduleCEO(schedule_ceo, agent, true);
          }}>
            {L('loc_data_string_schedule_ceo_' + schedule_id)}
          </ButtonInline>;

          return {
            header: btn_meeting,
            note,
          };
        }}
        onClickPortrait={onClickPortrait}
        makeDiffs={(ch) => {
          let diffs = game.getAgentScheduleDiffs(ch);
          const realStatsDiffs = diffs.realStatsDiffs;
          if (realStatsDiffs) {
            diffs = { ...diffs, ...realStatsDiffs };
            delete diffs.realStatsDiffs;
          }

          for (const [key, value] of Object.entries(diffs)) {
            if (key === 'level' && value > 0) {
              diffs[key] = <font color='green'>(+{value})</font>;
            }
            else if (!value || value === 0) {
              diffs[key] = null;
            }
            else if (value > 0) {
              diffs[key] = <div className="figmalist-body-list-row-etc-label diff positive">(+{value.toFixed(1)})</div>
            }
            else {
              diffs[key] = <div className="figmalist-body-list-row-etc-label diff negative">({value.toFixed(1)})</div>
            }
          }
          return diffs;
        }}
      />
    </div>
  </div>;

}

const CLOSABLES = ['EQUIP', 'AGENT_BACKGROUND', 'AGENT_DETAIL', 'LOBBY-OBJECTIVE', 'LOBBY-SHOP', 'LOBBY-INVENTORY', 'LOBBY-RECRUITS', 'LOBBY-MISSION', 'LOBBY-AGENTS', 'LOBBY-SQUAD', 'LOBBY-MENU', 'LOBBY-EXCHANGE', 'LOBBY-ASSIGN', 'AGENT_REPORT'];
const FOCUSABLES = ['LOBBY-OBJECTIVE', 'LOBBY-SHOP', 'LOBBY-INVENTORY', 'LOBBY-RECRUITS', 'LOBBY-MISSION', 'LOBBY-AGENTS', 'LOBBY-SQUAD'];

function FigmaRecruitView(props) {
  const { characters, listHeader, renderDetailButtons, header, onClickButtonClose, additionalButton, OnClickBackgroundButton, onClickPerkUnlockButton, turn, getCharacterPaymentFunction } = props;

  const [selected, onSelect] = useState(characters[0]);
  if (characters.indexOf(selected) === -1) {
    setTimeout(() => {
      onSelect(characters[0]);
    });
  }

  return <FigmaListView
    characters={characters}
    selected={selected}
    header={header}
    listHeader={listHeader}
    onSelect={(ch) => onSelect(ch)}
    renderDetailButtons={renderDetailButtons}
    onClickButtonClose={onClickButtonClose}
    additionalButton={additionalButton}
    OnClickBackgroundButton={OnClickBackgroundButton}
    onClickPerkUnlockButton={onClickPerkUnlockButton}
    renderNoteView={(ch) => {
      let note = null;
      const visible_modifiers = ch.modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);
      if (visible_modifiers.length > 0) {
        note = visible_modifiers.map((mod, i) => <>
          {i > 0 ? <span key={'span' + i} className='grind-agent-modifier grind-agent-modifier-separator'>,&nbsp;</span> : ''}
          <AgentModifierBody key={i} modifier={mod} turn={turn} />
        </>);
      }
      return note;
    }}
    getCharacterPaymentFunction={getCharacterPaymentFunction}
  />;
}

const PerkAcquireView = (props) => {
  const { perk, onPerkAcquire, tactical } = props;
  let perkData;
  if (perk.ty === 'stat') {
    perkData = statsPerksdata[perk.key];
  }
  else {
    perkData = rolesPerksdata[perk.key];
  }

  let descrValue = perkData.perkPower;
  if (descrValue !== 0) {
    const perkKey = perk.key;
    const bonusType = Object.keys(perk2_tactical_bonus_types).find((key) => {
      return perk2_tactical_bonus_types[key].includes(perkKey);
    });
    if (bonusType) {
      const added = (perk2_tactical_bonus_display[bonusType] * tactical / 100).toFixed(1);
      const result = (+(perkData.perkPower) + +(added)).toFixed(1);
      descrValue = `${result}(${perkData.perkPower} + ${added})`;
    }
  }

  return <div className='agent-perks-acquire-item'>
    <div className='agent-perks-acquire-item-header'>
      <div className='agent-perks-acquire-item-tags'>
        {perkData.tags.map((tag, i) =>
          <div key={i} className='agent-perks-acquire-item-tag'>
            [{L("loc_data_string_perk_tag_" + tag)}]
          </div>)
        }
      </div>
      <div className='agent-perks-acquire-item-title'>
        {L(perkData.name, { level: perkData.level })}
      </div>
    </div>
    <div className='agent-perks-acquire-item-body'>
      <div className='agent-perks-acquire-perk-body-text'>
        {L(perkData.descr, { value: descrValue })}
      </div>
      <DetailButton
        key={L(perkData.name)}
        label={L('loc_ui_string_perk2_acquire')}
        cueList={['UI_Outgame_Button_Click_Yellow']}
        onClick={() => {
          onPerkAcquire(perk);
        }} />
    </div>
  </div>
}

function ButtonsWithFilter(props) {
  const { buttons } = props;

  const [filter, setFilter] = useState('');

  return <div>
    <input type='text' value={filter} onChange={(ev) => setFilter(ev.target.value)} />
    <br />
    {buttons.map((b, i) => {
      const { title, onClick } = b;
      if (filter && title.indexOf(filter) === -1) {
        return null;
      }
      return <button key={i} onClick={onClick}>{b.title}</button>;
    })}
  </div>
}

function AgentsButton(props) {
  const { conditionInfo, onScheduleCEO } = props;
  const {
    schedule_meeting: schedule,
    schedule_allowed,
  } = conditionInfo;

  return <SoundButton className="grind-ceoschedule-button" disabled={!schedule_allowed} onClick={() => {
    onScheduleCEO(schedule, null, false);
  }} title={L('loc_data_longtext_schedule_ceo_' + schedule.id + '_desc')}>
    <span className="grind-ceoschedule-button-label">{L('loc_data_string_schedule_ceo_' + schedule.id)}</span>
  </SoundButton>;
}

function EnemyBadge(props) {
  const { enemy } = props;
  const { role, tier } = enemy;
  return <span className="enemy-badge">
    <span className="enemy-badge-tier">{tier}</span> <span className="enemy-badge-role">{role}</span>
  </span>;
}

function WeekTransitionView(props) {
  const { week, onFinish } = props;

  const ref = React.createRef();

  React.useEffect(() => {
    const handleAnimationEnd = () => {
      onFinish();
    };

    const element = ref.current;
    element.addEventListener('animationend', handleAnimationEnd);
  });

  return <div className="overlay-week-transition">
    <div className="overlay-week-transition-content" ref={ref}>WEEK {week}</div>
  </div>;
}

export class GrindView extends React.Component {
  constructor(props) {
    super(props);
    const { enable_dialog, autoload } = props;

    this.keyDown = this.onKeyDown.bind(this);
    this.sims = [];
    this.simRefs = [];
    this.now = Date.now();
    this.last_ms = this.now;
    this.onTimer = () => {
      if (!this.onTick()) {
        this.startTimer();
      }
    };
    this.saveimpl = this.props.saveimpl ?? new SaveImpl();

    this.saveRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.dayRef = React.createRef();

    const query = parseQuery(window.location.search);
    this.debug = !!query.debug;

    let game = props.game;
    if (!game) {
      game = new GrindGame();
      game.reset(opts.GRIND_SELECTED_MAP);
    }
    if (enable_dialog) {
      game.enable_dialog = true;
    }

    game.enable_quest = !!query.enable_quest;

    const overlays = [];
    const focusedMenus = ['mission_objective'];
    if (enable_dialog) {
      overlays.push({ 'ty': 'LOBBY-OBJECTIVE' });
      focusedMenus.push('mission_objective');
    }

    this.state = {
      game,
      overlays,
      focusedEvent: null,
      showEvent: false,
      focusLobby: true,
      focusedMenus: focusedMenus,
      savedescrs: [],
      simtps: TPS_BASE,
      paused: !AUTOSTART,
      locale: localeGet(),
      selectedItem: null,
      buyOrSellTriggered: false,

      tooltipContext: {
        content: null,
        pos: null,
        target: null,
        ref: this.tooltipRef,
      },

      autoload: autoload ?? AUTOLOAD,
    };

    triggerBgm('BGM_Outgame_Default_Loop', 'Play');
  }

  onTooltipTimer() {
    /*
    const { tooltipContext } = this.state;
    const { pos, target } = tooltipContext;
    if (pos && target) {
      const { x, y } = pos;
      const elem = document.elementFromPoint(x, y);
      if (elem !== target) {
        this.setState({ tooltipContext: { ...tooltipContext, content: null, target: null } });
      }
    }
    */
    this.tooltipTimer = requestAnimationFrame(this.onTooltipTimer.bind(this));
  }

  componentDidMount() {
    const { autoload, paused } = this.state;
    document.addEventListener('keydown', this.keyDown);

    if (!paused) {
      this.startTimer();
    }

    (async () => {
      const savedescrs = await this.saveimpl.loadDescrs();
      this.setState({ savedescrs });
    })();

    this.tooltipTimer = requestAnimationFrame(this.onTooltipTimer.bind(this));

    if (autoload >= 0) {
      (async () => {
        const data = await this.saveimpl.loadSlot(autoload);
        this.deserializeState(data);
        this.setState({ autoload: -1 });
      })();
    } else {
      const { game } = this.state;
      if (game.date === 0) {
        // TODO
        game.dialogMaybe(['init']);
        this.setState({ game });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    cancelAnimationFrame(this.tooltipTimer);
  }

  startTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = window.requestAnimationFrame(this.onTimer);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onKeyDown(ev) {
    const simRef = this.simRefs[0];
    if (simRef) {
      // 시뮬레이션 돌고 있으면 키 입력을 무시
      return;
    }

    const { overlays, game } = this.state;
    const { lobby } = game;

    if (ev.key === ' ') {
      const actions = this.buildNextActionButtons();
      actions.reverse();
      const action = actions.find((a) => a?.enabled);
      if (action) {
        if (action.className === 'event' || overlays.length === 0) {
          ev.preventDefault();
          action.onClick();
          return;
        }
      }
    }

    if (!overlays.find((o) => o.ty === 'DIALOG')) {
      if (ev.key === 'Escape') {
        this.onKeyEscape();
      }

      if (!overlays.find((o) => o.ty === 'LOBBY-MENU')) {
        const callbacks = Object.entries(this.menuCallbacks());
        for (let i = 0; i < callbacks.length; i++) {
          const [key, callback] = callbacks[i];
          if (ev.key === (i + 1).toString() && lobby[key] && callback) {
            this.overlayCloseAll();
            callback();
            break;
          }
        }
      }
    }

    if (!overlays.find((o) => o.ty === 'LOBBY-MENU')) {
      if (ev.key === '0') {
        this.overlayPush({ ty: 'LOBBY-MENU' });
      }
    }

    if (ev.key === ',') {
      this.onTickToPause();
    }
  }

  onTickToPause() {
    while (true) {
      const paused = this.onTickGame();
      if (!paused) {
        continue;
      }

      this.setState({ paused });
      break;
    }
  }

  canProgress() {
    const { game, focusedEvent } = this.state;
    if (!game.canProgress()) {
      return false;
    }
    if (game.events.length > 0 || focusedEvent) {
      return false;
    }
    return true;
  }

  setPaused(paused) {
    if (paused === this.state.paused) {
      return;
    }

    if (paused) {
      this.stopTimer();
    } else {
      this.last_ms = Date.now();
      this.startTimer();
    }
    this.setState({ paused });
  }

  overlayCloseAll() {
    while (true) {
      const ty = this.overlay_cur?.ty;
      if (CLOSABLES.includes(ty)) {
        this.overlayPop();
      }
      break;
    }
  }

  onKeyEscape() {
    const { game, focusLobby, focusedEvent, showEvent, focusedMenus } = this.state;
    if (showEvent && focusedEvent) {
      if (focusedEvent.allow_postpone) {
        this.setState({ showEvent: false });
      }
      return;
    }

    const ty = this.overlay_cur?.ty;
    let overlay_close_button_key = '';
    if (ty) {
      const splits = ty.split('-');
      overlay_close_button_key = `${splits[splits.length - 1].toLowerCase()}_prev`;
    }

    if (CLOSABLES.includes(ty) && !game.disable_button[overlay_close_button_key]) {
      game.highlight_button[overlay_close_button_key] = false;
      this.overlayPop();
      if (FOCUSABLES.includes(ty)) {
        this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
        if (focusedMenus.length === 0) {
          this.setState({ focusedMenus: ['mission_objective'] });
        }
      }
      return;
    }

    const schedule_prev_button_key = `schedule_${game.schedule_key}_prev`;
    if (!focusLobby && !game.disable_button[schedule_prev_button_key]) {
      game.highlight_button[schedule_prev_button_key] = false;
      game.dialogMaybe(['focus_lobby']);
      this.setState({ focusLobby: true, focusedMenus: ['mission_objective'] });
      return;
    }
  }

  onTick() {
    const { simtps, game, overlays } = this.state;
    let { paused } = this.state;

    this.now = Date.now();

    if (paused) {
      this.last_ms = this.now;
      return paused;
    }

    const ms_per_tick = 1000 / simtps;
    let max_tick = 100;
    while (this.last_ms + ms_per_tick < this.now) {
      this.last_ms += ms_per_tick;
      paused = this.onTickGame();
      if (paused) {
        break;
      }

      max_tick -= 1;
      if (max_tick <= 0) {
        break;
      }
    }

    if (paused) {
      let { focusLobby, focusedMenus } = this.state;
      if (game.schedule_key === 'agents') {
        focusLobby = false;
        focusedMenus = focusedMenus.concat(['schedule']);
      } else if (game.schedule_key !== 'event_agent') {
        focusLobby = true;
        focusedMenus = ['mission_objective'];
      }
      if (focusLobby && game.schedule_key === 'mission_brief') {
        game.dialogMaybe(['focus_lobby']);
      }
      this.setState({ paused, focusLobby, focusedMenus });
    }
    else {
      this.setState({ paused });
    }
    return paused;
  }

  onTickGame() {
    let { game } = this.state;
    const { paused } = game.onTick();

    this.setState({ game });
    if (game.turn % TICK_PER_DAY === 0) {
      const days = Math.floor(game.turn / TICK_PER_DAY);
      this.serializeState(days % 2 + 2, L('loc_ui_string_autosave_on_tick_game'));

      GameAnalytics.addDesignEvent(`day:${this.missionIdx}:${game.days()}`);
    }

    return paused;
  }

  onAgentEquipAvail(agent, equip_ty, index) {
    const { role } = agent;

    // TODO: 장비 규칙
    let cur = null;
    let avail = false;
    let reason = null;
    if (equip_ty === 'firearm') {
      if (agent.firearm.key === DEFAULT_FIREARM.key) {
        cur = <FirearmLabel firearm={firearm_none} />;
      }
      else {
        cur = <FirearmLabel firearm={agent.firearm} />;
      }
      avail = true;
    } else if (equip_ty === 'equipment') {
      cur = <GearLabel equipment={agent.equipment} />;
      avail = true;
    } else if (equip_ty === 'throwable') {
      cur = <ThrowableLabel throwable={agent.throwables[index]} />;
      if (['support', 'pointman'].includes(role)) {
        avail = true;
      }
    } else if (equip_ty === 'utility') {
      cur = <UtilityLabel utility={agent.utilities[index]} />;
      if (role === 'support') {
        avail = true;
      }
    }
    else {
      throw new Error(`unknown equip_ty ${equip_ty}`);
    }

    return {
      cur,
      avail,
      reason,
    };
  }

  onAgentEquipPopup(agent, equip_ty, equip_idx) {
    this.overlayPush({ ty: 'EQUIP', equip_ty, agent, equip_idx });
  }

  onAgentDisarm(agent) {
    const { game } = this.state;
    game.agentDisarm(agent);
    this.setState({ game });
  }

  get overlay_cur() {
    const { overlays } = this.state;
    if (overlays.length === 0) {
      return null;
    }
    return overlays[overlays.length - 1];
  }

  overlayPush(overlay) {
    if (this.overlay_cur?.ty === 'DIALOG') {
      return;
    }

    let { overlays } = this.state;
    const { game } = this.state;
    overlays.push(overlay);
    game.dialogMaybe(['overlay_open', overlay.ty]);
    this.setState({ overlays });
  }

  overlayUpdate(obj) {
    const overlays = this.state.overlays.slice();
    const last = this.overlay_cur;
    overlays[overlays.length - 1] = { ...last, ...obj };
    this.setState({ overlays });
  }

  overlayPop() {
    const { game } = this.state;
    const overlays = this.state.overlays;
    const { ty } = overlays.pop();
    game.dialogMaybe(['overlay_close', ty]);
    this.setState({ overlays });
  }

  overlayPopMaybe(ty) {
    if (this.overlay_cur?.ty === ty) {
      this.overlayPop();
    }
  }

  onFinish(squadIdx, sim, res) {
    const { game } = this.state;
    const squad = game.squads[squadIdx];

    const resText = res === 0 ? "clear" : "fail";
    GameAnalytics.addDesignEvent(`ingame:${this.missionIdx}:${game.mission_schedule_ty}:${game.weeks2()}:${resText}`);

    if (squad.state === 'stop') {
      // TODO: 나중에 정석적으로 고쳐야 함.
      return;
    }

    if (game.mission_schedule_ty !== 'trial') {
      this.overlayCloseAll();
    }

    const { onDone } = this.props;
    this.sims[squadIdx] = sim;
    game.onMissionFinish(squadIdx, res, sim, onDone);

    if (game.mission_schedule_ty === 'final') {
      GameAnalytics.addDesignEvent(`mission:${this.missionIdx}:${resText}`);
      this.state.sim = sim;
    }

    squad.state = 'stop';
    squad.mission_state = null;

    game.finishMissionSchedule(res);
    if (game.mission_schedule_ty === 'trial') {
      this.overlayPush({ ty: 'MISSION-RESULT', res, stopTimer: true });
    } else {
      game.schedule_key = 'mission_result';
    }

    game.dialogMaybe(['trial_after']);

    if (game.mission_schedule_ty === 'checkpoint') {
      this.serializeState(0, L('loc_ui_string_autosave_render_mission_result'));
    }
    this.setState({ game });
  }

  onSimEvent(squadIdx, ev) {
    const { game } = this.state;
    const squad = game.squads[squadIdx];
    const view = this.simRefs[squadIdx];
    const sim = view.state.sim;
    const controls = sim.controlsGet(0);

    let progress = _.max(sim.segments.filter((s) => s.clear).map((s) => s.idx)) ?? -1;
    const controls_list = game.squadControlsList(squadIdx);
    if (ev.ty === 'segment_clear') {
      progress = ev.segment;
      const segment = progress + 1;
      for (const agent of squad.agents) {
        const controls_list = game.agentControlsList(squad, agent);
        sim.entityControlsSet(agent.idx, controls_list?.[segment]);
      }
      sim.controlsSet(0, controls_list?.[segment]);
    } else if (ev.ty === 'control') {
      if (controls_list?.[progress]) {
        controls_list[progress] = controls;
      }
    } else if (ev.ty === 'entityControl') {
      const { entity, controls } = ev;
      const agent = squad.agents.find((a) => a.idx === entity.idx);
      const controls_list = game.agentControlsList(squad, agent);
      if (controls_list && controls_list?.[progress]) {
        controls_list[progress] = controls;
      }
    }

    this.setState({ game });
  }

  renderOverlay() {
    const { overlays, game, focusedEvent, showEvent, focusedMenus } = this.state;

    if (overlays.length === 0) {
      if (game.popups.length > 0) {
        const popup = game.popups.pop();
        overlays.push(popup);
        game.dialogMaybe(['overlay_open', popup.ty]);
      }
      else if (!focusedEvent || !showEvent) {
        return { overlay: null, showActions: false };
      }
    }

    let contents = [];

    for (const overlay of overlays) {
      let priority = 2;
      let content = null;
      let showActions = false;
      const { ty } = overlay;

      if (ty === 'EQUIP') {
        content = this.renderAgentEquip(overlay);
      } else if (ty === 'AGENT_BACKGROUND') {
        content = this.renderAgentBackground(overlay);
      } else if (ty === 'AGENT_BACKGROUND_ON_EVENT') {
        content = this.renderAgentBackground(overlay);
        priority = 0.5;
      } else if (ty === 'AGENT_DETAIL') {
        content = this.renderAgentDetailSmall(overlay);
      } else if (ty === 'GAMEOVER') {
        content = this.renderGameOver(overlay);
      } else if (ty === 'GAMEOVER-WARNING') {
        content = this.renderGameOverWarning();
      } else if (ty === 'AGENT_REPORT') {
        content = this.renderAgentReport(overlay);
      } else if (ty === 'LOBBY-SHOP') {
        content = this.renderShopPopup();
        showActions = true;
      } else if (ty === 'LOBBY-INVENTORY') {
        content = this.renderInventoryPopup();
      } else if (ty === 'LOBBY-RECRUITS') {
        content = this.renderRecruitsPopup();
        showActions = true;
      } else if (ty === 'LOBBY-AGENTS') {
        content = this.renderAgentsPopup();
        showActions = true;
      } else if (ty === 'LOBBY-OBJECTIVE') {
        content = this.renderObjectivePopup();
      } else if (ty === 'LOBBY-MENU') {
        content = this.renderSystemPopup();
      } else if (ty === 'WEEKLY_MEETING') {
        content = this.renderWeeklyMeeting(overlay);
      } else if (ty === 'LOBBY-EXCHANGE') {
        content = this.renderExchangeAgentPopup(overlay);
      } else if (ty === 'GENERAL') {
        content = this.renderFigmaPopup(overlay.props);
      } else if (ty === "LOBBY-ASSIGN") {
        content = this.renderAssignAgentPopup();
      } else if (ty === 'DIALOG') {
        priority = 0;
        content = this.renderDialog(overlay);
      } else if (ty === 'UNABLE') {
        content = this.renderUnable(overlay);
      } else if (overlay.ty === 'MISSION-RESULT') {
        content = this.renderMissionResult(game, true);
      } else if (overlay.ty === 'MISSION-EXP') {
        content = this.renderMissionExp(game, true);
      } else if (overlay.ty === 'CHECKPOINT-RESULT') {
        content = this.renderCheckpointResult(game, true);
      } else if (ty === 'DEBUG-EVENT-COUNT') {
        content = this.renderDebugEventCount();
      } else if (ty === 'LOBBY-SQUAD') {
        content = this.renderSquadPopup({
          squad: game.squads[0],
          squadIdx: 0,
          m: this.props.m,
        });
        showActions = true;
      } else if (ty === 'LOBBY-MISSION') {
        content = this.renderMissionPopup();
        showActions = true;
      } else if (ty === 'AGENT_PERK_ACQUIRE') {
        content = this.renderAgentPerkAcquire(overlay);
      } else if (ty === 'FINAL_DEAD_CARE') {
        content = this.renderFinalDeadCare(overlay);
      } else if (ty === 'MILESTONE_NOTICE') {
        content = this.renderMilestoneNotice(overlay);
      } else if (ty === 'WEEK-TRANSITION') {
        content = this.renderWeekTransition(overlay);
      }
      contents.push({
        content,
        suffix: '',
        showActions,
        priority,
      },);
    }

    if (focusedEvent && showEvent) {
      const content = this.renderEvent();
      contents.push({
        content,
        suffix: '',
        showActions: false,
        priority: 1,
      });
    }

    contents.sort((a, b) => b.priority - a.priority);

    return {
      overlay: <>
        {contents.map(({ content, suffix, showActions }, i) => {
          if (!content) {
            return null;
          }
          return <div key={i} className={`overlay-root${suffix}`}>
            {content}
          </div>;
        })}
      </>,
      showActions: contents.find((c) => c.showActions) !== undefined,
    };
  }

  onAgentEquip(agent, item, index) {
    const { game } = this.state;
    game.agentEquip(agent, item, index);
    this.setState({ game });
  }

  onAgentDisarmEquipment(agent) {
    const { game } = this.state;
    game.agentDisarmEquipment(agent);
    this.setState({ game });
  }

  onAgentDisarmThrowable(agent, equip_idx) {
    const { game } = this.state;
    game.agentDisarmThrowable(agent, equip_idx);
    this.setState({ game });
  }

  onMarketListingPurchase(item) {
    const { game } = this.state;
    const { ty, original_buy_cost, buy_discount_percent } = item;
    const buy_cost = game.getItemBuyCost(ty, original_buy_cost, buy_discount_percent)
    game.marketListingPurchase(item, buy_cost);
    this.setState({ game, buyOrSellTriggered: true });
  }

  onUnassign(squadIdx, agent, reason) {
    const { game } = this.state;
    game.onUnassign(squadIdx, agent, reason);
    this.setState({ game });
  }

  onAssign(squadIdx, agent) {
    const { game } = this.state;
    game.onAssign(squadIdx, agent);
    this.setState({ game });
  }

  onChangeAgentOrder(squadIdx, prevIndex, newIndex) {
    const { game } = this.state;
    game.changeAgentOrder(squadIdx, prevIndex, newIndex);
    this.setState({ game });
  }

  onExchangeAgent(squadIdx, agent, index) {
    const { game } = this.state;
    game.exchangeAgent(squadIdx, agent, index)
    this.setState(game);
  }

  onSquadSetConfig(squadIdx, config_key) {
    const { game } = this.state;
    game.squadSetConfig(squadIdx, config_key);
    this.setState({ game });
  }

  onScheduleCEO(schedule, target, block_schedule = true) {
    const { game } = this.state;

    if (schedule.event_list.length > 0) {
      const event_id = game.rng.choice(schedule.event_list);
      const eventCore = data_eventCores.find((d) => d.id === event_id);

      if (block_schedule) {
        game.ceoSchedules.push(schedule.id);
      }
      game.events.push({ ty: 'eventCEO', value: { eventCore, target } });

      this.setState({ game });
    }
  }

  onScheduleSuperior() {
    const { game } = this.state;

    const schedule_id = 'call_superior';
    const schedule = data_scheduleCEOs.find((d) => d.id === schedule_id);

    if (schedule.event_list.length > 0) {
      const event_id = game.rng.choice(schedule.event_list);
      const eventCore = data_eventCores.find((d) => d.id === event_id);

      game.events.push({ ty: 'eventCEO', value: { eventCore, target: null } });

      this.setState({ game });
    }
  }

  onLobbyShopButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_shop`] = false;
    game.dialogMaybe(['lobby_press', 'shop']);
    this.overlayPush({ ty: 'LOBBY-SHOP' });
    this.setState({ focusedMenus: focusedMenus.concat(['shop']) });
  }

  onLobbyInventoryButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_inventory`] = false;
    game.dialogMaybe(['lobby_press', 'inventory']);
    this.overlayPush({ ty: 'LOBBY-INVENTORY' });
    this.setState({ focusedMenus: focusedMenus.concat(['inventory']) });
  }

  onLobbyRecruitsButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_recruits`] = false;
    game.dialogMaybe(['lobby_press', 'recruits']);
    this.overlayPush({ ty: 'LOBBY-RECRUITS' });
    this.setState({ focusedMenus: focusedMenus.concat(['recruits']) });
  }

  onLobbyAgentsButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_agents`] = false;
    game.dialogMaybe(['lobby_press', 'agents']);
    this.overlayPush({ ty: 'LOBBY-AGENTS' });
    this.setState({ focusedMenus: focusedMenus.concat(['agents']) });
  }

  onLobbySquadButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_squad`] = false;
    game.mission_schedule_ty = 'trial';
    game.dialogMaybe(['lobby_press', 'squad']);
    this.overlayPush({ ty: 'LOBBY-SQUAD' });
    this.setState({ focusedMenus: focusedMenus.concat(['squad']) });
  }

  onLobbyMenuButton() {
    this.overlayPush({ ty: 'LOBBY-MENU' });
  }

  onLobbyMissionButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_mission`] = false;
    game.mission_schedule_ty = 'trial';
    game.dialogMaybe(['lobby_press', 'mission']);
    this.overlayPush({ ty: 'LOBBY-MISSION' });
    this.setState({ focusedMenus: focusedMenus.concat(['mission']) });
  }

  onLobbyCallSuperiorButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_call_superior`] = false;
    this.onScheduleSuperior();
    // this.setState({ focusedMenus: focusedMenus.concat(['call_superior']) }); 꺼줄 방법이 마땅치 않아 일단 켜지 않음
  }

  onLobbyMissionObjectiveButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_mission_objective`] = false;
    this.overlayPush({ ty: 'LOBBY-OBJECTIVE' });
    this.setState({ focusedMenus: focusedMenus.concat(['mission_objective']) });
  }

  onLobbyMissionFinalButton() {
    const { game, focusedMenus } = this.state;
    game.highlight_button[`lobby_mission_final`] = false;
    game.prev_final_schedule_key = game.schedule_key;
    game.schedule_key = 'final_brief';

    game.dialogMaybe(['start_final']);

    this.setState({ game, focusLobby: false, focusedMenus: focusedMenus.concat(['mission_objective']) });
  }

  renderFinalDeadCare(overlay) {
    const { deads, clear, sim } = overlay;
    const { onDone } = this.props;
    const { game } = this.state;
    const onClickPortrait = (agent) => this.overlayPush({ ty: "AGENT_DETAIL", agent });
    const totalCost = deads.reduce((sum, agent) => {
      const care_option = data_agentCareOptions.find((d) => d.id === agent.care_option_id);
      return sum + care_option.cost;
    }, 0);

    return <div className='overlay-root overlay-root-shrink'>
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L("loc_ui_stirng_agent_care_severely_injured")}</p>
        <p className="mission-warning-overlay-title">{L("loc_ui_stirng_agent_care_cash")}{game.resources.balance}</p>
        <p>{L("loc_ui_stirng_agent_care_severely_injured_desc")}</p>
        <FigmaListBody characters={deads}
          renderRowExtra={(ch) => {
            const options = data_agentCareOptions.map((option) => {
              return { key: option.id, label: `${L('loc_data_string_agent_care_options_' + option.id)} - $${option.cost}` };
            })
            const selected = data_agentCareOptions.find((d) => d.id === ch.care_option_id);
            const survival_prob = Math.min(ch.base_survival_prob + selected.additional_survival_prob, 1);
            const care_option_text = L(`loc_data_string_agent_care_options_desc_${selected.id}`, { prob: Math.floor(survival_prob * 100) });

            return <ListRowPlan options={options} comment={{ type: 'none', text: care_option_text }} selected={selected.id} onSelect={(key) => {
              ch.care_option_id = key;
              this.setState({ game });
            }} />;
          }}
          onClickPortrait={onClickPortrait}
        />
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" disabled={totalCost > game.resources.balance} onClick={() => {
            game.resources.balance -= totalCost;
            onDone({
              result: clear ? 'clear' : 'fail',
              game,
              sim,
            });
          }}>{L("loc_dynamic_string_agent_care_pay", { value: totalCost })}</Button>
        </div>
      </div>
    </div>;
  }

  renderAgentEquip(overlay) {
    const { game } = this.state;
    const { inventories } = game;
    const { agent, equip_ty, equip_idx } = overlay;
    const { role } = agent;

    const firearmAptitudeKey = {
      'hg': 'firearmAptitude_HG',
      'sg': 'firearmAptitude_SG',
      'smg': 'firearmAptitude_SMG',
      'ar': 'firearmAptitude_AR',
      'dmr': 'firearmAptitude_DMR',
    }

    function getFirearmAptitude(e) {
      if (e.shield && !['tank', 'vanguard'].includes(role)) {
        return 6;
      }
      return rolesBykey(role)['firearmAptitudes'][firearmAptitudeKey[e.firearm_ty]];
    }

    let equip_cur = null;
    let equip_list = null;
    let onClickClear = null;
    let onAgentDisarm = null;
    if (equip_ty === 'firearm') {
      equip_cur = agent.firearm;
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'firearm'), (e) => e.firearm.firearm_name + (e.firearm.options ?? []))
        .filter((e) => getFirearmAptitude(e.firearm) != 6 && e.firearm.key !== 'firearm_hg_t5').map((e) => { return { equip: e.firearm, item: e, owner: null } });
      for (const a of game.agents_avail_all) {
        if (agent.idx !== a.idx && getFirearmAptitude(a.firearm) != 6 && a.firearm.key !== 'firearm_hg_t5') {
          equip_list.push({ equip: a.firearm, item: null, owner: a });
        }
      }
      equip_list.sort((a, b) => {
        return a.equip.firearm_rate - b.equip.firearm_rate;
      }).sort((a, b) => {
        return (getFirearmAptitude(a.equip) ?? 7) - (getFirearmAptitude(b.equip) ?? 0);
      });
      onAgentDisarm = (target_agent, equip_idx) => {
        const item = game.agentDisarmFirearm(target_agent);
        return item;
      }
    } else if (equip_ty === 'equipment') {
      equip_cur = agent.equipment;
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'equipment'))
        .filter((e) => e.equipment.key != 'vest_bulletproof_nothing').map((e) => { return { equip: e.equipment, item: e, owner: null } });
      for (const a of game.agents_avail_all) {
        if (agent.idx !== a.idx && a.equipment.key != 'vest_bulletproof_nothing') {
          equip_list.push({ equip: a.equipment, item: null, owner: a });
        }
      }
      equip_list.sort((a, b) => {
        return a.equip.vest_armor - b.equip.vest_armor
      });
      onAgentDisarm = (target_agent, equip_idx) => {
        const item = game.agentDisarmEquipment(target_agent);
        return item;
      }
    } else if (equip_ty === 'throwable') {
      equip_cur = agent.throwables[equip_idx];
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'throwable'), (e) => e.throwable.throwable_name + (e.throwable.options ?? []))
        .filter((e) => e.throwable.key != 'throwable_none')
        .sort((a, b) => {
          return a.throwable.throwable_rate - b.throwable.throwable_rate
        }).map((e) => { return { equip: e.throwable, item: e, owner: null } });
      for (const a of game.agents_avail_all) {
        if (agent.idx === a.idx) {
          continue;
        }
        for (let i = 0; i < a.throwables.length; i++) {
          const throwable = a.throwables[i];
          if (throwable.key != 'throwable_none') {
            equip_list.push({ equip: throwable, item: null, owner: a, owner_equip_idx: i });
          }
        }
      }
      onAgentDisarm = (target_agent, equip_idx) => {
        const item = game.agentDisarmThrowable(target_agent, equip_idx);
        return item;
      }
    }
    onClickClear = () => {
      onAgentDisarm(agent, equip_idx);
      this.overlayPopMaybe('EQUIP');
    };

    return <FigmaGearExchangeView equip_ty={equip_ty} agent={agent} equip_cur={equip_cur}
      equip_list={equip_list}
      onClickCancel={() => {
        this.overlayPopMaybe('EQUIP');
      }}
      onClickExchange={(idx) => {
        const { owner, owner_equip_idx } = equip_list[idx];
        let { item } = equip_list[idx];
        if (owner) {
          item = onAgentDisarm(owner, owner_equip_idx);
        }
        this.onAgentEquip(agent, item, equip_idx);
        this.overlayPopMaybe('EQUIP');
      }}
      onClickClear={onClickClear}
      onClickUnable={(warning) => {
        this.overlayPush({ ty: 'UNABLE', warning: warning });
        setTimeout(() => this.overlayPopMaybe('UNABLE'), 2000);
      }} />
  }

  renderAgentDetailSmall(overlay) {
    const { agent } = overlay;
    const { game } = this.state;

    return <div className='grind-AgentDetail-root'>
      <div className="grind-AgentDetail-header">
        <div className="grind-AgentDetail-header-inner">
          <span className='grind-AgentDetail-header-title'>{L('loc_ui_title_menu_agent')}</span>
          <SoundButton className="grind-AgentDetail-header-btn-close" onClick={() => this.overlayPopMaybe('AGENT_DETAIL')} >
            {ICON_CLOSE}
          </SoundButton>
        </div>
      </div>
      <div className="grind-AgentDetail-body">
        <DetailView2 character={agent}
          additionalButton={null}
          OnClickBackgroundButton={(agent) => {
            this.overlayPush({ ty: 'AGENT_BACKGROUND', agent });
          }}
          //TODO: 콜사인 수정 기능 추가 필요
          OnEditCallsignButton={() => { }}
          onClickPerkUnlockButton={(character, type, key) => this.unlockAgentPerk(character, type, key)}
          turn={game.turn}
        />
      </div>
    </div>
  }

  renderAgentBackground(overlay) {
    const { agent } = overlay;
    const textRef = React.createRef();
    let debugPanel = null;

    if (this.debug) {
      const { game } = this.state;

      // i18n ignore 8
      debugPanel = <div className="box">
        <textarea ref={textRef} />
        <button onClick={() => {
          const perkKey = textRef.current.value;
          const type = perkKey.split('_')[1] === 'common' ? 'stat' : 'operator';
          activateCharacter2Perk(agent, type, perkKey);
        }}>퍽 획득</button>
        <button onClick={() => {
          const modifierKey = textRef.current.value;
          game.addModifier(agent, modifierKey);
        }}>모디파이어 획득</button>
      </div>
    }

    return <FigmaPopupView
      ty="simple"
      title={L('loc_ui_string_agent_preview')}
      message={<div>
        <p style={{ fontSize: 25 }}>{L('loc_ui_string_agent_background')}</p>
        <p style={{ whiteSpace: 'pre-line' }}>
          <span>{L(agent.background.desc)}</span>
          {debugPanel}
        </p>
      </div>
      }
      choices={null}
      buttons={[
        {
          label: L('loc_ui_button_common_confirm'),
          onClick: () => {
            this.overlayPopMaybe('AGENT_BACKGROUND');
            this.overlayPopMaybe('AGENT_BACKGROUND_ON_EVENT');
          },
        }
      ]}
      scene='tutorial'
    />;
  }

  renderGameOver(overlay) {
    const { result, reason } = overlay;
    const { game } = this.state;

    //TODO: 게임 종료 원인에 따라 엔딩 팝업 변경하기
    let reasonTitle = '';
    let reasonDesc = '';
    switch (reason) {
      case 'deadline_over':
        reasonTitle = 'loc_ui_string_gameover_reason_deadline_over';
        reasonDesc = 'loc_ui_longtext_gameover_reason_deadline_over_desc';
        break;
      case 'final_finish':
        if (result === 'fail') {
          reasonTitle = 'loc_ui_string_gameover_reason_final_finish';
          reasonDesc = 'loc_ui_longtext_gameover_reason_final_finish_desc';
        }
        else {
          reasonTitle = 'loc_ui_string_gameclear_reason_final_finish';
          reasonDesc = 'loc_ui_longtext_gameclear_reason_final_finish_desc';
        }
        break;
      case 'trust_low':
        reasonTitle = 'loc_ui_string_gameover_reason_trust_low';
        reasonDesc = 'loc_ui_longtext_gameover_reason_trust_low_desc';
        break;
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_string_gameover')} - {L(reasonTitle)}</p>
        {L(reasonDesc)}
        <div className="overlay-flex-btngroup flex-config-cta">
          <WishlistButton eventType={(result === 'clear') ? 'gameclear' : 'gameover'} />
          <CommunityButton eventType={(result === 'clear') ? 'gameclear' : 'gameover'} />
          <Button className="mission-warning-overlay-btn" onClick={() => {
            game.gameover = true;
            this.props.onGameOver?.();
            this.overlayPopMaybe('GAMEOVER');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;
  }

  renderGameOverWarning() {
    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_string_gameover_warning_trust_low')}</p>
        {L('loc_ui_longtext_gameover_warning_trust_low_desc')}
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('GAMEOVER-WARNING');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;
  }

  eventConditionText(condition_key, condition_value) {
    let value = condition_value;
    if (condition_key.search('modifier') >= 0) {
      value = L(`loc_data_string_agent_modifier_${condition_value}`);
    }
    else if (condition_key.search('higher_than') >= 0 || condition_key.search('more_than') >= 0) {
      value = parseInt(value) + 1;
    }
    else if (condition_key.search('lower_than') >= 0) {
      value = parseInt(value) - 1;
    }
    return L(`loc_dynamic_longtext_event_condition_${condition_key}`, { value });
  }

  renderEvent() {
    const { game, focusedEvent } = this.state;

    const addBranchResultctx = (ctx, eventBranch, targetAgents, result_idx, ctx_key) => {
      const { results, results_value } = eventBranch;
      if (result_idx >= 0) {
        const result = results[result_idx];
        let effect_targets = targetAgents;
        let effect_id = result;
        const splits = result.split('_');
        if (splits[0] === 'agent') {
          if (splits[1] === 'all') {
            effect_targets = this.agents_avail_all;
          }
          else {
            effect_targets = [targetAgents[parseInt(splits[1])]];
          }
          splits.splice(0, 2);
          effect_id = splits.join('_');
        }
        if (effect_id === 'salary_check') {
          ctx[ctx_key] = -Math.round(effect_targets[0].salary * parseFloat(results_value[result_idx]));
        }
        else if (effect_id === 'add_payment') {
          const payment = game.getAgentPayment(effect_targets[0]);
          ctx[ctx_key] = payment + Math.round(effect_targets[0].salary * parseFloat(results_value[result_idx]));
        }
        else if (effect_id === 'payment_check' && !ctx[ctx_key]) {
          ctx[ctx_key] = game.getAgentPayment(effect_targets[0]);
        }
        else if (effect_id === 'set_dispatch_reward_mult') {
          ctx[ctx_key] = Math.round(game.income * parseFloat(results_value[result_idx]));
        }
        else if (effect_id === 'get_dispatch_reward') {
          ctx[ctx_key] = Math.round(game.income * game.dispatch_reward_mult);
        }
        else if (effect_id === 'set_dispatch_success_prob') {
          ctx[ctx_key] = Math.round(parseFloat(results_value[result_idx]) * 100);
        }
      }
    }

    const checkEventCoreText = (ctx, eventCore, eventBranches, targetAgents) => {
      const searchString = 'result_';
      let startIdx = 0, idx;

      const descText = L(eventCore.desc, ctx);
      if (eventCore.desc) {
        while ((idx = descText.indexOf(searchString, startIdx)) >= 0) {
          if (descText.substring(idx - 9, idx - 3) === 'branch') {
            const branch_idx = parseInt(descText[idx - 2]);
            const result_idx = parseInt(descText[idx + searchString.length]);
            const targetBranch = eventBranches[branch_idx];
            addBranchResultctx(ctx, targetBranch, targetAgents, result_idx, `branch_${branch_idx}_result_${result_idx}`);
          }
          startIdx = idx + searchString.length;
        }
      }
    }

    const checkEventBranchText = (ctx, eventBranch, targetAgents) => {
      const searchString = 'result_';
      let startIdx = 0, idx;

      const targetTexts = [];
      if (eventBranch.desc) {
        targetTexts.push(L(eventBranch.desc, ctx));
      }
      if (eventBranch.mouseover) {
        targetTexts.push(L(eventBranch.mouseover, ctx));
      }
      for (const targetText of targetTexts) {
        startIdx = 0;
        while ((idx = targetText.indexOf(searchString, startIdx)) >= 0) {
          const result_idx = parseInt(targetText[idx + searchString.length]);
          addBranchResultctx(ctx, eventBranch, targetAgents, result_idx, `result_${result_idx}`);
          startIdx = idx + searchString.length;
        }
      }
    }

    const event = focusedEvent;
    const { ty, value } = event;

    const eventPop = () => {
      game.events.pop();
      this.overlayPopMaybe('EVENT');
      this.setState({ game, focusedEvent: null });
    };

    if (ty === 'eventAgents') {
      const { eventCore, targetAgents, triggerSoundOnEvent } = value;
      const { game } = this.state;

      let desc = eventCore.desc;
      if (targetAgents.length > 0) {
        const fixedOperatorKey = targetAgents[0].fixedOperatorKey;
        if (fixedOperatorKey) {
          const specialDesc = eventCore[fixedOperatorKey.replaceAll(' ', '_')];
          if (specialDesc && specialDesc.length > 0) {
            desc = specialDesc;
          }
        }
      }

      const ctx = { interpolation: { escapeValue: false } };
      for (let i = 0; i < targetAgents.length; i++) {
        ctx[`name_agent_${i}`] = L(targetAgents[i].name);
      }
      if (value.quest_id) {
        const quest = game.quests.find((q) => q.id === value.quest_id);
        if (quest) {
          ctx['quest_desc'] = game.makeQuestDescText(quest);
        }
      }

      const eventBranches = data_eventBranches.filter((d) => eventCore.branch.includes(d.id));
      const choices = [];
      const exposedAgentsIndex = [];

      checkEventCoreText(ctx, eventCore, eventBranches, targetAgents);

      const findExposedAgents = (text) => {
        let text_copy = text ? L(text, ctx).slice() : '';
        const searchString = 'name_agent_';
        let idx = text_copy.indexOf(searchString);

        while (idx >= 0) {
          const agentIdx = Number(text_copy[idx + searchString.length]);
          if (!exposedAgentsIndex.includes(agentIdx)) {
            exposedAgentsIndex.push(agentIdx);
          }
          text_copy = text_copy.substr(idx + searchString.length + 1);
          idx = text_copy.indexOf(searchString);
        }
      }

      findExposedAgents(desc);

      for (const branch of eventBranches) {
        const checkResult = game.checkBranchCondition(targetAgents[0], branch, targetAgents);
        const condition_check_passed = checkResult.passed;
        if (condition_check_passed || branch.disable_with_condition) {
          const ctx_branch = { ...ctx };
          checkEventBranchText(ctx_branch, branch, targetAgents);

          const { results } = branch;
          if (results[0] === 'trigger_event') {
            let { results_weight } = branch;
            if (results_weight[0] === 'agentRelations') {
              const relationData = getRelationData(targetAgents[0].relation);
              if (relationData) {
                results_weight = [relationData.prob_success, 1 - relationData.prob_success];
              }
            }

            const weight_percent = Math.round(results_weight[0] * 100 / results_weight.reduce((a, b) => a + b, 0));
            ctx_branch['results_weight'] = weight_percent;
          }

          const cueList = results[0] === 'trigger_event' ? ['UI_Outgame_Button_Click_EventOnly'] : ['UI_Outgame_Button_Click_EventOnly_Confirm'];
          let title = L(branch.mouseover, ctx_branch);
          if (!condition_check_passed && branch.show_disable_reason) {
            switch (checkResult.reason) {
              case 'condition':
                for (let i = 0; i < branch.condition.length; i++) {
                  title += `\n(${this.eventConditionText(branch.condition[i], branch.condition_value[i])})`;
                }
                break;
              case 'money':
                title += `\n${L(`loc_dynamic_longtext_event_condition_money`, { value })}`;
                break;
              case 'market_item':
              default:
                break;
            }
          }
          choices.push({
            key: L(branch.desc, ctx_branch),
            title,
            disabled: !condition_check_passed,
            cueList,
            onClick: () => {
              eventPop();
              game.applyEventBranchEffect(branch, targetAgents, event);
              if (game.schedule_key === 'event_agent' && game.events.length <= 0) {
                game.schedule_key = '';
              }
              this.setState({ game });
            },
            header: L('loc_data_string_event_branch_keyword_' + branch.keyword),
            body: L(branch.desc, ctx_branch),
          });

          findExposedAgents(branch.desc);
          findExposedAgents(branch.mouseover);
        }
      }

      if (choices.length === 0) {
        choices.push({
          key: 'fallback',
          disabled: false,
          cueList: ['UI_Outgame_Button_Click_EventOnly_Confirm'],
          onClick: () => {
            eventPop();
            if (game.schedule_key === 'event_agent' && game.events.length <= 0) {
              game.schedule_key = '';
            }
            this.setState({ game });
          },
          body: L('loc_ui_button_popup_common_close'),
        });
      }

      return this.renderFigmaPendingEvent({
        event,
        eventID: triggerSoundOnEvent === false ? null : eventCore.id,
        title: L(eventCore.title),
        message: L(desc, ctx),
        agents: targetAgents,
        choices,
        tags: null, // TODO
        scene: eventCore.scene,
      });
    }
    else if (ty === 'eventCEO') {
      const { eventCore, triggerSoundOnEvent } = value;
      let { target } = value;
      const { game } = this.state;
      const eventBranches = data_eventBranches.filter((d) => eventCore.branch.includes(d.id));

      const targetAgents = [];

      const ctx = { interpolation: { escapeValue: false } };
      target = game.agents_all.find((a) => a.idx === target?.idx);
      if (target) {
        ctx[`name_agent_0`] = L(target.name);
        targetAgents.push(target);
      }

      let desc = eventCore.desc;
      if (targetAgents.length > 0) {
        const fixedOperatorKey = targetAgents[0].fixedOperatorKey;
        if (fixedOperatorKey) {
          const specialDesc = eventCore[fixedOperatorKey.replaceAll(' ', '_')];
          if (specialDesc && specialDesc.length > 0) {
            desc = specialDesc;
          }
        }
      }

      checkEventCoreText(ctx, eventCore, eventBranches, targetAgents);

      const choices = [];
      if (eventBranches.length > 0) {
        for (const branch of eventBranches) {
          const checkResult = game.checkBranchCondition(targetAgents[0], branch, targetAgents);
          const condition_check_passed = checkResult.passed;
          if (condition_check_passed || branch.disable_with_condition) {
            const ctx_branch = { ...ctx };
            checkEventBranchText(ctx_branch, branch, targetAgents);

            const { results } = branch;
            if (results[0] === 'trigger_event') {
              let { results_weight } = branch;
              if (results_weight[0] === 'agentRelations') {
                const relationData = getRelationData(targetAgents[0].relation);
                if (relationData) {
                  results_weight = [relationData.prob_success, 1 - relationData.prob_success];
                }
              }

              const weight_percent = Math.round(results_weight[0] * 100 / results_weight.reduce((a, b) => a + b, 0));
              ctx_branch['results_weight'] = weight_percent;
            }
            const cueList = results[0] === 'trigger_event' ? ['UI_Outgame_Button_Click_EventOnly'] : ['UI_Outgame_Button_Click_EventOnly_Confirm'];
            let title = L(branch.mouseover, ctx_branch);
            if (!condition_check_passed && branch.show_disable_reason) {
              switch (checkResult.reason) {
                case 'condition':
                  for (let i = 0; i < branch.condition.length; i++) {
                    title += `\n(${this.eventConditionText(branch.condition[i], branch.condition_value[i])})`;
                  }
                  break;
                case 'money':
                  title += `\n${L(`loc_dynamic_longtext_event_condition_money`, { value })}`;
                  break;
                case 'market_item':
                default:
                  break;
              }
            }
            choices.push({
              key: L(branch.desc, ctx_branch),
              title,
              disabled: !condition_check_passed,
              cueList,
              onClick: () => {
                eventPop();
                game.applyEventBranchEffect(branch, targetAgents, event);
                this.setState({ game });
              },
              header: L('loc_data_string_event_branch_keyword_' + branch.keyword),
              body: L(branch.desc, ctx_branch),
            });
          }
        }
      }

      if (choices.length === 0) {
        choices.push({
          key: 'fallback',
          disabled: false,
          cueList: ['UI_Outgame_Button_Click_EventOnly_Confirm'],
          onClick: () => {
            eventPop();
            this.setState({ game });
          },
          body: L('loc_ui_button_popup_common_close'),
        });
      }

      let agents = [];
      if (target) {
        agents.push(target);
      }

      return this.renderFigmaPendingEvent({
        event,
        eventID: triggerSoundOnEvent === false ? null : eventCore.id,
        title: L(eventCore.title),
        message: L(desc, ctx),
        agents,
        choices,
        tags: null, // TODO
        scene: eventCore.scene,
      });
    } else {
      return null;
    }
  }

  renderFigmaPopup(props) {
    const { ty, title, message, agents, buttons, scene } = props;
    return <div className="overlay-root overlay-root-shrink">
      <FigmaPopupView
        ty={ty}
        title={title}
        message={message}
        agents={agents}
        choices={null}
        buttons={buttons}
        scene={scene}
        OnClickBackgroundButton={(agent) => {
          this.overlayPush({ ty: 'AGENT_BACKGROUND_ON_EVENT', agent });
        }}
      />
    </div>;
  }

  renderFigmaPendingEvent(props) {
    const { event, eventID, title, message, agents, tags, scene } = props;

    const choices = props.choices.slice();
    if (event.ty !== 'eventCEO' && event.src_ty !== 'eventCEO' && choices.length > 1) {
      // TODO
      event.allow_postpone = true;
      choices.push({
        key: 'postpone',
        className: 'postpone',
        disabled: false,
        cueList: ['UI_Outgame_Button_Click_EventOnly_Confirm'],
        title: L('loc_data_longtext_event_branch_mouseover_event_common_postpone'),
        onClick: () => {
          this.setState({ showEvent: false });
        },
        header: L('loc_data_string_event_branch_keyword_postpone'),
        body: L('loc_data_longtext_event_branch_desc_event_common_postpone'),
      });
    } else {
      event.allow_postpone = false;
    }

    return <TooltipContext.Consumer>
      {(context) => {
        let display_ty = 'unknown';
        let cooldown = data_eventCooldowns.find((d) => d.category === event?.value?.eventCore?.category);
        if (cooldown) {
          display_ty = cooldown.display_ty;
        }

        return <FigmaPopupView
          ty={event.ty}
          eventID={eventID}
          title={title}
          message={message}
          agents={agents}
          choices={choices}
          tags={tags}
          scene={scene}
          className={display_ty}
          OnClickBackgroundButton={(agent) => {
            this.overlayPush({ ty: 'AGENT_BACKGROUND_ON_EVENT', agent });
          }}
        />;
      }}
    </TooltipContext.Consumer>;
  }

  renderGeneralPopup(props) {
    this.overlayPush({ ty: 'GENERAL', props });
  }

  renderMissionResult(game, popup) {
    const { squads } = game;
    const squadIdx = 0;
    const squad = squads[squadIdx];

    const onClose = popup ? () => this.onMissionResultClose(popup) : null;

    return <div className="overlay-root">
      <div className="overlay-root overlay-root-shrink">
        <FigmaMissionResultView game={game} squad={squad} needExp={game.mission_schedule_ty !== 'trial'} onClose={onClose} />
      </div>
    </div>;
  }

  renderMissionExp(game, popup) {
    const { squads } = game;
    const squadIdx = 0;
    const squad = squads[squadIdx];

    const onClose = popup ? () => this.onMissionExpClose(popup) : null;

    return <div className="overlay-root">
      <div className="overlay-root overlay-root-shrink">
        <FigmaMissionExpView game={game} squad={squad} needExp={game.mission_schedule_ty !== 'trial'} onClose={onClose} />
      </div>
    </div>;
  }

  renderCheckpointResult(game, popup) {
    let btn = null;
    if (popup) {
      btn = <Button className="mission-warning-overlay-btn" onClick={() => {
        this.overlayPopMaybe('CHEKPOINT-RESULT');
        this.onCheckpointResultClose(popup);
      }}>{L('loc_ui_button_popup_common_close')}</Button>
    }

    // 마지막 checkpoint의 결과
    const { history } = game.squads[0];
    const history_item = history[history.length - 1];

    return <div className="overlay-root">
      <div className="overlay-root overlay-root-shrink">
        <div className='figmamissionresult-root'>
          <div className='figmamissionresult-title'>{L('loc_data_longtext_checkpoint_result_title')}</div>
          <Lobby2Goals states={history_item.checkpoint_result.states} show_result={true} />
          {btn}
        </div>
      </div>
    </div>;
  }

  renderAgentPerkAcquire(overlay) {
    const { agent_idx, ty, perks, remainReroll } = overlay;
    const { game, overlays } = this.state;
    const { agents_all } = game;

    const agent = agents_all.find((a) => a.idx === agent_idx);
    if (!agent || perks.length <= 0) {
      return <div className="overlay-root overlay-root-shrink">
        <div className="overlay-flex">
          <div className="overlay-flex-btngroup">
            <Button className="mission-warning-overlay-btn" onClick={() => {
              this.overlayPopMaybe(ty);
            }}>{L('loc_ui_button_popup_common_close')}</Button>
          </div>
        </div>
      </div>;
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <div className='figmalist-root figmapopup-perks-acquire'>
          <div className='figmalist-header'>
            <span className='figmalist-header-title-label'>{L('loc_ui_string_popup_perks_acquire')}</span>
          </div>
          <div className='figmalist-body'>
            <Detailview character={agent}
              getCharacterPaymentFunction={(agent) => game.getAgentPayment(agent)}
              OnClickBackgroundButton={(agent) => { }}
              onClickPerkUnlockButton={(character, type, key) => { }} />
            <div className='figmapopup-perks-acquire-right'>
              <div className='figmapopup-perks-acquire-desc'>
                {L('loc_ui_longtext_popup_perks_acquire_desc')}
              </div>
              <div className='figmapopup-perks-acquire-list'>
                {perks.map((perk, i) => <PerkAcquireView
                  key={i}
                  perk={perk}
                  onPerkAcquire={(perk) => {
                    activateCharacter2Perk(agent, perk.ty, perk.key);
                    this.overlayPopMaybe(ty);
                    if (overlays.length <= 0 && game.popups.length <= 0) {
                      const onDone = this.props?.onDone;
                      if (game.mission_schedule_ty === 'final' && onDone) {
                        onDone({
                          result: 'clear',
                          game,
                          sim: this.state.sim,
                        });
                      }
                    }
                  }}
                  tactical={agent.realStats.tactical}
                />)}
              </div>
            </div>
          </div>
          <div className='figmapopup-buttongroup inline'>
            <div
              className={`figmalist-reroll-button${(remainReroll <= 0) ? " disabled" : ""}`}
              onClick={() => {
                if (remainReroll > 0) {
                  overlay.remainReroll--;
                  overlay.perks = game.makePerkSelectList(agent, perks);
                  this.setState({ game });
                }
              }}>
              <div className="figmalist-reroll-button-emboss" />
              <div className="figmalist-reroll-button-background" />
              <div className="figmalist-reroll-button-outline">
                <span className="figmalist-reroll-button-label">
                  {/*TODO: locale 추가*/}
                  Reroll</span>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >;
  }

  renderAgentReport(overlay) {
    const { game } = this.state;
    const agentReports = [];
    for (let i = 0; i < game.agents_avail_all.length; i++) {
      const agent = game.agents_avail_all[i];
      let prevAgentState = game.agentStates.find((a) => a.idx === agent.idx);
      let prevModifier;
      if (prevAgentState) {
        prevModifier = prevAgentState.modifier;
      }
      else {
        prevAgentState = agent;
        prevModifier = agent.modifier.map((m) => m.key);
      }
      const schedule_key = agent.last_schedule ?? agent.schedule;
      const power_diff = (agent.power - prevAgentState.power).toFixed(1);
      const condition_diff = (agent.condition - prevAgentState.condition).toFixed(1);
      const mood_diff = (agent.mood - prevAgentState.mood).toFixed(1);
      let level = agent.level.cur;
      let exp_total = agent.level.exp;
      for (let i = prevAgentState.level; i < agent.level.cur; i++) {
        exp_total += data_operatorExp[i - 1].expMax;
      }
      let exp_diff = exp_total - prevAgentState.exp;
      const exp_max = data_operatorExp.length >= prevAgentState.level ? data_operatorExp[prevAgentState.level - 1].expMax : -1;

      const modifiers_current_key = agent.modifier.map((m) => m.key);
      const modifiers_remove_key = [];
      const modifiers_current = [];
      const modifiers_remove = [];
      const perks = [];
      for (const key of prevModifier) {
        const idx = modifiers_current_key.findIndex((m) => m === key);
        if (idx >= 0) {
          modifiers_current_key.splice(idx, 1);
        }
        else {
          modifiers_remove_key.push(key);
        }
      }

      let idx = 0;
      for (const key of modifiers_current_key) {
        const data = data_agentModifiers.find((d) => d.key === key);
        if (data.visible) {
          modifiers_current.push(data);
        }
      }
      for (const key of modifiers_remove_key) {
        const data = data_agentModifiers.find((d) => d.key === key);
        if (data.visible) {
          modifiers_remove.push(data);
        }
      }

      for (const [key, value] of Object.entries(agent.statsPerks)) {
        if (prevAgentState.statsPerks[key] === 'blocked' && value === 'deactivated') {
          const perkData = statsPerksdata[key];
          perks.push(perkData);
        }
      }
      for (const [key, value] of Object.entries(agent.operatorPerks)) {
        if (prevAgentState.operatorPerks[key] === 'blocked' && value === 'deactivated') {
          const perkData = rolesPerksdata[key];
          perks.push(perkData);
        }
      }

      agentReports.push({
        agent,
        schedule_key,
        train_result: agent.train_result,
        power: agent.power,
        power_diff,
        condition: agent.condition.toFixed(1),
        condition_diff: condition_diff,
        mood: agent.mood.toFixed(1),
        mood_diff,
        modifiers_current,
        modifiers_remove,
        perks: perks,
        level,
        exp: exp_total,
        exp_diff,
        exp_max,
      });
    }

    return <FigmaAgentReportView onClickCheck={() => {
      this.overlayPopMaybe(overlay.ty);
    }}
      agentReports={agentReports} />;
  }

  renderWeeklyMeeting(overlay) {
    const { game } = this.state;

    return <FigmaPopupView
      ty='system'
      title={L('loc_ui_string_popup_weekly_report')}
      message={<div className="milestone-notice-root">
        <WeeklyMeetingView game={game} />
      </div>
      }
      choices={null}
      buttons={[
        {
          label: L('loc_ui_button_popup_common_close'),
          onClick: () => {
            this.overlayPopMaybe('WEEKLY_MEETING');
          },
        }
      ]}
      scene='tutorial'
    />;
  }

  renderMilestoneNotice(overlay) {
    const { game } = this.state;

    // TODO
    const { config_key } = game.squads[0];
    const diff = game.getPlanPresetDiff(config_key, 0);
    const states = game.getMilestoneStates();

    return <FigmaPopupView
      ty='system'
      title={L('loc_data_longtext_milestone_title', { week: game.milestone_week })}
      message={<div className="milestone-notice-root">
        <p className="mission-warning-overlay-title"></p>

        {
          (diff.added.length > 0 || diff.updated.length > 0) ?
            <div className="lobby2-clipboard-section-border">
              <h1>{L('loc_data_longtext_milestone_enemy_changed_title')}</h1>
              <div className='box'>
                {diff.added.length > 0 ? <h2>{L('loc_data_longtext_milestone_enemy_added', { count: diff.added.length })}</h2> : null}
                {diff.added.map(({ enemy }, i) => <div key={i}><EnemyBadge enemy={enemy} /></div>)}

                {diff.updated.length > 0 ? <h2>{L('loc_data_longtext_milestone_enemy_updated', { count: diff.updated.length })}</h2> : null}
              </div>
            </div>
            : null
        }

        <div className="lobby2-clipboard-section-border">
          <h1>{L('loc_data_longtext_milestone_objective_updated_title')}</h1>
          <Lobby2Goals states={states} />
        </div>
      </div>
      }
      choices={null}
      buttons={[
        {
          label: L('loc_ui_button_common_confirm'),
          onClick: () => {
            this.overlayPopMaybe(overlay.ty);
          },
        }
      ]}
      scene='tutorial'
    />;
  }

  renderWeekTransition(overlay) {
    const { game } = this.state;
    const { week } = overlay;
    return <WeekTransitionView week={week} onFinish={() => {
      this.overlayPopMaybe('WEEK-TRANSITION');
      game.schedule_key = '';
      this.setState({ game });
    }} />;
  }

  static stateDescr(state) {
    const { turn, resources, agents_avail_all, squads } = state;
    const { config_key } = squads[0];
    const missionString = L(CONFIGS.find((c) => c.key === config_key).descr);
    const daysString = L('loc_dynamic_string_save_days_ordinal', { count: Math.floor(turn / 24) + 1, ordinal: true });
    const balanceString = '$' + resources.balance.toLocaleString("en-US");
    const agentsString = L('loc_dynamic_string_missionbrief_mission_squad_size', { count: agents_avail_all.length });
    return missionString + ', ' + daysString + ', ' + balanceString + ', ' + agentsString;
  }

  // save-load
  serializeState(idx, extra_descr) {
    const { game } = this.state;

    let descr = GrindView.stateDescr(game);
    if (extra_descr) {
      descr = `${descr} (${extra_descr})`;
    }

    const data = JSON.stringify({ ...game });

    (async () => {
      const savedescrs = await this.saveimpl.saveSlot(idx, data, descr);
      this.setState({ savedescrs });
    })();
  }

  discardState(idx) {
    (async () => {
      const savedescrs = await this.saveimpl.discardSlot(idx);
      this.setState({ savedescrs });
    })();
  }

  deserializeState(data) {
    function fixObject(o) {
      if (Array.isArray(o)) {
        return o.map(fixObject);
      }
      if (!isNaN(o)) {
        return o;
      }
      if (typeof o === 'string') {
        return o;
      }

      const keys = Object.keys(o);
      if (keys.length === 2 && keys[0] === 'x' && keys[1] === 'y') {
        return new v2(o.x, o.y);
      }

      for (const key of keys) {
        o[key] = fixObject(o[key]);
      }
      return o;
    }

    const state = JSON.parse(data);
    fixObject(state);

    state.rng = new Rng(Rng.randomseed());

    const game = new GrindGame(state);

    if (game.configs) {
      game.configs_unlocked = game.configs.filter((c) => !c.locked).map((c) => c.key);
      delete game.configs;
    }

    this.setState({
      game,
      overlays: [],
      focusedEvent: null,
    });
  }

  renderSystem() {
    const { saveRef } = this;
    const { game, savedescrs } = this.state;

    function downloadStringAsFile(filename, content) {
      var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      var downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    const cansave = !game.state;

    const buildSaveButton = (idx) => {
      if (idx < AUTOSAVE_SLOTS) {
        return null;
      }
      return <ButtonInline onClick={() => {
        this.serializeState(idx);
        GameAnalytics.addDesignEvent(`save:${idx}`);
      }} disabled={!cansave}>{L('loc_ui_button_system_save')}</ButtonInline>;
    };

    let slotcount = Math.max(savedescrs.length, AUTOSAVE_SLOTS);
    let slots = [];
    for (let idx = 0; idx < slotcount; idx++) {
      const descr = savedescrs[idx];

      const load = async () => {
        return await this.saveimpl.loadSlot(idx);
      };
      const loadButton = <>
        <ButtonInline onClick={() => {
          (async () => {
            this.deserializeState(await load());
          })();
        }}>{L('loc_ui_button_system_load')}</ButtonInline>
        <ButtonInline onClick={() => {
          (async () => {
            downloadStringAsFile(`save_${idx}.json`, await load());
          })();
        }}>{L('loc_ui_button_system_export_file')}</ButtonInline>
        <ButtonInline onClick={() => {
          (async () => {
            saveRef.current.innerText = await load();
          })();
        }}>{L('loc_ui_button_system_export_string')}</ButtonInline>
      </>;
      let saveButton = buildSaveButton(idx);
      const discardButton = <ButtonInline onClick={() => this.discardState(idx)}>{L('loc_ui_button_system_delete_save')}</ButtonInline>;

      if (!descr) {
        slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot_empty', { idx })} {saveButton}</div>);
        continue;
      }
      slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot', { idx, descr })} {loadButton} {saveButton} {discardButton}</div>);
    }

    {
      const idx = slotcount;
      let saveButton = buildSaveButton(idx);
      slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot_empty', { idx })} {saveButton}</div>);
    }

    let finishbuttons = null;
    const { onDone } = this.props;
    if (this.debug && onDone) {
      finishbuttons = <>
        <ButtonInline onClick={() => {
          const { game } = this.state;
          game.squads[0].state = 'mission';
          game.mission_schedule_ty = 'final';
          this.onFinish(0, null, 0);
        }}>debug: clear</ButtonInline>
        <ButtonInline onClick={() => {
          const { game } = this.state;
          game.squads[0].state = 'mission';
          game.mission_schedule_ty = 'final';
          this.onFinish(0, null, 1);
        }}>debug: fail</ButtonInline>
        <ButtonInline onClick={() => {
          onDone({ result: 'clear', game, sim: null });
        }}>debug: half dead</ButtonInline>
      </>;
    }

    return <div style={{ height: "100%" }}>
      <p className="flex-title">{L('loc_ui_string_save_load')}</p>
      {slots}
      <textarea ref={saveRef} />
      <ButtonInline onClick={(() => {
        const data = saveRef.current.value;
        this.deserializeState(data);
      })}>import</ButtonInline>
      {finishbuttons}
      <div>
        <ButtonInline onClick={() => {
          this.props.onGameOver?.();
        }}>go to title</ButtonInline>
        <ButtonInline onClick={() => {
          window.ue?.connection?.onquit?.();
        }}>quit game</ButtonInline>
      </div>
      <p className="flex-title">{L('loc_ui_string_system_setting')}</p>
      <div className="box">
        <p className="flex-config-title">{L('loc_ui_string_system_language')}</p>
        {localeKeys.map((locale) => {
          let cls = "mission-warning-overlay-btn2";
          if (locale === this.state.locale) {
            cls += " mission-warning-overlay-btn2-selected";
          }
          return <SoundButton key={locale} className={cls} onClick={() => {
            localeSet(locale);
            this.setState({ locale });
          }}>{L('loc_ui_string_system_language_' + locale.toLowerCase())}</SoundButton>;
        })}
      </div>
      <span className="flex-config-cta">
        <WishlistButton eventType={'system'} />
        <CommunityButton eventType={'system'} />
      </span>
      {this.renderSystemDebug()}
    </div >;
  }

  renderSystemDebug() {
    const { game } = this.state;

    if (!this.debug) {
      return null;
    }

    const soundDebugButtons = Object.keys(soundDebugEvents).map((eventName) => {
      return {
        title: eventName,
        onClick: () => {
          this.overlayPopMaybe('LOBBY-MENU');
          game.debugTriggerEvent(soundDebugEvents[eventName]);
          this.setState({ game });
        }
      };
    });

    const eventDebugButtons = data_eventCores.map((e, i) => {
      return {
        title: `#${i}: ${L(e.title)}`,
        onClick: () => {
          this.overlayPopMaybe('LOBBY-MENU');
          game.debugTriggerEvent(e.id);
          this.setState({ game });
        },
      };
    });

    // i18n ignore 60
    return <>
      <div className="box">
        <p className="flex-title">디버그</p>
        <button onClick={() => {
          game.resources.balance += 10000;
          this.setState({ game });
        }}>돈 추가: $10000</button>
        <button onClick={() => {
          this.overlayPush({ ty: 'DEBUG-EVENT-COUNT' })
        }}>이벤트 발생 횟수</button>
        <button onClick={() => {
          game.lobby = { ...OPTS_DEFAULT.lobby };
          this.setState({ game });
        }}>로비 버튼 활성화</button>
        <button onClick={() => {
          game.disable_button = { ...OPTS_DEFAULT.disable_button };
          this.setState({ game });
        }}>나머지 버튼 활성화</button>
        <button onClick={() => {
          game.enable_final = true;
          this.setState({ game });
        }}>실전 활성화</button>
        <button onClick={() => {
          game.triggered_dialogs = data_dialogs.map((d) => d.idx);
          game.lobby = { ...OPTS_DEFAULT.lobby };
          game.disable_button = { ...OPTS_DEFAULT.disable_button };
          this.setState({ game });
        }}>다이얼로그 스킵</button>
        <button onClick={() => {
          game.triggerGameOver(false, 'event');
          this.setState({ game });
        }}>게임오버</button>
      </div>
      <div className="box">
        <button onClick={() => {
          const day = this.dayRef.current.value;
          game.setTurn(day * TICK_PER_DAY);
          this.setState({ game });
        }}>n일차로 이동</button>
        <textarea ref={this.dayRef} />
      </div>
      <div className="box">
        <p className="flex-title">사운드 디버그용</p>
        <p className="flex-config-title">{"특정 이벤트 실행"}</p>
        <ButtonsWithFilter buttons={soundDebugButtons} />
      </div>
      <div className="box">
        <p className="flex-title">이벤트 디버그용</p>
        <p className="flex-config-title">{"특정 이벤트 실행"}</p>
        <ButtonsWithFilter buttons={eventDebugButtons} />
      </div>
    </>;
  }

  renderSystemPopup() {
    return <FigmaPopupView
      ty='system'
      title={L('loc_ui_title_menu_system')}
      message={this.renderSystem()}
      choices={null}
      buttons={[
        {
          label: L('loc_ui_button_common_confirm'),
          onClick: () => {
            this.overlayPopMaybe('LOBBY-MENU');
          },
        }
      ]}
      scene='tutorial'
    />;
  }


  renderDebugEventCount() {
    const { game } = this.state;
    // i18n ignore 12
    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">이벤트 발생 통계</p>
        {Object.entries(game.debug_eventCounts).map(([key, value], i) => <div>{key} - {value}번</div>)}
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('DEBUG-EVENT-COUNT');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;

  }

  unlockAgentPerk(agent, type, key) {
    const { game } = this.state;
    activateCharacter2Perk(agent, type, key);
    this.setState({ game });
  }

  renderShopPopup() {
    const { game, focusedMenus, selectedItem, buyOrSellTriggered } = this.state;
    const { pendings } = game;
    const schedule_id = 'meeting_dealer';
    const schedule = data_scheduleCEOs.find((d) => d.id === schedule_id);

    const subtitle = [];
    const pending_market = pendings.find((p) => p.ty === 'market');
    if (pending_market) {
      const remain = pending_market.turn - game.turn;
      if (remain > 0) {
        const refreshDays = Math.ceil(remain / TICK_PER_DAY);
        subtitle.push(<div key='days' className="figmalist-header-subtitle">{L('loc_dynamic_longtext_days_until_refresh', { count: refreshDays, })}</div>);
      }
    }

    const buyCostFunction = (item) => game.getItemBuyCost(item.ty, item.original_buy_cost, item.buy_discount_percent);

    const isDealed = game.ceoSchedules.includes(schedule_id);

    const body = <FigmaMarketView items={this.state.game.market_listings} buttonDisabled={game.events.length > 0 || this.state.focusedEvent} balance={game.resources.balance}
      originalCostFunction={(item) => game.getItemBuyCost(item.ty, item.original_buy_cost, 0)}
      buyCostFunction={buyCostFunction}
      onMarketListingPurchase={this.onMarketListingPurchase.bind(this)}
      disableSchedule={isDealed || game.events.length > 0 || this.state.focusedEvent}
      subtitle={subtitle}
      scheduleTitle={'loc_data_longtext_schedule_ceo_' + schedule.id + '_desc'}
      scheduleName={'loc_data_string_schedule_ceo_' + schedule.id}
      onClickSchedule={() => {
        this.onScheduleCEO(schedule, null, false);
      }}
      onClickButtonClose={() => {
        this.overlayPopMaybe('LOBBY-SHOP');
        this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
      }}
      onSelectedChange={(newSelectedItem) => this.setState({ selectedItem: newSelectedItem })}
      purchaseTriggered={buyOrSellTriggered}
      isDealed={isDealed}
    />;
    if (buyOrSellTriggered) this.setState({ buyOrSellTriggered: false });

    let buy_cost;
    if (selectedItem) buy_cost = buyCostFunction(selectedItem);
    else {
      setTimeout(() => buy_cost = buyCostFunction, 1);
    }

    const Lobby2FooterButtons = [{
      key: 'shop_prev',
      label: L('loc_ui_string_lobby_button_next_back'),
      desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
      onClick: () => {
        this.overlayPopMaybe('LOBBY-SHOP');
        this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
      },
      enabled: true,
      className: 'lobby2-page-button-wide secondary',
    }, {
      key: 'shop_purchase',
      label: L('loc_ui_string_market_buy'),
      desc: '',
      onClick: () => {
        this.onMarketListingPurchase(selectedItem);
      },
      enabled: selectedItem && buy_cost <= game.resources.balance,
      className: 'lobby2-page-button-wide',
    }, this.buildEventActionButton()];

    return <>{body}
      <Lobby2Footer
        game={game}
        overrideNextActionButtons={Lobby2FooterButtons}
      />
    </>;
  }

  renderInventoryPopup() {
    const { game, focusedMenus, selectedItem, buyOrSellTriggered } = this.state;
    const { resources, agents_avail_all } = game;
    let { nextItemId } = game;

    let sellItem = null;
    if (buyOrSellTriggered) this.setState({ buyOrSellTriggered: false });

    if (game.config.opts.allow_sell) {
      sellItem = (item) => {
        const sell_cost = game.getItemSellCost(item.ty, item.original_sell_cost)
        resources.balance += sell_cost;
        game.inventories = game.inventories.filter((e) => e != item)

        this.setState({ game });
        this.setState({ buyOrSellTriggered: true });
      }
    }

    const items = game.inventories.filter((i) => i.original_sell_cost > 0);

    for (const a of agents_avail_all) {
      if (a.firearm.key !== 'firearm_hg_t5') {
        items.push({ ty: 'firearm', original_buy_cost: a.firearm.buy_cost, original_sell_cost: a.firearm.sell_cost, firearm: a.firearm, id: nextItemId++, buy_discount_percent: 0, owner: a });
      }
      if (a.equipment.key !== 'vest_bulletproof_nothing') {
        items.push({ ty: 'equipment', original_buy_cost: a.equipment.buy_cost, original_sell_cost: a.equipment.sell_cost, equipment: a.equipment, id: nextItemId++, buy_discount_percent: 0, owner: a });
      }
      for (const t of a.throwables) {
        if (t.key !== 'throwable_none') {
          items.push({ ty: 'throwable', original_buy_cost: t.buy_cost, original_sell_cost: t.sell_cost, throwable: t, id: nextItemId++, buy_discount_percent: 0, owner: a });
        }
      }
    }

    const Lobby2FooterButtons = [{
      key: 'inventory_prev',
      label: L('loc_ui_string_lobby_button_next_back'),
      desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
      onClick: () => {
        this.overlayPopMaybe('LOBBY-INVENTORY');
        this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
      },
      enabled: true,
      className: 'lobby2-page-button-wide secondary',
    }];
    if (game.config.opts.allow_sell) {
      Lobby2FooterButtons.push({
        key: 'inventory_sell',
        label: L('loc_ui_string_market_sell'),
        desc: '',
        onClick: () => {
          sellItem(selectedItem);
        },
        enabled: selectedItem && !selectedItem.owner,
        className: 'lobby2-page-button-wide',
      });
    }
    Lobby2FooterButtons.push(this.buildEventActionButton());


    return <>
      <FigmaInventoryView items={items} onSellItem={sellItem} buttonDisabled={game.events.length > 0 || this.state.focusedEvent || !game.config.opts.allow_sell} balance={game.resources.balance}
        sellCostFunction={(item) => game.getItemSellCost(item.ty, item.original_sell_cost)}
        onClickButtonClose={() => {
          this.overlayPopMaybe('LOBBY-INVENTORY');
          this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
        }}
        onSelectedChange={(newSelectedItem) => this.setState({ selectedItem: newSelectedItem })}
        sellTriggered={buyOrSellTriggered}
      />
      <Lobby2Footer
        game={game}
        overrideNextActionButtons={Lobby2FooterButtons}
      />
    </>
  }

  agentFireButton(agent) {
    const { game } = this.state;
    const schedule_fire = data_scheduleCEOs.find((d) => d.id === 'fire_agent');
    let onClickFireButton = () => this.onScheduleCEO(schedule_fire, agent);
    if (!game.config.opts.allow_fire_agent) {
      return null;
    }
    let popup_message_key = '';

    const popup_buttons = [
      {
        label: L('loc_ui_button_popup_common_close'),
        onClick: () => {
          this.overlayPopMaybe('GENERAL');
        },
      }
    ];

    if (game.events.length > 0) {
      popup_message_key = 'during_another_event';
    }
    else if (agentHasModifierEffect(agent, 'block_fire')) {
      popup_message_key = 'blocked_by_modifier';
    }
    else if (agent.squad !== undefined) {
      popup_message_key = 'squad_member';
      popup_buttons.push(
        {
          label: L('loc_ui_button_popup_fire'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            this.onScheduleCEO(schedule_fire, agent);
          },
          primary: true,
        }
      );
    }

    if (popup_message_key !== '') {
      onClickFireButton = () => {
        this.renderGeneralPopup(
          {
            ty: 'simple',
            title: L('loc_ui_string_common_notice'),
            message: L(`loc_ui_longtext_popup_fire_${popup_message_key}`),
            agents: null,
            choices: null,
            buttons: popup_buttons,
            scene: 'tutorial',
          }
        );
      }
    }
    return <div {...soundprops(null)} className='grind-fire-button2' cueList={['UI_Outgame_Mercenary_Dismiss']}
      onClick={onClickFireButton}>
      <div className='grind-fire-button2-inner'>
        <span className='grind-fire-button2-label'>{L('loc_data_string_schedule_ceo_fire_agent')}</span>
      </div>
    </div>;
  }

  makeModifierIcons(ch) {
    let note = null;
    const visible_modifiers = ch.modifier.filter(({ key }) => data_agentModifiers.find((d) => d.key === key).visible);
    if (visible_modifiers.length > 0) {
      note = visible_modifiers.map((mod, i) => <>
        <AgentModifierIcon key={i} modifier={mod} turn={this.state.game.turn} />
      </>);
    };
    return note;
  }

  renderAgentsPopup() {
    const { game, focusedMenus, focusedEvent } = this.state;

    return <FigmaAgentsView2Wrapper
      game={game}
      focusedMenus={focusedMenus}
      focusedEvent={focusedEvent}
      onScheduleCEO={this.onScheduleCEO.bind(this)}
      conditionInfo={this.conditionInfo()}
      overlayPush={this.overlayPush.bind(this)}
      overlayPopMaybe={this.overlayPopMaybe.bind(this)}
      grindSetState={this.setState.bind(this)}
      buildEventActionButton={this.buildEventActionButton.bind(this)}
      agentFireButton={this.agentFireButton.bind(this)}
      agentHasModifierEffect={agentHasModifierEffect}
      onAssign={this.onAssign.bind(this)}
      onUnassign={this.onUnassign.bind(this)}
      makeNote={this.makeModifierIcons.bind(this)}
    />;
  }

  renderExchangeAgentPopup(overlay) {
    const { index } = overlay;
    const { game } = this.state;
    const { turn } = game

    const header = <>
      <div className="figmalist-header-title">{L('loc_ui_string_menu_employee_list')}</div>
      <div className="figmalist-header-sep" />
    </>;

    return <FigmaRecruitView
      characters={game.agents}
      renderDetailButtons={(agent) => {
        const squadIdx = 0;
        let btn_add = <DetailButton key={'assign'} disabled={!game.agentAvail(agent)} cueList={['UI_Outgame_Button_Click_Yellow']}
          onClick={() => {
            this.onExchangeAgent(squadIdx, agent, index);
            this.overlayPopMaybe('LOBBY-EXCHANGE');
          }}
          label={L('loc_ui_string_squad_agent_add')} />

        return [
          btn_add,
        ];
      }}
      header={header}
      onClickButtonClose={() => this.overlayPopMaybe('LOBBY-EXCHANGE')}
      OnClickBackgroundButton={(agent) => {
        this.overlayPush({ ty: 'AGENT_BACKGROUND', agent });
      }}
      onClickPerkUnlockButton={(character, type, key) => this.unlockAgentPerk(character, type, key)}
      turn={turn}
      getCharacterPaymentFunction={(agent) => game.getAgentPayment(agent)}
    />;
  }

  renderAssignAgentPopup() {
    const { game, focusedMenus, focusedEvent } = this.state;
    const header = <>
      <div className="figmalist-header-title">{L('loc_ui_string_menu_employee_list')}</div>
      <div className="figmalist-header-sep" />
    </>;

    return <FigmaAgentsView2Wrapper
      game={game}
      focusedMenus={focusedMenus}
      focusedEvent={focusedEvent}
      onScheduleCEO={null}
      conditionInfo={this.conditionInfo()}
      overlayPush={this.overlayPush.bind(this)}
      overlayPopMaybe={this.overlayPopMaybe.bind(this)}
      grindSetState={this.setState.bind(this)}
      buildEventActionButton={this.buildEventActionButton.bind(this)}
      agentFireButton={null}
      agentHasModifierEffect={agentHasModifierEffect}
      onAssign={(squadIdx, character) => {
        this.onAssign(0, character);
        this.overlayPopMaybe('LOBBY-ASSIGN');
      }}
      onUnassign={this.onUnassign.bind(this)}
      header={header}
      onClickButtonClose={() => this.overlayPopMaybe('LOBBY-ASSIGN')}
      OnClickBackgroundButton={(agent) => {
        this.overlayPush({ ty: 'AGENT_BACKGROUND', agent });
      }}
      onClickPerkUnlockButton={(character, type, key) => this.unlockAgentPerk(character, type, key)}

      getCharacterPaymentFunction={(agent) => game.getAgentPayment(agent)}
      makeNote={this.makeModifierIcons.bind(this)}
    />;
  }


  onRecruit(agent) {
    const { game } = this.state;

    const first_suffix = game.turn < TICK_PER_DAY ? '_first' : '';
    const event_id = `event_ceo_recruit${first_suffix}`;

    const eventCore = data_eventCores.find((d) => d.id === event_id);

    game.ceoSchedules.push(`${event_id}_${agent.idx}`);
    game.events.push({ ty: 'eventCEO', value: { eventCore, target: agent } });

    game.dialogMaybe(['recruit_start']);

    this.setState({ game });
  }

  renderRecruitsPopup() {
    const { game, focusedMenus, focusedEvent } = this.state;

    return <FigmaRecruitView2Wrapper
      game={game}
      focusedMenus={focusedMenus}
      focusedEvent={focusedEvent}
      overlayPush={this.overlayPush.bind(this)}
      overlayPopMaybe={this.overlayPopMaybe.bind(this)}
      grindSetState={this.setState.bind(this)}
      onRecruit={this.onRecruit.bind(this)}
      buildEventActionButton={this.buildEventActionButton.bind(this)}
      onScheduleCEO={this.onScheduleCEO.bind(this)}
      makeNote={this.makeModifierIcons.bind(this)}
    />;
  }

  menuCallbacks() {
    const { game } = this.state;
    const callbacks = {
      'squad': this.onLobbySquadButton.bind(this),
      'mission': null,
      'recruits': this.onLobbyRecruitsButton.bind(this),
      'agents': this.onLobbyAgentsButton.bind(this),
      'shop': this.onLobbyShopButton.bind(this),
      'inventory': this.onLobbyInventoryButton.bind(this),
      'mission-final': null,
    };

    if (game.events.length <= 0 && !this.state.focusedEvent) {
      callbacks['mission'] = this.onLobbyMissionButton.bind(this);
      if (game.enable_final) {
        callbacks['mission_final'] = this.onLobbyMissionFinalButton.bind(this);
      }
    }
    if (!game.block_call_superior) {
      callbacks['call_superior'] = this.onLobbyCallSuperiorButton.bind(this);
    }
    return callbacks;
  }

  renderPendings() {
    const { game, overlays } = this.state;
    const { schedule_key } = game;

    let body = null;
    switch (schedule_key) {
      case 'agents': {
        body = <GrindAgentsScheduleView
          game={game}
          onSelectChanged={() => this.setState({ game })}
          onClickPortrait={(agent) => this.overlayPush({ ty: "AGENT_DETAIL", agent })}
          conditionInfo={this.conditionInfo()}
          onScheduleCEO={this.onScheduleCEO.bind(this)}
        />;
        break;
      }
      case 'mission_brief':
        body = <>
          <div>
            <font size='20'>{L('loc_ui_string_lobby_inspection_title')}</font>
          </div>
          <div>
            <font size='15'>{L('loc_ui_longtext_lobby_button_next_mission_inspection_desc')}</font>
          </div>
        </>;
        break;
      case 'final_brief':
        body = <>
          <div>
            <font size='20'>{L('loc_ui_string_lobby_final_title')}</font>
          </div>
          <div>
            <font size='15'>{L('loc_ui_longtext_lobby_button_next_mission_final_desc')}</font>
          </div>
        </>;
        break;
      case 'mission_result':
        body = this.renderMissionResult(game);
        break;
      case 'mission_exp':
        body = this.renderMissionExp(game);
        break;
      case 'checkpoint_result':
        body = this.renderCheckpointResult(game);
        break;
      case '':
        break;
      default:
        console.error(`unknown schedule_key: ${schedule_key}`);
        break;
    }
    return body;
  }

  conditionInfo() {
    const { game } = this.state;
    const { integrity, agents_avail_all } = game;
    const integrityData = getIntegrityData(integrity);
    let condition_total = 0;
    let mood_total = 0;
    let power_total = 0;
    for (const agent of agents_avail_all) {
      condition_total += agent.condition;
      mood_total += agent.mood;
      power_total += agent.power;
    }

    const trustData = getTrustData(game.trust);
    const trust_body = <span className={`flex-stamina-label-${trustData.idx}`}>{L(trustData.name) + ' (' + game.trust.toFixed(0) + ')'}</span>;

    let condition_body, mood_body, power_body;
    if (agents_avail_all.length > 0) {
      const condition_average = condition_total / agents_avail_all.length;
      const mood_average = mood_total / agents_avail_all.length;
      const power_average = power_total / agents_avail_all.length;

      const conditionData = getConditionData(condition_average);
      const moodData = getMoodData(mood_average);

      condition_body = <span className={`flex-stamina-label-${conditionData.idx}`}>{L(conditionData.name) + ' (' + condition_average.toFixed(1) + ')'}</span>;
      mood_body = <span className={`flex-stamina-label-${moodData.idx}`}>{L(moodData.name) + ' (' + mood_average.toFixed(1) + ')'}</span>;
      power_body = `${power_average.toFixed(1)}`;
    }
    else {
      condition_body = '-';
      mood_body = '-';
      power_body = '-';
    }

    const schedule_id = 'meeting_team';
    const schedule_meeting = data_scheduleCEOs.find((d) => d.id === schedule_id);
    let schedule_allowed = !(game.ceoSchedules.includes(schedule_id) || game.events.length > 0 || this.state.focusedEvent);
    if (game.agents_avail_all.length === 0) {
      schedule_allowed = false;
    }

    return {
      integrity,
      integrityData,
      condition_body,
      mood_body,
      power_body,
      trust_body,

      schedule_meeting,
      schedule_allowed,
    };
  }

  startIngame(squadIdx) {
    const { game } = this.state;
    const squad = game.squads[squadIdx];

    if (squad.agents.length === 0) {
      game.squadStart(0, squad.config_key);
      this.onFinish(0, null, 1);
      this.setState({ game });
      return;
    }

    this.serializeState(1, L('loc_ui_string_autosave_start_ingame'));

    triggerBgm('BGM_Outgame_Default_Loop', 'Stop');

    game.dialogMaybe(['trial_before', `${game.weeks()}`], () => {
      game.squadStart(0, squad.config_key);

      const { tooltipContext: ctx } = this.state;
      this.setState({ game, tooltipContext: { ...ctx, content: null } });
    });
    this.setState({ game });
  }

  renderSquadPopup(props) {
    const { game, focusedMenus } = this.state;
    const { squad, squadIdx } = props;

    let onUnassign = (agent) => this.onUnassign(squadIdx, agent, 'player');
    let onExchangeAgent = (index) => this.overlayPush({ ty: 'LOBBY-EXCHANGE', index });
    if (!game.lobby.recruits) {
      // 채용 안 열렸을 때는 스쿼드 UI 제한합니다.
      onUnassign = null;
      onExchangeAgent = null;
    }

    const onBack = () => {
      this.overlayPopMaybe('LOBBY-SQUAD');
      this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
    };

    const body = <div className="figmasquad-wrapper">

    </div>;

    return <FigmaSquadView2Wrapper
      game={game}
      squad={squad}
      focusedMenus={focusedMenus}
      onAgentEquipAvail={this.onAgentEquipAvail.bind(this)}
      onAgentEquipPopup={this.onAgentEquipPopup.bind(this)}
      onAgentDetail={(agent) => {
        this.overlayPush({ ty: 'AGENT_DETAIL', agent });
      }}
      onUnassign={onUnassign}
      onChangeAgentOrder={(prev, next) => {
        this.onChangeAgentOrder(squadIdx, prev, next);
      }}
      onExchangeAgent={onExchangeAgent}
      onAssignAgent={() => this.overlayPush({ ty: 'LOBBY-ASSIGN' })}
      inventories={game.inventories}
      overlayPush={this.overlayPush.bind(this)}
      overlayPopMaybe={this.overlayPopMaybe.bind(this)}
      grindSetState={this.setState.bind(this)}
      buildEventActionButton={this.buildEventActionButton.bind(this)}
      makeNote={this.makeModifierIcons.bind(this)}
    />;
  }

  get missionIdx() {
    const { game } = this.state;
    return game.missionIdx ?? 0;
  }

  renderMissionPopup() {
    const { game, focusedEvent, focusedMenus } = this.state;

    let onClickButtonStart = () => {
      if (!focusedEvent) {
        const { mission_schedule_ty } = game;
        GameAnalytics.addDesignEvent(`ingame:${this.missionIdx}:${mission_schedule_ty}:${game.weeks2()}:start`);

        if (mission_schedule_ty !== 'trial') {
          this.overlayPopMaybe('LOBBY-MISSION');
          this.overlayPopMaybe('LOBBY-SQUAD');
        }

        game.highlight_button['mission_next'] = false;
        this.startIngame(0);
      }
    };

    const squad = game.squads[0];
    const adviceList = MakePlanStrategyHeaders(
      {
        currentPlan: squad.current_plan,
        current: -1,
        squad,
        agents: squad.agents,
        segments: game.getPlanPreset(squad.config_key, squad.current_plan).segments
      });

    if (adviceList.length > 0) {
      let buttons = [];
      if (game.agents.length + squad.agents.length < 4) {
        buttons.push(
          {
            label: L('loc_ui_button_general_tutorial_headhunter'),
            onClick: () => {
              this.overlayPopMaybe('GENERAL');
              this.overlayPush({ ty: 'LOBBY-RECRUITS' });
            },
            primary: true,
          }
        );
      }
      if (game.agents.length > 0 && squad.agents.length < 4) {
        const isPrimary = (game.agents.length + squad.agents.length >= 4)
          && (squad.agents.length < 4);
        buttons.push(
          {
            label: L('loc_ui_button_general_tutorial_squad'),
            onClick: () => {
              this.overlayPopMaybe('GENERAL');
              this.overlayPush({ ty: 'LOBBY-AGENTS' });
            },
            primary: isPrimary,
          }
        );
      }
      buttons.push(
        {
          label: L('loc_ui_button_general_tutorial_start_anyway', { value: squad.agents.length }),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            if (game.mission_schedule_ty !== 'trial') {
              this.overlayPopMaybe('LOBBY-MISSION');
              this.overlayPopMaybe('LOBBY-SQUAD');
            }
            game.highlight_button['mission_next'] = false;
            this.startIngame(0);
          }
        }
      );
      buttons.push(
        {
          label: L('loc_ui_button_popup_common_close'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
          },
        }
      );
      onClickButtonStart = () => {
        game.highlight_button['mission_next'] = false;
        this.renderGeneralPopup(
          {
            ty: 'simple',
            title: L('loc_ui_string_common_notice'),
            message: <div>
              {adviceList}
            </div>,
            agents: null,
            choices: null,
            buttons: buttons,
            scene: 'tutorial',
          }
        );
      }
    }

    let onClickButtonFinal = null;
    if (game.enable_final) {
      onClickButtonFinal = () => {
        this.overlayPopMaybe('LOBBY-MISSION');
        this.overlayPopMaybe('LOBBY-SQUAD');
        game.highlight_button['mission_next'] = false;

        GameAnalytics.addDesignEvent(`ingame:${this.missionIdx}:final:${game.weeks2()}:start`);

        this.startIngame(0);
      }
    }

    let buttons = [{
      key: 'mission_prev',
      label: L('loc_ui_string_lobby_button_next_back'),
      desc: L('loc_ui_longtext_lobby_button_next_back_desc'),
      onClick: () => {
        this.overlayPopMaybe('LOBBY-MISSION');
        this.setState({ focusedMenus: focusedMenus.slice(0, -1) });
      },
      enabled: true,
    }];

    let label = L('loc_ui_string_lobby_button_next_mission_wargame');
    let desc = L('loc_ui_longtext_lobby_button_next_mission_wargame_desc');
    let onClick = onClickButtonStart;
    if (game.mission_schedule_ty === 'checkpoint') {
      label = L('loc_ui_string_lobby_button_next_mission_inspection');
      desc = L('loc_ui_longtext_lobby_button_next_mission_inspection_desc');
    }
    else if (game.mission_schedule_ty === 'final' && onClickButtonFinal) {
      label = L('loc_ui_string_lobby_button_next_mission_final');
      desc = L('loc_ui_longtext_lobby_button_next_mission_final_desc');
      onClick = onClickButtonFinal;
    }
    if (game.mission_schedule_ty === 'trial' && squad.agents.length === 0) {
      onClick = null;
    }
    buttons.push({
      key: 'mission_next',
      label: label,
      desc: desc,
      onClick,
      enabled: !!onClick && !focusedEvent,
      cueList: ['UI_Outgame_SimulationTraining_Start'],
    });

    buttons.push(this.buildEventActionButton());

    const body = <FigmaPlanPopupView game={game} squad={game.squads[0]}
      onReset={() => {
        game.resetSquadControls(0);
        this.setState({ game });
      }}
      onClickButtonStart={onClickButtonStart}
      onClickButtonFinal={onClickButtonFinal}
      onAgentUpdate={(agent) => this.setState({ game })} />;

    return <>{body}
      <LobbyRightFooter
        game={game}
        overrideNextActionButtons={buttons}
      />
    </>;
  }

  renderObjectivePopup() {
    const { game } = this.state;
    const { config_key } = game.squads[0];
    const config = CONFIGS.find((c) => c.key === config_key);

    const { objective } = config;
    const states = game.getMilestoneStates();

    return this.renderFigmaPopup({
      ty: 'simple',
      title: L('loc_ui_string_missionbrief_contract_information'),
      message: <div style={{ height: "100%" }}>
        <h1>{L('loc_ui_string_missionbrief_mission_title')}: {L(config.descr)}</h1>

        <br />
        <Lobby2Goals states={states} />
        <br />

        <p className="figmapopup-content-message-body">
          {L(objective.descr).replace('\r', '<br/>')}
        </p>
      </div>,
      agents: null,
      choices: null,
      buttons: [
        {
          label: L('loc_ui_button_missionbrief_contract_confirm_contract'),
          onClick: () => {
            this.overlayPopMaybe('LOBBY-OBJECTIVE');
          },
          primary: true,
        },
      ],
      scene: null,
    });
  }

  buildEventActionButton() {
    const { focusedEvent, showEvent } = this.state;

    if (!focusedEvent || showEvent) {
      return null;
    }
    const title = focusedEvent.value?.eventCore?.title;
    return {
      key: 'event',
      label: `${L('loc_ui_string_lobby_button_next_decision')}: [${L(title)}]`,
      desc: L('loc_ui_longtext_lobby_button_next_decision_desc'),
      onClick: () => {
        this.setState({ showEvent: true })
      },
      enabled: true,
      className: 'event',
    };
  }

  onMissionResultClose(popup) {
    this.overlayPopMaybe('MISSION-RESULT');

    const { game } = this.state;
    const [squad] = game.squads;

    const { entity_exp } = squad.history[squad.history.length - 1];
    if (Object.keys(entity_exp).length > 0) {
      if (popup) {
        this.overlayPush({ ty: 'MISSION-EXP', stopTimer: true });
      } else {
        game.schedule_key = 'mission_exp';
      }
    } else {
      if (!popup) {
        game.schedule_key = '';
        this.setPaused(false);
      }
    }

    this.setState({ game });
  }

  onMissionExpClose(popup) {
    this.overlayPopMaybe('MISSION-EXP');

    const { game } = this.state;

    const { squads } = game;
    const squadIdx = 0;
    const squad = squads[squadIdx];

    const { entity_exp } = squad.history[squad.history.length - 1];
    for (const [key, exp] of Object.entries(entity_exp)) {
      const agent = game.agents_avail_all.find((a) => a.idx === parseInt(key));
      if (agent) {
        game.addAgentExp(agent, exp.total.value);
      }
    }
    const { mission_schedule_ty } = game;

    let focusLobby = this.state.focusLobby;
    if (mission_schedule_ty === 'final') {
      this.props?.onDone?.({
        result: 'clear',
        game,
        sim: this.state.sim,
      });
    } else if (mission_schedule_ty === 'checkpoint') {
      // TODO: checkpoint인 경우, 주간 결과 팝업을 띄워야 합니다.
      const { history } = game.squads[0];
      const history_item = history[history.length - 1];
      history_item.checkpoint_result = game.updateMilestoneStates();
      if (popup) {
        this.overlayPush({ ty: 'CHECKPOINT-RESULT' });
      } else {
        game.schedule_key = 'checkpoint_result';
      }
    } else {
      if (!popup) {
        game.schedule_key = '';
        this.setPaused(false);
      }
      focusLobby = true;
    }

    this.setState({ focusLobby, game });
  }

  onCheckpointResultClose(popup) {
    const { game } = this.state;

    this.overlayPopMaybe('CHECKPOINT-RESULT');

    this.overlayPush({ ty: 'WEEK-TRANSITION', week: game.weeks2() + 1 });
    // checkpoint 결과 반영하기
    const focusLobby = true;
    this.setState({ focusLobby: true, game });

  }

  buildNextActionButton() {
    const { game, paused, focusedEvent, focusLobby, focusedMenus } = this.state;
    const { schedule_key } = game;

    if (focusLobby) {
      return {
        key: 'lobby_next',
        label: L('loc_ui_string_lobby_button_next_schedule_setting'),
        desc: L('loc_ui_longtext_lobby_button_next_schedule_setting_desc'),
        onClick: () => {
          if (schedule_key === 'agents') {
            game.dialogMaybe(['train', `${game.date}`]);
          }
          if (schedule_key !== '') {
            game.dialogMaybe(['start_schedule', schedule_key]);
          }
          this.setState({ focusLobby: false, focusedMenus: focusedMenus.concat(['schedule']) })
        },
        enabled: paused && !focusedEvent,
      };
    }

    const next_button_key = `schedule_${schedule_key}_next`;

    switch (schedule_key) {
      case 'agents': {
        return {
          key: next_button_key,
          label: 'loc_ui_string_lobby_button_next_schedule',
          desc: 'loc_ui_longtext_lobby_button_next_schedule_desc',
          onClick: () => {
            game.schedule_key = '';
            this.setState({ game, focusLobby: true, focusedMenus: ['mission_objective'] });
          },
          enabled: true,
        };
      }
      case 'mission_brief':
        return {
          key: next_button_key,
          label: L('loc_ui_string_lobby_button_next_squad'),
          desc: L('loc_ui_longtext_lobby_button_next_squad_desc'),
          onClick: () => {
            game.mission_schedule_ty = 'checkpoint';
            this.overlayPush({ ty: 'LOBBY-SQUAD' });
            this.setState({ game });
          },
          enabled: true,
        };
      case 'final_brief':
        return {
          key: next_button_key,
          label: L('loc_ui_string_lobby_button_next_squad'),
          desc: L('loc_ui_longtext_lobby_button_next_squad_desc'),
          onClick: () => {
            game.mission_schedule_ty = 'final';
            this.overlayPush({ ty: 'LOBBY-SQUAD' });
            this.setState({ game });
          },
          enabled: true,
        };
      case 'event_agent':
        return {
          key: next_button_key,
          label: 'loc_ui_string_lobby_button_next_decision',
          desc: 'loc_ui_longtext_lobby_button_next_decision_desc',
          onClick: null,
          enabled: false,
        };
      case 'mission_result':
        return {
          key: next_button_key,
          label: 'loc_ui_button_common_confirm',
          desc: null,
          onClick: () => this.onMissionResultClose(false),
          enabled: true,
        };
      case 'mission_exp':
        return {
          key: next_button_key,
          label: 'loc_ui_button_common_confirm',
          desc: null,
          onClick: () => this.onMissionExpClose(false),
          enabled: true,
        };
      case 'checkpoint_result':
        return {
          key: next_button_key,
          label: 'loc_ui_button_common_confirm',
          desc: null,
          onClick: () => this.onCheckpointResultClose(false),
          enabled: true,
        };
      default:
        if (!paused) {
          return {
            key: 'ongoing_next',
            label: 'loc_ui_string_lobby_button_next_ongoing',
            desc: 'loc_ui_longtext_lobby_button_next_ongoing_desc',
            onClick: null,
            enabled: false,
          };
        } else {
          return {
            key: 'default_next',
            label: 'loc_ui_string_lobby_button_next_tomorrow',
            desc: 'loc_ui_longtext_lobby_button_next_tomorrow_desc',
            onClick: () => this.setPaused(false),
            enabled: true,
          };
        }
    }
  }

  buildNextActionButtons() {
    const button = this.buildNextActionButton();
    const eventbtn = this.buildEventActionButton();
    return [button, eventbtn];
  }

  renderNewAgentsPopup() {
    const { game } = this.state;
    const { agents_avail_all } = game;
    let { new_agents } = game.attentions;

    const agent = new_agents[0];
    new_agents = new_agents.splice(0, 1);
    let name = agent.name;

    this.setState({ game });
    const isRecruitsPrimary = agents_avail_all.length < 4;
    const isAgentsPrimary = agents_avail_all.length >= 4;

    // TODO: 맥락에 따라 버튼 보여주기
    let buttons = [
      {
        label: L('loc_ui_button_popup_common_close'),
        onClick: () => {
          game.dialogMaybe(['overlay_close', 'new-agents']);
          this.overlayPopMaybe('GENERAL');
        },
      },
    ];

    return this.renderGeneralPopup({
      ty: 'eventAgents',
      title: L('loc_ui_string_common_notice'),
      message: L('loc_dynamic_longtext_popup_tutorial_recruit_basic', { value: L(name) }) + ((agents_avail_all.length <= 1) ? '\n' + L('loc_ui_longtext_popup_tutorial_recruit_additional') : ''),
      agents: [agent],
      choices: null,
      buttons,
      scene: 'tutorial',
    });
  }

  renderNewAssignedAgentsPopup() {
    const { game } = this.state;
    const squad = game.squads[0];
    let { new_assigned_agents } = game.attentions;

    const agent = new_assigned_agents[0];
    new_assigned_agents = new_assigned_agents.splice(0, 1);
    let name = agent.name;

    this.setState({ game });

    return this.renderGeneralPopup({
      ty: 'eventAgents',
      title: L('loc_ui_string_common_notice'),
      message: L('loc_dynamic_longtext_general_tutorial_squad_assigned_basic', { value: L(name) }) + ((squad.agents.length <= 1) ? '\n' + L('loc_ui_longtext_general_tutorial_squad_assigned_additonal') : ''),
      agents: [agent],
      choices: null,
      buttons: [
        {
          label: L('loc_ui_button_general_tutorial_squad'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            this.overlayPopMaybe('LOBBY-AGENTS');
            this.overlayPush({ ty: 'LOBBY-SQUAD' });
          },
          primary: false,
        },
        {
          label: L('loc_ui_button_popup_common_close'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            this.overlayPopMaybe('LOBBY-AGENTS');
          },
          primary: false,
        },
      ],
      scene: 'tutorial',
    });
  }

  renderNewUnassignedAgentsPopup() {
    const { game } = this.state;
    let { new_unassigned_agents } = game.attentions;

    const { agent, reason } = new_unassigned_agents[0];
    new_unassigned_agents = new_unassigned_agents.splice(0, 1);
    if (reason === 'player' || reason === 'fire') {
      return null;
    }
    let name = agent.name;
    let message = '';
    if (reason === 'modifier') {
      message = L('loc_dynamic_longtext_general_tutorial_squad_unassigned', { value: L(name) });
    }
    this.setState({ game });
    return this.renderGeneralPopup({
      ty: 'eventAgents',
      title: L('loc_ui_string_common_notice'),
      message: message,
      agents: [agent],
      choices: null,
      buttons: [
        {
          label: L('loc_ui_button_general_tutorial_squad'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            this.overlayPush({ ty: 'LOBBY-SQUAD' });
          },
        },
        {
          label: L('loc_ui_button_general_tutorial_employee_list'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
            this.overlayPush({ ty: 'LOBBY-AGENTS' });
          },
        },
        {
          label: L('loc_ui_button_popup_common_close'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
          },
        },
      ],
      scene: 'tutorial',
    });
  }

  renderNewItemsPopup() {
    const { game } = this.state;
    const { new_items } = game.attentions;

    const item = new_items[0];
    let name = '';
    if (item.ty === 'throwable') {
      const nameTemp = L(item.throwable.throwable_name);
      name = nameTemp.slice(0, nameTemp.indexOf('('));
    } else if (item.ty === 'firearm') {
      const optionText = item.firearm.options?.length > 0 ? item.firearm.options.join(' ') + ' ' : '';
      name = optionText + L(item.firearm.firearm_name);
    } else if (item.ty === 'equipment') {
      name = L(item.equipment.vest_name);
    };

    new_items.splice(0, 1);
    this.setState({ game });
    return this.renderGeneralPopup({
      ty: 'simple',
      title: L('loc_ui_string_common_notice'),
      message: L('loc_dynamic_longtext_general_tutorial_new_item_basic', { value: name }) + ((game.inventories.length <= 1) ? '\n' + L('loc_ui_longtext_general_tutorial_new_item_additional') : ''),
      agents: null,
      choices: null,
      buttons: [
        {
          label: L('loc_ui_button_popup_common_close'),
          onClick: () => {
            this.overlayPopMaybe('GENERAL');
          },
        },
      ],
      scene: 'tutorial',
    });
  }

  renderDialog(overlay) {
    const { dialog, cb, squadNames } = overlay;
    return <DialogView data={dialog.dialog} squadNames={squadNames} onDone={() => {
      this.overlayPopMaybe('DIALOG');
      const result = cb?.();
      if (result?.focusLobby) {
        this.setState({ focusLobby: true, focusedMenus: ['mission_objective'] });
      }
    }} />;
  }

  renderUnable(overlay) {
    const { warning } = overlay;

    return <div className='grind-unablewarning'>
      <span className='grind-unablewarning-label'>{warning}</span>
    </div>;
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDialog();
  }

  updateDialog() {
    const { game } = this.state;
    const { events } = game;

    if (this.overlay_cur?.ty === 'DIALOG') {
      return;
    }
    const dialog = events.find((e) => e.ty === 'dialog');
    if (!dialog) {
      return;
    }

    events.splice(events.indexOf(dialog), 1);
    this.overlayPush({
      ...dialog,
      ty: 'DIALOG',
    });
    this.setState({
      game,
      focusedEvent: null,
    });
  }

  renderActionButtons() {
    const { game, focusLobby, paused, focusedEvent } = this.state;

    let showBackButton = !focusLobby;
    if (['mission_result', 'mission_exp'].includes(game.schedule_key)) {
      showBackButton = false;
    }

    const onClickMainButton = () => {
      game.highlight_button[`schedule_${game.schedule_key}_prev`] = false;
      game.dialogMaybe(['focus_lobby']);
      if (game.schedule_key === 'final_brief' && !game.last_day) {
        game.schedule_key = game.prev_final_schedule_key;
      }
      this.setState({ focusLobby: true, focusedMenus: ['mission_objective'] });
    }
    const onClickNextActionButton = () => {
      game.highlight_button[`schedule_${game.schedule_key}_next`] = false;
      this.setPaused(false)
    }
    let onClickFinalButton = null;
    let finalEnabled = false;
    if (game.enable_final && game.schedule_key !== 'final_brief') {
      onClickFinalButton = this.onLobbyMissionFinalButton.bind(this);
      finalEnabled = paused && game.events.length <= 0 && !focusedEvent;
    }

    return <Lobby2Footer
      game={game}
      label={L('loc_ui_string_lobby_button_next_tomorrow')}
      desc={L('loc_ui_longtext_lobby_button_next_tomorrow_desc')}
      buttonEnabled={this.canProgress()}
      onClickNextAction={onClickNextActionButton}
      overrideNextActionButtons={this.buildNextActionButtons()}
      mainButtonVisible={showBackButton}
      onClickMainButton={onClickMainButton}
      mainButtonHighlighted={game.highlight_button[`schedule_${game.schedule_key}_prev`]}
      onClickFinalButton={onClickFinalButton}
      finalEnabled={finalEnabled}
    />;
  }

  render() {
    const { game, autoload, focusedEvent, focusedMenus, overlays, paused } = this.state;
    const { events } = game;
    if (autoload >= 0) {
      return null;
    }

    const ongoing = game.squads.find((s) => s.state === 'mission' && s.mission_state?.simstate);
    if (ongoing) {
      const squad = ongoing;
      const squadIdx = game.squads.indexOf(squad);

      const { simstate, seed } = ongoing.mission_state;

      // 작전 계획 정보도 세이브 데이터에 저장할 수 있도록 설정합니다.
      simstate.segment_controls_list = [];
      for (let i = 0; i < game.squads.length; i++) {
        const controls = game.squadControlsList(i);
        const entity_controls = [];
        for (const agent of squad.agents) {
          entity_controls.push({
            idx: agent.idx,
            controls: game.agentControlsList(game.squads[i], agent)
          });
        }

        simstate.segment_controls_list.push({ controls, entity_controls });
      }

      let cls = "flex-simview";
      if (window.ue?.connection) {
        cls += " flex-simview-canvas";
      } else {
        cls += " flex-simview-canvas-browser";
      }

      return <div className={cls}>
        <SimView ref={(e) => { this.simRefs[squadIdx] = e; }}
          m={this.props.m}
          seed={seed}
          debug={false}
          debug1={this.state.debug}
          nocontrol={false}
          showoverlay={true}
          onEvent={(ev) => this.onSimEvent(squadIdx, ev)}
          onFinish={(sim, res) => this.onFinish(squadIdx, sim, res)}
          autoscroll={true}
          embed={false}
          is_practice={game.mission_schedule_ty !== 'final'}
          viewWidth={1024}
          viewHeight={1024}
          defaultScale={2}
          onSimCreate={(sim) => {
            const segment = 0;

            for (const agent of squad.agents) {
              const controls_list = game.agentControlsList(squad, agent);
              sim.entityControlsSet(agent.idx, controls_list?.[segment]);
            }
            const controls_list = game.squadControlsList(squadIdx);
            sim.controlsSet(0, controls_list?.[segment]);
          }}
          simstate={simstate}
        />
      </div>;
    }

    // TODO: 고쳐야 함
    if (game.attentions.new_agents.length > 0) {
      this.renderNewAgentsPopup();
    }
    if (game.attentions.new_assigned_agents.length > 0) {
      this.renderNewAssignedAgentsPopup();
    }
    if (game.attentions.new_unassigned_agents.length > 0) {
      this.renderNewUnassignedAgentsPopup();
    }
    if (game.attentions.new_items.length > 0) {
      this.renderNewItemsPopup();
    }

    const callbacks = this.menuCallbacks();
    const onClicks = Object.assign({}, ...Object.entries(game.lobby).map(([key, value]) => {
      let cb = null;
      if (value) {
        cb = callbacks[key] ?? null;
      }
      return { [key]: cb };
    }));

    const { overlay, showActions } = this.renderOverlay();


    if (game.gameover && overlay === null) {
      return <div>
        <font size={8}>
          gameover
        </font>
        <WishlistButton eventType={`gameover`} />
      </div>
    }

    if (!focusedEvent && events.length > 0) {
      this.setPaused(true);
      this.setState({ focusedEvent: events[events.length - 1], showEvent: true });
    }

    let stopTimer = false;
    if (overlays.filter((o) => o.stopTimer).length > 0) {
      stopTimer = true;
    }
    if (game.popups.filter((p) => p.stopTimer).length > 0) {
      stopTimer = true;
    }

    if (paused && game.schedule_key === '' && !stopTimer) {
      this.setPaused(false);
      this.setState({ focusLobby: true, focusedMenus: ['mission_objective'] });
    }
    else if (!paused && stopTimer) {
      this.setPaused(true);
    }

    if (focusedMenus.length < 1) {
      this.setState({ focusedMenus: ['mission_objective'] });
    }

    return <TooltipContext.Provider value={{
      ctx: this.state.tooltipContext, setCtx: (ctx) => {
        this.setState({ tooltipContext: ctx });
      }
    }}>
      <div className="outgame-lobby" onMouseMove={(e) => {
        if (!e._tooltipmove) {
          this.setState({ tooltipContext: { ...this.state.tooltipContext, content: null } });
        }
      }}>
        {this.state.focusLobby &&
          focusedMenus[focusedMenus.length - 1] === 'mission_objective' &&
          <Lobby2Clipboard game={game} />
        }
        <div className='lobby2-light'>
          <img className='lobby2-light-multiply'
            src='/img/cutscene/EFX_CC_Multiply.png' />
          <img className='lobby2-light-overlay'
            src='/img/cutscene/EFX_CC_Overlay.png' />
        </div>
        {
          !this.state.focusLobby &&
          <div className="lobby-pendings">
            {this.renderPendings()}
          </div>
        }
        <div className="lobby2-header">
          <Lobby2HeaderPrimary
            game={game}
            onClickMenu={() => {
              this.onLobbyMenuButton();
              triggerSound('UI_Outgame_Button_Click_Default');
            }}
            onClickDetail={game.weeklyReports.length > 1 ? () => {
              game.popups.push({
                ty: 'WEEKLY_MEETING',
                trigger_enable_final: false,
              });
              this.setState({ game });
            } : null}
          />
          <div className="lobby2-header-divider" />
          <Lobby2HeaderSecondary game={game} />
        </div>
        <Lobby2SideMenu game={game} onClicks={onClicks} focusedMenu={focusedMenus[focusedMenus.length - 1]} />

        {!showActions && this.renderActionButtons()}
        {overlay}
      </div>

      {renderTooltip(this.tooltipRef)}
    </TooltipContext.Provider >;
  }
}
