import source from '../downloaded/potential.json' assert { type: 'json' };
import * as weights from './data_weights.mjs';

const potentials = [];
for (const row of source) {
  let { label, potential_min, potential_max, weight,
    overall_min,
    overall_max,
    stat_min,
    stat_max,
    physical_min,
    physical_max,
    prob_vocation,
    power_mult,
    exp_mult,
  } = row;

  potentials.push({
    label,
    potential_min: +potential_min,
    potential_max: +potential_max,
    weight: +weight,
    prob_vocation: +prob_vocation,
    power_mult: +power_mult,
    exp_mult: +exp_mult,

    stat_min: +stat_min,
    stat_max: +stat_max,
    overall_min: +overall_min,
    overall_max: +overall_max,
    physical_min: +physical_min,
    physical_max: +physical_max,

    // backward compatibility
    prob_point: ((+overall_min) + (+overall_max)) / 2,
    prob_physical: ((+physical_min) + (+physical_max)) / 2,
  });
}

export function descrPotential(potential) {
  const { label, prob_point, prob_vocation, power_mult, exp_mult, prob_physical } = potential;
  return `grade: ${label}
포인트 획득 확률: ${(prob_point * 100).toFixed(1)}%
직업 획득 확률: ${(prob_vocation * 100).toFixed(1)}%
피지컬 성장 확률: ${(prob_physical * 100).toFixed(1)}%
성장 배수: ${power_mult.toFixed(1)}배
경험치 배수: ${exp_mult.toFixed(1)}배
`;
}

export function findByLabel(key) {
  return potentials.find(({ label }) => {
    return label === key;
  });
}

export function findByPotential(potential) {
  return potentials.find(({ potential_min, potential_max }) => {
    return potential_min <= potential && potential < potential_max;
  });
}

export function sample(rng, areaNum, delta) {
  const label = weights.sample(rng, areaNum, 'potential', delta);
  const selected = findByLabel(label);
  return rng.range(selected.potential_min, selected.potential_max);
}
