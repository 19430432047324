import source from '../downloaded/growthcap.json' assert { type: 'json' };
import * as weights from './data_weights.mjs';

const list = [];
for (const row of source) {
  let { label, weight, power_cap, spawn_power_min, spawn_power_max, stat_deviation } = row;
  list.push({
    label,
    weight: +weight,
    power_cap: +power_cap,
    spawn_power_min: +spawn_power_min,
    spawn_power_max: +spawn_power_max,
    stat_deviation: +stat_deviation,
  });
}

export function descr(data) {
  const { label, power_cap } = data;
  return `grade: ${label}
최대 성장: ${power_cap}`;
}

export function find(key) {
  return list.find(({ label }) => label === key);
}

export function sample(rng, areaNum, delta) {
  const label = weights.sample(rng, areaNum, 'growthcap', delta);
  return find(label);
}
