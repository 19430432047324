import source from '../downloaded/modifiers.json' assert { type: 'json' };

export const modifiers = {};
export const agendakeys = [];
for (const row of source) {
  let { type, key, name, desc, overwrite } = row;
  overwrite = overwrite ?? '';
  overwrite = overwrite.split(', ');
  if (key.includes('agenda')) {
    agendakeys.push(key);
  }
  modifiers[key] = {
    type, name, desc, overwrite
  }
}
